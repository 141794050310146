import { GraphicsBanner } from '../../GraphicsBanner';
import { Graphics } from './styles';

export const NoProgressBanner = (): JSX.Element => (
  <GraphicsBanner>
    <GraphicsBanner.ImageArea>
      <Graphics />
    </GraphicsBanner.ImageArea>
    <GraphicsBanner.Title>No learning time registered</GraphicsBanner.Title>
    <GraphicsBanner.Description>
      {"Progress updates can be delayed up to 72 hours. Keep learning and start today if you haven't already!"}
    </GraphicsBanner.Description>
  </GraphicsBanner>
);
