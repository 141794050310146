import { SurveyVariant } from '@/pages/Survey/store/survey/types';
import { BlockId } from '../types';

export enum BlockType {
  TEXT = 'TEXT',
  RESULT = 'RESULT',
  SURVEY_RESULT = 'SURVEY_RESULT',
  REACTION = 'REACTION',
  QUESTION = 'QUESTION',
  PRACTICAL_ASSESSMENT_TEXT = 'PRACTICAL_ASSESSMENT_TEXT',
  PRACTICAL_ASSESSMENT_DOCUMENT = 'PRACTICAL_ASSESSMENT_DOCUMENT',
  REFLECTION = 'REFLECTION',
  QUIZ = 'QUIZ',
}

export type PracticalAssessmentTextBlock = {
  id: BlockId;
  type: BlockType.PRACTICAL_ASSESSMENT_TEXT;
  description: string;
  peerReviewEnabled: boolean;

  remoteId?: number;
};

export type PracticalAssessmentDocumentBlock = {
  id: BlockId;
  type: BlockType.PRACTICAL_ASSESSMENT_DOCUMENT;
  description: string;
  peerReviewEnabled: boolean;

  remoteId?: number;
};

export type TextBlock = {
  id: BlockId;
  type: BlockType.TEXT;
  text: string;

  remoteId?: number;
};

export type ResultBlock = {
  id: BlockId;
  type: BlockType.RESULT;
  level: string;
  effortHours?: number;
  effortMinutes?: number;
};

export type SurveyResultBlock = {
  id: BlockId;
  type: BlockType.SURVEY_RESULT;
  variant: SurveyVariant;
};

export enum ReactionId {
  CONTINUE_LEARNING = 'continue_learning',
  CHANGE_TOPIC = 'change_topic',
  APPLY = 'apply',
  DISCUSS = 'discuss',
  NOT_SURE = 'dont_know',
}

export type ReactionBlock = {
  id: BlockId;
  type: BlockType.REACTION;
  reactionId?: ReactionId;
};

type RadioQuestionVariant = {
  variant: 'RADIO';
  answerValue?: number;
  answerOptions: {
    order: number;
    value: number;
    title: string;
  }[];
};

type QuestionVariant = RadioQuestionVariant;

export type QuestionBlock = {
  id: BlockId;
  type: BlockType.QUESTION;
  title: string;
  description?: string;
  remoteId?: number;
} & QuestionVariant;

export type ReflectionBlock = {
  id: BlockId;
  type: BlockType.REFLECTION;
  description: string;

  sectionPath?: string[];
  postId?: number;

  remoteId?: number;
};

export type QuizBlock = {
  id: BlockId;
  type: BlockType.QUIZ;
  title: string;
  text: string;
};

export type Block =
  | TextBlock
  | QuestionBlock
  | ResultBlock
  | SurveyResultBlock
  | ReactionBlock
  | PracticalAssessmentTextBlock
  | PracticalAssessmentDocumentBlock
  | ReflectionBlock
  | QuizBlock;
