import React, { ReactNode, useContext, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { SharedSnackbarContext } from '../component/SharedSnackbar/SharedSnackbar';
import { createApolloClient } from './apolloClient';

export const CustomApolloProvider = (props: { children: ReactNode | ReactNode[] }): JSX.Element => {
  const { openSnackbar } = useContext(SharedSnackbarContext);

  const navigate = useNavigate();

  // useMemo wrapping ApolloProvider without dependencies so that app doesn't re-render on SharedSnackbarContext changes
  return useMemo(
    () => <ApolloProvider client={createApolloClient({ openSnackbar }, navigate)}>{props.children}</ApolloProvider>,
    []
  );
};
