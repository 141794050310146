import { WeeklyLearningLoop } from '../../types';
import { WeeksNavigationProvider } from '../../context';
import { WeeksSetup } from './WeeksSetup';

export type OngoingWeeksNavigationProps = {
  weeks: WeeklyLearningLoop[];
  selectedWeek: WeeklyLearningLoop | null;
  maxVisibleWeeksCount?: number;
  onChangeSelectedWeek: (weekNumber: number) => void;
  onHelpClick: () => void;
};

export const WeeksNavigation = (props: OngoingWeeksNavigationProps): JSX.Element => (
  <WeeksNavigationProvider>
    <WeeksSetup {...props} />
  </WeeksNavigationProvider>
);
