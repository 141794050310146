import { useFormContext } from 'react-hook-form';

import { LearningLoopReflectionApplied } from '@/store/learningLoops/types';
import { ApplicationField } from './ApplicationField';
import { DescriptionField } from './DescriptionField';
import { FormWrapper } from './styles';

export type FormValues = {
  description: string;
  applied: LearningLoopReflectionApplied;
};

export const Form = ({ onSubmit }: { onSubmit: () => void }): JSX.Element => {
  const { control } = useFormContext();

  return (
    <FormWrapper onSubmit={onSubmit}>
      <DescriptionField control={control} />
      <ApplicationField control={control} />
    </FormWrapper>
  );
};
