import styled, { css } from 'styled-components';

export const SelectStyled = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          display: flex;
          width: 100%;
        `
      : css`
          display: inline-block;
        `}

  position: relative;
  height: fit-content;
`;
