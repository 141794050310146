/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled, { css } from 'styled-components';

import { HeroDescription } from '@/pages/Assignment/components';
import { useElementExpandable } from '@/hooks/useElementExpandable';
import { Button } from '@/ui/Button';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

export const DescriptionStyled = styled(HeroDescription)<{
  expanded: boolean;
  fullHeight?: number;
  collapseHeight?: number;
  canExpand?: boolean;
}>`
  overflow: hidden;
  position: relative;

  transition: max-height 0.3s ease-out;

  ${({ collapseHeight: customCollapseHeight = 280, canExpand }) => {
    const collapseHeight = customCollapseHeight;

    if (!canExpand) return false;

    return css`
      max-height: ${collapseHeight}px;

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%);
        width: 100%;
        height: 100px;
      }
    `;
  }}
`;

export const Title = styled.div`
  ${(p) => p.theme.typography.title3};
  margin: 24px 0 16px 0;
`;

export const ExpandDescriptionButton = styled(Button).attrs({ variant: 'ghost' })`
  margin-left: auto;
`;

export const DESCRIPTION_COLLAPSE_THRESHOLD = 168;

const Description = ({
  description,
  businessGoal,
  onShowMore,
}: {
  description?: string;
  businessGoal?: string;
  onShowMore: () => void;
}): JSX.Element => {
  const {
    ref: descriptionRef,
    expanded: descriptionExpanded,
    fullHeight: descriptionFullHeight,
    canExpand,
  } = useElementExpandable({
    collapseThreshold: DESCRIPTION_COLLAPSE_THRESHOLD,
  });

  return (
    <>
      <DescriptionStyled
        ref={descriptionRef}
        expanded={false}
        fullHeight={descriptionFullHeight}
        collapseHeight={DESCRIPTION_COLLAPSE_THRESHOLD}
        canExpand={canExpand}
      >
        {description && <div dangerouslySetInnerHTML={{ __html: description || '' }} />}
        {businessGoal && (
          <>
            <Title>Journey goals</Title>
            <div dangerouslySetInnerHTML={{ __html: businessGoal || '' }} />
          </>
        )}
      </DescriptionStyled>
      {canExpand && (
        <ExpandDescriptionButton icon={descriptionExpanded ? 'angle-up' : 'angle-down'} onClick={onShowMore}>
          Show more
        </ExpandDescriptionButton>
      )}
    </>
  );
};

const LoadingState = () => <Skeleton height={168} />;

const _Description = makeNamespacedComponent(Description, {
  LoadingState,
});

export { _Description as Description };
