import { createContext } from 'react';

interface Context {
  productId?: number;
  spaceId?: number;
  isAdmin: boolean;
}

export const CoursePageContext = createContext<Context>({ isAdmin: false });
CoursePageContext.displayName = 'CoursePageContext';
