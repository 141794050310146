import React, { CSSProperties, useEffect, useRef, useState, useCallback } from 'react';

import './discussions.scss';
import { ENTER_CHAR_CODE } from '../../utils/misc';
import { CustomEditor, CustomEditorHandle } from '../customEditor/CustomEditor';
import { LinkButton } from '@/components/Button/LinkButton';

interface Props {
  initialTitleValue?: string;
  initialContentValue?: string;
  placeholder?: string;
  titleStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  onPosted({ title, content }: { title: string; content: string }): Promise<void> | void;
  onFinished?(): Promise<void> | void;
  $fullSize?: boolean;
}

export const TextInput = (props: Props): JSX.Element => {
  const [title, setTitle] = useState(props.initialTitleValue || '');
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, toggleError] = useState(false);
  const [content, setContent] = useState(props.initialContentValue || '');
  const inputEl = useRef<HTMLTextAreaElement>(null);
  // CustomEditor.tsx comments explain why we need such a ref
  const editorEl = useRef<CustomEditorHandle>(null);

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, []);

  const onChange = (newValue: string): void => {
    if (isSaving) return;

    setContent(newValue);
  };

  const handleHasContent = (postContent: string): boolean => {
    if (postContent) {
      const parsed = JSON.parse(postContent);
      if (parsed?.length) {
        const { children } = parsed[0];
        if (children?.length) {
          const { text } = children[0];
          if (text.length) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const create = async () => {
    toggleError(false);
    setIsSaving(true);
    if (handleHasContent(content)) {
      try {
        await props.onPosted({ title, content });

        setTitle('');
        setContent('');

        // CustomEditor.tsx comments explain why we need this even after reseting the content
        editorEl.current?.reset();
      } catch (e) {
        toggleError(true);
      } finally {
        setIsSaving(false);
        if (props.onFinished) props.onFinished();
      }
    }
  };

  const handleSavingOnEnter = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.charCode === ENTER_CHAR_CODE) {
        create();
      }
    },
    [create]
  );

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div className="field has-addons">
          <div className="control" style={{ flex: 1 }}>
            <CustomEditor
              hideToolbar={true}
              ref={editorEl}
              className={'textarea content has-fixed-size'}
              style={{
                height: '4rem',
                minHeight: '3rem',
                maxHeight: '8rem',
                borderBottomRightRadius: 0,
                fontSize: 14,
                ...props.contentStyle,
              }}
              $fullSize={props.$fullSize}
              placeholder={props.placeholder || ''}
              value={content}
              onChange={onChange}
            />
          </div>
          <div className="control">
            <LinkButton
              style={{ ...props.buttonStyle, height: '100%' }}
              onClick={create}
              onKeyPress={handleSavingOnEnter}
              role="button"
              tabIndex={0}
              $icon="paper-plane"
              $iconSize="lg"
              $type="primary"
            />
          </div>
        </div>
      </div>

      {hasError && (
        <article className="message is-warning">
          <div className="message-body">Something went wrong while trying to create your post. Please try again.</div>
        </article>
      )}
    </div>
  );
};
