import styled, { css } from 'styled-components';

import { ChipStyledProps } from '../types';

import {
  ChipTypeStyling,
  DefaultStatesStyling,
  DisabledStylings,
  ElevatedStylings,
  SelectedDisabledStylings,
  SelectedStylings,
} from '.';

export const DefaultDropdownStyling = css`
  ${(p) => p.theme.typography.body4};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.neutral.onContainer};
  border: 1px solid ${(p) => p.theme.colors.neutral.outline2};
  box-sizing: border-box;
  transition: all 0.3s;
`;

export const ContentStyled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  ${(p) =>
    p.selected &&
    css`
      height: 100%;
      padding: 0px 8px;
      border-radius: 8px 0px 0px 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      ${SelectedStylings}
      ${p.disabled && SelectedDisabledStylings}
    `}
`;

export const Divider = styled.div<{ disabled?: boolean }>`
  width: 1px;
  height: 32px;
  background: ${(p) => (p.disabled ? p.theme.colors.neutral.disabled : p.theme.tones.primary[70])};
`;

export const TrailingContentStyled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  ${(p) =>
    p.selected &&
    css`
      height: 100%;
      padding: 0px 8px;
      border-radius: 0px 8px 8px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      ${SelectedStylings}
      ${p.disabled && SelectedDisabledStylings}
    `}
`;

export const DropdownChipStyled = styled.div<ChipStyledProps>`
  /* Specified in a certain order to override the stylings they are supposed to be overriden.
   Do not change the order, if you don't intend to change overriding logic
 */
  ${DefaultDropdownStyling}
  ${({ selected }) => !selected && DefaultStatesStyling}
  ${({ variant }) => ChipTypeStyling[variant || 'outline']}
  ${({ elevated }) => elevated && ElevatedStylings}
  ${({ selected }) => selected && `padding: 0px; border: none;`}
  ${({ disabled, selected }) => disabled && !selected && DisabledStylings}
`;
