import styled, { useTheme } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { MenuList } from '@/ui/MenuList';
import { useLiveEventAddToCalendar } from '@/pages/LiveEvent/store/useLiveEventAddToCalendar';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';

type LiveEventCalendarBlockProps = Pick<
  LiveEvent,
  'id' | 'room' | 'title' | 'teaser' | 'address' | 'endDate' | 'startDate' | 'dateAndTime' | 'myEnrollment'
> & { withTitle?: boolean };

const CommonTitle = styled.h3`
  margin-bottom: 24px;
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const IconWithLabelContainer = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

const AddToCalendarButtonContainer = styled.div`
  padding: 8px 16px;
`;

const StyledDateAndTime = styled.div`
  ${({ theme }) => theme.typography.subheadline};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const LiveEventCalendarBlock = ({
  id,
  room,
  title,
  teaser,
  address,
  endDate,
  startDate,
  withTitle,
  dateAndTime,
  myEnrollment,
}: LiveEventCalendarBlockProps): JSX.Element => {
  const theme = useTheme();
  const addToCalendarProps = useLiveEventAddToCalendar({
    id,
    room,
    title,
    teaser,
    address,
    endDate,
    startDate,
    myEnrollment,
  });

  return (
    <>
      {withTitle && <CommonTitle>When</CommonTitle>}
      <IconWithLabelContainer>
        <Icon icon={['far', 'calendar']} color={theme.colors.neutral.onContainer} size="lg" />
        <StyledDateAndTime>{dateAndTime}</StyledDateAndTime>
      </IconWithLabelContainer>
      <AddToCalendarButtonContainer>
        <MenuList {...addToCalendarProps} />
      </AddToCalendarButtonContainer>
    </>
  );
};
