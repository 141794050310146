/* eslint-disable import/no-unused-modules */
import styled from 'styled-components';

import { Button } from '@/ui/Button';
import { LearningCardProps, LearningCard } from '../LearningCard/LearningCard';
import { PARENT_JOURNEY_ID_SEARCH_KEY } from '@/store/v2/journeys/useParentJourneyId';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

type UpNextLearningProps = {
  isActionActive: boolean;
  learningCardProps: LearningCardProps;
  actionHref: string;
  parentJourneyId?: number;
};

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 8px;
  padding: 32px;
  gap: 8px;
  width: 100%;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const CardWrapperStyled = styled.div`
  padding: 24px 0;
`;

export const UpNextLearning = ({
  isActionActive,
  learningCardProps,
  actionHref,
  parentJourneyId,
}: UpNextLearningProps): JSX.Element | null => {
  const enhancedNavigationEnabled = useFeatureEnabled(FEATURE.UI_LEARNING_JOURNEYS_UP_ENHANCE_NAVIGATION);

  if (!enhancedNavigationEnabled) {
    return null;
  }

  return (
    <RootStyled>
      <TitleStyled>Up next</TitleStyled>
      <CardWrapperStyled>
        <LearningCard {...learningCardProps} />
      </CardWrapperStyled>
      <Button
        variant={isActionActive ? 'primary' : 'neutral'}
        icon="arrow-right"
        $iconPosition="end"
        to={{
          pathname: actionHref,
          search: `${PARENT_JOURNEY_ID_SEARCH_KEY}=${parentJourneyId}`,
        }}
      >
        Continue learning
      </Button>
    </RootStyled>
  );
};
