import React from 'react';

import { InputContainer, Label, Slider, Input } from './style';

type ToggleProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  label: string;
  labelRight?: boolean;
};

export const Toggle = ({ onChange, checked, disabled, labelRight, label }: ToggleProps): JSX.Element => {
  return (
    <Label flip={labelRight} disabled={!!disabled}>
      {label}
      <InputContainer>
        <Input type="checkbox" onChange={onChange} tabIndex={0} checked={checked} disabled={disabled} />
        <Slider />
      </InputContainer>
    </Label>
  );
};
