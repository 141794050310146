import { gql, MutationTuple, QueryResult, useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { AttendedType } from '@/types/learning';

export interface Enrollment {
  id: number;
  userId: number;
  enrolledAt: string;
  locationId: number;
  attended: AttendedType;
  user: {
    email: string;
    username: string;
  };
}

const ENROLLMENT_CREATE = gql`
  mutation enrollmentCreateByEmails($input: EnrollByEmailInput!) {
    enrollmentCreateByEmails(input: $input) {
      id
      locationId
      userId
      enrolledAt
      attended
    }
  }
`;
export const useEnrollmentCreateByEmails = (): MutationTuple<
  { enrollmentCreateByEmails: Enrollment[] },
  { input: { locationId: number; emails: string[] } }
> => {
  return useMutation(ENROLLMENT_CREATE, { awaitRefetchQueries: true, refetchQueries: [getOperationName(ENROLLMENTS)!] });
};

const ENROLL_SELF = gql`
  mutation enrollSelf($input: EnrollSelfInput!) {
    enrollSelf(input: $input) {
      id
      locationId
      userId
      enrolledAt
      attended
    }
  }
`;
export const useEnrollSelf = (): MutationTuple<{ enrollSelf: Enrollment }, { input: { locationId: number } }> => {
  return useMutation(ENROLL_SELF, { awaitRefetchQueries: true, refetchQueries: [getOperationName(ENROLLMENTS)!] });
};

const DELETE_ENROLLMENT = gql`
  mutation deleteEnrollment($input: DeleteEnrollmentInput!) {
    deleteEnrollment(input: $input)
  }
`;
export const useDeleteEnrollment = (): MutationTuple<
  { deleteEnrollment: { id: unknown } },
  { input: { enrollmentId: number } }
> => {
  return useMutation(DELETE_ENROLLMENT, { awaitRefetchQueries: true, refetchQueries: [getOperationName(ENROLLMENTS)!] });
};

export const ENROLLMENTS = gql`
  query enrollments($input: EnrollmentsInput!) {
    enrollments(input: $input) {
      id
      locationId
      userId
      enrolledAt
      attended
      user {
        username
        email
      }
    }
  }
`;
export const useEnrollments = (
  locationIds: number[],
  skip?: boolean
): QueryResult<{ enrollments: Enrollment[] }, { input: { locationIds: number[] } }> => {
  return useQuery(ENROLLMENTS, { variables: { input: { locationIds } }, skip });
};

const SET_ENROLLMENT_ATTENDANCE = gql`
  mutation setEnrollmentAttendance($input: SetEnrollmentAttendanceInput!) {
    setEnrollmentAttendance(input: $input) {
      id
      locationId
      userId
      enrolledAt
      attended
    }
  }
`;
export const useSetEnrollmentAttendance = (): MutationTuple<
  { setEnrollmentAttendance: Enrollment },
  { input: { enrollmentId: number; attended: AttendedType } }
> => {
  return useMutation(SET_ENROLLMENT_ATTENDANCE, { awaitRefetchQueries: true, refetchQueries: [getOperationName(ENROLLMENTS)!] });
};

const SET_BULK_ENROLLMENT_ATTENDANCE = gql`
  mutation setBulkEnrollmentAttendance($input: [SetEnrollmentAttendanceInput!]!) {
    setBulkEnrollmentAttendance(input: $input) {
      id
      locationId
      userId
      enrolledAt
      attended
    }
  }
`;
export const useSetBulkEnrollmentAttendance = (): MutationTuple<
  { setEnrollmentAttendance: Enrollment },
  { input: { enrollmentId: number; attended: AttendedType }[] }
> => {
  return useMutation(SET_BULK_ENROLLMENT_ATTENDANCE, {
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(ENROLLMENTS)!],
  });
};
