import { useMemo } from 'react';

import { useRootContext } from '@/store/RootContext';
import { useLocalStorage } from '@/hooks/useLocalStorage';

export const FEATURE = {
  PROVIDER_PLURALSIGHT: 'PROVIDER_PLURALSIGHT',
  PROVIDER_COLLEGIAL: 'PROVIDER_COLLEGIAL',
  PROVIDER_COURSERA: 'PROVIDER_COURSERA',
  PROVIDER_UDACITY: 'PROVIDER_UDACITY',
  PROVIDER_AUTOLIV: 'PROVIDER_AUTOLIV',
  PROVIDER_LINKEDIN: 'PROVIDER_LINKEDIN',
  PROVIDER_FINANSKOMPETENS: 'PROVIDER_FINANSKOMPETENS',
  PROVIDER_IMD: 'PROVIDER_IMD',
  PROVIDER_CUSTOM_LINK: 'PROVIDER_CUSTOM_LINK',
  PROVIDER_ENGINEER_FOUR_POINT_ZERO: 'PROVIDER_ENGINEER_FOUR_POINT_ZERO',
  PROVIDER_TEKNISKA_COLLEGE: 'PROVIDER_TEKNISKA_COLLEGE',
  PROVIDER_MOLK: 'PROVIDER_MOLK',
  PROVIDER_KOMPETENS_EXPRESS: 'PROVIDER_KOMPETENS_EXPRESS',
  PROVIDER_INTERMEZZON: 'PROVIDER_INTERMEZZON',
  PROVIDER_LEXICON: 'PROVIDER_LEXICON',
  PROVIDER_LANSFORSAKRINGAR: 'PROVIDER_LANSFORSAKRINGAR',
  PROVIDER_IMPARTA: 'PROVIDER_IMPARTA',
  PROVIDER_LEARNIFIER: 'PROVIDER_LEARNIFIER',

  UI_ANALYTICS_USER_ACTIVITY_SUMMARY_EXTENDED_REPORT_BUTTON: 'UI_ANALYTICS_USER_ACTIVITY_SUMMARY_EXTENDED_REPORT_BUTTON',
  UI_PRODUCT_EDITOR_ATTRIBUTES_TAB: 'UI_PRODUCT_EDITOR_ATTRIBUTES_TAB',
  UI_LEARNING_EDITOR_ATTRIBUTES_TAB: 'UI_LEARNING_EDITOR_ATTRIBUTES_TAB',
  UI_LEARNING_CHALLENGE: 'UI_LEARNING_CHALLENGE',
  UI_LANGUAGE_PICKER: 'UI_LANGUAGE_PICKER',
  UI_HR_ONLINE_DATA: 'UI_HR_ONLINE_DATA',
  UI_SELF_TRACKED_LEARNING: 'UI_SELF_TRACKED_LEARNING',
  UI_SIMPLE_UI: 'UI_SIMPLE_UI',
  UI_ATTRIBUTE_FILTER_CATALOG: 'UI_ATTRIBUTE_FILTER_CATALOG',
  UI_ASSIGNMENT_TEASER: 'UI_ASSIGNMENT_TEASER',
  UI_COMPETENCY_ASSESSMENT: 'UI_COMPETENCY_ASSESSMENT',
  UI_ASSIGNMENT_CARD: 'UI_ASSIGNMENT_CARD',
  UI_SLI_TEMPLATES: 'UI_SLI_TEMPLATES',
  UI_MODULAR_COURSES: 'UI_MODULAR_COURSES',
  UI_RICH_TEXT_AI: 'UI_RICH_TEXT_AI',
  UI_LEARNING_JOURNEYS: 'UI_LEARNING_JOURNEYS',
  UI_LEARNING_JOURNEYS_LINEAR: 'UI_LEARNING_JOURNEYS_LINEAR',
  UI_LEARNING_JOURNEYS_UP_ENHANCE_NAVIGATION: 'UI_LEARNING_JOURNEYS_UP_ENHANCE_NAVIGATION',
  UI_RICH_TEXT_V2: 'UI_RICH_TEXT_V2',
  UI_JOURNEY_SURVEYS: 'UI_JOURNEY_SURVEYS',
  UI_JOURNEY_PRACTICAL_ASSESSMENTS: 'UI_JOURNEY_PRACTICAL_ASSESSMENTS',
  UI_PARTICIPATION_ADMIN_ACTIONS_ENABLED: 'UI_PARTICIPATION_ADMIN_ACTIONS_ENABLED',
  UI_JOURNEY_LIVE_EVENTS: 'UI_JOURNEY_LIVE_EVENTS',
  UI_CARD_DESCRIPTION_HIDDEN: 'UI_CARD_DESCRIPTION_HIDDEN',
  UI_CATEGORY_SUBJECT_HIDDEN: 'UI_CATEGORY_SUBJECT_HIDDEN',

  UI_CALENDAR_GOOGLE_DISABLED: 'UI_CALENDAR_GOOGLE_DISABLED',
  UI_CALENDAR_MICROSOFT_DISABLED: 'UI_CALENDAR_MICROSOFT_DISABLED',
  UI_CALENDAR_OUTLOOK_DISABLED: 'UI_CALENDAR_OUTLOOK_DISABLED',

  UI_LEARNING_JOURNEYS_EDIT_ITEMS_COLLABORATIVE: 'UI_LEARNING_JOURNEYS_EDIT_ITEMS_COLLABORATIVE',

  UI_QUIZ_IN_COURSE: 'UI_QUIZ_IN_COURSE',

  SYSTEM_EVENT_NOTIFICATION: 'SYSTEM_EVENT_NOTIFICATION',
  SYSTEM_OPTIMISTIC_MUTATIONS: 'SYSTEM_OPTIMISTIC_MUTATIONS',

  SYSTEM_ADMIN_PEOPLE_TEAMS_V2_READ_ONLY: 'SYSTEM_ADMIN_PEOPLE_TEAMS_V2_READ_ONLY',
} as const;

export type FeatureName = typeof FEATURE[keyof typeof FEATURE];

/**
 * Provides all available features
 */
export const useFeatureToggles = (): Set<FeatureName> => {
  const { featureToggles } = useRootContext();
  return useMemo(() => new Set(<FeatureName[]>featureToggles), [featureToggles]);
};

/**
 * Determines whether feature is enabled
 */
export const useFeatureEnabled = (feature: FeatureName): boolean => {
  const featureToggles = useFeatureToggles();
  return featureToggles.has(feature);
};

/**
 * Returns isFeatureEnabled factory
 */
export const useIsFeatureEnabled = (): ((feature: FeatureName) => boolean) => {
  const featureToggles = useFeatureToggles();

  return (feature: FeatureName): boolean => featureToggles.has(feature);
};

/**
 * Determines whether simple mode is enabled
 */
export const useIsSimpleModeEnabled = (): [boolean, boolean, (b: boolean) => void] => {
  const isSimpleUIEnabled = useFeatureEnabled(FEATURE.UI_SIMPLE_UI);
  const [forceSimpleMode, setForceSimpleMode] = useLocalStorage<boolean>('forceSimpleMode', isSimpleUIEnabled);

  return [isSimpleUIEnabled || forceSimpleMode, forceSimpleMode, setForceSimpleMode];
};
