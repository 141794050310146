import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '../../../../../components/Form/types';

import './fields-values.css';

interface Props {
  useCustom: boolean;
  fieldsValues: FieldsValues[];
  updateFieldsValues: (index: number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => void;
}

interface FieldsValues {
  name: string;
  value: string;
}

export const DEFAULT_FIELD_VALUES: FieldsValues[] = [
  {
    name: 'Objectives',
    value: ''
  },
  {
    name: 'Contribution',
    value: ''
  },
  {
    name: 'Challenges',
    value: ''
  },
  {
    name: 'Gender identification',
    value: ''
  },
  {
    name: 'Comfort level in English',
    value: ''
  },
  {
    name: 'First language',
    value: ''
  },
  {
    name: 'Geographic location',
    value: ''
  },
  {
    name: 'Highest educational degree',
    value: ''
  },
  {
    name: 'Current position',
    value: ''
  },
  {
    name: 'Work experience in current area',
    value: ''
  },
  {
    name: 'Management experience',
    value: ''
  }
];

export const CUSTOM_FIELDS_VALUES: { [key: number]: FieldsValues[] } = {
  // SEB-specific, and thus not localized
  // Lean portfolio management
  615: [
    {
      name: 'Do you have a purpose with what you want to achieve with your participation?',
      value: ''
    },
    {
      name: 'Do you have a work task that is connected to the Agile transformation?',
      value: ''
    },
    {
      name: 'Do you have a context where you can use your skills in the near future (tomorrow or within a couple of weeks)?',
      value: ''
    }
  ]
};

export const FieldsValues = (props: Props): JSX.Element | null => {
  const { useCustom, fieldsValues, updateFieldsValues } = props;
  const { t } = useTranslation('application');

  if (!fieldsValues.length) {
    return null;
  }

  if (useCustom) {
    return (
      <>
        {fieldsValues.map((fieldValue, i) => (
          <div styleName="section" key={i}>
            <div styleName="description">{fieldValue.name}</div>
            <textarea styleName="textarea" rows={5} value={fieldValue.value} onChange={updateFieldsValues(i)} required />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <p>{t('paragraph-1')}</p>
      <p>{t('paragraph-2')}</p>
      <div styleName="section">
        <div styleName="description">
          <span styleName="title">{t(fieldsValues[0].name)} - </span>
          {t(fieldsValues[0].name, { context: 'details' })}
        </div>
        <textarea styleName="textarea" rows={5} value={fieldsValues[0].value} onChange={updateFieldsValues(0)} required />
      </div>
      <div styleName="section">
        <div styleName="description">
          <span styleName="title">{t(fieldsValues[1].name)} - </span>
          {t(fieldsValues[1].name, { context: 'details' })}
        </div>
        <textarea styleName="textarea" rows={5} value={fieldsValues[1].value} onChange={updateFieldsValues(1)} required />
      </div>
      <div styleName="section">
        <div styleName="description">
          <span styleName="title">{t(fieldsValues[2].name)} - </span>
          {t(fieldsValues[2].name, { context: 'details' })}
        </div>
        <textarea styleName="textarea" rows={5} value={fieldsValues[2].value} onChange={updateFieldsValues(2)} required />
      </div>
      <div styleName="section">
        <div styleName="title">{t('Personal Information')}</div>
        <div styleName="description">
          {t('Personal Information', { context: 'details' })}
          {t('personal-info-p1')}
          {t('personal-info-p2')}
          <br />
          <br />
        </div>
        <div styleName="personal-information">
          <div styleName="field">{t(fieldsValues[3].name)}</div>
          <select styleName="select" value={fieldsValues[3].value} onChange={updateFieldsValues(3)} required>
            <option value="">{t('Select an option')}</option>
            <option value="Male">{t('Male')}</option>
            <option value="Female">{t('Female')}</option>
            <option value="Other">{t('Other')}</option>
            <option value="Prefer not to state">{t('Prefer not to state')}</option>
          </select>
        </div>
        <div styleName="personal-information">
          <div styleName="field">{t(fieldsValues[4].name)}</div>
          <select styleName="select" value={fieldsValues[4].value} onChange={updateFieldsValues(4)} required>
            <option value="">{t('Select an option')}</option>
            <option value="1">1 {t('(Basic)')}</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5 {t('(Native)')}</option>
          </select>
        </div>
        <div styleName="personal-information">
          <div styleName="field">{t(fieldsValues[5].name)}</div>
          <input
            styleName="input"
            type="text"
            autoComplete={AutoComplete.on}
            value={fieldsValues[5].value}
            onChange={updateFieldsValues(5)}
            required
          />
        </div>
        <div styleName="personal-information">
          <div styleName="field">{t(fieldsValues[6].name)}</div>
          <select styleName="select" value={fieldsValues[6].value} onChange={updateFieldsValues(6)} required>
            <option value="">{t('Select an option')}</option>
            <option value="Sweden">{t('Sweden')}</option>
            <option value="Denmark">{t('Denmark')}</option>
            <option value="Norway">{t('Norway')}</option>
            <option value="Finland">{t('Finland')}</option>
            <option value="Baltics">{t('Baltics')}</option>
            <option value="Other Europe">{t('Other Europe')}</option>
            <option value="Asia">{t('Asia')}</option>
            <option value="Americas">{t('Americas')}</option>
            <option value="Other">{t('Other')}</option>
          </select>
        </div>
        <div styleName="personal-information">
          <div styleName="field">{t(fieldsValues[7].name)}</div>
          <input
            styleName="input"
            type="text"
            autoComplete={AutoComplete.on}
            value={fieldsValues[7].value}
            onChange={updateFieldsValues(7)}
            required
          />
        </div>
        <div styleName="personal-information">
          <div styleName="field">{fieldsValues[8].name}</div>
          <select styleName="select" value={fieldsValues[8].value} onChange={updateFieldsValues(8)} required>
            <option value="">{t('Select an option')}</option>
            <option value="Team member">{t('Team member')}</option>
            <option value="Team leader">{t('Team leader')}</option>
            <option value="Manager">{t('Manager')}</option>
            <option value="Executive">{t('Executive')}</option>
          </select>
        </div>
        <div styleName="personal-information">
          <div styleName="field">{t(fieldsValues[9].name)}</div>
          <select styleName="select" value={fieldsValues[9].value} onChange={updateFieldsValues(9)} required>
            <option value="">{t('Select an option')}</option>
            <option value="1-5 years">1-5 {t('years')}</option>
            <option value="6-10 years">6-10 {t('years')}</option>
            <option value="11-15 years">11-15 {t('years')}</option>
            <option value="16-20 years">16-20 {t('years')}</option>
            <option value="21+ years">21+ {t('years')}</option>
          </select>
        </div>
        <div styleName="personal-information">
          <div styleName="field">{fieldsValues[10].name}</div>
          <select styleName="select" value={fieldsValues[10].value} onChange={updateFieldsValues(10)} required>
            <option value="">{t('Select an option')}</option>
            <option value="1-5 years">1-5 {t('years')}</option>
            <option value="6-10 years">6-10 {t('years')}</option>
            <option value="11-15 years">11-15 {t('years')}</option>
            <option value="16-20 years">16-20 {t('years')}</option>
            <option value="21+ years">21+ {t('years')}</option>
          </select>
        </div>
      </div>
    </>
  );
};
