import styled from 'styled-components';

import { Button } from '@/ui/Button';

export const BannerStyled = styled.div`
  padding: 20px 16px;
  background: ${({ theme }) => theme.colors.neutral.container};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  border-radius: 4px;
  ${({ theme }) => theme.typography.body2};
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ActionButton = styled(Button).attrs({ variant: 'link' })`
  background: transparent;
  padding: 0;
`;
