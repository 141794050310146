import { v4 as uuid } from 'uuid';

import { RBlock, RBlockInput } from '@/store/v2';
import { BlockType, ReflectionBlock } from '@/administration/pages/Course/store/block/types';
import { BlockId } from '../types';

export const createReflectionBlock = (): ReflectionBlock => ({
  id: `block-l-${uuid()}` as BlockId,
  type: BlockType.REFLECTION,
  description: '',
});

export const transformReflBlockToRemoteInput = (block: ReflectionBlock, rPageId?: number, order?: number): RBlockInput => ({
  id: block?.remoteId || undefined,
  type: BlockType.REFLECTION,
  order: order || 0,
  pageId: rPageId || undefined,
  data: {
    description: block.description || '',
    sectionPath: `/${block.sectionPath?.join('/')}`,
    postId: block.postId,
  },
});

export const transformReflBlockFromRemote = (rBlock: RBlock): ReflectionBlock => ({
  id: `block-r-${rBlock.id || 0}` as BlockId,
  remoteId: rBlock.id || 0,
  description: rBlock.data.description || '',
  type: BlockType.REFLECTION,
  sectionPath: rBlock.data.sectionPath?.split('/')?.slice(1),
  postId: rBlock.data.postId,
});
