/* eslint-disable import/no-unused-modules */
import React from 'react';

import { Modal } from '@/ui/Modal';
import { NoProgressBanner } from './NoProgressBanner';

type NoProgressModalProps = {
  open: boolean;
  onClose: () => void;
  loopIndex: number;
};

export const NoProgressModal = ({ open, onClose, loopIndex }: NoProgressModalProps): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} size="tablet">
      <Modal.Title>Your progress · week {loopIndex + 1}</Modal.Title>
      <Modal.Contents>
        <NoProgressBanner />
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>Close</Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
