import styled from 'styled-components';

import { BadgeLabel } from '@/ui/Label/variants/BadgeLabel';
import { LinearProgressBar } from '@/ui/ProgressBar';

export const MyLearningGoal = styled.p`
  ${(p) => p.theme.typography.overline}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const ChallengeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ChallengeTitle = styled.span`
  ${(p) => p.theme.typography.title4}
  color: ${(p) => p.theme.colors.neutral.onBackground}
`;
export const StyledBadge = styled(BadgeLabel)`
  margin-left: 6px;
`;

export const ChallengeDate = styled.p`
  ${(p) => p.theme.typography.body3}
  color: ${(p) => p.theme.colors.neutral.onBackgroundVariant}
`;

export const StyledProgressBar = styled(LinearProgressBar)`
  margin-top: 10px;
`;

export const MyLearningTime = styled.span`
  ${(p) => p.theme.typography.subheadline}
  color: ${(p) => p.theme.colors.neutral.onBackground};
  grid-area: headline;
`;

export const MyLearningTimeBody = styled.p`
  ${(p) => p.theme.typography.body3}
  color: ${(p) => p.theme.colors.neutral.onContainer};
  grid-area: desc;
`;

export const WelcomeTooltip = styled.div<{
  carrotVisible: boolean;
}>`
  display: grid;
  grid-template:
    'headline .     info .    my-learning' auto
    'desc     desc  desc desc desc' auto
    / auto 12px auto 1fr auto;
  row-gap: 13px;
  align-items: center;

  width: 100%;
  position: relative;
  padding: 0.875rem 1.5rem;
  margin-bottom: 10px;

  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.neutral.outline1};

  &::before,
  &::after {
    ${({ carrotVisible }) => carrotVisible && `content: '';`}
    left: 50%;
  }

  &::before {
    height: 0;
    width: 0;
    position: absolute;
    top: -7px;
    transform: translateX(-50%);
    border-top: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${(p) => p.theme.colors.neutral.background};
  }
  &::after {
    height: 10px;
    width: 10px;
    position: absolute;
    top: -6px;
    transform: translateX(-50%) rotate(45deg);
    border-top: 1px solid ${(p) => p.theme.colors.neutral.outline1};
    border-left: 1px solid ${(p) => p.theme.colors.neutral.outline1};
  }
`;
