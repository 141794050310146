import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { FormSchema } from '..';
import { FormTitle } from './fields/FormTitle';
import { FormCoverImage } from './fields/FormCoverImage';
import { FormTimezone } from './fields/FormTimezone';
import { ParticipantsInfoBanner } from './ParticipantsInfoBanner';
import { FormDescription } from './fields/FormDescription';
import { FormLocation } from './fields/FormLocation';
import { FormDate } from './fields/FormDate';
import { FormStartsAt } from './fields/FormStartsAt';
import { FormEndsAt } from './fields/FormEndsAt';
import { DurationInformer } from './DurationInformer';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderStyled = styled.div`
  padding-bottom: 8px;
`;

const DateTimeContainerStyled = styled.div`
  display: flex;
  gap: 8px;
`;

const TimeContainerStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const SeparatorStyled = styled.span`
  height: 42px;
  display: flex;
  align-items: center;
`;

export const LiveEventForm = ({ spaceId }: { spaceId: number }): JSX.Element => {
  const { control } = useFormContext<FormSchema>();
  return (
    <RootStyled>
      <HeaderStyled>
        <FormCoverImage control={control} spaceId={spaceId} />
      </HeaderStyled>
      <FormTitle control={control} />
      <FormTimezone control={control} />
      <DateTimeContainerStyled>
        <FormDate control={control} />
        <TimeContainerStyled>
          <FormStartsAt control={control} />
          <SeparatorStyled>{'-'}</SeparatorStyled>
          <FormEndsAt control={control} />
        </TimeContainerStyled>
        <DurationInformer />
      </DateTimeContainerStyled>
      <FormLocation control={control} />
      <FormDescription control={control} spaceId={spaceId} />
      <ParticipantsInfoBanner />
    </RootStyled>
  );
};
