import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalContext } from './ModalContext';
import { StyledModal, StyledModalBody, StyledModalHeader, StyledModalOverlay } from './styles';
import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { Portal } from './components/Portal';
import { ModalCloseButton } from './components/ModalCloseButton';
import { Button } from '../Button/Button';
import { Header4 } from '../Typography/Typography';

export function Modal(): JSX.Element | null {
  const {
    modalTarget,
    hideModal,
    blockHide,
    show,
    modalProps: {
      children,
      size,
      customHeaderText,
      customHeaderElement,
      shouldHideOnClickOutside,
      modalStyles,
      headerStyles,
      bodyStyles,
      onClose
    }
  } = useModalContext();
  const { t } = useTranslation('common');

  // escape will close both modal + confirmation modal
  useOnEscapePress(
    event => {
      if (show) {
        event.preventDefault();
        hideModal();
      }
    },
    [show]
  );

  const handleClose = () => {
    if (blockHide) return;
    onClose && onClose();
    hideModal();
  };

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>): void => {
    if (!shouldHideOnClickOutside || event.target !== event.currentTarget) {
      return;
    }

    handleClose();
  };

  const isHeaderCustom = customHeaderElement || customHeaderText;

  return (
    <Portal mount={modalTarget} show={show}>
      <StyledModalOverlay onClick={handleClickOutside}>
        <StyledModal
          aria-modal
          aria-label={''}
          tabIndex={-1}
          role="dialog"
          size={size ? size : 'default'}
          style={modalStyles}
          isConfirmation={false}
        >
          <>
            {isHeaderCustom && (
              <StyledModalHeader style={headerStyles}>
                {customHeaderElement ? <>{customHeaderElement}</> : <Header4 unpadded>{customHeaderText}</Header4>}
                <Button $type="base" $icon="times" $iconStyle={{ opacity: 0.3 }} $round onClick={handleClose} />
              </StyledModalHeader>
            )}
            <StyledModalBody style={bodyStyles}>
              {!blockHide && !isHeaderCustom && (
                <ModalCloseButton onClick={handleClose} $ariaLabel={t('Close', { ns: 'common' })} />
              )}
              {children}
            </StyledModalBody>
          </>
        </StyledModal>
      </StyledModalOverlay>
    </Portal>
  );
}
