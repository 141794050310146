import styled from 'styled-components';

export const GraphicsBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageArea = styled.div`
  padding: 0 20px;
  margin-bottom: 32px;
  height: 256px;
`;

export const Title = styled.span`
  display: block;
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.tones.primary[0]};
  text-align: center;
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.tones.neutral[20]};
  max-width: 500px;
  text-align: center;
  margin-top: 8px;
`;
