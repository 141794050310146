import React, { useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import { Icon } from '@/components/Icon';
import { IconPropType } from '../../types/icon';

type InputProps = {
  label: string;
  icon?: IconPropType;
  shouldShowCloseIcon?: boolean;
  iconRight?: boolean;
  iconSpin?: boolean;
  iconStyle?: string;
  fullWidth?: boolean;
  hasAddons?: boolean;
  hasError?: boolean;
  medium?: boolean;
  hideLabel?: boolean;
  marginless?: boolean;
  onCloseIconClick?: () => void;
} & React.HTMLProps<HTMLInputElement>;

export const Input = ({
  marginless,
  label,
  icon,
  shouldShowCloseIcon,
  iconRight,
  iconSpin,
  iconStyle,
  fullWidth,
  hasAddons,
  hasError,
  medium,
  hideLabel,
  className,
  onCloseIconClick,
  ...baseProps
}: InputProps): JSX.Element => {
  const ConditionalWrapper = (children: JSX.Element) =>
    hasAddons ? children : <div className={clsx('field is-floating-label', marginless && 'is-marginless')}>{children}</div>;
  const [inputId] = useState(`input-${uuid()}`);

  return ConditionalWrapper(
    <>
      {!hasAddons && !hideLabel && (
        <label htmlFor={inputId} className={clsx('label', 'nowrap', baseProps.required && 'required')}>
          {label}
        </label>
      )}
      <div
        className={clsx(
          'control',
          hasAddons && 'is-floating-label',
          icon && (iconRight ? 'has-icons-right' : 'has-icons-left'),
          fullWidth && 'is-expanded',
          className
        )}
      >
        {hasAddons && !hideLabel && (
          <label htmlFor={inputId} className={clsx('label', baseProps.required && 'required')}>
            {label}
          </label>
        )}
        <input
          autoComplete={baseProps.autoComplete}
          disabled={baseProps.disabled}
          className={clsx('input', medium && 'is-medium', hasError && 'is-danger')}
          type={baseProps.type || 'text'}
          placeholder={baseProps.placeholder}
          value={baseProps.value}
          onChange={baseProps.onChange}
          onBlur={baseProps.onBlur}
          onFocus={baseProps.onFocus}
          required={baseProps.required}
          aria-required={baseProps.required ? 'true' : 'false'}
          aria-label={baseProps['aria-label'] || label}
          max={baseProps.max}
          min={baseProps.min}
          step={baseProps.step}
          id={inputId}
        />
        {icon && <Icon icon={icon} spin={!!iconSpin} styleName={iconStyle} style={shouldShowCloseIcon ? { right: 23 } : {}} />}
        {shouldShowCloseIcon && (
          <Icon icon={'times'} styleName={iconStyle} style={{ cursor: 'pointer' }} onClick={onCloseIconClick} />
        )}
      </div>
    </>
  );
};
