import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useCardCatalog } from '@/store/catalog';
import { QueryParamKeys } from '@/pages/Search/SearchPage';
import { fuzzyOptions } from '@/pages/Search/fuseOptions';
import { InputBasic } from '@/components/Form/components/Input/InputBasic';
import { StyledDropdownWrapper } from '@/components/Dropdown/StyledDropdownWrapper';
import { StyledDropdown } from '@/components/Dropdown/StyledDropdown';
import { Tag } from '@/components/Tag/Tag';
import { useProductTypeTranslations } from '@/i18n/hooks';
import { Icon } from '@/components/Icon';
import { Overlay } from '../../styles';
import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { getLink } from '@/utils/card';
import { useDropdown } from '@/hooks/useDropdown';
import { useOnEnterPress } from '@/hooks/useOnEnterPress';
import { StyledGoToPage, NavSearchWrapper } from './styles';
import { StyledDropdownRowStandard } from '@/components/Dropdown/StyledDropdownRow';
import { NavSearchProps } from './types';
import { trackOpenLearningCardFromSearchBar, trackUseNavbarSearch } from '@/utils/tracking/learnings';
import { useDebouncedFunction } from '@/hooks/useDebounce';

const MAX_HITS = 5;

export const NavSearch = ({ isMobile, onNavigation }: NavSearchProps): JSX.Element => {
  const { t } = useTranslation('navbar');
  const { containerRef, open, close, isOpen } = useDropdown<HTMLDivElement>({ defaultOpen: isMobile });
  const { catalog } = useCardCatalog();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchStr = searchParams.get(QueryParamKeys.q)?.toLocaleLowerCase();
  const [search, setSearch] = useState(searchStr);
  const { productType } = useProductTypeTranslations();

  const onSearch = (props?: { clearSearch?: boolean; noSideEffects?: boolean }) => {
    props?.clearSearch && setSearch(undefined);
    close();
    !props?.noSideEffects && !!onNavigation && onNavigation();
  };

  useOnEscapePress(() => onSearch, [setSearch]);

  useEffect(() => {
    isMobile && containerRef?.current?.querySelector('input')?.focus();
  }, [isMobile]);

  const cards = catalog.cards.filter((card) => card.available).map((card) => ({ ...card, title: card.title.trim() }));
  const fuse = new Fuse(cards, fuzzyOptions);
  const filteredCards = search && search.length > 1 ? fuse.search(search).map(({ item }) => item) : [];
  const maxFilteredCards = filteredCards.length ? filteredCards.slice(0, MAX_HITS) : [];
  const hasSearch = !!search;
  const showViewAll = maxFilteredCards.length === MAX_HITS;

  useOnEnterPress(() => {
    if (hasSearch && showViewAll) {
      onSearch();
      navigate(`/learning/search?q=${encodeURIComponent(search || '')}`);
    }
  });

  const handleCardClick = useCallback((title: string) => {
    trackOpenLearningCardFromSearchBar(title);
    onSearch({ clearSearch: true });
  }, []);

  const debouncedTrackFreeTextFilterUsed = useDebouncedFunction(trackUseNavbarSearch, 500);
  const handleNavbarSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    debouncedTrackFreeTextFilterUsed(e.currentTarget.value);
    open();
    setSearch(e.target.value);
  }, []);

  return (
    <>
      <NavSearchWrapper isMobile={isMobile}>
        <StyledDropdownWrapper ref={containerRef}>
          <InputBasic
            icon={hasSearch ? 'times' : 'search'}
            hiddenLabel={true}
            label={t('Search learning items')}
            placeholder={`${t('Search learning items')}...`}
            value={search || ''}
            onFocus={open}
            onChange={handleNavbarSearchChange}
            onIconClick={hasSearch ? () => onSearch({ clearSearch: true, noSideEffects: true }) : undefined}
            cursor="pointer"
          />
          <StyledDropdown open={isOpen}>
            {!!maxFilteredCards.length && (
              <>
                {maxFilteredCards.map((c) => (
                  <StyledDropdownRowStandard as={NavLink} key={c.id} to={getLink(c)} onClick={() => handleCardClick(c.title)}>
                    <span>{c.title}</span>
                    <Tag>{productType(c.type)}</Tag>
                  </StyledDropdownRowStandard>
                ))}
                <StyledGoToPage
                  as={NavLink}
                  to={`/learning/search?q=${encodeURIComponent(search || '')}`}
                  onClick={() => onSearch()}
                >
                  {t('Search results', { ns: 'search' })}...
                  <Icon icon="level-down-alt" rotation={90} />
                </StyledGoToPage>
              </>
            )}
          </StyledDropdown>
        </StyledDropdownWrapper>
      </NavSearchWrapper>
      {!isMobile && <Overlay isOpen={isOpen} />}
    </>
  );
};
