import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';

export type CheckboxSizeProp = 'small' | 'default' | 'large';

type InputProps = {
  bulkAll?: boolean;
};

export const CheckboxInput = styled.input<InputProps>`
  position: absolute;
  opacity: 0;

  &:hover + label:before,
  & + label:hover:before {
    background: rgba(0, 0, 0, 0.07);
  }

  &:focus + label:before,
  & + label:focus:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:disabled + label,
  &.is-loading + label {
    color: var(--color-silver);
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label > svg {
    display: block;
    position: absolute;
    left: 4px;
    top: 1px;
    top: ${(props: InputProps) => (props.bulkAll ? `4px` : `1px`)};
    transform: rotate(-9deg);

    > path {
      color: var(--color-dove-gray);
    }
  }
`;

type LabelProps = {
  dark: boolean;
  marginless: boolean;
  newTheme?: boolean;
  size?: CheckboxSizeProp;
};

export const CheckboxLabel = styled.label<LabelProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  height: 16px;
  ${({ newTheme }) => newTheme && `color: ${cvar('color-dark-gray')};`}
  ${({ size }) => {
    if (size === 'small') {
      return `
        display: flex;
        font-size: ${cvar('font-size-8')};
        line-height: 1.5;
      `;
    }
  }}
  
  > svg {
    font-size: ${({ size }) => (size === 'large' ? 16 : 12)}px;
    display: none;
  }

  :before {
    content: '';
    ${({ marginless, size }) => {
      if (marginless) return '';
      if (size === 'small') {
        return 'margin-right: 4px;';
      }
      return 'margin-right: 10px;';
    }}
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;

    ${({ size }) => {
      if (size === 'large') {
        return `
          width: 20px;
          height: 20px;
          margin-right: 10px;
        `;
      }
    }}

    border-radius: 6px;
    border: 2px solid ${(props: LabelProps) => (props.dark ? `var(--color-dusty-gray)` : `var(--color-alto)`)};

    ${({ newTheme }) => newTheme && `border-radius: 4px;`}
  }
`;
