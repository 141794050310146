import { EMPTY_VALUE } from '@/component/customEditorV2';
import {
  RLearningType,
  RLearningVisibility,
  RLocationType,
  RSaveLearningMutation,
  useSaveLearningMutationRemote,
} from '@/store/v2';

export type UseUpdateLearningJourneyLiveEventHook = () => {
  updateLearningJourneyEvent: (
    spaceId: number,
    learningId: number,
    liveEvent: {
      locationId: number;
      customTimezoneOffsetInMinutes: number;
      title: string;
      plannedDuration: number;
      bannerURL?: string;
      description?: string;
      startDate: Date;
      endDate: Date;
      address: string;
      type: RLocationType;
    },
    onSuccess?: () => void
  ) => Promise<{ errors?: unknown | undefined; data?: RSaveLearningMutation | null | undefined }>;
  loading: boolean;
};

export const useUpdateLearningJourneyLiveEvent: UseUpdateLearningJourneyLiveEventHook = () => {
  const [updateLearningJourneyEvent, { loading }] = useSaveLearningMutationRemote();

  return {
    updateLearningJourneyEvent: async (
      spaceId,
      learningId,
      {
        customTimezoneOffsetInMinutes,
        title,
        plannedDuration,
        bannerURL,
        description,
        locationId,
        startDate,
        endDate,
        address,
        type,
      },
      onSuccess?: () => void
    ) => {
      return await updateLearningJourneyEvent({
        variables: {
          input: {
            title,
            type: RLearningType.LiveEvent,
            teaser: description || EMPTY_VALUE,
            spaceId,
            effort: plannedDuration,
            visibility: RLearningVisibility.Visible,
            locations: [
              {
                id: locationId,
                address,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                type,
              },
            ],
            subjects: [],
            id: learningId,
            meta: {
              timezone: customTimezoneOffsetInMinutes,
            },
            image: bannerURL,
          },
        },
        update(cache, { data }) {
          if (data?.saveLearning) {
            cache.evict({ fieldName: 'learningJourney' });
            cache.evict({ fieldName: 'enrollments' });

            // Removing target Learning entity from cache to have it refetched on next access attempt
            cache.evict({ id: `Learning:${data.saveLearning.id}` });

            onSuccess && onSuccess();
          }
        },
      });
    },
    loading,
  };
};
