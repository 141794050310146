import React from 'react';
import { useTranslation } from 'react-i18next';
import { Composition } from 'atomic-layout';

import { Button } from '@/components/Button/Button';
import { ProviderAccessStatus, ProviderList } from '@/store/providers';
import { useProviderAccess } from '@/hooks/useProviderAccess';
import { usePurchaseProduct } from '@/store/learning-catalog';
import { FeatureToggle } from '@/component/FeatureToggle/FeatureToggle';
import { ButtonList } from '@/components/Button/ButtonList';
import { LinkButton } from '@/components/Button/LinkButton';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { Header1, BodyLg } from '@/components/Typography/Typography';
import { providerFormAreas, providerFormAreasLg } from './GeneralProvider';
import { FEATURE } from '@/feature-toggles';

interface Props {
  accessProductId?: number;
}

export const Linkedin = ({ accessProductId }: Props): React.ReactElement => {
  const provider = ProviderList.LINKEDIN;
  const { t } = useTranslation('providers');
  const providerStatus = useProviderAccess(provider);
  const [purchaseProduct, { loading }] = usePurchaseProduct(accessProductId);
  const hasAccess = providerStatus === ProviderAccessStatus.HAS_ACCESS;
  const hasPendingAccess = providerStatus === ProviderAccessStatus.PENDING_ACCESS;
  const applyText = hasPendingAccess ? t('Access requested') : t('request-access');
  const linkedinUrl =
    'https://www.linkedin.com/checkpoint/enterprise/login/88496514?application=learning&redirect=https://www.linkedin.com/learning/me';

  return (
    <Composition
      areas={providerFormAreas}
      areasLg={providerFormAreasLg}
      templateCols="minmax(0, 1fr)"
      templateColsLg="minmax(0, 9fr) minmax(0, 3fr)"
      gap={0.875}
      gapMd={1.75}
    >
      {({ First, Second }) => (
        <>
          <First>
            <Header1>{t('linkedin-title')}</Header1>
            <BodyLg>{t('linkedin-description')}</BodyLg>
          </First>
          <Second>
            <ButtonList align="right" padded="bottom">
              <ProviderLogo provider={provider} />
            </ButtonList>
            <ButtonList align="right">
              {accessProductId !== undefined && !hasAccess && (
                <Button
                  disabled={hasPendingAccess}
                  onClick={() => purchaseProduct({ variables: { products: [{ id: accessProductId }] } })}
                  $icon="check"
                  $loading={loading}
                >
                  {applyText}
                </Button>
              )}
              <FeatureToggle name={FEATURE.PROVIDER_LINKEDIN}>
                <LinkButton href={linkedinUrl} target="_blank" rel="noopener noreferrer" $type="primary">
                  {t('linkedin-go-to')}
                </LinkButton>
              </FeatureToggle>
            </ButtonList>
          </Second>
        </>
      )}
    </Composition>
  );
};
