import React from 'react';

import { CellFormatterComponent } from '../types';

export const ProgressCellFormatter: CellFormatterComponent = ({ data }) => {
  if (typeof data !== 'number' || Number.isNaN(data) || data < 0) {
    return null;
  }
  return <>{Math.floor(Math.min(data, 1) * 100)}%</>;
};
