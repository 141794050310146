import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { OnboardingStep } from './types';

export const useRedirect = (currentStep: OnboardingStep): void => {
  const navigate = useNavigate();
  useEffect(() => {
    if (currentStep === OnboardingStep.FinalStep) {
      const targetUrl = '/';
      return navigate(targetUrl);
    }
  }, [currentStep]);
};
