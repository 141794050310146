import React from 'react';

import { Block as IBlock, BlockType } from '../../../../../types/learning';
import { TextBlock } from './TextBlock';
import { ImageBlock } from './ImageBlock';
import { VideoLinkBlock } from './VideoLinkBlock';
import {
  AudioMeta,
  ImageMeta,
  ResourceMeta,
  TextMeta,
  VideoLinkMeta,
  VideoUploadMeta
} from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { AudioBlock } from './AudioBlock';
import { VideoUploadBlock } from './VideoUploadBlock';
import { ResourceBlock } from './ResourceBlock';

interface Props {
  block: IBlock;
}

export const Block = ({ block: { type, data } }: Props): JSX.Element | null => {
  const blockData: unknown = data;
  switch (type) {
    case BlockType.AUDIO:
      return <AudioBlock data={blockData as AudioMeta} />;
    case BlockType.IMAGE:
      return <ImageBlock data={blockData as ImageMeta} />;
    case BlockType.RESOURCE:
      return <ResourceBlock data={blockData as ResourceMeta} />;
    case BlockType.TEXT:
      return <TextBlock data={blockData as TextMeta} />;
    case BlockType.PREAMBLE:
      return <TextBlock data={blockData as TextMeta} isPreamble />;
    case BlockType.VIDEO_LINK:
      return <VideoLinkBlock data={blockData as VideoLinkMeta} />;
    case BlockType.VIDEO:
      return <VideoUploadBlock data={blockData as VideoUploadMeta} />;
    default:
      return <div className="content is-article is-unpadded">Missing type {type}</div>;
  }
};
