import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

import { Icon } from '@/components/Icon';
import { IconPropType } from '@/ui/Icon';

export interface SelectOption<T> {
  label: string;
  value: T;
}

interface Props<T> {
  label?: string;
  required?: boolean;
  options: SelectOption<T>[];
  selected?: T | undefined;
  // defaultSelectOnload can be used when the opions data is loaded asynchronously and
  // you wish Select to set a default value once options has loaded
  defaultSelectOnload?: boolean;
  // Display a string when no options are available
  noOptionsText?: string;
  // Text to be displayed as a non selectable option
  placeholderText?: string;
  // Allow placeholder to be selected will return
  selectablePlaceholder?: boolean;
  onChange: (item: T) => void;
  disabled?: boolean;
  floatingLabel?: boolean;
  icon?: IconPropType;
  inline?: boolean;
  marginless?: boolean;
  maxWidth?: number;
}

export function Select<T>({
  marginless,
  label,
  required,
  options,
  selected,
  defaultSelectOnload,
  noOptionsText,
  placeholderText,
  selectablePlaceholder,
  onChange,
  disabled,
  floatingLabel,
  icon,
  inline = false,
  maxWidth,
}: Props<T>): JSX.Element {
  const [selectId] = useState(`select-${uuid()}`);

  useEffect(() => {
    // Fire an onChange with the first option in the list once options has been populated
    if (defaultSelectOnload && !selected && options.length > 0) {
      onChange(options[0].value);
    }
  }, [options]);

  // The HTML option component allows only numbers and strings as values.
  // In order to support returning generic objects T to the user of this component,
  // we create an internal representation to keep to map <option>-friendly values to T values
  const mappedOptions = options.map((o, i) => ({
    ...o,
    internalSelectValue: i.toString(),
  }));
  const noOptionsInternalValue = uuid();
  const placeholderInternalValue = uuid();

  if (options.length === 0 && noOptionsText) {
    mappedOptions.unshift({
      label: noOptionsText,
      value: null as unknown as T,
      internalSelectValue: placeholderInternalValue,
    });
  } else if (placeholderText) {
    mappedOptions.unshift({
      label: placeholderText,
      value: null as unknown as T,
      internalSelectValue: placeholderInternalValue,
    });
  }
  const select = (selectValue: string) => {
    const item = mappedOptions.find((o) => o.internalSelectValue === selectValue);
    if (!item) return;
    // Don't trigger changes if selected option is the placeholder or the no options text
    if (
      selectablePlaceholder ||
      !(item.internalSelectValue === placeholderInternalValue || item.internalSelectValue === noOptionsInternalValue)
    ) {
      onChange(item.value);
    } else {
      return;
    }
  };
  const internalSelectValue = mappedOptions.find((o) => o.value === selected)?.internalSelectValue;

  return (
    <div
      className={clsx('field', floatingLabel && 'is-floating-label', inline && 'is-inline-block', marginless && 'is-marginless')}
    >
      {label && (
        <label className={clsx('label', required && 'required')} htmlFor={selectId}>
          {label}
        </label>
      )}
      <div className={clsx('control', icon && 'has-icons-left')}>
        <div className="select is-fullwidth">
          {icon && <Icon icon={icon} className="has-text-dark" />}
          <select
            {...{ style: maxWidth ? { minWidth: maxWidth, maxWidth } : {} }}
            value={internalSelectValue}
            onChange={(e) => select(e.target.value)}
            disabled={disabled}
            required={required}
            aria-required={required ? 'true' : 'false'}
            aria-label={label}
            id={selectId}
          >
            {mappedOptions.map((o) => (
              <option value={o.internalSelectValue} key={o.label}>
                {o.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
