import React from 'react';
import styled from 'styled-components';

import { Grid } from '@/ui/Grid/atoms';
import { Participant, ParticipantProps } from '../../../../common/atoms/Participant';
import { OrderedLearningCard } from '../../../../common/atoms/LearningCard';
import { makeNamespacedComponent } from '@/ui/utils';
import { Hero } from '../../../../common/molecules/JourneyHero';
import { ListCard } from '../ListCard';
import { Page } from '../../../../common/atoms/Page';
import { OrderedLearningCardProps } from '@/administration/pages/Journey/common/atoms/LearningCard/variants/OrderedLearningCard';
import { Description } from '@/administration/pages/Journey/pages/ViewJourney/atoms/Description';
import { Separator } from '@/administration/pages/Journey/pages/ViewJourney/atoms/Separator';
import { CompletionCriteria } from '@/administration/pages/Journey/pages/ViewJourney/atoms/CompletionCriteria';
import { ProgressSummary } from '@/administration/pages/Journey/pages/ViewJourney/atoms/ProgressSummary';
import { ParticipationV3 } from '@/store/v2/catalog-v2/model/participation';
import { LearningJourney } from '@/store/v2/journeys/journey';
import { MultiKeyMap } from '@/utils/datastructure/multi-key-map';
import { DiscusssionsBox } from '../DiscussionsBox';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type LearningItemProps = OrderedLearningCardProps & {
  objectId?: string;
};

type LayoutProps = {
  journey: LearningJourney;

  learnersPage: ParticipantProps[];
  learnersTotal: number;
  onLearnersViewAll: () => void;

  learningItems: LearningItemProps[];
  learningItemsTotal: number;

  participationsMap: MultiKeyMap<ParticipationV3, [string]>;

  onExpandDescription: () => void;

  isSavedFavourite: boolean;
  onSaveFavourite: () => void;

  journeyParticipation: ParticipationV3 | undefined;
  journeyParticipationLoading: boolean;

  onJoinJourneyLoading: boolean;
  onJoinJourney: () => void;
  onLockedItemClick: ({ learningItemObjectId }: { learningItemObjectId: string }) => void;
};

const Layout = ({
  journey,

  learnersPage,
  learnersTotal,
  onLearnersViewAll,

  learningItems,
  learningItemsTotal,
  participationsMap,
  onExpandDescription,
  isSavedFavourite,
  onSaveFavourite,
  journeyParticipation,
  journeyParticipationLoading,
  onJoinJourney,
  onLockedItemClick,
}: LayoutProps): JSX.Element => {
  const renderDescription = () => {
    if (!journey.description && !journey.businessGoal) return null;

    return (
      <>
        <Description
          description={journey.description || ''}
          businessGoal={journey.businessGoal || ''}
          onShowMore={onExpandDescription}
        />
        <Separator />
      </>
    );
  };

  const renderLearningItems = () => (
    <ListCard
      itemsCount={learningItems.length}
      title="Learning items"
      emptyProps={{
        title: 'No learning items yet!',
      }}
    >
      {learningItems.map((props) => {
        const participation = participationsMap.get([props.objectId || '']);
        const progress = participation
          ? {
              isCompleted: participation.isCompleted || undefined,
              userEffort: participation.userEffort || undefined,
              progress: participation.progress || undefined,
              completedAt: participation.completedAt || undefined,
              expiryAt: participation.expiryAt || undefined,
            }
          : undefined;

        return (
          <OrderedLearningCard
            {...props}
            progress={progress}
            key={props.orderIndex}
            onLockedClick={() => props.objectId && onLockedItemClick({ learningItemObjectId: props.objectId })}
          />
        );
      })}
    </ListCard>
  );

  const renderCompletionCriteria = () => {
    if (journeyParticipationLoading) return <CompletionCriteria.LoadingState />;

    return (
      <CompletionCriteria
        journey={journey}
        hasJoined={!!journeyParticipation}
        onJoin={onJoinJourney}
        learningItemsTotal={learningItemsTotal}
      />
    );
  };

  const renderProgressSummary = () => {
    if (journeyParticipationLoading) return <ProgressSummary.LoadingState />;
    if (!journeyParticipation) return null;

    return <ProgressSummary journey={journey} participation={journeyParticipation} />;
  };

  const renderParticipants = () => (
    <ListCard
      itemsCount={learnersTotal}
      title="Participants"
      onViewClick={onLearnersViewAll}
      emptyProps={{
        title: 'Be the first to join!',
      }}
      styleBordered
    >
      {learnersPage.slice(0, Math.min(learnersPage.length, 5)).map((props) => (
        <Participant {...props} key={props.email} />
      ))}
    </ListCard>
  );

  const renderDiscussionsBox = () => {
    if (journeyParticipationLoading) return <DiscusssionsBox.LoadingState />;
    if (!journeyParticipation || !journey.discussionEnabled) return null;

    return <DiscusssionsBox />;
  };
  return (
    <Page>
      <Hero isSaved={isSavedFavourite} onSave={onSaveFavourite} title={journey.title} imageUrl={journey.bannerURL || undefined} />
      <Grid rowGap="medium">
        <Grid.Item mobile={12} mobileLandscape={12} tablet={12} desktop={8} desktopLarge={8} widescreen={8} fullHD={8}>
          {renderDescription()}
          {renderLearningItems()}
        </Grid.Item>
        <Grid.Item mobile={12} mobileLandscape={12} tablet={12} desktop={4} desktopLarge={4} widescreen={4} fullHD={4}>
          <List>
            {renderDiscussionsBox()}
            {renderCompletionCriteria()}
            {renderProgressSummary()}
            {renderParticipants()}
          </List>
        </Grid.Item>
      </Grid>
    </Page>
  );
};

const LoadingState = () => (
  <Page>
    <Hero.LoadingState />
    <Grid rowGap="medium">
      <Grid.Item mobile={12} mobileLandscape={12} tablet={12} desktop={8} desktopLarge={8} widescreen={8} fullHD={8}>
        <Description.LoadingState />
        <Separator />
        <ListCard.LoadingState />
      </Grid.Item>
      <Grid.Item mobile={12} mobileLandscape={12} tablet={12} desktop={4} desktopLarge={4} widescreen={4} fullHD={4}>
        <List>
          <DiscusssionsBox.LoadingState />
          <CompletionCriteria.LoadingState />
          <ProgressSummary.LoadingState />
          <ListCard.LoadingState />
        </List>
      </Grid.Item>
    </Grid>
  </Page>
);

const _Layout = makeNamespacedComponent(Layout, {
  LoadingState,
});

export { _Layout as Layout };
