import _isNil from 'lodash.isnil';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconPropType } from '@/ui/Icon';
import { minutesToRelativeTime } from '@/utils/time';
import { Meta } from '@/components/Meta/Meta';
import { Participation } from '@/types/analytics';

interface Props {
  isPending: boolean;
  isCollection: boolean;
  isJourney: boolean;
  itemCount?: number | undefined;
  participation?: Participation;
  hasExpiration?: boolean;
  format?: string | null;
  level?: string | null;
  totalEffort?: number | null;
}

const StyledFooter = styled.footer<{ $isCollection?: boolean }>`
  align-items: center;
  border-top: var(--border-style);
  display: flex;
  height: 50px;
  justify-content: center;
  margin-top: auto;
  padding: 0 var(--gap-sm);
  ${({ $isCollection }) => $isCollection && 'border: 2px solid var(--color-alto); border-radius: 0 0 8px 8px;'};
`;

const StyledList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  > * + * {
    margin-left: var(--gap-xs);
  }
`;

export const CourseCardFooter = ({
  isPending,
  isCollection,
  isJourney,
  itemCount,
  participation,
  hasExpiration,
  format,
  level,
  totalEffort,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('catalog');
  let singleMetaField: { caption: string; icon?: IconPropType } | undefined;

  if (isPending) {
    singleMetaField = { icon: 'clock', caption: t('Pending approval') };
  } else if (participation && !hasExpiration) {
    if (participation.isCompleted) {
      singleMetaField = { caption: t('Completed') };
    } else {
      singleMetaField = { icon: 'sync-alt', caption: t('Ongoing') };
    }
  } else if (isCollection || (isJourney && !_isNil(itemCount))) {
    singleMetaField = { caption: t('learning-item', { count: itemCount || 0 }), icon: 'layer-group' };
  }

  if (!Boolean(level || format || totalEffort || singleMetaField)) return null;

  return (
    <StyledFooter $isCollection={isCollection}>
      <StyledList>
        {!!singleMetaField ? (
          <Meta caption={singleMetaField.caption} icon={singleMetaField.icon} />
        ) : (
          <>
            {!!level && <Meta caption={level} icon="signal-alt" />}
            {!!totalEffort && <Meta caption={minutesToRelativeTime(totalEffort)} icon="clock" />}
            {!!format && <Meta caption={format} icon="laptop" />}
          </>
        )}
      </StyledList>
    </StyledFooter>
  );
};
