import styled, { css } from 'styled-components';

import { CircleContainerProps, InnerCircleProps } from './types';

const CIRCLE_TRANSITION_DURATION_MS = 300;
const CIRCLE_TRANSITION_DELAY_MS = 200;
const DEFAULT_CONTAINER_TRANSITION_DELAY_MS = CIRCLE_TRANSITION_DURATION_MS + CIRCLE_TRANSITION_DELAY_MS;

export const RootStyled = styled.div`
  position: relative;
  width: fit-content;
`;

export const CircleContainerStyled = styled.svg<CircleContainerProps & { overflowed?: boolean }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  transform: ${({ rotateDeg }) => `rotate(${rotateDeg}deg)`};
  /* We should delay it when oveflowed to avoid "pre-rotating" visual side-effect */
  transition: transform 0s ${({ overflowed }) => (overflowed ? `${DEFAULT_CONTAINER_TRANSITION_DELAY_MS}ms` : '0s')};
`;

const CircleCSS = css<Omit<InnerCircleProps, 'arcOffset' | 'arcLength'>>`
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px`};
  cx: ${({ center }) => `${center}px`};
  cy: ${({ center }) => `${center}px`};
  r: ${({ radius }) => `${radius}px`};
  fill: transparent;

  ${({ rounded }) =>
    rounded &&
    css`
      stroke-linecap: round;
    `}
`;

export const CircleTrackStyled = styled.circle<Omit<InnerCircleProps, 'arcOffset'> & { customTrackBgColor?: string }>`
  ${CircleCSS};
  stroke: ${({ customTrackBgColor, theme }) => (customTrackBgColor ? customTrackBgColor : theme.colors.neutral.container)};
  stroke-dasharray: ${({ arcLenght }) => arcLenght};
`;

export const CircleProgressStyled = styled.circle<InnerCircleProps>`
  ${CircleCSS};
  transition: stroke-dashoffset ${() => `${CIRCLE_TRANSITION_DURATION_MS}ms`} ease-out;
  transition-delay: ${() => `${CIRCLE_TRANSITION_DELAY_MS}ms`};
  stroke: ${({ theme }) => theme.colors.primary.outline};
  stroke-dasharray: ${({ arcLenght }) => arcLenght};
  stroke-dashoffset: ${({ arcOffset }) => arcOffset};
`;

export const InnerContent = styled.div<{ size: number }>`
  position: absolute;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const FakeCorner = styled.div<{ visible?: boolean; strokeWidth: number }>`
  position: absolute;
  width: ${({ strokeWidth }) => `${strokeWidth}px`};
  height: ${({ strokeWidth }) => `${strokeWidth / 2}px`};
  background: ${({ theme }) => theme.colors.primary.outline};
  top: calc(50% - 2px);
  right: 0%;
  border-bottom-left-radius: ${({ strokeWidth }) => `${strokeWidth * 2}px`};
  border-bottom-right-radius: ${({ strokeWidth }) => `${strokeWidth * 2}px`};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.15s ease-out;
  transition-delay: ${({ visible }) => (visible ? '0.5s' : '0s')};
  transform: scaleY(${({ visible }) => (visible ? '1' : '0')});
`;
