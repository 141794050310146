import React, { FC } from 'react';

import { Input } from '@/component/Input/Input';
import { AutoComplete } from '@/components/Form/types';

type LinkSectionProps = {
  link: string;
  setLink: (link: string) => void;
};

export const LinkSection: FC<LinkSectionProps> = ({ link, setLink }) => {
  return (
    <div className="field has-addons has-addons--mobile is-floating-label">
      <div className="control is-expanded has-icon-right">
        <Input
          type="text"
          autoComplete={AutoComplete.off}
          value={link}
          onChange={e => setLink(e.currentTarget.value)}
          label="Link"
          icon="link"
          iconRight
        />
      </div>
    </div>
  );
};
