import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp as FaIconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

import { IconPropType } from './types';

type IconProps = WithClassNameProp<{
  icon: IconPropType;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  size?: SizeProp;
  fixedWidth?: boolean;
  color?: string;
}>;

export const Icon = ({ className, onClick, icon, size, fixedWidth, color }: IconProps): JSX.Element => (
  <FontAwesomeIcon
    icon={icon as FaIconProp}
    className={className}
    size={size}
    fixedWidth={fixedWidth}
    color={color}
    onClick={onClick}
  />
);
