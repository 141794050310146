import { Dispatch, SetStateAction, useEffect } from 'react';

import { FilterFn } from '@/components/CardGrid/CardFilter';
import { Card, isProgressCard } from '@/types/learning/card';
import { deserializeValue } from '@/utils/valueSerialization';
import { checkDuration } from '../utils/checkDuration';

type FilterValues = {
  filterTitle?: string;
  selectedCategory?: number;
  selectedSubject?: number;
  filterType?: string;
  filterProvider?: string;
  filterDuration?: string;
  filterLanguage?: string;
  filterAttributes: (string | undefined)[];
};

export function useUpdateFilterFunction(
  {
    filterTitle,
    selectedCategory,
    selectedSubject,
    filterType,
    filterProvider,
    filterDuration,
    filterLanguage,
    filterAttributes
  }: FilterValues,
  setFilterFn: Dispatch<SetStateAction<FilterFn>>
): void {
  useEffect(() => {
    const filterFn = (card: Card) => {
      const searchStrLower = filterTitle?.toLocaleLowerCase() || '';
      const isFilteredCategory = !selectedCategory || card.subjects.some(catSub => catSub.categoryId === selectedCategory);
      const isFilteredSubject = !selectedSubject || card.subjects.some(catSub => catSub.subjectId === selectedSubject);
      const isFilteredLanguage = !filterLanguage || card.language?.toLocaleLowerCase() === filterLanguage.toLocaleLowerCase();
      const parsedAttributeValues = filterAttributes
        .filter(v => v)
        .map((v = '') => {
          const [key, value] = deserializeValue(v);
          return { key, value };
        });
      const isFilteredAttributes =
        !parsedAttributeValues ||
        !parsedAttributeValues.length ||
        parsedAttributeValues.some(({ key, value }) => {
          return card.attributes && card.attributes[key] === value;
        });
      const isFilteredType = !filterType || filterType === 'all' || card.type === filterType;
      const isFilteredProvider =
        !filterProvider ||
        filterProvider === 'all' ||
        (filterProvider === 'empty' && card.provider === '') ||
        card.provider?.toLocaleLowerCase() === filterProvider;
      const isFilteredDuration =
        !filterDuration ||
        filterDuration === 'all' ||
        (isProgressCard(card) && checkDuration(card.totalEffort) === filterDuration);
      const isFilteredText =
        card.title?.toLocaleLowerCase().includes(searchStrLower) ||
        card.teaser?.toLocaleLowerCase().includes(searchStrLower) ||
        card.subjects.some(s => s.category.includes(searchStrLower) || s.subject.includes(searchStrLower));
      return (
        isFilteredCategory &&
        isFilteredSubject &&
        isFilteredLanguage &&
        isFilteredType &&
        isFilteredDuration &&
        isFilteredProvider &&
        isFilteredText &&
        isFilteredAttributes
      );
    };
    setFilterFn(() => filterFn);
  }, [
    filterTitle,
    selectedCategory,
    selectedSubject,
    filterType,
    filterProvider,
    filterDuration,
    filterLanguage,
    filterAttributes
  ]);
}
