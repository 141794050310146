import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Only } from 'atomic-layout';

import { StyledNavButton, StyledNavSectionWrapper, StyledNavItem, Overlay } from '@/components/Navbar/styles';
import { Icon } from '@/components/Icon';
import { isRealm } from '@/utils/realms';
import { NAV_LINKS } from '@/components/Navbar/consts';
import { useDropdown } from '@/hooks/useDropdown';
import { trackOpenExploreMenu } from '@/utils/tracking/learnings';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { ExploreMenuComponent } from './ExploreMenu/ExploreMenu';

export const NavContent = (): JSX.Element | null => {
  const { t } = useTranslation('navbar');
  const { containerRef, isOpen, handleOpenClose, close } = useDropdown<HTMLDivElement>();
  const isCategorySubjectHidden = useFeatureEnabled(FEATURE.UI_CATEGORY_SUBJECT_HIDDEN);

  const handleOpenCloseExploreMenu = useCallback(() => {
    if (isOpen) {
      handleOpenClose(false);
    } else {
      handleOpenClose(true);
      trackOpenExploreMenu();
    }
  }, [isOpen]);

  return (
    <Only from="lg">
      <StyledNavSectionWrapper isKompetens={isRealm('kompetens')} itemsAlign="center">
        <StyledNavItem to={NAV_LINKS.HOME}>{t('Home')}</StyledNavItem>
        {!isCategorySubjectHidden && (
          <div ref={containerRef}>
            <StyledNavButton onClick={handleOpenCloseExploreMenu}>
              {t('Explore')} <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
            </StyledNavButton>
            {isOpen && <ExploreMenuComponent closeMenu={close} />}
          </div>
        )}
      </StyledNavSectionWrapper>
      <Overlay isOpen={isOpen} />
    </Only>
  );
};
