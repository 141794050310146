/* eslint-disable import/no-unused-modules */
import React, { useEffect, useState } from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { Container as PaddedContainer, WeekChart } from '../../atoms';
import { WeeklyLearningLoop } from '../../atoms/WeeksNavigation/types';
import { LoopsContainer, RootStyled, Divider, NavigationsContainer, Container } from './styles';
import { Skeleton } from '@/ui/Skeleton';
import { Graphics, Informers, Title } from './atoms';
import { EndedWeeksNavigation, EndedWeeksSetup } from '../../atoms/WeeksNavigation/variants';

export type EndedWeeklyLoopChartProps = {
  weeks: WeeklyLearningLoop[];
  onViewDetailsClick: (selectedWeekId: string) => void;
  goalReachedCount: number;
  goalLimitCount: number;
  timeSpent: number;
  reflectionsCount: number;
  status: 'completed' | 'ended';
};

export const isPastWeek = ({ timeState }: WeeklyLearningLoop): boolean => timeState === 'past';

const _EndedWeeklyLoopChart = ({ weeks, onViewDetailsClick, ...rest }: EndedWeeklyLoopChartProps): JSX.Element => {
  const [selectedWeekId, setSelectedWeekId] = useState<string | null>(null);
  useEffect(
    () => {
      // but if there is no current week, since all of them are past, we pick the last one in the array
      setSelectedWeekId(weeks[weeks?.length - 1]?.id || null);
    },
    // We want to run this hook only when there's a fresh collection of weeks,
    // even if the reference is different *or* when any individual week's
    // details have changed (eg. `canReflect` status).
    //
    // A simple list of week ID's acts like a unique signature of the collection:
    weeks.map((w) => w.id)
  );

  const handleSelectedWeekChanged = (selectedWeekNumber: number) => {
    const targetWeek = weeks.find(({ weekNumber }) => weekNumber === selectedWeekNumber);

    if (targetWeek) {
      setSelectedWeekId(targetWeek.id);
      onViewDetailsClick(targetWeek.id);
    }
  };
  const selectedWeek = (selectedWeekId ? weeks.find((w) => w.id === selectedWeekId) : null) ?? null;

  return (
    <RootStyled>
      <Container>
        <Graphics />
        <LoopsContainer>
          <Title>{"That's wrap!"}</Title>
          <Informers {...rest} />
        </LoopsContainer>
        <Divider />
        <NavigationsContainer>
          <EndedWeeksNavigation
            weeks={weeks}
            selectedWeek={selectedWeek}
            onChangeSelectedWeek={(newSelectedWeekNumber: number) => handleSelectedWeekChanged(newSelectedWeekNumber)}
          />
        </NavigationsContainer>
      </Container>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <PaddedContainer>
    <EndedWeeksSetup.LoadingState />
    <WeekChart.LoadingState actions={<Skeleton width={86} height={32} />} />
  </PaddedContainer>
);

export const EndedWeeklyLoopChart = makeNamespacedComponent(_EndedWeeklyLoopChart, {
  LoadingState,
});
