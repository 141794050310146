import { Nominal } from './nominal';
import { LanguageMap } from './i18n/languageMap';

export type Verb = Nominal<VerbProps, 'XAPIVerb'>;

export interface VerbProps {
  id: VerbId;
  display: LanguageMap;
}

// See https://registry.tincanapi.com/#home/verbs for reference
enum VerbId {
  COMPLETED = 'http://adlnet.gov/expapi/verbs/completed',
  REACTED = 'http://id.collegial.com/verb/reacted',
  ANSWERED = 'http://id.collegial.com/verb/answered',
}

export const verbCompleted = (): Verb =>
  <Verb>{
    id: VerbId.COMPLETED,
    display: <LanguageMap>{
      en: 'completed',
    },
  };

export const verbReacted = (): Verb =>
  <Verb>{
    id: VerbId.REACTED,
    display: <LanguageMap>{
      en: 'reacted',
    },
  };

export const verbAnswered = (): Verb =>
  <Verb>{
    id: VerbId.ANSWERED,
    display: <LanguageMap>{
      en: 'answered',
    },
  };
