import React from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { Icon } from '@/components/Icon';
import { getProviderIcon } from '@/store/providers';

interface Props {
  provider: string;
  size?: SizeProp;
}

export const ProviderIcon = ({ provider, size }: Props): JSX.Element | null => {
  const icon = getProviderIcon(provider);
  if (!icon) return null;

  return <Icon icon={icon} size={size} title={provider} />;
};
