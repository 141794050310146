import styled from 'styled-components';

import { RUserLearningSubmissionReview } from '@/store/v2';
import { SubmittedReview } from './SubmittedReview';

const RootStyled = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
`;

const ReviewsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SubmittedReviews = ({ reviews }: { reviews: RUserLearningSubmissionReview[] }): JSX.Element => {
  return (
    <RootStyled>
      <TitleStyled>Review</TitleStyled>
      {reviews.length ? (
        <ReviewsListStyled>
          {reviews.map((review) => (
            <SubmittedReview key={review.id} review={review} />
          ))}
        </ReviewsListStyled>
      ) : (
        'There are no reviews yet.'
      )}
    </RootStyled>
  );
};
