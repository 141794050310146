import { ApolloQueryResult, gql, NetworkStatus, PureQueryOptions, useMutation, useQuery, MutationTuple } from '@apollo/client';

import { ProviderList } from '@/store/providers';
import { HookResult } from './apolloClient';
import { getProductQuery } from './learning-catalog';
import { REFETCH_PROGRESS_CATALOG } from './catalog';
import { CardType, ProductCard } from '@/types/learning/card';
import { EventEnrollment, Inventory, InventoryProduct } from '@/types/learning/learning-catalog';

export interface HistoricCompletedCourse {
  id: number;
  type: string;
  completed: string;
  name: string;
}

const INVENTORY_FRAGMENT = gql`
  fragment InventoryFragment on InventoryItem {
    name
    id
    productId
    userSelfTrackedLearningId
    journeyId
    label
    spaceId
    state
    image
    provider
    providerAccess
    customLink
    accessLink
    teaser
    totalEffort
    totalLearningsCount
    orderId
    isCancellable
    isApprovalRequired
    mandatory
    isExternal
    objectId
    participation {
      completedAt
      progress
      userEffort
    }
    variant {
      id
      name
      validFrom
      validTo
      meta {
        METHOD
        LOCATION
      }
    }
    archived
  }
`;

const MY_ENROLLMENTS_FRAGMENT = gql`
  fragment MyEnrollmentsFragment on EventEnrollment {
    id
    learningId
    locationId
    title
    teaser
    type
    image
    address
    startDate
    endDate
    room
  }
`;

export const getInventoryQuery = gql`
  query getInventory {
    getInventory {
      ongoing {
        ...InventoryFragment
      }
      completed {
        ...InventoryFragment
      }
    }
    myEnrollments {
      ...MyEnrollmentsFragment
    }
  }
  ${INVENTORY_FRAGMENT}
  ${MY_ENROLLMENTS_FRAGMENT}
`;

const getHistoricCompletedCoursesQuery = gql`
  query getHistoricCompletedCourses {
    getHistoricCompletedCourses {
      id
      name
      completed
      type
    }
  }
`;

const cancelMutation = gql`
  mutation cancel($orderId: Int!) {
    cancel(orderId: $orderId) {
      value
    }
  }
`;

const mapInventoryProducts = (item: InventoryProduct) => {
  const { totalEffort, ...restItem } = item;
  const mappedItem: InventoryProduct = restItem;
  if (totalEffort) mappedItem.totalEffort = Number(totalEffort);
  return mappedItem;
};

interface HistoricCompletedCoursesResponse extends HookResult {
  courses: readonly HistoricCompletedCourse[];
  refetch: () => Promise<ApolloQueryResult<{ getHistoricCompletedCourses: HistoricCompletedCourse[] }>>;
}

export const useHistoricCompletedCourses = (): HistoricCompletedCoursesResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery<{ getHistoricCompletedCourses: HistoricCompletedCourse[] }>(
    getHistoricCompletedCoursesQuery
  );
  const courses: ReadonlyArray<HistoricCompletedCourse> = data?.getHistoricCompletedCourses || [];

  return {
    courses,
    error,
    refetch,
    networkStatus,
    loading,
  };
};

interface InventoryResponse extends HookResult {
  inventory: Inventory;
  refetch: () => Promise<
    ApolloQueryResult<{
      getInventory: {
        ongoing: InventoryProduct[];
        completed: InventoryProduct[];
      };
      myEnrollments: EventEnrollment[];
    }>
  >;
}
export const useInventory = (): InventoryResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery<{
    getInventory: {
      ongoing: InventoryProduct[];
      completed: InventoryProduct[];
    };
    myEnrollments: EventEnrollment[];
  }>(getInventoryQuery);

  const inventory: Inventory = {
    ongoing: [],
    completed: [],
    eventEnrollments: [],
    allProgress: [],
  };

  if (!(error || loading || !data)) {
    inventory.eventEnrollments = data.myEnrollments;

    inventory.ongoing = (data.getInventory?.ongoing || []).map(mapInventoryProducts);
    inventory.completed = (data.getInventory?.completed || []).map(mapInventoryProducts);
    inventory.allProgress = [...inventory.ongoing, ...inventory.completed];
  }

  return {
    inventory,
    error,
    refetch,
    networkStatus,
    loading: loading && networkStatus === NetworkStatus.loading,
  };
};

export interface CourseCardData {
  extra: CardExtra;
  card: ProductCard;
}

export interface InventoryProductCard extends ProductCard {
  // __typename: 'ProductCard'
  state?: 'registered' | 'pending approval' | 'not approved' | 'approved' | 'cancelled';
  userSelfTrackedLearningId?: number;
  journeyId?: number;
  totalLearningsCount?: number;
}

export interface CardExtra {
  cancelOrder?: () => void;
  loadingCancel?: boolean;
  isOngoing?: boolean;
}

export function inventoryToCourseCards(
  inventory: InventoryProduct[],
  cancelOrder: (params: { variables: { orderId: number } }) => void,
  loadingCancel: boolean,
  isOngoing = false,
  showArchived = true
): CourseCardData[] {
  const items = inventory.map((item) => {
    const getCardType = (item: InventoryProduct): CardType => {
      if (
        item.label === 'user-generated' ||
        item.label === 'external' ||
        item.label === 'course' ||
        item.label === 'journey' ||
        item.provider === ProviderList.USER_SELF_TRACKED_LEARNING
      ) {
        return item.label as CardType;
      }

      return 'learning-path';
    };

    const card: InventoryProductCard = {
      __typename: 'ProductCard',
      id: `product-${item.id}`,
      entity: 'product',
      entityId: item.productId,
      userSelfTrackedLearningId: item.userSelfTrackedLearningId,
      journeyId: item.journeyId,
      title: item.name,
      teaser: item.teaser,
      subjects: item.subjects,
      image: item.image || null,
      type: getCardType(item),
      totalEffort: item.totalEffort,
      totalLearningsCount: item.totalLearningsCount,
      spaceId: item.spaceId,
      provider: item.provider,
      customLink: item.customLink,
      featured: item.featured,
      participation: item.participation,
      // inventory specific
      available: item.available,
      state: item.state,
      // fake props
      isExternal: item.isExternal,
      language: null,
      archived: item.archived,
      canEdit: false,
      isApprovalRequired: item.isApprovalRequired,
    };

    const extra: CardExtra = {};
    if (isOngoing) extra.isOngoing = true;
    if (isOngoing && item.isCancellable) {
      // No discernible reason to cancel completed courses
      extra.cancelOrder = () => cancelOrder({ variables: { orderId: item.orderId } });
      extra.loadingCancel = loadingCancel;
    }
    return { card, extra };
  });

  return showArchived ? items : items.filter((m) => !m.card.archived);
}

export const useCancelOrder = (id?: number): MutationTuple<{ getInventory: Inventory }, { orderId: number }> => {
  const refetchQueries: PureQueryOptions[] = [{ query: getInventoryQuery }];

  if (id) refetchQueries.push({ query: getProductQuery, variables: { id } });
  else refetchQueries.push(REFETCH_PROGRESS_CATALOG);

  return useMutation(cancelMutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });
};
