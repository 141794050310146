import { useCallback, useState } from 'react';

import { ExpandedRowRenderSubComponent, IsRowExpandable } from '@/components/Table';
import { setItemInSet } from '@/utils/set';

export interface ExpandingMeta<T> {
  renderRowSubComponent?: ExpandedRowRenderSubComponent<T>;
  rowExpandableSubRows?: (item: T) => T[];
  isRowExpandable: IsRowExpandable<T>;
  isRowExpanded: (item: T) => boolean;
  toggleExpanded: (item: T, expanded: boolean) => void;
  clearExpanded: () => void;
  subRowsDepth: number;
}

const DEFAULT_EXPANDING_META: ExpandingMeta<any> = {
  renderRowSubComponent: () => null,
  rowExpandableSubRows: () => [],
  isRowExpandable: () => false,
  isRowExpanded: () => false,
  toggleExpanded: () => undefined,
  clearExpanded: () => undefined,
  subRowsDepth: 0,
};

export interface ExpandingParams<T> {
  subRowsDepth: number;
  isRowExpandable?: IsRowExpandable<T>;
  renderExpandedRowSubComponent?: ExpandedRowRenderSubComponent<T>;
  rowExpandableSubRows?: (item: T) => T[];
}

export function useExpanding<T>({
  subRowsDepth,
  renderExpandedRowSubComponent,
  isRowExpandable: isRowExpandableRaw,
  rowExpandableSubRows,
}: ExpandingParams<T>): ExpandingMeta<T> {
  const [expandedItems, setExpandedItems] = useState<Set<T>>(new Set());

  const clearExpanded = useCallback((): void => setExpandedItems(new Set()), []);
  const toggleExpanded = useCallback(
    (item: T, expanded: boolean) => setExpandedItems(setItemInSet(expandedItems, item, expanded)),
    [setExpandedItems, expandedItems]
  );

  const isRowExpandable = useCallback(
    (item: T): boolean =>
      isRowExpandableRaw ? isRowExpandableRaw(item) : !!renderExpandedRowSubComponent || !!rowExpandableSubRows,
    [isRowExpandableRaw, renderExpandedRowSubComponent, rowExpandableSubRows]
  );

  const isRowExpanded = useCallback((item: T): boolean => expandedItems.has(item), [expandedItems]);

  if (!renderExpandedRowSubComponent && !rowExpandableSubRows) {
    return DEFAULT_EXPANDING_META;
  }

  return {
    renderRowSubComponent: renderExpandedRowSubComponent,
    rowExpandableSubRows,
    isRowExpandable,
    isRowExpanded,
    toggleExpanded,
    clearExpanded,
    subRowsDepth,
  };
}
