import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoLinkMeta } from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { getThumbnail, VideoThumbnail } from './VideoThumbnail';

interface Props {
  data: VideoLinkMeta;
}

export const VideoLinkBlock = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const [showThumbnail, setShowThumbnail] = useState(Boolean(getThumbnail(data.embedUrl, data.type)));
  try {
    return (
      <div className="content is-article is-unpadded">
        <figure>
          {showThumbnail ? (
            <VideoThumbnail
              thumbnail={getThumbnail(data.embedUrl, data.type)}
              caption={data.caption}
              onClickPlay={() => setShowThumbnail(false)}
            />
          ) : (
            <div className="video is-16by9">
              <iframe src={data.embedUrl} frameBorder="0" allowFullScreen title={t('Video')} />
            </div>
          )}
          {data.caption && <figcaption>{data.caption}</figcaption>}
        </figure>
      </div>
    );
  } catch (e) {
    return (
      <div className="container">
        <h2>Error Loading Video</h2>
        {e instanceof Error && <details style={{ whiteSpace: 'pre-wrap' }}>{e.toString()}</details>}
      </div>
    );
  }
};
