/* eslint-disable import/no-unused-modules */
import React, { useEffect, useState } from 'react';

import { durationToFutureDateByUnits } from '@/utils/time';
import { useInterval } from '@/hooks/useInterval';
import { CountdownStyled, CountdownTitleStyled, RootStyled, SeparatorStyled, Graphics } from './styles';
import { TimeUnit } from './TimeUnit';

const DEFAULT_DELAY_MS = 1000;

export type CountdownProps = {
  sliStartDate: string;
  onSLIStarted: (started: boolean) => void;
};

export const Countdown = ({ onSLIStarted, sliStartDate }: CountdownProps): JSX.Element => {
  const [value, setValue] = useState<{
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  }>({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const [isTimeUp, setIsTimeUp] = useState<boolean>(false);

  const handleSLIStartedCheck = () => {
    const { days, hours, minutes, seconds, isTimeUp } = durationToFutureDateByUnits(new Date(sliStartDate));

    if (isTimeUp) {
      setIsTimeUp(true);
      onSLIStarted(true);
    } else {
      onSLIStarted(false);
      setValue({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  // Running it on mount, for a pre-check
  useEffect(() => {
    handleSLIStartedCheck();
  }, []);

  useInterval(handleSLIStartedCheck, isTimeUp ? null : DEFAULT_DELAY_MS);

  return (
    <RootStyled>
      <CountdownTitleStyled>Ready to launch in</CountdownTitleStyled>
      <CountdownStyled>
        <TimeUnit value={value.days} label="Days" />
        <SeparatorStyled />
        <TimeUnit value={value.hours} label="Hours" />
        <SeparatorStyled />
        <TimeUnit value={value.minutes} label="Minutes" />
        <SeparatorStyled />
        <TimeUnit value={value.seconds} label="Seconds" />
      </CountdownStyled>
      <Graphics />
    </RootStyled>
  );
};
