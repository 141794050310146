import styled, { css } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { variant } from './sizing';

const styles = {
  icon: css`
    color: ${(p) => p.theme.colors.primary.onMain};
    width: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  wrapper: css`
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    ${variant.small} {
      top: 16px;
      left: 80px;
      transform: translateX(-50%);
    }

    ${variant.large} {
      top: 155px;
      transform: translateY(-50%);
      right: 24px;
    }
  `,
};

const CheckmarkIcon = styled(Icon).attrs({ icon: 'check' })`
  ${styles.icon}
`;

const ExclamationMarkIcon = styled(Icon).attrs({ icon: 'exclamation' })`
  ${styles.icon}
`;

const CrossIcon = styled(Icon).attrs({ icon: 'xmark' })`
  ${styles.icon}
`;

const Checkmark = styled.div.attrs({
  children: <CheckmarkIcon />,
})`
  ${styles.wrapper}
  background: ${(p) => p.theme.colors.primary.main};
`;

const ExclamationMark = styled.div.attrs({
  children: <ExclamationMarkIcon />,
})`
  ${styles.wrapper}
  background: ${(p) => p.theme.colors.warning.main};
`;

const Cross = styled.div.attrs({
  children: <CrossIcon />,
})`
  ${styles.wrapper}
  background: ${(p) => p.theme.colors.error.main};
`;

export const StatusIcon = {
  Checkmark,
  ExclamationMark,
  Cross,
};
