import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { OverlineMixin } from '@/components/Typography/Typography';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';

export type Cursor = 'auto' | 'pointer';

type StyledInputProps = {
  $error?: boolean;
  $hasIcon?: boolean;
  $overline?: boolean;
  $newTheme?: boolean;
  cursor?: 'auto' | 'pointer';
};

export const StyledInput = styled.input<StyledInputProps>`
  ${({ $overline }) => ($overline ? OverlineMixin() : `font-size: ${cvar('font-size-6')};`)}
  background-color: ${cvar('color-white')};
  border: ${cvar('border-style')};
  ${({ $error }) => $error && `border-color: ${cvar('color-monza')};`};

  color: ${cvar('color-black')};
  height: 45px;
  outline: none;
  padding: ${cvar('gap-xs')} ${cvar('gap-sm')};
  padding-right: ${cvar('gap-lg')};
  transition: all 0.2s ease;
  ${({ cursor }) => cursor && `cursor: ${cursor};`}

  &:focus {
    box-shadow: ${cvar('box-shadow-small-hover')};
  }

  ${({ $newTheme }) =>
    $newTheme
      ? ` 
    && {
      &::-webkit-input-placeholder,
      &::placeholder {
        color: ${cvar('color-black')};
        opacity: 1;
      }
    
      &:disabled {
        color: ${cvar('color-alto')};
        cursor: not-allowed;
        opacity: 1;
        
        &::-webkit-input-placeholder,
        &::placeholder {
          color: ${cvar('color-alto')};
        }
      }
    }
  `
      : `
    && {
       border-radius: ${cvar('radius-inner')};
    }
  `}
`;

export const StyledIcon = styled.div<{
  onClick?: () => void;
  rotated?: boolean;
  spin?: boolean;
  newTheme?: boolean;
  disabled?: boolean;
}>`
  color: ${cvar('color-silver-chalice')};
  font-size: ${cvar('font-size-7')};
  height: ${BUTTON_HEIGHT_ENUM.default}rem;
  position: absolute;
  right:0;
  transition: all 0.2s ease;
  padding: ${cvar('gap-xs')} ${cvar('gap-sm')};
  @keyframes spin {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  ${({ spin }) =>
    spin &&
    `
    svg {
      animation: spin 1s linear infinite
    }
  `}
  
  ${({ onClick, rotated }) =>
    !!onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${cvar('color-secondary')};
      border-radius: 0 ${cvar('radius-inner')} ${cvar('radius-inner')} 0;
      color: ${cvar('color-black')};
    }

    svg {
      transition: all 0.2s ease;
      ${rotated && `transform: rotate(180deg);`}
    }
  `}
  ${({ newTheme, disabled }) =>
    newTheme &&
    `
    color: ${cvar('color-black')};
    
    ${disabled &&
      `
      && {
        cursor: not-allowed;
        color: ${cvar('color-alto')};
        
        &:hover {
          background-color: initial;
        }
      }
    `}
  `}

  
`;

export const StyledIconV2 = styled.div<{
  onClick?: () => void;
  rotated?: boolean;
  spin?: boolean;
  newTheme?: boolean;
  disabled?: boolean;
}>`
  color: ${cvar('color-silver-chalice')};
  font-size: ${cvar('font-size-7')};
  height: ${BUTTON_HEIGHT_ENUM.default}rem;
  position: absolute;

  left:0
  transition: all 0.2s ease;
  padding: ${cvar('gap-xs')} ${cvar('gap-sm')};
  @keyframes spin {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  ${({ spin }) =>
    spin &&
    `
    svg {
      animation: spin 1s linear infinite
    }
  `}
  
  ${({ onClick, rotated }) =>
    !!onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${cvar('color-secondary')};
      border-radius: 0 ${cvar('radius-inner')} ${cvar('radius-inner')} 0;
      color: ${cvar('color-black')};
    }

    svg {
      transition: all 0.2s ease;
      ${rotated && `transform: rotate(180deg);`}
    }
  `}
  ${({ newTheme, disabled }) =>
    newTheme &&
    `
    color: ${cvar('color-black')};
    
    ${disabled &&
      `
      && {
        cursor: not-allowed;
        color: ${cvar('color-alto')};
        
        &:hover {
          background-color: initial;
        }
      }
    `}
  `}

  
`;
export const StyledInputV2 = styled(StyledInput)`
  box-sizing: border-box;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 4px;

  padding-left: ${cvar('gap-xl')};
  padding-right: ${cvar('gap-xs')};
  padding-top: ${cvar('gap-xs')};
  padding-bottom: ${cvar('gap-xs')};

  &:hover {
    border: 1px solid #141d1e;
  }

  &:focus {
    border: 2x solid #0fc290;
  }

  &:enabled,
  :active {
    border: 1px solid #6f797a;
  }
`;
