import React from 'react';

import { Grid } from '@/ui/Grid/atoms';
import { Banner } from './styles';
import { Hero } from '../Hero';
import { LearningItems } from '../LearningItems';
import { Layout } from '@/ui/Layout';
import { StandardAssignmentDetails } from '@/pages/Assignment/standard/types';
import { Assignment } from '@/store/utils/assignments/types';
import { makeNamespacedComponent } from '@/ui/utils';
import { Breadcrumbs } from '@/ui/Breadcrumbs';
import { BREADCRUMBS } from '@/pages/Assignment/constants';
import { Skeleton } from '@/ui/Skeleton';
import { ProgressBanner } from '../ProgressBanner';

const _Page = ({ assignment }: { assignment: StandardAssignmentDetails }): JSX.Element => {
  const standardAssignment: Assignment<'STANDARD'> = {
    ...assignment,
    assignmentType: 'STANDARD',
  };

  const progressData = {
    completionRate: assignment.completionRate,
    requiredCompletions: assignment.requiredCompletions,
    completed: assignment.completed,
  };

  return (
    <>
      <Layout.ContentSection marginTop>
        <Banner>
          <Grid>
            <Grid.Item
              mobile="small"
              mobileLandscape="medium"
              tablet="medium"
              desktop="medium"
              desktopLarge="medium"
              widescreen="medium"
              fullHD="medium"
            >
              <Breadcrumbs {...BREADCRUMBS} />
              <Hero
                assignment={assignment}
                learningItemsCount={progressData.requiredCompletions.total}
                completedLearningItemsCount={progressData.requiredCompletions.current}
              />
            </Grid.Item>
            <Grid.Item
              mobile="small"
              mobileLandscape="medium"
              tablet="medium"
              desktop="small"
              desktopLarge="small"
              widescreen="small"
              fullHD="small"
            >
              <ProgressBanner {...progressData} />
            </Grid.Item>
          </Grid>
        </Banner>
      </Layout.ContentSection>
      <Layout.ContentSection>
        <LearningItems assignment={standardAssignment} />
      </Layout.ContentSection>
    </>
  );
};

const LoadingState = (): JSX.Element => (
  <>
    <Layout.ContentSection marginTop>
      <Skeleton width={80} height={16} />
      <Banner>
        <Grid>
          <Grid.Item
            mobile="small"
            mobileLandscape="medium"
            tablet="medium"
            desktop="medium"
            desktopLarge="medium"
            widescreen="medium"
            fullHD="medium"
          >
            <Hero.LoadingState />
          </Grid.Item>
        </Grid>
      </Banner>
    </Layout.ContentSection>
    <Layout.ContentSection>
      <LearningItems.LoadingState />
    </Layout.ContentSection>
  </>
);

export const Page = makeNamespacedComponent(_Page, {
  LoadingState,
});
