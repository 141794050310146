/* eslint-disable import/no-unused-modules */
import React from 'react';

import { useWeekNavigationsContext } from '../../context';
import { NavigationButton, NavigationContainerStyled } from '../../variants/styles';

interface WeeksPaginationProps {
  children?: JSX.Element;
}

export const WeeksPagination = ({ children }: WeeksPaginationProps): JSX.Element => {
  const { offset, setOffset, setTriggeredPagination, maxPaginationOffset } = useWeekNavigationsContext();

  const shouldShowPrevButton = offset !== 0;
  const shouldShowNextButton = offset !== maxPaginationOffset;

  const handlePrevClick = () => {
    if (offset > 0) {
      setOffset((current) => current - 1);
      setTriggeredPagination(true);
    }
  };

  const handleNextClick = () => {
    if (offset < maxPaginationOffset) {
      setOffset((current) => current + 1);
      setTriggeredPagination(true);
    }
  };

  return (
    <NavigationContainerStyled>
      <NavigationButton icon="angle-left" onClick={handlePrevClick} buttonVariant="dark" hidden={!shouldShowPrevButton} />
      {children}
      <NavigationButton icon="angle-right" onClick={handleNextClick} buttonVariant="dark" hidden={!shouldShowNextButton} />
    </NavigationContainerStyled>
  );
};
