import { createContext } from 'react';

import { CustomEditorV2ToggledFeatures } from '@/component/customEditorV2';

type CustomEditorContextState = {
  spaceId: number;
  readOnly?: boolean;
  /**
   * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
   */
  activePlaceholdersElementsIds: string[];
  /**
   * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
   */
  setElementPlaceholderActive: (ops: { id: string; enabled: boolean }) => void;
  toggledFeatures?: CustomEditorV2ToggledFeatures;
};

export const CustomEditorContext = createContext<CustomEditorContextState>({
  spaceId: 0,
  readOnly: false,
  /**
   * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
   */
  activePlaceholdersElementsIds: [],
  /**
   * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
   */
  setElementPlaceholderActive: () => {},
  toggledFeatures: undefined,
});
