import styled, { css } from 'styled-components';
import { Editable } from 'slate-react';

type CustomEditableProps = {
  disabled?: boolean;
  size?: 'large' | 'small';
  $minimized?: boolean;
  $fullSize?: boolean;
};

export const CustomEditable = styled(Editable)<CustomEditableProps>`
  ${({ theme, $minimized }) => {
    return $minimized
      ? css`
          border: 1px ${theme.colors.neutral.onContainer} solid; // var(--border-style);
          border-top: none;
        `
      : css`
          border: var(--border-style);
        `;
  }};

  background-color: var(--color-white);
  border-radius: 0 0 calc(var(--radius-inner) + var(--border-width)) calc(var(--radius-inner) + var(--border-width));
  box-sizing: border-box;
  color: var(--color-text);
  font-size: var(--font-size-6);
  line-height: 1.6;
  ${({ size, $fullSize }) => {
    return $fullSize
      ? css`
          flex: 1;
        `
      : css`
        max-height: 250px;
        min-height: ${
          size === 'large' ? '250px' : '50px'
        } !important}; // !important, since we override inline styling from Slate.js
          `;
  }};
  outline: none;
  overflow-y: scroll;
  padding: var(--gap-sm);
  transition: 0.225s ease;
  width: 100%;
  word-break: break-word;

  &:focus {
    color: var(--color-mine-shaft);
  }

  &:hover {
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}

  h2 {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    margin: 20px 0 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  ul > li {
    list-style: inside;
  }

  ol > li {
    margin-left: 15px;
  }

  /* Newly introduced issue after Slate.js internal updates. Known, but looks like they don't want to fix that. 
    Fix idea taken from: @link https://github.com/ianstormtaylor/slate/pull/5423#issuecomment-1565455560
  */
  span[data-slate-placeholder='true'] {
    top: unset !important;
  }
`;
