import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TagWithIcon } from '@/components/Tag/v2';

interface Props {
  isMandatory: boolean;
  isRecommended?: boolean;
}

export const CourseCardTags = styled.div`
  padding: 0 1rem 0.75rem 1rem;
`;

export const CourseCardHeaderLabelsV2 = ({ isMandatory, isRecommended }: Props): React.ReactElement => {
  const { t } = useTranslation('catalog');

  return (
    <CourseCardTags>
      <TagWithIcon
        icon={isRecommended && !isMandatory ? ['far', 'star'] : ['far', 'star-exclamation']}
        bgColor={isRecommended && !isMandatory ? '#FFEDE9' : '#FFB4A9'}
      >
        {isMandatory && t('Required')}
        {isRecommended && !isMandatory && t('Recommended')}
      </TagWithIcon>
    </CourseCardTags>
  );
};
