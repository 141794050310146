import React from 'react';
import { useTheme } from 'styled-components';

import { PrimaryInformer } from '@/ui/Informer';
import { InformersGroupStyled, RootStyled, TitleStyled } from './styles';
import { PrimaryInformerProps } from '@/ui/Informer/variants/PrimaryInformer';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';

export type ProgressBannerProps = {
  completionRate: number;
  requiredCompletions: {
    current: number;
    total: number;
  };
  completed?: boolean;
};

export const _ProgressBanner = ({ completionRate, requiredCompletions, completed }: ProgressBannerProps): JSX.Element => {
  const theme = useTheme();

  const informers: PrimaryInformerProps[] = [
    {
      title: `${completionRate}%`,
      label: 'Completion rate',
      sideBox: {
        background: theme.tones.primary[70],
        icon: 'bars-progress',
      },
    },
    {
      title: `${requiredCompletions.current}/${requiredCompletions.total}`,
      label: 'Required completion',
      sideBox: {
        background: theme.tones.primary[70],
        icon: 'list-check',
      },
    },
    {
      title: completed ? 'Completed' : 'Not completed',
      label: 'Status',
      sideBox: {
        background: theme.tones.primary[70],
        icon: 'loader',
      },
    },
  ];

  return (
    <RootStyled>
      <TitleStyled>My progress</TitleStyled>
      <InformersGroupStyled>
        {informers.map((props) => (
          <PrimaryInformer key={`${props.title}-${props.label}`} {...props} />
        ))}
      </InformersGroupStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => {
  return (
    <RootStyled>
      <Skeleton height={16} width={150} />
      <InformersGroupStyled>
        <PrimaryInformer.LoadingState />
        <PrimaryInformer.LoadingState />
        <PrimaryInformer.LoadingState />
      </InformersGroupStyled>
    </RootStyled>
  );
};

export const ProgressBanner = makeNamespacedComponent(_ProgressBanner, {
  LoadingState,
});
