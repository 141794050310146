import * as React from 'react';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { DiscussionPost } from '../component/Discussions/DiscussionPost';
import { usePost } from '../store/discussion';
import { LoadingHero } from '../component/LoadingHero';
import { useMarkNotificationAsSeen, useNotifications } from '../store/notifications';

type Params = 'postId' | '*';

export const PostPage = (): JSX.Element => {
  const params = useParams<Params>();
  const section = params['*'] ? decodeURIComponent(params['*']) : null;
  const path: string[] = (section || '/fail').replace(/^\//, '').split('/');
  const location = useLocation();
  const parsedPostId = parseInt(params.postId || '-1');
  const { post, error, loading } = usePost(path, parsedPostId);
  const { notifications, loading: loadingNotifications } = useNotifications();
  const { markAsSeen, loading: markingNotification } = useMarkNotificationAsSeen();
  const urlSearchParams = new URLSearchParams(location.search);
  const highlightedPost = parseInt(urlSearchParams.get('highlight') || '-1', 10);

  useEffect(
    () =>
      void (async () => {
        if (!markingNotification && notifications.find((notification) => notification.topLevelPostId === parsedPostId)) {
          await markAsSeen({ variables: { postId: parsedPostId } });
        } else {
          console.log('nothing to do here (should only trigger once after load please)');
        }
      })(),
    [loadingNotifications, loading]
  );

  return (
    <div className="section is-padded has-background-white-ter">
      <div className="container">
        {loading ? (
          <LoadingHero />
        ) : error || !post ? (
          <article className="message is-warning">
            <div className="message-body">
              <Trans ns="discussions" i18nKey="Something went wrong" />
            </div>
          </article>
        ) : (
          <DiscussionPost highlightedPost={highlightedPost} isStandalone post={post} selected={true} path={path} />
        )}
      </div>
    </div>
  );
};
