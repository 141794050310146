import { Participation } from '@/types/analytics';

export const getTimeSpent = (items: Participation[]): number =>
  items.reduce((prev, current) => prev + (current?.userEffort || 0), 0);

export const sortOrder = (first?: number, second?: number, ascending = true): number => {
  if (!first && first !== 0) return 1;
  if (!second && second !== 0) return -1;
  return ascending ? first - second : second - first;
};

export const sortStringOrder = (first?: string, second?: string): number => {
  const firstParam = first ?? '';
  const secondParam = second ?? '';

  return firstParam.trim().localeCompare(secondParam.trim());
};
