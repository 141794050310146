import styled from 'styled-components';

import { TextStyledProps } from './types';

export const TextStyled = styled.p<TextStyledProps>`
  margin: 0;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'event':
      case 'learning':
        return `
        ${theme.typography.body3};
        color: ${theme.colors.neutral.onBackground};
        `;
      case 'meta':
        return `
        ${theme.typography.body3};
        color: ${theme.colors.neutral.onContainer};
        `;
      default:
        return `
        ${theme.typography.caption};
        color: ${theme.colors.neutral.onBackground};
        `;
    }
  }}
`;
