import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';
import { RLocationType, useLearningByIdQueryRemote } from '@/store/v2';
import { formatTimeDependingOnUserTimezone } from '@/utils/dates';
import { convertDateWithCustomTimezone } from '@/utils/time';
import { getIsLiveEventActive, getIsLiveEventEnded } from '@/pages/LiveEvent/store/utils';

type UsePracticalAssessmentViewerProps = {
  id: string;
};

type UsePracticalAssessmentViewerHook = {
  loading: boolean;
  liveEvent: LiveEvent | null;
};

export const useLiveEventViewer = ({ id }: UsePracticalAssessmentViewerProps): UsePracticalAssessmentViewerHook => {
  const { data, loading } = useLearningByIdQueryRemote({
    variables: { id: String(id), includeProgress: true, includeLocations: true },
  });

  const liveEventData = data?.learning;
  const liveEventLocation = liveEventData?.locations?.[0];
  const startDateInUsersTimezone = loading
    ? null
    : convertDateWithCustomTimezone({
        date: new Date(liveEventLocation?.startDate as Date),
        customTimezoneOffsetInMinutes: Number(liveEventData?.meta?.timezone),
      });
  const endDateInUsersTimezone = loading
    ? null
    : convertDateWithCustomTimezone({
        date: new Date(liveEventLocation?.endDate as Date),
        customTimezoneOffsetInMinutes: Number(liveEventData?.meta?.timezone),
      });

  return {
    loading,
    liveEvent: {
      id: liveEventData?.id,
      spaceId: liveEventData?.spaceId,
      locationId: liveEventData?.locations?.[0]?.id,
      timezone: liveEventData?.meta?.timezone,
      title: liveEventData?.title || '',
      teaser: liveEventData?.teaser || '',
      image: liveEventData?.image || '',
      language: liveEventData?.languageId || 0,
      startDate: liveEventLocation?.startDate || null,
      endDate: liveEventLocation?.endDate || null,
      address: liveEventLocation?.address || '',
      type: liveEventLocation?.type || null,
      meta: liveEventData?.meta,
      myEnrollment: liveEventLocation?.myEnrollment || null,
      room: liveEventLocation?.room || '',
      isOnline: liveEventLocation?.type === RLocationType.Online,
      isStartingSoon: loading
        ? false
        : getIsLiveEventActive({
            startDate: startDateInUsersTimezone as Date,
            endDate: endDateInUsersTimezone as Date,
          }),
      hasEnded: loading ? false : getIsLiveEventEnded({ endDate: endDateInUsersTimezone as Date }),
      dateAndTime: loading
        ? ''
        : formatTimeDependingOnUserTimezone({
            startTime: liveEventLocation?.startDate as Date,
            endTime: liveEventLocation?.endDate as Date,
            formatOptions: {
              showTimezone: true,
            },
          }),
      monthName: startDateInUsersTimezone?.toLocaleString('en-US', { month: 'short' }) || '',
      dayOfMonth: startDateInUsersTimezone?.getDate() || '',
    } as unknown as LiveEvent,
  };
};
