import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';

import { mediaQuery } from '@/ui/utils';

const contentSectionCss = css`
  width: 100%;
  max-width: ${(p) => p.theme.sizes.maxContentWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(p) => p.theme.gutter.small}px;
  padding-right: ${(p) => p.theme.gutter.small}px;
  ${mediaQuery.from('desktop')} {
    padding-left: ${(p) => p.theme.gutter.medium}px;
    padding-right: ${(p) => p.theme.gutter.medium}px;
  }
`;

type ContentSectionProps = { marginTop?: boolean; marginBottom?: boolean };

type ContentSection = StyledComponent<'div', DefaultTheme, ContentSectionProps, never> & { css: typeof contentSectionCss };

/**
 * Simple component that keeps page contents at a maximum width and centered horizontally.
 */
export const ContentSection = styled.div<ContentSectionProps>`
  ${contentSectionCss}
  margin-top: ${(p) => (p.marginTop ? 64 : 0)}px;
  margin-bottom: ${(p) => (p.marginBottom ? 64 : 0)}px;
` as ContentSection;

ContentSection.css = contentSectionCss;
