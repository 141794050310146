import { gql, useQuery } from '@apollo/client';

import { Me, useCurrentUserHook } from '@/store/currentUser/types';

export const CURRENT_USER = gql`
  query me {
    me {
      acceptedExtraTerms
      acceptedGdpr
      acceptedTos
      email
      firstname
      hasPassword
      id
      isDebugging
      language
      lastname
      passwordExpiryDate
      permissions {
        hasViewAccessToAdminPanel
        hasViewAccessToManagerDashboard
        hasWriteAccessToInternals
      }
      profileImage
      realm
      status
      username
    }
  }
`;

export const useCurrentUser: useCurrentUserHook = () => {
  const { data, error, loading } = useQuery<{ me: Me }>(CURRENT_USER, {
    // Dont cache error responses
    errorPolicy: 'none',
  });

  return {
    user: data?.me || undefined,
    error,
    loading,
    loadingFirstTime: loading && !data?.me,
  };
};
