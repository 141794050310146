import React from 'react';

import { CourseCard } from '@/components/CourseCard';
import { CARD_SORTING_METHODS } from '@/store/catalog';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';
import { Card } from '@/types/learning/card';
import { numberOfCards } from './constants';
import { CampusSectionDetails } from './types';

export const getFeaturedCards = (featuredCards: Card[]): CampusSectionDetails => {
  const cards = [...featuredCards]
    .sort(CARD_SORTING_METHODS[CatalogSortingEnum.ALPHA_ASC])
    .slice(numberOfCards.startIndex, numberOfCards.endIndex)
    .map((c) => <CourseCard card={c} key={c.id} />);
  return { count: featuredCards.length, cards };
};
