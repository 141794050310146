import React from 'react';

import { Icon } from '@/ui/Icon';
import {
  Divider,
  EditIconWrapStyled,
  EditLinkRootStyled,
  EditLinkRowStyled,
  FloatingLinkUrlInput,
  TextDisplayStyled,
} from './styles';

interface EditLinkFormProps {
  textInputProps: {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    defaultValue: string;
    ref: (el: HTMLInputElement) => void;
  };
}

export function EditLinkForm({ textInputProps }: EditLinkFormProps): JSX.Element | null {
  return (
    <EditLinkRootStyled>
      <EditLinkRowStyled>
        <EditIconWrapStyled>
          <Icon icon="link" size="xs" />
        </EditIconWrapStyled>

        <FloatingLinkUrlInput placeholder="Paste link" />
      </EditLinkRowStyled>

      <Divider orientation="horizontal" />

      <EditLinkRowStyled>
        <EditIconWrapStyled>
          <Icon icon="text" size="xs" />
        </EditIconWrapStyled>
        <TextDisplayStyled placeholder="Text to display" {...textInputProps} />
      </EditLinkRowStyled>
    </EditLinkRootStyled>
  );
}
