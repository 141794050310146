import { DependencyList, useCallback, useEffect } from 'react';

const handlers: ((e: KeyboardEvent) => void)[] = [];

/**
 * Calls all handlers in reverse order
 * @param event The KeyboardEvent generated by the Escape keydown.
 */
function handleEscape(event: KeyboardEvent) {
  if (event.key === 'Escape' && !event.defaultPrevented) {
    for (let i = handlers.length - 1; i >= 0; --i) {
      handlers[i](event);
      if (event.defaultPrevented) {
        break;
      }
    }
  }
}

/**
 * Sets up a `keydown` listener on `window.document`. If
 * 1) The pressed key is "Escape", and
 * 2) The event has not had `.preventDefault()` called
 * The given callback will be executed.
 *
 * Note: If multiple `useOnEscapePress` hooks are active simultaneously, the
 * callbacks will occur in reverse order. In other words, if a parent component
 * and a child component both call `useOnEscapePress`, when the user presses
 * Escape, the child component's callback will execute, followed by the parent's
 * callback. Each callback has the chance to call `.preventDefault()` on the
 * event to prevent further callbacks.
 *
 * @param onEscape {(e: KeyboardEvent) => void} The callback that gets executed
 * when the Escape key is pressed. The KeyboardEvent generated by the Escape
 * keypress is passed as the only argument.
 *
 * @param callbackDependencies {React.DependencyList} The dependencies of the given
 * `onEscape` callback for memoization. See `React.useCallback` for more info.
 */
export const useOnEscapePress = (
  onEscape: (e: KeyboardEvent) => void,
  callbackDependencies: DependencyList = [onEscape]
): void => {
  const escapeCallback = useCallback(onEscape, callbackDependencies);
  useEffect(() => {
    if (handlers.length === 0) {
      document.addEventListener('keydown', handleEscape);
    }
    handlers.push(escapeCallback);
    return () => {
      handlers.splice(
        handlers.findIndex(h => h === escapeCallback),
        1
      );
      if (handlers.length === 0) {
        document.removeEventListener('keydown', handleEscape);
      }
    };
  }, [escapeCallback]);
};
