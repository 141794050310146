import React from 'react';

import { CourseCard } from '@/components/CourseCard';
import { Grid } from '@/ui/Grid/atoms';
import { makeNamespacedComponent } from '@/ui/utils';
import { CourseCardsProps } from './types';
import { trackLearningCardClicked } from '@/utils/tracking/strategicAssignment';

const _CourseCards = ({ cards }: CourseCardsProps): JSX.Element => {
  const renderedCards = cards.map((card) => (
    <CourseCard key={card.customKey ?? card.id} card={card} onClick={() => trackLearningCardClicked(card.title)} />
  ));

  return (
    <Grid rowGap="large">
      {renderedCards.map((card) => (
        <Grid.Item
          key={card.key}
          mobile={3}
          mobileLandscape={4}
          tablet={3}
          desktop={3}
          desktopLarge={3}
          widescreen={3}
          fullHD={3}
        >
          {card}
        </Grid.Item>
      ))}
    </Grid>
  );
};

const LoadingState = (): JSX.Element => (
  <Grid rowGap="large">
    {[0, 1, 2, 3].map((i) => (
      <Grid.Item
        key={`grid-item-loading-${i}`}
        mobile={3}
        mobileLandscape={4}
        tablet={3}
        desktop={3}
        desktopLarge={3}
        widescreen={3}
        fullHD={3}
      >
        <CourseCard.Loader />
      </Grid.Item>
    ))}
  </Grid>
);

const CourseCards = makeNamespacedComponent(_CourseCards, {
  LoadingState,
});

export { CourseCards };
