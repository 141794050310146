import React, { useState } from 'react';

import { Grid } from '@/ui/Grid/atoms';
import { Banner, FlexedEndedWeeklyChart, FlexedWeeklyChart } from './styles';
import { Hero } from '../Hero';
import { Layout } from '@/ui/Layout';
import { LearningItems } from '../LearningItems';
import { makeNamespacedComponent } from '@/ui/utils';
import { StrategicAssignmentDetails } from '../../useStrategicAssignment/types';
import { Breadcrumbs } from '@/ui/Breadcrumbs';
import { BREADCRUMBS } from '@/pages/Assignment/constants';
import { Skeleton } from '@/ui/Skeleton';
import { WeeklyLoopChart } from '../LoopChart/variants/WeeklyLoopChart';

const _Page = ({
  assignment,
  onAddLearningTimeClick,
  onCreateReflectionClick,
  onViewDetailsClick,
  onHelpClick,
  onOpenParticipants,
}: {
  assignment: StrategicAssignmentDetails;
  onAddLearningTimeClick: (selectedWeekId: string) => void;
  onCreateReflectionClick: (selectedWeekId: string) => void;
  onViewDetailsClick: (selectedWeekId: string) => void;
  onHelpClick: () => void;
  onOpenParticipants: () => void;
}): JSX.Element => {
  const [sliStarted, setSLIStarted] = useState<boolean>(true);

  const assignmentEnded = assignment.progressStatus === 'ended' || assignment.progressStatus === 'completed';
  return (
    <>
      <Layout.ContentSection marginTop>
        <Banner>
          <Grid>
            <Grid.Item
              mobile="small"
              mobileLandscape="medium"
              tablet="medium"
              desktop="medium"
              desktopLarge="medium"
              widescreen="medium"
              fullHD="medium"
            >
              <Breadcrumbs {...BREADCRUMBS} />
              <Hero assignment={assignment} onOpenParticipants={onOpenParticipants} />
            </Grid.Item>
            <Grid.Item
              mobile="small"
              mobileLandscape="medium"
              tablet="medium"
              desktop="small"
              desktopLarge="small"
              widescreen="small"
              fullHD="small"
            >
              {!assignmentEnded && (
                <div>
                  {assignment.weeks?.length ? (
                    <FlexedWeeklyChart
                      weeks={assignment.weeks}
                      onWeekReflect={onCreateReflectionClick}
                      onAddLearningTimeClick={onAddLearningTimeClick}
                      onViewDetailsClick={onViewDetailsClick}
                      onHelpClick={onHelpClick}
                      sliStarted={sliStarted}
                      startAtFullTimestamp={assignment.startAtFullTimestamp}
                      onSLIStarted={(started) => setSLIStarted(started)}
                    />
                  ) : (
                    <FlexedWeeklyChart.LoadingState />
                  )}
                </div>
              )}
              {assignmentEnded && (
                <div>
                  {assignment.weeks?.length ? (
                    <FlexedEndedWeeklyChart
                      weeks={assignment.weeks}
                      status={assignment.progressStatus === 'completed' ? 'completed' : 'ended'}
                      onViewDetailsClick={onViewDetailsClick}
                      goalReachedCount={assignment.weeklyGoalReached}
                      reflectionsCount={assignment.reflectionsCompleted}
                      timeSpent={assignment.estimatedEffort ?? 0}
                      goalLimitCount={assignment.goalLimitCount}
                    />
                  ) : (
                    <FlexedEndedWeeklyChart.LoadingState />
                  )}
                </div>
              )}
            </Grid.Item>
          </Grid>
        </Banner>
      </Layout.ContentSection>
      <Layout.ContentSection marginBottom>
        <LearningItems assignment={assignment} hasAccess={sliStarted} />
      </Layout.ContentSection>
    </>
  );
};

const LoadingState = (): JSX.Element => (
  <>
    <Layout.ContentSection marginTop>
      <Banner>
        <Grid>
          <Grid.Item
            mobile="small"
            mobileLandscape="medium"
            tablet="medium"
            desktop="medium"
            desktopLarge="medium"
            widescreen="medium"
            fullHD="medium"
          >
            <Skeleton width={120} height={16} />

            <Hero.LoadingState />
          </Grid.Item>
          <Grid.Item
            mobile="small"
            mobileLandscape="medium"
            tablet="medium"
            desktop="small"
            desktopLarge="small"
            widescreen="small"
            fullHD="small"
          >
            <WeeklyLoopChart.LoadingState />
          </Grid.Item>
        </Grid>
      </Banner>
    </Layout.ContentSection>
    <Layout.ContentSection>
      <LearningItems.LoadingState />
    </Layout.ContentSection>
  </>
);

export const Page = makeNamespacedComponent(_Page, {
  LoadingState,
});
