import styled, { css, useTheme } from 'styled-components';
import { useMemo } from 'react';

import { Icon, IconPropType } from '@/ui/Icon';
import { CorrectnessLabel } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/CorrectnessLabel';

export enum AnswerStates {
  UNSELECTED = 'UNSELECTED',
  SELECTED = 'SELECTED',
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  DISABLED = 'DISABLED',
}

const AnswerContainerStyled = styled.div<{ $state: AnswerStates }>`
  padding: 24px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  border-radius: 8px;

  ${({ $state }) =>
    $state === AnswerStates.SELECTED &&
    css`
      cursor: pointer;
      border: 2px solid ${({ theme }) => theme.colors.tertiary.outline};
      background-color: ${(p) => p.theme.tones.tertiary[95]};
    `};

  ${({ $state }) =>
    $state === AnswerStates.UNSELECTED &&
    css`
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.colors.neutral.outline2};
      &:hover {
        background: ${({ theme }) => theme.colors.states.hover5};
      }
    `};

  ${({ $state }) =>
    $state === AnswerStates.CORRECT &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
      background-color: ${({ theme }) => theme.tones.primary[99]};
    `};

  ${({ $state }) =>
    $state === AnswerStates.INCORRECT &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.error.main};
      background-color: ${(p) => p.theme.tones.error[95]};
    `};

  ${({ $state }) =>
    $state === AnswerStates.DISABLED &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.neutral.disabled};
    `};
`;

const AnswerLabelStyled = styled.div<{ $state: AnswerStates }>`
  ${({ theme }) => theme.typography.title4};

  ${({ $state }) =>
    $state !== AnswerStates.DISABLED &&
    css`
      color: ${(p) => p.theme.colors.neutral.onBackground};
    `};

  ${({ $state }) =>
    $state === AnswerStates.DISABLED &&
    css`
      color: ${(p) => p.theme.colors.neutral.disabled};
    `};
`;

const AnswerLabelContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

type AnswerItemProps = {
  label?: string;
  state: AnswerStates;
  isMultiSelect: boolean;
  isCorrect: boolean;
  correctnessBadgeShown: boolean;
  onSelect: () => void;
};

export const AnswerItem = ({
  label,
  state,
  isMultiSelect,
  correctnessBadgeShown,
  isCorrect,
  onSelect,
}: AnswerItemProps): JSX.Element => {
  const theme = useTheme();

  const icon = useMemo(() => {
    if (isMultiSelect) {
      return state === AnswerStates.UNSELECTED || state === AnswerStates.DISABLED ? ['far', 'square'] : ['far', 'square-check'];
    } else {
      return state === AnswerStates.UNSELECTED || state === AnswerStates.DISABLED ? ['far', 'circle'] : ['far', 'circle-dot'];
    }
  }, [state, isMultiSelect]);

  return (
    <AnswerContainerStyled $state={state} onClick={onSelect}>
      <Icon
        icon={icon as IconPropType}
        color={state === AnswerStates.DISABLED ? theme.colors.neutral.disabled : theme.colors.neutral.onBackground}
      />
      <AnswerLabelContainerStyled>
        <AnswerLabelStyled $state={state}>{label}</AnswerLabelStyled>
        {correctnessBadgeShown && <CorrectnessLabel isCorrect={isCorrect} />}
      </AnswerLabelContainerStyled>
    </AnswerContainerStyled>
  );
};
