import styled from 'styled-components';

import { ScreenSize } from '@/ui/theme/layout';
import { mediaQuery } from '@/ui/utils';

export const MainBox = styled.dialog<{ size?: ScreenSize }>`
  box-sizing: border-box;
  border: none;
  &:focus-visible {
    outline: none;
  }
  margin: 0;
  position: relative;
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[64]}
  border-radius: 8px;
  padding: 32px;

  display: grid;
  grid-template-areas:
    'title close'
    'gap1 .'
    'contents contents'
    'gap2 .'
    'error error '
    'gap3 .'
    'actions actions';
  grid-template-rows: auto 24px auto 40px auto 8px auto;
  grid-template-columns: auto minmax(0, auto);
  grid-column-gap: 8px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.colors.neutral.background};
    border-radius: 0 8px 8px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.tones.neutral[90]};
    border-radius: 8px;
  }

  width: ${(p) => {
    if (!p.size) return 360;
    const size = p.theme.breakpoints[p.size].end;
    if (size === Infinity) return p.theme.breakpoints.fhd.start;
    return size;
  }}px;

  ${mediaQuery.until('tablet')} {
    max-width: calc(100vw - ${(p) => p.theme.gutter.small * 2}px);
    max-height: calc(100vh - ${(p) => p.theme.gutter.small * 2}px);
  }

  ${mediaQuery.from('desktop')} {
    max-width: calc(100vw - ${(p) => p.theme.gutter.medium * 2}px);
    max-height: calc(100vh - ${(p) => p.theme.gutter.medium * 2}px);
  }
`;
