import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/sv';
import readingTime from 'reading-time';

import i18n from '../i18n/i18n';
import { VideoData } from '../administration/component/VideoUpload/VideoUpload';
import { Location } from '../types/learning';

dayjs.locale(i18n.language);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);

export const TIMEZONE_TO_LABEL_CONFIG = [
  { offsetInMinutes: 720, name: '(UTC-12:00)' },
  { offsetInMinutes: 660, name: '(UTC-11:00)' },
  { offsetInMinutes: 600, name: '(UTC-10:00)' },
  { offsetInMinutes: 540, name: '(UTC-09:00)' },
  { offsetInMinutes: 480, name: '(UTC-08:00)' },
  { offsetInMinutes: 420, name: '(UTC-07:00)' },
  { offsetInMinutes: 360, name: '(UTC-06:00)' },
  { offsetInMinutes: 300, name: '(UTC-05:00)' },
  { offsetInMinutes: 240, name: '(UTC-04:00)' },
  { offsetInMinutes: 180, name: '(UTC-03:00)' },
  { offsetInMinutes: 120, name: '(UTC-02:00)' },
  { offsetInMinutes: 60, name: '(UTC-01:00)' },
  { offsetInMinutes: 0, name: '(UTC)' },
  { offsetInMinutes: -60, name: '(UTC+01:00)' },
  { offsetInMinutes: -120, name: '(UTC+02:00)' },
  { offsetInMinutes: -180, name: '(UTC+03:00)' },
  { offsetInMinutes: -240, name: '(UTC+04:00)' },
  { offsetInMinutes: -300, name: '(UTC+05:00)' },
  { offsetInMinutes: -360, name: '(UTC+06:00)' },
  { offsetInMinutes: -420, name: '(UTC+07:00)' },
  { offsetInMinutes: -480, name: '(UTC+08:00)' },
  { offsetInMinutes: -540, name: '(UTC+09:00)' },
  { offsetInMinutes: -600, name: '(UTC+10:00)' },
  { offsetInMinutes: -660, name: '(UTC+11:00)' },
  { offsetInMinutes: -720, name: '(UTC+12:00)' },
  { offsetInMinutes: -780, name: '(UTC+13:00)' },
  { offsetInMinutes: -840, name: '(UTC+14:00)' },
];

export type DateRange = {
  from: string;
  to: string;
};

export function round(value: number, step: number): number {
  const inv = step ? 1.0 / step : 1;
  return Math.round(value * inv) / inv;
}

export function isValidTimestampFormat(timestamp: string): boolean {
  return timestamp.length == 'YYYY-MM-DD HH:MM'.length && /^20\d{2}-[01]\d-[0-3]\d [012]\d:[0-6]\d$/.test(timestamp);
}

export function datetimeAsLocalDate(dateString: string): Date {
  const [year, month, date, hours, minutes] = dateString.split(/[ :-]/).map((i) => parseInt(i));
  return new Date(year, month - 1, date, hours, minutes);
}

export function utcToLocalDateСustomFormat(timestamp: Date | string, format: string): string {
  return dayjs.utc(timestamp).local().format(format);
}

export function utcToLocalDate(timestamp: Date | string): string {
  return dayjs.utc(timestamp).local().format('YYYY-MM-DD');
}

export function utcToLocalDateTimeDisplayFormat(timestamp: Date | string): string {
  return dayjs.utc(timestamp).local().format('YYYY-MM-DD HH:mm');
}

export function utcToICSDateTime(timestamp: Date | string): string {
  return dayjs.utc(timestamp).format('YYYYMMDDTHHmmss[Z]');
}

export function localToUtcISOString(timestamp: Date | string): string {
  return dayjs(timestamp).utc().toISOString();
}

export function getCsvDateString(): string {
  const dayjsObj = dayjs();
  return `${dayjsObj.format('YYYY-MM-DD')}_${dayjsObj.format('HH.mm.ss')}`;
}

export function formatToHoursAndMinutesFromSeconds(seconds: number, defaultText = '0m'): string {
  const minutes = Math.floor(seconds / 60);

  const hoursText = i18n.language === 'sv' ? 't' : 'h';
  const minutesText = 'm';

  const hoursValue = Math.floor(minutes / 60);
  const minutesValue = minutes % 60;

  const showHours = hoursValue > 0;
  const showMinutes = minutesValue > 0;

  if (!showMinutes && !showHours) return defaultText;

  const hoursPart = showHours ? `${hoursValue}${hoursText}` : '';
  const minutesPart = showMinutes ? `${minutesValue}${minutesText}` : '';
  const spacingPart = showHours && showMinutes ? ' ' : '';
  return `${hoursPart}${spacingPart}${minutesPart}`;
}

export function formatMinutes(minutes: number): string {
  const hour = i18n.language === 'sv' ? 't' : 'h';
  return `${Math.floor(minutes / 60)}${hour}${minutes % 60 > 0 ? `${minutes % 60}m` : ''}`;
}

export function formatSeconds(seconds: number): string {
  if (!seconds) return '00:00';
  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor(seconds / 60);
  return `${hours ? hours + ':' : ''}${zeroPaddedTime(minutes)}:${zeroPaddedTime(seconds % 60)}`;
}

function zeroPaddedTime(time: number) {
  const evenTime = Math.floor(time);
  return evenTime ? (evenTime > 9 ? evenTime : `0${evenTime}`) : '00';
}

export function distanceToNow(date: Date): string {
  return dayjs(date).locale(i18n.language).fromNow();
}

export function minutesToRelativeTime(value: number | null | undefined): string {
  if (value == null || isNaN(value) || value <= 0 || typeof value.toFixed != 'function') return '';

  let unit = 'minute_plural';
  let displayValue = value;

  if (value === 1) {
    unit = 'minute';
  }

  if (value === 60) {
    unit = 'hour';
  }

  if (value > 60) {
    displayValue = round(value / 60, 0.5);
    unit = 'hour_plural';
  }
  return i18n.t(unit, { ns: 'time', count: displayValue });
}

export function minutesToHoursMinutes(minutes: number): { hours: number; minutes: number } {
  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
}

export function hoursMinutesToMinutes({ hours, minutes }: { hours: number; minutes: number }): number {
  return hours * 60 + minutes;
}

export function minutesToHours(minutes: number): number {
  return minutes > 0 ? minutes / 60 : minutes;
}

export function secondsToMinutes(seconds: number): number {
  return seconds > 0 ? Math.floor(seconds / 60) : 0;
}

export function millisecondsToMinutes(milliseconds: number): number {
  return milliseconds > 0 ? Math.floor(milliseconds / (60 * 1000)) : 0;
}

export function secondsToHours(seconds: number, decimals: number): number {
  const decimalPadding = Math.pow(10, decimals) || 1;
  return seconds > 0 ? Math.floor((seconds * decimalPadding) / 60 / 60) / decimalPadding : 0.0;
}

export interface ReadTimeResultsEx {
  text: string;
  textShort?: string;
  time: number;
  words: number;
  minutes: number;
}

export function readingTimeEx(text: string): ReadTimeResultsEx {
  const stats: ReadTimeResultsEx = readingTime(text);
  if (stats.minutes < 0.5) stats.text = '< 1 min read';
  stats.textShort = stats.text.replace(' read', '');
  return stats;
}

export function viewingTimeVimeo(data: VideoData): { text: string; textShort: string; minutes: number; time: number } {
  const minutes = data.duration ? data.duration / 60 : 0;
  const time = data.duration ? data.duration * 1000 : 0;
  const displayed = Math.ceil(minutes);
  const textShort = `${minutes < 0.5 && '< '}${displayed} min`;
  return {
    text: `${textShort} view`,
    textShort,
    minutes,
    time,
  };
}

export function localizedLocationDate({ startDate, endDate }: Location): string {
  return `${dayjs(startDate).local().format('YYYY-MM-DD LT')} - ${dayjs(endDate).local().format('LT')}`;
}

export function locationDateFormat(datePart: Date, timePart: Date): Date {
  return new Date(
    `${datePart.getFullYear()}-${datePart.getMonth() + 1}-${datePart.getDate()} ${timePart.getHours()}:${timePart.getMinutes()}`
  );
}

/**
 * Function, returning duration (split by formatted units) from current date to future date
 *
 * If target date < current date, formatted units are all `00`
 *
 * @param endDate target date, againts which we want to compare
 * @returns object containing units to date (pre-formatted)
 */
export const durationToFutureDateByUnits = (
  endDate: Date
): {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  isTimeUp: boolean;
} => {
  /**
   * Turns:
   *
   * -1 => 0
   * 9 => 09
   * 10 => 10 (keeps original)
   *
   * Purpose: if current date is greater than target date, we want a result as 00:00:00:00, not as -XX:-XX:-XX:-XX
   */
  const format = (target: number): string => {
    const value = target > 0 ? target : 0;
    return value > 9 ? String(value) : `0${value}`;
  };

  const diff = dayjs(endDate).diff(dayjs());

  const duration = dayjs.duration(diff);

  return {
    days: format(Math.floor(duration.asDays())), // asDays with floor() since days is hour primary unit (meaning we want to see >30 days, if that's the duration)
    hours: format(duration.hours()),
    minutes: format(duration.minutes()),
    seconds: format(duration.seconds()),
    isTimeUp: diff <= 0,
  };
};

export const getWeeksFromRange = ({ from, to }: DateRange): DateRange[] => {
  const fromDay = dayjs(from);
  const toDay = dayjs(to);

  let pointerDay = fromDay;
  const weeks: DateRange[] = [];

  while (pointerDay.isBefore(toDay)) {
    const newPointerDay = pointerDay.clone().add(7, 'day');

    const notFullWeek = toDay.isBefore(newPointerDay);

    weeks.push({
      from: pointerDay.toISOString(),
      to: notFullWeek ? toDay.toISOString() : newPointerDay.toISOString(),
    });

    pointerDay = newPointerDay;
  }

  return weeks;
};

export const convertDateWithCustomTimezone = ({
  date,
  customTimezoneOffsetInMinutes,
  convertionDirection = 'toCustomTimezome',
}: {
  date: Date;
  customTimezoneOffsetInMinutes: number;
  convertionDirection?: 'toCustomTimezome' | 'fromCustomTimezone';
}): Date => {
  const directionMultiplier = convertionDirection === 'toCustomTimezome' ? 1 : -1;
  const localTimezoneOffsetInMinutes = new Date().getTimezoneOffset() || 0;
  const missingTimezoneOffsetInMinutes = localTimezoneOffsetInMinutes - customTimezoneOffsetInMinutes;

  // If we are converting to custom timezone we need to subsract missing offset. If we are converting back, we need to add it instead
  const convertedDateInMs = new Date(date).getTime() - directionMultiplier * missingTimezoneOffsetInMinutes * 60 * 1000;

  return new Date(convertedDateInMs);
};

export const getRangeBetweenDatesInMs = (firstDate: Date, secondDate: Date): number => {
  return Math.abs(new Date(firstDate).getTime() - new Date(secondDate).getTime());
};
