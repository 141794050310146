import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { Lng, resources } from './resources';
import { isRealm } from '@/utils/realms';

export const changeLanguage = async (lng: Lng): Promise<void> => {
  await i18n.changeLanguage(lng);
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'clsDetector',
  lookup() {
    return isRealm('kompetens') ? Lng.sv : Lng.en;
  },
  cacheUserLanguage(_lng) {
    // use to persist users preferred language
  }
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      // https://github.com/i18next/i18next-browser-languageDetector#detector-options
      order: ['querystring', 'localStorage', 'clsDetector'],
      caches: ['localStorage', 'cookie']
    },
    resources: (resources as unknown) as Resource,
    fallbackLng: Lng.en,
    keySeparator: false, // allow '.' in translation keys
    nsSeparator: '::', // allow ':' in translation keys
    debug: window.REACT_APP_ENV === 'local',
    appendNamespaceToCIMode: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true
    }
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
