import { Me } from './types';
import { isRealm } from '@/utils/realms';

export const isUserAuthenticated = (me?: Me): boolean => !!me;

export const isUserOnboarded = (me?: Me): boolean => {
  const needsToAcceptExtraTerms = isRealm('kompetens') && !me?.acceptedExtraTerms;

  return !!me?.acceptedTos && !!me?.acceptedGdpr && !needsToAcceptExtraTerms;
};
