import { Modal } from '@/ui/Modal';
import { Form } from './Form';

export const FormContent = ({
  onSubmit,
  onModalClose,
  submitLoading,
}: {
  onSubmit: () => void;
  onModalClose: () => void;
  submitLoading?: boolean;
}): JSX.Element => (
  <>
    <Modal.Title>Add learning time</Modal.Title>
    <Modal.Contents>
      <Form onSubmit={onSubmit} />
    </Modal.Contents>
    <Modal.Actions>
      <Modal.Action action={onModalClose}>Cancel</Modal.Action>
      <Modal.Action primary action={onSubmit} disabled={submitLoading}>
        Add
      </Modal.Action>
    </Modal.Actions>
  </>
);
