// eslint-disable-next-line filenames/no-index
import about from './about.json';
import application from './application.json';
import analytics from './analytics.json';
import auth from './auth.json';
import autoTable from './autoTable.json';
import builderAdmin from './builderAdmin.json';
import catalog from './catalog.json';
import catalogAdmin from './catalogAdmin.json';
import categories from './categories.json';
import changelog from './changelog.json';
import common from './common.json';
import discussions from './discussions.json';
import fileUpload from './fileUpload.json';
import footer from './footer.json';
import groupsAdmin from './groupsAdmin.json';
import teams from './teams.json';
import home from './home.json';
import languages from './languages.json';
import learning from './learning.json';
import assignmentGroup from './assignmentGroup.json';
import license from './license.json';
import myLearning from './myLearning.json';
import navbar from './navbar.json';
import onboarding from './onboarding.json';
import ordersAdmin from './ordersAdmin.json';
import orderState from './orderState.json';
import profile from './profile.json';
import profileImageUpload from './profileImageUpload.json';
import productType from './productType.json';
import providers from './providers.json';
import search from './search.json';
import serverMessages from './serverMessages.json';
import subjects from './subjects.json';
import terms from './terms.json';
import time from './time.json';
import usersAdmin from './usersAdmin.json';
import userStatus from './userStatus.json';
import validation from './validation.json';
import variants from './variants.json';
import userSelfTrackedLearning from './userSelfTrackedLearning.json';
import managerDashboard from './managerDashboard.json';
import assignmentTemplate from './assignmentTemplate.json';
import uploaders from './uploaders.json';
import journey from './journey.json';
import journeyTemplate from './journeyTemplate.json';
import { I18nLanguage } from '../resources';

export const sv: I18nLanguage = {
  about,
  analytics,
  application,
  assignmentGroup,
  auth,
  autoTable,
  builderAdmin,
  catalog,
  catalogAdmin,
  categories,
  changelog,
  common,
  discussions,
  fileUpload,
  footer,
  groupsAdmin,
  teams,
  home,
  languages,
  learning,
  license,
  myLearning,
  navbar,
  onboarding,
  ordersAdmin,
  orderState,
  productType,
  profile,
  profileImageUpload,
  providers,
  search,
  serverMessages,
  subjects,
  terms,
  time,
  usersAdmin,
  userSelfTrackedLearning,
  userStatus,
  validation,
  variants,
  managerDashboard,
  assignmentTemplate,
  uploaders,
  journey,
  journeyTemplate,
};
