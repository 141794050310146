import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { factoryUseContext } from '@/utils/factoryUseContext';
import { WeeklyLearningLoop } from '../types';

const noop = () => {};

interface WeekNavigationContextProps {
  weeks: WeeklyLearningLoop[];
  setWeeks: Dispatch<SetStateAction<WeeklyLearningLoop[]>>;
  selectedWeek: WeeklyLearningLoop | null;
  setSelectedWeek: Dispatch<SetStateAction<WeeklyLearningLoop | null>>;
  shownWeeks: WeeklyLearningLoop[];
  setShownWeeks: Dispatch<SetStateAction<WeeklyLearningLoop[]>>;
  triggeredPagination: boolean;
  setTriggeredPagination: Dispatch<SetStateAction<boolean>>;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  maxVisibleWeeksCount: number;
  setMaxVisibleWeeksCount: Dispatch<SetStateAction<number>>;
  maxPaginationOffset: number;
  setMaxPaginationOffsett: Dispatch<SetStateAction<number>>;
}

const WeeksNavigationContext = createContext<WeekNavigationContextProps>({
  weeks: [],
  setWeeks: noop,
  selectedWeek: null,
  setSelectedWeek: noop,
  shownWeeks: [],
  setShownWeeks: noop,
  triggeredPagination: false,
  setTriggeredPagination: noop,
  offset: 0,
  setOffset: noop,
  maxVisibleWeeksCount: 0,
  setMaxVisibleWeeksCount: noop,
  maxPaginationOffset: 0,
  setMaxPaginationOffsett: noop,
});

export const useWeekNavigationsContext = factoryUseContext('WeeksNavigation', WeeksNavigationContext);

export function WeeksNavigationProvider({ children }: { children: ReactNode }): JSX.Element {
  const [weeks, setWeeks] = useState<WeeklyLearningLoop[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<WeeklyLearningLoop | null>(null);
  const [shownWeeks, setShownWeeks] = useState<WeeklyLearningLoop[]>([]);
  const [triggeredPagination, setTriggeredPagination] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [maxVisibleWeeksCount, setMaxVisibleWeeksCount] = useState<number>(0);
  const [maxPaginationOffset, setMaxPaginationOffsett] = useState<number>(0);

  useEffect(() => {
    setShownWeeks(weeks.slice(offset, maxVisibleWeeksCount + offset));
    setMaxPaginationOffsett(Math.max(weeks.length - maxVisibleWeeksCount, 0));
  }, [offset, weeks]);

  useEffect(() => {
    if (selectedWeek) {
      const fitsOffset = selectedWeek.weekNumber <= offset + maxVisibleWeeksCount;

      if (!fitsOffset) {
        const newOffset = Math.min(selectedWeek.weekNumber - 1, maxPaginationOffset);
        setOffset(newOffset);
      }
    }
  }, [selectedWeek, weeks]);

  const defaultContext: WeekNavigationContextProps = {
    weeks,
    setWeeks,
    selectedWeek,
    setSelectedWeek,
    shownWeeks,
    setShownWeeks,
    triggeredPagination,
    setTriggeredPagination,
    offset,
    setOffset,
    maxVisibleWeeksCount,
    setMaxVisibleWeeksCount,
    maxPaginationOffset,
    setMaxPaginationOffsett,
  };

  return <WeeksNavigationContext.Provider value={defaultContext}>{children}</WeeksNavigationContext.Provider>;
}
