import { Location } from '@/types/learning';
import { useLocalizedDate, LONG_DATE_FORMAT, SHORT_DATE_FORMAT } from '@/hooks/useLocalizedDate';

export const useFormattedEventDate = (location: Location | null): string | null => {
  // Sometimes we need to call this hook with no location data (can't call it conditionally
  // because of the rules of hooks), hence the empty location object:
  const { startDate, endDate } = location ?? { startDate: '', endDate: '' };

  const formattedStart = useLocalizedDate(startDate, LONG_DATE_FORMAT);
  const formattedEnd = useLocalizedDate(endDate, SHORT_DATE_FORMAT);

  if (!location) {
    return null;
  }

  if (formattedStart && formattedEnd) {
    return `${formattedStart} - ${formattedEnd}`;
  }

  if (formattedStart && !formattedEnd) {
    return formattedStart;
  }

  return null;
};
