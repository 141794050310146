import { useTranslation } from 'react-i18next';

import { AnalyticsCardType } from '../types/learning/card';

export const useProductTypeTranslations = (): {
  productType: (type: AnalyticsCardType, isPlural?: boolean) => string;
} => {
  const { t } = useTranslation('productType');
  return {
    productType: (type: AnalyticsCardType, isPlural?: boolean) => t(type, { count: isPlural ? 2 : 1 }),
  };
};

export const useOrderStateTranslations = (): { orderState: (stateName: string) => string } => {
  const { t } = useTranslation('orderState');
  return {
    orderState: (stateName: string) => t(stateName),
  };
};
