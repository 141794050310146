import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';

import { AssignmentsV3QueryResult } from '../types';
import { transformResults } from '../shared/utils';
import { UseAssignmentsHook } from './types';

export const ASSIGNMENTS_QUERY = gql`
  query assignmentsV3(
    $spacedIdsIN: [Int!]
    $searchPhrase: String
    $type: AssignmentV3Type
    $userIdIN: [Int!]
    $userTeamIdIN: [Int!]
    $status: AssignmentV3Status
    $sort: AssignmentsV3Sort
    $pagination: Pagination
  ) {
    assignmentsV3(
      filter: {
        spacedIdsIN: $spacedIdsIN
        searchPhrase: $searchPhrase
        type: $type
        userIdIN: $userIdIN
        userTeamIdIN: $userTeamIdIN
        status: $status
      }
      sort: $sort
      pagination: $pagination
    ) {
      data {
        id
        name
        type
        ... on AssignmentStrategicV3 {
          startAt
          endAt
          totalThresholdEffort
        }
        users(
          pagination: $pagination
          filter: { userTeamIdIN: $userTeamIdIN, userIdIN: $userIdIN, searchPhrase: $searchPhrase }
        ) {
          data {
            assignmentId
            userId
            user {
              id
            }
          }
          ... on AssignmentStandardV3UsersPage {
            data {
              ... on AssignmentStandardV3User {
                progress {
                  estimatedEffort
                  requiredItemsCompletedCount
                  requiredItemsCount
                  completed
                  participations {
                    progress
                  }
                }
              }
            }
          }
          ... on AssignmentStrategicV3UsersPage {
            data {
              ... on AssignmentStrategicV3User {
                progress {
                  completed
                  estimatedEffort
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useAssignments: UseAssignmentsHook = ({ userIds, teamsIds, spaces, searchPhrase, status, sort, type, skip }) => {
  const spacesIds = spaces?.map((space) => space.id) ?? null;

  const { data, loading, error, refetch } = useQuery<AssignmentsV3QueryResult>(ASSIGNMENTS_QUERY, {
    variables: {
      spacedIdsIN: spacesIds,
      searchPhrase: searchPhrase ?? null,
      userIdIN: userIds,
      userTeamIdIN: teamsIds ?? null,
      status: status ?? null,
      sort: sort ?? null,
      type: type ?? null,
      pagination: {
        limit: -1,
        page: 1,
      },
    },
    skip,
  });

  return {
    data: data?.assignmentsV3.data ? data?.assignmentsV3.data.map((x) => transformResults(x, userIds ?? [])) : [],
    loading,
    error,
    refetch,
  };
};
