import styled, { css } from 'styled-components';

export const AnswerOptionButton = styled.button<{ selected?: boolean }>`
  all: unset;
  display: flex;
  cursor: pointer;
  padding: 16px;
  height: 48px;
  align-items: center;
  ${({ theme }) => theme.typography.title4};
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline2};

  &:hover {
    ${({ selected }) =>
      !selected &&
      css`
        border: 2px solid ${({ theme }) => theme.colors.neutral.onBackground};
        padding: 15px;
        background: ${({ theme }) => theme.colors.states.hover5};
      `}
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
      padding: 15px;
      background: ${({ theme }) => theme.colors.states.hover7};
    `};

  &:disabled {
    border-color: ${({ theme }) => theme.colors.neutral.disabled};
    color: ${({ theme }) => theme.colors.neutral.disabled};
    pointer-events: none;
  }
`;
