import { gql, useQuery, ApolloQueryResult } from '@apollo/client';

import { LearningCollection } from '../types/learning/learning-catalog';
import { LearningVisibility } from '../types/learning';
import { courseCardBase, learningCardExtras, productCardExtras } from './fragments';
import { HookResult } from './apolloClient';

export interface CollectionInput {
  id?: number;
  spaceId: number;
  visibility: LearningVisibility;
  imageUrl: string;
  languageId: number;
  title: string;
  subjectId: number;
  teaser: string;
  contentOwner?: number;
  creator?: string;
}

export interface CollectionFormType {
  id?: number;
  spaceId: string;
  visibility: LearningVisibility;
  imageUrl: string;
  languageId: string;
  title: string;
  subjectId: string;
  teaser: string;
  contentOwner?: number;
  creator?: string;
}

export interface CollectionInputItem {
  productId?: number;
  learningId?: number;
  order: number;
  visibility: LearningVisibility;
}

export const LEARNING_COLLECTION_FRAGMENT = gql`
  fragment LearningCollectionFragment on LearningCollection {
    id
    spaceId
    title
    teaser
    imageUrl
    categoryId
    subjectId
    type
    canEdit
    visibility
    status
    languageId
    contentOwner
    created
    creator
    creatorEmail
    updated
    updatedByEmail
  }
`;

export const COLLECTION_ITEM_FRAGMENT = gql`
  fragment CollectionItemFragment on CollectionItemShort {
    productId
    learningId
    order
    visibility
  }
`;

export const getLearningCollectionQuery = gql`
  query learningCollection($id: Int!) {
    learningCollection(id: $id) {
      ...LearningCollectionFragment
      items {
        ${courseCardBase}
        ${learningCardExtras}
        ${productCardExtras}
      }
    }
  }
  ${LEARNING_COLLECTION_FRAGMENT}
`;

interface LearningCollectionResponse extends HookResult {
  learningCollection: Readonly<LearningCollection> | null;
  refetch: (variables?: { id?: number }) => Promise<ApolloQueryResult<{ learningCollection: LearningCollection }>>;
}

export const useLearningCollection = (id: number): LearningCollectionResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery<{ learningCollection: Readonly<LearningCollection> }>(
    getLearningCollectionQuery,
    {
      skip: !id,
      variables: { id }
    }
  );
  const learningCollection = (data && data.learningCollection) || null;

  return {
    learningCollection,
    error,
    refetch,
    networkStatus,
    loading
  };
};
