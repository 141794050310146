import React, { useEffect, useState, createContext } from 'react';

import { factoryUseContext } from '@/utils/factoryUseContext';
import { Me, useCurrentUser } from './currentUser';
import { useLanguage } from '@/hooks/useLanguage';
import { FeatureToggles, useRealmFeatureToggles, useUserFeatureToggles } from '@/store/feature-toggles';
import { useRealmConfig, RealmConfig } from '@/store/realms';
import { REALM } from '@/types/realm';

interface ApplicationState {
  isAuthenticated: boolean;
  currentUser?: Me;
  acceptedPrivacy: boolean;
  acceptedTos: boolean;
  acceptedExtraTerms: boolean;
  loading: boolean;
  realmConfig?: RealmConfig;
  featureToggles: FeatureToggles;
}

const InitialState: ApplicationState = {
  loading: true,
  isAuthenticated: false,
  currentUser: undefined,
  acceptedPrivacy: false,
  acceptedTos: false,
  acceptedExtraTerms: false,
  realmConfig: {},
  featureToggles: [],
};

interface Props {
  children: JSX.Element;
}

const RootContext = createContext(InitialState);

export const useRootContext = factoryUseContext('Root', RootContext);

export function RootContextProvider({ children }: Props): JSX.Element {
  const { user, loadingFirstTime: loadingUserFirstTime, error: errorUser } = useCurrentUser();
  // for users accidentally using foreign realm, use native realm for fetching feature toggles
  const realm = user?.realm && user?.realm !== REALM.CLS ? user.realm : window.realm;

  const { data: featureTogglesRealm, loading: realmLoading } = useRealmFeatureToggles(realm, !!user);
  const { data: featureTogglesUser, loading: userLoading } = useUserFeatureToggles(realm, !!user);
  const { realmConfig, loading: realmConfigLoading } = useRealmConfig();
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(user));
  const [currentUser, setCurrentUser] = useState(user);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(Boolean(user?.acceptedGdpr));
  const [acceptedTos, setAcceptedTos] = useState(Boolean(user?.acceptedTos));
  const [acceptedExtraTerms, setAcceptedExtraTerms] = useState(Boolean(user?.acceptedExtraTerms));
  const [loading, setLoading] = useState(true);
  useLanguage(currentUser);

  useEffect(() => {
    setIsAuthenticated(Boolean(user));
    setCurrentUser(user);
    setAcceptedPrivacy(Boolean(user?.acceptedGdpr));
    setAcceptedTos(Boolean(user?.acceptedTos));
    setAcceptedExtraTerms(Boolean(user?.acceptedExtraTerms));
    setLoading((loadingUserFirstTime && !errorUser) || realmLoading || userLoading || realmConfigLoading);
  }, [user, featureTogglesRealm, featureTogglesUser, realmConfig, realmConfigLoading, loadingUserFirstTime]);

  const defaultContext = {
    loading,
    isAuthenticated,
    currentUser,
    acceptedPrivacy,
    acceptedTos,
    acceptedExtraTerms,
    realmConfig,
    featureToggles: featureTogglesUser && featureTogglesUser.length ? featureTogglesUser : featureTogglesRealm,
  };

  return <RootContext.Provider value={defaultContext}>{children}</RootContext.Provider>;
}
