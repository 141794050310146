import gql from 'graphql-tag';
import { QueryResult, useQuery } from '@apollo/client';

import { UsersPool, ParticipationGroup } from '@/types/analytics';

interface QueryParticipationsInput {
  userIdEq?: number;
  objectIdIn?: string[];
  objectTypeIn?: string[];
  objectTypeNin?: string[];
  isCompletedEq?: boolean;
  includeCatalogData?: boolean;
  usersPool?: UsersPool;
  orderBy?: string;
  from?: string;
  to?: string;
}

const queryParticipations = gql`
  query getParticipations($input: ParticipationsInput!) {
    participations: getParticipations(input: $input) {
      id
      userId
      objectId
      catalogObjectId
      registration
      progress
      isCompleted
      userEffort
      completedAt
      expiredAt
      platform
      objectName
      objectType
      plannedDuration
      firstStatement
      lastStatement
      firstActivity
      lastActivity
      previousParticipations {
        id
        userId
        objectId
        catalogObjectId
        registration
        progress
        isCompleted
        userEffort
        completedAt
        expiredAt
        platform
        objectName
        objectType
        plannedDuration
        firstStatement
        lastStatement
        firstActivity
        lastActivity
      }
    }
  }
`;

export function useQueryParticipations(
  input: QueryParticipationsInput,
  skip?: boolean
): QueryResult<{ participations: ParticipationGroup[] }> {
  return useQuery<{ participations: ParticipationGroup[] }>(queryParticipations, {
    skip: skip,
    variables: {
      input: input,
    },
  });
}
