import { useCurrentUserAccessHook } from '@/store/currentUser/types';
import { useCurrentUser } from './useCurrentUser';

export const useCurrentUserAccess: useCurrentUserAccessHook = () => {
  const { user, loading } = useCurrentUser();

  return {
    isManager: user?.permissions?.hasViewAccessToManagerDashboard ?? false,
    isAdmin: user?.permissions?.hasViewAccessToAdminPanel ?? false,
    isSuperAdmin: user?.permissions?.hasWriteAccessToInternals ?? false,
    loading
  };
};
