import styled, { css } from 'styled-components';

import { Container } from '../../atoms';

export const CountdownContainer = styled(Container)<{ loopsStarted: boolean }>`
  position: absolute;
  opacity: 1;
  transition: all 0.5s;
  ${({ loopsStarted }) =>
    loopsStarted &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const RootStyled = styled.div`
  position: relative;
`;

export const LoopsContainer = styled(Container)<{ loopsStarted: boolean }>`
  opacity: 1;
  transition: all 0.5s;
  ${({ loopsStarted }) =>
    !loopsStarted &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const ActionsContainerStyled = styled.div`
  position: relative;
`;

export const CanReflectDotStyled = styled.div`
  height: 6px;
  width: 6px;
  background: ${({ theme }) => theme.colors.error.outline};
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.colors.neutral.background};
  position: absolute;
  right: -3px;
  top: -5px;
  box-sizing: content-box;
`;
