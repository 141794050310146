import { useEffect } from 'react';

import { calculateFilteredMyLearnings } from '@/pages/user/MyLearning/calculateFilteredMyLearnings';
import { useQueryParticipations } from '@/store/analytics/participations';
import { PROGRESS_CATALOG_OPTIONS, useCardCatalog } from '@/store/catalog';
import { inventoryToCourseCards, useInventory } from '@/store/inventory';
import { useRootContext } from '@/store/RootContext';
import { UsersPool } from '@/types/analytics';
import { UseAssignmentPageHook } from './types';
import { getAssignmentPage } from './getAssignmentPage';

export const useAssignmentPage: UseAssignmentPageHook = ({ assignment, refetchInventory }) => {
  const { inventory, loading: loadingInventory, error: inventoryError, refetch } = useInventory();
  const { catalog, loading: loadingCatalog, error: catalogError } = useCardCatalog(PROGRESS_CATALOG_OPTIONS);
  const { currentUser } = useRootContext();

  useEffect(() => {
    refetchInventory && refetch();
  }, [refetchInventory]);

  const requiredDataLoading = !currentUser?.id || loadingInventory || loadingCatalog;
  const {
    data,
    loading: loadingParticipations,
    error: participationsError,
  } = useQueryParticipations(
    {
      userIdEq: currentUser?.id,
      includeCatalogData: true,
      usersPool: UsersPool.REALM,
    },
    requiredDataLoading && !!assignment?.learnings
  );

  const loading = loadingInventory || loadingCatalog || loadingParticipations;
  const error = inventoryError || catalogError || participationsError;

  if (loading || error || !assignment?.learnings) {
    return { loading, error };
  }

  const { filtered } = calculateFilteredMyLearnings({ catalogCards: catalog.cards, inventory });

  const myLearningCatalogCards = filtered.ongoing.catalog.concat(filtered.completed.catalog);
  const inventoryCards = inventoryToCourseCards(filtered.ongoing.inventory, () => {}, false, status === 'ongoing', false);

  return {
    loading,
    error,
    card: getAssignmentPage({
      myLearningCatalogCards,
      inventoryCards,
      assignment,
      catalogCards: catalog.cards,
      participations: data?.participations,
      eventEnrollments: inventory.eventEnrollments,
    }),
  };
};
