/* eslint-disable import/no-unused-modules */
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Select } from '@/ui/Select';
import { PageVariantDeprecated as PageVariant } from '@/administration/pages/Course/store/page/types';

export const AddPageButton = ({ onAddClick }: { onAddClick: (type: PageVariant) => void }): JSX.Element => {
  const { t } = useTranslation('catalogAdmin');
  return (
    <Select
      value=""
      onChange={(type) => {
        onAddClick(type as PageVariant);
      }}
    >
      <Select.Header>
        {({ toggleShowOptions }) => (
          <Button variant="neutral" onClick={toggleShowOptions}>
            <Icon icon="plus" size="lg" />
          </Button>
        )}
      </Select.Header>
      <Select.List>
        <Select.Option value={PageVariant.TEXT} prefix={<Icon icon={['far', 'text']} />}>
          {t('page')}
        </Select.Option>
        <Select.Option value={PageVariant.REFLECTION} prefix={<Icon icon={['far', 'comment-pen']} />}>
          Reflection
        </Select.Option>
      </Select.List>
    </Select>
  );
};
