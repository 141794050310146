import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCardCatalog } from '@/store/catalog';
import { Hero, Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { Header1 } from '@/components/Typography/Typography';
import { CARDFILTER_PAGETYPE } from '@/components/CardGrid/CardFilter';
import { Button } from '@/components/Button/Button';
import { useFilterPastEvents } from '@/hooks/useFilterPastEvents';
import { sortingMethods } from '@/util';

export const EventsPage = (): JSX.Element => {
  const { t } = useTranslation('catalog');
  const navigate = useNavigate();
  const { catalog, loading } = useCardCatalog({ onlyEvents: true });

  const cards = useFilterPastEvents(catalog.cards);

  return (
    <>
      <Hero paddedBottom>
        <Button onClick={() => navigate(-1)} $type="text" $iconPosition="left" $icon="long-arrow-alt-left">
          {t('Back', { ns: 'common' })}
        </Button>
        <Header1 unpadded>{t('Events')}</Header1>
      </Hero>
      <Section>
        <CardGrid
          pageType={CARDFILTER_PAGETYPE.EVENTS}
          cards={cards}
          sortFunction={sortingMethods.DATE_ASC}
          loading={loading}
          filters={['title', 'meta', 'taxonomy', 'attributes']}
        />
      </Section>
    </>
  );
};
