/* eslint-disable import/no-unused-modules */

import { nanoid, TElement, insertNodes } from '@udecode/plate-common';
import { ParagraphPlugin, findNodePath, focusEditor, PlateEditor, useEditorRef, useElement } from '@udecode/plate-common/react';
import { BlockSelectionPlugin } from '@udecode/plate-selection/react';
import { Path } from 'slate';
import styled from 'styled-components';

import { Icon } from '@/ui/Icon';
import { OVERRIDE_PLUGINS } from '@/component/customEditorV2/useCreateEditor';
import { IconButton } from '@/ui/Button';

const HandleStyled = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

export const DraggableInsertHandle = (): JSX.Element => {
  const editor = useEditorRef();
  const element = useElement();

  return (
    <HandleStyled
      buttonVariant="buddySmall"
      icon="plus"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();

        const at = findNodePath(editor, element);
        triggerComboboxNextBlock(editor, '/', at, event.altKey);
      }}
      onMouseDown={() => {
        focusEditor(editor);
        editor.getApi(BlockSelectionPlugin).blockSelection?.resetSelectedIds();
      }}
      tabIndex={-1}
    >
      <Icon icon={'plus'} />
    </HandleStyled>
  );
};

const triggerComboboxNextBlock = (editor: PlateEditor, triggerText: string, at?: Path, insertAbove = false) => {
  const emptyBlock = {
    id: nanoid(),
    children: [{ text: '' }],
    type: OVERRIDE_PLUGINS[ParagraphPlugin.key as keyof typeof OVERRIDE_PLUGINS]?.node?.type,
  };

  let _at: Path | undefined;

  if (at) {
    const slicedPath = at.slice(0, 1);
    _at = insertAbove ? slicedPath : Path.next(slicedPath);
  }

  insertNodes<TElement>(editor, emptyBlock, {
    at: _at,
    select: true,
  });
  editor.insertText(triggerText);
};
