import styled from 'styled-components';

import { UploadedFileVideo } from '@/component/FileUpload/types';

type VideoSerializableProps = {
  width: number;
  uploadedVideo?: UploadedFileVideo;
  videoLink?: { embedUrl: string };
};

const RootStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
`;

const IFrameContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`;

const CustomSizeContainerStyled = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const VideoSerializable = ({ width, uploadedVideo, videoLink }: VideoSerializableProps): JSX.Element => {
  const vimeoSrc = `https://player.vimeo.com/video/${uploadedVideo?.vimeoId}?speed=true`;

  return (
    <RootStyled>
      <CustomSizeContainerStyled width={width}>
        <IFrameContainer>
          <iframe
            src={videoLink?.embedUrl || vimeoSrc}
            frameBorder="0"
            allowFullScreen
            title="Video"
            style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
          />
        </IFrameContainer>
      </CustomSizeContainerStyled>
    </RootStyled>
  );
};
