import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCardCatalog } from '@/store/catalog';
import { Hero, Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { Header1 } from '@/components/Typography/Typography';
import { CARDFILTER_PAGETYPE } from '@/components/CardGrid/CardFilter';
import { UpcomingEvents } from './components/UpcomingEvents';
import { useFilterPastEvents } from '@/hooks/useFilterPastEvents';

export const CatalogPage = (): JSX.Element => {
  const { t } = useTranslation('catalog');
  const { catalog, loading } = useCardCatalog();

  const cards = useFilterPastEvents(catalog.cards);

  return (
    <>
      <Hero paddedBottom>
        <Header1 unpadded>{t('All learning items')}</Header1>
      </Hero>
      <UpcomingEvents cards={cards} loading={loading} />
      <Section>
        <CardGrid
          pageType={CARDFILTER_PAGETYPE.ALL}
          cards={cards}
          loading={loading}
          filters={['title', 'meta', 'taxonomy', 'attributes']}
        />
      </Section>
    </>
  );
};
