import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  justify-content: center;
  align-items: center;
`;

export const RootStyled = styled.div`
  position: relative;
`;

export const LoopsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.5s;
  ${(p) => `gap: ${p.theme.gutter.large}px;`}
  ${(p) => `padding: ${p.theme.gutter.small}px ${p.theme.gutter.small}px ${p.theme.gutter.xlarge}px ${p.theme.gutter.small}px;  
  `}
`;

export const Divider = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.neutral.container};
  width: 100%;
`;

export const NavigationsContainer = styled.div`
  ${(p) => `padding: ${p.theme.gutter.small}px`};
`;
