import { gql, MutationFunction, MutationResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { HookResult } from '../../../../store/apolloClient';

export const getXAPISession = gql`
  query getXAPISession($learningId: Int!) {
    getXAPISession(learningId: $learningId) {
      queryString
      registration
    }
  }
`;

export const registerToLearningMutation = gql`
  mutation registerToLearning($learningId: Int!) {
    registerToLearning(learningId: $learningId) {
      queryString
      registration
    }
  }
`;

const isLearningCompletedQueryString = gql`
  query isLearningCompleted($learningId: Int!) {
    isLearningCompleted(learningId: $learningId)
  }
`;

export interface XAPISession {
  queryString: string;
  registration: string;
}

interface CompletedRegistrationResponse extends HookResult {
  registrationCompleted: boolean;
}

export const useRegistrationCompleted = (learningId: number, skip = false): CompletedRegistrationResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery<{ isLearningCompleted: string }>(
    isLearningCompletedQueryString,
    {
      skip,
      variables: {
        learningId,
      },
      fetchPolicy: 'network-only',
    }
  );

  return {
    registrationCompleted: !!data?.isLearningCompleted,
    error,
    refetch,
    networkStatus,
    loading,
  };
};

export const useMutationRegister = (
  learningId: number
): [MutationFunction<{ registerToLearning: XAPISession }>, MutationResult<{ registerToLearning: XAPISession }>] => {
  const [send, obj] = useMutation(registerToLearningMutation, {
    refetchQueries: (mutationResult) => {
      // Dont refetch if mutation was unsuccessful
      if (mutationResult?.errors) return [];
      return [
        { query: getXAPISession, variables: { learningId } },
        { query: isLearningCompletedQueryString, variables: { learningId } },
      ];
    },
    awaitRefetchQueries: true,
  });

  return [(opts) => send({ ...opts, variables: { learningId } }), obj];
};

/**
 * useELearningSesssion will get a session query string for the users latest registration on an E-Learning.
 * If there's no existing registration, it will attempt to create a new one.
 * @param learningId
 */
export const useELearningSession = (learningId: number): [string, { loading: boolean; refetch: () => void; error?: Error }] => {
  const [session, setSession] = useState<XAPISession>();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [register] = useMutationRegister(learningId);
  const [getLatestSession] = useLazyQuery<{ getXAPISession: XAPISession }>(getXAPISession, { fetchPolicy: 'network-only' });

  const getSession = async () => {
    if (!learningId) return;

    setIsLoading(true);
    try {
      const latestResult = await getLatestSession({ variables: { learningId } });
      if (
        latestResult.data?.getXAPISession.registration &&
        latestResult.data.getXAPISession.registration !== session?.registration
      ) {
        setSession(latestResult.data.getXAPISession);
      } else if (!session) {
        const registrationResult = await register();
        if (registrationResult.data?.registerToLearning.registration) {
          setSession(registrationResult.data.registerToLearning);
        }
      }
    } catch (err) {
      err instanceof Error && setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  return [session?.queryString || '', { loading, refetch: getSession, error }];
};
