/* eslint-disable import/no-unused-modules */
import { ReactNode, useEffect, useRef } from 'react';

import { ViewNavbar } from '@/administration/pages/Course/MainLayout/variants/View/atoms/ViewNavbarV2';
import { MultiPageBody } from '@/administration/pages/Course/MainLayout/variants/View/atoms/PageV2';
import { LayoutV2 } from '@/administration/pages/Course/MainLayout/variants/View/atoms/LayoutV2';
import { ViewDrawerV2 } from '@/administration/pages/Course/MainLayout/variants/View/atoms/ViewDrawerV2';
import { makeNamespacedComponent } from '@/ui/utils';
import { ViewItemV2Props } from '@/administration/pages/Course/MainLayout/variants/View/atoms/ViewItemV2/ViewItemV2';

type ViewLayoutV2Props = {
  title: string;
  content: ReactNode[];
  drawerItems: ViewItemV2Props[];
  onShowHideDrawerClick: () => void;
  onAddFavoritesClick?: () => void;
  onCloseClick: () => void;
  onUpNavigationClick: () => void;
  onDownNavigationClick: () => void;
  onNewActivityClick: (activityNumber: number) => void;
  isSavedToMyLearning?: boolean;
  isDrawerOpen: boolean;
  progress?: {
    prevValue: number;
    value: number;
    maxValue?: number;
  };
  activityActiveCount: number;
  activitiesTotalCount: number;
};

export const _ViewLayoutV2 = ({
  title,
  content,
  drawerItems,
  onShowHideDrawerClick,
  onAddFavoritesClick,
  onCloseClick,
  onUpNavigationClick,
  onDownNavigationClick,
  onNewActivityClick,
  isSavedToMyLearning: isSavedToMyLearning,
  isDrawerOpen,
  progress,
  activityActiveCount,
  activitiesTotalCount,
}: ViewLayoutV2Props): JSX.Element => {
  const activeContentContainerRef = useRef<HTMLDivElement>(null);
  const activeContent = content.find(
    (content) => (content as { props: { activity: { active: boolean } } })?.props?.activity?.active
  );

  useEffect(() => {
    if (activeContentContainerRef.current) {
      activeContentContainerRef.current.scrollTo(0, 0);
    }
  }, [activeContentContainerRef.current, activityActiveCount]);

  return (
    <LayoutV2
      navbar={
        <ViewNavbar
          title={title}
          onShowHideDrawerClick={onShowHideDrawerClick}
          onSaveFavoritesClick={onAddFavoritesClick}
          onCloseClick={onCloseClick}
          isSavedToMyLearning={isSavedToMyLearning}
        />
      }
      progress={progress || undefined}
      content={
        activeContent ? (
          <MultiPageBody
            ref={activeContentContainerRef}
            backDisabled={activityActiveCount === 0}
            nextHidden={activityActiveCount === activitiesTotalCount - 1}
            onBackClick={onUpNavigationClick}
            onNextClick={onDownNavigationClick}
          >
            {activeContent}
          </MultiPageBody>
        ) : null
      }
      drawer={<ViewDrawerV2 items={drawerItems} />}
      isDrawerOpen={isDrawerOpen}
      contentScroll={{
        page: activityActiveCount + 1,
        maxPage: activitiesTotalCount,
        onBackPage: onUpNavigationClick,
        onNextPage: onDownNavigationClick,
        onUpdatePage: onNewActivityClick,
      }}
    />
  );
};

const LoadingState = (): JSX.Element => <LayoutV2.LoadingState />;

export const ViewLayoutV2 = makeNamespacedComponent(_ViewLayoutV2, {
  LoadingState,
});
