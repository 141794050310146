import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/types/learning/card';
import { Location } from '@/types/learning';
import { useLocalizedDate, LONG_DATE_FORMAT, SHORT_DATE_FORMAT } from '@/hooks/useLocalizedDate';
import { firstLocation } from '@/util';
import { StyledEventBody } from '@/components/CourseCardLegacy/styles/CourseCardEvent';
import { replaceNbsps } from '@/utils/text';
import { IconPropType } from '@/ui/Icon';
import { MetaV2 } from '@/components/Meta/MetaV2';
import { fontStyles } from '@/components/Typography/v2/font-styles';
import { StyledEventBoxV2, StyledEventFooterV2, StyledEventWrapperV2 } from '../../styles/v2/CourseCardEvent';
import { TagWithIcon } from '@/components/Tag/v2/TagWithIcon';
import { Caption, Overline, Title4 } from '@/components/Typography/v2';
import { Participation } from '@/types/analytics';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

type CourseCardEventPropsV2 = {
  card: Card & { locations?: Location[] };
  participation?: Participation;
};
export type EventFooterStylingProps = {
  fontStyle?: keyof typeof fontStyles;
};

export const CourseCardEventV2 = ({
  card: { teaser, title, type, locations },
  participation,
}: CourseCardEventPropsV2): JSX.Element => {
  const { t } = useTranslation('catalog');
  const isCardDescrtionHidden = useFeatureEnabled(FEATURE.UI_CARD_DESCRIPTION_HIDDEN);

  const { startDate, endDate, address } = firstLocation(locations);

  const formattedStart = useLocalizedDate(startDate, LONG_DATE_FORMAT);
  const formattedEnd = useLocalizedDate(endDate, SHORT_DATE_FORMAT);

  const calendarIcon: IconPropType = ['far', 'calendar'];
  const locationIcon: IconPropType = ['far', 'location-dot'];

  const getFormattedDateString = () => {
    if (!formattedEnd) return formattedStart;
    return `${formattedStart} - ${formattedEnd}`;
  };

  return (
    <StyledEventWrapperV2>
      <StyledEventBoxV2>
        <Overline color="#6F797A">{t(type, { ns: 'productType' })}</Overline>
        <Title4>{replaceNbsps(title)}</Title4>
        <MetaV2
          caption={getFormattedDateString() || ''}
          icon={calendarIcon}
          iconStyle={{ color: '#3F494A', marginRight: '2px' }}
          isEventWrapper
        />
        {type === 'seminar' && !!address && (
          <MetaV2 caption={address} icon={locationIcon} iconStyle={{ color: '#3F494A', marginRight: '3px' }} isEventWrapper />
        )}
      </StyledEventBoxV2>
      <StyledEventBody>{!isCardDescrtionHidden && <Caption>{teaser}</Caption>}</StyledEventBody>
      {!participation?.isCompleted && (
        <StyledEventFooterV2>
          <TagWithIcon icon={['far', 'calendar-star']} bgColor={'#B9FCFF'}>
            {t('upcoming')}
          </TagWithIcon>
        </StyledEventFooterV2>
      )}
    </StyledEventWrapperV2>
  );
};
