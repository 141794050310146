import styled from 'styled-components';

export const MenuListOptionContainer = styled.div`
  z-index: 100;
  color: ${(p) => p.theme.tones.neutral[10]};
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.neutral.background};
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: ${(p) => p.theme.colors.states.hover1};
  }
`;

export const MenuListOptionLabel = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  gap: 8px;
  display: flex;
  overflow: hidden;
  max-height: 20px;
  box-sizing: content-box;
  white-space: nowrap;
  align-items: center;
  text-overflow: ellipsis;
`;
