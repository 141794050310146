/* eslint-disable import/no-unused-modules */
import styled, { css } from 'styled-components';

import { HeroDescription } from '@/pages/Assignment/components';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/ButtonGroup';

export const Title = styled.span`
  ${(p) => p.theme.typography.title2}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
`;

export const MetaButtonsStyled = styled(ButtonGroup)`
  padding-bottom: 8px;
`;

export const Description = styled(HeroDescription)<{ expanded: boolean; fullHeight?: number; collapseHeight?: number }>`
  overflow: hidden;
  position: relative;

  transition: max-height 0.3s ease-out;

  ${({ expanded, fullHeight, collapseHeight: customCollapseHeight = 280 }) => {
    const collapseHeight = customCollapseHeight;

    return expanded
      ? css`
          max-height: ${fullHeight || collapseHeight}px;
        `
      : css`
          max-height: ${collapseHeight}px;

          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%);
            width: 100%;
            height: 100px;
          }
        `;
  }}
`;

export const ExpandDescriptionButton = styled(Button).attrs({ variant: 'ghost' })`
  margin-left: auto;
`;

export const DescriptionWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ActionsStyled = styled.div`
  display: flex;
  gap: 8px;
`;
