import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { LearningJourneyItem } from './journey';
import { useLearningJourney } from './useLearningJourney';
import { useParentJourney } from './useParentJourneyId';

export const useJourneyEnhancedNavigation = ({
  id,
  isProduct,
}: {
  id: number;
  isProduct?: boolean;
}): {
  currentItem: LearningJourneyItem | null;
  nextItem: LearningJourneyItem | null;
  journeyId?: number;
  journeyTitle?: string;
} => {
  const enhancedNavigationEnabled = useFeatureEnabled(FEATURE.UI_LEARNING_JOURNEYS_UP_ENHANCE_NAVIGATION);

  const parentJourney = useParentJourney({ id, isProduct });

  const { items } = useLearningJourney({
    id: Number(parentJourney?.journeyId),
    include: {
      items: true,
    },
    skip: !parentJourney?.journeyId || !enhancedNavigationEnabled,
  });

  const targetItem = items?.find((item) => item.contentId === parentJourney?.currentLearningObjectId) || null;
  const targetItemOrder = targetItem?.order;
  const nextItem = (targetItemOrder !== undefined && items?.find((item) => item.order === targetItemOrder + 1)) || null;

  return {
    journeyId: parentJourney?.journeyId,
    journeyTitle: parentJourney?.journeyTitle,
    nextItem,
    currentItem: targetItem,
  };
};
