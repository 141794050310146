import React from 'react';
import { useTranslation } from 'react-i18next';

import { Hero, Section } from '@/components/Section/Section';
import { Header1 } from '@/components/Typography/Typography';
import { ProvidersList } from './components/ProvidersList';
import { useCardCatalog } from '@/store/catalog';
import { LoadingHero } from '@/component/LoadingHero';

export const AllProvidersPage = (): JSX.Element => {
  const { t } = useTranslation('providers');
  const { catalog, loading } = useCardCatalog();

  if (loading) return <LoadingHero />;

  return (
    <>
      <Hero paddedBottom>
        <Header1 unpadded>{t('All providers')}</Header1>
      </Hero>
      <Section>
        <ProvidersList catalog={catalog} />
      </Section>
    </>
  );
};
