import { useAssignments } from './useAssignments';
import { AssignmentCard, UseAssignmentCardsResult, UseAssignmentParams } from './types';
import { AssignmentV3 } from '../types';
import { ASSIGNMENT_STATUS } from '../constants';
import { AssignmentProgressStatus } from '../shared/types';

export const useAssignmentCards = ({ userId, spaces, skip }: UseAssignmentParams): UseAssignmentCardsResult => {
  const {
    loading: assignmentsLoading,
    data,
    error: assignmentsError,
  } = useAssignments({
    userIds: [userId],
    spaces,
    status: ASSIGNMENT_STATUS.published,
    includeUsers: true,
    skip,
  });

  const strategicCards =
    data
      ?.filter(
        (x): x is AssignmentV3<'STRATEGIC'> & { progressStatus: AssignmentProgressStatus; estimatedEffort?: number } =>
          x.type === 'STRATEGIC'
      )
      .map((x): AssignmentCard<'STRATEGIC'> => {
        return {
          id: x.id,
          name: x.name,
          startDate: x.startAt,
          endDate: x.endAt,
          status: x.progressStatus,
          effortThreshold: x.totalThresholdEffort,
          estimatedEffort: x.estimatedEffort ?? 0,
          type: 'STRATEGIC',
        };
      })
      .filter((x): x is AssignmentCard<'STRATEGIC'> => !!x) ?? [];

  const standardCards =
    data
      ?.filter((x): x is AssignmentV3<'STANDARD'> & { progressStatus: AssignmentProgressStatus } => x.type === 'STANDARD')
      .map((x): AssignmentCard<'STANDARD'> => {
        const progress = x?.users?.data?.find((x) => x.userId === userId)?.progress;
        return {
          id: x.id,
          name: x.name,
          learningItemsCount: progress?.requiredItemsCount ?? 0,
          learningItemsCompleted: progress?.requiredItemsCompletedCount ?? 0,
          status: x.progressStatus,
          type: 'STANDARD',
        };
      })
      .filter((x): x is AssignmentCard<'STANDARD'> => !!x) ?? [];

  const tempCards: AssignmentCard[] = [];
  const cards = tempCards.concat(standardCards, strategicCards);

  return {
    loading: assignmentsLoading,
    error: assignmentsError,
    cards,
  };
};
