const size = {
  mobile: '479px',
  mobileLandscape: '480px',
  tablet: '768px',
  desktop: '991px',
  desktopLarge: '1280px',
  widescreen: '1440px',
  fullHD: '1920px',
};

const margins = { sm: '16', md: '32', lg: '44', xl: '84', auto: 'auto' };

const gutter = { sm: '16', md: '24' };

export const columns = { sm: 4, md: 8, lg: 12 };

export const device = {
  mobile: {
    screenSize: `(max-width: ${size.mobile})`,
    margin: margins.sm,
    columns: `repeat(${columns.sm}, 1fr)`,
    gutter: gutter.sm,
  },
  mobileLandscape: {
    screenSize: `(min-width: ${size.mobileLandscape})`,
    margin: margins.md,
    columns: `repeat(${columns.md}, 1fr)`,
    gutter: gutter.sm,
  },
  tablet: {
    screenSize: `(min-width: ${size.tablet})`,
    margin: margins.md,
    columns: `repeat(${columns.md}, 1fr)`,
    gutter: gutter.sm,
  },
  desktop: {
    screenSize: `(min-width: ${size.desktop})`,
    margin: margins.md,
    columns: `repeat(${columns.lg}, 1fr)`,
    gutter: gutter.md,
  },
  desktopLarge: {
    screenSize: `(min-width: ${size.desktopLarge})`,
    margin: margins.lg,
    columns: `repeat(${columns.lg}, 1fr)`,
    gutter: gutter.md,
  },

  widescreen: {
    screenSize: `(min-width: ${size.widescreen})`,
    margin: margins.xl,
    columns: `repeat(${columns.lg}, 1fr)`,
    gutter: gutter.md,
  },
  fullHD: {
    screenSize: `(min-width: ${size.fullHD})`,
    margin: margins.auto,
    columns: `repeat(${columns.lg}, 1fr)`,
    gutter: gutter.md,
    body: '1752',
  },
};
