import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-spacing: 0;
  background-image: none;
  border-radius: 4px;
  border-spacing: 0;
  border-collapse: separate;

  & > thead:first-of-type > tr:first-of-type > th:first-of-type {
    border-top-left-radius: 4px;
  }

  & > thead:first-of-type > tr:first-of-type > th:last-of-type {
    border-top-right-radius: 4px;
  }

  & > tbody:last-child > tr:last-of-type > td:first-of-type {
    border-bottom-left-radius: 4px;
  }

  & > tbody:last-child > tr:last-of-type > td:last-of-type {
    border-bottom-right-radius: 4px;
  }

  & > tfoot:last-of-type > tr:first-of-type > td:first-of-type {
    border-bottom-left-radius: 4px;
  }

  & > tfoot:last-of-type > tr:first-of-type > td:last-of-type {
    border-bottom-right-radius: 4px;
  }

  & > & > thead > tr > th:first-of-type,
  & > tbody > tr > td:first-of-type,
  & > tfoot:last-of-type > tr > td:first-of-type {
    border-left: 1px solid ${(p) => p.theme.colors.neutral.outline1};
  }

  & > thead > tr > th:last-of-type,
  & > tbody > tr > td:last-of-type,
  & > tfoot:last-of-type > tr > td:last-of-type {
    border-right: 1px solid ${(p) => p.theme.colors.neutral.outline1};
  }

  & > tbody > tr:last-of-type > td,
  & > tfoot > tr:last-of-type > td {
    border-bottom: 1px solid ${(p) => p.theme.colors.neutral.outline1};
  }
`;
