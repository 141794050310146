import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@/ui/Skeleton';
import { WelcomeTooltip } from '@/component/start/styles';

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const HeaderTitleStyled = styled.div`
  margin-bottom: 16px;
`;

const HeaderBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 4px;
`;

const BodyWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BodyHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BodyContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const CourseCardPlaceholderV2 = (): JSX.Element => {
  return (
    <div>
      <HeaderStyled>
        <HeaderTitleStyled>
          <Skeleton height={16} width={148} />
        </HeaderTitleStyled>
        <HeaderBodyStyled>
          <Skeleton height={24} width={148} />
          <Skeleton height={16} width={148} />
        </HeaderBodyStyled>
        <Skeleton height={12} width={352} />
      </HeaderStyled>
      <WelcomeTooltip carrotVisible>
        <BodyWrapStyled>
          <BodyHeaderStyled>
            <Skeleton height={24} width={156} />
            <Skeleton height={24} width={78} />
          </BodyHeaderStyled>
          <BodyContentStyled>
            <Skeleton height={16} width={300} />
            <Skeleton height={16} width={300} />
          </BodyContentStyled>
        </BodyWrapStyled>
      </WelcomeTooltip>
      <FooterStyled>
        <Skeleton height={16} width={154} />
      </FooterStyled>
    </div>
  );
};
