import React, { ChangeEvent, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { IconPropType } from '@/ui/Icon';
import { TextFieldProps } from './types';
import {
  RootStyled,
  TextFieldContainerStyled,
  InputStyled,
  TextAreaStyled,
  LabelStyled,
  IconStyled,
  HelperTextStyled,
  HelperTextWrap,
} from './styles';

const ERROR_ICON: IconPropType = 'circle-exclamation';
const ERASE_ICON: IconPropType = 'xmark';

const useTextFieldProps = ({
  value,
  placeholder,
  label: customLabel,
  error,
  leadingIcon: customLeadingIcon,
  trailingIcon: customTrailingIcon,
  erasable: customErasable,
  multiline,
  required,
  optional,
  helperText: customHelperText,
  disabled,
  onChange,
  onLeadingIconClick: customOnLeadingIconClick,
  onTrailingIconClick: customOnTrailingIconClick,
  fullWidth,
  showMaxLengthCounter,
  ...htmlProps
}: Partial<TextFieldProps>): {
  hasValue: boolean;
  hasError: boolean;
  hasLeadingIcon: boolean;
  hasTrailingIcon: boolean;
} & Partial<TextFieldProps> => {
  const { t } = useTranslation('common');

  const hasValue = Boolean(value || placeholder);
  const hasError = Boolean(error);
  const hasErrorMessage = Boolean(error?.message);
  const hasLeadingIcon = !multiline && Boolean(customLeadingIcon);
  const erasable = Boolean(!multiline && customErasable);
  const hasTrailingIcon = !multiline && Boolean(Boolean(customTrailingIcon) || hasError || erasable);

  let label = customLabel;

  if (required) {
    label = `${label}*`;
  } else if (optional) {
    // TODO add translations
    label = `${label} (${t('Optional').toLowerCase()})`;
  }

  let helperText = customHelperText;

  if (hasErrorMessage) {
    helperText = error?.message;
  } else if (required) {
    helperText = `*${t('Required')}`;
  }

  let trailingIcon = customTrailingIcon;

  if (hasError) {
    trailingIcon = ERROR_ICON;
  } else if (erasable) {
    trailingIcon = ERASE_ICON;
  }

  const handleLeadingIconClick = () => {
    !disabled && customOnLeadingIconClick && customOnLeadingIconClick();
  };

  const handleTrailingIconClick = () => {
    if (disabled) return;

    if (erasable && onChange) {
      onChange({
        target: {
          value: '',
        },
      } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    }

    customOnTrailingIconClick && customOnTrailingIconClick();
  };

  return {
    label,
    hasValue,
    hasError,
    hasLeadingIcon,
    erasable,
    hasTrailingIcon,
    helperText,
    trailingIcon,
    leadingIcon: customLeadingIcon,
    multiline,
    value,
    placeholder,
    required,
    disabled,
    showMaxLengthCounter,
    onChange,
    onLeadingIconClick: handleLeadingIconClick,
    onTrailingIconClick: handleTrailingIconClick,
    fullWidth,
    ...htmlProps,
  };
};

export const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>((props, ref): JSX.Element => {
  const id = useMemo(uuid, []);

  const {
    value,
    label,
    placeholder,
    helperText,
    multiline,
    trailingIcon,
    leadingIcon,
    disabled,
    erasable,
    hasValue,
    hasError,
    hasLeadingIcon,
    hasTrailingIcon,
    required,
    onLeadingIconClick,
    onTrailingIconClick,
    onChange,
    rows = 4,
    as,
    maxLength,
    showMaxLengthCounter,
    fullWidth,
    ...htmlProps
  } = useTextFieldProps(props);

  const textFieldElementProps = {
    id,
    value,
    placeholder,
    disabled,
    required,
    hasError,
    hasValue,
    onChange,
    fullWidth,
    maxLength,
    ...htmlProps,
  };

  return (
    <RootStyled fullWidth={fullWidth}>
      <TextFieldContainerStyled fullWidth={fullWidth}>
        {hasLeadingIcon && leadingIcon && (
          <IconStyled
            icon={leadingIcon}
            iconPosition="start"
            onClick={onLeadingIconClick}
            visible={hasLeadingIcon}
            disabled={disabled}
          />
        )}
        {multiline ? (
          <TextAreaStyled {...textFieldElementProps} ref={ref as React.RefObject<HTMLTextAreaElement>} rows={rows} />
        ) : (
          <InputStyled
            {...textFieldElementProps}
            ref={ref as React.RefObject<HTMLInputElement>}
            hasLeadingIcon={hasLeadingIcon}
            hasTrailingIcon={hasTrailingIcon}
          />
        )}
        <LabelStyled htmlFor={id} hasValue={hasValue} hasLeadingIcon={hasLeadingIcon} hasError={hasError}>
          {label}
        </LabelStyled>
        {hasTrailingIcon && trailingIcon && (
          <IconStyled
            icon={trailingIcon}
            iconPosition="end"
            onClick={onTrailingIconClick}
            hasError={hasError}
            pointer={erasable && !disabled}
            visible={erasable ? Boolean(value) : true}
            disabled={disabled}
          />
        )}
      </TextFieldContainerStyled>
      <HelperTextWrap>
        {helperText && <HelperTextStyled hasError={hasError}>{helperText}</HelperTextStyled>}
        {showMaxLengthCounter && maxLength && (
          <HelperTextStyled>
            {value?.length || 0}/{maxLength}
          </HelperTextStyled>
        )}
      </HelperTextWrap>
    </RootStyled>
  );
});

TextField.displayName = 'TextField';
