import React from 'react';
import styled, { keyframes } from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { StyledWrapper } from './CourseCard';

const CARD_PADDING = cvar('gap-sm');
const CARD_HEIGHT = '370px';
const IMAGE_HEIGHT = '140px';
const LARGE_LINE_HEIGHT = '32px';
const SMALL_LINE_HEIGHT = '16px';

const VARIABLES = {
  'card-skeleton': `linear-gradient(white ${CARD_HEIGHT}, transparent 0)`,
  'card-skeleton-position': '0 0',
  'card-skeleton-size': '100% 100%',

  'title-width': '200px',
  'title-position': `${CARD_PADDING} 195px`,
  'title-skeleton': `linear-gradient(lightgrey ${LARGE_LINE_HEIGHT}, transparent 0)`,

  'desc-line-skeleton': `linear-gradient(lightgrey ${SMALL_LINE_HEIGHT}, transparent 0)`,
  'desc-line-1-width': '230px',
  'desc-line-1-position': `${CARD_PADDING} 257px`,
  'desc-line-2-width': '180px',
  'desc-line-2-position': `${CARD_PADDING} 280px`,
  'overline-width': '180px',
  'overline-position': `${CARD_PADDING} 170px`,

  'image-position': '0 0',
  'image-size': `100% ${IMAGE_HEIGHT}`,
  'image-skeleton': `linear-gradient(lightgrey ${IMAGE_HEIGHT}, transparent 0)`,

  'animation-size': `120px ${CARD_HEIGHT}`,
  'animation-skeleton':
    'linear-gradient(90deg, rgba(245, 245, 245, 0) 0, rgba(245, 245, 245, 0.8) 50%, rgba(245, 245, 245, 0) 100%)',
  'animation-position': '-150% 0',
  'animation-position-keyframes': '350% 0',
};

const Animation = keyframes`
  to {
    background-position: ${VARIABLES['animation-position-keyframes']}, ${VARIABLES['overline-position']}, ${VARIABLES['title-position']},
      ${VARIABLES['desc-line-1-position']}, ${VARIABLES['desc-line-2-position']}, ${VARIABLES['image-position']}, ${VARIABLES['card-skeleton-position']};
  }
`;

const PlaceholderWrapper = styled(StyledWrapper)`
  &,
  &::after {
  }

  height: ${CARD_HEIGHT};
  overflow: hidden;
  width: 100%;

  &:empty::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: ${cvar('radius-outer')};

    background-image: ${VARIABLES['animation-skeleton']}, ${VARIABLES['desc-line-skeleton']}, ${VARIABLES['title-skeleton']},
      ${VARIABLES['desc-line-skeleton']}, ${VARIABLES['desc-line-skeleton']}, ${VARIABLES['image-skeleton']},
      ${VARIABLES['card-skeleton']};

    background-size: ${VARIABLES['animation-size']}, ${VARIABLES['overline-width']} ${SMALL_LINE_HEIGHT},
      ${VARIABLES['title-width']} ${LARGE_LINE_HEIGHT}, ${VARIABLES['desc-line-1-width']} ${SMALL_LINE_HEIGHT},
      ${VARIABLES['desc-line-2-width']} ${SMALL_LINE_HEIGHT}, ${VARIABLES['image-size']}, ${VARIABLES['card-skeleton-size']};

    background-position: ${VARIABLES['animation-position']}, ${VARIABLES['overline-position']}, ${VARIABLES['title-position']},
      ${VARIABLES['desc-line-1-position']}, ${VARIABLES['desc-line-2-position']}, ${VARIABLES['image-position']},
      ${VARIABLES['card-skeleton-position']};

    background-repeat: no-repeat;
    animation: ${Animation} 1.5s infinite;
  }
`;

export const CourseCardPlaceholder = (): JSX.Element => {
  return <PlaceholderWrapper />;
};
