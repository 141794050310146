export const BREADCRUMBS = {
  visible: true,
  items: [
    {
      title: 'MY LEARNING ',
      path: '/user/my-learning/ongoing',
    },
    {
      title: 'ASSIGNED',
      path: '/user/my-learning/assigned',
    },
  ],
};
