import React from 'react';

import { Modal } from '@/ui/Modal';

type RetakeModalProps = {
  onClose: () => void;
  open: boolean;
  onRetake: () => void;
};

export const RetakeModal = ({ onClose, open, onRetake }: RetakeModalProps): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} size="mobile">
      <Modal.Title>Retake quiz</Modal.Title>
      <Modal.Contents>
        You can retake the quiz as many times as you want. Only your latest attempt will be recorded.
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>Cancel</Modal.Action>
        <Modal.Action action={onRetake} primary>
          Retake
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
