import React from 'react';
import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { renderToStaticMarkup } from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components';

import { CustomSlateElementType } from '@/types/slate';
import { migrateDataModel } from '../migration/dataModelMigration';
import { CustomNode } from '../types';
import { ImageElement } from '../components/elements/ImageElement';
import { BuddyThemeProvider } from '@/ui/theme';
import { Serializable as DocumentSerializable } from '@/component/customEditorV2/ui/elements/DocumentElement/Serializable';
import { UploadedFileVideo } from '@/component/FileUpload/types';
import { VideoSerializable } from '@/component/customEditorV2/ui/elements/Video/Serializable/VideoSerializable';
import {
  Heading2,
  Heading3,
  Body1,
  HeadingBold,
  BodyBold,
  ItalicText,
  UnderlineText,
  StrikethroughText,
  Link as StyledLink,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@/component/customEditorV2/ui/Typography';

const HEADING_TYPES: CustomSlateElementType[] = ['heading', 'heading2'];

export const serialize = (node?: CustomNode | string, parentType?: CustomSlateElementType): React.ReactNode => {
  if (!node) {
    return '';
  }

  if (typeof node === 'string') {
    return node;
  }

  if (Text.isText(node)) {
    const text = node.text || '';

    let children: React.ReactNode = text;

    if (node.bold) {
      if (parentType && HEADING_TYPES.includes(parentType)) {
        children = <HeadingBold>{children}</HeadingBold>;
      } else {
        children = <BodyBold>{children}</BodyBold>;
      }
    }

    if (node.italic) {
      children = <ItalicText>{children}</ItalicText>;
    }

    if (node.underline) {
      children = <UnderlineText>{children}</UnderlineText>;
    }

    if (node.lineThrough) {
      children = <StrikethroughText>{children}</StrikethroughText>;
    }

    return children;
  }

  if (!node.children) {
    return '';
  }

  const children = node.children.map((n) => serialize(n, node.type));

  switch (node.type) {
    case 'heading': {
      return <Heading2>{children}</Heading2>;
    }
    case 'heading2': {
      return <Heading3>{children}</Heading3>;
    }
    case 'paragraph': {
      return <Body1>{children}</Body1>;
    }
    case 'bulleted-list': {
      return <UnorderedList>{children}</UnorderedList>;
    }
    case 'ordered-list': {
      return <OrderedList>{children}</OrderedList>;
    }
    case 'list-item': {
      return <ListItem>{children}</ListItem>;
    }
    case 'link': {
      return (
        <StyledLink target="_blank" href={escapeHtml(node.url || '')}>
          {children}
        </StyledLink>
      );
    }
    case 'image': {
      return <ImageElement.Serializable width={node?.width} url={node.url} caption={node?.caption} />;
    }
    case 'document': {
      const documentNode = node as CustomNode & {
        documentUrl: string;
        documentName: string;
        width?: number;
      };
      if (!documentNode.documentUrl) return null;

      return (
        <BuddyThemeProvider>
          <DocumentSerializable
            width={documentNode.width}
            documentUrl={documentNode.documentUrl}
            documentName={documentNode.documentName}
          />
        </BuddyThemeProvider>
      );
    }
    case 'video': {
      const videoNode = node as CustomNode & {
        uploadedVideo?: UploadedFileVideo;
        videoLink?: { embedUrl: string };
        width?: number;
      };

      if (!videoNode?.uploadedVideo && !videoNode?.videoLink?.embedUrl) return null;

      return (
        <BuddyThemeProvider>
          <VideoSerializable
            width={videoNode?.width as number}
            uploadedVideo={videoNode?.uploadedVideo}
            videoLink={videoNode?.videoLink}
          />
        </BuddyThemeProvider>
      );
    }
    case 'text': {
      return <span>{children}</span>;
    }
    default: {
      return <Body1>{children}</Body1>;
    }
  }
};

const serializeNodes = (nodes: Array<CustomNode | string | undefined>): React.ReactNode => {
  if (!Array.isArray(nodes)) {
    return null;
  }

  return nodes.map((node) => serialize(node));
};

export const toHtml = (text: string | null): string => {
  try {
    const dataModel = migrateDataModel(text);
    const content = serializeNodes(dataModel);

    const sheet = new ServerStyleSheet();
    let html = '';
    let styleTags = '';

    try {
      html = renderToStaticMarkup(
        sheet.collectStyles(
          <BuddyThemeProvider>
            <>{content}</>
          </BuddyThemeProvider>
        )
      );
      styleTags = sheet.getStyleTags();
    } finally {
      sheet.seal();
    }

    return `${styleTags}${html}`;
  } catch (e) {
    if (e instanceof Error) {
      console.warn('Unable to parse json:', text, e.message);
    }
    return '';
  }
};
