import { createGlobalStyle } from 'styled-components';

import { BreakpointUnion } from './types';
import { Layout } from '@/ui/Layout';

export const breakpoints: {
  [key in BreakpointUnion]: number;
} = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const styleConfig = {
  gap: {
    xxs: 0.4375,
    xs: 0.875,
    sm: 1.3125,
    md: 1.75,
    lg: 2.625,
    xl: 3.9375,
  },
  logo: {
    height: '36px',
    mobile: {
      height: '20px',
    },
    heightStacked: '67px',
    headerGap: '4px',
  },
  navbar: {
    mobile: {
      height: '45px',
    },
    desktop: {
      height: '80px',
    },
    banner: {
      height: '50px',
    },
  },
};

const cssColorVariables = {
  'color-mine-shaft': '#242424',
  'color-black': '#000',
  'color-alto': '#d8d8d8',
  'color-caribbean-green': '#01d3a9',
  'color-deep-sea': '#008066',
  'color-tropical-rain-forest': '#006b56',
  'color-aqua-deep': '#004d3d',
  'color-seashell': '#f1f1f1',
  'color-wild-sand': '#f5f5f5',
  'color-white': '#fff',
  'color-silver-chalice': '#a4a4a4',
  'color-silver': '#bebebe',
  'color-gray': '#8c8c8c',
  'color-dark-gray': '#666666',
  'color-dove-gray': '#707070',
  'color-dusty-gray': '#979797',
  'color-flush-mahogany': '#cf3e3e',
  'color-brick-red': '#c7292e',
  'color-monza': '#da190b',
  'color-transparent': 'transparent',
  'color-mercury': '#eaeaea',
  'color-moon-crater-gray': '#363636',
  'color-lynx-white': '#f7f7f7',
  'color-champagne': '#F9E6D3',
  'color-old-lace': '#FCF2E8',
  'color-buddy-light': '#3F494A',

  'color-brand': 'var(--color-deep-sea)',
  'color-brand-light': 'var(--color-caribbean-green)',
  'color-primary': 'var(--color-deep-sea)',
  'color-primary-hover': 'var(--color-tropical-rain-forest)',
  'color-secondary': 'var(--color-alto)',
  'color-secondary-hover': 'var(--color-silver)',
  'color-quaternary': 'var(--color-old-lace)',
  'color-quaternary-hover': 'var(--color-champagne)',
  'color-border': 'var(--color-alto)',
  'color-border-dark': 'var(--color-gray)',
  'color-text': 'var(--color-dove-gray)',
  'color-text-disabled': 'var(--color-dusty-gray)',
  'color-overline': 'var(--color-dusty-gray)',
  'color-table-overline': 'var(--color-dark-gray)',
  'color-background-light': 'var(--color-wild-sand)',
  'color-overlay': 'rgba(0, 0, 0, 0.8)',
  'color-overlay-light': 'rgba(0, 0, 0, 0.15)',
  'color-label': 'rgba(255, 255, 255, 0.7)',
  'color-icon': 'rgba(0, 0, 0, .3)',
  'color-icon-hover': 'var(--color-black)',
  'gradient-gray': 'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.04) 100%)',
  'color-label-dark': 'rgba(0, 0, 0, 0.7)',
  'color-background-code': 'var(--color-mercury)',
} as const;

const gap = {
  xxs: 0.4375,
  xs: 0.875,
  sm: 1.3125,
  md: 1.75,
  lg: 2.625,
  xl: 3.9375,
} as const;

const cssGapVariables = {
  'gap-xxs': `${styleConfig.gap.xxs}rem`,
  'gap-xs': `${styleConfig.gap.xs}rem`,
  'gap-sm': `${styleConfig.gap.sm}rem`,
  'gap-md': `${styleConfig.gap.md}rem`,
  'gap-lg': `${styleConfig.gap.lg}rem`,
  'gap-xl': `${styleConfig.gap.xl}rem`,
  'v2-gap-xxs': `${gap.xxs}rem`,
  'v2-gap-xs': `${gap.xs}rem`,
  'v2-gap-sm': `${gap.sm}rem`,
  'v2-gap-md': `${gap.md}rem`,
  'v2-gap-lg': `${gap.lg}rem`,
  'v2-gap-xl': `${gap.xl}rem`,
} as const;

const cssFontSizeVariables = {
  'font-size-1': '2.5rem',
  'font-size-2': '1.875rem',
  'font-size-3': '1.5rem',
  'font-size-4': '1.25rem',
  'font-size-5': '1.125rem',
  'font-size-6': '0.9375rem',
  'font-size-7': '0.8125rem',
  'font-size-8': '0.6875rem',
  'font-size-9': '0.5625rem',
  'font-size-10': '0.4375rem',

  'v2-font-size-1': '2.5rem',
  'v2-font-size-2': '1.875rem',
  'v2-font-size-3': '1.25rem',
  'v2-font-size-4': '1.125rem',
  'v2-font-size-5': '1rem',
  'v2-font-size-6': '0.9375rem',
  'v2-font-size-7': '0.875rem',
  'v2-font-size-8': '0.75rem',
  'v2-font-size-9': '0.5rem',
  'v2-font-size-10': '2.25rem',
} as const;

const cssLineHeightVariables = {
  'v2-line-height-1': '2.75rem',
  'v2-line-height-2': '2.5rem',
  'v2-line-height-3': '1.75rem',
  'v2-line-height-4': '1.5rem',
  'v2-line-height-5': '1.25rem',
  'v2-line-height-6': '1rem',
  'v2-line-height-7': '0.75rem',
  'v2-line-height-8': '2.25rem',
} as const;

export type FontSize = keyof typeof cssFontSizeVariables;

const cssRadiusVariables = {
  'radius-inner': '4px',
  'radius-inner-with-border': '6px',
  'radius-outer': '8px',
  'radius-outer-with-border': '10px',
} as const;

const cssFontWeightVariables = {
  'font-weight-regular': '400',
  'font-weight-bold': '700',
  'font-weight-bold-extra': '800',
} as const;

const cssFontWeightV2Variables = {
  'v2-font-weight-regular': '400',
  'v2-font-weight-medium': '500',
  'v2-font-weight-bold': '700',
  'v2-font-weight-extra-bold': '800',
} as const;

const cssVariables = {
  ...cssColorVariables,
  ...cssGapVariables,
  ...cssFontSizeVariables,
  ...cssRadiusVariables,
  ...cssFontWeightVariables,
  ...cssLineHeightVariables,
  ...cssFontWeightV2Variables,

  'font-family': 'Inter, sans-serif',
  'font-family-monospace':
    'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',

  'border-width': '2px',
  'border-style': 'var(--border-width) solid var(--color-border)',

  'box-shadow-small': '2px 4px 12px rgba(0, 0, 0, 0.06)',
  'box-shadow-small-hover': '2px 4px 12px rgba(0, 0, 0, 0.10)',
  'box-shadow-large': '5px 10px 30px rgba(0, 0, 0, 0.10)',
  'box-shadow-large-hover': '5px 10px 30px rgba(0, 0, 0, 0.14)',
  'box-shadow-3': 'inset 0 -35px 60px -35px rgb(0, 0, 0, .12)',
  'box-shadow-nav': '0 35px 60px rgb(0, 0, 0, .12)',

  'breakpoint-xs': `${breakpoints.xs}px`,
  'breakpoint-sm': `${breakpoints.sm}px`,
  'breakpoint-md': `${breakpoints.md}px`,
  'breakpoint-lg': `${breakpoints.lg}px`,
  'breakpoint-xl': `${breakpoints.xl}px`,
  'breakpoint-xxl': `${breakpoints.xxl}px`,

  'logo-height': styleConfig.logo.height,
  'logo-stacked-height': styleConfig.logo.heightStacked,
  'logo-mobile-height': styleConfig.logo.mobile.height,
  'logo-header-gap': styleConfig.logo.headerGap,
  'navbar-height-mobile': styleConfig.navbar.mobile.height,
  'navbar-height-desktop': styleConfig.navbar.desktop.height,
  'navbar-banner-height': styleConfig.navbar.banner.height,
} as const;

type CssVariablesUnion = keyof typeof cssVariables;

export type CssColorVariable = keyof typeof cssColorVariables;
export type CssGapVariable = keyof typeof cssGapVariables;
export type CssRadiusVariable = keyof typeof cssGapVariables;
export type CssFontWeightVariable = keyof typeof cssFontWeightVariables;

export function cvar(cssVariable: CssVariablesUnion): string {
  return `var(--${cssVariable})`;
}

const globalVariables = Object.keys(cssVariables).reduce<string[]>(
  (result, key) => [...result, `--${key}: ${cssVariables[key as CssVariablesUnion]};`],
  []
);

export const LegacyGlobalStyle = createGlobalStyle`
  :root, ::after, ::before {
    ${globalVariables.join('')} 
  }

  body {
    background-color: ${cvar('color-wild-sand')};
    color: ${cvar('color-gray')};
    font-family: ${cvar('font-family')};
    font-size: 1rem;
    font-weight: ${cvar('font-weight-regular')};
    height: 100%;
    margin: 0;
    padding: 0;
  }

  // Make sure that legacy views behave in line with Buddy layout.
  .hero-body, .container {
    &&& { 
      > .container {
        padding-left: 0;
        padding-right: 0;
      }
      ${Layout.ContentSection.css}
    }
  }
`;
