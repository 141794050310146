import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  StyledDocumentContainer,
  StyledHeader,
  StyledDescriptionContainer,
  StyledProgressBarContainer,
  StyledProgressLabel,
  StyledSubHeader,
} from '../styles';
import { DocumentVideoUploadButton } from '@/component/FileUpload/DocumentVideoUploadButton';
import { LinearProgressBar } from '@/ui/ProgressBar';
import { FileType, FileUploader } from '@/component/FileUpload/types';
import uploadImage from './upload.svg';
import errorImage from '../../error.svg';

const DocumentImage = styled.img.attrs({ src: uploadImage })``;
const ErrorImage = styled.img.attrs({ src: errorImage })``;

export const UploadFile = ({
  disabled,
  documentUpload,
}: {
  disabled: boolean;
  documentUpload: FileUploader<'document'>;
}): JSX.Element => {
  const { t } = useTranslation('uploaders');
  const fileType: FileType = 'document';
  return (
    <>
      {!documentUpload.uploading && !documentUpload.error && (
        <StyledDocumentContainer border="dashed" fullHeight>
          <DocumentImage />
          <StyledDescriptionContainer>
            <StyledHeader>{t('add-your-document', { ns: 'catalog' })}</StyledHeader>
          </StyledDescriptionContainer>
          <DocumentVideoUploadButton
            icon={['far', 'file-upload']}
            $loading={!!documentUpload.uploading}
            variant="secondary"
            size="large"
            disabled={disabled}
            uploadFile={documentUpload.uploadFile}
            fileType={fileType}
          >
            {t('upload-from-device')}
          </DocumentVideoUploadButton>
        </StyledDocumentContainer>
      )}
      {documentUpload.uploading && !documentUpload.error && (
        <StyledDocumentContainer border="solid" fullHeight>
          <StyledProgressBarContainer>
            <StyledProgressLabel>{t('uploading', { count: documentUpload.uploadingProgress })}</StyledProgressLabel>
            <LinearProgressBar maxValue={100} value={documentUpload.uploadingProgress} animated={true} rounded />
          </StyledProgressBarContainer>
        </StyledDocumentContainer>
      )}
      {!documentUpload.uploading && documentUpload.error && (
        <StyledDocumentContainer border="solid" fullHeight>
          <ErrorImage />
          <StyledDescriptionContainer>
            <StyledHeader>{documentUpload.error.title}</StyledHeader>
            <StyledSubHeader>{documentUpload.error.message}</StyledSubHeader>
          </StyledDescriptionContainer>
          <DocumentVideoUploadButton
            $loading={!!documentUpload.uploading}
            variant="primary"
            size="large"
            disabled={disabled}
            uploadFile={documentUpload.uploadFile}
            fileType={fileType}
          >
            {t('try-again')}
          </DocumentVideoUploadButton>
        </StyledDocumentContainer>
      )}
    </>
  );
};
