import { Block } from '@/administration/pages/Course/store/block/types';
import { PageId } from '../types';

export enum PageVariant {
  TEXT = 'TEXT',
  RESULT = 'RESULT',
  REACTION = 'REACTION',
  QUESTION = 'QUESTION',
  PRACTICAL_ASSESSMENT_TEXT = 'PRACTICAL_ASSESSMENT_TEXT',
  PRACTICAL_ASSESSMENT_DOCUMENT = 'PRACTICAL_ASSESSMENT_DOCUMENT',
  // actual ones go below
  REFLECTION = 'REFLECTION',
  MODULE = 'MODULE',
  PAGE = 'PAGE',
  QUIZ = 'QUIZ',
}

export enum PageVariantDeprecated {
  TEXT = 'TEXT',
  RESULT = 'RESULT',
  REACTION = 'REACTION',
  QUESTION = 'QUESTION',
  PRACTICAL_ASSESSMENT_TEXT = 'PRACTICAL_ASSESSMENT_TEXT',
  PRACTICAL_ASSESSMENT_DOCUMENT = 'PRACTICAL_ASSESSMENT_DOCUMENT',
  REFLECTION = 'REFLECTION',
  QUIZ = 'QUIZ',
}

export type Page = {
  id: PageId;
  remoteId?: number;

  title: string;

  type: PageVariantDeprecated;
  block: Block;

  active?: boolean;
  hasError?: boolean;
  completed?: boolean;
};
