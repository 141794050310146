import { convertVideoToEmbedWithType, EmbedUrlType } from '@/administration/utils/videoUrl';
import { useCurrentUserAccess } from '@/store/currentUser';

export const useVideoLink = (): { getEmbedUrlType: (url: string) => EmbedUrlType } => {
  const { isSuperAdmin } = useCurrentUserAccess();

  const getEmbedUrlType = (url: string): EmbedUrlType => {
    const embed = convertVideoToEmbedWithType(url);
    if (embed.embedUrl) {
      return embed;
    }
    if (isSuperAdmin) {
      return { type: 'generic', embedUrl: url, url };
    }
    return { url, type: undefined, embedUrl: '' };
  };

  return { getEmbedUrlType };
};
