import { UsePracticalAssessmentViewerHook } from '../store/practicalAssessmentViewer/usePracticalAssessmentViewer';
import { ModularLearningDetails } from '@/pages/ModularLearningDeprecated/ModularLearningDetails';

export const PracticalAssessmentDetails = (props: ReturnType<UsePracticalAssessmentViewerHook>): JSX.Element => {
  return (
    <ModularLearningDetails
      title={props.practicalAssessment?.title || ''}
      learningId={Number(props.practicalAssessment?.id)}
      spaceId={props.practicalAssessment?.spaceId}
      loading={props.loadingLearning}
      pages={props.pages}
      isDrawerOpen={props.isDrawerOpen}
      pageTotalCount={props.pageTotalCount}
      pageActiveCount={props.pageActiveCount}
      prevPageActiveCount={props.prevPageActiveCount}
      onNewPageClick={props.onSetPageActiveByIndex}
      onUpNavigationClick={props.onSetPrevPageActive}
      onDownNavigationClick={props.onSetNextPageActive}
      onSetDrawerOpen={props.setDrawerOpen}
      onSetPageActive={props.onSetPageActive}
      onPageChange={props.onPageChange}
    />
  );
};
