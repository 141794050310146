import { HTMLProps } from 'react';
import styled from 'styled-components';

import { CssGapVariable, cvar } from '@/styles/GlobalStyle';

type FlexWrap = 'wrap' | 'nowrap';
type FlexDirection = 'row' | 'column';
type AlignItems = 'stretch' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'center' | 'self-start' | 'self-end' | 'normal';
type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
type JustifyContent =
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

type FlexProps = {
  alignItems?: AlignItems;
  justifyContent?: JustifyContent;
  flexDirection?: FlexDirection;
  flexWrap?: FlexWrap;
  flex?: string;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  mt?: CssGapVariable;
  mb?: CssGapVariable;
  mr?: CssGapVariable;
  ml?: CssGapVariable;
  gap?: CssGapVariable;
  gapRow?: CssGapVariable;
  gapColumn?: CssGapVariable;
  pt?: CssGapVariable;
  pb?: CssGapVariable;
  pr?: CssGapVariable;
  pl?: CssGapVariable;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  gridArea?: string;
  textAlign?: TextAlign;
} & HTMLProps<HTMLDivElement>;

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')};
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : '')};
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: ${flexWrap};` : '')};
  ${({ flexGrow }) => (flexGrow ? `flex-grow: ${flexGrow};` : '')};
  ${({ flexShrink }) => (flexShrink ? `flex-shrink: ${flexShrink};` : '')};
  ${({ flexBasis }) => (flexBasis ? `flex-basis: ${flexBasis};` : '')};
  ${({ flex }) => (flex ? `flex: ${flex};` : '')};

  ${({ gap }) => (gap ? `gap: ${cvar(gap)};` : '')};
  ${({ gapRow }) => (gapRow ? `row-gap: ${cvar(gapRow)};` : '')};
  ${({ gapColumn }) => (gapColumn ? `column-gap: ${cvar(gapColumn)};` : '')};

  ${({ mt }) => (mt ? `margin-top: ${cvar(mt)};` : '')};
  ${({ mb }) => (mb ? `margin-bottom: ${cvar(mb)};` : '')};
  ${({ mr }) => (mr ? `margin-right: ${cvar(mr)};` : '')};
  ${({ ml }) => (ml ? `margin-left: ${cvar(ml)};` : '')};

  ${({ pt }) => (pt ? `padding-top: ${cvar(pt)};` : '')};
  ${({ pb }) => (pb ? `padding-bottom: ${cvar(pb)};` : '')};
  ${({ pr }) => (pr ? `padding-right: ${cvar(pr)};` : '')};
  ${({ pl }) => (pl ? `padding-left: ${cvar(pl)};` : '')};

  ${({ width }) => (width ? `width: ${width};` : '')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')};

  ${({ height }) => (height ? `height: ${height};` : '')};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')};

  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}

  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')};
`;
