import styled from 'styled-components';

import { device, columns } from './constants';

export const CampusGrid = styled.div`
  background: ${(p) => p.theme.colors.neutral.background};
  background-image: none;
  display: grid;

  @media ${device.mobile.screenSize} {
    grid-template-columns: ${device.mobile.columns};
    grid-column-gap: ${device.mobile.gutter}px;
  }

  @media ${device.mobileLandscape.screenSize} {
    grid-template-columns: ${device.mobileLandscape.columns};
    grid-column-gap: ${device.mobileLandscape.gutter}px;
  }
  @media ${device.tablet.screenSize} {
    grid-template-columns: ${device.tablet.columns};
    grid-column-gap: ${device.tablet.gutter}px;
  }

  @media ${device.desktop.screenSize} {
    grid-template-columns: ${device.desktop.columns};
    grid-column-gap: ${device.desktop.gutter}px;
  }

  @media ${device.desktopLarge.screenSize} {
    grid-template-columns: ${device.desktopLarge.columns};
    grid-column-gap: ${device.desktopLarge.gutter}px;
  }

  @media ${device.widescreen.screenSize} {
    grid-template-columns: ${device.widescreen.columns};
    grid-column-gap: ${device.widescreen.gutter}px;
  }
  @media ${device.fullHD.screenSize} {
    grid-template-columns: ${device.fullHD.columns};
    grid-column-gap: ${device.fullHD.gutter}px;
    max-width: ${device.fullHD.body}px;
  }
`;
export const Container = styled.div`
  @media ${device.mobile.screenSize} {
    grid-column: span ${columns.sm};
  }

  @media ${device.mobileLandscape.screenSize} {
    grid-column: span ${columns.md};
  }
  @media ${device.tablet.screenSize} {
    grid-column: span ${columns.md};
  }

  @media ${device.desktop.screenSize} {
    grid-column: span ${columns.lg};
  }

  @media ${device.desktopLarge.screenSize} {
    grid-column: span ${columns.lg};
  }

  @media ${device.widescreen.screenSize} {
    grid-column: span ${columns.lg};
  }
  @media ${device.fullHD.screenSize} {
    grid-column: span ${columns.lg};
  }
`;

export const Header = styled.div`
  display: grid;
  width: 100%;

  @media ${device.mobile.screenSize} {
    grid-template-columns: ${device.mobile.columns};
    grid-column-gap: ${device.mobile.gutter}px;
    grid-column: span ${columns.sm};
  }

  @media ${device.mobileLandscape.screenSize} {
    grid-template-columns: ${device.mobileLandscape.columns};
    grid-column-gap: ${device.mobileLandscape.gutter}px;
    grid-column: span ${columns.md};
  }
  @media ${device.tablet.screenSize} {
    grid-template-columns: ${device.tablet.columns};
    grid-column-gap: ${device.tablet.gutter}px;
    grid-column: span ${columns.md};
  }

  @media ${device.desktop.screenSize} {
    grid-template-columns: ${device.desktop.columns};
    grid-column-gap: ${device.desktop.gutter}px;
    grid-column: span ${columns.lg};
  }

  @media ${device.desktopLarge.screenSize} {
    grid-template-columns: ${device.desktopLarge.columns};
    grid-column-gap: ${device.desktopLarge.gutter}px;
    grid-column: span ${columns.lg};
  }

  @media ${device.widescreen.screenSize} {
    grid-template-columns: ${device.widescreen.columns};
    grid-column-gap: ${device.widescreen.gutter}px;
    grid-column: span ${columns.lg};
  }
  @media ${device.fullHD.screenSize} {
    grid-column-gap: ${device.fullHD.gutter}px;
    max-width: ${device.fullHD.body}px;
    grid-column: span ${columns.lg};
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  @media ${device.mobile.screenSize} {
    grid-column: span ${columns.sm};
  }

  @media ${device.mobileLandscape.screenSize} {
    grid-column: span ${columns.sm};
  }
  @media ${device.tablet.screenSize} {
    grid-column: span ${columns.sm};
  }

  @media ${device.desktop.screenSize} {
    grid-column: span ${columns.md};
  }

  @media ${device.desktopLarge.screenSize} {
    grid-column: span ${columns.md};
  }

  @media ${device.widescreen.screenSize} {
    grid-column: span ${columns.md};
  }
  @media ${device.fullHD.screenSize} {
    grid-column: span ${columns.md};
  }
`;
export const WelcomeContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${device.mobile.screenSize} {
    grid-column: span ${columns.sm};
  }
  @media ${device.mobileLandscape.screenSize} {
    grid-column: span ${columns.sm};
  }
  @media ${device.tablet.screenSize} {
    grid-column: span ${columns.md};
  }
  @media ${device.desktop.screenSize} {
    grid-column: span ${columns.sm};
  }

  @media ${device.desktopLarge.screenSize} {
    grid-column: span ${columns.sm};
  }
  @media ${device.widescreen.screenSize} {
    grid-column: span ${columns.sm};
  }
  @media ${device.fullHD.screenSize} {
    grid-column: span ${columns.sm};
  }
`;
