import React, { FC, useCallback, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import { Input } from '@/component/Input/Input';
import { Button } from '@/components/Button/Button';
import { validateUrl } from '@/utils/text';
import { hasSelection, insertLink } from '../../../logic/links';
import { LinkPopoverWrapper, MessageWrapper } from './styledComponents/LinkSection';
import { ButtonList } from '@/components/Button/ButtonList';
import { AutoComplete } from '@/components/Form/types';

interface LinkSectionProps {
  children: JSX.Element;
  onClose: () => void;
  editor: Editor;
  isOpened?: boolean;
}

export const LinkSection: FC<LinkSectionProps> = ({ children, isOpened, onClose }) => {
  const editor = useSlate();
  const { t } = useTranslation('builderAdmin');

  const [linkUrl, setLinkUrl] = useState<string>('');
  const [linkText, setLinkText] = useState<string>('');

  const resetLinkPopover = useCallback(() => {
    setLinkUrl('');
    setLinkText('');
    onClose();
  }, [linkUrl, linkText, linkUrl]);

  const handleSetLinkButtonClick = useCallback(() => {
    if (!editor.blurSelection) {
      return;
    }

    insertLink(editor, linkUrl, linkText);
    resetLinkPopover();
  }, [editor, linkUrl, linkText]);

  const renderTextInput = useCallback((): JSX.Element | null => {
    if (hasSelection(editor)) {
      return null;
    }

    return (
      <div className="field">
        <Input
          label={t('Link text')}
          hasAddons
          fullWidth
          type="text"
          autoComplete={AutoComplete.on}
          value={linkText}
          onChange={(e) => setLinkText(e.currentTarget.value)}
        />
      </div>
    );
  }, [editor, linkText]);

  return (
    <Popover
      isOpen={Boolean(isOpened)}
      positions={['bottom']}
      padding={10}
      onClickOutside={() => resetLinkPopover()}
      containerStyle={{ zIndex: '2000', overflow: 'visible' }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer position={position} childRect={childRect} popoverRect={popoverRect} arrowColor={'white'} arrowSize={10}>
          <LinkPopoverWrapper>
            <div className="field">
              <Input
                type="text"
                label={t('Link URL')}
                autoComplete={AutoComplete.on}
                value={linkUrl}
                hasAddons
                fullWidth
                onChange={(e) => setLinkUrl(e.currentTarget.value)}
              />
            </div>
            {renderTextInput()}
            {linkUrl.length > 0 && !validateUrl(linkUrl) && (
              <MessageWrapper isErrorMessage>Link URL is not a valid URL.</MessageWrapper>
            )}
            <ButtonList align="right">
              <Button $type="secondary" $size="small" onClick={handleSetLinkButtonClick}>
                Set link
              </Button>
            </ButtonList>
          </LinkPopoverWrapper>
        </ArrowContainer>
      )}
    >
      <div>{children}</div>
    </Popover>
  );
};
