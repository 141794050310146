import React, { useCallback } from 'react';
import styled from 'styled-components';

import { TableRow } from '../styles';
import { CellStyle, ColumnDeclaration, TableDeclaration } from '../types';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { ExpandingMeta, useExpanding } from '@/components/Table/hooks/useExpanding';
import { AutoRows } from '@/components/Table/components/AutoRows';
import { DEFAULT_DISABLED_SELECTION_META } from '@/components/Table/hooks/useSelection';
import { DEFAULT_DISABLED_PAGINATION_META } from '@/components/Table/hooks/usePagination';
import { cvar } from '@/styles/GlobalStyle';

const Cell = styled.td<{ cellStyle?: CellStyle }>`
  text-align: ${(props) => props.cellStyle?.textAlign || 'right'};
  white-space: ${(props) => props.cellStyle?.whiteSpace || 'normal'};
  font-weight: ${(props) => props.cellStyle?.fontWeight || 'normal'};
  ${(props) => props.cellStyle?.fontSize && `font-size: ${cvar(props.cellStyle.fontSize)}`};
`;

interface Props<T> {
  id: string;
  item: T;
  keyProp: keyof T;
  tableDecl: TableDeclaration<T>;
  colSpan: number;
  /**
   * Selection
   */
  isSelectionEnabled?: boolean;
  selected: boolean | null;
  onSelected?: (item: T) => void;

  /**
   * Pagination
   */
  isFirst?: boolean;
  isLast?: boolean;

  /**
   * Expanding props
   */
  expanding: ExpandingMeta<T>;
}

export function AutoRow<T>({
  id,
  item,
  keyProp,
  colSpan,
  tableDecl,
  isFirst,
  isLast,
  isSelectionEnabled,
  selected,
  onSelected,
  expanding,
}: Props<T>): JSX.Element {
  const subRowsExpanding = useExpanding({
    rowExpandableSubRows: expanding.rowExpandableSubRows,
    renderExpandedRowSubComponent: expanding.renderRowSubComponent,
    isRowExpandable: expanding.isRowExpandable,
    subRowsDepth: expanding.subRowsDepth + 1,
  });
  const isRowExpandable = expanding.isRowExpandable(item);
  const isRowExpanded = expanding.isRowExpanded(item);

  const toggleExpanded = useCallback((): void => {
    expanding.toggleExpanded(item, !isRowExpanded);

    if (!isRowExpanded) {
      subRowsExpanding.clearExpanded();
    }
  }, [expanding, isRowExpanded]);

  return (
    <>
      <TableRow gray={selected === false}>
        {isSelectionEnabled && (
          <td key="checkbox">
            <Checkbox
              id={`${id}-checkbox`}
              onChange={() => onSelected && onSelected(item)}
              checked={Boolean(selected)}
              marginless
            />
          </td>
        )}
        {tableDecl.map((colDefinition: ColumnDeclaration<T>, i: number) => (
          <Cell key={i} cellStyle={colDefinition.cellStyle}>
            {colDefinition.render &&
              colDefinition.render({
                column: colDefinition,
                element: item,
                toggleIsRowExpanded: toggleExpanded,
                isRowExpanded,
                isRowExpandable,
                rowExpandedDepth: expanding.subRowsDepth,
                isFirst,
                isLast,
              })}
            <>{!colDefinition.render && colDefinition.prop && item[colDefinition.prop]}</>
          </Cell>
        ))}
      </TableRow>
      {isRowExpanded && expanding.rowExpandableSubRows && (
        <AutoRows
          keyProp={keyProp}
          tableDecl={tableDecl}
          colSpan={colSpan}
          items={expanding.rowExpandableSubRows(item)}
          expanding={subRowsExpanding}
          selection={DEFAULT_DISABLED_SELECTION_META}
          pagination={DEFAULT_DISABLED_PAGINATION_META}
        />
      )}
      {isRowExpanded && expanding.renderRowSubComponent && (
        <tr key={id + '-expand'}>
          <td colSpan={colSpan}>
            {expanding.renderRowSubComponent({
              item,
              toggleIsRowExpanded: toggleExpanded,
            })}
          </td>
        </tr>
      )}
    </>
  );
}
