import styled from 'styled-components';

import { BaseButton } from '../components/BaseButton';

type SimpleButtonGroupProps = { vertical?: boolean; padded?: boolean; $fullwidth?: boolean };

export const SimpleButtonGroup = styled.div<SimpleButtonGroupProps>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  flex-wrap: wrap;

  ${({ $fullwidth }) => $fullwidth && 'width: 100%;'}
  ${({ padded }) => padded && 'padding-bottom: var(--gap-sm);'}

  ${BaseButton}:first-of-type {
    ${({ vertical }) =>
      vertical
        ? `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 1px;
      `
        : `
        border-bottom-right-radius: 0;
        border-right-width: 1px;
        border-top-right-radius: 0;
    `};
  }

  ${BaseButton}:last-of-type {
    ${({ vertical }) =>
      vertical
        ? `
        border-top-width: 1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `
        : `
        border-bottom-left-radius: 0;
        border-left-width: 1px;
        border-top-left-radius: 0;
    `};
  }
  ${BaseButton}:not(:first-of-type):not(:last-of-type) {
    ${({ vertical }) =>
      vertical
        ? `
        border-bottom-width: 1px;
        border-radius: 0;
        border-top-width: 1px;
      `
        : `
        border-left-width: 1px;
        border-radius: 0;
        border-right-width: 1px;
    `};
  }
`;
