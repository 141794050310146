import React, { CSSProperties } from 'react';
import { Plate, TPlateEditor } from '@udecode/plate-common/react';
import { useTranslation } from 'react-i18next';
import { Value } from '@udecode/plate-common';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuid } from 'uuid';

import { Editor } from './ui/Editor';
import { CustomEditorContext } from './store/CustomEditorContext';
import { Toolbar } from './ui/fixedToolbar';
import { useCreateEditor } from './useCreateEditor';
import { FloatingToolbar } from '@/component/customEditorV2/ui/floatingToolbar';
import { FloatingToolbarButtons } from '@/component/customEditorV2/ui/floatingToolbar/FloatingToolbarButtons';
import { useCustomPlaceholdersState } from '@/component/customEditorV2/ui/placeholder/useCustomPlaceholdersState';

export type CustomEditorV2ToggledFeatures = {
  draggableInsertHandler?: boolean; // will take effect only if slashInput is also enabled
  editorGhostMode?: boolean;
  withQuestions?: boolean;
  dragAndDrop?: boolean;
  floatingToolbar?: boolean;
  slashInput?: boolean;
  mediaFiles?: boolean;
};

interface EditorProps {
  value: string;
  onChange?: (newValue: string) => void;
  className?: string;
  hasChanged?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  hideToolbar?: boolean;
  disabled?: boolean;
  required?: boolean;
  minimized?: boolean;
  size?: 'large';
  $fullSize?: boolean;
  spaceId: number;
  readOnly?: boolean;
  toggledFeatures?: CustomEditorV2ToggledFeatures;
}

// We need to generate id for empty paragraph because block ids are required for correct DnD work.
export const EMPTY_VALUE = `[{"type":"paragraph","id":"${uuid()}","children":[{"text":""}]}]`;

export const CustomEditorV2 = ({
  value,
  minimized,
  placeholder,
  size,
  disabled,
  $fullSize,
  style,
  spaceId,
  onChange,
  readOnly,
  toggledFeatures,
}: EditorProps): JSX.Element => {
  const { t } = useTranslation('discussions');
  /**
   * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
   */
  const { canShowEditorPlaceholder, ...customPlaceholdersState } = useCustomPlaceholdersState();

  let parsedInitialValue;

  try {
    parsedInitialValue = JSON.parse(value);

    if (parsedInitialValue.length === 1 && !parsedInitialValue[0]?.type) {
      parsedInitialValue = JSON.parse(EMPTY_VALUE);
    }
  } catch (error) {
    console.error('Failed to parse the editor initial value', error);
  }

  const editor = useCreateEditor({ initialValue: parsedInitialValue, toggledFeatures });

  const handleChange = (options: { editor: TPlateEditor; value: Value }) => {
    try {
      onChange && onChange(JSON.stringify(options.value));
    } catch (error) {
      console.error('Failed to stringify editor value', error);
    }
  };

  return (
    <CustomEditorContext.Provider
      value={{
        spaceId,
        readOnly,
        toggledFeatures,
        ...customPlaceholdersState,
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <Plate editor={editor} readOnly={readOnly} onChange={handleChange}>
          {!readOnly && !toggledFeatures?.editorGhostMode && <Toolbar minimized={minimized} />}
          <Editor
            /**
             * Remove the check after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
             */
            placeholder={canShowEditorPlaceholder ? placeholder || t('Text (required)') : undefined}
            disabled={disabled}
            size={size}
            style={style}
            $minimized={minimized}
            $readOnly={readOnly}
            $fullSize={$fullSize}
            $toggledFeatures={toggledFeatures}
          />
          {toggledFeatures?.floatingToolbar && (
            <FloatingToolbar>
              <FloatingToolbarButtons />
            </FloatingToolbar>
          )}
        </Plate>
      </DndProvider>
    </CustomEditorContext.Provider>
  );
};

export const CustomEditorV2Readonly = (props: EditorProps): JSX.Element => <CustomEditorV2 {...props} readOnly />;
