import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { deviceUp } from '@/styles/utils';
import { cvar } from '@/styles/GlobalStyle';
import { Layout } from '@/ui/Layout';

type SectionProps = {
  children: ReactNode | ReactNode[];
  $bordered?: boolean;
};

type HeroProps = {
  children: ReactNode | ReactNode[];
  paddedBottom?: boolean;
};

export const StyledContainer = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  ${Layout.ContentSection.css}
`;

export const StyledSection = styled.section<SectionProps>`
  padding-top: var(--gap-md);

  &:last-of-type {
    padding-bottom: var(--gap-md);
  }

  ${deviceUp.md`
    padding-top: var(--gap-lg);

    &:last-of-type {
      padding-bottom: var(--gap-lg);
    }
  `};

  ${({ $bordered }) => deviceUp.lg`
    padding-top: var(--gap-xl);
    
    &:last-of-type {
      padding-bottom: var(--gap-xl);
    }

    ${$bordered && `padding-bottom: var(--gap-xl); border-bottom: ${cvar('border-style')};`}
  `};
`;

export const Section = ({ children, $bordered }: SectionProps): JSX.Element => (
  <StyledSection $bordered={$bordered}>
    <StyledContainer>{children}</StyledContainer>
  </StyledSection>
);

const StyledHero = styled(StyledSection)<{ paddedBottom?: boolean }>`
  box-shadow: var(--box-shadow-3);

  ${({ paddedBottom }) =>
    paddedBottom
      ? `
        padding-bottom: var(--gap-xl);
      
        ${deviceUp.md`
          padding-bottom: var(--gap-lg);
        `}

        ${deviceUp.lg`
          padding-bottom: var(--gap-xl);
        `}
      `
      : null}
`;

export const Hero = ({ children, paddedBottom }: HeroProps): JSX.Element => (
  <StyledHero paddedBottom={paddedBottom}>
    <StyledContainer>{children}</StyledContainer>
  </StyledHero>
);
