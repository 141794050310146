import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getPrettyRealmName, isRealm } from '@/utils/realms';
import { getErrorResponseMessage } from '@/utils/gqlErrors';
import { useCurrentUser } from '@/store/currentUser';
import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { OnboardingStep, StepComponent } from '../types';
import { useExtraTermsLink } from '../useExtraTermsLink';
import { Checkbox, TermsLink } from '../styles';
import { useUpdateCurrentUser } from '@/store/user';

export const ExtraTerms: StepComponent = ({ onReady, onSave }) => {
  const { t } = useTranslation('onboarding');
  const extraTermsLink = useExtraTermsLink();
  const [extraTerms, setExtraTerms] = useState(false);
  const { updateUser } = useUpdateCurrentUser();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      setExtraTerms(user.acceptedExtraTerms);
    }
  }, [user]);

  useEffect(() => {
    if (extraTerms) {
      onReady(OnboardingStep.ExtraTerms);
    }
  }, [extraTerms, onReady]);

  if (!extraTermsLink) {
    throw new Error('Could not resolve link to extra terms');
  }

  onSave(
    OnboardingStep.ExtraTerms,
    useCallback(async () => {
      const { errors } = await updateUser({ acceptedExtraTerms: true });
      if (errors) {
        throw getErrorResponseMessage(errors);
      }
    }, [extraTerms])
  );

  return (
    <>
      <Header1>{t('One more thing')}</Header1>
      <BodyLg>
        <Trans
          t={t}
          i18nKey={isRealm('kompetens') ? 'extra-terms-kompetens' : 'extra-terms-generic'}
          values={{ realm: getPrettyRealmName() }}
          components={[<TermsLink to={extraTermsLink} target="_blank" key={extraTermsLink} />]}
        />
      </BodyLg>
      <hr />
      <Checkbox
        id="extraTerms"
        checked={extraTerms}
        onChange={() => setExtraTerms(true)}
        size="large"
        disabled={extraTerms}
        label={t('I have read it!')}
      />
    </>
  );
};
