import { useMediaState } from '@udecode/plate-media/react';
import styled, { css } from 'styled-components';
import { RefObject } from 'react';
import { useSelected } from 'slate-react';

import { Resizable, ResizeHandle } from '@/component/customEditorV2/ui/elements/Resizable';
import { UploadedFileVideo } from '@/component/FileUpload/types';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { VideoFloatingToolbarButtons } from '@/component/customEditorV2/ui/elements/Video/VideoFloatingToolbarButton';

type UploadedVideoProps = {
  videoLink?: { embedUrl: string };
  uploadedVideoState?: UploadedFileVideo;
  playerElementId?: string;
  videoRef: React.RefObject<HTMLElement>;
  localRatio: number;
  onDeleteClick: () => void;
  onReplaceClick: () => void;
};

const SelectedContainerCSS = css<{ $selected?: boolean }>`
  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `};
`;

const IFrameContainer = styled.div<{ isInteractive?: boolean; $selected?: boolean }>`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  & iframe {
    ${({ isInteractive }) =>
      !isInteractive &&
      css`
        /**
          We need to allow selection of this element by plate to show floating bar & resize handlers.
          Thus, we need to disable it's own player pointer events.
          We only enable them back when the element is already selected (same as for document previews)
       */
        pointer-events: none;
      `}
  }

  ${SelectedContainerCSS};
`;

const VimeoContainer = styled.div<{ $localRatio: number; $selected?: boolean; $readOnly?: boolean }>`
  border-radius: 12px;
  padding-bottom: ${({ $localRatio }) =>
    /**
     * Math.floor seems to be giving a bit more relevant result, which can be bordered
     */
    css`
      ${Math.floor($localRatio * 100)}%
    `};
  pointer-events: ${({ $selected, $readOnly }) => ($selected || $readOnly ? undefined : 'none')};

  ${SelectedContainerCSS};
`;

export const UploadedVideo = ({
  videoLink,
  uploadedVideoState,
  playerElementId,
  videoRef,
  localRatio,
  onDeleteClick,
  onReplaceClick,
}: UploadedVideoProps): JSX.Element => {
  const selected = useSelected();
  const { readOnly, align = 'center' } = useMediaState();
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected });

  return (
    <Resizable
      $align={align}
      options={{
        align,
        readOnly,
      }}
    >
      {selected && <ResizeHandle options={{ direction: 'left' }} />}
      <div ref={setReference} style={{ width: '100%' }}>
        {videoLink && (
          <IFrameContainer isInteractive={selected || readOnly} $selected={selected}>
            <iframe
              src={videoLink?.embedUrl}
              frameBorder="0"
              allowFullScreen
              title="Video"
              style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
            />
          </IFrameContainer>
        )}
        {uploadedVideoState?.vimeoId && (
          <VimeoContainer
            id={playerElementId}
            ref={videoRef as RefObject<HTMLDivElement>}
            className="video"
            $selected={selected}
            $readOnly={readOnly}
            $localRatio={localRatio}
          />
        )}
      </div>
      {selected && <ResizeHandle options={{ direction: 'right' }} />}
      {!readOnly && (
        <div
          ref={setFloating}
          style={{
            ...style,
          }}
        >
          <VideoFloatingToolbarButtons onRemoveClick={onDeleteClick} onReplaceFile={onReplaceClick} />
        </div>
      )}
    </Resizable>
  );
};
