import { useTranslation } from 'react-i18next';

import { OnboardingStep } from './types';

export const useNextStepLabel = (currentStep: OnboardingStep): string => {
  const { t } = useTranslation('onboarding');
  return {
    [OnboardingStep.Hello]: t("Let's go"),
    [OnboardingStep.LFTerms]: t('Continue', { ns: 'common' }),
    [OnboardingStep.Terms]: t('Continue', { ns: 'common' }),
    [OnboardingStep.ExtraTerms]: t('Continue', { ns: 'common' }),
    [OnboardingStep.Profile]: t('Finish', { ns: 'common' }),
    [OnboardingStep.FinalStep]: t('Finish', { ns: 'common' }),
  }[currentStep];
};
