import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  margin-top: 2rem;
`;

export const Loader = styled.div`
  display: flex;
  margin: 0 auto;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 5px solid transparent;
  animation: spin 1.5s linear infinite;

  background: linear-gradient(${(p) => p.theme.colors.neutral.background}, ${(p) => p.theme.colors.neutral.background}),
    conic-gradient(from 0.15turn, ${(p) => p.theme.colors.neutral.background}, ${(p) => p.theme.tones.primary[70]});
  background-origin: border-box;
  background-clip: content-box, border-box;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
