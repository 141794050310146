import { MetaLabel } from '@/ui/Label';
import { makeNamespacedComponent } from '@/ui/utils';
import {
  Card as Card_,
  Labels,
  Description,
  Footer,
  Image,
  Overline,
  Progress,
  ProviderLogo,
  Title,
  TypeIcon,
  Loader,
  StatusIcon,
} from './atoms';

const Card = makeNamespacedComponent(Card_, {
  Labels,
  StatusIcon,
  Description,
  Footer: makeNamespacedComponent(Footer, { Progress, Label: MetaLabel }),
  Image,
  Overline,
  Progress,
  ProviderLogo,
  Title,
  TypeIcon,
  Loader,
});

export { Card };
