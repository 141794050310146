import React from 'react';

const hasDisplayName = (value: unknown): value is { displayName: string } => {
  return (
    (typeof value === 'object' || typeof value === 'function') &&
    value !== null &&
    !Array.isArray(value) &&
    typeof (value as { displayName?: string })?.displayName === 'string'
  );
};

type MakeNamespacedComponent = <Props extends Record<string, unknown>, Subcomponents extends Record<string, unknown>>(
  rootComponent: React.ComponentType<Props>,
  subcomponents: Subcomponents
) => React.ComponentType<Props> & Subcomponents;

export const makeNamespacedComponent: MakeNamespacedComponent = <R, C extends Record<string, unknown>>(
  rootComponent: R,
  subcomponents: C
) => {
  const namespacedComponent = rootComponent as R & C;
  Object.entries(subcomponents).forEach(([key, value]) => {
    (namespacedComponent as Record<keyof C, unknown>)[key as keyof C] = value as C[keyof C];

    // The names of all sub-components should reflect the structure of the composite.
    // See https://reactjs.org/docs/react-component.html#displayname
    if (hasDisplayName(rootComponent) && hasDisplayName(value)) {
      value.displayName = `${rootComponent.displayName}.${key}`;
    }
  });

  return namespacedComponent;
};
