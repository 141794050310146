/* eslint-disable import/no-unused-modules */
import React from 'react';

import { Icon } from '@/ui/Icon';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { IconWrapStyled, RootStyled, TitleText, TitleWrap } from '../styles';
import { DrawerItemType, PAGE_TYPE_TO_ICON } from '../types';
import { PageVariantDeprecated as PageVariant } from '@/administration/pages/Course/store/page/types';

export type ViewDrawerItemProps = {
  id: string;
  title: string;
  type: PageVariant;
  active?: boolean;
  onSelect: (id: string) => void;
  completed?: boolean;
};

const _ViewItem = ({ id, title, active, type, onSelect, completed }: ViewDrawerItemProps): JSX.Element => {
  const drawerItemType: DrawerItemType = completed ? 'completed' : type;

  return (
    <RootStyled onClick={() => onSelect(id)} active={!!active}>
      <TitleWrap>
        <IconWrapStyled type={drawerItemType}>
          <Icon icon={PAGE_TYPE_TO_ICON[drawerItemType]} />
        </IconWrapStyled>
        <TitleText>{title}</TitleText>
      </TitleWrap>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => <Skeleton width={312} height={36} />;

export const ViewItem = makeNamespacedComponent(_ViewItem, {
  LoadingState,
});
