import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getDaysUntilDate, shouldShowLearningExpirationMessage } from '@/utils/expirations';

interface Props {
  expiredAt?: string;
}

const StyledSpan = styled.span`
  margin-left: 2rem;
  margin-right: auto;
`;

export const ExpirationMessage = ({ expiredAt }: Props): React.ReactElement | null => {
  const { t } = useTranslation(['learning', 'catalogAdmin', 'builderAdmin']);

  if (!expiredAt) return null;

  const expirationDate = new Date(expiredAt);
  const showExpirationMessage = shouldShowLearningExpirationMessage(expirationDate);
  if (!showExpirationMessage) return null;

  const daysUntilExpiration = getDaysUntilDate(expirationDate);

  let message: string;
  if (daysUntilExpiration === 1) {
    message = t('mandatory-expires-singular', { count: daysUntilExpiration });
  } else if (daysUntilExpiration > 1) {
    message = t('mandatory-expires-plural', { count: daysUntilExpiration });
  } else {
    message = t('mandatory-expired');
  }

  return <StyledSpan>{message}</StyledSpan>;
};
