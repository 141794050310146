/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { FC } from 'react';
import { ParagraphPlugin, createNodesWithHOC } from '@udecode/plate-common/react';
import { WithDraggableOptions, withDraggable as withDraggablePrimitive } from '@udecode/plate-dnd';
import { HEADING_KEYS } from '@udecode/plate-heading';

import { ImagePlugin } from '@/component/customEditorV2/plugins/custom/imagePlugin';
import { VideoPlugin } from '@/component/customEditorV2/plugins/custom/videoPlugin';
import { DocumentPlugin } from '@/component/customEditorV2/plugins/custom/documentPlugin';
import { DraggableProps, Draggable } from '@/component/customEditorV2/ui/pluginsAddons/DragAndDrop/Draggable';
import { QuizQuestionPlugin } from '@/component/customEditorV2/plugins/custom/quizQuestionPlugin';

export const withDraggable = (
  Component: FC,
  options?: WithDraggableOptions<Partial<Omit<DraggableProps, 'children' | 'editor' | 'element'>>>
) => withDraggablePrimitive<DraggableProps>(Draggable, Component, options as any);

export const withDraggablesPrimitive = createNodesWithHOC(withDraggable);

export const withDraggables = (components: any) => {
  return withDraggablesPrimitive(components, [
    {
      keys: [
        VideoPlugin.key,
        ImagePlugin.key,
        DocumentPlugin.key,
        ParagraphPlugin.key,
        QuizQuestionPlugin.key,
        'ul',
        'ol',
        HEADING_KEYS.h1,
        HEADING_KEYS.h2,
        HEADING_KEYS.h3,
        HEADING_KEYS.h4,
        HEADING_KEYS.h5,
        HEADING_KEYS.h6,
      ],
    },
  ]);
};
