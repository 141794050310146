import styled from 'styled-components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Divider } from '@/administration/pages/Course/modals/SettingsModal/styles';
import { RichTextDisplayer } from '@/ui/RichTextDisplayer';
import { LiveEventCalendarBlock } from '@/pages/LiveEvent/LiveEventCalendarBlock';
import { LiveEventLink } from '@/pages/LiveEvent/LiveEventLink';
import { UpNextLearning } from '@/administration/pages/Journey/common/atoms/UpNextLearning';
import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';

type LiveEventDescriptionProps = Pick<
  LiveEvent,
  | 'id'
  | 'room'
  | 'title'
  | 'teaser'
  | 'address'
  | 'endDate'
  | 'isOnline'
  | 'startDate'
  | 'dateAndTime'
  | 'myEnrollment'
  | 'hasEnded'
>;

const CommonTitle = styled.h3`
  margin-bottom: 24px;
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 32px 0;
`;

const UpNextContainer = styled.div`
  margin-top: 64px;
  width: 100%;
`;

export const LiveEventDescription = ({
  id,
  title,
  endDate,
  startDate,
  dateAndTime,
  teaser,
  isOnline,
  address,
  myEnrollment,
  room,
  hasEnded,
}: LiveEventDescriptionProps): JSX.Element => {
  const { id: courseId } = useParams<{ id: string }>();

  const parsedCourseId = courseId ? parseInt(courseId) : NaN;

  const { nextItem, journeyId } = useJourneyEnhancedNavigation({
    id: parsedCourseId,
  });

  return (
    <>
      <LiveEventCalendarBlock
        id={id}
        room={room}
        title={title}
        teaser={teaser}
        address={address}
        endDate={endDate}
        startDate={startDate}
        withTitle
        dateAndTime={dateAndTime}
        myEnrollment={myEnrollment}
      />
      <StyledDivider />
      <CommonTitle>{isOnline ? 'Link to join' : 'Location'}</CommonTitle>
      <LiveEventLink address={address} isOnline={isOnline} linkMaxWidth="35vw" />
      {!!teaser && (
        <>
          <StyledDivider />
          <CommonTitle>Event description</CommonTitle>
          <RichTextDisplayer content={teaser} />
        </>
      )}
      {!!nextItem && !hasEnded && (
        <UpNextContainer>
          <UpNextLearning
            learningCardProps={{
              objectId: nextItem.contentId,
              title: nextItem.content?.title,
              imageUrl: nextItem.content?.imageURL,
              type: nextItem.content?.metadata?.type,
              level: nextItem.content?.metadata?.level,
              totalEffort: nextItem.content?.plannedDuration,
            }}
            isActionActive
            actionHref={`${nextItem.content?.curations[0]?.launchURLPath}}`}
            parentJourneyId={journeyId}
          />
        </UpNextContainer>
      )}
    </>
  );
};
