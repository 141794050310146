import React, { CSSProperties, useState, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Descendant, Node, Location } from 'slate';
import { Slate, RenderElementProps } from 'slate-react';
import { useTranslation } from 'react-i18next';
import { isKeyHotkey } from 'is-hotkey';

import { CustomEditable } from '@/component/customEditor/components/Editor';
import { Toolbar } from '@/component/customEditor/components/Toolbar/Toolbar';
import { Leaf } from './components/Leaf';
import { Element } from './components/Element';
import { migrateDataModel } from './migration/dataModelMigration';
import { CustomNode, EditorConfig } from './types';
import { useEditor } from './hooks/useEditor';
import { toggleMark } from './utils';
import { CustomSlateElementType, TextFormats } from '@/types/slate';
import { EditorAssistant } from './components/EditorAssistant';
import { EditorAssistantContext } from './components/EditorAssistant/EditorAssistantContext';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

export type CustomEditorHandle = {
  reset: () => void;
};

interface EditorProps {
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  hasChanged?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  hideToolbar?: boolean;
  disabled?: boolean;
  config?: EditorConfig;
  required?: boolean;
  minimized?: boolean;
  supportsEditorAssistant?: boolean;
  supportsImages?: boolean;
  size?: 'large';
  $fullSize?: boolean;
  spaceId?: number;
}

const HOTKEYS: { [key: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const LOCAL_SLATE_ELEMENTS_TYPES: CustomSlateElementType[] = ['imageLoading'];
/**
 * React editor implementation of Slate.js is kinda tricky.
 * Though the <Slate> component looks like a contolled one (it takes value & onChange as props),
 * it's half controlled by inner logic, where it's very tight coupled with editor.selection.
 * @link https://github.com/ianstormtaylor/slate/issues/3575#issuecomment-923250127 (contributor)
 *
 * What it means for us, is that we cannot fully control it.
 *
 * The "value" prop is really only meant to be used as an initial value.
 * When applying a different value prop (one that was not returned from onChange)
 * later on this can cause all kinds of problems because editor.selection.
 *
 * @link https://github.com/ianstormtaylor/slate/issues/3575#issuecomment-923227427
 *
 * If we want, for example, reset the editor to initial state after successful form submition from
 * one of the parent components, we cannot simply pass the '' as a new value, it will have no effect,
 * since <Slate> expects the value to be equal to the one, returned from it's onChange(value) function.
 *
 * So, in order to perform something like that, we need to reset selections of the editor as well.
 *
 * Current functionality exposes the reset() method using useImperativeHandle()
 * to allow parent reset the editor state manually when needed.
 *
 * Though it's well known that imperative code
 * is a bad practice in most cases here is seems to be a perfect fit insead of some messy useEffect().
 */
export const CustomEditor = forwardRef<CustomEditorHandle, EditorProps>(
  (
    {
      value,
      onChange,
      required,
      hideToolbar,
      hasChanged,
      disabled,
      minimized,
      config,
      size,
      style,
      $fullSize,
      placeholder,
      supportsEditorAssistant,
      supportsImages,
      spaceId,
    },
    ref
  ) => {
    const { t } = useTranslation('discussions');
    const [editorValue, setValue] = useState<Node[]>([]);
    const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    /**
     * Editor assistant section
     */
    const [editorAssistantButtonToggled, setEditorAssistantButtonToggled] = useState(false);
    const [editorAssistantCharge, setEditorAssistantCharge] = useState(0);
    const editorAssistantFeatureEnabled = useFeatureEnabled(FEATURE.UI_RICH_TEXT_AI);
    const canUseEditorAssistant = supportsEditorAssistant && editorAssistantFeatureEnabled;

    useEffect(() => {
      /**
       * This is the only time when value from props is actually used (see comments above)
       */
      const initialValue = migrateDataModel(value);
      setValue(initialValue);
    }, []);

    useImperativeHandle(ref, () => ({
      reset: (): void => {
        /**
         * Setting up an empty sting as a new value,
         * will be migrated to appropriate editor default value
         */
        const initialValue = migrateDataModel('');

        /**
         * Reseting the selection to allow the following setValue
         */
        editor.selection = {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        };

        /**
         * Reseting the value
         */
        setValue(initialValue);
      },
    }));

    const editor = useEditor(config);
    const renderLeaf = useCallback((props: React.ComponentProps<typeof Leaf>) => <Leaf {...props} />, []);
    const renderElement = useCallback((props: RenderElementProps): JSX.Element => <Element {...(props as any)} />, []);

    useEffect(() => {
      if (isLinkPopoverOpen) {
        // See explanation at src/component/customEditor/logic/links.ts
        editor.blurSelection = editor.selection as Location;
      }
    }, [isLinkPopoverOpen]);

    const togglePopover = (isOpened: boolean): void => {
      setIsReadOnly(isOpened);
      setIsLinkPopoverOpen(isOpened);
    };

    const handleOnChange = (newValue: Node[]) => {
      setValue(newValue);

      try {
        /**
         * We need to restrict all editor-only (like loading state) element from going upper, than editor state
         *
         * Example:
         * We should not let image loading element to be saved,
         * since user can forget to save after loading has finished & just refresh the page.
         * It can lead to "hanging" loading elements, since they won't be replaced
         */
        const filteredNodes = newValue.filter(
          (node) => !LOCAL_SLATE_ELEMENTS_TYPES.includes((node as CustomNode)?.type as CustomSlateElementType)
        );

        const valueString = JSON.stringify(filteredNodes);

        if (!onChange) return;

        onChange(valueString);
      } catch (error) {
        console.error('Failed to parse the input value', error);
      }
    };

    const renderToolbar = ({ minimized }: { minimized?: boolean }) => {
      if (hideToolbar) return null;

      return (
        <Toolbar
          editor={editor}
          hasChanged={hasChanged}
          isLinkPopoverOpen={isLinkPopoverOpen}
          setIsLinkPopoverOpen={togglePopover}
          minimized={minimized}
          editorAssistantEnabled={canUseEditorAssistant}
          supportsImages={supportsImages}
          spaceId={spaceId}
        />
      );
    };

    /**
     * @link https://github.com/ianstormtaylor/slate/pull/4540#issuecomment-951380551
     * @link https://github.com/ianstormtaylor/slate/issues/4710#issuecomment-1019495512
     *
     * @link https://github.com/ianstormtaylor/slate/pull/4540 (main discussion tread)
     *
     * Starting from v0.71 of Slate.js, we have to manually override value by ourself, instead of sending it down as a prop. Brrrr...
     */
    editor.children = editorValue as Descendant[];

    return (
      <Slate editor={editor} initialValue={editorValue as Descendant[]} onChange={handleOnChange}>
        <EditorAssistantContext.Provider
          value={{
            totalCharge: editorAssistantCharge,
            enabled: editorAssistantButtonToggled,
            setTotalCharge: (charge) => setEditorAssistantCharge(charge),
            setEnabled: (toggled) => setEditorAssistantButtonToggled(toggled),
          }}
        >
          {renderToolbar({ minimized })}
          {canUseEditorAssistant && editorAssistantButtonToggled && <EditorAssistant />}
          <CustomEditable
            id="custom-editor"
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            aria-required={required}
            placeholder={placeholder || t('Text (required)')}
            readOnly={isReadOnly}
            disabled={disabled}
            size={size}
            style={style}
            $minimized={minimized}
            $fullSize={$fullSize}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isKeyHotkey(hotkey)(event as unknown as KeyboardEvent)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey] as TextFormats;
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </EditorAssistantContext.Provider>
      </Slate>
    );
  }
);

CustomEditor.displayName = 'CustomEditor';
