import React from 'react';
import styled from 'styled-components';

import { Icon } from '@/ui/Icon';
import { Cell } from './Cell';

type Variant = 'narrow' | 'medium' | 'wide';

const ExpanderCell = styled(Cell)<{ variant: Variant }>`
  cursor: pointer;
  padding: 16px 0 16px 16px;
  padding-left: ${(p) =>
    ({
      narrow: 16,
      medium: 32,
      wide: 64,
    }[p.variant])}px;
`;

const Expander = styled(Icon).attrs({ icon: 'caret-down' })<{ expanded?: boolean }>`
  width: 12px;
  height: 12px;
  color: ${(p) => p.theme.colors.neutral.onContainer};
  transition: transform 0.2s linear;
  transform: rotate(${(p) => (p.expanded ? 0 : -90)}deg);
  ${ExpanderCell}:hover > & {
    transform: rotate(0deg);
  }
`;

export const RowExpanderCell = ({
  expanded = false,
  variant = 'wide',
  onClick,
}: {
  expanded?: boolean;
  onClick?: () => void;
  variant?: Variant;
}): JSX.Element => (
  <ExpanderCell variant={variant} onClick={onClick}>
    <Expander expanded={expanded} />
  </ExpanderCell>
);
