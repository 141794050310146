import React from 'react';

import { Skeleton } from '@/ui/Skeleton';
import { RootStyled, SelectedWeekHeader, NavigationContainerStyled } from '../../variants/styles';

export const LoadingState = (): JSX.Element => (
  <RootStyled>
    <SelectedWeekHeader>
      <Skeleton width={328} height={14} />
    </SelectedWeekHeader>
    <NavigationContainerStyled>
      <Skeleton width={328} height={72} />
    </NavigationContainerStyled>
  </RootStyled>
);
