import { useState, useMemo } from 'react';

import { Catalog, useCardCatalog } from '@/store/catalog';
import {
  getCategoriesFromCatalog,
  getFlatSubjectsFromCards,
  getTopProvidersFromCatalog,
} from '@/components/Navbar/utils/helpers';
import { providers } from '@/store/providers';
import { sortByProp } from '@/utils/sort';
import { useIsFeatureEnabled } from '@/feature-toggles';
import { CategoryFromCatalog, SubjectFromCatalog, TopProvider } from '@/types/categories';

interface CategoriesFromCatalog {
  selectedCategoryId: number | null;
  setSelectedCategoryId: (arg: number | null) => void;
  isLoadingCatalog: boolean;
  sortedCategoriesFromCatalog: CategoryFromCatalog[];
  topProviders: TopProvider[];
  shouldDisplayAllProvidersButton: boolean;
  subjectsFromCatalog?: SubjectFromCatalog[];
  selectedCategoryName?: string;
  catalog: Catalog;
}

const providersToDisplay = 6;

export const useCategoriesFromCatalog = (): CategoriesFromCatalog => {
  const { catalog, loading: isLoadingCatalog } = useCardCatalog();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const isFeatureEnabled = useIsFeatureEnabled();

  const topProviders = getTopProvidersFromCatalog(catalog.cards, providers, providersToDisplay);
  const providersPerRealm = providers.filter((provider) => !provider.feature || isFeatureEnabled(provider.feature));
  const shouldDisplayAllProvidersButton = providersPerRealm.length > providersToDisplay;

  const sortedCategoriesFromCatalog = useMemo(() => {
    const flatSubjects = getFlatSubjectsFromCards(catalog.cards);
    const categoriesFromCatalog = getCategoriesFromCatalog(flatSubjects);
    const categoriesWithSortedSubjects = categoriesFromCatalog.map((category) => ({
      ...category,
      subjects: category.subjects.sort((a, b) => sortByProp(a, b, 'subjectName')),
    }));
    return categoriesWithSortedSubjects.sort((a, b) => sortByProp(a, b, 'categoryName'));
  }, [catalog.cards]);

  const subjectsFromCatalog = sortedCategoriesFromCatalog.find(
    (category) => category.categoryId === selectedCategoryId
  )?.subjects;
  const selectedCategoryName = sortedCategoriesFromCatalog.find(
    (category) => category.categoryId === selectedCategoryId
  )?.categoryName;

  return {
    selectedCategoryId,
    setSelectedCategoryId,
    isLoadingCatalog,
    sortedCategoriesFromCatalog,
    topProviders,
    shouldDisplayAllProvidersButton,
    subjectsFromCatalog,
    selectedCategoryName,
    catalog,
  };
};
