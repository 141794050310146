import React, { useState, useRef } from 'react';
import clsx from 'clsx';

import { Team } from '../../administration/pages/ProductEditor/component/CourseTeams';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Avatar } from '../Avatar';
import { displayRealm } from '@/utils/misc';
import { Button } from '@/components/Button/Button';
import { Media, MediaBody, MediaFigure } from '@/components/MediaObject/MediaObject';
import { useSpaceMembersQueryRemote } from '@/store/v2';

interface Props {
  team: Team;
  spaceId?: number;
}

export const PostTeamParticipants = ({ team, spaceId }: Props): JSX.Element => {
  const { data: users, loading } = useSpaceMembersQueryRemote({
    skip: !spaceId,
    variables: { filter: { idIN: team.map((t) => t.id), spaceIdIN: [spaceId || 0] }, pagination: { limit: -1, page: 1 } },
  });

  const [showMenu, setShowMenu] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  useOnClickOutside(showMenu, setShowMenu, node);

  return (
    <div ref={node} className={clsx('dropdown', 'is-right', showMenu && 'is-active')} onClick={() => setShowMenu(!showMenu)}>
      <div className="dropdown-trigger">
        <Button $toggled={showMenu} $loading={loading} aria-haspopup="true" aria-controls="dropdown-menu" $icon="users">
          Members
        </Button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ minWidth: '20em' }}>
        <div className="dropdown-content">
          {!loading &&
            users?.spaceMembers.data.map((user, index) => (
              <div className="dropdown-item" key={index}>
                <Media>
                  <MediaFigure>
                    <figure className="image is-48x48">
                      <Avatar profileImage={user.profileImage} />
                    </figure>
                  </MediaFigure>
                  <MediaBody>
                    <p className="overline is-marginless">{displayRealm(user.realm)}</p>
                    <p className="header4 is-size-6">{user.username}</p>
                    <p className="caption is-marginless">{user.email}</p>
                  </MediaBody>
                </Media>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
