/* eslint-disable import/no-unused-modules */
import React from 'react';

import { SelectedWeekHeader, HelpButton } from '../../variants/styles';
import { utcToLocalDateСustomFormat } from '@/utils/time';
import { useWeekNavigationsContext } from '../../context';
import { isCurrentWeek } from '../../../../variants/WeeklyLoopChart';

interface WeeksNavigationHeaderProps {
  onHelpClick: () => void;
}

export const WeeksNavigationHeader = ({ onHelpClick }: WeeksNavigationHeaderProps): JSX.Element => {
  const { selectedWeek, weeks } = useWeekNavigationsContext();

  return (
    <>
      {selectedWeek && (
        <SelectedWeekHeader isCurrentWeek={isCurrentWeek(selectedWeek)}>
          <p>
            <span>
              Week {selectedWeek.weekNumber}/{weeks.length}
            </span>{' '}
            · {utcToLocalDateСustomFormat(selectedWeek.weekStartTimestamp, 'MMM D')} -{' '}
            {utcToLocalDateСustomFormat(selectedWeek.weekEndTimestamp, 'MMM D')}
          </p>
          <HelpButton onClick={onHelpClick} />
        </SelectedWeekHeader>
      )}
    </>
  );
};
