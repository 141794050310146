import { Editor } from 'slate';

import { TextFormats } from '@/types/slate';

const isMarkActive = (editor: Editor, format: TextFormats) => Boolean(Editor.marks(editor)?.[format]);

export const toggleMark = (editor: Editor, format: TextFormats): void => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
