import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';

import { RadioContext } from '../RadioContext';
import { RootStyled, RadioStyled, LabelStyled } from './styles';
import { ButtonProps } from './types';

export const Button = ({ label, className, disabled, value }: ButtonProps): JSX.Element => {
  const { currentValue, onChange } = useContext(RadioContext);

  const checked = value === currentValue;
  const id =
    typeof label === 'string'
      ? `radio-button-${label.replace(/\s+/g, '')}-${value.replace(/\s+/g, '')}`
      : `radio-button-${uuid()}-${value.replace(/\s+/g, '')}`;

  return (
    <RootStyled className={className}>
      <RadioStyled type="radio" id={id} checked={checked} disabled={disabled} onChange={onChange} value={value} />
      <LabelStyled htmlFor={id} disabled={disabled} checked={checked}>
        {label}
      </LabelStyled>
    </RootStyled>
  );
};
