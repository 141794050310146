import { Block, BlockType, TextBlock } from '@/administration/pages/Course/store/block/types';
import { RBlock, RBlockInput, RBlockV2Type } from '@/store/v2';
import { createTextBlock, transformTextBlockFromRemote, transformTextBlockToRemoteInput } from './transformText';
import { createResultBlock } from './transformResult';
import { createReactionBlock } from './transformReaction';
import { createQuestionBlock, transformQuestionBlockFromRemote, transformQuestionBlockToRemoteInput } from './transformQuestion';
import {
  createPracticalAssessmentTextBlock,
  transformPracticalAssessmentTextBlockFromRemote,
  transformPracticalAssessmentTextBlockToRemoteInput,
} from './transformPracticalAssessmentText';
import {
  createPracticalAssessmentDocumentBlock,
  transformPracticalAssessmentDocumentBlockFromRemote,
  transformPracticalAssessmentDocumentBlockToRemoteInput,
} from './transformPracticalAssessmentDocument';
import { createReflectionBlock, transformReflBlockFromRemote, transformReflBlockToRemoteInput } from './transformReflection';

export const createBlock = (type: BlockType): Block => {
  switch (type) {
    case BlockType.RESULT:
      return createResultBlock();
    case BlockType.REACTION:
      return createReactionBlock();
    case BlockType.QUESTION:
      return createQuestionBlock();
    case BlockType.PRACTICAL_ASSESSMENT_TEXT:
      return createPracticalAssessmentTextBlock();
    case BlockType.PRACTICAL_ASSESSMENT_DOCUMENT:
      return createPracticalAssessmentDocumentBlock();
    case BlockType.REFLECTION:
      return createReflectionBlock();
    case BlockType.TEXT:
    default:
      return createTextBlock();
  }
};

export const transformBlockToRemoteInput = (block: Block, rPageId?: number, order?: number): RBlockInput => {
  switch (block.type) {
    case BlockType.QUESTION:
      return transformQuestionBlockToRemoteInput(block, rPageId, order);
    case BlockType.PRACTICAL_ASSESSMENT_TEXT:
      return transformPracticalAssessmentTextBlockToRemoteInput(block, rPageId, order);
    case BlockType.PRACTICAL_ASSESSMENT_DOCUMENT:
      return transformPracticalAssessmentDocumentBlockToRemoteInput(block, rPageId, order);
    case BlockType.REFLECTION:
      return transformReflBlockToRemoteInput(block, rPageId, order);
    case BlockType.TEXT:
    default:
      return transformTextBlockToRemoteInput(block as TextBlock, rPageId, order);
  }
};

export const transformBlockFromRemote = (rBlock?: RBlock): Block => {
  switch (rBlock?.type) {
    case BlockType.QUESTION:
      return transformQuestionBlockFromRemote(rBlock);
    case BlockType.TEXT:
      return transformTextBlockFromRemote(rBlock);
    case RBlockV2Type.PracticalAssessmentTask:
      // PRACTICAL_ASSESSMENT_TASK block type is general and not used as it is
      // Specific block type PRACTICAL_ASSESSMENT_TEXT & PRACTICAL_ASSESSMENT_DOCUMENT is taken from data obj
      if (rBlock.data.type === BlockType.PRACTICAL_ASSESSMENT_TEXT) {
        return transformPracticalAssessmentTextBlockFromRemote(rBlock);
      }

      if (rBlock.data.type === BlockType.PRACTICAL_ASSESSMENT_DOCUMENT) {
        return transformPracticalAssessmentDocumentBlockFromRemote(rBlock);
      }

      // PRACTICAL_ASSESSMENT_TEXT block is used as a fallback format
      return transformPracticalAssessmentTextBlockFromRemote(rBlock);
    case BlockType.REFLECTION:
      return transformReflBlockFromRemote(rBlock);
    default:
      return createBlock(BlockType.TEXT);
  }
};
