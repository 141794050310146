/* eslint-disable import/no-unused-modules */
import { useEffect, useRef, useState } from 'react';
import { LottieRefCurrentProps } from 'lottie-react';

import { AnimationVariant, LottieAnimationHook } from './types';

type AnimationFilePath = `./lottie-animations/${AnimationVariant}.json`;

const VARIANT_TO_ANIMATION_FILE_PATH: Record<AnimationVariant, AnimationFilePath> = {
  confetti: './lottie-animations/confetti.json',
  'confetti-page': './lottie-animations/confetti-page.json',
};

const VARIANT_TO_ANIMATION_INTERVAL: Record<AnimationVariant, number> = {
  confetti: 100,
  'confetti-page': 500,
};

export const useLottieAnimation: LottieAnimationHook = ({ variant, loop = false, autoplay = false }) => {
  const [animationData, setAnimationData] = useState(null);
  const [animationReady, setAnimationReady] = useState(false);
  const [isPlaying, setPlaying] = useState(autoplay);

  useEffect(() => {
    const pathToAnimationFile = VARIANT_TO_ANIMATION_FILE_PATH[variant];

    import(`${pathToAnimationFile}`).then((data) => {
      setAnimationData(data);
    });
  }, [variant]);

  const ref = useRef<LottieRefCurrentProps | null>(null);
  const lottie = ref.current;

  const play = () => {
    setPlaying(true);
    lottie?.play();
  };
  const stop = () => {
    setPlaying(false);
    lottie?.stop();
  };
  const pause = () => {
    setPlaying(false);
    lottie?.pause();
  };

  const handlePlayingUpdate = (playing: boolean) => {
    setPlaying(playing);
  };

  useEffect(() => {
    const animationInterval = VARIANT_TO_ANIMATION_INTERVAL[variant];
    if (!animationReady) {
      const interval = setInterval(() => {
        if (ref.current) {
          setAnimationReady(true);
        }
      }, animationInterval);
      return () => clearInterval(interval);
    }
  }, [animationReady]);

  const animationProps = {
    animationData,
    loop,
    autoplay,
    lottieRef: ref,
    isPlaying,
    onPlayingUpdate: handlePlayingUpdate,
    animationReady,
  };

  return {
    animationReady,
    ref,
    lottie,
    play,
    stop,
    pause,
    animationProps,
  };
};
