import styled from 'styled-components';

export const RootStyled = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
  display: inline-flex;
`;

export const OptionsStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
`;

export const LegendStyled = styled.legend`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  padding: 0;
  margin-bottom: 8px;
`;
