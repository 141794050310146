import { Link } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { LinkOverline, Separator } from './BreadcrumbsStyles';

interface Props {
  link?: string;
  title: string;
}

export const BreadcrumbItem = ({ title, link }: Props): ReactElement => (
  <>
    <Link to={link || '#'}>
      <LinkOverline as="span">{title}</LinkOverline>
    </Link>
    <Separator>/</Separator>
  </>
);
