import { Editor, Location, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { CustomElement } from '@/types/slate';
import { CustomNode } from '../types';

const BREAK_ELEMENT: CustomElement = { type: 'paragraph', children: [{ text: '' }] };

export const insertImage = (editor: Editor & ReactEditor, url: string, imageLoadingId?: string): void => {
  if (imageLoadingId) {
    const imageLoadingElements = Editor.nodes(editor, {
      at: [], // Path of Editor
      match: (node) => 'imageLoading' === (node as CustomNode).type && (node as CustomNode).url === imageLoadingId,
      // mode defaults to "all", so this also searches the Editor's children
    }).next().value;

    const targetLocation = (imageLoadingElements as CustomNode[])[1];
    Transforms.select(editor, targetLocation as Location);
    const image: CustomNode & { url: string } = { type: 'image', url, caption: '', children: [{ text: '' }] };

    Editor.insertFragment(editor, [image, BREAK_ELEMENT]);
  } else {
    const text = { text: '' };
    const image: CustomElement & { url: string } = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, [image, BREAK_ELEMENT]);
  }
};

export const handleImageCaptionChange = ({
  editor,
  element,
  newCaption,
}: {
  newCaption: string;
  editor: Editor & ReactEditor;
  element: CustomElement & { url: string };
}): void => {
  const ownPath = ReactEditor.findPath(editor, element);

  Transforms.setNodes(
    editor,
    { ...element, caption: newCaption },
    {
      at: ownPath,
    }
  );
};

export const insertImageLoading = (editor: Editor & ReactEditor, fileId: string): void => {
  const text = { text: '' };
  const image: CustomElement & { url: string } = { type: 'imageLoading', url: fileId, children: [text] };
  Transforms.insertNodes(editor, image);
};

export const removeImageLoading = (editor: Editor & ReactEditor, fileId: string): void => {
  const imageLoadingElements = Editor.nodes(editor, {
    at: [], // Path of Editor
    match: (node) => 'imageLoading' === (node as CustomNode).type && (node as CustomNode).url === fileId,
    // mode defaults to "all", so this also searches the Editor's children
  }).next().value;

  const targetLocation = (imageLoadingElements as CustomNode[])[1] as Location;

  Transforms.delete(editor, {
    at: targetLocation,
  });
};

export const withImages = (editor: Editor & ReactEditor): Editor & ReactEditor => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  return editor;
};
