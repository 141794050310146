/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled from 'styled-components';

import { Grid } from '@/ui/Grid/atoms';
import { IconPropType } from '@/ui/Icon';
import { ReactionCard } from '../ReactionCard';
import { ReactionId } from '@/administration/pages/Course/store/block/types';

export type Reaction = { title: string; icon: IconPropType; id: ReactionId };

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const HeadingBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleStyled = styled.h2`
  all: unset;
  ${({ theme }) => theme.typography.title2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const SubtitleStyled = styled.p`
  all: unset;
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ReactionsPage = ({
  reactions,
  selectedReactionId,
  onReactionSelected,
}: {
  reactions: Reaction[];
  selectedReactionId: ReactionId | undefined;
  onReactionSelected: (id: ReactionId) => void;
}): JSX.Element => {
  const hasSelectedReaction = reactions.some((reaction) => reaction.id === selectedReactionId);

  return (
    <RootStyled>
      <HeadingBlockStyled>
        <TitleStyled>What will you do next?</TitleStyled>
        <SubtitleStyled>Choose one option to complete the course</SubtitleStyled>
      </HeadingBlockStyled>
      <Grid rowGap="medium">
        {reactions.map(({ title, icon, id }) => (
          <Grid.Item
            key={String(id)}
            mobile={4}
            mobileLandscape={4}
            tablet={4}
            desktop={4}
            desktopLarge={4}
            widescreen={4}
            fullHD={4}
          >
            <ReactionCard
              icon={icon}
              title={title}
              disabled={hasSelectedReaction && id !== selectedReactionId}
              selected={id === selectedReactionId}
              onClick={() => !hasSelectedReaction && onReactionSelected(id)}
            />
          </Grid.Item>
        ))}
      </Grid>
    </RootStyled>
  );
};
