import React from 'react';

import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { Container, Icon, Text } from '../../atoms';
import { MetaLabelProps } from './types';

const _MetaLabel = ({ icon, text, className }: MetaLabelProps): JSX.Element => (
  <Container variant="meta" className={className}>
    <Icon variant="meta" icon={icon} />
    <Text variant="meta" content={text} />
  </Container>
);

const LoadingState = (): JSX.Element => (
  <Container variant="meta">
    <Skeleton width={12} height={12} />
    <Skeleton width={46} height={16} />
  </Container>
);

const MetaLabel = makeNamespacedComponent(_MetaLabel, {
  LoadingState,
});

export { MetaLabel };
