import React, { FC } from 'react';
import { Element as SlateElement } from 'slate';
import { RenderElementProps } from 'slate-react';

import { Header2, Header3 } from '@/components/Typography/Typography';
import { Link } from './Toolbar/sections/styledComponents/LinkSection';
import { CustomElement } from '@/types/slate';
import { ImageElement } from './elements/ImageElement';

export interface ElementProps extends RenderElementProps {
  element: SlateElement & { url: string };
}

export const Element: FC<ElementProps> = ({ children, element, attributes }) => {
  switch (element.type) {
    case 'text':
      return <span {...attributes}>{children}</span>;
    case 'heading':
      return <Header2 {...attributes}>{children}</Header2>;
    case 'heading2':
      return <Header3 {...attributes}>{children}</Header3>;
    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'link':
      return (
        <Link {...attributes} href={element.url}>
          {children}
        </Link>
      );
    case 'image': {
      return (
        <div {...attributes} contentEditable={false}>
          <ImageElement element={element as CustomElement & { url: string }} />
          {children}
        </div>
      );
    }
    case 'imageLoading':
      return (
        <div {...attributes} contentEditable={false}>
          <ImageElement.LoadingState />
          {children}
        </div>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};
