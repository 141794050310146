import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLearningCollectionItemRoute } from '@/pages/learning/LearningCollection/LearningCollectionPage';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { mapProviderLink } from '@/store/providers';
import { Card } from '@/types/learning/card';
import { http } from '@/utils/lib/http';
import { getLink } from '@/utils/card';
import { config } from '@/config';
import { LinkConfig, TransformedCard } from '../types';

// TODO: move to @/config
const CANVAS_INSTANCE_URL = 'https://collegial.instructure.com';

type CardWithExtras = Card & Pick<TransformedCard, 'isApproved' | 'customLink'>;
type ResolveLink = (card: CardWithExtras) => LinkConfig;

export const useResolveLink: ResolveLink = (card) => {
  const link = getLink(card);
  const { customLink, provider, entityId, isApprovalRequired, isApproved } = card;

  let isInternalLink = true;
  let isCanvasLink = false;
  let linkUrl = useLearningCollectionItemRoute(card.isExternal ? '' : link) ?? null;
  const [canvasLinkUrl, setCanvasLinkUrl] = useState<string | null>(null);

  if (customLink && customLink.includes(CANVAS_INSTANCE_URL)) {
    isCanvasLink = true;
  }

  // link non-curated Coursera & Pluralsight content directly to Coursera (even if they do not have access)
  if (!linkUrl && !isCanvasLink && provider && customLink) {
    linkUrl = mapProviderLink(provider, customLink);
    isInternalLink = false;
  }

  // Retrieve canvas URL if necessary
  const { t } = useTranslation('auth');
  const { openSnackbar } = useContext(SharedSnackbarContext);
  useEffect(() => {
    if (!isCanvasLink) return;
    let abort = false;
    // TODO: don't assemble the url here; create a helper function `retrieveCanvasRedirectUrl(entityId: string)` instead (in @/config)
    http
      .get<{ redirectUrl: Location }>(`${config.baseApiRoute}/provider/canvas/${entityId}`)
      // TODO: will stringifying work?
      // `toString` is probably a regular Object.prototype.toString,
      // unlike Location.prototype.toString (https://developer.mozilla.org/en-US/docs/Web/API/Location/toString)
      .then(({ redirectUrl }) => {
        !abort && setCanvasLinkUrl(redirectUrl.toString());
      })
      .catch(() => openSnackbar({ type: SnackbarType.DANGER, message: t('canvas-error') }));
    return () => {
      abort = true;
    };
  }, [isCanvasLink]);

  // Canvas link
  if ((isCanvasLink && isApproved && isApprovalRequired) || (isCanvasLink && !isApprovalRequired)) {
    return {
      type: 'canvas',
      url: canvasLinkUrl,
    };
  }
  // Internal link
  else if ((isInternalLink && linkUrl) || (linkUrl && isCanvasLink)) {
    return {
      type: 'internal',
      url: linkUrl,
    };
  }
  // External link
  else if (!isInternalLink) {
    return {
      type: 'external',
      url: linkUrl,
    };
  }
  // No link 🤔
  return {
    type: null,
    url: null,
  };
};
