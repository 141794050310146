import React from 'react';
import { useTranslation } from 'react-i18next';

import { Variant as VariantType } from '../../types/learning/learning-catalog';
import { utcToLocalDate } from '@/utils/time';
import { Icon } from '@/components/Icon';
import { BodyMd, Overline } from '@/components/Typography/Typography';
import { StyledEventBox } from '@/components/CourseCardLegacy/styles/CourseCardEvent';

interface VariantProps {
  variant: VariantType;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  noOverflow?: boolean;
}

export const Variant = ({ variant, ...rest }: VariantProps): JSX.Element => {
  const { t } = useTranslation('variants');
  return (
    <StyledEventBox isVariant noOverflow {...rest}>
      <Overline>
        {variant.name} {!variant.enabled && t('(disabled)', { ns: 'common' })}
      </Overline>
      <BodyMd as="div">
        {variant.validFrom || variant.validTo ? (
          <>
            <Icon icon="calendar" /> {variant.validFrom ? utcToLocalDate(variant.validFrom) : ''}
            {variant.validTo && variant.validFrom && ' — '}
            {variant.validTo ? utcToLocalDate(variant.validTo) : ''}
            {variant.meta && variant.meta.METHOD === 'open-ended' && t('open-ended')}
          </>
        ) : (
          <br />
        )}
        <div>
          {variant.meta?.LOCATION ? (
            <>
              <Icon icon="map-marker-alt" /> {variant.meta.LOCATION}
            </>
          ) : (
            <br />
          )}
        </div>
        <div>
          {variant.meta?.METHOD && variant.meta.METHOD !== 'open-ended' ? (
            <>
              {t('Method')}: {t(variant.meta.METHOD)}
            </>
          ) : (
            <br />
          )}
        </div>
      </BodyMd>
    </StyledEventBox>
  );
};
