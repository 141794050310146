import clsx from 'clsx';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { GraphQLFormattedError } from 'graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddToCalendar } from '@/component/AddToCalendar/AddToCalendar';
import { Event } from '@/component/AddToCalendar/utils';
import { EventSession } from '@/component/EventSession/EventSession';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { Icon } from '@/components/Icon';
import { useDeleteEnrollment, useEnrollSelf } from '@/store/enrollments';
import { useLanguages } from '@/store/languages';
import { AttendedType, LearningType, Location, WaitlistType } from '@/types/learning';
import { getTranslatedErrorMessages } from '@/utils/gqlErrors';
import { minutesToRelativeTime } from '@/utils/time';
import { trackEnrollEventClicked, trackUnEnrollEventClicked, trackWaitlistEventClicked } from '@/utils/tracking/events';
import './hero.scss';
import { RightButtons } from './RightButtons';
import { BackToParentButton } from '@/pages/learning/CourseDetails/components/BackToParentButton';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface Props {
  id: number;
  title: string;
  teaser: string;
  type: LearningType;
  locations: Location[];
  editLink?: string;
  imageUrl?: string;
  onEnrolled: () => void;
  isArchived: boolean;
  effort?: number;
  languageId: number;
}

export const EventHero = ({
  title,
  teaser,
  type,
  editLink,
  imageUrl,
  locations,
  onEnrolled,
  isArchived,
  id,
  effort,
  languageId,
}: Props): JSX.Element => {
  const now = dayjs(new Date());
  const { t, i18n } = useTranslation(['learning', 'serverMessages', 'catalogAdmin', 'builderAdmin']);
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const [enrollSelf, { loading: loadingEnrollSelf }] = useEnrollSelf();
  const [deleteEnrollment, { loading: loadingDeleteEnrollment }] = useDeleteEnrollment();
  const { languages } = useLanguages();
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>();

  const updatedLocations = locations?.map((l) => ({
    ...l,
    localizedDate: `${dayjs(l.startDate).locale(i18n.language).format('LLL')} - ${dayjs(l.endDate)
      .locale(i18n.language)
      .format('LT')}`,
  }));
  const enrolledLocation = locations?.find((l) => l.myEnrollment && l.myEnrollment.attended !== AttendedType.NOT_ATTENDED);
  const filteredLocations = updatedLocations?.filter((l) =>
    enrolledLocation ? enrolledLocation.id === l.id : dayjs(l.endDate).isAfter(now)
  );
  const isEnrolledLocationWaitlisted = enrolledLocation?.myEnrollment?.attended === AttendedType.WAITLISTED;
  const hasEnded = enrolledLocation && dayjs(enrolledLocation.endDate || enrolledLocation.startDate).isBefore(now);
  const language = languages?.find((l) => l.id === languageId);

  useEffect((): void => {
    if (filteredLocations.length === 1) {
      setSelectedLocation(filteredLocations[0]);
    }
  }, [locations]);

  const handleEnroll = async (): Promise<void> => {
    if (!locations) return;

    let err: ReadonlyArray<GraphQLFormattedError> = [];

    if (enrolledLocation?.myEnrollment) {
      const { errors } = await deleteEnrollment({ variables: { input: { enrollmentId: enrolledLocation.myEnrollment.id } } });
      if (errors) {
        err = errors;
      } else {
        trackUnEnrollEventClicked();
        openSnackbar({
          message: t('learning::Cancelled enrollment successfully'),
          type: SnackbarType.SUCCESS,
          isDismissive: true,
        });
      }
    } else if (selectedLocation?.id) {
      const isWaitlisted = isCapacityReached(selectedLocation);
      const { errors } = await enrollSelf({ variables: { input: { locationId: selectedLocation.id } } });
      if (errors) {
        err = errors;
      } else {
        isWaitlisted ? trackWaitlistEventClicked() : trackEnrollEventClicked();
        openSnackbar({
          message: isWaitlisted
            ? t('learning::You will be added if a seat becomes available')
            : t('learning::Enrolled successfully'),
          type: SnackbarType.SUCCESS,
          isDismissive: true,
        });
      }
    }
    if (err.length > 0) {
      const messages = getTranslatedErrorMessages(err);
      return openSnackbar({
        message: t('serverMessages::generic|:error-occurred', { message: messages.join('') }),
        type: SnackbarType.DANGER,
      });
    }

    onEnrolled();
  };

  const icalEvent: Event | false = !!enrolledLocation?.endDate && {
    learningUrl: document.URL,
    teaser: teaser,
    end: enrolledLocation.endDate,
    learningId: id,
    enrollmentId: enrolledLocation.myEnrollment?.id || 0,
    locationAddress: enrolledLocation.address,
    locationRoom: enrolledLocation.room,
    start: enrolledLocation.startDate,
    title,
  };

  const isCapacityReached = (l?: Location): boolean => {
    if (!l || (!l.capacity && l.capacity !== 0)) return false;

    const totalEnrollmentsCount =
      (l.stats?.enrolledCount || 0) + (l.stats?.attendedCount || 0) + (l.stats?.notAttendedCount || 0);

    return totalEnrollmentsCount >= l.capacity;
  };

  const isLocationDisabled = (l: Location): boolean => {
    const isWaitlistEnabled = l.waitlistType === WaitlistType.AUTOMATIC;
    const isCapacityFull = isCapacityReached(l);
    const isEnrolled = l.id === enrolledLocation?.id;
    if (isEnrolled) {
      return true;
    } else if (isCapacityFull && isWaitlistEnabled) {
      return false;
    }
    return isCapacityFull;
  };

  const getEnrollmentText = (): string => {
    let text = '';
    if (enrolledLocation) {
      text = isEnrolledLocationWaitlisted ? 'learning::Cancel waitlist' : 'learning::Cancel enrollment';
    } else if (isCapacityReached(selectedLocation)) {
      text =
        selectedLocation?.waitlistType === WaitlistType.AUTOMATIC ? 'learning::Join waitlist' : 'learning::No seats available';
    } else {
      text = 'learning::Enroll';
    }
    return t(text);
  };

  return (
    <section
      className={clsx('hero', 'is-learning', 'is-padded', imageUrl && 'has-image')}
      styleName="has-background-image"
      style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : undefined }}
    >
      <div className="hero-body">
        <div className="container">
          <Box>
            {(isArchived || hasEnded) && (
              <div className="message is-warning is-large is-marginless">
                <div className="message-header">
                  {t('builderAdmin::event-has-been', { context: isArchived ? 'archived' : hasEnded ? 'ended' : '' })}
                </div>
              </div>
            )}
            <div className="grid">
              <div className="gc gc-8-d gc-7-t gc-12-l gc-12-p">
                <div styleName="preamble">
                  <BackToParentButton />
                  <h1 className="header1">{title}</h1>
                  <div className="preamble">{teaser}</div>
                </div>
              </div>
              <div className="gc gc-4-d gc-5-t gc-12-l gc-12-p">
                <div styleName="right-block">
                  <p className="overline">{t('Event', { ns: 'catalog' })}</p>
                  <div styleName="meta" className="caption">
                    {!!effort && (
                      <div>
                        <Icon icon="clock" fixedWidth />
                        <span>{minutesToRelativeTime(effort)}</span>
                      </div>
                    )}
                    <div>
                      <Icon icon="language" fixedWidth />
                      <span>{t(language?.name || '', { ns: 'languages' })}</span>
                    </div>
                    <div>
                      <Icon icon="laptop" fixedWidth />
                      <span>{t(type.toLowerCase(), { ns: 'productType' })}</span>
                    </div>
                    <div>
                      <Icon icon="calendar" fixedWidth />
                      <span>{t('scheduled', { ns: 'variants' })}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!!updatedLocations && (
              <div styleName="enroll-box">
                <div className="grid">
                  {filteredLocations
                    .sort((a, b) => dayjs(a.startDate).unix() - dayjs(b.startDate).unix())
                    .map((l, key) => (
                      <div className="gc gc-4-d gc-4-t gc-6-l gc-12-p" key={key}>
                        <EventSession
                          location={l}
                          onChange={() => setSelectedLocation(l)}
                          isDisabled={isLocationDisabled(l)}
                          isChecked={l.id === selectedLocation?.id || l.id === enrolledLocation?.id}
                          isSeminar={type === LearningType.SEMINAR}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div styleName="enroll-box" className="is-flex is-justified-space-between">
              <ButtonList>
                <Button
                  $type={enrolledLocation ? 'secondary' : 'primary'}
                  $loading={loadingEnrollSelf || loadingDeleteEnrollment}
                  onClick={handleEnroll}
                  disabled={
                    isArchived ||
                    (!enrolledLocation && !selectedLocation?.id) ||
                    (enrolledLocation && dayjs(enrolledLocation.endDate).isBefore(now)) ||
                    (!enrolledLocation &&
                      selectedLocation &&
                      selectedLocation.waitlistType === WaitlistType.DISABLED &&
                      isCapacityReached(selectedLocation))
                  }
                  $icon={enrolledLocation ? 'times' : 'pen'}
                >
                  {getEnrollmentText()}
                </Button>
                {icalEvent &&
                  enrolledLocation &&
                  !isEnrolledLocationWaitlisted &&
                  dayjs(enrolledLocation.endDate).isAfter(now) &&
                  !isArchived && <AddToCalendar event={icalEvent} />}
              </ButtonList>
              <RightButtons id={id} isEvent entity="learning" editLink={editLink} />
            </div>
          </Box>
        </div>
      </div>
    </section>
  );
};
