import React from 'react';

import { Informer } from '../../atoms/Informer';
import { DYNAMIC_ICON_DOWN, DYNAMIC_ICON_UP } from '../../constants';
import { DynamicDataIconStyled, DynamicDataStyled, DynamicDataTextStyled } from './styles';
import { DynamicInformerProps } from './types';

export const DynamicInformer = ({ dynamicData: { direction, text }, ...rest }: DynamicInformerProps): JSX.Element => (
  <Informer
    {...rest}
    titleSuffix={
      <DynamicDataStyled>
        <DynamicDataIconStyled icon={direction === 'up' ? DYNAMIC_ICON_UP : DYNAMIC_ICON_DOWN} direction={direction} />
        <DynamicDataTextStyled direction={direction}>{text}</DynamicDataTextStyled>
      </DynamicDataStyled>
    }
  />
);
