import { format } from 'date-fns-tz';

export function formatYMD(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function formatTime(date: Date): string {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

export function formatFullDate(date: Date): string {
  return `${formatYMD(date)} ${formatTime(date)}`;
}

export function formatMMMD(date: Date): string {
  const month = date.toLocaleString('en-us', { month: 'short' });
  const day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day}`;
}

export function getPreviousDay(currentDate: string | undefined): Date | undefined {
  if (!currentDate) return undefined;

  const endAtDate = new Date(currentDate);
  const previous = new Date(endAtDate.getTime());
  previous.setDate(endAtDate.getDate() - 1);

  return previous;
}

export function formatStringAsDate(currentDate: string | undefined): Date | undefined {
  if (!currentDate) return undefined;

  return new Date(currentDate);
}

export function nextDayAsStringWithDefaultTimezone(currentDate: Date | undefined): string | undefined {
  if (!currentDate) return undefined;

  const nextDay = new Date(currentDate.getTime());
  nextDay.setDate(currentDate.getDate() + 1);

  return `${formatYMD(nextDay)}T00:00:00.000Z`;
}

export function formatDateWithDefaultTimezone(currentDate: Date | undefined): string | undefined {
  if (!currentDate) return undefined;

  return `${formatYMD(currentDate)}T00:00:00.000Z`;
}

export function getCurrentDateWithDefaultTimezone(): Date {
  return new Date(formatYMD(new Date()));
}

/**  Returns the date of the current week's Monday relative to the date provided,
 A week is from Monday to Sunday
 */
export const getMondayForCurrentWeek = (date?: Date): Date => {
  const selectedDate = date || getCurrentDateWithDefaultTimezone();
  const day = selectedDate.getDay();
  const diff = selectedDate.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(selectedDate.setDate(diff));
};

export const formatTimeDependingOnUserTimezone = ({
  startTime,
  endTime,
  formatOptions,
}: {
  startTime: Date | string;
  endTime: Date | string;
  formatOptions?: {
    startFormat?: string;
    endFormat?: string;
    showTimezone?: boolean;
  };
}): string => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const startFormat = formatOptions?.startFormat || 'eeee, dd MMMM, yyyy · HH:mm';
  const endFormat = formatOptions?.endFormat || 'HH:mm';

  // Receiving current user timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Forming of dates
  const formattedStart = format(startDate, startFormat, { timeZone: userTimeZone });
  const formattedEnd = format(endDate, endFormat, { timeZone: userTimeZone });

  // Formatting of user's timezone
  const userOffset = new Date().getTimezoneOffset();
  const userOffsetHours = Math.floor(userOffset / 60);
  const userOffsetMinutes = userOffset % 60;
  const userOffsetSign = userOffsetHours > 0 ? '-' : '+';
  const userOffsetStr = `${userOffsetSign}${String(Math.abs(userOffsetHours)).padStart(2, '0')}:${String(
    Math.abs(userOffsetMinutes)
  ).padStart(2, '0')}`;

  if (formatOptions?.showTimezone) {
    return `${formattedStart} - ${formattedEnd} (UTC${userOffsetStr})`;
  }

  return `${formattedStart} - ${formattedEnd}`;
};
