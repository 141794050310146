import { ModularLearningDetailsV2 } from '@/pages/ModularLearning/ModularLearningDetails/ModularLearningDetailsV2';
import { UseCourseViewerV2Result } from '@/administration/pages/Course/store/courseViewer/useCourseViewerV2';

export const CourseDetails = (props: UseCourseViewerV2Result): JSX.Element => {
  return (
    <ModularLearningDetailsV2
      title={props.course?.title || ''}
      spaceId={props.course?.spaceId}
      loading={props.loadingLearning}
      learningId={Number(props.course?.id)}
      activities={props.activities}
      xapiSession={props?.xapiSession}
      isDrawerOpen={props.isDrawerOpen}
      activitiesTotalCount={props.activitiesTotalCount}
      activityActiveCount={props.activityActiveCount}
      previousActiveActivityIndex={props.previousActiveActivityIndex}
      isSavedToMyLearning={props.isCourseSaved}
      onNewActivityClick={props.onSetActivityActiveByIndex}
      onUpNavigationClick={props.onSetPrevActivityActive}
      onDownNavigationClick={props.onSetNextActivityActive}
      onSaveClick={props.onSaveCourse}
      onSetDrawerOpen={props.setDrawerOpen}
      onSetActivityActive={props.onSetActivityActive}
      onActivityChange={props.onActivityChange}
      treeActivities={props.treeActivities}
    />
  );
};
