import React from 'react';

import {
  StyledLogoContentWrapper,
  StyledNavBanner,
  StyledNavInner,
  StyledNavV2,
  StyledNavWrapper,
} from '@/components/Navbar/styles';
import { NavLogo } from '@/components/Navbar/components/NavLogo';
import { NavProfile } from '@/components/Navbar/components/NavProfile';
import { NavMobileMenu } from './components/mobile/NavMobileMenu';
import { checkAdminView } from '@/utils/checkAdminView';
import { checkManagerView } from '@/utils/checkManagerView';
import { NavAdminContent } from '@/components/Navbar/components/NavAdminContent';
import { NavContent } from './components/NavContent';
import { NavSearchV2 } from './components/NavSearch/NavSearchV2';
import { useCurrentUser, isUserOnboarded, isUserAuthenticated } from '@/store/currentUser';

export const Navbar = (): JSX.Element => {
  const { user } = useCurrentUser();
  const isAdminView = checkAdminView();
  const isManagerView = checkManagerView();
  const userRealm = user?.realm;
  const windowRealm = window.realm;
  const isDifferentRealms = userRealm !== windowRealm && !!userRealm;

  const isAuthenticated = isUserAuthenticated(user);
  const isOnboarded = isUserOnboarded(user);
  return (
    <StyledNavWrapper>
      {(!!user?.isDebugging || isDifferentRealms) && (
        <StyledNavBanner>
          <span>
            {!!user?.isDebugging && (
              <span aria-label="eyes" role="img">
                👀 Currently logged in as: {user?.email}.
              </span>
            )}
            {isDifferentRealms && (
              <span>
                {' '}
                You are visiting realm:{' '}
                <em>
                  <b>{windowRealm}</b>
                </em>
                , but your expected realm is:{' '}
                <em>
                  <b>{userRealm}</b>
                </em>
              </span>
            )}
          </span>
        </StyledNavBanner>
      )}
      <StyledNavV2 justifyContent={isAuthenticated ? 'center' : undefined}>
        <StyledNavInner justifyContent={isAuthenticated && isOnboarded ? 'space-between' : 'center'}>
          <StyledLogoContentWrapper css={isAdminView ? `width: 100%` : undefined}>
            <NavLogo />
            {isAuthenticated && isOnboarded && !isAdminView && !isManagerView && <NavContent />}
          </StyledLogoContentWrapper>
          {isAuthenticated && isOnboarded && <NavMobileMenu />}
          {isAuthenticated && isOnboarded && <NavProfile />}
        </StyledNavInner>
        {isAuthenticated && isOnboarded && !isAdminView && !isManagerView && <NavSearchV2 />}
        {isAuthenticated && isOnboarded && isAdminView && !isManagerView && <NavAdminContent />}
      </StyledNavV2>
    </StyledNavWrapper>
  );
};
