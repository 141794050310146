import { trackEvent } from './countly';

export const trackFilteringMyLearning = (): void => trackEvent({ key: 'Clicked filter dropdown on my learning page' });
export const trackRemovingItemFromSavedByMe = (): void => trackEvent({ key: 'Removed item from saved by me tab' });
export const trackOpeningModalForCancellingEnrollment = (): void => trackEvent({ key: 'Opened modal for cancelling enrollment' });
export const trackOpeningModalForAddingOtherLearningTime = (): void =>
  trackEvent({ key: 'Opened modal for adding other learning time' });

export const trackClickingMyLearningTab = (tabName: string): void =>
  trackEvent({ key: 'Clicked tab on my learning page', segmentation: { tab: tabName } });
