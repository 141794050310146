import React, { useContext, useMemo } from 'react';
import { getParser } from 'bowser';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { useMutationRegister, useRegistrationCompleted } from '@/pages/learning/Learning/schema/queries';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { convertTitle } from '@/utils/misc';
import { useWindowFocus } from '@/hooks/useWindowFocus';
import { ExpirationMessage } from '@/pages/learning/Learning/components/Hero/ExpirationMessage';
import { useModalContext } from '@/components/Modal/ModalContext';
import { Icon } from '@/components/Icon';
import { IconPropType } from '@/ui/Icon';
import { ButtonType } from '@/components/Button/types';
import './eLearningOpenButton.scss';
import { getDaysUntilDate } from '@/utils/expirations';

interface Props {
  learningId: number;
  eLearningUrl?: string;
  title: string;
  expiredAt?: string;
  isLearningCompleted: boolean;
  isLearningOnGoing: boolean;
  onLearningRefetch: () => void;
}

export const ELearningOpenButton = ({
  eLearningUrl,
  learningId,
  title,
  expiredAt,
  isLearningCompleted,
  isLearningOnGoing,
  onLearningRefetch,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['learning']);

  const browser = getParser(window.navigator.userAgent);
  const isIEOrLegacyEdge = ['EdgeHTML', 'Trident'].includes(browser.getEngineName());
  const isELearning = Boolean(eLearningUrl);

  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { showConfirmationModal } = useModalContext();
  const {
    registrationCompleted,
    refetch: refetchRegistrationCompleted,
    loading: loadingRegistrationCompleted,
  } = useRegistrationCompleted(learningId, !isELearning);
  const [sendRegisterToLearning, { loading: isRegistering }] = useMutationRegister(learningId);

  useWindowFocus((hasFocus) => {
    if (hasFocus) {
      refetchRegistrationCompleted();
      onLearningRefetch();
    }
  });

  const learningItem = useMemo(() => {
    if (isLearningOnGoing) {
      return {
        status: 'ongoing',
        icon: 'sync-alt',
        btnText: 'Continue',
        btnType: 'primary',
      };
    }
    if (isLearningCompleted) {
      return {
        status: 'completed',
        icon: 'check',
        btnText: 'View Completed',
        btnType: 'secondary',
      };
    }
    return {
      btnText: 'Start',
      btnType: 'primary',
    };
  }, [isLearningOnGoing, isLearningCompleted]);

  if (!isELearning) {
    return <span />;
  }

  const learningViewLink = `/learning/e-learning/${learningId}/${convertTitle(title)}/view`;

  const register = async () => {
    showConfirmationModal({
      customHeaderText: t('retake-modal-header'),
      message: t('retake-modal-message'),
      action: async () => {
        if (isRegistering) return;

        const result = await sendRegisterToLearning();
        if (result.errors) {
          openSnackbar({
            message: t('registration failed'),
            type: SnackbarType.DANGER,
          });
        } else {
          window.open(learningViewLink, '_blank');
        }
      },
    });
  };

  const isLoading = (isELearning && loadingRegistrationCompleted) || isRegistering;

  if (isIEOrLegacyEdge && learningId === 124) {
    return (
      <>
        <Button disabled>{t('Open')}</Button>
        <div className="message is-warning is-marginless">
          <div className="message-body">{t('Your browser is currently not supported. Please use Chrome or Edge-Chromium.')}</div>
        </div>
      </>
    );
  }

  const hasExpired = Boolean(expiredAt && getDaysUntilDate(new Date(expiredAt)) < 1);

  return (
    <>
      <ButtonList>
        {((isLearningCompleted && !hasExpired) || isLearningOnGoing) && (
          <div styleName="learning-status">
            <Icon styleName="learning-icon" icon={learningItem.icon as IconPropType} />
            <p>{learningItem.status}</p>
          </div>
        )}
        {!hasExpired && (
          <RouterLinkButton
            to={learningViewLink}
            target="_blank"
            rel="noopener noreferrer"
            $type={learningItem.btnType as ButtonType}
            $loading={isLoading}
          >
            {t(learningItem.btnText)}
          </RouterLinkButton>
        )}

        {registrationCompleted && (
          <Button onClick={register} $type="primary" $loading={isRegistering}>
            {t('Retake')}
          </Button>
        )}
      </ButtonList>
      <ExpirationMessage expiredAt={expiredAt} />
    </>
  );
};
