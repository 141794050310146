import React from 'react';

import { Select } from '../..';
import { SelectProps } from '../../atoms/Select/types';
import { Input } from '@/ui/TextField';
import { IconPropType } from '@/ui/Icon';

type TextFieldSelectProps = {
  /**
   * Placeholder will be displayed before any value is selected
   */
  placeholder?: string;
  /**
   * Label to display when a value *is* selected. If none is provided,
   * the component will use the selected value(s) as its label.
   */
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
  leadingIcon?: IconPropType;
  error?: {
    message: string;
  } | null;
} & SelectProps;

export const TextFieldSelect = ({
  children,
  multiple,
  value,
  onChange,
  placeholder,
  disabled,
  leadingIcon,
  label,
  fullWidth,
  className,
  error,
}: TextFieldSelectProps): JSX.Element => (
  <Select onChange={onChange} value={value} multiple={multiple} className={className} fullWidth={fullWidth}>
    <Select.Header fullWidth={fullWidth}>
      {({ toggleShowOptions, shouldShowOptions }) => (
        <Input
          error={error}
          disabled={disabled}
          readOnly // We only want user input from options menu
          label={label}
          onChange={toggleShowOptions}
          onClick={toggleShowOptions}
          onTrailingIconClick={toggleShowOptions}
          trailingIcon={shouldShowOptions ? 'xmark' : 'chevron-down'}
          value={(Array.isArray(value) ? value.join(',') : value) || placeholder || ''}
          fullWidth={fullWidth}
          leadingIcon={leadingIcon}
        />
      )}
    </Select.Header>
    {children}
  </Select>
);
