import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from '@/ui/Layout';
import { Button } from '@/ui/Button';
import { HeroStyled, TitleStyled, ContentStyled } from './styles';
import { Participants } from './components/Participants';
import { useAssignmentUsers } from '@/store/assignments';
import { ParticipantProps } from './components/Participant';

const LIST_CHUNK_SIZE = 20;

export const ParticipantsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const assignmentId = id ? parseInt(id) : undefined;
  const [limit, setLimit] = useState<number>(LIST_CHUNK_SIZE);

  const { data: usersData, loading } = useAssignmentUsers({ assignmentId, limit });

  const onLoadMore = () => {
    setLimit((prev) => prev + LIST_CHUNK_SIZE);
  };

  const participants = usersData?.users?.map(({ profileImage, email, username: name, effort }) => ({
    name,
    email,
    imageSrc: profileImage,
    timeSpent: effort,
  })) as ParticipantProps[];

  return (
    <>
      <Layout.ContentSection marginTop>
        <HeroStyled>
          <Button variant="ghost" icon="arrow-left" onClick={() => navigate(-1)}>
            Back
          </Button>
          <TitleStyled>Colleagues</TitleStyled>
        </HeroStyled>
      </Layout.ContentSection>
      <Layout.ContentSection marginTop marginBottom>
        <ContentStyled>
          {loading ? (
            <Participants.LoadingState />
          ) : (
            <Participants participants={participants} total={usersData?.total || 0} onLoadMoreItems={onLoadMore} />
          )}
        </ContentStyled>
      </Layout.ContentSection>
    </>
  );
};
