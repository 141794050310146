import { XAPISession } from './useXAPISession';
import { useXAPIClient } from '@/store/xapi/useXAPIClient';
import { StateId } from '@/store/xapi/useXAPIState';
import { ActivityDefinitionType, ResultProps } from '@/store/xapi/model';
import { useXAPIStates } from '@/store/xapi/useXAPIStates';

export type XAPIReaction = {
  answers: Record<number, ResultProps | undefined>;
  isInitialized: boolean;
  saveAnswer: (page: { id: number; name: string; type: ActivityDefinitionType }, answer: ResultProps) => Promise<void>;
  loading: boolean;
  error?: Error;
};

export type StateType = ResultProps;

export const useXAPIQuestionAnswers = (
  learning: { id: number; name: string },
  pageIds: number[],
  session?: XAPISession
): XAPIReaction => {
  const {
    data: stateData,
    loading: stateLoading,
    isInitialized: isStateInitialized,
    setState: setXAPIState,
    error: stateError,
  } = useXAPIStates<StateType>(
    pageIds.map((pageId) => `${StateId.QUESTION_ANSWER}:urn:collegial:page:${pageId}`),
    session
  );

  const {
    isInitialized: isXAPIClientInitialized,
    loading: loadingXAPIClient,
    setLearningQuestionAnswer,
  } = useXAPIClient(session);

  const saveAnswer = async (
    page: { id: number; name: string; type: ActivityDefinitionType },
    questionAnswer: ResultProps
  ): Promise<undefined> => {
    if (!isXAPIClientInitialized || !isStateInitialized) return;

    setLearningQuestionAnswer({
      learningObject: {
        id: learning.id,
        name: learning.name,
      },
      questionObject: {
        id: page.id,
        name: page.name,
        type: page.type,
      },
      questionAnswer,
    });

    await setXAPIState(`${StateId.QUESTION_ANSWER}:urn:collegial:page:${page.id}`, questionAnswer);

    return;
  };

  const answers = pageIds.reduce(
    (acc, pageId) => ({ ...acc, [pageId]: stateData[`${StateId.QUESTION_ANSWER}:urn:collegial:page:${pageId}`] }),
    {}
  );

  return {
    answers,
    isInitialized: isStateInitialized,
    saveAnswer,
    loading: stateLoading || loadingXAPIClient,
    error: stateError,
  };
};
