import styled from 'styled-components';

import { Provider } from '@/store/providers';
import { Icon, IconPropType } from '@/ui/Icon';
import { variant } from './sizing';

const iconMap: Partial<Record<Provider, IconPropType | null>> = {
  pluralsight: 'c-pluralsight-square',
  collegial: 'c-collegial-square',
  coursera: 'c-coursera-square',
  udacity: 'c-udacity-square',
  learnifier: 'c-collegial-square', // learnifier intentionally uses the collegial logo.
  linkedin: 'c-linkedin-square',
  finanskompetens: 'c-finanskompetens-square',
  imd: 'c-imd-square',
  'engineer-four-point-zero': 'c-engineer-four-point-zero-square',
  'tekniska-college': 'c-tekniska-college-square',
  mölk: 'c-mölk-square',
  'kompetens-express': 'c-kompetens-express-square',
  intermezzon: 'c-intermezzon-square',
  lexicon: 'c-lexicon-square',
  autoliv: 'c-autoliv-square',
};

type Props = WithClassNameProp<{
  provider: Provider;
}>;

export const ProviderLogo = ({ provider, className }: Props): JSX.Element | null =>
  iconMap[provider] ? (
    <ProviderBox className={className}>
      <ProviderIcon icon={iconMap[provider]!} />
    </ProviderBox>
  ) : null;

const ProviderBox = styled.div`
  position: absolute;
  z-index: 2; // Make sure TypeIcon is below
  background: ${p => p.theme.colors.neutral.background};
  border: 1px solid ${p => p.theme.colors.neutral.outline1};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.shadow.primary[2]};

  ${variant.small} {
    display: none;
  }

  ${variant.large} {
    width: 100px;
    height: 80px;
    top: 155px;
    transform: translateY(calc(-100% + 8px));
    left: 16px;
  }
`;

const ProviderIcon = styled(Icon)`
  color: ${p => p.theme.colors.neutral.onBackground};
  height: 50%;
`;
