import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Modal } from '@/ui/Modal';
import { LiveEventForm } from './LiveEventForm';
import { useCreateLearningJourneyLiveEvent } from '../../../store/editLearnings/useCreateLearningJourneyLiveEvent';
import { RLocationType } from '@/store/v2';
import { DnDLearningCardUnordered } from '../../../store/editLearnings/useDnDLearningCards';
import { convertDateWithCustomTimezone, getRangeBetweenDatesInMs, millisecondsToMinutes } from '@/utils/time';
import { useUpdateLearningJourneyLiveEvent } from '../../../store/editLearnings/useUpdateLearningJourneyLiveEvent';
import { SharedSnackbarContext } from '@/component/SharedSnackbar/SharedSnackbar';

type LiveEventModalMode = 'add' | 'edit';

type LiveEventModalProps = {
  onClose: () => void;
  onCreate: (card: DnDLearningCardUnordered) => void;
  onUpdate: () => void;
  open: boolean;
  spaceId: number;
  mode: LiveEventModalMode;
  defaultValues?: Partial<FormSchema>;
  learningId?: number;
  locationId?: number;
};

export type FormSchema = {
  coverImageURL?: string;
  title: string;
  description: string;
  timezone: number;
  startsAtTime: string;
  endsAtTime: string;
  date: string;
  format: RLocationType;
  location?: string;
  calendarURL?: string;
};

export enum TextFieldName {
  CoverImageURL = 'coverImageURL',
  Title = 'title',
  Description = 'description',
  Timezone = 'timezone',
  Date = 'date',
  StartsAtTime = 'startsAtTime',
  EndsAtTime = 'endsAtTime',
  Format = 'format',
  Location = 'location',
  CalendarURL = 'calendarURL',
}

const ModalContent = styled.div`
  max-height: 800px;
  height: calc(100vh - 330px);
  overflow: scroll;
`;

export const LiveEventModal = ({
  onClose,
  onCreate,
  onUpdate,
  open,
  spaceId,
  mode,
  defaultValues,
  learningId,
  locationId,
}: LiveEventModalProps): JSX.Element => {
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const form = useForm<FormSchema>();

  useEffect(() => {
    const defaultTimezone =
      typeof defaultValues?.timezone === 'number' ? defaultValues?.timezone : new Date().getTimezoneOffset();

    const defaultStartsAtTime = defaultValues?.startsAtTime
      ? convertDateWithCustomTimezone({
          date: new Date(defaultValues?.startsAtTime),
          customTimezoneOffsetInMinutes: defaultTimezone,
          convertionDirection: 'fromCustomTimezone', // converting it back from specified default timezone
        }).toISOString()
      : undefined;

    const defaultEndsAtTime = defaultValues?.endsAtTime
      ? convertDateWithCustomTimezone({
          date: new Date(defaultValues?.endsAtTime),
          customTimezoneOffsetInMinutes: defaultTimezone, // converting it back from specified default timezone
          convertionDirection: 'fromCustomTimezone',
        }).toISOString()
      : undefined;

    form.reset({
      ...defaultValues,
      title: defaultValues?.title || '',
      format: defaultValues?.format || RLocationType.Online,
      timezone: defaultTimezone,
      startsAtTime: defaultStartsAtTime,
      endsAtTime: defaultEndsAtTime,
    });
  }, [defaultValues]);

  const { createLearningJourneyLiveEvent, loading: loadingCreateLearningJourneyLiveEvent } = useCreateLearningJourneyLiveEvent();
  const { updateLearningJourneyEvent, loading: loadingUpdateLearningJourneyLiveEvent } = useUpdateLearningJourneyLiveEvent();

  const loading = loadingCreateLearningJourneyLiveEvent || loadingUpdateLearningJourneyLiveEvent;

  const _handleSubmit = async (data: FormSchema) => {
    const { timezone, startsAtTime, endsAtTime, title, coverImageURL, description, format, location, calendarURL } = data;

    const customTimezoneOffsetInMinutes = Number(timezone); // initially it comes as a string from form select

    const startsAtDateWithOffset = convertDateWithCustomTimezone({
      date: new Date(startsAtTime),
      customTimezoneOffsetInMinutes,
    });

    const endsAtDateWithOffset = convertDateWithCustomTimezone({
      date: new Date(endsAtTime),
      customTimezoneOffsetInMinutes,
    });

    const plannedDurationInMinutes = millisecondsToMinutes(
      getRangeBetweenDatesInMs(endsAtDateWithOffset, startsAtDateWithOffset)
    );

    const liveEventMutationPayload = {
      title,
      customTimezoneOffsetInMinutes,
      plannedDuration: plannedDurationInMinutes,
      startDate: startsAtDateWithOffset,
      endDate: endsAtDateWithOffset,
      address: (format === RLocationType.InPerson ? location : calendarURL) || '',
      type: format,
      bannerURL: coverImageURL,
      description: description,
    };

    if (mode === 'add') {
      await createLearningJourneyLiveEvent(spaceId, liveEventMutationPayload, (card) => {
        if (card) {
          onCreate(card);
        }
      });

      openSnackbar({ message: 'Live event created', isDismissive: true });
    } else if (mode === 'edit' && learningId && locationId) {
      await updateLearningJourneyEvent(
        spaceId,
        learningId,
        {
          ...liveEventMutationPayload,
          locationId,
        },
        () => {
          onUpdate();
        }
      );

      openSnackbar({ message: 'Live event updated', isDismissive: true });
    }
  };

  const handleSubmit = form.handleSubmit(_handleSubmit);

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open]);

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} size="mobileLandscape">
      <Modal.Title>{mode === 'add' ? 'New live event' : 'Edit live event'}</Modal.Title>
      <Modal.Contents>
        <FormProvider {...form}>
          <ModalContent>
            <form onSubmit={handleSubmit}>
              <LiveEventForm spaceId={spaceId} />
            </form>
          </ModalContent>
        </FormProvider>
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={handleClose}>Cancel</Modal.Action>
        <Modal.Action action={handleSubmit} variant="primary" loading={loading}>
          {mode === 'add' ? 'Create' : 'Save'}
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
