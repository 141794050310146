import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useRootContext } from '@/store/RootContext';
import { Loader } from '@/component/Loader';
import { useCurrentUserAccess } from '@/store/currentUser';

export const SuperAdminRoute = (): JSX.Element => {
  const { isAuthenticated, loading } = useRootContext();
  const { isSuperAdmin } = useCurrentUserAccess();
  const location = useLocation();

  if (loading) return <Loader />;

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />;
  }
  if (!isSuperAdmin) {
    return <Navigate to={{ pathname: '/' }} state={{ from: location }} />;
  }

  return <Outlet />;
};
