import { CourseCardV2 } from '@/components/CourseCardLegacy/v2/CourseCard';
import { Flex } from '@/components/Layout/Flex';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { CampusCardLayoutWrapperV2, CampusCardSectionHeaderContainerV2, CampusCardSectionHeaderV2, Section } from './styles';

export type LayoutProps = {
  count?: number;
  title: string;
  button: JSX.Element;
  cards?: JSX.Element[];
  defaultButtonLayout?: boolean;
  useCustomCardLayout?: boolean;
};

const _SectionBaseLayout = ({
  count,
  title,
  button,
  cards,
  defaultButtonLayout = true,
  useCustomCardLayout,
}: LayoutProps): JSX.Element => {
  if (!!count && count > 0) {
    return (
      <Section>
        <CampusCardSectionHeaderContainerV2>
          <CampusCardSectionHeaderV2 title={title} sectionCount={count} />
          {defaultButtonLayout && <div>{button}</div>}
        </CampusCardSectionHeaderContainerV2>
        {cards && <CampusCardLayoutWrapperV2 useCustomLayout={useCustomCardLayout}>{cards}</CampusCardLayoutWrapperV2>}
        {!defaultButtonLayout && (
          <Flex justifyContent="center" mt="gap-lg">
            {button}
          </Flex>
        )}
      </Section>
    );
  }

  return <></>;
};

const LoadingState = (): JSX.Element => (
  <Section>
    <CampusCardSectionHeaderContainerV2>
      <Skeleton width={241} height={32} />
      <div>
        <Skeleton width={122} height={16} />
      </div>
    </CampusCardSectionHeaderContainerV2>
    <CampusCardLayoutWrapperV2>
      <>
        <CourseCardV2.LoadingState />
        <CourseCardV2.LoadingState />
        <CourseCardV2.LoadingState />
        <CourseCardV2.LoadingState />
      </>
    </CampusCardLayoutWrapperV2>
  </Section>
);

const SectionBaseLayout = makeNamespacedComponent(_SectionBaseLayout, {
  LoadingState,
});

export { SectionBaseLayout };
