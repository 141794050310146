import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.nav<{ visible: boolean }>`
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.visible ? 1 : 0)};
  pointer-events: ${(p) => (p.visible ? 'auto' : 'none')};
`;

export const Item = styled(NavLink)`
  ${(p) => p.theme.typography.overline};
  text-align: left;
  color: ${(p) => p.theme.colors.primary.main};
  &:hover {
    color: ${(p) => p.theme.colors.states.hover4};
  }
`;

export const PhantomItem = styled.div`
  opacity: 0;
  pointer-events: none;
  :after {
    content: '&nbsp;';
  }
`;

export const Separator = styled.span`
  display: block;
  width: 2px;
  margin: 0 8px;
  height: 9px;
  border-radius: 0.5px;
  transform: rotate(20deg);
  background: ${(p) => p.theme.colors.neutral.disabled};
`;
