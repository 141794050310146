import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

export type ExtraIconPath = [prefix: 'fas', iconName: 'buddy-round-arrow-square'];

export const extraIcons: IconDefinition[] = [
  {
    prefix: 'fas',
    iconName: 'buddy-round-arrow-square' as IconName,
    icon: [
      24,
      8,
      [],
      'c0000',
      'M3 8s2.021-.015 5.253-4.218C9.584 2.051 10.797 1.007 12 1c1.203-.007 2.416 1.035 3.761 2.782C19.012 8.005 21 8 21 8H3z',
    ],
  },
];
