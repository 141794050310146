import styled from 'styled-components';

import { Icon } from '@/ui/Icon';

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutral.container};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  ${({ theme }) => theme.typography.body2};
  padding: 20px 16px;
`;

export const ParticipantsInfoBanner = (): JSX.Element => {
  return (
    <RootStyled>
      <Icon icon="check" />
      <div>All journey participants are automatically added</div>
    </RootStyled>
  );
};
