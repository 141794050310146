import React from 'react';
import styled from 'styled-components';

import { Provider, providers } from '@/store/providers';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { customLinkName } from '@/utils/constants';

type Props = {
  provider?: Provider;
  isCollection?: boolean;
};

const StyledCourseCardProviderLogo = styled(ProviderLogo)`
  position: absolute;
  bottom: calc(var(--gap-xs) * -1);
  left: var(--gap-sm);
`;

export const CourseCardProvider = ({ provider, isCollection }: Props): JSX.Element | null => {
  const providerMeta = providers.find((p) => p.provider === provider);
  if (!providerMeta || providerMeta.name === customLinkName) return null;

  return <StyledCourseCardProviderLogo provider={provider} isCollection={isCollection} />;
};
