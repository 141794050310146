import styled from 'styled-components';

import { Avatar } from '@/ui/Avatar';
import { shortenName } from '@/utils/text';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

export type ParticipantProps = {
  email: string;
  profileImage?: string;
  name: string;
};

const RootStyled = styled.div`
  padding: 16px 16px 16px 32px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const NameStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const EmailStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

const Participant = ({
  email,
  profileImage: imageSrc,
  name,
  className,
}: ParticipantProps & { className?: string }): JSX.Element => {
  const shortName = shortenName(name);

  return (
    <RootStyled className={className}>
      <Avatar text={shortName} image={imageSrc} />
      <div>
        <NameStyled>{name}</NameStyled>
        <EmailStyled>{email}</EmailStyled>
      </div>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <Avatar text="" />
    <div style={{ gap: '4px', display: 'flex', flexDirection: 'column' }}>
      <Skeleton height={20} width={240} />
      <Skeleton height={16} width={120} />
    </div>
  </RootStyled>
);

const _Participant = makeNamespacedComponent(Participant, {
  LoadingState,
});

export { _Participant as Participant };
