import { Typography } from '../types';

export const typography: Typography = {
  title1: `
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.2px;
  `,
  title2: `
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 40px !important;
    letter-spacing: -0.15px !important;
  `,
  title3: `
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: -0.1px !important;
  `,
  title4: `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  title5: `
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`,
  headline: `
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  `,
  subheadline: `
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  `,
  body1: `
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 28px !important;
  `,
  body2: `
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  `,
  body3: `
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  body4: `
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  `,
  overline: `
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  caption: `
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
  icon0: `
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
  `,
  icon1: `
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
  `,
  icon2: `
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  `,
  icon3: `
    font-style: normal;
    font-weight: 900;
    font-size: 8px;
    line-height: 12px;
  `,
};
