import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/sv';

export const SHORT_DATE_FORMAT = 'HH:mm';
export const LONG_DATE_FORMAT = 'D MMM, HH:mm';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const useLocalizedDate = (date: Date | string | undefined, format: string): string | undefined => {
  const { i18n } = useTranslation();
  if (!date) {
    return undefined;
  }

  return dayjs(date)
    .locale(i18n.language)
    .format(format);
};
