import { formatYMD, getPreviousDay } from '@/utils/dates';
import { LearningLoop } from './types';

export const transformResults = (loops: LearningLoop[]): LearningLoop[] => {
  return loops.map((x): LearningLoop => {
    const { endAt, ...loopRest } = x;
    const transformedEndAt = getPreviousDay(endAt);

    if (!!transformedEndAt) {
      const sunday = `${formatYMD(transformedEndAt)}T00:00:00.000Z`;
      return { ...loopRest, endAt: sunday };
    }
    return x;
  });
};
