import { Provider } from '@/store/providers';
import { Card, isProgressCard } from '@/types/learning/card';
import { filterProviderEnum, filterDurationEnum, filterTypeEnum } from '../types';
import { checkDuration } from '../utils/checkDuration';

export type AvailableMeta = { providers: filterProviderEnum[]; durations: filterDurationEnum[]; types: filterTypeEnum[] };

export function useAvailableMeta(cards: Card[], skip: boolean): AvailableMeta {
  if (skip) {
    return { providers: [], durations: [], types: [] };
  }
  const providers: filterProviderEnum[] = cards
    ?.map(c => c.provider)
    .filter(p => p || p === '')
    .filter((p): p is Provider => !!p)
    .filter((value, index, self) => self.indexOf(value) === index);
  const durations = (cards || [])
    .map(c => (isProgressCard(c) ? checkDuration(c.totalEffort) : undefined))
    .filter((d): d is filterDurationEnum => !!d)
    .filter((value, index, self) => self.indexOf(value) === index);
  const types = cards?.map(c => c.type).filter((value, index, self) => self.indexOf(value) === index);
  return { providers, durations, types };
}
