import React from 'react';
import { useTranslation } from 'react-i18next';

import { TagHanging } from '@/components/Tag/Tag';
import { getDaysUntilDate, shouldShowLearningExpirationMessage } from '@/utils/expirations';

interface Props {
  isFeatured: boolean;
  isMandatory: boolean;
  isRecommended?: boolean;
  isCancellable?: boolean;
  expiredAt?: string;
}

export const CourseCardHeaderLabels = ({ isFeatured, isMandatory, isRecommended, expiredAt }: Props): React.ReactElement => {
  const { t } = useTranslation('catalog');

  const showExpirationMessage = expiredAt ? shouldShowLearningExpirationMessage(new Date(expiredAt)) : false;
  const expiresInDays = expiredAt ? getDaysUntilDate(new Date(expiredAt)) : 0;

  return (
    <>
      <TagHanging primary={showExpirationMessage} dark={isMandatory} right largeSpacingRight>
        {(() => {
          if (showExpirationMessage) {
            if (expiresInDays === 1) {
              return t('expires_in_n_days_singular', { count: expiresInDays });
            } else if (expiresInDays > 1) {
              return t('expires_in_n_days_plural', { count: expiresInDays });
            } else {
              return t('expired');
            }
          }
          if (isMandatory) return t('Required');
          if (isRecommended) return t('Recommended');
          if (isFeatured) return t('Featured');
        })()}
      </TagHanging>
    </>
  );
};
