import React from 'react';
import { withRef } from '@udecode/cn';
import { Resizable as ResizablePrimitive, ResizeHandle as ResizeHandlePrimitive } from '@udecode/plate-resizable';
import styled, { css } from 'styled-components';

export const Resizable = styled(ResizablePrimitive)<{ $align: 'left' | 'center' | 'right' }>`
  ${({ $align }) => {
    switch ($align) {
      case 'left':
        return css`
          margin-right: auto;
        `;
      case 'center':
        return css`
          margin-right: auto;
          margin-left: auto;
        `;
      case 'right':
        return css`
          margin-left: auto;
        `;
      default:
        return '';
    }
  }};
`;

const ResizeHandleVariantsStyled = styled(ResizeHandlePrimitive)<{ $direction: 'left' | 'right' }>`
  position: absolute;
  z-index: 100;

  top: 0;
  display: flex;
  user-select: none;
  flex-direction: column;
  justify-content: center;

  &::after {
    display: flex;
    height: 64px;
    width: 3px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.neutral.disabled};
    content: '';
  }

  ${({ $direction }) => {
    switch ($direction) {
      case 'left':
        return css`
          height: 100%;
          cursor: col-resize;
          left: -12px;
          margin-left: -12px;
          padding-left: 12px;
        `;
      case 'right':
        return css`
          height: 100%;
          cursor: col-resize;
          right: -12px;
          margin-right: -12px;
          padding-right: 12px;
          align-items: end;
        `;
      default:
        return ``;
    }
  }}
`;

export const ResizeHandle = withRef<typeof ResizeHandlePrimitive>((props, ref) => (
  <ResizeHandleVariantsStyled ref={ref} $direction={props.options?.direction} {...props} />
));
