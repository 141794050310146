import React, { useState } from 'react';
import Cropper from 'cropperjs';
import { useTranslation } from 'react-i18next';

import './choosePicture.scss';
import { SimpleButtonGroup } from '@/components/Button/styles/buttonGroupStyles';
import { Button } from '@/components/Button/Button';

interface Props {
  src: string;
  imgRef: React.RefObject<HTMLImageElement>;
  cropper?: Cropper;
  handleImgLoad: () => void;
}

export const CropperComponent = ({ src, imgRef, handleImgLoad, cropper }: Props): JSX.Element => {
  const [scaleXValue, setScaleXValue] = useState(-1);
  const [scaleYValue, setScaleYValue] = useState(-1);
  const { t } = useTranslation('profileImageUpload');

  const rotate = (degree: number) => cropper?.rotate(degree);
  const scaleY = () => cropper?.scaleY(scaleYValue) && setScaleYValue(scaleYValue * -1);
  const scaleX = () => cropper?.scaleX(scaleXValue) && setScaleXValue(scaleXValue * -1);
  const reset = () => cropper?.reset();

  return (
    <>
      <div styleName="cropper-wrapper">
        <img styleName="cropper-image" ref={imgRef} onLoad={handleImgLoad} src={src} alt={t('Original picture')} />
      </div>
      {!!cropper && (
        <div className="field is-grouped">
          <div className="control">
            <SimpleButtonGroup>
              <Button $icon="undo-alt" data-degrees="-45" onClick={() => rotate(-45)}></Button>
              <Button $icon="redo-alt" data-degrees="45" onClick={() => rotate(45)}></Button>
            </SimpleButtonGroup>
          </div>
          <div className="control">
            <SimpleButtonGroup>
              <Button $icon="exchange-alt" data-option="-1" data-method="scaleX" onClick={() => scaleX()}></Button>
              <Button $icon="sort-alt" data-option="1" data-method="scaleY" onClick={() => scaleY()}></Button>
            </SimpleButtonGroup>
          </div>
          <div className="control">
            <Button $icon="sync-alt" onClick={() => reset()}>
              {t('Reset')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
