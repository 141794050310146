import { Children, ReactNode, cloneElement, isValidElement, useContext } from 'react';

import { Context } from './Context';

export const ContentsList = ({ children }: { children: ReactNode | ReactNode[] }): JSX.Element | null => {
  const { activeTab } = useContext(Context);

  const arrayChildren = Children.toArray(children);

  const currentContent = arrayChildren.find((child) => isValidElement(child) && child.props.value === activeTab);

  if (!currentContent) return null;

  return <>{isValidElement(currentContent) ? cloneElement(currentContent, {}) : null}</>;
};
