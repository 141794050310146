import React from 'react';

import { Container, Icon, Text } from '../../atoms';
import { useLabelState } from './hooks';
import { LearningTypeLabelProps } from './types';

export const LearningTypeLabel = ({ type, text, className }: LearningTypeLabelProps): JSX.Element => {
  const labelState = useLabelState(type);

  return (
    <Container variant="learning" className={className}>
      {labelState?.icon && <Icon variant="learning" icon={labelState?.icon} />}
      <Text variant="learning" content={text} />
    </Container>
  );
};
