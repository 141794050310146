import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';

export const StyledSelect = styled.select<{ width?: number; error?: boolean }>`
  appearance: none;
  background-color: ${cvar('color-white')};
  border: ${cvar('border-style')};
  ${({ error }) => error && `border-color: ${cvar('color-monza')};`};
  border-radius: ${cvar('radius-inner')};
  display: block;
  font-size: ${cvar('font-size-6')};
  height: 45px;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: ${cvar('gap-xxs')} ${cvar('gap-lg')} ${cvar('gap-xxs')} ${cvar('gap-sm')};
  transition: all 0.2s ease;
  width: ${({ width }) => (width ? `${width}px` : '100%')};

  &:disabled {
    background-color: ${cvar('color-secondary')};
    cursor: not-allowed;
    opacity: 1;
  }

  &:focus {
    box-shadow: ${cvar('box-shadow-small-hover')};
    color: ${cvar('color-mine-shaft')};
    outline: none;
  }

  & option {
    font-weight: normal;
  }
`;
