import React from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { ViewDrawerItemProps, ViewItem } from '@/administration/pages/Course/MainLayoutDeprecated/atoms/Drawer/Item';
import { ListStyled, RootStyled } from '../styles';

type DrawerProps = {
  items: ViewDrawerItemProps[];
};

const _ViewDrawer = ({ items }: DrawerProps): JSX.Element => {
  return (
    <RootStyled>
      <ListStyled>
        {items.map((item) => (
          <ViewItem {...item} key={item.id} />
        ))}
      </ListStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <ViewItem.LoadingState />
  </RootStyled>
);

export const ViewDrawer = makeNamespacedComponent(_ViewDrawer, {
  LoadingState,
});
