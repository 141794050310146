import { getDate, getYear } from 'date-fns';

import { isRealm } from '@/utils/realms';
import { Lng } from '@/i18n/resources';
import { REALM } from '@/types/realm';

export function getChallengeDate(currentYear: number): { from: string; to: string } {
  if (isRealm(REALM.AUTOLIVPILOT)) {
    return {
      from: `2022-06-01T00:00:00Z`,
      to: `2022-09-28T00:00:00Z`,
    };
  } else {
    return {
      from: `${currentYear}-01-01T00:00:00Z`,
      to: `${currentYear}-12-31T00:00:00Z`,
    };
  }
}
export function getChallengesDatesSplit(
  challengeDate: { from: string; to: string },
  language: Lng
): { startDay: number; startMonth: string; startYear: string; endDay: number; endMonth: string; endYear: string } {
  const fromDate = new Date(challengeDate.from);
  const toDate = new Date(challengeDate.to);

  return {
    startDay: getDate(fromDate),
    startMonth: toMonthName(fromDate, language),
    startYear: getYear(fromDate).toString(),
    endDay: getDate(toDate),
    endMonth: toMonthName(toDate, language),
    endYear: getYear(toDate).toString(),
  };
}
function toMonthName(date: Date, language: Lng): string {
  const locale = {
    [Lng.en]: 'en-US',
    [Lng.sv]: 'sv-SE',
  }[language];

  return date
    .toLocaleString(locale, {
      month: 'short',
    })
    .slice(0, 3)
    .toLowerCase();
}
