import { LanguageMap, RFC5646LanguageCodes } from '../i18n';

export enum ActivityDefinitionCollegialType {
  QUESTION_RELEVANCE = 'http://id.collegial.com/activity-type/question/relevance',
}

export type ActivityDefinitionType = ActivityDefinitionCollegialType | string;

export type ActivityDefinitionExtension = string;

export interface ActivityDefinition {
  type?: ActivityDefinitionType;
  name?: LanguageMap;
  description?: LanguageMap;
  extensions?: {
    [uri: ActivityDefinitionExtension]: any;
  };
}

export const activityDefinitionFromName = (
  lang: RFC5646LanguageCodes,
  name: string,
  type?: ActivityDefinitionType
): ActivityDefinition =>
  ({
    name: { [lang]: name },
    type,
  } as ActivityDefinition);
