import React from 'react';
import { Paginated } from '@makotot/paginated';
import { useTranslation } from 'react-i18next';

import { Ellipsis, PrevOrNext, ToPage, Wrapper } from './styles';

/**
 * Simple as could be, but it's better to write it 1 time, and never mistake with forgetting -1 :)
 */
const getIndexFromPageNumber = (pageNumber: number) => pageNumber - 1;

export const Paginator = ({
  page: pageIndexedFromZero,
  pageCount,
  onPageChange,
  className,
}: {
  /** Current page index (indexed from `0`) */
  page: number;
  pageCount: number;
  onPageChange?: (page: number) => void;
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation('common');
  const page = pageIndexedFromZero + 1;

  /** Kicks onPageChange with boundary index (first one, or last one) */
  const handleBoundaryClick = (boundary: number) => {
    const targetBoundaryIndex = getIndexFromPageNumber(boundary);
    onPageChange?.(targetBoundaryIndex);
  };

  /** Kicks onPageChange with current page index - 1 */
  const handlePrevClick = (currentPage: number) => {
    const prevPageIndex = getIndexFromPageNumber(currentPage) - 1;
    onPageChange?.(prevPageIndex);
  };

  /** Kicks onPageChange with current page index + 1 */
  const handleNextClick = (currentPage: number) => {
    const nextPageIndex = getIndexFromPageNumber(currentPage) + 1;
    onPageChange?.(nextPageIndex);
  };

  /** Kicks onPageChange with target page index */
  const handleToPageClick = (targetPageNumber: number) => {
    const targetPageIndex = getIndexFromPageNumber(targetPageNumber);
    onPageChange?.(targetPageIndex);
  };

  return (
    <Paginated currentPage={page} totalPage={pageCount} siblingsSize={2} boundarySize={1}>
      {({ pages, currentPage, hasPrev, hasNext, getFirstBoundary, getLastBoundary, isPrevTruncated, isNextTruncated }) => (
        <Wrapper className={className}>
          <PrevOrNext aria-disabled={!hasPrev()} disabled={!hasPrev()} onClick={() => handlePrevClick(currentPage)}>
            {t('Previous')}
          </PrevOrNext>

          {getFirstBoundary().map((boundary) => (
            <ToPage key={boundary} onClick={() => handleBoundaryClick(boundary)}>
              {boundary}
            </ToPage>
          ))}

          {isPrevTruncated && <Ellipsis />}

          {pages.map((pageNumber) => (
            <ToPage
              key={pageNumber}
              onClick={() => handleToPageClick(pageNumber)}
              current={pageNumber === currentPage}
              aria-current={pageNumber === currentPage}
            >
              {pageNumber}
            </ToPage>
          ))}

          {isNextTruncated && <Ellipsis />}

          {getLastBoundary().map((boundary) => (
            <ToPage key={boundary} onClick={() => handleBoundaryClick(boundary)}>
              {boundary}
            </ToPage>
          ))}

          <PrevOrNext aria-disabled={!hasNext()} disabled={!hasNext()} onClick={() => handleNextClick(currentPage)}>
            {t('Next')}
          </PrevOrNext>
        </Wrapper>
      )}
    </Paginated>
  );
};
