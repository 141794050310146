import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import isUrl from 'is-url';

import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { UserSelfTrackedLearning, UserSelfTrackedLearningsTypes } from '../../types';
import { FormValues, useFormController } from './hooks/useFormController';
import { FooterContainer } from './components/FooterContainer';
import { FooterSection } from './components/FooterSection';
import { TypeSection } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/sections/TypeSection';
import { TitleSection } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/sections/TitleSection';
import { WhatDidILearnSection } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/sections/WhatDidILearn';
import { TimeSpentSection } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/sections/TimeSpentSection';
import { LinkSection } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/sections/LinkSection';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';

const FIFTY_HOURS_IN_MINUTES = 3000;

type UserSelfTrackedLearningFormProps = {
  userSelfTrackedLearning?: UserSelfTrackedLearning;
  save: (externalLearning: UserSelfTrackedLearning) => Promise<void>;
  handleDelete?: (externalLearningId: number) => Promise<void>;
  isEditing?: boolean;
  loading?: boolean;
};

const UserSelfTrackedLearningTypes = [
  UserSelfTrackedLearningsTypes.ARTICLE,
  UserSelfTrackedLearningsTypes.BOOK,
  UserSelfTrackedLearningsTypes.LIVE_EVENT,
  UserSelfTrackedLearningsTypes.PODCAST,
  UserSelfTrackedLearningsTypes.PROGRAM,
  UserSelfTrackedLearningsTypes.VIDEO,
  UserSelfTrackedLearningsTypes.WORKSHOP,
  UserSelfTrackedLearningsTypes.OTHER,
];

export const UserSelfTrackedLearningForm: FC<UserSelfTrackedLearningFormProps> = ({
  save,
  handleDelete,
  userSelfTrackedLearning,
  isEditing,
  loading,
}) => {
  const { t } = useTranslation('common');
  const { openSnackbar } = useContext(SharedSnackbarContext);

  const {
    type,
    setType,
    title,
    setTitle,
    whatDidILearn,
    setWhatDidILearn,
    hours,
    setHours,
    minutes,
    setMinutes,
    link,
    setLink,
    getFormValues,
  } = useFormController(userSelfTrackedLearning);

  const checkIfSubmitIsDisabled = () => {
    return !title || !type || (parseInt(minutes) === 0 && parseInt(hours) === 0);
  };

  const isValidTime = (time: string): boolean => {
    return !isNaN(parseInt(time));
  };

  const validateInput = (formValues: FormValues): { error: string | null } => {
    const { link, hours, minutes } = formValues;

    if (!isValidTime(hours) || !isValidTime(minutes)) {
      return {
        error: t('Provided time is not valid', { ns: 'userSelfTrackedLearning' }),
      };
    }

    const parsedHours = Number(hours);
    const parsedMinutes = Number(minutes);

    if (parsedHours < 0 || parsedMinutes < 0) {
      return {
        error: t('Provided time must be greater or equal to 0', { ns: 'userSelfTrackedLearning' }),
      };
    }

    if (!Number.isInteger(parsedHours) || !Number.isInteger(parsedMinutes)) {
      return {
        error: t('Provided time must be an integer', { ns: 'userSelfTrackedLearning' }),
      };
    }

    if (60 * parsedHours + parsedMinutes > FIFTY_HOURS_IN_MINUTES) {
      return {
        error: t('Provided time must be less than or equal to 50 hours', { ns: 'userSelfTrackedLearning' }),
      };
    }

    if (link !== '' && !isUrl(link)) {
      return {
        error: t('Link needs to be a valid URL', { ns: 'userSelfTrackedLearning' }),
      };
    }

    return {
      error: null,
    };
  };

  const handleClickSubmit = useCallback(async () => {
    const formValues = getFormValues();

    const { error } = validateInput(formValues);

    if (error) {
      openSnackbar({ type: SnackbarType.DANGER, message: t(error) });
      return;
    }

    const { title, type, whatDidILearn, link, hours, minutes } = formValues;

    const newUserSelfTrackedLearning: UserSelfTrackedLearning = {
      id: userSelfTrackedLearning?.id,
      title,
      type,
      description: whatDidILearn,
      link,
      effort: 60 * 60 * Number(hours) + 60 * Number(minutes),
    };

    await save(newUserSelfTrackedLearning);
  }, [getFormValues, userSelfTrackedLearning]);

  const handleClickDelete = useCallback(async () => {
    if (!userSelfTrackedLearning || !userSelfTrackedLearning.id || !handleDelete) {
      return;
    }

    await handleDelete(userSelfTrackedLearning.id);
  }, [userSelfTrackedLearning]);

  return (
    <>
      <TypeSection options={UserSelfTrackedLearningTypes} type={type} setType={setType} />
      <TitleSection title={title} setTitle={setTitle} />
      <WhatDidILearnSection whatDidILearn={whatDidILearn} setWhatDidILearn={setWhatDidILearn} />
      <TimeSpentSection hours={hours} minutes={minutes} setHours={setHours} setMinutes={setMinutes} />
      <LinkSection link={link} setLink={setLink} />
      <FooterContainer>
        <FooterSection />
        <FooterSection>
          <ButtonList align="right">
            {isEditing && (
              <Button $type="secondary" onClick={handleClickDelete} style={{ display: 'flex', justifyContent: 'center' }}>
                {t('Delete')}
              </Button>
            )}
            <Button
              $loading={loading}
              $type="primary"
              onClick={handleClickSubmit}
              style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
              disabled={checkIfSubmitIsDisabled()}
            >
              {isEditing ? t('Update') : t('Add to profile', { ns: 'userSelfTrackedLearning' })}
            </Button>
          </ButtonList>
        </FooterSection>
      </FooterContainer>
    </>
  );
};
