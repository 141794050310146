import { filterDurationEnum } from '../types';

export const checkDuration = (minutes?: number | null): filterDurationEnum | undefined => {
  if (!minutes) return;

  if (minutes <= 15) {
    return 'micro';
  } else if (minutes <= 60) {
    return 'hour';
  } else if (minutes <= 240) {
    return 'hours';
  } else if (minutes <= 480) {
    return 'day';
  } else if (minutes >= 480 && minutes <= 10080) {
    return 'week';
  } else if (minutes >= 10080 && minutes <= 40320) {
    return 'month';
  }

  return 'months';
};
