import styled, { useTheme } from 'styled-components';

import { IconPropType, Icon } from '@/ui/Icon';
import { Button } from '@/ui/Button';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';

type LiveEventLinkProps = Pick<LiveEvent, 'address' | 'isOnline'> & { linkMaxWidth?: string };

const IconWithLabelContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  font-size: 18px;
`;

const StyledButtonLabel = styled.div<{ linkMaxWidth?: string }>`
  ${({ linkMaxWidth }) => linkMaxWidth && `max-width: ${linkMaxWidth};`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLabel = styled.div`
  ${({ theme }) => theme.typography.subheadline};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const LiveEventLink = ({ isOnline, address, linkMaxWidth }: LiveEventLinkProps): JSX.Element => {
  const theme = useTheme();

  const handleClick = () => {
    window.open(address, '_blank');
  };

  return (
    <IconWithLabelContainer>
      <Icon
        icon={(isOnline ? 'link' : ['far', 'map-marker-alt']) as IconPropType}
        color={theme.colors.neutral.onContainer}
        size="lg"
      />
      {isOnline ? (
        <StyledButton variant="link" onClick={handleClick}>
          <StyledButtonLabel linkMaxWidth={linkMaxWidth}>{address}</StyledButtonLabel>
        </StyledButton>
      ) : (
        <StyledLabel>{address}</StyledLabel>
      )}
    </IconWithLabelContainer>
  );
};
