import React, { ChangeEvent, useCallback, useState } from 'react';
import Fuse from 'fuse.js';
import { useLocation } from 'react-router-dom';

import { QueryParamKeys } from '@/pages/Search/SearchPage';
import { fuzzyOptions } from '@/pages/Search/fuseOptions';
import { ModalStyledDropdown } from '@/components/Dropdown/StyledDropdown';
import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { useDropdown } from '@/hooks/useDropdown';
import { StyledDropdownRowStandard } from '@/components/Dropdown/StyledDropdownRow';
import { trackUseNavbarSearch } from '@/utils/tracking/learnings';
import { useDebouncedFunction } from '@/hooks/useDebounce';
import { InputBasicV2 } from '@/components/Form/components/Input/InputBasicV2';
import { Card } from '@/types/learning/card';

const MAX_HITS = 12;

interface Props {
  placeholder: string;
  initial: { label: string; value: Card }[];
  onSelect: (selected: Card) => void;
}
export const PinnedLearningsSearch = ({ placeholder, initial, onSelect }: Props): JSX.Element => {
  const { open, close } = useDropdown<HTMLDivElement>({ defaultOpen: true });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchStr = searchParams.get(QueryParamKeys.q)?.toLocaleLowerCase();
  const [search, setSearch] = useState(searchStr);
  const onSearch = (props?: { clearSearch?: boolean; noSideEffects?: boolean }) => {
    props?.clearSearch && setSearch(undefined);
    close();
    !props?.noSideEffects;
  };

  useOnEscapePress(() => onSearch, [setSearch]);

  const cards = initial.map((card) => ({ ...card.value, title: card.value.title.trim() }));
  const fuse = new Fuse(cards, fuzzyOptions);
  const filteredCards = search && search.length > 1 ? fuse.search(search).map(({ item }) => item) : [];
  const maxFilteredCards = filteredCards.length ? filteredCards.slice(0, MAX_HITS) : [];
  const hasSearch = !!search;

  const handleClick = (c: Card) => {
    onSelect(c);
    onSearch({ clearSearch: true });
  };

  const debouncedTrackFreeTextFilterUsed = useDebouncedFunction(trackUseNavbarSearch, 500);
  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    debouncedTrackFreeTextFilterUsed(e.currentTarget.value);
    open();
    setSearch(e.target.value);
  }, []);

  return (
    <>
      <InputBasicV2
        icon={'search'}
        hiddenLabel={true}
        placeholder={placeholder}
        value={search || ''}
        onFocus={open}
        onChange={handleSearchChange}
        onIconClick={hasSearch ? () => onSearch({ clearSearch: true, noSideEffects: true }) : undefined}
        cursor="pointer"
      />
      <ModalStyledDropdown open={true} align="right">
        {!!maxFilteredCards.length && (
          <>
            {maxFilteredCards.map((c) => (
              <StyledDropdownRowStandard
                key={c.id}
                onClick={() => {
                  handleClick(c as Card);
                }}
              >
                <span>{c.title}</span>
              </StyledDropdownRowStandard>
            ))}
          </>
        )}
      </ModalStyledDropdown>
    </>
  );
};
