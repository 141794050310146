import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';

type TimeSpentSectionProps = {
  hours: string;
  setHours: (externalHours: string) => void;
  minutes: string;
  setMinutes: (externalMinutes: string) => void;
};

export const TimeSpentSection: FC<TimeSpentSectionProps> = ({ hours, setHours, minutes, setMinutes }) => {
  const { t } = useTranslation('home');

  const renderInput = (time: string, setTime: (time: string) => void, label: string) => {
    return (
      <div className="control" style={{ width: '100%' }}>
        <div className="field has-addons" style={{ width: '100%' }}>
          <div className="control" style={{ width: '100%' }}>
            <input
              className="input"
              type="number"
              value={time}
              onChange={e => setTime(e.currentTarget.value)}
              min={0}
              required
              aria-required="true"
            />
          </div>
          <div className="control">
            <Button $type="static">{t(label, { ns: 'time' })}</Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="field is-floating-label">
      <label className="label required">{t('Time spent', { ns: 'userSelfTrackedLearning' })}</label>
      <div className="field has-addons">
        {renderInput(hours, setHours, 'Hours')}
        {renderInput(minutes, setMinutes, 'Minutes')}
      </div>
    </div>
  );
};
