import React from 'react';

import { Modal } from '@/ui/Modal';
import { ReflectionBox } from './ReflectionBox';
import { LearningProgress } from './WeeklySummaryTable/cells/types';
import { ContentStyled, PreReflectionMessageStyled } from './styles';
import { WeeklySummaryTable } from './WeeklySummaryTable';
import { Icon } from '@/ui/Icon';
import { NoProgressBanner } from '../NoProgressModal/NoProgressBanner';

type WeeklySummaryModalProps = {
  open: boolean;
  loopIndex: number;
  loopId: string;
  reflection?: string;
  isCurrentWeek?: boolean;
  learningsProgress: LearningProgress[];
  isPreReflection?: boolean;
  onClose: () => void;
} & Either<
  {
    onCreateReflectionClick: (loopId: string) => void;
  },
  {
    onNext: (loopId: string) => void;
  }
>;

export const WeeklySummaryModal = ({
  open,
  onClose,
  onNext,
  loopIndex,
  loopId,
  onCreateReflectionClick,
  reflection,
  isCurrentWeek,
  learningsProgress,
  isPreReflection,
}: WeeklySummaryModalProps): JSX.Element => {
  const handleOnReflectClick = () => {
    onCreateReflectionClick && onCreateReflectionClick(loopId);
  };

  return (
    <Modal open={open} onClose={onClose} size="desktop">
      <Modal.Title>Your progress · week {loopIndex + 1}</Modal.Title>
      <Modal.Contents>
        <ContentStyled>
          {isPreReflection ? (
            <PreReflectionMessageStyled>
              <Icon icon="sparkles" size="xs" />
              Take a moment to reflect on what you have learned and how you will use it, to turn learning into action.
            </PreReflectionMessageStyled>
          ) : (
            <>{!isCurrentWeek && <ReflectionBox onReflect={handleOnReflectClick} reflection={reflection} />}</>
          )}
          {learningsProgress?.length ? <WeeklySummaryTable learningProgressList={learningsProgress} /> : <NoProgressBanner />}
        </ContentStyled>
      </Modal.Contents>
      <Modal.Actions>
        {onClose && <Modal.Action action={onClose}>Close</Modal.Action>}
        {onNext && (
          <Modal.Action action={() => onNext(loopId)} primary>
            Next
          </Modal.Action>
        )}
      </Modal.Actions>
    </Modal>
  );
};
