import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './coursePageParticipants.scss';
import { useParticipants } from '@/store/learning-catalog';
import { Avatar } from '@/component/Avatar';
import { OrderStates } from '@/administration/types/administration';
import { displayRealm } from '@/utils/misc';
import { Paginator } from '@/components/Paginator/Paginator';
import { Box } from '@/components/Box/Box';
import { Media, MediaBody, MediaFigure } from '@/components/MediaObject/MediaObject';

interface CoursePageParticipantsProps {
  productId: number;
}

const PAGE_SIZE = 100;

export const CoursePageParticipants = ({ productId }: CoursePageParticipantsProps): JSX.Element => {
  const { t } = useTranslation('learning');
  const [page, setPage] = useState(1);

  const {
    total,
    totalPages,
    participants,
    loading: loadingParticipants,
  } = useParticipants({
    productId,
    page: (page - 1) * PAGE_SIZE, // TODO PT-1526 page = offset, pageSize = limit
    pageSize: PAGE_SIZE,
    stateIds: [OrderStates.APPROVED],
  });

  return (
    <>
      {loadingParticipants && (!participants || participants.length === 0) && <div className="pageloader" />}
      {(!loadingParticipants || participants.length > 0) && (
        <>
          {participants.length === 0 && (
            <div style={{ maxWidth: 300 }}>
              <div className="message is-info">
                <div className="message-body">{t('There are no participants yet')}</div>
              </div>
            </div>
          )}

          {participants.length > 0 && (
            <>
              <h2 className="header3 has-bottom-margin">
                {t(`total-participants${total > 1 ? '_plural' : ''}`, { count: total })}
              </h2>
              <Box>
                <div className="grid">
                  {participants.map((participant, index) => (
                    <div className="gc gc-4-d gc-6-t gc-12-l gc-12-p" key={index}>
                      <Media>
                        <MediaFigure>
                          <figure className="image is-48x48">
                            <Avatar profileImage={participant.profileImage} />
                          </figure>
                        </MediaFigure>
                        <MediaBody>
                          <p className="header4 is-size-6">{participant.username}</p>
                          <p className="overline is-marginless">{displayRealm(participant.realm)}</p>
                        </MediaBody>
                      </Media>
                    </div>
                  ))}
                </div>
              </Box>
              {totalPages > 1 && (
                <div className="grid">
                  <div className="gc gc-12">
                    <Paginator page={page} pageCount={totalPages} onPageChange={setPage} />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
