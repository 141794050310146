import styled, { css } from 'styled-components';

import { ContainerStyledProps } from './types';

/** Default badge styling */
const DefaultBadge = css`
  height: 16px;
  border-radius: 4px;
  padding: 0px 4px;
  gap: 4px;
`;

export const ContainerStyled = styled.div<ContainerStyledProps>`
  display: inline-flex;
  align-items: center;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `};

  ${({ variant, theme }) => {
    switch (variant) {
      case 'event':
        return `
        border-radius: 0px 0px 4px 4px;
        gap: 8px;
        padding: 2px 8px;
        `;
      case 'learning':
        return `
        border-radius: 4px;
        gap: 8px;
        background: ${theme.colors.neutral.outline1};
        padding: 2px 8px;
        `;
      case 'meta':
        return `
        gap: 8px;
        `;
      case 'round':
        return `
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        overflow: hidden;
        color: ${theme.colors.neutral.background};
        `;
      case 'badge-dot':
        return `
        width: 6px;
        height: 6px;
        border-radius: 4px;
        `;
      case 'badge-text':
        return `
        ${DefaultBadge};
        gap: 0px;
        `;
      default:
        return `${DefaultBadge}`;
    }
  }}
`;
