import styled, { css } from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { ButtonProps, ButtonSizeEnum, ButtonSizeUnion, ButtonType } from '../types';

export const BUTTON_HEIGHT_ENUM: { [key in ButtonSizeUnion]: number } = {
  default: 2.75,
  large: 3.375,
  mini: 1.5,
  'x-mini': 1,
  small: 2,
};

const BUTTON_ROUND_PADDING_ENUM: { [key in ButtonSizeUnion]: number } = {
  default: 1.375,
  large: 1.375,
  mini: 0.5,
  'x-mini': 0.5,
  small: 0.5,
};

const DefaultStyling = css<ButtonProps>`
  background-color: var(--color-transparent);
  border-color: var(--color-border);

  &,
  &:hover,
  & .icon-container,
  &:not(.icon-container) > svg {
    color: var(--color-black);
  }

  &:hover {
    background-color: var(--color-overlay-light);
  }

  ${({ $toggled, $loading }) =>
    $toggled || $loading
      ? `
    background-color: var(--color-overlay-light);
    color: var(--color-text);
    `
      : null}
`;

const PrimaryStyling = css<ButtonProps>`
  background-color: var(--color-primary);
  border-color: var(--color-primary);

  &,
  &:hover {
    color: var(--color-white);
  }

  .icon-container,
  &:not(.icon-container) > svg {
    color: var(--color-brand-light);
    opacity: 0.7;
  }

  &:hover {
    background-color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
  }

  ${({ $toggled, $loading }) =>
    $toggled || $loading
      ? `
    background-color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
    color: var(--color-brand-light);
    .icon-container,
    &:not(.icon-container) > svg {
      opacity: 1;
    }
  `
      : null}

  /* @TODO remove after Bulma form stuff is gone */
  .field.has-addons .control & {
    padding: 0 1rem;
  }

  .field.has-addons .control:last-child & {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .field.has-addons .control:first-child & {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .field.has-addons .control:not(:first-child):not(:last-child) & {
    border-radius: 0;
  }
`;

const SecondaryStyling = css<ButtonProps>`
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);

  &,
  &:hover,
  & .icon-container,
  &:not(.icon-container) > svg {
    color: var(--color-black);
  }

  &:hover {
    background-color: var(--color-secondary-hover);
    border-color: var(--color-secondary-hover);
  }

  ${({ $toggled, $loading }) =>
    $toggled || $loading
      ? `
    background-color: var(--color-secondary-hover);
    border-color: var(--color-secondary-hover);
  `
      : null}
`;

const QuaternaryStyling = css<ButtonProps>`
  background-color: var(--color-quaternary);
  border-color: var(--color-quaternary);

  &,
  &:hover,
  & .icon-container,
  &:not(.icon-container) > svg {
    color: var(--color-black);
  }

  &:hover {
    background-color: var(--color-quaternary-hover);
    border-color: var(--color-quaternary-hover);
  }

  ${({ $toggled, $loading }) =>
    $toggled || $loading
      ? `
    background-color: var(--color-quaternary-hover);
    border-color: var(--color-quaternary-hover);
  `
      : null}
`;

const TextStyling = css<ButtonProps>`
  background-color: transparent;
  border-color: transparent;
  color: var(--color-mine-shaft);
  height: ${({ $size }) => ($size === ButtonSizeEnum.mini ? 1.75 : 2.125)}rem;
  padding: 0;
  width: auto;

  .icon-container:not(:only-child),
  &:not(.icon-container) > svg:not(:only-child) {
    margin: ${({ $iconPosition }) => ($iconPosition === 'left' ? '0 .5rem 0 0' : '0 0 0 .5rem')};
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: var(--color-black);

    &:after {
      border-bottom: var(--border-style);
      bottom: 0.1rem;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
    }
  }

  &:disabled {
    &,
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    &:after {
      display: none;
    }
  }
`;

const LinkStyling = css<ButtonProps>`
  background-color: transparent;
  border-color: transparent;
  color: var(--color-primary);
  height: ${({ $size }) => ($size === ButtonSizeEnum.mini ? 1.75 : 2.125)}rem;
  padding: 0;
  width: auto;

  .icon-container:not(:only-child),
  &:not(.icon-container) > svg:not(:only-child) {
    margin: ${({ $iconPosition }) => ($iconPosition === 'left' ? '0 .5rem 0 0' : '0 0 0 .5rem')};
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: ${cvar('color-moon-crater-gray')};
  }

  &:disabled {
    &,
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    &:after {
      display: none;
    }
  }
`;

const BaseStyling = css<ButtonProps>`
  background-color: transparent;
  border-color: transparent;
  color: var(--color-mine-shaft);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ $toggled, $loading }) => ($toggled || $loading) && `background-color: rgba(0, 0, 0, 0.1);`}
`;

const BaseInvertedStyling = css`
  background-color: transparent;
  border-color: transparent;
  color: var(--color-white);
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const StaticStyling = css`
  &,
  &:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    cursor: default;

    & .icon-container,
    &:not(.icon-container) > svg {
      color: var(--color-black);
    }
  }
`;

const HiddenStyling = css`
  &,
  &:focus,
  &:hover,
  &:active {
    cursor: default;
    opacity: 0;
  }
`;

const DangerStyling = css<ButtonProps>`
  background-color: var(--color-flush-mahogany);
  border-color: var(--color-flush-mahogany);

  &,
  &:hover,
  & .icon-container,
  &:not(.icon-container) > svg {
    color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-brick-red);
    border-color: var(--color-brick-red);
  }

  ${({ $toggled, $loading }) =>
    $toggled || $loading
      ? `
    background-color: var(--color-brick-red);
    border-color: var(--color-brick-red);
  `
      : null}
`;

const WhiteButtonStyling = css`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* Collegial-theme/light/on-surface */

  color: #141d1e;
  background-color: white;
  /* Inside auto layout */
  border-style: none;
  margin: 0px 8px;
  padding: 8px 16px;
  &:hover {
    background: rgba(0, 136, 98, 0.08);
    opacity: 1;
    padding: 8px 16px;
  }
  .icon-container:not(:only-child),
  &:not(.icon-container) > svg:not(:only-child) {
    margin-left: 0.5rem;
  }
`;

const PrimaryStylingV2 = css<ButtonProps>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 8px 16px;

  & {
    color: #ffffff;
    background-color: #008862;
    border-color: #008862;
    border-radius: 4px;
  }

  &:hover {
    background-color: #005139;
    border-color: #005139;
    padding: 8px 16px;
  }
`;

const ToolTipStyling = css<ButtonProps>`
  background-color: #ffffff;
  border-color: #008862;
  &:not(.icon-container) > svg {
    opacity: 1;
    color: #008862;
  }

  &:hover {
    background-color: #005139;
    border-color: #005139;
    &:not(.icon-container) > svg {
      color: #ffffff;
    }
  }
`;
const ButtonSelector = {
  primary: PrimaryStyling,
  secondary: SecondaryStyling,
  quaternary: QuaternaryStyling,
  default: DefaultStyling,
  text: TextStyling,
  link: LinkStyling,
  base: BaseStyling,
  baseInverted: BaseInvertedStyling,
  static: StaticStyling,
  hidden: HiddenStyling,
  danger: DangerStyling,
  whiteButton: WhiteButtonStyling,
  primaryV2: PrimaryStylingV2,
  toolTip: ToolTipStyling,
};

export const BaseButton = styled.button.attrs(({ $type, $round, $size = 'default', ...otherProps }: ButtonProps) => {
  const isRound = $round && $type !== 'text';
  const newSize = $type === 'text' && ['large', 'mini'].includes($size) ? 'default' : $size;
  return { ...otherProps, $size: newSize, $round: isRound };
})<ButtonProps>`
  // Reset
  align-items: center;
  appearance: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  outline: none;
  padding: 0;
  position: relative;
  text-decoration: none;
  user-select: none;
  --webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:before,
  &:after {
    box-sizing: border-box;
  }

  // Custom
  border-width: var(--border-width);
  border-style: solid;
  border-radius: ${({ $round }) => ($round ? '100px' : 'var(--radius-inner)')};
  cursor: pointer;
  flex-direction: ${({ $iconPosition }) => ($iconPosition === 'left' ? 'row-reverse' : 'row')};
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-bold-extra);
  height: ${({ $size }) => BUTTON_HEIGHT_ENUM[$size || 'default']}rem;
  letter-spacing: 0.2px;
  line-height: 2.182;
  padding: 0 ${({ $round, $onlyIcon, $size }) => ($round || $onlyIcon ? 0 : BUTTON_ROUND_PADDING_ENUM[$size || 'default'])}rem;
  text-transform: uppercase;
  ${({ $round, $onlyIcon, $size }) =>
    $round || $onlyIcon
      ? `width: ${BUTTON_HEIGHT_ENUM[$size || 'default']}rem;max-width: ${BUTTON_HEIGHT_ENUM[$size || 'default']}rem;`
      : null}
  visibility: ${({ $visibility }) => $visibility || 'visible'};

  ${({ $fullwidth, $hasIcon }) =>
    $fullwidth &&
    `
    justify-content: ${$hasIcon ? 'space-between' : 'center'};
    width: 100%;
  `}

  &:disabled {
    &,
    &:hover {
      cursor: not-allowed;
      ${({ $loading }) =>
        !$loading
          ? `
      background-color: var(--color-alto);
      border-color: var(--color-alto);
      color: var(--color-text-disabled);

      .icon-container,
      &:not(.icon-container) > svg {
        color: var(--color-text-disabled);
        opacity: 0.3;
      }
    `
          : `
      .icon-container,
      &:not(.icon-container) > svg {
        opacity: 1;
      }
      `}
    }
  }

  /* Remove default focus styles for mouse users ONLY if :focus-visible is supported on this platform. */
  :focus:not(:focus-visible) {
    outline: none;
  }

  .icon-container,
  &:not(.icon-container) > svg {
    opacity: ${({ $onlyIcon }) => ($onlyIcon ? 1 : 0.3)};

    &:not(:only-child) {
      ${({ $iconPosition, $size }) =>
        $iconPosition === 'left'
          ? `margin-right: ${$size === ButtonSizeEnum.mini ? 1 : 2}rem;`
          : `margin-left: ${$size === ButtonSizeEnum.mini ? 1 : 2}rem;`};
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    .icon-container,
    &:not(.icon-container) > svg {
      opacity: 1;
    }
  }

  ${({ $type }) => ButtonSelector[$type as ButtonType]}
  ${({ $type, $textButtonAlign, $size }) =>
    $type === 'text' &&
    $textButtonAlign &&
    `
    margin-left: ${BUTTON_ROUND_PADDING_ENUM[$size || 'default']}rem;
    margin-right: ${BUTTON_ROUND_PADDING_ENUM[$size || 'default']}rem;
  `}
  
  ${({ $gridArea }) => $gridArea && `grid-area: ${$gridArea};`}
`;
