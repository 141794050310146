import { FC, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: JSX.Element;
  mount: HTMLElement | null;
  show: boolean;
};

export const Portal: FC<PortalProps> = ({ children, mount, show }) => {
  const modalContainer = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    mount?.appendChild(modalContainer);
    return () => {
      modalContainer.remove();
    };
  }, [modalContainer, mount]);

  if (!mount || !show) return null;
  return createPortal(children, modalContainer);
};
