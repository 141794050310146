import styled from 'styled-components';

import { Icon } from '@/ui/Icon';
import { DynamicDataStyledProps, DynamicDataIconStyledProps } from './types';

export const DynamicDataStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DynamicDataTextStyled = styled.span<DynamicDataStyledProps>`
  ${(p) => p.theme.typography.caption};
  color: ${({ direction, theme }) => (direction === 'up' ? theme.tones.primary[70] : theme.tones.error[50])};
`;

export const DynamicDataIconStyled = styled(Icon)<DynamicDataIconStyledProps>`
  width: 6px;

  path {
    fill: ${({ direction, theme }) => (direction === 'up' ? theme.tones.primary[70] : theme.tones.error[50])};
  }
`;
