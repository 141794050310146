import React from 'react';

import { ToggleStyled, AccessibleInputStyled, RootStyled, ToggleSlider } from './styles';
import { CheckboxProps } from './types';

export const Toggle = ({ toggled, onChange, disabled, className }: CheckboxProps): JSX.Element => {
  const handleCheckboxClick = () => {
    !disabled &&
      onChange({
        target: {
          checked: !toggled,
        },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <RootStyled onClick={handleCheckboxClick} disabled={disabled} className={className}>
        <AccessibleInputStyled type="checkbox" checked={toggled} disabled={disabled} onChange={onChange} />
        <ToggleStyled aria-hidden="true" toggled={toggled} tabIndex={0} disabled={disabled}>
          <ToggleSlider toggled={toggled} />
        </ToggleStyled>
      </RootStyled>
    </>
  );
};
