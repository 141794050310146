import styled from 'styled-components';

export const PageLoader = styled.div<{ full?: boolean }>`
  display: block;
  height: 150px;
  margin: 0 auto;
  position: relative;
  width: 150px;
  ${({ full }) => full && 'height: 100vh;'}

  @media screen and (min-width: 768px) {
    height: 300px;
    width: 300px;
  }

  &:after {
    animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 100%;
    border: 0 solid #363636;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    z-index: 9999;
  }

  .title {
    color: $dark;
    font-size: 0.875em;
    left: 50%;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    margin: 2em 0 0 0;
    position: absolute;
    top: 55%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  &--full {
    height: 100vh;
  }
`;
