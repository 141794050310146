import styled from 'styled-components';

import defaultCover from '../../assets/defaultCover.png';
import { Button } from '@/ui/Button';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

const ImageStyled = styled.img`
  border-radius: 8px;
  height: 280px;
  width: 100%;
  object-fit: cover;
  opacity: 80%;
`;

const RootStyled = styled.div`
  position: relative;
  height: 280px;
  background: ${({ theme }) => theme.tones.neutral[0]};
  border-radius: 8px;
`;

const FloatingButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title1};
  color: ${({ theme }) => theme.tones.neutral[100]};
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  max-height: 132px;
  overflow: hidden;
`;

type HeroProps = {
  title: string;
  imageUrl?: string;
  onEdit?: () => void;

  isSaved?: boolean;
  onSave?: () => void;
};
const Hero = ({ onEdit, isSaved, onSave, title, imageUrl }: HeroProps): JSX.Element => {
  return (
    <RootStyled>
      <ImageStyled src={imageUrl || defaultCover} />
      {onEdit && !onSave && (
        <FloatingButton variant="neutral" onClick={onEdit}>
          Edit
        </FloatingButton>
      )}
      {!onEdit && onSave && (
        <FloatingButton icon={[isSaved ? 'fas' : 'far', 'heart']} $iconPosition="end" variant="neutral" onClick={onSave}>
          {isSaved ? 'Saved' : 'Save'}
        </FloatingButton>
      )}
      <TitleStyled>{title}</TitleStyled>
    </RootStyled>
  );
};

const LoadingState = () => <Skeleton height={280} />;

const _Hero = makeNamespacedComponent(Hero, {
  LoadingState,
});

export { _Hero as Hero };
