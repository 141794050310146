import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { color, fontStyles } from '@/components/Typography/v2';

type LinkProps = {
  fontStyle?: keyof typeof fontStyles;
  $gridArea?: string;
};

export const Link = styled(NavLink)<LinkProps>`
  ${({ fontStyle = 'body3' }) => fontStyles[fontStyle]}
  ${color('#008862')}
  
  ${({ $gridArea }) => $gridArea && `grid-area: ${$gridArea};`}
  
  :hover {
    ${color('#141D1E')}

    text-decoration-line: underline;
  }

  :disabled {
    ${color('#BEC8C8')}

    opacity: 0.38;
  }

  :active {
    ${color('#005139')}
  }
`;
