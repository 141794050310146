import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Only } from 'atomic-layout';

import { StyledLogo, StyledNavLogoContainer } from '@/components/Navbar/styles';
import { useRootContext } from '@/store/RootContext';
import { getHomeLink } from '@/components/Navbar/utils/helpers';
import { checkAdminView } from '@/utils/checkAdminView';
import { checkManagerView } from '@/utils/checkManagerView';
import { BodyLg, Caption } from '@/components/Typography/Typography';
import { isRealm } from '@/utils/realms';
import { NAV_LINKS } from '@/components/Navbar/consts';
import { getLogoUrl } from '@/utils/cdn/url';

export const NavLogo = (): JSX.Element => {
  const { t } = useTranslation('navbar');
  const { isAuthenticated, acceptedPrivacy, acceptedTos } = useRootContext();
  const isAdminView = checkAdminView();
  const isManagerView = checkManagerView();
  const needsOnboarding = isAuthenticated && (!acceptedTos || !acceptedPrivacy);

  let homeLink = getHomeLink(isAuthenticated, needsOnboarding);

  if (isAdminView) {
    homeLink = NAV_LINKS.ADMIN;
  } else if (isManagerView) {
    homeLink = NAV_LINKS.MANAGER;
  }

  return (
    <StyledNavLogoContainer>
      <NavLink to={homeLink}>
        <StyledLogo src={getLogoUrl()} alt={t('Site logo')} isKompetens={isRealm('kompetens')} />
      </NavLink>
      {isAdminView && (
        <NavLink to={homeLink}>
          <Only to="lg">
            <Caption>{t('Admin')}</Caption>
          </Only>
          <Only from="lg">
            <BodyLg>{t('Admin')}</BodyLg>
          </Only>
        </NavLink>
      )}
      {isManagerView && (
        <NavLink to={homeLink}>
          <Only to="lg">
            <Caption>Manager</Caption>
            {/* TODO add translations */}
          </Only>
          <Only from="lg">
            <BodyLg>Manager</BodyLg>
            {/* TODO add translations */}
          </Only>
        </NavLink>
      )}
    </StyledNavLogoContainer>
  );
};
