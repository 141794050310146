import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { getErrorResponseMessage } from '@/utils/gqlErrors';
import { useCurrentUser } from '@/store/currentUser';
import { OnboardingStep, StepComponent } from '../types';
import { Checkbox, TermsLink } from '../styles';
import { useUpdateCurrentUser } from '@/store/user';

export const Terms: StepComponent = ({ onReady, onSave }) => {
  const { t } = useTranslation('onboarding');
  const [tos, setTos] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const { updateUser } = useUpdateCurrentUser();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      setTos(user.acceptedTos);
      setPrivacy(user.acceptedGdpr);
    }
  }, [user]);

  useEffect(() => onReady(OnboardingStep.Terms, tos && privacy), [tos, privacy, onReady]);

  onSave(
    OnboardingStep.Terms,
    useCallback(async () => {
      const { errors } = await updateUser({ acceptedTos: true, acceptedGdpr: true });
      if (errors) {
        throw getErrorResponseMessage(errors);
      }
    }, [tos, privacy])
  );

  return (
    <>
      <Header1>{t('Our Terms & Conditions')}</Header1>
      <BodyLg>{t('Before you can start on the platform, you must agree to our Terms of Use and Privacy Notes.')}</BodyLg>
      <BodyLg>{t('You can request a copy of your data via the Privacy Notes page at any time.')}</BodyLg>
      <hr />

      <Checkbox
        id="tos"
        checked={tos}
        onChange={() => setTos((v) => !v)}
        size="large"
        disabled={tos}
        label={
          <Trans
            t={t}
            i18nKey="I agree to"
            values={{ terms: t('Terms of Use') }}
            components={[<TermsLink to="/terms" target="_blank" key="terms" />]}
          />
        }
      />

      <Checkbox
        id="privacy"
        checked={privacy}
        onChange={() => setPrivacy((v) => !v)}
        size="large"
        disabled={privacy}
        label={
          <Trans
            t={t}
            i18nKey="I agree to"
            values={{ terms: t('Privacy Notes') }}
            components={[<TermsLink to="/privacy" target="_blank" key="privacy" />]}
          />
        }
      />
    </>
  );
};
