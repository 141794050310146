import { useSearchParams } from 'react-router-dom';

import { useLearningParentsByIdQueryRemote, useProductParentsByIdQueryRemote } from '..';
import { useSpaces } from '@/store/spaces';
import { useCurrentUser } from '@/store/currentUser';
import { isObjectIdCollegialJourney } from '@/utils/analyticObjects';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

export const PARENT_JOURNEY_ID_SEARCH_KEY = 'parentJourneyId';

export const useParentJourneyIdFromSearchParams = (): number | undefined => {
  const [searchParams] = useSearchParams();

  const value = searchParams.get(PARENT_JOURNEY_ID_SEARCH_KEY);

  if (!value || isNaN(Number(value))) {
    return;
  }

  return Number(value);
};

const useParents = ({ id, isProduct }: { id: number; isProduct?: boolean }) => {
  const { spaces } = useSpaces();
  const { user } = useCurrentUser();
  const enhancedNavigationEnabled = useFeatureEnabled(FEATURE.UI_LEARNING_JOURNEYS_UP_ENHANCE_NAVIGATION);

  const canFetchParents = Boolean(id && spaces.length && user?.id);

  const commonVariables = {
    filter: {
      curation: {
        spaceIdIN: spaces.map((space) => space.id),
      },
    },
    userId: Number(user?.id),
  };

  const { data: learningContent } = useLearningParentsByIdQueryRemote({
    variables: {
      id: String(id),
      ...commonVariables,
    },
    skip: !canFetchParents || isProduct || !enhancedNavigationEnabled,
  });

  const { data: productContent } = useProductParentsByIdQueryRemote({
    variables: {
      id,
      ...commonVariables,
    },
    skip: !canFetchParents || !isProduct || !enhancedNavigationEnabled,
  });

  const parents = learningContent?.learning?.parents || productContent?.getProduct?.parents;
  const currentLearningObjectId = learningContent?.learning?.urnV2 || productContent?.getProduct?.learningContentUrnV2;

  return {
    parents,
    currentLearningObjectId,
  };
};

export const useParentJourney = (options: {
  id: number;
  isProduct?: boolean;
}): {
  journeyId: number;
  journeyTitle: string;
  currentLearningObjectId?: string;
} | null => {
  const { parents, currentLearningObjectId } = useParents(options);
  // Will be provided if navigated through enhanced flow or from journey. Otherwise will be empty
  const parentJourneyId = useParentJourneyIdFromSearchParams();

  // No parents found for the entity => no parent journey
  if (!parents?.length) return null;

  // We only keep those items that have current user participantion. The rest should be ignored
  const parentsWithUserParticipation = parents.filter((parent) => Boolean(parent.userLastParticipation));

  // We only keep journeys parents. Parsing urn is a bit lame, though :(
  const journeys = parentsWithUserParticipation.filter((parent) => isObjectIdCollegialJourney(parent?.id));

  // No journeys after filtering => no parent journey
  if (!journeys.length) return null;

  // We don't trust the journey id from the router. We still must verify if it's in the fetched parents list
  const parentJourneyFromArgs = parentJourneyId && journeys.find((i) => i?.id.includes(String(parentJourneyId)));

  // If we have a valid journey picking instuction from router, then we use it. Otherwise we go with the first in the list
  const targetParentJourney = parentJourneyFromArgs ? parentJourneyFromArgs : journeys[0];

  // No journey was determined as target => no parent journey
  if (!targetParentJourney) return null;

  // The lamest part. We need to get the id from urn, since it's  the only field "storing" it
  const targetParentJourneyId = Number(targetParentJourney.id.split(':').pop());

  if (Number.isNaN(targetParentJourneyId)) return null;

  return {
    journeyId: targetParentJourneyId,
    journeyTitle: targetParentJourney.title,
    currentLearningObjectId: currentLearningObjectId,
  };
};
