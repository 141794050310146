import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/types/learning/card';
import { Location } from '@/types/learning';
import { BodyMd, Header4, Overline } from '@/components/Typography/Typography';
import { Meta } from '@/components/Meta/Meta';
import { useLocalizedDate, LONG_DATE_FORMAT, SHORT_DATE_FORMAT } from '@/hooks/useLocalizedDate';
import { firstLocation } from '@/util';
import { StyledEventBody, StyledEventBox, StyledEventWrapper } from '@/components/CourseCardLegacy/styles/CourseCardEvent';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

type CourseCardEventProps = {
  card: Card & { locations?: Location[] };
};

export const CourseCardEvent = ({ card: { teaser, title, type, locations } }: CourseCardEventProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  const isCardDescrtionHidden = useFeatureEnabled(FEATURE.UI_CARD_DESCRIPTION_HIDDEN);

  const { startDate, endDate, address } = firstLocation(locations);

  const formattedStart = useLocalizedDate(startDate, LONG_DATE_FORMAT);
  const formattedEnd = useLocalizedDate(endDate, SHORT_DATE_FORMAT);

  const getFormattedDateString = () => {
    if (!formattedEnd) return formattedStart;
    return `${formattedStart} - ${formattedEnd}`;
  };

  return (
    <StyledEventWrapper>
      <StyledEventBox>
        <Overline>{t(type, { ns: 'productType' })}</Overline>
        <Header4>{title}</Header4>
        <Meta caption={getFormattedDateString() || ''} icon="clock" />
        {type === 'seminar' && !!address && <Meta caption={address} icon="map-marker-alt" />}
      </StyledEventBox>
      <StyledEventBody>{!isCardDescrtionHidden && <BodyMd>{teaser}</BodyMd>}</StyledEventBody>
    </StyledEventWrapper>
  );
};
