import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Composition } from 'atomic-layout';
import { useLocation } from 'react-router-dom';

import { useAvailableMeta } from '@/components/CardGrid/hooks/useAvailableMeta';
import { CourseCard } from '@/components/CourseCardLegacy/CourseCard';
import { Card } from '@/types/learning/card';
import { CourseCardPlaceholder } from '../CourseCardLegacy/CourseCardPlaceholder';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { trackLoadMoreClicked } from '@/utils/tracking/learnings';
import { CardFilter, CARDFILTER_PAGETYPE, FilterSchema } from '@/components/CardGrid/CardFilter';
import { CARD_SORTING_METHODS } from '@/store/catalog';
import { LearningSubject, LearningVisibility } from '@/types/learning';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';

enum QueryParamKeys {
  category = 'category',
  subject = 'subject',
}
interface CardGridProps {
  title?: string;
  loading: boolean;
  cards: Card[];
  pageType?: CARDFILTER_PAGETYPE;
  noProviderLogo?: boolean;
  filters?: [FilterType, ...FilterType[]];
  sortFunction?: (a: Card, b: Card) => number;
  isCollection?: boolean;
}

export type FilterType = 'taxonomy' | 'meta' | 'title' | 'attributes';

const PAGE_SIZE = 12;

function getInitialFilter() {
  return (_card: Card) => true;
}

function getInitialSort() {
  return CARD_SORTING_METHODS[CatalogSortingEnum.FEATURED];
}

export const renderCardPlaceholders = (length: number): JSX.Element[] =>
  Array.from({ length }, (_, i) => <CourseCardPlaceholder key={`placeholder-${i}`} />);

export const CardGrid = ({
  title,
  filters,
  loading,
  cards,
  noProviderLogo,
  pageType,
  sortFunction,
  isCollection,
}: CardGridProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [filterFn, setFilterFn] = useState<(card: Card) => boolean>(() => getInitialFilter());
  const [sortFn] = useState<(a: Card, b: Card) => number>(() => sortFunction || getInitialSort());
  const availableMeta = useAvailableMeta(cards, !filters?.includes('meta'));
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category = params.has(QueryParamKeys.category) && parseInt(params.get(QueryParamKeys.category) as string, 10);
  const subject = params.has(QueryParamKeys.subject) && parseInt(params.get(QueryParamKeys.subject) as string, 10);

  useEffect(() => {
    setPageSize(PAGE_SIZE);
  }, [cards]);

  const loadMoreHandler = useCallback(() => {
    setPageSize(pageSize + PAGE_SIZE);
    trackLoadMoreClicked();
  }, [setPageSize, pageSize, PAGE_SIZE, trackLoadMoreClicked]);

  const subjects: LearningSubject[] = useMemo(
    () =>
      Object.values(
        cards.reduce((acc: Record<number, LearningSubject>, card) => {
          card.subjects.forEach((sub) => (acc[sub.subjectId] = sub));
          return acc;
        }, {})
      ),
    [cards]
  );

  const filteredCards = isCollection
    ? cards.filter((card) => card.visibility !== LearningVisibility.HIDDEN)
    : cards
        .filter(({ available }) => available)
        .filter((card) => filterFn(card))
        .sort(sortFn);

  const paginatedSortedFilteredCards = filteredCards.length ? filteredCards.slice(0, pageSize) : [];

  const defaultValues: FilterSchema = { attributes: [] };
  if (category) defaultValues.category = category;
  if (subject) defaultValues.subject = subject;

  return (
    <>
      {filters && pageType && (
        <CardFilter
          pageType={pageType}
          title={title}
          filterTypes={filters}
          availableMeta={availableMeta}
          subjects={subjects}
          setFilterFn={setFilterFn}
          defaultValues={defaultValues}
        />
      )}
      <Composition
        templateCols="minmax(0, 1fr)"
        templateColsMd="repeat(2, minmax(0, 1fr))"
        templateColsLg="repeat(3, minmax(0, 1fr))"
        templateColsXl="repeat(4, minmax(0, 1fr))"
        gap={0.875}
        gapMd={1.75}
      >
        {loading
          ? renderCardPlaceholders(PAGE_SIZE)
          : paginatedSortedFilteredCards.map((card) => (
              <CourseCard
                key={card.id}
                {...{
                  card,
                  noProviderLogo,
                }}
              />
            )) || <p>{t('no-learning-material')}</p>}
      </Composition>
      {!loading && pageSize < filteredCards.length && (
        <ButtonList align="center" padded="vertical">
          <Button $type="primary" onClick={loadMoreHandler}>
            {t('Load more', { ns: 'common' })}
          </Button>
        </ButtonList>
      )}
    </>
  );
};
