export interface EmbedUrlType {
  type?: 'creo' | 'youtube' | 'ted' | 'microsoft' | 'generic' | 'vimeo';
  embedUrl?: string;
  url: string;
}
export const convertVideoToEmbedWithType = (url: string): EmbedUrlType => {
  const youtubeLink = convertYouTubeUrlToEmbed(url);
  if (youtubeLink) {
    return { url, type: 'youtube', embedUrl: youtubeLink };
  }
  const tedLink = convertTedUrlToEmbed(url);
  if (tedLink) {
    return { type: 'ted', embedUrl: tedLink, url };
  }
  const creoLink = convertCreoToEmbed(url);
  if (creoLink) {
    return { type: 'creo', embedUrl: creoLink, url };
  }
  const microsoftLink = convertMicrosoftUrlToEmbed(url);
  if (microsoftLink) {
    return { type: 'microsoft', embedUrl: microsoftLink, url };
  }
  const vimeoLink = convertVimeoUrlToEmbed(url);
  if (vimeoLink) {
    return { type: 'vimeo', embedUrl: vimeoLink, url };
  }
  return { url };
};

export const convertVideoToEmbed = (url: string): string => {
  return (
    convertCreoToEmbed(url) ||
    convertTedUrlToEmbed(url) ||
    convertYouTubeUrlToEmbed(url) ||
    convertMicrosoftUrlToEmbed(url) ||
    convertVimeoUrlToEmbed(url) ||
    url
  );
};

export const convertMicrosoftUrlToEmbed = (url: string): string | undefined => {
  const regExp = /^.*(microsoft.com\/[a-z]{2}-[a-z]{2}\/videoplayer\/embed\/)([a-zA-Z0-9]+).*/;
  const match = url.match(regExp);
  // https://www.microsoft.com/en-us/videoplayer/embed/RWfEsB?pid=ocpVideo0-innerdiv-oneplayer&postJsllMsg=true&maskLevel=20&market=en-us
  if (match && match[2]) {
    return `//www.microsoft.com/en-us/videoplayer/embed/${match[2]}`;
  } else {
    return;
  }
};
export const convertYouTubeUrlToEmbed = (url: string): string | undefined => {
  // stolen here https://stackoverflow.com/a/38134765/869256 and improved
  const regExp =
    /(?:.+?)?(?:youtube\.com\/embed\/|\/v\/|watch\/|\?v=|\&v=|youtu\.be\/|\/v=|^youtu\.be\/|watch\%3Fv\%3D)([a-zA-Z0-9_-]{11})+/;
  const match = url.match(regExp);

  if (match) {
    return `//www.youtube.com/embed/${match[1]}`;
  } else {
    return;
  }
};

export const convertCreoToEmbed = (url: string): string | undefined => {
  let videoId, subdomain;
  let targetUrl = url;
  if (url.indexOf('creo.se') > -1) {
    const domain = new URL(url).host.split('.');
    subdomain = domain.slice(0, domain.length - 2).join('.');
    if (url.indexOf('oembed') > -1) {
      const urlParam = new URL(url).searchParams.get('url');
      if (urlParam) {
        targetUrl = decodeURIComponent(urlParam);
      }
    }
    const match = targetUrl.match(/^.*creo\.se\/i\/([^#\&\?/]*).*/);
    videoId = match && match[1];
    if (videoId) {
      const creoUrl = `//${subdomain ? subdomain + '.' : ''}creo.se/i/${videoId}`;
      return creoUrl;
    }
    return;
  }

  const isCreoV3Url = /https?:\/\/api\.cvm3\.se\/./.test(url);
  if (isCreoV3Url) {
    return url.replace('/i/', '/player/');
  }
};

export const convertTedUrlToEmbed = (url: string): string | undefined => {
  const isTedUrl = /(https?:\/\/(.+?\.)?ted\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/.test(url);
  if (isTedUrl) {
    if (url.indexOf('embed.ted.com') !== -1) return url;
    return url.replace('www.', 'embed.');
  }
  return;
};

export const convertVimeoUrlToEmbed = (url: string): string | undefined => {
  const vimeoMatch = String(url).match(/(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:video\/|)(\d+)(?:|\/\?)/);

  if (!vimeoMatch || vimeoMatch.length < 4 || isNaN(parseInt(vimeoMatch[3]))) return;

  return `https://player.vimeo.com/video/${vimeoMatch[3]}?speed=true`;
};
