export type EventParams = {
  key: string;
  count?: number;
  sum?: number;
  dur?: number;
  segmentation?: Record<string, string>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const trackEvent = (eventParams: EventParams): void => {};
