import { useMemo } from 'react';

import { UseCourseCardsHook } from '../types';
import { sortOrder } from '@/store/utils';
import { CatalogItem } from '@/types/learning/learning-catalog';
import { PROGRESS_CATALOG_OPTIONS, useCardCatalog } from '@/store/catalog';
import { isProgressCard, ProgressCard } from '@/types/learning/card';
import { AssignmentLearningCard, CardWithProgressStatus } from '@/store/utils/assignments/types';
import { getStrategicPriority } from '@/store/utils/assignments/strategicAssignment/getPriority';

export const useCourseCards: UseCourseCardsHook = ({ assignment }) => {
  const catalogItems = assignment?.learnings
    ?.map((x): CatalogItem | undefined => {
      if (x.learningId) {
        return {
          id: x.learningId,
          entity: 'learning',
        };
      } else if (x.learningPathId) {
        return {
          id: x.learningPathId,
          entity: 'product',
        };
      }
    })
    .filter((m): m is CatalogItem => !!m);

  const { catalog, loading, getCardByObjectId, error } = useCardCatalog({ items: catalogItems, ...PROGRESS_CATALOG_OPTIONS });
  const cardsWithParticipations =
    assignment.participations
      .map((x) => {
        const card = x.catalogObjectId
          ? getCardByObjectId(x.catalogObjectId)?.find((m) =>
              catalogItems?.some((p) => p.entity === m.entity && p.id === m.entityId)
            )
          : undefined;

        if (!card) {
          return;
        }

        return { ...card, participation: x } as ProgressCard;
      })
      .filter((m): m is ProgressCard => !!m)
      .map(
        (x): CardWithProgressStatus => ({
          card: x,
          status: x.participation?.isCompleted ? 'completed' : 'ongoing',
        })
      )
      .concat(
        catalog.cards
          .filter((c) => isProgressCard(c) && (c as ProgressCard).participation)
          .map(
            (c): CardWithProgressStatus => ({
              card: c,
              status: (c as ProgressCard).participation?.isCompleted ? 'completed' : 'ongoing',
            })
          )
      )
      .filter(({ card }, index, arr) => arr.findIndex(({ card: { id } }) => id === card.id) === index) ?? []; // filtering unique only

  const cardsWithoutParticipations = catalog.cards
    .filter((m) => !cardsWithParticipations?.some((x) => x.card.entityId === m.entityId && x.card.entity === m.entity))
    .map((x): CardWithProgressStatus => {
      return {
        card: { ...x, participation: undefined } as ProgressCard,
        status: 'not-started',
      };
    });

  const allItems = [...cardsWithParticipations, ...cardsWithoutParticipations].map((x): AssignmentLearningCard<'STRATEGIC'> => {
    const order = assignment?.learnings?.find(
      (m) =>
        (m.learningId === x.card.entityId && x.card.entity === 'learning') ||
        (m.learningPathId === x.card.entityId && x.card.entity === 'product')
    )?.order;

    return {
      ...x.card,
      order,
      priority: getStrategicPriority(x.status),
      status: x.status,
    };
  });

  const [processedCards, count, cards] = useMemo(() => {
    if (loading || !allItems || error) {
      return [[], 0, []];
    }
    const cards = allItems;
    let processedItems = allItems;

    processedItems = [...processedItems].sort((m, o) => sortOrder(m.priority, o.priority) || sortOrder(m.order, o.order));

    const total = processedItems.length;

    return [processedItems, total, cards];
  }, [allItems, loading]);

  return {
    cards,
    processedCards,
    total: cards?.length,
    processedTotal: count,
    loading,
    error,
  };
};
