import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mediaQuery } from '@/ui/utils';
import { ButtonGroup } from '@/ui/ButtonGroup/ButtonGroup';

const variant = {
  narrow: mediaQuery.until('mobileLandscape'),
  wide: mediaQuery.from('tablet'),
};

export const Background = styled.div`
  background: ${(p) => p.theme.tones.neutral[99]};
`;

export const FooterWrapper = styled.footer`
  padding: 64px 0;
  color: ${(p) => p.theme.colors.neutral.onContainer};
  ${(p) => p.theme.typography.body2}
`;

export const FooterLayout = {
  Top: styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${(p) => p.theme.colors.neutral.outline1};
    gap: 32px;
    ${variant.narrow} {
      padding-bottom: 8px;
    }
    ${variant.wide} {
      padding-bottom: 56px;
    }
  `,
  Bottom: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${variant.narrow} {
      padding-top: 8px;
    }
    ${variant.wide} {
      padding-top: 16px;
    }
  `,
};

export const LogoLink = styled(Link)`
  ${variant.narrow} {
    display: none;
  }
  ${variant.wide} {
    display: block;
    width: 70px;
    height: 70px;
  }
`;

export const CollegialLogo = styled.img`
  display: block;
  width: 70px;
  height: 70px;
`;

export const EuLogo = styled.img`
  ${variant.narrow} {
    display: none;
  }
  ${variant.wide} {
    display: block;
    width: 82px;
    height: 70px;
  }
`;

export const Support = styled.div`
  ${variant.narrow} {
    align-self: flex-start;
  }
  ${variant.wide} {
    order: 1;
    align-self: center;
  }
`;

export const LinksTop = styled(ButtonGroup).attrs({
  direction: 'vertical',
})`
  flex-grow: 1;
`;

export const Copyright = styled.div`
  ${variant.narrow} {
    width: 100%;
    order: 2;
  }
  ${variant.wide} {
    order: 1;
    flex-grow: 1;
  }
`;

export const LinksBottom = styled(ButtonGroup).attrs({
  verticalUntil: 'mobileLandscape',
  gap: 'large',
})`
  ${variant.narrow} {
    width: 100%;
    order: 1;
    align-items: flex-end;
  }
  ${variant.wide} {
    order: 2;
  }
`;

export const TextLink = styled(Link)`
  color: ${(p) => p.theme.colors.primary.main};
`;

export const AppVersion = styled.span`
  display: none;
`;
