import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Element as SlateElement, Transforms } from 'slate';

import { ListTypes, CustomNode } from '../types';
import { CustomSlateElementType } from '@/types/slate';

type ElementsLogic = {
  editor: Editor & ReactEditor;
  isBlockActive: (format: string) => boolean;
  toggleBlock: (format: CustomSlateElementType) => void;
};

export const useElements = (): ElementsLogic => {
  const editor = useSlate();

  const isBlockActive = (format: string): boolean => {
    const [match] = Editor.nodes(editor, {
      match: (node: CustomNode) => !Editor.isEditor(node) && SlateElement.isElement(node) && node.type === format,
    });

    return !!match;
  };

  const toggleBlock = (format: CustomSlateElementType): void => {
    const isActive = isBlockActive(format);
    const isList = ListTypes.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (node: CustomNode) => {
        if (Editor.isEditor(node)) {
          return false;
        }

        if (!SlateElement.isElement(node)) {
          return false;
        }

        if (!node.type) {
          return false;
        }

        return ListTypes.includes(node.type);
      },
      split: true,
    });

    const newProperties: Partial<SlateElement> = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [{ text: '' }] };
      Transforms.wrapNodes(editor, block);
    }
  };

  return {
    editor,
    isBlockActive,
    toggleBlock,
  };
};
