import styled, { css } from 'styled-components';

export const RootStyled = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-flex;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
    `}
`;

/**
 * Used hack from @link https://polished.js.org/docs/#hidevisually
 * To make it visible for screen-readers, but also hidden for user
 */
export const AccessibleInputStyled = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const ToggleStyled = styled.div<{ toggled?: boolean; disabled?: boolean }>`
  width: 40px;
  height: 22px;
  border-radius: 11px;
  background: #cedada;
  box-sizing: border-box;
  padding: 1px;
  transition: all 0.2s;
  ${({ theme }) => theme.shadow.inset[2]};

  ${({ toggled }) =>
    toggled &&
    css`
      background: ${({ theme }) => theme.colors.primary.main};
    `}
`;

export const ToggleSlider = styled.div<{ toggled?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary.onMain};
  transition: all 0.2s;

  ${({ toggled }) =>
    toggled &&
    css`
      margin-left: calc(100% - 20px);
    `}
`;
