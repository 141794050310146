import styled, { useTheme } from 'styled-components';

import { Informer } from '@/ui/Informer';

const RootStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 8px;
  padding: 32px;
  display: flex;
  gap: 32px;
  /**
    TODO (for later PRs)
    Remove it after setting up the correct course view layout.
   */
  // Start of removal target block
  margin-top: 72px;
  margin-bottom: -72px;
  margin-left: 58px;
  margin-right: 58px;

  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    margin-left: 0;
    margin-right: 0;
  }
  // End of removal target block
`;

const InformerStyled = styled(Informer)`
  width: 166px;
`;

export const ResultsSection = ({ correctAnswersCount, score }: { correctAnswersCount: number; score: number }): JSX.Element => {
  const theme = useTheme();

  return (
    <RootStyled>
      <InformerStyled
        title={`${Math.round(score) || 0}%`}
        label={'Score'}
        sideBox={{
          background: theme.tones.primary[95],
          icon: ['far', 'bullseye-arrow'],
          size: 'medium',
          iconColor: theme.tones.primary[70],
        }}
      />
      <InformerStyled
        title={String(correctAnswersCount)}
        label={'Correct Answers'}
        sideBox={{
          background: theme.colors.tertiary.container,
          icon: ['far', 'bolt'],
          size: 'medium',
          iconColor: theme.tones.tertiary[70],
        }}
      />
    </RootStyled>
  );
};
