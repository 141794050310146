import React, { useEffect, useState } from 'react';
import { NavLink, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import './discussions.scss';
import { GET_SECTION, useCreateSection } from '../../store/discussion';
import { Section } from '../../types/Section';
import { PostSection } from './PostSection';
import { NewSectionForm } from './NewSectionForm';
import { Teams } from '../../administration/pages/ProductEditor/component/CourseTeams';
import { REFLECTIONS } from '../../pages/learning/CourseDetails/components/ReflectionsTab';
import { ErrorMessage } from '../ErrorMessage';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';

interface Props {
  path: string[];
  teamNo?: number;
  teams?: Teams;
  spaceId: number;
  enableGeneralChannel?: boolean;
}

enum SIDEBAR {
  GENERAL = 'general',
  TEAM = 'my-team',
}

enum SECTION {
  GENERAL = 'general',
  TEAM = 'team',
}
const DEFAULT_SIDEBAR: string[] = [SIDEBAR.TEAM, SIDEBAR.GENERAL];

interface MenuItem {
  label: string;
  pathname: SIDEBAR | string;
  section: SECTION | string;
  subsections?: MenuItem[];
}

const Loading = () => <div className="pageloader" />;

export const CoursePageDiscussion = ({ path, teamNo, teams, spaceId, enableGeneralChannel }: Props): JSX.Element | null => {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('');
  const { t } = useTranslation('discussions');
  const [showSectionForm, setSectionForm] = useState(false);
  const createSection = useCreateSection();
  const { data, updateQuery, refetch, loading, error } = useQuery(GET_SECTION, {
    variables: { path, page: 1, pageSize: 0 },
  });

  const baseParentUrl: any = '/learning/course/details/2/agile-literacy-o/discussions';

  // If the section doesn't exist, the (apollo) cache id will be null
  // and any similar query will override the cache result
  useEffect(() => {
    if (data) {
      updateQuery((res) => {
        if (res) {
          const { getSection: prev } = res;
          return { getSection: { ...prev, id: prev?.id || prev?.path } };
        }
      });
    }
  }, [data]);

  const canListAllTeams = data?.getSection?.role?.permissions?.canEditSection;

  const subsections: Section[] = data?.getSection?.subsections || [];
  const sectionId: number | null = data?.getSection?.id || null;
  const channels: MenuItem[] = subsections
    .filter(({ title }) => title?.substr(0, 5) !== 'team-')
    .map(({ title, path: subSectionPath }) => {
      const value = subSectionPath.split('/').slice(path.length + 1)[0]; // + 1 Because the first item is ""

      return {
        label: title!,
        pathname: encodeURIComponent(value!),
        section: value!,
      };
    });

  const teamChannels: MenuItem[] = [];
  if (teams) {
    teams.forEach((team, index) => {
      teamChannels.push({
        label: `Team ${index + 1}`,
        pathname: encodeURIComponent(`team-${index + 1}`),
        section: `team-${index + 1}`,
      });
    });
  }
  const generalSidebarItem: MenuItem = { label: t('General'), pathname: SIDEBAR.GENERAL, section: SECTION.GENERAL };
  generalSidebarItem.subsections = channels
    .filter((ch) => !DEFAULT_SIDEBAR.includes(ch.pathname))
    .filter((ch) => ch.section !== REFLECTIONS)
    .sort((a, b) => a.label.localeCompare(b.label));
  const topLevelSidebarItems: MenuItem[] = [generalSidebarItem];

  if (enableGeneralChannel || !channels.length) {
    channels.push(generalSidebarItem);
  }

  if (teamNo) {
    topLevelSidebarItems.push({ label: t('My team'), pathname: SIDEBAR.TEAM, section: `${SECTION.TEAM}-${teamNo}` });

    if (!channels.find(({ pathname }) => pathname === SIDEBAR.TEAM)) {
      channels.push({ label: t('My team'), pathname: SIDEBAR.TEAM, section: `${SECTION.TEAM}-${teamNo}` });
    }
  }

  const addSubsection = async (name: string, title?: string, description?: string) => {
    await createSection(path, name, title, description);
    await refetch();
  };

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorMessage>
        <span>{t('Something went wrong')}</span>
      </ErrorMessage>
    );

  const teamChannel = teamChannels?.find((m) => m.pathname === selectedMenu);

  return sectionId !== null ? (
    <div className="grid">
      <div className="gc gc-3-d gc-4-t gc-12-l gc-12-p" styleName="discussion-sidebar">
        <aside className="menu is-sublist" style={{ marginBottom: '2rem' }}>
          <div className="overline has-text-grey-overline">{t('Channels')}</div>
          <ul className="menu-list">
            {topLevelSidebarItems.map(({ label, pathname, subsections }) => (
              <li className="is-active is-sentence" key={pathname}>
                <NavLink
                  to={{ pathname }}
                  className={({ isActive }) => (isActive ? 'is-active' : undefined)}
                  state={{
                    noScroll: true,
                  }}
                >
                  {label}
                </NavLink>
                {!!subsections?.length && (
                  <ul>
                    {subsections?.map(({ label, pathname: subMenuPathname }) => (
                      <li className="is-sentence" key={label}>
                        <NavLink
                          to={{ pathname: subMenuPathname }}
                          className={({ isActive }) => (isActive ? 'is-active' : undefined)}
                          state={{
                            noScroll: true,
                          }}
                        >
                          {label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </aside>

        {canListAllTeams && Boolean(teamChannels.length) && (
          <aside className="menu" style={{ marginBottom: '2rem' }}>
            <div className="overline has-text-grey-overline">{t('Team Channels')}</div>
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    className="select"
                    value={selectedMenu}
                    onChange={(e) => {
                      setSelectedMenu(e.target.value);
                      navigate(e.target.value, { state: { noScroll: true } });
                    }}
                  >
                    <option value={baseParentUrl}>{t('Select a team channel')}</option>
                    {teamChannels.map(({ label, pathname }) => (
                      <option value={pathname} key={pathname}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </aside>
        )}

        {data?.getSection?.role.permissions.canEditSection && (
          <aside className="menu">
            <div className="overline has-text-grey-overline">{t('Actions', { ns: 'common' })}</div>
            {!showSectionForm && (
              <ButtonList>
                <Button $fullwidth onClick={() => setSectionForm(true)}>
                  {t('New channel')}
                </Button>
              </ButtonList>
            )}
            {showSectionForm && <NewSectionForm onSubmit={addSubsection} setSectionForm={setSectionForm} />}
          </aside>
        )}
      </div>
      <div className="gc gc-9-d gc-8-t gc-12-l gc-12-p">
        <Routes>
          <Route
            path={':selected'}
            element={
              <>
                {canListAllTeams && teamChannel && (
                  <PostSection
                    spaceId={spaceId}
                    path={[...path, teamChannel.section]}
                    team={
                      teamChannel.section.includes('team-') && teams
                        ? teams.get(parseInt(teamChannel.section.split('-')[1]) - 1)
                        : undefined
                    }
                  />
                )}
              </>
            }
          />
          {channels.map(({ pathname, section }) => (
            <Route
              key={`route-${pathname}`}
              path={pathname}
              element={
                <PostSection
                  spaceId={spaceId}
                  path={[...path, section]}
                  team={section.includes('team-') && teams ? teams.get(parseInt(section.split('-')[1]) - 1) : undefined}
                />
              }
            />
          ))}
          <Route path="*" element={<Navigate to={SIDEBAR.GENERAL} />} />
        </Routes>
      </div>
    </div>
  ) : null;
};
