import { useEffect, useRef, useState } from 'react';

export const useElementExpandable = ({
  collapseThreshold,
}: {
  collapseThreshold: number;
}): {
  ref: React.RefObject<HTMLElement>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  fullHeight?: number;
  canExpand: boolean;
} => {
  const [expanded, setExpanded] = useState(false);
  const [fullHeight, setFullHeight] = useState<number | undefined>();
  const [canExpand, setCanExpand] = useState(false);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const scrollHeight = ref?.current?.scrollHeight;
    setFullHeight(scrollHeight);
    if (scrollHeight && scrollHeight > collapseThreshold) {
      setCanExpand(true);
    }
  }, [ref]);

  return {
    ref,
    expanded,
    setExpanded,
    fullHeight,
    canExpand,
  };
};
