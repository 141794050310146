import React, { ReactElement } from 'react';

import { Provider } from '@/store/providers';
import { Coursera } from './components/Coursera';
import { GeneralProvider } from './components/GeneralProvider';
import { Linkedin } from './components/Linkedin';
import { Pluralsight } from './components/Pluralsight';
import { Imparta } from '@/pages/Provider/components/Imparta';

export function getProviderHero(provider: Provider, productId?: number): ReactElement {
  const providerJsx: Partial<{ [key in Provider]: () => JSX.Element }> = {
    pluralsight: function pluralsight() {
      return <Pluralsight accessProductId={productId} />;
    },
    coursera: function coursera() {
      return <Coursera />;
    },
    linkedin: function linkedin() {
      return <Linkedin accessProductId={productId} />;
    },
    imparta: function imparta() {
      return <Imparta accessProductId={productId} />;
    },
  };
  const providerDefault = () => <GeneralProvider provider={provider} key={provider} />;

  return (providerJsx[provider] || providerDefault)();
}
