import { Control, Controller } from 'react-hook-form';

import { FormSchema, TextFieldName } from '../..';
import { Select, TextFieldSelect } from '@/ui/Select';
import { TIMEZONE_TO_LABEL_CONFIG } from '@/utils/time';

export const FormTimezone = ({ control }: { control: Control<FormSchema> }): JSX.Element => {
  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.Timezone}
      render={({ field: { onChange, value } }) => (
        <TextFieldSelect
          label="Time zone"
          value={TIMEZONE_TO_LABEL_CONFIG.find(({ offsetInMinutes }) => offsetInMinutes === Number(value))?.name || ''}
          onChange={onChange}
          fullWidth
          leadingIcon={['far', 'globe']}
        >
          <Select.List fullWidth>
            {TIMEZONE_TO_LABEL_CONFIG.map(({ offsetInMinutes, name }) => (
              <Select.Option value={String(offsetInMinutes)} key={offsetInMinutes}>
                {name}
              </Select.Option>
            ))}
          </Select.List>
        </TextFieldSelect>
      )}
    />
  );
};
