import styled, { css } from 'styled-components';

export type EmptyStateProps = {
  title: string;
  minimized?: boolean;
};

const RootStyled = styled.div`
  gap: 32px;
`;

const TitleStyled = styled.div<{ minimized?: boolean }>`
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.neutral.onBackground};

  margin: 44px 32px;
  ${({ minimized }) =>
    minimized &&
    css`
      margin-left: 0;
    `};
`;

export const EmptyState = ({ title, minimized }: EmptyStateProps): JSX.Element => {
  return (
    <RootStyled>
      <TitleStyled minimized={minimized}>{title}</TitleStyled>
    </RootStyled>
  );
};
