import { ASSIGNMENT_STATUS } from '@/store/assignments/constants';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { calculateFilteredMyLearnings, FilteredMyLearnings } from '@/pages/user/MyLearning/calculateFilteredMyLearnings';
import { useQueryParticipations } from '@/store/analytics/participations';
import { AssignmentV3 } from '@/store/assignments/types';
import { useCardCatalog } from '@/store/catalog';
import { useInventory } from '@/store/inventory';
import { useRootContext } from '@/store/RootContext';
import { useSpaces } from '@/store/spaces';
import { UsersPool } from '@/types/analytics';
import { Card } from '@/types/learning/card';
import { EventEnrollment, ProductType } from '@/types/learning/learning-catalog';
import { Assignment } from '@/store/utils/assignments/types';

import { AssignmentCard, useAssignmentCards, useBaseAssignments } from '.';

type UseMyLearningCardsHook = (query: { filterType?: ProductType; hideStrategicLearnings?: boolean }) => {
  cardFilterPredicate?: (_: Card) => boolean;
  ongoingCount: number;
  todoCount: number;
  filtered: FilteredMyLearnings;
  inventoryEnrollments: readonly EventEnrollment[];
  loading: boolean;
  featuredCards: Card[];
  assignmentCards: AssignmentCard[];
};

export const useMyLearningCards: UseMyLearningCardsHook = ({ filterType, hideStrategicLearnings }) => {
  const { inventory, loading: loadingInventory } = useInventory();
  const { catalog, loading: loadingCatalog } = useCardCatalog({ showArchived: true });
  const { currentUser, loading: loadingUser } = useRootContext();
  const userIds = currentUser?.id ? [currentUser.id] : undefined;
  const { spaces, loading: loadingSpaces } = useSpaces();

  const hasLoadedUser = userIds?.length || !loadingUser;
  const hasLoadedSpaces = spaces?.length || !loadingSpaces;

  const isAssignmentCardEnabled = useFeatureEnabled(FEATURE.UI_ASSIGNMENT_CARD);

  const { data, loading: loadingAssignments } = useBaseAssignments({
    userIds,
    status: ASSIGNMENT_STATUS.published,
    spaces,
    skip: !hasLoadedSpaces || !hasLoadedUser || isAssignmentCardEnabled,
  });

  const { loading: loadingAssignmentCards, cards: assignmentCards } = useAssignmentCards({
    userId: currentUser?.id ?? 0,
    spaces,
    skip: !isAssignmentCardEnabled || !currentUser?.id,
  });

  const hasLoadedInventory = inventory.allProgress.length || !loadingInventory;
  const hasLoadedCatalog = catalog.cards.length || !loadingCatalog;
  const skip = !hasLoadedUser || !hasLoadedInventory || !hasLoadedCatalog;

  const { data: participationsData, loading: loadingParticipations } = useQueryParticipations(
    {
      userIdEq: currentUser?.id,
      includeCatalogData: true,
      usersPool: UsersPool.REALM,
      objectTypeNin: ['survey'],
    },
    skip
  );

  const hasLoadedAssignments = isAssignmentCardEnabled
    ? assignmentCards?.length || !loadingAssignmentCards
    : data?.length || !loadingAssignments;
  const hasLoadedParticipations = participationsData?.participations?.length || !loadingParticipations;

  const loading =
    !hasLoadedInventory ||
    !hasLoadedCatalog ||
    !hasLoadedAssignments ||
    !hasLoadedParticipations ||
    !hasLoadedSpaces ||
    !hasLoadedUser;

  if (loading) {
    return {
      loading,
      ongoingCount: 0,
      todoCount: 0,
      featuredCards: [],
      inventoryEnrollments: [],
      filtered: {
        assigned: {
          assignedCards: [],
          otherAssignments: [],
        },
        ongoing: {
          catalog: [],
          inventory: [],
        },
        completed: {
          catalog: [],
          inventory: [],
        },
      },
      assignmentCards: [],
    };
  }

  const assignments = createAssignments(data, hideStrategicLearnings);
  const { cardFilterPredicate, ongoingCount, todoCount, filtered } = calculateFilteredMyLearnings({
    filterType,
    catalogCards: catalog.cards,
    inventory,
    serviceManagerCertificateCards: [],
    assignments,
    participations: participationsData?.participations,
  });

  const assignmentCount = isAssignmentCardEnabled
    ? assignmentCards.filter((x) => x.status !== 'completed' && x.status !== 'ended').length
    : todoCount;
  return {
    cardFilterPredicate,
    ongoingCount,
    todoCount: assignmentCount,
    filtered,
    inventoryEnrollments: inventory.eventEnrollments,
    loading,
    featuredCards: catalog.cards.filter((s) => s.featured),
    assignmentCards,
  };
};

const createAssignments = (data?: readonly AssignmentV3[], hideStrategicLearnings?: boolean): Assignment[] => {
  if (!data) {
    return [];
  }
  return data
    .map((element) => {
      if (element.type === 'STANDARD') {
        const { type: type, learnings: learnings, ...rest } = element;
        const standardAssignment: Assignment<'STANDARD'> = {
          ...rest,
          assignmentType: type,
          learnings: learnings.data,
        };
        return standardAssignment;
      } else if (element.type === 'STRATEGIC') {
        const { type: type, learnings: learnings, ...rest } = element;
        const strategicAssignment: Assignment<'STRATEGIC'> = {
          ...rest,
          assignmentType: type,
          learnings: !hideStrategicLearnings ? learnings.data : undefined,
        };
        return strategicAssignment;
      }
    })
    .filter((l): l is Assignment => !!l);
};
