import { ApolloError } from '@apollo/client';

import { useUserLearningContentLastParticipationQueryRemote } from '@/store/v2';
import { ParticipationV3 } from '@/store/v2/catalog-v2/model/participation';

export const useUserContentLastParticipation = ({
  userId,
  contentURN,
}: {
  userId?: number;
  contentURN?: string;
}): {
  participation: ParticipationV3 | undefined;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
} => {
  const { data, loading, error, refetch } = useUserLearningContentLastParticipationQueryRemote({
    variables: { userId: userId || 0, learningContentId: contentURN || '' },
    skip: !userId || !contentURN,
  });

  return {
    loading,
    error,
    participation: data?.userLearningContentLastParticipation || undefined,
    refetch,
  };
};
