import React from 'react';

import { PostSection } from '../../../../component/Discussions/PostSection';

interface Props {
  path: string;
  spaceId: number;
}

export const REFLECTIONS = 'reflections';

export const ReflectionsTab = ({ path, spaceId }: Props): JSX.Element => {
  return (
    <div className="grid">
      <div className="gc gc-12">
        <PostSection spaceId={spaceId} isReflections path={[...path.split('/').slice(1), REFLECTIONS]} />
      </div>
    </div>
  );
};
