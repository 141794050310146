import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { SuperAdminMenu } from '@/components/SuperAdminMenu/SuperAdminMenu';
import { StyledBodyFill, StyledBodyWrapper } from './styles';
import { Footer } from '@/component/Footer';
import { useCurrentUser, useCurrentUserAccess } from '@/store/currentUser';
import { Navbar } from '@/components/Navbar';

export const LayoutWrapper = (): JSX.Element => {
  const { user } = useCurrentUser();
  const { isSuperAdmin } = useCurrentUserAccess();
  const [showSuperAdminTools] = useLocalStorage<boolean>('showSuperAdminTools', false);

  return (
    <>
      <StyledBodyWrapper banner={!!user?.isDebugging}>
        <Navbar />
        <StyledBodyFill>
          <Suspense fallback={<div className="pageloader" />}>
            <Outlet />
          </Suspense>
        </StyledBodyFill>
        <Footer />
      </StyledBodyWrapper>
      {isSuperAdmin && showSuperAdminTools && <SuperAdminMenu />}
    </>
  );
};
