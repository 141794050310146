import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Composition } from 'atomic-layout';

import { Provider, providers } from '@/store/providers';
import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { ButtonList } from '@/components/Button/ButtonList';

interface Props {
  provider: Provider;
}

export const providerFormAreas = `
  first
  second
`;

export const providerFormAreasLg = `
  first second
`;

export const GeneralProvider = ({ provider }: Props): React.ReactElement | null => {
  const { t } = useTranslation('providers');
  const providerMeta = providers.find((p) => p.provider === provider);

  if (!providerMeta) return null;

  return (
    <Composition
      areas={providerFormAreas}
      areasLg={providerFormAreasLg}
      templateCols="minmax(0, 1fr)"
      templateColsLg="minmax(0, 9fr) minmax(0, 3fr)"
      gap={0.875}
      gapMd={1.75}
    >
      {({ First, Second }) => (
        <>
          <First>
            <Header1>{t(providerMeta.titleKey)}</Header1>
            <BodyLg>
              <Trans t={t} i18nKey={providerMeta.descriptionKey}>
                <BodyLg fontWeight="font-weight-bold" />
              </Trans>
            </BodyLg>
          </First>
          <Second>
            <ButtonList align="right">
              <ProviderLogo provider={provider} />
            </ButtonList>
          </Second>
        </>
      )}
    </Composition>
  );
};
