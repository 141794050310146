import React from 'react';

import { ImageMeta } from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';

interface Props {
  data: ImageMeta;
}

export const ImageBlock = ({ data }: Props): JSX.Element => {
  return (
    <div className="content is-article is-unpadded">
      <figure>
        <div className="image is-background is-16by9">
          <div className="image-background" style={{ backgroundImage: `url(${data.image})` }} />
        </div>
        {data.description && <figcaption>{data.description}</figcaption>}
      </figure>
    </div>
  );
};
