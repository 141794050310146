import styled from 'styled-components';

import { getFileExtensionAndName } from '@/component/FileUpload/helpers';
import { Preview } from './Preview';

const RootStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CustomSizeContainerStyled = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const Serializable = ({
  width,
  documentUrl,
  documentName,
}: {
  width?: number;
  documentUrl: string;
  documentName?: string;
}): JSX.Element => {
  const { name, extension } = getFileExtensionAndName({ documentUrl: documentUrl || '', fileName: documentName || '' });

  return (
    <RootStyled>
      <CustomSizeContainerStyled width={width}>
        <Preview fileExtension={extension} fileName={name} url={documentUrl} isInteractive />
      </CustomSizeContainerStyled>
    </RootStyled>
  );
};
