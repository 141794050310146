import styled from 'styled-components';

import { BaseButton } from '@/components/Button/components/BaseButton';

export const NestedDropdownFooterButton = styled(BaseButton)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;
