import { QuestionBlock } from '@/administration/pages/Course/store/block/types';
import { RadioQuestionPage } from './variants/RadioQuestionPage';

type ViewQuestionPageProps = {
  block: QuestionBlock;
  onBlockChange: (block: QuestionBlock) => void;
};

export const ViewQuestionPage = ({ block, onBlockChange }: ViewQuestionPageProps): JSX.Element => {
  switch (block?.variant) {
    case 'RADIO':
    default:
      return (
        <RadioQuestionPage
          questionBlock={block}
          onAnswerSelected={(answerValue) => onBlockChange({ ...block, answerValue })}
          selectedAnswer={block.answerValue}
        />
      );
  }
};
