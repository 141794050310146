import React from 'react';

import { Loader, LoaderWrapper } from './styles';
export const SpinningLoader = (): JSX.Element => {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
};
