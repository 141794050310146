import React, { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Only } from 'atomic-layout';

import { StyledMobileButtonsWrapper, StyledMobileMenu, StyledNavMobileIcon } from '@/components/Navbar/styles';
import { useDropdown } from '@/hooks/useDropdown';
import { NAV_LINKS } from '@/components/Navbar/consts';
import { NavSearch } from '../NavSearch/NavSearch';
import { checkAdminView } from '@/utils/checkAdminView';
import { ExploreMobileMenu } from '../ExploreMenu/ExploreMobileMenu';

type MenuTypes = 'home' | 'search' | 'explore';

export const NavMobileMenu = (): JSX.Element | null => {
  const { isOpen, containerRef, close, handleOpenClose } = useDropdown<HTMLDivElement>();
  const [menuType, setMenuType] = useState<MenuTypes | null>(null);
  const location = useLocation();
  const isAdminView = checkAdminView();
  const handleOpenFactory = useCallback(
    (type: MenuTypes) => () => {
      setMenuType(type);
      handleOpenClose(menuType === type ? !isOpen : true);
    },
    [isOpen, handleOpenClose, setMenuType, menuType]
  );

  if (isAdminView) return null;
  const renderStyledMobileMenuContent = () => {
    if (menuType === 'search') {
      return <NavSearch isMobile onNavigation={close} />;
    }
    if (menuType === 'explore') {
      return <ExploreMobileMenu close={close} />;
    }
    return null;
  };

  return (
    <Only to="lg">
      <StyledMobileButtonsWrapper ref={containerRef}>
        <NavLink to={NAV_LINKS.HOME} onClick={close}>
          <StyledNavMobileIcon icon="home" selected={location.pathname === '/' && !isOpen} />
        </NavLink>
        <StyledNavMobileIcon icon="compass" selected={menuType === 'explore' && isOpen} onClick={handleOpenFactory('explore')} />
        <StyledNavMobileIcon icon="search" selected={menuType === 'search' && isOpen} onClick={handleOpenFactory('search')} />
        {isOpen && <StyledMobileMenu>{renderStyledMobileMenuContent()}</StyledMobileMenu>}
      </StyledMobileButtonsWrapper>
    </Only>
  );
};
