import styled from 'styled-components';

export const StyledLabel = styled.span`
  ${(p) => p.theme.typography.overline};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;
export const StyledCaption = styled.span`
  ${(p) => p.theme.typography.caption};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;
export const ColumnStyled = styled.div`
  width: 50%;
`;

export const WrapStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

export const Divider = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.neutral.container};
  width: 100%;
`;
