export const getHomeTextTranslateKey = (percentage: number): string => {
  if (percentage <= 30) {
    return 'home-text-top';
  }
  if (percentage <= 50) {
    return 'home-text-mid';
  }

  return 'home-text-low';
};
