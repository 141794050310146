import styled, { css } from 'styled-components';

export const ToolbarWrapper = styled.div<{ $minimized?: boolean }>`
  ${({ theme, $minimized }) => {
    return $minimized
      ? css`
          border: 1px solid ${theme.colors.neutral.onContainer};
          border-radius: 4px 4px 0 0;
          max-height: 40px;
        `
      : css`
          background: var(--color-background-light);
          border: var(--border-style);
          border-bottom: none;
          border-radius: calc(var(--radius-inner) + var(--border-width)) calc(var(--radius-inner) + var(--border-width)) 0 0;
        `;
  }};
`;

export const StyledToolbarGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;

export const Divider = styled.div<{ minimized?: boolean }>`
  ${({ theme, minimized }) => {
    return minimized
      ? css`
          height: 24px;
          border-left: 1px ${theme.colors.neutral.outline1} solid;
          margin-top: auto;
          margin-bottom: auto;
        `
      : css`
          border-left: var(--border-style);
        `;
  }};
  display: inline-block;
  height: ${({ minimized }) => (minimized ? `24px` : `2.75rem`)};
  margin-left: ${({ minimized }) => (minimized ? `0px` : `8px`)};
  padding-right: ${({ minimized }) => (minimized ? `0px` : `8px`)};
  width: 0;
`;
