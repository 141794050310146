import styled, { css } from 'styled-components';

export const StyledDocumentContainer = styled.div<{ border?: 'dashed' | 'solid'; nogap?: boolean; fullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ nogap }) => (nogap ? '0px' : '32px')};
  ${({ fullHeight }) =>
    fullHeight
      ? css`
          flex: 1;
          justify-content: center;
        `
      : css`
          justify-content: flex-start;
        `}
  border: 1px ${({ border }) => border || 'dashed'} ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
`;

export const StyledDocumentPDFHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 68px;
  padding: 0px 16px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
`;

export const StyledDocumentPDFTitle = styled.h4`
  color: ${({ theme }) => theme.colors.neutral.onBackground};

  ${({ theme }) => theme.typography.title4};
`;

export const StyledDocumentPDFObject = styled.object`
  align-self: stretch;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1;
`;

export const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const StyledProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
`;

export const StyledProgressLabel = styled.span`
  ${({ theme }) => theme.typography.caption};
`;

export const StyledHeader = styled.span`
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${({ theme }) => theme.typography.title3};
`;

export const StyledSubHeader = styled.span`
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${({ theme }) => theme.typography.body3};
`;
