import React from 'react';

import { BadgeLoadingProps, BadgeProps } from './types';
import { Container, Icon, Text } from '../../atoms';
import { IconVariant } from '../../atoms/Icon/types';
import { useLabelState } from './hooks';
import { TextVariant } from '../../atoms/Text/types';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

const _BadgeLabel = ({ text, badgeVariant, className }: BadgeProps): JSX.Element => {
  const { icon, backgroundColor } = useLabelState(badgeVariant);
  const isTextRequired = badgeVariant !== 'badge-dot';

  return (
    <Container variant={badgeVariant || 'badge-text'} backgroundColor={backgroundColor} className={className}>
      {icon && <Icon variant={badgeVariant as IconVariant} icon={icon} />}
      {text && isTextRequired && <Text variant={(badgeVariant || 'badge-text') as TextVariant} content={text} />}
    </Container>
  );
};

const LoadingState = ({ badgeVariant, overrideWidth }: BadgeLoadingProps): JSX.Element => {
  const { skeletonWidth, skeletonHeight } = useLabelState(badgeVariant);

  return (
    <Container variant={badgeVariant || 'badge-text'}>
      <Skeleton height={skeletonHeight} width={overrideWidth ?? skeletonWidth} />
    </Container>
  );
};

const BadgeLabel = makeNamespacedComponent(_BadgeLabel, {
  LoadingState,
});

export { BadgeLabel };
