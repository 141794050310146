import React, { ChangeEvent } from 'react';

import { Icon } from '@/components/Icon';
import { CheckboxInput, CheckboxLabel, CheckboxSizeProp } from './CheckboxInput';
import {
  InfoToolTip,
  InfoToolTipDescription,
  InfoToolTipIcon,
  InfoToolTipLabel,
  InfoToolTipContent
} from '../Tooltip/InfoToolTip';

interface Props {
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent) => void;
  label?: string | JSX.Element;
  dark?: boolean;
  marginless?: boolean;
  bulkAll?: boolean;
  disabled?: boolean;
  newTheme?: boolean;
  className?: string;
  size?: CheckboxSizeProp;
  useInfoToolTip?: boolean;
  infoToolTipLabel?: string;
  infoToolTipDescription?: string;
}

const Checkbox = ({
  id,
  checked,
  onChange,
  label,
  dark,
  marginless,
  bulkAll,
  disabled,
  newTheme,
  className,
  size = 'default',
  useInfoToolTip,
  infoToolTipLabel,
  infoToolTipDescription
}: Props): JSX.Element => (
  <div className={className}>
    <CheckboxInput id={id} type="checkbox" checked={checked} onChange={onChange} bulkAll={bulkAll} disabled={disabled} />
    <CheckboxLabel dark={!!dark} marginless={!!marginless} htmlFor={id} size={size} newTheme={newTheme}>
      <Icon icon="check" />
      <span>
        {label}
        {!!useInfoToolTip && (
          <InfoToolTip aria-describedby="help-info">
            <InfoToolTipIcon></InfoToolTipIcon>
            {(!!infoToolTipLabel || !!infoToolTipDescription) && (
              <InfoToolTipContent id="help-info-text">
                {!!infoToolTipLabel && <InfoToolTipLabel>{infoToolTipLabel} </InfoToolTipLabel>}
                {!!infoToolTipDescription && <InfoToolTipDescription>{infoToolTipDescription}</InfoToolTipDescription>}
              </InfoToolTipContent>
            )}
          </InfoToolTip>
        )}
      </span>
    </CheckboxLabel>
  </div>
);

export { Checkbox };
