import styled from 'styled-components';

export const RootStyled = styled.div`
  min-height: 100%;
  padding: 0px 28px;
  overflow-y: scroll;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
`;

export const TextEditorStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    align-items: center;
  }
`;
