import styled from 'styled-components';

import { variant } from './sizing';

export const Overline = styled.span`
  ${p => p.theme.typography.overline};
  color: ${p => p.theme.colors.neutral.outline2};

  ${variant.small} {
    margin: 0 24px;
  }

  ${variant.large} {
    margin: 0 16px;
  }
`;
