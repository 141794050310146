import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';

import { AssignmentV3QueryResult, UseAssignmentHook } from './types';
import { assigmentTypes, assignmentBase, learnings, users } from './queryContants';

export const ASSIGNMENT_QUERY = gql`
query assignmentV3( $id: Int!, $userTeamIdIN: [Int!], $pagination: Pagination, $searchPhrase: String, $includeUserTeams: Boolean!, $includeUsers: Boolean!, $includeLearnings: Boolean!) {
  assignmentV3(id: $id) {
    ${assignmentBase}
    ${learnings}
    ${users}
    ${assigmentTypes}
  }
}`;

export const useAssignment: UseAssignmentHook = ({ id, teamsIds, includeUsers, includeLearnings }) => {
  const { data, loading, error } = useQuery<AssignmentV3QueryResult>(ASSIGNMENT_QUERY, {
    variables: {
      id: id,
      userTeamIdIN: teamsIds ?? null,
      searchPhrase: null,
      pagination: {
        limit: -1,
        page: 1,
      },
      includeUserTeams: !!teamsIds,
      includeUsers: !!includeUsers,
      includeLearnings: !!includeLearnings,
    },
  });

  return {
    data: data?.assignmentV3,
    loading,
    error,
  };
};
