import { useMediaState } from '@udecode/plate-media/react';
import { PlateElementProps } from '@udecode/plate-common/react';

import { QuizQuestionEditElement } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuizQuestionEditElement';
import { QuizQuestionViewElement } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/QuizQuestionViewElement';

export const QuizQuestionElement = (props: PlateElementProps): JSX.Element => {
  const { readOnly } = useMediaState();

  return readOnly ? <QuizQuestionViewElement {...props} /> : <QuizQuestionEditElement {...props} />;
};
