import React, { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface ElementSize {
  width: number;
  height: number;
}

/**
 * Found how to do this: https://stackoverflow.com/questions/68175873/detect-element-reference-height-change
 * Using useEffect
    You can create a ResizeObserver inside a useEffect-hook, and then do what you want to do when the element changes its size. Remember to disconnect from the resizeObserver in the cleanup function.

    useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect(); // clean up 
    }, []);
 * @param elementRef 
 * @returns Element Size
 */

export const useSize = (elementRef: React.RefObject<HTMLElement>): ElementSize => {
  const [elementSize, setElementSize] = useState<ElementSize>({ width: 0, height: 0 });

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setElementSize({ width, height });
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [elementRef]);

  return elementSize;
};
