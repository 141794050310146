import React from 'react';

import './audio.scss';
import { Icon } from '@/components/Icon';
import { AudioMeta } from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { AudioPlayer } from './AudioPlayer';
import { Media, MediaBody, MediaFigure } from '@/components/MediaObject/MediaObject';

interface Props {
  data: AudioMeta;
}

export const AudioBlock = ({ data: { description, url, imageUrl } }: Props): JSX.Element => {
  return (
    <div className="content is-article is-unpadded">
      <Media bordered unpadded>
        <MediaFigure>
          {imageUrl ? (
            <div styleName="image" className="image is-background is-1by1">
              <div className="image-background" style={{ backgroundImage: `url(${imageUrl})` }} />
            </div>
          ) : (
            <div styleName="image icon">
              <Icon icon={'file-audio'} size="4x" />
            </div>
          )}
        </MediaFigure>
        <MediaBody>
          <AudioPlayer url={url} description={description} />
        </MediaBody>
      </Media>
    </div>
  );
};
