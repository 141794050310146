import { createPlatePlugin } from '@udecode/plate-common/react';

export const ELEMENT_QUIZ_QUESTION = 'quizQuestion';

export const QuizQuestionPlugin = createPlatePlugin({
  key: ELEMENT_QUIZ_QUESTION,
  node: {
    isElement: true,
    isVoid: true,
    type: ELEMENT_QUIZ_QUESTION,
  },
});
