import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCurrentUser } from '@/store/currentUser';
import { trackLearningTimeClicked, trackOpenUserSelfTrackedLearningModalV2 } from '@/utils/tracking/homePage';
import { CourseCardPlaceholderV2 } from '@/components/CourseCardLegacy/v2/CourseCardPlaceholder';
import { useEffort } from '@/component/start/hooks/useEffort';
import { useUserSelfTrackedLearningLogic } from '@/component/UserSelfTrackedLearning/hooks/useUserSelfTrackedLearningLogic';
import { useModalContext } from '@/components/Modal/ModalContext';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { WelcomeCardContainerV2 } from './components/WelcomeCardContainer';
import { Link } from '@/components/Link';
import { Lng } from '@/i18n/resources';
import { getChallengeDate, getChallengesDatesSplit } from './utils/challengeDate';
import { ButtonList } from '@/components/Button/v2/ButtonList';
import { Button, IconButton } from '@/ui/Button';
import { HomeTextKeys } from './types';
import { getHomeTextTranslateKey } from './utils/getHomeTextTranslateKey';
import {
  ChallengeContainer,
  ChallengeDate,
  StyledBadge,
  StyledProgressBar,
  MyLearningGoal,
  ChallengeTitle,
  MyLearningTime,
  MyLearningTimeBody,
  WelcomeTooltip,
} from './styles';
import { CURRENT_YEAR } from '@/utils/currentYear';

const translationKeys: HomeTextKeys = {
  top: 'home-text-top',
  mid: 'home-text-mid',
  low: 'home-text-low',
};

export const Welcome = (): React.ReactElement => {
  const { showModal } = useModalContext();
  const { user, loading: loadingUser } = useCurrentUser();
  const { openUserSelfTrackedLearningForm } = useUserSelfTrackedLearningLogic();
  const trackLearningTime = useCallback(() => trackLearningTimeClicked(), []);
  const { t, i18n } = useTranslation('home');

  const isChallengeOn = useFeatureEnabled(FEATURE.UI_LEARNING_CHALLENGE);
  const currentYear = useMemo(() => CURRENT_YEAR, []);
  const challengeDate = getChallengeDate(currentYear);
  const {
    progressTotal,
    top,
    loading: loadingEffort,
    companyAverageHours,
    nextLevel,
  } = useEffort({ challengeDate: challengeDate, userId: user?.id });
  const handleInfoModal = () =>
    showModal({
      size: 'large',
      children: (
        <p>
          <Trans ns="home" i18nKey="my-invested-time-description">
            <i>My invested time</i> takes the following sources into consideration:
          </Trans>
          <br />
          <br />
          <Trans ns="home" i18nKey={'my-invested-time-disclaimer'}>
            Due to technical dependencies on external providers, <i>My invested time</i> number can be delayed up to 72 hours.
          </Trans>
        </p>
      ),
    });

  if (loadingUser || loadingEffort) {
    return (
      <WelcomeCardContainerV2>
        <CourseCardPlaceholderV2 />
      </WelcomeCardContainerV2>
    );
  }

  const progressPercent = isChallengeOn ? progressTotal.percent : 0;
  const language = i18n.language as Lng;

  return (
    <WelcomeCardContainerV2>
      <MyLearningGoal>{isChallengeOn ? t('my-learning-goal') : t('my-learning-progress')}</MyLearningGoal>
      <div>
        <ChallengeContainer>
          <ChallengeTitle>{t('current-challenge')}</ChallengeTitle>
          <StyledBadge badgeVariant="badge-text" text={nextLevel} />
        </ChallengeContainer>
        <ChallengeDate>{t('current-year-challenge', getChallengesDatesSplit(challengeDate, language))}</ChallengeDate>
        <StyledProgressBar maxValue={100} value={progressPercent} animated showLabel labelFormat="percentage" />
      </div>

      <WelcomeTooltip carrotVisible={isChallengeOn}>
        <MyLearningTime>{t('my-invested-time')}</MyLearningTime>
        <IconButton
          icon="info"
          buttonVariant="minimal"
          aria-label={t('Info')}
          aria-haspopup="true"
          onClick={handleInfoModal}
          gridArea="info"
        />
        <Link $gridArea="my-learning" to="/user/my-learning/completed" onClick={trackLearningTime} aria-label="myLearning">
          {progressTotal.minutes}
        </Link>
        <MyLearningTimeBody>
          {getHomeTextTranslateKey(top) === translationKeys.top && (
            <Trans
              t={t}
              i18nKey="home-text-top"
              values={{ companyHours: companyAverageHours, userTime: progressTotal.minutes, percentageTop: top }}
              components={[<Link key={translationKeys.top} to="/user/my-learning/completed" onClick={trackLearningTime} />]}
            />
          )}
          {getHomeTextTranslateKey(top) === translationKeys.mid && (
            <Trans
              t={t}
              i18nKey="home-text-mid"
              values={{ companyHours: companyAverageHours, userTime: progressTotal.minutes, percentageTop: top }}
              components={[<Link key={translationKeys.mid} to="/user/my-learning/completed" onClick={trackLearningTime} />]}
            />
          )}
          {getHomeTextTranslateKey(top) === translationKeys.low && (
            <Trans
              t={t}
              i18nKey="home-text-low"
              values={{ companyHours: companyAverageHours, userTime: progressTotal.minutes, percentageTop: top }}
              components={[<Link key={translationKeys.low} to="/user/my-learning/completed" onClick={trackLearningTime} />]}
            />
          )}
        </MyLearningTimeBody>
      </WelcomeTooltip>
      <ButtonList align="right">
        <Button
          icon="plus"
          $iconPosition="start"
          variant="ghost"
          size="large"
          onClick={() => {
            trackOpenUserSelfTrackedLearningModalV2();
            openUserSelfTrackedLearningForm();
          }}
        >
          {t('track-time')}
        </Button>
      </ButtonList>
    </WelcomeCardContainerV2>
  );
};
