import { useCallback, useContext, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';

import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { useExtraTermsLink } from './useExtraTermsLink';
import { OnboardingStep, StepState } from './types';
import { isRealm } from '@/utils/realms';

const ALL_STEPS = [
  OnboardingStep.Hello,
  OnboardingStep.LFTerms,
  OnboardingStep.Terms,
  OnboardingStep.ExtraTerms,
  OnboardingStep.Profile,
  OnboardingStep.FinalStep,
];

type UseOnboardingHook = () => {
  stepState: StepState[];
  currentStep: OnboardingStep;
  markStepReady: (stepToComplete: OnboardingStep, ready?: boolean) => void;
  goToNextStep: () => void;
  setCurrentStep: (step: OnboardingStep) => void;
  isNextStepEnabled: boolean;
  storeSaveFn: (step: OnboardingStep, saveFn: () => void | Promise<unknown>) => void;
  requestNextStep: () => void;
  committingCurrentStep: boolean;
};

export const useOnboarding: UseOnboardingHook = () => {
  const { t } = useTranslation('onboarding');
  const { openSnackbar } = useContext(SharedSnackbarContext);

  const requireExtraTerms = !!useExtraTermsLink();
  const resolveNextStep = stepResolver(requireExtraTerms);

  const [currentStep, setCurrentStep] = useState(OnboardingStep.Hello);

  const [stepState, setStepState] = useState<StepState[]>(
    ALL_STEPS.filter((step) => {
      if (!isRealm('lansforsakringar') && step === OnboardingStep.LFTerms) return false;
      if (!requireExtraTerms && step === OnboardingStep.ExtraTerms) return false;

      return true;
    }).map((step) => ({
      step,
      ready: step === OnboardingStep.Hello,
      enabled: step === OnboardingStep.Hello,
    }))
  );

  const isNextStepEnabled = useMemo(() => {
    const nextStep = resolveNextStep(currentStep);
    return stepState.find(({ step }) => step === nextStep)?.enabled ?? false;
  }, [currentStep, stepState, requireExtraTerms]);

  const goToNextStep = useCallback(() => setCurrentStep(resolveNextStep), [requireExtraTerms]);

  const markStepReady = useCallback(
    (stepToComplete: OnboardingStep, toggleReady = true) => {
      setStepState((prevState) => {
        const nextState = prevState
          // Mark specified step as "ready", leave others as-is:
          .map(({ step, enabled, ready, ...rest }) => ({
            ...rest,
            step,
            enabled,
            ready: step === stepToComplete ? toggleReady : ready,
            isCompleting: false,
          }))
          // Mark any step as "enabled" as long as all preceding steps are completed:
          .map((step, index, allSteps) => ({
            ...step,
            enabled: allSteps.slice(0, index).every((s) => s.ready),
          }));
        return isEqual(prevState, nextState) ? prevState : nextState;
      });
    },
    [requireExtraTerms]
  );

  const [stepSaveFns, setStepSaveFns] = useState<Partial<Record<OnboardingStep, () => void | Promise<unknown>>>>({});
  const storeSaveFn = useCallback(
    (step: OnboardingStep, saveFn: () => void | Promise<unknown>) => {
      if (stepSaveFns[step] !== saveFn) {
        setStepSaveFns({ ...stepSaveFns, [step]: saveFn });
      }
    },
    [stepSaveFns]
  );

  const [committingCurrentStep, setCommittingCurrentStep] = useState(false);

  const requestNextStep = useCallback(async () => {
    const saveFn = stepSaveFns[currentStep] ?? (() => Promise.resolve());
    try {
      setCommittingCurrentStep(true);
      await saveFn();
      markStepReady(currentStep);
      goToNextStep();
    } catch (error) {
      openSnackbar({
        message: error instanceof Error ? error.message : typeof error === 'string' ? error : t('Something went wrong'),
        type: SnackbarType.DANGER,
      });
    } finally {
      setCommittingCurrentStep(false);
    }
  }, [currentStep, stepSaveFns, markStepReady, goToNextStep, openSnackbar]);

  return useMemo(
    () => ({
      stepState,
      currentStep,
      markStepReady,
      goToNextStep,
      setCurrentStep,
      isNextStepEnabled,
      storeSaveFn,
      requestNextStep,
      committingCurrentStep,
    }),
    [stepState, currentStep, markStepReady, goToNextStep, setCurrentStep, isNextStepEnabled, storeSaveFn, committingCurrentStep]
  );
};

const stepResolver = (requireExtraTerms: boolean) => (currentStep: OnboardingStep) => {
  switch (currentStep) {
    case OnboardingStep.Hello:
      return isRealm('lansforsakringar') ? OnboardingStep.LFTerms : OnboardingStep.Terms;
    case OnboardingStep.LFTerms:
      return OnboardingStep.Terms;
    case OnboardingStep.Terms:
      return requireExtraTerms ? OnboardingStep.ExtraTerms : OnboardingStep.Profile;
    case OnboardingStep.ExtraTerms:
      return OnboardingStep.Profile;
    case OnboardingStep.Profile:
      return OnboardingStep.FinalStep;
    default:
      return OnboardingStep.Hello;
  }
};
