import { Children, ReactNode, cloneElement, isValidElement, useContext } from 'react';
import styled from 'styled-components';

import { useUnderlinedTabs } from '../../hooks/useUnderlinedTabs';
import { Underline } from './Underline';
import { Context } from './Context';

const TabsListStyled = styled.nav`
  display: flex;
  overflow: hidden;
`;

type TabRegisterFunction = (tabIndex: number) => {
  isActive: boolean;
  onClick: () => void;
  ref: (el: HTMLButtonElement | null) => void;
};

export const TriggersList = ({ children }: { children: ReactNode | ReactNode[] }): JSX.Element => {
  const { activeTab, setActiveTab } = useContext(Context);
  const { allTabsElements, activeTabLeft, activeTabWidth, listElement } = useUnderlinedTabs({ activeTab });

  const arrayChildren = Children.toArray(children);

  const registerTab: TabRegisterFunction = (tabIndex) => {
    return {
      onClick: () => setActiveTab(tabIndex),
      isActive: activeTab === tabIndex,
      ref: (el: HTMLButtonElement | null) => (allTabsElements.current[tabIndex] = el),
    };
  };

  return (
    <div>
      <TabsListStyled ref={listElement}>
        {Children.map(arrayChildren, (child) => {
          return <>{isValidElement(child) ? cloneElement(child, { ...registerTab(child.props.value) }) : null}</>;
        })}
      </TabsListStyled>
      <Underline activeTabWidth={activeTabWidth} activeTabLeft={activeTabLeft} />
    </div>
  );
};
