import { Columns } from '../types/columns';

export const columns: Columns = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  large: 12,
  medium: 8,
  small: 4,
};
