import React from 'react';
import styled from 'styled-components';

const SerializableRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 32px 0;
  gap: 8px;
`;

const SerializableImage = styled.img<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '686px')};
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

/** Styled theme vars don't get serialized via renderToStaticMarkup */
const SerializableCaption = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #141d1e;
`;

export const Serializable = ({ url, caption, width }: { url?: string; caption?: string; width?: number }): JSX.Element | null => {
  if (!url) return null;

  return (
    <SerializableRoot>
      <SerializableImage src={url} width={width} />
      {caption && <SerializableCaption>{caption}</SerializableCaption>}
    </SerializableRoot>
  );
};
