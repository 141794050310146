/* eslint-disable import/no-unused-modules */
import styled from 'styled-components';

import image from './image.svg';

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

export const CountdownTitleStyled = styled.div`
  ${({ theme }) => theme.typography.headline};

  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const UnitContainerStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const UnitValueStyled = styled.div`
  ${({ theme }) => theme.typography.title1};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const UnitLabelStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

export const CountdownStyled = styled.div`
  display: flex;
  gap: 12px;
`;

export const SeparatorStyled = styled.div.attrs({ children: ':' })`
  ${({ theme }) => theme.typography.title1};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const Graphics = styled.img.attrs({ src: image })<{ hidden?: boolean }>`
  padding-top: 10px;
`;
