import React from 'react';
import { useFormContext } from 'react-hook-form';

import { escapeRegex } from '@/utils/text';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Overline } from '@/components/Typography/Typography';
import { MultiSelectOption } from '../types';
import { StyledMultiSelectRow } from '../styles';
import { StyledIcon } from '../../Input/styles';
import { HandleNumber, HandleString } from '@/components/Form/types';
import { Icon } from '@/components/Icon';

interface MultiSelectRowProps {
  name: string;
  localOptions: MultiSelectOption[];
  valueAsNumber?: boolean;
  isMultiple?: boolean;
  limit?: number;
  searchString?: string;
  handleSideEffect?: HandleString | HandleNumber;
  onSingleSelect?: () => void;
}

export const MultiSelectRows = ({
  name,
  localOptions,
  limit,
  searchString = '',
  valueAsNumber,
  isMultiple,
  handleSideEffect,
  onSingleSelect
}: MultiSelectRowProps): JSX.Element | null => {
  const { watch, setValue, reset, getValues } = useFormContext();
  const watched = watch(name);
  const limitReached = isMultiple && limit && watched && watched.filter(Boolean).length >= limit;
  const toRender: MultiSelectOption[] = [];
  localOptions
    .filter(o => o.isHeader || o.label.toLowerCase().includes(searchString.toLowerCase()))
    .filter(o => (limitReached ? o.isHeader || watched?.some((s: string | number) => s == o.value) : true))
    .forEach((o, i, src) => {
      if (o.isHeader) {
        if (toRender[toRender.length - 1]?.isHeader) toRender.pop();
        if (i === src.length - 1) return;
      }

      toRender.push(o);
    });

  const regex = new RegExp(escapeRegex(searchString), 'i');
  const getRegexedLabel = (label: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: label.replace(regex, `<strong>$&</strong>`)
      }}
    />
  );

  const handleSingleRowClick = (value: string | number) => {
    if (handleSideEffect && value && watched !== value) {
      valueAsNumber ? (handleSideEffect as HandleNumber)(value as number) : (handleSideEffect as HandleString)(value as string);
    }
    if (watched === value) {
      reset({ ...getValues(), [name]: undefined });
    } else {
      setValue(name, value, {
        shouldDirty: true,
        shouldValidate: true
      });
    }
    onSingleSelect?.();
  };

  return toRender.length ? (
    <>
      {toRender.map(({ isHeader, isSubHeader, value, label }, i) =>
        isHeader || isSubHeader ? (
          <Overline key={i} subLevel={isSubHeader} data-testid="option" aria-label={label}>
            {label}
          </Overline>
        ) : isMultiple ? (
          <Checkbox
            key={i}
            data-testid="option"
            name={`${name}.${i}`}
            value={value}
            valueAsNumber={valueAsNumber}
            fullwidth
            handleSideEffect={handleSideEffect}
          >
            {getRegexedLabel(label)}
          </Checkbox>
        ) : (
          <StyledMultiSelectRow
            key={i}
            as="div"
            data-testid="option"
            aria-label={label}
            isSelected={watched === value}
            onClick={() => handleSingleRowClick(value)}
          >
            {getRegexedLabel(label)}
            {watched === value ? (
              <StyledIcon>
                <Icon icon="check" fixedWidth size="lg" />
              </StyledIcon>
            ) : null}
          </StyledMultiSelectRow>
        )
      )}
    </>
  ) : null;
};
