import styled from 'styled-components';

import { Button } from '@/ui/Button';

const ButtonStyled = styled(Button)`
  width: 120px;
  background: transparent;
  margin-top: 24px;
`;

type AddAnswerButton = {
  onClick: VoidFunction;
};

export function AddAnswerButton({ onClick }: AddAnswerButton): JSX.Element {
  return (
    <ButtonStyled variant="ghost" onClick={onClick}>
      + Add option
    </ButtonStyled>
  );
}
