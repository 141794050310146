import { useState } from 'react';

/**
 * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
 *
 * WHY WE DO THAT?
 *
 * Currenlty Plate.js overlaps general editor placeholder with custom placeholders.
 * It doesn't detect correctly, when editor general placeholder should be hidden.
 *
 * @link https://github.com/udecode/plate/issues/3730
 *
 * So, we need to make that check on our side for now.
 *
 * Basically, we check if there are any custom placeholders enabled & visible.
 * If yes, we mark editor placeholder "canShow" flag to false.
 *
 * We do that by tracking ids of the elements which have enabled placeholders.
 */
export const useCustomPlaceholdersState = (): {
  canShowEditorPlaceholder: boolean;
  activePlaceholdersElementsIds: string[];
  setElementPlaceholderActive: (ops: { id: string; enabled: boolean }) => void;
} => {
  const [activePlaceholdersElementsIds, setActivePlaceholdersElementsIds] = useState<string[]>([]);

  const handleSetElementPlaceholderActive = ({ id, enabled }: { id: string; enabled: boolean }): void => {
    setActivePlaceholdersElementsIds((value) => {
      let newValue = [];
      if (!enabled) {
        newValue = value.filter((i) => i !== id);
      } else {
        newValue = [...value, id];
      }

      return [...new Set(newValue)];
    });
  };

  return {
    canShowEditorPlaceholder: !activePlaceholdersElementsIds.length,
    activePlaceholdersElementsIds,
    setElementPlaceholderActive: handleSetElementPlaceholderActive,
  };
};
