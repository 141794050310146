import React from 'react';

import { ChipVariantProps } from '../../types';
import { CHECKMARK_ICON, CLOSE_ICON, DROPDOWN_ICON_CLOSED, DROPDOWN_ICON_OPEN } from '../../constants';
import { Chip } from '../../atoms/Chip';

interface FilterChipProps extends ChipVariantProps {
  /**
  Should the chip have elevation styling
  */
  elevated?: boolean;
  /**
  Should the chip dropdown icon be open
  */
  isDropdownOpen?: boolean;
}

export const FilterChip = ({
  label,
  elevated,
  isDropdownOpen,
  selected,
  disabled,
  onSelectedChange,
  className,
  onTrailingIconClick,
  variant,
  onClick,
}: FilterChipProps): JSX.Element => {
  let dropdownIcon = undefined;

  if (isDropdownOpen !== undefined) {
    const icon = isDropdownOpen ? DROPDOWN_ICON_OPEN : DROPDOWN_ICON_CLOSED;
    dropdownIcon = !!selected && variant === 'dropdown' ? CLOSE_ICON : icon;
  }

  return (
    <Chip
      selected={selected}
      className={className}
      disabled={disabled}
      onSelectedChange={onSelectedChange}
      leadingIcon={selected ? CHECKMARK_ICON : undefined}
      trailingIcon={dropdownIcon}
      elevated={elevated}
      onTrailingIconClick={onTrailingIconClick}
      onClick={onClick}
      variant={variant}
    >
      <>{label}</>
    </Chip>
  );
};
