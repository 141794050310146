/* eslint-disable import/no-unused-modules */
import React from 'react';
import { useTheme } from 'styled-components';

import { CircularProgressBar } from '@/ui/ProgressBar';
import { RootStyled, ChartContainer, TitleStyled, ReflectionMarker, ProgressLabel } from './styles';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';

export type TimeState = 'past' | 'current' | 'future';

interface WeekTabProps {
  weekNumber: number;
  recommendedEffort: number;
  currentEffort: number;
  canReflect?: boolean;
  selected?: boolean;
  timeState: TimeState;
  onClick: () => void;
  showProgressLabel?: boolean;
}

export const WeekTab = ({
  weekNumber,
  timeState,
  currentEffort,
  recommendedEffort,
  selected,
  canReflect,
  onClick,
  showProgressLabel,
}: WeekTabProps): JSX.Element => {
  const disabled = timeState === 'future';
  const theme = useTheme();

  return (
    <RootStyled selected={selected} disabled={disabled} onClick={!disabled ? onClick : () => {}}>
      <TitleStyled state={timeState} selected={selected}>
        {weekNumber}
      </TitleStyled>
      <ChartContainer>
        {canReflect && <ReflectionMarker badgeVariant="badge-dot" />}
        <CircularProgressBar
          size={32}
          strokeWidth={3}
          rounded
          value={currentEffort}
          maxValue={recommendedEffort}
          gap={4}
          customTrackBgColor={theme.colors.states.hover8}
        />
      </ChartContainer>
      {!!showProgressLabel && <ProgressLabel>{formatToHoursAndMinutesFromSeconds(currentEffort)}</ProgressLabel>}
    </RootStyled>
  );
};
