import React, { memo, ReactElement } from 'react';

import { Image, ImageWrapper, LogoWrapper } from '@/pages/learning/CourseDetails/components/styles';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { Provider } from '@/store/providers';

type Props = {
  image?: string | null;
  provider?: string | null;
};
export const ThumbnailImage = ({ image, provider }: Props): ReactElement => {
  return (
    <ImageWrapper>
      <ImageMemo image={image} provider={provider} />
    </ImageWrapper>
  );
};

const ImageMemo = memo(Img);

function Img({ image, provider }: Partial<Props>) {
  return (
    <Image image={image}>
      <LogoWrapper>
        <ProviderLogo provider={provider as Provider} />
      </LogoWrapper>
    </Image>
  );
}
