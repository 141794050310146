import React from 'react';

import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { TitleStyled, DescriptionStyled, BannerStyled, Graphics, RootStyled } from './styles';

type SuccessfulStateProps = {
  effort?: number;
  customText?: string;
};

export const SuccessfulState = ({ effort, customText }: SuccessfulStateProps): JSX.Element => {
  return (
    <RootStyled>
      <Graphics />
      <BannerStyled>
        <TitleStyled>Nice work!</TitleStyled>
        <DescriptionStyled>
          {!!effort && `You’ve just added ${formatToHoursAndMinutesFromSeconds(effort)} to your learning loop`}
          {!!customText && customText}
        </DescriptionStyled>
      </BannerStyled>
    </RootStyled>
  );
};
