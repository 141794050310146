import urlRegex from 'url-regex-safe';

import { localDomains } from './constants';

export function replaceNbsps(str: string): string {
  return str.replace(/\u00a0/g, ' ');
}

export function validateUrl(url: string | undefined): boolean {
  return url ? urlRegex({ exact: true }).test(url) : false;
}

export function createTextLinks(text = ''): string {
  return text.replace(urlRegex(), function (match) {
    let hyperlink = match;
    const isLocal = localDomains.some((domain) => match.includes(domain));
    if (!hyperlink.match('^https?://')) hyperlink = 'http://' + hyperlink;
    return `<a href="${hyperlink}"${!isLocal ? ' target="_blank"' : ''}>${match}</a>`;
  });
}

export const escapeRegex = (string: string): string => string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

/** Replaces the final whitespace with a non-breaking space to prevent single words handging in the final line of text. */
export const preventOrphans = (text: string): string => text.trim().replace(/^(.+)\s(\S*)$/, '$1\u00a0$2');

export const shortenName = (name: string): string =>
  name
    .split(' ')
    .map((slice) => (slice.length ? String(slice[0]).toUpperCase() : ''))
    .join('')
    .slice(0, 2);
