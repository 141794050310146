import { PROGRESS_CATALOG_OPTIONS, useCardCatalog } from '@/store/catalog';
import { LearningLoop } from '@/store/learningLoops/types';
import { useSelfTrackedLearnings } from '@/store/selft-tracked-learnings/self-tracked-learnings';
import { selfTrackIdFromObjectId } from '@/utils/analyticObjects';
import { getLearningProgressDetails } from './utils/getLearningProgressDetails';
import { SelfTrackLearningDetails, UseLearningProgressHookResult } from './types';

export const useLearningProgress = ({ loops }: { loops: LearningLoop[] }): UseLearningProgressHookResult => {
  const selfTrackIds = loops
    .flatMap((m) => m.activitiesAggregated)
    .map(
      (x): SelfTrackLearningDetails => ({
        catalogObjectId: x.participation.catalogObjectId,
        id: selfTrackIdFromObjectId(x.participation.catalogObjectId),
      })
    )
    .filter((m) => m.id > 0);
  const { catalog, loading: catalogLoading, getCardByObjectId, error: catalogError } = useCardCatalog(PROGRESS_CATALOG_OPTIONS);
  const {
    data: selfTrackedLearnings,
    loading: selfTrackLoading,
    error: selfTrackErrors,
    refetch: refetchSelfTrackLearnings,
    networkStatus,
  } = useSelfTrackedLearnings({ ids: selfTrackIds.map((x) => x.id) });
  const loading = catalogLoading || selfTrackLoading;
  const error = catalogError || selfTrackErrors;

  const learningProgessDetails = getLearningProgressDetails({
    loops,
    getCard: getCardByObjectId,
    cards: catalog.cards,
    selfTrackIds,
    selfTrackedLearnings,
  });

  return {
    data: learningProgessDetails,
    error,
    loading,
    networkStatus,
    refetch: refetchSelfTrackLearnings,
  };
};
