import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { TextArea } from '@/ui/TextField';
import { TextFieldName } from '../../../constants';

export const LearningTitle = ({ control }: { control: Control<FieldValues> }): JSX.Element => (
  <Controller
    control={control}
    name={TextFieldName.LearningTitle}
    rules={{ required: true }}
    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
      <TextArea
        onChange={onChange}
        name={name}
        value={value}
        fullWidth
        label="Title"
        rows={3}
        error={
          error && {
            message: 'Title required',
          }
        }
      />
    )}
  />
);
