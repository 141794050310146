import styled, { css, keyframes } from 'styled-components';

import { CircleContainerProps, InnerCircleProps } from './types';

const AnimateRotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const getStrokeAnimation = ({ arcLenght }: { arcLenght: number }) => keyframes`
   0% {
        stroke-dasharray: ${arcLenght * 0.1}, ${arcLenght};
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray:  ${arcLenght};
        stroke-dashoffset: ${arcLenght * 0.1 * -1};
    }
    100% {
        stroke-dasharray: ${arcLenght};
        stroke-dashoffset: ${arcLenght * -1};
    }
`;

export const RootStyled = styled.div`
  position: relative;
  width: fit-content;
`;

export const CircleContainerStyled = styled.svg<CircleContainerProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  animation: ${AnimateRotate} 2s linear infinite;
`;

export const CircleProgressStyled = styled.circle<InnerCircleProps>`
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px`};
  cx: ${({ center }) => `${center}px`};
  cy: ${({ center }) => `${center}px`};
  r: ${({ radius }) => `${radius}px`};
  fill: transparent;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
  transition-delay: 200ms;
  stroke: ${({ theme }) => theme.colors.primary.outline};
  ${({ arcLenght }) => css`
    animation: ${getStrokeAnimation({ arcLenght })} 1.5s linear infinite;
  `};
`;
