import styled from 'styled-components';

import { Tag } from '@/components/Tag/Tag';

export const Addons = styled.div`
  white-space: nowrap;

  ${Tag}:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  ${Tag}:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }
`;
