import React from 'react';

import { toHtml } from '@/component/customEditor/logic/serialization';
import { Page } from './components/Page';
import { StandardAssignmentDetails, StandardAssignmentProps } from './types';

export const StandardAssignment = ({ assignment }: StandardAssignmentProps): JSX.Element => {
  const description = assignment?.description ? toHtml(assignment.description) : undefined;
  const requiredCompletions = {
    current: assignment.requiredItemsCompletedCount ?? 0,
    total: assignment.requiredItemsCount ?? 0,
  };
  const processedCompletionRate = Math.round((requiredCompletions.current / requiredCompletions.total || 0) * 100);

  const progressData = {
    completionRate: processedCompletionRate,
    requiredCompletions: requiredCompletions,
    completed: assignment.progressStatus === 'completed',
  };

  const standardAssignment: StandardAssignmentDetails = {
    ...assignment,
    type: assignment?.type,
    description,
    learnings: assignment?.learnings?.data,
    ...progressData,
  };

  return <Page assignment={standardAssignment} />;
};
