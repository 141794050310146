import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import { ById, ByName, Category } from '../types/categories';
import { HookResult } from './apolloClient';

const getCategoriesQuery = gql`
  query getCategories {
    getCategories {
      id
      name
      urlKey
      subjects {
        id
        name
        urlKey
      }
    }
  }
`;

interface CategoriesResponse extends HookResult {
  categories: ReadonlyArray<Category>;
  categoriesById: ById;
  categoriesByName: ByName;
  subjectsById: ById;
  refetch: () => Promise<ApolloQueryResult<{ getCategories: Category[] }>>;
}

export const useCategories = (): CategoriesResponse => {
  const { data, error, refetch, loading, networkStatus } = useQuery<{ getCategories: Category[] }>(getCategoriesQuery);

  const categories: ReadonlyArray<Category> = (data && data.getCategories) || [];

  const categoriesById = categories.reduce<ById>(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.id]: {
        name: currentValue.name,
        urlKey: currentValue.urlKey
      }
    }),
    {}
  );

  const categoriesByName = categories.reduce<ByName>(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.name]: {
        name: currentValue.name,
        id: currentValue.id,
        urlKey: currentValue.urlKey
      }
    }),
    {}
  );

  const subjectsById = categories.reduce<ById>((accumulator, currentValue) => {
    currentValue.subjects.forEach(subject => {
      accumulator[subject.id] = {
        name: subject.name,
        urlKey: subject.urlKey
      };
    });

    return accumulator;
  }, {});

  return {
    categories,
    categoriesById,
    categoriesByName,
    subjectsById,
    error,
    refetch,
    loading,
    networkStatus
  };
};
