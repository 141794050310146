import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomEditor } from '../customEditor/CustomEditor';
import { Icon } from '@/components/Icon';
import { AutoComplete } from '../../components/Form/types';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';

export interface UpdateSectionPayload {
  path: string[];
  newPath?: string;
  title?: string;
  description?: string;
}

interface Props extends UpdateSectionPayload {
  onSave({ title, description }: UpdateSectionPayload): Promise<void> | void;
  onFinished?(): Promise<void> | void;
  onCancel?(): Promise<void> | void;
}

export const UpdateSectionForm = (props: Props): JSX.Element => {
  const { t } = useTranslation('builderAdmin');
  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState(props.description || '');
  const [hasError, toggleError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    toggleError(false);

    try {
      setLoading(true);
      const updatePayload: UpdateSectionPayload = {
        path: props.path,
        title,
        description
      };
      await props.onSave(updatePayload);
    } catch (e) {
      toggleError(true);
    } finally {
      setLoading(false);

      if (props.onFinished) {
        await props.onFinished();
      }
    }
  };

  return (
    <>
      <div className="field">
        <p className="control has-icons-right">
          <input
            className="input"
            type="text"
            autoComplete={AutoComplete.on}
            placeholder={'Title (same as channel name if left blank)'}
            style={{ marginBottom: 5, marginTop: 5 }}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          {title !== props.title && (
            <span className="icon is-small is-right" style={{ height: '2.75rem' }}>
              <Icon icon="asterisk" fixedWidth />
            </span>
          )}
        </p>
      </div>
      <div className="field">
        <div className="control">
          <CustomEditor
            hasChanged={!!description && description !== props.description}
            className="textarea content has-bottom-margin"
            style={{ fontSize: 14 }}
            placeholder={t('Description')}
            value={description}
            onChange={setDescription}
            size="large"
          />
        </div>
      </div>
      <ButtonList align="right">
        <Button $type="text" onClick={() => props.onCancel && props.onCancel()}>
          Cancel
        </Button>
        <Button $type="primary" $loading={loading} onClick={handleSave}>
          Save
        </Button>
      </ButtonList>

      {hasError && (
        <article className="message is-warning">
          <div className="message-body">Something went wrong while trying to update section. Please try again.</div>
        </article>
      )}
    </>
  );
};
