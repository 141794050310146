import React from 'react';

import { Meta } from '@/components/Meta/Meta';
import { ProductMeta } from '@/types/learning/learning-catalog';
import { minutesToRelativeTime } from '@/utils/time';
import { BodyMd } from '@/components/Typography/Typography';

export const MetaBlock = ({
  meta: { LANGUAGE, LOCATION, INSTRUCTORS, INSTITUTION, TOTAL_EFFORT, LEVEL, FORMAT }
}: {
  meta: ProductMeta;
}): JSX.Element => (
  <BodyMd>
    <Meta inBlock icon="chart-line" caption={LEVEL || undefined} />
    <Meta inBlock icon="clock" caption={TOTAL_EFFORT || undefined ? minutesToRelativeTime(Number(TOTAL_EFFORT)) : undefined} />
    <Meta inBlock icon="th-large" caption={FORMAT || undefined} />
    <Meta inBlock icon="language" caption={LANGUAGE || undefined} />
    <Meta inBlock icon="map-marker-alt" caption={LOCATION || undefined} />
    <Meta inBlock icon="chalkboard-teacher" caption={INSTRUCTORS || undefined} />
    <Meta inBlock icon="university" caption={INSTITUTION || undefined} />
  </BodyMd>
);
