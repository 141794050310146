import React from 'react';

import { Icon } from '@/ui/Icon';
import {
  ContentStyled,
  LabelStyled,
  RootStyled,
  SideBoxStyled,
  TitleContainerStyled,
  TitleStyled,
  TitleSuffixStyled,
} from './styles';
import { InformerProps } from './types';

export const Informer = ({
  title,
  label,
  sideBox: { background, icon, iconColor, size },
  titleSuffix,
  className,
}: InformerProps): JSX.Element => (
  <RootStyled className={className}>
    <SideBoxStyled background={background} iconColor={iconColor} size={size}>
      <Icon icon={icon} />
    </SideBoxStyled>
    <ContentStyled minimized={size === 'small' || size === 'medium'}>
      <LabelStyled>{label}</LabelStyled>
      <TitleContainerStyled>
        <TitleStyled>{title}</TitleStyled>
        <TitleSuffixStyled>{titleSuffix}</TitleSuffixStyled>
      </TitleContainerStyled>
    </ContentStyled>
  </RootStyled>
);
