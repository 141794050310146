import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;
