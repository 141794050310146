import { RootStyled, IndicatorStyled } from './styles';

export type UnderlineProps = {
  activeTabLeft: number;
  activeTabWidth: number;
};

export const Underline = ({ activeTabLeft, activeTabWidth }: UnderlineProps): JSX.Element => (
  <RootStyled>
    <IndicatorStyled activeTabLeft={activeTabLeft} activeTabWidth={activeTabWidth} />
  </RootStyled>
);
