import React from 'react';

import './terms.scss';
import { PrivacyEn } from './components/PrivacyEn';
import { Section } from '@/components/Section';

export const PrivacyPage = (): JSX.Element => {
  return (
    <Section>
      <div className="grid">
        <div className="gc gc-3-d gc-2-t gc-1-l gc-0-p" />
        <div className="gc gc-6 gc-8-t gc-10-l gc-12-p">
          <div className="content" styleName="privacy-content">
            <PrivacyEn />
          </div>
        </div>
      </div>
    </Section>
  );
};
