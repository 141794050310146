import { Nominal } from './nominal';
import { Actor, ActorGroup } from './actor';
import { LanguageMap, RFC5646LanguageCodes } from './i18n/languageMap';
import { StatementRef } from './statementRef';
import { ReactionId } from '@/administration/pages/Course/store/block/types';

export type Context = Nominal<ContextProps, 'XAPIContext'>;

// https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#context
export type ContextProps = {
  registration?: string;
  instructor?: Actor;
  team?: ActorGroup;
  contextActivities?: {
    parent?: ContextActivity[];
    grouping?: ContextActivity[];
    category?: ContextActivity[];
    other?: ContextActivity[];
  };
  statement?: StatementRef;
  revision?: string;
  platform?: string;
  language?: RFC5646LanguageCodes;
  extensions?: ContextExtensions;
};

export type ContextActivity = {
  objectType?: 'Activity';
  id: string;
  definition?: {
    name?: LanguageMap;
    description?: LanguageMap;
    type: string;
  };
};

export type ContextExtensions =
  | {
      [uri: string]: any;
    }
  | { key: string; value: any };

/**
 * @param duration Duration value represented in MINUTES
 */
export const contextExtensions = (duration?: number, reactionId?: ReactionId): ContextExtensions | undefined => {
  const extensions: { key: string; value: any }[] = [];

  const durationInPT = convertDurationToPT(duration);
  if (durationInPT) extensions.push({ key: 'http://id.tincanapi.com/extension/planned-duration', value: durationInPT });

  if (reactionId) extensions.push({ key: 'http://id.collegial.com/extension/reacted', value: reactionId });

  return extensions;
};

/**
 * Duration is converted based on ISO-8601 duration: https://en.wikipedia.org/wiki/ISO_8601
 * @param duration Duration value represented in MINUTES
 */
export const convertDurationToPT = (duration?: number): string | undefined => {
  if (typeof duration !== 'number' || duration < 1) {
    return undefined;
  }

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  const ptHours = hours ? `${hours}H` : '';
  const ptMinutes = minutes ? `${minutes}M` : '';

  return `PT${ptHours}${ptMinutes}`;
};

/**
 * @param duration Duration value represented in MINUTES
 */
export const contextPlatformCollegial = (duration?: number, registration?: string, reactionId?: ReactionId): Context =>
  <Context>{
    platform: 'collegial',
    language: 'en',
    registration,
    extensions: contextExtensions(duration, reactionId),
  };

export const contextPlatformCollegialQuestion = (registration: string, parentObjectId: string): Context =>
  <Context>{
    platform: 'collegial',
    language: 'en',
    registration,
    contextActivities: {
      parent: [{ id: parentObjectId }],
    },
  };
