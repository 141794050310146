import React from 'react';

import { Welcome } from '@/component/start/Welcome';
import { BillboardStickyWithLearnings } from './components/billboardStickyWithLearnings';
import { CampusLearningSection } from './components/CampusLearningSection';
import { CampusGrid, Header, BannerContainer, WelcomeContainer, Container } from './styles';
import { Layout } from '@/ui/Layout';

export const Homepage = (): JSX.Element => {
  return (
    <>
      <Layout.ContentSection marginTop>
        <CampusGrid>
          <Header>
            <BannerContainer>
              <BillboardStickyWithLearnings />
            </BannerContainer>
            <WelcomeContainer>
              <Welcome />
            </WelcomeContainer>
          </Header>
        </CampusGrid>
      </Layout.ContentSection>
      <Layout.ContentSection marginTop marginBottom>
        <CampusGrid>
          <Container>
            <CampusLearningSection />
          </Container>
        </CampusGrid>
      </Layout.ContentSection>
    </>
  );
};
