import { Children } from 'react';
import styled from 'styled-components';

import { variant } from './sizing';

export const Footer = styled.div`
  flex-shrink: 1;
  display: ${p => Children.map(p.children, c => !!c)?.reduce((a, b) => a || b, false) ? 'flex' : 'none'};
  flex-wrap: wrap;
  gap: 16px;
  
  ${variant.small} {
    margin: 8px 24px 16px 24px;
    justify-content: flex-start;
  }
  
  ${variant.large} {
    border-top: 1px solid ${p => p.theme.colors.neutral.outline1};
    padding: 16px;
    justify-content: center;
  }
`;
