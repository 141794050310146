import styled from 'styled-components';

export const RootStyled = styled.div`
  max-width: 1064px;
  min-height: 100%;
  padding: 72px 84px;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
`;

export const TextEditorStyled = styled.div`
  height: 100%;
  max-height: 205px;
  display: flex;
  flex-direction: column;
`;

export const InformerBannerStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: flex;
  align-items: center;
  padding: 20px 16px;
  background: ${({ theme }) => theme.colors.neutral.container};
  border-radius: 4px;
  gap: 10px;
`;

export const PeerReviewRootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 8px;
`;

export const PeerReviewMetaStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PeerReviewTitleStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const PeerReviewDescriptionStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;
