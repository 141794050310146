import React, { CSSProperties, useEffect, useState } from 'react';
import { flip, offset, autoUpdate, UseVirtualFloatingOptions, useFloating } from '@udecode/plate-floating';

const floatingOptions: UseVirtualFloatingOptions = {
  placement: 'top',
  middleware: [
    offset(16),
    flip({
      padding: 5,
      fallbackPlacements: ['bottom'],
    }),
  ],
  whileElementsMounted: autoUpdate,
};

export const useSelectedElementFloatingToolbar = ({
  selected,
}: {
  selected: boolean;
}): {
  setFloating: (node: HTMLElement | null) => void;
  setReference: (node: HTMLElement | null) => void;
  style: React.CSSProperties;
} => {
  const [open, setOpen] = useState(false);
  const { refs, x, y, strategy, update } = useFloating({ ...floatingOptions, open });

  useEffect(() => {
    if (selected && refs.reference && refs.floating) {
      setOpen(true);
      update();
    } else {
      setOpen(false);
    }
  }, [selected, refs.reference, refs.floating]);

  return {
    setFloating: refs.setFloating,
    setReference: refs.setReference,
    style: {
      position: strategy,
      left: `${x}px`,
      top: `${y}px`,
      zIndex: 1000,
      display: floatingOptions.open === false ? 'none' : undefined,
      visibility: open ? undefined : 'hidden',
      transform: open ? 'scale(1)' : 'scale(0)',
      opacity: open ? '100' : '0',
      transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
      userSelect: 'none',
    } as CSSProperties,
  };
};
