import styled from 'styled-components';

export const RootStyled = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.onMain};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 72px 84px;
  border-radius: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContentStyled = styled.div`
  width: 100%;
  max-width: 920px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  word-wrap: break-word;
  overflow-x: auto;
  gap: 24px;
`;

export const TitleStyled = styled.span`
  ${(p) => p.theme.typography.title2};
`;

export const TextStyled = styled.div`
  ${(p) => p.theme.typography.body1};

  &.content.is-article {
    padding: 0;
  }
`;
