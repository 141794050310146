import React from 'react';
import { PlateLeaf, PlateLeafProps } from '@udecode/plate-common/react';

import { UnderlineText } from '@/component/customEditorV2/ui/Typography';

export const UnderlineLeaf: React.FC<PlateLeafProps> = (props: any) => {
  const { leaf, children, ...rest } = props;

  const UnderlineComponent = UnderlineText;

  return (
    <PlateLeaf {...rest} leaf={leaf}>
      <UnderlineComponent>{children}</UnderlineComponent>
    </PlateLeaf>
  );
};
