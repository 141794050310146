import { CourseCardData } from '@/store/inventory';
import { ParticipationGroup } from '@/types/analytics';
import { Card, ProgressCard } from '@/types/learning/card';
import { EventEnrollment } from '@/types/learning/learning-catalog';
import { Assignment, AssignmentPage } from './types';
import { getAssignmentPage } from './getAssignmentPage';

type LearningCardProps = {
  assignments: Assignment[];
  myLearningCatalogCards: ProgressCard[];
  inventoryCards: CourseCardData[];
  catalogCards: Card[];
  participations?: ParticipationGroup[];
  eventEnrollments?: readonly EventEnrollment[];
};

export const getAssignmentPages = ({
  assignments,
  myLearningCatalogCards,
  inventoryCards,
  catalogCards,
  participations,
  eventEnrollments,
}: LearningCardProps): AssignmentPage[] => {
  return assignments
    .map((assignment) => {
      return getAssignmentPage({
        assignment,
        myLearningCatalogCards,
        inventoryCards,
        catalogCards,
        participations,
        eventEnrollments,
      });
    })
    .filter((m): m is AssignmentPage => !!m);
};
