import styled, { css } from 'styled-components';

import { TooltipStyledProps } from './types';

export const TooltipContainerStyled = styled.div`
  position: relative;
  width: fit-content;
`;

/** Placement around the wrapped component: above, below, to the left, to the right */
const PlacementTop = css`
  top: auto;
  bottom: 100%;
  margin-bottom: 6px;
`;

const PlacementBottom = css`
  top: 100%;
  bottom: auto;
  margin-top: 6px;
`;

const PlacementLeft = css`
  right: 100%;
  left: auto;
  margin-right: 6px;
`;

const PlacementRight = css`
  right: auto;
  left: 100%;
  margin-left: 6px;
`;

/** Position inside of the placement: start, middle, end  */
const PositionVerticalStart = css`
  left: 0;
  right: auto;
`;

const PositionVerticalMiddle = css`
  left: 50%;
  transform: translateX(-50%);
`;

const PositionVerticalEnd = css`
  left: auto;
  right: 0;
`;

const PositionHorizontalStart = css`
  top: 0;
  bottom: auto;
`;

const PositionHorizontalMiddle = css`
  top: 50%;
  transform: translateY(-50%);
`;

const PositionHorizontalEnd = css`
  bottom: 0;
  top: auto;
`;

/** Icon positioning:
 * - top-start
 * - top-middle
 * - top-end
 * - left-start
 * - left-middle
 * - left-end
 * - right-start
 * - right-middle
 * - right-end
 * - bottom-start
 * - bottom-middle
 * - bottom-end
 *  */
const IconTopStart = css`
  svg {
    left: 8px;
    transform: rotate(180deg) translateY(-5px);
    bottom: 0;
  }
`;
const IconTopMiddle = css`
  svg {
    right: 50%;
    transform: rotate(180deg) translateY(-5px) translateX(-50%);
    bottom: 0;
  }
`;
const IconTopEnd = css`
  svg {
    right: 8px;
    transform: rotate(180deg) translateY(-5px);
    bottom: 0;
  }
`;

const IconBottomStart = css`
  svg {
    left: 8px;
    transform: rotate(0deg) translateY(-5px);
  }
`;
const IconBottomMiddle = css`
  svg {
    left: 50%;
    transform: rotate(0deg) translateY(-5px) translateX(-50%);
  }
`;
const IconBottomEnd = css`
  svg {
    right: 8px;
    transform: rotate(0deg) translateY(-5px);
  }
`;

const IconRightStart = css`
  svg {
    left: 0;
    transform: rotate(270deg) translateY(-10px);
    top: 12px;
  }
`;

const IconRightMiddle = css`
  svg {
    left: 0;
    transform: rotate(270deg) translateY(-10px) translateX(-25%);
    bottom: 50%;
  }
`;

const IconRightEnd = css`
  svg {
    left: 0;
    transform: rotate(270deg) translateY(-10px);
    bottom: 12px;
  }
`;

const IconLeftStart = css`
  svg {
    left: 100%;
    transform: rotate(90deg) translateY(6px) translateX(10%);
    top: 12px;
  }
`;

const IconLeftMiddle = css`
  svg {
    left: 100%;
    transform: rotate(90deg) translateY(6px) translateX(10%);
    bottom: 50%;
  }
`;

const IconLeftEnd = css`
  svg {
    left: 100%;
    transform: rotate(90deg) translateY(6px) translateX(10%);
    bottom: 12px;
  }
`;

/** Wraps tooltip box and tooltip icon */
export const TooltipContentStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  color: ${(p) => p.theme.colors.neutral.background};
  pointer-events: none; // hovering the tooltip shouldn't keep it open
`;

/** Default icon styling  */
const DefaultIconCSS = css`
  svg {
    display: block;
    position: absolute;
    width: 16px;
    height: 6px;

    path {
      fill: ${(p) => p.theme.colors.neutral.onContainer};
    }
  }
`;

/** Tooltip title stylings */
export const TooltipTitleStyled = styled.div`
  ${(p) => p.theme.typography.body4};
  padding: 5px 12px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
  text-transform: none;
`;

/** Default tooltip styling */
const DefaultTooltipCSS = css`
  width: max-content;
  max-width: 250px;
  transition: opacity 0.2s;
  z-index: 10;
  position: absolute;
  background: ${(p) => p.theme.colors.neutral.onContainer};
  border-radius: 3px;
  ${(p) => p.theme.shadow.primary[16]};
`;

export const TooltipStyled = styled.div<TooltipStyledProps>`
  ${DefaultTooltipCSS};
  ${DefaultIconCSS};

  ${({ isVisible }) =>
    isVisible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `};

  ${({ placement }) => {
    switch (placement) {
      case 'top-start':
        return `
          ${PlacementTop}
          ${PositionVerticalStart}
          ${IconTopStart}
        `;
      case 'top':
        return `
          ${PlacementTop}
          ${PositionVerticalMiddle}
          ${IconTopMiddle}
        `;
      case 'top-end':
        return `
          ${PlacementTop}
          ${PositionVerticalEnd}
          ${IconTopEnd}
        `;
      case 'right-start':
        return `
          ${PlacementRight}
          ${PositionHorizontalStart}
          ${IconRightStart}
        `;
      case 'right':
        return `
          ${PlacementRight}
          ${PositionHorizontalMiddle}
          ${IconRightMiddle}
        `;
      case 'right-end':
        return `
          ${PlacementRight}
          ${PositionHorizontalEnd}
          ${IconRightEnd}
        `;
      case 'bottom-start':
        return `
          ${PlacementBottom}
          ${PositionVerticalStart}
          ${IconBottomStart}
        `;
      case 'bottom':
        return `
          ${PlacementBottom}
          ${PositionVerticalMiddle}
          ${IconBottomMiddle}
        `;
      case 'bottom-end':
        return `
          ${PlacementBottom}
          ${PositionVerticalEnd}
          ${IconBottomEnd}
        `;
      case 'left-start':
        return `
          ${PlacementLeft}
          ${PositionHorizontalStart}
          ${IconLeftStart}
        `;
      case 'left':
        return `
          ${PlacementLeft}
          ${PositionHorizontalMiddle}
          ${IconLeftMiddle}
        `;
      case 'left-end':
        return `
          ${PlacementLeft}
          ${PositionHorizontalEnd}
          ${IconLeftEnd}
        `;
    }
  }}
`;
