import { Control, Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useEffect } from 'react';

import { FormSchema, TextFieldName } from '../..';
import { RadioGroup } from '@/ui/RadioGroup';
import { Input } from '@/ui/TextField';
import { validateUrl } from '@/utils/text';
import { RLocationType } from '@/store/v2';

const OptionsListStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FormLocation = ({ control }: { control: Control<FormSchema> }): JSX.Element => {
  const { watch, setValue } = useFormContext<FormSchema>();

  const [format] = watch(['format']);

  useEffect(() => {
    setValue(format === RLocationType.InPerson ? 'calendarURL' : 'location', undefined);
  }, [format]);

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.Format}
      render={({ field: { onChange, value } }) => (
        <>
          <RadioGroup
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            <OptionsListStyled>
              <RadioGroup.Button value={RLocationType.Online} label="Online" />
              <RadioGroup.Button value={RLocationType.InPerson} label="In person" />
            </OptionsListStyled>
          </RadioGroup>
          <div>
            {format === RLocationType.InPerson && (
              <Controller
                control={control}
                rules={{ required: true }}
                name={TextFieldName.Location}
                render={({ field: { onChange: onLocationChange, name, value: locationValue }, fieldState: { error } }) => (
                  <Input
                    onChange={onLocationChange}
                    name={name}
                    value={locationValue || ''}
                    fullWidth
                    leadingIcon={['far', 'location-dot']}
                    label={'Location'}
                    error={
                      error && {
                        message: 'Enter location',
                      }
                    }
                  />
                )}
              />
            )}
            {format === RLocationType.Online && (
              <Controller
                control={control}
                rules={{ required: true, validate: (text) => validateUrl(text) }}
                name={TextFieldName.CalendarURL}
                render={({ field: { onChange: onCalendarURLChange, name, value: calendarURLValue }, fieldState: { error } }) => (
                  <Input
                    onChange={onCalendarURLChange}
                    name={name}
                    value={calendarURLValue || ''}
                    leadingIcon="link"
                    fullWidth
                    label={'Link'}
                    error={
                      error && {
                        message: 'Enter valid link. Example: https://www.collegial.com/',
                      }
                    }
                  />
                )}
              />
            )}
          </div>
        </>
      )}
    />
  );
};
