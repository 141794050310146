import { useNavigate } from 'react-router-dom';

import { ModularLearningStartLayout } from '../ModularLearningDeprecated/ModularLearningStart';
import { ModularLearningStartContent } from '../ModularLearningDeprecated/ModularLearningStart/Content';
import { UsePracticalAssessmentViewerHook } from './store/practicalAssessmentViewer/usePracticalAssessmentViewer';

export const PracticalAssessmentStart = (props: ReturnType<UsePracticalAssessmentViewerHook>): JSX.Element => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const loading = props.loadingLearning || !props.loadingProgressInitialized;
  return (
    <>
      {loading ? (
        <ModularLearningStartLayout.LoadingState />
      ) : (
        <ModularLearningStartLayout
          title={props.practicalAssessment?.title || ''}
          content={
            <ModularLearningStartContent
              title={props.practicalAssessment?.title}
              teaser={props.practicalAssessment?.teaser}
              imageSrc={props.practicalAssessment?.image}
              effort={{
                hours: props.practicalAssessment?.effortHours,
                minutes: props.practicalAssessment?.effortMinutes,
              }}
              onStartClick={props.startAssessment}
              startCourseLoading={props.loadingStartAssessment}
            />
          }
          onCloseClick={handleGoBack}
        />
      )}
    </>
  );
};
