// ####################
// ##### LEARNING #####
// ####################

import { LearningCardType } from './learning/learning-catalog';
import { LocationInput } from '../administration/pages/LearningEditor/types';
import { ApolloEntity } from './apollo';
import { CourseProgress } from './learning/card';

export enum LearningType {
  SEMINAR = 'SEMINAR',
  WEBINAR = 'WEBINAR',
  ARTICLE = 'ARTICLE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  RESOURCE = 'RESOURCE',
  E_LEARNING = 'E_LEARNING',
  ONLINE_LEARNING = 'ONLINE_LEARNING',
  COURSE = 'COURSE',
  JOURNEY = 'JOURNEY',
}

const cardToLearningMap: { [key in LearningCardType]: LearningType } = {
  webinar: LearningType.WEBINAR,
  seminar: LearningType.SEMINAR,
  article: LearningType.ARTICLE,
  audio: LearningType.AUDIO,
  video: LearningType.VIDEO,
  resource: LearningType.RESOURCE,
  'e-learning': LearningType.E_LEARNING,
  'online-learning': LearningType.ONLINE_LEARNING,
  course: LearningType.COURSE,
  journey: LearningType.JOURNEY,
};

export function CardTypeToLearningType(cardType: LearningCardType): LearningType {
  return cardToLearningMap[cardType];
}

export const EventTypes = [LearningType.SEMINAR, LearningType.WEBINAR];
export const CompletableTypes = [
  LearningType.ARTICLE,
  LearningType.AUDIO,
  LearningType.VIDEO,
  LearningType.RESOURCE,
  LearningType.ONLINE_LEARNING,
];

export const BlockApprovedTypes = [
  LearningType.ARTICLE,
  LearningType.AUDIO,
  LearningType.RESOURCE,
  LearningType.VIDEO,
  LearningType.ONLINE_LEARNING,
];

export enum LearningVisibility {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
  FEATURED = 'FEATURED',
}

export enum LearningStatus {
  AVAILABLE = 'AVAILABLE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export interface LearningMetaAttributes {
  [key: string]: string;
}

export enum UserRole {
  SUME = 'Subject Matter Expert',
  FACILITATOR = 'Learning facilitator',
  MOD = 'Moderator',
  RESPONSIBLE = 'Role Responsible',
  SUBOWN = 'Subject Owner',
}

export interface UserRoleConnection {
  userId?: number;
  role?: UserRole;
}

interface LearningMeta {
  eLearningUrl?: string;
  timezone?: number;
  attributes?: LearningMetaAttributes;
  connections?: UserRoleConnection[];
  provider?: string;
  providerUrl?: string;
  level?: string;
}

export interface LearningSubject extends ApolloEntity {
  subject: string;
  category: string;
  subjectId: number;
  categoryId: number;
}

export interface Learning {
  canEdit?: boolean;
  contentOwner?: number;
  created?: string;
  archived?: string;
  effort?: number;
  id: number;
  image: string | null;
  languageId: number;
  locations?: Location[];
  meta: LearningMeta;
  modules?: Module[];
  preamble: string;
  spaceId: number;
  subjects: LearningSubject[];
  teaser: string;
  title: string;
  type: LearningType;
  updated?: string;
  visibility: LearningVisibility;
  progress?: CourseProgress;
  creatorEmail?: string;
  updatedByEmail?: string;
}

export interface LearningInput {
  contentOwner?: number;
  archived?: Date;
  effort?: number;
  id?: number;
  image: string | null;
  languageId?: number;
  locations?: LocationInput[];
  meta: LearningMeta;
  modules?: Module[];
  preamble: string;
  spaceId?: number;
  subjects: number[];
  teaser: string;
  title: string;
  type: LearningType;
  visibility: LearningVisibility;
}

export interface SaddEnrollmentsResult {
  id?: number;
  email: string;
  message?: string;
}

export interface LearningLinks {
  link: string;
  previewLink: string;
  editLink: string;
}

// ####################
// ##### LOCATION #####
// ####################

export interface Location {
  id: number;
  startDate: string;
  endDate?: string;
  address?: string;
  room?: string;
  capacity?: number | null;
  waitlistType: WaitlistType;
  myEnrollment?: LocationEnrollment;
  enrollments?: LocationEnrollment[];

  stats?: {
    isFinished: boolean;
    enrolledCount: number;
    waitlistedCount: number;
    attendedCount: number;
    notAttendedCount: number;
  } | null;
}

export enum WaitlistType {
  DISABLED = 'DISABLED',
  AUTOMATIC = 'AUTOMATIC',
}

interface LocationEnrollment {
  id: number;
  userId: number;
  email: string;
  username: string;
  enrolledAt: string;
  locationId: number;
  attended: AttendedType;
}

// ####################
// ###### MODULE ######
// ####################

export interface Module {
  id?: number;
  created?: Date;
  updated?: Date;
  learningId?: number;
  name: string;
  order: number;
  pages?: Page[];
}

// ####################
// ####### PAGE #######
// ####################
export interface Page {
  id?: number;
  created?: Date;
  updated?: Date;
  moduleId?: number;
  name: string;
  order: number;
  blocks?: Block[];
}

// ####################
// ####### BLOCK ######
// ####################
export enum BlockType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  PREAMBLE = 'PREAMBLE',
  VIDEO_LINK = 'VIDEO_LINK',
  RESOURCE = 'RESOURCE',
  AUDIO = 'AUDIO',
}

export interface BlockData {
  [key: string]: string;
}

export interface Block extends ApolloEntity {
  id?: number;
  pageId?: number;
  type: BlockType;
  data: BlockData;
  order: number;
}

export enum AttendedType {
  ENROLLED = 'ENROLLED',
  ATTENDED = 'ATTENDED',
  NOT_ATTENDED = 'NOT_ATTENDED',
  WAITLISTED = 'WAITLISTED',
}
