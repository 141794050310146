import styled from 'styled-components';

export const RootStyled = styled.div`
  background: ${({ theme }) => theme.colors.primary.onMain};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 72px 84px;
  border-radius: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContentStyled = styled.div`
  max-width: 920px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;
`;

export const TitleStyled = styled.span`
  ${(p) => p.theme.typography.title2};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
`;

export const ResultsContainer = styled.div`
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.neutral.outline1};
`;

export const LayoutWrapper = styled.div``;

export const AnimationWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
`;
