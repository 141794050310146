import { css } from 'styled-components';

export const tabItemBase = css`
  outline: none;
  border: none;
  display: block;
  background: transparent;
  padding: 16px 20px;
  border-radius: 4px 4px 0 0;
  :not(:last-of-type) {
    margin-right: 8px;
  }
  color: ${(p) => p.theme.colors.neutral.outline2};
  text-decoration: none;
  transition: background 0.2s, color 0.2s, box-shadow 0.2s;
  cursor: pointer;
`;

export const tabItemActive = css`
  background: ${(p) => p.theme.colors.neutral.background};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  ${(p) => p.theme.shadow.primary[8]}
`;

export const tabItemInactiveHover = css`
  color: ${(p) => p.theme.colors.neutral.onBackground};
  background: rgba(0, 136, 98, 0.08);
`;

export const tabItemInactiveFocus = css`
  outline: 1px solid ${(p) => p.theme.colors.primary.outline};
`;
