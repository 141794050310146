/**
 * Utility to toggle item from a set, while returning a new Set
 * @param oldSet
 * @param item
 */
export function toggleItemInSet<T>(oldSet: Set<T>, item: T): Set<T> {
  return oldSet.has(item) ? new Set([...oldSet].filter(existingItem => item !== existingItem)) : new Set([...oldSet, item]);
}

/**
 * Utility to either add or remove item from a set and returning a new Set
 * @param oldSet
 * @param item
 */
export function setItemInSet<T>(oldSet: Set<T>, item: T, enabled: boolean): Set<T> {
  return enabled ? new Set([...oldSet, item]) : new Set([...oldSet].filter(existingItem => item !== existingItem));
}
