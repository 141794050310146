import React from 'react';
import clsx from 'clsx';

import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { useNextStepLabel } from './useNextStepLabel';
import { useOnboarding } from './useOnboarding';
import { useRedirect } from './useRedirect';
import { OnboardingStep } from './types';
import { Steps } from './Steps';
import { Wrapper, Content, AllSteps, SingleStep, Footer, Pagination, PaginationDot } from './styles';

export const OnboardingPage = (): JSX.Element => {
  const {
    stepState,
    currentStep,
    markStepReady,
    isNextStepEnabled,
    setCurrentStep,
    storeSaveFn,
    requestNextStep,
    committingCurrentStep,
  } = useOnboarding();
  const nextStepLabel = useNextStepLabel(currentStep);

  useRedirect(currentStep);

  return (
    <Wrapper>
      <Content>
        <AllSteps>
          {stepState.map(({ step, enabled }) => {
            const StepComponent = Steps[step];
            if (!StepComponent) {
              return;
            }
            return (
              <SingleStep key={step} className={clsx(step === currentStep && enabled && 'active')}>
                <StepComponent onReady={markStepReady} onSave={storeSaveFn} />
              </SingleStep>
            );
          })}
        </AllSteps>
      </Content>
      <Footer>
        <ButtonList align="center">
          <Button
            onClick={requestNextStep}
            disabled={!isNextStepEnabled || committingCurrentStep}
            $loading={committingCurrentStep}
            $type="primary"
          >
            {nextStepLabel}
          </Button>
        </ButtonList>
        <Pagination>
          {stepState
            .filter(({ step }) => step !== OnboardingStep.FinalStep)
            .map(({ step, enabled }) => (
              <PaginationDot
                key={step}
                active={step === currentStep}
                disabled={!enabled}
                onClick={enabled ? () => setCurrentStep(step) : undefined}
              />
            ))}
        </Pagination>
      </Footer>
    </Wrapper>
  );
};
