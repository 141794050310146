import styled from 'styled-components';

export const RootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const ParticipantContainerStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const NameStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const EmailStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

export const TimeSpentStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ParticipantInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
