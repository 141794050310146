import React from 'react';
import { LazyLoadBackgroundImage } from '@tjoskar/react-lazyload-img';
import styled from 'styled-components';

import FallbackImage from '@/assets/fallback.png';
import { ProviderMeta } from '@/store/providers';
import { CourseCardProvider } from './CourseCardProvider';
import { Icon } from '@/components/Icon';
import { CardType } from '@/types/learning/card';
import { IconPropType } from '@/ui/Icon';

type StyledImageProps = {
  $isCollection?: boolean;
  $bottomLeftShadow?: boolean;
  $topRightShadow?: boolean;
};

type CourseCardImageProps = {
  image: string;
  isCollection?: boolean;
  isCancellable?: boolean;
  isCompleted?: boolean;
  providerMeta?: ProviderMeta;
  type: CardType;
};

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledImage = styled(LazyLoadBackgroundImage).attrs({ defaultImage: FallbackImage })<StyledImageProps>`
  background-color: var(--color-wild-sand);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--radius-outer) var(--radius-outer) 0 0;
  padding-bottom: ${Math.ceil((9 / 21) * 100)}%;
  position: relative;
  width: 100%;
  ${({ $topRightShadow, $bottomLeftShadow }) =>
    ($topRightShadow || $bottomLeftShadow) &&
    `
    &:before {
      background: ${
        $topRightShadow && $bottomLeftShadow
          ? 'linear-gradient(45deg, var(--color-black) 0%, rgba(58, 58, 58, 0) 53.62%, var(--color-black) 100%)'
          : $topRightShadow
          ? 'linear-gradient(45deg, rgba(92, 92, 92, 0.0001) 31.36%, var(--color-black) 100%)'
          : $bottomLeftShadow
          ? 'linear-gradient(45deg, var(--color-black) 0%, rgba(0, 0, 0, 0.0001) 53.18%)'
          : null
      };
      border-radius: var(--radius-outer) var(--radius-outer) 0 0;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  `}
  ${({ $isCollection }) =>
    $isCollection &&
    `
      &:after {
        background-color: var(--color-alto);
        border-radius: 0 0 var(--radius-inner) var(--radius-inner);
        bottom: calc(var(--gap-xs) * -1);
        content: '';
        height: var(--gap-xs);
        left: calc(var(--gap-sm) * 1.5);
        position: absolute;
        right: calc(var(--gap-sm) * 1.5);
      }
  `}
`;

const StyledTypeIcon = styled(Icon)`
  bottom: calc(var(--gap-sm) * 1.5);
  box-shadow: var(--box-shadow-small);
  color: var(--color-white);
  font-size: var(--font-size-7);
  left: calc(var(--gap-sm) * 1.5);
  position: absolute;
`;

const StyledCompleted = styled.div`
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 50%;
  border: var(--border-width) solid var(--color-white);
  bottom: calc(var(--gap-sm) * -1);
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-7);
  height: 45px;
  justify-content: center;
  position: absolute;
  right: var(--gap-lg);
  width: 45px;
`;

const TypeIcon: { [key: string]: IconPropType } = {
  article: 'newspaper',
  audio: 'microphone',
  resource: 'file',
  video: 'video',
};

export const CourseCardImage = ({
  isCollection,
  image,
  providerMeta,
  type,
  isCancellable,
  isCompleted,
}: CourseCardImageProps): JSX.Element => {
  const hasTypeIcon = ['audio', 'video', 'article', 'resource'].includes(type);

  return (
    <StyledWrapper>
      <StyledImage
        image={image}
        $isCollection={!providerMeta && isCollection}
        $topRightShadow={isCancellable}
        $bottomLeftShadow={hasTypeIcon}
      />
      <CourseCardProvider provider={providerMeta?.provider} isCollection={isCollection} />
      {hasTypeIcon && <StyledTypeIcon icon={TypeIcon[type]} fixedWidth />}
      {isCompleted && (
        <StyledCompleted>
          <Icon icon="check" />
        </StyledCompleted>
      )}
    </StyledWrapper>
  );
};
