import { Control, Controller, FieldValues } from 'react-hook-form';

import { RadioGroup } from '@/ui/RadioGroup';

const OPTIONS = [
  {
    label: 'Within the coming month',
    value: 'PLANNED_NEXT_MONTH',
  },
  {
    label: 'In the coming 6 months',
    value: 'PLANNED_NEXT_SIX_MONTHS',
  },
  {
    label: 'I’m not sure yet, will learn more on this topic first',
    value: 'NOT_SURE',
  },
];

export const ApplicationField = ({ control }: { control: Control<FieldValues> }): JSX.Element => (
  <Controller
    control={control}
    name="applied"
    rules={{ required: true }}
    render={({ field: { onChange, value } }) => (
      <RadioGroup value={value} onChange={onChange} legend="When will you use this in your work?">
        {OPTIONS.map(({ label, value }) => (
          <RadioGroup.Button value={value} key={value} label={label} />
        ))}
      </RadioGroup>
    )}
  />
);
