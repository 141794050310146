import React, { ChangeEvent } from 'react';

import { RadioContext } from '../RadioContext';
import { LegendStyled, OptionsStyled, RootStyled } from './styles';
import { GroupProps } from './types';

export const Group = ({ children, legend, onChange, value, className }: GroupProps): JSX.Element => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <RadioContext.Provider value={{ currentValue: value, onChange: handleChange }}>
      <RootStyled className={className}>
        {legend && <LegendStyled>{legend}</LegendStyled>}
        <OptionsStyled>{children}</OptionsStyled>
      </RootStyled>
    </RadioContext.Provider>
  );
};
