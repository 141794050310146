import { useParams } from 'react-router-dom';

import { LiveEventDetails } from '@/pages/LiveEvent/LiveEventDetails';
import { useLiveEventViewer } from '@/pages/LiveEvent/store/useLiveEventViewer';
import { ModularLearningStartLayout } from '@/pages/ModularLearningDeprecated/ModularLearningStart';
import { LiveEvent as LiveEventType } from '@/pages/LiveEvent/store/liveEvent/types';

export const LiveEvent = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const { liveEvent, loading } = useLiveEventViewer({ id: id as string });

  if (loading) {
    return <ModularLearningStartLayout.LoadingState />;
  }

  return <LiveEventDetails {...(liveEvent as LiveEventType)} />;
};
