import React from 'react';
import styled from 'styled-components';

const FooterCell = styled.td`
  padding: 0;
  overflow: hidden; // prevents a bug where cell contents exceed rounded borders
`;

const FooterContent = styled.div`
  background: ${p => p.theme.colors.neutral.container};
  color: ${p => p.theme.colors.neutral.onContainer};
`;

export const Footer = ({
  colSpan,
  children,
  className
}: {
  colSpan?: number;
  children?: React.ReactNode;
  className?: string;
}): JSX.Element => (
  <tfoot>
    <tr>
      <FooterCell colSpan={colSpan}>
        <FooterContent className={className}>{children}</FooterContent>
      </FooterCell>
    </tr>
  </tfoot>
);
