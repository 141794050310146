import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { IconPropType } from '@/ui/Icon';
import { Icon } from '../Icon/Icon';
import { fontStyles } from '../Typography/v2/font-styles';
import { Caption } from '../Typography/v2';

type FontStylingProps = {
  fontStyle?: keyof typeof fontStyles;
};

const StyledMetaV2 = styled(Caption).attrs((props) => ({ ...props, as: 'span' }))`
  display: flex;
  align-items: center;

  display: inline-block;
  & > span {
    padding-left: calc(var(--gap-xs) / 2);
  }
  height: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #3f494a;
  padding: 0px 8px;
`;

const StyledMetaInBlockV2 = styled(StyledMetaV2)`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 0.25rem;
  text-align: center;

  .fa-icon {
    &:first-child:not(:last-child) {
      margin-right: 5px;
    }

    &:last-child:not(:first-child) {
      margin-left: 5px;
    }
  }
`;
const StyledEventMetaV2 = styled(Caption).attrs((props) => ({ ...props, as: 'span' }))<FontStylingProps>`
  ${({ fontStyle = 'caption' }) => fontStyles[fontStyle]}
  display: flex;
  align-items: center;

  /* Buddy-theme/light/Foreground 1 */

  color: #141d1e;
  padding-top: 3px;
  padding-bottom: 3px;
`;

export const MetaV2 = ({
  caption,
  icon,
  inBlock = false,
  iconStyle,
  isEventWrapper = false,
}: {
  caption?: string;
  icon?: IconPropType;
  inBlock?: boolean;
  iconStyle?: CSSProperties;
  isEventWrapper?: boolean;
}): JSX.Element | null => {
  if (!caption) return null;

  const iconStyleToUse = iconStyle ?? { color: '#6F797A' };
  if (isEventWrapper) {
    return (
      <StyledEventMetaV2>
        {icon && <Icon icon={icon} style={iconStyleToUse} />}
        <span>{caption}</span>
      </StyledEventMetaV2>
    );
  }

  const WrapperV2 = inBlock ? StyledMetaInBlockV2 : StyledMetaV2;

  return (
    <WrapperV2>
      {icon && <Icon icon={icon} style={iconStyleToUse} />}
      <span>{caption}</span>
    </WrapperV2>
  );
};
