import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { InformationMessage } from '@/pages/learning/CourseDetails/components/styles';
import { ProviderAccessStatus, ProviderList, ProviderMeta } from '@/store/providers';
import { ProductFlags, ProductMeta } from '@/types/learning/learning-catalog';
import { trackEnrollLearningItemClicked } from '@/utils/tracking/learnings';
import styles from './enroll-button.scss';

interface Props {
  flags: ProductFlags;
  meta: ProductMeta;
  providerMeta?: ProviderMeta;
  providerStatus: ProviderAccessStatus;
  handleApplication?: () => void;
  handleProvider: (options: { variables: { currentRealm: string; provider: string } }) => void;
  handleProviderLoading: boolean;
  hasAccessToCourse: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  preview?: boolean;
}

export const Enroll = ({
  flags,
  meta,
  providerMeta,
  providerStatus,
  handleApplication,
  handleProvider,
  handleProviderLoading,
  hasAccessToCourse,
  isDisabled,
  isLoading,
  preview
}: Props): JSX.Element | null => {
  const { t } = useTranslation('learning');
  const providerHasAccess = providerStatus === ProviderAccessStatus.HAS_ACCESS;
  const providerPendingAccess = providerStatus === ProviderAccessStatus.PENDING_ACCESS;
  const defaultButtonText = flags.approvalRequired || flags.requiresApplication ? t('Apply') : t('Enroll');
  const enrollButtonText = meta.ENROLL_BUTTON_TEXT || defaultButtonText;
  const onEnrollClick = useCallback(() => {
    handleApplication?.();
    trackEnrollLearningItemClicked(enrollButtonText);
  }, [handleApplication]);

  if (flags.enrollmentDisabled) return <div className={styles.informationText}>{meta.ENROLL_BUTTON_TEXT || t('Upcoming')}</div>;

  if (!preview && providerMeta && !providerHasAccess) {
    if (providerPendingAccess) {
      return <InformationMessage>{t('provider-application-pending', { provider: providerMeta.name })}</InformationMessage>;
    }

    if (providerMeta.provider === ProviderList.COURSERA) {
      return (
        <>
          <ButtonList>
            <Button
              $type="primary"
              $icon="lock"
              $loading={handleProviderLoading}
              onClick={() => handleProvider({ variables: { currentRealm: window.realm, provider: providerMeta.provider } })}
            >
              {t('request-access')}
            </Button>
          </ButtonList>
          <InformationMessage pl>{t('provider-application-required') + providerMeta.name}</InformationMessage>
        </>
      );
    }

    return (
      <div className="message is-warning">
        <div className="message-body">
          {t('provider-application-required')}
          <a href={`/learning/provider/${providerMeta.provider}`}>{providerMeta.name}</a>
        </div>
      </div>
    );
  }

  if (hasAccessToCourse) {
    return (
      <ButtonList>
        <Button $type="primary" $loading={isLoading} $icon="pen" disabled={isDisabled} onClick={onEnrollClick}>
          {enrollButtonText}
        </Button>
      </ButtonList>
    );
  }

  return null;
};
