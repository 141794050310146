import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { OnboardingStep, StepComponent } from '../types';
import { Checkbox } from '../styles';

export const LFTerms: StepComponent = ({ onReady, onSave }) => {
  const { t } = useTranslation('onboarding');
  const [understand, setUnderstand] = useState(false);

  useEffect(() => onReady(OnboardingStep.LFTerms, understand), [understand, onReady]);

  onSave(
    OnboardingStep.LFTerms,
    useCallback(async () => undefined, [])
  );

  return (
    <>
      <Header1>{t('Before we begin')}</Header1>
      <BodyLg>{t('Be aware that you are now registering to a learning platform approved but not operated by LFAB')}</BodyLg>
      <hr />
      <Checkbox
        id="understand"
        checked={understand}
        onChange={() => setUnderstand((v) => !v)}
        size="large"
        label={<Trans t={t} i18nKey="I understand" />}
      />
    </>
  );
};
