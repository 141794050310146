import { BuddyTheme } from '../types';
import { typography } from './typography';
import { colors } from './colors';
import { tones } from './tones';
import { sizes } from './sizes';
import { shadow } from './elevation';
import { breakpoints } from './breakpoints';
import { gutter } from './gutter';
import { columns } from './columns';
import { transitions } from './transitions';

export const defaultTheme: BuddyTheme = {
  colors,
  tones,
  typography,
  shadow,
  sizes,
  breakpoints,
  spacing: (size) => size * 4,
  gutter,
  columns,
  transitions,
};
