import styled from 'styled-components';

import { Button, IconButton } from '@/ui/Button';

export const ModalContent = styled.div`
  width: 100%;
`;
export const ModalSection = styled.div`
  padding: 1rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  ${(p) => p.theme.typography.body3}
  color: ${(p) => p.theme.colors.primary.main}
`;

export const Section = styled.div`
  padding-bottom: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  ${(p) => p.theme.typography.overline}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const FeaturedItemsFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterButton = styled(Button)`
  margin: 0.25rem;
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  ${(p) => p.theme.typography.body3}
  color: ${(p) => p.theme.colors.neutral.outline2}
`;

export const UpButton = styled(IconButton)`
  margin: 6px 0px 6px 6px;
`;

export const DownButton = styled(IconButton)`
  margin: 6px 6px 6px 0px;
`;

export const DeleteButton = styled(IconButton)`
  margin: 6px;
`;
