import { useMemo } from 'react';

import { MultiKeyMap } from './multi-key-map';

type ArrayElement<ArrayType extends unknown[]> = ArrayType extends (infer ElementType)[] ? ElementType : never;

type Predicate<T, K extends any[]> = (arg: T) => ArrayElement<K>;

/**
 * Group list by multiple keys
 * @param list
 * @param predicates
 */
export function useMultiKeyDict<T extends any[], K extends any[]>(
  list: T,
  predicates: Predicate<ArrayElement<T>, K>[]
): MultiKeyMap<ArrayElement<T>, K> {
  return useMemo(() => {
    const map = new MultiKeyMap<ArrayElement<T>, ArrayElement<K>[]>();

    list.forEach((listEntry) => {
      const keys = predicates.map((predicate) => predicate(listEntry));

      map.set(keys, listEntry);
    });

    return map;
  }, [list, ...predicates]);
}
