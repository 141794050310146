import React from 'react';

import { Modal } from '@/ui/Modal';
import { SuccessfulState } from './SuccessfulState';
import { SubmittedContentProps } from './types';

export const SubmittedContent = ({ effort, onModalClose, customText }: SubmittedContentProps): JSX.Element => (
  <>
    <Modal.Contents>
      <SuccessfulState effort={effort} customText={customText} />
    </Modal.Contents>
    <Modal.Actions>
      <Modal.Action action={onModalClose}>Close</Modal.Action>
    </Modal.Actions>
  </>
);
