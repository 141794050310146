import React from 'react';

import { AutoRow } from './AutoRow';
import { TableDeclaration } from '../types';
import { ExpandingMeta } from '@/components/Table/hooks/useExpanding';
import { SelectionMeta } from '@/components/Table/hooks/useSelection';
import { PaginationMeta } from '@/components/Table/hooks/usePagination';

interface Props<T> {
  tableId?: string;
  keyProp: keyof T;
  tableDecl: TableDeclaration<T>;
  items: T[];
  colSpan: number;
  expanding: ExpandingMeta<T>;
  selection: SelectionMeta<T>;
  pagination: PaginationMeta<T>;
}

export const AutoRows = <T extends unknown>({
  tableId,
  tableDecl,
  keyProp,
  colSpan,
  items,
  pagination,
  expanding,
  selection,
}: Props<T>): React.ReactElement => (
  <>
    {items.map((item, index) => {
      const key = `${item[keyProp]}-${index}`;
      const isFirst = pagination.page === 1 && index === 0;
      const isLast = pagination.page === pagination.totalPages && index === items.length - 1;

      return (
        <AutoRow<T>
          key={tableId + key}
          id={tableId + key}
          keyProp={keyProp}
          item={item}
          colSpan={colSpan}
          isFirst={isFirst}
          isLast={isLast}
          tableDecl={tableDecl}
          isSelectionEnabled={selection?.isEnabled}
          selected={selection?.isSelected(item) || false}
          onSelected={selection?.toggleSelectedItem}
          expanding={expanding}
        />
      );
    })}
  </>
);
