import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './discussions.scss';
import { ReflectionComments } from './ReflectionComments';
import { toHtml } from '../customEditor/logic/serialization';
import { Post } from '../../types/post';
import { useDeletePost, useUpdatePost } from '../../store/discussion';
import { CustomEditor } from '../customEditor/CustomEditor';
import { ReflectionActions } from './ReflectionActions';
import {
  datetimeAsLocalDate,
  isValidTimestampFormat,
  localToUtcISOString,
  utcToLocalDateTimeDisplayFormat,
} from '../../utils/time';
import { Icon } from '@/components/Icon';
import { AutoComplete } from '../../components/Form/types';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';

interface Props {
  path: string[];
  isLearnifier?: boolean;
  selected: boolean;
  canEdit?: boolean;
  reflection: Post;
  onSentPost?: () => void;
  onDeletePost?: () => void;
  expandable?: boolean;
}

export interface Action {
  type: 'startEditing' | 'toggleDropdown' | 'reset';
}

interface State {
  isActiveDropdown: boolean;
  isEditing: boolean;
}

export const Reflection = ({
  canEdit,
  selected,
  path,
  isLearnifier = false,
  reflection: {
    title: initTitle,
    publishDate: initPublishDate,
    content: initContent,
    id,
    permissions: { canViewReflectionReplies },
  },
  onSentPost,
  onDeletePost,
  expandable,
}: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [title, setTitle] = useState(initTitle);
  const [content, setContent] = useState(initContent);
  const [deleted, setDeleted] = useState(false);
  const formattedPublishDate = utcToLocalDateTimeDisplayFormat(initPublishDate);
  const [publishDate, setPublishDate] = useState(initPublishDate ? formattedPublishDate : '');
  const [error, setError] = useState('');

  const { updatePostAction: updateReflectionAction, loading: updateInProgress } = useUpdatePost();
  const deleteReflectionAction = useDeletePost();

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'toggleDropdown':
          return { ...state, isActiveDropdown: !state.isActiveDropdown };
        case 'startEditing':
          return { isEditing: true, isActiveDropdown: false };
        case 'reset':
          return { isEditing: false, isActiveDropdown: false };
        default:
          return state;
      }
    },
    {
      isActiveDropdown: false,
      isEditing: false,
    }
  );

  const editReflection = async () => {
    setError('');

    if (content && title) {
      let utcTimestamp;
      if (publishDate) {
        if (!isValidTimestampFormat(publishDate)) {
          setError(t('Invalid time format: YYYY-MM-DD HH:mm'));
          return;
        }
        const localDate = datetimeAsLocalDate(publishDate);
        utcTimestamp = localToUtcISOString(localDate);
      }
      await updateReflectionAction(path, id, { title, content, publishDate: utcTimestamp });
      dispatch({ type: 'reset' });
    }
  };

  const deleteReflection = async () => {
    await deleteReflectionAction(path, id);
    setDeleted(true);
    dispatch({ type: 'reset' });
  };

  if (deleted) {
    return (
      <div styleName="wrapper">
        <div styleName="question">
          <div styleName="header">{t('[deleted]')}</div>
        </div>
      </div>
    );
  }
  return (
    <div styleName="wrapper">
      <div styleName="question">
        {canEdit && initPublishDate && (
          <div styleName="header" title={'Only visible for moderators'}>
            {t(`Publishing: {{date}}`, { date: formattedPublishDate })}
            <Icon icon="info-circle" />
          </div>
        )}
        {!state.isEditing && (
          <>
            {title && <div styleName="title">{title}</div>}
            {content && (
              <div className="content body2" style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: toHtml(content) }} />
            )}
          </>
        )}
        {state.isEditing && (
          <>
            <input
              className="input"
              type="text"
              autoComplete={AutoComplete.on}
              placeholder={t('Title')}
              value={title}
              style={{ marginBottom: 5 }}
              onChange={(e) => setTitle(e.target.value)}
            />

            <CustomEditor
              className="textarea content is-radiusless"
              placeholder={t('Text (required)')}
              value={content}
              onChange={setContent}
              size="large"
            />

            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  autoComplete={AutoComplete.off}
                  pattern="\d{4}-\d{2}-\d{2} \d{2}:\d{2}"
                  placeholder={'YYYY-MM-DD HH:MM'}
                  style={{ width: '100%' }}
                  value={publishDate}
                  onChange={(e) => setPublishDate(e.target.value)}
                />
              </div>
            </div>
            {error && (
              <div className="grid-cell gc-12">
                <article className="message is-warning">
                  <div className="message-body">{error}</div>
                </article>
              </div>
            )}
            <ButtonList align="right">
              <Button $type="text" onClick={() => dispatch({ type: 'reset' })}>
                {t('Cancel')}
              </Button>
              <Button $loading={updateInProgress} onClick={editReflection}>
                {t('Update')}
              </Button>
            </ButtonList>
          </>
        )}

        {canEdit && (
          <ReflectionActions
            id={id}
            path={path}
            state={state}
            dispatch={dispatch}
            isLearnifier={isLearnifier}
            deleteReflection={deleteReflection}
          />
        )}
      </div>
      {selected && (
        <ReflectionComments
          forceLoadReplies={canViewReflectionReplies}
          path={path}
          postId={id}
          onSentPost={onSentPost}
          onDeletePost={onDeletePost}
          expandable={expandable}
        />
      )}
    </div>
  );
};
