import styled from 'styled-components';

import folderIcon from '@/assets/folder.svg';

export const StyledProvider = styled.div<{ isCollection?: boolean; isNewCourseCard?: boolean }>`
  align-items: center;
  background-color: var(--color-white);
  border: var(--border-style);
  border-radius: var(--radius-inner);
  box-shadow: var(--box-shadow-small);
  color: ${isNewCourseCard => (isNewCourseCard ? `#141D1E` : `var(--color-dusty-gray)`)};
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100px;

  ${({ isCollection }) =>
    isCollection &&
    `
      background-color: transparent;
      background-image: url(${folderIcon});
      background-repeat: no-repeat;
      background-size: contain;
      border: 0;
    `}
`;
