import React from 'react';

import { AssignmentCard as AssignmentCardType } from '@/store/assignments/myLearning/types';
import { AssignmentCard } from '@/components/AssignmentCard/AssignmentCard';
import { SortPriorityOrder } from '@/pages/user/MyLearning/components/constants';
import { sortOrder, sortStringOrder } from '@/store/utils';
import { trackHomepageAssignmentCardClicked } from '@/utils/tracking/homePage';

export const generateAssingmentCourseCards = (assignmentCards: AssignmentCardType[]): JSX.Element[] | undefined => {
  return assignmentCards
    .map((m) => {
      if (m.status === 'ongoing') {
        return {
          card: m,
          priority: m.type === 'STRATEGIC' ? SortPriorityOrder.StrategicOngoing : SortPriorityOrder.StandardOngoing,
          remaining:
            m.type === 'STRATEGIC' ? m.effortThreshold - m.estimatedEffort : m.learningItemsCount - m.learningItemsCompleted,
        };
      } else if (m.status === 'completed') {
        return {
          card: m,
          priority: m.type === 'STRATEGIC' ? SortPriorityOrder.StrategicCompleted : SortPriorityOrder.StandardCompleted,
        };
      } else if (m.status === 'ended') {
        return {
          card: m,
          priority: SortPriorityOrder.StrategicEnded,
        };
      } else {
        return {
          card: m,
          priority: m.type === 'STRATEGIC' ? SortPriorityOrder.StrategicNotStarted : SortPriorityOrder.StandardNotStarted,
        };
      }
    })
    .sort(
      (m, o) =>
        sortOrder(m.priority, o.priority, true) ||
        sortOrder(m.remaining, o.remaining, false) ||
        sortStringOrder(m.card.name, o.card.name)
    )

    .map((x, i) => {
      return (
        <AssignmentCard
          key={x.card.id}
          card={x.card}
          stagger={i}
          onClick={() => trackHomepageAssignmentCardClicked(x.card.name)}
        />
      );
    });
};
