import styled from 'styled-components';

import { ImageWithLoader } from '@/ui/ImageWithLoader/ImageWithLoader';

export const PageStyled = styled.div`
  min-height: 100%;
  height: 100%;
  align-self: stretch;
`;
export const PageBody = styled.div`
  background: ${({ theme }) => theme.tones.neutral[100]};
  border-radius: 20px;
  margin: 8px;

  min-height: calc(100% - 16px);

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InnerContainer = styled.div`
  display: flex;
  width: 920px;
  height: 440px;
  align-items: flex-start;
  gap: 40px;
`;
export const ColumnStyled = styled.div`
  width: 50%;
  height: 100%;
`;
export const ImageStyled = styled(ImageWithLoader)`
  height: 100%;
  img {
    object-fit: cover;
    border-radius: 12px;
  }
`;
export const TextButtonContainerStyled = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
`;
export const TextContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const TitleStyled = styled.span`
  ${(p) => p.theme.typography.title1}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;
export const MetaContainer = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
export const DescriptionStyled = styled.div`
  ${(p) => p.theme.typography.subheadline}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;
