import { RLearningContentProvider, RLearningContentContentType, RLearningContentLocationData } from '@/store/v2';
export type LearningContent = {
  id: string;
  title: string;
  description: string;
  imageURL?: string;
  provider: RLearningContentProvider;
  plannedDuration?: number;
  plannedLearningsCount?: number;
  locations?: RLearningContentLocationData[];
  metadata?: {
    language?: string;
    level?: string;
    type?: RLearningContentContentType;
    timezone?: number;
  };
  curations: LearningCuration[];
};

export type LearningCuration = {
  id: string;
  spaceId: number;
  launchURLPath?: string;
  title?: string;
  description?: string;
  imageURL?: string;
  editURLPath?: string;
};

export const learningContentsURNV2 = (content?: LearningContent[]): string[] =>
  (content?.map((content) => content?.id).filter((x) => !!x) as string[]) || [];

export function learningContentDisplayMeta(
  content?: LearningContent,
  spaceId?: number
): { title?: string; imageUrl?: string; launchPath?: string; editURLPath?: string } {
  const curation = spaceId ? content?.curations?.find((curation) => curation.spaceId === spaceId) : content?.curations?.[0];

  return {
    title: curation?.title || content?.title || '',
    imageUrl: curation?.imageURL || content?.imageURL,
    launchPath: curation?.launchURLPath,
    editURLPath: curation?.editURLPath,
  };
}
