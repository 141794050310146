import { withRef } from '@udecode/cn';
import { HEADING_KEYS } from '@udecode/plate-heading';

import { Heading2PlateElement, Heading3PlateElement } from '@/component/customEditorV2/ui/Typography';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';

type HeadingVariant = typeof HEADING_KEYS.h1 | typeof HEADING_KEYS.h2;

const MAP_HEADING_TO_STYLED_COMPONENT: Record<HeadingVariant, typeof PlateElement> = {
  [HEADING_KEYS.h1]: Heading2PlateElement,
  [HEADING_KEYS.h2]: Heading3PlateElement,
};

export const HeadingElement = withRef<typeof PlateElement, { variant: HeadingVariant }>(
  ({ children, className, variant = HEADING_KEYS.h1, ...props }, ref) => {
    const Element = MAP_HEADING_TO_STYLED_COMPONENT[variant as HeadingVariant];

    return (
      <Element ref={ref} as={variant!} className={className} {...props}>
        {children}
      </Element>
    );
  }
);
