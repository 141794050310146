import React from 'react';
import { useTheme } from 'styled-components';

import { Container } from '../../atoms';
import { RoundLabelProps } from './types';

export const RoundLabel = ({ children, backgroundColor, className }: RoundLabelProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Container className={className} variant="round" backgroundColor={backgroundColor || theme.colors.neutral.outline2}>
      {children}
    </Container>
  );
};
