import React, { FC } from 'react';
import { Editor } from 'slate';

import { MarkSection } from './sections/MarkSection';
import { ElementSection } from './sections/ElementSection';
import { EditorConfig } from '@/component/customEditor/types';
import { ToolbarRight, ToolbarWrapper, Divider, StyledToolbarGroup } from './sections/styledComponents/Toolbar';
import { EditorAssistantToolbarButton } from '../EditorAssistant/EditorAssistantToolbarButton';

interface ToolbarProps {
  editor: Editor;
  hasChanged?: boolean;
  minimized?: boolean;
  config?: EditorConfig;
  isLinkPopoverOpen?: boolean;
  editorAssistantEnabled?: boolean;
  supportsImages?: boolean;
  setIsLinkPopoverOpen?: (isOpened: boolean) => void;
  spaceId?: number;
}

export const Toolbar: FC<ToolbarProps> = ({
  editor,
  hasChanged,
  config,
  minimized,
  isLinkPopoverOpen,
  setIsLinkPopoverOpen,
  editorAssistantEnabled,
  supportsImages,
  spaceId,
}) => {
  const renderHasChangedIndicator = () => {
    if (!hasChanged) return null;

    return <ToolbarRight>Changed</ToolbarRight>;
  };

  return (
    <ToolbarWrapper $minimized={minimized}>
      <StyledToolbarGroup>
        <MarkSection editor={editor} />
        <Divider minimized={minimized} />
        <ElementSection
          editor={editor}
          isLinkPopoverOpen={isLinkPopoverOpen}
          setIsLinkPopoverOpen={setIsLinkPopoverOpen}
          hideLink={config && config?.excludeBlocks.includes('link')}
          supportsImages={supportsImages}
          spaceId={spaceId}
        />
        {editorAssistantEnabled && (
          <>
            <Divider minimized={minimized} />
            <EditorAssistantToolbarButton />
          </>
        )}

        {renderHasChangedIndicator()}
      </StyledToolbarGroup>
    </ToolbarWrapper>
  );
};
