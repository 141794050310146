import styled from 'styled-components';

import { IconPropType } from '@/ui/Icon';
import { Icon } from '@/components/Icon';
import { IndeterminateCircularProgressBar } from '@/ui/ProgressBar';

export const positions = ['start', 'end', 'center'] as const;
export type IconPosition = typeof positions[number];

export type BaseButtonIconProps = {
  icon: IconPropType;
  $iconPosition?: IconPosition;
};

export const StyledIcon = styled(Icon)<BaseButtonIconProps>`
  margin: ${({ $iconPosition }) => ($iconPosition === 'end' ? '0 0 0 8px' : $iconPosition === 'start' ? '0 8px 0 0' : '0')};
`;

export const StyledIndeterminateCircularProgressBar = styled(IndeterminateCircularProgressBar)`
  margin: 0 8px 0 0;
`;
