import { Tones } from '../types';

export const tones: Tones = {
  primary: {
    [0]: '#000000',
    [10]: '#002115',
    [20]: '#003826',
    [30]: '#005139',
    [40]: '#006C4D',
    [50]: '#008862',
    [60]: '#00A578',
    [70]: '#0FC290',
    [80]: '#42DCA8',
    [90]: '#68FBC5',
    [95]: '#B9FFE0',
    [99]: '#F1FFF7',
    [100]: '#FFFFFF',
  },
  secondary: {
    [0]: '#000000',
    [10]: '#312B20',
    [20]: '#4B2800',
    [30]: '#6B3B00',
    [40]: '#8C4F00',
    [50]: '#AD6608',
    [60]: '#CC7F26',
    [70]: '#EB983E',
    [80]: '#FFB86F',
    [90]: '#FFDCBC',
    [95]: '#F9E6D3',
    [99]: '#FCFCFC',
    [100]: '#FFFFFF',
  },
  tertiary: {
    [0]: '#000000',
    [10]: '#001B3D',
    [20]: '#003064',
    [30]: '#00468C',
    [40]: '#255FA7',
    [50]: '#4378C2',
    [60]: '#5F92DD',
    [70]: '#7AACF9',
    [80]: '#A6C8FF',
    [90]: '#D4E3FF',
    [95]: '#EBF1FF',
    [99]: '#FDFCFF',
    [100]: '#FFFFFF',
  },
  error: {
    [0]: '#000000',
    [10]: '#410001',
    [20]: '#680003',
    [30]: '#930006',
    [40]: '#BA1B1B',
    [50]: '#DD3730',
    [60]: '#FF5449',
    [70]: '#FF897A',
    [80]: '#FFB4A9',
    [90]: '#FFDAD4',
    [95]: '#FFEDE9',
    [99]: '#FCFCFC',
    [100]: '#FFFFFF',
  },
  warning: {
    [0]: '#000000',
    [10]: '#271900',
    [20]: '#412D00',
    [30]: '#5E4200',
    [40]: '#7C5800',
    [50]: '#9B6F00',
    [60]: '#BC8700',
    [70]: '#DEA002',
    [80]: '#FDBB2D',
    [90]: '#FFDEA7',
    [95]: '#FFEED8',
    [99]: '#FFFBFF',
    [100]: '#FFFFFF',
  },
  success: {
    [0]: '#000000',
    [10]: '#002200',
    [20]: '#003A00',
    [30]: '#005300',
    [40]: '#006E01',
    [50]: '#008B01',
    [60]: '#1FA717',
    [70]: '#43C435',
    [80]: '#61E14F',
    [90]: '#7EFE68',
    [95]: '#CAFFB9',
    [99]: '#F7FFEE',
    [100]: '#FFFFFF',
  },
  neutral: {
    [0]: '#000000',
    [10]: '#141D1E',
    [20]: '#293233',
    [30]: '#3F494A',
    [40]: '#566061',
    [50]: '#6F797A',
    [60]: '#899393',
    [70]: '#A3ADAE',
    [80]: '#BEC8C8',
    [90]: '#CEDADA',
    [95]: '#E6ECEC',
    [99]: '#FAFCFC',
    [100]: '#FFFFFF',
  },
  neutralVariant: {
    [0]: '#000000',
    [10]: '#111616',
    [20]: '#222C2C',
    [30]: '#334343',
    [40]: '#435959',
    [50]: '#546F6F',
    [60]: '#658585',
    [70]: '#7F9999',
    [80]: '#98AEAE',
    [90]: '#DCE5DE',
    [95]: '#EAF3EC',
    [99]: '#F5FEF7',
    [100]: '#FFFFFF',
  },
};
