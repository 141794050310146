import React from 'react';
import { Composition } from 'atomic-layout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Split } from '@/components/Layout/Split';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { CourseCard } from '@/components/CourseCardLegacy/CourseCard';
import { Header4 } from '@/components/Typography/Typography';
import { Card, LearningCard } from '@/types/learning/card';
import { isEvent } from '@/hooks/useFilterPastEvents';
import { Tag } from '@/components/Tag/Tag';
import { renderCardPlaceholders } from '@/components/CardGrid/CardGrid';
import { cvar, styleConfig } from '@/styles/GlobalStyle';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { sortingMethods } from '@/util';
import { Section } from '@/components/Section/Section';

interface UpcomingEventsProps {
  loading: boolean;
  cards: Card[];
}

const MAX_EVENTS = 4;

export const UpcomingEvents = ({ cards, loading }: UpcomingEventsProps): JSX.Element | null => {
  const { t } = useTranslation(['catalog', 'myLearning']);
  const { category, subject } = useParams<{ category?: string; subject?: string }>();
  const paramsRecord: { category?: string; subject?: string } = {};
  if (category) paramsRecord.category = category;
  if (subject) paramsRecord.subject = subject;
  const params = new URLSearchParams(paramsRecord);
  const events = (cards as LearningCard[]).filter((c) => isEvent(c)).sort(sortingMethods.DATE_ASC);
  const filteredEvents =
    paramsRecord.subject || paramsRecord.category
      ? events.filter(({ subjects }) =>
          subjects.some((s) =>
            paramsRecord.subject
              ? s.subjectId === parseInt(paramsRecord.subject, 10)
              : s.categoryId === parseInt(paramsRecord.category || '', 10)
          )
        )
      : events;

  if (filteredEvents.length === 0) return null;

  const eventsToRender = filteredEvents.slice(0, MAX_EVENTS);
  const showBack = Boolean(category || subject);

  return (
    <Section $bordered>
      <Split>
        <Header4 unpadded style={{ whiteSpace: 'nowrap', lineHeight: `${BUTTON_HEIGHT_ENUM.default}rem` }}>
          {t('Upcoming events')}
          <Tag style={{ marginLeft: cvar('gap-xs') }}>{filteredEvents.length}</Tag>
        </Header4>
        {filteredEvents.length > 4 && (
          <RouterLinkButton
            to={{ pathname: '/learning/events' }}
            $type="primary"
            state={{ state: showBack, search: params.toString() }}
          >
            {t('myLearning::View all')}
          </RouterLinkButton>
        )}
      </Split>
      <Composition
        templateCols="minmax(0, 1fr)"
        templateColsMd="repeat(2, minmax(0, 1fr))"
        templateColsLg="repeat(3, minmax(0, 1fr))"
        templateColsXl="repeat(4, minmax(0, 1fr))"
        gap={0.875}
        gapMd={1.75}
        paddingTop={styleConfig.gap.md}
      >
        {loading
          ? renderCardPlaceholders(MAX_EVENTS)
          : eventsToRender.map((card) => (
              <CourseCard
                key={card.id}
                {...{
                  card,
                }}
              />
            )) || <p>{t('no-learning-material')}</p>}
      </Composition>
    </Section>
  );
};
