import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@/administration/component/FormInput/TextArea';
import { AutoComplete } from '@/components/Form/types';

type TitleSectionProps = {
  title: string;
  setTitle: (title: string) => void;
};

export const TitleSection: FC<TitleSectionProps> = ({ title, setTitle }) => {
  const { t } = useTranslation('common');
  return (
    <div className="field has-addons has-addons--mobile is-floating-label">
      <div className="control is-expanded">
        <div className="field">
          <TextArea
            autoComplete={AutoComplete.off}
            value={title}
            onChange={e => setTitle(e.currentTarget.value)}
            label={t('Title')}
            required
            rows={2}
          />
        </div>
      </div>
    </div>
  );
};
