import { v4 as uuid } from 'uuid';

import { EditTextPage } from '../../text';
import { Page, PageVariantDeprecated as PageVariant } from '@/administration/pages/Course/store/page/types';
import {
  TextBlock,
  QuizBlock,
  PracticalAssessmentTextBlock,
  PracticalAssessmentDocumentBlock,
  ReflectionBlock,
} from '@/administration/pages/Course/store/block/types';
import { EditQuizPage } from '@/administration/pages/Course/pages/quiz/View/EditQuizPage/EditQuizPage';
import { EditPracticalAssessmentTextPage } from '../../assessments/practicalAssessmentText';
import { EditPracticalAssessmentDocumentPage } from '../../assessments/practicalAssessmentDocument';
import { EditReflectionPage } from '../../reflection';

export const EditPageContent = ({
  spaceId,
  page,
  onUpdatePage,
}: {
  spaceId: number;
  page: Page;
  onUpdatePage: (updateQuery: { pageId: string; pageChanges: Partial<Page> }) => void;
}): JSX.Element => {
  const handleUpdatePage = (pageChanges: Partial<Page>) => {
    onUpdatePage({ pageId: page.id, pageChanges });
  };

  if (page.type === PageVariant.PRACTICAL_ASSESSMENT_TEXT) {
    const practicalAssessmentTextBlock = page.block as PracticalAssessmentTextBlock;

    return (
      <EditPracticalAssessmentTextPage
        id={page.id}
        spaceId={spaceId}
        description={practicalAssessmentTextBlock.description}
        title={page.title}
        peerReviewEnabled={practicalAssessmentTextBlock.peerReviewEnabled}
        onTitleChange={(title) => {
          handleUpdatePage({ title });
        }}
        onDescriptionChange={(description) => {
          handleUpdatePage({ block: { description } } as Partial<Page>);
        }}
        onPeerReviewEnabledChange={(peerReviewEnabled) => {
          handleUpdatePage({ block: { peerReviewEnabled } } as Partial<Page>);
        }}
      />
    );
  } else if (page.type === PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT) {
    const practicalAssessmentDocumentBlock = page.block as PracticalAssessmentDocumentBlock;

    return (
      <EditPracticalAssessmentDocumentPage
        id={page.id}
        spaceId={spaceId}
        description={practicalAssessmentDocumentBlock.description}
        title={page.title}
        peerReviewEnabled={practicalAssessmentDocumentBlock.peerReviewEnabled}
        onTitleChange={(title) => {
          handleUpdatePage({ title });
        }}
        onDescriptionChange={(description) => {
          handleUpdatePage({ block: { description } } as Partial<Page>);
        }}
        onPeerReviewEnabledChange={(peerReviewEnabled) => {
          handleUpdatePage({ block: { peerReviewEnabled } } as Partial<Page>);
        }}
      />
    );
  } else if (page.type === PageVariant.REFLECTION) {
    const block = page.block as ReflectionBlock;

    return (
      <EditReflectionPage
        id={page.id}
        spaceId={spaceId}
        description={block.description}
        onDescriptionChange={(description) => handleUpdatePage({ block: { description } } as Partial<Page>)}
      />
    );
  } else if (page.type === PageVariant.QUIZ) {
    const block = page.block as QuizBlock;

    return (
      <EditQuizPage
        id={page.id}
        spaceId={spaceId}
        text={block.text}
        onTextChange={(text, questions) => {
          handleUpdatePage({ block: { text, questions, version: uuid() } } as unknown as Partial<Page>);
        }}
      />
    );
  } else {
    const textBlock = page.block as TextBlock;
    return (
      <EditTextPage
        id={page.id}
        spaceId={spaceId}
        text={textBlock.text}
        onTextChange={(text) => {
          handleUpdatePage({ block: { text } } as Partial<Page>);
        }}
      />
    );
  }
};
