import React from 'react';
import clsx from 'clsx';

import { toHtml } from '../../../../../component/customEditor/logic/serialization';
import { TextMeta } from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';

interface Props {
  data: TextMeta;
  isPreamble?: boolean;
}

export const TextBlock = ({ data, isPreamble = false }: Props): JSX.Element => {
  return (
    <div
      className={clsx('content', 'is-article', isPreamble ? 'preamble' : 'body1')}
      dangerouslySetInnerHTML={{ __html: toHtml(data.text) }}
    />
  );
};
