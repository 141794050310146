import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { isRealm } from '@/utils/realms';
import { cvar } from '@/styles/GlobalStyle';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { LinkButton } from '@/components/Button/LinkButton';
import { Box } from '@/components/Box/Box';
import { supportEmails, supportUrl } from '@/config';
import { Hero, Section } from '@/components/Section';

const MessageParagraph = styled.p`
  color: ${cvar('color-dark-gray')};
  margin-bottom: 40px !important;
`;

const LinkButtonSpan = styled.span`
  font-size: 14px;
`;

interface LocationState {
  isArchived?: boolean;
}

const Page404 = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const location = useLocation();

  const { isArchived } = location.state ? (location.state as LocationState) : { isArchived: false };

  const goToHomePage = () => {
    navigate('/');
  };

  return (
    <>
      <Hero>
        <div className="hero-body">
          <div className="container">
            <ButtonList padded="bottom">
              <Button onClick={() => navigate(-1)} $type="text">
                &larr; {t('Back')}
              </Button>
            </ButtonList>
            <h1 className="header1">{isArchived ? t('404-page-archived') : t('404-page-not-found')}</h1>
          </div>
        </div>
      </Hero>
      <Section>
        <div className="container">
          <div className="content">
            <Box boxShadow alignCenter>
              <MessageParagraph>
                <Trans i18nKey={isArchived ? '404-archived-message' : '404-message'} t={t}>
                  Something went wrong. You can go
                  <LinkButton $type="text" onClick={() => navigate(-1)}>
                    <LinkButtonSpan>Back</LinkButtonSpan>
                  </LinkButton>
                  , or contact
                  {isRealm('seb') ? (
                    <LinkButton $type="text" target="_blank" href={supportUrl.seb}>
                      <LinkButtonSpan>Support</LinkButtonSpan>
                    </LinkButton>
                  ) : (
                    <LinkButton
                      $type="text"
                      onClick={() => {
                        window.location.href = `mailto:${supportEmails.collegial}`;
                        return false;
                      }}
                    >
                      <LinkButtonSpan>Support</LinkButtonSpan>
                    </LinkButton>
                  )}
                  . Go to the start page.
                </Trans>
              </MessageParagraph>
              <Button $type="secondary" onClick={goToHomePage}>
                {t('404-go-to-start-page')}
              </Button>
            </Box>
          </div>
        </div>
      </Section>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Page404;
