import { gql, useQuery } from '@apollo/client';

import { SelfTrackedLearningsPage, UseSelfTrackedLearningsHook } from './types';

export const SELF_TRACKED_LEARNINGS_QUERY_OPERATION_NAME = `selfTrackedLearnings`;
export const SELF_TRACKED_LEARNINGS_QUERY = gql`
  query ${SELF_TRACKED_LEARNINGS_QUERY_OPERATION_NAME}($idIN: [Int!], $pagination: Pagination) {
    selfTrackedLearnings(filter: { idIN: $idIN}, pagination: $pagination) {
      data {
        id
        type
        title
        description
        effort
        link
      }
      limit
      page
      total
    }
  }
`;

export const useSelfTrackedLearnings: UseSelfTrackedLearningsHook = ({ ids }) => {
  const { data, loading, error, refetch, networkStatus } = useQuery<SelfTrackedLearningsPage>(SELF_TRACKED_LEARNINGS_QUERY, {
    skip: !ids,
    variables: {
      idIN: ids,
      pagination: {
        limit: -1,
        page: 1,
      },
    },
  });

  return {
    data: data?.selfTrackedLearnings.data ?? [],
    loading,
    error,
    refetch,
    limit: data?.selfTrackedLearnings.limit ?? 0,
    page: data?.selfTrackedLearnings.page ?? 0,
    total: data?.selfTrackedLearnings.total ?? 0,
    networkStatus,
  };
};
