import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { AutoComplete } from '@/components/Form/types';
import { StyledError } from '@/components/Form/styles';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { MultiSelectRows } from './components/MultiSelectRows';
import { InputBasic } from '../Input/InputBasic';
import { StyledDropdownWrapper } from '@/components/Dropdown/StyledDropdownWrapper';
import { StyledDropdownMultiSelect } from './styles';
import { useDropdown } from '@/hooks/useDropdown';
import { MultiSelectProps, MultiSelectOption } from './types';

export function MultiSelect({
  label,
  name,
  placeholder,
  sort,
  multiple = false,
  limit,
  icon = 'chevron-down',
  disabled,
  required,
  loading,
  hiddenLabel,
  options,
  defaultSelectOnload,
  showValues,
  valueAsNumber,
  fullwidth,
  newTheme,
  handleSideEffect,
  searchString: searchStringControlled,
  setSearchString: setSearchStringControlled
}: MultiSelectProps): JSX.Element {
  const { t } = useTranslation('common');
  const { containerRef, open, close, isOpen } = useDropdown<HTMLDivElement>();
  const [hasFocus, setHasFocus] = useState(false);
  const [searchStringInternal, setSearchStringInternal] = useState('');
  const [localOptions, setLocalOptions] = useState<MultiSelectOption[]>([]);
  const { formState, watch, setValue, register } = useFormContext();
  const watched = watch(name);
  const isMultiple = limit === 1 ? false : multiple;
  const error = formState.errors[name];

  const searchString = !!setSearchStringControlled ? searchStringControlled : searchStringInternal;
  const setSearchString = setSearchStringControlled || setSearchStringInternal;

  useEffect(() => {
    hasFocus ? open() : close();
  }, [hasFocus]);

  useEffect(() => {
    if (defaultSelectOnload && options.length > 0) {
      let index;
      const firstOption = options.find((opt, i) => {
        index = i;
        return !opt.isHeader || !opt.isSubHeader;
      });
      setValue(isMultiple ? `${name}[${index}]` : name, (firstOption as MultiSelectOption).value);
    }

    const opts = options.slice();
    if (sort) opts.sort((a, b) => a.label.localeCompare(b.label));
    setLocalOptions(opts);
  }, [options]);

  useEffect(() => {
    if (!isMultiple) register(name, { required, valueAsNumber });
  }, [register, isMultiple]);

  useOnClickOutside(hasFocus, setHasFocus, containerRef);
  useOnEscapePress(blur, [blur]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => setSearchString(e.currentTarget.value);

  function blur(e?: KeyboardEvent) {
    if (e instanceof KeyboardEvent && e.currentTarget instanceof HTMLElement) e.currentTarget?.blur();
    setHasFocus(false);
    setSearchString('');
  }

  let inputValue = '';
  if (hasFocus) {
    inputValue = searchString || '';
  } else if (isMultiple && watched && !showValues) {
    if (limit) {
      inputValue = t('Selected {{count}}/{{max}} (of {{total}})', {
        count: watched.filter(Boolean).length,
        max: limit,
        total: options.filter(o => !o.unselectable).length
      });
    } else {
      inputValue = t('Selected {{count}}/{{total}}', {
        count: watched.filter(Boolean).length,
        total: options.filter(o => !o.unselectable).length
      });
    }
  } else if (isMultiple && watched && showValues) {
    inputValue = watched.filter(Boolean).join(', ');
  } else {
    inputValue =
      localOptions.find(o => (isMultiple ? watched?.some((s: string | number) => o.value === s) : watched === o.value))?.label ||
      '';
  }

  const multiSelectRows = (
    <MultiSelectRows
      {...{ searchString, isMultiple, limit, localOptions, valueAsNumber, name, handleSideEffect }}
      onSingleSelect={blur}
    />
  );

  return (
    <StyledDropdownWrapper ref={containerRef} $fullwidth={fullwidth}>
      <InputBasic
        disabled={disabled}
        autoComplete={AutoComplete.on}
        label={label}
        hiddenLabel={hiddenLabel}
        icon={icon}
        rotatedIcon={isOpen}
        loading={loading}
        required={required}
        placeholder={placeholder}
        aria-required={required ? 'true' : 'false'}
        value={inputValue}
        onFocus={() => !disabled && setHasFocus(true)}
        onChange={handleInput}
        overline={true}
        newTheme={newTheme}
        onIconClick={() => !disabled && setHasFocus(!isOpen)}
        type="text"
        cursor="pointer"
      />
      {multiSelectRows && (
        <StyledDropdownMultiSelect open={isOpen} role="listbox" data-testid="listbox" aria-label="MultiSelectDropdown">
          {multiSelectRows}
        </StyledDropdownMultiSelect>
      )}
      {error && <StyledError>{error.message}</StyledError>}
    </StyledDropdownWrapper>
  );
}
