import { gql, FetchResult, useMutation } from '@apollo/client';

import { ASSIGNMENTS_QUERY_OPERATION_NAME } from './constants';
import { AssignmentsV3Result } from './types';

export const DELETE_ASSIGNMENT_MUTATION = gql`
  mutation deleteAssignmentV3($id: Int!) {
    deleteAssignmentV3(id: $id) {
      success
    }
  }
`;

export const useDeleteAssignment = (): {
  deleteAssignment: (id: number) => Promise<FetchResult<{ deleteAssignmentV3: AssignmentsV3Result }>>;
  loading: boolean;
} => {
  const [deleteAssignment, { loading }] = useMutation<{ deleteAssignmentV3: AssignmentsV3Result }>(DELETE_ASSIGNMENT_MUTATION);

  return {
    deleteAssignment: async (id: number) =>
      deleteAssignment({
        variables: { id },
        refetchQueries: [ASSIGNMENTS_QUERY_OPERATION_NAME],
      }),
    loading,
  };
};
