import styled from 'styled-components';

export { HeroDescription as Description } from '@/pages/Assignment/components';

export const ContentType = styled.span`
  ${(p) => p.theme.typography.overline}
  color: ${(p) => p.theme.colors.neutral.onContainer};
`;

export const Title = styled.span`
  ${(p) => p.theme.typography.title2}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;
  gap: 16px;
`;
