/* eslint-disable import/no-unused-modules */
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import { Modal } from '@/ui/Modal';
import { convertTitle } from '@/utils/misc';

type LockedLearningWarningModalProps = {
  open: boolean;
  onClose: () => void;
  parentJourneyId?: number;
  parentJourneyTitle?: string;
};

export const LockedLearningWarningModal = ({
  open,
  onClose,
  parentJourneyId,
  parentJourneyTitle,
}: LockedLearningWarningModalProps): JSX.Element | null => {
  const navigate = useNavigate();

  const handleGoToJourney = () => {
    navigate(`/journey/${parentJourneyId}/${convertTitle(parentJourneyTitle)}`);
  };

  if (!parentJourneyId || !parentJourneyTitle) return null;

  return (
    <Modal open={open} onClose={onClose} size="mobile">
      <Modal.Title>Part of a journey</Modal.Title>
      <Modal.Contents>
        This item is part of one of your learning journeys.
        <br />
        Go to journey to get the full experience.
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>stay on page</Modal.Action>
        <Modal.Action
          action={() => {
            handleGoToJourney();
          }}
          primary
        >
          Go to journey
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};

export const useLockedLearningWarningModalProps = ({
  isCurrentItemLock,
  parentJourneyId,
  parentJourneyTitle,
}: {
  isCurrentItemLock?: boolean;
  parentJourneyId?: number;
  parentJourneyTitle?: string;
}): LockedLearningWarningModalProps | null => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const shouldOpenModal = isCurrentItemLock && parentJourneyId && parentJourneyTitle;
    if (shouldOpenModal) {
      setOpen(true);
    }
  }, [isCurrentItemLock]);

  return {
    open,
    onClose: handleClose,
    parentJourneyId,
    parentJourneyTitle,
  };
};
