import { withRef } from '@udecode/cn';
import { PlateEditor, ParagraphPlugin } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { useContext, useMemo } from 'react';

import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { IconPropType } from '@/ui/Icon/types';
import { Icon } from '@/ui/Icon';
import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxGroup,
  InlineComboboxInput,
  InlineComboboxItem,
} from '@/component/customEditorV2/ui/elements/InlineCombobox';
import { VideoPlugin } from '@/component/customEditorV2/plugins/custom/videoPlugin';
import { DocumentPlugin } from '@/component/customEditorV2/plugins/custom/documentPlugin';
import { ImagePlugin } from '@/component/customEditorV2/plugins/custom/imagePlugin';
import { insertBlock } from '@/component/customEditorV2/ui/elements/SlashInputElement/transforms';
import { QuizQuestionPlugin } from '@/component/customEditorV2/plugins/custom/quizQuestionPlugin';
import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';

type Group = {
  group: string;
  items: Item[];
};

interface Item {
  icon: IconPropType;

  onSelect: (editor: PlateEditor, value: string) => void;

  value: string;
  className?: string;
  focusEditor?: boolean;
  keywords?: string[];
  label?: string;
}

const getGroups = ({ withQuestions }: { withQuestions?: boolean }): Group[] => {
  return [
    {
      group: '', // for future (possible grouping, when we get more elements)
      items: [
        {
          icon: ['far', 'h1'] as IconPropType,
          keywords: ['heading', 'title', 'h1'],
          label: 'Heading 1',
          value: HEADING_KEYS.h1,
        },
        {
          icon: ['far', 'h2'] as IconPropType,
          keywords: ['heading', 'subtitle', 'h2', 'hh'],
          label: 'Heading 2',
          value: HEADING_KEYS.h2,
        },
        {
          icon: ['far', 'text'] as IconPropType,
          keywords: ['regular text', 'p', 'paragraph'],
          label: 'Regular text',
          value: ParagraphPlugin.key,
        },
        {
          icon: ['far', 'list'] as IconPropType,
          keywords: ['list', 'bulleted', 'unordered', 'ul', '-', '*'],
          label: 'Bulleted list',
          value: BulletedListPlugin.key,
        },
        {
          icon: ['far', 'list-ol'] as IconPropType,
          keywords: ['list', 'numbered', 'ordered', 'ol', '1'],
          label: 'Numbered list',
          value: NumberedListPlugin.key,
        },
        {
          icon: ['far', 'image'] as IconPropType,
          keywords: ['image', 'img', 'media'],
          label: 'Image',
          value: ImagePlugin.key,
        },
        {
          icon: ['far', 'file'] as IconPropType,
          keywords: ['media', 'file', 'document', 'pdf', 'pptx', 'embedded'],
          label: 'Document',
          value: DocumentPlugin.key,
        },
        {
          icon: ['far', 'video'] as IconPropType,
          keywords: ['video', 'media'],
          label: 'Video',
          value: VideoPlugin.key,
        },
        ...(withQuestions
          ? [
              {
                icon: ['far', 'square-question'] as IconPropType,
                keywords: ['question', 'quiz'],
                label: 'Question',
                value: QuizQuestionPlugin.key,
              },
            ]
          : []),
      ].map((item) => ({
        ...item,
        onSelect: (editor, value) => {
          insertBlock(editor, value);
        },
      })),
    },
  ];
};

export const SlashInputElement = withRef<typeof PlateElement>(({ className, ...props }, ref) => {
  const { children, editor, element } = props;
  const { toggledFeatures } = useContext(CustomEditorContext);

  const groups = useMemo(() => {
    return getGroups({ withQuestions: toggledFeatures?.withQuestions });
  }, [toggledFeatures]);

  return (
    <PlateElement ref={ref} as="span" data-slate-value={element.value} {...props}>
      <InlineCombobox element={element} trigger="/">
        <InlineComboboxInput />

        <InlineComboboxContent>
          <InlineComboboxEmpty>No results</InlineComboboxEmpty>

          {groups.map(({ group, items }) => (
            <InlineComboboxGroup key={group}>
              {items.map(({ focusEditor, icon, keywords, label, value, onSelect }) => (
                <InlineComboboxItem
                  key={value}
                  value={value}
                  onClick={() => onSelect(editor, value)}
                  label={label}
                  focusEditor={focusEditor}
                  group={group}
                  keywords={keywords}
                >
                  <Icon icon={icon} />
                  {label ?? value}
                </InlineComboboxItem>
              ))}
            </InlineComboboxGroup>
          ))}
        </InlineComboboxContent>
      </InlineCombobox>
      {children}
    </PlateElement>
  );
});
