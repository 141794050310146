import React from 'react';

import { Select } from '../..';
import { SelectProps } from '../../atoms/Select/types';
import { FilterChip } from '../../../Chip';
import { Variant } from '@/ui/Chip/atoms/Chip/types';

type FilterSelectProps = {
  /**
   * Placeholder will be displayed before any value is selected
   */
  placeholder: string;
  /**
   * Label to display when a value *is* selected. If none is provided,
   * the component will use the selected value(s) as its label.
   */
  label?: string;
  onTrailingIconClick?: () => void;
  variant?: Variant;
} & SelectProps;

export const FilterSelect = ({
  children,
  multiple,
  autoclose,
  value,
  onChange,
  placeholder,
  label,
  className,
  onTrailingIconClick,
  variant,
}: FilterSelectProps): JSX.Element => (
  <Select onChange={onChange} value={value} multiple={multiple} autoclose={autoclose} className={className}>
    <Select.Header>
      {({ toggleShowOptions, shouldShowOptions }) => (
        <FilterChip
          label={label || (Array.isArray(value) ? value.join(',') : value) || placeholder}
          selected={value.length > 0}
          onSelectedChange={toggleShowOptions}
          isDropdownOpen={shouldShowOptions}
          onTrailingIconClick={onTrailingIconClick}
          variant={variant}
        />
      )}
    </Select.Header>
    {children}
  </Select>
);
