import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { RadioButton } from '@/components/Radio/v2/Radio';
import { IconButton } from '@/ui/Button';

export const StyledRadioButton = styled(RadioButton)`
  margin: 0.25rem;
  z-index: 1;
`;

export const Container = styled.div`
  flex: 1;
  z-index: 1;
  height: 380px;
`;

export const ArrowIcon = styled(IconButton)`
  margin: 10px;
  opacity: 0;
  transition: opacity 800ms;
  z-index: 1;
`;

export const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  :hover ${ArrowIcon} {
    opacity: 1;
  }
`;

export const CarouselContentBox = styled.div<{ active: boolean }>`
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  position: absolute;
  transition: opacity 0.5s ease-out;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`;

export const EllipsisContainer = styled.div`
  align-self: flex-end;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
`;

export const EllipsisIcon = styled(IconButton)`
  color: ${(p) => p.theme.colors.neutral.background};
  text-shadow: ${(p) => p.theme.shadow.primary[32]};
  margin: 10px;
`;

export const StickyTitle = styled.div`
  ${(p) => p.theme.typography.title1};
  color: ${(p) => p.theme.colors.neutral.background};
`;

export const StickyTeaser = styled.div`
  ${(p) => p.theme.typography.body2};
  color: ${(p) => p.theme.colors.neutral.background};
`;

export const StickyHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  justify-content: center;
  margin: 0 60px 0 30px;

  @media screen and (min-width: 1200px) {
    margin: 0 120px 0 60px;
  }
`;

export const StickyContent = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 20px;
  color: ${(p) => p.theme.colors.neutral.background};
`;

export const StyledBackgroundImage = styled.div<{ background: string }>`
  background-color: #e6e9ec;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 100%;
  left: 0;
  min-height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  background: ${({ background }) => background};
`;

export const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
`;
