/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import type { TEditor } from '@udecode/plate-common';
import type { DropTargetMonitor } from 'react-dnd';
import { cn, withRef } from '@udecode/cn';
import { PlateElementProps, MemoizedChildren, useEditorPlugin, useEditorRef, withHOC } from '@udecode/plate-common/react';
import {
  DragItemNode,
  DraggableProvider,
  useDraggable,
  useDraggableGutter,
  useDraggableState,
  useDropLine,
} from '@udecode/plate-dnd';
import { BlockSelectionPlugin } from '@udecode/plate-selection/react';
import { useSelected } from 'slate-react';
import styled, { css } from 'styled-components';

import { useMounted } from '@/component/customEditorV2/ui/pluginsAddons/DragAndDrop/useMounted';
import { IconButton } from '@/ui/Button';
import { DraggableInsertHandle } from '@/component/customEditorV2/ui/elements/DraggableInsertHandler';
import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';

const DropLineStyled = styled.div<{ $isTopPosition: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  height: 0.125rem;
  opacity: 1;
  transition: opacity 150ms;
  background: ${({ theme }) => theme.colors.tertiary.outline};

  ${({ $isTopPosition }) =>
    $isTopPosition
      ? css`
          top: -1px;
        `
      : css`
          bottom: -1px;
        `}
`;

const DraggableStyled = styled.div<{ $isDragging: boolean }>`
  position: relative;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      opacity: 0.5;
    `}
`;

const DraggableContentStyled = styled.div<{ draggableInsertHandlerEnabled?: boolean }>`
  position: absolute;
  top: 12px;
  left: ${({ draggableInsertHandlerEnabled }) => (draggableInsertHandlerEnabled ? '-28px' : '-24px')};
`;

const InsertContentStyled = styled.div`
  position: absolute;
  top: 12px;
  left: -52px;
`;

const GutterStyled = styled.div<{ $isSelectionAreaVisible: boolean; $selected: boolean }>`
  position: absolute;
  top: -1px;
  z-index: 50;
  display: flex;
  height: 100%;
  transform: translateX(-100%);
  cursor: text;
  transition: opacity 150ms;
  opacity: 0;

  @media (min-width: 640px) {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }

  /* Assuming this is triggered by a group hover state */
  .group:hover & {
    opacity: 1;
  }

  ${({ $isSelectionAreaVisible }) =>
    $isSelectionAreaVisible &&
    css`
      display: none;
    `}

  ${({ $selected }) =>
    !$selected &&
    css`
      opacity: 0;
    `}
`;

const DragHandleStyled = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

export interface DraggableProps extends PlateElementProps {
  /**
   * Intercepts the drop handling. If false is returned, the default drop
   * behavior is called after. If `true` is returned, the default behavior is
   * not called.
   */
  onDropHandler?: (
    editor: TEditor,
    props: {
      id: string;
      dragItem: DragItemNode;
      monitor: DropTargetMonitor<DragItemNode, unknown>;
      nodeRef: any;
    }
  ) => boolean;
}

const Gutter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    const { useOption } = useEditorPlugin(BlockSelectionPlugin as any);
    const isSelectionAreaVisible = useOption('isSelectionAreaVisible');
    const gutter = useDraggableGutter();
    const selected = useSelected();

    return (
      <GutterStyled
        ref={ref}
        $selected={selected}
        $isSelectionAreaVisible={isSelectionAreaVisible}
        className={cn('slate-gutterLeft', className)}
        {...props}
        {...gutter.props}
      >
        {children}
      </GutterStyled>
    );
  }
);

const DragHandle = React.memo(() => {
  const editor = useEditorRef();

  return (
    <DragHandleStyled
      buttonVariant="buddySmall"
      icon="grip-dots-vertical"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      onMouseDown={() => {
        editor.getApi(BlockSelectionPlugin).blockSelection?.resetSelectedIds();
      }}
    />
  );
});

const DropLine = React.memo(
  React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ children, className, ...props }, ref) => {
    const state = useDropLine();

    if (!state.dropLine) return null;

    return (
      <DropLineStyled
        ref={ref}
        {...props}
        {...state.props}
        $isTopPosition={state.dropLine === 'top'}
        className={cn('slate-dropLine', className)}
      >
        {children}
      </DropLineStyled>
    );
  })
);

export const Draggable = withHOC(
  DraggableProvider,
  withRef<'div', DraggableProps>(({ className, onDropHandler, ...props }, ref) => {
    const { children, element } = props;
    const { toggledFeatures } = useContext(CustomEditorContext);

    const state = useDraggableState({ element, onDropHandler });
    const { isDragging } = state;
    const { previewRef, handleRef } = useDraggable(state);
    const mounted = useMounted();

    if (!toggledFeatures?.dragAndDrop) {
      return <>{children}</>;
    }

    return (
      <DraggableStyled ref={ref} $isDragging={isDragging} className={cn('group', className)}>
        <Gutter>
          {toggledFeatures?.draggableInsertHandler && (
            <InsertContentStyled>
              <DraggableInsertHandle />
            </InsertContentStyled>
          )}

          <DraggableContentStyled
            draggableInsertHandlerEnabled={toggledFeatures?.draggableInsertHandler}
            ref={handleRef}
            className="size-4"
            data-key={mounted ? (element.id as string) : undefined}
          >
            <DragHandle />
          </DraggableContentStyled>
        </Gutter>
        <div ref={previewRef} className="slate-blockWrapper" style={{ position: 'relative' }}>
          <MemoizedChildren>{children}</MemoizedChildren>
          <DropLine />
        </div>
      </DraggableStyled>
    );
  })
);
