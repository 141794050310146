import { ReactNode } from 'react';

import { Context } from './Context';

export const Tabs = ({
  activeTab,
  children,
  onActiveTabChange,
}: {
  activeTab: number;
  onActiveTabChange: (activeTab: number) => void;
  children: ReactNode;
}): JSX.Element => {
  return <Context.Provider value={{ activeTab, setActiveTab: onActiveTabChange }}>{children}</Context.Provider>;
};
