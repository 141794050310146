/**
 * Ref from here @link https://openai.com/pricing (October the 3rd, 2023)
 */
const PRICING = {
  USD_FOR_1K_PROMPT: 0.0015,
  USD_FOR_1K_RESULT: 0.002,
};

export const usageToCharge = ({
  prompt_tokens,
  completion_tokens,
}: {
  prompt_tokens: number;
  completion_tokens: number;
}): number => {
  const inputTotalUsageUSD = PRICING.USD_FOR_1K_PROMPT * (prompt_tokens / 1000);
  const outputTotalUsageUSD = PRICING.USD_FOR_1K_RESULT * (completion_tokens / 1000);

  return parseFloat((inputTotalUsageUSD + outputTotalUsageUSD).toFixed(8));
};
