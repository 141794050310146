import { RefObject, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

export type PageScroll = {
  page: number;
  maxPage: number;
  onNextPage: () => void;
  onBackPage: () => void;
  onUpdatePage: (pageNumber: number) => void;
};

type UsePageScrollHook = (
  props?: PageScroll,
  opts?: { clientHeightOffset: number }
) => {
  handleScroll: (() => void) | undefined;
  scrollRootRef: RefObject<HTMLDivElement> | null;
};

const DEBOUNCE_DELAY = 100;

export const usePageScroll: UsePageScrollHook = (props?: PageScroll, opts?) => {
  const rootElementRef = useRef<HTMLDivElement & { scrollTopMax?: number }>(null);

  const scrollTo = (page: number) => {
    if (!rootElementRef?.current) return;

    const contentPageHeight = rootElementRef?.current.clientHeight - (opts?.clientHeightOffset || 0);
    const nextScrollPosition = (page - 1) * contentPageHeight;

    rootElementRef?.current.scrollTo({
      top: nextScrollPosition,
      behavior: 'auto', // not smooth, because smooth scroll will trigger handleScroll multiple times
    });
  };

  const handleScroll = () => {
    if (!rootElementRef?.current || !props) return;

    const contentPageHeight = rootElementRef?.current.clientHeight - (opts?.clientHeightOffset || 0);

    /**
     * WARN: Firefox edge case
     *
     * For some reason the maximum value of scrollTop is smaller by 1px on Firefox
     * (comparing to Chrome). As a workaround we use Firefox specific scrollTopMax
     * property to determine whether user is on the last page.
     */
    const scrollTopMax = rootElementRef?.current.scrollTopMax;

    const newPageIndex =
      scrollTopMax && scrollTopMax === rootElementRef?.current.scrollTop
        ? props.maxPage
        : Math.floor(rootElementRef?.current.scrollTop / contentPageHeight);

    // That case, when auto scroll is finished, and triggers handleScroll. We can detect it by comparing page number from desired & actual one from props
    const isAutoScroll = newPageIndex + 1 === props.page;

    if (!isAutoScroll) {
      props.onUpdatePage(newPageIndex);
    }
  };

  useEffect(() => {
    if (!props?.page || !rootElementRef?.current) return;

    scrollTo(props.page || 1);
  }, [props?.page]);

  if (!props || !rootElementRef) return { handleScroll: undefined, scrollRootRef: null };

  return {
    handleScroll: debounce(handleScroll, DEBOUNCE_DELAY),
    scrollRootRef: rootElementRef,
  };
};
