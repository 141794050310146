import * as React from 'react';

import { Icon } from '@/components/Icon';

interface LoaderProps {
  loading: boolean;
  children: JSX.Element;
  colSpan: number;
  loadingMessage: string;
}

export function Loader(props: LoaderProps): JSX.Element {
  if (props.loading) {
    return (
      <tr>
        <td colSpan={props.colSpan}>
          {props.loadingMessage}
          <br />
          <Icon icon="spinner-third" spin size="2x" />
        </td>
      </tr>
    );
  }

  return props.children;
}
