import React from 'react';

import './terms.scss';
import { TermsEn } from './components/TermsEn';
import { Section } from '@/components/Section';

export const TermsPage = (): JSX.Element => {
  return (
    <Section>
      <div className="grid">
        <div className="gc gc-3-d gc-2-t gc-1-l gc-0-p" />
        <div className="gc gc-6 gc-8-t gc-10-l gc-12-p">
          <div styleName="content">
            <TermsEn />
          </div>
        </div>
      </div>
    </Section>
  );
};
