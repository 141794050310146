import styled from 'styled-components';

import { IconButton } from '@/ui/Button';

export type NavigationProps = {
  submissionsForReviewCount: number;
  selectedSubmissionIndex: number;
  onNextClick: () => void;
  onPrevClick: () => void;
};

const RootStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  display: flex;
  gap: 16px;
  align-items: center;
`;

const NavigateIconButton = styled(IconButton)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.onBackground};

  &:disabled {
    pointer-events: none;
    border-color: ${({ theme }) => theme.colors.neutral.disabled};
    cursor: not-allowed;

    & path {
      fill: ${({ theme }) => theme.colors.neutral.disabled};
    }
  }
`;
export const Navigation = ({
  submissionsForReviewCount,
  selectedSubmissionIndex,
  onNextClick,
  onPrevClick,
}: NavigationProps): JSX.Element => {
  return (
    <RootStyled>
      <NavigateIconButton icon={'arrow-left'} disabled={selectedSubmissionIndex === 0} onClick={onPrevClick} />
      Review {selectedSubmissionIndex + 1} out of {submissionsForReviewCount}
      <NavigateIconButton
        icon={'arrow-right'}
        onClick={onNextClick}
        disabled={submissionsForReviewCount === selectedSubmissionIndex + 1}
      />
    </RootStyled>
  );
};
