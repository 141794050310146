import React, { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

/**
 * NOTION
 *
 * animation-fill-mode set to be "initial" (not "both"),
 * due to issues with z-indexes (causes the select to break, for example)
 *
 * @link https://stackoverflow.com/a/39495529
 */
const FadeIn = styled.div`
  animation: ${fadeIn} 0.2s 0.2s initial;
`;

export const FadeInContents = ({ children }: { children: ReactNode }): JSX.Element => <FadeIn>{children}</FadeIn>;
