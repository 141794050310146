import styled from 'styled-components';

import { IconButton } from '@/ui/Button';
import { DocumentVideoUploadButton } from '@/component/FileUpload/DocumentVideoUploadButton';

const RootStyled = styled.div`
  /**
  Handling case, when element is resized to small dimensions,
  but we still want to have regular toolbar size
 */
  min-width: 176px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.shadow.primary[8]};
  background: ${({ theme }) => theme.colors.neutral.background};
  border-radius: 8px;
  padding: 8px;
  user-select: none;
  gap: 8px;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background: ${({ theme }) => theme.colors.neutral.outline1};
`;

const UploadButton = styled(DocumentVideoUploadButton)`
  & {
    background: transparent;
  }

  &:focus:not(:active) {
    outline: none;
  }
`;

export const FloatingToolbarButtons = ({
  onRemoveClick,
  onReplaceFile,
}: {
  onRemoveClick: () => void;
  onReplaceFile: (file: File) => void;
}): JSX.Element => {
  return (
    <RootStyled>
      <UploadButton icon={['far', 'rotate']} variant="ghost" uploadFile={onReplaceFile} fileType={'image'}>
        Replace
      </UploadButton>
      <Divider />
      <IconButton icon={['far', 'trash']} onClick={onRemoveClick} />
    </RootStyled>
  );
};
