import { useComposedRef, useEditorRef, useEventEditorSelectors, withRef } from '@udecode/plate-common/react';
import { FloatingToolbarState, flip, offset, shift, useFloatingToolbar, useFloatingToolbarState } from '@udecode/plate-floating';
import { LinkPlugin } from '@udecode/plate-link/react';
import styled from 'styled-components';

const ToolbarStyled = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 50;
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.neutral.background};
  padding: 8px;
  border-radius: 8px;
  opacity: 1;
  ${({ theme }) => theme.shadow.primary[8]};
  max-width: 80vw;
  overflow-y: visible;
`;

export const FloatingToolbar = withRef<
  typeof ToolbarStyled,
  {
    state?: FloatingToolbarState;
  }
>(({ children, state, ...props }, componentRef) => {
  const editor = useEditorRef();
  const focusedEditorId = useEventEditorSelectors.focus();
  const isFloatingLinkOpen = !!editor.useOption(LinkPlugin, 'mode');

  const floatingToolbarState = useFloatingToolbarState({
    editorId: editor.id,
    focusedEditorId,
    hideToolbar: isFloatingLinkOpen,
    ...state,
    floatingOptions: {
      middleware: [
        offset({
          crossAxis: -24,
          mainAxis: 12,
        }),
        shift({ padding: 50 }),
        flip({
          fallbackPlacements: ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
          padding: 12,
        }),
      ],
      placement: 'top-start',
      ...state?.floatingOptions,
    },
  });

  const { clickOutsideRef, hidden, props: rootProps, ref: floatingRef } = useFloatingToolbar(floatingToolbarState);

  const ref = useComposedRef<HTMLDivElement>(componentRef as React.Ref<HTMLDivElement> | undefined, floatingRef);

  if (hidden) return null;

  return (
    <div ref={clickOutsideRef}>
      <ToolbarStyled ref={ref} {...rootProps} {...props}>
        {children}
      </ToolbarStyled>
    </div>
  );
});
