import { DependencyList, useEffect } from 'react';

export const useWindowFocus = (fn: (visible: boolean) => void, updateOn?: DependencyList): void => {
  useEffect(() => {
    const handleFocus = () => {
      fn(true);
    };
    const handleBlur = () => {
      fn(false);
    };
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, updateOn);
};
