import React from 'react';

import { RootStyled, CircleContainerStyled, CircleProgressStyled } from './styles';
import { IndeterminateCircularProgressBarProps } from './types';

export const IndeterminateCircularProgressBar = ({
  size = 32,
  strokeWidth = 2,
  className,
}: IndeterminateCircularProgressBarProps): JSX.Element => {
  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const arcLength = 2 * Math.PI * radius;

  return (
    <RootStyled className={className}>
      <CircleContainerStyled size={size}>
        <CircleProgressStyled center={center} radius={radius} strokeWidth={strokeWidth} arcLenght={arcLength} />
      </CircleContainerStyled>
    </RootStyled>
  );
};
