import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BodyLg, Header1 } from '@/components/Typography/Typography';
import { OnboardingStep, StepComponent } from '../types';

export const Hello: StepComponent = ({ onReady }) => {
  const { t } = useTranslation('onboarding');
  useEffect(() => onReady(OnboardingStep.Hello), [onReady]); // no extra action required

  return (
    <>
      <Header1>{t('Hello!')}</Header1>
      <BodyLg>{t('Welcome to your learning hub.')}</BodyLg>
      <BodyLg>
        {t(
          'Get started with discovering content relevant to your role, interests, and personal development by setting up an account.'
        )}
      </BodyLg>
    </>
  );
};
