import styled from 'styled-components';

import { Navigation, NavigationProps } from './Navigation';
import { Icon } from '@/ui/Icon';

type HeaderProps = NavigationProps & {
  reviewsLeftCount: number;
};

const RootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReviewsLeftStyled = styled.div`
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${({ theme }) => theme.typography.body2};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Header = ({ reviewsLeftCount, ...headerProps }: HeaderProps): JSX.Element => {
  return (
    <RootStyled>
      <Navigation {...headerProps} />
      <ReviewsLeftStyled>
        {reviewsLeftCount ? (
          <>{reviewsLeftCount} left</>
        ) : (
          <>
            <Icon icon="check" />
            <span>Done</span>
          </>
        )}
      </ReviewsLeftStyled>
    </RootStyled>
  );
};
