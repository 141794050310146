/* eslint-disable import/no-unused-modules */

import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@/ui/Button';
import { Tooltip } from '@/ui/Tooltip';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { RootStyled, HeadingWrapStyled, TitleStyled, ActionsStyled } from '../styles';

type EditNavbarProps = {
  onBackClick: () => void;
  onSettingsClick: () => void;
  onSaveClick: () => void;
  viewHref?: string;
  title: string;
};

const _EditNavbar = ({ title, onBackClick, viewHref, onSettingsClick, onSaveClick }: EditNavbarProps): JSX.Element => {
  const theme = useTheme();
  const iconColor = theme.tones.neutral[0];
  const { t } = useTranslation('catalogAdmin');

  return (
    <RootStyled>
      <HeadingWrapStyled>
        <Tooltip title="Back" placement="bottom-start">
          <IconButton icon="arrow-left" color={iconColor} onClick={onBackClick} />
        </Tooltip>
        <TitleStyled>{title}</TitleStyled>
      </HeadingWrapStyled>
      <ActionsStyled>
        <Tooltip title="Settings" placement="bottom">
          <IconButton icon={['far', 'gear']} color={iconColor} onClick={onSettingsClick} />
        </Tooltip>
        {viewHref && (
          <Button variant="secondary" href={viewHref} target="_blank">
            {t('view')}
          </Button>
        )}
        <Button variant="primary" onClick={onSaveClick}>
          {t('save')}
        </Button>
      </ActionsStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <HeadingWrapStyled>
      <TitleStyled>
        <Skeleton width={244} height={36} />
      </TitleStyled>
    </HeadingWrapStyled>
    <ActionsStyled>
      <Skeleton width={66} height={36} />
      <Skeleton width={87} height={36} />
    </ActionsStyled>
  </RootStyled>
);

export const EditNavbar = makeNamespacedComponent(_EditNavbar, {
  LoadingState,
});
