/* eslint-disable import/no-unused-modules */
import { useCurrentUser } from '@/store/currentUser';
import {
  useCreateUserLearningSubmissionMutationRemote,
  RCreateUserLearningSubmissionMutation,
  RUserLearningSubmissionType,
} from '@/store/v2';

export type SubmissionData = { text: string } | { documentURL: string };

type UseCreateUserLearningSubmissionHook = () => {
  createUserLearningSubmission: (
    input: {
      learningId: number;
      pageId: number;
      assessmentType: RUserLearningSubmissionType;
      submissionData: SubmissionData;
    },
    onSuccess?: () => void
  ) => Promise<{ errors?: unknown | undefined; data?: RCreateUserLearningSubmissionMutation | null | undefined }>;
  loading: boolean;
};

export const useCreateUserLearningSubmission: UseCreateUserLearningSubmissionHook = () => {
  const [createUserLearningSubmission, { loading }] = useCreateUserLearningSubmissionMutationRemote();

  const { user } = useCurrentUser();
  const userId = Number(user?.id);

  return {
    createUserLearningSubmission: async ({ learningId, pageId, assessmentType, submissionData }, onSuccess?: () => void) => {
      return await createUserLearningSubmission({
        variables: {
          input: {
            learningId,
            pageId,
            type: assessmentType,
            userId: userId,
            data: submissionData,
          },
        },
        update(_, { data }) {
          if (data?.createUserLearningSubmission) {
            onSuccess && onSuccess();
          }
        },
      });
    },
    loading,
  };
};
