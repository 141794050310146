import React, { MouseEvent as ReactMouseEvent, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _isNil from 'lodash.isnil';

import {
  Card,
  isCollectionCard,
  isProductCard,
  isProgressCard,
  isCardCompletionAboutToExpire,
  isJourneyCard,
} from '@/types/learning/card';
import { CourseCardWrapper } from './components/CourseCardWrapper';
import { useProductTypeTranslations } from '@/i18n/hooks';
import { InventoryProductCard } from '@/store/inventory';
import { getCardProviderMeta } from '@/store/providers';
import { MyAssignmentCard } from '@/types/assignments';
import { getLink } from '@/utils/card';
import { useModalContext } from '../Modal/ModalContext';
import { CourseCardImage } from './components/CourseCardImage';
import { BodyMd, Header4, Overline } from '../Typography/Typography';
import { replaceNbsps } from '@/utils/text';
import { CourseCardBody } from './components/CourseCardBody';
import { CourseCardFooter } from './components/CourseCardFooter';
import { CourseCardEvent } from './components/CourseCardEvent';
import { CourseCardHeaderLabels } from './components/CourseCardHeaderLabels';
import { CourseCardCancelButton } from './components/CourseCardCancelButton';
import { trackUnEnrollLearningItemClicked } from '@/utils/tracking/learnings';
import { trackOpeningModalForCancellingEnrollment } from '@/utils/tracking/myLearningPage';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

interface CourseCardProps {
  card: Card;
  cancelOrder?: () => void;
  loadingCancel?: boolean;
  isRecommended?: boolean;
  skipCancelConfirmation?: boolean;
  noProviderLogo?: boolean;
  isMandatory?: boolean;
}

export const StyledWrapper = styled.div<{ $isMandatory?: boolean; $isCollection?: boolean }>`
  background-color: var(${({ $isCollection }) => ($isCollection ? '--color-wild-sand' : '--color-white')});
  border-radius: var(--radius-outer);
  box-shadow: var(${({ $isCollection }) => !$isCollection && '--box-shadow-large'});
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  a&:hover {
    box-shadow: var(--box-shadow-large-hover);
  }
  ${({ $isMandatory }) =>
    $isMandatory &&
    `
    border-radius: calc(var(--radius-outer) + var(--border-width));
    border: var(--border-width) solid var(--color-black);
  `}
`;

export const CourseCard = ({
  card,
  cancelOrder,
  loadingCancel,
  isRecommended,
  skipCancelConfirmation,
  noProviderLogo,
  ...props
}: CourseCardProps): React.ReactElement => {
  const { t } = useTranslation('catalog');
  const { productType } = useProductTypeTranslations();
  const { showConfirmationModal } = useModalContext();
  const isCardDescrtionHidden = useFeatureEnabled(FEATURE.UI_CARD_DESCRIPTION_HIDDEN);
  const isCollection = isCollectionCard(card);
  const isJourney = isJourneyCard(card);
  const isEvent = ['seminar', 'webinar'].includes(card.type);
  const isCancellable = !!cancelOrder;
  const { provider, title, image, teaser, type, featured = false } = card;

  let participation, customLink, format, level, totalEffort, totalLearningsCount;
  if (isProgressCard(card)) ({ customLink, participation, level, totalEffort } = card);
  if (isJourney) {
    if (!_isNil(card.totalLearningsCount)) totalLearningsCount = card.totalLearningsCount || 0;
    if (!_isNil(card.totalEffort)) totalEffort = card.totalEffort;
  }
  if (isCollection) totalLearningsCount = card.items?.length || 0;
  if (isProductCard(card)) ({ customLink, format } = card);

  const assignmentCard = card as MyAssignmentCard;
  const isMandatory = props.isMandatory !== undefined ? props.isMandatory : assignmentCard.mandatory;
  const { state } = card as InventoryProductCard;

  const isApproved = state === 'approved' || !!participation;
  const isPending = state ? ['registered', 'pending approval'].includes(state) : false;

  const providerMeta = getCardProviderMeta(card);

  const onCancelOrder = useCallback(() => {
    cancelOrder?.();
    trackUnEnrollLearningItemClicked();
  }, []);

  const handleCancel = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!cancelOrder) return;

    if (skipCancelConfirmation) {
      onCancelOrder();
    } else {
      trackOpeningModalForCancellingEnrollment();
      showConfirmationModal({
        action: onCancelOrder,
        message: <Trans t={t} i18nKey="remove-course-prompt" values={{ name: title }} components={[<br key="br" />]} />,
      });
    }
  };

  const showExpiration = isCardCompletionAboutToExpire(card);

  return (
    <CourseCardWrapper
      Component={StyledWrapper}
      customLink={customLink}
      link={getLink(card)}
      isApproved={isApproved}
      provider={provider}
      ariaLabel={title.trim()}
      isMandatory={isMandatory}
      isCollection={isCollection}
      entityId={card.entityId}
      isApprovalRequired={card.isApprovalRequired}
      title={title}
      type={type}
      featured={featured}
      isExternal={card.isExternal}
    >
      {(!participation?.isCompleted || showExpiration) && (
        <CourseCardHeaderLabels
          isFeatured={featured}
          isMandatory={isMandatory}
          isRecommended={isRecommended}
          expiredAt={participation?.expiredAt}
        />
      )}
      {isCancellable && (
        <CourseCardCancelButton
          onClick={handleCancel}
          $loading={loadingCancel}
          disabled={loadingCancel}
          $ariaLabel={t('Close', { ns: 'common' })}
        />
      )}
      <CourseCardImage
        image={image ? `${image}?width=420` : ''}
        isCollection={isCollection}
        providerMeta={noProviderLogo ? undefined : providerMeta}
        type={type}
        isCancellable={isCancellable}
        isCompleted={participation?.isCompleted && !showExpiration}
      />
      {isEvent ? (
        <CourseCardEvent card={card} />
      ) : (
        <>
          <CourseCardBody $isCollection={isCollection} size={isCardDescrtionHidden ? 'sm' : 'lg'}>
            <Overline>{productType(type)}</Overline>
            <Header4>{replaceNbsps(title)}</Header4>
            {!isCardDescrtionHidden && <BodyMd>{teaser}</BodyMd>}
          </CourseCardBody>
          <CourseCardFooter
            isPending={isPending}
            participation={participation}
            hasExpiration={showExpiration}
            isCollection={isCollection}
            isJourney={isJourney}
            itemCount={totalLearningsCount || undefined}
            format={format}
            level={level}
            totalEffort={totalEffort}
          />
        </>
      )}
    </CourseCardWrapper>
  );
};
