import React, { useMemo, useState } from 'react';

import { AutoTable, AutoTableProps } from '@/ui/AutoTable';
import { LearningProgress, SortBy } from './cells/types';
import { useColumnDeclaration } from './columnDeclaration';
import { sort } from '@/store/utils/table/sort';

type WeeklySummaryTableProps = {
  learningProgressList: LearningProgress[];
};

const PAGE_SIZE = 4;

type SortOrder = 'asc' | 'desc';

const sortByProgress = (items: LearningProgress[], sortOrder: SortOrder): LearningProgress[] => {
  const sorted = [...items].sort((a, b) => {
    const aTimeSpent = Math.max(a.plannedDuration - a.userEffort, 0);
    const bTimeSpent = Math.max(b.plannedDuration - b.userEffort, 0);

    // We consider completed to be lower in time left comparation, that anything else
    if (a.completed || b.completed) {
      return Number(Boolean(b.completed)) - Number(Boolean(a.completed));
    }

    return aTimeSpent - bTimeSpent;
  });
  if (sortOrder === 'desc') {
    sorted.reverse();
  }
  return sorted;
};

const useProgressData = ({
  allItems,
  sortOrder,
  sortBy,
  limit,
  skip,
}: {
  allItems: LearningProgress[];
  sortOrder: SortOrder;
  sortBy: SortBy;
  limit: number;
  skip: number;
}): LearningProgress[] => {
  const processedItems = useMemo(() => {
    if (!allItems) {
      return [];
    }

    let processedItems =
      sortBy === 'progress' ? sortByProgress(allItems, sortOrder) : sort<LearningProgress>(allItems, sortBy, sortOrder);
    processedItems = processedItems.slice(skip, skip + limit);

    return processedItems;
  }, [allItems, sortBy, sortOrder, skip, limit]);
  return processedItems;
};

export const WeeklySummaryTable = ({ learningProgressList }: WeeklySummaryTableProps): JSX.Element => {
  const columnDeclaration = useColumnDeclaration();

  const [sortBy, setSortBy] = useState<SortBy>('title');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [page, setPage] = useState<number>(0);

  const processedItems = useProgressData({
    allItems: learningProgressList,
    sortBy,
    sortOrder,
    limit: PAGE_SIZE,
    skip: page * PAGE_SIZE,
  });

  const pageCount = Math.ceil(learningProgressList.length / PAGE_SIZE);

  const tableProps: AutoTableProps<LearningProgress, SortBy> = {
    columnDeclaration,
    page,
    pageCount,
    rows: processedItems || [],
    currentSortKey: sortBy,
    currentSortOrder: sortOrder,
    rowKey: (item) => JSON.stringify(item),
    onSort: (sortKey, order) => {
      setSortBy(sortKey);
      setSortOrder(order);
    },
    onPageChange: (nextPage) => {
      setPage(nextPage);
    },
  };

  return <AutoTable<LearningProgress, SortBy> {...tableProps} />;
};
