import { BlockType, ReactionBlock, ReactionId } from './types';
import { BlockId } from '../types';
import { Course } from '@/administration/pages/Course/store/course/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createReactionBlock = (course?: Course, reactionId?: ReactionId): ReactionBlock => ({
  id: `block-l-return` as BlockId,
  type: BlockType.REACTION,
  reactionId,
});
