import { Activity, ActivityType, TreeActivity } from '@/administration/pages/Course/store/activity/types';

export const convertActivitiesToTree = (activities: Activity[]): TreeActivity[] => {
  return activities
    .filter((activity) => !activity.parentActivityId)
    .sort((a, b) => a.order - b.order)
    .map((activity) => {
      const activityType = activity.type as unknown as ActivityType;
      const isModule = activityType === ActivityType.Module;

      if (isModule) {
        return {
          ...activity,
          children: activities.filter((child) => child.parentActivityId === activity.id).sort((a, b) => a.order - b.order),
        };
      }

      return activity;
    });
};

export const convertActivitiesToSortedNavigationList = (activities: Activity[]): Activity[] => {
  // A lookup table to store items by their parentActivityId
  const childrenMap: { [key: string]: Activity[] } = {};

  activities.forEach((activity) => {
    if (activity.parentActivityId) {
      if (!childrenMap[activity.parentActivityId]) {
        childrenMap[activity.parentActivityId] = [];
      }
      childrenMap[activity.parentActivityId].push(activity);
    }
  });

  // Sort all child arrays by their 'order' property
  Object.keys(childrenMap).forEach((key) => {
    childrenMap[key].sort((a, b) => a.order - b.order);
  });

  // Filter the main activities and inject their children
  const result: Activity[] = [...activities.filter((activity) => !activity.parentActivityId)]
    .sort((a, b) => a.order - b.order) // Sort top-level activities by 'order'
    .reduce((acc, curr) => {
      if (childrenMap[curr.id]) {
        const children = childrenMap[curr.id] || [];

        return [...acc, ...children];
      }

      return [...acc, curr];
    }, [] as Activity[]);

  return result;
};
