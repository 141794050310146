import { Reaction, ReactionsPage } from './ReactionsPage';
import { ReactionId } from '@/administration/pages/Course/store/block/types';
import { useXAPIReaction } from '@/store/xapi/useXAPIReaction';
import { XAPISession } from '@/store/xapi/useXAPISession';

type ViewReactionActivityV2Props = {
  learningId?: number;
  title?: string;
  isActive: boolean;
  onCompleted(): void;
  xapiSession?: XAPISession;
};

const REACTIONS_LIST: Reaction[] = [
  {
    id: ReactionId.CONTINUE_LEARNING,
    icon: ['far', 'book-open-cover'],
    title: 'Learn more on this topic',
  },
  {
    id: ReactionId.CHANGE_TOPIC,
    icon: ['far', 'books'],
    title: 'Learn more about another topic',
  },
  {
    id: ReactionId.APPLY,
    icon: ['far', 'tools'],
    title: 'Apply what I have learned',
  },
  {
    id: ReactionId.DISCUSS,
    icon: ['far', 'comments'],
    title: 'Discuss with a colleague',
  },
  {
    id: ReactionId.NOT_SURE,
    icon: ['far', 'face-thinking'],
    title: 'Not sure',
  },
];

export const ViewReactionActivityV2 = ({ learningId, title, onCompleted, xapiSession }: ViewReactionActivityV2Props): JSX.Element => {
  const xapiReaction = useXAPIReaction(Number(learningId), title, xapiSession);

  if (xapiReaction.loading) return <div className="pageloader" />;

  return (
    <ReactionsPage
      reactions={REACTIONS_LIST}
      selectedReactionId={xapiReaction.reactionId}
      onReactionSelected={(reactionId) => {
        xapiReaction.setReactionId(reactionId);
        onCompleted();
      }}
    />
  );
};
