import { formatYMD, getCurrentDateWithDefaultTimezone, getPreviousDay } from '@/utils/dates';
import { AssignmentWithStatus } from '../assignmentPage/types';
import { AssignmentV3 } from '../types';
import { AssignmentProgressStatus } from './types';

export const transformResults = (assignment: AssignmentV3, userIds: number[]): AssignmentWithStatus => {
  if (assignment.type === 'STRATEGIC') {
    const progress = assignment?.users?.data?.find((x) => userIds.includes(x.userId))?.progress;
    const estimatedEffort = progress?.estimatedEffort ?? 0;
    const endAtDate = new Date(assignment.endAt);
    const currentDate = getCurrentDateWithDefaultTimezone();
    const isPast = endAtDate < currentDate;

    let progressStatus: AssignmentProgressStatus = 'not-started';
    if (isPast) {
      progressStatus = !!progress?.completed ? 'completed' : 'ended';
    } else {
      progressStatus = estimatedEffort === 0 ? 'not-started' : 'ongoing';
    }

    const { endAt, ...rest } = assignment;
    const transformedEndAt = getPreviousDay(endAt);

    if (!!transformedEndAt) {
      const sunday = `${formatYMD(transformedEndAt)}T00:00:00.000Z`;
      return {
        ...rest,
        endAt: sunday,
        progressStatus,
        estimatedEffort,
        progressCompetencyAssessment: progress?.competencyAssessment,
      };
    }

    return {
      ...assignment,
      progressStatus,
      estimatedEffort,
      progressCompetencyAssessment: progress?.competencyAssessment,
    };
  }

  const progress = assignment?.users?.data?.find((x) => userIds.includes(x.userId))?.progress;
  return {
    ...assignment,
    progressStatus: !!progress?.completed ? 'completed' : progress?.participations.length === 0 ? 'not-started' : 'ongoing',
    requiredItemsCount: progress?.requiredItemsCount,
    requiredItemsCompletedCount: progress?.requiredItemsCompletedCount,
  };
};
