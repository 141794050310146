import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { providers } from '@/store/providers'; // TODO: Break out any provider specific styles
import { LoadingHero } from '@/component/LoadingHero';
import { CARD_SORTING_METHODS, useCardCatalog } from '@/store/catalog';
import { Hero, Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { providerAccessProductIdQuery } from './queries';
import { getProviderHero } from './utils';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';

type Props = 'provider';

export const ProviderPage = (): JSX.Element | null => {
  const params = useParams<Props>();
  const providerMeta = providers.find(p => p.provider === params.provider);
  const { catalog, loading } = useCardCatalog();
  const { data, loading: loadingProviderAccess } = useQuery(providerAccessProductIdQuery, {
    skip: !providerMeta,
    variables: {
      realm: window.realm,
      provider: providerMeta?.provider
    }
  });

  if (!providerMeta) return null;
  if (loading || loadingProviderAccess) return <LoadingHero />;

  const { provider } = providerMeta;
  const { providerAccessProductId } = data;
  const productId = providerAccessProductId?.id;
  const cards = catalog.cards
    .filter(card => card.provider?.toLowerCase() === provider)
    .filter(card => card.available)
    .sort(CARD_SORTING_METHODS[CatalogSortingEnum.FEATURED]);

  return (
    <>
      <Hero paddedBottom>{getProviderHero(provider, productId)}</Hero>
      <Section>
        <CardGrid cards={cards} loading={loading} noProviderLogo />
      </Section>
    </>
  );
};
