import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import './avatar.scss';
import { Icon } from '@/components/Icon';
import { ChoosePicture } from './ChoosePicture/ChoosePicture';
import { useModalContext } from '@/components/Modal/ModalContext';

interface Props {
  profileImage?: string;
  canChoosePicture?: boolean;
  onClick?: HTMLAttributes<HTMLDivElement>['onClick'];
  imageSize?: ImageSize;
  className?: string;
}

export enum ImageSize {
  Default = 320,
  Small = 32
}

export const Avatar = ({
  profileImage,
  canChoosePicture = false,
  onClick,
  imageSize = ImageSize.Default,
  className
}: Props): JSX.Element => {
  const { t } = useTranslation('profileImageUpload');
  const needsPlaceholder = !profileImage || profileImage === 'null';
  const { showModal } = useModalContext();

  const attributes: HTMLAttributes<HTMLDivElement> = {};

  const handleChoosePictureModal = () =>
    showModal({
      children: <ChoosePicture />,
      size: 'large'
    });

  if (canChoosePicture) {
    attributes.onClick = handleChoosePictureModal;
  }

  if (onClick) {
    attributes.onClick = onClick;
  }

  return (
    <div
      styleName={clsx(needsPlaceholder ? 'placeholder' : 'image', canChoosePicture && 'can-choose')}
      data-text={canChoosePicture ? t('Upload picture') : null}
      className={className}
      {...attributes}
    >
      {needsPlaceholder ? (
        <Icon icon="user" styleName={clsx('placeholder-icon')} />
      ) : (
        <img src={`${profileImage}?width=${imageSize}`} alt={t('Profile picture')} />
      )}
    </div>
  );
};
