import React, { lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from 'atomic-layout';

import 'intersection-observer';
import './app.scss';
import '@/ui/utils/bootstrapIcons';
import { LoginPage } from '@/pages/auth/LoginPage';
import { CatalogPage } from '@/pages/Catalog/CatalogPage';
import { CourseDetailsPage } from '@/pages/learning/CourseDetails/CourseDetailsPage';
import { LearningCollectionPage } from '@/pages/learning/LearningCollection/LearningCollectionPage';
import { NotificationsPage } from '@/pages/user/Notifications/NotificationsPage';
import { PostPage } from '@/pages/PostPage';
import { ReflectionInjection } from '@/pages/ReflectionInjection';
import { OnboardingPage } from '@/pages/Onboarding/OnboardingPage';
import { TermsPage } from '@/pages/Terms/TermsPage';
import { PrivacyPage } from '@/pages/Terms/PrivacyPage';
import { AboutUsPage } from '@/pages/AboutUsPage';
import { ELearningView } from '@/pages/learning/ELearningView';
import { ArticleRedirect } from '../ArticleRedirect/ArticleRedirect';
import { AuthenticatedRoute } from './component/AuthenticatedRoute/AuthenticatedRoute';
import { HistoricCompletedCourses } from '@/pages/user/MyLearning/components/HistoricCompletedCourses';
import { Modal } from '@/components/Modal/Modal';
import { SuperAdminRoute } from './component/SuperAdminRoute/SuperAdminRoute';
import { useCurrentUser } from '@/store/currentUser';
import { ConfirmationModal } from '@/components/Modal/ConfirmationModal';
import { usePageview } from '@/utils/tracking/countly';
import { ProviderPage } from '@/pages/Provider/ProviderPage';
import { SearchPage } from '@/pages/Search/SearchPage';
import { CategoryPage } from '@/pages/Catalog/CategoryPage';
import { SubjectPage } from '@/pages/Catalog/SubjectPage';
import { AllProvidersPage } from '@/pages/AllProviders/AllProvidersPage';
import { checkAdminView } from '@/utils/checkAdminView';
import { EventsPage } from '@/pages/Catalog/EventsPage';
import { useIsSimpleModeEnabled } from '@/feature-toggles';
import { Homepage } from '@/pages/Homepage';
import { LegacyGlobalStyle } from '@/styles';
import { BuddyThemeProvider } from '@/ui/theme';
import { LayoutWrapper } from './component/LayoutWrapper/LayoutWrapper';
import { ManagerNavigate } from './component/ManagerNavigate';
import { Assignment } from '@/pages/Assignment';
import { ParticipantsPage } from '@/pages/Assignment/ParticipantsPage';
import { ManagerRoute } from '@/component/App/component/ManagerRoute';
import { ScrollToTop } from '@/component/ScrollToTop';
import { BundleChecker } from '@/component/BundleChecker/BundleChecker';
import { ErrorBoundary } from '@/component/ErrorBoundary';
import { ViewJourney } from '@/administration/pages/Journey/pages/ViewJourney';
import { ViewParticipants } from '@/administration/pages/Journey/pages/ViewParticipants';
import { LearningViewPage } from '@/pages/learning/LearningViewPage';
import { ViewDiscussions } from '@/administration/pages/Journey/pages/ViewDiscussions';

const MyLearningPage = lazy(() => import('@/pages/user/MyLearning/MyLearningPage'));
const Page404 = lazy(() => import('@/pages/Page404'));
const Styleguide = lazy(() => import('@/pages/Styleguide/Styleguide'));
const ProfilePage = lazy(() => import('@/pages/user/Profile/ProfilePage'));
const Administration = lazy(() => import('@/administration/Administration'));
const Management = lazy(() => import('@/management'));
const Checklist = lazy(() => import('@/pages/SuperAdmin/Checklist/Checklist'));

const App = (): JSX.Element => {
  const { loadingFirstTime, error } = useCurrentUser();
  const [isSimpleMode] = useIsSimpleModeEnabled();
  const isAdminView = checkAdminView();

  usePageview();

  useEffect(() => {
    Layout.configure({ defaultUnit: 'rem' });
  }, []);

  useEffect(() => {
    if (isAdminView && !document.title.match(/.+- Admin/g)) {
      document.title = `${document.title} - Admin`;
    }
  }, [isAdminView]);

  useEffect(() => {
    if (!isAdminView && document.title.match(/.+- Admin/g)) {
      document.title = `${document.title.replace(/\s- Admin/g, '')}`;
    }
  }, [isAdminView]);

  // Only show loader if user info is being loaded *for the first time*; otherwise
  // if the user is refetched (eg. after changing username), the current component tree
  // is unmounted, the loader is rendered instead for a brief moment,
  // and then the component tree is rendered again from scratch - losing its previous state.
  if (loadingFirstTime && !error) return <div className="pageloader pageloader--full" />;

  return (
    <BuddyThemeProvider>
      <ErrorBoundary>
        <LegacyGlobalStyle />

        <Modal />
        <ConfirmationModal />

        <ScrollToTop />
        <BundleChecker />

        <Routes>
          {/** Learnifier iFrame */}
          <Route path="inject-reflection" element={<ReflectionInjection />} />

          {/** Public routes */}
          <Route element={<LayoutWrapper />}>
            <Route path="404" element={<Page404 />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="terms" element={<TermsPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="about" element={<AboutUsPage />} />
          </Route>

          <Route element={<AuthenticatedRoute />}>
            {/** Administration routes */}
            <Route path="/administration/*" element={<Administration />} />

            {/** Management routes */}
            <Route element={<LayoutWrapper />}>
              <Route path="management" element={<ManagerRoute />}>
                <Route index element={<Navigate to="analyze/learners" replace />} />
                <Route path="*" element={<Management />} />
              </Route>
              <Route path={'/users/:id'} element={<ManagerNavigate />} />
              <Route path={'analytics/*'} element={<ManagerNavigate />} />
            </Route>

            {/**
             * No route rendering LearningViewPage should be
             * wrapped in LayoutWrapper, since it's rendering it itself conditionally
             * */}
            <Route path="learning">
              <Route path=":id" element={<LearningViewPage />} />
              <Route path=":id/:learningName/*" element={<LearningViewPage />} />
            </Route>
            <Route path="learning-collection/:collectionId/:collectionName">
              <Route path="learning/:id/*" element={<LearningViewPage />} />
              <Route path="learning/:learningName/*" element={<LearningViewPage />} />
            </Route>
            {/** Learner routes, that are always wrapped with LayoutWrapper*/}
            <Route element={<LayoutWrapper />}>
              <Route path="" element={isSimpleMode ? <CatalogPage /> : <Homepage />} />
              <Route path="/" element={isSimpleMode ? <CatalogPage /> : <Homepage />} />
              <Route path="onboarding" element={<OnboardingPage />} />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route path="assignment/:id/:title/*">
                <Route index element={<Assignment />} />
                <Route path="participants" element={<ParticipantsPage />} />
              </Route>

              <Route path="journey/:journeyId/:name/*">
                <Route index element={<ViewJourney />} />
                <Route path={'sessions/:sessionId/participants'} element={<ViewParticipants />} />
                <Route path={'discussions/*'} element={<ViewDiscussions />} />
              </Route>

              <Route path="learning">
                <Route index element={<CatalogPage />} />
                <Route path=":type/:id/:articleName" element={<ArticleRedirect />} />
                <Route path="events" element={<EventsPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="course/details/:id/:courseName/*" element={<CourseDetailsPage />} />
                <Route path="course/:id/:courseName/*" element={<CourseDetailsPage />} />
                <Route path="provider" element={<AllProvidersPage />} />
                <Route path="provider/:provider" element={<ProviderPage />} />
                <Route path="category/:category" element={<CategoryPage />} />
                <Route path="category/:category/subject/:subject" element={<SubjectPage />} />
              </Route>
              <Route path="learning-collection/:collectionId/:collectionName">
                <Route index element={<LearningCollectionPage />} />
                <Route path="*" element={<LearningCollectionPage />} />
                <Route path="learning/course/details/:id/:courseName/*" element={<CourseDetailsPage />} />
                <Route path="learning/course/:id/:courseName/*" element={<CourseDetailsPage />} />
                <Route path=":type/:id/:articleName/*" element={<ArticleRedirect />} />
              </Route>
              <Route path="/post/:postId/*" element={<PostPage />} />
              <Route path="/reflection/:postId/*" element={<PostPage />} />

              <Route path="/user/profile" element={<ProfilePage />} />

              <Route path="/user/my-learning/hr-online-data" element={<HistoricCompletedCourses />} />
              <Route path="/user/my-learning/*" element={<MyLearningPage />} />

              <Route path="styleguide" element={<Navigate to="/super-admin/styleguide" replace />} />
              <Route path="styleguide/:section" element={<Navigate to="/super-admin/styleguide/:section" replace />} />
              <Route path="/super-admin" element={<SuperAdminRoute />}>
                <Route path="styleguide" element={<Styleguide />}>
                  <Route path=":section" />
                </Route>
                <Route path="checklist" element={<Checklist />} />
              </Route>

              <Route path="*" element={<Page404 />} />
            </Route>

            {/** xAPI e-learning view */}
            <Route path="learning">
              <Route path="e-learning/:id/view" element={<ELearningView />} />
              <Route path="e-learning/:id/:learningName/view" element={<ELearningView />} />
            </Route>
          </Route>
        </Routes>
      </ErrorBoundary>
    </BuddyThemeProvider>
  );
};

// const HotApp = hot(App);

export { App };
