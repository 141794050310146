import React, { useEffect, Dispatch, SetStateAction } from 'react';

export const useOnClickOutside = (
  bool: boolean,
  fn: Dispatch<SetStateAction<boolean>>,
  node: React.RefObject<HTMLDivElement | HTMLElement> | null
): void => {
  const handleClickOutside = (e: Event) => {
    if (node && node.current && node?.current?.contains(e.target as Node)) return;

    fn(false);
  };

  useEffect(() => {
    if (bool) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [bool]);
};
