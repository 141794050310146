import { useNavigate } from 'react-router-dom';

import { UseCourseViewerV2Result } from '@/administration/pages/Course/store/courseViewer/useCourseViewerV2';
import { ModularLearningStartLayout } from '@/pages/ModularLearningDeprecated/ModularLearningStart';
import { ModularLearningStartContent } from '@/pages/ModularLearningDeprecated/ModularLearningStart/Content';

export const CourseStart = (props: UseCourseViewerV2Result): JSX.Element => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {!!props.loadingLearning || !props.loadingProgressInitialized ? (
        <ModularLearningStartLayout.LoadingState />
      ) : (
        <ModularLearningStartLayout
          title={props.course?.title || ''}
          content={
            <ModularLearningStartContent
              title={props.course?.title}
              teaser={props.course?.teaser}
              imageSrc={props.course?.image}
              effort={{
                hours: props.course?.effortHours,
                minutes: props.course?.effortMinutes,
              }}
              level={props.course?.level}
              onStartClick={props.startCourse}
              startCourseLoading={props.loadingStartCourse}
            />
          }
          onAddFavoritesClick={props.onSaveCourse}
          onCloseClick={handleGoBack}
          isSavedToMyLearning={props.isCourseSaved}
        />
      )}
    </>
  );
};
