import { ApolloEntity } from '../apollo';
import { LearningSubject, LearningVisibility, Location } from '../learning';
import { Provider } from '@/store/providers';
import { CatalogEntities, LEARNING_CARD_TYPES, ProductType } from './learning-catalog';
import { CollectionInputItem } from '@/store/learning-collection';
import { MyAssignmentCard } from '../assignments';
import { shouldShowLearningExpirationMessage } from '@/utils/expirations';
import { Participation } from '@/types/analytics';

type ExternalCard = 'external' | 'user-generated';

export type CardType = ProductType | ExternalCard;

export type AnalyticsUnmappedProgressCard = 'course';

export type AnalyticsCardType = CardType | AnalyticsUnmappedProgressCard;

export interface Card extends ApolloEntity {
  id: string;
  entity: CatalogEntities;
  entityId: number;
  title: string;
  teaser: string;
  subjects: LearningSubject[];
  language?: string | null;
  languageId?: number | null;
  image: string | null;
  canEdit: boolean;
  available: boolean;
  featured: boolean;
  type: CardType;
  spaceId: number;
  archived: boolean;
  provider?: Provider | null; // PT-617 should have added provider to all content types;
  isApprovalRequired?: boolean;
  isExternal?: boolean;
  visibility?: LearningVisibility;
  attributes?: Record<string, unknown>;
  customKey?: string; //Needed to handle multiple sessions for an event
}

export interface ProgressCard extends Card {
  totalEffort?: number;
  level?: string | null;
  participation?: Participation;
  customLink?: string;
}
export interface ProductCard extends ProgressCard {
  __typename: 'ProductCard';
  format?: string;
  userSelfTrackedLearningId?: number;
  customLink?: string;
  objectId?: string;
}

export interface LearningCard extends ProgressCard {
  __typename: 'LearningCard';
  locations?: Location[];
  objectId?: string;
}

export interface CollectionCard extends Card {
  __typename: 'CollectionCard';
  items: CollectionInputItem[];
}

export interface JourneyCard extends ProgressCard {
  __typename: 'JourneyCard';
  totalLearningsCount?: number;
}

export type AllCardTypes = ProgressCard | ProductCard | LearningCard | CollectionCard | JourneyCard | MyAssignmentCard | Card;

export function isProgressCard(card: Card): card is ProgressCard {
  return card.type === 'learning-path' || LEARNING_CARD_TYPES.includes(card.type as never);
}
export function isProductCard(card: Card): card is ProductCard {
  return card.type === 'learning-path' || !!card.isExternal;
}
export function isCollectionCard(card: Card): card is CollectionCard {
  return card.type === 'collection';
}
export function isJourneyCard(card: Card): card is JourneyCard {
  return card.type === 'journey';
}

export function isCardCompletionAboutToExpire(card: Card): boolean {
  if (!isProgressCard(card) || !card.participation?.expiredAt) return false;
  return shouldShowLearningExpirationMessage(new Date(card.participation.expiredAt));
}

export interface CourseProgress {
  completedAt?: Date;
  completed: boolean;
  completedPercentage: number;
  historical?: boolean;
  expiredAt?: string;
}
