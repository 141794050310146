import React from 'react';
import styled from 'styled-components';

import { Card, isCollectionCard, isProductCard, isProgressCard, isCardCompletionAboutToExpire } from '@/types/learning/card';
import { useProductTypeTranslations } from '@/i18n/hooks';
import { InventoryProductCard } from '@/store/inventory';
import { getCardProviderMeta } from '@/store/providers';
import { MyAssignmentCard } from '@/types/assignments';
import { getLink } from '@/utils/card';
import { Title4, Overline } from '../../Typography/v2';
import { replaceNbsps } from '@/utils/text';
import { CourseCardBodyCaption, CourseCardBodyV2 } from './components/CourseCardBody';
import { CourseCardWrapperV2 } from './components/CourseCardWrapper';
import { CourseCardImageV2 } from './components/CourseCardImage';
import { CourseCardEventV2 } from './components/CourseCardEvent';
import { CourseCardHeaderLabelsV2 } from './components/CourseCardHeaderLabels';
import { CourseCardFooter, StyledFooterV2 } from './components/CourseCardFooter';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

type CourseCardProps = {
  card: Card;
  cancelOrder?: () => void;
  loadingCancel?: boolean;
  isRecommended?: boolean;
  isMandatory?: boolean;
  skipCancelConfirmation?: boolean;
  noProviderLogo?: boolean;
  sectionName: string;
  fullWidth?: boolean;
  minWidth?: boolean;
};

type SkeleteonCourseCardProps = {
  containerFullWidth?: boolean;
  minWidth?: boolean;
};

const StyledWrapperV2 = styled.div<{ $isMandatory?: boolean; $isCollection?: boolean; $fullWidth?: boolean; size?: 'sm' | 'lg' }>`
  background-color: var(${({ $isCollection }) => ($isCollection ? '--color-wild-sand' : '--color-white')});
  border-radius: var(--radius-outer);
  box-shadow: var(${({ $isCollection }) => !$isCollection && '--box-shadow-large'});
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  padding: 0px;
  height: ${(p) => (p.size === 'sm' ? '327px' : '400px')};

  ${({ $fullWidth }) => (!!$fullWidth ? 'width: 100%; min-width: 300px;' : 'max-width: 100%; width: 300px;')}

  /* Collegial-theme/light/on-secondary */

  background: #ffffff;
  /* Shadow-2 */

  box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  a&:hover {
    box-shadow: var(--box-shadow-large-hover);
  }
`;

const _CourseCardV2 = ({
  card,
  cancelOrder,
  isRecommended,
  noProviderLogo,
  sectionName,
  fullWidth,
  ...props
}: CourseCardProps): JSX.Element => {
  const { productType } = useProductTypeTranslations();

  const isCardDescrtionHidden = useFeatureEnabled(FEATURE.UI_CARD_DESCRIPTION_HIDDEN);

  const isCollection = isCollectionCard(card);
  const isEvent = ['seminar', 'webinar'].includes(card.type);
  const isCancellable = !!cancelOrder;
  const { provider, image, title, teaser, type } = card;

  let participation, customLink, level, totalEffort;
  if (isProgressCard(card)) ({ participation, level, totalEffort } = card);
  if (isProductCard(card)) ({ customLink } = card);

  const assignmentCard = card as MyAssignmentCard;
  const isMandatory = props.isMandatory !== undefined ? props.isMandatory : assignmentCard.mandatory;
  const { state } = card as InventoryProductCard;
  const isApproved = state === 'approved' || !!participation;
  const providerMeta = getCardProviderMeta(card);
  const showExpiration = isCardCompletionAboutToExpire(card);

  return (
    <CourseCardWrapperV2
      Component={StyledWrapperV2}
      customLink={customLink}
      link={getLink(card)}
      isApproved={isApproved}
      provider={provider}
      ariaLabel={title.trim()}
      isMandatory={isMandatory}
      isCollection={isCollection}
      entityId={card.entityId}
      isApprovalRequired={card.isApprovalRequired}
      title={title}
      isExternal={card.isExternal}
      sectionName={sectionName ?? ''}
      fullWidth={fullWidth}
    >
      <CourseCardImageV2
        image={image ? `${image}?width=420` : ''}
        providerMeta={noProviderLogo ? undefined : providerMeta}
        type={type}
        isCancellable={isCancellable}
        isCompleted={participation?.isCompleted && !showExpiration}
        fullWidth={fullWidth}
      />
      {isEvent && <CourseCardEventV2 card={card} participation={participation} />}
      {!isEvent && (
        <>
          <CourseCardBodyV2 withTags={isMandatory || isRecommended}>
            <Overline color="#6F797A">{productType(type)}</Overline>
            <Title4>{replaceNbsps(title)}</Title4>
            {!isCardDescrtionHidden && <CourseCardBodyCaption>{teaser}</CourseCardBodyCaption>}
          </CourseCardBodyV2>
          {(isMandatory || isRecommended) && <CourseCardHeaderLabelsV2 isMandatory={isMandatory} isRecommended={isRecommended} />}
          <CourseCardFooter
            isCollection={isCollection}
            itemCount={isCollectionCard(card) ? card.items?.length : 0}
            level={level}
            totalEffort={totalEffort}
            participation={participation}
          />
        </>
      )}
    </CourseCardWrapperV2>
  );
};

const LoadingState = ({ minWidth, containerFullWidth }: SkeleteonCourseCardProps): JSX.Element => {
  const isCardDescrtionHidden = useFeatureEnabled(FEATURE.UI_CARD_DESCRIPTION_HIDDEN);

  return (
    <StyledWrapperV2 $fullWidth={containerFullWidth} size={isCardDescrtionHidden ? 'sm' : 'lg'}>
      <div>{!!minWidth ? <Skeleton minwidth={300} height={155} /> : <Skeleton width={300} height={155} />}</div>
      <CourseCardBodyV2>
        <Skeleton width={142} height={16} />
        <Skeleton width={268} height={32} />
        {!isCardDescrtionHidden && (
          <>
            <Skeleton width={268} height={16} />
            <Skeleton width={268} height={16} />
            <Skeleton width={268} height={16} />
          </>
        )}
      </CourseCardBodyV2>
      <StyledFooterV2>
        <Skeleton width={174} height={16} />
      </StyledFooterV2>
    </StyledWrapperV2>
  );
};

const CourseCardV2 = makeNamespacedComponent(_CourseCardV2, {
  LoadingState,
});

export { CourseCardV2 };
