import { ApolloError } from '@apollo/client';

import { RLearningJourneySessionQuery, useLearningJourneySessionQueryRemote } from '@/store/v2';

export const useLearningJourneySession = ({
  id,
  includeProgress,
}: {
  id: number;
  includeProgress?: boolean;
}): {
  journeySession: RLearningJourneySessionQuery['learningJourneySession'] | undefined;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useLearningJourneySessionQueryRemote({
    variables: { id, includeProgress: includeProgress || false },
  });

  return {
    loading,
    error,
    journeySession: data?.learningJourneySession || undefined,
  };
};
