import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BabelonNotification } from './types';
import { Notification } from './components/Notification';
import { LoadingHero } from '@/component/LoadingHero';
import { useNotifications, useMarkAllNotificationsAsSeen } from '@/store/notifications';
import { Paginator } from '@/components/Paginator/Paginator';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { Box } from '@/components/Box/Box';
import { useModalContext } from '@/components/Modal/ModalContext';

const PAGE_SIZE = 10;

export const NotificationsPage = (): JSX.Element => {
  const { t } = useTranslation('navbar');
  const [page, setPage] = useState(1);
  const { notifications, loading } = useNotifications();
  const { markAll } = useMarkAllNotificationsAsSeen();
  const { showConfirmationModal } = useModalContext();

  const pageBegin = (page - 1) * PAGE_SIZE;
  const pageCount = Math.ceil((notifications.length || 0) / PAGE_SIZE);

  const handleEmptyNotifications = async () => {
    showConfirmationModal({
      action: async () => {
        await markAll();
      },
      message: t('Are you sure that you want to clear all notifications?', { ns: 'discussions' })
    });
  };

  return (
    <>
      <section className="hero is-cls is-padded">
        <div className="hero-body">
          <div className="container">
            <Link to="/" className="page-back">
              &larr; {t('Back', { ns: 'common' })}
            </Link>
            <h1 className="header1 has-bottom-margin">{t('Notifications')}</h1>
            <ButtonList>
              <Button $icon="trash" onClick={handleEmptyNotifications}>
                {t('Clear', { ns: 'common' })}
              </Button>
            </ButtonList>
          </div>
        </div>
      </section>
      {loading ? (
        <LoadingHero />
      ) : (
        <section className="section is-padded has-background-white-ter">
          <div className="container">
            {notifications.slice(pageBegin, pageBegin + PAGE_SIZE).map((notification: BabelonNotification) => (
              <Notification key={notification.topLevelPostId} notification={notification} />
            ))}
            {notifications.length === 0 && <Box>{t('No notifications')}</Box>}
          </div>
        </section>
      )}
      {Boolean(pageCount) && (
        <section>
          <Paginator page={page} pageCount={pageCount} onPageChange={setPage} isPadded />
        </section>
      )}
    </>
  );
};
