import { ProgressStatus } from '../types';
import { SortPriorityOrder } from './constants';

export const getStrategicPriority = (status: ProgressStatus): number => {
  if (status === 'completed') {
    return SortPriorityOrder.Completed;
  } else if (status === 'not-started') {
    return SortPriorityOrder.NotStarted;
  } else {
    return SortPriorityOrder.Ongoing;
  }
};
