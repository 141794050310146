import { Provider } from '@/store/providers';
import { trackEvent } from './countly';

/**
 * Learnings
 */

export const trackEnrollLearningItemClicked = (buttonText: string): void =>
  trackEvent({ key: 'Clicked enroll', segmentation: { buttonText } });

export const trackUnEnrollLearningItemClicked = (): void => trackEvent({ key: 'Clicked cancel enrollment' });

export const trackSaveToMyLearningsLearningItemAdded = (): void =>
  trackEvent({ key: 'Added save to my learning', segmentation: { source: 'Learnings' } });

export const trackSaveToMyLearningsLearningItemRemoved = (): void =>
  trackEvent({ key: 'Removed save to my learning', segmentation: { source: 'Learnings' } });

export const trackDiscussionPostLearningItemAdded = (): void => trackEvent({ key: 'Added a post' });

export const trackDiscussionLearningItemCommented = (): void => trackEvent({ key: 'Commented on a post' });

export const trackLearningItemTabClicked = (tabName: string): void =>
  trackEvent({ key: 'Clicked tab on learning item page', segmentation: { tab: tabName } });

export const trackReflectionLearningItemAdded = (): void => trackEvent({ key: 'Added a reflection' });

export const trackReflectionLearningItemCommented = (): void => trackEvent({ key: 'Commented on a reflection' });

export const trackLearningCardClicked = (title: string, type: string, featuredFlag?: boolean | null): void =>
  trackEvent({
    key: 'Clicked learning card',
    segmentation: { title, type, featuredFlag: Boolean(featuredFlag).toString() }
  });

export const trackLoadMoreClicked = (): void => trackEvent({ key: 'Clicked load more' });

export const trackProviderClicked = (provider: Provider): void =>
  trackEvent({ key: 'Selected a provider in explore menu', segmentation: { provider } });

export const trackCategoryOrSubjectClicked = (category: string): void =>
  trackEvent({ key: 'Selected a category or subject in explore menu', segmentation: { category } });

export const trackSortingChanged = (sorting: string): void =>
  trackEvent({ key: 'Changed the sorting', segmentation: { sorting } });

export const trackFreeTextFilterUsed = (searchText: string): void =>
  trackEvent({ key: 'Used the free text filter', segmentation: { searchText } });

export const trackDurationFilterUsed = (duration: string): void =>
  trackEvent({ key: 'Used the duration filter', segmentation: { duration } });

export const trackTypeFilterUsed = (type: string): void => trackEvent({ key: 'Used the type filter', segmentation: { type } });

export const trackProviderFilterUsed = (provider: string): void =>
  trackEvent({ key: 'Used the provider filter', segmentation: { provider } });

export const trackCategoryFilterUsed = (category: string): void =>
  trackEvent({ key: 'Used the category filter', segmentation: { category } });

export const trackLanguageFilterUsed = (language: string): void =>
  trackEvent({ key: 'Used the language filter', segmentation: { language } });

export const trackAttributeFilterUsed = (attribute: string | number): void =>
  trackEvent({ key: 'Used the attribute filter', segmentation: { attribute: `${attribute}` } });

export const trackSubjectFilterUsed = (subject: string): void =>
  trackEvent({ key: 'Used the subject filter', segmentation: { subject } });

export const trackOpenExploreMenu = (): void => trackEvent({ key: 'Opened explore menu' });

export const trackOpenLearningCardFromSearchBar = (title: string): void =>
  trackEvent({ key: 'Opened a learning card from the search page', segmentation: { title } });

export const trackUseNavbarSearch = (searchText: string): void =>
  trackEvent({ key: 'Used navbar search', segmentation: { searchText } });

export const trackMarkAsCompletedClicked = (payload: { learningTitle: string; learningType: string }): void =>
  trackEvent({
    key: 'Clicked marked as completed on learning',
    segmentation: { title: payload.learningTitle, type: payload.learningType }
  });

export const trackClickedGoToLearning = (buttonText: string): void =>
  trackEvent({
    key: 'Clicked go to learning',
    segmentation: { buttonText }
  });
