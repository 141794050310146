import { useTheme } from 'styled-components';

import { Grid } from '@/ui/Grid/atoms';
import { PrimaryInformer } from '@/ui/Informer';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';

export type InformersProps = {
  goalReachedCount: number;
  timeSpent: number;
  goalLimitCount: number;
  reflectionsCount: number;
  status: 'completed' | 'ended';
};

export const Informers = ({
  goalReachedCount,
  goalLimitCount,
  timeSpent,
  reflectionsCount,
  status,
}: InformersProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Grid columns={2} rowGap={'large'} columnGap={'large'}>
      <PrimaryInformer
        title={`${goalReachedCount.toString()} out of ${goalLimitCount}`}
        label={'Weekly goals met'}
        sideBox={{
          background: theme.colors.tertiary.container,
          icon: ['far', 'bullseye-arrow'],
          size: 'medium',
          iconColor: theme.tones.tertiary[70],
        }}
      />
      <PrimaryInformer
        title={`${reflectionsCount.toString()} out of ${goalLimitCount}`}
        label={'Reflections'}
        sideBox={{
          background: theme.tones.error[95],
          icon: ['far', 'lightbulb'],
          size: 'medium',
          iconColor: theme.tones.error[60],
        }}
      />
      <PrimaryInformer
        title={formatToHoursAndMinutesFromSeconds(timeSpent)}
        label={'Total time'}
        sideBox={{
          background: theme.tones.primary[95],
          icon: 'timer',
          size: 'medium',
          iconColor: theme.tones.primary[70],
        }}
      />
      <PrimaryInformer
        title={status === 'completed' ? 'Completed' : 'Not completed'}
        label={'Status'}
        sideBox={{
          background: theme.tones.warning[95],
          icon: status === 'completed' ? 'check' : 'loader',
          size: 'medium',
          iconColor: theme.tones.secondary[70],
        }}
      />
    </Grid>
  );
};
