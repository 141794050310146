import { useParams } from 'react-router-dom';

import { CourseDetails } from './CourseDetails';
import { CourseStart } from './CourseStart';
import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import {
  LockedLearningWarningModal,
  useLockedLearningWarningModalProps,
} from '@/administration/pages/Journey/common/modals/LockedLearningWarningModal';
import { useCourseViewerV2 } from '@/administration/pages/Course/store/courseViewer/useCourseViewerV2';

export const CoursePage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const props = useCourseViewerV2({ id: Number(id) });

  const parsedCourseId = id ? parseInt(id) : NaN;

  const { currentItem, journeyTitle, journeyId } = useJourneyEnhancedNavigation({
    id: parsedCourseId,
  });
  const learningLockedModalProps = useLockedLearningWarningModalProps({
    parentJourneyId: journeyId,
    parentJourneyTitle: journeyTitle,
    isCurrentItemLock: currentItem?.isLocked,
  });

  return (
    <>
      {learningLockedModalProps && <LockedLearningWarningModal {...learningLockedModalProps} />}
      {props?.progress?.isStarted ? <CourseDetails {...props} /> : <CourseStart {...props} />}
    </>
  );
};
