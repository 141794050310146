import { createContext } from 'react';

type UnderlinedTabsContextState = {
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
};

export const Context = createContext<UnderlinedTabsContextState>({
  activeTab: 0,
  setActiveTab: () => {},
});
