import styled from 'styled-components';

import { LiveEventCalendarBlock } from '@/pages/LiveEvent/LiveEventCalendarBlock';
import { LiveEventLink } from '@/pages/LiveEvent/LiveEventLink';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';
import { Button } from '@/ui/Button';

type LiveEventHighlightsProps = Pick<
  LiveEvent,
  | 'id'
  | 'room'
  | 'title'
  | 'teaser'
  | 'address'
  | 'endDate'
  | 'isOnline'
  | 'startDate'
  | 'dateAndTime'
  | 'myEnrollment'
  | 'monthName'
  | 'dayOfMonth'
  | 'isStartingSoon'
>;

const StyledCard = styled.div`
  top: 120px;
  border: 1px solid ${(p) => p.theme.colors.neutral.outline1};
  padding: 32px;
  position: sticky;
  border-radius: 8px;
`;

const DateContainer = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

const DateBadge = styled.div`
  width: 68px;
  height: 68px;
  display: flex;
  padding: 16px;
  background: ${({ theme }) => theme.colors.secondary.container};
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
`;

const DateTitle = styled.h4`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`;

const CommonTitle = styled.h3`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const CalendarContainer = styled.div`
  margin: 24px 0 16px;
`;

const JoinNowButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`;

export const LiveEventHighlights = ({
  id,
  room,
  title,
  teaser,
  address,
  endDate,
  isOnline,
  startDate,
  monthName,
  dayOfMonth,
  dateAndTime,
  myEnrollment,
  isStartingSoon,
}: LiveEventHighlightsProps): JSX.Element => {
  const handleJoinNowClick = () => {
    window.open(address, '_blank');
  };

  return (
    <StyledCard>
      <DateContainer>
        <DateBadge>
          <DateTitle>{monthName}</DateTitle>
          <CommonTitle>{dayOfMonth}</CommonTitle>
        </DateBadge>
        <CommonTitle>{title}</CommonTitle>
      </DateContainer>
      <CalendarContainer>
        <LiveEventCalendarBlock
          id={id}
          room={room}
          title={title}
          teaser={teaser}
          address={address}
          endDate={endDate}
          startDate={startDate}
          dateAndTime={dateAndTime}
          myEnrollment={myEnrollment}
        />
      </CalendarContainer>
      <LiveEventLink address={address} isOnline={isOnline} linkMaxWidth="300px" />
      {isStartingSoon && isOnline && (
        <JoinNowButton variant="primary" onClick={handleJoinNowClick}>
          Join now
        </JoinNowButton>
      )}
    </StyledCard>
  );
};
