import React from 'react';

import { Button } from './Button';
import { ButtonProps } from './types';

interface LinkProps {
  href: string;
  download?: string;
  target?: string;
  rel?: string;
}

export const LinkButton = (props: ButtonProps | LinkProps): JSX.Element => <Button as="a" {...props} />;
