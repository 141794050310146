import styled, { css } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { HelperTextStyledProps, IconStyledProps, InputStyledProps, LabelStyledProps, TextFieldStyledProps } from './types';

const TextFieldDefaultCSS = css<TextFieldStyledProps>`
  // Next 2 lines needed to apply font theming for form elements
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  font-family: inherit;
  font-size: inherit;
  ${(p) => p.theme.typography.body4};
  transition: all 0.2s;
  background: ${(p) => p.theme.colors.neutral.background};
  &,
  &::placeholder {
    color: ${(p) => p.theme.colors.neutral.onContainer};
  }
  border: 1px solid ${(p) => p.theme.colors.neutral.onContainer};
  border-radius: 4px;
  padding: 10px 16px;

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${(p) => p.theme.colors.error.main};
    `}

  ${({ hasError, hasValue }) =>
    hasError &&
    hasValue &&
    css`
      outline: 1px solid ${(p) => p.theme.colors.error.main};
    `}
`;

const TextAreaDefaultCSS = css`
  resize: none;
`;

const InputDefaultCSS = css<InputStyledProps>`
  ${({ hasLeadingIcon }) =>
    hasLeadingIcon &&
    css`
      padding-left: 48px;
    `}
  ${({ hasTrailingIcon }) =>
    hasTrailingIcon &&
    css`
      padding-right: 32px;
    `}
`;

const LabelTransitionCSS = css<LabelStyledProps>`
  background: ${(p) => p.theme.colors.neutral.background};
  transform-origin: top left;
  left: 14px;
  transform: scale(0.75) translateY(-100%);
  padding: 0 5px;

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${(p) => p.theme.colors.error.main};
    `};
`;

const LabelDefaultCSS = css<LabelStyledProps>`
  max-width: 65%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  transition: all 0.2s;
  ${(p) => p.theme.typography.body2};
  color: ${(p) => p.theme.colors.neutral.onContainer};
  cursor: text;
  position: absolute;
  top: 9px;
  left: 16px;

  ${({ hasLeadingIcon }) =>
    hasLeadingIcon &&
    css`
      padding-left: 32px;
    `}

  ${(p) => p.hasValue && LabelTransitionCSS}
`;

const TextFieldHoverCSS = css<TextFieldStyledProps>`
  &:hover:not(:focus) {
    &,
    &::placeholder {
      color: ${(p) => p.theme.colors.neutral.onBackground};
    }
  }
`;

const InputHoverCSS = css<InputStyledProps>``;

const LabelHoverCSS = css<LabelStyledProps>`
  textarea:hover:not(:focus):not(:disabled) + &&,
  input:hover:not(:focus):not(:disabled) + && {
    ${(p) =>
      !p.hasError &&
      css`
        color: ${(p) => p.theme.colors.neutral.onBackground};
      `}
  }
`;

const TextFieldFocusCSS = css<TextFieldStyledProps>`
  &:focus {
    ${({ hasError }) =>
      hasError
        ? css`
            border: 1px solid ${(p) => p.theme.colors.error.main};
            outline: 1px solid ${(p) => p.theme.colors.error.main};
          `
        : css`
            border: ${(p) => p.theme.colors.primary.outline} solid 1px;
            outline: ${(p) => p.theme.colors.primary.outline} solid 1px;
          `}
  }
`;

const LabelFocusCSS = css<LabelStyledProps>`
  input:focus + &&,
  textarea:focus + && {
    ${LabelTransitionCSS}
  }
`;

const TextFieldDisabledCSS = css`
  &:disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      &,
      &::placeholder {
        color: ${(p) => p.theme.colors.neutral.disabled};
      }
      border: 1px solid ${(p) => p.theme.colors.neutral.disabled};
    }
  }
`;

const LabelDisabledCSS = css`
  textarea:disabled + &&,
  input:disabled + && {
    color: ${(p) => p.theme.colors.neutral.disabled};
  }
`;

export const TextFieldContainerStyled = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          display: flex;
        `
      : css`
          display: inline-flex;
        `}
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${TextFieldDefaultCSS};
  ${InputDefaultCSS};
  ${TextFieldFocusCSS};
  ${TextFieldHoverCSS};
  ${InputHoverCSS};
  ${TextFieldDisabledCSS};
`;

export const TextAreaStyled = styled.textarea<TextFieldStyledProps>`
  ${TextFieldDefaultCSS};
  ${TextAreaDefaultCSS};
  ${TextFieldFocusCSS};
  ${TextFieldHoverCSS};
  ${TextFieldDisabledCSS};
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  ${LabelDefaultCSS};
  ${LabelFocusCSS};
  ${LabelHoverCSS};
  ${LabelDisabledCSS};
`;

export const IconStyled = styled(Icon)<IconStyledProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ iconPosition }) =>
    iconPosition === 'end'
      ? css`
          right: 12px;
        `
      : css`
          left: 16px;
        `};
  ${({ hasError }) =>
    hasError &&
    css`
      path {
        fill: ${(p) => p.theme.colors.error.main};
      }
    `}

  ${({ visible }) =>
    !visible &&
    css`
      visibility: hidden;
    `}

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}
  
  ${({ disabled }) =>
    disabled &&
    css`
      path {
        fill: ${(p) => p.theme.colors.neutral.disabled};
      }
    `}
`;

export const RootStyled = styled.div<{ fullWidth?: boolean }>`
  flex-direction: column;
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          display: flex;
          width: 100%;
        `
      : css`
          display: inline-flex;
          max-width: min-content;
        `}
`;

export const HelperTextWrap = styled.div`
  margin: 5px 0 0 16px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const HelperTextStyled = styled.p<HelperTextStyledProps>`
  margin: 0;
  color: ${(p) => p.theme.colors.neutral.outline2};
  ${(p) => p.theme.typography.icon2};
  width: fit-content;

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${(p) => p.theme.colors.error.main};
    `}
`;
