import { useContext } from 'react';

import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';
import { InsertToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/InsertToolbarButton';
import { LinkToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/LinkToolbarButton';
import { MarkToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/MarkToolbarButton';
import { Divider, StyledToolbarGroup, ToolbarWrapper } from '@/component/customEditorV2/ui/fixedToolbar/styles';
import { ToggleToolbarButton } from '@/component/customEditorV2/ui/fixedToolbar/ToggleToolbarButton';

type ToolbarProps = {
  minimized?: boolean;
};

const MarkToolbarButtonsGroup = () => {
  return (
    <>
      <MarkToolbarButton type={'bold'} />
      <MarkToolbarButton type={'italic'} />
      <MarkToolbarButton type={'underline'} />
      <MarkToolbarButton type={'lineThrough'} />
    </>
  );
};

const ToggleToolbarButtonsGroup = () => (
  <>
    <ToggleToolbarButton type="heading" />
    <ToggleToolbarButton type="heading2" />
  </>
);

const ListsToobarButtonsGroup = () => (
  <>
    <ToggleToolbarButton type="bulleted-list" />
    <ToggleToolbarButton type="ordered-list" />
  </>
);

export const Toolbar = ({ minimized }: ToolbarProps): JSX.Element => {
  const { toggledFeatures } = useContext(CustomEditorContext);

  return (
    <ToolbarWrapper $minimized={minimized}>
      <StyledToolbarGroup>
        <ToggleToolbarButtonsGroup />
        <Divider minimized={minimized} />
        <MarkToolbarButtonsGroup />
        <Divider minimized={minimized} />
        <LinkToolbarButton />

        {toggledFeatures?.mediaFiles && (
          <>
            <InsertToolbarButton type="image" />
            <InsertToolbarButton type="document" />
            <InsertToolbarButton type="video" />
          </>
        )}

        <Divider minimized={minimized} />

        <ListsToobarButtonsGroup />

        {/* @todo Uncomment quiz questions */}
        {/*<InsertToolbarButton type="quizQuestion" />*/}
      </StyledToolbarGroup>
    </ToolbarWrapper>
  );
};
