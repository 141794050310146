import { Navigate, useLocation } from 'react-router-dom';

import { useCurrentUserAccess } from '@/store/currentUser';

export const ManagerNavigate = (): JSX.Element => {
  const { isManager } = useCurrentUserAccess();
  const location = useLocation();

  if (!isManager) {
    return <Navigate to={{ pathname: '/' }} state={{ from: location }} />;
  }

  return <Navigate to={{ pathname: '/management' }} state={{ from: location }} />;
};
