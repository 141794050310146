import React from 'react';

import { RootStyled } from './styles';

export type AvatarSize = 'small' | 'medium';

type AvatarProps = {
  size?: AvatarSize;
  outlined?: boolean;
  image?: string;
  text?: string;
};

export const Avatar = ({ size = 'medium', outlined, image, text = '' }: AvatarProps): JSX.Element => {
  return (
    <RootStyled size={size} outlined={outlined} image={image}>
      {image ? null : text}
    </RootStyled>
  );
};

export { AvatarsGroup } from './styles';
