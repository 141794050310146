import React, { useMemo } from 'react';
import { Composition } from 'atomic-layout';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Caption, Header4 } from '@/components/Typography/Typography';
import { getProviderCourseCount } from '@/pages/AllProviders/utils';
import { getIconSize } from '@/utils/getIconSize';
import { Catalog } from '@/store/catalog';
import { Provider, ProviderList, providers } from '@/store/providers';
import { useIsFeatureEnabled } from '@/feature-toggles';
import { StyledProviderBox } from '@/pages/AllProviders/components/StyledProviderBox';
import { ProviderIcon } from '@/component/ProviderIcon/ProviderIcon';

const NOT_LISTED_PROVIDERS: Provider[] = [ProviderList.CUSTOM_LINK, ProviderList.LEARNIFIER];

export const ProvidersList = ({ catalog }: { catalog: Catalog }): JSX.Element => {
  const { t } = useTranslation('providers');
  const isFeatureEnabled = useIsFeatureEnabled();
  const processedProviders = useMemo(
    () =>
      providers
        .filter(
          (provider) =>
            (!provider.feature || isFeatureEnabled(provider.feature)) && !NOT_LISTED_PROVIDERS.includes(provider.provider)
        )
        .map((provider) => ({ ...provider, coursesCount: getProviderCourseCount(catalog.cards, provider.provider) }))
        .sort((a, b) => b.coursesCount - a.coursesCount),
    [catalog.cards]
  );

  return (
    <Composition
      templateCols="minmax(0, 1fr)"
      templateColsMd="repeat(2, minmax(0, 1fr))"
      templateColsLg="repeat(3, minmax(0, 1fr))"
      gap={0.875}
      gapMd={1.75}
    >
      {processedProviders.map((provider) => {
        return (
          <NavLink key={provider.titleKey} to={`/learning/provider/${provider.provider}`}>
            <StyledProviderBox paddingSize="default" marginless border>
              <ProviderIcon provider={provider.provider} size={getIconSize(provider.provider)} />
              <div>
                <Header4 unpadded>{provider.name}</Header4>
                <Caption>
                  {provider.coursesCount} {t('learning items')}
                </Caption>
              </div>
            </StyledProviderBox>
          </NavLink>
        );
      })}
    </Composition>
  );
};
