export const ADMIN_ROUTE_PATH = {
  ORDER_HISTORY: '/administration/orders/history',
  APPROVALS: '/administration/approvals/content',
  CONTENT_PROVIDERS: '/administration/approvals/license',
  ASSIGN: '/administration/assign',

  ASSIGNMENT_GROUPS: '/administration/assignment-group',
  ASSIGNMENT_GROUP: '/administration/assignment-group/:id/:name/*',
  NEW_ASSIGNMENT_GROUP: '/administration/assignment-group/new/*',

  JOURNEYS: '/administration/journeys',

  PEOPLE: '/administration/people',

  USERS: '/administration/people/users',
  USER: '/administration/people/users/user/:id',

  TEAMS: '/administration/people/teams',
  TEAM: '/administration/people/teams/team/:id/*',
  TEAM_USERS: '/administration/people/teams/team/:id/users',
  TEAM_SUB_TEAMS: '/administration/people/teams/team/:id/subTeams',

  CATALOG: '/administration/catalog',
  COURSE_NEW: '/administration/catalog/course/new',
  COURSE: '/administration/catalog/course/:id',
  LEARNING: '/administration/catalog/:learningType/:learningId/*',
  LEARNING_NEW: '/administration/catalog/:learningType/new/*',

  COURSES_V2: '/administration/catalog/courses',
  PRACTICAL_ASSESSMENTS: '/administration/catalog/assessments',

  COLLECTION: '/administration/learning-collection/*',
  COLLECTION_ID: '/administration/learning-collection/:id/*',
  COLLECTION_NEW: '/administration/learning-collection/new/*',
  COLLECTION_PREVIEW: '/administration/learning-collection/:collectionId/:collectionName/preview',
};

export const ADMIN_ROUTE_RELATIVE_PATH = {
  ORDER_HISTORY: 'orders/history',
  APPROVALS: 'approvals/*',
  APPROVALS_CONTENT: 'content',
  CONTENT_PROVIDERS: 'license',
  ASSIGN: 'assign',

  ASSIGNMENT_GROUPS: 'assignment-group',
  ASSIGNMENT_GROUP: 'assignment-group/:id/:name/*',
  NEW_ASSIGNMENT_GROUP: 'assignment-group/new/*',

  USERS: 'users',
  USER: 'users/user/:id',

  TEAMS: 'teams/*',
  TEAM: 'teams/team/:id/*',
  TEAM_USERS: 'users',
  TEAM_SUB_TEAMS: 'subTeams',

  CATALOG: 'catalog/*',
  LEARNING: ':learningType/:learningId/*',
  LEARNING_NEW: ':learningType/new/*',

  COURSE_NEW: 'course/new/*',
  COURSE: 'course/:id/*',

  COURSES_V2: 'courses/:learningId/:name/*',
  PRACTICAL_ASSESSMENT: 'assessments/:learningId/:name/*',
  JOURNEYS: 'journeys',
  JOURNEY: ':journeyId/:name/*',
  JOURNEY_EDIT_LEARNINGS: 'editLearnings',
  JOURNEY_EDIT_PARTICIPANTS: 'editParticipants',
  JOURNEY_LIVE_EVENT_ATTENDANCE: 'learning/:learningId/attendance',
  JOURNEY_PUBLISH: 'publish',
  JOURNEY_INSIGHTS: 'insights',
  JOURNEY_LEARNING_INSIGHTS: 'learning/:learningId',

  COLLECTION: 'learning-collection/*',
  COLLECTION_ID: 'learning-collection/:id/*',
  COLLECTION_NEW: 'learning-collection/new/*',
  COLLECTION_PREVIEW: 'learning-collection/:collectionId/:collectionName/preview',
};
