import { findNodePath, PlateLeaf, PlateLeafProps, useEditorRef } from '@udecode/plate-common/react';
import { getParentNode } from '@udecode/plate-common';

import { HeadingBold, BodyBold } from '@/component/customEditorV2/ui/Typography';

export const BoldLeaf: React.FC<PlateLeafProps> = (props: any) => {
  const editor = useEditorRef();
  const { leaf, text, children, ...rest } = props;

  const path = findNodePath(editor, text);

  let isHeading = false;

  if (path) {
    const parentEntry = getParentNode(editor, path);

    if (parentEntry) {
      const [parentNode] = parentEntry;
      isHeading = parentNode.type === 'heading' || parentNode.type === 'heading2';
    }
  }

  const BoldComponent = isHeading ? HeadingBold : BodyBold;

  return (
    <PlateLeaf leaf={leaf} editor={editor} {...rest}>
      <BoldComponent>{children}</BoldComponent>
    </PlateLeaf>
  );
};
