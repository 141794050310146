import React, { useEffect, useMemo, useState } from 'react';

import {
  ActionsStyled,
  ContentStyled,
  EditorContainer,
  RootStyled,
  SubmissionContainer,
  TextStyled,
  TitleStyled,
} from './styles';
import { toHtml } from '@/component/customEditor/logic/serialization';
import { ViewPracticalAssessmentTextPageProps } from './types';
import { CustomEditorV2, EMPTY_VALUE } from '@/component/customEditorV2';
import { Button } from '@/ui/Button';
import { useCreateUserLearningSubmission } from '@/pages/PracticalAssessment/store/useCreateUserLearningSubmission';
import { SubmissionResult } from './SubmissionResult';
import {
  RUserLearningSubmissionReview,
  RUserLearningSubmissionReviewAssignment,
  RUserLearningSubmissionType,
  useUserLearningSubmissionsQueryRemote,
} from '@/store/v2';
import { useCurrentUser } from '@/store/currentUser';
import { Skeleton } from '@/ui/Skeleton';
import { AssessmentTab, AssessmentViewerTabs } from '../../../common/tabs/AssessmentViewerTabs';
import { AssessmentSubmitted } from '../../../common/banners';

export const ViewPracticalAssessmentTextPage = ({
  id,
  spaceId,
  description,
  title,
  remoteId: pageRemoteId,
  learningId,
  peerReviewEnabled,
  onSubmitted: onCompleted,
}: ViewPracticalAssessmentTextPageProps): JSX.Element => {
  const { createUserLearningSubmission, loading: creatingSubmission } = useCreateUserLearningSubmission();
  const [textToSubmit, setTextToSubmit] = useState(EMPTY_VALUE);
  const [submissionResult, setSubmissionResult] = useState<{ createdAt: string; submission: string }>();
  const [assessmentsToReview, setAssessmentsToReview] = useState<RUserLearningSubmissionReviewAssignment[]>([]);
  const [receivedReviews, setReceivedReviews] = useState<RUserLearningSubmissionReview[]>([]);

  const [activeTab, setActiveTab] = useState(AssessmentTab.MySubmission);

  const handleActiveTabChange = (tabValue: number) => {
    setActiveTab(tabValue);
  };

  const { user } = useCurrentUser();

  const {
    data: remoteSubmissions,
    loading: loadingSubmissions,
    refetch,
  } = useUserLearningSubmissionsQueryRemote({
    variables: {
      input: {
        learningIdsIN: learningId ? [learningId] : [],
        userIdsIN: user ? [user.id] : [],
        pageIdsIN: pageRemoteId ? [pageRemoteId] : [],
      },
    },
    skip: !pageRemoteId || !learningId || !user?.id,
  });

  useEffect(() => {
    if (remoteSubmissions?.userLearningSubmissions.length) {
      const currentRemoteSubmission = remoteSubmissions.userLearningSubmissions[0];

      if (currentRemoteSubmission) {
        if (currentRemoteSubmission.reviewAssignments) {
          setAssessmentsToReview(currentRemoteSubmission.reviewAssignments as RUserLearningSubmissionReviewAssignment[]);
        }

        if (currentRemoteSubmission.reviews) {
          setReceivedReviews(currentRemoteSubmission.reviews);
        }

        setSubmissionResult({
          createdAt: new Date(currentRemoteSubmission.createdAt).toISOString(),
          submission: currentRemoteSubmission.data.text,
        });
      }
    }
  }, [remoteSubmissions]);

  const handleSubmit = async () => {
    if (pageRemoteId && learningId) {
      await createUserLearningSubmission({
        pageId: pageRemoteId,
        learningId,
        submissionData: {
          text: textToSubmit,
        },
        assessmentType: RUserLearningSubmissionType.PracticalAssessmentText,
      });

      refetch();
    }
  };

  const hasReceivedReviews = Boolean(receivedReviews.length);
  const hasSubmitted = Boolean(submissionResult);
  const hasReviewedPeers = useMemo(
    () =>
      Boolean(assessmentsToReview.length) &&
      assessmentsToReview.every((assessment) => assessment.submission.reviews.some((review) => review.reviewerId === user?.id)),
    [assessmentsToReview, user]
  );
  const shouldShowSubmittedBanner = hasSubmitted && peerReviewEnabled;

  useEffect(() => {
    if (!hasSubmitted) return;

    if (peerReviewEnabled && !hasReviewedPeers) return;

    onCompleted();
  }, [hasSubmitted, hasReviewedPeers, peerReviewEnabled]);

  const renderSubmissionContainer = () => {
    if (loadingSubmissions) {
      return <Skeleton height={254} />;
    }

    if (submissionResult) {
      return (
        <SubmissionResult
          peerReviewEnabled={peerReviewEnabled}
          submissionText={submissionResult.submission}
          createdAt={submissionResult.createdAt}
          receivedReviews={receivedReviews}
        />
      );
    }

    return (
      <SubmissionContainer>
        <EditorContainer>
          <CustomEditorV2
            key={id}
            value={textToSubmit}
            onChange={(description) => setTextToSubmit(description)}
            style={{ fontSize: 14 }}
            $fullSize
            minimized
            placeholder="Write here"
            spaceId={spaceId}
          />
        </EditorContainer>
        <ActionsStyled>
          <Button variant="primary" onClick={handleSubmit} $loading={creatingSubmission}>
            Submit
          </Button>
        </ActionsStyled>
      </SubmissionContainer>
    );
  };

  return (
    <RootStyled>
      <ContentStyled>
        <TitleStyled>{title}</TitleStyled>
        <TextStyled className="content is-article" dangerouslySetInnerHTML={{ __html: toHtml(description) }}></TextStyled>
        {shouldShowSubmittedBanner && (
          <AssessmentSubmitted
            hasReviewedPeers={hasReviewedPeers}
            hasReceivedReviews={hasReceivedReviews}
            onReviewPeersClick={() => setActiveTab(AssessmentTab.PeersToReview)}
            onCheckReviewClick={() => setActiveTab(AssessmentTab.MySubmission)}
          />
        )}
        {peerReviewEnabled ? (
          <AssessmentViewerTabs
            submissionFormSection={renderSubmissionContainer()}
            submitted={!!submissionResult}
            assessmentsToReview={assessmentsToReview}
            pageRemoteId={pageRemoteId}
            type="text"
            activeTab={activeTab}
            onActiveTabChange={handleActiveTabChange}
            spaceId={spaceId}
          />
        ) : (
          renderSubmissionContainer()
        )}
      </ContentStyled>
    </RootStyled>
  );
};
