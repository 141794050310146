import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { ContentStyledProps, InformerSize, SideBoxStyledProps } from './types';

export const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const informerSizeStyle: Record<InformerSize, FlattenSimpleInterpolation> = {
  small: css`
    height: 36px;
    width: 32px;
  `,
  medium: css`
    width: 44px;
    height: 44px;
  `,
  large: css`
    width: 68px;
    height: 68px;
  `,
} as const;

export const SideBoxStyled = styled.div<SideBoxStyledProps>`
  ${({ size }) => informerSizeStyle[size || 'large']}
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }) => background};
  svg {
    ${({ size }) =>
      size === 'small' || size === 'medium'
        ? css`
            height: 16px;
            width: 16px;
          `
        : css`
            height: 36px;
            width: 36px;
          `}

    path {
      fill: ${({ iconColor, theme: { colors } }) => (!!iconColor ? iconColor : colors.neutral.background)};
    }
  }
`;

export const ContentStyled = styled.div<ContentStyledProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ minimized }) => (minimized ? '0px' : '2px')};
  white-space: nowrap;
`;

export const TitleContainerStyled = styled.div`
  display: inline-flex;
  gap: 8px;
`;

export const LabelStyled = styled.div`
  ${(p) => p.theme.typography.body3};
  color: ${(p) => p.theme.colors.neutral.onContainer};
`;

export const TitleStyled = styled.div`
  ${(p) => p.theme.typography.title4};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const TitleSuffixStyled = styled.div`
  display: flex;
  align-items: center;
`;
