import React, { useEffect, useRef, useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import Player from '@vimeo/player';
import { withRef } from '@udecode/cn';
import { ResizableProvider } from '@udecode/plate-resizable';
import { withHOC, findNodePath, useEditorRef } from '@udecode/plate-common/react';
import { setNodes, removeNodes, TElement } from '@udecode/plate-common';
import { useMediaState } from '@udecode/plate-media/react';

import { useFileUploader } from '@/component/FileUpload/useFileUpload';
import { useVideoUploadProgress } from '@/component/customEditorV2/ui/elements/Video/hooks/useVideoUploadProgress';
import { VideoLinkModal } from '@/component/customEditorV2/ui/elements/Video/VideoLinkModal';
import { useVideoLink } from '@/component/FileUpload/useVideoLink';
import { UploadedVideo } from '@/component/customEditorV2/ui/elements/Video/UploadedVideo';
import { VideoElementPlaceholder } from '@/component/customEditorV2/ui/elements/Video/VideoElementPlaceholder';
import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';
import { UploadedFileVideo } from '@/component/FileUpload/types';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { MediaElementUploadProgress } from '@/component/customEditorV2/ui/elements/MediaElementUploadProgress';

export const VideoElement = withHOC(
  ResizableProvider,
  withRef<typeof PlateElement>(({ className, ...props }, ref) => {
    const videoLink = props?.element?.videoLink as { embedUrl: string };
    const uploadedVideo = props?.element?.uploadedVideo as UploadedFileVideo;
    const { spaceId } = useContext(CustomEditorContext);
    const editor = useEditorRef();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [vimeoPlayer, setVimeoPlayer] = useState<Player>();
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
    const videoRef = useRef<HTMLDivElement>(null);
    const [playerElementId] = useState(`vimeo-${uuid()}`);
    const [localRatio, setLocalRatio] = useState(uploadedVideo?.ratio || 0.6015625);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { getEmbedUrlType } = useVideoLink();
    const { readOnly } = useMediaState();

    const videoUpload = useFileUploader<'video'>({
      spaceId,
      type: 'video',
      onUploadStart: () => {},
      onUploadSuccess: ({ vimeoId, ratio, thumbnail, duration }) => onSuccessfulUpload({ vimeoId, ratio, thumbnail, duration }),
    });

    const transcodingInProgress = videoUpload.uploading && videoUpload.uploadingState === 'Transcoding';

    const { current: currentProgress, prev: prevProgress } = useVideoUploadProgress({
      transcodingInProgress,
      vimeoProgress: videoUpload.uploadingProgress,
      maxVisualUploadingProgress: 50,
    });

    const handleUploadClick = () => {
      fileInputRef?.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0];

      if (file) {
        videoUpload.uploadFile(file);
      }
    };

    const handleOpenLinkModalClick = () => {
      setIsLinkModalOpen(true);
    };

    const handleCloseLinkModalClick = () => {
      setIsLinkModalOpen(false);
    };

    const handleDeleteClick = () => {
      const path = findNodePath(editor, props.element);

      removeNodes(editor, {
        at: path,
      });
    };

    const handleReplaceClick = () => {
      const path = findNodePath(editor, props.element);

      setNodes<TElement>(
        editor,
        { videoLink: undefined, uploadedVideo: {} },
        {
          at: path,
        }
      );
    };

    const onSuccessfulUpload = ({ vimeoId, ratio, thumbnail, duration }: UploadedFileVideo) => {
      const path = findNodePath(editor, props.element);

      setNodes<TElement>(
        editor,
        { uploadedVideo: { vimeoId, ratio, thumbnail, duration } },
        {
          at: path,
        }
      );
    };

    const createPlayer = () => {
      if (uploadedVideo?.vimeoId && videoRef.current) {
        return new Player(videoRef.current, {
          id: parseInt(uploadedVideo?.vimeoId),
          width: 800,
          height: 450,
          speed: true,
        });
      }

      return null;
    };

    const handleLinkSubmit = (link: string) => {
      const path = findNodePath(editor, props.element);

      setNodes<TElement>(
        editor,
        { videoLink: getEmbedUrlType(link) },
        {
          at: path,
        }
      );
    };

    useEffect(() => {
      const player = createPlayer();

      if (player) {
        setVimeoPlayer(player);

        if (uploadedVideo?.ratio) {
          Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(([width, height]) => {
            setLocalRatio(height / width);
          });
        }
      }
    }, [uploadedVideo]);

    if (readOnly && !(videoLink || uploadedVideo?.vimeoId)) {
      return null;
    }

    return (
      <PlateElement ref={ref} className={className} {...props}>
        <figure contentEditable={false}>
          <VideoLinkModal open={isLinkModalOpen} onClose={handleCloseLinkModalClick} onSubmit={handleLinkSubmit} />
          {!!videoUpload?.uploading && (
            <MediaElementUploadProgress prevProgress={prevProgress} currentProgress={currentProgress} />
          )}
          {!!(videoLink || uploadedVideo?.vimeoId) && (
            <UploadedVideo
              videoLink={videoLink}
              videoRef={videoRef}
              uploadedVideoState={uploadedVideo}
              playerElementId={playerElementId}
              localRatio={localRatio}
              onDeleteClick={handleDeleteClick}
              onReplaceClick={handleReplaceClick}
            />
          )}
          {!videoUpload?.uploading && !videoLink && !uploadedVideo?.vimeoId && (
            <VideoElementPlaceholder
              onFileChange={handleFileChange}
              onOpenLinkModalClick={handleOpenLinkModalClick}
              onUploadClick={handleUploadClick}
              fileInputRef={fileInputRef}
              onDeleteClick={handleDeleteClick}
            />
          )}
          {props?.children}
        </figure>
      </PlateElement>
    );
  })
);
