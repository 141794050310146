import React, { FC, MouseEvent } from 'react';
import { Editor } from 'slate';

import { useMarks } from '@/component/customEditor/hooks/useMarks';
import { Button } from '@/components/Button/Button';
import { IconPropType } from '@/ui/Icon';
import { TextFormats } from '@/types/slate';

interface MarkSectionProps {
  editor: Editor;
}

export const MarkSection: FC<MarkSectionProps> = () => {
  const { toggleMark, hasMark } = useMarks();

  const renderMarkButton = (type: TextFormats, label: IconPropType) => (
    <Button $type="base" $icon={label} $toggled={hasMark(type)} onMouseDown={(event) => onMarkClick(event, type)} tabIndex={0} />
  );

  const onMarkClick = (e: MouseEvent, type: TextFormats) => {
    e.preventDefault();
    toggleMark(type);
  };

  return (
    <>
      {renderMarkButton('bold', 'bold')}
      {renderMarkButton('italic', 'italic')}
      {renderMarkButton('underline', 'underline')}
      {renderMarkButton('lineThrough', 'strikethrough')}
    </>
  );
};
