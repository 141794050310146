import { useContext } from 'react';
import { Editor } from 'slate';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import { insertImage, insertImageLoading, removeImageLoading } from '@/component/customEditor/hoc/withImages';
import { useFileUploader } from '@/component/FileUpload/useFileUpload';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';

export const useEditorImageUpload = ({ spaceId, editor }: { spaceId?: number; editor: Editor }): ((file: File) => void) => {
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { t } = useTranslation('uploaders');

  const documentUpload = useFileUploader({
    spaceId,
    type: 'image',
    onUploadStart: (fileLoadingId) => {
      if (fileLoadingId) {
        insertImageLoading(editor, fileLoadingId);
      }
    },
    onUploadSuccess: (file, fileLoadingId) => {
      if (file.url) {
        insertImage(editor, file.url, fileLoadingId);
      }
    },
    onUploadFail: (fileLoadingId) => {
      if (fileLoadingId) {
        removeImageLoading(editor, fileLoadingId);
        openSnackbar({ type: SnackbarType.DANGER, message: t('something-went-wrong') });
      }
    },
    onValidationFail: (fileLoadingId) => {
      if (fileLoadingId) {
        removeImageLoading(editor, fileLoadingId);
        openSnackbar({ type: SnackbarType.DANGER, message: t('image-too-large') });
      }
    },
  });

  const uploadImage = (file: File) => {
    documentUpload.uploadFile(file, `uploading-img-${uuid()}`);
  };

  return uploadImage;
};
