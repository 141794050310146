import { withRef } from '@udecode/cn';

import { ListItemPlateElement as ListItem } from '@/component/customEditorV2/ui/Typography';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';

export const ListItemElement = withRef<typeof PlateElement>(({ children, className, ...props }, ref) => {
  return (
    <ListItem ref={ref} className={className} {...props}>
      {children}
    </ListItem>
  );
});
