import React from 'react';

import { getProgressState } from './helpers';
import { LabelStyled, ProgressBarStyled, RootStyled } from './styles';
import { ProgressBarProps } from './types';

export const ProgressBar = ({
  animated,
  animationStartPercentage = 0,
  showLabel,
  rounded = true,
  className,
  ...restProps
}: ProgressBarProps): JSX.Element => {
  const { percentage, labelValue } = getProgressState(restProps);

  return (
    <RootStyled className={className}>
      <ProgressBarStyled
        percentage={percentage}
        animated={animated}
        rounded={rounded}
        animationStartPercentage={animationStartPercentage}
      />
      {showLabel && <LabelStyled>{labelValue}</LabelStyled>}
    </RootStyled>
  );
};
