import React, { ErrorInfo, Component } from 'react';

import { Button } from '@/components/Button/Button';

interface Props {
  children?: JSX.Element | JSX.Element[];
  errorInfo?: ErrorInfo;
}

interface State {
  hasError: boolean;
  error?: { toString: () => string };
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    console.error(error, errorInfo);
  }

  resetError = (): void => {
    window.location.href = '/';
  };

  render(): JSX.Element | JSX.Element[] | undefined {
    if (!this.state.hasError) return this.props.children;

    return (
      <>
        <section className="hero is-cls is-padded">
          <div className="hero-body">
            <div className="container">
              <h1 className="header1">Something went wrong</h1>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo?.componentStack}
              </details>
            </div>
          </div>
        </section>
        <div className="section is-padded">
          <div className="container">
            <Button $type="primary" onClick={this.resetError}>
              Reset application
            </Button>
          </div>
        </div>
      </>
    );
  }
}
