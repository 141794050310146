import styled from 'styled-components';

/**
 * This component displays simple HTML markup from a WYSIWYG editor,
 * so we need to apply some tag-based CSS for all the elements the editor supports.
 */
export const HeroDescription = styled.section`
  ${(p) => p.theme.typography.body1}
  color: ${(p) => p.theme.colors.neutral.onBackground};

  p,
  h2,
  h3,
  ul,
  ol {
    margin-bottom: 0.5em;
  }

  h2 {
    ${(p) => p.theme.typography.title2}
  }

  h3 {
    ${(p) => p.theme.typography.title3}
  }

  ul {
    list-style-type: initial;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
`;
