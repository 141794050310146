import styled from 'styled-components';

import { IconButton } from '@/ui/Button';

export const Close = styled(IconButton).attrs({
  buttonVariant: 'dark',
  icon: 'xmark',
})`
  grid-area: close;
  justify-self: right;
  align-self: center;
  transform: translateX(12px);
`;
