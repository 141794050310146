/* eslint-disable import/no-unused-modules */
import React from 'react';
import { useTheme } from 'styled-components';

import { Icon, IconPropType } from '@/ui/Icon';
import { ContentBoxStyled, InformerBox, ReflectNowButton, ReflextionTextStyled, RootStyled, TitleStyled } from './styles';

export type ReflectionBoxProps = {
  reflection?: string;
  onReflect?: () => void;
};

type ReflectionUIState = {
  informerBoxBg: string;
  informerIcon: IconPropType;
  informerIconColor: string;
  reflectionBoxContent: JSX.Element;
};

export const ReflectionBox = ({ reflection, onReflect }: ReflectionBoxProps): JSX.Element => {
  const theme = useTheme();

  const reflectionUIState: ReflectionUIState = reflection
    ? {
        informerBoxBg: theme.colors.tertiary.container,
        informerIcon: 'lightbulb',
        informerIconColor: theme.colors.neutral.onBackground,
        reflectionBoxContent: <>{reflection}</>,
      }
    : {
        informerBoxBg: theme.colors.error.outline,
        informerIcon: 'lightbulb-exclamation',
        informerIconColor: theme.colors.neutral.background,
        reflectionBoxContent: (
          <>
            <span>No reflection registered this week.</span>
            <ReflectNowButton variant="link" onClick={onReflect}>
              Reflect now
            </ReflectNowButton>
          </>
        ),
      };

  return (
    <RootStyled>
      <InformerBox background={reflectionUIState.informerBoxBg}>
        <Icon icon={reflectionUIState.informerIcon} color={reflectionUIState.informerIconColor} />
      </InformerBox>
      <ContentBoxStyled>
        <TitleStyled>Your reflection</TitleStyled>
        <ReflextionTextStyled>{reflectionUIState.reflectionBoxContent}</ReflextionTextStyled>
      </ContentBoxStyled>
    </RootStyled>
  );
};
