import { gql, FetchResult, useMutation, useQuery, ApolloQueryResult } from '@apollo/client';

import { CatalogEntities } from '../types/learning/learning-catalog';
import { HookResult } from './apolloClient';

export interface LearningPinnedUpdateInput {
  realm: string;
  entity: CatalogEntities;
  entityId: number;
}

export interface LearningPinned {
  realm: string;
  learningId: number | null;
  learningPathId: number | null;
  learningJourneyId: number | null;
  learningCollectionId: number | null;
  position: number;
}

export const LEARNINGS_PINNED_QUERY_OPERATION_NAME = `learningsPinned`;
export const LEARNING_PINNED_FRAGMENT = gql`
  fragment LearningPinnedFragment on LearningPinned {
    realm
    learningId
    learningPathId
    learningCollectionId
    learningJourneyId
    position
  }
`;

export const PINNED_LEARNING_QUERY = gql`
  query learningsPinned($realm: String!) {
    learningsPinned(realm: $realm) {
      ...LearningPinnedFragment
    }
  }
  ${LEARNING_PINNED_FRAGMENT}
`;

export const UPDATE_PINNED_LEARNING_MUTATION = gql`
  mutation learningPinnedUpdate($realm: String!, $input: [LearningPinnedUpdateInput!]!) {
    learningPinnedUpdate(realm: $realm, input: $input) {
      ...LearningPinnedFragment
    }
  }
  ${LEARNING_PINNED_FRAGMENT}
`;

interface LearningPinnedResponse extends HookResult {
  learningsPinned: readonly LearningPinned[];
  refetch: () => Promise<ApolloQueryResult<{ learningsPinned: ReadonlyArray<LearningPinned> }>>;
}

export const useLearningPinned = (realm: string, skip?: boolean): LearningPinnedResponse => {
  const { data, loading, refetch, error, networkStatus } = useQuery<{ learningsPinned: LearningPinned[] }>(
    PINNED_LEARNING_QUERY,
    {
      variables: { realm },
      skip,
    }
  );

  const learningsPinned: ReadonlyArray<LearningPinned> = data?.learningsPinned || [];

  return {
    learningsPinned,
    loading,
    refetch,
    error,
    networkStatus,
  };
};

interface LearningPinnedUpdateResponse {
  learningPinnedUpdate: (
    realm: string,
    input: LearningPinnedUpdateInput[]
  ) => Promise<FetchResult<{ learningPinnedUpdate: LearningPinned[] }>>;
  loading: boolean;
}

export const useLearningPinnedUpdate = (): LearningPinnedUpdateResponse => {
  const [learningPinnedUpdate, { loading }] =
    useMutation<{ learningPinnedUpdate: LearningPinned[] }>(UPDATE_PINNED_LEARNING_MUTATION);

  return {
    learningPinnedUpdate: async (realm: string, input: LearningPinnedUpdateInput[]) =>
      learningPinnedUpdate({
        variables: { realm, input },
        refetchQueries: [LEARNINGS_PINNED_QUERY_OPERATION_NAME],
      }),
    loading,
  };
};
