import { gql, useMutation, useQuery, MutationTuple, ApolloError, ApolloQueryResult } from '@apollo/client';

import { HookResult } from '../apolloClient';
import { ProviderList } from '@/store/providers';
import { useEffortForRealmQueryRemote } from '@/store/v2';
import { useCurrentUser } from '@/store/currentUser';
import { EffortForRealm, EffortPerProvider } from '@/types/analytics';

const getTimePerProvider = gql`
  query getTotalTimeSpentPerProvider {
    getTotalTimeSpentPerProvider {
      effort
      provider
    }
  }
`;

const SET_LEARNING_COMPLETED = gql`
  mutation completeLearning($learningId: Int!) {
    completeLearning(learningId: $learningId)
  }
`;

export const getEffortForUser = gql`
  query getUserEffort($from: String, $to: String) {
    getUserEffort(from: $from, to: $to) {
      total
    }
  }
`;

interface SetLearningCompletedResponse {
  setLearningCompleted: MutationTuple<any, { learningId: number }>[0];
  loading: boolean;
  error?: ApolloError;
}

export const useSetLearningCompleted = (learningId: number, onCompleted?: () => void): SetLearningCompletedResponse => {
  const [setLearningCompleted, { loading, error }] = useMutation<any, { learningId: number }>(SET_LEARNING_COMPLETED, {
    variables: { learningId },
    onCompleted,
    update(cache, { data, errors }) {
      if (!data || Boolean(errors)) return;
      const cacheId = cache.identify({ __typename: 'Learning', id: learningId });

      // fetch any existing fragment from cache to use the expiredAt value
      const fromCache: { progress: { __typename: string; expiredAt: string } } | null = cache.readFragment({
        id: cacheId,
        fragment: gql`
          fragment LearningProgress on Learning {
            progress {
              __typename
              expiredAt
            }
          }
        `,
      });

      cache.writeFragment({
        id: cacheId,
        fragment: gql`
          fragment LearningProgress on Learning {
            progress {
              completed
              completedPercentage
              historical
              expiredAt
            }
          }
        `,
        data: {
          progress: {
            __typename: fromCache?.progress?.__typename,
            completed: true,
            completedPercentage: 100,
            historical: false,
            expiredAt: fromCache?.progress?.expiredAt || null,
          },
        },
      });
    },
  });
  return {
    setLearningCompleted,
    loading,
    error,
  };
};

interface TimePerProviderResponse extends HookResult {
  timePerProvider: EffortPerProvider[];
  refetch: () => Promise<ApolloQueryResult<{ getTotalTimeSpentPerProvider: EffortPerProvider[] }>>;
}

export const useTimePerProvider = (provider: ProviderList.PLURALSIGHT): TimePerProviderResponse => {
  const { data, error, refetch, networkStatus, loading } =
    useQuery<{ getTotalTimeSpentPerProvider: EffortPerProvider[] }>(getTimePerProvider);

  const time: EffortPerProvider[] = data?.getTotalTimeSpentPerProvider || [];

  const timePerProvider = provider ? time.filter((entry) => entry.provider === provider) : time;

  return {
    timePerProvider,
    error,
    refetch,
    networkStatus,
    loading,
  };
};

interface EffortPerRealmResponse extends HookResult {
  effortPerRealm: EffortForRealm[];
}

export const useEffortPerRealm = ({ from, to }: { from?: string; to?: string }): EffortPerRealmResponse => {
  const { user } = useCurrentUser();

  const { loading, refetch, networkStatus, error, data } = useEffortForRealmQueryRemote({
    variables: {
      fromTime: from,
      toTime: to,
      realm: user?.realm || '',
    },
    skip: !user?.realm,
  });

  const effortPerRealm: EffortForRealm[] =
    data?.reportingAggregationEffortForRealm?.map((data) => ({
      average_effort_per_user: data.averageEffort || 0,
      total_effort_for_realm: data.totalEffort || 0,
      p90: data.p90Effort || 0,
      p80: data.p80Effort || 0,
      p70: data.p70Effort || 0,
      p60: data.p60Effort || 0,
      p50: data.p50Effort || 0,
    })) || [];

  return {
    effortPerRealm,
    error,
    refetch,
    networkStatus,
    loading,
  };
};

interface EffortForUserResponse extends HookResult {
  refetch: () => Promise<ApolloQueryResult<{ getUserEffort: { total: number } }>>;
  effortForUser: { total: number };
}

export function useEffortForUser({ from, to }: { from?: string; to?: string }): EffortForUserResponse {
  const { data, error, refetch, networkStatus, loading } = useQuery<{ getUserEffort: { total: number } }>(getEffortForUser, {
    variables: { from, to },
  });

  const effortForUser = data?.getUserEffort ?? { total: 0 };

  return {
    effortForUser,
    error,
    refetch,
    networkStatus,
    loading,
  };
}
