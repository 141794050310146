import React, { useState } from 'react';

import { ACTIVITY_TYPE_TO_ICON } from '@/administration/pages/Course/MainLayout/variants/View/types';
import { Icon } from '@/ui/Icon';
import { Activity, ActivityContent, ActivityType } from '@/administration/pages/Course/store/activity/types';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { IconPropType } from '@/types/icon';
import { ActivityId } from '@/administration/pages/Course/store/types';
import {
  StyledCollapseIcon,
  IconWrapStyled,
  TitleWrap,
  TitleText,
  RootStyled,
} from '@/administration/pages/Course/MainLayout/variants/View/atoms/ViewItemV2/styles';

export type ViewItemV2Props = {
  id: string;
  content: ActivityContent;
  type: ActivityType;
  active?: boolean;
  onSelect: (id: ActivityId) => void;
  completed?: boolean;
  isChild?: boolean;
  children?: Activity[];
};

const MAX_TITLE_LENGTH = 55;
const MAX_CHILD_TITLE_LENGTH = 40;

export const getShortenTitle = (title?: string, isChild?: boolean, parentLimit?: number, childLimit?: number): string => {
  if (!title) return '';

  const resultParentLimit = parentLimit || MAX_TITLE_LENGTH;
  const resultChildLimit = childLimit || MAX_CHILD_TITLE_LENGTH;

  const limit = isChild ? resultChildLimit : resultParentLimit;

  if (title?.length < limit) {
    return title;
  } else {
    return `${title?.slice(0, limit)}...`;
  }
};

const _ViewItemV2 = ({ id, content, active, type, onSelect, completed, isChild, children }: ViewItemV2Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const drawerItemType = completed ? 'completed' : type;

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleItemClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (type === ActivityType.Module) {
      handleCollapseClick();
    } else {
      onSelect(id as ActivityId);
    }
  };

  return (
    <>
      <RootStyled onClick={handleItemClick} active={!!active} isChild={isChild}>
        <TitleWrap>
          <IconWrapStyled type={drawerItemType}>
            <Icon size="xs" icon={ACTIVITY_TYPE_TO_ICON[drawerItemType] as IconPropType} />
          </IconWrapStyled>
          <TitleText title={content?.title || ''}>{getShortenTitle(content?.title || '', isChild)}</TitleText>
        </TitleWrap>
        {!!children?.length && (
          <StyledCollapseIcon icon={isCollapsed ? 'angle-down' : 'angle-up'} onClick={handleCollapseClick} />
        )}
      </RootStyled>
      {!isCollapsed &&
        children?.map((child) => {
          return (
            <_ViewItemV2 {...child} type={child?.type as unknown as ActivityType} key={child.id} isChild onSelect={onSelect} />
          );
        })}
    </>
  );
};

const LoadingState = (): JSX.Element => <Skeleton width={312} height={36} />;

export const ViewItemV2 = makeNamespacedComponent(_ViewItemV2, {
  LoadingState,
});
