import { createContext } from 'react';

import { SelectContextState } from '../types';

export const SelectContext = createContext<SelectContextState>({
  value: '',
  selectRef: null,
  onOptionClick: () => {},
  toggleShowOptions: () => {},
  setShouldShowOptions: () => {},
  shouldShowOptions: false
});
