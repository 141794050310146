import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { UnmountClosed } from 'react-collapse';

const GlobalCollapseStyle = createGlobalStyle`
  .ReactCollapse--collapse {
    transition: height 200ms;
  }
`;

export const Collapse = ({
  children,
  isOpened: isOpened,
  forceInitialAnimation = false,
}: {
  children?: React.ReactNode;
  isOpened: boolean;
  /**
   * If the child element is initially visible (`isOpened`),
   * setting this to `true` will trigger the reveal animation.
   */
   forceInitialAnimation?: boolean;
}): JSX.Element => {

  const [isReallyOpened, setIsReallyOpened] = useState(forceInitialAnimation ? false : isOpened);
  useEffect(() => setIsReallyOpened(isOpened), [isOpened]);
  useEffect(() => {
    if (forceInitialAnimation && isOpened) {
      const ref = setTimeout(() => setIsReallyOpened(true));
      return () => clearTimeout(ref);
    }
  }, []);

  return (
    <>
      <GlobalCollapseStyle />
      <UnmountClosed isOpened={isReallyOpened} >
        {children}
      </UnmountClosed>
    </>
  );
};
