import React from 'react';

import { CourseCard } from '@/components/CourseCard';
import { AssignmentLearningCard } from '@/store/utils/assignments/types';
import { CatalogEntities } from '@/types/learning/learning-catalog';
import { numberOfCards } from './constants';
import { AssignmentSectionDetails } from './types';
import { sortOrder, sortStringOrder } from '@/store/utils';
import { trackHomepageLearningCardClicked } from '@/utils/tracking/homePage';

const SortPriorityOrder = {
  MandatoryOngoing: 1,
  MandatoryNotStarted: 2,
  StrategicOngoing: 3,
  StrategicNotStarted: 4,
  RecommendedOngoing: 5,
  RecommendedNotStarted: 6,
};

type AssignedCardWithSort = { card: JSX.Element; sortPriorityOrder: number; title: string; id: number; entity: CatalogEntities };

export const getAssignedCards: AssignmentSectionDetails = ({ assignmentPages, showAssignmentCards }) => {
  const cards = showAssignmentCards
    ? []
    : assignmentPages
        .map((x) => {
          if (x.type === 'STANDARD') {
            if (x.cards) {
              return x.cards
                .filter((m): m is AssignmentLearningCard<'STANDARD'> => !!m)
                .map((m) => {
                  return m.isMandatory
                    ? {
                        card: (
                          <CourseCard
                            card={m}
                            key={`${x.id}-${m.id}`}
                            onClick={() => trackHomepageLearningCardClicked(m.title, 'assigned')}
                          />
                        ),
                        sortPriorityOrder:
                          m.status === 'ongoing' ? SortPriorityOrder.MandatoryOngoing : SortPriorityOrder.MandatoryNotStarted,
                        title: m.title,
                        id: m.entityId,
                        entity: m.entity,
                      }
                    : {
                        card: (
                          <CourseCard
                            card={m}
                            key={`${x.id}-${m.id}`}
                            onClick={() => trackHomepageLearningCardClicked(m.title, 'assigned')}
                          />
                        ),
                        sortPriorityOrder:
                          m.status === 'ongoing' ? SortPriorityOrder.RecommendedOngoing : SortPriorityOrder.RecommendedNotStarted,
                        title: m.title,
                        id: m.entityId,
                        entity: m.entity,
                      };
                });
            }
          } else if (x.type === 'STRATEGIC') {
            if (x.cards) {
              return x.cards
                .filter((m): m is AssignmentLearningCard<'STRATEGIC'> => !!m)
                .map((m) => {
                  return {
                    card: (
                      <CourseCard
                        card={m}
                        key={`${x.id}-${m.id}`}
                        onClick={() => trackHomepageLearningCardClicked(m.title, 'assigned')}
                      />
                    ),
                    sortPriorityOrder:
                      m.status === 'ongoing' ? SortPriorityOrder.StrategicOngoing : SortPriorityOrder.StrategicNotStarted,
                    title: m.title,
                    id: m.entityId,
                    entity: m.entity,
                  };
                });
            }
          }
        })
        .flat()
        .filter((l): l is AssignedCardWithSort => !!l);

  const allAssigned = cards
    .sort((m, o) => sortOrder(m.sortPriorityOrder, o.sortPriorityOrder) || sortStringOrder(m.title, o.title))
    .map((c) => c.card);
  return {
    count: allAssigned.length,
    cards: allAssigned.slice(numberOfCards.startIndex, numberOfCards.endIndex),
  };
};
