import { gql, FetchResult, useMutation } from '@apollo/client';

import { CreateLearningLoopReflectionInput, CreateLearningLoopReflectionResult } from './types';
import { LEARNING_LOOPS_QUERY_OPERATION_NAME } from './useLearningLoops';

export const CREATE_LEARNING_LOOP_REFLECTION_MUTATION = gql`
  mutation createLearningLoopReflection($input: CreateLearningLoopReflectionInput!) {
    createLearningLoopReflection(input: $input) {
      learningLoop {
        id
        learningLoopId
        description
        applied
        createdAt
        updatedAt
      }
      success
    }
  }
`;

export const useCreateLearningLoopReflection = (): {
  createLearningLoopReflection: (
    input: CreateLearningLoopReflectionInput
  ) => Promise<FetchResult<{ createLearningLoopReflection: CreateLearningLoopReflectionResult }>>;
  loading: boolean;
  error: boolean;
} => {
  const [createLearningLoopReflection, { loading, error }] = useMutation<{
    createLearningLoopReflection: CreateLearningLoopReflectionResult;
  }>(CREATE_LEARNING_LOOP_REFLECTION_MUTATION, {
    refetchQueries: [LEARNING_LOOPS_QUERY_OPERATION_NAME],
  });

  return {
    createLearningLoopReflection: async (input: CreateLearningLoopReflectionInput) =>
      createLearningLoopReflection({
        variables: { input },
      }),
    loading,
    error: !!error,
  };
};
