import { v4 as uuid } from 'uuid';

import { RootStyled, TextEditorStyled } from '@/administration/pages/Course/pages/text/variants/Edit/styles';
import { CustomEditorV2 } from '@/component/customEditorV2';
import { ELEMENT_QUIZ_QUESTION } from '@/component/customEditorV2/plugins/custom/quizQuestionPlugin';
import { QuizQuestionType } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/Edit/QuizQuestionEditElement';
import { QuizBlock } from '@/administration/pages/Course/store/block/types';

type EditQuizPageProps = {
  id: string;
  spaceId: number;
  text: string;
  onTextChange: (text?: string, formattedQuestions?: QuizBlock) => void;
};

const getQuestions = (text?: string) => {
  if (!text) return [];

  const parsedText = JSON.parse(text);

  if (Array.isArray(parsedText) && !parsedText?.length) return [];

  const questions = parsedText.filter((block: QuizQuestionType) => {
    return block?.type === ELEMENT_QUIZ_QUESTION;
  });

  const formattedQuestions = questions.map((questionItem: QuizQuestionType, index: number) => {
    return {
      id: questionItem?.id || uuid(),
      title: questionItem?.title,
      type: questionItem?.questionType?.value,
      order: index + 1,
      options: questionItem?.answers?.map((answerItem, index) => {
        return {
          id: answerItem?.id,
          label: answerItem?.label,
          isCorrect: answerItem?.isCorrect,
          order: index + 1,
        };
      }),
    };
  });

  return formattedQuestions;
};

export const EditQuizPage = ({ id, text, spaceId, onTextChange }: EditQuizPageProps): JSX.Element => {
  return (
    <RootStyled>
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={text}
          onChange={(text) => onTextChange(text, getQuestions(text))}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          placeholder="Write your content or ‘/’ for options"
          toggledFeatures={{
            dragAndDrop: true,
            draggableInsertHandler: true,
            editorGhostMode: true,
            withQuestions: true,
            floatingToolbar: true,
            slashInput: true,
            mediaFiles: true,
          }}
        />
      </TextEditorStyled>
    </RootStyled>
  );
};
