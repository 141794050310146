/* eslint-disable import/no-unused-modules */

import styled, { useTheme } from 'styled-components';
import React, { useContext } from 'react';

import { Button } from '@/components/Button/Button'; // here we use deprecated one to align with toolbar desing
import { Icon } from '@/ui/Icon';
import { Tooltip } from '@/ui/Tooltip';
import { EditorAssistantContext } from './EditorAssistantContext';

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ChargeLabel = styled.div`
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${({ theme }) => theme.typography.body4};
  align-items: center;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.neutral.container};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  /* border: 1px ${({ theme }) => theme.colors.primary.main} solid; */
  /* color: ${({ theme }) => theme.colors.primary.onMain}; */
  border-radius: 8px;
`;

export const EditorAssistantToolbarButton = (): JSX.Element => {
  const { totalCharge, enabled, setEnabled } = useContext(EditorAssistantContext);
  const theme = useTheme();

  return (
    <RootStyled>
      <Tooltip title="Editor Assistant" placement="top">
        <Button $type="base" $icon={['far', 'robot']} $toggled={enabled} onMouseDown={() => setEnabled(!enabled)} />
      </Tooltip>

      {enabled && (
        <Tooltip title="Charge in total (while using this browser)" placement="top">
          <ChargeLabel>
            <Icon icon={['far', 'coin']} color={theme.colors.neutral.onContainer} /> ${totalCharge}
          </ChargeLabel>
        </Tooltip>
      )}
    </RootStyled>
  );
};
