import React from 'react';

import { Icon } from '@/components/Icon';
import { ActivityLinkStyled, CompletionBoxStyled, RootStyled } from './styles';
import { Button } from '@/ui/Button';
import { ActionByState, ActionsProps, ActionStateProps } from './types';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';

const ActionState = ({
  loopState,
  onAddLearningTimeClick,
  onCloseLoopClick,
  onFinishLoopClick,
  onStartLoopClick,
}: ActionStateProps): JSX.Element => {
  const actionsByState: ActionByState = {
    progress: {
      icon: 'plus',
      onClick: onAddLearningTimeClick,
      children: 'Add learning time',
    },
    close: {
      icon: 'flag-checkered',
      onClick: onCloseLoopClick,
      children: 'Close loop',
    },
    finish: {
      icon: 'flag-checkered',
      onClick: onFinishLoopClick,
      children: 'Finish loop',
    },
    start: {
      icon: ['far', 'play'],
      onClick: onStartLoopClick,
      children: 'Start',
    },
  };

  if (loopState === 'completed') {
    return (
      <CompletionBoxStyled>
        <Icon icon="party-horn" />
        You completed all loops
      </CompletionBoxStyled>
    );
  } else if (loopState === 'progress-no-button') {
    return <></>;
  }

  return <Button variant="primary" {...actionsByState[loopState]} />;
};

const _Actions = ({ learningActivityHref, ...rest }: ActionsProps): JSX.Element => {
  return (
    <RootStyled>
      <ActionState {...rest} />
      {learningActivityHref && (
        <ActivityLinkStyled to={learningActivityHref}>Understand your learning activity</ActivityLinkStyled>
      )}
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <Skeleton width={90} height={32} />
  </RootStyled>
);

export const Actions = makeNamespacedComponent(_Actions, {
  LoadingState,
});
