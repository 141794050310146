import { Control, Controller } from 'react-hook-form';

import { Input } from '@/ui/TextField';
import { FormSchema, TextFieldName } from '../..';

export const FormTitle = ({ control }: { control: Control<FormSchema> }): JSX.Element => {
  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.Title}
      render={({ field: { onChange, name, value }, fieldState: { error } }) => (
        <Input
          onChange={onChange}
          name={name}
          value={value}
          fullWidth
          label={'Title'}
          error={
            error && {
              message: 'Enter title',
            }
          }
        />
      )}
    />
  );
};
