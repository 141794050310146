import { useSearchParams, useLocation } from 'react-router-dom';

type StateValueType = string | undefined;

export function useQueryState(
  key: string,
  initialValue: StateValueType
): [StateValueType, (value: StateValueType) => void, (value: StateValueType) => string] {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const setValue = (value: StateValueType) => {
    if (value) searchParams.set(key, value);
    else searchParams.delete(key);

    setSearchParams(searchParams, { replace: true });
  };

  const getLocationSearch = (val: StateValueType) => {
    const params = new URLSearchParams(location.search);

    if (val) params.set(key, val);
    else params.delete(key);

    return params.toString();
  };

  return [searchParams.get(key) || initialValue, setValue, getLocationSearch];
}
