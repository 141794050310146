import { useMediaState, Image } from '@udecode/plate-media/react';
import styled, { css } from 'styled-components';

import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { FloatingToolbarButtons } from '@/component/customEditorV2/ui/elements/ImageElement/UploadedImage/FloatingToolbarButtons';

const ImageStyled = styled(Image)<{ $selected: boolean; $readOnly: boolean }>`
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 460px;
  object-fit: cover;
  padding-right: 0;
  padding-left: 0;
  border-radius: 12px;
  cursor: ${({ $readOnly }) => ($readOnly ? 'auto' : 'pointer')};
  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `};
`;

export const UploadedImage = ({
  onUpload,
  onRemove,
  url,
  shouldShowToolbar,
}: {
  onUpload: (file: File) => void;
  onRemove: () => void;
  url: string;
  shouldShowToolbar: boolean;
}): JSX.Element => {
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: shouldShowToolbar });
  const { readOnly } = useMediaState();

  return (
    <>
      <div ref={setReference} style={{ width: '100%' }}>
        <ImageStyled $selected={shouldShowToolbar} $readOnly={readOnly} alt="" src={url} />
      </div>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <FloatingToolbarButtons onRemoveClick={onRemove} onReplaceFile={onUpload} />
      </div>
    </>
  );
};
