import { v4 as uuid } from 'uuid';

import { RModule, RPage } from '@/store/v2';
import { Module } from '@/administration/pages/Course/store/modules/types';
import { Page } from '@/administration/pages/Course/store/page/types';
import { transformPageFromRemote, transformPageToRemote } from '@/administration/pages/Course/store/page/transform';
import { ModuleId } from '../types';

export const createModule = (): Module => {
  return {
    id: `module-l-${uuid()}` as ModuleId,
    name: 'default module',
  };
};

export const transformModuleFromRemote = (rModule?: RModule): [Module, Page[]] => {
  const pages = rModule?.pages?.map((page) => transformPageFromRemote(page as RPage)) || [];
  return [
    {
      id: `module-r-${rModule?.id || 0}` as ModuleId,
      name: rModule?.name || 'default module',
      remoteId: rModule?.id || undefined,
    },
    pages,
  ];
};

export const transformModuleToRemote = (module: Module, pages: Page[], learningId?: number, order?: number): RModule => ({
  id: module.remoteId,
  learningId,
  order,
  name: module.name,
  pages: pages.map((page, index) => transformPageToRemote(page, module.remoteId, index)),
});
