/** NOTE: This file has been autogenerated. Do not edit this file. 
 *
 * To update this file, perform following steps:
 *  1. ensure that your local cls-api and fapi are working
 *  2. run `npm run generate` in scripts directory.
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: Date; output: Date; }
  JSON: { input: any; output: any; }
  Time: { input: Date; output: Date; }
  URN: { input: string; output: string; }
  URNV2: { input: any; output: any; }
  Upload: { input: any; output: any; }
  bigint: { input: any; output: any; }
  catalog_item_type: { input: any; output: any; }
  float8: { input: any; output: any; }
  json: { input: any; output: any; }
  learning_journey_completion_criteria: { input: any; output: any; }
  learning_journey_status: { input: any; output: any; }
  learning_status: { input: any; output: any; }
  learning_type: { input: any; output: any; }
  learning_visibility: { input: any; output: any; }
  metrics_dimension: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
};

export enum RAccountStatus {
  Deactivated = 'deactivated',
  Incomplete = 'incomplete',
  Pending = 'pending',
  Registered = 'registered'
}

export type RAddAssignmentUserSurveyResult = {
  __typename?: 'AddAssignmentUserSurveyResult';
  success: Scalars['Boolean']['output'];
};

export type RAddSelfTrackedLearningToLearningLoopInput = {
  description: Scalars['String']['input'];
  effort: Scalars['Int']['input'];
  learningLoopId: Scalars['String']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RSelfTrackedLearningType;
  userId: Scalars['Int']['input'];
};

export type RAddSelfTrackedLearningToLearningLoopResult = {
  __typename?: 'AddSelfTrackedLearningToLearningLoopResult';
  learningLoop: RLearningLoop;
  learningSelfTracked: RSelfTrackedLearning;
  success: Scalars['Boolean']['output'];
};

export type RAggregation = {
  __typename?: 'Aggregation';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type RAggregationNameInput = {
  name: Scalars['String']['input'];
};

export type RAnalyticsProgress = {
  __typename?: 'AnalyticsProgress';
  is_completed?: Maybe<Scalars['Boolean']['output']>;
  learning_id?: Maybe<Scalars['Int']['output']>;
};

export type RArticleRedirect = {
  __typename?: 'ArticleRedirect';
  learningId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RAssignmentAssignmentsV3UsersFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  statusIN?: InputMaybe<Array<RAssignmentUserCompletionStatus>>;
  userIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentStandardV3 = RAssignmentV3 & {
  __typename?: 'AssignmentStandardV3';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learnings: RAssignmentV3LearningPage;
  name: Scalars['String']['output'];
  progress: RAssignmentV3Progress;
  rules?: Maybe<RAssignmentsV3Rule>;
  spaceId: Scalars['Int']['output'];
  status?: Maybe<RAssignmentV3Status>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  type: RAssignmentV3Type;
  updatedAt: Scalars['DateTime']['output'];
  users: RAssignmentV3UsersPage;
};


export type RAssignmentStandardV3LearningsArgs = {
  pagination?: InputMaybe<RPagination>;
};


export type RAssignmentStandardV3ProgressArgs = {
  filter: RAssignmentV3ProgressFilter;
};


export type RAssignmentStandardV3UsersArgs = {
  filter?: InputMaybe<RAssignmentAssignmentsV3UsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RAssignmentStandardV3Learning = RAssignmentV3Learning & {
  __typename?: 'AssignmentStandardV3Learning';
  assignmentGroupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type: RAssignmentStandardV3LearningType;
};

export type RAssignmentStandardV3LearningPage = RAssignmentV3LearningPage & {
  __typename?: 'AssignmentStandardV3LearningPage';
  data: Array<RAssignmentStandardV3Learning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RAssignmentStandardV3LearningType {
  Mandatory = 'MANDATORY',
  Suggested = 'SUGGESTED'
}

export type RAssignmentStandardV3User = RAssignmentV3User & {
  __typename?: 'AssignmentStandardV3User';
  agent: RAssignmentV3UserAgent;
  assignmentId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  progress: RAssignmentStandardV3UserProgress;
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RAssignmentStandardV3UserParticipation = {
  __typename?: 'AssignmentStandardV3UserParticipation';
  assignmentLearningId: Scalars['Int']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  participationId: Scalars['Int']['output'];
  progress: Scalars['Float']['output'];
};

export type RAssignmentStandardV3UserProgress = {
  __typename?: 'AssignmentStandardV3UserProgress';
  completed: Scalars['Boolean']['output'];
  estimatedEffort: Scalars['Float']['output'];
  participations: Array<RAssignmentStandardV3UserParticipation>;
  requiredItemsCompletedCount: Scalars['Float']['output'];
  requiredItemsCount: Scalars['Float']['output'];
};

export type RAssignmentStandardV3UsersPage = RAssignmentV3UsersPage & {
  __typename?: 'AssignmentStandardV3UsersPage';
  data: Array<RAssignmentStandardV3User>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3 = RAssignmentV3 & {
  __typename?: 'AssignmentStrategicV3';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learningLoopRecommendedEffort: Scalars['Int']['output'];
  learnings: RAssignmentV3LearningPage;
  name: Scalars['String']['output'];
  progress: RAssignmentStrategicV3Progress;
  rules?: Maybe<RAssignmentsV3Rule>;
  spaceId: Scalars['Int']['output'];
  startAt: Scalars['DateTime']['output'];
  status?: Maybe<RAssignmentV3Status>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  totalThresholdEffort: Scalars['Int']['output'];
  type: RAssignmentV3Type;
  updatedAt: Scalars['DateTime']['output'];
  users: RAssignmentV3UsersPage;
};


export type RAssignmentStrategicV3LearningsArgs = {
  pagination?: InputMaybe<RPagination>;
};


export type RAssignmentStrategicV3ProgressArgs = {
  filter: RAssignmentStrategicV3ProgressFilter;
};


export type RAssignmentStrategicV3UsersArgs = {
  filter?: InputMaybe<RAssignmentAssignmentsV3UsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RAssignmentStrategicV3LearnersProgress = {
  __typename?: 'AssignmentStrategicV3LearnersProgress';
  activeCount: Scalars['Int']['output'];
  completedCount: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3Learning = RAssignmentV3Learning & {
  __typename?: 'AssignmentStrategicV3Learning';
  assignmentGroupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type RAssignmentStrategicV3LearningPage = RAssignmentV3LearningPage & {
  __typename?: 'AssignmentStrategicV3LearningPage';
  data: Array<RAssignmentStrategicV3Learning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3Progress = {
  __typename?: 'AssignmentStrategicV3Progress';
  estimatedEffort: Scalars['Float']['output'];
  learners: RAssignmentStrategicV3LearnersProgress;
  reflections: RAssignmentStrategicV3ReflectionsProgress;
};

export type RAssignmentStrategicV3ProgressFilter = {
  periodIN?: InputMaybe<Array<RPeriodFilter>>;
  statusIN?: InputMaybe<Array<RAssignmentUserCompletionStatus>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentStrategicV3ReflectionsProgress = {
  __typename?: 'AssignmentStrategicV3ReflectionsProgress';
  completedCount: Scalars['Int']['output'];
  completedThreshold: Scalars['Int']['output'];
};

export type RAssignmentStrategicV3User = RAssignmentV3User & {
  __typename?: 'AssignmentStrategicV3User';
  agent: RAssignmentV3UserAgent;
  assignmentId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  progress: RAssignmentStrategicV3UserProgress;
  user: RUserV2;
  userId: Scalars['Int']['output'];
};


export type RAssignmentStrategicV3UserProgressArgs = {
  filter?: InputMaybe<RAssignmentsV3UserStrategicProgressFilter>;
};

export type RAssignmentStrategicV3UserProgress = {
  __typename?: 'AssignmentStrategicV3UserProgress';
  active: Scalars['Boolean']['output'];
  competencyAssessment: RAssignmentStrategicV3UserSurveyProgress;
  /** @deprecated Use status field */
  completed: Scalars['Boolean']['output'];
  estimatedEffort: Scalars['Float']['output'];
  estimatedEffortProgress: Scalars['Float']['output'];
  reflectionsCompleted: Scalars['Int']['output'];
  reflectionsLimit: Scalars['Int']['output'];
  userCompletionStatus: RAssignmentUserCompletionStatus;
  weeklyCompletion: Scalars['Boolean']['output'];
};

export type RAssignmentStrategicV3UserSurveyProgress = {
  __typename?: 'AssignmentStrategicV3UserSurveyProgress';
  canTake: Scalars['Boolean']['output'];
  isTakenAtEnd: Scalars['Boolean']['output'];
  isTakenAtStart: Scalars['Boolean']['output'];
};

export type RAssignmentStrategicV3UsersPage = RAssignmentV3UsersPage & {
  __typename?: 'AssignmentStrategicV3UsersPage';
  data: Array<RAssignmentStrategicV3User>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentTemplate = {
  __typename?: 'AssignmentTemplate';
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  teaser: Scalars['String']['output'];
};

export enum RAssignmentUserCompletionStatus {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  NotCompleted = 'NOT_COMPLETED'
}

export type RAssignmentUserSurveyInput = {
  assignmentId: Scalars['Int']['input'];
  surveyId: Scalars['String']['input'];
  surveyResponseId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type RAssignmentUsersV3Sort = {
  field?: InputMaybe<RAssignmentUsersV3SortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RAssignmentUsersV3SortField {
  Id = 'ID',
  Name = 'NAME'
}

export type RAssignmentV3 = {
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learnings: RAssignmentV3LearningPage;
  name: Scalars['String']['output'];
  rules?: Maybe<RAssignmentsV3Rule>;
  spaceId: Scalars['Int']['output'];
  status?: Maybe<RAssignmentV3Status>;
  surveyId?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  templateId?: Maybe<Scalars['Int']['output']>;
  type: RAssignmentV3Type;
  updatedAt: Scalars['DateTime']['output'];
  users: RAssignmentV3UsersPage;
};


export type RAssignmentV3LearningsArgs = {
  pagination?: InputMaybe<RPagination>;
};


export type RAssignmentV3UsersArgs = {
  filter?: InputMaybe<RAssignmentAssignmentsV3UsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};

export type RAssignmentV3Learning = {
  assignmentGroupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type RAssignmentV3LearningPage = {
  data: Array<RAssignmentV3Learning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentV3Progress = {
  __typename?: 'AssignmentV3Progress';
  completedCount: Scalars['Int']['output'];
  completionRate: Scalars['Float']['output'];
};

export type RAssignmentV3ProgressFilter = {
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum RAssignmentV3Status {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export enum RAssignmentV3Type {
  Standard = 'STANDARD',
  Strategic = 'STRATEGIC'
}

export type RAssignmentV3User = {
  agent: RAssignmentV3UserAgent;
  assignmentId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export enum RAssignmentV3UserAgent {
  Manual = 'MANUAL',
  Rule = 'RULE'
}

export type RAssignmentV3UsersFilter = {
  assignmentGroupId: Scalars['Int']['input'];
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  statusIN?: InputMaybe<Array<RAssignmentUserCompletionStatus>>;
  userIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentV3UsersPage = {
  data: Array<RAssignmentV3User>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentsV3Filter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spacedIdsIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<RAssignmentV3Status>;
  type?: InputMaybe<RAssignmentV3Type>;
  userIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userTeamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RAssignmentsV3Page = {
  __typename?: 'AssignmentsV3Page';
  data: Array<RAssignmentV3>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RAssignmentsV3Result = {
  __typename?: 'AssignmentsV3Result';
  assignment?: Maybe<RAssignmentV3>;
  success: Scalars['Boolean']['output'];
};

export type RAssignmentsV3Rule = {
  __typename?: 'AssignmentsV3Rule';
  expressions: Array<RAssignmentsV3RuleExpression>;
  operator: RAssignmentsV3RuleOperator;
};

export enum RAssignmentsV3RuleCompareOperator {
  Equal = 'EQUAL',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  NotEqual = 'NOT_EQUAL'
}

export type RAssignmentsV3RuleCondition = {
  __typename?: 'AssignmentsV3RuleCondition';
  column: RAssignmentsV3RuleField;
  compare: RAssignmentsV3RuleCompareOperator;
  selections?: Maybe<Array<Scalars['String']['output']>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type RAssignmentsV3RuleConditionInput = {
  column: RAssignmentsV3RuleField;
  compare: RAssignmentsV3RuleCompareOperator;
  selections?: InputMaybe<Array<Scalars['String']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RAssignmentsV3RuleExpression = {
  __typename?: 'AssignmentsV3RuleExpression';
  conditions: Array<RAssignmentsV3RuleCondition>;
  operator: RAssignmentsV3RuleOperator;
};

export type RAssignmentsV3RuleExpressionInput = {
  conditions: Array<RAssignmentsV3RuleConditionInput>;
  operator: RAssignmentsV3RuleOperator;
};

export enum RAssignmentsV3RuleField {
  BusinessArea = 'BUSINESS_AREA',
  BusinessUnit = 'BUSINESS_UNIT',
  CompanyId = 'COMPANY_ID',
  Country = 'COUNTRY',
  DepartmentId = 'DEPARTMENT_ID',
  DepartmentName = 'DEPARTMENT_NAME',
  DeptId = 'DEPT_ID',
  Division = 'DIVISION',
  Indicator = 'INDICATOR',
  JobCode = 'JOB_CODE',
  JobEntryDate = 'JOB_ENTRY_DATE',
  JobFamily = 'JOB_FAMILY',
  JobFunct = 'JOB_FUNCT',
  MgrEmail = 'MGR_EMAIL',
  OrgRelation = 'ORG_RELATION',
  PayStatus = 'PAY_STATUS',
  Product = 'PRODUCT',
  ProductArea = 'PRODUCT_AREA',
  StartDate = 'START_DATE',
  Subfunction = 'SUBFUNCTION',
  SupervisorLvl = 'SUPERVISOR_LVL'
}

export type RAssignmentsV3RuleInput = {
  expressions: Array<RAssignmentsV3RuleExpressionInput>;
  operator: RAssignmentsV3RuleOperator;
};

export enum RAssignmentsV3RuleOperator {
  And = 'AND',
  Or = 'OR'
}

export type RAssignmentsV3Sort = {
  field?: InputMaybe<RAssignmentsV3SortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RAssignmentsV3SortField {
  Id = 'ID',
  Name = 'NAME',
  TypeV2 = 'TYPE_V2'
}

export type RAssignmentsV3UserRuleField = {
  __typename?: 'AssignmentsV3UserRuleField';
  field: Scalars['String']['output'];
  values: Array<Maybe<Scalars['String']['output']>>;
};

export type RAssignmentsV3UserRuleFieldFilter = {
  rules?: InputMaybe<RAssignmentsV3RuleInput>;
  spaceId: Scalars['Int']['input'];
};

export type RAssignmentsV3UserRulePreview = {
  __typename?: 'AssignmentsV3UserRulePreview';
  users: Array<RAssignmentsV3UserRulePreviewUser>;
};

export type RAssignmentsV3UserRulePreviewFilter = {
  rules: RAssignmentsV3RuleInput;
  spaceId: Scalars['Int']['input'];
};

export type RAssignmentsV3UserRulePreviewUser = {
  __typename?: 'AssignmentsV3UserRulePreviewUser';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RAssignmentsV3UserStrategicProgressFilter = {
  periodIN?: InputMaybe<Array<RPeriodFilter>>;
};

export enum RAttendedType {
  Attended = 'ATTENDED',
  Enrolled = 'ENROLLED',
  NotAttended = 'NOT_ATTENDED',
  Waitlisted = 'WAITLISTED'
}

export type RBabelonNotification = {
  __typename?: 'BabelonNotification';
  id: Scalars['ID']['output'];
  notificationType?: Maybe<RBabelonNotificationType>;
  postId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productTitle?: Maybe<Scalars['String']['output']>;
  replyId?: Maybe<Scalars['Int']['output']>;
  respondentName?: Maybe<Scalars['String']['output']>;
  sectionPath?: Maybe<Scalars['String']['output']>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
  topLevelPostId?: Maybe<Scalars['Int']['output']>;
  topLevelPostTitle?: Maybe<Scalars['String']['output']>;
};

export enum RBabelonNotificationType {
  Like = 'LIKE',
  Reply = 'REPLY'
}

export type RBatchEnrollmentResponse = {
  __typename?: 'BatchEnrollmentResponse';
  created?: Maybe<Array<Maybe<RLocationEnrollment>>>;
  skipped?: Maybe<Array<Maybe<RFailedEnrollmentMessage>>>;
};

export type RBlock = {
  __typename?: 'Block';
  created?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RBlockInput = {
  created?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['String']['input']>;
};

export type RBlockV2 = {
  __typename?: 'BlockV2';
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  pageId: Scalars['Int']['output'];
  type: RBlockV2Type;
  urn: Scalars['String']['output'];
};

export type RBlockV2Input = {
  data: Scalars['JSON']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  pageId: Scalars['Int']['input'];
  type: RBlockV2Type;
};

export enum RBlockV2Type {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  PracticalAssessmentTask = 'PRACTICAL_ASSESSMENT_TASK',
  Question = 'QUESTION',
  Reflection = 'REFLECTION',
  Resource = 'RESOURCE',
  Text = 'TEXT',
  Video = 'VIDEO',
  VideoLink = 'VIDEO_LINK'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type RBoolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type RCatalog = {
  __typename?: 'Catalog';
  cards?: Maybe<Array<Maybe<RCourseCard>>>;
};

export enum RCatalogCardType {
  Collection = 'collection',
  Journey = 'journey',
  Learning = 'learning',
  Product = 'product'
}

export type RCatalogItems = {
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RCategory = {
  __typename?: 'Category';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subjects?: Maybe<Array<Maybe<RSubject>>>;
  urlKey?: Maybe<Scalars['String']['output']>;
};

export type RCloneLearningInput = {
  learningId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
};

export type RCollectionCard = RCourseCard & {
  __typename?: 'CollectionCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<RCollectionItemShort>>>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RCollectionItemInput = {
  learningId?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<RLearningVisibility>;
};

export type RCollectionItemShort = {
  __typename?: 'CollectionItemShort';
  learningId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RCompetence = {
  __typename?: 'Competence';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  spaceId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urnV2: Scalars['String']['output'];
};

export type RCompetencePage = {
  __typename?: 'CompetencePage';
  data: Array<RCompetence>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RCompetenceResult = {
  __typename?: 'CompetenceResult';
  competence?: Maybe<RCompetence>;
  success: Scalars['Boolean']['output'];
};

export type RCompetencesFilter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spaceIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RCompetencesSort = {
  order?: InputMaybe<RSortOrder>;
};

export type RCompletionPerElearning = {
  __typename?: 'CompletionPerElearning';
  completed: Scalars['Boolean']['output'];
  learning_id: Scalars['Int']['output'];
};

export type RCompletionRatePerCourse = {
  __typename?: 'CompletionRatePerCourse';
  completed?: Maybe<Scalars['Int']['output']>;
  completion_rate?: Maybe<Scalars['Float']['output']>;
  object_id?: Maybe<Scalars['String']['output']>;
  object_name?: Maybe<Scalars['String']['output']>;
  object_type?: Maybe<Scalars['String']['output']>;
  progressed?: Maybe<Scalars['Int']['output']>;
};

export type RConnectorConfigInput = {
  appType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  notificationTriggers: Array<RNotificationTriggerInput>;
  teamsUserId: Scalars['String']['input'];
  webhookUrl: Scalars['String']['input'];
};

export type RCourseCard = {
  archived?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RCreateAssignmentFromTemplateInput = {
  assignmentTemplateId: Scalars['Int']['input'];
  createExportWebinar: Scalars['Boolean']['input'];
  createGetStartedLearning: Scalars['Boolean']['input'];
  createKickOffWebinar: Scalars['Boolean']['input'];
  createManagerCheckIn: Scalars['Boolean']['input'];
  createTeamsChannel: Scalars['Boolean']['input'];
  endAt: Scalars['DateTime']['input'];
  spaceId: Scalars['Int']['input'];
  startAt: Scalars['DateTime']['input'];
};

export type RCreateCollection = {
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  imageUrl: Scalars['String']['input'];
  languageId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  status: RLearningStatus;
  subjectId: Scalars['Int']['input'];
  teaser: Scalars['String']['input'];
  title: Scalars['String']['input'];
  visibility: RLearningVisibility;
};

export type RCreateCompetenceInput = {
  spaceId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyFromTemplateInput = {
  engagementLearnings?: InputMaybe<REngagementLearnings>;
  sourceTemplateId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyInput = {
  spaceId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyLiveEventInput = {
  bannerURL?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  locations: Array<RCreateLearningJourneyLiveEventLocationInput>;
  plannedDuration: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  timezone: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type RCreateLearningJourneyLiveEventLocationInput = {
  address: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  type: RLocationType;
};

export type RCreateLearningJourneyPracticalAssessmentsInput = {
  practicalAssessmentTemplateIdsIN: Array<Scalars['Int']['input']>;
  spaceId: Scalars['Int']['input'];
};

export type RCreateLearningJourneySurveysInput = {
  spaceId: Scalars['Int']['input'];
  surveyIdsIN: Array<Scalars['Int']['input']>;
};

export type RCreateLearningLoopReflectionInput = {
  applied: RLearningLoopReflectionApplied;
  description: Scalars['String']['input'];
  learningLoopId: Scalars['String']['input'];
};

export type RCreateLearningLoopReflectionResult = {
  __typename?: 'CreateLearningLoopReflectionResult';
  learningLoop?: Maybe<RLearningLoopReflection>;
  success: Scalars['Boolean']['output'];
};

export type RCreateSectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  path: Array<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RCreateSelfTrackedLearningInput = {
  description: Scalars['String']['input'];
  effort: Scalars['Int']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RSelfTrackedLearningType;
  userId: Scalars['Int']['input'];
};

export type RCreateSpaceV2Input = {
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  realm: Scalars['String']['input'];
};

export type RCreateSpaceV2RoleAssignInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  role: RSpaceV2Role;
  roleDisplayName?: InputMaybe<Scalars['String']['input']>;
  spaceId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RCreateTourCompleteUserInput = {
  tourId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RCreateUser = {
  email: Scalars['String']['input'];
  realm: Scalars['String']['input'];
};

export type RCreateUserInput = {
  users: Array<RCreateUser>;
};

export type RCreateUserLearningSubmissionInput = {
  data: Scalars['JSON']['input'];
  learningId: Scalars['Int']['input'];
  pageId: Scalars['Int']['input'];
  type: RUserLearningSubmissionType;
  userId: Scalars['Int']['input'];
};

export type RCreateUserLearningSubmissionReviewInput = {
  data: Scalars['JSON']['input'];
  pageId: Scalars['Int']['input'];
  reviewerId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
  type: RUserLearningSubmissionReviewType;
};

export type RCreateUsersResponse = {
  __typename?: 'CreateUsersResponse';
  realm: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type RCurationFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spacedIdsIN: Array<Scalars['Int']['input']>;
};

export type RDataItem = {
  __typename?: 'DataItem';
  name: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type RDeleteEnrollmentInput = {
  enrollmentId: Scalars['Int']['input'];
};

export type RDeleteLearningJourneyInput = {
  id: Scalars['Int']['input'];
};

export type REffortForRealm = {
  __typename?: 'EffortForRealm';
  average_effort_per_user?: Maybe<Scalars['Float']['output']>;
  p50?: Maybe<Scalars['Float']['output']>;
  p60?: Maybe<Scalars['Float']['output']>;
  p70?: Maybe<Scalars['Float']['output']>;
  p80?: Maybe<Scalars['Float']['output']>;
  p90?: Maybe<Scalars['Float']['output']>;
  total_effort_for_realm?: Maybe<Scalars['Float']['output']>;
};

export type REffortForRealmOverTime = {
  __typename?: 'EffortForRealmOverTime';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type REffortPerProvider = {
  __typename?: 'EffortPerProvider';
  effort: Scalars['Float']['output'];
  provider: Scalars['String']['output'];
};

export type REngagementLearnings = {
  expertWebinar?: InputMaybe<Scalars['Boolean']['input']>;
  getStarted?: InputMaybe<Scalars['Boolean']['input']>;
  kickOffWebinar?: InputMaybe<Scalars['Boolean']['input']>;
  managerCheckin?: InputMaybe<Scalars['Boolean']['input']>;
  teamsChannel?: InputMaybe<Scalars['Boolean']['input']>;
};

export type REnrollByEmailInput = {
  emails: Array<Scalars['String']['input']>;
  locationId: Scalars['Int']['input'];
};

export type REnrollSelfInput = {
  locationId: Scalars['Int']['input'];
};

export type REnrollment = {
  __typename?: 'Enrollment';
  attended: RAttendedType;
  enrolledAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  user?: Maybe<RUserV2>;
  userId: Scalars['Int']['output'];
};

export type REnrollmentsInput = {
  locationIds: Array<Scalars['Int']['input']>;
};

export type REventEnrollment = {
  __typename?: 'EventEnrollment';
  address: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  learningId: Scalars['Int']['output'];
  locationId: Scalars['Int']['output'];
  room?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RFailedEnrollmentMessage = {
  __typename?: 'FailedEnrollmentMessage';
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type RFeatureToggle = {
  __typename?: 'FeatureToggle';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  enabledRealms: Array<RFeatureToggleRealm>;
  enabledUsers: Array<RFeatureToggleUser>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RFeatureToggleEnableRealmInput = {
  featureId: Scalars['Int']['input'];
  realmId: Scalars['Int']['input'];
};

export type RFeatureToggleEnableUserInput = {
  featureId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RFeatureToggleFilter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  publicEQ?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RFeatureTogglePage = {
  __typename?: 'FeatureTogglePage';
  data: Array<RFeatureToggle>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RFeatureToggleRealm = {
  __typename?: 'FeatureToggleRealm';
  createdAt: Scalars['DateTime']['output'];
  featureId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  realm: RRealmV2;
  realmId: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RFeatureToggleSort = {
  field: RFindFtSortField;
  order: Scalars['String']['input'];
};

export type RFeatureToggleUser = {
  __typename?: 'FeatureToggleUser';
  createdAt: Scalars['DateTime']['output'];
  featureId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: RUserV2;
  userId: Scalars['Float']['output'];
};

export type RFile = {
  __typename?: 'File';
  path: Scalars['String']['output'];
};

export type RFilteredOrdersFlatResult = {
  __typename?: 'FilteredOrdersFlatResult';
  productOrders?: Maybe<Array<Maybe<ROrderRowFlat>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export enum RFindFtSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

export type RHistoricCompletedCourse = {
  __typename?: 'HistoricCompletedCourse';
  completed?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RIdResponse = {
  __typename?: 'IdResponse';
  id: Scalars['Int']['output'];
};

export type RImportLearningContentToSpaceInput = {
  learningContentIdIN: Array<Scalars['URN']['input']>;
  originId: Scalars['URN']['input'];
  spaceId: Scalars['Int']['input'];
};

export type RImportLearningContentToSpaceResult = {
  __typename?: 'ImportLearningContentToSpaceResult';
  content?: Maybe<Array<RLearningContent>>;
  failedIds?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type RInt_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RInventory = {
  __typename?: 'Inventory';
  completed?: Maybe<Array<Maybe<RInventoryItem>>>;
  ongoing?: Maybe<Array<Maybe<RInventoryItem>>>;
};

export type RInventoryItem = {
  __typename?: 'InventoryItem';
  accessLink?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  customLink?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isApprovalRequired?: Maybe<Scalars['Boolean']['output']>;
  isCancellable?: Maybe<Scalars['Boolean']['output']>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  journeyId?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  mandatory?: Maybe<Scalars['Boolean']['output']>;
  meta?: Maybe<RProductMeta>;
  name?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  productId?: Maybe<Scalars['Int']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  providerAccess?: Maybe<Scalars['String']['output']>;
  services?: Maybe<Array<Maybe<RService>>>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  totalEffort?: Maybe<Scalars['Int']['output']>;
  totalLearningsCount?: Maybe<Scalars['Int']['output']>;
  userSelfTrackedLearningId?: Maybe<Scalars['Int']['output']>;
  variant?: Maybe<RVariant>;
};

export type RIssuedLicense = {
  __typename?: 'IssuedLicense';
  businessArea?: Maybe<Scalars['String']['output']>;
  businessUnit?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  orgLicenseId?: Maybe<Scalars['Int']['output']>;
  sId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RLicenseStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RUser>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RJourneyCard = RCourseCard & {
  __typename?: 'JourneyCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalEffort?: Maybe<Scalars['Int']['output']>;
  totalLearningsCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLanguage = {
  __typename?: 'Language';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RLanguageV2 = {
  __typename?: 'LanguageV2';
  isoCode: Scalars['String']['output'];
  languageId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nativeName: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type RLearnifierCourse = {
  __typename?: 'LearnifierCourse';
  adminLink?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RLearnifierCourseInput = {
  adminLink?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RLearning = {
  __typename?: 'Learning';
  archived?: Maybe<Scalars['String']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  contentOwner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  creatorEmail?: Maybe<Scalars['String']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  locations?: Maybe<Array<Maybe<RLocation>>>;
  meta?: Maybe<Scalars['JSON']['output']>;
  modules?: Maybe<Array<Maybe<RModule>>>;
  parents: Array<RLearningContent>;
  preamble?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<RLearningProgress>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<RLearningStatus>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RLearningType>;
  updated?: Maybe<Scalars['String']['output']>;
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  urnV2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};


export type RLearningParentsArgs = {
  filter: RLearningParentsFilter;
};

export type RLearningActivityDto = {
  __typename?: 'LearningActivityDto';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  learningId?: Maybe<Scalars['Int']['output']>;
  order: Scalars['Int']['output'];
  parentActivityId?: Maybe<Scalars['Int']['output']>;
  type: RLearningActivityType;
  updatedAt: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};

export type RLearningActivityInput = {
  data: Scalars['JSON']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  parentActivityId?: InputMaybe<Scalars['Int']['input']>;
  type: RLearningActivityType;
};

export type RLearningActivityInputDto = {
  children: Array<RLearningActivityInput>;
  parent?: InputMaybe<RLearningActivityInput>;
};

export enum RLearningActivityType {
  Module = 'MODULE',
  Page = 'PAGE',
  PracticalAssessmentTask = 'PRACTICAL_ASSESSMENT_TASK',
  Question = 'QUESTION',
  Quiz = 'QUIZ',
  Reflection = 'REFLECTION'
}

export type RLearningCard = RCourseCard & {
  __typename?: 'LearningCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Scalars['JSON']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<Maybe<RLocation>>>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalEffort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLearningCollection = {
  __typename?: 'LearningCollection';
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  contentOwner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  creatorEmail?: Maybe<Scalars['String']['output']>;
  editLink?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<RCourseCard>>>;
  languageId?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  previewLink?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<RLearningStatus>;
  subjectId?: Maybe<Scalars['Int']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RLearningType>;
  updated?: Maybe<Scalars['String']['output']>;
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RLearningContent = {
  __typename?: 'LearningContent';
  curations: Array<RLearningCuration>;
  description: Scalars['String']['output'];
  id: Scalars['URN']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  launchURL?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<RLearningContentLocationData>>;
  metadata?: Maybe<RLearningContentMetadata>;
  originIds: Array<Scalars['URN']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  plannedLearningsCount?: Maybe<Scalars['Int']['output']>;
  provider: RLearningContentProvider;
  title: Scalars['String']['output'];
  userLastParticipation?: Maybe<RParticipationV2>;
};


export type RLearningContentCurationsArgs = {
  filter: RLearningCurationFilter;
};


export type RLearningContentUserLastParticipationArgs = {
  userId: Scalars['Int']['input'];
};

export enum RLearningContentContentType {
  Article = 'ARTICLE',
  Audio = 'AUDIO',
  Collection = 'COLLECTION',
  Course = 'COURSE',
  ELearning = 'E_LEARNING',
  Journey = 'JOURNEY',
  LiveEvent = 'LIVE_EVENT',
  OnlineLearning = 'ONLINE_LEARNING',
  Path = 'PATH',
  PracticalAssessment = 'PRACTICAL_ASSESSMENT',
  Resource = 'RESOURCE',
  Seminar = 'SEMINAR',
  Survey = 'SURVEY',
  Video = 'VIDEO',
  Webinar = 'WEBINAR'
}

export type RLearningContentLocationData = {
  __typename?: 'LearningContentLocationData';
  address?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<RLocationType>;
};

export type RLearningContentMetadata = {
  __typename?: 'LearningContentMetadata';
  language?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<RLearningContentContentType>;
};

export enum RLearningContentProvider {
  Collegial = 'COLLEGIAL',
  Coursera = 'COURSERA',
  CustomLink = 'CUSTOM_LINK',
  Finanskompetens = 'FINANSKOMPETENS',
  Imd = 'IMD',
  Imparta = 'IMPARTA',
  Learnifier = 'LEARNIFIER',
  Linkedin = 'LINKEDIN',
  Lumesse = 'LUMESSE',
  Pluralsight = 'PLURALSIGHT'
}

export type RLearningContentsPage = {
  __typename?: 'LearningContentsPage';
  data: Array<RLearningContent>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningCuration = {
  __typename?: 'LearningCuration';
  /** @deprecated Temporary measure to get the editPathURL relevant content type */
  contentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editURLPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['URN']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  launchURL?: Maybe<Scalars['String']['output']>;
  launchURLPath?: Maybe<Scalars['String']['output']>;
  learningContentId: Scalars['URN']['output'];
  spaceId: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};


export type RLearningCurationLaunchUrlArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
};


export type RLearningCurationLaunchUrlPathArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningCurationFilter = {
  spacedIdsIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RLearningInput = {
  archived?: InputMaybe<Scalars['String']['input']>;
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  effort: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  languageId?: InputMaybe<Scalars['Int']['input']>;
  locations?: InputMaybe<Array<InputMaybe<RLocationInput>>>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  modules?: InputMaybe<Array<InputMaybe<RModuleInput>>>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  spaceId: Scalars['Int']['input'];
  subjects: Array<Scalars['Int']['input']>;
  teaser: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RLearningType;
  updated?: InputMaybe<Scalars['String']['input']>;
  visibility: RLearningVisibility;
};

export type RLearningItemCard = RLearningCard | RProductCard;

export type RLearningJourney = {
  __typename?: 'LearningJourney';
  bannerURL?: Maybe<Scalars['String']['output']>;
  businessGoal?: Maybe<Scalars['String']['output']>;
  competences: RLearningJourneyCompetencePage;
  completionCriteria?: Maybe<RLearningJourneyCompletionStatus>;
  completionInvestTimeSec?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discussionEnabled: Scalars['Boolean']['output'];
  discussionForumSectionPath: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impactSurveysEnabled: Scalars['Boolean']['output'];
  isLinear: Scalars['Boolean']['output'];
  items: RLearningJourneyItemPage;
  sourceTemplateId?: Maybe<Scalars['Int']['output']>;
  spaceFeatured: Scalars['Boolean']['output'];
  spaceId: Scalars['Int']['output'];
  status: RLearningJourneyStatus;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};


export type RLearningJourneyCompetencesArgs = {
  pagination?: InputMaybe<RPagination>;
};


export type RLearningJourneyItemsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RLearningJourneyCohort = {
  __typename?: 'LearningJourneyCohort';
  learners: Array<RLearningJourneyLearner>;
};

export type RLearningJourneyCompetence = {
  __typename?: 'LearningJourneyCompetence';
  competence: RCompetence;
  competenceId: Scalars['Int']['output'];
  journeyId: Scalars['Int']['output'];
};

export type RLearningJourneyCompetencePage = {
  __typename?: 'LearningJourneyCompetencePage';
  data: Array<RLearningJourneyCompetence>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RLearningJourneyCompletionStatus {
  CompleteAllItems = 'COMPLETE_ALL_ITEMS',
  InvestTime = 'INVEST_TIME'
}

export type RLearningJourneyFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spaceIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  statusIN?: InputMaybe<Array<RLearningJourneyStatus>>;
};

export type RLearningJourneyItem = {
  __typename?: 'LearningJourneyItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isLocked: Scalars['Boolean']['output'];
  journeyId: Scalars['Int']['output'];
  learningItem?: Maybe<RLearningContent>;
  learningItemURNV2: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};


export type RLearningJourneyItemIsLockedArgs = {
  userId: Scalars['Int']['input'];
};

export type RLearningJourneyItemPage = {
  __typename?: 'LearningJourneyItemPage';
  data: Array<RLearningJourneyItem>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningJourneyItemsCatalogFilter = {
  id: Scalars['Int']['input'];
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
};

export type RLearningJourneyLearner = {
  __typename?: 'LearningJourneyLearner';
  progress?: Maybe<RParticipationV2>;
  sessionId: Scalars['Int']['output'];
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RLearningJourneyPage = {
  __typename?: 'LearningJourneyPage';
  data: Array<RLearningJourney>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningJourneyResult = {
  __typename?: 'LearningJourneyResult';
  journey?: Maybe<RLearningJourney>;
  success: Scalars['Boolean']['output'];
};

export type RLearningJourneySession = {
  __typename?: 'LearningJourneySession';
  cohort: RLearningJourneyCohort;
  id: Scalars['Int']['output'];
  journey?: Maybe<RLearningJourney>;
  journeyId: Scalars['Int']['output'];
  registration: Scalars['String']['output'];
  spaceId: Scalars['Int']['output'];
  startedAt: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};

export type RLearningJourneySessionPage = {
  __typename?: 'LearningJourneySessionPage';
  data: Array<RLearningJourneySession>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningJourneySessionResult = {
  __typename?: 'LearningJourneySessionResult';
  session?: Maybe<RLearningJourneySession>;
  success: Scalars['Boolean']['output'];
};

export type RLearningJourneySort = {
  field?: InputMaybe<RLearningJourneySortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RLearningJourneySortField {
  Id = 'ID',
  SpaceId = 'SPACE_ID',
  Status = 'STATUS',
  Title = 'TITLE'
}

export enum RLearningJourneyStatus {
  Deleted = 'DELETED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type RLearningJourneyTemplateDto = {
  __typename?: 'LearningJourneyTemplateDto';
  bannerURL: Scalars['String']['output'];
  businessGoal: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RLearningLoop = {
  __typename?: 'LearningLoop';
  activitiesAggregated: Array<RLearningLoopActivityAggregated>;
  assignmentId: Scalars['Int']['output'];
  canReflect: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  endAt: Scalars['DateTime']['output'];
  estimatedEffort: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  recommendedEffort: Scalars['Int']['output'];
  reflection?: Maybe<RLearningLoopReflection>;
  startAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type RLearningLoopActivityAggregated = {
  __typename?: 'LearningLoopActivityAggregated';
  estimatedEffortSum: Scalars['Int']['output'];
  participation: RParticipationV2;
};

export type RLearningLoopFilter = {
  assignmentId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RLearningLoopPage = {
  __typename?: 'LearningLoopPage';
  data: Array<RLearningLoop>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RLearningLoopReflection = {
  __typename?: 'LearningLoopReflection';
  applied: RLearningLoopReflectionApplied;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  learningLoopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum RLearningLoopReflectionApplied {
  Applied = 'APPLIED',
  NotSure = 'NOT_SURE',
  PlannedNextMonth = 'PLANNED_NEXT_MONTH',
  PlannedNextSixMonths = 'PLANNED_NEXT_SIX_MONTHS'
}

export type RLearningParentCurationsFilter = {
  spaceIdIN: Array<Scalars['Int']['input']>;
};

export type RLearningParentsFilter = {
  curation: RLearningParentCurationsFilter;
};

export type RLearningPinned = {
  __typename?: 'LearningPinned';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  learningCollectionId?: Maybe<Scalars['Int']['output']>;
  learningId?: Maybe<Scalars['Int']['output']>;
  learningJourneyId?: Maybe<Scalars['Int']['output']>;
  learningPathId?: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  realm: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RLearningPinnedUpdateInput = {
  entity: RCatalogCardType;
  entityId: Scalars['Int']['input'];
  realm: Scalars['String']['input'];
};

export type RLearningProgress = {
  __typename?: 'LearningProgress';
  completed?: Maybe<Scalars['Boolean']['output']>;
  completedPercentage?: Maybe<Scalars['Float']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  historical?: Maybe<Scalars['Boolean']['output']>;
};

export enum RLearningStatus {
  Archived = 'ARCHIVED',
  Available = 'AVAILABLE',
  Deleted = 'DELETED'
}

export type RLearningSubject = {
  __typename?: 'LearningSubject';
  category?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['Int']['output']>;
};

export enum RLearningType {
  Article = 'ARTICLE',
  Audio = 'AUDIO',
  Course = 'COURSE',
  ELearning = 'E_LEARNING',
  LearningPath = 'LEARNING_PATH',
  LiveEvent = 'LIVE_EVENT',
  OnlineLearning = 'ONLINE_LEARNING',
  PracticalAssessment = 'PRACTICAL_ASSESSMENT',
  Resource = 'RESOURCE',
  Seminar = 'SEMINAR',
  Survey = 'SURVEY',
  Video = 'VIDEO',
  Webinar = 'WEBINAR'
}

export type RLearningV2 = {
  __typename?: 'LearningV2';
  activities?: Maybe<Array<RLearningActivityDto>>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  contentOwner?: Maybe<RUserV2>;
  contentOwnerId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<RUserV2>;
  creatorId?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language?: Maybe<RLanguageV2>;
  languageId: Scalars['Int']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  modules: Array<RModuleV2>;
  preamble: Scalars['String']['output'];
  spaceId: Scalars['Int']['output'];
  status: RLearningV2Status;
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: RLearningV2Type;
  updateByUser?: Maybe<RUserV2>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedByUserId?: Maybe<Scalars['Int']['output']>;
  urn: Scalars['String']['output'];
  userLastParticipation?: Maybe<RParticipationV2>;
  visibility?: Maybe<RLearningV2Visibility>;
};


export type RLearningV2UserLastParticipationArgs = {
  userId: Scalars['Int']['input'];
};

export type RLearningV2Input = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  contentOwnerId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  languageId: Scalars['Int']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  preamble: Scalars['String']['input'];
  spaceId: Scalars['Int']['input'];
  status: RLearningV2Status;
  teaser: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RLearningV2Type;
  updatedByUserId?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<RLearningV2Visibility>;
};

export enum RLearningV2Status {
  Archived = 'ARCHIVED',
  Available = 'AVAILABLE',
  Deleted = 'DELETED'
}

export enum RLearningV2Type {
  Article = 'ARTICLE',
  Audio = 'AUDIO',
  Course = 'COURSE',
  ELearning = 'E_LEARNING',
  LiveEvent = 'LIVE_EVENT',
  OnlineLearning = 'ONLINE_LEARNING',
  PracticalAssessment = 'PRACTICAL_ASSESSMENT',
  Resource = 'RESOURCE',
  Seminar = 'SEMINAR',
  Survey = 'SURVEY',
  Video = 'VIDEO',
  Webinar = 'WEBINAR'
}

export enum RLearningV2Visibility {
  Featured = 'FEATURED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}

export enum RLearningVisibility {
  Featured = 'FEATURED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}

export enum RLicenseStatus {
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  PendingApproval = 'PENDING_APPROVAL'
}

export type RLocation = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  learning?: Maybe<RLearning>;
  learningId: Scalars['Int']['output'];
  myEnrollment?: Maybe<REnrollment>;
  room?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  stats?: Maybe<RLocationStats>;
  type?: Maybe<RLocationType>;
  updated: Scalars['DateTime']['output'];
  waitlistType: RWaitlistType;
};

export type RLocationEnrollment = {
  __typename?: 'LocationEnrollment';
  attended?: Maybe<RAttendedType>;
  email?: Maybe<Scalars['String']['output']>;
  enrolledAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RLocationInput = {
  address: Scalars['String']['input'];
  archived?: InputMaybe<Scalars['String']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId?: InputMaybe<Scalars['Int']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
  type?: InputMaybe<RLocationType>;
  waitlistType?: InputMaybe<RWaitlistType>;
};

export type RLocationStats = {
  __typename?: 'LocationStats';
  attendedCount: Scalars['Int']['output'];
  enrolledCount: Scalars['Int']['output'];
  isFinished: Scalars['Boolean']['output'];
  notAttendedCount: Scalars['Int']['output'];
  waitlistedCount: Scalars['Int']['output'];
};

export enum RLocationType {
  InPerson = 'IN_PERSON',
  Online = 'ONLINE'
}

export type RLocationV2Input = {
  address: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['DateTime']['input'];
  type: RLocationType;
};

export type RMe = {
  __typename?: 'Me';
  acceptedExtraTerms: Scalars['Boolean']['output'];
  acceptedGdpr: Scalars['Boolean']['output'];
  acceptedTos: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isDebugging: Scalars['Boolean']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  passwordExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  permissions: RMePermissions;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm: Scalars['String']['output'];
  status: RUserStatus;
  username?: Maybe<Scalars['String']['output']>;
};

export type RMePermissions = {
  __typename?: 'MePermissions';
  hasViewAccessToAdminPanel: Scalars['Boolean']['output'];
  hasViewAccessToManagerDashboard: Scalars['Boolean']['output'];
  hasWriteAccessToInternals: Scalars['Boolean']['output'];
};

export type RModule = {
  __typename?: 'Module';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  learningId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  pages?: Maybe<Array<Maybe<RPage>>>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RModuleInput = {
  created?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  pages?: InputMaybe<Array<InputMaybe<RPageInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['String']['input']>;
};

export type RModuleV2 = {
  __typename?: 'ModuleV2';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  learningId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  pages: Array<RPageV2>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  urn: Scalars['String']['output'];
};

export type RModuleV2Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  learningId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  pages: Array<RPageV2Input>;
};

/** mutation root */
export type RMutation = {
  __typename?: 'Mutation';
  _blank?: Maybe<Scalars['Boolean']['output']>;
  acceptExtraTerms?: Maybe<Scalars['ID']['output']>;
  acceptGdpr?: Maybe<Scalars['ID']['output']>;
  acceptTos?: Maybe<Scalars['ID']['output']>;
  addAssignmentUserSurvey: RAddAssignmentUserSurveyResult;
  addSelfTrackedLearningToLearningLoop: RAddSelfTrackedLearningToLearningLoopResult;
  applyOrderRules?: Maybe<RUpdateResponse>;
  approveLicense?: Maybe<Scalars['Boolean']['output']>;
  archiveLearning?: Maybe<RLearning>;
  cancel?: Maybe<RReturnData>;
  changePassword?: Maybe<Scalars['ID']['output']>;
  cloneLearning: RLearningV2;
  completeLearning: Scalars['Boolean']['output'];
  completeLearningPath?: Maybe<Scalars['Boolean']['output']>;
  completeObject?: Maybe<Scalars['ID']['output']>;
  createAssignmentFromTemplate: RAssignmentsV3Result;
  createCollection?: Maybe<RLearningCollection>;
  createCompetence: RCompetenceResult;
  createLearningJourney: RLearningJourneyResult;
  createLearningJourneyFromTemplate: RLearningJourneyResult;
  createLearningJourneyLiveEvent: RLearningV2;
  createLearningJourneyPracticalAssessments: Array<RLearningV2>;
  createLearningJourneySurveys: Array<RLearningV2>;
  createLearningLoopReflection: RCreateLearningLoopReflectionResult;
  createOrUpdate?: Maybe<RProduct>;
  createPost?: Maybe<RPost>;
  createSection?: Maybe<RSection>;
  createSelfTrackedLearning: RSelfTrackedLearningResult;
  createSpace: RSpaceV2Result;
  createSpaceRoleAssignment: RSpaceV2RoleAssignResultDto;
  createUserLearningSubmission: RUserLearningSubmissionResult;
  createUserLearningSubmissionReview: RUserLearningSubmissionReviewResult;
  createUsers?: Maybe<Array<Maybe<RCreateUsersResponse>>>;
  createVariant?: Maybe<RVariant>;
  deleteAssignmentV3: RAssignmentsV3Result;
  deleteEnrollment: Scalars['Boolean']['output'];
  deleteFeatureToggle: RFeatureToggle;
  deleteLearning?: Maybe<RLearning>;
  deleteLearningJourney: RLearningJourneyResult;
  deletePost?: Maybe<RPost>;
  deleteSpaceRoleAssignment: RSpaceV2RoleAssignResultDto;
  deleteTeamsSubscription?: Maybe<Scalars['Boolean']['output']>;
  deleteXAPIState?: Maybe<Scalars['ID']['output']>;
  /** delete data from the table: "clsapi.learning" */
  delete_clsapi_learning?: Maybe<RClsapi_Learning_Mutation_Response>;
  /** delete single row from the table: "clsapi.learning" */
  delete_clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** delete data from the table: "clsapi.learning_journeys" */
  delete_clsapi_learning_journeys?: Maybe<RClsapi_Learning_Journeys_Mutation_Response>;
  /** delete single row from the table: "clsapi.learning_journeys" */
  delete_clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  disableFeatureToggleForRealm: RFeatureToggleRealm;
  disableFeatureToggleForUser: RFeatureToggleUser;
  enableFeatureToggleForRealm: RFeatureToggleRealm;
  enableFeatureToggleForUser: RFeatureToggleUser;
  enrollSelf: REnrollment;
  enrollmentCreateByEmails: Array<REnrollment>;
  importLearningContentToSpace: RImportLearningContentToSpaceResult;
  /** insert data into the table: "clsapi.learning" */
  insert_clsapi_learning?: Maybe<RClsapi_Learning_Mutation_Response>;
  /** insert data into the table: "clsapi.learning_journeys" */
  insert_clsapi_learning_journeys?: Maybe<RClsapi_Learning_Journeys_Mutation_Response>;
  /** insert a single row into the table: "clsapi.learning_journeys" */
  insert_clsapi_learning_journeys_one?: Maybe<RClsapi_Learning_Journeys>;
  /** insert a single row into the table: "clsapi.learning" */
  insert_clsapi_learning_one?: Maybe<RClsapi_Learning>;
  joinLearningJourneyCohort: RLearningJourneySessionResult;
  learningPinnedUpdate: Array<RLearningPinned>;
  markAllNotificationsAsSeen?: Maybe<Array<Maybe<RBabelonNotification>>>;
  markNotificationAsSeen?: Maybe<Array<Maybe<RBabelonNotification>>>;
  purchase?: Maybe<RPurchaseResponse>;
  registerToLearning: RXapiSession;
  removeSelfTrackedLearning: RSelfTrackedLearningResult;
  removeVariant?: Maybe<RVariantResponse>;
  requestLicense?: Maybe<Scalars['Boolean']['output']>;
  restoreLearning?: Maybe<RLearning>;
  revokeLicense?: Maybe<Scalars['Boolean']['output']>;
  sadminPurchase?: Maybe<RSadminPurchaseResponse>;
  saveLearning?: Maybe<RLearning>;
  saveTeamsSubscription?: Maybe<RTeamsSubscription>;
  setBulkEnrollmentAttendance: Array<REnrollment>;
  setEnrollmentAttendance: REnrollment;
  setLanguage?: Maybe<Scalars['ID']['output']>;
  setProgressPerCourseByUser?: Maybe<Scalars['Boolean']['output']>;
  setUserStatus?: Maybe<Scalars['ID']['output']>;
  setUsername?: Maybe<Scalars['ID']['output']>;
  submitLearningActivityQuizAnswers: RLearningActivityDto;
  teamCreate: RTeam;
  teamDelete: Scalars['Boolean']['output'];
  teamUpdate: RTeam;
  teamUserChangeRole: RTeamUser;
  teamUserRemove: Scalars['Boolean']['output'];
  teamUsersAdd: Array<RTeamUser>;
  toggleAction?: Maybe<RPost>;
  unlockUserLearningJourneyItem: RLearningJourneyResult;
  updateCollection?: Maybe<RLearningCollection>;
  updateCollectionItems?: Maybe<RLearningCollection>;
  updateCompetence: RCompetenceResult;
  updateLearningJourney: RLearningJourneyResult;
  updateMeta?: Maybe<RUserMeta>;
  updateOrder?: Maybe<RUpdateResponse>;
  updatePost?: Maybe<RPost>;
  updateSection?: Maybe<RSection>;
  updateSelfTrackedLearning: RSelfTrackedLearningResult;
  updateSpace: RSpaceV2Result;
  updateSpaceRoleAssignment: RSpaceV2RoleAssignResultDto;
  updateUser: RUserV2;
  updateVariant?: Maybe<RVariant>;
  /** update data of the table: "clsapi.learning" */
  update_clsapi_learning?: Maybe<RClsapi_Learning_Mutation_Response>;
  /** update single row of the table: "clsapi.learning" */
  update_clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** update data of the table: "clsapi.learning_journeys" */
  update_clsapi_learning_journeys?: Maybe<RClsapi_Learning_Journeys_Mutation_Response>;
  /** update single row of the table: "clsapi.learning_journeys" */
  update_clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  /** update multiples rows of table: "clsapi.learning_journeys" */
  update_clsapi_learning_journeys_many?: Maybe<Array<Maybe<RClsapi_Learning_Journeys_Mutation_Response>>>;
  /** update multiples rows of table: "clsapi.learning" */
  update_clsapi_learning_many?: Maybe<Array<Maybe<RClsapi_Learning_Mutation_Response>>>;
  uploadProfileImage: RFile;
  upsertAssignmentV3: RAssignmentsV3Result;
  upsertFeatureToggle: RFeatureToggle;
  upsertLearning: RLearningV2;
  upsertLearningJourneyCohorts: RLearningJourneySessionResult;
  upsertLearningJourneyCompetences: RLearningJourneyResult;
  upsertLearningJourneyItems: RLearningJourneyResult;
  userChangeRealmRole: RUserV2;
  userCompleteTour: RTourUserCompletions;
  voidObject?: Maybe<Scalars['ID']['output']>;
};


/** mutation root */
export type RMutationAddAssignmentUserSurveyArgs = {
  input: RAssignmentUserSurveyInput;
};


/** mutation root */
export type RMutationAddSelfTrackedLearningToLearningLoopArgs = {
  input: RAddSelfTrackedLearningToLearningLoopInput;
};


/** mutation root */
export type RMutationApplyOrderRulesArgs = {
  orderId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationApproveLicenseArgs = {
  licenseId?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type RMutationArchiveLearningArgs = {
  learningId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationCancelArgs = {
  orderId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationCloneLearningArgs = {
  input: RCloneLearningInput;
};


/** mutation root */
export type RMutationCompleteLearningArgs = {
  learningId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationCompleteLearningPathArgs = {
  learningPathId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationCompleteObjectArgs = {
  objectId: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  targetUserId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationCreateAssignmentFromTemplateArgs = {
  input: RCreateAssignmentFromTemplateInput;
};


/** mutation root */
export type RMutationCreateCollectionArgs = {
  payload: RCreateCollection;
};


/** mutation root */
export type RMutationCreateCompetenceArgs = {
  input: RCreateCompetenceInput;
};


/** mutation root */
export type RMutationCreateLearningJourneyArgs = {
  input: RCreateLearningJourneyInput;
};


/** mutation root */
export type RMutationCreateLearningJourneyFromTemplateArgs = {
  input: RCreateLearningJourneyFromTemplateInput;
};


/** mutation root */
export type RMutationCreateLearningJourneyLiveEventArgs = {
  input: RCreateLearningJourneyLiveEventInput;
};


/** mutation root */
export type RMutationCreateLearningJourneyPracticalAssessmentsArgs = {
  input: RCreateLearningJourneyPracticalAssessmentsInput;
};


/** mutation root */
export type RMutationCreateLearningJourneySurveysArgs = {
  input: RCreateLearningJourneySurveysInput;
};


/** mutation root */
export type RMutationCreateLearningLoopReflectionArgs = {
  input: RCreateLearningLoopReflectionInput;
};


/** mutation root */
export type RMutationCreateOrUpdateArgs = {
  product: RProductInput;
};


/** mutation root */
export type RMutationCreatePostArgs = {
  path: Array<Scalars['String']['input']>;
  payload?: InputMaybe<RPostInput>;
};


/** mutation root */
export type RMutationCreateSectionArgs = {
  payload?: InputMaybe<RCreateSectionInput>;
};


/** mutation root */
export type RMutationCreateSelfTrackedLearningArgs = {
  input: RCreateSelfTrackedLearningInput;
};


/** mutation root */
export type RMutationCreateSpaceArgs = {
  input: RCreateSpaceV2Input;
};


/** mutation root */
export type RMutationCreateSpaceRoleAssignmentArgs = {
  input: RCreateSpaceV2RoleAssignInput;
};


/** mutation root */
export type RMutationCreateUserLearningSubmissionArgs = {
  input: RCreateUserLearningSubmissionInput;
};


/** mutation root */
export type RMutationCreateUserLearningSubmissionReviewArgs = {
  input: RCreateUserLearningSubmissionReviewInput;
};


/** mutation root */
export type RMutationCreateUsersArgs = {
  payload?: InputMaybe<RCreateUserInput>;
};


/** mutation root */
export type RMutationCreateVariantArgs = {
  productId: Scalars['Int']['input'];
  variant: RVariantInput;
};


/** mutation root */
export type RMutationDeleteAssignmentV3Args = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDeleteEnrollmentArgs = {
  input: RDeleteEnrollmentInput;
};


/** mutation root */
export type RMutationDeleteFeatureToggleArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDeleteLearningArgs = {
  learningId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDeleteLearningJourneyArgs = {
  input: RDeleteLearningJourneyInput;
};


/** mutation root */
export type RMutationDeletePostArgs = {
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type RMutationDeleteSpaceRoleAssignmentArgs = {
  input: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDeleteTeamsSubscriptionArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type RMutationDeleteXapiStateArgs = {
  objectId: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  targetUserId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDelete_Clsapi_LearningArgs = {
  where: RClsapi_Learning_Bool_Exp;
};


/** mutation root */
export type RMutationDelete_Clsapi_Learning_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDelete_Clsapi_Learning_JourneysArgs = {
  where: RClsapi_Learning_Journeys_Bool_Exp;
};


/** mutation root */
export type RMutationDelete_Clsapi_Learning_Journeys_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationDisableFeatureToggleForRealmArgs = {
  input: RFeatureToggleEnableRealmInput;
};


/** mutation root */
export type RMutationDisableFeatureToggleForUserArgs = {
  input: RFeatureToggleEnableUserInput;
};


/** mutation root */
export type RMutationEnableFeatureToggleForRealmArgs = {
  input: RFeatureToggleEnableRealmInput;
};


/** mutation root */
export type RMutationEnableFeatureToggleForUserArgs = {
  input: RFeatureToggleEnableUserInput;
};


/** mutation root */
export type RMutationEnrollSelfArgs = {
  input: REnrollSelfInput;
};


/** mutation root */
export type RMutationEnrollmentCreateByEmailsArgs = {
  input: REnrollByEmailInput;
};


/** mutation root */
export type RMutationImportLearningContentToSpaceArgs = {
  input: RImportLearningContentToSpaceInput;
};


/** mutation root */
export type RMutationInsert_Clsapi_LearningArgs = {
  objects: Array<RClsapi_Learning_Insert_Input>;
  on_conflict?: InputMaybe<RClsapi_Learning_On_Conflict>;
};


/** mutation root */
export type RMutationInsert_Clsapi_Learning_JourneysArgs = {
  objects: Array<RClsapi_Learning_Journeys_Insert_Input>;
  on_conflict?: InputMaybe<RClsapi_Learning_Journeys_On_Conflict>;
};


/** mutation root */
export type RMutationInsert_Clsapi_Learning_Journeys_OneArgs = {
  object: RClsapi_Learning_Journeys_Insert_Input;
  on_conflict?: InputMaybe<RClsapi_Learning_Journeys_On_Conflict>;
};


/** mutation root */
export type RMutationInsert_Clsapi_Learning_OneArgs = {
  object: RClsapi_Learning_Insert_Input;
  on_conflict?: InputMaybe<RClsapi_Learning_On_Conflict>;
};


/** mutation root */
export type RMutationJoinLearningJourneyCohortArgs = {
  sessionId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationLearningPinnedUpdateArgs = {
  input: Array<RLearningPinnedUpdateInput>;
  realm: Scalars['String']['input'];
};


/** mutation root */
export type RMutationMarkNotificationAsSeenArgs = {
  postId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationPurchaseArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  products: Array<InputMaybe<RPurchaseProduct>>;
};


/** mutation root */
export type RMutationRegisterToLearningArgs = {
  learningId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationRemoveSelfTrackedLearningArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationRemoveVariantArgs = {
  productId: Scalars['Int']['input'];
  variantId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationRequestLicenseArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type RMutationRestoreLearningArgs = {
  learningId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationRevokeLicenseArgs = {
  licenseId?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type RMutationSadminPurchaseArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  product: RSadminPurchaseProduct;
};


/** mutation root */
export type RMutationSaveLearningArgs = {
  payload: RLearningInput;
};


/** mutation root */
export type RMutationSaveTeamsSubscriptionArgs = {
  connectorConfig: RConnectorConfigInput;
};


/** mutation root */
export type RMutationSetBulkEnrollmentAttendanceArgs = {
  input: Array<RSetEnrollmentAttendanceInput>;
};


/** mutation root */
export type RMutationSetEnrollmentAttendanceArgs = {
  input: RSetEnrollmentAttendanceInput;
};


/** mutation root */
export type RMutationSetLanguageArgs = {
  payload?: InputMaybe<RSetLanguageInput>;
};


/** mutation root */
export type RMutationSetProgressPerCourseByUserArgs = {
  learningId: Scalars['String']['input'];
};


/** mutation root */
export type RMutationSetUserStatusArgs = {
  payload?: InputMaybe<RSetUserStatusInput>;
};


/** mutation root */
export type RMutationSetUsernameArgs = {
  payload?: InputMaybe<RSetUsernameInput>;
};


/** mutation root */
export type RMutationSubmitLearningActivityQuizAnswersArgs = {
  input: RUserLearningActivityQuizSubmissionInput;
};


/** mutation root */
export type RMutationTeamCreateArgs = {
  input: RTeamCreateInput;
};


/** mutation root */
export type RMutationTeamDeleteArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationTeamUpdateArgs = {
  input: RTeamUpdateInput;
};


/** mutation root */
export type RMutationTeamUserChangeRoleArgs = {
  input: RTeamUserChangeRoleInput;
};


/** mutation root */
export type RMutationTeamUserRemoveArgs = {
  input: RTeamUserRemoveInput;
};


/** mutation root */
export type RMutationTeamUsersAddArgs = {
  input: Array<RTeamUsersAddInput>;
};


/** mutation root */
export type RMutationToggleActionArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  path: Array<Scalars['String']['input']>;
  payload?: InputMaybe<RPostActionInput>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type RMutationUnlockUserLearningJourneyItemArgs = {
  itemURN: Scalars['URN']['input'];
  learningJourneyId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationUpdateCollectionArgs = {
  payload: RUpdateCollection;
};


/** mutation root */
export type RMutationUpdateCollectionItemsArgs = {
  payload: RUpdateCollectionItems;
};


/** mutation root */
export type RMutationUpdateCompetenceArgs = {
  input: RUpdateCompetenceInput;
};


/** mutation root */
export type RMutationUpdateLearningJourneyArgs = {
  input: RUpdateLearningJourneyInput;
};


/** mutation root */
export type RMutationUpdateMetaArgs = {
  meta: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};


/** mutation root */
export type RMutationUpdateOrderArgs = {
  orderId: Scalars['Int']['input'];
  stateId: Scalars['Int']['input'];
};


/** mutation root */
export type RMutationUpdatePostArgs = {
  path: Array<Scalars['String']['input']>;
  payload?: InputMaybe<RPostInput>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type RMutationUpdateSectionArgs = {
  payload?: InputMaybe<RUpdateSectionInput>;
};


/** mutation root */
export type RMutationUpdateSelfTrackedLearningArgs = {
  input: RUpdateSelfTrackedLearningInput;
};


/** mutation root */
export type RMutationUpdateSpaceArgs = {
  input: RUpdateSpaceV2Input;
};


/** mutation root */
export type RMutationUpdateSpaceRoleAssignmentArgs = {
  input: RUpdateSpaceV2RoleAssignInput;
};


/** mutation root */
export type RMutationUpdateUserArgs = {
  input: RUserChangeInput;
};


/** mutation root */
export type RMutationUpdateVariantArgs = {
  productId: Scalars['Int']['input'];
  variant: RVariantInput;
};


/** mutation root */
export type RMutationUpdate_Clsapi_LearningArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Set_Input>;
  where: RClsapi_Learning_Bool_Exp;
};


/** mutation root */
export type RMutationUpdate_Clsapi_Learning_By_PkArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Set_Input>;
  pk_columns: RClsapi_Learning_Pk_Columns_Input;
};


/** mutation root */
export type RMutationUpdate_Clsapi_Learning_JourneysArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Journeys_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Journeys_Set_Input>;
  where: RClsapi_Learning_Journeys_Bool_Exp;
};


/** mutation root */
export type RMutationUpdate_Clsapi_Learning_Journeys_By_PkArgs = {
  _inc?: InputMaybe<RClsapi_Learning_Journeys_Inc_Input>;
  _set?: InputMaybe<RClsapi_Learning_Journeys_Set_Input>;
  pk_columns: RClsapi_Learning_Journeys_Pk_Columns_Input;
};


/** mutation root */
export type RMutationUpdate_Clsapi_Learning_Journeys_ManyArgs = {
  updates: Array<RClsapi_Learning_Journeys_Updates>;
};


/** mutation root */
export type RMutationUpdate_Clsapi_Learning_ManyArgs = {
  updates: Array<RClsapi_Learning_Updates>;
};


/** mutation root */
export type RMutationUploadProfileImageArgs = {
  file: Scalars['Upload']['input'];
};


/** mutation root */
export type RMutationUpsertAssignmentV3Args = {
  input: RUpsertAssignmentInput;
};


/** mutation root */
export type RMutationUpsertFeatureToggleArgs = {
  input: RUpsertFeatureToggleInput;
};


/** mutation root */
export type RMutationUpsertLearningArgs = {
  input: RUpsertLearningInput;
};


/** mutation root */
export type RMutationUpsertLearningJourneyCohortsArgs = {
  input: RUpsertLearningJourneyCohortsInput;
};


/** mutation root */
export type RMutationUpsertLearningJourneyCompetencesArgs = {
  input: RUpsertLearningJourneyCompetencesInput;
};


/** mutation root */
export type RMutationUpsertLearningJourneyItemsArgs = {
  input: RUpsertLearningJourneyItemsInput;
};


/** mutation root */
export type RMutationUserChangeRealmRoleArgs = {
  input: RUserChangeRealmRoleInput;
};


/** mutation root */
export type RMutationUserCompleteTourArgs = {
  input: RCreateTourCompleteUserInput;
};


/** mutation root */
export type RMutationVoidObjectArgs = {
  objectId: Scalars['String']['input'];
  registration: Scalars['String']['input'];
  targetUserId: Scalars['Int']['input'];
};

export type RMutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RMyLocationEnrollment = {
  __typename?: 'MyLocationEnrollment';
  enrolled?: Maybe<Scalars['Boolean']['output']>;
  enrollmentStatus?: Maybe<RAttendedType>;
};

export type RNotificationTrigger = {
  __typename?: 'NotificationTrigger';
  categoryId?: Maybe<Scalars['Int']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  subjectId?: Maybe<Scalars['Int']['output']>;
};

export type RNotificationTriggerInput = {
  categoryId: Scalars['Int']['input'];
  spaceId: Scalars['Int']['input'];
  subjectId: Scalars['Int']['input'];
};

export type ROrder = {
  __typename?: 'Order';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<RProductRow>>>;
  rowHistory?: Maybe<Array<Maybe<ROrderRow>>>;
  stateName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RUser>;
};

export enum ROrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ROrderHistory = {
  __typename?: 'OrderHistory';
  error?: Maybe<Scalars['String']['output']>;
  orderRowId?: Maybe<Scalars['String']['output']>;
  stateId?: Maybe<Scalars['String']['output']>;
  stateName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<RUser>;
};

export type ROrderRow = {
  __typename?: 'OrderRow';
  product?: Maybe<RProductRow>;
  state?: Maybe<RState>;
  stateName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<RUser>;
};

export type ROrderRowFlat = {
  __typename?: 'OrderRowFlat';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productVariantId?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<RProductRow>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
  stateName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RUser>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type ROrderSearch = {
  __typename?: 'OrderSearch';
  orders?: Maybe<Array<Maybe<ROrder>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ROrderSearchQuery = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  products?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ROrderSearchTotal = {
  __typename?: 'OrderSearchTotal';
  total?: Maybe<Scalars['Int']['output']>;
};

export type RPage = {
  __typename?: 'Page';
  blocks?: Maybe<Array<Maybe<RBlock>>>;
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  moduleId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
};

export type RPageInput = {
  blocks?: InputMaybe<Array<InputMaybe<RBlockInput>>>;
  created?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  moduleId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['String']['input']>;
};

export type RPageV2 = {
  __typename?: 'PageV2';
  blocks: Array<RBlockV2>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  moduleId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  urn: Scalars['String']['output'];
};

export type RPageV2Input = {
  blocks: RBlockV2Input;
  id?: InputMaybe<Scalars['Int']['input']>;
  moduleId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type RPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RParticipant = {
  __typename?: 'Participant';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RParticipantsResult = {
  __typename?: 'ParticipantsResult';
  participants?: Maybe<Array<Maybe<RParticipant>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type RParticipation = {
  __typename?: 'Participation';
  catalogObjectId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['Time']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['Time']['output']>;
  firstActivity?: Maybe<Scalars['Time']['output']>;
  firstStatement: Scalars['Time']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  lastActivity?: Maybe<Scalars['Time']['output']>;
  lastStatement: Scalars['Time']['output'];
  objectId: Scalars['String']['output'];
  objectName?: Maybe<Scalars['String']['output']>;
  objectType?: Maybe<Scalars['String']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  platform: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
  registration: Scalars['String']['output'];
  userEffort?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type RParticipationGroup = {
  __typename?: 'ParticipationGroup';
  catalogObjectId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  firstActivity?: Maybe<Scalars['String']['output']>;
  firstStatement: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  lastActivity?: Maybe<Scalars['String']['output']>;
  lastStatement: Scalars['String']['output'];
  objectId: Scalars['String']['output'];
  objectName?: Maybe<Scalars['String']['output']>;
  objectType?: Maybe<Scalars['String']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  platform: Scalars['String']['output'];
  previousParticipations?: Maybe<Array<RParticipation>>;
  progress: Scalars['Float']['output'];
  registration: Scalars['String']['output'];
  userEffort?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type RParticipationV2 = {
  __typename?: 'ParticipationV2';
  catalogObjectId?: Maybe<Scalars['URN']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  expiryAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCompleted: Scalars['Boolean']['output'];
  objectId: Scalars['URN']['output'];
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  plannedLearningsCount?: Maybe<Scalars['Int']['output']>;
  progress: Scalars['Float']['output'];
  userEffort?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type RParticipationsInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  includeCatalogData?: InputMaybe<Scalars['Boolean']['input']>;
  isCompletedEq?: InputMaybe<Scalars['Boolean']['input']>;
  objectIdIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectTypeNin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  userIdEq?: InputMaybe<Scalars['Int']['input']>;
  userTeamIdIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  usersPool?: InputMaybe<RUsersPool>;
};

export type RPeriodFilter = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type RPermissionsRole = {
  __typename?: 'PermissionsRole';
  admin?: Maybe<Scalars['Boolean']['output']>;
  guest?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  member?: Maybe<Scalars['Boolean']['output']>;
  moderator?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<RSectionPermissions>;
};

export type RPost = {
  __typename?: 'Post';
  ancestors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  content?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  media?: Maybe<RPostMedia>;
  path?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<RPostPermissions>;
  publishDate?: Maybe<Scalars['String']['output']>;
  replies?: Maybe<Array<Maybe<RPost>>>;
  section?: Maybe<RSection>;
  stats?: Maybe<RStats>;
  sticky?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['String']['output']>;
  user?: Maybe<RPostUser>;
  you?: Maybe<RYou>;
};

export type RPostActionInput = {
  scalar?: InputMaybe<Scalars['Int']['input']>;
};

export type RPostInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  media?: InputMaybe<RPostMediaInput>;
  originProductId?: InputMaybe<Scalars['Int']['input']>;
  pid?: InputMaybe<Scalars['Int']['input']>;
  propagates?: InputMaybe<Scalars['Boolean']['input']>;
  publishDate?: InputMaybe<Scalars['String']['input']>;
  shouldSendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  sticky?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RPostMedia = {
  __typename?: 'PostMedia';
  type?: Maybe<RPostMediaType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RPostMediaInput = {
  type?: InputMaybe<RPostMediaType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum RPostMediaType {
  Image = 'image',
  Resource = 'resource',
  Video = 'video'
}

export type RPostPermissions = {
  __typename?: 'PostPermissions';
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  canLock?: Maybe<Scalars['Boolean']['output']>;
  canPurge?: Maybe<Scalars['Boolean']['output']>;
  canReply?: Maybe<Scalars['Boolean']['output']>;
  canRestore?: Maybe<Scalars['Boolean']['output']>;
  canSticky?: Maybe<Scalars['Boolean']['output']>;
  canUnlock?: Maybe<Scalars['Boolean']['output']>;
  canUnsticky?: Maybe<Scalars['Boolean']['output']>;
  canViewReflectionReplies?: Maybe<Scalars['Boolean']['output']>;
};

export type RPostUser = {
  __typename?: 'PostUser';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RPostUserRole>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RPostUserRole = {
  __typename?: 'PostUserRole';
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RPostsMeta = {
  __typename?: 'PostsMeta';
  items?: Maybe<Array<Maybe<RPost>>>;
  lastUpdate?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  pages?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RProduct = {
  __typename?: 'Product';
  contentOwner?: Maybe<Scalars['Int']['output']>;
  costCenter?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  flags?: Maybe<RProductFlags>;
  id?: Maybe<Scalars['Int']['output']>;
  isPending?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  learnifierCourseName?: Maybe<Scalars['String']['output']>;
  learningContentUrnV2?: Maybe<Scalars['URNV2']['output']>;
  legalEntity?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<RProductMeta>;
  name?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  parents: Array<RLearningContent>;
  price?: Maybe<Scalars['Int']['output']>;
  productVariantId?: Maybe<Scalars['Int']['output']>;
  rowId?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Array<Maybe<RService>>>;
  space?: Maybe<RSpace>;
  state?: Maybe<RState>;
  status?: Maybe<RLearningStatus>;
  stocks?: Maybe<Array<Maybe<RStock>>>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  timestamp?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<Maybe<RVariant>>>;
};


export type RProductParentsArgs = {
  filter: RProductParentsFilter;
};

export type RProductCard = RCourseCard & {
  __typename?: 'ProductCard';
  archived?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Scalars['JSON']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  customLink?: Maybe<Scalars['String']['output']>;
  entity: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isApprovalRequired?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  participation?: Maybe<RParticipation>;
  provider?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subjects?: Maybe<Array<Maybe<RLearningSubject>>>;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalEffort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<RLearningVisibility>;
};

export type RProductFlags = {
  __typename?: 'ProductFlags';
  approvalRequired?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  enrollmentDisabled?: Maybe<Scalars['Boolean']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  globalMandatoryTraining?: Maybe<Scalars['Boolean']['output']>;
  hasTeams?: Maybe<Scalars['Boolean']['output']>;
  reflectionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  requiresApplication?: Maybe<Scalars['Boolean']['output']>;
  socialFeaturesEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type RProductFlagsInput = {
  approvalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  canEdit?: InputMaybe<Scalars['Boolean']['input']>;
  enrollmentDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  globalMandatoryTraining?: InputMaybe<Scalars['Boolean']['input']>;
  hasTeams?: InputMaybe<Scalars['Boolean']['input']>;
  reflectionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requiresApplication?: InputMaybe<Scalars['Boolean']['input']>;
  socialFeaturesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RProductInput = {
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  costCenter?: InputMaybe<Scalars['String']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<RProductFlagsInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  learnifierCourseName?: InputMaybe<Scalars['String']['input']>;
  legalEntity?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<RProductMetaInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  rowId?: InputMaybe<Scalars['Int']['input']>;
  services?: InputMaybe<Array<InputMaybe<RServiceInput>>>;
  space?: InputMaybe<RSpaceInput>;
  state?: InputMaybe<RStateInput>;
  status?: InputMaybe<RLearningStatus>;
  stocks?: InputMaybe<Array<InputMaybe<RStockInput>>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  variants?: InputMaybe<Array<InputMaybe<RVariantInput>>>;
};

export type RProductLabel = {
  __typename?: 'ProductLabel';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RProductMeta = {
  __typename?: 'ProductMeta';
  ATTRIBUTES?: Maybe<Scalars['String']['output']>;
  CONFIRM_MESSAGE?: Maybe<Scalars['String']['output']>;
  CONNECTIONS?: Maybe<Scalars['String']['output']>;
  CUSTOM_LINK?: Maybe<Scalars['String']['output']>;
  DESCRIPTION?: Maybe<Scalars['String']['output']>;
  DISCUSSION_SECTION?: Maybe<Scalars['String']['output']>;
  EFFORT?: Maybe<Scalars['String']['output']>;
  ENROLL_BUTTON_TEXT?: Maybe<Scalars['String']['output']>;
  FORMAT?: Maybe<Scalars['String']['output']>;
  IMAGE?: Maybe<Scalars['String']['output']>;
  INSTITUTION?: Maybe<Scalars['String']['output']>;
  INSTRUCTORS?: Maybe<Scalars['String']['output']>;
  LANGUAGE?: Maybe<Scalars['String']['output']>;
  LENGTH?: Maybe<Scalars['String']['output']>;
  LEVEL?: Maybe<Scalars['String']['output']>;
  LOCATION?: Maybe<Scalars['String']['output']>;
  METHOD?: Maybe<Scalars['String']['output']>;
  OBJECT_ID?: Maybe<Scalars['String']['output']>;
  PROVIDER?: Maybe<Scalars['String']['output']>;
  PROVIDER_ACCESS?: Maybe<Scalars['String']['output']>;
  REFLECTION_SECTION?: Maybe<Scalars['String']['output']>;
  TEAMS?: Maybe<Scalars['String']['output']>;
  TEAMSIZE?: Maybe<Scalars['String']['output']>;
  TEASER?: Maybe<Scalars['String']['output']>;
  TOTAL_EFFORT?: Maybe<Scalars['String']['output']>;
};

export type RProductMetaInput = {
  ATTRIBUTES?: InputMaybe<Scalars['String']['input']>;
  CATEGORY?: InputMaybe<Scalars['String']['input']>;
  CONFIRM_MESSAGE?: InputMaybe<Scalars['String']['input']>;
  CONNECTIONS?: InputMaybe<Scalars['String']['input']>;
  CUSTOM_LINK?: InputMaybe<Scalars['String']['input']>;
  DESCRIPTION?: InputMaybe<Scalars['String']['input']>;
  DISCUSSION_SECTION?: InputMaybe<Scalars['String']['input']>;
  EFFORT?: InputMaybe<Scalars['String']['input']>;
  ENROLL_BUTTON_TEXT?: InputMaybe<Scalars['String']['input']>;
  FORMAT?: InputMaybe<Scalars['String']['input']>;
  IMAGE?: InputMaybe<Scalars['String']['input']>;
  INSTITUTION?: InputMaybe<Scalars['String']['input']>;
  INSTRUCTORS?: InputMaybe<Scalars['String']['input']>;
  LANGUAGE?: InputMaybe<Scalars['String']['input']>;
  LENGTH?: InputMaybe<Scalars['String']['input']>;
  LEVEL?: InputMaybe<Scalars['String']['input']>;
  LOCATION?: InputMaybe<Scalars['String']['input']>;
  METHOD?: InputMaybe<Scalars['String']['input']>;
  OBJECT_ID?: InputMaybe<Scalars['String']['input']>;
  PROVIDER?: InputMaybe<Scalars['String']['input']>;
  PROVIDER_ACCESS?: InputMaybe<Scalars['String']['input']>;
  REFLECTION_SECTION?: InputMaybe<Scalars['String']['input']>;
  SUBJECT?: InputMaybe<Scalars['String']['input']>;
  TEAMS?: InputMaybe<Scalars['String']['input']>;
  TEAMSIZE?: InputMaybe<Scalars['String']['input']>;
  TEASER?: InputMaybe<Scalars['String']['input']>;
  TOTAL_EFFORT?: InputMaybe<Scalars['String']['input']>;
};

export type RProductOrder = {
  __typename?: 'ProductOrder';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<RProductRow>>>;
  rows?: Maybe<Array<Maybe<RProductOrderRow>>>;
  user?: Maybe<RProductOrderUser>;
};

export type RProductOrderRow = {
  __typename?: 'ProductOrderRow';
  active?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<RProductOrderRowProduct>;
  quantity?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<RProductOrderState>;
};

export type RProductOrderRowProduct = {
  __typename?: 'ProductOrderRowProduct';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<RVariant>;
};

export type RProductOrderState = {
  __typename?: 'ProductOrderState';
  drawStock?: Maybe<Scalars['Boolean']['output']>;
  endState?: Maybe<Scalars['Boolean']['output']>;
  entitlement?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RProductOrderUser = {
  __typename?: 'ProductOrderUser';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RProductOrdersFlat = {
  __typename?: 'ProductOrdersFlat';
  email?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productVariantId?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  spaceId?: Maybe<Scalars['Int']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RProductOrdersFlatResult = {
  __typename?: 'ProductOrdersFlatResult';
  productOrders?: Maybe<Array<Maybe<RProductOrdersFlat>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type RProductParentCurationsFilter = {
  spaceIdIN: Array<Scalars['Int']['input']>;
};

export type RProductParentsFilter = {
  curation: RProductParentCurationsFilter;
};

export type RProductRow = {
  __typename?: 'ProductRow';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<RState>;
  variant?: Maybe<RVariantRow>;
};

export type RProgressPerCourse = {
  __typename?: 'ProgressPerCourse';
  course_id?: Maybe<Scalars['String']['output']>;
  course_name?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['String']['output']>;
};

export type RProvider = {
  __typename?: 'Provider';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  type: RLearningContentProvider;
  updatedAt: Scalars['DateTime']['output'];
};

export type RProviderContentOrigin = {
  __typename?: 'ProviderContentOrigin';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  metadata: RProviderContentOriginMetadata;
  provider: RProvider;
  providerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RProviderContentOriginMetadata = {
  __typename?: 'ProviderContentOriginMetadata';
  programId?: Maybe<Scalars['String']['output']>;
  programSlug?: Maybe<Scalars['String']['output']>;
};

export type RProviderContentOriginPage = {
  __typename?: 'ProviderContentOriginPage';
  data: Array<RProviderContentOrigin>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RPurchaseProduct = {
  id: Scalars['Int']['input'];
  productVariantId?: InputMaybe<Scalars['Int']['input']>;
};

export type RPurchaseResponse = {
  __typename?: 'PurchaseResponse';
  orderId: Scalars['Int']['output'];
};

export type RQuery = {
  __typename?: 'Query';
  _blank?: Maybe<Scalars['Boolean']['output']>;
  articleRedirect?: Maybe<RArticleRedirect>;
  assignmentTemplates: Array<RAssignmentTemplate>;
  assignmentV3?: Maybe<RAssignmentV3>;
  assignmentV3Learnings: RAssignmentV3LearningPage;
  assignmentV3Users: RAssignmentV3UsersPage;
  assignmentsV3: RAssignmentsV3Page;
  assignmentsV3UserRuleFields: Array<RAssignmentsV3UserRuleField>;
  assignmentsV3UserRulePreview: RAssignmentsV3UserRulePreview;
  blockV2: RBlockV2;
  catalog?: Maybe<RCatalog>;
  /** fetch data from the table: "clsapi.learning" */
  clsapi_learning: Array<RClsapi_Learning>;
  /** fetch aggregated fields from the table: "clsapi.learning" */
  clsapi_learning_aggregate: RClsapi_Learning_Aggregate;
  /** fetch data from the table: "clsapi.learning" using primary key columns */
  clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** fetch data from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys: Array<RClsapi_Learning_Journeys>;
  /** fetch aggregated fields from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys_aggregate: RClsapi_Learning_Journeys_Aggregate;
  /** fetch data from the table: "clsapi.learning_journeys" using primary key columns */
  clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  competence: RCompetence;
  competences: RCompetencePage;
  enrollments: Array<REnrollment>;
  exportLicenses?: Maybe<Array<Maybe<RIssuedLicense>>>;
  featureToggle: RFeatureToggle;
  featureToggles: RFeatureTogglePage;
  getAggregationByName?: Maybe<RAggregation>;
  getAllOrdersByState?: Maybe<Array<Maybe<ROrderRowFlat>>>;
  getAllOrdersFiltered?: Maybe<RFilteredOrdersFlatResult>;
  getAllProductsShort?: Maybe<Array<Maybe<RProductLabel>>>;
  getApprovalsFiltered?: Maybe<RFilteredOrdersFlatResult>;
  getCategories?: Maybe<Array<Maybe<RCategory>>>;
  getCompletionRatePerCourse?: Maybe<Array<RCompletionRatePerCourse>>;
  getCompletionsOverTime?: Maybe<Array<Maybe<RDataItem>>>;
  getCurrentUserFeatureToggles?: Maybe<Array<RUserFeatureToggle>>;
  getEffortForRealm?: Maybe<Array<Maybe<REffortForRealm>>>;
  getEffortForRealmOverTime?: Maybe<Array<Maybe<REffortForRealmOverTime>>>;
  getElearningCompletionPerLearningIdByUserId?: Maybe<Array<Maybe<RCompletionPerElearning>>>;
  getHistoricCompletedCourses?: Maybe<Array<Maybe<RHistoricCompletedCourse>>>;
  getInventory?: Maybe<RInventory>;
  getLanguages?: Maybe<Array<Maybe<RLanguage>>>;
  getLearnifierCourses?: Maybe<Array<Maybe<RLearnifierCourse>>>;
  getLearningProgress?: Maybe<Array<Maybe<RAnalyticsProgress>>>;
  getMeta?: Maybe<RUserMeta>;
  getOrderHistoryById?: Maybe<Array<Maybe<ROrderHistory>>>;
  getOrders?: Maybe<ROrderSearch>;
  getParticipants?: Maybe<RParticipantsResult>;
  getParticipations: Array<RParticipationGroup>;
  getPost?: Maybe<RPost>;
  getProduct?: Maybe<RProduct>;
  getProductOrdersFlat?: Maybe<RProductOrdersFlatResult>;
  getProducts?: Maybe<Array<Maybe<RProduct>>>;
  getProgressPerCourseByUser?: Maybe<Array<Maybe<RProgressPerCourse>>>;
  getReplies?: Maybe<RReplies>;
  getSebUsersEmpAndActive?: Maybe<RSebUsersEmpAndActive>;
  getSection?: Maybe<RSection>;
  getSectionConversation?: Maybe<RSection>;
  getSpaces?: Maybe<Array<Maybe<RSpace>>>;
  getTeamsSubscriptionConfig?: Maybe<RTeamsSubscription>;
  getTotalTimeSpentPerProvider?: Maybe<Array<Maybe<REffortPerProvider>>>;
  getUserActivitySummary?: Maybe<Array<Maybe<RUserActivitySummary>>>;
  getUserById?: Maybe<RUser>;
  getUserEffort: RUserEffort;
  getUserEffortForRealmOverTime?: Maybe<RUserEffortOverTime>;
  getUserReplies?: Maybe<RReplies>;
  getUserStatuses?: Maybe<Array<Maybe<RDataItem>>>;
  getUsersByEmail?: Maybe<Array<Maybe<RUser>>>;
  getUsersPerVerb?: Maybe<Array<Maybe<RDataItem>>>;
  getVariants?: Maybe<Array<Maybe<RVariant>>>;
  getXAPISession: RXapiSession;
  isLearningCompleted: Scalars['Boolean']['output'];
  isLearningPathCompleted?: Maybe<Scalars['Boolean']['output']>;
  issuedLicense?: Maybe<RIssuedLicense>;
  issuedLicenses?: Maybe<Array<Maybe<RIssuedLicense>>>;
  learning?: Maybe<RLearning>;
  learningCollection?: Maybe<RLearningCollection>;
  learningCollections?: Maybe<Array<Maybe<RLearningCollection>>>;
  learningContentByIds: Array<RLearningContent>;
  learningJourney: RLearningJourney;
  learningJourneyContentInsightsCSV: Scalars['String']['output'];
  learningJourneyItemsCatalog: RLearningContentsPage;
  learningJourneySession: RLearningJourneySession;
  learningJourneySessions: RLearningJourneySessionPage;
  learningJourneyTemplates: Array<RLearningJourneyTemplateDto>;
  learningJourneys: RLearningJourneyPage;
  learningLoop: RLearningLoop;
  learningLoopReflectionByLearningLoopId?: Maybe<RLearningLoopReflection>;
  learningLoops: RLearningLoopPage;
  learningQuizInsightsCSV: Scalars['String']['output'];
  learningV2: RLearningV2;
  learningsPinned: Array<RLearningPinned>;
  me: RMe;
  moduleV2: RModuleV2;
  modules?: Maybe<Array<Maybe<RModule>>>;
  myEnrollments: Array<REventEnrollment>;
  myLearningActivityQuizSubmissions: Array<RUserLearningActivityQuizSubmission>;
  notifications?: Maybe<Array<Maybe<RBabelonNotification>>>;
  overLicenseLimit?: Maybe<Scalars['Boolean']['output']>;
  pageV2: RPageV2;
  pages?: Maybe<Array<Maybe<RPage>>>;
  providerAccessProductId?: Maybe<RIdResponse>;
  realmConfig?: Maybe<RRealmConfig>;
  realmFeatureToggles?: Maybe<Array<RRealmFeatureToggle>>;
  realmProviderLicensesSummary?: Maybe<RRealmProviderLicenseSummary>;
  realmV2?: Maybe<RRealmV2>;
  realmV2BySlug?: Maybe<RRealmV2>;
  realms: Array<RRealm>;
  realmsV2: RRealmsV2Page;
  /** fetch data from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealm: Array<RReportingAggregationEffortForRealm>;
  /** fetch aggregated fields from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealmAggregate: RReportingAggregationEffortForRealm_Aggregate;
  /** fetch data from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsights: Array<RReportingLearningJourneyContentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsightsAggregate: RReportingLearningJourneyContentInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsights: Array<RReportingPracticalAssessmentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsightsAggregate: RReportingPracticalAssessmentInsights_Aggregate;
  /** fetch data from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswers: Array<RReportingSurveyAnswers>;
  /** fetch aggregated fields from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswersAggregate: RReportingSurveyAnswers_Aggregate;
  searchLearningContent: RLearningContentsPage;
  searchLearningContentByCurations: RLearningContentsPage;
  searchSubordinateUsersByEmail?: Maybe<Array<Maybe<RUser>>>;
  selfTrackedLearning: RSelfTrackedLearning;
  selfTrackedLearnings: RSelfTrackedLearningsPage;
  space: RSpaceV2;
  spaceMembers: RSpaceMemberPage;
  spaces: RSpaceV2Page;
  surveyAnswerInsightsCSV: Scalars['String']['output'];
  team?: Maybe<RTeam>;
  teamUsers: RTeamUsersPage;
  teamUsersList: RUserList;
  teams: RTeamsPage;
  user?: Maybe<RUserV2>;
  userLearningContentLastParticipation?: Maybe<RParticipationV2>;
  userLearningContentLastParticipations: Array<RParticipationV2>;
  userLearningSubmissions: Array<RUserLearningSubmission>;
  userPendingTours: Array<RTour>;
  userTeams: RTeamUsersPage;
  users: RUsersPage;
};


export type RQueryArticleRedirectArgs = {
  articleId: Scalars['Int']['input'];
};


export type RQueryAssignmentV3Args = {
  id: Scalars['Int']['input'];
};


export type RQueryAssignmentV3LearningsArgs = {
  id: Scalars['Int']['input'];
  pagination?: InputMaybe<RPagination>;
};


export type RQueryAssignmentV3UsersArgs = {
  filter: RAssignmentV3UsersFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
};


export type RQueryAssignmentsV3Args = {
  filter?: InputMaybe<RAssignmentsV3Filter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentsV3Sort>;
};


export type RQueryAssignmentsV3UserRuleFieldsArgs = {
  filter: RAssignmentsV3UserRuleFieldFilter;
};


export type RQueryAssignmentsV3UserRulePreviewArgs = {
  filter: RAssignmentsV3UserRulePreviewFilter;
};


export type RQueryBlockV2Args = {
  id: Scalars['Int']['input'];
};


export type RQueryCatalogArgs = {
  items?: InputMaybe<Array<InputMaybe<RCatalogItems>>>;
  showArchived?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RQueryClsapi_LearningArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};


export type RQueryClsapi_Learning_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};


export type RQueryClsapi_Learning_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryClsapi_Learning_JourneysArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};


export type RQueryClsapi_Learning_Journeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};


export type RQueryClsapi_Learning_Journeys_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryCompetenceArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryCompetencesArgs = {
  filter: RCompetencesFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RCompetencesSort>;
};


export type RQueryEnrollmentsArgs = {
  input: REnrollmentsInput;
};


export type RQueryExportLicensesArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RQueryFeatureToggleArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryFeatureTogglesArgs = {
  filter?: InputMaybe<RFeatureToggleFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RFeatureToggleSort>;
};


export type RQueryGetAggregationByNameArgs = {
  name: Scalars['String']['input'];
};


export type RQueryGetAllOrdersByStateArgs = {
  stateId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetAllOrdersFilteredArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetApprovalsFilteredArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetCompletionRatePerCourseArgs = {
  userTeamIdIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  usersPool?: InputMaybe<RUsersPool>;
};


export type RQueryGetCompletionsOverTimeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};


export type RQueryGetCurrentUserFeatureTogglesArgs = {
  realm: Scalars['String']['input'];
};


export type RQueryGetEffortForRealmArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  select?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};


export type RQueryGetEffortForRealmOverTimeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};


export type RQueryGetElearningCompletionPerLearningIdByUserIdArgs = {
  user_id: Scalars['Int']['input'];
};


export type RQueryGetMetaArgs = {
  type: Scalars['String']['input'];
};


export type RQueryGetOrderHistoryByIdArgs = {
  orderId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetOrdersArgs = {
  orderBy: Scalars['String']['input'];
  orderByDirection: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  query: ROrderSearchQuery;
};


export type RQueryGetParticipantsArgs = {
  orderDirection?: InputMaybe<ROrderDirection>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  stateIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type RQueryGetParticipationsArgs = {
  input: RParticipationsInput;
};


export type RQueryGetPostArgs = {
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetProductArgs = {
  fetchAllVariants?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
};


export type RQueryGetProductOrdersFlatArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  stateIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type RQueryGetProgressPerCourseByUserArgs = {
  email: Scalars['String']['input'];
};


export type RQueryGetRepliesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetSebUsersEmpAndActiveArgs = {
  usersPool: RUsersPool;
};


export type RQueryGetSectionArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
};


export type RQueryGetSectionConversationArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
  postId: Scalars['Int']['input'];
  replies?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetTeamsSubscriptionConfigArgs = {
  id: Scalars['String']['input'];
};


export type RQueryGetUserActivitySummaryArgs = {
  usersPool: RUsersPool;
};


export type RQueryGetUserByIdArgs = {
  userId: Scalars['Int']['input'];
};


export type RQueryGetUserEffortArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};


export type RQueryGetUserEffortForRealmOverTimeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  userIdIn?: InputMaybe<Scalars['Int']['input']>;
  usersPool: RUsersPool;
};


export type RQueryGetUserRepliesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  path: Array<Scalars['String']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryGetUserStatusesArgs = {
  usersPool: RUsersPool;
};


export type RQueryGetUsersByEmailArgs = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type RQueryGetUsersPerVerbArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  usersPool: RUsersPool;
};


export type RQueryGetVariantsArgs = {
  productId: Scalars['Int']['input'];
};


export type RQueryGetXapiSessionArgs = {
  learningId: Scalars['Int']['input'];
};


export type RQueryIsLearningCompletedArgs = {
  learningId: Scalars['Int']['input'];
};


export type RQueryIsLearningPathCompletedArgs = {
  learningPathId: Scalars['Int']['input'];
};


export type RQueryIssuedLicenseArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryIssuedLicensesArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type RQueryLearningArgs = {
  id: Scalars['ID']['input'];
};


export type RQueryLearningCollectionArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryLearningContentByIdsArgs = {
  input: Array<Scalars['URN']['input']>;
  pagination?: InputMaybe<RPagination>;
};


export type RQueryLearningJourneyArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryLearningJourneyContentInsightsCsvArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryLearningJourneyItemsCatalogArgs = {
  filter: RLearningJourneyItemsCatalogFilter;
  pagination?: InputMaybe<RPagination>;
};


export type RQueryLearningJourneySessionArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryLearningJourneySessionsArgs = {
  spaceId: Scalars['Int']['input'];
};


export type RQueryLearningJourneysArgs = {
  filter: RLearningJourneyFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RLearningJourneySort>;
};


export type RQueryLearningLoopArgs = {
  id: Scalars['String']['input'];
};


export type RQueryLearningLoopReflectionByLearningLoopIdArgs = {
  learningLoopId: Scalars['String']['input'];
};


export type RQueryLearningLoopsArgs = {
  filter: RLearningLoopFilter;
  pagination?: InputMaybe<RPagination>;
};


export type RQueryLearningQuizInsightsCsvArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryLearningV2Args = {
  id: Scalars['Int']['input'];
};


export type RQueryLearningsPinnedArgs = {
  realm: Scalars['String']['input'];
};


export type RQueryModuleV2Args = {
  id: Scalars['Int']['input'];
};


export type RQueryModulesArgs = {
  learningId: Scalars['ID']['input'];
};


export type RQueryMyLearningActivityQuizSubmissionsArgs = {
  input: RUserLearningActivityQuizSubmissionFilterInput;
};


export type RQueryOverLicenseLimitArgs = {
  realm?: InputMaybe<Scalars['String']['input']>;
};


export type RQueryPageV2Args = {
  id: Scalars['Int']['input'];
};


export type RQueryPagesArgs = {
  moduleId: Scalars['ID']['input'];
};


export type RQueryProviderAccessProductIdArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};


export type RQueryRealmConfigArgs = {
  realm: Scalars['String']['input'];
};


export type RQueryRealmFeatureTogglesArgs = {
  realm: Scalars['String']['input'];
};


export type RQueryRealmProviderLicensesSummaryArgs = {
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};


export type RQueryRealmV2Args = {
  id: Scalars['Float']['input'];
};


export type RQueryRealmV2BySlugArgs = {
  slug: Scalars['String']['input'];
};


export type RQueryRealmsV2Args = {
  filter?: InputMaybe<RRealmsFilter>;
  pagination?: InputMaybe<RPagination>;
};


export type RQueryReportingAggregationEffortForRealmArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};


export type RQueryReportingAggregationEffortForRealmAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};


export type RQueryReportingLearningJourneyContentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};


export type RQueryReportingLearningJourneyContentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};


export type RQueryReportingPracticalAssessmentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};


export type RQueryReportingPracticalAssessmentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};


export type RQueryReportingSurveyAnswersArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};


export type RQueryReportingSurveyAnswersAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};


export type RQuerySearchLearningContentArgs = {
  filter: RSearchLearningContentFilter;
  pagination?: InputMaybe<RPagination>;
};


export type RQuerySearchLearningContentByCurationsArgs = {
  filter: RCurationFilter;
  pagination?: InputMaybe<RPagination>;
};


export type RQuerySearchSubordinateUsersByEmailArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<RSearchSubordinateUsersInput>;
};


export type RQuerySelfTrackedLearningArgs = {
  id: Scalars['Int']['input'];
};


export type RQuerySelfTrackedLearningsArgs = {
  filter: RSelfTrackedLearningsFilter;
  pagination?: InputMaybe<RPagination>;
};


export type RQuerySpaceArgs = {
  id: Scalars['Int']['input'];
};


export type RQuerySpaceMembersArgs = {
  filter: RSpaceMemberFilter;
  pagination?: InputMaybe<RPagination>;
};


export type RQuerySpacesArgs = {
  pagination?: InputMaybe<RPagination>;
};


export type RQuerySurveyAnswerInsightsCsvArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryTeamArgs = {
  id: Scalars['Int']['input'];
};


export type RQueryTeamUsersArgs = {
  filter: RTeamUsersFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};


export type RQueryTeamUsersListArgs = {
  filter: RTeamUsersFilter;
};


export type RQueryTeamsArgs = {
  filter?: InputMaybe<RTeamsFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamsSort>;
};


export type RQueryUserArgs = {
  id: Scalars['Float']['input'];
};


export type RQueryUserLearningContentLastParticipationArgs = {
  learningContentId: Scalars['URN']['input'];
  userId: Scalars['Int']['input'];
};


export type RQueryUserLearningContentLastParticipationsArgs = {
  learningContentIdIN: Array<Scalars['URN']['input']>;
  userId: Scalars['Int']['input'];
};


export type RQueryUserLearningSubmissionsArgs = {
  input: RUserLearningSubmissionsFilter;
};


export type RQueryUserTeamsArgs = {
  filter: RUserTeamsFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};


export type RQueryUsersArgs = {
  filter?: InputMaybe<RUsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RUsersSort>;
};

export type RQueryResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RRealm = {
  __typename?: 'Realm';
  name: Scalars['String']['output'];
  realm: Scalars['String']['output'];
};

export type RRealmConfig = {
  __typename?: 'RealmConfig';
  heroImageUrl?: Maybe<Scalars['String']['output']>;
  heroVideoUrl?: Maybe<Scalars['String']['output']>;
  languageDefault?: Maybe<Scalars['String']['output']>;
};

export type RRealmFeatureToggle = {
  __typename?: 'RealmFeatureToggle';
  name: Scalars['String']['output'];
};

export type RRealmProviderLicenseSummary = {
  __typename?: 'RealmProviderLicenseSummary';
  catalogUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  pendingLicenses: Scalars['Int']['output'];
  provider: Scalars['String']['output'];
  realmId: Scalars['Int']['output'];
};

export enum RRealmRole {
  Admin = 'admin',
  User = 'user'
}

export type RRealmV2 = {
  __typename?: 'RealmV2';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type RRealmsFilter = {
  idNIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RRealmsV2Page = {
  __typename?: 'RealmsV2Page';
  data: Array<RRealmV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RReplies = {
  __typename?: 'Replies';
  post?: Maybe<RPost>;
  replies?: Maybe<RPostsMeta>;
};

export type RReturnData = {
  __typename?: 'ReturnData';
  value?: Maybe<Scalars['String']['output']>;
};

export type RRole = {
  __typename?: 'Role';
  label?: Maybe<Scalars['String']['output']>;
};

export type RSadminPurchaseProduct = {
  id: Scalars['Int']['input'];
  productVariantId?: InputMaybe<Scalars['Int']['input']>;
  spaceId: Scalars['Int']['input'];
};

export type RSadminPurchaseResponse = RMutationResponse & {
  __typename?: 'SadminPurchaseResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  result?: Maybe<Array<Maybe<RSadminPurchaseResult>>>;
  success: Scalars['Boolean']['output'];
};

export type RSadminPurchaseResult = {
  __typename?: 'SadminPurchaseResult';
  email?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
};

export type RSearchLearningContentFilter = {
  idIN?: InputMaybe<Array<Scalars['String']['input']>>;
  originIdIN?: InputMaybe<Array<Scalars['String']['input']>>;
  providerIn?: InputMaybe<Array<RLearningContentProvider>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RSearchSubordinateUsersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type RSebUsersEmpAndActive = {
  __typename?: 'SebUsersEmpAndActive';
  count?: Maybe<Scalars['Int']['output']>;
};

export type RSection = {
  __typename?: 'Section';
  access?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCorporeal?: Maybe<Scalars['Boolean']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  post?: Maybe<RPost>;
  postCount?: Maybe<Scalars['Int']['output']>;
  posts?: Maybe<RPostsMeta>;
  role?: Maybe<RPermissionsRole>;
  stickyPosts?: Maybe<Array<Maybe<RPost>>>;
  subsections?: Maybe<Array<Maybe<RSubSection>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RSectionPermissions = {
  __typename?: 'SectionPermissions';
  canAddReflections?: Maybe<Scalars['Boolean']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  canEdit?: Maybe<Scalars['Boolean']['output']>;
  canEditSection?: Maybe<Scalars['Boolean']['output']>;
  canLock?: Maybe<Scalars['Boolean']['output']>;
  canPost?: Maybe<Scalars['Boolean']['output']>;
  canPostSticky?: Maybe<Scalars['Boolean']['output']>;
  canPurge?: Maybe<Scalars['Boolean']['output']>;
  canReply?: Maybe<Scalars['Boolean']['output']>;
  canRestore?: Maybe<Scalars['Boolean']['output']>;
  canView?: Maybe<Scalars['Boolean']['output']>;
  canViewReflections?: Maybe<Scalars['Boolean']['output']>;
};

export type RSelfTrackedLearning = {
  __typename?: 'SelfTrackedLearning';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  effort: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  link?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: RSelfTrackedLearningType;
  updatedAt: Scalars['DateTime']['output'];
};

export type RSelfTrackedLearningResult = {
  __typename?: 'SelfTrackedLearningResult';
  selfTrackedLearning?: Maybe<RSelfTrackedLearning>;
  success: Scalars['Boolean']['output'];
};

export enum RSelfTrackedLearningType {
  Article = 'ARTICLE',
  Book = 'BOOK',
  LiveEvent = 'LIVE_EVENT',
  Other = 'OTHER',
  Podcast = 'PODCAST',
  Program = 'PROGRAM',
  Video = 'VIDEO',
  Workshop = 'WORKSHOP'
}

export type RSelfTrackedLearningsFilter = {
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RSelfTrackedLearningsPage = {
  __typename?: 'SelfTrackedLearningsPage';
  data: Array<RSelfTrackedLearning>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RService = {
  __typename?: 'Service';
  id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<RLearnifierCourse>;
  provider?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RServiceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<RLearnifierCourseInput>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RSetEnrollmentAttendanceInput = {
  attended: RAttendedType;
  enrollmentId: Scalars['Int']['input'];
};

export type RSetLanguageInput = {
  language: Scalars['String']['input'];
};

export type RSetUserStatusInput = {
  status?: InputMaybe<RAccountStatus>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RSetUsernameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export enum RSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type RSpace = {
  __typename?: 'Space';
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions: RSpacePermissions;
  realm?: Maybe<Scalars['String']['output']>;
};

export type RSpaceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RSpaceMember = {
  __typename?: 'SpaceMember';
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm: Scalars['String']['output'];
  spaceId: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type RSpaceMemberFilter = {
  emailIN?: InputMaybe<Array<Scalars['String']['input']>>;
  idIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeGlobalAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  spaceIdIN: Array<Scalars['Int']['input']>;
};

export type RSpaceMemberPage = {
  __typename?: 'SpaceMemberPage';
  data: Array<RSpaceMember>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RSpacePermissions = {
  __typename?: 'SpacePermissions';
  hasWriteAccessToLearningContent?: Maybe<Scalars['Boolean']['output']>;
};

export type RSpaceV2 = {
  __typename?: 'SpaceV2';
  contentOrigins: RProviderContentOriginPage;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: RSpacePermissions;
  realm?: Maybe<Scalars['String']['output']>;
  roleAssignments: RSpaceV2RoleAssignmentPage;
  updatedAt: Scalars['DateTime']['output'];
};


export type RSpaceV2ContentOriginsArgs = {
  pagination?: InputMaybe<RPagination>;
};


export type RSpaceV2RoleAssignmentsArgs = {
  pagination?: InputMaybe<RPagination>;
};

export type RSpaceV2Page = {
  __typename?: 'SpaceV2Page';
  data: Array<RSpaceV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RSpaceV2Result = {
  __typename?: 'SpaceV2Result';
  space?: Maybe<RSpaceV2>;
  success: Scalars['Boolean']['output'];
};

export enum RSpaceV2Role {
  Admin = 'admin',
  DiscussionsModerator = 'discussions_moderator',
  Learner = 'learner'
}

export type RSpaceV2RoleAssignResultDto = {
  __typename?: 'SpaceV2RoleAssignResultDto';
  spaceRoleAssignment?: Maybe<RSpaceV2RoleAssignment>;
  success: Scalars['Boolean']['output'];
};

export type RSpaceV2RoleAssignment = {
  __typename?: 'SpaceV2RoleAssignment';
  createdAt: Scalars['DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  realm?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RSpaceV2Role>;
  roleDisplayName?: Maybe<Scalars['String']['output']>;
  spaceId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<RUserV2>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RSpaceV2RoleAssignmentPage = {
  __typename?: 'SpaceV2RoleAssignmentPage';
  data: Array<RSpaceV2RoleAssignment>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RState = {
  __typename?: 'State';
  flags?: Maybe<RStateFlag>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RStateFlag = {
  __typename?: 'StateFlag';
  drawStock?: Maybe<Scalars['Boolean']['output']>;
  endState?: Maybe<Scalars['Boolean']['output']>;
  entitlement?: Maybe<Scalars['Boolean']['output']>;
};

export type RStateFlagInput = {
  drawStock?: InputMaybe<Scalars['Boolean']['input']>;
  endState?: InputMaybe<Scalars['Boolean']['input']>;
  entitlement?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RStateInput = {
  flags?: InputMaybe<RStateFlagInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RStats = {
  __typename?: 'Stats';
  likes?: Maybe<Scalars['Int']['output']>;
  replies?: Maybe<Scalars['Int']['output']>;
};

export type RStock = {
  __typename?: 'Stock';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reserved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RStockInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reserved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type RString_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type RSubSection = {
  __typename?: 'SubSection';
  access?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCorporeal?: Maybe<Scalars['Boolean']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<RPermissionsRole>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RSubject = {
  __typename?: 'Subject';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  urlKey?: Maybe<Scalars['String']['output']>;
};

export type RSubscription = {
  __typename?: 'Subscription';
  /** fetch data from the table: "clsapi.learning" */
  clsapi_learning: Array<RClsapi_Learning>;
  /** fetch aggregated fields from the table: "clsapi.learning" */
  clsapi_learning_aggregate: RClsapi_Learning_Aggregate;
  /** fetch data from the table: "clsapi.learning" using primary key columns */
  clsapi_learning_by_pk?: Maybe<RClsapi_Learning>;
  /** fetch data from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys: Array<RClsapi_Learning_Journeys>;
  /** fetch aggregated fields from the table: "clsapi.learning_journeys" */
  clsapi_learning_journeys_aggregate: RClsapi_Learning_Journeys_Aggregate;
  /** fetch data from the table: "clsapi.learning_journeys" using primary key columns */
  clsapi_learning_journeys_by_pk?: Maybe<RClsapi_Learning_Journeys>;
  /** fetch data from the table in a streaming manner: "clsapi.learning_journeys" */
  clsapi_learning_journeys_stream: Array<RClsapi_Learning_Journeys>;
  /** fetch data from the table in a streaming manner: "clsapi.learning" */
  clsapi_learning_stream: Array<RClsapi_Learning>;
  /** fetch data from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealm: Array<RReportingAggregationEffortForRealm>;
  /** fetch aggregated fields from the table: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealmAggregate: RReportingAggregationEffortForRealm_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_aggregation_effort_for_realm" */
  reportingAggregationEffortForRealm_stream: Array<RReportingAggregationEffortForRealm>;
  /** fetch data from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsights: Array<RReportingLearningJourneyContentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsightsAggregate: RReportingLearningJourneyContentInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_learning_journey_content_insights" */
  reportingLearningJourneyContentInsights_stream: Array<RReportingLearningJourneyContentInsights>;
  /** fetch data from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsights: Array<RReportingPracticalAssessmentInsights>;
  /** fetch aggregated fields from the table: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsightsAggregate: RReportingPracticalAssessmentInsights_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_practical_assessment_insights" */
  reportingPracticalAssessmentInsights_stream: Array<RReportingPracticalAssessmentInsights>;
  /** fetch data from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswers: Array<RReportingSurveyAnswers>;
  /** fetch aggregated fields from the table: "reporting.mv_survey_answers" */
  reportingSurveyAnswersAggregate: RReportingSurveyAnswers_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.mv_survey_answers" */
  reportingSurveyAnswers_stream: Array<RReportingSurveyAnswers>;
};


export type RSubscriptionClsapi_LearningArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};


export type RSubscriptionClsapi_Learning_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};


export type RSubscriptionClsapi_Learning_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type RSubscriptionClsapi_Learning_JourneysArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};


export type RSubscriptionClsapi_Learning_Journeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RClsapi_Learning_Journeys_Order_By>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};


export type RSubscriptionClsapi_Learning_Journeys_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type RSubscriptionClsapi_Learning_Journeys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RClsapi_Learning_Journeys_Stream_Cursor_Input>>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};


export type RSubscriptionClsapi_Learning_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RClsapi_Learning_Stream_Cursor_Input>>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};


export type RSubscriptionReportingAggregationEffortForRealmArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};


export type RSubscriptionReportingAggregationEffortForRealmAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingAggregationEffortForRealm_Order_By>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};


export type RSubscriptionReportingAggregationEffortForRealm_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingAggregationEffortForRealm_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
};


export type RSubscriptionReportingLearningJourneyContentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};


export type RSubscriptionReportingLearningJourneyContentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};


export type RSubscriptionReportingLearningJourneyContentInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingLearningJourneyContentInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
};


export type RSubscriptionReportingPracticalAssessmentInsightsArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};


export type RSubscriptionReportingPracticalAssessmentInsightsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Order_By>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};


export type RSubscriptionReportingPracticalAssessmentInsights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingPracticalAssessmentInsights_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
};


export type RSubscriptionReportingSurveyAnswersArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};


export type RSubscriptionReportingSurveyAnswersAggregateArgs = {
  distinct_on?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RReportingSurveyAnswers_Order_By>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};


export type RSubscriptionReportingSurveyAnswers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RReportingSurveyAnswers_Stream_Cursor_Input>>;
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
};

export type RTeam = {
  __typename?: 'Team';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentTeamId?: Maybe<Scalars['Int']['output']>;
  parentTeams: RTeamsPage;
  realm: Scalars['String']['output'];
  subTeams: RTeamsPage;
  teamUsers: RTeamUsersPage;
  updatedAt: Scalars['DateTime']['output'];
};


export type RTeamParentTeamsArgs = {
  filter?: InputMaybe<RTeamRelsInput>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamsSort>;
};


export type RTeamSubTeamsArgs = {
  filter?: InputMaybe<RTeamRelsInput>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamsSort>;
};


export type RTeamTeamUsersArgs = {
  filter?: InputMaybe<RTeamsTeamUsersFilter>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};

export type RTeamCreateInput = {
  name: Scalars['String']['input'];
  parentTeamId?: InputMaybe<Scalars['Int']['input']>;
  realm: Scalars['String']['input'];
};

export type RTeamRelsInput = {
  indirect?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RTeamRole {
  Manager = 'manager',
  Member = 'member'
}

export type RTeamUpdateInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  parentTeamId?: InputMaybe<Scalars['Int']['input']>;
  realm: Scalars['String']['input'];
};

export type RTeamUser = {
  __typename?: 'TeamUser';
  createdAt: Scalars['DateTime']['output'];
  role: RTeamRole;
  team: RTeam;
  teamId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: RUserV2;
  userId: Scalars['Int']['output'];
};

export type RTeamUserChangeRoleInput = {
  role: RTeamRole;
  teamId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RTeamUserRemoveInput = {
  teamId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RTeamUsersAddInput = {
  teamId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type RTeamUsersFilter = {
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  teamIdIN: Array<Scalars['Int']['input']>;
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RTeamUsersPage = {
  __typename?: 'TeamUsersPage';
  data: Array<RTeamUser>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RTeamUsersSort = {
  field?: InputMaybe<RTeamUsersSortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RTeamUsersSortField {
  Email = 'EMAIL',
  Id = 'ID',
  Status = 'STATUS',
  Teamname = 'TEAMNAME',
  Username = 'USERNAME'
}

export type RTeamsFilter = {
  parentTeamEQ?: InputMaybe<RTeamsParentEqFilter>;
  realm?: InputMaybe<Scalars['String']['input']>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  teamNEQ?: InputMaybe<RTeamsTeamNeqFilter>;
};

export type RTeamsPage = {
  __typename?: 'TeamsPage';
  data: Array<RTeam>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type RTeamsParentEqFilter = {
  id?: InputMaybe<Scalars['Int']['input']>;
  indirect?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RTeamsSort = {
  field?: InputMaybe<RTeamsSortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RTeamsSortField {
  Id = 'ID',
  Name = 'NAME'
}

export type RTeamsSubscription = {
  __typename?: 'TeamsSubscription';
  appType: Scalars['String']['output'];
  collegialUserId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  notificationTriggers: Array<RNotificationTrigger>;
  teamsUserId: Scalars['String']['output'];
  webhookUrl: Scalars['String']['output'];
};

export type RTeamsTeamNeqFilter = {
  excludeAllSubteams?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RTeamsTeamUsersFilter = {
  role?: InputMaybe<RTeamRole>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RTour = {
  __typename?: 'Tour';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RTourUserCompletions = {
  __typename?: 'TourUserCompletions';
  createdAt: Scalars['DateTime']['output'];
  tourId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['Int']['output'];
};

export type RUpdateCollection = {
  contentOwner?: InputMaybe<Scalars['Int']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  languageId?: InputMaybe<Scalars['Int']['input']>;
  spaceId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<RLearningStatus>;
  subjectId?: InputMaybe<Scalars['Int']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<RLearningVisibility>;
};

export type RUpdateCollectionItems = {
  id: Scalars['ID']['input'];
  items: Array<InputMaybe<RCollectionItemInput>>;
};

export type RUpdateCompetenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RUpdateLearningJourneyInput = {
  bannerURL?: InputMaybe<Scalars['String']['input']>;
  businessGoal?: InputMaybe<Scalars['String']['input']>;
  completionCriteria?: InputMaybe<RLearningJourneyCompletionStatus>;
  completionInvestTimeSec?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  impactSurveysEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isLinear?: InputMaybe<Scalars['Boolean']['input']>;
  spaceFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<RLearningJourneyStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RUpdateResponse = {
  __typename?: 'UpdateResponse';
  orderId?: Maybe<Scalars['Int']['output']>;
};

export type RUpdateSectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  path: Array<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RUpdateSelfTrackedLearningInput = {
  description: Scalars['String']['input'];
  effort: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: RSelfTrackedLearningType;
};

export type RUpdateSpaceV2Input = {
  id: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  realm: Scalars['String']['input'];
};

export type RUpdateSpaceV2RoleAssignInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  role: RSpaceV2Role;
  roleDisplayName?: InputMaybe<Scalars['String']['input']>;
};

export type RUpsertAssignmentInput = {
  description: Scalars['String']['input'];
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  learningLoopRecommendedEffort?: InputMaybe<Scalars['Int']['input']>;
  learnings?: InputMaybe<Array<RUpsertAssignmentLearningsInput>>;
  name: Scalars['String']['input'];
  rules?: InputMaybe<RAssignmentsV3RuleInput>;
  spaceId: Scalars['Int']['input'];
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  status: RAssignmentV3Status;
  surveyId?: InputMaybe<Scalars['String']['input']>;
  teaser: Scalars['String']['input'];
  totalThresholdEffort?: InputMaybe<Scalars['Int']['input']>;
  type: RAssignmentV3Type;
  users?: InputMaybe<Array<RUpsertAssignmentUsersInput>>;
};

export type RUpsertAssignmentLearningsInput = {
  learningId?: InputMaybe<Scalars['Int']['input']>;
  learningPathId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  type?: InputMaybe<RAssignmentStandardV3LearningType>;
};

export type RUpsertAssignmentUsersInput = {
  userId: Scalars['Int']['input'];
};

export type RUpsertFeatureToggleInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
};

export type RUpsertLearningInput = {
  activities?: InputMaybe<Array<RLearningActivityInputDto>>;
  learning: RLearningV2Input;
  locations?: InputMaybe<Array<RLocationV2Input>>;
  modules?: InputMaybe<Array<RModuleV2Input>>;
  subjects?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RUpsertLearningJourneyCohortsInput = {
  journeyId: Scalars['Int']['input'];
  learnerIds: Array<Scalars['Int']['input']>;
};

export type RUpsertLearningJourneyCompetencesInput = {
  competenceIds: Array<Scalars['Int']['input']>;
  journeyId: Scalars['Int']['input'];
};

export type RUpsertLearningJourneyItemsInput = {
  itemIds: Array<Scalars['String']['input']>;
  journeyId: Scalars['Int']['input'];
};

export type RUser = {
  __typename?: 'User';
  acceptedExtraTerms?: Maybe<Scalars['Boolean']['output']>;
  acceptedGdpr?: Maybe<Scalars['Boolean']['output']>;
  acceptedTos?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Maybe<RUserGroup>>>;
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  hasViewAccessToAdminPanel?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDebugging?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  passwordExpiryDate?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RAccountStatus>;
  timestamp?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RUserActivitySummary = {
  __typename?: 'UserActivitySummary';
  company?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_activity?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_activity?: Maybe<Scalars['String']['output']>;
  participations_completed?: Maybe<Scalars['Int']['output']>;
  participations_registered?: Maybe<Scalars['Int']['output']>;
  participations_started?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  total_effort_hours?: Maybe<Scalars['Float']['output']>;
};

export type RUserChangeInput = {
  acceptedExtraTerms?: InputMaybe<Scalars['Boolean']['input']>;
  acceptedGdpr?: InputMaybe<Scalars['Boolean']['input']>;
  acceptedTos?: InputMaybe<Scalars['Boolean']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['Upload']['input']>;
  statusV2?: InputMaybe<RUserStatusV2>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RUserChangeRealmRoleInput = {
  realm?: InputMaybe<Scalars['String']['input']>;
  role: RRealmRole;
  userId: Scalars['Int']['input'];
};

export type RUserEffort = {
  __typename?: 'UserEffort';
  total: Scalars['Float']['output'];
};

export type RUserEffortOverTime = {
  __typename?: 'UserEffortOverTime';
  average_effort_per_user_in_seconds?: Maybe<Scalars['Float']['output']>;
  completed?: Maybe<Scalars['Int']['output']>;
  completed_not_expired?: Maybe<Scalars['Int']['output']>;
  distinct_completed?: Maybe<Scalars['Int']['output']>;
  distinct_completed_not_expired?: Maybe<Scalars['Int']['output']>;
  distinct_users?: Maybe<Scalars['Int']['output']>;
  expired?: Maybe<Scalars['Int']['output']>;
  in_progress?: Maybe<Scalars['Int']['output']>;
  total_effort_in_seconds?: Maybe<Scalars['Int']['output']>;
};

export type RUserFeatureToggle = {
  __typename?: 'UserFeatureToggle';
  name: Scalars['String']['output'];
};

export type RUserGroup = {
  __typename?: 'UserGroup';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RUserLearningActivityQuizQuestionAnswers = {
  questionId: Scalars['String']['input'];
  selectedOptionIds: Array<Scalars['String']['input']>;
};

export type RUserLearningActivityQuizSubmission = {
  __typename?: 'UserLearningActivityQuizSubmission';
  answers: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  learningActivityId: Scalars['Int']['output'];
  quiz: Scalars['JSON']['output'];
  score: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

export type RUserLearningActivityQuizSubmissionFilterInput = {
  includeOlderVersions: Scalars['Boolean']['input'];
  learningActivityQuizIdsIN: Array<Scalars['Int']['input']>;
};

export type RUserLearningActivityQuizSubmissionInput = {
  answers: Array<RUserLearningActivityQuizQuestionAnswers>;
  learningActivityId: Scalars['Int']['input'];
  version: Scalars['String']['input'];
};

export type RUserLearningSubmission = {
  __typename?: 'UserLearningSubmission';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  learningURNV1: Scalars['String']['output'];
  learningURNV2: Scalars['String']['output'];
  pageURNV1: Scalars['String']['output'];
  pageURNV2: Scalars['String']['output'];
  reviewAssignments: Array<RUserLearningSubmissionReviewAssignment>;
  reviews: Array<RUserLearningSubmissionReview>;
  type: RUserLearningSubmissionType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type RUserLearningSubmissionResult = {
  __typename?: 'UserLearningSubmissionResult';
  submission?: Maybe<RUserLearningSubmission>;
  success: Scalars['Boolean']['output'];
};

export type RUserLearningSubmissionReview = {
  __typename?: 'UserLearningSubmissionReview';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  reviewerId: Scalars['Int']['output'];
  submissionId: Scalars['Int']['output'];
  type: RUserLearningSubmissionReviewType;
  updatedAt: Scalars['DateTime']['output'];
};

export type RUserLearningSubmissionReviewAssignment = {
  __typename?: 'UserLearningSubmissionReviewAssignment';
  createdAt: Scalars['DateTime']['output'];
  expiryAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  reviewerId: Scalars['Int']['output'];
  submission: RUserLearningSubmission;
  submissionId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RUserLearningSubmissionReviewResult = {
  __typename?: 'UserLearningSubmissionReviewResult';
  review?: Maybe<RUserLearningSubmissionReview>;
  success: Scalars['Boolean']['output'];
};

export enum RUserLearningSubmissionReviewType {
  PracticalAssessmentReviewText = 'PRACTICAL_ASSESSMENT_REVIEW_TEXT'
}

export enum RUserLearningSubmissionType {
  PracticalAssessmentDocument = 'PRACTICAL_ASSESSMENT_DOCUMENT',
  PracticalAssessmentText = 'PRACTICAL_ASSESSMENT_TEXT'
}

export type RUserLearningSubmissionsFilter = {
  learningIdsIN: Array<Scalars['Int']['input']>;
  pageIdsIN: Array<Scalars['Int']['input']>;
  userIdsIN: Array<Scalars['Int']['input']>;
};

export type RUserList = {
  __typename?: 'UserList';
  total: Scalars['Int']['output'];
  userIds: Array<Scalars['Int']['output']>;
};

export type RUserMeta = {
  __typename?: 'UserMeta';
  meta?: Maybe<Scalars['JSON']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type RUserRealmRoleAssignment = {
  __typename?: 'UserRealmRoleAssignment';
  realm?: Maybe<Scalars['String']['output']>;
  role: RRealmRole;
};

export type RUserStats = {
  __typename?: 'UserStats';
  liked?: Maybe<Scalars['Int']['output']>;
};

export enum RUserStatus {
  Deactivated = 'deactivated',
  Incomplete = 'incomplete',
  Pending = 'pending',
  Registered = 'registered'
}

export enum RUserStatusV2 {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive'
}

export type RUserTeamsFilter = {
  role?: InputMaybe<RTeamRole>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RUserV2 = {
  __typename?: 'UserV2';
  acceptedExtraTerms: Scalars['Boolean']['output'];
  acceptedGdpr: Scalars['Boolean']['output'];
  acceptedTos: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastActive: Scalars['DateTime']['output'];
  lastPasswordUpdate: Scalars['DateTime']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  realm: Scalars['String']['output'];
  realmRoleAssignments: Array<RUserRealmRoleAssignment>;
  status: RUserStatus;
  statusV2: RUserStatusV2;
  teams: RTeamUsersPage;
  timestamp: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};


export type RUserV2TeamsArgs = {
  filter?: InputMaybe<RUsersUserTeams>;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RTeamUsersSort>;
};

export type RUsersFilter = {
  emailIN?: InputMaybe<Array<Scalars['String']['input']>>;
  idNIN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  realm?: InputMaybe<Scalars['String']['input']>;
  realmRole?: InputMaybe<RRealmRole>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<RUserStatus>>>;
  teamIdIN?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type RUsersPage = {
  __typename?: 'UsersPage';
  data: Array<RUserV2>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RUsersPool {
  Realm = 'REALM',
  Subordinates = 'SUBORDINATES'
}

export type RUsersSort = {
  field?: InputMaybe<RUsersSortField>;
  order?: InputMaybe<RSortOrder>;
};

export enum RUsersSortField {
  Email = 'EMAIL',
  Id = 'ID',
  Status = 'STATUS',
  Username = 'USERNAME'
}

export type RUsersUserTeams = {
  role?: InputMaybe<RTeamRole>;
  teamIdIN?: InputMaybe<Array<Scalars['Int']['input']>>;
  withSubteams?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RVariant = {
  __typename?: 'Variant';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  flags?: Maybe<RProductFlags>;
  id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<RProductMeta>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTo?: Maybe<Scalars['String']['output']>;
};

export type RVariantInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  flags?: InputMaybe<RProductFlagsInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<RProductMetaInput>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
};

export type RVariantResponse = {
  __typename?: 'VariantResponse';
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type RVariantRow = {
  __typename?: 'VariantRow';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  flags?: Maybe<RProductFlags>;
  id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<RProductMeta>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTo?: Maybe<Scalars['String']['output']>;
};

export enum RWaitlistType {
  Automatic = 'AUTOMATIC',
  Disabled = 'DISABLED'
}

export type RXapiSession = {
  __typename?: 'XAPISession';
  queryString?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
};

export type RYou = {
  __typename?: 'You';
  have?: Maybe<RUserStats>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type RBigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "catalog_item_type". All fields are combined with logical 'AND'. */
export type RCatalog_Item_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _gt?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _gte?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _in?: InputMaybe<Array<Scalars['catalog_item_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _lte?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _neq?: InputMaybe<Scalars['catalog_item_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['catalog_item_type']['input']>>;
};

/** columns and relationships of "clsapi.learning" */
export type RClsapi_Learning = {
  __typename?: 'clsapi_learning';
  archived?: Maybe<Scalars['timestamptz']['output']>;
  content_owner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['timestamptz']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  meta?: Maybe<Scalars['json']['output']>;
  preamble: Scalars['String']['output'];
  space_id?: Maybe<Scalars['Int']['output']>;
  status: Scalars['learning_status']['output'];
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['learning_type']['output'];
  updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn_v1?: Maybe<Scalars['String']['output']>;
  urn_v2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['learning_visibility']['output']>;
};


/** columns and relationships of "clsapi.learning" */
export type RClsapi_LearningMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "clsapi.learning" */
export type RClsapi_Learning_Aggregate = {
  __typename?: 'clsapi_learning_aggregate';
  aggregate?: Maybe<RClsapi_Learning_Aggregate_Fields>;
  nodes: Array<RClsapi_Learning>;
};

/** aggregate fields of "clsapi.learning" */
export type RClsapi_Learning_Aggregate_Fields = {
  __typename?: 'clsapi_learning_aggregate_fields';
  avg?: Maybe<RClsapi_Learning_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RClsapi_Learning_Max_Fields>;
  min?: Maybe<RClsapi_Learning_Min_Fields>;
  stddev?: Maybe<RClsapi_Learning_Stddev_Fields>;
  stddev_pop?: Maybe<RClsapi_Learning_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RClsapi_Learning_Stddev_Samp_Fields>;
  sum?: Maybe<RClsapi_Learning_Sum_Fields>;
  var_pop?: Maybe<RClsapi_Learning_Var_Pop_Fields>;
  var_samp?: Maybe<RClsapi_Learning_Var_Samp_Fields>;
  variance?: Maybe<RClsapi_Learning_Variance_Fields>;
};


/** aggregate fields of "clsapi.learning" */
export type RClsapi_Learning_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RClsapi_Learning_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RClsapi_Learning_Avg_Fields = {
  __typename?: 'clsapi_learning_avg_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "clsapi.learning". All fields are combined with a logical 'AND'. */
export type RClsapi_Learning_Bool_Exp = {
  _and?: InputMaybe<Array<RClsapi_Learning_Bool_Exp>>;
  _not?: InputMaybe<RClsapi_Learning_Bool_Exp>;
  _or?: InputMaybe<Array<RClsapi_Learning_Bool_Exp>>;
  archived?: InputMaybe<RTimestamptz_Comparison_Exp>;
  content_owner?: InputMaybe<RInt_Comparison_Exp>;
  created?: InputMaybe<RTimestamptz_Comparison_Exp>;
  creator?: InputMaybe<RInt_Comparison_Exp>;
  effort?: InputMaybe<RInt_Comparison_Exp>;
  id?: InputMaybe<RInt_Comparison_Exp>;
  image?: InputMaybe<RString_Comparison_Exp>;
  language_id?: InputMaybe<RInt_Comparison_Exp>;
  meta?: InputMaybe<RJson_Comparison_Exp>;
  preamble?: InputMaybe<RString_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  status?: InputMaybe<RLearning_Status_Comparison_Exp>;
  teaser?: InputMaybe<RString_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  type?: InputMaybe<RLearning_Type_Comparison_Exp>;
  updated?: InputMaybe<RTimestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<RInt_Comparison_Exp>;
  urn_v1?: InputMaybe<RString_Comparison_Exp>;
  urn_v2?: InputMaybe<RString_Comparison_Exp>;
  visibility?: InputMaybe<RLearning_Visibility_Comparison_Exp>;
};

/** unique or primary key constraints on table "clsapi.learning" */
export enum RClsapi_Learning_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningPkey = 'learning_pkey',
  /** unique or primary key constraint on columns "urn_v1" */
  LearningUrnV1Idx = 'learning_urn_v1_idx',
  /** unique or primary key constraint on columns "urn_v2" */
  LearningUrnV2Idx = 'learning_urn_v2_idx'
}

/** input type for incrementing numeric columns in table "clsapi.learning" */
export type RClsapi_Learning_Inc_Input = {
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "clsapi.learning" */
export type RClsapi_Learning_Insert_Input = {
  archived?: InputMaybe<Scalars['timestamptz']['input']>;
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_status']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['learning_type']['input']>;
  updated?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['learning_visibility']['input']>;
};

/** columns and relationships of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys = {
  __typename?: 'clsapi_learning_journeys';
  banner_url: Scalars['String']['output'];
  business_goal: Scalars['String']['output'];
  completion_criteria: Scalars['learning_journey_completion_criteria']['output'];
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  discussion_enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  impact_surveys_enabled: Scalars['Boolean']['output'];
  is_linear: Scalars['Boolean']['output'];
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_featured: Scalars['Boolean']['output'];
  space_id: Scalars['Int']['output'];
  status: Scalars['learning_journey_status']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Aggregate = {
  __typename?: 'clsapi_learning_journeys_aggregate';
  aggregate?: Maybe<RClsapi_Learning_Journeys_Aggregate_Fields>;
  nodes: Array<RClsapi_Learning_Journeys>;
};

/** aggregate fields of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Aggregate_Fields = {
  __typename?: 'clsapi_learning_journeys_aggregate_fields';
  avg?: Maybe<RClsapi_Learning_Journeys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RClsapi_Learning_Journeys_Max_Fields>;
  min?: Maybe<RClsapi_Learning_Journeys_Min_Fields>;
  stddev?: Maybe<RClsapi_Learning_Journeys_Stddev_Fields>;
  stddev_pop?: Maybe<RClsapi_Learning_Journeys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RClsapi_Learning_Journeys_Stddev_Samp_Fields>;
  sum?: Maybe<RClsapi_Learning_Journeys_Sum_Fields>;
  var_pop?: Maybe<RClsapi_Learning_Journeys_Var_Pop_Fields>;
  var_samp?: Maybe<RClsapi_Learning_Journeys_Var_Samp_Fields>;
  variance?: Maybe<RClsapi_Learning_Journeys_Variance_Fields>;
};


/** aggregate fields of "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RClsapi_Learning_Journeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RClsapi_Learning_Journeys_Avg_Fields = {
  __typename?: 'clsapi_learning_journeys_avg_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "clsapi.learning_journeys". All fields are combined with a logical 'AND'. */
export type RClsapi_Learning_Journeys_Bool_Exp = {
  _and?: InputMaybe<Array<RClsapi_Learning_Journeys_Bool_Exp>>;
  _not?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
  _or?: InputMaybe<Array<RClsapi_Learning_Journeys_Bool_Exp>>;
  banner_url?: InputMaybe<RString_Comparison_Exp>;
  business_goal?: InputMaybe<RString_Comparison_Exp>;
  completion_criteria?: InputMaybe<RLearning_Journey_Completion_Criteria_Comparison_Exp>;
  completion_invest_time?: InputMaybe<RInt_Comparison_Exp>;
  created_at?: InputMaybe<RTimestamptz_Comparison_Exp>;
  description?: InputMaybe<RString_Comparison_Exp>;
  discussion_enabled?: InputMaybe<RBoolean_Comparison_Exp>;
  id?: InputMaybe<RInt_Comparison_Exp>;
  impact_surveys_enabled?: InputMaybe<RBoolean_Comparison_Exp>;
  is_linear?: InputMaybe<RBoolean_Comparison_Exp>;
  source_template_id?: InputMaybe<RInt_Comparison_Exp>;
  space_featured?: InputMaybe<RBoolean_Comparison_Exp>;
  space_id?: InputMaybe<RInt_Comparison_Exp>;
  status?: InputMaybe<RLearning_Journey_Status_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  updated_at?: InputMaybe<RTimestamptz_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

/** unique or primary key constraints on table "clsapi.learning_journeys" */
export enum RClsapi_Learning_Journeys_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningJourneysPk = 'learning_journeys_pk'
}

/** input type for incrementing numeric columns in table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Inc_Input = {
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Insert_Input = {
  banner_url?: InputMaybe<Scalars['String']['input']>;
  business_goal?: InputMaybe<Scalars['String']['input']>;
  completion_criteria?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussion_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impact_surveys_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_linear?: InputMaybe<Scalars['Boolean']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_featured?: InputMaybe<Scalars['Boolean']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_journey_status']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type RClsapi_Learning_Journeys_Max_Fields = {
  __typename?: 'clsapi_learning_journeys_max_fields';
  banner_url?: Maybe<Scalars['String']['output']>;
  business_goal?: Maybe<Scalars['String']['output']>;
  completion_criteria?: Maybe<Scalars['learning_journey_completion_criteria']['output']>;
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_journey_status']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RClsapi_Learning_Journeys_Min_Fields = {
  __typename?: 'clsapi_learning_journeys_min_fields';
  banner_url?: Maybe<Scalars['String']['output']>;
  business_goal?: Maybe<Scalars['String']['output']>;
  completion_criteria?: Maybe<Scalars['learning_journey_completion_criteria']['output']>;
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_journey_status']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Mutation_Response = {
  __typename?: 'clsapi_learning_journeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RClsapi_Learning_Journeys>;
};

/** on_conflict condition type for table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_On_Conflict = {
  constraint: RClsapi_Learning_Journeys_Constraint;
  update_columns?: Array<RClsapi_Learning_Journeys_Update_Column>;
  where?: InputMaybe<RClsapi_Learning_Journeys_Bool_Exp>;
};

/** Ordering options when selecting data from "clsapi.learning_journeys". */
export type RClsapi_Learning_Journeys_Order_By = {
  banner_url?: InputMaybe<ROrder_By>;
  business_goal?: InputMaybe<ROrder_By>;
  completion_criteria?: InputMaybe<ROrder_By>;
  completion_invest_time?: InputMaybe<ROrder_By>;
  created_at?: InputMaybe<ROrder_By>;
  description?: InputMaybe<ROrder_By>;
  discussion_enabled?: InputMaybe<ROrder_By>;
  id?: InputMaybe<ROrder_By>;
  impact_surveys_enabled?: InputMaybe<ROrder_By>;
  is_linear?: InputMaybe<ROrder_By>;
  source_template_id?: InputMaybe<ROrder_By>;
  space_featured?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  status?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  updated_at?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** primary key columns input for table: clsapi.learning_journeys */
export type RClsapi_Learning_Journeys_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clsapi.learning_journeys" */
export enum RClsapi_Learning_Journeys_Select_Column {
  /** column name */
  BannerUrl = 'banner_url',
  /** column name */
  BusinessGoal = 'business_goal',
  /** column name */
  CompletionCriteria = 'completion_criteria',
  /** column name */
  CompletionInvestTime = 'completion_invest_time',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionEnabled = 'discussion_enabled',
  /** column name */
  Id = 'id',
  /** column name */
  ImpactSurveysEnabled = 'impact_surveys_enabled',
  /** column name */
  IsLinear = 'is_linear',
  /** column name */
  SourceTemplateId = 'source_template_id',
  /** column name */
  SpaceFeatured = 'space_featured',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Urn = 'urn'
}

/** input type for updating data in table "clsapi.learning_journeys" */
export type RClsapi_Learning_Journeys_Set_Input = {
  banner_url?: InputMaybe<Scalars['String']['input']>;
  business_goal?: InputMaybe<Scalars['String']['input']>;
  completion_criteria?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussion_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impact_surveys_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_linear?: InputMaybe<Scalars['Boolean']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_featured?: InputMaybe<Scalars['Boolean']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_journey_status']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type RClsapi_Learning_Journeys_Stddev_Fields = {
  __typename?: 'clsapi_learning_journeys_stddev_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RClsapi_Learning_Journeys_Stddev_Pop_Fields = {
  __typename?: 'clsapi_learning_journeys_stddev_pop_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RClsapi_Learning_Journeys_Stddev_Samp_Fields = {
  __typename?: 'clsapi_learning_journeys_stddev_samp_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "clsapi_learning_journeys" */
export type RClsapi_Learning_Journeys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RClsapi_Learning_Journeys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RClsapi_Learning_Journeys_Stream_Cursor_Value_Input = {
  banner_url?: InputMaybe<Scalars['String']['input']>;
  business_goal?: InputMaybe<Scalars['String']['input']>;
  completion_criteria?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  completion_invest_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discussion_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impact_surveys_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_linear?: InputMaybe<Scalars['Boolean']['input']>;
  source_template_id?: InputMaybe<Scalars['Int']['input']>;
  space_featured?: InputMaybe<Scalars['Boolean']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_journey_status']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RClsapi_Learning_Journeys_Sum_Fields = {
  __typename?: 'clsapi_learning_journeys_sum_fields';
  completion_invest_time?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  source_template_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "clsapi.learning_journeys" */
export enum RClsapi_Learning_Journeys_Update_Column {
  /** column name */
  BannerUrl = 'banner_url',
  /** column name */
  BusinessGoal = 'business_goal',
  /** column name */
  CompletionCriteria = 'completion_criteria',
  /** column name */
  CompletionInvestTime = 'completion_invest_time',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DiscussionEnabled = 'discussion_enabled',
  /** column name */
  Id = 'id',
  /** column name */
  ImpactSurveysEnabled = 'impact_surveys_enabled',
  /** column name */
  IsLinear = 'is_linear',
  /** column name */
  SourceTemplateId = 'source_template_id',
  /** column name */
  SpaceFeatured = 'space_featured',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type RClsapi_Learning_Journeys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RClsapi_Learning_Journeys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RClsapi_Learning_Journeys_Set_Input>;
  /** filter the rows which have to be updated */
  where: RClsapi_Learning_Journeys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RClsapi_Learning_Journeys_Var_Pop_Fields = {
  __typename?: 'clsapi_learning_journeys_var_pop_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RClsapi_Learning_Journeys_Var_Samp_Fields = {
  __typename?: 'clsapi_learning_journeys_var_samp_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RClsapi_Learning_Journeys_Variance_Fields = {
  __typename?: 'clsapi_learning_journeys_variance_fields';
  completion_invest_time?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  source_template_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type RClsapi_Learning_Max_Fields = {
  __typename?: 'clsapi_learning_max_fields';
  archived?: Maybe<Scalars['timestamptz']['output']>;
  content_owner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['timestamptz']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  preamble?: Maybe<Scalars['String']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_status']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['learning_type']['output']>;
  updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn_v1?: Maybe<Scalars['String']['output']>;
  urn_v2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['learning_visibility']['output']>;
};

/** aggregate min on columns */
export type RClsapi_Learning_Min_Fields = {
  __typename?: 'clsapi_learning_min_fields';
  archived?: Maybe<Scalars['timestamptz']['output']>;
  content_owner?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['timestamptz']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  preamble?: Maybe<Scalars['String']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['learning_status']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['learning_type']['output']>;
  updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  urn_v1?: Maybe<Scalars['String']['output']>;
  urn_v2?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['learning_visibility']['output']>;
};

/** response of any mutation on the table "clsapi.learning" */
export type RClsapi_Learning_Mutation_Response = {
  __typename?: 'clsapi_learning_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RClsapi_Learning>;
};

/** on_conflict condition type for table "clsapi.learning" */
export type RClsapi_Learning_On_Conflict = {
  constraint: RClsapi_Learning_Constraint;
  update_columns?: Array<RClsapi_Learning_Update_Column>;
  where?: InputMaybe<RClsapi_Learning_Bool_Exp>;
};

/** Ordering options when selecting data from "clsapi.learning". */
export type RClsapi_Learning_Order_By = {
  archived?: InputMaybe<ROrder_By>;
  content_owner?: InputMaybe<ROrder_By>;
  created?: InputMaybe<ROrder_By>;
  creator?: InputMaybe<ROrder_By>;
  effort?: InputMaybe<ROrder_By>;
  id?: InputMaybe<ROrder_By>;
  image?: InputMaybe<ROrder_By>;
  language_id?: InputMaybe<ROrder_By>;
  meta?: InputMaybe<ROrder_By>;
  preamble?: InputMaybe<ROrder_By>;
  space_id?: InputMaybe<ROrder_By>;
  status?: InputMaybe<ROrder_By>;
  teaser?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  type?: InputMaybe<ROrder_By>;
  updated?: InputMaybe<ROrder_By>;
  updated_by?: InputMaybe<ROrder_By>;
  urn_v1?: InputMaybe<ROrder_By>;
  urn_v2?: InputMaybe<ROrder_By>;
  visibility?: InputMaybe<ROrder_By>;
};

/** primary key columns input for table: clsapi.learning */
export type RClsapi_Learning_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clsapi.learning" */
export enum RClsapi_Learning_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ContentOwner = 'content_owner',
  /** column name */
  Created = 'created',
  /** column name */
  Creator = 'creator',
  /** column name */
  Effort = 'effort',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Preamble = 'preamble',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  Teaser = 'teaser',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UrnV1 = 'urn_v1',
  /** column name */
  UrnV2 = 'urn_v2',
  /** column name */
  Visibility = 'visibility'
}

/** input type for updating data in table "clsapi.learning" */
export type RClsapi_Learning_Set_Input = {
  archived?: InputMaybe<Scalars['timestamptz']['input']>;
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_status']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['learning_type']['input']>;
  updated?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['learning_visibility']['input']>;
};

/** aggregate stddev on columns */
export type RClsapi_Learning_Stddev_Fields = {
  __typename?: 'clsapi_learning_stddev_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RClsapi_Learning_Stddev_Pop_Fields = {
  __typename?: 'clsapi_learning_stddev_pop_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RClsapi_Learning_Stddev_Samp_Fields = {
  __typename?: 'clsapi_learning_stddev_samp_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "clsapi_learning" */
export type RClsapi_Learning_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RClsapi_Learning_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RClsapi_Learning_Stream_Cursor_Value_Input = {
  archived?: InputMaybe<Scalars['timestamptz']['input']>;
  content_owner?: InputMaybe<Scalars['Int']['input']>;
  created?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Scalars['Int']['input']>;
  effort?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language_id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  preamble?: InputMaybe<Scalars['String']['input']>;
  space_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['learning_status']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['learning_type']['input']>;
  updated?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['Int']['input']>;
  urn_v1?: InputMaybe<Scalars['String']['input']>;
  urn_v2?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['learning_visibility']['input']>;
};

/** aggregate sum on columns */
export type RClsapi_Learning_Sum_Fields = {
  __typename?: 'clsapi_learning_sum_fields';
  content_owner?: Maybe<Scalars['Int']['output']>;
  creator?: Maybe<Scalars['Int']['output']>;
  effort?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  space_id?: Maybe<Scalars['Int']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "clsapi.learning" */
export enum RClsapi_Learning_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  ContentOwner = 'content_owner',
  /** column name */
  Created = 'created',
  /** column name */
  Creator = 'creator',
  /** column name */
  Effort = 'effort',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Preamble = 'preamble',
  /** column name */
  SpaceId = 'space_id',
  /** column name */
  Status = 'status',
  /** column name */
  Teaser = 'teaser',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Visibility = 'visibility'
}

export type RClsapi_Learning_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RClsapi_Learning_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RClsapi_Learning_Set_Input>;
  /** filter the rows which have to be updated */
  where: RClsapi_Learning_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RClsapi_Learning_Var_Pop_Fields = {
  __typename?: 'clsapi_learning_var_pop_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RClsapi_Learning_Var_Samp_Fields = {
  __typename?: 'clsapi_learning_var_samp_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RClsapi_Learning_Variance_Fields = {
  __typename?: 'clsapi_learning_variance_fields';
  content_owner?: Maybe<Scalars['Float']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  effort?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  language_id?: Maybe<Scalars['Float']['output']>;
  space_id?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum RCursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type RFloat8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type RJson_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

/** Boolean expression to compare columns of type "learning_journey_completion_criteria". All fields are combined with logical 'AND'. */
export type RLearning_Journey_Completion_Criteria_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _gt?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _gte?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_journey_completion_criteria']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _lte?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _neq?: InputMaybe<Scalars['learning_journey_completion_criteria']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_journey_completion_criteria']['input']>>;
};

/** Boolean expression to compare columns of type "learning_journey_status". All fields are combined with logical 'AND'. */
export type RLearning_Journey_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _gt?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _gte?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_journey_status']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _lte?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _neq?: InputMaybe<Scalars['learning_journey_status']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_journey_status']['input']>>;
};

/** Boolean expression to compare columns of type "learning_status". All fields are combined with logical 'AND'. */
export type RLearning_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_status']['input']>;
  _gt?: InputMaybe<Scalars['learning_status']['input']>;
  _gte?: InputMaybe<Scalars['learning_status']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_status']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_status']['input']>;
  _lte?: InputMaybe<Scalars['learning_status']['input']>;
  _neq?: InputMaybe<Scalars['learning_status']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_status']['input']>>;
};

/** Boolean expression to compare columns of type "learning_type". All fields are combined with logical 'AND'. */
export type RLearning_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_type']['input']>;
  _gt?: InputMaybe<Scalars['learning_type']['input']>;
  _gte?: InputMaybe<Scalars['learning_type']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_type']['input']>;
  _lte?: InputMaybe<Scalars['learning_type']['input']>;
  _neq?: InputMaybe<Scalars['learning_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_type']['input']>>;
};

/** Boolean expression to compare columns of type "learning_visibility". All fields are combined with logical 'AND'. */
export type RLearning_Visibility_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_visibility']['input']>;
  _gt?: InputMaybe<Scalars['learning_visibility']['input']>;
  _gte?: InputMaybe<Scalars['learning_visibility']['input']>;
  _in?: InputMaybe<Array<Scalars['learning_visibility']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['learning_visibility']['input']>;
  _lte?: InputMaybe<Scalars['learning_visibility']['input']>;
  _neq?: InputMaybe<Scalars['learning_visibility']['input']>;
  _nin?: InputMaybe<Array<Scalars['learning_visibility']['input']>>;
};

/** Boolean expression to compare columns of type "metrics_dimension". All fields are combined with logical 'AND'. */
export type RMetrics_Dimension_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _gt?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _gte?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _in?: InputMaybe<Array<Scalars['metrics_dimension']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _lte?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _neq?: InputMaybe<Scalars['metrics_dimension']['input']>;
  _nin?: InputMaybe<Array<Scalars['metrics_dimension']['input']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type RNumeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum ROrder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm = {
  __typename?: 'reportingAggregationEffortForRealm';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  fromTime?: Maybe<Scalars['timestamptz']['output']>;
  meta_update_time?: Maybe<Scalars['timestamptz']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  toTime?: Maybe<Scalars['timestamptz']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm_Aggregate = {
  __typename?: 'reportingAggregationEffortForRealm_aggregate';
  aggregate?: Maybe<RReportingAggregationEffortForRealm_Aggregate_Fields>;
  nodes: Array<RReportingAggregationEffortForRealm>;
};

/** aggregate fields of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm_Aggregate_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_aggregate_fields';
  avg?: Maybe<RReportingAggregationEffortForRealm_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingAggregationEffortForRealm_Max_Fields>;
  min?: Maybe<RReportingAggregationEffortForRealm_Min_Fields>;
  stddev?: Maybe<RReportingAggregationEffortForRealm_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingAggregationEffortForRealm_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingAggregationEffortForRealm_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingAggregationEffortForRealm_Sum_Fields>;
  var_pop?: Maybe<RReportingAggregationEffortForRealm_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingAggregationEffortForRealm_Var_Samp_Fields>;
  variance?: Maybe<RReportingAggregationEffortForRealm_Variance_Fields>;
};


/** aggregate fields of "reporting.mv_aggregation_effort_for_realm" */
export type RReportingAggregationEffortForRealm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingAggregationEffortForRealm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingAggregationEffortForRealm_Avg_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_avg_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_aggregation_effort_for_realm". All fields are combined with a logical 'AND'. */
export type RReportingAggregationEffortForRealm_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingAggregationEffortForRealm_Bool_Exp>>;
  _not?: InputMaybe<RReportingAggregationEffortForRealm_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingAggregationEffortForRealm_Bool_Exp>>;
  averageEffort?: InputMaybe<RBigint_Comparison_Exp>;
  fromTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  meta_update_time?: InputMaybe<RTimestamptz_Comparison_Exp>;
  p50Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p60Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p70Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p80Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  p90Effort?: InputMaybe<RFloat8_Comparison_Exp>;
  realm?: InputMaybe<RString_Comparison_Exp>;
  toTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  totalEffort?: InputMaybe<RBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingAggregationEffortForRealm_Max_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_max_fields';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  fromTime?: Maybe<Scalars['timestamptz']['output']>;
  meta_update_time?: Maybe<Scalars['timestamptz']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  toTime?: Maybe<Scalars['timestamptz']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type RReportingAggregationEffortForRealm_Min_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_min_fields';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  fromTime?: Maybe<Scalars['timestamptz']['output']>;
  meta_update_time?: Maybe<Scalars['timestamptz']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  toTime?: Maybe<Scalars['timestamptz']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_aggregation_effort_for_realm". */
export type RReportingAggregationEffortForRealm_Order_By = {
  averageEffort?: InputMaybe<ROrder_By>;
  fromTime?: InputMaybe<ROrder_By>;
  meta_update_time?: InputMaybe<ROrder_By>;
  p50Effort?: InputMaybe<ROrder_By>;
  p60Effort?: InputMaybe<ROrder_By>;
  p70Effort?: InputMaybe<ROrder_By>;
  p80Effort?: InputMaybe<ROrder_By>;
  p90Effort?: InputMaybe<ROrder_By>;
  realm?: InputMaybe<ROrder_By>;
  toTime?: InputMaybe<ROrder_By>;
  totalEffort?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_aggregation_effort_for_realm" */
export enum RReportingAggregationEffortForRealm_Select_Column {
  /** column name */
  AverageEffort = 'averageEffort',
  /** column name */
  FromTime = 'fromTime',
  /** column name */
  MetaUpdateTime = 'meta_update_time',
  /** column name */
  P50Effort = 'p50Effort',
  /** column name */
  P60Effort = 'p60Effort',
  /** column name */
  P70Effort = 'p70Effort',
  /** column name */
  P80Effort = 'p80Effort',
  /** column name */
  P90Effort = 'p90Effort',
  /** column name */
  Realm = 'realm',
  /** column name */
  ToTime = 'toTime',
  /** column name */
  TotalEffort = 'totalEffort'
}

/** aggregate stddev on columns */
export type RReportingAggregationEffortForRealm_Stddev_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_stddev_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingAggregationEffortForRealm_Stddev_Pop_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_stddev_pop_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingAggregationEffortForRealm_Stddev_Samp_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_stddev_samp_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingAggregationEffortForRealm" */
export type RReportingAggregationEffortForRealm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingAggregationEffortForRealm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingAggregationEffortForRealm_Stream_Cursor_Value_Input = {
  averageEffort?: InputMaybe<Scalars['bigint']['input']>;
  fromTime?: InputMaybe<Scalars['timestamptz']['input']>;
  meta_update_time?: InputMaybe<Scalars['timestamptz']['input']>;
  p50Effort?: InputMaybe<Scalars['float8']['input']>;
  p60Effort?: InputMaybe<Scalars['float8']['input']>;
  p70Effort?: InputMaybe<Scalars['float8']['input']>;
  p80Effort?: InputMaybe<Scalars['float8']['input']>;
  p90Effort?: InputMaybe<Scalars['float8']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
  toTime?: InputMaybe<Scalars['timestamptz']['input']>;
  totalEffort?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type RReportingAggregationEffortForRealm_Sum_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_sum_fields';
  averageEffort?: Maybe<Scalars['bigint']['output']>;
  p50Effort?: Maybe<Scalars['float8']['output']>;
  p60Effort?: Maybe<Scalars['float8']['output']>;
  p70Effort?: Maybe<Scalars['float8']['output']>;
  p80Effort?: Maybe<Scalars['float8']['output']>;
  p90Effort?: Maybe<Scalars['float8']['output']>;
  totalEffort?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingAggregationEffortForRealm_Var_Pop_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_var_pop_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingAggregationEffortForRealm_Var_Samp_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_var_samp_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingAggregationEffortForRealm_Variance_Fields = {
  __typename?: 'reportingAggregationEffortForRealm_variance_fields';
  averageEffort?: Maybe<Scalars['Float']['output']>;
  p50Effort?: Maybe<Scalars['Float']['output']>;
  p60Effort?: Maybe<Scalars['Float']['output']>;
  p70Effort?: Maybe<Scalars['Float']['output']>;
  p80Effort?: Maybe<Scalars['Float']['output']>;
  p90Effort?: Maybe<Scalars['Float']['output']>;
  totalEffort?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights = {
  __typename?: 'reportingLearningJourneyContentInsights';
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  learningContentTitle?: Maybe<Scalars['String']['output']>;
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyTitle?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learningType?: Maybe<Scalars['catalog_item_type']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights_Aggregate = {
  __typename?: 'reportingLearningJourneyContentInsights_aggregate';
  aggregate?: Maybe<RReportingLearningJourneyContentInsights_Aggregate_Fields>;
  nodes: Array<RReportingLearningJourneyContentInsights>;
};

/** aggregate fields of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights_Aggregate_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_aggregate_fields';
  avg?: Maybe<RReportingLearningJourneyContentInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingLearningJourneyContentInsights_Max_Fields>;
  min?: Maybe<RReportingLearningJourneyContentInsights_Min_Fields>;
  stddev?: Maybe<RReportingLearningJourneyContentInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingLearningJourneyContentInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingLearningJourneyContentInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingLearningJourneyContentInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingLearningJourneyContentInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingLearningJourneyContentInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingLearningJourneyContentInsights_Variance_Fields>;
};


/** aggregate fields of "reporting.mv_learning_journey_content_insights" */
export type RReportingLearningJourneyContentInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingLearningJourneyContentInsights_Avg_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_avg_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_learning_journey_content_insights". All fields are combined with a logical 'AND'. */
export type RReportingLearningJourneyContentInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Bool_Exp>>;
  completedCount?: InputMaybe<RBigint_Comparison_Exp>;
  completionRate?: InputMaybe<RNumeric_Comparison_Exp>;
  learningContentTitle?: InputMaybe<RString_Comparison_Exp>;
  learningContentURN?: InputMaybe<RString_Comparison_Exp>;
  learningJourneyTitle?: InputMaybe<RString_Comparison_Exp>;
  learningJourneyURN?: InputMaybe<RString_Comparison_Exp>;
  learningType?: InputMaybe<RCatalog_Item_Type_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  plannedDuration?: InputMaybe<RInt_Comparison_Exp>;
  startedCount?: InputMaybe<RBigint_Comparison_Exp>;
  totalEffortInSeconds?: InputMaybe<RNumeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingLearningJourneyContentInsights_Max_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_max_fields';
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  learningContentTitle?: Maybe<Scalars['String']['output']>;
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyTitle?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learningType?: Maybe<Scalars['catalog_item_type']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type RReportingLearningJourneyContentInsights_Min_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_min_fields';
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  learningContentTitle?: Maybe<Scalars['String']['output']>;
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyTitle?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learningType?: Maybe<Scalars['catalog_item_type']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_learning_journey_content_insights". */
export type RReportingLearningJourneyContentInsights_Order_By = {
  completedCount?: InputMaybe<ROrder_By>;
  completionRate?: InputMaybe<ROrder_By>;
  learningContentTitle?: InputMaybe<ROrder_By>;
  learningContentURN?: InputMaybe<ROrder_By>;
  learningJourneyTitle?: InputMaybe<ROrder_By>;
  learningJourneyURN?: InputMaybe<ROrder_By>;
  learningType?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  plannedDuration?: InputMaybe<ROrder_By>;
  startedCount?: InputMaybe<ROrder_By>;
  totalEffortInSeconds?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_learning_journey_content_insights" */
export enum RReportingLearningJourneyContentInsights_Select_Column {
  /** column name */
  CompletedCount = 'completedCount',
  /** column name */
  CompletionRate = 'completionRate',
  /** column name */
  LearningContentTitle = 'learningContentTitle',
  /** column name */
  LearningContentUrn = 'learningContentURN',
  /** column name */
  LearningJourneyTitle = 'learningJourneyTitle',
  /** column name */
  LearningJourneyUrn = 'learningJourneyURN',
  /** column name */
  LearningType = 'learningType',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  PlannedDuration = 'plannedDuration',
  /** column name */
  StartedCount = 'startedCount',
  /** column name */
  TotalEffortInSeconds = 'totalEffortInSeconds'
}

/** aggregate stddev on columns */
export type RReportingLearningJourneyContentInsights_Stddev_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_stddev_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingLearningJourneyContentInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_stddev_pop_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingLearningJourneyContentInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_stddev_samp_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingLearningJourneyContentInsights" */
export type RReportingLearningJourneyContentInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingLearningJourneyContentInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingLearningJourneyContentInsights_Stream_Cursor_Value_Input = {
  completedCount?: InputMaybe<Scalars['bigint']['input']>;
  completionRate?: InputMaybe<Scalars['numeric']['input']>;
  learningContentTitle?: InputMaybe<Scalars['String']['input']>;
  learningContentURN?: InputMaybe<Scalars['String']['input']>;
  learningJourneyTitle?: InputMaybe<Scalars['String']['input']>;
  learningJourneyURN?: InputMaybe<Scalars['String']['input']>;
  learningType?: InputMaybe<Scalars['catalog_item_type']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  plannedDuration?: InputMaybe<Scalars['Int']['input']>;
  startedCount?: InputMaybe<Scalars['bigint']['input']>;
  totalEffortInSeconds?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type RReportingLearningJourneyContentInsights_Sum_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_sum_fields';
  completedCount?: Maybe<Scalars['bigint']['output']>;
  completionRate?: Maybe<Scalars['numeric']['output']>;
  plannedDuration?: Maybe<Scalars['Int']['output']>;
  startedCount?: Maybe<Scalars['bigint']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingLearningJourneyContentInsights_Var_Pop_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_var_pop_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingLearningJourneyContentInsights_Var_Samp_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_var_samp_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingLearningJourneyContentInsights_Variance_Fields = {
  __typename?: 'reportingLearningJourneyContentInsights_variance_fields';
  completedCount?: Maybe<Scalars['Float']['output']>;
  completionRate?: Maybe<Scalars['Float']['output']>;
  plannedDuration?: Maybe<Scalars['Float']['output']>;
  startedCount?: Maybe<Scalars['Float']['output']>;
  totalEffortInSeconds?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights = {
  __typename?: 'reportingPracticalAssessmentInsights';
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learning_content_title?: Maybe<Scalars['String']['output']>;
  learning_journey_title?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  pageURN?: Maybe<Scalars['String']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights_Aggregate = {
  __typename?: 'reportingPracticalAssessmentInsights_aggregate';
  aggregate?: Maybe<RReportingPracticalAssessmentInsights_Aggregate_Fields>;
  nodes: Array<RReportingPracticalAssessmentInsights>;
};

/** aggregate fields of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights_Aggregate_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_aggregate_fields';
  avg?: Maybe<RReportingPracticalAssessmentInsights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingPracticalAssessmentInsights_Max_Fields>;
  min?: Maybe<RReportingPracticalAssessmentInsights_Min_Fields>;
  stddev?: Maybe<RReportingPracticalAssessmentInsights_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingPracticalAssessmentInsights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingPracticalAssessmentInsights_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingPracticalAssessmentInsights_Sum_Fields>;
  var_pop?: Maybe<RReportingPracticalAssessmentInsights_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingPracticalAssessmentInsights_Var_Samp_Fields>;
  variance?: Maybe<RReportingPracticalAssessmentInsights_Variance_Fields>;
};


/** aggregate fields of "reporting.mv_practical_assessment_insights" */
export type RReportingPracticalAssessmentInsights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingPracticalAssessmentInsights_Avg_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_avg_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_practical_assessment_insights". All fields are combined with a logical 'AND'. */
export type RReportingPracticalAssessmentInsights_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Bool_Exp>>;
  _not?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingPracticalAssessmentInsights_Bool_Exp>>;
  learningContentURN?: InputMaybe<RString_Comparison_Exp>;
  learningJourneyURN?: InputMaybe<RString_Comparison_Exp>;
  learning_content_title?: InputMaybe<RString_Comparison_Exp>;
  learning_journey_title?: InputMaybe<RString_Comparison_Exp>;
  pageId?: InputMaybe<RInt_Comparison_Exp>;
  pageURN?: InputMaybe<RString_Comparison_Exp>;
  responsesCount?: InputMaybe<RBigint_Comparison_Exp>;
  reviewsCount?: InputMaybe<RBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingPracticalAssessmentInsights_Max_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_max_fields';
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learning_content_title?: Maybe<Scalars['String']['output']>;
  learning_journey_title?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  pageURN?: Maybe<Scalars['String']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type RReportingPracticalAssessmentInsights_Min_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_min_fields';
  learningContentURN?: Maybe<Scalars['String']['output']>;
  learningJourneyURN?: Maybe<Scalars['String']['output']>;
  learning_content_title?: Maybe<Scalars['String']['output']>;
  learning_journey_title?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  pageURN?: Maybe<Scalars['String']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_practical_assessment_insights". */
export type RReportingPracticalAssessmentInsights_Order_By = {
  learningContentURN?: InputMaybe<ROrder_By>;
  learningJourneyURN?: InputMaybe<ROrder_By>;
  learning_content_title?: InputMaybe<ROrder_By>;
  learning_journey_title?: InputMaybe<ROrder_By>;
  pageId?: InputMaybe<ROrder_By>;
  pageURN?: InputMaybe<ROrder_By>;
  responsesCount?: InputMaybe<ROrder_By>;
  reviewsCount?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_practical_assessment_insights" */
export enum RReportingPracticalAssessmentInsights_Select_Column {
  /** column name */
  LearningContentUrn = 'learningContentURN',
  /** column name */
  LearningJourneyUrn = 'learningJourneyURN',
  /** column name */
  LearningContentTitle = 'learning_content_title',
  /** column name */
  LearningJourneyTitle = 'learning_journey_title',
  /** column name */
  PageId = 'pageId',
  /** column name */
  PageUrn = 'pageURN',
  /** column name */
  ResponsesCount = 'responsesCount',
  /** column name */
  ReviewsCount = 'reviewsCount'
}

/** aggregate stddev on columns */
export type RReportingPracticalAssessmentInsights_Stddev_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_stddev_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingPracticalAssessmentInsights_Stddev_Pop_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_stddev_pop_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingPracticalAssessmentInsights_Stddev_Samp_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_stddev_samp_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingPracticalAssessmentInsights" */
export type RReportingPracticalAssessmentInsights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingPracticalAssessmentInsights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingPracticalAssessmentInsights_Stream_Cursor_Value_Input = {
  learningContentURN?: InputMaybe<Scalars['String']['input']>;
  learningJourneyURN?: InputMaybe<Scalars['String']['input']>;
  learning_content_title?: InputMaybe<Scalars['String']['input']>;
  learning_journey_title?: InputMaybe<Scalars['String']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  pageURN?: InputMaybe<Scalars['String']['input']>;
  responsesCount?: InputMaybe<Scalars['bigint']['input']>;
  reviewsCount?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type RReportingPracticalAssessmentInsights_Sum_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_sum_fields';
  pageId?: Maybe<Scalars['Int']['output']>;
  responsesCount?: Maybe<Scalars['bigint']['output']>;
  reviewsCount?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingPracticalAssessmentInsights_Var_Pop_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_var_pop_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingPracticalAssessmentInsights_Var_Samp_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_var_samp_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingPracticalAssessmentInsights_Variance_Fields = {
  __typename?: 'reportingPracticalAssessmentInsights_variance_fields';
  pageId?: Maybe<Scalars['Float']['output']>;
  responsesCount?: Maybe<Scalars['Float']['output']>;
  reviewsCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers = {
  __typename?: 'reportingSurveyAnswers';
  answer?: Maybe<Scalars['String']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  dimension?: Maybe<Scalars['metrics_dimension']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  questionURN?: Maybe<Scalars['String']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers_Aggregate = {
  __typename?: 'reportingSurveyAnswers_aggregate';
  aggregate?: Maybe<RReportingSurveyAnswers_Aggregate_Fields>;
  nodes: Array<RReportingSurveyAnswers>;
};

/** aggregate fields of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers_Aggregate_Fields = {
  __typename?: 'reportingSurveyAnswers_aggregate_fields';
  avg?: Maybe<RReportingSurveyAnswers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RReportingSurveyAnswers_Max_Fields>;
  min?: Maybe<RReportingSurveyAnswers_Min_Fields>;
  stddev?: Maybe<RReportingSurveyAnswers_Stddev_Fields>;
  stddev_pop?: Maybe<RReportingSurveyAnswers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RReportingSurveyAnswers_Stddev_Samp_Fields>;
  sum?: Maybe<RReportingSurveyAnswers_Sum_Fields>;
  var_pop?: Maybe<RReportingSurveyAnswers_Var_Pop_Fields>;
  var_samp?: Maybe<RReportingSurveyAnswers_Var_Samp_Fields>;
  variance?: Maybe<RReportingSurveyAnswers_Variance_Fields>;
};


/** aggregate fields of "reporting.mv_survey_answers" */
export type RReportingSurveyAnswers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RReportingSurveyAnswers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RReportingSurveyAnswers_Avg_Fields = {
  __typename?: 'reportingSurveyAnswers_avg_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reporting.mv_survey_answers". All fields are combined with a logical 'AND'. */
export type RReportingSurveyAnswers_Bool_Exp = {
  _and?: InputMaybe<Array<RReportingSurveyAnswers_Bool_Exp>>;
  _not?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
  _or?: InputMaybe<Array<RReportingSurveyAnswers_Bool_Exp>>;
  answer?: InputMaybe<RString_Comparison_Exp>;
  answerCount?: InputMaybe<RBigint_Comparison_Exp>;
  answerPercent?: InputMaybe<RNumeric_Comparison_Exp>;
  answerTotal?: InputMaybe<RBigint_Comparison_Exp>;
  dimension?: InputMaybe<RMetrics_Dimension_Comparison_Exp>;
  metaUpdateTime?: InputMaybe<RTimestamptz_Comparison_Exp>;
  pageId?: InputMaybe<RInt_Comparison_Exp>;
  questionTitle?: InputMaybe<RString_Comparison_Exp>;
  questionURN?: InputMaybe<RString_Comparison_Exp>;
  rangeMax?: InputMaybe<RInt_Comparison_Exp>;
  rangeMin?: InputMaybe<RInt_Comparison_Exp>;
  scoreRaw?: InputMaybe<RInt_Comparison_Exp>;
  title?: InputMaybe<RString_Comparison_Exp>;
  urn?: InputMaybe<RString_Comparison_Exp>;
};

/** aggregate max on columns */
export type RReportingSurveyAnswers_Max_Fields = {
  __typename?: 'reportingSurveyAnswers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  dimension?: Maybe<Scalars['metrics_dimension']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  questionURN?: Maybe<Scalars['String']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RReportingSurveyAnswers_Min_Fields = {
  __typename?: 'reportingSurveyAnswers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  dimension?: Maybe<Scalars['metrics_dimension']['output']>;
  metaUpdateTime?: Maybe<Scalars['timestamptz']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  questionURN?: Maybe<Scalars['String']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  urn?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "reporting.mv_survey_answers". */
export type RReportingSurveyAnswers_Order_By = {
  answer?: InputMaybe<ROrder_By>;
  answerCount?: InputMaybe<ROrder_By>;
  answerPercent?: InputMaybe<ROrder_By>;
  answerTotal?: InputMaybe<ROrder_By>;
  dimension?: InputMaybe<ROrder_By>;
  metaUpdateTime?: InputMaybe<ROrder_By>;
  pageId?: InputMaybe<ROrder_By>;
  questionTitle?: InputMaybe<ROrder_By>;
  questionURN?: InputMaybe<ROrder_By>;
  rangeMax?: InputMaybe<ROrder_By>;
  rangeMin?: InputMaybe<ROrder_By>;
  scoreRaw?: InputMaybe<ROrder_By>;
  title?: InputMaybe<ROrder_By>;
  urn?: InputMaybe<ROrder_By>;
};

/** select columns of table "reporting.mv_survey_answers" */
export enum RReportingSurveyAnswers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerCount = 'answerCount',
  /** column name */
  AnswerPercent = 'answerPercent',
  /** column name */
  AnswerTotal = 'answerTotal',
  /** column name */
  Dimension = 'dimension',
  /** column name */
  MetaUpdateTime = 'metaUpdateTime',
  /** column name */
  PageId = 'pageId',
  /** column name */
  QuestionTitle = 'questionTitle',
  /** column name */
  QuestionUrn = 'questionURN',
  /** column name */
  RangeMax = 'rangeMax',
  /** column name */
  RangeMin = 'rangeMin',
  /** column name */
  ScoreRaw = 'scoreRaw',
  /** column name */
  Title = 'title',
  /** column name */
  Urn = 'urn'
}

/** aggregate stddev on columns */
export type RReportingSurveyAnswers_Stddev_Fields = {
  __typename?: 'reportingSurveyAnswers_stddev_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RReportingSurveyAnswers_Stddev_Pop_Fields = {
  __typename?: 'reportingSurveyAnswers_stddev_pop_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RReportingSurveyAnswers_Stddev_Samp_Fields = {
  __typename?: 'reportingSurveyAnswers_stddev_samp_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reportingSurveyAnswers" */
export type RReportingSurveyAnswers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RReportingSurveyAnswers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<RCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RReportingSurveyAnswers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  answerCount?: InputMaybe<Scalars['bigint']['input']>;
  answerPercent?: InputMaybe<Scalars['numeric']['input']>;
  answerTotal?: InputMaybe<Scalars['bigint']['input']>;
  dimension?: InputMaybe<Scalars['metrics_dimension']['input']>;
  metaUpdateTime?: InputMaybe<Scalars['timestamptz']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  questionURN?: InputMaybe<Scalars['String']['input']>;
  rangeMax?: InputMaybe<Scalars['Int']['input']>;
  rangeMin?: InputMaybe<Scalars['Int']['input']>;
  scoreRaw?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urn?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RReportingSurveyAnswers_Sum_Fields = {
  __typename?: 'reportingSurveyAnswers_sum_fields';
  answerCount?: Maybe<Scalars['bigint']['output']>;
  answerPercent?: Maybe<Scalars['numeric']['output']>;
  answerTotal?: Maybe<Scalars['bigint']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  rangeMax?: Maybe<Scalars['Int']['output']>;
  rangeMin?: Maybe<Scalars['Int']['output']>;
  scoreRaw?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type RReportingSurveyAnswers_Var_Pop_Fields = {
  __typename?: 'reportingSurveyAnswers_var_pop_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RReportingSurveyAnswers_Var_Samp_Fields = {
  __typename?: 'reportingSurveyAnswers_var_samp_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RReportingSurveyAnswers_Variance_Fields = {
  __typename?: 'reportingSurveyAnswers_variance_fields';
  answerCount?: Maybe<Scalars['Float']['output']>;
  answerPercent?: Maybe<Scalars['Float']['output']>;
  answerTotal?: Maybe<Scalars['Float']['output']>;
  pageId?: Maybe<Scalars['Float']['output']>;
  rangeMax?: Maybe<Scalars['Float']['output']>;
  rangeMin?: Maybe<Scalars['Float']['output']>;
  scoreRaw?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type RTimestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type RAssignmentV3UsersQueryVariables = Exact<{
  filter: RAssignmentV3UsersFilter;
  pagination?: InputMaybe<RPagination>;
  sort?: InputMaybe<RAssignmentUsersV3Sort>;
  includeProgress: Scalars['Boolean']['input'];
}>;


export type RAssignmentV3UsersQuery = { __typename?: 'Query', assignmentV3Users: { __typename?: 'AssignmentStandardV3UsersPage', total: number, data: Array<{ __typename?: 'AssignmentStandardV3User', userId: number, assignmentId: number, progress?: { __typename: 'AssignmentStandardV3UserProgress', participations: Array<{ __typename?: 'AssignmentStandardV3UserParticipation', assignmentLearningId: number, participationId: number, progress: number, completedAt?: Date | undefined }> }, user: { __typename?: 'UserV2', id: number, email: string, profileImage?: string | undefined, username?: string | undefined, status: RUserStatus } }> } | { __typename?: 'AssignmentStrategicV3UsersPage', total: number, data: Array<{ __typename?: 'AssignmentStrategicV3User', userId: number, assignmentId: number, progress?: { __typename?: 'AssignmentStrategicV3UserProgress', completed: boolean, estimatedEffort: number }, user: { __typename?: 'UserV2', id: number, email: string, profileImage?: string | undefined, username?: string | undefined, status: RUserStatus } }> } };

export type RUserLearningContentLastParticipationQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
  learningContentId: Scalars['URN']['input'];
}>;


export type RUserLearningContentLastParticipationQuery = { __typename?: 'Query', userLearningContentLastParticipation?: { __typename?: 'ParticipationV2', id?: number | undefined, objectId: string, userId: number, progress: number, isCompleted: boolean, completedAt?: Date | undefined, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, userEffort?: number | undefined, expiryAt?: Date | undefined } | undefined };

export type RUserLearningContentLastParticipationsQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
  learningContentIdIn: Array<Scalars['URN']['input']> | Scalars['URN']['input'];
}>;


export type RUserLearningContentLastParticipationsQuery = { __typename?: 'Query', userLearningContentLastParticipations: Array<{ __typename?: 'ParticipationV2', id?: number | undefined, objectId: string, userId: number, progress: number, isCompleted: boolean, completedAt?: Date | undefined, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, userEffort?: number | undefined, expiryAt?: Date | undefined }> };

export type RLearningContentFragment = { __typename?: 'LearningContent', id: string, title: string, description: string, imageURL?: string | undefined, provider: RLearningContentProvider, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, metadata?: { __typename?: 'LearningContentMetadata', language?: string | undefined, level?: string | undefined, type?: RLearningContentContentType | undefined, timezone?: number | undefined } | undefined, locations?: Array<{ __typename?: 'LearningContentLocationData', id?: number | undefined, endDate?: Date | undefined, startDate?: Date | undefined, type?: RLocationType | undefined, address?: string | undefined }> | undefined, curations: Array<{ __typename?: 'LearningCuration', id: string, spaceId: number, launchURLPath?: string | undefined, title?: string | undefined, description?: string | undefined, imageURL?: string | undefined, editURLPath?: string | undefined }> };

export type RParticipationFragment = { __typename?: 'ParticipationV2', id?: number | undefined, objectId: string, userId: number, progress: number, isCompleted: boolean, completedAt?: Date | undefined, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, userEffort?: number | undefined, expiryAt?: Date | undefined };

export type RLearningJourneyTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type RLearningJourneyTemplatesQuery = { __typename?: 'Query', learningJourneyTemplates: Array<{ __typename?: 'LearningJourneyTemplateDto', id: number, bannerURL: string, businessGoal: string, description: string, teaser: string, title: string }> };

export type RCreateLearningJourneyFromTemplateMutationVariables = Exact<{
  input: RCreateLearningJourneyFromTemplateInput;
}>;


export type RCreateLearningJourneyFromTemplateMutation = { __typename?: 'Mutation', createLearningJourneyFromTemplate: { __typename?: 'LearningJourneyResult', success: boolean, journey?: { __typename?: 'LearningJourney', id: number } | undefined } };

export type RLearningJourneyQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  includeItems: Scalars['Boolean']['input'];
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
}>;


export type RLearningJourneyQuery = { __typename?: 'Query', learningJourney: { __typename?: 'LearningJourney', id: number, urn: string, spaceId: number, title: string, bannerURL?: string | undefined, businessGoal?: string | undefined, impactSurveysEnabled: boolean, description?: string | undefined, status: RLearningJourneyStatus, spaceFeatured: boolean, completionCriteria?: RLearningJourneyCompletionStatus | undefined, completionInvestTimeSec?: number | undefined, isLinear: boolean, discussionEnabled: boolean, discussionForumSectionPath: string, createdAt: Date, items?: { __typename?: 'LearningJourneyItemPage', data: Array<{ __typename?: 'LearningJourneyItem', id: number, order: number, isLocked: boolean, contentId: string, content?: { __typename?: 'LearningContent', id: string, title: string, description: string, imageURL?: string | undefined, provider: RLearningContentProvider, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, metadata?: { __typename?: 'LearningContentMetadata', language?: string | undefined, level?: string | undefined, type?: RLearningContentContentType | undefined, timezone?: number | undefined } | undefined, locations?: Array<{ __typename?: 'LearningContentLocationData', id?: number | undefined, endDate?: Date | undefined, startDate?: Date | undefined, type?: RLocationType | undefined, address?: string | undefined }> | undefined, curations: Array<{ __typename?: 'LearningCuration', id: string, spaceId: number, launchURLPath?: string | undefined, title?: string | undefined, description?: string | undefined, imageURL?: string | undefined, editURLPath?: string | undefined }> } | undefined }> } } };

export type RLearningJourneysQueryVariables = Exact<{
  filter: RLearningJourneyFilter;
  pagination?: InputMaybe<RPagination>;
}>;


export type RLearningJourneysQuery = { __typename?: 'Query', learningJourneys: { __typename?: 'LearningJourneyPage', total: number, data: Array<{ __typename?: 'LearningJourney', id: number, spaceId: number, title: string, status: RLearningJourneyStatus, items: { __typename?: 'LearningJourneyItemPage', total: number } }> } };

export type RLearningJourneySessionQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  includeProgress: Scalars['Boolean']['input'];
}>;


export type RLearningJourneySessionQuery = { __typename?: 'Query', learningJourneySession: { __typename?: 'LearningJourneySession', id: number, startedAt: Date, cohort: { __typename?: 'LearningJourneyCohort', learners: Array<{ __typename?: 'LearningJourneyLearner', userId: number, sessionId: number, user: { __typename?: 'UserV2', id: number, email: string, username?: string | undefined, profileImage?: string | undefined }, progress?: { __typename?: 'ParticipationV2', isCompleted: boolean, progress: number } | undefined }> } } };

export type RLearningJourneyItemsCatalogQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  pagination: RPagination;
}>;


export type RLearningJourneyItemsCatalogQuery = { __typename?: 'Query', learningJourneyItemsCatalog: { __typename?: 'LearningContentsPage', limit: number, page: number, data: Array<{ __typename?: 'LearningContent', id: string, title: string, description: string, imageURL?: string | undefined, provider: RLearningContentProvider, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, metadata?: { __typename?: 'LearningContentMetadata', language?: string | undefined, level?: string | undefined, type?: RLearningContentContentType | undefined, timezone?: number | undefined } | undefined, locations?: Array<{ __typename?: 'LearningContentLocationData', id?: number | undefined, endDate?: Date | undefined, startDate?: Date | undefined, type?: RLocationType | undefined, address?: string | undefined }> | undefined, curations: Array<{ __typename?: 'LearningCuration', id: string, spaceId: number, launchURLPath?: string | undefined, title?: string | undefined, description?: string | undefined, imageURL?: string | undefined, editURLPath?: string | undefined }> }> } };

export type RReportingLearningJourneyContentInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
  orderBy?: InputMaybe<Array<RReportingLearningJourneyContentInsights_Order_By> | RReportingLearningJourneyContentInsights_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  whereAggregate?: InputMaybe<RReportingLearningJourneyContentInsights_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RReportingLearningJourneyContentInsightsQuery = { __typename?: 'Query', reportingLearningJourneyContentInsights: Array<{ __typename?: 'reportingLearningJourneyContentInsights', learningContentURN?: string | undefined, learningContentTitle?: string | undefined, learningType?: any | undefined, startedCount?: any | undefined, completedCount?: any | undefined, completionRate?: any | undefined }>, filteredTotal: { __typename?: 'reportingLearningJourneyContentInsights_aggregate', aggregate?: { __typename?: 'reportingLearningJourneyContentInsights_aggregate_fields', count: number } | undefined }, total: { __typename?: 'reportingLearningJourneyContentInsights_aggregate', aggregate?: { __typename?: 'reportingLearningJourneyContentInsights_aggregate_fields', count: number } | undefined } };

export type RReportingSurveyAnswersQueryVariables = Exact<{
  where?: InputMaybe<RReportingSurveyAnswers_Bool_Exp>;
}>;


export type RReportingSurveyAnswersQuery = { __typename?: 'Query', reportingSurveyAnswers: Array<{ __typename?: 'reportingSurveyAnswers', answer?: string | undefined, answerCount?: any | undefined, answerPercent?: any | undefined, answerTotal?: any | undefined, questionURN?: string | undefined, urn?: string | undefined, scoreRaw?: number | undefined }> };

export type RSurveyAnswerInsightsCsvQueryVariables = Exact<{
  surveyAnswerInsightsCsvId: Scalars['Int']['input'];
}>;


export type RSurveyAnswerInsightsCsvQuery = { __typename?: 'Query', surveyAnswerInsightsCSV: string };

export type RCreateLearningJourneyMutationVariables = Exact<{
  input: RCreateLearningJourneyInput;
}>;


export type RCreateLearningJourneyMutation = { __typename?: 'Mutation', createLearningJourney: { __typename?: 'LearningJourneyResult', success: boolean, journey?: { __typename?: 'LearningJourney', id: number } | undefined } };

export type RUpsertLearningJourneyItemsMutationVariables = Exact<{
  input: RUpsertLearningJourneyItemsInput;
}>;


export type RUpsertLearningJourneyItemsMutation = { __typename?: 'Mutation', upsertLearningJourneyItems: { __typename?: 'LearningJourneyResult', success: boolean, journey?: { __typename?: 'LearningJourney', id: number } | undefined } };

export type RUpdateLearningJourneyMutationVariables = Exact<{
  input: RUpdateLearningJourneyInput;
}>;


export type RUpdateLearningJourneyMutation = { __typename?: 'Mutation', updateLearningJourney: { __typename?: 'LearningJourneyResult', success: boolean, journey?: { __typename?: 'LearningJourney', id: number, businessGoal?: string | undefined, title: string, description?: string | undefined, bannerURL?: string | undefined, status: RLearningJourneyStatus, completionCriteria?: RLearningJourneyCompletionStatus | undefined, completionInvestTimeSec?: number | undefined } | undefined } };

export type RUpsertLearningJourneyCohortsMutationVariables = Exact<{
  input: RUpsertLearningJourneyCohortsInput;
}>;


export type RUpsertLearningJourneyCohortsMutation = { __typename?: 'Mutation', upsertLearningJourneyCohorts: { __typename?: 'LearningJourneySessionResult', success: boolean } };

export type RJoinLearningJourneyCohortsMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  sessionId: Scalars['Int']['input'];
}>;


export type RJoinLearningJourneyCohortsMutation = { __typename?: 'Mutation', joinLearningJourneyCohort: { __typename?: 'LearningJourneySessionResult', success: boolean } };

export type RUnlockUserLearningJourneyItemMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  learningJourneyId: Scalars['Int']['input'];
  itemURN: Scalars['URN']['input'];
}>;


export type RUnlockUserLearningJourneyItemMutation = { __typename?: 'Mutation', unlockUserLearningJourneyItem: { __typename?: 'LearningJourneyResult', success: boolean } };

export type RCreateLearningJourneySurveysMutationVariables = Exact<{
  input: RCreateLearningJourneySurveysInput;
}>;


export type RCreateLearningJourneySurveysMutation = { __typename?: 'Mutation', createLearningJourneySurveys: Array<{ __typename?: 'LearningV2', id: number, title: string, urn: string, type: RLearningV2Type, image?: string | undefined, effort?: number | undefined }> };

export type RCreateLearningJourneyPracticalAssessmentsMutationVariables = Exact<{
  input: RCreateLearningJourneyPracticalAssessmentsInput;
}>;


export type RCreateLearningJourneyPracticalAssessmentsMutation = { __typename?: 'Mutation', createLearningJourneyPracticalAssessments: Array<{ __typename?: 'LearningV2', id: number, title: string, urn: string, type: RLearningV2Type, image?: string | undefined, effort?: number | undefined }> };

export type RCreateUserLearningSubmissionMutationVariables = Exact<{
  input: RCreateUserLearningSubmissionInput;
}>;


export type RCreateUserLearningSubmissionMutation = { __typename?: 'Mutation', createUserLearningSubmission: { __typename?: 'UserLearningSubmissionResult', success: boolean, submission?: { __typename?: 'UserLearningSubmission', data: any, id: number, createdAt: Date, userId: number, type: RUserLearningSubmissionType, pageURNV2: string, learningURNV2: string } | undefined } };

export type RUserLearningSubmissionsQueryVariables = Exact<{
  input: RUserLearningSubmissionsFilter;
}>;


export type RUserLearningSubmissionsQuery = { __typename?: 'Query', userLearningSubmissions: Array<{ __typename?: 'UserLearningSubmission', createdAt: Date, data: any, id: number, pageURNV2: string, learningURNV2: string, type: RUserLearningSubmissionType, updatedAt: Date, userId: number, reviews: Array<{ __typename?: 'UserLearningSubmissionReview', createdAt: Date, data: any, id: number, reviewerId: number, submissionId: number, type: RUserLearningSubmissionReviewType, updatedAt: Date }>, reviewAssignments: Array<{ __typename?: 'UserLearningSubmissionReviewAssignment', createdAt: Date, expiryAt: Date, id: number, reviewerId: number, submissionId: number, updatedAt: Date, submission: { __typename?: 'UserLearningSubmission', data: any, id: number, createdAt: Date, pageURNV2: string, type: RUserLearningSubmissionType, userId: number, updatedAt: Date, reviews: Array<{ __typename?: 'UserLearningSubmissionReview', reviewerId: number, createdAt: Date, data: any, id: number }> } }> }> };

export type RCreateUserLearningSubmissionReviewMutationVariables = Exact<{
  input: RCreateUserLearningSubmissionReviewInput;
}>;


export type RCreateUserLearningSubmissionReviewMutation = { __typename?: 'Mutation', createUserLearningSubmissionReview: { __typename?: 'UserLearningSubmissionReviewResult', success: boolean } };

export type RReportingPracticalAssessmentInsightsQueryVariables = Exact<{
  where?: InputMaybe<RReportingPracticalAssessmentInsights_Bool_Exp>;
}>;


export type RReportingPracticalAssessmentInsightsQuery = { __typename?: 'Query', reportingPracticalAssessmentInsights: Array<{ __typename?: 'reportingPracticalAssessmentInsights', learningContentURN?: string | undefined, learningJourneyURN?: string | undefined, pageId?: number | undefined, pageURN?: string | undefined, responsesCount?: any | undefined, reviewsCount?: any | undefined }> };

export type RCreateLearningJourneyLiveEventMutationVariables = Exact<{
  input: RCreateLearningJourneyLiveEventInput;
}>;


export type RCreateLearningJourneyLiveEventMutation = { __typename?: 'Mutation', createLearningJourneyLiveEvent: { __typename?: 'LearningV2', id: number, title: string, type: RLearningV2Type, urn: string, effort?: number | undefined, image?: string | undefined } };

export type RSearchLearningJourneyContentQueryVariables = Exact<{
  filter: RSearchLearningContentFilter;
  pagination: RPagination;
  spaceIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type RSearchLearningJourneyContentQuery = { __typename?: 'Query', searchLearningContent: { __typename?: 'LearningContentsPage', limit: number, page: number, data: Array<{ __typename?: 'LearningContent', id: string, title: string, description: string, imageURL?: string | undefined, provider: RLearningContentProvider, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, metadata?: { __typename?: 'LearningContentMetadata', language?: string | undefined, level?: string | undefined, type?: RLearningContentContentType | undefined, timezone?: number | undefined } | undefined, locations?: Array<{ __typename?: 'LearningContentLocationData', id?: number | undefined, endDate?: Date | undefined, startDate?: Date | undefined, type?: RLocationType | undefined, address?: string | undefined }> | undefined, curations: Array<{ __typename?: 'LearningCuration', id: string, spaceId: number, launchURLPath?: string | undefined, title?: string | undefined, description?: string | undefined, imageURL?: string | undefined, editURLPath?: string | undefined }> }> } };

export type RLearningFragmentFragment = { __typename?: 'Learning', id: number, title?: string | undefined, spaceId?: number | undefined, languageId?: number | undefined, teaser?: string | undefined, effort?: number | undefined, image?: string | undefined, visibility?: RLearningVisibility | undefined, meta?: any | undefined, status?: RLearningStatus | undefined, type?: RLearningType | undefined, urnV2?: string | undefined };

export type RLearningV2FragmentFragment = { __typename?: 'LearningV2', id: number, title: string, spaceId: number, languageId: number, teaser: string, effort?: number | undefined, image?: string | undefined, visibility?: RLearningV2Visibility | undefined, meta?: any | undefined, status: RLearningV2Status, type: RLearningV2Type, urn: string };

export type RActivityFragmentFragment = { __typename?: 'LearningActivityDto', createdAt: Date, data: any, id: number, learningId?: number | undefined, order: number, parentActivityId?: number | undefined, type: RLearningActivityType, updatedAt: Date };

export type RLearningModulesDeepFragmentFragment = { __typename?: 'Module', id?: number | undefined, learningId?: number | undefined, pages?: Array<{ __typename?: 'Page', id?: number | undefined, name?: string | undefined, order?: number | undefined, moduleId?: number | undefined, blocks?: Array<{ __typename?: 'Block', id?: number | undefined, pageId?: number | undefined, type?: string | undefined, data?: any | undefined } | undefined> | undefined } | undefined> | undefined };

export type RLearningProgressFragmentFragment = { __typename?: 'LearningProgress', completed?: boolean | undefined, completedPercentage?: number | undefined, expiredAt?: string | undefined, historical?: boolean | undefined };

export type RLocationsFragmentFragment = { __typename?: 'Location', id: number, type?: RLocationType | undefined, room?: string | undefined, address?: string | undefined, endDate?: Date | undefined, startDate?: Date | undefined, myEnrollment?: { __typename?: 'Enrollment', id: number, attended: RAttendedType } | undefined };

export type RLearningByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeProgress: Scalars['Boolean']['input'];
  includeLocations: Scalars['Boolean']['input'];
}>;


export type RLearningByIdQuery = { __typename?: 'Query', learning?: { __typename?: 'Learning', id: number, title?: string | undefined, spaceId?: number | undefined, languageId?: number | undefined, teaser?: string | undefined, effort?: number | undefined, image?: string | undefined, visibility?: RLearningVisibility | undefined, meta?: any | undefined, status?: RLearningStatus | undefined, type?: RLearningType | undefined, urnV2?: string | undefined, modules?: Array<{ __typename?: 'Module', id?: number | undefined, learningId?: number | undefined, pages?: Array<{ __typename?: 'Page', id?: number | undefined, name?: string | undefined, order?: number | undefined, moduleId?: number | undefined, blocks?: Array<{ __typename?: 'Block', id?: number | undefined, pageId?: number | undefined, type?: string | undefined, data?: any | undefined } | undefined> | undefined } | undefined> | undefined } | undefined> | undefined, progress?: { __typename?: 'LearningProgress', completed?: boolean | undefined, completedPercentage?: number | undefined, expiredAt?: string | undefined, historical?: boolean | undefined } | undefined, locations?: Array<{ __typename?: 'Location', id: number, type?: RLocationType | undefined, room?: string | undefined, address?: string | undefined, endDate?: Date | undefined, startDate?: Date | undefined, myEnrollment?: { __typename?: 'Enrollment', id: number, attended: RAttendedType } | undefined } | undefined> | undefined } | undefined };

export type RLearningV2ByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type RLearningV2ByIdQuery = { __typename?: 'Query', learningV2: { __typename?: 'LearningV2', id: number, title: string, spaceId: number, languageId: number, teaser: string, effort?: number | undefined, image?: string | undefined, visibility?: RLearningV2Visibility | undefined, meta?: any | undefined, status: RLearningV2Status, type: RLearningV2Type, urn: string, activities?: Array<{ __typename?: 'LearningActivityDto', createdAt: Date, data: any, id: number, learningId?: number | undefined, order: number, parentActivityId?: number | undefined, type: RLearningActivityType, updatedAt: Date }> | undefined, userLastParticipation?: { __typename?: 'ParticipationV2', id?: number | undefined, expiryAt?: Date | undefined, isCompleted: boolean } | undefined } };

export type RSaveLearningMutationVariables = Exact<{
  input: RLearningInput;
}>;


export type RSaveLearningMutation = { __typename?: 'Mutation', saveLearning?: { __typename?: 'Learning', id: number, title?: string | undefined } | undefined };

export type RUpsertLearningMutationVariables = Exact<{
  input: RUpsertLearningInput;
}>;


export type RUpsertLearningMutation = { __typename?: 'Mutation', upsertLearning: { __typename?: 'LearningV2', id: number, title: string } };

export type RDeleteLearningMutationVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;


export type RDeleteLearningMutation = { __typename?: 'Mutation', deleteLearning?: { __typename?: 'Learning', id: number } | undefined };

export type RLearningTypeByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RLearningTypeByIdQuery = { __typename?: 'Query', learning?: { __typename?: 'Learning', id: number, type?: RLearningType | undefined } | undefined };

export type RLearningParentsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  filter: RLearningParentsFilter;
  userId: Scalars['Int']['input'];
}>;


export type RLearningParentsByIdQuery = { __typename?: 'Query', learning?: { __typename?: 'Learning', id: number, urnV2?: string | undefined, parents: Array<{ __typename?: 'LearningContent', id: string, title: string, userLastParticipation?: { __typename?: 'ParticipationV2', id?: number | undefined } | undefined }> } | undefined };

export type RSearchLearningContentQueryVariables = Exact<{
  filter: RSearchLearningContentFilter;
  pagination: RPagination;
  spaceIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type RSearchLearningContentQuery = { __typename?: 'Query', searchLearningContent: { __typename?: 'LearningContentsPage', limit: number, page: number, total: number, data: Array<{ __typename?: 'LearningContent', id: string, title: string, description: string, imageURL?: string | undefined, provider: RLearningContentProvider, plannedDuration?: number | undefined, plannedLearningsCount?: number | undefined, metadata?: { __typename?: 'LearningContentMetadata', language?: string | undefined, level?: string | undefined, type?: RLearningContentContentType | undefined, timezone?: number | undefined } | undefined, locations?: Array<{ __typename?: 'LearningContentLocationData', id?: number | undefined, endDate?: Date | undefined, startDate?: Date | undefined, type?: RLocationType | undefined, address?: string | undefined }> | undefined, curations: Array<{ __typename?: 'LearningCuration', id: string, spaceId: number, launchURLPath?: string | undefined, title?: string | undefined, description?: string | undefined, imageURL?: string | undefined, editURLPath?: string | undefined }> }> } };

export type RImportLearningContentToSpaceMutationVariables = Exact<{
  input: RImportLearningContentToSpaceInput;
}>;


export type RImportLearningContentToSpaceMutation = { __typename?: 'Mutation', importLearningContentToSpace: { __typename?: 'ImportLearningContentToSpaceResult', success: boolean, failedIds?: Array<string> | undefined, content?: Array<{ __typename?: 'LearningContent', id: string, title: string }> | undefined } };

export type RSubmitLearningActivityQuizAnswersMutationVariables = Exact<{
  input: RUserLearningActivityQuizSubmissionInput;
}>;


export type RSubmitLearningActivityQuizAnswersMutation = { __typename?: 'Mutation', submitLearningActivityQuizAnswers: { __typename?: 'LearningActivityDto', id: number } };

export type RMyLearningActivityQuizSubmissionsQueryVariables = Exact<{
  input: RUserLearningActivityQuizSubmissionFilterInput;
}>;


export type RMyLearningActivityQuizSubmissionsQuery = { __typename?: 'Query', myLearningActivityQuizSubmissions: Array<{ __typename?: 'UserLearningActivityQuizSubmission', answers: any, id: number, score: number }> };

export type RGetLearningXapiSessionQueryVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;


export type RGetLearningXapiSessionQuery = { __typename?: 'Query', getXAPISession: { __typename?: 'XAPISession', queryString?: string | undefined, registration?: string | undefined } };

export type RRegisterToLearningMutationVariables = Exact<{
  learningId: Scalars['Int']['input'];
}>;


export type RRegisterToLearningMutation = { __typename?: 'Mutation', registerToLearning: { __typename?: 'XAPISession', queryString?: string | undefined, registration?: string | undefined } };

export type RProductParentsByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  filter: RProductParentsFilter;
  userId: Scalars['Int']['input'];
}>;


export type RProductParentsByIdQuery = { __typename?: 'Query', getProduct?: { __typename?: 'Product', id?: number | undefined, learningContentUrnV2?: any | undefined, parents: Array<{ __typename?: 'LearningContent', id: string, title: string, userLastParticipation?: { __typename?: 'ParticipationV2', id?: number | undefined } | undefined }> } | undefined };

export type REffortForRealmQueryVariables = Exact<{
  realm: Scalars['String']['input'];
  fromTime: Scalars['timestamptz']['input'];
  toTime: Scalars['timestamptz']['input'];
}>;


export type REffortForRealmQuery = { __typename?: 'Query', reportingAggregationEffortForRealm: Array<{ __typename?: 'reportingAggregationEffortForRealm', totalEffort?: any | undefined, averageEffort?: any | undefined, p90Effort?: any | undefined, p80Effort?: any | undefined, p70Effort?: any | undefined, p60Effort?: any | undefined, p50Effort?: any | undefined, fromTime?: any | undefined, toTime?: any | undefined }> };

export type RSpaceMembersQueryVariables = Exact<{
  filter: RSpaceMemberFilter;
  pagination: RPagination;
}>;


export type RSpaceMembersQuery = { __typename?: 'Query', spaceMembers: { __typename?: 'SpaceMemberPage', data: Array<{ __typename?: 'SpaceMember', id: number, email: string, realm: string, spaceId: number, firstname?: string | undefined, lastname?: string | undefined, username?: string | undefined, profileImage?: string | undefined }> } };

export type RSpacesQueryVariables = Exact<{
  withPermissions: Scalars['Boolean']['input'];
  withContentOrigins: Scalars['Boolean']['input'];
}>;


export type RSpacesQuery = { __typename?: 'Query', spaces: { __typename?: 'SpaceV2Page', data: Array<{ __typename?: 'SpaceV2', id: number, realm?: string | undefined, label: string, name: string, permissions?: { __typename?: 'SpacePermissions', hasWriteAccessToLearningContent?: boolean | undefined }, contentOrigins?: { __typename?: 'ProviderContentOriginPage', data: Array<{ __typename?: 'ProviderContentOrigin', id: string, provider: { __typename?: 'Provider', id: string, type: RLearningContentProvider } }> } }> } };

export type RUpdateUsersMutationVariables = Exact<{
  input: RUserChangeInput;
}>;


export type RUpdateUsersMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserV2', id: number } };

export const LearningContentFragmentDoc = gql`
    fragment LearningContent on LearningContent {
  id
  title
  description
  imageURL
  provider
  plannedDuration
  plannedLearningsCount
  metadata {
    language
    level
    type
    timezone
  }
  locations {
    id
    endDate
    startDate
    type
    address
  }
  curations(filter: {spacedIdsIN: $spaceIds}) {
    id
    spaceId
    launchURLPath
    title
    description
    imageURL
    editURLPath
  }
}
    `;
export const ParticipationFragmentDoc = gql`
    fragment Participation on ParticipationV2 {
  id
  objectId
  userId
  progress
  isCompleted
  completedAt
  plannedDuration
  plannedLearningsCount
  userEffort
  expiryAt
}
    `;
export const LearningFragmentFragmentDoc = gql`
    fragment LearningFragment on Learning {
  id
  title
  spaceId
  languageId
  teaser
  effort
  image
  visibility
  meta
  status
  type
  urnV2
}
    `;
export const LearningV2FragmentFragmentDoc = gql`
    fragment LearningV2Fragment on LearningV2 {
  id
  title
  spaceId
  languageId
  teaser
  effort
  image
  visibility
  meta
  status
  type
  urn
}
    `;
export const ActivityFragmentFragmentDoc = gql`
    fragment ActivityFragment on LearningActivityDto {
  createdAt
  data
  id
  learningId
  order
  parentActivityId
  type
  updatedAt
}
    `;
export const LearningModulesDeepFragmentFragmentDoc = gql`
    fragment LearningModulesDeepFragment on Module {
  id
  learningId
  pages {
    id
    name
    order
    moduleId
    blocks {
      id
      pageId
      type
      data
    }
  }
}
    `;
export const LearningProgressFragmentFragmentDoc = gql`
    fragment LearningProgressFragment on LearningProgress {
  completed
  completedPercentage
  expiredAt
  historical
}
    `;
export const LocationsFragmentFragmentDoc = gql`
    fragment LocationsFragment on Location {
  id
  type
  room
  address
  endDate
  startDate
  myEnrollment {
    id
    attended
  }
}
    `;
export const AssignmentV3UsersDocument = gql`
    query assignmentV3Users($filter: AssignmentV3UsersFilter!, $pagination: Pagination, $sort: AssignmentUsersV3Sort, $includeProgress: Boolean!) {
  assignmentV3Users(filter: $filter, pagination: $pagination, sort: $sort) {
    data {
      userId
      assignmentId
      user {
        id
        email
        profileImage
        username
        status
      }
      ... on AssignmentStrategicV3User {
        progress @include(if: $includeProgress) {
          completed
          estimatedEffort
        }
      }
      ... on AssignmentStandardV3User {
        progress @include(if: $includeProgress) {
          __typename
          participations {
            assignmentLearningId
            participationId
            progress
            completedAt
          }
        }
      }
    }
    total
  }
}
    `;

/**
 * __useAssignmentV3UsersQueryRemote__
 *
 * To run a query within a React component, call `useAssignmentV3UsersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentV3UsersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentV3UsersQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      includeProgress: // value for 'includeProgress'
 *   },
 * });
 */
export function useAssignmentV3UsersQueryRemote(baseOptions: Apollo.QueryHookOptions<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>(AssignmentV3UsersDocument, options);
      }
export function useAssignmentV3UsersLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>(AssignmentV3UsersDocument, options);
        }
export type AssignmentV3UsersQueryRemoteHookResult = ReturnType<typeof useAssignmentV3UsersQueryRemote>;
export type AssignmentV3UsersLazyQueryRemoteHookResult = ReturnType<typeof useAssignmentV3UsersLazyQueryRemote>;
export type AssignmentV3UsersQueryResult = Apollo.QueryResult<RAssignmentV3UsersQuery, RAssignmentV3UsersQueryVariables>;
export const UserLearningContentLastParticipationDocument = gql`
    query userLearningContentLastParticipation($userId: Int!, $learningContentId: URN!) {
  userLearningContentLastParticipation(
    userId: $userId
    learningContentId: $learningContentId
  ) {
    ...Participation
  }
}
    ${ParticipationFragmentDoc}`;

/**
 * __useUserLearningContentLastParticipationQueryRemote__
 *
 * To run a query within a React component, call `useUserLearningContentLastParticipationQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningContentLastParticipationQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningContentLastParticipationQueryRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningContentId: // value for 'learningContentId'
 *   },
 * });
 */
export function useUserLearningContentLastParticipationQueryRemote(baseOptions: Apollo.QueryHookOptions<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>(UserLearningContentLastParticipationDocument, options);
      }
export function useUserLearningContentLastParticipationLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>(UserLearningContentLastParticipationDocument, options);
        }
export type UserLearningContentLastParticipationQueryRemoteHookResult = ReturnType<typeof useUserLearningContentLastParticipationQueryRemote>;
export type UserLearningContentLastParticipationLazyQueryRemoteHookResult = ReturnType<typeof useUserLearningContentLastParticipationLazyQueryRemote>;
export type UserLearningContentLastParticipationQueryResult = Apollo.QueryResult<RUserLearningContentLastParticipationQuery, RUserLearningContentLastParticipationQueryVariables>;
export const UserLearningContentLastParticipationsDocument = gql`
    query userLearningContentLastParticipations($userId: Int!, $learningContentIdIn: [URN!]!) {
  userLearningContentLastParticipations(
    userId: $userId
    learningContentIdIN: $learningContentIdIn
  ) {
    ...Participation
  }
}
    ${ParticipationFragmentDoc}`;

/**
 * __useUserLearningContentLastParticipationsQueryRemote__
 *
 * To run a query within a React component, call `useUserLearningContentLastParticipationsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningContentLastParticipationsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningContentLastParticipationsQueryRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningContentIdIn: // value for 'learningContentIdIn'
 *   },
 * });
 */
export function useUserLearningContentLastParticipationsQueryRemote(baseOptions: Apollo.QueryHookOptions<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>(UserLearningContentLastParticipationsDocument, options);
      }
export function useUserLearningContentLastParticipationsLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>(UserLearningContentLastParticipationsDocument, options);
        }
export type UserLearningContentLastParticipationsQueryRemoteHookResult = ReturnType<typeof useUserLearningContentLastParticipationsQueryRemote>;
export type UserLearningContentLastParticipationsLazyQueryRemoteHookResult = ReturnType<typeof useUserLearningContentLastParticipationsLazyQueryRemote>;
export type UserLearningContentLastParticipationsQueryResult = Apollo.QueryResult<RUserLearningContentLastParticipationsQuery, RUserLearningContentLastParticipationsQueryVariables>;
export const LearningJourneyTemplatesDocument = gql`
    query learningJourneyTemplates {
  learningJourneyTemplates {
    id
    bannerURL
    businessGoal
    description
    teaser
    title
  }
}
    `;

/**
 * __useLearningJourneyTemplatesQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyTemplatesQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyTemplatesQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyTemplatesQueryRemote({
 *   variables: {
 *   },
 * });
 */
export function useLearningJourneyTemplatesQueryRemote(baseOptions?: Apollo.QueryHookOptions<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>(LearningJourneyTemplatesDocument, options);
      }
export function useLearningJourneyTemplatesLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>(LearningJourneyTemplatesDocument, options);
        }
export type LearningJourneyTemplatesQueryRemoteHookResult = ReturnType<typeof useLearningJourneyTemplatesQueryRemote>;
export type LearningJourneyTemplatesLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyTemplatesLazyQueryRemote>;
export type LearningJourneyTemplatesQueryResult = Apollo.QueryResult<RLearningJourneyTemplatesQuery, RLearningJourneyTemplatesQueryVariables>;
export const CreateLearningJourneyFromTemplateDocument = gql`
    mutation createLearningJourneyFromTemplate($input: CreateLearningJourneyFromTemplateInput!) {
  createLearningJourneyFromTemplate(input: $input) {
    success
    journey {
      id
    }
  }
}
    `;
export type RCreateLearningJourneyFromTemplateMutationFn = Apollo.MutationFunction<RCreateLearningJourneyFromTemplateMutation, RCreateLearningJourneyFromTemplateMutationVariables>;

/**
 * __useCreateLearningJourneyFromTemplateMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyFromTemplateMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyFromTemplateMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyFromTemplateMutationRemote, { data, loading, error }] = useCreateLearningJourneyFromTemplateMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyFromTemplateMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneyFromTemplateMutation, RCreateLearningJourneyFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateLearningJourneyFromTemplateMutation, RCreateLearningJourneyFromTemplateMutationVariables>(CreateLearningJourneyFromTemplateDocument, options);
      }
export type CreateLearningJourneyFromTemplateMutationRemoteHookResult = ReturnType<typeof useCreateLearningJourneyFromTemplateMutationRemote>;
export type CreateLearningJourneyFromTemplateMutationResult = Apollo.MutationResult<RCreateLearningJourneyFromTemplateMutation>;
export type CreateLearningJourneyFromTemplateMutationOptions = Apollo.BaseMutationOptions<RCreateLearningJourneyFromTemplateMutation, RCreateLearningJourneyFromTemplateMutationVariables>;
export const LearningJourneyDocument = gql`
    query learningJourney($id: Int!, $includeItems: Boolean!, $spaceIds: [Int!], $userId: Int!) {
  learningJourney(id: $id) {
    id
    urn
    spaceId
    title
    bannerURL
    businessGoal
    impactSurveysEnabled
    description
    status
    spaceFeatured
    completionCriteria
    completionInvestTimeSec
    isLinear
    discussionEnabled
    discussionForumSectionPath
    createdAt
    items(pagination: {limit: -1}) @include(if: $includeItems) {
      data {
        id
        contentId: learningItemURNV2
        content: learningItem {
          ...LearningContent
        }
        order
        isLocked(userId: $userId)
      }
    }
  }
}
    ${LearningContentFragmentDoc}`;

/**
 * __useLearningJourneyQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      includeItems: // value for 'includeItems'
 *      spaceIds: // value for 'spaceIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLearningJourneyQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningJourneyQuery, RLearningJourneyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningJourneyQuery, RLearningJourneyQueryVariables>(LearningJourneyDocument, options);
      }
export function useLearningJourneyLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneyQuery, RLearningJourneyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningJourneyQuery, RLearningJourneyQueryVariables>(LearningJourneyDocument, options);
        }
export type LearningJourneyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyQueryRemote>;
export type LearningJourneyLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyLazyQueryRemote>;
export type LearningJourneyQueryResult = Apollo.QueryResult<RLearningJourneyQuery, RLearningJourneyQueryVariables>;
export const LearningJourneysDocument = gql`
    query learningJourneys($filter: LearningJourneyFilter!, $pagination: Pagination) {
  learningJourneys(filter: $filter, pagination: $pagination) {
    total
    data {
      id
      spaceId
      title
      status
      items(pagination: {limit: -1}) {
        total
      }
    }
  }
}
    `;

/**
 * __useLearningJourneysQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneysQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneysQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneysQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLearningJourneysQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningJourneysQuery, RLearningJourneysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningJourneysQuery, RLearningJourneysQueryVariables>(LearningJourneysDocument, options);
      }
export function useLearningJourneysLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneysQuery, RLearningJourneysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningJourneysQuery, RLearningJourneysQueryVariables>(LearningJourneysDocument, options);
        }
export type LearningJourneysQueryRemoteHookResult = ReturnType<typeof useLearningJourneysQueryRemote>;
export type LearningJourneysLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneysLazyQueryRemote>;
export type LearningJourneysQueryResult = Apollo.QueryResult<RLearningJourneysQuery, RLearningJourneysQueryVariables>;
export const LearningJourneySessionDocument = gql`
    query learningJourneySession($id: Int!, $includeProgress: Boolean!) {
  learningJourneySession(id: $id) {
    id
    startedAt
    cohort {
      learners {
        userId
        sessionId
        user {
          id
          email
          username
          profileImage
        }
        progress @include(if: $includeProgress) {
          isCompleted
          progress
        }
      }
    }
  }
}
    `;

/**
 * __useLearningJourneySessionQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneySessionQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneySessionQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneySessionQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      includeProgress: // value for 'includeProgress'
 *   },
 * });
 */
export function useLearningJourneySessionQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>(LearningJourneySessionDocument, options);
      }
export function useLearningJourneySessionLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>(LearningJourneySessionDocument, options);
        }
export type LearningJourneySessionQueryRemoteHookResult = ReturnType<typeof useLearningJourneySessionQueryRemote>;
export type LearningJourneySessionLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneySessionLazyQueryRemote>;
export type LearningJourneySessionQueryResult = Apollo.QueryResult<RLearningJourneySessionQuery, RLearningJourneySessionQueryVariables>;
export const LearningJourneyItemsCatalogDocument = gql`
    query learningJourneyItemsCatalog($id: Int!, $spaceIds: [Int!]!, $searchPhrase: String, $pagination: Pagination!) {
  learningJourneyItemsCatalog(
    filter: {id: $id, searchPhrase: $searchPhrase}
    pagination: $pagination
  ) {
    data {
      ...LearningContent
    }
    limit
    page
  }
}
    ${LearningContentFragmentDoc}`;

/**
 * __useLearningJourneyItemsCatalogQueryRemote__
 *
 * To run a query within a React component, call `useLearningJourneyItemsCatalogQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningJourneyItemsCatalogQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningJourneyItemsCatalogQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      spaceIds: // value for 'spaceIds'
 *      searchPhrase: // value for 'searchPhrase'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLearningJourneyItemsCatalogQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>(LearningJourneyItemsCatalogDocument, options);
      }
export function useLearningJourneyItemsCatalogLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>(LearningJourneyItemsCatalogDocument, options);
        }
export type LearningJourneyItemsCatalogQueryRemoteHookResult = ReturnType<typeof useLearningJourneyItemsCatalogQueryRemote>;
export type LearningJourneyItemsCatalogLazyQueryRemoteHookResult = ReturnType<typeof useLearningJourneyItemsCatalogLazyQueryRemote>;
export type LearningJourneyItemsCatalogQueryResult = Apollo.QueryResult<RLearningJourneyItemsCatalogQuery, RLearningJourneyItemsCatalogQueryVariables>;
export const ReportingLearningJourneyContentInsightsDocument = gql`
    query reportingLearningJourneyContentInsights($where: reportingLearningJourneyContentInsights_bool_exp, $orderBy: [reportingLearningJourneyContentInsights_order_by!], $limit: Int, $whereAggregate: reportingLearningJourneyContentInsights_bool_exp, $offset: Int) {
  reportingLearningJourneyContentInsights(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    learningContentURN
    learningContentTitle
    learningType
    startedCount
    completedCount
    completionRate
  }
  filteredTotal: reportingLearningJourneyContentInsightsAggregate(where: $where) {
    aggregate {
      count
    }
  }
  total: reportingLearningJourneyContentInsightsAggregate(where: $whereAggregate) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useReportingLearningJourneyContentInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingLearningJourneyContentInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingLearningJourneyContentInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingLearningJourneyContentInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      whereAggregate: // value for 'whereAggregate'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReportingLearningJourneyContentInsightsQueryRemote(baseOptions?: Apollo.QueryHookOptions<RReportingLearningJourneyContentInsightsQuery, RReportingLearningJourneyContentInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RReportingLearningJourneyContentInsightsQuery, RReportingLearningJourneyContentInsightsQueryVariables>(ReportingLearningJourneyContentInsightsDocument, options);
      }
export function useReportingLearningJourneyContentInsightsLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RReportingLearningJourneyContentInsightsQuery, RReportingLearningJourneyContentInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RReportingLearningJourneyContentInsightsQuery, RReportingLearningJourneyContentInsightsQueryVariables>(ReportingLearningJourneyContentInsightsDocument, options);
        }
export type ReportingLearningJourneyContentInsightsQueryRemoteHookResult = ReturnType<typeof useReportingLearningJourneyContentInsightsQueryRemote>;
export type ReportingLearningJourneyContentInsightsLazyQueryRemoteHookResult = ReturnType<typeof useReportingLearningJourneyContentInsightsLazyQueryRemote>;
export type ReportingLearningJourneyContentInsightsQueryResult = Apollo.QueryResult<RReportingLearningJourneyContentInsightsQuery, RReportingLearningJourneyContentInsightsQueryVariables>;
export const ReportingSurveyAnswersDocument = gql`
    query reportingSurveyAnswers($where: reportingSurveyAnswers_bool_exp) {
  reportingSurveyAnswers(where: $where) {
    answer
    answerCount
    answerPercent
    answerTotal
    questionURN
    urn
    scoreRaw
  }
}
    `;

/**
 * __useReportingSurveyAnswersQueryRemote__
 *
 * To run a query within a React component, call `useReportingSurveyAnswersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingSurveyAnswersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingSurveyAnswersQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingSurveyAnswersQueryRemote(baseOptions?: Apollo.QueryHookOptions<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>(ReportingSurveyAnswersDocument, options);
      }
export function useReportingSurveyAnswersLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>(ReportingSurveyAnswersDocument, options);
        }
export type ReportingSurveyAnswersQueryRemoteHookResult = ReturnType<typeof useReportingSurveyAnswersQueryRemote>;
export type ReportingSurveyAnswersLazyQueryRemoteHookResult = ReturnType<typeof useReportingSurveyAnswersLazyQueryRemote>;
export type ReportingSurveyAnswersQueryResult = Apollo.QueryResult<RReportingSurveyAnswersQuery, RReportingSurveyAnswersQueryVariables>;
export const SurveyAnswerInsightsCsvDocument = gql`
    query surveyAnswerInsightsCSV($surveyAnswerInsightsCsvId: Int!) {
  surveyAnswerInsightsCSV(id: $surveyAnswerInsightsCsvId)
}
    `;

/**
 * __useSurveyAnswerInsightsCsvQueryRemote__
 *
 * To run a query within a React component, call `useSurveyAnswerInsightsCsvQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSurveyAnswerInsightsCsvQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyAnswerInsightsCsvQueryRemote({
 *   variables: {
 *      surveyAnswerInsightsCsvId: // value for 'surveyAnswerInsightsCsvId'
 *   },
 * });
 */
export function useSurveyAnswerInsightsCsvQueryRemote(baseOptions: Apollo.QueryHookOptions<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>(SurveyAnswerInsightsCsvDocument, options);
      }
export function useSurveyAnswerInsightsCsvLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>(SurveyAnswerInsightsCsvDocument, options);
        }
export type SurveyAnswerInsightsCsvQueryRemoteHookResult = ReturnType<typeof useSurveyAnswerInsightsCsvQueryRemote>;
export type SurveyAnswerInsightsCsvLazyQueryRemoteHookResult = ReturnType<typeof useSurveyAnswerInsightsCsvLazyQueryRemote>;
export type SurveyAnswerInsightsCsvQueryResult = Apollo.QueryResult<RSurveyAnswerInsightsCsvQuery, RSurveyAnswerInsightsCsvQueryVariables>;
export const CreateLearningJourneyDocument = gql`
    mutation createLearningJourney($input: CreateLearningJourneyInput!) {
  createLearningJourney(input: $input) {
    success
    journey {
      id
    }
  }
}
    `;
export type RCreateLearningJourneyMutationFn = Apollo.MutationFunction<RCreateLearningJourneyMutation, RCreateLearningJourneyMutationVariables>;

/**
 * __useCreateLearningJourneyMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyMutationRemote, { data, loading, error }] = useCreateLearningJourneyMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneyMutation, RCreateLearningJourneyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateLearningJourneyMutation, RCreateLearningJourneyMutationVariables>(CreateLearningJourneyDocument, options);
      }
export type CreateLearningJourneyMutationRemoteHookResult = ReturnType<typeof useCreateLearningJourneyMutationRemote>;
export type CreateLearningJourneyMutationResult = Apollo.MutationResult<RCreateLearningJourneyMutation>;
export type CreateLearningJourneyMutationOptions = Apollo.BaseMutationOptions<RCreateLearningJourneyMutation, RCreateLearningJourneyMutationVariables>;
export const UpsertLearningJourneyItemsDocument = gql`
    mutation upsertLearningJourneyItems($input: UpsertLearningJourneyItemsInput!) {
  upsertLearningJourneyItems(input: $input) {
    success
    journey {
      id
    }
  }
}
    `;
export type RUpsertLearningJourneyItemsMutationFn = Apollo.MutationFunction<RUpsertLearningJourneyItemsMutation, RUpsertLearningJourneyItemsMutationVariables>;

/**
 * __useUpsertLearningJourneyItemsMutationRemote__
 *
 * To run a mutation, you first call `useUpsertLearningJourneyItemsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLearningJourneyItemsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLearningJourneyItemsMutationRemote, { data, loading, error }] = useUpsertLearningJourneyItemsMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLearningJourneyItemsMutationRemote(baseOptions?: Apollo.MutationHookOptions<RUpsertLearningJourneyItemsMutation, RUpsertLearningJourneyItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RUpsertLearningJourneyItemsMutation, RUpsertLearningJourneyItemsMutationVariables>(UpsertLearningJourneyItemsDocument, options);
      }
export type UpsertLearningJourneyItemsMutationRemoteHookResult = ReturnType<typeof useUpsertLearningJourneyItemsMutationRemote>;
export type UpsertLearningJourneyItemsMutationResult = Apollo.MutationResult<RUpsertLearningJourneyItemsMutation>;
export type UpsertLearningJourneyItemsMutationOptions = Apollo.BaseMutationOptions<RUpsertLearningJourneyItemsMutation, RUpsertLearningJourneyItemsMutationVariables>;
export const UpdateLearningJourneyDocument = gql`
    mutation updateLearningJourney($input: UpdateLearningJourneyInput!) {
  updateLearningJourney(input: $input) {
    success
    journey {
      id
      businessGoal
      title
      description
      bannerURL
      status
      completionCriteria
      completionInvestTimeSec
    }
  }
}
    `;
export type RUpdateLearningJourneyMutationFn = Apollo.MutationFunction<RUpdateLearningJourneyMutation, RUpdateLearningJourneyMutationVariables>;

/**
 * __useUpdateLearningJourneyMutationRemote__
 *
 * To run a mutation, you first call `useUpdateLearningJourneyMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningJourneyMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningJourneyMutationRemote, { data, loading, error }] = useUpdateLearningJourneyMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLearningJourneyMutationRemote(baseOptions?: Apollo.MutationHookOptions<RUpdateLearningJourneyMutation, RUpdateLearningJourneyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RUpdateLearningJourneyMutation, RUpdateLearningJourneyMutationVariables>(UpdateLearningJourneyDocument, options);
      }
export type UpdateLearningJourneyMutationRemoteHookResult = ReturnType<typeof useUpdateLearningJourneyMutationRemote>;
export type UpdateLearningJourneyMutationResult = Apollo.MutationResult<RUpdateLearningJourneyMutation>;
export type UpdateLearningJourneyMutationOptions = Apollo.BaseMutationOptions<RUpdateLearningJourneyMutation, RUpdateLearningJourneyMutationVariables>;
export const UpsertLearningJourneyCohortsDocument = gql`
    mutation upsertLearningJourneyCohorts($input: UpsertLearningJourneyCohortsInput!) {
  upsertLearningJourneyCohorts(input: $input) {
    success
  }
}
    `;
export type RUpsertLearningJourneyCohortsMutationFn = Apollo.MutationFunction<RUpsertLearningJourneyCohortsMutation, RUpsertLearningJourneyCohortsMutationVariables>;

/**
 * __useUpsertLearningJourneyCohortsMutationRemote__
 *
 * To run a mutation, you first call `useUpsertLearningJourneyCohortsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLearningJourneyCohortsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLearningJourneyCohortsMutationRemote, { data, loading, error }] = useUpsertLearningJourneyCohortsMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLearningJourneyCohortsMutationRemote(baseOptions?: Apollo.MutationHookOptions<RUpsertLearningJourneyCohortsMutation, RUpsertLearningJourneyCohortsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RUpsertLearningJourneyCohortsMutation, RUpsertLearningJourneyCohortsMutationVariables>(UpsertLearningJourneyCohortsDocument, options);
      }
export type UpsertLearningJourneyCohortsMutationRemoteHookResult = ReturnType<typeof useUpsertLearningJourneyCohortsMutationRemote>;
export type UpsertLearningJourneyCohortsMutationResult = Apollo.MutationResult<RUpsertLearningJourneyCohortsMutation>;
export type UpsertLearningJourneyCohortsMutationOptions = Apollo.BaseMutationOptions<RUpsertLearningJourneyCohortsMutation, RUpsertLearningJourneyCohortsMutationVariables>;
export const JoinLearningJourneyCohortsDocument = gql`
    mutation joinLearningJourneyCohorts($userId: Int!, $sessionId: Int!) {
  joinLearningJourneyCohort(userId: $userId, sessionId: $sessionId) {
    success
  }
}
    `;
export type RJoinLearningJourneyCohortsMutationFn = Apollo.MutationFunction<RJoinLearningJourneyCohortsMutation, RJoinLearningJourneyCohortsMutationVariables>;

/**
 * __useJoinLearningJourneyCohortsMutationRemote__
 *
 * To run a mutation, you first call `useJoinLearningJourneyCohortsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLearningJourneyCohortsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLearningJourneyCohortsMutationRemote, { data, loading, error }] = useJoinLearningJourneyCohortsMutationRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinLearningJourneyCohortsMutationRemote(baseOptions?: Apollo.MutationHookOptions<RJoinLearningJourneyCohortsMutation, RJoinLearningJourneyCohortsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RJoinLearningJourneyCohortsMutation, RJoinLearningJourneyCohortsMutationVariables>(JoinLearningJourneyCohortsDocument, options);
      }
export type JoinLearningJourneyCohortsMutationRemoteHookResult = ReturnType<typeof useJoinLearningJourneyCohortsMutationRemote>;
export type JoinLearningJourneyCohortsMutationResult = Apollo.MutationResult<RJoinLearningJourneyCohortsMutation>;
export type JoinLearningJourneyCohortsMutationOptions = Apollo.BaseMutationOptions<RJoinLearningJourneyCohortsMutation, RJoinLearningJourneyCohortsMutationVariables>;
export const UnlockUserLearningJourneyItemDocument = gql`
    mutation unlockUserLearningJourneyItem($userId: Int!, $learningJourneyId: Int!, $itemURN: URN!) {
  unlockUserLearningJourneyItem(
    userId: $userId
    learningJourneyId: $learningJourneyId
    itemURN: $itemURN
  ) {
    success
  }
}
    `;
export type RUnlockUserLearningJourneyItemMutationFn = Apollo.MutationFunction<RUnlockUserLearningJourneyItemMutation, RUnlockUserLearningJourneyItemMutationVariables>;

/**
 * __useUnlockUserLearningJourneyItemMutationRemote__
 *
 * To run a mutation, you first call `useUnlockUserLearningJourneyItemMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockUserLearningJourneyItemMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockUserLearningJourneyItemMutationRemote, { data, loading, error }] = useUnlockUserLearningJourneyItemMutationRemote({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningJourneyId: // value for 'learningJourneyId'
 *      itemURN: // value for 'itemURN'
 *   },
 * });
 */
export function useUnlockUserLearningJourneyItemMutationRemote(baseOptions?: Apollo.MutationHookOptions<RUnlockUserLearningJourneyItemMutation, RUnlockUserLearningJourneyItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RUnlockUserLearningJourneyItemMutation, RUnlockUserLearningJourneyItemMutationVariables>(UnlockUserLearningJourneyItemDocument, options);
      }
export type UnlockUserLearningJourneyItemMutationRemoteHookResult = ReturnType<typeof useUnlockUserLearningJourneyItemMutationRemote>;
export type UnlockUserLearningJourneyItemMutationResult = Apollo.MutationResult<RUnlockUserLearningJourneyItemMutation>;
export type UnlockUserLearningJourneyItemMutationOptions = Apollo.BaseMutationOptions<RUnlockUserLearningJourneyItemMutation, RUnlockUserLearningJourneyItemMutationVariables>;
export const CreateLearningJourneySurveysDocument = gql`
    mutation createLearningJourneySurveys($input: CreateLearningJourneySurveysInput!) {
  createLearningJourneySurveys(input: $input) {
    id
    title
    urn
    type
    image
    effort
  }
}
    `;
export type RCreateLearningJourneySurveysMutationFn = Apollo.MutationFunction<RCreateLearningJourneySurveysMutation, RCreateLearningJourneySurveysMutationVariables>;

/**
 * __useCreateLearningJourneySurveysMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneySurveysMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneySurveysMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneySurveysMutationRemote, { data, loading, error }] = useCreateLearningJourneySurveysMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneySurveysMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneySurveysMutation, RCreateLearningJourneySurveysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateLearningJourneySurveysMutation, RCreateLearningJourneySurveysMutationVariables>(CreateLearningJourneySurveysDocument, options);
      }
export type CreateLearningJourneySurveysMutationRemoteHookResult = ReturnType<typeof useCreateLearningJourneySurveysMutationRemote>;
export type CreateLearningJourneySurveysMutationResult = Apollo.MutationResult<RCreateLearningJourneySurveysMutation>;
export type CreateLearningJourneySurveysMutationOptions = Apollo.BaseMutationOptions<RCreateLearningJourneySurveysMutation, RCreateLearningJourneySurveysMutationVariables>;
export const CreateLearningJourneyPracticalAssessmentsDocument = gql`
    mutation createLearningJourneyPracticalAssessments($input: CreateLearningJourneyPracticalAssessmentsInput!) {
  createLearningJourneyPracticalAssessments(input: $input) {
    id
    title
    urn
    type
    image
    effort
  }
}
    `;
export type RCreateLearningJourneyPracticalAssessmentsMutationFn = Apollo.MutationFunction<RCreateLearningJourneyPracticalAssessmentsMutation, RCreateLearningJourneyPracticalAssessmentsMutationVariables>;

/**
 * __useCreateLearningJourneyPracticalAssessmentsMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyPracticalAssessmentsMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyPracticalAssessmentsMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyPracticalAssessmentsMutationRemote, { data, loading, error }] = useCreateLearningJourneyPracticalAssessmentsMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyPracticalAssessmentsMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneyPracticalAssessmentsMutation, RCreateLearningJourneyPracticalAssessmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateLearningJourneyPracticalAssessmentsMutation, RCreateLearningJourneyPracticalAssessmentsMutationVariables>(CreateLearningJourneyPracticalAssessmentsDocument, options);
      }
export type CreateLearningJourneyPracticalAssessmentsMutationRemoteHookResult = ReturnType<typeof useCreateLearningJourneyPracticalAssessmentsMutationRemote>;
export type CreateLearningJourneyPracticalAssessmentsMutationResult = Apollo.MutationResult<RCreateLearningJourneyPracticalAssessmentsMutation>;
export type CreateLearningJourneyPracticalAssessmentsMutationOptions = Apollo.BaseMutationOptions<RCreateLearningJourneyPracticalAssessmentsMutation, RCreateLearningJourneyPracticalAssessmentsMutationVariables>;
export const CreateUserLearningSubmissionDocument = gql`
    mutation createUserLearningSubmission($input: CreateUserLearningSubmissionInput!) {
  createUserLearningSubmission(input: $input) {
    success
    submission {
      data
      id
      createdAt
      userId
      type
      pageURNV2
      learningURNV2
    }
  }
}
    `;
export type RCreateUserLearningSubmissionMutationFn = Apollo.MutationFunction<RCreateUserLearningSubmissionMutation, RCreateUserLearningSubmissionMutationVariables>;

/**
 * __useCreateUserLearningSubmissionMutationRemote__
 *
 * To run a mutation, you first call `useCreateUserLearningSubmissionMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLearningSubmissionMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLearningSubmissionMutationRemote, { data, loading, error }] = useCreateUserLearningSubmissionMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLearningSubmissionMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateUserLearningSubmissionMutation, RCreateUserLearningSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateUserLearningSubmissionMutation, RCreateUserLearningSubmissionMutationVariables>(CreateUserLearningSubmissionDocument, options);
      }
export type CreateUserLearningSubmissionMutationRemoteHookResult = ReturnType<typeof useCreateUserLearningSubmissionMutationRemote>;
export type CreateUserLearningSubmissionMutationResult = Apollo.MutationResult<RCreateUserLearningSubmissionMutation>;
export type CreateUserLearningSubmissionMutationOptions = Apollo.BaseMutationOptions<RCreateUserLearningSubmissionMutation, RCreateUserLearningSubmissionMutationVariables>;
export const UserLearningSubmissionsDocument = gql`
    query userLearningSubmissions($input: UserLearningSubmissionsFilter!) {
  userLearningSubmissions(input: $input) {
    createdAt
    data
    id
    pageURNV2
    learningURNV2
    reviews {
      createdAt
      data
      id
      reviewerId
      submissionId
      type
      updatedAt
    }
    reviewAssignments {
      createdAt
      expiryAt
      id
      reviewerId
      submissionId
      updatedAt
      submission {
        data
        id
        createdAt
        pageURNV2
        type
        userId
        updatedAt
        reviews {
          reviewerId
          createdAt
          data
          id
        }
      }
    }
    type
    updatedAt
    userId
  }
}
    `;

/**
 * __useUserLearningSubmissionsQueryRemote__
 *
 * To run a query within a React component, call `useUserLearningSubmissionsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningSubmissionsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningSubmissionsQueryRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserLearningSubmissionsQueryRemote(baseOptions: Apollo.QueryHookOptions<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>(UserLearningSubmissionsDocument, options);
      }
export function useUserLearningSubmissionsLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>(UserLearningSubmissionsDocument, options);
        }
export type UserLearningSubmissionsQueryRemoteHookResult = ReturnType<typeof useUserLearningSubmissionsQueryRemote>;
export type UserLearningSubmissionsLazyQueryRemoteHookResult = ReturnType<typeof useUserLearningSubmissionsLazyQueryRemote>;
export type UserLearningSubmissionsQueryResult = Apollo.QueryResult<RUserLearningSubmissionsQuery, RUserLearningSubmissionsQueryVariables>;
export const CreateUserLearningSubmissionReviewDocument = gql`
    mutation createUserLearningSubmissionReview($input: CreateUserLearningSubmissionReviewInput!) {
  createUserLearningSubmissionReview(input: $input) {
    success
  }
}
    `;
export type RCreateUserLearningSubmissionReviewMutationFn = Apollo.MutationFunction<RCreateUserLearningSubmissionReviewMutation, RCreateUserLearningSubmissionReviewMutationVariables>;

/**
 * __useCreateUserLearningSubmissionReviewMutationRemote__
 *
 * To run a mutation, you first call `useCreateUserLearningSubmissionReviewMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLearningSubmissionReviewMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLearningSubmissionReviewMutationRemote, { data, loading, error }] = useCreateUserLearningSubmissionReviewMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLearningSubmissionReviewMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateUserLearningSubmissionReviewMutation, RCreateUserLearningSubmissionReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateUserLearningSubmissionReviewMutation, RCreateUserLearningSubmissionReviewMutationVariables>(CreateUserLearningSubmissionReviewDocument, options);
      }
export type CreateUserLearningSubmissionReviewMutationRemoteHookResult = ReturnType<typeof useCreateUserLearningSubmissionReviewMutationRemote>;
export type CreateUserLearningSubmissionReviewMutationResult = Apollo.MutationResult<RCreateUserLearningSubmissionReviewMutation>;
export type CreateUserLearningSubmissionReviewMutationOptions = Apollo.BaseMutationOptions<RCreateUserLearningSubmissionReviewMutation, RCreateUserLearningSubmissionReviewMutationVariables>;
export const ReportingPracticalAssessmentInsightsDocument = gql`
    query reportingPracticalAssessmentInsights($where: reportingPracticalAssessmentInsights_bool_exp) {
  reportingPracticalAssessmentInsights(where: $where) {
    learningContentURN
    learningJourneyURN
    pageId
    pageURN
    responsesCount
    reviewsCount
  }
}
    `;

/**
 * __useReportingPracticalAssessmentInsightsQueryRemote__
 *
 * To run a query within a React component, call `useReportingPracticalAssessmentInsightsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useReportingPracticalAssessmentInsightsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingPracticalAssessmentInsightsQueryRemote({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportingPracticalAssessmentInsightsQueryRemote(baseOptions?: Apollo.QueryHookOptions<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>(ReportingPracticalAssessmentInsightsDocument, options);
      }
export function useReportingPracticalAssessmentInsightsLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>(ReportingPracticalAssessmentInsightsDocument, options);
        }
export type ReportingPracticalAssessmentInsightsQueryRemoteHookResult = ReturnType<typeof useReportingPracticalAssessmentInsightsQueryRemote>;
export type ReportingPracticalAssessmentInsightsLazyQueryRemoteHookResult = ReturnType<typeof useReportingPracticalAssessmentInsightsLazyQueryRemote>;
export type ReportingPracticalAssessmentInsightsQueryResult = Apollo.QueryResult<RReportingPracticalAssessmentInsightsQuery, RReportingPracticalAssessmentInsightsQueryVariables>;
export const CreateLearningJourneyLiveEventDocument = gql`
    mutation createLearningJourneyLiveEvent($input: CreateLearningJourneyLiveEventInput!) {
  createLearningJourneyLiveEvent(input: $input) {
    id
    title
    type
    urn
    effort
    image
  }
}
    `;
export type RCreateLearningJourneyLiveEventMutationFn = Apollo.MutationFunction<RCreateLearningJourneyLiveEventMutation, RCreateLearningJourneyLiveEventMutationVariables>;

/**
 * __useCreateLearningJourneyLiveEventMutationRemote__
 *
 * To run a mutation, you first call `useCreateLearningJourneyLiveEventMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningJourneyLiveEventMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningJourneyLiveEventMutationRemote, { data, loading, error }] = useCreateLearningJourneyLiveEventMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLearningJourneyLiveEventMutationRemote(baseOptions?: Apollo.MutationHookOptions<RCreateLearningJourneyLiveEventMutation, RCreateLearningJourneyLiveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RCreateLearningJourneyLiveEventMutation, RCreateLearningJourneyLiveEventMutationVariables>(CreateLearningJourneyLiveEventDocument, options);
      }
export type CreateLearningJourneyLiveEventMutationRemoteHookResult = ReturnType<typeof useCreateLearningJourneyLiveEventMutationRemote>;
export type CreateLearningJourneyLiveEventMutationResult = Apollo.MutationResult<RCreateLearningJourneyLiveEventMutation>;
export type CreateLearningJourneyLiveEventMutationOptions = Apollo.BaseMutationOptions<RCreateLearningJourneyLiveEventMutation, RCreateLearningJourneyLiveEventMutationVariables>;
export const SearchLearningJourneyContentDocument = gql`
    query searchLearningJourneyContent($filter: SearchLearningContentFilter!, $pagination: Pagination!, $spaceIds: [Int!]) {
  searchLearningContent(filter: $filter, pagination: $pagination) {
    data {
      ...LearningContent
    }
    limit
    page
  }
}
    ${LearningContentFragmentDoc}`;

/**
 * __useSearchLearningJourneyContentQueryRemote__
 *
 * To run a query within a React component, call `useSearchLearningJourneyContentQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSearchLearningJourneyContentQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLearningJourneyContentQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useSearchLearningJourneyContentQueryRemote(baseOptions: Apollo.QueryHookOptions<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>(SearchLearningJourneyContentDocument, options);
      }
export function useSearchLearningJourneyContentLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>(SearchLearningJourneyContentDocument, options);
        }
export type SearchLearningJourneyContentQueryRemoteHookResult = ReturnType<typeof useSearchLearningJourneyContentQueryRemote>;
export type SearchLearningJourneyContentLazyQueryRemoteHookResult = ReturnType<typeof useSearchLearningJourneyContentLazyQueryRemote>;
export type SearchLearningJourneyContentQueryResult = Apollo.QueryResult<RSearchLearningJourneyContentQuery, RSearchLearningJourneyContentQueryVariables>;
export const LearningByIdDocument = gql`
    query learningById($id: ID!, $includeProgress: Boolean!, $includeLocations: Boolean!) {
  learning(id: $id) {
    ...LearningFragment
    modules {
      ...LearningModulesDeepFragment
    }
    progress @include(if: $includeProgress) {
      ...LearningProgressFragment
    }
    locations @include(if: $includeLocations) {
      ...LocationsFragment
    }
  }
}
    ${LearningFragmentFragmentDoc}
${LearningModulesDeepFragmentFragmentDoc}
${LearningProgressFragmentFragmentDoc}
${LocationsFragmentFragmentDoc}`;

/**
 * __useLearningByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      includeProgress: // value for 'includeProgress'
 *      includeLocations: // value for 'includeLocations'
 *   },
 * });
 */
export function useLearningByIdQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningByIdQuery, RLearningByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningByIdQuery, RLearningByIdQueryVariables>(LearningByIdDocument, options);
      }
export function useLearningByIdLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningByIdQuery, RLearningByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningByIdQuery, RLearningByIdQueryVariables>(LearningByIdDocument, options);
        }
export type LearningByIdQueryRemoteHookResult = ReturnType<typeof useLearningByIdQueryRemote>;
export type LearningByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningByIdLazyQueryRemote>;
export type LearningByIdQueryResult = Apollo.QueryResult<RLearningByIdQuery, RLearningByIdQueryVariables>;
export const LearningV2ByIdDocument = gql`
    query learningV2ById($id: Int!, $userId: Int!) {
  learningV2(id: $id) {
    ...LearningV2Fragment
    activities {
      ...ActivityFragment
    }
    userLastParticipation(userId: $userId) {
      id
      expiryAt
      isCompleted
    }
  }
}
    ${LearningV2FragmentFragmentDoc}
${ActivityFragmentFragmentDoc}`;

/**
 * __useLearningV2ByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningV2ByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningV2ByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningV2ByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLearningV2ByIdQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>(LearningV2ByIdDocument, options);
      }
export function useLearningV2ByIdLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>(LearningV2ByIdDocument, options);
        }
export type LearningV2ByIdQueryRemoteHookResult = ReturnType<typeof useLearningV2ByIdQueryRemote>;
export type LearningV2ByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningV2ByIdLazyQueryRemote>;
export type LearningV2ByIdQueryResult = Apollo.QueryResult<RLearningV2ByIdQuery, RLearningV2ByIdQueryVariables>;
export const SaveLearningDocument = gql`
    mutation saveLearning($input: LearningInput!) {
  saveLearning(payload: $input) {
    id
    title
  }
}
    `;
export type RSaveLearningMutationFn = Apollo.MutationFunction<RSaveLearningMutation, RSaveLearningMutationVariables>;

/**
 * __useSaveLearningMutationRemote__
 *
 * To run a mutation, you first call `useSaveLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLearningMutationRemote, { data, loading, error }] = useSaveLearningMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLearningMutationRemote(baseOptions?: Apollo.MutationHookOptions<RSaveLearningMutation, RSaveLearningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RSaveLearningMutation, RSaveLearningMutationVariables>(SaveLearningDocument, options);
      }
export type SaveLearningMutationRemoteHookResult = ReturnType<typeof useSaveLearningMutationRemote>;
export type SaveLearningMutationResult = Apollo.MutationResult<RSaveLearningMutation>;
export type SaveLearningMutationOptions = Apollo.BaseMutationOptions<RSaveLearningMutation, RSaveLearningMutationVariables>;
export const UpsertLearningDocument = gql`
    mutation upsertLearning($input: UpsertLearningInput!) {
  upsertLearning(input: $input) {
    id
    title
  }
}
    `;
export type RUpsertLearningMutationFn = Apollo.MutationFunction<RUpsertLearningMutation, RUpsertLearningMutationVariables>;

/**
 * __useUpsertLearningMutationRemote__
 *
 * To run a mutation, you first call `useUpsertLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLearningMutationRemote, { data, loading, error }] = useUpsertLearningMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLearningMutationRemote(baseOptions?: Apollo.MutationHookOptions<RUpsertLearningMutation, RUpsertLearningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RUpsertLearningMutation, RUpsertLearningMutationVariables>(UpsertLearningDocument, options);
      }
export type UpsertLearningMutationRemoteHookResult = ReturnType<typeof useUpsertLearningMutationRemote>;
export type UpsertLearningMutationResult = Apollo.MutationResult<RUpsertLearningMutation>;
export type UpsertLearningMutationOptions = Apollo.BaseMutationOptions<RUpsertLearningMutation, RUpsertLearningMutationVariables>;
export const DeleteLearningDocument = gql`
    mutation deleteLearning($learningId: Int!) {
  deleteLearning(learningId: $learningId) {
    id
  }
}
    `;
export type RDeleteLearningMutationFn = Apollo.MutationFunction<RDeleteLearningMutation, RDeleteLearningMutationVariables>;

/**
 * __useDeleteLearningMutationRemote__
 *
 * To run a mutation, you first call `useDeleteLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningMutationRemote, { data, loading, error }] = useDeleteLearningMutationRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useDeleteLearningMutationRemote(baseOptions?: Apollo.MutationHookOptions<RDeleteLearningMutation, RDeleteLearningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RDeleteLearningMutation, RDeleteLearningMutationVariables>(DeleteLearningDocument, options);
      }
export type DeleteLearningMutationRemoteHookResult = ReturnType<typeof useDeleteLearningMutationRemote>;
export type DeleteLearningMutationResult = Apollo.MutationResult<RDeleteLearningMutation>;
export type DeleteLearningMutationOptions = Apollo.BaseMutationOptions<RDeleteLearningMutation, RDeleteLearningMutationVariables>;
export const LearningTypeByIdDocument = gql`
    query learningTypeById($id: ID!) {
  learning(id: $id) {
    id
    type
  }
}
    `;

/**
 * __useLearningTypeByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningTypeByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningTypeByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningTypeByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningTypeByIdQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>(LearningTypeByIdDocument, options);
      }
export function useLearningTypeByIdLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>(LearningTypeByIdDocument, options);
        }
export type LearningTypeByIdQueryRemoteHookResult = ReturnType<typeof useLearningTypeByIdQueryRemote>;
export type LearningTypeByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningTypeByIdLazyQueryRemote>;
export type LearningTypeByIdQueryResult = Apollo.QueryResult<RLearningTypeByIdQuery, RLearningTypeByIdQueryVariables>;
export const LearningParentsByIdDocument = gql`
    query learningParentsById($id: ID!, $filter: LearningParentsFilter!, $userId: Int!) {
  learning(id: $id) {
    id
    urnV2
    parents(filter: $filter) {
      id
      title
      userLastParticipation(userId: $userId) {
        id
      }
    }
  }
}
    `;

/**
 * __useLearningParentsByIdQueryRemote__
 *
 * To run a query within a React component, call `useLearningParentsByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useLearningParentsByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningParentsByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLearningParentsByIdQueryRemote(baseOptions: Apollo.QueryHookOptions<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>(LearningParentsByIdDocument, options);
      }
export function useLearningParentsByIdLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>(LearningParentsByIdDocument, options);
        }
export type LearningParentsByIdQueryRemoteHookResult = ReturnType<typeof useLearningParentsByIdQueryRemote>;
export type LearningParentsByIdLazyQueryRemoteHookResult = ReturnType<typeof useLearningParentsByIdLazyQueryRemote>;
export type LearningParentsByIdQueryResult = Apollo.QueryResult<RLearningParentsByIdQuery, RLearningParentsByIdQueryVariables>;
export const SearchLearningContentDocument = gql`
    query searchLearningContent($filter: SearchLearningContentFilter!, $pagination: Pagination!, $spaceIds: [Int!]!) {
  searchLearningContent(filter: $filter, pagination: $pagination) {
    data {
      ...LearningContent
    }
    limit
    page
    total
  }
}
    ${LearningContentFragmentDoc}`;

/**
 * __useSearchLearningContentQueryRemote__
 *
 * To run a query within a React component, call `useSearchLearningContentQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSearchLearningContentQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLearningContentQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      spaceIds: // value for 'spaceIds'
 *   },
 * });
 */
export function useSearchLearningContentQueryRemote(baseOptions: Apollo.QueryHookOptions<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>(SearchLearningContentDocument, options);
      }
export function useSearchLearningContentLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>(SearchLearningContentDocument, options);
        }
export type SearchLearningContentQueryRemoteHookResult = ReturnType<typeof useSearchLearningContentQueryRemote>;
export type SearchLearningContentLazyQueryRemoteHookResult = ReturnType<typeof useSearchLearningContentLazyQueryRemote>;
export type SearchLearningContentQueryResult = Apollo.QueryResult<RSearchLearningContentQuery, RSearchLearningContentQueryVariables>;
export const ImportLearningContentToSpaceDocument = gql`
    mutation importLearningContentToSpace($input: ImportLearningContentToSpaceInput!) {
  importLearningContentToSpace(input: $input) {
    success
    content {
      id
      title
    }
    failedIds
  }
}
    `;
export type RImportLearningContentToSpaceMutationFn = Apollo.MutationFunction<RImportLearningContentToSpaceMutation, RImportLearningContentToSpaceMutationVariables>;

/**
 * __useImportLearningContentToSpaceMutationRemote__
 *
 * To run a mutation, you first call `useImportLearningContentToSpaceMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportLearningContentToSpaceMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importLearningContentToSpaceMutationRemote, { data, loading, error }] = useImportLearningContentToSpaceMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportLearningContentToSpaceMutationRemote(baseOptions?: Apollo.MutationHookOptions<RImportLearningContentToSpaceMutation, RImportLearningContentToSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RImportLearningContentToSpaceMutation, RImportLearningContentToSpaceMutationVariables>(ImportLearningContentToSpaceDocument, options);
      }
export type ImportLearningContentToSpaceMutationRemoteHookResult = ReturnType<typeof useImportLearningContentToSpaceMutationRemote>;
export type ImportLearningContentToSpaceMutationResult = Apollo.MutationResult<RImportLearningContentToSpaceMutation>;
export type ImportLearningContentToSpaceMutationOptions = Apollo.BaseMutationOptions<RImportLearningContentToSpaceMutation, RImportLearningContentToSpaceMutationVariables>;
export const SubmitLearningActivityQuizAnswersDocument = gql`
    mutation submitLearningActivityQuizAnswers($input: UserLearningActivityQuizSubmissionInput!) {
  submitLearningActivityQuizAnswers(input: $input) {
    id
  }
}
    `;
export type RSubmitLearningActivityQuizAnswersMutationFn = Apollo.MutationFunction<RSubmitLearningActivityQuizAnswersMutation, RSubmitLearningActivityQuizAnswersMutationVariables>;

/**
 * __useSubmitLearningActivityQuizAnswersMutationRemote__
 *
 * To run a mutation, you first call `useSubmitLearningActivityQuizAnswersMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLearningActivityQuizAnswersMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLearningActivityQuizAnswersMutationRemote, { data, loading, error }] = useSubmitLearningActivityQuizAnswersMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitLearningActivityQuizAnswersMutationRemote(baseOptions?: Apollo.MutationHookOptions<RSubmitLearningActivityQuizAnswersMutation, RSubmitLearningActivityQuizAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RSubmitLearningActivityQuizAnswersMutation, RSubmitLearningActivityQuizAnswersMutationVariables>(SubmitLearningActivityQuizAnswersDocument, options);
      }
export type SubmitLearningActivityQuizAnswersMutationRemoteHookResult = ReturnType<typeof useSubmitLearningActivityQuizAnswersMutationRemote>;
export type SubmitLearningActivityQuizAnswersMutationResult = Apollo.MutationResult<RSubmitLearningActivityQuizAnswersMutation>;
export type SubmitLearningActivityQuizAnswersMutationOptions = Apollo.BaseMutationOptions<RSubmitLearningActivityQuizAnswersMutation, RSubmitLearningActivityQuizAnswersMutationVariables>;
export const MyLearningActivityQuizSubmissionsDocument = gql`
    query myLearningActivityQuizSubmissions($input: UserLearningActivityQuizSubmissionFilterInput!) {
  myLearningActivityQuizSubmissions(input: $input) {
    answers
    id
    score
  }
}
    `;

/**
 * __useMyLearningActivityQuizSubmissionsQueryRemote__
 *
 * To run a query within a React component, call `useMyLearningActivityQuizSubmissionsQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useMyLearningActivityQuizSubmissionsQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearningActivityQuizSubmissionsQueryRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyLearningActivityQuizSubmissionsQueryRemote(baseOptions: Apollo.QueryHookOptions<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>(MyLearningActivityQuizSubmissionsDocument, options);
      }
export function useMyLearningActivityQuizSubmissionsLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>(MyLearningActivityQuizSubmissionsDocument, options);
        }
export type MyLearningActivityQuizSubmissionsQueryRemoteHookResult = ReturnType<typeof useMyLearningActivityQuizSubmissionsQueryRemote>;
export type MyLearningActivityQuizSubmissionsLazyQueryRemoteHookResult = ReturnType<typeof useMyLearningActivityQuizSubmissionsLazyQueryRemote>;
export type MyLearningActivityQuizSubmissionsQueryResult = Apollo.QueryResult<RMyLearningActivityQuizSubmissionsQuery, RMyLearningActivityQuizSubmissionsQueryVariables>;
export const GetLearningXapiSessionDocument = gql`
    query getLearningXAPISession($learningId: Int!) {
  getXAPISession(learningId: $learningId) {
    queryString
    registration
  }
}
    `;

/**
 * __useGetLearningXapiSessionQueryRemote__
 *
 * To run a query within a React component, call `useGetLearningXapiSessionQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningXapiSessionQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningXapiSessionQueryRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useGetLearningXapiSessionQueryRemote(baseOptions: Apollo.QueryHookOptions<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>(GetLearningXapiSessionDocument, options);
      }
export function useGetLearningXapiSessionLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>(GetLearningXapiSessionDocument, options);
        }
export type GetLearningXapiSessionQueryRemoteHookResult = ReturnType<typeof useGetLearningXapiSessionQueryRemote>;
export type GetLearningXapiSessionLazyQueryRemoteHookResult = ReturnType<typeof useGetLearningXapiSessionLazyQueryRemote>;
export type GetLearningXapiSessionQueryResult = Apollo.QueryResult<RGetLearningXapiSessionQuery, RGetLearningXapiSessionQueryVariables>;
export const RegisterToLearningDocument = gql`
    mutation registerToLearning($learningId: Int!) {
  registerToLearning(learningId: $learningId) {
    queryString
    registration
  }
}
    `;
export type RRegisterToLearningMutationFn = Apollo.MutationFunction<RRegisterToLearningMutation, RRegisterToLearningMutationVariables>;

/**
 * __useRegisterToLearningMutationRemote__
 *
 * To run a mutation, you first call `useRegisterToLearningMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterToLearningMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerToLearningMutationRemote, { data, loading, error }] = useRegisterToLearningMutationRemote({
 *   variables: {
 *      learningId: // value for 'learningId'
 *   },
 * });
 */
export function useRegisterToLearningMutationRemote(baseOptions?: Apollo.MutationHookOptions<RRegisterToLearningMutation, RRegisterToLearningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RRegisterToLearningMutation, RRegisterToLearningMutationVariables>(RegisterToLearningDocument, options);
      }
export type RegisterToLearningMutationRemoteHookResult = ReturnType<typeof useRegisterToLearningMutationRemote>;
export type RegisterToLearningMutationResult = Apollo.MutationResult<RRegisterToLearningMutation>;
export type RegisterToLearningMutationOptions = Apollo.BaseMutationOptions<RRegisterToLearningMutation, RRegisterToLearningMutationVariables>;
export const ProductParentsByIdDocument = gql`
    query productParentsById($id: Int!, $filter: ProductParentsFilter!, $userId: Int!) {
  getProduct(id: $id) {
    id
    learningContentUrnV2
    parents(filter: $filter) {
      id
      title
      userLastParticipation(userId: $userId) {
        id
      }
    }
  }
}
    `;

/**
 * __useProductParentsByIdQueryRemote__
 *
 * To run a query within a React component, call `useProductParentsByIdQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useProductParentsByIdQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductParentsByIdQueryRemote({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProductParentsByIdQueryRemote(baseOptions: Apollo.QueryHookOptions<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>(ProductParentsByIdDocument, options);
      }
export function useProductParentsByIdLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>(ProductParentsByIdDocument, options);
        }
export type ProductParentsByIdQueryRemoteHookResult = ReturnType<typeof useProductParentsByIdQueryRemote>;
export type ProductParentsByIdLazyQueryRemoteHookResult = ReturnType<typeof useProductParentsByIdLazyQueryRemote>;
export type ProductParentsByIdQueryResult = Apollo.QueryResult<RProductParentsByIdQuery, RProductParentsByIdQueryVariables>;
export const EffortForRealmDocument = gql`
    query effortForRealm($realm: String!, $fromTime: timestamptz!, $toTime: timestamptz!) {
  reportingAggregationEffortForRealm(
    where: {realm: {_eq: $realm}, fromTime: {_gte: $fromTime}, toTime: {_gte: $toTime}}
    limit: 1
  ) {
    totalEffort
    averageEffort
    p90Effort
    p80Effort
    p70Effort
    p60Effort
    p50Effort
    fromTime
    toTime
  }
}
    `;

/**
 * __useEffortForRealmQueryRemote__
 *
 * To run a query within a React component, call `useEffortForRealmQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useEffortForRealmQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffortForRealmQueryRemote({
 *   variables: {
 *      realm: // value for 'realm'
 *      fromTime: // value for 'fromTime'
 *      toTime: // value for 'toTime'
 *   },
 * });
 */
export function useEffortForRealmQueryRemote(baseOptions: Apollo.QueryHookOptions<REffortForRealmQuery, REffortForRealmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<REffortForRealmQuery, REffortForRealmQueryVariables>(EffortForRealmDocument, options);
      }
export function useEffortForRealmLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<REffortForRealmQuery, REffortForRealmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<REffortForRealmQuery, REffortForRealmQueryVariables>(EffortForRealmDocument, options);
        }
export type EffortForRealmQueryRemoteHookResult = ReturnType<typeof useEffortForRealmQueryRemote>;
export type EffortForRealmLazyQueryRemoteHookResult = ReturnType<typeof useEffortForRealmLazyQueryRemote>;
export type EffortForRealmQueryResult = Apollo.QueryResult<REffortForRealmQuery, REffortForRealmQueryVariables>;
export const SpaceMembersDocument = gql`
    query spaceMembers($filter: SpaceMemberFilter!, $pagination: Pagination!) {
  spaceMembers(filter: $filter, pagination: $pagination) {
    data {
      id
      email
      realm
      spaceId
      firstname
      lastname
      username
      profileImage
    }
  }
}
    `;

/**
 * __useSpaceMembersQueryRemote__
 *
 * To run a query within a React component, call `useSpaceMembersQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSpaceMembersQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceMembersQueryRemote({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSpaceMembersQueryRemote(baseOptions: Apollo.QueryHookOptions<RSpaceMembersQuery, RSpaceMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RSpaceMembersQuery, RSpaceMembersQueryVariables>(SpaceMembersDocument, options);
      }
export function useSpaceMembersLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RSpaceMembersQuery, RSpaceMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RSpaceMembersQuery, RSpaceMembersQueryVariables>(SpaceMembersDocument, options);
        }
export type SpaceMembersQueryRemoteHookResult = ReturnType<typeof useSpaceMembersQueryRemote>;
export type SpaceMembersLazyQueryRemoteHookResult = ReturnType<typeof useSpaceMembersLazyQueryRemote>;
export type SpaceMembersQueryResult = Apollo.QueryResult<RSpaceMembersQuery, RSpaceMembersQueryVariables>;
export const SpacesDocument = gql`
    query spaces($withPermissions: Boolean!, $withContentOrigins: Boolean!) {
  spaces(pagination: {limit: -1}) {
    data {
      id
      realm
      label
      name
      permissions @include(if: $withPermissions) {
        hasWriteAccessToLearningContent
      }
      contentOrigins(pagination: {limit: -1}) @include(if: $withContentOrigins) {
        data {
          id
          provider {
            id
            type
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSpacesQueryRemote__
 *
 * To run a query within a React component, call `useSpacesQueryRemote` and pass it any options that fit your needs.
 * When your component renders, `useSpacesQueryRemote` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpacesQueryRemote({
 *   variables: {
 *      withPermissions: // value for 'withPermissions'
 *      withContentOrigins: // value for 'withContentOrigins'
 *   },
 * });
 */
export function useSpacesQueryRemote(baseOptions: Apollo.QueryHookOptions<RSpacesQuery, RSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RSpacesQuery, RSpacesQueryVariables>(SpacesDocument, options);
      }
export function useSpacesLazyQueryRemote(baseOptions?: Apollo.LazyQueryHookOptions<RSpacesQuery, RSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RSpacesQuery, RSpacesQueryVariables>(SpacesDocument, options);
        }
export type SpacesQueryRemoteHookResult = ReturnType<typeof useSpacesQueryRemote>;
export type SpacesLazyQueryRemoteHookResult = ReturnType<typeof useSpacesLazyQueryRemote>;
export type SpacesQueryResult = Apollo.QueryResult<RSpacesQuery, RSpacesQueryVariables>;
export const UpdateUsersDocument = gql`
    mutation updateUsers($input: UserChangeInput!) {
  updateUser(input: $input) {
    id
  }
}
    `;
export type RUpdateUsersMutationFn = Apollo.MutationFunction<RUpdateUsersMutation, RUpdateUsersMutationVariables>;

/**
 * __useUpdateUsersMutationRemote__
 *
 * To run a mutation, you first call `useUpdateUsersMutationRemote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersMutationRemote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersMutationRemote, { data, loading, error }] = useUpdateUsersMutationRemote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsersMutationRemote(baseOptions?: Apollo.MutationHookOptions<RUpdateUsersMutation, RUpdateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RUpdateUsersMutation, RUpdateUsersMutationVariables>(UpdateUsersDocument, options);
      }
export type UpdateUsersMutationRemoteHookResult = ReturnType<typeof useUpdateUsersMutationRemote>;
export type UpdateUsersMutationResult = Apollo.MutationResult<RUpdateUsersMutation>;
export type UpdateUsersMutationOptions = Apollo.BaseMutationOptions<RUpdateUsersMutation, RUpdateUsersMutationVariables>;