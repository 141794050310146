import React, { createRef } from 'react';

import { Button } from '@/ui/Button';
import { getMeta } from './helpers';
import { DocumentUploadButtonProps } from './types';

export const DocumentVideoUploadButton = ({
  uploadFile,
  onClick,
  fileType,
  accept: customAccept,
  ...buttonProps
}: DocumentUploadButtonProps): JSX.Element => {
  const fileInput = createRef<HTMLInputElement>();
  const { accept } = getMeta(fileType);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (fileInput.current) fileInput.current.click();
    if (onClick) onClick(e);
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleClick} />

      <input
        type="file"
        accept={customAccept || accept}
        onChange={(event) => {
          const selectedFile = event.target.files?.[0];
          if (selectedFile) uploadFile(selectedFile);
        }}
        ref={fileInput}
        style={{ display: 'none' }}
      />
    </>
  );
};
