import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button as NewButton } from '@/ui/Button';
import { CatalogItem } from '@/types/learning/learning-catalog';
import { Button } from '@/components/Button/Button';
import { trackSaveToMyLearningsLearningItemAdded, trackSaveToMyLearningsLearningItemRemoved } from '@/utils/tracking/learnings';
import { trackSaveToMyLearningsEventAdded, trackSaveToMyLearningsEventRemoved } from '@/utils/tracking/events';
import { useSavedLearnings, useUpdateSavedLearnings } from '../store';
import { SharedSnackbarContext } from '@/component/SharedSnackbar/SharedSnackbar';

interface Props {
  item: CatalogItem;
  isEvent?: boolean;
  minimized?: boolean;
  saveNotificationLabel?: string;
  deleteNotificationLabel?: string;
}

const SaveButton = styled(NewButton)`
  position: relative;
`;

export const SaveToMyLearning = (props: Props): JSX.Element => {
  const { item } = props;
  const { t } = useTranslation('myLearning');
  const { savedLearnings, loading } = useSavedLearnings();
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { addSavedLearnings, removeSavedLearnings, loading: loadingUpdate } = useUpdateSavedLearnings();

  const isSaved = Boolean(savedLearnings.meta?.find((l) => l.id === item.id && l.entity === item.entity));

  const onSaveClick = useCallback(() => {
    if (isSaved) {
      removeSavedLearnings(savedLearnings, item);
      props.isEvent ? trackSaveToMyLearningsEventRemoved() : trackSaveToMyLearningsLearningItemRemoved();

      if (!!props?.deleteNotificationLabel) {
        openSnackbar({
          message: props?.deleteNotificationLabel,
          isDismissive: true,
        });
      }
    } else {
      addSavedLearnings(savedLearnings, item);
      props.isEvent ? trackSaveToMyLearningsEventAdded() : trackSaveToMyLearningsLearningItemAdded();

      if (!!props?.saveNotificationLabel) {
        openSnackbar({
          message: props?.saveNotificationLabel,
          isDismissive: true,
        });
      }
    }
  }, [item, isSaved, savedLearnings]);

  if (props.minimized) {
    return (
      <SaveButton icon={isSaved ? 'heart' : ['far', 'heart']} title={isSaved ? 'Saved' : 'Save'} onClick={onSaveClick}>
        {isSaved ? 'Saved' : 'Save'}
      </SaveButton>
    );
  }

  return (
    <Button
      $type="secondary"
      $loading={loading || loadingUpdate}
      $icon="heart"
      $iconStyle={{ color: isSaved ? `var(--color-monza)` : undefined }}
      onClick={onSaveClick}
    >
      {t('Save to my learning')}
    </Button>
  );
};
