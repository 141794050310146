import React from 'react';

import { Avatar } from '@/ui/Avatar';
import {
  EmailStyled,
  NameStyled,
  ParticipantContainerStyled,
  ParticipantInfoStyled,
  RootStyled,
  TimeSpentStyled,
} from './styles';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { shortenName } from '@/utils/text';

export type ParticipantProps = {
  name: string;
  imageSrc?: string;
  email: string;
  timeSpent: number;
};

const _Participant = ({ imageSrc, name, email, timeSpent }: ParticipantProps): JSX.Element => {
  const shortName = shortenName(name);

  return (
    <RootStyled>
      <ParticipantContainerStyled>
        <Avatar text={shortName} image={imageSrc} />
        <ParticipantInfoStyled>
          <NameStyled>{name}</NameStyled>
          <EmailStyled>{email}</EmailStyled>
        </ParticipantInfoStyled>
      </ParticipantContainerStyled>
      <TimeSpentStyled>{formatToHoursAndMinutesFromSeconds(timeSpent)}</TimeSpentStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <ParticipantContainerStyled>
      <Avatar text="" />
      <ParticipantInfoStyled style={{ gap: '4px' }}>
        <Skeleton height={20} width={240} />
        <Skeleton height={16} width={120} />
      </ParticipantInfoStyled>
    </ParticipantContainerStyled>
    <Skeleton height={20} width={80} />
  </RootStyled>
);

const Participant = makeNamespacedComponent(_Participant, {
  LoadingState,
});

export { Participant };
