import { getLink } from '@/utils/card';
import { LearningProgress } from '../../components/modals/WeeklySummaryModal/WeeklySummaryTable/cells/types';
import { GetLearningProgressDetailsProps, LearningProgressDetails } from '../types';

export const getLearningProgressDetails: GetLearningProgressDetailsProps = ({
  loops,
  getCard,
  cards,
  selfTrackIds,
  selfTrackedLearnings,
}) =>
  loops.flatMap(
    (x): LearningProgressDetails => ({
      loopId: x.id,
      reflection: x.reflection?.description,
      isCurrentWeek: x.isActive,
      learningProgress: x.activitiesAggregated
        .map((l): LearningProgress | undefined => {
          const card = getCard(l.participation.catalogObjectId)?.find((m) =>
            cards?.some((p) => p.entity === m.entity && p.entityId === m.entityId)
          );
          if (card) {
            return {
              loopId: x.id,
              id: card ? getLink(card) : '',
              category: card?.subjects.length ? card?.subjects[0].category : '',
              subject: card?.subjects.length ? card?.subjects[0].subject : '',
              title: card?.title ?? '',
              plannedDuration: l.participation.plannedDuration ?? 0,
              userEffort: l.participation.userEffort ?? 0,
              completed:
                l?.participation?.isCompleted &&
                !!l?.participation?.completedAt &&
                new Date(l.participation.completedAt) >= new Date(x.startAt) &&
                new Date(l.participation.completedAt) <= new Date(x.endAt),
              canNavigate: true,
            };
          } else {
            const selfTrackId = selfTrackIds.find((x) => x.catalogObjectId === l.participation.catalogObjectId);

            if (!!selfTrackId) {
              const selfTrackLearning = selfTrackedLearnings.find((x) => x.id === selfTrackId.id);

              if (!!selfTrackLearning) {
                return {
                  loopId: x.id,
                  id: selfTrackLearning.id?.toString() ?? '',
                  title: selfTrackLearning?.title ?? '',
                  plannedDuration: l.participation.plannedDuration ?? 0,
                  userEffort: l.participation.userEffort ?? 0,
                  completed:
                    l?.participation?.isCompleted &&
                    !!l?.participation?.completedAt &&
                    new Date(l.participation.completedAt) >= new Date(x.startAt) &&
                    new Date(l.participation.completedAt) <= new Date(x.endAt),
                };
              }
            }
          }
        })
        .filter((x): x is LearningProgress => !!x),
    })
  );
