import React, { useContext } from 'react';

import { HeaderStyled } from './styles';
import { SelectContext } from '../SelectContext';
import { HeaderProps } from './types';

export const Header = ({ children, className, fullWidth }: HeaderProps): JSX.Element => {
  const { toggleShowOptions, shouldShowOptions } = useContext(SelectContext);

  return (
    <HeaderStyled className={className} fullWidth={fullWidth}>
      {children({
        toggleShowOptions,
        shouldShowOptions,
      })}
    </HeaderStyled>
  );
};
