import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { CircularProgressBar } from '@/ui/ProgressBar';
import {
  ActionsContainerStyled,
  AddLearningTimeBox,
  CurrentEffortStyled,
  EffortDescriptionBoxStyled,
  ExpectedEffortStyled,
  InnerCircleContentStyled,
  RootStyled,
  ViewDetailsButtonStyled,
} from './styles';
import { IconButton } from '@/ui/Button';
import { Tooltip } from '@/ui/Tooltip';
import { ChartProps } from './types';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';

const _WeekChart = ({
  currentEffort,
  expectedEffort,
  onAddLearningTimeClick,
  actions,
  onViewDetailsClick,
}: ChartProps): JSX.Element => {
  const theme = useTheme();

  return (
    <RootStyled>
      <CircularProgressBar
        size={250}
        strokeWidth={25}
        value={currentEffort}
        maxValue={expectedEffort}
        gap={30}
        rounded
        customTrackBgColor={theme.colors.states.hover8}
      >
        <InnerCircleContentStyled>
          <EffortDescriptionBoxStyled>
            <CurrentEffortStyled>{formatToHoursAndMinutesFromSeconds(currentEffort)}</CurrentEffortStyled>
            <ExpectedEffortStyled>of {formatToHoursAndMinutesFromSeconds(expectedEffort)} weekly goal</ExpectedEffortStyled>
            <ViewDetailsButtonStyled variant="link" onClick={onViewDetailsClick}>
              View details
            </ViewDetailsButtonStyled>
          </EffortDescriptionBoxStyled>
          {onAddLearningTimeClick && (
            <AddLearningTimeBox>
              <Tooltip title="Add learning time">
                <IconButton buttonVariant="dark" icon="plus" onClick={onAddLearningTimeClick} />
              </Tooltip>
            </AddLearningTimeBox>
          )}
        </InnerCircleContentStyled>
      </CircularProgressBar>
      {actions && <ActionsContainerStyled>{actions}</ActionsContainerStyled>}
    </RootStyled>
  );
};

const LoadingState = ({ actions }: { actions?: ReactNode }): JSX.Element => {
  return (
    <RootStyled>
      <CircularProgressBar size={250} strokeWidth={25} value={0} gap={30} rounded>
        <InnerCircleContentStyled>
          <Skeleton width={84} height={36} />
        </InnerCircleContentStyled>
      </CircularProgressBar>
      {actions && <ActionsContainerStyled>{actions}</ActionsContainerStyled>}
    </RootStyled>
  );
};

const WeekChart = makeNamespacedComponent(_WeekChart, {
  LoadingState,
});

export { WeekChart };
