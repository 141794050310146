import styled, { css } from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { deviceUp } from '@/styles/utils';

interface Props {
  open?: boolean;
  align?: 'right' | 'center';
  border?: boolean;
  borderRounded?: 'bottom' | 'all';
  carrot?: 'none' | 'top';
  width?: string;
  maxWidth?: string;
}

const ALIGN_STYLES = {
  right: css`
    right: 0;
  `,
  center: css`
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  `
} as const;

const BORDER_ROUNDED_STYLES = {
  bottom: css`
    border-radius: 0 0 ${cvar('radius-inner-with-border')} ${cvar('radius-inner-with-border')};
  `,
  all: css`
    border-radius: ${cvar('radius-inner-with-border')};
  `
} as const;

const CARROT_STYLES = {
  top: css`
    top: calc(100% - ${cvar('border-width')} + 10px);
    overflow-y: visible;
    &::before {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid ${cvar('color-white')};
    }
    &::after {
      content: '';
      height: 10px;
      width: 10px;
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      border-top: 1px solid ${cvar('color-alto')};
      border-left: 1px solid ${cvar('color-alto')};
    }
  `,
  none: css``
} as const;

export const StyledDropdown = styled.div<Props>`
  background-color: ${cvar('color-white')};
  box-shadow: ${cvar('box-shadow-large')};
  height: ${({ open }) => (open ? 'auto' : 0)};
  max-height: calc(100vh - ${cvar('navbar-height-mobile')} - (${cvar('gap-sm')} * 2));
  min-width: 15ch;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: calc(100% - ${cvar('border-width')});
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  z-index: 40;

  ${({ align }) => ALIGN_STYLES[align || 'right']}
  ${({ border }) => border && `border: 1px solid ${cvar('color-alto')};`}
  ${({ borderRounded }) => BORDER_ROUNDED_STYLES[borderRounded || 'bottom']}
  ${({ carrot }) => CARROT_STYLES[carrot || 'none']}
  ${({ width }) => `width: ${width || '100%'};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  ${deviceUp.md`
    max-height: 600px;
  `}
`;

export const ModalStyledDropdown = styled(StyledDropdown)`
  top: 9.7rem;
`;
