import { useEffect, useRef, useState, RefObject } from 'react';

export const useCourseIframeNavigationListener = (): { iframeHeight: string; courseIframe: RefObject<HTMLIFrameElement> } => {
  const [iframeHeight, setIframeHeight] = useState('100vh');
  const courseIframe = useRef<HTMLIFrameElement>(null);
  const initialLoad = useRef(true);

  useEffect((): (() => void) => {
    const onMessage = (event: MessageEvent) => {
      if (!courseIframe?.current) {
        return;
      }

      const childWindow = courseIframe.current && courseIframe.current.contentWindow;
      if (event.source !== childWindow || event.data[0] !== 'learnifier:page:navigation') return;
      if (initialLoad.current) {
        initialLoad.current = false;
        return;
      }

      setIframeHeight(`${event.data[1].height}px`);

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const iframeRelativeToDocumentTop = courseIframe.current.getBoundingClientRect().top + scrollTop;
      window.scrollTo(0, iframeRelativeToDocumentTop);
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [courseIframe]);

  return {
    iframeHeight,
    courseIframe
  };
};
