import React from 'react';

import { CourseCard } from '@/components/CourseCard';
import { CourseCardData } from '@/store/inventory';
import { ProgressCard } from '@/types/learning/card';
import { numberOfCards } from './constants';
import { CampusSectionDetails } from './types';
import { sortStringOrder } from '@/store/utils';
import { trackHomepageLearningCardClicked } from '@/utils/tracking/homePage';

export const getOngoingCards = (
  ongoingCatalogCards: ProgressCard[],
  ongoingInventoryCards: CourseCardData[]
): CampusSectionDetails => {
  const allOngoingCatalog = ongoingCatalogCards.map((c) => {
    return {
      title: c.title,
      card: <CourseCard card={c} key={c.id} onClick={() => trackHomepageLearningCardClicked(c.title, 'continue-learning')} />,
    };
  });
  const allOngoingInventory = ongoingInventoryCards.map(({ card }) => {
    return {
      title: card.title,
      card: (
        <CourseCard key={card.id} card={card} onClick={() => trackHomepageLearningCardClicked(card.title, 'continue-learning')} />
      ),
    };
  });

  const allOngoing = allOngoingCatalog
    .concat(allOngoingInventory)
    .sort((m, o) => sortStringOrder(m.title, o.title))
    .map((m) => m.card);

  return { count: allOngoing.length, cards: allOngoing.slice(numberOfCards.startIndex, numberOfCards.endIndex) };
};
