/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { RootStyled } from '../styles';
import { WeeklyLearningLoop } from '../../types';
import { WeeksPagination, WeeksTabNavigation, LoadingState } from '../../atoms';
import { useWeekNavigationsContext } from '../../context';

type EndedWeeksSetupProps = {
  weeks: WeeklyLearningLoop[];
  selectedWeek: WeeklyLearningLoop | null;
  maxVisibleWeeksCount?: number;
  onChangeSelectedWeek: (weekNumber: number) => void;
};

const _EndedWeeksSetup = ({
  weeks,
  maxVisibleWeeksCount = 4,
  selectedWeek,
  onChangeSelectedWeek,
}: EndedWeeksSetupProps): JSX.Element => {
  const { setWeeks, setMaxVisibleWeeksCount, setSelectedWeek, triggeredPagination, shownWeeks } = useWeekNavigationsContext();

  useEffect(() => {
    setWeeks(weeks);
    setMaxVisibleWeeksCount(maxVisibleWeeksCount);
    setSelectedWeek(selectedWeek);
  }, [weeks, maxVisibleWeeksCount, selectedWeek]);

  // Whether currently selected week is an element of shown weeks list
  const isSelectedWeekVisible = shownWeeks.map(({ id }) => id).includes(selectedWeek?.id || '');

  if (!triggeredPagination && !isSelectedWeekVisible) {
    return <LoadingState />;
  }

  return (
    <RootStyled>
      <WeeksPagination>
        <WeeksTabNavigation onChangeSelectedWeek={onChangeSelectedWeek} showProgressLabel showSelectedStyling={false} />
      </WeeksPagination>
    </RootStyled>
  );
};

const EndedWeeksSetup = makeNamespacedComponent(_EndedWeeksSetup, {
  LoadingState,
});

export { EndedWeeksSetup };
