import { useParams } from 'react-router-dom';

import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import {
  LockedLearningWarningModal,
  useLockedLearningWarningModalProps,
} from '@/administration/pages/Journey/common/modals/LockedLearningWarningModal';
import { SurveyDetails } from './SurveyDetails';
import { SurveyStart } from './SurveyStart';
import { useSurveyViewer } from './store/surveyViewer/useSurveyViewer';

export const SurveyPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const parsedSurveyId = id ? parseInt(id) : NaN;

  const props = useSurveyViewer(Number(id));

  const { currentItem, journeyTitle, journeyId } = useJourneyEnhancedNavigation({
    id: parsedSurveyId,
  });

  const learningLockedModalProps = useLockedLearningWarningModalProps({
    parentJourneyId: journeyId,
    parentJourneyTitle: journeyTitle,
    isCurrentItemLock: currentItem?.isLocked,
  });

  return (
    <>
      {learningLockedModalProps && <LockedLearningWarningModal {...learningLockedModalProps} />}
      {props.progress?.isStarted ? <SurveyDetails {...props} /> : <SurveyStart {...props} />}
    </>
  );
};
