import { useEffect } from 'react';

export const useLockModalOverlay = (showModals: boolean[]): void => {
  const { documentElement: htmlTag } = document;
  const navbarTag = document.getElementById('navbar');

  useEffect(() => {
    const show = showModals.some(s => s);
    // check for scrollbar to prevent page jumping
    const scrollBarWidth = window.innerWidth - htmlTag.clientWidth;
    show && scrollBarWidth > 0 ? (htmlTag.style.marginRight = scrollBarWidth + 'px') : (htmlTag.style.marginRight = '');
    show ? (htmlTag.style.overflow = 'hidden') : (htmlTag.style.overflow = '');
    if (navbarTag) show ? (navbarTag.style.overflowY = 'scroll') : (navbarTag.style.overflowY = '');
  }, showModals);
};
