import { useState } from 'react';

import { UserSelfTrackedLearning, UserSelfTrackedLearningsTypes } from '../../../types';

export type FormValues = {
  id?: number;
  type: UserSelfTrackedLearningsTypes;
  title: string;
  whatDidILearn: string;
  hours: string;
  minutes: string;
  link: string;
};

type FormController = {
  type: UserSelfTrackedLearningsTypes;
  setType: (type: UserSelfTrackedLearningsTypes) => void;
  title: string;
  setTitle: (title: string) => void;
  whatDidILearn: string;
  setWhatDidILearn: (whatILearnt: string) => void;
  hours: string;
  setHours: (hours: string) => void;
  minutes: string;
  setMinutes: (minutes: string) => void;
  success: boolean;
  setSuccess: (success: boolean) => void;
  link: string;
  setLink: (link: string) => void;
  getFormValues: () => FormValues;
};

export const useFormController = (initialValues?: UserSelfTrackedLearning): FormController => {
  const initialTitle = initialValues?.title || '';
  const initialType = initialValues?.type || UserSelfTrackedLearningsTypes.ARTICLE;
  const initialDescription = initialValues?.description || '';

  const initialHours = Math.floor(initialValues?.effort ? initialValues?.effort / (60 * 60) : 0);
  const initialMinutes = Math.floor(initialValues?.effort ? (initialValues?.effort / 60) % 60 : 0);

  const initialLink = initialValues?.link || '';

  const [title, setTitle] = useState<string>(initialTitle);
  const [type, setType] = useState<UserSelfTrackedLearningsTypes>(initialType);
  const [whatDidILearn, setWhatDidILearn] = useState<string>(initialDescription);
  const [hours, setHours] = useState<string>(initialHours.toString());
  const [minutes, setMinutes] = useState<string>(initialMinutes.toString());
  const [success, setSuccess] = useState<boolean>(false);
  const [link, setLink] = useState<string>(initialLink);

  const getFormValues = (): FormValues => {
    return {
      type,
      title,
      whatDidILearn,
      hours,
      minutes,
      link,
    };
  };

  return {
    type,
    setType,
    title,
    setTitle,
    whatDidILearn,
    setWhatDidILearn,
    hours,
    setHours,
    minutes,
    setMinutes,
    success,
    setSuccess,
    link,
    setLink,
    getFormValues,
  };
};
