import { trackEvent } from './countly';

export interface StrategicAssignmentSegmentation {
  assignmentId: string;
  assignmentName: string;
  loopId: string;
  loopStartDate: string;
  loopEndDate: string;
  source?: string;
}

export const trackHelpIcon = ({
  assignmentId,
  assignmentName,
  loopId,
  loopStartDate,
  loopEndDate,
}: StrategicAssignmentSegmentation): void =>
  trackEvent({
    key: '[Strategic Assignment] Clicked help icon',
    segmentation: { assignmentId, assignmentName, loopId, loopStartDate, loopEndDate },
  });

export const trackSubmitReflection = ({
  assignmentId,
  assignmentName,
  loopId,
  loopStartDate,
  loopEndDate,
}: StrategicAssignmentSegmentation): void =>
  trackEvent({
    key: '[Strategic Assignment] Submit a reflection',
    segmentation: { assignmentId, assignmentName, loopId, loopStartDate, loopEndDate },
  });

export const trackAddedLearningTime = ({
  assignmentId,
  assignmentName,
  loopId,
  loopStartDate,
  loopEndDate,
}: StrategicAssignmentSegmentation): void =>
  trackEvent({
    key: '[Strategic Assignment] Added learning time',
    segmentation: { assignmentId, assignmentName, loopId, loopStartDate, loopEndDate },
  });

export const trackViewDetails = ({
  assignmentId,
  assignmentName,
  loopId,
  loopStartDate,
  loopEndDate,
}: StrategicAssignmentSegmentation): void =>
  trackEvent({
    key: '[Strategic Assignment] Clicked view details',
    segmentation: { assignmentId, assignmentName, loopId, loopStartDate, loopEndDate },
  });

export const trackReflectButtonOnWeeklyChart = ({
  assignmentId,
  assignmentName,
  loopId,
  loopStartDate,
  loopEndDate,
  source = 'button',
}: StrategicAssignmentSegmentation): void =>
  trackEvent({
    key: '[Strategic Assignment]  Opened reflection modal ',
    segmentation: { assignmentId, assignmentName, loopId, loopStartDate, loopEndDate, source },
  });

export const trackPeopleButton = ({
  assignmentId,
  assignmentName,
  loopId,
  loopStartDate,
  loopEndDate,
}: StrategicAssignmentSegmentation): void =>
  trackEvent({
    key: '[Strategic assignment] Opened participants list',
    segmentation: { assignmentId, assignmentName, loopId, loopStartDate, loopEndDate },
  });

export const trackLearningCardClicked = (title: string): void =>
  trackEvent({
    key: '[Strategic Assignment] Clicked learning card',
    segmentation: { title },
  });
