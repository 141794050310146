import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import './dropdown.scss';

import { IconPropType } from '@/ui/Icon';
import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { escapeRegex } from '@/utils/text';
import { AutoComplete } from '@/components/Form/types';
import { StyledDropdownWrapper } from '@/components/Dropdown/StyledDropdownWrapper';
import { InputBasic } from '@/components/Form/components/Input/InputBasic';
import { StyledDropdown, StyledDropdownItem } from './DropdownStyles';

function isDescendantOf(element: (Node & ParentNode) | null, ancestor: (Node | ParentNode) | null): boolean {
  if (!element) return false;

  let p: (Node & ParentNode) | null = element;
  while (p) {
    if (p === ancestor) return true;
    p = p.parentNode;
  }

  return false;
}

export interface DropdownOption<T> {
  label: string;
  details?: string;
  value: T;
  unselectable?: boolean;
  hidden?: boolean;
  isHeader?: boolean;
}
interface Props<T> {
  name?: string;
  label: string;
  hideLabel?: boolean;
  sort?: boolean;
  multiple?: boolean;
  limit?: number;
  hideLimit?: boolean;
  icon?: IconPropType;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  fullMatch?: boolean;
  writableWhileLoading?: boolean;
  options: DropdownOption<T>[];
  selected?: T | T[] | undefined;
  // defaultSelectOnload can be used when the options data is loaded asynchronously and
  // you wish Select to set a default value once options has loaded
  defaultSelectOnload?: boolean;
  onInput?: (text: string) => void;
  onSelect: (item: T) => void;
  onBlur?: () => void;
  onDeselect?: (item: T) => void;
  showValues?: boolean;
  error?: FieldError;
  placeholder?: string;
  styles?: CSSProperties;
  shouldShowCloseIcon?: boolean;
  onCloseIconClick?: () => void;
}

export function Dropdown<T>({
  label,
  hideLabel,
  sort,
  multiple,
  limit,
  hideLimit,
  name,
  icon = 'chevron-down' as IconPropType,
  disabled,
  required,
  loading,
  fullMatch,
  writableWhileLoading,
  options,
  selected,
  defaultSelectOnload,
  onInput,
  onSelect,
  onBlur,
  onDeselect,
  showValues,
  error,
  placeholder,
  styles = {},
  shouldShowCloseIcon,
  onCloseIconClick,
}: Props<T>): JSX.Element {
  const { t } = useTranslation('common');
  const parentRef = useRef<HTMLDivElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [localOptions, setLocalOptions] = useState<DropdownOption<T>[]>([]);

  useEffect(() => {
    // Fire an onChange with the first option in the list once options has been populated
    if (defaultSelectOnload && (Array.isArray(selected) ? selected.length === 0 : !selected) && options.length > 0) {
      onSelect(options[0].value);
    }

    const opts = options.slice();
    if (sort) {
      opts.sort((a, b) => a.label.localeCompare(b.label));
    }

    setLocalOptions(opts);
  }, [options]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const clickedDropdown = isDescendantOf(e.target as HTMLElement, parentRef.current);
      if (!clickedDropdown) {
        blur();
      }
    };

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  useOnEscapePress((event) => {
    event.preventDefault();
    blur();
  }, []);

  const handleInput = (text: string) => {
    if (onInput) onInput(text);
    setSearchString(text);
  };

  const handleSelect = (value: T) => {
    const item = localOptions.find((o) => o.value === value);
    if (!item || item.unselectable) return;

    onSelect(item.value);
    if (!multiple) blur();
  };

  const handleDeselect = (value: T) => {
    if (!onDeselect) return;

    const item = localOptions.find((o) => o.value === value);
    if (!item || item.unselectable) return;

    onDeselect(item.value);
  };

  const blur = () => {
    setHasFocus(false);
    setSearchString('');
  };

  const handleSetHasFocus = (focus: boolean) => {
    if (disabled) return;
    setHasFocus(focus);
  };

  let inputValue = '';
  if (hasFocus) {
    inputValue = searchString;
  } else if (!hideLimit && multiple && Array.isArray(selected) && !showValues) {
    if (limit) {
      inputValue = t('Selected {{count}}/{{max}} (of {{total}})', { count: selected.length, max: limit, total: options.length });
    } else {
      inputValue = t('Selected {{count}}/{{total}}', { count: selected.length, total: options.length });
    }
  } else if (multiple && Array.isArray(selected) && showValues) {
    inputValue = selected.join(', ');
  } else {
    inputValue = localOptions.find((o) => o.value === selected)?.label || '';
  }

  const itemsJsx = useMemo(() => {
    const limitReached = multiple && limit && Array.isArray(selected) && selected.length >= limit;
    const filteredOptions: DropdownOption<T>[] = [];
    localOptions
      .filter((o) => {
        if (o.isHeader) return true;
        const isLabelMatch = o.label.toLowerCase().includes(searchString.toLowerCase());
        return fullMatch ? isLabelMatch || o.details?.toLowerCase().includes(searchString.toLowerCase()) : isLabelMatch;
      })
      .filter((option) =>
        limitReached ? option.isHeader || (Array.isArray(selected) && selected.some((s) => s === option.value)) : true
      )
      .forEach((opt, i, src) => {
        if (opt.isHeader) {
          if (filteredOptions[filteredOptions.length - 1]?.isHeader) filteredOptions.pop();
          if (i === src.length - 1) return;
        }

        if (opt.hidden) return;
        filteredOptions.push(opt);
      });
    const regex = new RegExp(escapeRegex(inputValue), 'i');

    return filteredOptions.map((o, i) => {
      const isSelected = (multiple && Array.isArray(selected) && selected.some((s) => s === o.value)) || selected === o.value;
      return (
        <StyledDropdownItem
          key={i}
          className="dropdown-item"
          styleName={clsx('dropdown-item', isSelected && 'selected', o.isHeader && 'is-header')}
          onClick={() => (isSelected ? handleDeselect(o.value) : handleSelect(o.value))}
        >
          {multiple && Array.isArray(selected) && !o.unselectable && (
            <input styleName="checkbox" type="checkbox" checked={isSelected} onChange={() => false} />
          )}
          <div>
            <span
              className="body2"
              dangerouslySetInnerHTML={{
                __html: o.label.replace(regex, `<b class="has-text-dark">$&</b>`),
              }}
            />
            {o.details && <div className="overline">{o.details}</div>}
          </div>
        </StyledDropdownItem>
      );
    });
  }, [localOptions, searchString, selected]);

  return (
    <StyledDropdownWrapper ref={parentRef} onFocus={() => handleSetHasFocus(true)} style={styles} disabled={disabled}>
      <div className={clsx(['dropdown', hasFocus && 'is-active'])} styleName="dropdown">
        <div className="dropdown-trigger" styleName="dropdown-trigger">
          <InputBasic
            name={name}
            disabled={disabled}
            autoComplete={AutoComplete.on}
            label={label}
            hiddenLabel={hideLabel}
            icon={icon}
            rotatedIcon={hasFocus}
            loading={loading}
            writableWhileLoading={writableWhileLoading}
            required={required}
            placeholder={placeholder}
            aria-required={required ? 'true' : 'false'}
            value={inputValue}
            onFocus={() => !disabled && setHasFocus(true)}
            onBlur={onBlur}
            onChange={(e) => handleInput(e.currentTarget.value)}
            overline={true}
            onIconClick={() => !disabled && setHasFocus(!hasFocus)}
            type="text"
            error={error}
            cursor="pointer"
            shouldShowCloseIcon={shouldShowCloseIcon}
            onCloseIconClick={onCloseIconClick}
          />
        </div>
        {!!itemsJsx.length && (
          <StyledDropdown open={hasFocus} role="listbox" data-testid="listbox" aria-label="MultiSelectDropdown">
            {itemsJsx}
          </StyledDropdown>
        )}
      </div>
    </StyledDropdownWrapper>
  );
}
