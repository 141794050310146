import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';

import { AssignmentsV3QueryResult, UseAssignmentsHook } from './types';
import { assignmentBase, learnings, users, assigmentTypes, infoExtras } from './queryContants';

export const ASSIGNMENTS_QUERY = gql`
query assignmentsV3( $spacedIdsIN: [Int!], $searchPhrase: String, $type: AssignmentV3Type, $userIdIN: [Int!], $userTeamIdIN: [Int!], $status: AssignmentV3Status, $sort: AssignmentsV3Sort, $pagination: Pagination, $includeUserTeams: Boolean!, $includeUsers: Boolean!, $includeLearnings: Boolean! ) {
  assignmentsV3(filter: { spacedIdsIN: $spacedIdsIN, searchPhrase: $searchPhrase, type: $type, userIdIN: $userIdIN, userTeamIdIN: $userTeamIdIN, status: $status }, sort: $sort, pagination: $pagination) {
    data {
      ${assignmentBase}
      ${learnings}
      ${users}
      ${assigmentTypes}
    }
    ${infoExtras}
  }
}
`;

export const useAssignments: UseAssignmentsHook = ({
  userIds,
  teamsIds,
  spaces,
  searchPhrase,
  status,
  sort,
  type,
  includeUsers,
  includeLearnings,
  skip,
}) => {
  const spacesIds = spaces?.map((space) => space.id) ?? null;

  const { data, loading, error, refetch } = useQuery<AssignmentsV3QueryResult>(ASSIGNMENTS_QUERY, {
    variables: {
      spacedIdsIN: spacesIds,
      searchPhrase: searchPhrase ?? null,
      userIdIN: userIds,
      userTeamIdIN: teamsIds ?? null,
      status: status ?? null,
      sort: sort ?? null,
      type: type ?? null,
      pagination: {
        limit: -1,
        page: 1,
      },
      includeUserTeams: !!teamsIds,
      includeLearnings: !!includeLearnings,
      includeUsers: !!includeUsers,
    },
    skip,
  });

  return {
    data: data?.assignmentsV3.data,
    loading,
    error,
    refetch,
  };
};
