import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Player from '@vimeo/player';
import clsx from 'clsx';

import { VideoUploadMeta } from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { VideoThumbnail } from './VideoThumbnail';
import { api } from '../../../../../utils/lib/api';

interface Props {
  data: VideoUploadMeta;
}

export const VideoUploadBlock = ({ data: { caption, vimeoId, thumbnail, duration, ratio } }: Props): JSX.Element => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [vimeoPlayer, setVimeoPlayer] = useState<Player>();
  const [vimeoThumbnail, setVimeoThumbnail] = useState(thumbnail);
  const [showVideo, setShowVideo] = useState(!!vimeoThumbnail);
  const [playerElementId] = useState(`vimeo-${uuid()}`);
  const [localRatio, setLocalRatio] = useState(ratio || 0.6015625);

  useEffect(() => {
    // Fetching thumbnail on load to avoid having to wait for vimeo transcoding
    const request = async () => {
      if (!vimeoThumbnail) {
        const { transcode, thumbnail } = await api.uploadStatus(vimeoId);
        if (transcode === 'complete') setVimeoThumbnail(thumbnail);
      }
    };
    request();
  }, []);

  function createPlayer() {
    if (vimeoId && videoRef.current) {
      return new Player(videoRef.current, {
        id: parseInt(vimeoId),
        width: 800,
        height: 450,
        speed: true,
      });
    }
    return null;
  }

  useEffect(() => {
    const player = createPlayer();
    if (player) {
      setVimeoPlayer(player);
      if (!ratio) {
        Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(([width, height]) => {
          setLocalRatio(height / width);
        });
      }
    }
  }, []);

  return (
    <div className="content is-article is-unpadded">
      <figure>
        <figure className={clsx('figure', !showVideo && 'is-hidden')} style={{ width: '100%' }}>
          <div id={playerElementId} ref={videoRef} className="video" style={{ paddingBottom: `${localRatio * 100}%` }} />
        </figure>
        {!showVideo && (
          <VideoThumbnail
            duration={duration}
            caption={caption}
            thumbnail={vimeoThumbnail}
            onClickPlay={() => {
              vimeoPlayer?.play();
              setShowVideo(true);
            }}
          />
        )}
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    </div>
  );
};
