import { gql, FetchResult, useMutation } from '@apollo/client';

import { ASSIGNMENTS_QUERY_OPERATION_NAME } from './constants';
import { AssignmentsV3Result, UpsertAssignmentInput } from './types';

export const UPSERT_ASSIGNMENT_MUTATION = gql`
  mutation upsertAssignmentV3($input: UpsertAssignmentInput!) {
    upsertAssignmentV3(input: $input) {
      assignment {
        id
        spaceId
        name
        teaser
        description
        image
        surveyId
        rules {
          operator
          expressions {
            operator
            conditions {
              column
              compare
              value
              selections
            }
          }
        }
        type
        status
        learnings(pagination: { limit: -1, page: 1 }) {
          data {
            id
            assignmentGroupId
            learningPathId
            learningId
            order
          }
          ... on AssignmentStandardV3LearningPage {
            data {
              ... on AssignmentStandardV3Learning {
                type
              }
            }
          }
        }

        users(pagination: { limit: -1, page: 1 }) {
          data {
            userId
            assignmentId
            user {
              id
              email
              username
            }
            agent
          }
          ... on AssignmentStandardV3UsersPage {
            data {
              ... on AssignmentStandardV3User {
                progress {
                  completed
                  participations {
                    assignmentLearningId
                    participationId
                    progress
                    completedAt
                    expiredAt
                  }
                }
              }
            }
          }
        }
        ... on AssignmentStrategicV3 {
          startAt
          endAt
          learningLoopRecommendedEffort
          totalThresholdEffort
        }
      }
      success
    }
  }
`;

export const useUpsertAssignment = (): {
  upsertAssignmentV3: (input: UpsertAssignmentInput) => Promise<FetchResult<{ upsertAssignmentV3: AssignmentsV3Result }>>;
  loading: boolean;
} => {
  const [upsertAssignmentV3, { loading }] = useMutation<{
    upsertAssignmentV3: AssignmentsV3Result;
  }>(UPSERT_ASSIGNMENT_MUTATION);

  return {
    upsertAssignmentV3: async (input: UpsertAssignmentInput) =>
      upsertAssignmentV3({
        variables: { input },
        refetchQueries: [ASSIGNMENTS_QUERY_OPERATION_NAME],
      }),
    loading,
  };
};
