import React from 'react';

import { Description, Header, Title } from './styles';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { HeroProps } from './types';
import { MetaLabel } from '@/ui/Label';
import { ProgressWrapper, ProgressWrapStyled } from '@/pages/Assignment/styles';
import { LinearProgressBar } from '@/ui/ProgressBar';
import { ButtonGroup } from '@/ui/ButtonGroup';
import { Icon } from '@/ui/Icon';
import { IconPropType } from '@/ui/Icon';

type MetaItem =
  | {
      type: 'label';
      text: string;
      icon: IconPropType;
    }
  | {
      type: 'progress';
      text: string;
      value: number;
      maxValue: number;
    }
  | {
      type: 'icon';
      icon: IconPropType;
    };

const _Hero = ({ assignment, learningItemsCount, loading, completedLearningItemsCount }: HeroProps): JSX.Element => {
  const learningItemsLeft =
    assignment.progressStatus === 'ongoing' && Number.isFinite(completedLearningItemsCount) && Number.isFinite(learningItemsCount)
      ? learningItemsCount! - completedLearningItemsCount!
      : null;

  const metadata = (
    [
      assignment.progressStatus === 'not-started' && {
        type: 'label',
        text: learningItemsCount > 1 ? `${learningItemsCount} learning items` : `${learningItemsCount} learning item`,
        icon: 'layer-group',
      },
      assignment.progressStatus === 'ongoing' &&
        learningItemsLeft !== null && [
          {
            type: 'icon',
            icon: 'layer-group',
          },
          {
            type: 'progress',
            text: learningItemsLeft > 1 ? `${learningItemsLeft} learning items left` : `${learningItemsLeft} learning item left`,
            value: completedLearningItemsCount!,
            maxValue: learningItemsCount!,
          },
        ],
      assignment.progressStatus === 'completed' && [
        {
          type: 'label',
          text: 'Completed',
          icon: 'check',
        },
        {
          type: 'label',
          text: learningItemsCount > 1 ? `${learningItemsCount} learning items` : `${learningItemsCount} learning item`,
          icon: 'layer-group',
        },
      ],
    ].flat() as Array<MetaItem | false>
  ).filter((item): item is MetaItem => !!item && ((item.type !== 'icon' && !!item?.text) || item.type === 'icon'));

  return (
    <Header>
      <Title>{assignment.name}</Title>
      {!loading ? (
        <ButtonGroup gap="small" direction="horizontal" align="center">
          {metadata.map((meta, index) =>
            meta.type === 'label' ? (
              <MetaLabel key={index} text={meta.text} icon={meta.icon} />
            ) : meta.type === 'icon' ? (
              <Icon icon={meta.icon} size={'xs'} key={index} />
            ) : (
              <ProgressWrapper key={index}>
                <span>{meta.text}</span>
                <ProgressWrapStyled>
                  <LinearProgressBar value={meta.value} maxValue={meta.maxValue} animated />
                </ProgressWrapStyled>
              </ProgressWrapper>
            )
          )}
        </ButtonGroup>
      ) : (
        <Skeleton height={20} width={150} />
      )}
      <Description dangerouslySetInnerHTML={{ __html: assignment.description || '' }} />
    </Header>
  );
};

const LoadingState = (): JSX.Element => (
  <Header>
    <Skeleton width={736} height={44} />
    <Skeleton width={840} height={18} />
    <Skeleton width={840} height={18} />
    <Skeleton width={840} height={18} />
    <Skeleton width={840} height={18} />
  </Header>
);

const Hero = makeNamespacedComponent(_Hero, {
  LoadingState,
});

export { Hero };
