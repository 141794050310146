/**
 * Turns a regular path (eg. `some/path`) into a nested path (eg. `some/path/*`).
 * 
 * This is necessary for correctly resolving deep routes to the paths specified
 * in tab definitions, so that:
 * 
 * a) the proper top-level tab component is rendered;
 * b) the current tab is marked as selected in the tab navigation.
 */
export const makeNestedPath = (path: string): string => {
  if (path.endsWith('/*')) {
    return path;
  }
  return path
    .replace(/(.*)(\/)$/, '| $1 |') // strip trailing slash, if any
    .concat('/*');
};
