import { AssignmentCard as Card } from '@/ui/AssignmentCard';
import { AssignmentCard as AssignmentCardType } from '@/store/assignments/myLearning/types';
import { formatTimeProgressLabel } from './formatTimeProgressLabel';
import { IconPropType } from '@/ui/Icon';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { formatMMMD } from '@/utils/dates';

type AssignmentCardProps = WithClassNameProp<{
  /** Assignment card data */
  card: AssignmentCardType;
  /** Card index for stagerring the fade-in animation */
  stagger?: number;
  onClick?: () => void;
}>;

export type MetaItem =
  | {
      type: 'label';
      text: string;
      icon: IconPropType;
    }
  | {
      type: 'progress';
      text: string;
      value: number;
      maxValue: number;
    };

export const AssignmentCard = ({ card, className, stagger, onClick }: AssignmentCardProps): JSX.Element => {
  const { id, name, status, type } = card;
  const link = `/assignment/${id}/${name}`;

  const learningItemsLeft =
    card.type === 'STANDARD' &&
    status === 'ongoing' &&
    Number.isFinite(card.learningItemsCompleted) &&
    Number.isFinite(card.learningItemsCount)
      ? card.learningItemsCount! - card.learningItemsCompleted!
      : null;

  const metadata = (
    [
      card.type === 'STANDARD' &&
        status === 'not-started' && {
          type: 'label',
          text:
            card.learningItemsCount > 1
              ? `${card.learningItemsCount} learning items`
              : `${card.learningItemsCount} learning item`,
          icon: 'layer-group',
        },
      card.type === 'STRATEGIC' &&
        status === 'not-started' && [
          {
            type: 'label',
            text:
              `${formatMMMD(new Date(card.startDate))} - ${formatMMMD(new Date(card.endDate))}, ${new Date(
                card.endDate
              ).getFullYear()}` ?? '',
            icon: ['far', 'calendar'],
          },
          {
            type: 'label',
            text: `${formatToHoursAndMinutesFromSeconds(card.effortThreshold)} total` ?? '',
            icon: ['far', 'clock'],
          },
        ],
      card.type === 'STANDARD' &&
        status === 'ongoing' &&
        learningItemsLeft !== null && {
          type: 'progress',
          text: learningItemsLeft > 1 ? `${learningItemsLeft} learning items left` : `${learningItemsLeft} learning item left`,
          value: card.learningItemsCompleted!,
          maxValue: card.learningItemsCount!,
        },
      card.type === 'STRATEGIC' &&
        status === 'ongoing' && {
          type: 'progress',
          text: formatTimeProgressLabel(card.estimatedEffort, card.effortThreshold) ?? '',
          value: card.estimatedEffort,
          maxValue: card.effortThreshold,
        },
      card.type === 'STANDARD' &&
        status === 'completed' && [
          {
            type: 'label',
            text: 'Completed',
            icon: 'check',
          },
          {
            type: 'label',
            text:
              card.learningItemsCount > 1
                ? `${card.learningItemsCount} learning items`
                : `${card.learningItemsCount} learning item`,
            icon: 'layer-group',
          },
        ],
      card.type === 'STRATEGIC' &&
        status === 'completed' && [
          {
            type: 'label',
            text: 'Completed',
            icon: 'check',
          },
          {
            type: 'label',
            text: formatToHoursAndMinutesFromSeconds(card.effortThreshold) ?? '',
            icon: ['far', 'clock'],
          },
        ],
      card.type === 'STRATEGIC' &&
        status === 'ended' && [
          {
            type: 'label',
            text: 'Ended',
            icon: 'minus',
          },
          {
            type: 'label',
            text: formatToHoursAndMinutesFromSeconds(card.effortThreshold) ?? '',
            icon: ['far', 'clock'],
          },
        ],
    ].flat() as Array<MetaItem | false>
  ).filter((item): item is MetaItem => !!item && !!item?.text);

  return (
    <Card link={link} className={className} stagger={stagger} onClick={onClick}>
      <Card.Title>{name}</Card.Title>
      <Card.Animation
        variant={({ 'not-started': 'notStarted', ongoing: 'ongoing', completed: 'completed', ended: 'ended' } as const)[status]}
      />
      <Card.Labels>{type === 'STRATEGIC' && <Card.Labels.Strategic />}</Card.Labels>
      <Card.Footer>
        {metadata.map((meta, index) =>
          meta.type === 'label' ? (
            <Card.Footer.Label key={index} text={meta.text} icon={meta.icon} />
          ) : (
            <Card.Footer.Progress key={index} text={meta.text} value={meta.value} maxValue={meta.maxValue} />
          )
        )}
      </Card.Footer>
    </Card>
  );
};
