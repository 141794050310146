import React from 'react';
import styled from 'styled-components';

import { OverlineMixin } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';
import { NavSearchProps } from './types';
import { StyledDropdownRowStandard } from '@/components/Dropdown/StyledDropdownRow';
import { StyledMobileWrapper, StyledDesktopWrapper } from '@/components/Navbar/styles';

export const StyledGoToPage = styled(StyledDropdownRowStandard)`
  ${OverlineMixin({ color: 'color-text' })};
  background-color: ${cvar('color-silver')};
  border: 0;
  justify-content: center;
  border-bottom-left-radius: ${cvar('radius-inner-with-border')};
  border-bottom-right-radius: ${cvar('radius-inner-with-border')};

  &:hover,
  &:focus {
    background-color: ${cvar('color-secondary')};
  }

  svg {
    color: ${cvar('color-black')};
    margin-left: ${cvar('gap-xxs')};
    opacity: 0.3;
  }
`;

export const NavSearchWrapper = ({ isMobile, children }: NavSearchProps): JSX.Element =>
  isMobile ? <StyledMobileWrapper>{children}</StyledMobileWrapper> : <StyledDesktopWrapper>{children}</StyledDesktopWrapper>;
