import { breakpoints, MediaQueryHelpers } from '../theme/layout';

export const mediaQuery: MediaQueryHelpers = {
  from: (screenSize) => `@media (min-width: ${breakpoints[screenSize].start}px)`,
  until: (screenSize) => `@media (max-width: ${breakpoints[screenSize].end}px)`,
  only: (screenSize) =>
    `@media (min-width: ${breakpoints[screenSize].start}px) and (max-width: ${breakpoints[screenSize].end}px)`,
  between: (startScreenSize, endScreenSize) =>
    `@media (min-width: ${breakpoints[startScreenSize].start}px) and (max-width: ${breakpoints[endScreenSize].end}px) `,
};
