import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { StyledDropdown as StyledDropdownRaw } from '@/components/Dropdown';

export const StyledDropdown = styled(StyledDropdownRaw)`
  > * {
    display: flex;
    padding: ${cvar('gap-xxs')} ${cvar('gap-sm')};
    min-height: ${BUTTON_HEIGHT_ENUM.default}rem;
    align-items: center;
    border: ${cvar('border-style')};
    border-bottom: 0;
    color: ${cvar('color-text')};
  }
`;

export const StyledDropdownItem = styled.div`
  display: flex;
  align-items: center;
`;
