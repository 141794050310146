import { ButtonHTMLAttributes } from 'react';
import styled, { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

export const iconVariants = ['default'] as const;
export const sizes = ['small'] as const;

export type ButtonVariant = typeof iconVariants[number];
export type ButtonSize = typeof sizes[number];

export type ToggleButtonProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  toggled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const buttonVariantStyle: Record<ButtonVariant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  default: css`
    border-radius: 4px;
  `,
} as const;

const buttonSizeStyle: Record<ButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  small: css`
    height: 24px;
    min-width: 24px;
  `,
} as const;

const BaseButton = styled.button<ToggleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
  border: none;
  cursor: pointer;
  background: ${({ toggled, theme }) => (toggled ? theme.tones.neutral[20] : 'transparent')};
  color: ${({ theme, toggled }) => (toggled ? theme.colors.neutral.background : theme.colors.neutral.onBackground)};

  transition: all 0.2s;

  &:hover {
    background: ${({ theme, toggled }) => (toggled ? theme.colors.states.hover4 : theme.colors.states.hover1)};
  }

  ${({ variant }) => buttonVariantStyle[variant || 'default']};
  ${({ size }) => buttonSizeStyle[size || 'small']}
`;

export const ToggleButton = ({
  size = 'small',
  variant = 'default',
  toggled,
  children,
  onClick,
}: ToggleButtonProps): JSX.Element => {
  return (
    <BaseButton type="button" onClick={onClick} variant={variant} toggled={toggled} size={size}>
      {children}
    </BaseButton>
  );
};
