import React from 'react';
import styled from 'styled-components';

import { ColumnDeclaration, SortParams, SortOrder } from '../types';
import { Icon } from '@/components/Icon';

const getColumnKey = <T extends unknown>(column: ColumnDeclaration<T>): string => {
  if (column.id) return column.id;
  return typeof column.title === 'string' ? column.title : '';
};

interface Props<T> {
  sort: SortParams<T> | undefined;
  setSort: (p: SortParams<T>) => void;
  column: ColumnDeclaration<T>;
  index: number;
}

export function SortableTableHeader<T>({ sort, setSort, column, index }: Props<T>): React.ReactElement {
  const columnHasSort = sort?.columnIndex === index;
  const handleHeaderClick = () => {
    if (!column.sortable) return;

    const order: SortOrder = sort?.order === 'asc' ? 'desc' : 'asc';
    setSort({ columnIndex: index, order, column: column });
  };

  return (
    <th
      key={getColumnKey(column)}
      onClick={handleHeaderClick}
      style={{
        textAlign: column.cellStyle?.textAlign || 'left',
        cursor: column.sortable ? 'pointer' : 'default'
      }}
    >
      <CellContentWrapper>
        {column.title}
        {columnHasSort && (
          <Icon style={{ marginLeft: '5px' }} rotation={sort?.order === 'desc' ? undefined : 180} icon="chevron-down" />
        )}
      </CellContentWrapper>
    </th>
  );
}

const CellContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
