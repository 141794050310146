/* eslint-disable import/no-unused-modules */
import {
  PlaceholderProps,
  createNodeHOC,
  createNodesHOC,
  usePlaceholderState,
  ParagraphPlugin,
  useEditorReadOnly,
} from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import React, { Children, cloneElement, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';

const PlaceholderWrapper = styled.div<{ enabled: boolean; placeholder?: string }>`
  ${({ enabled, placeholder }) =>
    enabled &&
    css`
      &::before {
        position: absolute;
        cursor: text;
        color: ${({ theme }) => theme.colors.neutral.disabled};
        content: ${placeholder ? `"${placeholder}"` : '""'};
      }
    `}
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Placeholder = (props: PlaceholderProps) => {
  const { children, nodeProps, placeholder, element } = props;

  const { enabled } = usePlaceholderState(props);

  const readonly = useEditorReadOnly();

  const { setElementPlaceholderActive } = useContext(CustomEditorContext);

  /**
   * Remove after the fix of library bug: @link https://github.com/udecode/plate/issues/3730
   */
  useEffect(() => {
    if (element.id) {
      setElementPlaceholderActive({ id: String(element.id), enabled });
    }
  }, [enabled, element.id]);

  return Children.map(children, (child) => {
    return cloneElement(child, {
      className: child.props.className,
      nodeProps: {
        ...nodeProps,
        placeholder,
      },
      children: (
        <PlaceholderWrapper enabled={enabled && !readonly} placeholder={placeholder}>
          {child.props.children}
        </PlaceholderWrapper>
      ),
    });
  });
};

export const withPlaceholder = createNodeHOC(Placeholder);

export const withPlaceholdersPrimitive = createNodesHOC(Placeholder);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const withPlaceholders = (components: any) =>
  withPlaceholdersPrimitive(components, [
    {
      key: ParagraphPlugin.key,
      hideOnBlur: true,
      placeholder: 'Write your content or ‘/’ for options',
      query: {
        maxLevel: 1,
      },
    },
    {
      key: HEADING_KEYS.h1,
      hideOnBlur: false,
      placeholder: 'Heading 1',
    },
    {
      key: HEADING_KEYS.h2,
      hideOnBlur: false,
      placeholder: 'Heading 2',
    },
  ]);
