import React, { createRef, RefObject, useState } from 'react';
import Cropper from 'cropperjs';
import { Trans, useTranslation } from 'react-i18next';

import './choosePicture.scss';
import { Icon } from '@/components/Icon';
import { CropperComponent } from './CropperComponent';
import { GenericErrorMessage } from '../GenericError';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { Header2 } from '@/components/Typography/Typography';
import { useModalContext } from '@/components/Modal/ModalContext';
import { useUpdateCurrentUser } from '@/store/user';

type SelectedFile = string | null;

const cropperOptions: Cropper.Options = {
  aspectRatio: 1,
  initialAspectRatio: 1,
  viewMode: 1,
  checkOrientation: false,
  autoCropArea: 1,
  minCropBoxHeight: 100,
  minCropBoxWidth: 100,
};

export const ChoosePicture = (): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<SelectedFile>(null);
  const [selectedFilename, setSelectedFilename] = useState('');
  const [selectedFiletype, setSelectedFiletype] = useState('');
  const [error, setError] = useState(false);
  const [cropper, setCropper] = useState<Cropper | undefined>();
  const { updateUser, loading } = useUpdateCurrentUser();
  const { t } = useTranslation('profileImageUpload');
  const { hideModal } = useModalContext();

  const fileReader: FileReader = new FileReader();
  const imgRef: RefObject<HTMLImageElement> = createRef();

  const onFileSelect = (file: File) => {
    if (!file) return;
    setSelectedFilename(file.name);
    setSelectedFiletype(file.type);
    fileReader.onloadend = handleFile;
    fileReader.readAsDataURL(file);
  };

  const onClickUpload = (): void => {
    const croppedCanvas: (HTMLCanvasElement & { msToBlob?: () => Blob | null }) | undefined = cropper?.getCroppedCanvas({
      maxHeight: 1000,
      maxWidth: 1000,
    });
    if (!croppedCanvas) return setError(true);

    const handleBlob = async (image: Blob | null) => {
      if (!image) return;

      const { errors } = await updateUser({ profileImage: image });

      if (errors) {
        setError(true);
      } else {
        reset(true);
      }
    };

    if (croppedCanvas.msToBlob) {
      handleBlob(croppedCanvas.msToBlob());
    } else {
      croppedCanvas.toBlob(handleBlob, selectedFiletype, 0.9);
    }
  };

  const handleImgLoad = () => setCropper(new Cropper(imgRef.current as HTMLImageElement, cropperOptions));
  const handleFile = () => reset() && setSelectedFile(fileReader.result as SelectedFile);
  const reset = (close = false) => {
    setError(false);
    setCropper(undefined);
    setSelectedFile(null);
    setSelectedFilename('');
    close && hideModal();
    return true;
  };

  return (
    <>
      <Header2>{t('Upload picture')}</Header2>
      <section className="has-bottom-margin">
        <form className="has-bottom-margin">
          <div className="file has-name">
            <label className="file-label">
              <input
                className="file-input"
                onChange={(e) => e.target.files && onFileSelect(e.target.files[0])}
                type="file"
                id="fileInput"
                name="files[]"
                accept=".png, .jpg, .jpeg"
                aria-labelledby="choose-picture-label"
              />
              <span className="file-cta">
                <span className="file-icon">
                  <Icon icon="upload" />
                </span>
                <span className="file-label" id="choose-picture-label">
                  {t('Choose a file…')}
                </span>
              </span>
              <span className="file-name">{selectedFilename}</span>
            </label>
          </div>
          <p className="help">
            <Trans t={t} components={[<em key={0}>t</em>]}>
              allowed-file-types
            </Trans>
          </p>
          <input type="hidden" id="params" name="params" />
        </form>

        {!!selectedFile && (
          <CropperComponent cropper={cropper} handleImgLoad={handleImgLoad} imgRef={imgRef} src={selectedFile} />
        )}
        {error && <GenericErrorMessage />}
      </section>
      <ButtonList padded="vertical" align="center">
        <Button $type="text" onClick={hideModal}>
          {t('Close', { ns: 'common' })}
        </Button>
        <Button $type="primary" $loading={loading} disabled={!cropper} onClick={() => onClickUpload()}>
          {t('Upload')}
        </Button>
      </ButtonList>
    </>
  );
};
