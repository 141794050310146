import styled, { css } from 'styled-components';

import { StyledDocumentPDFObject } from '@/administration/pages/Course/blocks/document/styles';
import { PowerPointFrameStyled } from '@/administration/pages/Course/blocks/document/components/DownloadableResource/styles';

export const PDFStyled = styled(StyledDocumentPDFObject)<{ isInteractive: boolean }>`
  height: 440px;
  border-radius: 12px;

  ${({ isInteractive }) =>
    !isInteractive &&
    css`
      pointer-events: none;
    `};
`;

export const PowerPointStyled = styled(PowerPointFrameStyled)<{ isInteractive: boolean }>`
  border-radius: 12px;
  overflow: hidden;

  ${({ isInteractive }) =>
    !isInteractive &&
    css`
      pointer-events: none;
    `};
`;
