import { ReactNode, useCallback, useEffect, useState } from 'react';
import copy from 'clipboard-copy';

export function useCopyToClipboard(resetInterval: number | null = null): [boolean, (text: ReactNode) => void] {
  const [isCopied, setCopied] = useState(false);

  const handleCopy = useCallback((text: ReactNode) => {
    if (typeof text === 'string' || typeof text == 'number') {
      copy(text.toString());
      setCopied(true);
    } else {
      setCopied(false);
      console.error(`Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`);
    }
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return [isCopied, handleCopy];
}
