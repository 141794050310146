import { ApolloQueryResult, gql, useMutation, useQuery, FetchResult } from '@apollo/client';

import { REFETCH_ADMIN_CATALOG } from '@/store/catalog';
import { HookResult } from './apolloClient';
import { Learning, LearningLinks, Module, Page } from '@/types/learning';
import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';

const LEARNING_FRAGMENT = gql`
  fragment LearningBaseFragment on Learning {
    id
    created
    updated
    title
    type
    teaser
    image
    preamble
    spaceId
    meta
    effort
    archived
    visibility
    languageId
    updatedByEmail
    creatorEmail
  }
`;

const QUERY_LEARNINGS = gql`
  query learnings {
    learnings {
      ...LearningBaseFragment
      locations {
        id
        startDate
        endDate
        address
        room
        capacity
      }
    }
  }
  ${LEARNING_FRAGMENT}
`;

export const QUERY_LEARNING = gql`
  query Learning($id: ID!) {
    learning(id: $id) {
      ...LearningBaseFragment
      progress {
        completed
        completedPercentage
        historical
        expiredAt
      }
      contentOwner
      canEdit
      subjects {
        subjectId
        subject
        categoryId
        category
      }
      locations {
        id
        startDate
        endDate
        address
        room
        capacity
        waitlistType
        myEnrollment {
          id
          attended
        }

        stats {
          isFinished
          enrolledCount
          waitlistedCount
          attendedCount
          notAttendedCount
        }
      }
      modules {
        id
        created
        updated
        learningId
        type
        name
        order
        pages {
          id
          created
          updated
          moduleId
          type
          name
          order
          blocks {
            id
            created
            updated
            pageId
            order
            type
            data
          }
        }
      }
    }
  }
  ${LEARNING_FRAGMENT}
`;

const QUERY_MODULES = gql`
  query GetModules($learningId: ID!) {
    modules(learningId: $learningId) {
      id
      created
      updated
      learningId
      type
      name
      order
      pages {
        id
      }
    }
  }
`;

const QUERY_PAGES = gql`
  query GetPages($moduleId: ID!) {
    pages(moduleId: $moduleId) {
      id
      created
      updated
      moduleId
      type
      name
      order
      blocks {
        id
      }
    }
  }
`;

const ARCHIVE_LEARNING = gql`
  mutation archiveLearning($learningId: Int!) {
    archiveLearning(learningId: $learningId) {
      id
    }
  }
`;

const DELETE_LEARNING = gql`
  mutation deleteLearning($learningId: Int!) {
    deleteLearning(learningId: $learningId) {
      id
    }
  }
`;

const RESTORE_LEARNING = gql`
  mutation restoreLearning($learningId: Int!) {
    restoreLearning(learningId: $learningId) {
      id
    }
  }
`;

export type LearningWithLinks = Learning & { links: LearningLinks };

function getLearningLinks(learning: Learning): LearningLinks {
  return {
    link: `/learning/${learning.id}/${learning.title}`,
    editLink: `${ADMIN_ROUTE_PATH.CATALOG}/${learning.type.toLowerCase().replace(/_/g, '-')}/${learning.id}`,
    previewLink: `/learning/${learning.id}/${learning.title}`,
  };
}

interface LearningResponse extends HookResult {
  learning: LearningWithLinks | null;
  refetch: (variables?: { id?: number | string }) => Promise<ApolloQueryResult<{ learning: Readonly<Learning> }>>;
}

export const useLearning = ({ id, skip = false }: { id: number | string; skip?: boolean }): LearningResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery<{ learning: Readonly<Learning> }>(QUERY_LEARNING, {
    skip,
    variables: { id },
  });

  const learning: LearningWithLinks | null = data?.learning ? { ...data.learning, links: getLearningLinks(data.learning) } : null;

  return {
    learning,
    error,
    refetch,
    networkStatus,
    loading: !learning ? loading : false,
  };
};

interface ModulesResponse extends HookResult {
  modules: Module[];
  refetch: (variables?: { learningId?: number }) => Promise<ApolloQueryResult<{ modules: Module[] }>>;
}

export const useModules = (learningId: number, skip = false): ModulesResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery(QUERY_MODULES, {
    skip,
    variables: { learningId },
  });

  const modules: Module[] = (data && data.modules) || null;

  return {
    modules,
    error,
    refetch,
    networkStatus,
    loading,
  };
};

interface PagesResponse extends HookResult {
  pages: Page[];
  refetch: (variables?: { moduleId?: number }) => Promise<ApolloQueryResult<{ pages: Page[] }>>;
}

export const usePages = (moduleId: number, skip = false): PagesResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery(QUERY_PAGES, {
    skip,
    variables: { moduleId },
  });

  const pages: Page[] = (data && data.pages) || null;

  return {
    pages,
    error,
    refetch,
    networkStatus,
    loading,
  };
};

export const useArchiveLearning = (): {
  archiveLearning: (
    learningId: number
  ) => Promise<FetchResult<{ archiveLearning: { learningId: number } }, Record<string, unknown>>>;
  loading: boolean;
} => {
  const [mutate, { loading }] = useMutation(ARCHIVE_LEARNING);

  return {
    archiveLearning: (learningId: number) =>
      mutate({
        variables: { learningId },
        refetchQueries: [
          {
            query: QUERY_LEARNING,
            variables: { id: learningId },
          },
        ],
      }),
    loading,
  };
};

export const useRestoreLearning = (): {
  restoreLearning: (
    learningId: number
  ) => Promise<FetchResult<{ restoreLearning: { learningId: number } }, Record<string, unknown>>>;
  loading: boolean;
} => {
  const [mutate, { loading }] = useMutation(RESTORE_LEARNING);

  return {
    restoreLearning: (learningId: number) =>
      mutate({
        variables: { learningId },
        refetchQueries: [
          {
            query: QUERY_LEARNING,
            variables: { id: learningId },
          },
        ],
      }),
    loading,
  };
};

export const useDeleteLearning = (): {
  deleteLearning: (
    learningId: number
  ) => Promise<FetchResult<{ deleteLearning: { learningId: number } }, Record<string, unknown>>>;
  loading: boolean;
} => {
  const [mutate, { loading }] = useMutation(DELETE_LEARNING);

  return {
    deleteLearning: (learningId: number) =>
      mutate({
        variables: { learningId },
        refetchQueries: [
          {
            query: QUERY_LEARNINGS,
          },
          REFETCH_ADMIN_CATALOG,
        ],
      }),
    loading,
  };
};
