import styled, { css } from 'styled-components';

import { mediaQuery } from '../utils';
import { ButtonGroupProps } from './types';

export const ButtonGroup = styled.ul<ButtonGroupProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: ${(p) =>
    ({
      start: `flex-start`,
      center: 'center',
      end: `flex-end`,
    }[p.align ?? 'start'])};
  justify-content: ${(p) =>
    ({
      start: `flex-start`,
      center: 'center',
      end: `flex-end`,
    }[p.align ?? 'start'])};

  column-gap: ${(p) =>
    ({
      large: 32,
      medium: 16,
      small: 8,
    }[p.gap ?? 'small'])}px;

  row-gap: ${(p) =>
    ({
      large: 24,
      medium: 16,
      small: 8,
    }[p.verticalGap ?? 'small'])}px;

  flex-direction: ${(p) => (p.direction === 'vertical' ? 'column' : 'row')};
  ${({ verticalUntil }) =>
    !verticalUntil
      ? undefined
      : css`
          ${mediaQuery.until(verticalUntil)} {
            flex-direction: column;
          }
        `}
`;

export const ButtonGroupItem = styled.li<{ fullWidth?: boolean }>`
  display: block;
  ${(props) => props.fullWidth && `width: 100%;`}
`;
