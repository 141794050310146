import { v5 as uuidv5 } from 'uuid';

import { Nominal } from './nominal';

export type StatementId = Nominal<string, 'XAPIStatementId'>;

export enum StatementIdNamespace {
  REGISTRATION = '361cc5af-5c39-47a4-80a7-8e1ca3baea37',
  COMPLETION = '361cc5af-5c39-47a4-80a7-8e1ca3baea37',
  REACTION = '361cc5af-5c39-47a4-80a7-8e1ca3baea37',
  ANSWERED = '361cc5af-5c39-47a4-80a7-8e1ca3baea37',
}

export const statementIdFromNameAndNamespace = (name: string, namespace: StatementIdNamespace): StatementId =>
  uuidv5(name, namespace) as StatementId;
