import { getCardProviderMeta } from '@/store/providers';
import { isCardCompletionAboutToExpire, isCollectionCard, isProductCard, isProgressCard } from '@/types/learning/card';
import { firstLocation } from '@/util';
import { getDaysUntilDate } from '@/utils/expirations';
import { CardType, TransformedCard } from '../types';
import { useFormattedEventDate } from './useFormattedEventDate';

export const useTransformedCard = (card: CardType): TransformedCard => {
  const participation = isProgressCard(card) ? card.participation ?? null : null;

  const state = 'state' in card ? card.state ?? null : null;
  const locations = 'locations' in card ? card.locations ?? [] : [];
  const expiresAt = participation?.expiredAt || null;
  const eventLocation = locations?.length > 0 ? firstLocation(locations) : null;
  const expiresInDays = expiresAt ? getDaysUntilDate(new Date(expiresAt)) : null;
  const hasExpirationInfo = Number.isFinite(expiresInDays) && isCardCompletionAboutToExpire(card);
  const isEvent = card.type === 'seminar' || card.type === 'webinar';

  return {
    isCompleted: participation?.isCompleted ?? false,
    completedAt: participation?.completedAt,
    participation,
    state,
    isCollection: isCollectionCard(card),
    itemsInCollection: isCollectionCard(card) ? card.items.length : null,
    isEvent,
    eventLocation: eventLocation?.address ?? null,
    eventDate: useFormattedEventDate(eventLocation),
    level: isProgressCard(card) ? card.level ?? null : null,
    totalEffort: isProgressCard(card) ? card.totalEffort ?? null : null,
    customLink: isProductCard(card) || isProgressCard(card) ? card.customLink ?? null : null,
    format: isProductCard(card) ? card.format ?? null : null,
    isMandatory: 'isMandatory' in card ? !!card.isMandatory : false,
    isRecommended: 'isRecommended' in card ? !!card.isRecommended : false,
    isApproved: state === 'approved' || !!participation,
    isPending: state ? ['registered', 'pending approval'].includes(state) : false,
    isFeatured: card.featured,
    hasExpirationInfo,
    expiresInDays,
    providerMeta: getCardProviderMeta(card) ?? null,
    title: card.title,
    image: card.image,
    teaser: card.teaser,
    type: card.type,
  };
};
