import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditTextPageProps } from './types';
import {
  InformerBannerStyled,
  PeerReviewDescriptionStyled,
  PeerReviewMetaStyled,
  PeerReviewRootStyled,
  PeerReviewTitleStyled,
  RootStyled,
  TextEditorStyled,
} from './styles';
import { Input } from '@/ui/TextField';
import { CustomEditorV2 } from '@/component/customEditorV2';
import { Icon } from '@/ui/Icon';
import { Toggle } from '@/ui/Toggle';

export const EditPracticalAssessmentDocumentPage = ({
  id,
  onDescriptionChange,
  description = '',
  title,
  peerReviewEnabled,
  onTitleChange,
  onPeerReviewEnabledChange,
  spaceId,
}: EditTextPageProps): JSX.Element => {
  const { t } = useTranslation('catalog');

  return (
    <RootStyled>
      <Input
        value={title}
        onChange={(e) => onTitleChange(e.currentTarget.value)}
        label={t('title')}
        error={!title?.length ? { message: t('enter-title') } : undefined}
        fullWidth
      />
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={description}
          onChange={(description) => onDescriptionChange(description)}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          toggledFeatures={{
            mediaFiles: true,
          }}
          placeholder="Instructions (optional)"
        />
      </TextEditorStyled>
      <InformerBannerStyled>
        <Icon icon={['far', 'file-upload']} />
        Participants will complete this assessment by uploading a document.
      </InformerBannerStyled>
      <PeerReviewRootStyled>
        <PeerReviewMetaStyled>
          <PeerReviewTitleStyled>Peer review</PeerReviewTitleStyled>
          <PeerReviewDescriptionStyled>
            Participants are required to write 2 reviews and share with their peers to complete the assessment.
          </PeerReviewDescriptionStyled>
        </PeerReviewMetaStyled>
        <Toggle toggled={peerReviewEnabled} onChange={(e) => onPeerReviewEnabledChange(e.target.checked)} />
      </PeerReviewRootStyled>
    </RootStyled>
  );
};
