import { Node } from 'slate';

import { CustomNode } from './../types';

type PreviousMarkNode = {
  object: 'mark';
  type: 'bold' | 'italic' | 'underline' | 'lineThrough';
};

type PreviousLeafNode = {
  object: 'leaf';
  text: string;
  marks?: PreviousMarkNode[];
};

type PreviousInlineNode = {
  object: 'inline';
  type: 'link';
  data: {
    href: string;
  };
  nodes: Array<PreviousTextNode>;
};

type PreviousTextNode = {
  object: 'text';
  leaves: PreviousLeafNode[];
};

type PreviousBlockNode = {
  object: 'block';
  nodes: Array<PreviousBlockNode | PreviousTextNode>;
  type: 'heading' | 'heading2' | 'paragraph' | 'list-item' | 'bulleted-list';
};

export type PreviousDataModel = {
  object: 'value';
  document: {
    object: 'document';
    nodes: Array<PreviousBlockNode | PreviousTextNode>;
  };
};

export const migrateDataModel = (data: string | null, placeholder?: string): CustomNode[] => {
  const defaultValue: CustomNode[] = [{ children: [{ text: placeholder ? placeholder : '' }] }];

  if (!data) {
    return defaultValue;
  }

  let model: PreviousDataModel | CustomNode[] | undefined;

  try {
    model = JSON.parse(data);
  } catch (error) {
    if (typeof data !== 'string') {
      return defaultValue;
    }

    return [{ children: [{ text: data.trim() }] }];
  }

  if (Node.isNodeList(model)) {
    return model;
  }

  if (!model || !model.object || model.object !== 'value') {
    return defaultValue;
  }

  const nodes = model.document?.nodes;

  if (!nodes) {
    return defaultValue;
  }

  return nodes.map(node => migrateNode(node));
};

const migrateMarks = (markNodes?: PreviousMarkNode[]) => {
  if (!markNodes || markNodes.length === 0) {
    return {};
  }

  const bold = markNodes.find(node => node.type === 'bold');
  const italic = markNodes.find(node => node.type === 'italic');
  const lineThrough = markNodes.find(node => node.type === 'lineThrough');
  const underline = markNodes.find(node => node.type === 'underline');

  return {
    bold: Boolean(bold),
    italic: Boolean(italic),
    lineThrough: Boolean(lineThrough),
    underline: Boolean(underline)
  };
};

const migrateTextNodes = (node: PreviousTextNode): CustomNode[] => {
  return node.leaves.map(leaf => {
    return {
      type: 'leaf',
      text: leaf.text,
      ...migrateMarks(leaf.marks)
    };
  });
};

const migrateLinkNode = (node: PreviousInlineNode): CustomNode => {
  const children = node.nodes.reduce((acc, textNode) => {
    return [...acc, ...migrateTextNodes(textNode)];
  }, [] as CustomNode[]);

  return {
    type: node.type,
    url: node.data ? node.data.href : '',
    children
  };
};

const migrateBlockNode = (node: PreviousBlockNode): CustomNode => {
  const children = node.nodes.reduce((acc, childNode) => {
    if (childNode.object === 'text') {
      return [...acc, ...migrateTextNodes(childNode)];
    }

    return [...acc, migrateNode(childNode)];
  }, [] as CustomNode[]);

  return {
    type: node.type,
    children
  };
};

const migrateNode = (node: PreviousBlockNode | PreviousTextNode | PreviousLeafNode | PreviousInlineNode): CustomNode => {
  if (node.object === 'inline' && node.type === 'link') {
    return migrateLinkNode(node);
  }

  if (node.object === 'block') {
    return migrateBlockNode(node);
  }

  return {
    type: 'leaf',
    text: ''
  };
};
