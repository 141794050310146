import React, { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';

import './fileUpload.scss';
import { api } from '@/utils/lib/api';
import { Button } from '@/components/Button/Button';
import fallback from '@/assets/fallback.png';
import { Icon } from '@/components/Icon';
import loader from '@/assets/loader.svg';
import { GenericErrorMessage } from '../GenericError';
import { Media, MediaFigure, MediaIconWrapper, MediaBody } from '@/components/MediaObject/MediaObject';
import { Overline, BodyMd } from '@/components/Typography/Typography';
import { StyledError } from '@/components/Form/styles';
import { ButtonList } from '@/components/Button/ButtonList';
import { ToolTip, ToolTipText } from '@/components/Tooltip/Tooltip';
import { IconPropType } from '@/ui/Icon';

interface Dimensions {
  height: number;
  width: number;
}

type FileType = 'image' | 'resource' | 'e-learning' | 'audio';

interface Props {
  spaceId?: number;
  path?: string;
  type: FileType;
  minDimensions?: Dimensions;
  isDiscussions?: boolean;
  disabled?: boolean;
  uploaded: (url: string) => void;
  onSpaceChange?: () => void;
  url: string;
  required?: boolean;
  previewData?: { name: string; description: string; fileUrl: string };
}

const IMAGE_SIZE_LIMIT = 4 * 1024 * 1024;
const RESOURCE_AUDIO_SIZE_LIMIT = 200 * 1024 * 1024;
const E_LEARNING_SIZE_LIMIT = 1000 * 1024 * 1024;

type TypeMeta = {
  accept?: string;
  button: string;
  successMessage: string;
  sizeLimit: number;
};

const useTypeMeta = (type: FileType): TypeMeta => {
  const { t } = useTranslation('fileUpload');
  switch (type) {
    case 'image':
      return {
        accept: 'image/png, image/jpeg',
        button: t('Select file'),
        successMessage: t('image-upload-success'),
        sizeLimit: IMAGE_SIZE_LIMIT,
      };
    case 'resource':
      return {
        button: t('Select file'),
        successMessage: t('file-upload-success'),
        sizeLimit: RESOURCE_AUDIO_SIZE_LIMIT,
      };
    case 'audio':
      return {
        accept: '.mp3',
        button: t('Select file'),
        successMessage: t('file-upload-success'),
        sizeLimit: RESOURCE_AUDIO_SIZE_LIMIT,
      };
    case 'e-learning':
      return {
        accept: '.zip',
        button: t('Select file'),
        successMessage: t('file-upload-success'),
        sizeLimit: E_LEARNING_SIZE_LIMIT,
      };
  }
};

const fileTypeIconEnum: { [key: string]: IconPropType } = {
  'application/pdf': 'file-pdf',
  'audio/mpeg': 'file-audio',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
  'application/zip': 'file-archive',
};

const fileTypeEnum: { [key: string]: string } = {
  'application/pdf': 'pdf',
  'audio/mpeg': 'audio',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/zip': 'archive',
};

const humanFileSize = (bytes: number, si = false) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

export const FileUpload = (props: Props): JSX.Element => {
  const initialIdSetRef = useRef(0);
  const { t } = useTranslation('fileUpload');
  const fileInput = createRef<HTMLInputElement>();

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [fileError, setFileError] = useState('');
  const [uploadedFile, setUploadedFile] = useState<undefined | File>(undefined);
  const { accept, button, sizeLimit } = useTypeMeta(props.type);

  // If space is changed the image needs to be uploaded again
  useEffect(() => {
    if (props.spaceId === undefined || initialIdSetRef.current === props.spaceId) return;

    if (initialIdSetRef.current === 0) {
      // Don't react to the initial setting of spaceId
      initialIdSetRef.current = props.spaceId;
      return;
    }

    if (uploadedFile || props.url) {
      props.uploaded('');
      props.onSpaceChange?.();
      setError('');
      setFileError('upload-reset-space');
      setUploadedFile(undefined);
    }
  }, [props.spaceId]);

  const handleClick = () => fileInput.current && fileInput.current.click();

  const checkImageDimensions = (file: File) =>
    new Promise<Dimensions>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = () => reject();
      img.onabort = () => reject();
      img.src = window.URL.createObjectURL(file);
    });

  const uploadSuccess = (file: File) => {
    setUploading(false);
    setError('');
    setUploadedFile(file);
  };

  const uploadFail = (error: Error) => {
    setUploading(false);
    setError(error.message);
    setUploadedFile(undefined);
    setFileError(error.message);
  };

  const validationFail = (message: string) => {
    setUploading(false);
    setFileError(message);
    setUploadedFile(undefined);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;
    // reset img to enable reupload
    if (fileInput.current) {
      fileInput.current.value = '';
    }

    setFileError('');
    setUploading(true);
    setError('');
    setUploadedFile(undefined);

    if (props.spaceId === undefined) {
      validationFail(t('image-upload-space'));
      return;
    }

    if (file.size > sizeLimit) {
      validationFail(t('file-too-large'));
      return;
    }

    if (props.type === 'image' && props.minDimensions) {
      const { width, height } = await checkImageDimensions(file);
      if (width < props.minDimensions.width && height < props.minDimensions.height) {
        validationFail(t('image-dimension-validation-fail'));
        return;
      }
    }

    if (props.type === 'image') {
      api
        .uploadImage(file, props.spaceId, () => undefined)
        .then((response) => {
          props.uploaded(response.path);
        })
        .then(() => uploadSuccess(file))
        .catch(uploadFail);
    } else if (props.type === 'audio') {
      api
        .uploadResource(file, props.spaceId, 'audio', () => undefined)
        .then((response) => {
          props.uploaded(response.path);
        })
        .then(() => uploadSuccess(file))
        .catch(uploadFail);
    } else if (props.type === 'resource') {
      api
        .uploadResource(file, props.spaceId, 'file', () => undefined)
        .then((response) => {
          props.uploaded(response.path);
        })
        .then(() => uploadSuccess(file))
        .catch(uploadFail);
    } else if (props.type === 'e-learning') {
      const formData = new FormData();
      formData.append('file', file);

      api
        .uploadELearningBundle(formData, props.spaceId)
        .then(({ path }) => {
          props.uploaded(path);
        })
        .then(() => uploadSuccess(file))
        .catch(uploadFail);
    }
  };

  let label;
  let preview;

  if (props.type === 'image') {
    preview = (
      <div className="field">
        <div className="control">
          <div styleName="image">
            {uploading && <div styleName="imageInBackgroundLoading" style={{ backgroundImage: `url(${loader})` }} />}
            {!uploading && (
              <div styleName="imageInBackground" style={{ backgroundImage: `url(${props.url || fallback}?width=360)` }} />
            )}
          </div>
        </div>
      </div>
    );
  } else if (props.type === 'resource' || props.type === 'e-learning' || props.type === 'audio') {
    label = (
      <Trans t={t} i18nKey="file-upload">
        File upload
      </Trans>
    );

    preview = uploadedFile ? (
      <>
        <div className="label">{t('File preview')}</div>
        <Media alignCenter bordered>
          <MediaFigure>
            <MediaIconWrapper>
              <Icon icon={fileTypeIconEnum[uploadedFile.type] || 'file'} />
            </MediaIconWrapper>
          </MediaFigure>
          <MediaBody>
            <Overline>
              {fileTypeEnum[uploadedFile.type] || 'unknown'} - {humanFileSize(uploadedFile.size)}
            </Overline>
            <BodyMd>{uploadedFile.name}</BodyMd>
          </MediaBody>
        </Media>
      </>
    ) : (
      props.previewData && (
        <>
          <br />
          <div className="label">{t('File preview')}</div>
          <Media alignCenter bordered>
            <MediaFigure>
              <MediaIconWrapper>
                <Icon icon={props.previewData.fileUrl.indexOf('pdf') !== -1 ? 'file-pdf' : 'file-alt'} />
              </MediaIconWrapper>
            </MediaFigure>
            <MediaBody>
              <Overline>{props.previewData.name}</Overline>
              <BodyMd>{props.previewData.description}</BodyMd>
            </MediaBody>
          </Media>
        </>
      )
    );
  }

  const requiredUpload = props.required ? (
    <span style={{ color: 'var(--color-monza)', fontSize: 'var(--font-size-5)', height: 12, lineHeight: 1, paddingLeft: 5 }}>
      *
    </span>
  ) : null;

  return (
    <>
      {!error && props.type === 'image' && preview}
      <div className="field">
        {Boolean(!props.isDiscussions && label) && (
          <label id="file" className={clsx('label', props.required && 'required')}>
            {label}
          </label>
        )}
        <div className="control" styleName="uploadWrapper">
          <ButtonList>
            <Button
              $type="secondary"
              onClick={handleClick}
              disabled={props.disabled || !props.spaceId}
              $loading={uploading}
              $icon="folder"
            >
              {button}
              {requiredUpload}
            </Button>
            <input
              type="file"
              ref={fileInput}
              onChange={handleChange}
              accept={accept}
              required={props.required}
              aria-required={props.required ? 'true' : 'false'}
              aria-labelledby="file"
            />
            <ToolTip aria-describedby="help-info" style={{ fontSize: 'var(--font-size-6)' }}>
              <Icon icon="info-circle" />
              <ToolTipText id="help-info">
                <Trans t={t} i18nKey={`help-${props.type}`} components={[<span key={0} />]} />
              </ToolTipText>
            </ToolTip>
          </ButtonList>
          {!props.spaceId && <StyledError>{t('image-upload-space')}</StyledError>}
          {fileError && <StyledError>{t(fileError!)}</StyledError>}
          {error &&
            (t(error, { defaultValue: '', ns: 'serverMessages' }) ? (
              <StyledError>{t(error, { ns: 'serverMessages' })}</StyledError>
            ) : (
              /* TODO remove when all /upload endpoints send sensible error messages */
              <GenericErrorMessage />
            ))}
        </div>
      </div>
      {!error && props.type !== 'image' && preview}
    </>
  );
};
