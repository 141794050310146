import { useEffect, useState } from 'react';

import { PracticalAssessment } from '../practicalAssessment/types';
import { PageId } from '@/administration/pages/Course/store/types';
import { usePracticalAssessment } from '../practicalAssessment/usePracticalAssessment';
import { Page } from '@/administration/pages/Course/store/page/types';
import { useXAPISession } from '@/store/xapi/useXAPISession';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { transformProgressWithRegistration } from '@/administration/pages/Course/store/progress/transform';
import { useXAPIClient } from '@/store/xapi/useXAPIClient';
import { createResultPage, updatePage } from '@/administration/pages/Course/store/page/transform';
import { useModularLearningViewer } from '@/pages/ModularLearningDeprecated/useModularLearningViewer';

const AUTO_NAVIGATION_TIMEOUT_DELAY = 1000;

export type UsePracticalAssessmentViewerHook = (id: number) => {
  practicalAssessment?: PracticalAssessment;
  pages: Page[];
  progress?: Progress;
  loadingLearning: boolean;
  onPageChange: (page: Page) => void;

  onSetPageActive: (pageId: PageId) => void;
  onSetPrevPageActive: () => void;
  onSetNextPageActive: () => void;
  onSetPageActiveByIndex: (index: number) => void;

  prevPageActiveCount: number;
  pageActiveCount: number;
  pageTotalCount: number;

  startAssessment: () => void;
  loadingStartAssessment: boolean;
  loadingProgressInitialized: boolean;

  isDrawerOpen: boolean;
  setDrawerOpen: (p: boolean) => void;
};

export const usePracticalAssessmentViewer: UsePracticalAssessmentViewerHook = (id: number) => {
  const { data, loading: loadingLearning } = usePracticalAssessment(id, { includeProgress: true, includeLocations: true });

  const { session, loadingSession, register, refetchSession } = useXAPISession(Number(data?.practicalAssessment?.id));
  const xapiClient = useXAPIClient(session);

  const {
    pages,
    isDrawerOpen,
    pageTotalCount,
    pageActiveCount,
    prevPageActiveCount,
    setPages,
    setDrawerOpen,
    onSetPageActive,
    onSetPrevPageActive,
    onSetNextPageActive,
    onSetPageActiveByIndex,
  } = useModularLearningViewer({
    onPageCompleted: (p) => Boolean(p.completed),
    onLearningCompleted: () => {
      if (data?.practicalAssessment?.id) {
        xapiClient.setLearningCompleted(Number(data.practicalAssessment.id), data?.module?.name || '');
      }
    },
  });

  const [loadingStartAssessment, setLoadingStartAssessment] = useState(false);
  const [loadingProgressInitialized, setLoadingProgressInitialized] = useState(false);

  useEffect(() => {
    if (!data?.pages) return;

    setPages(data.pages);
  }, [loadingLearning]);

  useEffect(() => {
    if (loadingProgressInitialized || loadingLearning || loadingSession) return;

    setLoadingProgressInitialized(true);
  }, [loadingLearning, loadingSession, loadingProgressInitialized]);

  useEffect(() => {
    if (!pages.length) return;

    const allAssessmentsSubmitted = pages.every((page) => page.completed === true);

    if (allAssessmentsSubmitted) {
      const resultsPage = createResultPage({ course: data?.practicalAssessment });

      if (!resultsPage) return;

      setPages((prev) => [...prev, resultsPage]);

      if (resultsPage && !resultsPage.active) {
        setTimeout(() => onSetPageActive({ pageId: resultsPage.id }), AUTO_NAVIGATION_TIMEOUT_DELAY);
      }
    }
  }, [pages]);

  const onPageChange = (page: Page): void => {
    xapiClient.setLearningPageCompleted(Number(page?.remoteId), data?.module?.name || '');

    setPages((pages) =>
      pages.map((curPage) => (curPage.id !== page.id ? curPage : updatePage(curPage, { completed: true } as Partial<Page>)))
    );
  };

  const handleStartAssessment = async () => {
    try {
      if (data?.progress?.isCompleted || session) return;

      setLoadingStartAssessment(true);

      await refetchSession();

      if (session) return;

      await register();

      await refetchSession();
    } catch (e) {
    } finally {
      setLoadingStartAssessment(false);
    }
  };

  return {
    practicalAssessment: data?.practicalAssessment,
    pages,
    progress: transformProgressWithRegistration(data?.progress, session?.registration),

    startAssessment: handleStartAssessment,
    onSetPageActive: (pageId: PageId) => onSetPageActive({ pageId }),
    onSetPrevPageActive,
    onSetNextPageActive,
    onSetPageActiveByIndex,
    pageActiveCount,
    prevPageActiveCount,
    pageTotalCount,
    loadingLearning: loadingLearning,
    loadingProgressInitialized: loadingProgressInitialized,
    loadingStartAssessment,
    isDrawerOpen,
    setDrawerOpen,
    onPageChange,
  };
};
