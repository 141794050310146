import React from 'react';
import { Control, Controller, FieldValues, Validate } from 'react-hook-form';

import { Input } from '@/ui/TextField';
import { TextFieldName } from '../../../constants';

export const Hours = ({ control, validate }: { control: Control<FieldValues>; validate: Validate<string> }): JSX.Element => (
  <Controller
    control={control}
    rules={{
      validate,
    }}
    name={TextFieldName.Hours}
    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
      <Input
        onChange={onChange}
        name={name}
        value={value}
        type="number"
        fullWidth
        label="Hours"
        min={0}
        error={
          error && {
            message: 'Enter number of hours',
          }
        }
      />
    )}
  />
);
