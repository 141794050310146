import { useEffect, useState } from 'react';

import { BlockType } from '../../../../../types/learning';
import { AudioMeta, BlockMeta, ImageMeta, ResourceMeta, TextMeta, VideoLinkMeta, VideoUploadMeta } from './blocks/types';

// clicking slate.js editor generates a document with a paragraph containing an empty string
const EMPTY_EDITOR =
  '{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","leaves":[{"object":"leaf","text":"","marks":[]}]}]}]}}';

export function useBlockValidation(type: BlockType, blockMeta: BlockMeta): boolean {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(validateBlock(type, blockMeta));
  }, [blockMeta]);

  return isValid;
}

export function editorHasContent(editorValue: string): boolean {
  return Boolean(editorValue) && editorValue !== EMPTY_EDITOR;
}

const OPTIONAL_THUMBNAIL = true; // vimeo transcoding can take forever
/**
 * Validation mirroring the blockTypeValidatorSchemas in cls-api.
 */
export function validateBlock(type: BlockType, blockMeta: BlockMeta): boolean {
  switch (type) {
    case BlockType.PREAMBLE:
    case BlockType.TEXT:
      const { text } = blockMeta as TextMeta;
      return editorHasContent(text);
    case BlockType.IMAGE:
      const { image, description } = blockMeta as ImageMeta;
      return Boolean(image) && Boolean(description);
    case BlockType.VIDEO:
      const { thumbnail, vimeoId, caption: videoUploadCaption } = blockMeta as VideoUploadMeta;
      const optionalThumbnail = OPTIONAL_THUMBNAIL || Boolean(thumbnail);
      return optionalThumbnail && Boolean(vimeoId) && Boolean(videoUploadCaption);
    case BlockType.VIDEO_LINK:
      const { type: videoType, url, embedUrl, caption: videoLinkCaption } = blockMeta as VideoLinkMeta;
      return Boolean(videoType) && Boolean(url) && Boolean(embedUrl) && Boolean(videoLinkCaption);
    case BlockType.RESOURCE:
      const { url: resourceUrl, description: resourceDescription } = blockMeta as ResourceMeta;
      return Boolean(resourceUrl) && Boolean(resourceDescription);
    case BlockType.AUDIO:
      const { url: audioUrl, description: audioDescription } = blockMeta as AudioMeta;
      return Boolean(audioUrl) && Boolean(audioDescription);
    default:
      console.error('No validation exists for blocktype', type);
      return false;
  }
}
