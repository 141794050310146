import styled, { css } from 'styled-components';

import { BadgeLabel } from '@/ui/Label/variants/BadgeLabel';

import { TimeState } from '.';

/** Root CSS */
const RootCommonCSS = css`
  display: inline-flex;
  flex-direction: column;
  padding: 8px 16px;
  border-radius: 16px;
  transition: all 0.3s;
  align-items: center;
  gap: 2px;
`;

const RootDisabledCSS = css`
  cursor: not-allowed;
`;

const RootHoverCSS = css<{ selected?: boolean }>`
  &:hover {
    cursor: pointer;
    background: ${({
      selected,
      theme: {
        colors: {
          states: { hover5, hover6 },
        },
      },
    }) => (selected ? hover6 : hover5)};
  }
`;

/** TITLE CSS */
const TitleCommonCSS = css`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s;
  ${(p) => p.theme.typography.caption};
`;
const TitlePastCSS = css<{ selected?: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.colors.primary.onMain : theme.colors.neutral.outline2)};
  background: ${({ selected, theme }) => (selected ? theme.colors.neutral.outline2 : 'none')};
`;

const TitleCurrentCSS = css<{ selected?: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.colors.primary.onMain : theme.colors.primary.outline)};
  background: ${({ selected, theme }) => (selected ? theme.colors.primary.outline : 'none')};
`;

const TitleFutureCSS = css`
  color: ${({ theme }) => theme.colors.neutral.disabled};
`;

/** Components */
export const RootStyled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  ${RootCommonCSS};
  ${({ disabled }) => (disabled ? RootDisabledCSS : RootHoverCSS)}
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 38px;
  justify-content: flex-end;
  position: relative;
`;

export const TitleStyled = styled.div<{ state: TimeState; selected?: boolean }>`
  ${TitleCommonCSS};
  ${({ state }) => {
    switch (state) {
      case 'current':
        return TitleCurrentCSS;
      case 'future':
        return TitleFutureCSS;
      case 'past':
      default:
        return TitlePastCSS;
    }
  }}
`;

export const ReflectionMarker = styled(BadgeLabel)`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.error.outline};
`;

export const ProgressLabel = styled.div`
  ${(p) => p.theme.typography.caption};
  color: ${(p) => p.theme.colors.neutral.outline2};
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;
