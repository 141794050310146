import { useParams } from 'react-router-dom';

import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import {
  LockedLearningWarningModal,
  useLockedLearningWarningModalProps,
} from '@/administration/pages/Journey/common/modals/LockedLearningWarningModal';
import { PracticalAssessmentDetails } from './PracticalAssessmentDetails';
import { PracticalAssessmentStart } from './PracticalAssessmentStart';
import { usePracticalAssessmentViewer } from './store/practicalAssessmentViewer/usePracticalAssessmentViewer';

export const PracticalAssessment = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const parsedSurveyId = id ? parseInt(id) : NaN;

  const props = usePracticalAssessmentViewer(Number(id));

  const { currentItem, journeyTitle, journeyId } = useJourneyEnhancedNavigation({
    id: parsedSurveyId,
  });

  const learningLockedModalProps = useLockedLearningWarningModalProps({
    parentJourneyId: journeyId,
    parentJourneyTitle: journeyTitle,
    isCurrentItemLock: currentItem?.isLocked,
  });

  return (
    <>
      {learningLockedModalProps && <LockedLearningWarningModal {...learningLockedModalProps} />}
      {props.progress?.isStarted ? <PracticalAssessmentDetails {...props} /> : <PracticalAssessmentStart {...props} />}
    </>
  );
};
