import React, { FC } from 'react';

import { UpdateUserSelfTrackedLearning } from '@/component/UserSelfTrackedLearning/components/UpdateUserSelfTrackedLearning/UpdateUserSelfTrackedLearning';
import { AddUserSelfTrackedLearning } from '@/component/UserSelfTrackedLearning/components/AddUserSelfTrackedLearning/AddUserSelfTrackedLearning';

type UserSelfTrackedLearningProps = {
  userSelfTrackedLearningId?: number;
  isEditing?: boolean;
  onClose?: () => void;
};

export const UserSelfTrackedLearning: FC<UserSelfTrackedLearningProps> = ({ userSelfTrackedLearningId, isEditing, onClose }) => {
  if (isEditing) {
    return <UpdateUserSelfTrackedLearning userSelfTrackedLearningId={userSelfTrackedLearningId} isEditing onClose={onClose} />;
  }

  return <AddUserSelfTrackedLearning onClose={onClose} />;
};
