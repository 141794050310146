import styled from 'styled-components';

import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { BodyMd } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';
import { Tag } from '@/components/Tag/Tag';

export const StyledDropdownRowStandard = styled(BodyMd)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${cvar('gap-xxs')} ${cvar('gap-sm')};
  min-height: ${BUTTON_HEIGHT_ENUM.default}rem;
  position: relative;
  cursor: pointer;
  white-space: pre-wrap;
  border: ${cvar('border-style')};
  border-bottom: 0;
  color: ${cvar('color-text')};

  &:hover {
    background-color: ${cvar('color-wild-sand')};
  }

  &:focus {
    background-color: ${cvar('color-wild-sand')};
    outline: none;
  }

  & ${Tag} {
    margin-left: ${cvar('gap-xxs')};
  }
`;
