import Lottie, { LottieComponentProps } from 'lottie-react';
import { useEffect, useState, AnimationEvent } from 'react';
import styled, { css } from 'styled-components';

const AnimationStyled: React.FC<LottieComponentProps & { hidden?: boolean }> = styled(Lottie)`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};
`;

export type LottieAnimationProps = LottieComponentProps & {
  hideWhenStill?: boolean;
  isPlaying?: boolean;
  animationReady?: boolean;
  onPlayingUpdate?: (playing: boolean) => void;
};

export const Animation = ({
  hideWhenStill,
  onComplete,
  isPlaying,
  onPlayingUpdate,
  animationReady,
  ...props
}: LottieAnimationProps): JSX.Element => {
  const [hidden, setHidden] = useState(hideWhenStill);

  useEffect(() => {
    if (!animationReady) return;

    // When playing we don't hide it
    if (isPlaying) {
      setHidden(false);
    } else if (hideWhenStill) {
      // when not playing and hiding on still flag is provided, we hide it
      setHidden(true);
    }
  }, [isPlaying, hideWhenStill, animationReady]);

  const handleComplete = (e: AnimationEvent<Element>) => {
    onComplete?.(e);
    onPlayingUpdate?.(false);
  };

  return <AnimationStyled {...props} hidden={hidden} onComplete={handleComplete} />;
};

export { useLottieAnimation } from './useLottieAnimation';
