import styled from 'styled-components';

import { Button } from '@/ui/Button';

export const RootStyled = styled.div`
  padding: 16px 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 4px;
  display: flex;
  gap: 16px;
`;

export const InformerBox = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ReflextionTextStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ContentBoxStyled = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: fit-content;
`;

export const ReflectNowButton = styled(Button)`
  display: inline-flex;
  padding: 0 0 0 4px;
  ${({ theme }) => theme.typography.body3};
`;
