import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';

import { LearningLoopV3QueryResult, UseLearningLoopsHook } from './types';
import { transformResults } from './utils';

export const LEARNING_LOOPS_QUERY_OPERATION_NAME = `learningLoops`;
export const LEARNING_LOOPS_QUERY = gql`
  query ${LEARNING_LOOPS_QUERY_OPERATION_NAME}($assignmentId: Int!, $userId: Int!, $pagination: Pagination) {
    learningLoops(filter: { assignmentId: $assignmentId, userId: $userId }, pagination: $pagination) {
      data {
        id
        assignmentId
        userId
        estimatedEffort
        startAt
        endAt
        isActive
        createdAt
        updatedAt
        canReflect
        recommendedEffort
        activitiesAggregated {
          participation{
            id
            objectId
            catalogObjectId
            completedAt
            endAt
            expiryAt
            isCompleted
            plannedDuration
            userId
            userEffort
          }
        }
        reflection {
          id
          learningLoopId
          description
          applied
          createdAt
          updatedAt
        }
      }
      limit
      page
      total
    }
  }
`;

export const useLearningLoops: UseLearningLoopsHook = ({ assignmentId, userId }) => {
  const variables = {
    assignmentId: assignmentId,
    userId: userId,
    pagination: {
      limit: -1,
      page: 1,
    },
  };
  const { data, loading, error, refetch, client, networkStatus } = useQuery<LearningLoopV3QueryResult>(LEARNING_LOOPS_QUERY, {
    skip: !userId || !assignmentId,
    variables,
  });

  return {
    data: data?.learningLoops.data ? transformResults(data?.learningLoops.data) : undefined,
    loading,
    error,
    networkStatus,
    limit: data?.learningLoops.limit ?? 0,
    page: data?.learningLoops.page ?? 0,
    total: data?.learningLoops.total ?? 0,
    refetch: async () => {
      const cachedLoops =
        client.cache.readQuery<LearningLoopV3QueryResult>({
          query: LEARNING_LOOPS_QUERY,
          variables,
        })?.learningLoops.data ?? [];

      await refetch();

      cachedLoops.forEach((loop) => {
        const cachedEffort = cachedLoops.find(({ id }) => id === loop.id)?.estimatedEffort;
        if (cachedEffort === undefined) {
          return;
        }
        client.cache.modify({
          id: client.cache.identify({ __typename: 'LearningLoop', id: loop.id }),
          fields: {
            estimatedEffort: () => cachedEffort,
          },
        });
      });
    },
  };
};
