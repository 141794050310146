import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { TextArea } from '@/ui/TextField';

export const DescriptionField = ({ control }: { control: Control<FieldValues> }): JSX.Element => (
  <Controller
    control={control}
    name="description"
    rules={{ required: true }}
    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
      <TextArea
        onChange={onChange}
        name={name}
        value={value}
        fullWidth
        label="What is your main takeaway?"
        rows={3}
        error={error && { message: 'Describe your main takeway' }}
      />
    )}
  />
);
