import { useNavigate } from 'react-router-dom';

import { UseSurveyViewerHook } from './store/surveyViewer/useSurveyViewer';
import { ModularLearningStartLayout } from '../ModularLearningDeprecated/ModularLearningStart';
import { ModularLearningStartContent } from '../ModularLearningDeprecated/ModularLearningStart/Content';

export const SurveyStart = (props: ReturnType<UseSurveyViewerHook>): JSX.Element => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const loading = props.loadingLearning || !props.loadingProgressInitialized;
  return (
    <>
      {loading ? (
        <ModularLearningStartLayout.LoadingState />
      ) : (
        <ModularLearningStartLayout
          title={props.survey?.title || ''}
          content={
            <ModularLearningStartContent
              title={props.survey?.title}
              teaser={props.survey?.teaser}
              imageSrc={props.survey?.image}
              effort={{
                hours: props.survey?.effortHours,
                minutes: props.survey?.effortMinutes,
              }}
              onStartClick={props.startSurvey}
              startCourseLoading={props.loadingStartCourse}
            />
          }
          onCloseClick={handleGoBack}
        />
      )}
    </>
  );
};
