import { trackEvent } from './countly';

/**
 * Events
 */

export const trackEnrollEventClicked = (): void => trackEvent({ key: 'Clicked enroll to event' });

export const trackUnEnrollEventClicked = (): void => trackEvent({ key: 'Clicked cancel enrollment from event' });

export const trackWaitlistEventClicked = (): void => trackEvent({ key: 'Clicked waitlist to event' });

export const trackSaveToMyLearningsEventAdded = (): void =>
  trackEvent({ key: 'Added save to my learning', segmentation: { source: 'Events' } });

export const trackSaveToMyLearningsEventRemoved = (): void =>
  trackEvent({ key: 'Removed save to my learning', segmentation: { source: 'Events' } });
