import { useState, ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Product } from '../../../../../types/learning/learning-catalog';
import { usePurchaseProduct } from '../../../../../store/learning-catalog';
import { useInventory } from '../../../../../store/inventory';
import { SharedSnackbarContext, SnackbarType } from '../../../../../component/SharedSnackbar/SharedSnackbar';
import { FieldsValues, DEFAULT_FIELD_VALUES, CUSTOM_FIELDS_VALUES } from './FieldsValues';
import { Button } from '@/components/Button/Button';
import { useModalContext } from '@/components/Modal/ModalContext';
import { ButtonList } from '@/components/Button/ButtonList';

export interface ApplicationFormProps {
  product: Product;
  selectedVariantId?: number;
}

export const ApplicationForm = ({ product, selectedVariantId }: ApplicationFormProps): JSX.Element => {
  const { t } = useTranslation('application');
  const [purchaseProduct, { loading: loadingPurchase }] = usePurchaseProduct(product.id);
  const { refetch } = useInventory();
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { hideModal } = useModalContext();
  const navigate = useNavigate();
  const useCustomFieldsValues = product.id in CUSTOM_FIELDS_VALUES;

  const [fieldsValues, setFieldsValues] = useState(
    useCustomFieldsValues ? CUSTOM_FIELDS_VALUES[product.id] : DEFAULT_FIELD_VALUES
  );

  const updateFieldsValues = (index: number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => {
    const newFieldsValues = fieldsValues.slice();
    newFieldsValues[index].value = e.currentTarget.value;
    setFieldsValues(newFieldsValues);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        const note = JSON.stringify(fieldsValues);

        const purchasedProduct: { id: number; productVariantId?: number } = { id: product.id };
        if (selectedVariantId) purchasedProduct.productVariantId = selectedVariantId;
        purchaseProduct({ variables: { products: [purchasedProduct], note } }).then(async () => {
          if (product.meta.CONFIRM_MESSAGE) {
            openSnackbar({
              message: product.meta.CONFIRM_MESSAGE,
              type: SnackbarType.SUCCESS,
              isDismissive: true
            });
          }
          await refetch();
          navigate('/learning');
          hideModal();
        });
      }}
    >
      <FieldsValues useCustom={useCustomFieldsValues} fieldsValues={fieldsValues} updateFieldsValues={updateFieldsValues} />
      <ButtonList align="center" padded="vertical">
        <Button $type="primary" type="submit" disabled={loadingPurchase}>
          {t('Submit application')}
        </Button>
      </ButtonList>
    </form>
  );
};
