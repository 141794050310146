import { makeNamespacedComponent } from '@/ui/utils';
import { TabButton } from './TabButton';
import { TabLink, TabLoadingLink } from './TabLink';
import { Tabs as Tabs_ } from './Tabs';
import {
  Content as UnderlinedTabsContent,
  Tabs as _UnderlinedTabs,
  ContentsList as UnderlinedTabsContentsList,
  TriggersList as UnderlinedTabsTriggersList,
  Trigger as UnderlinedTabsTrigger,
} from './UnderlinedTabs';

export const Tabs = makeNamespacedComponent(Tabs_, { Button: TabButton, Link: TabLink, LoadingState: TabLoadingLink });
export const UnderlinedTabs = makeNamespacedComponent(_UnderlinedTabs, {
  Trigger: UnderlinedTabsTrigger,
  TriggersList: UnderlinedTabsTriggersList,
  ContentsList: UnderlinedTabsContentsList,
  Content: UnderlinedTabsContent,
});
export { FadeInContents } from './FadeInContents';
