import styled from 'styled-components';
import { ModalUnstyled } from '@mui/base';

export const RootContainer = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
