import { useCallback } from 'react';

import { useQueryState } from '@/hooks/useQueryState';

export function useQueryBooleanState(
  key: string,
  initialValue?: boolean
): [boolean, (value: boolean) => void, (value: boolean) => string] {
  const [value, setValue, searchURLByValue] = useQueryState(key, initialValue ? 'true' : 'false');

  const boolValue = String(value).toLowerCase() === 'true';
  const setBoolValue = useCallback((val: boolean) => setValue(val ? 'true' : 'false'), [setValue]);

  const getSearchUrl = useCallback((val: boolean) => searchURLByValue(val ? 'true' : 'false'), [searchURLByValue]);

  return [boolValue, setBoolValue, getSearchUrl];
}
