import React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { useLearningType } from '@/store/v2/learnings/useLearningType';
import { RLearningType } from '@/store/v2';
import { CoursePage } from '@/pages/Course';
import { SurveyPage } from '@/pages/Survey';
import { PracticalAssessment } from '@/pages/PracticalAssessment';
import { Learning } from '@/pages/learning/Learning/Learning';
import { LoadingHero } from '@/component/LoadingHero';
import { LayoutWrapper } from '@/component/App/component/LayoutWrapper/LayoutWrapper';
import { LiveEvent } from '@/pages/LiveEvent';

export const LearningViewPage = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const parsedId = id ? parseInt(id) : NaN;

  const { type, loading, error } = useLearningType({ id: parsedId });

  if (!parsedId) {
    navigate('/404');
    return <></>;
  }

  if (error) {
    navigate('/404');
    return <>Error</>;
  }

  if (loading) return <LoadingHero />;

  if (type === RLearningType.PracticalAssessment) return <PracticalAssessment />;
  if (type === RLearningType.Survey) return <SurveyPage />;
  if (type === RLearningType.Course) return <CoursePage />;

  return (
    <Routes>
      <Route element={<LayoutWrapper />}>
        <Route path="*" element={type === RLearningType.LiveEvent ? <LiveEvent /> : <Learning />} />
      </Route>
    </Routes>
  );
};
