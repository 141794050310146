import React from 'react';

import './video.scss';
import { formatSeconds } from '../../../../../utils/time';
import { Icon } from '@/components/Icon';

export function getThumbnail(embedUrl: string, type?: string): string {
  if (type === 'youtube') {
    // format: //www.youtube.com/embed/lL42O2p1nx8
    const youtubeId = embedUrl.split('/').slice(-1)[0];
    return `https://i.ytimg.com/vi/${youtubeId}/mqdefault.jpg`;
  }
  return '';
}

interface Props {
  thumbnail?: string;
  duration?: number;
  caption: string;
  onClickPlay: () => void;
}

export const VideoThumbnail = ({ thumbnail, duration, caption, onClickPlay }: Props): JSX.Element => {
  return (
    <div className="image is-background is-16by9">
      {thumbnail ? (
        <div className="image-background" style={{ backgroundImage: `url(${thumbnail})` }} />
      ) : (
        <div className="image-background" style={{ backgroundColor: 'black' }} />
      )}
      <div style={{ position: 'absolute', textAlign: 'left', top: 25, left: 25 }}>
        <span className="overline">VIDEO{Boolean(duration) && ` - ${formatSeconds(duration || 0)}`}</span>
        <br />
        <span className="header4 has-text-white">{caption}</span>
      </div>
      <div styleName="play-button">
        <Icon icon="play-circle" size="6x" onClick={onClickPlay} />
      </div>
    </div>
  );
};
