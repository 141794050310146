import React, { forwardRef, ReactNode, useEffect } from 'react';

import { Backdrop, Close, MainBox, OpenCloseTransition, RootContainer } from './atoms';
import { Progress } from './atoms/Progress';
import { ModalProps } from './types';

const ConditionalWrapper = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    condition: boolean;
    wrapper: (children: React.ReactNode) => JSX.Element;
  }
>(({ children, condition, wrapper }, _) => (condition ? wrapper(children) : <>{children}</>));
ConditionalWrapper.displayName = 'ConditionalWrapper';

export const Modal = ({
  open,
  children,
  onClose,
  progress,
  closeOnEscapeKey = true,
  size,
  hasTransition = true,
  className,
}: ModalProps): JSX.Element => {
  useEffect(() => {
    if (closeOnEscapeKey && onClose) {
      const close = ({ key }: KeyboardEvent) => key === 'Escape' && onClose();
      document.addEventListener('keyup', close);
      return () => document.removeEventListener('keyup', close);
    }
  }, [onClose, closeOnEscapeKey]);

  return (
    <RootContainer
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      // We need a loose type here due to MUI's unprecise type defs.
      // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
      slotProps={{ backdrop: { open } as any, root: { 'data-testid': 'modalRoot' } as any }}
      className={className}
    >
      <ConditionalWrapper
        condition={hasTransition}
        wrapper={(children) => (
          <OpenCloseTransition in={open}>
            <>{children}</>
          </OpenCloseTransition>
        )}
      >
        <>
          <MainBox size={size} tabIndex={-1}>
            {progress && <Progress animationStartPercentage={progress.from} value={progress.to} rounded={false} animated />}
            {onClose && <Close onClick={onClose} />}
            {children}
          </MainBox>
        </>
      </ConditionalWrapper>
    </RootContainer>
  );
};
