export function deepMerge<T extends Record<string, any>>(obj1: Partial<T> | undefined, obj2: Partial<T> | undefined): any {
  const result = { ...obj1 };

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if ((obj2?.[key] as any) instanceof Object && (obj1?.[key] as any) instanceof Object) {
        result[key] = deepMerge(obj1?.[key], obj2[key]);
      } else {
        result[key] = obj2[key];
      }
    }
  }

  return result;
}
