import styled from 'styled-components';

import { LinearProgressBar } from '@/ui/ProgressBar';
import { LearningProgress } from './types';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { MetaLabel } from '@/ui/Label';

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 354px;
  justify-content: flex-end;
`;

const ProgressWrapStyled = styled.div`
  width: 80px;
`;

const TimeLeftStyled = styled.div`
  color: ${({ theme }) => theme.colors.neutral.onContainer};
`;

const CompletedLabel = styled(MetaLabel)`
  & p {
    ${({ theme }) => theme.typography.body3};
  }
`;

export const Progress = ({ row: { plannedDuration, userEffort, completed } }: { row: LearningProgress }): JSX.Element => {
  const timeLeft = formatToHoursAndMinutesFromSeconds(plannedDuration - userEffort);

  const progress = userEffort / Math.max(plannedDuration, 1);

  return (
    <RootStyled>
      {completed ? (
        <CompletedLabel icon="check" text="Completed" />
      ) : (
        <>
          <TimeLeftStyled>{timeLeft} left</TimeLeftStyled>
          <ProgressWrapStyled>
            <LinearProgressBar value={progress} maxValue={1} animated />
          </ProgressWrapStyled>
        </>
      )}
    </RootStyled>
  );
};
