import { Fragment } from 'react';

import { Wrapper, Item, PhantomItem, Separator } from './styles';
import { BreadcrumbDefinition } from './types';

type BreadcrumbsProps = {
  items: BreadcrumbDefinition[];
  visible?: boolean;
};

export const Breadcrumbs = ({ items, visible = true, className }: WithClassNameProp<BreadcrumbsProps>): JSX.Element => (
  <Wrapper className={className} visible={visible}>
    {items.map(({ path, search, title }) => (
      <Fragment key={JSON.stringify([path, title])}>
        <Item to={{ pathname: path, search }}>{title}</Item>
        <Separator />
      </Fragment>
    ))}
    <PhantomItem />
  </Wrapper>
);
