import React, { InputHTMLAttributes } from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';

import { Icon } from '@/components/Icon';
import { StyledCheckbox, StyledCheckboxControl, StyledCheckboxInput, StyledCheckboxLabel } from './styles';
import { HandleNumber, HandleString } from '../../types';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  valueAsNumber?: boolean;
  value: string | number;
  fullwidth?: boolean;
  handleSideEffect?: HandleString | HandleNumber;
}

export function Checkbox({
  name,
  children,
  required,
  fullwidth,
  value,
  valueAsNumber,
  handleSideEffect,
  ...rest
}: CheckboxProps): JSX.Element {
  const { register } = useFormContext();

  const registerOptions: RegisterOptions = { valueAsNumber, required };

  const { onChange, ...registered } = register(`${name}`, { ...registerOptions });

  return (
    <StyledCheckbox $fullwidth={fullwidth}>
      <StyledCheckboxInput>
        <input
          type="checkbox"
          {...rest}
          value={value}
          required={required}
          {...registered}
          onChange={e => {
            const returnValue = valueAsNumber ? parseInt(e.target.value, 10) : e.target.value;
            onChange(e);
            if (handleSideEffect) {
              valueAsNumber
                ? (handleSideEffect as HandleNumber)(returnValue as number)
                : (handleSideEffect as HandleString)(returnValue as string);
            }
          }}
        />
        <StyledCheckboxControl>
          <Icon icon="check" />
        </StyledCheckboxControl>
      </StyledCheckboxInput>
      <StyledCheckboxLabel>{children}</StyledCheckboxLabel>
    </StyledCheckbox>
  );
}
