import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import cloneDeep from 'lodash.clonedeep';
import { ApolloError } from '@apollo/client/errors';
import { NetworkStatus, NextLink, Operation } from '@apollo/client/core';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { NavigateFunction } from 'react-router';

import { realmFeatureTogglesQuery } from '@/store/feature-toggles';
import { realmConfigQuery } from '@/store/realms';
import { config } from '../config';
import { Lng } from '../i18n/resources';
import { SnackbarContent, SnackbarType } from '../component/SharedSnackbar/SharedSnackbar';
import i18n from '../i18n/i18n';
import { getErrorResponseMessages, getTranslatedErrorMessages } from '../utils/gqlErrors';

export interface HookResult {
  error?: ApolloError;
  loading: boolean;
  networkStatus: NetworkStatus;
  refetch: (variables?: Partial<Record<string, any>>) => Promise<ApolloQueryResult<any>>;
}

const customFetch = (input: RequestInfo | URL, options?: RequestInit) => {
  let operationName = 'unknown';
  try {
    if (typeof options?.body === 'string') {
      const parsedOptions = JSON.parse(options?.body as string);
      if (Array.isArray(parsedOptions)) {
        operationName = parsedOptions.map((option: any) => option.operationName).join(',');
      } else {
        operationName = parsedOptions.operationName;
      }
    } else if (options?.body instanceof FormData) {
      operationName = JSON.parse(options.body.get('operations') as string).operationName;
    }
  } catch (error) {
    console.error(error, options?.body);
  }

  return fetch(`${config.frontendApiRoute}/graphql?opname=${operationName}`, {
    ...options,
    headers: {
      ...options?.headers,
      /**
       * apollo-require-preflight is required to work around csrfPrevention.
       *
       * @see https://github.com/apollographql/apollo-server/issues/6433
       */
      'apollo-require-preflight': 'true',
      'accept-language': localStorage.getItem('i18nextLng') || Lng.en,
    },
  });
};

const httpLink = createUploadLink({ fetch: customFetch, credentials: 'include' });

interface LinkOptions {
  openSnackbar: (param: SnackbarContent) => void;
}

const IGNORED_QUERY_ERRORS = [
  'getTotalTimeSpentPerProvider',
  'providerAccessProductId',
  'issuedLicense',
  'learningById',
  'learningTypeById',
];
const PUBLIC_ROUTES = ['/login', '/inject-reflection', '/terms', '/privacy', '/about'];

function isAuthFlowRoute(path: string): boolean {
  for (const route of PUBLIC_ROUTES) {
    if (path.indexOf(route) === 0) return true;
  }
  return false;
}

const baseOmitTypeName = (value: any) => {
  if (!value) return value;

  if (typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    value.forEach(baseOmitTypeName);
  }

  delete value['__typename'];

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      baseOmitTypeName(value[key]);
    }
  }
};

export function omitTypename<T extends unknown>(value: T): T {
  const newValue = cloneDeep(value);

  baseOmitTypeName(newValue);

  return newValue;
}

// Strip __typename from variables
const middleWareLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitTypename(operation.variables);
  }

  return forward(operation);
});

const unauthenticatedResponseHandler = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  query: string,
  operation: Operation,
  forward: NextLink,
  navigate: NavigateFunction
) => {
  try {
    // TODO: remove when authv2 is a required config option
    if (!config.authv2) throw new Error('config authv2 is missing');

    // First try refresh token and if success retry operation.
    // TODO: Currently, concurrent queries cause multiple refresh
    // requests. It would be nice to only having to request once.
    const response = await fetch(config.authv2.refresh_url, {
      credentials: 'include',
    });

    if (response.status !== 200) {
      throw new Error('refresh failed');
    }

    return forward(operation);
  } catch (err) {
    // Refresh failed, user will need to re-login.
    if (!(query === 'me' && (isAuthFlowRoute(location.pathname) || location.pathname === '/'))) {
      await apolloClient.clearStore();
      /**
       * Here is the case:
       *
       * User is trying to access some content, that requires auth.
       * Together with getCurrentUser query (can show if the user is UNAUTHENTICATED),
       * we also call realmConfig & realmFeatureToggles queries.
       *
       * If the getCurrentUser query fails (user is UNAUTHENTICATED), it goes right here and
       * triggers apolloClient.clearStore(), which tries to clear the cache, but we have
       * realmConfig & realmFeatureToggles queries still running.
       *
       * It leads to a weird state of the app (data is already in apollo cache, but the queries are "loading: true"
       * forever with no actual data comming out), when we actually have to invalidate those queries.
       *
       * So, here we do a refetch for that.
       * Seems takes those data directly from the correct cache, no extra network requests noticed :)
       */
      await apolloClient.refetchQueries({
        include: [realmConfigQuery, realmFeatureTogglesQuery],
      });
      navigate('/login');
    }
  }
};

const createApolloClient = ({ openSnackbar }: LinkOptions, navigate: NavigateFunction): ApolloClient<NormalizedCacheObject> => {
  const apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      middleWareLink,
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          const code = graphQLErrors[0].extensions?.code;
          const query = operation.operationName;
          console.warn('GraphQLErrors', graphQLErrors);
          // display no errors for discussions reply navigations
          if (query === 'getReplies' && graphQLErrors?.[0]?.message === '404: Not Found') return;
          // display no errors for injected reflections
          if (query === 'getPost' && location.pathname === '/inject-reflection') return;
          if (code === 'UNAUTHENTICATED') {
            (async () => {
              await unauthenticatedResponseHandler(apolloClient, query, operation, forward, navigate);
            })();
          } else if (!IGNORED_QUERY_ERRORS.includes(query)) {
            const [firstTrans] = getTranslatedErrorMessages(graphQLErrors);
            const [firstErr, ...messages] = getErrorResponseMessages(graphQLErrors);

            const defaultSnackbarMessage = i18n.t('serverMessages::generic|:error-occurred', {
              message: firstTrans || firstErr,
              count: messages.length,
            });

            const contactSupportSnackbarMessage = i18n.t('serverMessages::generic|:error-occured-contact-support');

            openSnackbar({
              message:
                firstErr === 'generic|:error-occured-contact-support' ? contactSupportSnackbarMessage : defaultSnackbarMessage,
              type: SnackbarType.DANGER,
            });
          }
        }
        if (networkError) {
          console.error('NetworkError', networkError);
        }
      }),
      httpLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        UserMeta: {
          keyFields: ['userId', 'type'],
          fields: {
            meta: {
              merge: false,
            },
          },
        },
        InventoryProduct: {
          keyFields: ['id', 'provider'],
        },
        InventoryItem: {
          // PT-2080: Must use "productId" to cache inventory items. Id is always random
          keyFields: ['id', 'productId'],
        },
        AssignmentStrategicV3User: {
          keyFields: ['userId', 'assignmentId'],
        },
        AssignmentGroup: {
          fields: {
            users: {
              merge: false,
            },
          },
        },
        AssignmentGroupUser: {
          keyFields: ['id', 'assignmentGroupId'],
        },
      },
    }),
  });
  apolloClient.defaultOptions = {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };
  return apolloClient;
};

export { createApolloClient };
