import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { Select, TextFieldSelect } from '@/ui/Select';
import { LEARNING_TYPES, TextFieldName } from '../../../constants';

export const LearningType = ({ control }: { control: Control<FieldValues> }): JSX.Element => (
  <Controller
    control={control}
    name={TextFieldName.LearningType}
    rules={{ required: true }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <TextFieldSelect
        onChange={onChange}
        value={value}
        label="Learning type"
        fullWidth
        error={
          error && {
            message: 'Choose learning type',
          }
        }
      >
        <Select.List>
          {LEARNING_TYPES.map(({ value }) => (
            <Select.Option value={value} key={value}>
              {value}
            </Select.Option>
          ))}
        </Select.List>
      </TextFieldSelect>
    )}
  />
);
