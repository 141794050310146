import { ObjectId } from '../types/analytics';
import { config } from '@/config';
import { isValidUUID } from '../utils/uuid';

export const E_LEARNING_OBJECT_ID_PREFIX = 'urn:collegial:';

export const SELF_TRACKED_OBJECT_ID_PREFIX = 'urn:collegial-com:self-tracked-learning:';
export const IMPARTA_OBJECT_ID_PREFIX = 'urn:collegial:imparta:course:';
export const PLURALSIGHT_OBJECT_ID_PREFIX = 'urn:pluralsight:';

const JOURNEY_TYPE = 'journey';

export const elearningIdFromObjectId = (objectId: ObjectId): number => {
  if (!objectId.startsWith(E_LEARNING_OBJECT_ID_PREFIX)) {
    return 0;
  }

  const rawLearningId = objectId.slice(E_LEARNING_OBJECT_ID_PREFIX.length);
  const learningId = parseInt(rawLearningId, 10);
  if (isNaN(learningId)) {
    return 0;
  }

  return learningId;
};

export const isObjectIdCollegialJourney = (objectId: ObjectId): boolean => {
  return objectId.startsWith(E_LEARNING_OBJECT_ID_PREFIX) && objectId.includes(JOURNEY_TYPE);
};

export const selfTrackIdFromObjectId = (objectId: ObjectId): number => {
  if (!objectId?.startsWith(SELF_TRACKED_OBJECT_ID_PREFIX)) {
    return 0;
  }

  const rawLearningId = objectId.slice(SELF_TRACKED_OBJECT_ID_PREFIX.length);
  const learningId = parseInt(rawLearningId, 10);
  if (isNaN(learningId)) {
    return 0;
  }

  return learningId;
};

export const isSebCampusOnlineObject = (objectId: ObjectId): boolean => objectId.toLowerCase().startsWith('urn:lumesse:');

export const learnifierObjectId = (organizationId?: string, projectId?: string): string | undefined => {
  if (!organizationId || !projectId) return;

  return `urn:learnifier:${organizationId}:${projectId}`;
};

export const courseraObjectId = (courseraLearningId?: string): string | undefined => {
  if (!courseraLearningId) return;
  if (!config.coursera?.organizationId) return;

  return `urn:coursera:${config.coursera.organizationId}:${courseraLearningId}`;
};

export const impartaObjectId = (rawId: string): string | undefined => {
  const learningId = parseInt(rawId, 10);
  if (isNaN(learningId)) return;

  return `${IMPARTA_OBJECT_ID_PREFIX}${learningId}`;
};

export const impartaLearningIdFromObjectId = (objectId: string): number => {
  if (!objectId.startsWith(IMPARTA_OBJECT_ID_PREFIX)) return 0;

  const learningId = parseInt(objectId.slice(IMPARTA_OBJECT_ID_PREFIX.length), 10);
  if (isNaN(learningId)) {
    return 0;
  }

  return learningId;
};

export const pluralsightObjectId = (rawId: string): string | undefined => {
  if (!isValidUUID(rawId)) return;

  return `${PLURALSIGHT_OBJECT_ID_PREFIX}${rawId}`;
};

export const pluralsightLearningIdFromObjectId = (objectId: string): string => {
  if (!objectId.startsWith(PLURALSIGHT_OBJECT_ID_PREFIX)) return '';

  const uuidMaybe = objectId.slice(PLURALSIGHT_OBJECT_ID_PREFIX.length);
  if (!isValidUUID(uuidMaybe)) return '';

  return uuidMaybe;
};
