import React, { ReactNode, useCallback, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { StyledComponent } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useLearningCollectionItemRoute } from '@/pages/learning/LearningCollection/LearningCollectionPage';
import { Provider, mapProviderLink } from '@/store/providers';
import { http } from '@/utils/lib/http';
import { config } from '@/config';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { useUserSelfTrackedLearningLogic } from '@/component/UserSelfTrackedLearning/hooks/useUserSelfTrackedLearningLogic';
import { trackHomepageLearningCardClicked } from '@/utils/tracking/homePage';

interface Props {
  children: ReactNode | ReactNode[];
  isApproved: boolean;
  link?: string;
  customLink?: string | null;
  provider?: Provider | null;
  ariaLabel: string;
  isMandatory?: boolean;
  isCollection?: boolean;
  entityId: number;
  isApprovalRequired?: boolean;
  userSelfTrackedLearningId?: number;
  isCompletedPage?: boolean;
  Component: StyledComponent<any, any>;
  title: string;
  isExternal?: boolean;
  sectionName: string;
  fullWidth?: boolean;
}

const canvasInstanceUrl = 'https://collegial.instructure.com';

export const CourseCardWrapperV2 = ({
  children,
  isApproved,
  link,
  customLink,
  provider,
  ariaLabel,
  isMandatory = false,
  isCollection = false,
  entityId,
  isApprovalRequired,
  userSelfTrackedLearningId,
  title,
  Component,
  isExternal,
  sectionName,
  fullWidth = false,
}: Props): JSX.Element => {
  let linkToUse: string | undefined = useLearningCollectionItemRoute(isExternal ? '' : link);
  let isInternalLink = true;
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { t } = useTranslation('auth');
  const { openUserSelfTrackedLearningForm } = useUserSelfTrackedLearningLogic();

  const baseComponentProps = {
    $isMandatory: isMandatory,
    $isCollection: isCollection,
    'aria-label': ariaLabel,
    $fullWidth: fullWidth,
  };

  const [isCanvasLink, setIsCanvasLink] = useState(false);
  const handleCanvasCourse = useCallback(async () => {
    try {
      const data = (await http.get(`${config.baseApiRoute}/provider/canvas/${entityId}`)) as { redirectUrl: Location };
      window.location = data.redirectUrl;
    } catch (error) {
      openSnackbar({ type: SnackbarType.DANGER, message: t('canvas-error') });
    }
  }, []);

  useEffect(() => {
    if (customLink && customLink.includes(canvasInstanceUrl)) {
      setIsCanvasLink(true);
    }
  }, [customLink]);

  // link non-curated Coursera & Pluralsight content directly to Coursera (even if they do not have access)
  if (!linkToUse && !isCanvasLink && provider && customLink) {
    linkToUse = mapProviderLink(provider, customLink);
    isInternalLink = false;
  }

  if (userSelfTrackedLearningId) {
    return (
      <Component
        onClick={() => {
          openUserSelfTrackedLearningForm(userSelfTrackedLearningId);
          trackHomepageLearningCardClicked(title, sectionName);
        }}
      >
        {children}
      </Component>
    );
  }

  if ((isCanvasLink && isApproved && isApprovalRequired) || (isCanvasLink && !isApprovalRequired)) {
    return (
      <Component
        {...baseComponentProps}
        onClick={() => {
          handleCanvasCourse();
          trackHomepageLearningCardClicked(title, sectionName);
        }}
      >
        {children}
      </Component>
    );
  } else if ((isInternalLink && linkToUse) || (linkToUse && isCanvasLink)) {
    return (
      <Component
        {...baseComponentProps}
        as={Link}
        to={linkToUse}
        onClick={() => trackHomepageLearningCardClicked(title, sectionName)}
      >
        {children}
      </Component>
    );
  } else if (!isInternalLink) {
    return (
      <Component
        {...baseComponentProps}
        as="a"
        href={linkToUse}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackHomepageLearningCardClicked(title, sectionName)}
      >
        {children}
      </Component>
    );
  }

  return (
    <Component {...baseComponentProps} onClick={() => trackHomepageLearningCardClicked(title, sectionName)}>
      {children}
    </Component>
  );
};
