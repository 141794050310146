import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import '../notifications.css';
import {
  BabelonNotification,
  BabelonNotificationAggregate,
  BabelonNotificationSingle,
  isSingle,
  NotificationType
} from '../types';
import { convertTitle } from '@/utils/misc';
import { Icon } from '@/components/Icon';
import { REFLECTIONS } from '../../../learning/CourseDetails/components/ReflectionsTab';
import { Box } from '@/components/Box/Box';
import { trackNotificationItemClicked } from '@/utils/tracking/notifications';

interface Props {
  notification: BabelonNotification | BabelonNotificationAggregate;
}

export const Notification = ({ notification }: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  let notificationType: NotificationType;
  let replyId: number | null;
  let respondentName = '';
  let respondentNames: string[] = [];
  const { productId, productTitle, sectionPath, topLevelPostId, topLevelPostTitle, sectionTitle } = notification;
  if (isSingle(notification)) {
    ({ notificationType, replyId, respondentName } = notification as BabelonNotificationSingle);
  } else {
    const aggregateNotification = notification as BabelonNotificationAggregate;
    notificationType =
      [...new Set(aggregateNotification.notificationTypes)].length > 1 ? 'MIXED' : aggregateNotification.notificationTypes[0];
    replyId = aggregateNotification.replyIds.find(id => !!id) || null;
    // might be same person leaving multiple comments
    const names = [...new Set(aggregateNotification.respondentNames)];
    if (names.length > 1) {
      respondentNames = names;
    } else {
      respondentName = names[0];
    }
  }

  const section = sectionPath
    .split('/')
    .slice(-1)
    .pop();

  const origin = sectionTitle || section;
  const type = section === REFLECTIONS ? 'reflection' : 'post';
  let link = `/${type}/${topLevelPostId}${sectionPath}${replyId ? `?highlight=${replyId}` : ''}`;

  if (productId) {
    link = `/learning/course/${productId}/${convertTitle(productTitle!)}${link}`;
  }

  return (
    <Link styleName="notification" to={link} onClick={trackNotificationItemClicked}>
      <Box>
        {notificationType === 'MIXED' && <Icon icon="inbox-in" size="sm" />}
        {notificationType === 'REPLY' && <Icon icon="reply" size="sm" />}
        {notificationType === 'LIKE' && <Icon icon="thumbs-up" size="sm" />}{' '}
        <Trans
          ns="discussions"
          i18nKey={section === REFLECTIONS ? 'notification-reflection' : 'notification-post'}
          tOptions={{
            context: productId ? 'product' : ''
          }}
          values={{
            name: respondentName || `${respondentNames[0]} ${t('notification-other', { count: respondentNames.length - 1 })}`,
            channel: origin,
            title: `"${topLevelPostTitle}"`,
            verb: t('notification-verb', { context: notificationType }),
            productTitle: productId ? productTitle : ''
          }}
          components={[
            <span key="0" className="overline is-marginless">
              {respondentName}
            </span>,
            <strong key="1" className="is-capitalized">
              {origin}
            </strong>,
            <span key="2" className="has-text-grey is">{`"${topLevelPostTitle}"`}</span>
          ]}
        />
      </Box>
    </Link>
  );
};
