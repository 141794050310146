import Lottie from 'lottie-react';
import styled from 'styled-components';

import { Modal } from '@/ui/Modal';
import thumbsUpAnimation from './thumbsUpAnimation.json';
import { GraphicsBanner } from '../../GraphicsBanner';
import { Button } from '@/ui/Button';

type ReflectionSuccessModalProps = {
  open: boolean;
  onClose: () => void;
  onViewDetails: () => void;
};

export const ReflectionSuccessModal = ({ open, onClose, onViewDetails }: ReflectionSuccessModalProps): JSX.Element => (
  <Modal open={open} size="mobileLandscape" onClose={onClose}>
    <Modal.Contents>
      <GraphicsBanner>
        <GraphicsBanner.ImageArea>
          <Animation animationData={thumbsUpAnimation} loop={true} />
        </GraphicsBanner.ImageArea>
        <GraphicsBanner.Title>Nice work!</GraphicsBanner.Title>
        <GraphicsBanner.Description>
          You can always view all your reflections under <ViewDetailsLink onClick={onViewDetails}>View Details</ViewDetailsLink>
        </GraphicsBanner.Description>
      </GraphicsBanner>
    </Modal.Contents>
    <Modal.Actions>
      <Modal.Action action={onClose}>Close</Modal.Action>
    </Modal.Actions>
  </Modal>
);

const Animation = styled(Lottie)`
  width: 100%;
  height: 100%;
`;

const ViewDetailsLink = styled(Button).attrs({ variant: 'link' })`
  ${({ theme }) => theme.typography.body3};
  display: inline-block;
  padding: 0;
`;
