import { createPlatePlugin } from '@udecode/plate-common/react';

const ELEMENT_IMAGE = 'image';

export const ImagePlugin = createPlatePlugin({
  key: ELEMENT_IMAGE,
  node: {
    isElement: true,
    isVoid: true,
    type: ELEMENT_IMAGE,
  },
});
