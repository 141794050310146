import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import './audio.scss';
import { AudioMeta } from '../../../../../administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { formatSeconds } from '../../../../../utils/time';
import { Icon } from '@/components/Icon';

export const AudioPlayer = ({ url, description }: AudioMeta): JSX.Element => {
  const { t } = useTranslation('common');
  const { current: player } = useRef(new Audio(url));
  const progressRef = useRef<HTMLProgressElement>(null);
  const [canPlay, setCanPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');

  const updateProgress = () => {
    setCurrentTime(formatSeconds(player.currentTime || 0));
    setProgress(player.currentTime ? (player.currentTime / player.duration) * 100 : 0);
  };

  function toggleCanPlay() {
    setCanPlay(true);
  }

  function togglePlaying() {
    setPlaying(true);
  }

  function togglePause() {
    setPlaying(false);
  }

  useEffect(() => {
    player.addEventListener('canplaythrough', toggleCanPlay);
    player.addEventListener('timeupdate', updateProgress);
    player.addEventListener('play', togglePlaying);
    player.addEventListener('pause', togglePause);

    if (player.readyState > 3) toggleCanPlay();

    return () => {
      player.removeEventListener('canplaythrough', toggleCanPlay);
      player.removeEventListener('timeupdate', updateProgress);
      player.removeEventListener('play', togglePlaying);
      player.removeEventListener('pause', togglePause);
    };
  }, []);

  const handleProgressClick = (e: React.MouseEvent<HTMLProgressElement>) => {
    if (!progressRef.current) return;
    const { width, left } = progressRef.current.getBoundingClientRect();
    const position = (e.clientX - left) / width;
    player.currentTime = player.duration * position;
  };

  const timePlaceholder = '--:--';

  return (
    <div styleName="player">
      <div styleName="player-main">
        <div
          styleName={clsx('play-button', !playing && 'play-button--paused')}
          onClick={() => (!canPlay ? null : playing ? player.pause() : player.play())}
        >
          {!canPlay ? <Icon icon="spinner-third" spin /> : playing ? <Icon icon="pause" /> : <Icon icon="play" />}
        </div>
        <div styleName="info">
          <span className="overline no-wrap is-marginless">{t('audio', { ns: 'productType' })}</span>
          <div className="header4">{description}</div>
        </div>
      </div>
      <div styleName="player-controls">
        <div className="overline is-marginless" styleName="rewind" onClick={() => (player.currentTime -= 15)}>
          -15
        </div>
        <div styleName="progress">
          <span className="caption">{canPlay ? currentTime : timePlaceholder}</span>
          <progress ref={progressRef} className="progress is-small" max="100" value={progress} onClick={handleProgressClick}>
            {currentTime}
          </progress>
          <span className="caption">{canPlay ? formatSeconds(player.duration) : timePlaceholder}</span>
        </div>
        <div className="overline is-marginless" styleName="forward" onClick={() => (player.currentTime += 15)}>
          +15
        </div>
      </div>
    </div>
  );
};
