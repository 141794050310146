import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IFrame } from '@/component/IFrame/IFrame';

export const LearningPathFrame = ({
  isHidden,
  learningPathLink,
  trackClickedGoToLearning,
  linkInsteadOfIframe,
  enrolledWithNoCustomLinkOrPluralsight
}: {
  isHidden: boolean;
  learningPathLink: string | null;
  trackClickedGoToLearning: (buttonText: string) => void;
  linkInsteadOfIframe: boolean;
  enrolledWithNoCustomLinkOrPluralsight: boolean;
}): JSX.Element => {
  const { t } = useTranslation(['learning', 'catalog']);

  return (
    <TabSection isHidden={isHidden}>
      {enrolledWithNoCustomLinkOrPluralsight && learningPathLink && !linkInsteadOfIframe && (
        <IFrame src={learningPathLink} title={t('Learning path')} />
      )}
      {enrolledWithNoCustomLinkOrPluralsight && learningPathLink && linkInsteadOfIframe && (
        <p>
          {t('The module content cannot be displayed on this page using your current browser.')}
          <br />
          {t('Click the link below to open the module in a new tab.')}
          <br />
          <a
            href={learningPathLink ?? ''}
            onClick={() => trackClickedGoToLearning(t('Go to module'))}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Go to module')}
          </a>
        </p>
      )}
    </TabSection>
  );
};

const TabSection = styled.div<{ isHidden: boolean }>`
  ${({ isHidden }) =>
    isHidden &&
    `
opacity: 0;
overflow: hidden;
height: 0 !important;
min-height: 0 !important;`}
`;
