import { useNavigate } from 'react-router-dom';

const DEFAULT_FALLBACK_ROUTE = '/'; // Homepage route

export const useNavigateBack = (): {
  navigateBack: (noBackHistoryFallbackRoute?: string) => void;
} => {
  const navigate = useNavigate();

  const navigateBack = (noBackHistoryFallbackRoute = DEFAULT_FALLBACK_ROUTE) => {
    // we check whether there is actually a back state in history
    if (window.history.state.idx !== 0) {
      navigate(-1);
    } else {
      navigate(noBackHistoryFallbackRoute);
    }
  };

  return {
    navigateBack,
  };
};
