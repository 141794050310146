/* eslint-disable import/no-unused-modules */
import React from 'react';
import { useTheme } from 'styled-components';

import { IconButton } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { RootStyled, TitleWrap, IconWrapStyled, TitleText, ActionsStyled } from '../styles';
import { PAGE_TYPE_TO_ICON } from '../types';
import { PageVariantDeprecated as PageVariant } from '@/administration/pages/Course/store/page/types';

export type EditDrawerItemProps = {
  id: string;
  title: string;
  type: PageVariant;
  active?: boolean;
  onRemoveClick: (id: string) => void;
  onSelect: (id: string) => void;
  removalForbidden?: boolean;
  hasError?: boolean;
};

const _EditItem = ({
  id,
  title,
  active,
  type,
  removalForbidden,
  hasError,
  onRemoveClick,
  onSelect,
}: EditDrawerItemProps): JSX.Element => {
  const theme = useTheme();

  return (
    <RootStyled onClick={() => onSelect(id)} active={!!active}>
      <TitleWrap>
        <IconWrapStyled type={type}>
          <Icon icon={PAGE_TYPE_TO_ICON[type]} />
        </IconWrapStyled>
        <TitleText>{title}</TitleText>
      </TitleWrap>
      <ActionsStyled>
        {!!hasError && <Icon icon={['fas', 'circle-exclamation']} color={theme.colors.error.main} />}
        {!removalForbidden && (
          <IconButton
            icon={['far', 'trash']}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveClick(id);
            }}
          />
        )}
      </ActionsStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => <Skeleton width={312} height={36} />;

export const EditItem = makeNamespacedComponent(_EditItem, {
  LoadingState,
});
