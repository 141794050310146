import React, { useContext, useRef } from 'react';

import { ListStyled } from './styles';
import { SelectContext } from '../SelectContext';
import { useSelectOptionsListState } from '../../hooks';
import { ListProps } from './types';

export const List = ({ children, className, fullWidth, forcedPosition }: ListProps): JSX.Element | null => {
  const { shouldShowOptions, setShouldShowOptions, selectRef } = useContext(SelectContext);
  const listRef = useRef<HTMLUListElement>(null);

  const { isListVisible, position, maxHeight } = useSelectOptionsListState({
    shouldShowOptions,
    listRef,
    containerRef: selectRef,
    setShouldShowOptions,
  });

  return (
    <ListStyled
      position={forcedPosition || position}
      ref={listRef}
      visible={isListVisible}
      shouldShowOptions={shouldShowOptions}
      maxHeight={maxHeight}
      className={className}
      fullWidth={fullWidth}
    >
      {children}
    </ListStyled>
  );
};
