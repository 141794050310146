import { useTranslation } from 'react-i18next';

import { attributeGroups } from '@/administration/component/Attributes/attributeGroups';
import { MultiSelectOption } from '@/components/Form/components/MultiSelect/types';
import { serializeValue } from '@/utils/valueSerialization';
import { getProviderMeta } from '@/store/providers';
import { SelectOption } from '@/component/Input/Select';
import { filterProviderEnum, filterDurationEnum, filterTypeEnum } from '../types';

export function useProviderOptions(providers: Array<filterProviderEnum>): Array<SelectOption<filterProviderEnum>> {
  const { t } = useTranslation('providers');
  const hasProviderlessCards = providers.some((p) => !p);
  const providerOptions = providers
    .filter((provider) => getProviderMeta(provider))
    .map((provider) => ({
      value: provider,
      label: getProviderMeta(provider)?.name || '',
    }));
  if (!hasProviderlessCards) return providerOptions;
  return providerOptions.concat({ value: 'empty' as filterProviderEnum, label: t('No provider') });
}

export function useDurationOptions(availableDurations: Array<filterDurationEnum>): Array<SelectOption<filterDurationEnum>> {
  const { t } = useTranslation('catalog');

  const possibleDurations: Array<SelectOption<filterDurationEnum>> = [
    { value: 'micro', label: t('< 15 min') },
    { value: 'hour', label: t('15-60 min') },
    { value: 'hours', label: t('1-4 hrs') },
    { value: 'day', label: t('4-8 hrs') },
    { value: 'week', label: t('1-7 days') },
    { value: 'month', label: t('7-30 days') },
    { value: 'months', label: t('> 1 month') },
  ];
  return possibleDurations.filter((d) => availableDurations.includes(d.value));
}

export function useLearningTypeOptions(availableTypes: Array<filterTypeEnum>): Array<SelectOption<filterTypeEnum>> {
  const { t } = useTranslation('catalog');

  const possibleLearningTypes: Array<SelectOption<filterTypeEnum>> = [
    { value: 'audio', label: t('audio', { ns: 'productType' }) },
    { value: 'article', label: t('article', { ns: 'productType' }) },
    { value: 'video', label: t('video', { ns: 'productType' }) },
    { value: 'resource', label: t('resource', { ns: 'productType' }) },
    { value: 'online-learning', label: t('online-learning', { ns: 'productType' }) },
    { value: 'e-learning', label: t('e-learning', { ns: 'productType' }) },
    { value: 'webinar', label: t('webinar', { ns: 'productType' }) },
    { value: 'seminar', label: t('seminar', { ns: 'productType' }) },
    { value: 'learning-path', label: t('learning-path', { ns: 'productType' }) },
    { value: 'collection', label: t('collection', { ns: 'productType' }) },
    { value: 'journey', label: t('journey', { ns: 'productType' }) },
  ];
  return possibleLearningTypes.filter((t) => availableTypes.includes(t.value));
}

// TODO: This is hardcoded ATM for SEB realm but in the future should change to dynamic attributes for all realms
//  Maybe use feature toggles?
export function useAttributeOptions(): MultiSelectOption[] {
  return attributeGroups.reduce((acc: MultiSelectOption[], attr) => {
    // Top level groups that contain nested attributes with values
    if (attr.isFilterOption) {
      acc.push({
        value: attr.name,
        label: attr.name,
        unselectable: true,
        isHeader: true,
      });
    }
    // Not sure why but rows which contain attributes is an Array<Array<Attribute>>, so we have to iterate more
    attr.rows.forEach((row) => {
      row.forEach((item) => {
        // Second level attributes that contain values
        if (item.isFilterOption) {
          acc.push({
            value: item.attribute,
            label: item.attribute,
            unselectable: true,
            isSubHeader: true,
          });
          item.values.forEach((v) => {
            // Third level values
            acc.push({
              // The structure is a mess and to workaround with filtering the results we have to separate
              // and identify parent|child relation as key|value pair within meta attributes data.
              value: serializeValue([item.attribute, v]),
              label: v,
            });
          });
        }
      });
    });
    return acc;
  }, []);
}
