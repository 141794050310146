import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { DefaultTheme } from 'styled-components';

export const getDraggableItemStyle = ({
  isDragging,
  theme,
  draggableStyle,
}: {
  isDragging: boolean;
  theme: DefaultTheme;
  draggableStyle?: DraggingStyle | NotDraggingStyle;
}): React.CSSProperties => {
  return {
    // shadows in theme are kept as full property definition 'box-shadow: ... ;' , so we have to cut it
    boxShadow: isDragging ? String(theme.shadow.primary[64]).replace(/box-shadow:|;/g, '') : undefined,
    userSelect: 'none',
    borderRadius: '4px',
    ...draggableStyle,
  };
};
