import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Button } from '@/ui/Button';

const MultiPageStyled = styled.div`
  min-height: 100%;
  height: 100%;
  align-self: stretch;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-snap-align: center;
`;

const MultiPageNavStyled = styled.div`
  position: fixed;

  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 44px;

  right: 24px;
  bottom: 30px;
`;

export const PageBody = styled.div`
  background: ${({ theme }) => theme.tones.neutral[100]};
  border-radius: 20px;
  margin: 8px;

  min-height: calc(100% - 16px);

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const MultiPageBody = ({ children }: { children: ReactNode }): JSX.Element => (
  <MultiPageStyled>
    <PageBody>{children}</PageBody>
  </MultiPageStyled>
);

export const MultiPageNav = ({
  backDisabled,
  nextDisabled,
  onBackClick,
  onNextClick,
}: {
  backDisabled: boolean;
  onBackClick: () => void;
  nextDisabled: boolean;
  onNextClick: () => void;
}): JSX.Element => (
  <MultiPageNavStyled>
    <Button onClick={onBackClick} variant="neutral" icon="chevron-up" $iconPosition="center" disabled={backDisabled} />
    <Button onClick={onNextClick} variant="neutral" icon="chevron-down" $iconPosition="center" disabled={nextDisabled} />
  </MultiPageNavStyled>
);
