import styled, { css } from 'styled-components';

import { PageVariantDeprecated as PageVariant } from '@/administration/pages/Course/store/page/types';
import { DrawerItemType } from './types';

const HoverCSS = css<{ active?: boolean }>`
  background: ${({ theme, active }) => !active && theme.colors.states.hover5};

  & [data-icon='trash'] {
    opacity: 1;
  }
`;
const ActiveCSS = css`
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.colors.states.hover6} 0%, ${theme.colors.states.hover6} 100%), ${theme.tones.neutral[99]};`};
`;
export const RootStyled = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  transition: all 0.2s;
  gap: 8px;

  & [data-icon='trash'] {
    opacity: 0;
  }

  &:hover {
    ${HoverCSS}
  }

  ${({ active }) => active && ActiveCSS};

  &:active {
    ${ActiveCSS}
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  gap: 16px;
  overflow: hidden;
`;
export const TitleText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;
export const IconWrapStyled = styled.div<{ type: DrawerItemType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, type }) => {
    switch (type) {
      case 'completed':
        return `none`;
      case PageVariant.TEXT:
        return theme.colors.tertiary.container;
      case PageVariant.QUESTION:
        return theme.colors.tertiary.main;
      case PageVariant.PRACTICAL_ASSESSMENT_TEXT:
        return theme.colors.neutral.background;
      case PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT:
        return theme.colors.neutral.background;
      case PageVariant.REACTION:
        return theme.colors.secondary.outline;
      case PageVariant.REFLECTION:
        return theme.colors.secondary.container;
      case PageVariant.RESULT:
      default:
        return theme.colors.primary.container;
    }
  }};
  color: ${({ theme, type }) => {
    switch (type) {
      case PageVariant.QUESTION:
        return theme.colors.primary.onMain;
      default:
        return theme.colors.neutral.onBackground;
    }
  }};
  flex: 1 0 34px;
  width: 34px;
  height: 34px;
  border-radius: 8px;
`;
