import { useEffect, useState } from 'react';

import { Placement, usePlacementProps } from '../types';

export const usePlacement = ({
  desiredPlacement,
  shouldShow,
  wrapperRef,
  tooltipRef
}: usePlacementProps): {
  actualPlacement: Placement;
  isVisible: boolean;
} => {
  const [actualPlacement, setActualPlacement] = useState<Placement>(desiredPlacement);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (shouldShow) {
      const wrapperRect = wrapperRef?.current?.getBoundingClientRect();

      /** VERTICAL */
      const actualTooltipVisibleHeight = tooltipRef?.current?.clientHeight || 0;
      const spaceAboveWrapperPx = wrapperRect ? wrapperRect.top : 0;
      const spaceBelowWrapperPx = window.innerHeight - (wrapperRect ? wrapperRect.bottom : 0);

      const canBePlacedBelow = spaceBelowWrapperPx > actualTooltipVisibleHeight;
      const canBePlacedAbove = spaceAboveWrapperPx > actualTooltipVisibleHeight;

      /** HORIZONTAL */
      const actualListVisibleWidth = tooltipRef?.current?.clientWidth || 0;
      const spaceToLeftOfWrapperPx = wrapperRect ? wrapperRect.left : 0;
      const spaceToRightOfWrapperPx = window.innerWidth - (wrapperRect ? wrapperRect.right : 0);

      const canBePlacedRight = spaceToRightOfWrapperPx > actualListVisibleWidth;
      const canBePlacedLeft = spaceToLeftOfWrapperPx > actualListVisibleWidth;

      if (desiredPlacement.includes('top')) {
        setActualPlacement(canBePlacedAbove ? desiredPlacement : (desiredPlacement.replace('top', 'bottom') as Placement));
      } else if (desiredPlacement.includes('right')) {
        setActualPlacement(canBePlacedRight ? desiredPlacement : (desiredPlacement.replace('right', 'left') as Placement));
      } else if (desiredPlacement.includes('bottom')) {
        setActualPlacement(canBePlacedBelow ? desiredPlacement : (desiredPlacement.replace('bottom', 'top') as Placement));
      } else if (desiredPlacement.includes('left')) {
        setActualPlacement(canBePlacedLeft ? desiredPlacement : (desiredPlacement.replace('left', 'right') as Placement));
      }
    }

    setVisible(shouldShow);
  }, [desiredPlacement, shouldShow, wrapperRef, tooltipRef]);

  return {
    actualPlacement,
    isVisible
  };
};
