/* eslint-disable import/no-unused-modules */
import React, { useState, useRef } from 'react';

import { Placement, TooltipProps } from './types';
import { TooltipContainerStyled, TooltipStyled, TooltipContentStyled, TooltipTitleStyled } from './styles';
import { usePlacement } from './hooks';
import { Icon } from '@/ui/Icon';

const DEFAULT_PLACEMENT: Placement = 'right';

export const Tooltip = ({ children, placement: desiredPlacement = DEFAULT_PLACEMENT, title }: TooltipProps): JSX.Element => {
  const [shouldShow, setShouldShow] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { actualPlacement, isVisible } = usePlacement({ shouldShow, desiredPlacement, wrapperRef, tooltipRef });

  return (
    <TooltipContainerStyled onMouseEnter={() => setShouldShow(true)} onMouseLeave={() => setShouldShow(false)} ref={wrapperRef}>
      {children}
      <TooltipStyled placement={actualPlacement || DEFAULT_PLACEMENT} ref={tooltipRef} isVisible={isVisible}>
        <TooltipContentStyled>
          <Icon icon="buddy-round-arrow-square" />
          <TooltipTitleStyled>{title}</TooltipTitleStyled>
        </TooltipContentStyled>
      </TooltipStyled>
    </TooltipContainerStyled>
  );
};
