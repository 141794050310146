import { MAX_NOTIFICATIONS, NAV_LINKS } from '@/components/Navbar/consts';
import { getProviderIcon, ProviderMeta } from '@/store/providers';
import { Card } from '@/types/learning/card';
import { sortByProp } from '@/utils/sort';
import { TopProvider, CategoryFromCatalog } from '@/types/categories';
import { LearningSubject } from '@/types/learning';
import { customLinkName } from '@/utils/constants';

export const getHomeLink = (authenticated: boolean, onboarding: boolean): string => {
  return onboarding ? NAV_LINKS.ONBOARDING : authenticated ? NAV_LINKS.HOME : NAV_LINKS.LOGIN;
};

export const getNotificationsCount = (notificationsCount: number): string | number =>
  notificationsCount > MAX_NOTIFICATIONS ? `${MAX_NOTIFICATIONS}+` : notificationsCount;

export const mapDirectionToFlexbox = {
  center: 'center',
  right: 'flex-end',
  left: 'flex-start'
};

export const getTopProvidersFromCatalog = (
  catalogCards: Card[],
  providers: ProviderMeta[],
  numberOfProviders: number
): TopProvider[] => {
  const topProviders = catalogCards.reduce((prev: TopProvider[], { provider: key }) => {
    const providerName = providers.find(provider => provider.provider === key)?.name;
    const icon = key && getProviderIcon(key);
    if (key && providerName && providerName !== customLinkName && icon) {
      const find = prev.find(value => value.provider === key);
      if (find) {
        return [...prev.filter(value => value.provider !== key), { provider: key, count: find.count + 1, providerName, icon }];
      }
      return [
        ...prev,
        {
          provider: key,
          count: 1,
          providerName,
          icon
        }
      ];
    }
    return prev;
  }, []);

  return topProviders.sort((a, b) => sortByProp(b, a, 'count')).slice(0, numberOfProviders);
};

export const getFlatSubjectsFromCards = (catalogCards: Card[]): LearningSubject[] => {
  const subjectsById: { [key: string]: LearningSubject } = {};
  catalogCards
    .flatMap(card => card.subjects)
    .forEach(subject => {
      if (!subjectsById[subject.subjectId]) {
        subjectsById[subject.subjectId] = subject;
      }
    });
  return Object.values(subjectsById);
};

export const getCategoriesFromCatalog = (flatSubjects: LearningSubject[]): CategoryFromCatalog[] => {
  return flatSubjects.reduce((categoriesWithContent: CategoryFromCatalog[], { categoryId, category, subjectId, subject }) => {
    const isAlreadyInAccumulator = categoriesWithContent.some(category => category.categoryId === categoryId);
    if (!isAlreadyInAccumulator) {
      return [
        ...categoriesWithContent,
        {
          categoryId: categoryId,
          categoryName: category,
          subjects: [
            {
              subjectId: subjectId,
              subjectName: subject
            }
          ]
        }
      ];
    }
    const existingCategory = categoriesWithContent.find(category => category.categoryId === categoryId);
    if (existingCategory) {
      return [
        ...categoriesWithContent.filter(el => el.categoryId !== categoryId),
        {
          ...existingCategory,
          subjects: [
            ...existingCategory.subjects,
            {
              subjectId: subjectId,
              subjectName: subject
            }
          ]
        }
      ];
    }
    return categoriesWithContent;
  }, []);
};
