import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { EmptyState, EmptyStateProps } from './EmptyState';
import { ActionsBar } from './ActionsBar';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

type ListCardProps = {
  emptyProps: EmptyStateProps;
  itemsCount: number;
  title: string;
  onEditClick?: () => void;
  onViewClick?: () => void;
  children: ReactNode;
  className?: string;
  styleBordered?: boolean;
};

const RootStyled = styled.div<{ bordered?: boolean }>`
  ${({ bordered }) =>
    bordered &&
    css`
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
    `}
`;

const ContentStyled = styled.div`
  padding: 0 0 32px 0;
`;

const ListCard = ({
  emptyProps,
  itemsCount,
  title,
  children,
  className,
  onEditClick,
  onViewClick,
  styleBordered,
}: ListCardProps): JSX.Element => {
  return (
    <RootStyled className={className} bordered={styleBordered}>
      {!React.Children.count(children) ? (
        <>
          <ActionsBar title={title} count={itemsCount} minimized={!styleBordered} />
          <ContentStyled>
            <EmptyState {...emptyProps} minimized={!styleBordered} />
          </ContentStyled>
        </>
      ) : (
        <>
          <ActionsBar
            title={title}
            count={itemsCount}
            minimized={!styleBordered}
            onEditClick={onEditClick}
            onViewClick={onViewClick}
          />
          <ContentStyled>{children}</ContentStyled>
        </>
      )}
    </RootStyled>
  );
};

const LoadingState = () => <Skeleton height={560} />;

const _ListCard = makeNamespacedComponent(ListCard, {
  LoadingState,
});

export { _ListCard as ListCard };
