import styled, { css } from 'styled-components';
import { PlateContent } from '@udecode/plate-core/react';

import { CustomEditorV2ToggledFeatures } from '@/component/customEditorV2';

type EditorProps = {
  disabled?: boolean;
  size?: 'large' | 'small';
  $minimized?: boolean;
  $readOnly?: boolean;
  $fullSize?: boolean;
  $toggledFeatures?: CustomEditorV2ToggledFeatures;
};

export const Editor = styled(PlateContent)<EditorProps>`
  ${({ theme, $minimized, $readOnly }) => {
    if ($readOnly) return '';

    return $minimized
      ? css`
          border: 1px ${theme.colors.neutral.onContainer} solid; // var(--border-style);
          border-top: none;
        `
      : css`
          border: var(--border-style);
        `;
  }};

  ${({ $readOnly }) => {
    return (
      !$readOnly &&
      css`
        overflow-y: scroll;
      `
    );
  }};

  box-sizing: border-box;
  * {
    box-sizing: inherit;
  }

  ${({ size, $fullSize }) => {
    return $fullSize
      ? css`
          flex: 1;
        `
      : css`
        max-height: 250px;
        min-height: ${
          size === 'large' ? '250px' : '50px'
        } !important}; // !important, since we override inline styling from Slate.js
          `;
  }};
  outline: none;
  transition: 0.225s ease;
  width: 100%;
  word-break: break-word;

  &:focus {
    color: var(--color-mine-shaft);
  }

  &:hover {
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}

  h2 {
    &:first-child {
      margin-top: 0;
    }
  }

  ul > li {
    margin-left: 15px; // TODO Verify this margin.
  }

  ol > li {
    margin-left: 15px;
  }

  margin: 0;
  padding: 24px;

  ${({ $toggledFeatures, $readOnly }) => {
    return (
      ($toggledFeatures?.draggableInsertHandler || $readOnly) &&
      css`
        padding-left: 58px;
        padding-right: 58px;
      `
    );
  }};

  ${({ $toggledFeatures, $readOnly }) => {
    return (
      ($toggledFeatures?.editorGhostMode || $readOnly) &&
      css`
        border: none;
        padding-top: 72px;
        padding-bottom: ${$readOnly ? '40px' : '72px'};
        overflow-y: visible;

        @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
          max-width: 920px;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }

        max-width: 100%;
      `
    );
  }};

  /* Newly introduced issue after Slate.js internal updates. Known, but looks like they don't want to fix that. 
    Fix idea taken from: @link https://github.com/ianstormtaylor/slate/pull/5423#issuecomment-1565455560
  */
  span[data-slate-placeholder='true'] {
    top: unset !important;
  }
`;
