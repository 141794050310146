import styled from 'styled-components';

const paddingSize = {
  none: 0,
  small: 'var(--gap-xs)',
  default: 'var(--gap-sm)',
  medium: 'var(--gap-md)',
  large: 'var(--gap-lg)'
};

type Size = 'none' | 'small' | 'default' | 'medium' | 'large';

export const Split = styled.div<{
  $padding?: Size;
  $gutter?: Size;
  $wrap?: boolean;
}>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ $padding }) => $padding && paddingSize[$padding]};
  flex-wrap: ${({ $wrap }) => $wrap && 'wrap'};
  ${({ $gutter }) => $gutter && `gap: ${paddingSize[$gutter]};`}
`;
