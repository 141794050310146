import React from 'react';
import { useTranslation } from 'react-i18next';

import { CellFormatterComponent } from '../types';

export const BooleanCellFormatter: CellFormatterComponent = ({ data }) => {
  const { t } = useTranslation('common');

  if (typeof data !== 'boolean') {
    return null;
  }

  return <>{data ? t('Yes') : t('No')}</>;
};
