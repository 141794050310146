import { ResultBlock, SurveyResultBlock } from '../../../store/block/types';
import { ResultPageLayout } from './ResultPageLayout';

export type ViewResultPageProps = {
  id: string;
  block: ResultBlock | SurveyResultBlock;
  isActive: boolean;
};

export const ViewResultPage = ({ id, block, isActive }: ViewResultPageProps): JSX.Element => {
  return <ResultPageLayout id={id} block={block} isActive={isActive} />;
};
