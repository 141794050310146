import React, { useState } from 'react';
import styled from 'styled-components';

import { Table } from '../Table';
import { AutoTableProps, AnyDataItem } from './types';
import { Collapse } from '../Collapse/Collapse';
import { cellFormatters } from './cellFormatters';

const ExpandedRowWrapper = styled.div`
  padding: 0 16px 16px 16px;
`;

export const RowItem = <DataItem extends AnyDataItem, SortKey extends string>({
  columns,
  row,
  ExpandedRow,
  condensed = false,
}: {
  row: DataItem;
  columns: AutoTableProps<DataItem, SortKey>['columnDeclaration'];
  ExpandedRow?: AutoTableProps<DataItem, SortKey>['ExpandedRow'];
  condensed?: boolean;
}): JSX.Element => {
  const expandable = !!ExpandedRow;
  const columnCount = expandable ? columns.length + 1 : columns.length;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Table.Row>
        {expandable && <Table.RowExpanderCell onClick={() => setExpanded(!expanded)} variant="narrow" />}
        {columns.map(({ component: Component, dataKey, cellFormatter, align }, columnIndex) => {
          let cellContents: React.ReactNode | string = null;
          if (Component) {
            cellContents = <Component row={row} isRowExpanded={expanded} />;
          } else if (dataKey) {
            const cellData = row[dataKey];
            if (cellFormatter) {
              const FormatterComponent = cellFormatters[cellFormatter];
              cellContents = <FormatterComponent data={cellData} />;
            } else {
              // Just to be safe (technically, we have no idea about the type here)
              cellContents = String(cellData);
            }
          }
          return (
            <Table.Cell key={columnIndex} align={align} condensed={condensed}>
              {cellContents}
            </Table.Cell>
          );
        })}
      </Table.Row>
      {expandable && ExpandedRow && (
        <Table.Row>
          <Table.Cell minimal />
          {/* dummy cell for pushing the actual content after the "expander" column */}
          <Table.Cell minimal colSpan={columnCount - 1}>
            <Collapse isOpened={expanded}>
              <ExpandedRowWrapper>
                <ExpandedRow row={row} />
              </ExpandedRowWrapper>
            </Collapse>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};
