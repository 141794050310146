import styled from 'styled-components';

import image from './image.svg';

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const BannerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.tones.primary[0]};
`;

export const DescriptionStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.tones.neutral[20]};
`;

export const Graphics = styled.img.attrs({ src: image })`
  padding: 0 20px;
`;
