import React, { useEffect, useState } from 'react';

import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { Position } from '../types';

/**
 * When we have enough space, it's gonna be default max possible height for the list as specified in Figma
 */
export const DEFAULT_MAX_POSSIBLE_HEIGHT =
  // default max height
  304 -
  // vertical paddings
  16 -
  // half of the last element
  8;
/**
 * When we cannot have a default max possible height for the list due to Select position on a screen,
 * we'll cut this offset from the max possible height, to give a list some nice visual spacing
 */
export const LIST_OFFSET = 30;

export const useSelectOptionsListState = ({
  shouldShowOptions,
  containerRef,
  listRef,
  setShouldShowOptions,
}: {
  shouldShowOptions: boolean;
  containerRef: React.RefObject<HTMLDivElement> | null;
  listRef: React.RefObject<HTMLUListElement> | null;
  setShouldShowOptions: (value: boolean) => void;
}): {
  isListVisible: boolean;
  position: Position;
  maxHeight: number;
} => {
  const [position, setPosition] = useState<Position>('bottom-right');
  const [maxHeight, setMaxHeight] = useState<number>(DEFAULT_MAX_POSSIBLE_HEIGHT);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const handleClickOutsideOfSelect = () => setShouldShowOptions(false);

  useOnClickOutside(shouldShowOptions, handleClickOutsideOfSelect, containerRef);

  useEffect(() => {
    if (shouldShowOptions) {
      const selectContainerRect = containerRef?.current?.getBoundingClientRect();

      /** VERTICAL */
      const actualListVisibleHeight = listRef?.current?.clientHeight || 0;
      const spaceAboveDropdownPx = selectContainerRect ? selectContainerRect.top : 0;
      const spaceBelowDropdownPx = window.innerHeight - (selectContainerRect ? selectContainerRect.bottom : 0);

      const maxPossibleVerticalSpace = Math.max(spaceAboveDropdownPx, spaceBelowDropdownPx);
      const canBePlacedBelow = spaceBelowDropdownPx > actualListVisibleHeight;

      const vertical = canBePlacedBelow || maxPossibleVerticalSpace === spaceBelowDropdownPx ? 'bottom' : 'top';

      /** HORIZONTAL */
      const actualListVisibleWidth = listRef?.current?.clientWidth || 0;
      const spaceToLeftOfDropdownPx = selectContainerRect ? selectContainerRect.left : 0;
      const spaceToRightOfDropdownPx = window.innerWidth - (selectContainerRect ? selectContainerRect.right : 0);

      const maxPossibleHorizontalSpace = Math.max(spaceToLeftOfDropdownPx, spaceToRightOfDropdownPx);
      const canBePlacedRight = spaceToRightOfDropdownPx > actualListVisibleWidth;

      const horizontal = canBePlacedRight || maxPossibleHorizontalSpace === spaceToRightOfDropdownPx ? 'right' : 'left';

      /**
       * Covering an edge case, when the window inner height is too small for the list in both top & bottom cases.
       *
       * We sipmply pick the max space value (above or below select) and cut the list offset from it for visual purposes.
       * (Case, when max space is even less than list offset, is not considered to be an actual case).
       *
       * Then we pick the min between max space and max possible height.
       *
       * E.g.
       * - Space below of select on screen is: 200.
       * - Space above of select on screen is: 120.
       *
       * 1.We will be rendering it on bottom (as calculated in the code above) and max possible space is 200 (max of 120 and 200)
       * 2.We give it an offset of 30px => list max height becomes 170px
       * 3. We pick min of 170px and default max possible height (280px), so the final calculated max height is 170px
       */
      const maxHeight = Math.min(Math.max(spaceAboveDropdownPx, spaceBelowDropdownPx) - LIST_OFFSET, DEFAULT_MAX_POSSIBLE_HEIGHT);
      setPosition(`${vertical}-${horizontal}` as Position);
      setMaxHeight(maxHeight);
    }

    setIsListVisible(shouldShowOptions);
  }, [shouldShowOptions]);

  return {
    isListVisible,
    position,
    maxHeight,
  };
};
