import styled from 'styled-components';

import { deviceUp } from '@/styles/utils';

type StyledBodyWrapperProps = { banner: boolean };

export const StyledBodyWrapper = styled.div<StyledBodyWrapperProps>`
  background-color: ${(p) => p.theme.colors.neutral.background};
  display: flex;
  flex-direction: column;
  margin-top: ${({ banner }) =>
    banner ? 'calc(var(--navbar-height-mobile) + var(--navbar-banner-height))' : 'var(--navbar-height-mobile)'};
  min-height: 100vh;

  ${({ banner }) => deviceUp.lg`
    margin-top: ${
      banner ? 'calc(var(--navbar-height-desktop) + var(--navbar-banner-height))' : 'var(--navbar-height-desktop)'
    };`}};
`;

export const StyledBodyFill = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;
