import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';

import { useEffortPerRealm } from '@/store/analytics/learning-progress';
import { EffortForRealm, PERCENTILES_RANGE } from '@/types/analytics';
import { minutesToHours, secondsToMinutes, formatMinutes, round } from '@/utils/time';
import { getRealm } from '@/utils/realms';
import { useQueryParticipations } from '@/store/analytics/participations';
import { REALM } from '@/types/realm';

export interface ProgressType {
  translationKey: string;
  percent: number;
  minutes: string;
  style?: string;
  divider?: boolean;
}

interface UseEffortReturn {
  progressTotal: ProgressType;
  loading: boolean;
  companyAverageHours: string;
  top: number;
  nextLevel: string;
}
export interface UseEffortParams {
  challengeDate?: {
    from?: string;
    to?: string;
  };
  userId?: number;
}
const calculateTop = (totalUserMinutes: number, effortPerRealm: EffortForRealm) => {
  const percentiles: { [key in PERCENTILES_RANGE]: number } = pick(effortPerRealm, orderBy(Object.values(PERCENTILES_RANGE)));
  const totalUserSeconds = totalUserMinutes * 60;

  return Object.entries(percentiles).reduce((percent, [key, value]) => {
    if (totalUserSeconds >= value && totalUserSeconds !== 0 && value !== 0) {
      const valueFromKey = Number(key?.match(/(\d+)/)?.[0] ?? 0);

      return 100 - valueFromKey;
    }

    return percent;
  }, 60);
};

const calculatePercent = (hoursDone: number, nextLevel: number) => Math.round((hoursDone / nextLevel) * 100);

export function useEffort({ challengeDate, userId }: UseEffortParams): UseEffortReturn {
  const NEXT_LEVEL_IN_HOURS = getNextLevelInHours();
  const NEXT_LEVEL_IN_MINS = NEXT_LEVEL_IN_HOURS * 60;
  const { effortPerRealm, loading: loadingAverageTimePerOrganization } = useEffortPerRealm({
    from: challengeDate?.from,
    to: challengeDate?.to,
  });

  const { data, loading: loadingEffortForUser } = useQueryParticipations({
    userIdEq: userId,
    from: challengeDate?.from,
    to: challengeDate?.to,
  });

  const totalEffort = data?.participations
    ? data.participations.reduce((prev, current) => prev + (current?.userEffort || 0), 0)
    : 0;

  const totalEffortMinutes = Number(secondsToMinutes(totalEffort).toFixed(0));
  const companyAverageHours = round(
    minutesToHours(secondsToMinutes(effortPerRealm[0]?.average_effort_per_user ?? 0)),
    0.5
  ).toString();

  const nextLevel = formatMinutes(NEXT_LEVEL_IN_MINS); //@todo: Move it to config or db
  const percent = calculatePercent(minutesToHours(secondsToMinutes(totalEffort)), NEXT_LEVEL_IN_HOURS);

  const progressTotal: ProgressType = {
    translationKey: 'Total',
    percent,
    minutes: formatMinutes(totalEffortMinutes),
    divider: true,
  };

  return {
    progressTotal,
    loading: loadingAverageTimePerOrganization || loadingEffortForUser,
    companyAverageHours,
    top: calculateTop(totalEffortMinutes, effortPerRealm[0]),
    nextLevel,
  };
}

function getNextLevelInHours() {
  return (
    {
      [REALM.AUTOLIV]: 24,
      [REALM.AUTOLIVPILOT]: 40,
      [REALM.SEB]: 42,
      [REALM.LANSFORSAKRINGAR]: 36,
    }[getRealm()] ?? 42
  );
}
