import styled, { css } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { AnswerStates } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/AnswerItem';

const AnsweredBadgeStyled = styled.div<{ $state: AnswerStates }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.typography.body4};

  ${({ $state }) =>
    $state === AnswerStates.CORRECT &&
    css`
      color: ${(p) => p.theme.colors.primary.main};
    `};

  ${({ $state }) =>
    $state === AnswerStates.INCORRECT &&
    css`
      color: ${(p) => p.theme.colors.error.main};
    `};
`;

export const CorrectnessLabel = ({ isCorrect }: { isCorrect: boolean }): JSX.Element => {
  if (isCorrect) {
    return (
      <AnsweredBadgeStyled $state={AnswerStates.CORRECT}>
        <Icon icon={'circle-check'} />
        Correct
      </AnsweredBadgeStyled>
    );
  }

  return (
    <AnsweredBadgeStyled $state={AnswerStates.INCORRECT}>
      <Icon icon={'circle-xmark'} />
      Incorrect
    </AnsweredBadgeStyled>
  );
};
