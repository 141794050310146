import React from 'react';

import { Icon } from '@/components/Icon';
import { BodyMd, Overline } from '@/components/Typography/Typography';
import { Media, MediaBody, MediaFigure, MediaIconWrapper } from '@/components/MediaObject/MediaObject';
import { LinkButton } from '@/components/Button/LinkButton';

interface Props {
  resource: {
    fileUrl: string;
    name?: string;
    description?: string;
  };
}

export const FileResource = ({ resource }: Props): JSX.Element => {
  return (
    <Media alignCenter bordered>
      <MediaFigure>
        <MediaIconWrapper>
          <Icon size="lg" icon={resource.fileUrl.indexOf('pdf') !== -1 ? 'file-pdf' : 'file-alt'} />
        </MediaIconWrapper>
      </MediaFigure>
      <MediaBody>
        <Overline>{resource.name}</Overline>
        <BodyMd>{resource.description}</BodyMd>
      </MediaBody>
      <LinkButton href={resource.fileUrl} target="_blank" rel="noopener noreferrer" $type="base" $round $icon="download" />
    </Media>
  );
};
