import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';
import _partition from 'lodash/partition';

import { UserStatus } from './types';
import { useCurrentUser } from '../currentUser';
import { useAssignmentV3UsersQueryRemote } from '@/store/v2';
import { sort } from '../utils/table/sort';

type UseAssignmentUsersHook = (query: { assignmentId: number | undefined; limit?: number; currentUserOnTop?: boolean }) => {
  data:
    | {
        total: number;
        users: {
          effort: number;
          email: string;
          profileImage?: string | null;
          id: number;
          username: string;
          status: UserStatus;
        }[];
      }
    | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useAssignmentUsers: UseAssignmentUsersHook = ({ assignmentId, limit, currentUserOnTop }) => {
  const { user: currentUser, loading: loadingCurrentUser } = useCurrentUser();

  const {
    data,
    loading: loadingAssignmentUsers,
    error,
  } = useAssignmentV3UsersQueryRemote({
    variables: {
      filter: {
        assignmentGroupId: assignmentId || 0,
      },
      pagination: {
        limit: -1,
        page: 1,
      },
      includeProgress: true,
    },
    skip: !assignmentId,
  });

  const loading = loadingAssignmentUsers || loadingCurrentUser;

  const [processedUsers, total] = useMemo(() => {
    if (loading || !data?.assignmentV3Users || error) {
      return [undefined, 0];
    }

    let processedUsers = data.assignmentV3Users?.data.map(({ progress, user }) => ({
      effort: progress?.__typename === 'AssignmentStrategicV3UserProgress' ? progress.estimatedEffort : 0,
      email: user.email,
      profileImage: user.profileImage,
      id: user.id,
      username: user.username || '',
      status: user.status,
    }));

    const [registeredUsers, notRegisteredUsers] = _partition(
      processedUsers,
      ({ status, username }) => status === 'registered' && username
    );

    // We only sort registered users who have username
    const registeredUsersSorted = sort(registeredUsers, 'username', 'asc');

    processedUsers = [
      ...registeredUsersSorted,
      // we put not registered users (or those who don't have username) at the back of the list
      ...notRegisteredUsers,
    ];

    if (currentUserOnTop && currentUser) {
      const targetUser = processedUsers.find((user) => user.id === currentUser.id);

      processedUsers = targetUser
        ? [{ ...targetUser }, ...processedUsers.filter((user) => user.id !== currentUser.id)]
        : processedUsers;
    }

    if (typeof limit === 'number') {
      processedUsers = processedUsers.slice(0, limit);
    }

    return [processedUsers, data.assignmentV3Users.total];
  }, [loading, data, error, limit, currentUserOnTop]);

  return {
    data: processedUsers
      ? {
          total,
          users: processedUsers,
        }
      : undefined,
    loading,
    error,
  };
};
