import { makeNamespacedComponent } from '@/ui/utils';
import { Table as Table_ } from './Table';
import { Body } from './Body';
import { EmptyBody } from './EmptyBody';
import { Cell } from './Cell';
import { Footer } from './Footer';
import { Header } from './Header';
import { HeaderCell } from './HeaderCell';
import { Row } from './Row';
import { RowExpanderCell } from './RowExpanderCell';

export const Table = makeNamespacedComponent(Table_, {
  Header,
  HeaderCell,
  Body,
  EmptyBody,
  Row,
  Cell,
  RowExpanderCell,
  Footer,
});
