import React from 'react';

import { MetaLabel } from '@/ui/Label';
import {
  Description,
  RootStyled,
  HeaderStyled,
  Title,
  ExpandDescriptionButton,
  DescriptionWrapStyled,
  ActionsStyled,
  MetaButtonsStyled,
} from './styles';
import { ProgressWrapStyled, ProgressWrapper } from '@/pages/Assignment/styles';
import { HeroProps } from '../../types';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { CompetencyAssessmentButton } from '@/pages/Assignment/strategic/components/Hero/CompetencyAssesmentButton';
import { useElementExpandable } from '@/hooks/useElementExpandable';
import { PeopleButton } from '../PeopleButton';
import { ParticipantProps } from '@/pages/Assignment/ParticipantsPage/components/Participant';
import { formatTimeProgressLabel } from '@/components/AssignmentCard/formatTimeProgressLabel';
import { MetaItem } from '@/components/AssignmentCard/AssignmentCard';
import { LinearProgressBar } from '@/ui/ProgressBar';

const DESCRIPTION_COLLAPSE_HEIGHT_PX = 280;

const _Hero = ({ assignment, onOpenParticipants }: HeroProps): JSX.Element => {
  const {
    ref: descriptionRef,
    expanded: descriptionExpanded,
    setExpanded: setDescriptionExpanded,
    fullHeight: descriptionFullHeight,
    canExpand,
  } = useElementExpandable({
    collapseThreshold: DESCRIPTION_COLLAPSE_HEIGHT_PX,
  });

  const metadata = (
    [
      assignment.progressStatus === 'not-started' && {
        type: 'label',
        text: `${assignment.totalHoursRequired} total` ?? '',
        icon: ['far', 'clock'],
      },
      assignment.progressStatus === 'ongoing' && {
        type: 'progress',
        text: formatTimeProgressLabel(assignment.estimatedEffort ?? 0, assignment.totalThresholdEffort) ?? '',
        value: assignment.estimatedEffort,
        maxValue: assignment.totalThresholdEffort,
      },
      assignment.progressStatus === 'completed' && [
        {
          type: 'label',
          text: 'Completed',
          icon: 'check',
        },
        {
          type: 'label',
          text: assignment.totalHoursRequired,
          icon: ['far', 'clock'],
        },
      ],
      assignment.progressStatus === 'ended' && [
        {
          type: 'label',
          text: 'Ended',
          icon: 'minus',
        },
        {
          type: 'label',
          text: assignment.totalHoursRequired,
          icon: ['far', 'clock'],
        },
      ],
    ].flat() as Array<MetaItem | false>
  ).filter((item): item is MetaItem => !!item && !!item?.text);

  return (
    <RootStyled>
      <HeaderStyled>
        <Title>{assignment.name}</Title>
        <MetaButtonsStyled gap="medium" direction="horizontal">
          <MetaLabel
            icon={['far', 'calendar']}
            text={`${assignment.startAt} - ${assignment.endAt}, ${new Date(assignment.endAtFullTimestamp).getFullYear()} · ${
              assignment.weeks?.length
            } weeks`}
          />
          {metadata.map((meta, index) =>
            meta.type === 'label' ? (
              <MetaLabel key={index} text={meta.text} icon={meta.icon} />
            ) : (
              <ProgressWrapper>
                <span>{meta.text}</span>
                <ProgressWrapStyled>
                  <LinearProgressBar value={meta.value} maxValue={meta.maxValue} animated />
                </ProgressWrapStyled>
              </ProgressWrapper>
            )
          )}
        </MetaButtonsStyled>
        <ActionsStyled>
          <CompetencyAssessmentButton
            assignmentId={assignment.id}
            surveyId={assignment.surveyId}
            enabled={assignment.progressCompetencyAssessment?.canTake || false}
          />
          <PeopleButton
            onClick={onOpenParticipants}
            total={assignment.users?.total || 0}
            participants={
              assignment.users?.participants.map(({ profileImage, email, name }) => ({
                name,
                email,
                imageSrc: profileImage,
              })) as ParticipantProps[]
            }
          />
        </ActionsStyled>
      </HeaderStyled>

      <DescriptionWrapStyled>
        <Description
          dangerouslySetInnerHTML={{ __html: assignment.description || '' }}
          expanded={descriptionExpanded || !canExpand}
          ref={descriptionRef}
          fullHeight={descriptionFullHeight}
          collapseHeight={DESCRIPTION_COLLAPSE_HEIGHT_PX}
        />
        {canExpand && (
          <ExpandDescriptionButton
            icon={descriptionExpanded ? 'angle-up' : 'angle-down'}
            onClick={() => setDescriptionExpanded((prev) => !prev)}
          >
            {descriptionExpanded ? 'Show less' : 'Show more'}
          </ExpandDescriptionButton>
        )}
      </DescriptionWrapStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <HeaderStyled>
      <Skeleton width={736} height={44} />
      <MetaButtonsStyled gap="medium" direction="horizontal">
        <MetaLabel.LoadingState />
        <MetaLabel.LoadingState />
      </MetaButtonsStyled>
      <Skeleton width={250} height={32} />
    </HeaderStyled>

    <Skeleton width={800} height={18} />
    <Skeleton width={800} height={18} />
    <Skeleton width={800} height={18} />
    <Skeleton width={800} height={18} />
  </RootStyled>
);

const Hero = makeNamespacedComponent(_Hero, {
  LoadingState,
});

export { Hero };
