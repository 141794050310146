import React from 'react';

import { InformerProps } from '../../atoms/Informer/types';
import { Informer } from '../../atoms/Informer';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { ContentStyled, LabelStyled, RootStyled, TitleContainerStyled } from '../../atoms/Informer/styles';

export type PrimaryInformerProps = Omit<InformerProps, 'titleSuffix'>;

const _PrimaryInformer = (props: PrimaryInformerProps): JSX.Element => <Informer {...props} />;

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <Skeleton height={68} width={68} />
    <ContentStyled>
      <LabelStyled>
        <Skeleton height={20} width={120} />
      </LabelStyled>
      <TitleContainerStyled>
        <Skeleton height={24} width={60} />
      </TitleContainerStyled>
    </ContentStyled>
  </RootStyled>
);

export const PrimaryInformer = makeNamespacedComponent(_PrimaryInformer, {
  LoadingState,
});
