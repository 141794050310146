/* eslint-disable import/no-unused-modules */

import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@/ui/Button';
import { Tooltip } from '@/ui/Tooltip';
import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { ActionsStyled, HeadingWrapStyled, RootStyled, TitleStyled, StyledDisabledButton, StyledEnabledButton } from './styles';

type ViewNavbarProps = {
  onShowHideDrawerClick?: () => void;
  onSaveFavoritesClick?: () => void;
  onCloseClick: () => void;
  title: string;
  isSavedToMyLearning?: boolean;
};

const _ViewNavbar = ({
  title,
  onShowHideDrawerClick,
  onSaveFavoritesClick,
  onCloseClick,
  isSavedToMyLearning,
}: ViewNavbarProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  const theme = useTheme();
  const iconColor = theme.tones.neutral[0];

  return (
    <RootStyled>
      <HeadingWrapStyled>
        {onShowHideDrawerClick && (
          <Tooltip title={t('list')} placement="bottom-start">
            <IconButton icon="list" color={iconColor} onClick={onShowHideDrawerClick} />
          </Tooltip>
        )}
        <TitleStyled>{title}</TitleStyled>
        {onSaveFavoritesClick && (
          <>
            {!!isSavedToMyLearning ? (
              <Tooltip title={t('remove-my-learning')} placement="bottom">
                <StyledEnabledButton icon={'heart'} onClick={onSaveFavoritesClick} buttonVariant="dark" />
              </Tooltip>
            ) : (
              <Tooltip title={t('save-my-learning')} placement="bottom">
                <StyledDisabledButton icon={['far', 'heart']} onClick={onSaveFavoritesClick} buttonVariant="dark" />
              </Tooltip>
            )}
          </>
        )}
      </HeadingWrapStyled>
      <ActionsStyled>
        <Tooltip title={t('close')} placement="bottom">
          <IconButton icon="xmark" color={iconColor} onClick={onCloseClick} />
        </Tooltip>
      </ActionsStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <HeadingWrapStyled>
      <TitleStyled>
        <Skeleton width={244} height={36} />
      </TitleStyled>
      <Skeleton width={36} height={36} />
    </HeadingWrapStyled>
    <ActionsStyled>
      <Skeleton width={36} height={36} />
    </ActionsStyled>
  </RootStyled>
);

export const ViewNavbar = makeNamespacedComponent(_ViewNavbar, {
  LoadingState,
});
