import React from 'react';

import { TFoot } from '../styles';
import { Paginator } from '@/components/Paginator/Paginator';

interface Props {
  colSpan: number;
  page: number;
  totalPages: number;
  onPageChange: (selected: number) => void;
}

export const AutoFooter = ({ colSpan, page, totalPages, onPageChange }: Props): React.ReactElement => (
  <TFoot>
    <tr>
      <td colSpan={colSpan}>
        <Paginator page={page} pageCount={totalPages} onPageChange={onPageChange} />
      </td>
    </tr>
  </TFoot>
);
