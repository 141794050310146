import React from 'react';

import { Button } from '@/components/Button/Button';

interface Props {
  text: string;
  isSelected?: boolean;
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
}

export const LikeButton = ({ isSelected, onClick, text }: Props): JSX.Element => (
  <Button $icon="thumbs-up" $toggled={isSelected} onClick={onClick}>
    {text}
  </Button>
);
