import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { Box } from '@/components/Box/Box';

const MAX_ICON_WIDTH = '40px';

export const StyledProviderBox = styled(Box)`
  display: flex;
  align-items: center;
  color: ${cvar('color-overline')};
  box-shadow: ${cvar('box-shadow-large')};
  padding: ${cvar('gap-sm')};
  > svg {
    margin-right: ${cvar('gap-sm')};
    max-width: ${MAX_ICON_WIDTH};
  }

  &:hover {
    box-shadow: ${cvar('box-shadow-large-hover')};
  }
`;
