import React, { useRef, useState } from 'react';

import { Button } from '@/ui/Button';
import { MenuListBody, MenuListContainer, MenuListStyledDivider } from '@/ui/MenuList/styles';
import { MenuListOption } from '@/ui/MenuList/atoms/MenuListOption';
import { MenuListProps, MenuListOption as MenuListOptionType } from '@/ui/MenuList/types';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

export const MenuList = ({ options, buttonLabel, onOptionClick, optionsAfterDivider }: MenuListProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(isOpen, handleButtonClick, containerRef);

  const enhancedHandleOptionClick = (value: string) => {
    onOptionClick(value);
    setIsOpen(false);
  };

  const shouldRenderDivider = !!optionsAfterDivider?.length;

  return (
    <MenuListContainer ref={containerRef}>
      <Button variant="link" onClick={handleButtonClick}>
        {buttonLabel}
      </Button>
      {isOpen && (
        <MenuListBody>
          {options.map((option) => {
            return <MenuListOption key={option.value} onClick={enhancedHandleOptionClick} {...option} />;
          })}
          {shouldRenderDivider && (
            <>
              <MenuListStyledDivider />
              {(optionsAfterDivider as MenuListOptionType[]).map((option) => {
                return <MenuListOption key={option.value} onClick={onOptionClick} {...option} />;
              })}
            </>
          )}
        </MenuListBody>
      )}
    </MenuListContainer>
  );
};
