import { createPlatePlugin } from '@udecode/plate-common/react';

const ELEMENT_DOCUMENT = 'document';

export const DocumentPlugin = createPlatePlugin({
  key: ELEMENT_DOCUMENT,
  node: {
    isElement: true,
    isVoid: true,
    type: ELEMENT_DOCUMENT,
  },
});
