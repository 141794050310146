import styled from 'styled-components';

import { deviceUp } from '@/styles/utils';

export const Box = styled.div<{
  paddingSize?: 'none' | 'default' | 'large';
  boxShadow?: boolean;
  bgDarker?: boolean;
  border?: boolean;
  marginless?: boolean;
  alignCenter?: boolean;
}>`
  background-color: ${({ bgDarker }) => (bgDarker ? 'var(--color-seashell)' : 'var(--color-white)')};
  ${({ border }) => border && 'border: var(--border-style);'}
  border-radius: var(--radius-outer);
  ${({ boxShadow }) => boxShadow && 'box-shadow: var(--box-shadow-small);'}
  padding: var(${({ paddingSize: ps }) => (ps === 'none' ? 0 : ps !== 'large' ? '--gap-xs' : '--gap-sm')});
  position: relative;
  ${({ marginless }) =>
    !marginless &&
    `&:not(:last-child) {
      margin-bottom: 1.5rem;
    }`}
  

  ${({ alignCenter }) => alignCenter && `text-align: center;`}
  ${({ paddingSize: ps }) =>
    ps === 'none'
      ? null
      : deviceUp.sm`
    padding: var(${ps !== 'large' ? '--gap-sm' : '--gap-md'});
  `}

  ${({ paddingSize: ps }) =>
    ps === 'none'
      ? null
      : deviceUp.lg`
    padding: var(${ps !== 'large' ? '--gap-md' : '--gap-lg'});
  `}

  & .is-divider {
    ${({ paddingSize: ps }) =>
      ps !== 'none' &&
      `
        margin-left: calc(-1 * var(${ps !== 'large' ? '--gap-xs' : '--gap-sm'}))
        width: calc(100% + (2 * var(${ps !== 'large' ? '--gap-xs' : '--gap-sm'})));
      `}
    
    ${({ paddingSize: ps }) =>
      ps === 'none'
        ? null
        : deviceUp.sm`
      margin-left: calc(-1 * var(${ps !== 'large' ? '--gap-sm' : '--gap-md'}));
      width: calc(100% + (2 * var(${ps !== 'large' ? '--gap-sm' : '--gap-md'})));
    `}
      
    ${({ paddingSize: ps }) =>
      ps === 'none'
        ? null
        : deviceUp.lg`
      margin-left: calc(-1 * var(${ps !== 'large' ? '--gap-md' : '--gap-lg'}));
      width: calc(100% + (2 * var(${ps !== 'large' ? '--gap-md' : '--gap-lg'})));
    `}

    &:after {
      background-color: ${({ bgDarker }) => (bgDarker ? 'var(--color-seashell)' : 'var(--color-white)')};
    } 
  }
`;
