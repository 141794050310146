export enum UserSelfTrackedLearningsTypes {
  BOOK = 'BOOK',
  VIDEO = 'VIDEO',
  PODCAST = 'PODCAST',
  ARTICLE = 'ARTICLE',
  PROGRAM = 'PROGRAM',
  WORKSHOP = 'WORKSHOP',
  LIVE_EVENT = 'LIVE_EVENT',
  OTHER = 'OTHER',
}

export type UserSelfTrackedLearning = {
  id?: number;
  type: UserSelfTrackedLearningsTypes;
  title: string;
  description: string;
  effort: number;
  link: string;
};
