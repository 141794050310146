import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import './discussions.scss';
import { Post } from '../../types/post';
import { toHtml } from '../customEditor/logic/serialization';
import { GET_REPLIES, useCreatePost, useUpdatePost, usePostAction, useDeletePost } from '../../store/discussion';
import { TextInput } from './TextInput';
import { PostTopMeta } from './PostTopMeta';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { LinkButton } from '@/components/Button/LinkButton';
import { useModalContext } from '@/components/Modal/ModalContext';
import { trackDiscussionLearningItemCommented, trackReflectionLearningItemCommented } from '@/utils/tracking/learnings';
import { LikeButton } from './LikeButton';

type Props = {
  depth: number;
  maxDepth: number;
  isStandalone?: boolean;
  highlightedPost?: number;
  path: string[];
  pageSize: number;
  isReflection?: boolean;
  onDeletePost?: () => void;
} & Post;

export const Thread = ({
  isStandalone,
  maxDepth,
  depth,
  highlightedPost,
  path,
  pageSize,
  isReflection,
  onDeletePost,
  ...referencePost
}: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [post, setPost] = useState(referencePost);
  const [hasLiked, setHasLiked] = useState(!!post.you.have.liked);
  const [likes, setLikes] = useState(post.stats.likes || 0);
  const [limit, setLimit] = useState(isStandalone ? 100 : 0);
  const [isReplying, toggleReplying] = useState(false);
  const [isEditing, toggleEditing] = useState(false);
  const { showConfirmationModal } = useModalContext();

  const createPostAction = useCreatePost();
  const { updatePostAction } = useUpdatePost();
  const deletePostAction = useDeletePost();
  const togglePostAction = usePostAction();

  const variables = { path, postId: +post.id, page: 1, pageSize: limit };
  const { data, error, refetch } = useQuery(GET_REPLIES, { variables, skip: !limit });

  const replies: Post[] = data?.getReplies?.replies?.items || [];

  const replyCount = post.stats.replies;

  const hasComments = replyCount > 0;

  const addReply = async (payload: Post) => {
    await createPostAction(path, { ...payload, pid: post.id });
    isReflection ? trackReflectionLearningItemCommented() : trackDiscussionLearningItemCommented();
    // This will cause the query to fire again. This is fine util we get cursor-based pagination
    setLimit(limit + 1);
  };

  const editPost = (payload: Post) => {
    updatePostAction(path, post.id, payload);
    setPost({ ...post, content: payload.content });
  };

  const deletePost = async () => {
    const { data } = await deletePostAction(path, post.id);
    onDeletePost && onDeletePost();
    if (data) {
      setPost({ ...data.deletePost });
    }
  };

  const setPostAction = async (action: string, scalar: number) => {
    if (action === 'liked') {
      if (hasLiked) {
        setHasLiked(false);
        setLikes(likes - 1);
        return togglePostAction(path, post.id, action);
      }

      setHasLiked(true);
      setLikes(likes + 1);
      return togglePostAction(path, post.id, action, { scalar });
    }
  };

  return (
    <div styleName={`discussion-thread-container ${depth === 0 ? 'is-top-level' : ''}`}>
      {error ? (
        <article className="message is-warning">
          <div className="message-body">
            <Trans ns="discussions" i18nKey="post-load-error">
              Something went wrong.{' '}
              <LinkButton $type="text" onClick={() => refetch()}>
                Click here
              </LinkButton>{' '}
              to try again.
            </Trans>
          </div>
        </article>
      ) : (
        <>
          <div styleName={clsx(highlightedPost === post.id && 'highlight-post')}>
            <PostTopMeta
              username={post.deleted ? t('[deleted]') : post.user.username}
              realm={post.user.realm}
              created={post.created}
              labels={post.user.role.labels}
            />

            {isEditing ? (
              <div styleName="discussion-content is-thread">
                <TextInput
                  onPosted={editPost}
                  onFinished={() => toggleEditing(false)}
                  initialContentValue={post.content}
                  contentStyle={{ padding: '10px' }}
                />
              </div>
            ) : (
              <div
                className="content"
                styleName="discussion-content is-thread"
                dangerouslySetInnerHTML={{ __html: toHtml(post.content) }}
              />
            )}

            <ButtonList>
              {!isEditing && (
                <>
                  {!post.deleted && (
                    <LikeButton isSelected={!hasLiked} onClick={() => setPostAction('liked', 1)} text={String(likes || 0)} />
                  )}

                  {depth < maxDepth && post.permissions.canReply && (
                    <Button $type="text" onClick={() => toggleReplying(!isReplying)}>
                      {t('Reply')}
                    </Button>
                  )}
                </>
              )}
              {post.permissions.canEdit && (
                <Button $type="text" onClick={() => toggleEditing(!isEditing)}>
                  {isEditing ? t('Cancel') : t('Edit')}
                </Button>
              )}
              {post.permissions.canDelete && !isEditing && (
                <Button
                  $type="text"
                  onClick={() =>
                    showConfirmationModal({ action: deletePost, message: t('Are you sure you want to delete this post?') })
                  }
                >
                  {t('Delete')}
                </Button>
              )}
              {limit === 0 && hasComments && (
                <Button $type="text" onClick={() => setLimit(limit + pageSize)}>
                  {post.stats.replies > pageSize ? `${pageSize}+` : post.stats.replies}{' '}
                  {t('Comment', { count: post.stats.replies })}
                </Button>
              )}
            </ButtonList>
          </div>

          {isReplying && (
            <div styleName={'discussion-thread-container'}>
              <TextInput
                onPosted={addReply}
                initialContentValue={''}
                onFinished={() => toggleReplying(false)}
                contentStyle={{ padding: '10px' }}
              />
              <Button $type="text" onClick={() => toggleReplying(false)}>
                {t('Cancel')}
              </Button>
            </div>
          )}

          {limit > 0 &&
            replies
              .slice(0, limit)
              .map((reply: Post) => (
                <Thread
                  isStandalone={isStandalone}
                  highlightedPost={highlightedPost}
                  path={path}
                  key={`post-${reply.id}`}
                  maxDepth={maxDepth}
                  depth={depth + 1}
                  pageSize={pageSize}
                  {...reply}
                />
              ))}

          <div>
            {hasComments && replyCount > limit && limit !== 0 && (
              <Button $type="text" onClick={() => setLimit(limit + pageSize)}>
                {t('Show more')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
