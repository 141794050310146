export interface OrderUser {
  id: number;
  username: string;
  email: string;
  realm: string;
}

export enum OrderStates {
  'REGISTERED' = 1,
  'PENDING_APPROVAL' = 2,
  'NOT_APPROVED' = 3,
  'APPROVED' = 4,
  'CANCELLED' = 5,
  'OUT_OF_STOCK' = 6
}

export interface ProductOrderFlat {
  orderId: number;
  productId: number;
  productVariantId?: number;
  stateId: number;
  spaceId: number;
  userId: number;
  username: string;
  email: string;
  realm: string;
  profileImage?: string;
}
