import styled from 'styled-components';

import { Caption } from '@/components/Typography/v2';

export const CourseCardBodyV2 = styled.div<{ withTags?: boolean }>`
  position: relative;

  overflow: hidden;

  height: 200px;
  padding: 1rem;

  word-break: break-word;

  &:after {
    content: '';

    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 3rem;

    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);

    pointer-events: none;
  }
`;

export const CourseCardBodyCaption = styled(Caption)`
  margin-top: 0.5rem;
`;
