import { ApolloError } from '@apollo/client';

import { RLearningType, useLearningTypeByIdQueryRemote } from '@/store/v2';

export const useLearningType = ({
  id,
}: {
  id: number;
}): {
  type: RLearningType | undefined;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useLearningTypeByIdQueryRemote({
    variables: { id: String(id) },
    skip: !id,
  });

  return {
    loading,
    error,
    type: data?.learning?.type,
  };
};
