import { Context, useContext } from 'react';

export const factoryUseContext = <T>(name: string, context: Context<T>) => {
  return (): T => {
    const ctx: T = useContext(context);
    if (ctx === undefined) {
      throw new Error(`use${name}Context must be used within a ${name}ContextProvider.`);
    }
    return ctx;
  };
};
