import { withRef } from '@udecode/plate-common/react';

import { Body1 } from '@/component/customEditorV2/ui/Typography';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';

export const ParagraphElement = withRef<typeof PlateElement>(({ children, className, ...props }, ref) => {
  return (
    <PlateElement ref={ref} className={className} {...props}>
      <Body1>{children}</Body1>
    </PlateElement>
  );
});
