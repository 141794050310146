import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { BadgeLabel } from '@/ui/Label';
import { makeNamespacedComponent } from '@/ui/utils';

const Labels = styled.div`
  grid-area: labels;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ColoredLabels = {
  Strategic: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.warning[95]};
  `,
};

const StrategicLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('Strategic');
  return <ColoredLabels.Strategic badgeVariant="badge-text" text={label} />;
};

const LabelCollection = makeNamespacedComponent(Labels, {
  Strategic: StrategicLabel,
});

export { LabelCollection as Labels };
