import { AssignmentV3Status } from '@/store/assignments/typesOther';

export const ASSIGNMENT_STATUS: { published: AssignmentV3Status; unpublished: AssignmentV3Status } = {
  published: 'PUBLISHED',
  unpublished: 'UNPUBLISHED',
};

export const ASSIGNMENT_LEARNING_TYPE = {
  mandatory: 'mandatory',
  suggested: 'suggested',
};

export const ASSIGNMENTS_QUERY_OPERATION_NAME = `assignmentsV3`;
