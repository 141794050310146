import React from 'react';

import { EditTextPageProps } from './types';
import { RootStyled, TextEditorStyled } from './styles';
import { CustomEditorV2 } from '@/component/customEditorV2';

export const EditTextPage = ({ id, onTextChange, text = '', spaceId }: EditTextPageProps): JSX.Element => {
  return (
    <RootStyled>
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={text}
          onChange={(text) => onTextChange(text)}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          placeholder="Write your content or ‘/’ for options"
          toggledFeatures={{
            dragAndDrop: true,
            draggableInsertHandler: true,
            editorGhostMode: true,
            floatingToolbar: true,
            slashInput: true,
            mediaFiles: true,
          }}
        />
      </TextEditorStyled>
    </RootStyled>
  );
};
