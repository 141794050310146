import styled from 'styled-components';

import { CardType } from '@/types/learning/card';
import { Icon as RawIcon, IconPropType } from '@/ui/Icon';
import { variant } from './sizing';

const iconMap: Partial<Record<CardType, IconPropType>> = {
  article: 'newspaper',
  audio: 'microphone',
  resource: 'file',
  video: 'video',
  webinar: ['far', 'camera-web'],
  seminar: ['far', 'presentation'],
  // TODO: check if we want to add icons for any other types
};

type Props = WithClassNameProp<{
  type: CardType;
}>;

export const TypeIcon = ({ type, className }: Props): JSX.Element | null => !!iconMap[type] ? (
  <Icon icon={iconMap[type]!} className={className} />
) : null;

const Icon = styled(RawIcon)`
  position: absolute;
  width: 16px;
  filter: drop-shadow(0 0 8px rgb(0 0 0 / 0.4));
  color: ${p => p.theme.colors.neutral.background};

  ${variant.small} {
    bottom: 20px;
    left: 24px;
  }

  ${variant.large} {
    left: 24px;
    top: 155px;
    transform: translateY(calc(-100% - 24px));
  }
`;
