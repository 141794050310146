import {
  gql,
  useMutation,
  useQuery,
  ApolloQueryResult,
  FetchResult,
  FetchMoreQueryOptions,
  FetchMoreOptions,
  QueryResult,
} from '@apollo/client';
import { useContext } from 'react';
import { v4 as uuid } from 'uuid';

import { useFeatureEnabled, FEATURE } from '@/feature-toggles';
import { useRootContext } from '@/store/RootContext';
import { HookResult } from './apolloClient';
import { Post, PostPayload } from '../types/post';
import { Section, SectionPermissions } from '../types/Section';
import { CoursePageContext } from '../pages/learning/CourseDetails/CoursePageContext';
import { Me, useCurrentUserAccess } from './currentUser';

export const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    __typename
    id
    title
    content
    media {
      url
      type
    }
    created
    deleted
    publishDate
    sticky
    user {
      id
      username
      realm
      email
      role {
        labels
      }
      profileImage
    }
    section {
      path
    }
    permissions {
      canReply
      canEdit
      canDelete
      canSticky
      canUnsticky
      canViewReflectionReplies
    }
    stats {
      likes
      replies
    }
    you {
      have {
        liked
      }
    }
  }
`;

const SECTION_FRAGMENT = gql`
  fragment SectionFragment on Section {
    __typename
    id
    path
    title
    description
    role {
      permissions {
        canPost
        canPostSticky
        canAddReflections
        canViewReflections
        canEditSection
      }
    }
  }
`;

const CREATE_SECTION = gql`
  mutation createSection($payload: CreateSectionInput) {
    createSection(payload: $payload) {
      ...SectionFragment
    }
  }
  ${SECTION_FRAGMENT}
`;

const UPDATE_SECTION = gql`
  mutation updateSection($payload: UpdateSectionInput) {
    updateSection(payload: $payload) {
      ...SectionFragment
    }
  }
  ${SECTION_FRAGMENT}
`;

export const GET_SECTION = gql`
  query getSection($path: [String!]!, $page: Int, $pageSize: Int) {
    getSection(path: $path, page: $page, pageSize: $pageSize) {
      ...SectionFragment
      posts {
        page
        pageSize
        total
        pages
        items {
          ...PostFragment
        }
      }
      stickyPosts {
        ...PostFragment
      }
      subsections {
        id
        title
        path
      }
    }
  }
  ${SECTION_FRAGMENT}
  ${POST_FRAGMENT}
`;

const GET_SECTION_ONLY_STICKY = gql`
  query getSection($path: [String!]!, $page: Int, $pageSize: Int) {
    getSection(path: $path, page: $page, pageSize: $pageSize) {
      ...SectionFragment
      stickyPosts {
        ...PostFragment
      }
    }
  }
  ${SECTION_FRAGMENT}
  ${POST_FRAGMENT}
`;

export const GET_REPLIES = gql`
  query getReplies($path: [String!]!, $postId: Int, $page: Int, $pageSize: Int) {
    getReplies(path: $path, postId: $postId, page: $page, pageSize: $pageSize) {
      post {
        ...PostFragment
      }
      replies {
        page
        pageSize
        total
        pages
        items {
          ...PostFragment
        }
      }
    }
  }
  ${POST_FRAGMENT}
`;

const GET_POST = gql`
  query getPost($path: [String!]!, $postId: Int) {
    getPost(path: $path, postId: $postId) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

const CREATE_POST = gql`
  mutation createPost($path: [String!]!, $payload: PostInput) {
    createPost(path: $path, payload: $payload) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

const UPDATE_POST = gql`
  mutation updatePost($path: [String!]!, $postId: Int, $payload: PostInput) {
    updatePost(path: $path, postId: $postId, payload: $payload) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

const DELETE_POST = gql`
  mutation deletePost($path: [String!]!, $postId: Int) {
    deletePost(path: $path, postId: $postId) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

const TOGGLE_ACTION = gql`
  mutation toggleAction($path: [String!]!, $postId: Int, $action: String, $payload: PostActionInput) {
    toggleAction(path: $path, postId: $postId, action: $action, payload: $payload) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;

interface PostResponse extends HookResult {
  post?: Post;
  refetch: (variables?: { path?: string; postId?: number }) => Promise<ApolloQueryResult<{ getPost: Post }>>;
}

export const usePost = (path: string[], postId: number, skip = false): PostResponse => {
  const { data, error, loading, refetch, networkStatus } = useQuery<{ getPost: Post }>(GET_POST, {
    variables: { path, postId: postId },
    skip,
  });

  return {
    post: data?.getPost,
    error,
    loading,
    refetch,
    networkStatus,
  };
};

export const useCreateSection = (): ((
  path: string[],
  name: string,
  title?: string,
  description?: string
) => Promise<FetchResult<{ createSection: Section }>>) => {
  const [create] = useMutation(CREATE_SECTION);

  return (path: string[], name: string, title?: string, description?: string) =>
    create({
      variables: {
        payload: {
          path,
          name,
          title,
          description,
        },
      },
    });
};

export const useUpdateSection = (): ((
  path: string[],
  title?: string,
  description?: string
) => Promise<FetchResult<{ updateSection: Section }>>) => {
  const [update] = useMutation(UPDATE_SECTION);

  return (path: string[], title?: string, description?: string) =>
    update({
      variables: {
        payload: {
          path,
          title,
          description,
        },
      },
    });
};

export type GetSectionResponse = {
  getSection: Section & {
    description: string;
    role: {
      permissions: Omit<SectionPermissions, 'canEdit'>;
    };
    posts: {
      page: number;
      pageSize: number;
      total: number;
      pages: number;
      items: Post[];
    };
    stickyPosts: Post[];
    subsections: {
      id: number;
      title: string;
      path: string;
    }[];
  };
};

type GetSectionOnlyStickyResponse = {
  getSection: Section & {
    description: string;
    role: {
      permissions: Omit<SectionPermissions, 'canEdit'>;
    };
    stickyPosts: Post[];
  };
};

type GetSectionVariables = { path: string[]; page: number; pageSize: number };

interface GetSectionHookResult<TData> extends HookResult {
  refetch: (variables?: { path?: string[]; page?: number; pageSize?: number }) => Promise<ApolloQueryResult<TData>>;
  data?: TData;
  fetchMore: (
    params: FetchMoreQueryOptions<{ path: string[]; page: number; pageSize: number }, TData> &
      FetchMoreOptions<TData, { path: string[]; page: number; pageSize: number }>
  ) => Promise<ApolloQueryResult<TData>>;
}

type GetSectionFunction = ((params: {
  variables: GetSectionVariables;
  onlySticky: true;
}) => GetSectionHookResult<GetSectionOnlyStickyResponse>) &
  ((params: { variables: GetSectionVariables; onlySticky?: false }) => GetSectionHookResult<GetSectionResponse>);

export const useGetSection: GetSectionFunction = ({
  variables,
  onlySticky = false,
}: {
  variables: { page: number; pageSize: number; path: string[] };
  onlySticky?: boolean;
}) => {
  const { data, loading, fetchMore, error, refetch, networkStatus } = useQuery(
    onlySticky ? GET_SECTION_ONLY_STICKY : GET_SECTION,
    {
      variables,
    }
  );

  return { data, loading, fetchMore, error, refetch, networkStatus };
};

// We have to follow the full schema of the object that is supposed to be returned if mutation is successful
const getCreatePostOptimistic = (postPayload: PostPayload, path: string[], isAdmin: boolean, currentUser?: Me) => {
  return {
    __typename: 'Post',
    id: `temp-id-${uuid()}`,
    title: postPayload.title,
    content: postPayload.content,
    media: postPayload.media || null,
    created: new Date().toISOString(),
    deleted: false,
    publishDate: null,
    sticky: false,
    user: {
      id: currentUser?.id,
      username: currentUser?.username,
      realm: currentUser?.realm,
      email: currentUser?.email,
      role: { labels: isAdmin ? ['admin', 'member'] : ['member'], __typename: 'PostUserRole' },
      profileImage: currentUser?.profileImage,
      __typename: 'PostUser',
    },
    section: { path: `/${path.join('/')}`, __typename: 'Section' },
    permissions: {
      canReply: true,
      canEdit: true,
      canDelete: true,
      canSticky: true,
      canUnsticky: false,
      canViewReflectionReplies: true,
      __typename: 'PostPermissions',
    },
    stats: { likes: null, replies: null, __typename: 'Stats' },
    you: { have: { liked: null, __typename: 'UserStats' }, __typename: 'You' },
  };
};

export const useCreateReplyPost = (
  onUpdate: (post: Post) => void
): ((path: string[], payload: PostPayload) => Promise<FetchResult<{ createPost: Post }>>) => {
  const context = useContext(CoursePageContext);
  const { currentUser } = useRootContext();
  const { isAdmin } = useCurrentUserAccess();
  const optimisticMutationsEnabled = useFeatureEnabled(FEATURE.SYSTEM_OPTIMISTIC_MUTATIONS);

  const [create] = useMutation(CREATE_POST, {
    // It's called twice: first time, if optimisticResponse is provided, second time on the actual mutation result
    update: (_, { data: { createPost } }) => {
      onUpdate(createPost);
    },
  });

  return (path: string[], postPayload: PostPayload) => {
    const payload = {
      title: postPayload.title,
      content: postPayload.content,
      pid: postPayload.pid || null,
      originProductId: context.productId,
      media: postPayload.media || null,
      sticky: Boolean(postPayload.sticky),
      publishDate: postPayload.publishDate || null,
      shouldSendNotification: postPayload.shouldSendNotification,
    };
    if (postPayload.isReflectionSection) {
      delete payload.shouldSendNotification;
    }

    const createPostOptimistic = getCreatePostOptimistic(postPayload, path, isAdmin, currentUser);

    return create({
      variables: {
        path,
        payload,
      },
      optimisticResponse: optimisticMutationsEnabled
        ? {
            createPost: createPostOptimistic,
          }
        : undefined,
    });
  };
};

export const useCreatePost = (): ((path: string[], payload: PostPayload) => Promise<FetchResult<{ createPost: Post }>>) => {
  const context = useContext(CoursePageContext);

  const [create] = useMutation(CREATE_POST);

  return (path: string[], postPayload: PostPayload) => {
    const payload = {
      title: postPayload.title,
      content: postPayload.content,
      pid: postPayload.pid || null,
      originProductId: context.productId,
      media: postPayload.media || null,
      sticky: Boolean(postPayload.sticky),
      publishDate: postPayload.publishDate || null,
      shouldSendNotification: postPayload.shouldSendNotification,
    };
    if (postPayload.isReflectionSection) {
      delete payload.shouldSendNotification;
    }
    return create({
      variables: {
        path,
        payload,
      },
    });
  };
};

export const useUpdatePost = (): {
  loading: boolean;
  updatePostAction: (path: string[], postId: number, payload: PostPayload) => Promise<FetchResult<{ updatePost: Post }>>;
} => {
  const [update, { loading }] = useMutation(UPDATE_POST);

  return {
    loading,
    updatePostAction: (path: string[], postId: number, payload: PostPayload) =>
      update({
        variables: {
          path,
          postId,
          payload: {
            title: payload.title,
            content: payload.content,
            media: payload.media ? { url: payload.media.url, type: payload.media.type } : undefined, // Return undefined so graphql knows not to update field
            publishDate: payload.publishDate,
            sticky: payload.sticky,
          },
        },
      }),
  };
};

export const useDeletePost = (): ((path: string[], postId: number) => Promise<FetchResult<{ deletePost: Post }>>) => {
  const [deletePost] = useMutation(DELETE_POST);

  return (path: string[], postId: number) => deletePost({ variables: { path, postId } });
};

export const usePostAction = (): ((
  path: string[],
  postId: number,
  action: string,
  payload?: { scalar: number }
) => Promise<FetchResult<{ toggleAction: Record<string, unknown> }>>) => {
  const [toggleAction] = useMutation(TOGGLE_ACTION);

  return (path: string[], postId: number, action: string, payload?: { scalar: number }) =>
    toggleAction({
      variables: { path, postId, action, payload },
    });
};

export const useGetReplies = ({
  path,
  postId,
  page = 1,
  pageSize = 5,
  skip,
}: {
  path: string[];
  postId: number;
  page: number;
  pageSize: number;
  skip: boolean;
}): QueryResult<
  {
    getReplies: {
      post: Post;
      replies: {
        page: number;
        pageSize: number;
        total: number;
        pages: number;
        items: Post[];
      };
    };
  },
  {
    path: string[];
    postId: number;
    page?: number;
    pageSize?: number;
  }
> =>
  useQuery<
    {
      getReplies: {
        post: Post;
        replies: {
          page: number;
          pageSize: number;
          total: number;
          pages: number;
          items: Post[];
        };
      };
    },
    {
      path: string[];
      postId: number;
      page?: number;
      pageSize?: number;
    }
  >(GET_REPLIES, {
    variables: { path, postId, page, pageSize },
    skip,
  });
