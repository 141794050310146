import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getErrorResponseMessage } from '@/utils/gqlErrors';
import { Header1 } from '@/components/Typography/Typography';
import { AutoComplete } from '@/components/Form/types';
import { Input } from '@/component/Input/Input';
import { useCurrentUser } from '@/store/currentUser';
import { useUpdateCurrentUser } from '@/store/user';
import { OnboardingStep, StepComponent } from '../types';
import { Avatar } from '../styles';

export const Profile: StepComponent = ({ onReady, onSave }) => {
  const { t } = useTranslation('onboarding');
  const { user } = useCurrentUser();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { updateUser } = useUpdateCurrentUser();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname);
      setLastName(user.lastname);
    }
  }, [user]);

  useEffect(() => {
    onReady(OnboardingStep.Profile, !!firstName && !!lastName);
  }, [firstName, lastName]);

  onSave(
    OnboardingStep.Profile,
    useCallback(async () => {
      const { errors } = await updateUser({ firstname: firstName, lastname: lastName });
      if (errors) {
        const message = getErrorResponseMessage(errors);
        throw `${t('There were errors when trying to update your name:\n')} ${message}`;
      }
    }, [firstName, lastName])
  );

  return (
    <>
      <Header1>{t('Create a profile')}</Header1>
      <Avatar profileImage={user?.profileImage} canChoosePicture={true} />
      <Input
        label={t('First name')}
        autoComplete={AutoComplete.givenName}
        required
        value={firstName}
        onChange={(e) => setFirstName(e.currentTarget.value.trim())}
      />
      <Input
        label={t('Last name')}
        autoComplete={AutoComplete.familyName}
        required
        value={lastName}
        onChange={(e) => setLastName(e.currentTarget.value.trim())}
      />
    </>
  );
};
