export interface ContentProvider {
  id: number;
  name: string;
  label: string;
  userName?: string;
  lastAccessed?: Date;
  status?: string;
}

export enum LicenseStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
}
export interface IssuedLicense {
  id: number;
  userId: number;
  enrollment: string;
  status: LicenseStatus;
  sId: string;
  createdAt: string;
  updatedAt: string;
  employeeId: string;
  division: string;
  businessUnit: string;
  businessArea: string;
  user: {
    email: string;
    username?: string | undefined;
  };
}

export interface UserFilter {
  userId: number;
  email: string;
}
