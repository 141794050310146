/* eslint-disable import/no-unused-modules */
import styled from 'styled-components';

import { Overline } from '../Typography/Typography';

export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 1.75rem;
  height: 0.875rem;
`;

export const Input = styled.input`
  top: 0;
  left: -100%;
  width: 300%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;

  &:checked + span {
    background-color: var(--color-primary);

    &:before {
      border-color: var(--color-primary);
    }
  }

  &:disabled + span,
  &:disabled:checked + span {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:disabled + span {
    background-color: var(--color-secondaruy);
  }

  &:disabled:checked + span {
    background-color: var(--color-primary);

    &:before {
      border-color: var(--color-primary);
    }
  }

  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + span:before {
    transform: translateX(0.875rem);
  }

  // Allows listening to pointer events, though even parent label element has pointer-events set as none
  pointer-events: auto;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-secondary);
  transition: 0.225s;
  border-radius: 0.875rem;

  &:before {
    position: relative;
    border-radius: 50%;
    border: 2px solid var(--color-dusty-gray);
    box-sizing: border-box;
    content: '';
    height: 0.875rem;
    width: 0.875rem;
    left: 0;
    background-color: var(--color-white);
    transition: 0.225s;
  }
`;

export const Label = styled(Overline).attrs((props) => ({ as: 'label', ...props }))<{ flip?: boolean; disabled?: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};
  justify-content: ${({ flip }) => (flip ? 'flex-end' : 'flex-start')};

  ${InputContainer} {
    ${({ flip }) => (flip ? 'margin-right: var(--gap-sm);' : 'margin-left: var(--gap-sm);')}
  }

  // Prevents click events on label (it's required, that we only listen to input events)
  pointer-events: none;
`;
