export type Realm = string;

export const REALM: { [key: string]: Realm } = {
  CLS: 'cls',
  KOMPETENS: 'kompetens',
  SEB: 'seb',
  ATLASCOPCO: 'atlascopco',
  AUTOLIV: 'autoliv',
  AUTOLIVPILOT: 'autoliv-pilot',
  LANSFORSAKRINGAR: 'lansforsakringar',
};
