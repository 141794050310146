import React from 'react';
import { useTranslation } from 'react-i18next';

import './discussions.scss';
import { MediaType } from '../../types/post';
import { displayRealm, pickLabel } from '@/utils/misc';
import { distanceToNow } from '../../utils/time';
import { Icon } from '@/components/Icon';
import { Tag } from '@/components/Tag/Tag';
import { IconPropType } from '@/ui/Icon';

interface Props {
  username: string;
  realm: string;
  created: string;
  labels?: string[];
  sticky?: boolean;
  media?: MediaType;
}

const icons: { [key in MediaType]: IconPropType } = {
  image: 'image',
  video: 'video',
  resource: 'file',
};

export const PostTopMeta = ({ username, realm, created, sticky = false, labels, media }: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  return (
    <div styleName="post-top-meta">
      <span styleName="username" className="overline is-marginless">
        {username}
      </span>
      {pickLabel(labels) && <Tag>{t(pickLabel(labels)!)}</Tag>}
      <span className="caption">{displayRealm(realm)}</span>
      <span className="caption">{distanceToNow(new Date(created))}</span>
      {sticky && (
        <span>
          <Icon icon="thumbtack" />
        </span>
      )}
      {media && (
        <span>
          <Icon icon={icons[media]} />
        </span>
      )}
    </div>
  );
};
