import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';

import { AssignmentTemplateDto, UseAssignmentTemplatesHook } from './types';

export const ASSIGNMENT_TEMPLATES_QUERY = gql`
  query assignmentTemplates {
    assignmentTemplates {
      id
      image
      name
      teaser
    }
  }
`;

export const useAssignmentTemplates = (): UseAssignmentTemplatesHook => {
  const { data, loading, error, refetch } =
    useQuery<{ assignmentTemplates: AssignmentTemplateDto[] }>(ASSIGNMENT_TEMPLATES_QUERY);

  return {
    data: data?.assignmentTemplates,
    loading,
    error,
    refetch,
  };
};
