import React from 'react';
import { useParams } from 'react-router-dom';

import { Layout } from '@/ui/Layout';
import { Button } from '@/ui/Button';
import { CoursePageDiscussion } from '@/component/Discussions/CoursePageDiscussion';
import { HeroStyled, TitleStyled } from './styles';
import { useLearningJourney } from '@/store/v2/journeys/useLearningJourney';
import { useJourneyNavigate } from '../../utils/useJourneyNavigate';
import { convertTitle } from '@/utils/misc';
import { CoursePageContext } from '@/pages/learning/CourseDetails/CoursePageContext';
import { useHasSpacePermission } from '@/store/spaces';
import { SpacePermission } from '@/types/learning/learning-catalog';

export const ViewDiscussions = (): JSX.Element | null => {
  const { navigateToLearnerView, navigate } = useJourneyNavigate();

  const { journeyId } = useParams<{ journeyId: string }>();

  const { journey, loading: journeyLoading } = useLearningJourney({
    id: Number(journeyId),
  });

  const { data: isSpaceAdmin } = useHasSpacePermission(journey?.spaceId || 0, SpacePermission.HasWriteAccessToLearningContent);

  if (journeyLoading || !journey?.discussionForumSectionPath) return null;

  const handleNavigateBack = () => {
    if (!journeyId || !journey.title) {
      navigate(-1);
    }

    navigateToLearnerView({
      journeyId: journey.id,
      journeyTitle: convertTitle(journey.title),
    });
  };

  return (
    <>
      <Layout.ContentSection marginTop>
        <HeroStyled>
          <Button variant="ghost" icon="arrow-left" onClick={handleNavigateBack}>
            Back
          </Button>
          <TitleStyled>Discussion</TitleStyled>
        </HeroStyled>
      </Layout.ContentSection>
      <Layout.ContentSection marginTop marginBottom>
        <div>
          <CoursePageContext.Provider value={{ spaceId: journey?.spaceId, isAdmin: isSpaceAdmin }}>
            <CoursePageDiscussion
              path={journey.discussionForumSectionPath?.split('/').slice(1) || []}
              spaceId={journey.spaceId}
              enableGeneralChannel
            />
          </CoursePageContext.Provider>
        </div>
      </Layout.ContentSection>
    </>
  );
};
