import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { UNDERLINE_TAB_MARGIN_SIDE_PX } from '../atoms/UnderlinedTabs/Trigger';

export const useUnderlinedTabs = ({
  activeTab,
}: {
  activeTab: number;
}): {
  activeTabWidth: number;
  activeTabLeft: number;
  allTabsElements: MutableRefObject<(HTMLButtonElement | null)[]>;
  listElement: MutableRefObject<HTMLElement | null>;
} => {
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [activeTabLeft, setActiveTabLeft] = useState(0);
  const allTabsElements = useRef<(HTMLButtonElement | null)[]>([]);
  const listElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const currentTab = allTabsElements.current[activeTab];
    const list = listElement.current;
    setActiveTabWidth(
      (currentTab?.clientWidth || 0) + UNDERLINE_TAB_MARGIN_SIDE_PX * 2 // both sides
    );

    setActiveTabLeft(
      (currentTab?.getBoundingClientRect()?.left || 0) - (list?.getBoundingClientRect().left || 0) - UNDERLINE_TAB_MARGIN_SIDE_PX // left side only
    );
  }, [activeTab]);

  return {
    activeTabWidth,
    activeTabLeft,
    allTabsElements,
    listElement,
  };
};
