import styled from 'styled-components';

import { Button } from '@/ui/Button';

const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral.outline1}; ;
`;
const RootStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 84px 24px 84px;
`;

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const NavigationActions = ({
  backDisabled,
  onBackClick,
  nextDisabled,
  onNextClick,
  nextHidden,
}: {
  backDisabled?: boolean;
  onBackClick: () => void;
  nextDisabled?: boolean;
  nextHidden?: boolean;
  onNextClick: () => void;
}): JSX.Element => {
  return (
    <RootStyled>
      <Divider />
      <ActionsStyled>
        <Button variant="ghost" icon={'angle-left'} disabled={Boolean(backDisabled)} onClick={onBackClick}>
          Previous
        </Button>
        {!nextHidden && (
          <Button
            variant="primary"
            icon={'angle-right'}
            $iconPosition="end"
            disabled={Boolean(nextDisabled)}
            onClick={onNextClick}
          >
            Next
          </Button>
        )}
      </ActionsStyled>
    </RootStyled>
  );
};
