import React from 'react';
import { useTranslation } from 'react-i18next';

export const AboutUsPage = (): React.ReactElement => {
  const { t } = useTranslation('about');
  return (
    <section className="section is-padded has-background-white-ter">
      <div className="container">
        <div className="grid">
          <div className="gc gc-3-d gc-2-t gc-1-l gc-0-p" />
          <div className="gc gc-6 gc-8-t gc-10-l gc-12-p">
            <div className="content">
              <h1 className="header1">{t('About us')}</h1>
              <p>{t('about-p1')}</p>
              <p>{t('about-p2')}</p>
              <p>{t('about-p3')}</p>
            </div>
            <div className="body2">
              <ul>
                <li>
                  <div className="overline">{t('Find us', { ns: 'footer' })}</div>
                </li>
                <li>{t('address-street', { ns: 'footer' })}</li>
                <li>{t('address-postal', { ns: 'footer' })}</li>
                <li>{t('address-country', { ns: 'footer' })}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
