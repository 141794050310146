import styled, { css, keyframes } from 'styled-components';

import { deviceUp } from '@/styles/utils';
import { Box } from '@/components/Box/Box';
import { cvar } from '@/styles/GlobalStyle';
import { Header4 } from '@/components/Typography/Typography';
import { BaseButton } from '@/components/Button/components/BaseButton';

export type ModalSize = 'large' | 'default' | 'small';

const handleSize = (size: ModalSize) => {
  switch (size) {
    case 'large':
      return 800;
    case 'small':
      return 360;
    default:
      return 500;
  }
};

const fadeIn = keyframes`from { opacity: 0; }`;

export const StyledModalOverlay = styled.div`
  align-items: center;
  animation: ${fadeIn} 200ms ease-out;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1990;
`;

export const StyledModal = styled(Box).attrs(props => ({ ...props, paddingSize: 'none' }))<{
  size: ModalSize;
  isConfirmation?: boolean;
}>`
  align-items: center;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-large);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 80vh;
  overflow: hidden;
  width: ${props => handleSize(props.size)}px;
  z-index: ${props => (props.isConfirmation ? 2001 : 2000)};
`;

export const StyledModalHeader = styled.div<{ isConfirmation?: boolean; isWarning?: boolean }>`
  align-items: center;
  ${({ isConfirmation: is }) => !is && `background-color: ${cvar('color-wild-sand')}`};
  ${({ isWarning: is }) =>
    is &&
    css`
      background-color: ${cvar('color-monza')};
      ${Header4}, ${BaseButton}, & {
        color: ${cvar('color-white')};
      }
    `};
  border-top-left-radius: var(--radius-outer);
  border-top-right-radius: var(--radius-outer);
  display: flex;
  padding: var(--gap-xs);
  justify-content: space-between;
  width: 100%;

  ${deviceUp.sm`
    padding: var(--gap-sm);
  `}

  ${deviceUp.md`
    padding: var(--gap-md);
  `}
`;

export const StyledModalBody = styled.div<{ isConfirmation?: boolean }>`
  ${({ isConfirmation: is }) => (is ? 'background-color: var(--color-wild-sand)' : null)};
  border-bottom-left-radius: var(--radius-outer);
  border-bottom-right-radius: var(--radius-outer);
  overflow-wrap: break-word;
  overflow-y: auto;
  padding: var(--gap-sm) var(--gap-xs);
  width: 100%;
  white-space: break-spaces;
  ${deviceUp.sm`
    padding: var(--gap-md) var(--gap-sm);
  `}

  ${deviceUp.md`
    padding: var(--gap-lg) var(--gap-md);
  `}
`;
