import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Button } from './Button';
import { ButtonProps } from './types';

export interface RouterLinkButtonProps {
  to: LinkProps['to'];
  target?: string;
  rel?: string;
  state?: LinkProps['state'];
}

export const RouterLinkButton = (props: ButtonProps | RouterLinkButtonProps): JSX.Element => <Button as={Link} {...props} />;
