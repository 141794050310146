import styled from 'styled-components';

import { GridProps } from '../types';
import { mediaQuery } from '@/ui/utils';

export const Container = styled.div<GridProps>`
  display: grid;
  ${(p) => `row-gap: ${p.theme.gutter[p.rowGap ?? 'xsmall']}px;`}

  ${(p) =>
    p.columns
      ? `grid-template-columns: repeat(${p.theme.columns[p.columns]}, 1fr);
        grid-column-gap: ${p.theme.gutter[p.columnGap ?? 'medium']}px;
`
      : `
    ${mediaQuery.only('mobile')} {
        grid-template-columns: repeat(${p.theme.columns.small}, 1fr);
        grid-column-gap: ${p.theme.gutter.small}px;
    }

    ${mediaQuery.between('mobileLandscape', 'tablet')} {
        grid-template-columns: repeat(${p.theme.columns.medium}, 1fr);
        grid-column-gap: ${p.theme.gutter.small}px;
    }

    ${mediaQuery.from('desktop')} {
        grid-template-columns: repeat(${p.theme.columns.large}, 1fr);
        grid-column-gap: ${p.theme.gutter.medium}px;
    }
`}
`;
