import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { FormSchema } from '..';
import { formatToHoursAndMinutesFromSeconds, getRangeBetweenDatesInMs } from '@/utils/time';
import { Icon } from '@/ui/Icon';

const RootStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  min-width: 80px;
  height: 42px;
`;
export const DurationInformer = (): JSX.Element => {
  const { watch } = useFormContext<FormSchema>();

  const [startsAtTime, endsAtTime] = watch(['startsAtTime', 'endsAtTime']);

  return (
    <RootStyled>
      <Icon icon={['far', 'clock']} />
      {formatToHoursAndMinutesFromSeconds(getRangeBetweenDatesInMs(new Date(endsAtTime), new Date(startsAtTime)) / 1000)}
    </RootStyled>
  );
};
