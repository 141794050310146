/**
 * Left all components in one file to make it easier to compare it when needed with @link https://platejs.org/docs/components/slash-input-element
 */
import React, {
  HTMLAttributes,
  ReactNode,
  RefObject,
  createContext,
  forwardRef,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { PointRef } from 'slate';
import {
  ComboboxItemProps,
  Combobox,
  ComboboxGroup,
  ComboboxGroupLabel,
  ComboboxItem,
  ComboboxPopover,
  ComboboxProvider,
  ComboboxRow,
  Portal,
  useComboboxContext,
  useComboboxStore,
} from '@ariakit/react';
import { filterWords } from '@udecode/plate-combobox';
import { UseComboboxInputResult, useComboboxInput, useHTMLInputCursorState } from '@udecode/plate-combobox/react';
import { TElement, createPointRef, getPointBefore, insertText, moveSelection } from '@udecode/plate-common';
import { findNodePath, useComposedRef, useEditorRef } from '@udecode/plate-common/react';
import styled, { css } from 'styled-components';

type FilterFn = (item: { value: string; group?: string; keywords?: string[]; label?: string }, search: string) => boolean;

interface InlineComboboxContextValue {
  filter: FilterFn | false;
  inputProps: UseComboboxInputResult['props'];
  inputRef: RefObject<HTMLInputElement | null>;
  removeInput: UseComboboxInputResult['removeInput'];
  setHasEmpty: (hasEmpty: boolean) => void;
  showTrigger: boolean;
  trigger: string;
}

const InlineComboboxContext = createContext<InlineComboboxContextValue>(null as any);

export const defaultFilter: FilterFn = ({ group, keywords = [], label, value }, search) => {
  const uniqueTerms = new Set([value, ...keywords, group, label].filter(Boolean));

  return Array.from(uniqueTerms).some((keyword) => filterWords(keyword!, search));
};

interface InlineComboboxProps {
  children: ReactNode;
  element: TElement;
  trigger: string;
  filter?: FilterFn | false;
  hideWhenNoValue?: boolean;
  setValue?: (value: string) => void;
  showTrigger?: boolean;
  value?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InlineCombobox = ({
  children,
  element,
  filter = defaultFilter,
  hideWhenNoValue = false,
  setValue: setValueProp,
  showTrigger = true,
  trigger,
  value: valueProp,
}: InlineComboboxProps) => {
  const editor = useEditorRef();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const cursorState = useHTMLInputCursorState(inputRef);

  const [valueState, setValueState] = useState('');
  const hasValueProp = valueProp !== undefined;
  const value = hasValueProp ? valueProp : valueState;

  const setValue = useCallback(
    (newValue: string) => {
      setValueProp?.(newValue);

      if (!hasValueProp) {
        setValueState(newValue);
      }
    },
    [setValueProp, hasValueProp]
  );

  /**
   * Track the point just before the input element so we know where to
   * insertText if the combobox closes due to a selection change.
   */
  const [insertPoint, setInsertPoint] = useState<PointRef | null>(null);

  useEffect(() => {
    const path = findNodePath(editor, element);

    if (!path) return;

    const point = getPointBefore(editor, path);

    if (!point) return;

    const pointRef = createPointRef(editor, point);
    setInsertPoint(pointRef);

    return () => {
      pointRef.unref();
    };
  }, [editor, element]);

  const { props: inputProps, removeInput } = useComboboxInput({
    cancelInputOnBlur: false,
    cursorState,
    ref: inputRef,
    onCancelInput: (cause) => {
      if (cause !== 'backspace') {
        insertText(editor, trigger + value, {
          at: insertPoint?.current ?? undefined,
        });
      }
      if (cause === 'arrowLeft' || cause === 'arrowRight') {
        moveSelection(editor, {
          distance: 1,
          reverse: cause === 'arrowLeft',
        });
      }
    },
  });

  const [hasEmpty, setHasEmpty] = useState(false);

  const contextValue: InlineComboboxContextValue = useMemo(
    () => ({
      filter,
      inputProps,
      inputRef,
      removeInput,
      setHasEmpty,
      showTrigger,
      trigger,
    }),
    [trigger, showTrigger, filter, inputRef, inputProps, removeInput, setHasEmpty]
  );

  const store = useComboboxStore({
    setValue: (newValue) => startTransition(() => setValue(newValue)),
  });

  const items = store.useState('items');

  /**
   * If there is no active ID and the list of items changes, select the first
   * item.
   */
  useEffect(() => {
    if (!store.getState().activeId) {
      store.setActiveId(store.first());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, store]);

  return (
    <span contentEditable={false}>
      <ComboboxProvider open={(items.length > 0 || hasEmpty) && (!hideWhenNoValue || value.length > 0)} store={store}>
        <InlineComboboxContext.Provider value={contextValue}>{children}</InlineComboboxContext.Provider>
      </ComboboxProvider>
    </span>
  );
};

const InlineComboboxInputRootStyled = styled.span`
  position: relative;
  min-height: 1lh;
`;

const InlineComboboxInputValueStyled = styled.span`
  visibility: hidden;
  overflow: hidden;
`;

const ComboboxStyled = styled(Combobox)`
  position: absolute;
  left: 0;
  top: 0;
  width: fit-content;
  height: 100%;
  background: transparent;
  outline: none;
  border: none;
`;

const InlineComboboxInput = forwardRef<HTMLInputElement, HTMLAttributes<HTMLInputElement>>(({ className, ...props }, propRef) => {
  const { inputProps, inputRef: contextRef, showTrigger, trigger } = useContext(InlineComboboxContext);

  const store = useComboboxContext()!;
  const value = store.useState('value');

  const ref = useComposedRef(propRef, contextRef);

  /**
   * To create an auto-resizing input, we render a visually hidden span
   * containing the input value and position the input element on top of it.
   * This works well for all cases except when input exceeds the width of the
   * container.
   */
  return (
    <>
      {showTrigger && trigger}

      <InlineComboboxInputRootStyled>
        <InlineComboboxInputValueStyled aria-hidden="true">{value || '\u200B'}</InlineComboboxInputValueStyled>

        <ComboboxStyled ref={ref} className={className} value={value} autoSelect {...inputProps} {...props} />
      </InlineComboboxInputRootStyled>
    </>
  );
});

InlineComboboxInput.displayName = 'InlineComboboxInput';

const InlineComboboxContentStyled = styled(ComboboxPopover)`
  background: ${({ theme }) => theme.colors.neutral.background};
  ${({ theme }) => theme.shadow.primary[8]};
  border-radius: 4px;
  width: 240px;
  padding: 8px 0;
  overflow-y: scroll;
  z-index: 100;
  max-height: 320px;
`;

const InlineComboboxContent: typeof ComboboxPopover = ({ className, ...props }) => {
  // Portal prevents CSS from leaking into popover
  return (
    <Portal>
      <InlineComboboxContentStyled className={className} {...props} />
    </Portal>
  );
};

const ComboboxItemCSS = css`
  position: relative;
  margin: 0;
  display: flex;
  user-select: none;
  align-items: center;
  border-radius: 4px;
  color: black;
  outline: none;

  padding: 8px 16px;
  height: 40px;
  box-sizing: content-box;
  gap: 16px;
  ${({ theme }) => theme.typography.body3};
`;

const ComboboxItemInteractiveCSS = css`
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.states.hover1};
  }

  &[data-active-item='true'] {
    color: ${({ theme }) => theme.colors.neutral.onBackground};
    background: ${({ theme }) => theme.colors.neutral.backgroundVariant};
  }
`;

const ComboboxItemStyled = styled(ComboboxItem)`
  ${ComboboxItemCSS};
  ${ComboboxItemInteractiveCSS};
`;

export type InlineComboboxItemProps = {
  focusEditor?: boolean;
  group?: string;
  keywords?: string[];
  label?: string;
} & ComboboxItemProps &
  Required<Pick<ComboboxItemProps, 'value'>>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InlineComboboxItem = ({
  className,
  focusEditor = true,
  group,
  keywords,
  label,
  onClick,
  ...props
}: InlineComboboxItemProps) => {
  const { value } = props;

  const { filter, removeInput } = useContext(InlineComboboxContext);

  const store = useComboboxContext()!;

  // Optimization: Do not subscribe to value if filter is false
  const search = filter && store.useState('value');

  const visible = useMemo(
    () => !filter || filter({ group, keywords, label, value }, search as string),
    [filter, group, keywords, label, value, search]
  );

  if (!visible) return null;

  return (
    <ComboboxItemStyled
      className={className}
      onClick={(event) => {
        removeInput(focusEditor);
        onClick?.(event);
      }}
      {...props}
    />
  );
};

const InlineComboboxEmptyStyled = styled.div`
  ${ComboboxItemCSS};
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InlineComboboxEmpty = ({ children, className }: HTMLAttributes<HTMLDivElement>) => {
  const { setHasEmpty } = useContext(InlineComboboxContext);
  const store = useComboboxContext()!;
  const items = store.useState('items');

  useEffect(() => {
    setHasEmpty(true);

    return () => {
      setHasEmpty(false);
    };
  }, [setHasEmpty]);

  if (items.length > 0) return null;

  return <InlineComboboxEmptyStyled className={className}>{children}</InlineComboboxEmptyStyled>;
};

const InlineComboboxRow = ComboboxRow;

/**
 * Possible Tailwind styling example from Plate.js docs: withCn(ComboboxGroup, 'hidden py-1.5 [&:has([role=option])]:block [&:not(:last-child)]:border-b')
 */
const InlineComboboxGroup = ComboboxGroup;

/**
 * Possible Tailwind styling example from Plate.js docs:  withCn(ComboboxGroupLabel, 'mb-2 mt-1.5 px-3 text-xs font-medium text-muted-foreground');
 */
const InlineComboboxGroupLabel = ComboboxGroupLabel;

export {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxGroup,
  InlineComboboxGroupLabel,
  InlineComboboxInput,
  InlineComboboxItem,
  // eslint-disable-next-line import/no-unused-modules
  InlineComboboxRow,
};
