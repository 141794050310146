import '../CourseDetails/courseDetailsPage.scss';
import { Navigate, useParams } from 'react-router-dom';

import { useLearningCollection } from '../../../store/learning-collection';
import { LoadingHero } from '../../../component/LoadingHero';
import { CollectionHero } from './CollectionHero';
import { LearningVisibility } from '../../../types/learning';
import { LearningStatus } from '@/types/learning';
import { Section } from '@/components/Section/Section';
import { CardGrid } from '@/components/CardGrid/CardGrid';

export type CollectionRouteParams = 'collectionId' | 'collectionName';

export function useLearningCollectionItemRoute(link: string | undefined, skip?: boolean): string | undefined {
  const params = useParams<CollectionRouteParams>();
  if (skip || !link || !params.collectionId || !params.collectionName) {
    return link;
  }
  return `/learning-collection/${params.collectionId}/${params.collectionName}${link}`;
}

export const LearningCollectionPage = (): JSX.Element => {
  const { collectionId } = useParams();
  const { learningCollection, loading } = useLearningCollection(Number(collectionId));

  if (!learningCollection && loading) return <LoadingHero />;

  if (!learningCollection) {
    return <Navigate to="/404" replace />;
  }

  if (learningCollection.status === LearningStatus.ARCHIVED) {
    return <Navigate to={{ pathname: '/404' }} state={{ isArchived: true }} />;
  }

  const cards = learningCollection.items?.filter((card) => card.visibility !== LearningVisibility.HIDDEN) || [];

  return (
    <>
      <CollectionHero learningCollection={learningCollection} />
      <Section>
        <CardGrid loading={loading} cards={cards} isCollection />
      </Section>
    </>
  );
};
