import { v4 as uuid } from 'uuid';

import { BlockType, PracticalAssessmentDocumentBlock } from './types';
import { RBlock, RBlockInput, RBlockV2Type } from '@/store/v2';
import { BlockId } from '../types';

export const createPracticalAssessmentDocumentBlock = (): PracticalAssessmentDocumentBlock => ({
  id: `block-l-${uuid()}` as BlockId,
  type: BlockType.PRACTICAL_ASSESSMENT_DOCUMENT,
  description: '[{"children":[{"text":""}]}]',
  peerReviewEnabled: true,
});

export const transformPracticalAssessmentDocumentBlockToRemoteInput = (
  block: PracticalAssessmentDocumentBlock,
  rPageId?: number,
  order?: number
): RBlockInput => ({
  id: block?.remoteId || undefined,
  type: RBlockV2Type.PracticalAssessmentTask, // ATM PRACTICAL_ASSESSMENT_DOCUMENT is not a block type on remote. It should be converted to PRACTICAL_ASSESSMENT_TASK
  order: order || 0,
  pageId: rPageId || undefined,
  data: {
    description: block.description,
    type: BlockType.PRACTICAL_ASSESSMENT_DOCUMENT,
    peerReviewEnabled: block.peerReviewEnabled,
  },
});

export const transformPracticalAssessmentDocumentBlockFromRemote = (rBlock: RBlock): PracticalAssessmentDocumentBlock => ({
  id: `block-r-${rBlock.id || 0}` as BlockId,
  remoteId: rBlock.id || 0,
  type: BlockType.PRACTICAL_ASSESSMENT_DOCUMENT,
  description: rBlock.data.description || '[{"children":[{"text":""}]}]',
  peerReviewEnabled: Boolean(rBlock.data.peerReviewEnabled),
});
