import { ApolloError, useQuery, gql, ApolloQueryResult } from '@apollo/client';

import { Space } from '@/types/learning/learning-catalog';
import { AssignmentsV3QueryResult, AssignmentV3 } from '@/store/assignments/types';
import { AssignmentV3Status, AssignmentV3Type } from '@/store/assignments/typesOther';

type UseBaseAssignmentsHook = (query: {
  userIds: number[];
  spaces: readonly Space[] | null;
  status: AssignmentV3Status;
  type?: AssignmentV3Type;
  skip?: boolean;
}) => {
  data: AssignmentV3[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<AssignmentsV3QueryResult>>;
};

export const ASSIGNMENTS_QUERY = gql`
  query assignmentsV3(
    $userIdIN: [Int!]
    $spacedIdsIN: [Int!]
    $status: AssignmentV3Status
    $pagination: Pagination
    $type: AssignmentV3Type
  ) {
    assignmentsV3(
      filter: { userIdIN: $userIdIN, spacedIdsIN: $spacedIdsIN, status: $status, type: $type }
      pagination: $pagination
    ) {
      data {
        id
        name
        teaser
        image
      }
    }
  }
`;

export const useBaseAssignments: UseBaseAssignmentsHook = ({ userIds, spaces, status, skip, type }) => {
  const spacesIds = spaces?.map((space) => space.id) ?? null;

  const { data, loading, error, refetch } = useQuery<AssignmentsV3QueryResult>(ASSIGNMENTS_QUERY, {
    skip,
    variables: {
      type,
      userIdIN: userIds,
      spacedIdsIN: spacesIds,
      pagination: {
        limit: -1,
        page: 1,
      },
      status,
    },
  });

  return {
    data: data?.assignmentsV3?.data,
    loading,
    error,
    refetch,
  };
};
