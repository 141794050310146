import styled, { css } from 'styled-components';

import { IconButton } from '@/ui/Button';

export const RootStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
`;

export const NavigationContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const NavigationButton = styled(IconButton)<{ hidden?: boolean }>`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`;

export const SelectedWeekHeader = styled.div<{ isCurrentWeek?: boolean }>`
  position: relative;
  width: 100%;
  p {
    text-align: center;
    // Make sure the text never conflicts with the help icon:
    margin: 0 calc(12px + 8px);
  }

  color: ${({
    theme: {
      colors: {
        neutral: { onBackground, outline2 },
      },
    },
    isCurrentWeek,
  }) => (isCurrentWeek ? onBackground : outline2)};

  & span {
    ${({ isCurrentWeek }) =>
      isCurrentWeek &&
      css`
        font-weight: 700;
      `};
  }
`;

export const HelpButton = styled(IconButton).attrs({ icon: ['far', 'circle-question'] })`
  height: 12px;
  width: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
