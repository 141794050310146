import React from 'react';
import { withRef } from '@udecode/cn';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';

import { OrderedListPlateElement, UnorderedListPlateElement } from '@/component/customEditorV2/ui/Typography';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';

type ListVariant = typeof NumberedListPlugin.key | typeof BulletedListPlugin.key;

const MAP_LIST_TO_STYLED_COMPONENT: Record<ListVariant, typeof PlateElement> = {
  [NumberedListPlugin.key]: OrderedListPlateElement,
  [BulletedListPlugin.key]: UnorderedListPlateElement,
};

export const ListElement = withRef<typeof PlateElement, { variant: ListVariant }>(
  ({ children, className, variant = BulletedListPlugin.key, ...props }, ref) => {
    const Element = MAP_LIST_TO_STYLED_COMPONENT[variant as ListVariant];

    return (
      <Element ref={ref} as={variant} className={className} {...props}>
        {children}
      </Element>
    );
  }
);
