import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import isUrl from 'is-url';

import { Input } from '@/ui/TextField';
import { TextFieldName } from '../../../constants';

export const LearningLink = ({ control }: { control: Control<FieldValues> }): JSX.Element => (
  <Controller
    control={control}
    name={TextFieldName.LearningLink}
    rules={{
      validate: (text) => !text || isUrl(text),
    }}
    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
      <Input
        onChange={onChange}
        name={name}
        value={value}
        leadingIcon="link"
        fullWidth
        label="Link"
        optional
        error={
          error && {
            message: 'Enter valid link. Example: https://www.collegial.com',
          }
        }
      />
    )}
  />
);
