import React from 'react';
import clsx from 'clsx';

import './container.scss';
import { Box } from '@/components/Box/Box';

type CollegialContainerProps = React.PropsWithChildren<{ className?: string }>;

export const CollegialContainer = ({ children, className }: CollegialContainerProps): JSX.Element => (
  <section styleName="hero" className={clsx('hero', className)}>
    <div styleName="hero-body" className="grid hero-body">
      <Box paddingSize="none" styleName="container" className="container" boxShadow>
        <div styleName="inner" className="is-centered">
          {children}
        </div>
        <div styleName="footer" className="grid is-grey">
          <div className="gc gc-12 gc-12-p gc-12-l">
            <div styleName="logo" />
          </div>
        </div>
      </Box>
    </div>
  </section>
);
