import { ApolloQueryResult, gql, MutationTuple, PureQueryOptions, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { HookResult } from './apolloClient';
import { BaseProduct, Product, Space, Variant } from '@/types/learning/learning-catalog';
import { getInventoryQuery } from './inventory';
import { OrderDirection, Participant, ProductParticipantParams } from '@/types/product';
import { LearningSubject, LearningStatus } from '@/types/learning';
import { Card, CardType } from '@/types/learning/card';
import { Catalog, REFETCH_COURSE_CATALOG } from './catalog';

const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    name
    label
    creator
    contentOwner
    timestamp
    costCenter
    legalEntity
    price
    currency
    status
    subjects {
      subjectId
      categoryId
      subject
      category
    }
    space {
      id
      name
    }
    meta {
      IMAGE
      TEASER
      DESCRIPTION
      FORMAT
      LEVEL
      LENGTH
      EFFORT
      TOTAL_EFFORT
      LANGUAGE
      INSTITUTION
      INSTRUCTORS
      LOCATION
      ENROLL_BUTTON_TEXT
      CONFIRM_MESSAGE
      PROVIDER
      CUSTOM_LINK
      OBJECT_ID
      PROVIDER_ACCESS
      TEAMS
      TEAMSIZE
      DISCUSSION_SECTION
      REFLECTION_SECTION
      ATTRIBUTES
      CONNECTIONS
    }
    flags {
      available
      approvalRequired
      globalMandatoryTraining
      enrollmentDisabled
      socialFeaturesEnabled
      reflectionsEnabled
      requiresApplication
      canEdit
      featured
      hasTeams
    }
    services {
      id
      type
      provider
      resource
      url
      meta {
        name
        host
        organizationId
        projectId
        description
        title
        picture
        status
        adminLink
        locale
        timezone
        country
        resource
      }
    }
    stocks {
      id
      total
      reserved
      enabled
    }
    state {
      id
      name
      flags {
        drawStock
        endState
        entitlement
      }
    }
    variants {
      id
      name
      validTo
      validFrom
      enabled
      meta {
        LOCATION
        METHOD
      }
    }
    learnifierCourseName
  }
`;

const getProductsQuery = gql`
  query getProducts {
    getProducts {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const getProductQuery = gql`
  query getProduct($id: Int!) {
    getProduct(id: $id) {
      ...ProductFragment
      isPending
      productVariantId
      orderId
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const getVariantsQuery = gql`
  query getVariants($productId: Int!) {
    getVariants(productId: $productId) {
      id
      name
      validTo
      validFrom
      enabled
      meta {
        LOCATION
        METHOD
      }
    }
  }
`;

const getParticipantsQuery = gql`
  query getParticipants($productId: Int!, $page: Int, $pageSize: Int, $orderDirection: OrderDirection, $stateIds: [Int]!) {
    getParticipants(
      productId: $productId
      page: $page
      pageSize: $pageSize
      orderDirection: $orderDirection
      stateIds: $stateIds
    ) {
      total
      totalPages
      participants {
        id
        username
        email
        realm
        profileImage
      }
    }
  }
`;

const purchaseMutation = gql`
  mutation purchase($products: [PurchaseProduct]!, $note: String) {
    purchase(products: $products, note: $note) {
      orderId
    }
  }
`;

const sadminPurchaseMutation = gql`
  mutation sadminPurchase($product: SadminPurchaseProduct!, $emails: [String]!) {
    sadminPurchase(product: $product, emails: $emails) {
      result {
        orderId
        email
        message
      }
      success
      message
      code
    }
  }
`;

const createOrUpdateProductMutation = gql`
  mutation createOrUpdate($product: ProductInput!) {
    createOrUpdate(product: $product) {
      id
    }
  }
`;

const createVariantMutation = gql`
  mutation createVariant($productId: Int!, $variant: VariantInput!) {
    createVariant(productId: $productId, variant: $variant) {
      id
    }
  }
`;

const updateVariantMutation = gql`
  mutation updateVariant($productId: Int!, $variant: VariantInput!) {
    updateVariant(productId: $productId, variant: $variant) {
      id
    }
  }
`;

const removeVariantMutation = gql`
  mutation removeVariant($productId: Int!, $variantId: Int!) {
    removeVariant(productId: $productId, variantId: $variantId) {
      value
    }
  }
`;

interface RelatedFilter {
  currentId?: number;
  currentType: CardType;
  language?: string;
  subjects: LearningSubject[];
  author?: string;
}

export const filterRelated = (catalog: Catalog, filter: RelatedFilter): Card[] => {
  if (!catalog) return [];
  return catalog.cards
    .filter(({ available }) => available)
    .filter(({ type, entityId }) => type !== filter.currentType || entityId !== filter.currentId)
    .filter(({ language }) => language === filter.language || filter.language === 'empty')
    .filter(({ subjects }) => subjects.some((s) => filter.subjects.some((subject) => subject.subjectId === s.subjectId)))
    .map((card) => ({
      ...card,
      subjects: card.subjects.filter((s) => filter.subjects.some((subject) => subject.subject === s.subject)),
    }));
};

interface ProductResponse extends HookResult {
  product: Product | undefined;
  refetch: (variables?: { id?: number }) => Promise<ApolloQueryResult<{ getProduct: Product }>>;
}

export const useProduct = (id: string | undefined): ProductResponse => {
  const { data, loading, refetch, networkStatus, error } = useQuery<{ getProduct: Readonly<Product> }>(getProductQuery, {
    skip: !id || isNaN(parseInt(id)),
    variables: { id: id && parseInt(id) },
  });

  const product: Product | undefined = data?.getProduct || undefined;

  return {
    product,
    loading,
    refetch,
    error,
    networkStatus,
  };
};

type VariantWithId = Variant & { id: number };

interface VariantsResponse extends HookResult {
  variants: VariantWithId[];
  refetch: (variables?: { productId?: number }) => Promise<ApolloQueryResult<{ getVariants: VariantWithId[] }>>;
}

export const useVariants = (productId?: number): VariantsResponse => {
  const { data, loading, networkStatus, error, refetch } = useQuery<{ getVariants: VariantWithId[] }>(getVariantsQuery, {
    skip: !productId,
    variables: { productId },
  });

  const variantsToMap = data?.getVariants || [];
  const variants = variantsToMap?.map((variant: Readonly<VariantWithId>) => {
    return {
      ...variant,
      validFrom: variant.validFrom ? dayjs(variant.validFrom).local().format('YYYY-MM-DD') : variant.validFrom,
      validTo: variant.validTo ? dayjs(variant.validTo).local().format('YYYY-MM-DD') : variant.validTo,
    };
  });

  return {
    variants,
    loading,
    networkStatus,
    error,
    refetch,
  };
};

interface ParticipantsResponse extends HookResult {
  participants: Participant[];
  total: number;
  totalPages: number;
  refetch: (variables?: {
    productId?: number;
    page?: number;
    pageSize?: number;
    orderDirection?: OrderDirection;
    stateIds?: number[];
  }) => Promise<ApolloQueryResult<{ getParticipants: { participants: Participant[]; total: number; totalPages: number } }>>;
}

export const useParticipants = ({ productId, ...rest }: ProductParticipantParams): ParticipantsResponse => {
  const { data, loading, refetch, networkStatus, error } = useQuery<{
    getParticipants: { participants: Participant[]; total: number; totalPages: number };
  }>(getParticipantsQuery, {
    skip: !productId,
    variables: { productId, ...rest },
  });

  if (data?.getParticipants) {
    const { participants, total, totalPages } = data?.getParticipants;

    return {
      participants,
      total,
      totalPages,
      loading,
      refetch,
      error,
      networkStatus,
    };
  }

  return {
    participants: [],
    total: 0,
    totalPages: 0,
    loading,
    refetch,
    error,
    networkStatus,
  };
};

export const usePurchaseProduct = (
  id?: number
): MutationTuple<
  { purchase: { orderId: number } },
  { products: Partial<{ id: number; productVariantId: number }>[]; note?: string }
> => {
  const refetchQueries: PureQueryOptions[] = [{ query: getInventoryQuery }, REFETCH_COURSE_CATALOG];
  if (id) {
    refetchQueries.push({ query: getProductQuery, variables: { id } });
  }

  return useMutation(purchaseMutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export const useSadminPurchaseProduct = (
  refetchQueries?: Array<string | PureQueryOptions>
): MutationTuple<
  {
    sadminPurchase: {
      result: {
        orderId: number;
        email: string;
        message: string;
      }[];
      success: boolean;
      message: string;
      code: number;
    };
  },
  { product: { id: number; spaceId: number; productVariantId?: number }; emails: string[] }
> => useMutation(sadminPurchaseMutation, { refetchQueries });

export const useCreateOrUpdateProduct = (
  id?: number
): MutationTuple<
  {
    createOrUpdate: {
      id: number;
    };
  },
  { product: Product }
> => {
  const refetchQueries: PureQueryOptions[] = [];
  if (id) {
    refetchQueries.push({ query: getProductQuery, variables: { id } });
  }

  return useMutation(createOrUpdateProductMutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });
};

export const useTransitionProductStatus = (): MutationTuple<
  {
    createOrUpdate: {
      id: number;
    };
  },
  { product: Product }
> =>
  useMutation(createOrUpdateProductMutation, {
    refetchQueries: [{ query: getProductsQuery }, { query: getInventoryQuery }],
    awaitRefetchQueries: true,
  });

export const useCreateVariant = (
  productId?: number
): MutationTuple<
  {
    createVariant: {
      id: number;
    };
  },
  {
    productId: number;
    variant: Variant;
  }
> =>
  useMutation(createVariantMutation, {
    refetchQueries: [
      { query: getVariantsQuery, variables: { productId } },
      { query: getProductQuery, variables: { id: productId } },
    ],
    awaitRefetchQueries: true,
  });

export const useUpdateVariant = (
  productId?: number
): MutationTuple<
  {
    updateVariant: {
      id: number;
    };
  },
  {
    productId: number;
    variant: Variant;
  }
> =>
  useMutation(updateVariantMutation, {
    refetchQueries: [
      { query: getVariantsQuery, variables: { productId } },
      { query: getProductQuery, variables: { id: productId } },
    ],
    awaitRefetchQueries: true,
  });

export const useRemoveVariant = (
  productId?: number
): MutationTuple<
  {
    removeVariant: {
      value: string;
    };
  },
  {
    productId: number;
    variantId: number;
  }
> =>
  useMutation(removeVariantMutation, {
    refetchQueries: [
      { query: getVariantsQuery, variables: { productId } },
      { query: getProductQuery, variables: { id: productId } },
    ],
    awaitRefetchQueries: true,
  });

export function newProduct(spaces: readonly Space[]): BaseProduct {
  return {
    name: '',
    label: '',
    costCenter: '',
    contentOwner: undefined,
    legalEntity: '',
    price: 0,
    currency: '',
    status: LearningStatus.AVAILABLE,
    subjects: [],
    space: {
      id: spaces[0].id,
    },
    meta: {
      ATTRIBUTES: '{}',
      CONNECTIONS: '[]',
      IMAGE: null,
      TEASER: null,
      DESCRIPTION: null,
      FORMAT: null,
      LEVEL: null,
      LENGTH: null,
      EFFORT: null,
      TOTAL_EFFORT: null,
      LANGUAGE: 'English',
      DISCUSSION_SECTION: undefined,
      REFLECTION_SECTION: undefined,
      INSTITUTION: null,
      INSTRUCTORS: null,
      LOCATION: null,
      CATEGORY: null,
      SUBJECT: null,
      ENROLL_BUTTON_TEXT: null,
      CONFIRM_MESSAGE: null,
      PROVIDER: null,
      CUSTOM_LINK: null,
      OBJECT_ID: null,
    },
    flags: {
      available: false,
      approvalRequired: false,
      globalMandatoryTraining: false,
      enrollmentDisabled: false,
      socialFeaturesEnabled: false,
      reflectionsEnabled: false,
      requiresApplication: false,
      featured: false,
    },
    services: [],
  };
}
