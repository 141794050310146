import styled from 'styled-components';

import { BodyMd } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';

export const StyledCheckbox = styled.label<{ $fullwidth?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${({ $fullwidth }) => ($fullwidth ? 'stretch' : 'center')};

  &--disabled {
    opacity: 0.3;
  }
`;

export const StyledCheckboxControl = styled.span`
  border-radius: ${cvar('radius-inner-with-border')};
  border: ${cvar('border-style')};
  display: inline-block;
  font-size: ${cvar('font-size-6')};
  height: 18px;
  margin-right: 10px;
  position: relative;
  vertical-align: text-top;
  width: 18px;

  svg {
    color: ${cvar('color-gray')};
    display: none;
    font-size: 14px;
  }
`;

export const StyledCheckboxInput = styled.span`
  cursor: pointer;
  font-size: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${StyledCheckboxControl} {
      &:after {
        background-color: ${cvar('color-white')};
        content: '';
        height: 10px;
        position: absolute;
        right: -4px;
        top: -2px;
        width: 10px;
        z-index: 5;
      }
      svg {
        display: block;
        position: absolute;
        left: 2px;
        top: -1px;
        transform: rotate(-9deg);
        z-index: 10;
      }
    }

    &:disabled + ${StyledCheckboxControl} {
      opacity: 0.3;
    }
  }
`;

export const StyledCheckboxLabel = styled(BodyMd).attrs({ as: 'span' })`
  align-items: center;
  justify-content: center;
  padding: 0;
  white-space: pre-wrap;
`;
