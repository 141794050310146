import { ApolloError } from '@apollo/client';

import { useUserLearningContentLastParticipationsQueryRemote } from '@/store/v2';
import { ParticipationV3 } from '@/store/v2/catalog-v2/model/participation';

export const useUserContentLastParticipations = ({
  userId,
  contentURNs,
}: {
  userId?: number;
  contentURNs?: string[];
}): {
  participations: ParticipationV3[] | undefined;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
} => {
  const { data, loading, error, refetch } = useUserLearningContentLastParticipationsQueryRemote({
    variables: { userId: userId || 0, learningContentIdIn: contentURNs || [] },
    skip: !userId || !contentURNs?.length,
  });

  return {
    loading,
    error,
    participations: data?.userLearningContentLastParticipations || undefined,
    refetch,
  };
};
