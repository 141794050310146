import React from 'react';
import { PlateElementProps, PlateElement as PlateElementPrimitive } from '@udecode/plate-common/react';

export const PlateElement = React.forwardRef<HTMLDivElement, PlateElementProps>(
  ({ children, className, ...props }: PlateElementProps, ref) => {
    return (
      <PlateElementPrimitive ref={ref} className={className} {...props}>
        {children}
      </PlateElementPrimitive>
    );
  }
);

PlateElement.displayName = 'PlateElement';
