import styled, { css } from 'styled-components';

export const RootStyled = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  * {
    cursor: pointer;
  }
`;

/**
 * Input styles
 */
const DefaultRadioStateCSS = css`
  transition: all 0.3s;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline2};
  border-radius: 50%;
  margin: 0;

  &:hover,
  &:focus-visible {
    outline: ${({ theme }) => theme.colors.neutral.container} solid 4px;
  }

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0px;
    height: 0px;
    margin: 3px;
  }
`;

const CheckedRadioStateCSS = css`
  border: 1px solid ${({ theme }) => theme.colors.primary.main};

  &:hover,
  &:focus-visible {
    outline: ${({ theme }) => theme.colors.states.hover1} solid 4px;
  }

  &:after {
    background-color: ${({ theme }) => theme.colors.primary.main};
    width: 8px;
    height: 8px;
  }
`;

const DisabledRadioStateCSS = css`
  border: 1px solid ${({ theme }) => theme.colors.neutral.disabled};

  &:hover,
  &:focus-visible {
    outline: none;
  }

  &:after {
    background-color: ${({ theme }) => theme.colors.neutral.disabled};
  }
`;

export const RadioStyled = styled.input`
  ${DefaultRadioStateCSS};
  ${({ checked }) => checked && CheckedRadioStateCSS};
  ${({ disabled }) => disabled && DisabledRadioStateCSS};
`;

/**
 * Label Styles
 */
const DefaultLabelStateCSS = css`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.outline2};
`;

const CheckedLabelStateCSS = css`
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const DisabledLabelStateCSS = css`
  color: ${({ theme }) => theme.colors.neutral.disabled};
`;

export const LabelStyled = styled.label<{ disabled?: boolean; checked?: boolean }>`
  ${DefaultLabelStateCSS};

  ${({ checked }) => checked && CheckedLabelStateCSS};
  ${({ disabled }) => disabled && DisabledLabelStateCSS};
`;
