import { ReactionBlock } from '../../../store/block/types';
import { Reaction, ReactionsPage } from './ReactionsPage';
import { ReactionId } from '@/administration/pages/Course/store/block/types';

type ViewResultPageProps = {
  id: string;
  block: ReactionBlock;
  isActive: boolean;
  onBlockChange(block: ReactionBlock): void;
};

const REACTIONS_LIST: Reaction[] = [
  {
    id: ReactionId.CONTINUE_LEARNING,
    icon: ['far', 'book-open-cover'],
    title: 'Learn more on this topic',
  },
  {
    id: ReactionId.CHANGE_TOPIC,
    icon: ['far', 'books'],
    title: 'Learn more about another topic',
  },
  {
    id: ReactionId.APPLY,
    icon: ['far', 'tools'],
    title: 'Apply what I have learned',
  },
  {
    id: ReactionId.DISCUSS,
    icon: ['far', 'comments'],
    title: 'Discuss with a colleague',
  },
  {
    id: ReactionId.NOT_SURE,
    icon: ['far', 'face-thinking'],
    title: 'Not sure',
  },
];

export const ViewReactionPage = ({ id, block, onBlockChange }: ViewResultPageProps): JSX.Element => {
  return (
    <ReactionsPage
      key={id}
      reactions={REACTIONS_LIST}
      selectedReactionId={block?.reactionId}
      onReactionSelected={(reactionId) => onBlockChange({ ...block, reactionId })}
    />
  );
};
