import { useForm, FormProvider } from 'react-hook-form';

import { Modal } from '@/ui/Modal';
import { Form, FormValues } from './Form';
import { CreateReflection } from '../../../useStrategicAssignment/types';

type CreateReflectionModalProps = {
  open: boolean;
  loopId: string;
  loopIndex: number;
  onSubmit: CreateReflection;
  isSubmitting: boolean;
  onSuccess: () => void;
  onClose: () => void;
} & Either<
  {
    onCancel: () => void;
  },
  {
    onBack: (loopId: string) => void;
  }
>;

export const CreateReflectionModal = ({
  open,
  loopId,
  loopIndex,
  isSubmitting,
  onSubmit,
  onCancel,
  onBack,
  onSuccess,
  onClose,
}: CreateReflectionModalProps): JSX.Element => {
  const form = useForm<FormValues>();

  const closeButtonAction = onClose;

  const handleSubmit = form.handleSubmit((reflection) => {
    onSubmit({
      ...reflection,
      learningLoopId: loopId,
    }).then(onSuccess);
  });

  return (
    <FormProvider {...form} key={loopId}>
      <Modal open={open} onClose={closeButtonAction} size="mobileLandscape">
        <Modal.Title>Time to reflect · week {loopIndex + 1}</Modal.Title>

        <Modal.Contents>
          <Form onSubmit={handleSubmit} />
        </Modal.Contents>

        <Modal.Actions>
          {onCancel && <Modal.Action action={onCancel}>Cancel</Modal.Action>}
          {onBack && (
            <Modal.Action action={() => onBack(loopId)} disabled={isSubmitting}>
              Back
            </Modal.Action>
          )}
          <Modal.Action primary action={handleSubmit} disabled={isSubmitting}>
            Reflect
          </Modal.Action>
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
};
