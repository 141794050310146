import styled from 'styled-components';

export const RootStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.overline};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
`;

export const InformersGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;
