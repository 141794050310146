import { ReactNode } from 'react';
import styled from 'styled-components';

import { ContentSection } from '@/ui/Layout/ContentSection';

type PageProps = {
  children: ReactNode;
};

const RootStyled = styled.div`
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Page = ({ children }: PageProps): JSX.Element => {
  return (
    <ContentSection>
      <RootStyled>{children}</RootStyled>
    </ContentSection>
  );
};
