/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon, IconPropType } from '@/ui/Icon';

const RootStyled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline2};
  padding: 16px;
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  width: 296px;
  min-height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  &:hover {
    ${({ selected, disabled }) =>
      !(selected || disabled) &&
      css`
        border: 2px solid ${({ theme }) => theme.colors.neutral.onBackground};
        padding: 15px;
        cursor: pointer;
        background: ${({ theme }) => theme.colors.states.hover5};
      `}
  }

  ${({ selected }) =>
    selected &&
    css`
      padding: 15px;
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
      background: ${({ theme }) => theme.colors.states.hover7};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.neutral.disabled};

      color: ${({ theme }) => theme.colors.neutral.disabled};
    `}
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const ReactionCard = ({
  title,
  icon,
  selected,
  disabled,
  onClick,
}: {
  title: string;
  icon: IconPropType;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
}): JSX.Element => (
  <RootStyled selected={selected} disabled={disabled} onClick={onClick}>
    <ContentStyled>
      <Icon size="2x" icon={icon} />
      {title}
    </ContentStyled>
  </RootStyled>
);
