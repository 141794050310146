import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash.isequal';

import { useProductTypeTranslations } from '@/i18n/hooks';
import { minutesToRelativeTime, utcToLocalDate } from '@/utils/time';
import { MetaLabel } from '@/ui/Label';
import { Card } from '@/ui/Card';
import { CardType } from './types';
import { useOptionalCardElements, useTransformedCard, useResolveLink } from './hooks';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

type CourseCardProps = WithClassNameProp<{ card: CardType; onClick?: () => void }>;

const CourseCard = ({ card, className, onClick }: CourseCardProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  const { productType } = useProductTypeTranslations();
  const isCardDescrtionHidden = useFeatureEnabled(FEATURE.UI_CARD_DESCRIPTION_HIDDEN);

  const c = useTransformedCard(card);
  const e = useOptionalCardElements(c);
  const link = useResolveLink({ ...card, customLink: c.customLink, isApproved: c.isApproved });

  return (
    <Card
      isCardDescriptionHidden={isCardDescrtionHidden}
      className={className}
      link={link.url ?? '#'}
      isExternalLink={link.type !== 'internal'}
      onClick={onClick}
    >
      <Card.Image src={c.image ?? ''} />
      {e.statusIcon === 'checkmark' && <Card.StatusIcon.Checkmark />}
      {e.statusIcon === 'xMark' && <Card.StatusIcon.Cross />}
      <Card.TypeIcon type={c.type} />
      {c.providerMeta && <Card.ProviderLogo provider={c.providerMeta.provider} />}
      <Card.Overline>{c.isEvent && !!c.eventDate ? c.eventDate : productType(c.type)}</Card.Overline>
      <Card.Title strechVertically={isCardDescrtionHidden}>{c.title}</Card.Title>
      {!isCardDescrtionHidden && <Card.Description>{c.teaser}</Card.Description>}
      <Card.Labels>
        {e.tags.recommended && <Card.Labels.Recommended />}
        {e.tags.mandatory && <Card.Labels.Mandatory />}
        {e.tags.featured && <Card.Labels.Featured />}
        {e.tags.upcoming && <Card.Labels.Upcoming />}
        {e.tags.hasExpired && <Card.Labels.Expired />}
        {e.tags.pendingApproval && <Card.Labels.PendingApproval />}
        {(e.tags.expiresInDay || e.tags.expiresSoon) && (
          <Card.Labels.ExpiresInXDays daysCount={c.expiresInDays!} multipleDays={!e.tags.expiresInDay} />
        )}
      </Card.Labels>
      <Card.Footer>
        {e.metaLabels.primary.eventLocation && <MetaLabel icon={['far', 'location-dot']} text={c.eventLocation!} />}
        {e.metaLabels.secondary.completed && c.completedAt && <MetaLabel icon="check" text={utcToLocalDate(c.completedAt)} />}
        {e.metaLabels.secondary.ongoing && <MetaLabel icon="sync-alt" text={t('Ongoing')} />}
        {e.metaLabels.secondary.itemCount && (
          <MetaLabel icon="layer-group" text={t('learning-item', { count: c.itemsInCollection || 0 })} />
        )}
        {e.metaLabels.tertriary.level && c.level && <MetaLabel icon="signal-alt" text={c.level!} />}
        {(e.metaLabels.secondary.completed || e.metaLabels.tertriary.totalEffort) && c.totalEffort && (
          <MetaLabel icon={['far', 'clock']} text={minutesToRelativeTime(c.totalEffort)} />
        )}
      </Card.Footer>
    </Card>
  );
};

const MemoizedCourseCard = memo(CourseCard, isEqual);
export { MemoizedCourseCard as CourseCard };
