import { useRef, useState, useEffect } from 'react';

import { convertVideoToEmbedWithType } from '@/administration/utils/videoUrl';
import { useInterval } from '@/hooks/useInterval';

export const isValidEmbedVideoUrl = (url: string): boolean => Boolean(convertVideoToEmbedWithType(url).embedUrl);

export const useVideoUploadProgress = ({
  transcodingInProgress,
  vimeoProgress,
  maxVisualUploadingProgress,
}: {
  transcodingInProgress: boolean;
  vimeoProgress: number;
  maxVisualUploadingProgress: number;
}): { current: number; prev: number } => {
  const [progress, setProgress] = useState(0);
  const prevProgressRef = useRef<number>(0);

  const convertToBarUploadingPercentage = (p: number) => Math.round((p / 100) * maxVisualUploadingProgress);

  useInterval(
    () => {
      setProgress((prev) => {
        let delta = 0;

        if (prev < 60) {
          delta = 1;
        } else if (prev < 70) {
          delta = 0.75;
        } else if (prev < 80) {
          delta = 0.5;
        } else if (prev < 90) {
          delta = 0.25;
        } else {
          delta = 0.1;
        }
        return Math.min(99, prev + delta);
      });
    },
    !transcodingInProgress ? null : 1000
  );

  useEffect(() => {
    prevProgressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    if (!transcodingInProgress) {
      setProgress(convertToBarUploadingPercentage(vimeoProgress));
    }
  }, [transcodingInProgress, vimeoProgress]);

  const current = transcodingInProgress ? Math.round(progress) : progress;

  return {
    current,
    prev: prevProgressRef.current,
  };
};
