import { ActivityType } from '@/administration/pages/Course/store/activity/types';

export type DrawerItemTypeV2 = ActivityType | 'completed';

export const ACTIVITY_TYPE_TO_ICON = {
  [ActivityType.Page]: ['far', 'rectangle'],
  [ActivityType.Result]: ['far', 'party-horn'],
  completed: ['far', 'circle-check'],
  [ActivityType.Reaction]: ['far', 'angle-double-right'],
  [ActivityType.Question]: ['far', 'square-poll-vertical'],
  [ActivityType.PracticalAssessmentTask]: ['far', 'pen-line'],
  [ActivityType.Reflection]: ['far', 'comment-pen'],
  [ActivityType.Module]: ['far', 'rectangle-history'],
  [ActivityType.Quiz]: ['far', 'game-board-simple'],
};
