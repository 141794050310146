import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import './eventSession.scss';
import { Location } from '@/types/learning';
import { localizedLocationDate } from '@/utils/time';

interface Props {
  location: Location;
  onChange: () => void;
  isDisabled?: boolean;
  isChecked: boolean;
  isSeminar: boolean;
}

export const EventSession = ({ location, isDisabled = false, isChecked, onChange, isSeminar }: Props): JSX.Element => {
  const { t } = useTranslation('builderAdmin');
  const localizedDate = localizedLocationDate(location);

  const totalEnrollmentsCount =
    (location.stats?.enrolledCount || 0) + (location.stats?.attendedCount || 0) + (location.stats?.notAttendedCount || 0);

  return (
    <label className="is-flex radio" styleName={clsx('event', isDisabled && 'event-disabled')}>
      <input
        type="radio"
        className="radio"
        styleName="event-radio"
        checked={isChecked}
        onChange={() => onChange()}
        disabled={isDisabled}
      />
      <div styleName="event-content">
        <p className="body2 has-text-black has-tiny-margin">{localizedDate}</p>
        {isSeminar && (
          <p className="caption has-tiny-margin">
            {location.address}
            {location.room ? `, ${location.room}` : undefined}
          </p>
        )}
        {!location.stats?.isFinished && location.capacity && (
          <p className="caption is-italic">
            {location.capacity - totalEnrollmentsCount} {t('seat', { count: location.capacity - totalEnrollmentsCount })}{' '}
            {t('left')}
          </p>
        )}
        {location.stats?.isFinished && (
          <p className="caption is-italic">
            {location.stats?.attendedCount || 0} {t('attended').toLowerCase()} / {location.stats?.notAttendedCount}{' '}
            {t(`didn't-attend`).toLowerCase()}
          </p>
        )}
      </div>
    </label>
  );
};
