import { DEFAULT_MAX_PROGRESS_VALUE, PERCENTAGE_100 } from '../../../constants';
import { ProgressBarProps } from '../types';

export const getProgressState = ({
  value,
  maxValue = DEFAULT_MAX_PROGRESS_VALUE,
  labelFormat,
}: ProgressBarProps): {
  labelValue: string;
  percentage: number;
} => {
  const percentage = Math.min(Math.round((value / maxValue) * PERCENTAGE_100), PERCENTAGE_100);
  let labelValue = '';

  switch (labelFormat) {
    case 'ratio':
      labelValue = `${value}/${maxValue}`;
      break;
    case 'percentage':
    default:
      labelValue = `${percentage}%`;
      break;
  }

  return {
    labelValue,
    percentage,
  };
};
