import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconPropType } from '@/ui/Icon';
import { MetaV2 } from '@/components/Meta/MetaV2';
import { minutesToRelativeTime } from '@/utils/time';
import { Participation } from '@/types/analytics';
import { ProgressBar } from './ProgressBar';

interface Props {
  isPending?: boolean;
  isCollection: boolean;
  itemCount?: number | undefined;
  participation?: Participation;
  hasExpiration?: boolean;
  level?: string | null;
  totalEffort?: number | null;
}

export const StyledFooterV2 = styled.footer`
  flex-wrap: no-wrap;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;
  padding: 0px 12px;

  border-top: 1px solid var(--color-border);
`;

export const CourseCardFooter = ({
  isPending,
  isCollection,
  itemCount,
  participation,
  hasExpiration,
  level,
  totalEffort,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('catalog');
  let singleMetaField: { caption: string; icon?: IconPropType } | undefined;

  if (isPending) {
    singleMetaField = { icon: 'clock', caption: t('Pending approval') };
  } else if (participation && !hasExpiration) {
    if (participation.isCompleted) {
      singleMetaField = { caption: t('Completed') };
    } else {
      singleMetaField = { icon: 'sync-alt', caption: t('Ongoing') };
    }
  } else if (isCollection) {
    singleMetaField = { caption: t('learning-item', { count: itemCount || 0 }), icon: 'layer-group' };
  }

  if (!Boolean(level || totalEffort || singleMetaField)) {
    return null;
  }

  const hasProgress = participation?.progress && participation?.progress > 0;

  return (
    <StyledFooterV2>
      {!!singleMetaField && <MetaV2 caption={singleMetaField.caption} icon={singleMetaField.icon} />}
      {!singleMetaField && hasProgress && <ProgressBar percentage={participation?.progress ? participation.progress * 100 : 0} />}
      {!singleMetaField && !hasProgress && (
        <>
          {!!level && <MetaV2 caption={level} icon="signal-alt" />}
          {!!totalEffort && <MetaV2 caption={minutesToRelativeTime(totalEffort)} icon="clock" />}
        </>
      )}
    </StyledFooterV2>
  );
};
