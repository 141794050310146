import styled from 'styled-components';

import defaultCover from '../../../../common/assets/defaultCover.png';
import { Button } from '@/ui/Button';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

const ImageContainer = styled.div`
  border-radius: 8px;
  height: 280px;
  width: 100%;
  position: relative;
`;

const ImageStyled = styled.img`
  border-radius: 8px;
  height: 280px;
  width: 100%;
  object-fit: cover;
  opacity: 80%;
  z-index: 1;
`;

const OverlayStyled = styled.div`
  top: 0;
  border-radius: 8px;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 2;
`;

const RootStyled = styled.div`
  position: relative;
  height: 280px;
  background: ${({ theme }) => theme.tones.neutral[0]};
  border-radius: 8px;
`;

const EditButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 3;
`;

const TitlesContainer = styled.div`
  position: absolute;
  z-index: 3;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title1};
  color: ${({ theme }) => theme.tones.neutral[100]};
  max-height: 132px;
  overflow: hidden;
`;

const SubtitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.tones.neutral[100]};
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const ActionsContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 16px;
  right: 16px;
`;

type HeroProps = {
  subtitle?: string;
  actions?: JSX.Element;
  onEdit?: () => void;
  title: string;
  imageUrl?: string;
  withOverlay?: boolean;
};

const Hero = ({ onEdit, title, subtitle, imageUrl, actions, withOverlay }: HeroProps): JSX.Element => {
  return (
    <RootStyled>
      <ImageContainer>
        <ImageStyled src={imageUrl || defaultCover} />
        {withOverlay && <OverlayStyled />}
      </ImageContainer>
      {actions ? (
        <ActionsContainer>{actions}</ActionsContainer>
      ) : (
        <EditButton variant="neutral" onClick={onEdit}>
          Edit
        </EditButton>
      )}
      <TitlesContainer>
        {!!subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
        <TitleStyled>{title}</TitleStyled>
      </TitlesContainer>
    </RootStyled>
  );
};

const LoadingState = () => <Skeleton height={280} />;

const _Hero = makeNamespacedComponent(Hero, {
  LoadingState,
});

export { _Hero as Hero };
