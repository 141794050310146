import { AnalyticsCardType } from '../types/learning/card';

export interface EffortPerProvider {
  provider: string;
  effort: number;
}

export interface IsManager {
  manager: boolean;
}

export enum PERCENTILES_RANGE {
  TOP_10 = 'p90',
  TOP_20 = 'p80',
  TOP_30 = 'p70',
  TOP_40 = 'p60',
  TOP_50 = 'p50',
}

export type EffortForRealm = {
  [key in PERCENTILES_RANGE]: number;
} & MainEffortForRealm;

export type MainEffortForRealm = {
  total_effort_for_realm: number;
  average_effort_per_user: number;
};

// generic data item used in graphs
export interface DataItem {
  name: string;
  value: number;
}

export interface EffortForRealmOverTime {
  name: string;
  value: number;
}

export interface UserEffortOverTime {
  in_progress: number;
  completed: number;
  expired: number;
  completed_not_expired: number;
  total_effort_in_seconds: number;
  distinct_users: number;
  distinct_completed: number;
  distinct_completed_not_expired: number;
  average_effort_per_user_in_seconds: number;
}

export type ObjectId = string;

export interface CompletionRatePerCourse {
  completed: number;
  progressed: number;
  completion_rate: number;
  object_name: string;
  object_type: AnalyticsCardType;
  object_id: ObjectId;
}

export enum UsersPool {
  REALM = 'REALM',
}

export enum UserVerb {
  PROGRESSED = 'progressed',
  COMPLETED = 'completed',
}

export interface Participation {
  id: number;
  userId: number;
  email?: string;
  objectId: string;
  catalogObjectId: string;
  registration: string;
  progress: number;
  isCompleted: boolean;
  userEffort: number;
  completedAt: string;
  expiredAt: string;
  platform: string;
  firstStatement: string;
  lastStatement: string;
  firstActivity: string;
  lastActivity: string;
  objectName?: string;
  objectType?: ObjectType;
  plannedDuration?: number;
}

export interface ParticipationGroup extends Participation {
  previousParticipations?: Participation[];
}

export enum ObjectType {
  COURSE = 'course',
  E_LEARNING = 'e-learning',
  ONLINE_LEARNING = 'online-learning',
  SEMINAR = 'seminar',
  WEBINAR = 'webinar',
  ARTICLE = 'article',
  AUDIO = 'audio',
  VIDEO = 'video',
  RESOURCE = 'resource',
  SELF_TRACKED = 'self-tracked',
}

export enum ParticipationStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}
