import styled from 'styled-components';

import { Divider } from '@/administration/pages/Course/modals/SettingsModal/styles';

export const MenuListContainer = styled.div`
  position: relative;
`;

export const MenuListBody = styled.div`
  top: 38px;
  width: 200px;
  display: flex;
  position: absolute;
  background: ${({ theme }) => theme.colors.neutral.background};
  ${({ theme }) => theme.shadow.primary[8]};
  flex-direction: column;
  padding-bottom: 8px;
`;

export const MenuListStyledDivider = styled(Divider)`
  width: 100%;
  margin: 8px 0;
`;
