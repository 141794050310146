/* eslint-disable import/no-unused-modules */
import { ColumnDeclaration } from '@/ui/AutoTable';
import { Info, Progress } from './cells';
import { LearningProgress, SortBy } from './cells/types';

export const useColumnDeclaration = (): ColumnDeclaration<LearningProgress, SortBy> => {
  return [
    {
      header: 'Title',
      component: Info,
      expand: true,
      sortKey: 'title',
    },
    {
      header: 'Time spent',
      dataKey: 'userEffort',
      cellFormatter: 'time',
      align: 'right',
      sortKey: 'userEffort',
    },
    {
      header: 'Progress',
      component: Progress,
      align: 'right',
      sortKey: 'progress',
    },
  ];
};
