import styled from 'styled-components';

import { IconButton } from '@/ui/Button';

export const StyledEnabledButton = styled(IconButton)`
  transition: transform 200ms ease-in;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const StyledDisabledButton = styled(IconButton)`
  transition: transform 200ms ease-in;
`;

export const RootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const HeadingWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;
export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.tones.neutral[0]};
`;
