import React, { ReactNode } from 'react';
import { Composition } from 'atomic-layout';
import styled from 'styled-components';

import { BadgeLabel } from '@/ui/Label/variants/BadgeLabel';

const SectionHeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
const SectionTitle = styled.span`
  ${(p) => p.theme.typography.title2};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const Section = styled.div`
  padding: 32px 0px 32px;
  :last-of-type {
    padding-bottom: 0;
  }
`;

export const CampusCardLayoutWrapperV2 = ({
  useCustomLayout,
  children,
}: {
  useCustomLayout?: boolean;
  children: ReactNode;
}): JSX.Element => {
  return !!useCustomLayout ? (
    <Composition
      templateCols="minmax(0, 1fr)"
      templateColsMd="repeat(2, minmax(0, 1fr))"
      templateColsXl="repeat(2, minmax(0, 1fr))"
      gap={1}
      gapMd={2}
    >
      {children}
    </Composition>
  ) : (
    <Composition
      templateCols="minmax(0, 1fr)"
      templateColsMd="repeat(4, minmax(0, 1fr))"
      templateColsXl="repeat(4, minmax(0, 1fr))"
      gap={1}
      gapMd={2}
    >
      {children}
    </Composition>
  );
};

export const CampusCardSectionHeaderV2 = ({ sectionCount = 0, title }: { sectionCount: number; title: string }): JSX.Element => {
  return (
    <SectionHeaderContent>
      <SectionTitle>{title}</SectionTitle>
      <BadgeLabel text={sectionCount.toString()} badgeVariant="badge-text" />
    </SectionHeaderContent>
  );
};

export const CampusCardSectionHeaderContainerV2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
`;
