import { useMemo } from 'react';

import { sortOrder } from '@/store/utils';
import { useAssignmentPage } from '@/store/utils/assignments';
import { UseCourseCardsHook } from '../types';

export const useCourseCards: UseCourseCardsHook = ({ assignment }) => {
  const { loading, error, card: allItems } = useAssignmentPage({ assignment });

  const [processedCards, count, cards] = useMemo(() => {
    if (loading || !allItems || error || !allItems?.cards) {
      return [[], 0, []];
    }
    if (allItems?.type === 'STANDARD') {
      const cards = allItems.cards;
      let processedItems = allItems?.cards;

      processedItems = [...processedItems].sort((m, o) => sortOrder(m.priority, o.priority) || sortOrder(m.order, o.order));

      const total = processedItems.length;

      return [processedItems, total, cards];
    }

    return [[], 0, []];
  }, [allItems, loading]);

  return {
    cards,
    processedCards,
    total: cards?.length,
    processedTotal: count,
    loading,
    error,
  };
};
