import styled from 'styled-components';
import { forwardRef } from 'react';

// Used in underline calculations as well, so moved to exported constant
export const UNDERLINE_TAB_MARGIN_SIDE_PX = 8;

type UnderlinedTabProps = {
  /**
   * Text label for the tab button.
   */
  label: string;
  /**
   * Click event callback
   */
  onClick?: () => void;
  /**
   * Marks the tab button as active.
   */
  isActive?: boolean;
  value: number;
};

const Button = styled.button<{ isActive: boolean }>`
  all: unset;
  ${({ theme }) => theme.typography.body4};
  margin: ${UNDERLINE_TAB_MARGIN_SIDE_PX}px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.states.hover1};
  }
  color: ${(p) => (p.isActive ? p.theme.colors.neutral.onContainer : p.theme.colors.neutral.outline2)};
`;

export const Trigger = forwardRef<HTMLButtonElement, UnderlinedTabProps>(
  (
    {
      label,
      isActive = false,
      onClick,
      className,
      /** Used in higher components for filtering their children by this prop  */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value,
    }: WithClassNameProp<UnderlinedTabProps>,
    ref
  ): JSX.Element => (
    <Button isActive={isActive} onClick={onClick} className={className} ref={ref}>
      {label}
    </Button>
  )
);

Trigger.displayName = 'UnderlinedTabTrigger';
