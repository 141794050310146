import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useStrategicAssignment } from './useStrategicAssignment';
import { Page } from './components/Page';
import { StrategicAssignmentProps } from './types';
import { useCurrentUser } from '@/store/currentUser';
import {
  AddLearningTimeModal,
  CreateReflectionModal,
  HelpModal,
  NoProgressModal,
  ReflectionSuccessModal,
  WeeklySummaryModal,
} from './components/modals';
import { LearningProgress } from './components/modals/WeeklySummaryModal/WeeklySummaryTable/cells/types';
import {
  StrategicAssignmentSegmentation,
  trackAddedLearningTime,
  trackHelpIcon,
  trackPeopleButton,
  trackReflectButtonOnWeeklyChart,
  trackSubmitReflection,
  trackViewDetails,
} from '@/utils/tracking/strategicAssignment';
import { formatYMD } from '@/utils/dates';

type ModalProps = {
  AddLearningTime: React.ComponentProps<typeof AddLearningTimeModal>;
  CreateReflection: React.ComponentProps<typeof CreateReflectionModal>;
  HelpModal: React.ComponentProps<typeof HelpModal>;
  NoProgress: React.ComponentProps<typeof NoProgressModal>;
  ReflectionSuccess: React.ComponentProps<typeof ReflectionSuccessModal>;
  WeeklySummary: React.ComponentProps<typeof WeeklySummaryModal>;
};

type Modal =
  | 'addLearningTime' // triggered with the action button in the loop chart
  | 'createReflectionNow' // trigerred with the "Reflect" button in the loop chart
  | 'createReflection' // trigerred from the "View details" modal
  | 'noWeeklyProgress' // triggered from "View details" button (meaning no progress)
  | 'weeklySummary' // triggered from "View details" button (meaning no progress)
  | 'preReflectionSummary' // trigerred with the "Reflect" button in the loop chart
  | 'reflectionCreated' // trigerred right after a reflection is created
  | 'help'; // trigerred using an icon the loop chart box

const Source = {
  Email: 'email',
  Button: 'button',
};
export const StrategicAssignment = ({ assignment }: StrategicAssignmentProps): JSX.Element => {
  const { assignment: strategicAssignment, loading, error, reflection, refetch } = useStrategicAssignment(assignment);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialLoading = loading && !strategicAssignment;
  const navigate = useNavigate();
  const [selectedLoopId, setSelectedLoopId] = useState('');
  const [selectedLoopIndex, setSelectedIndex] = useState(-1);
  const selectedLoop = strategicAssignment?.weeks?.find((w) => w.id === selectedLoopId);
  const { user, loading: userLoading } = useCurrentUser();

  const [learningProgressForSelectedWeek, setLearningProgressForSelectedWeek] = useState<LearningProgress[]>([]);
  const [selectedReflection, setReflection] = useState<string | undefined>();
  const [isCurrentWeek, setIsCurrentWeek] = useState(false);

  const segmentation: StrategicAssignmentSegmentation = {
    assignmentId: strategicAssignment?.id.toString() ?? '',
    assignmentName: strategicAssignment?.name ?? '',
    loopId: selectedLoopId,
    loopStartDate: selectedLoop?.weekStartTimestamp ? formatYMD(new Date(selectedLoop.weekStartTimestamp)) : '',
    loopEndDate: selectedLoop?.weekEndTimestamp ? formatYMD(new Date(selectedLoop.weekEndTimestamp)) : '',
  };

  const [activeModal, setActiveModal] = useState<Modal | null>(null);
  const trackClickedSucessfulSubmitReflection = useCallback(() => trackSubmitReflection(segmentation), []);
  const trackClickedHelpIcon = useCallback(() => trackHelpIcon(segmentation), []);
  const trackClickedAddedLearningTime = useCallback(() => trackAddedLearningTime(segmentation), []);
  const trackClickedViewDetails = useCallback(() => trackViewDetails(segmentation), []);
  const trackClickedReflectButton = useCallback(
    (source: string = Source.Button) => trackReflectButtonOnWeeklyChart({ ...segmentation, source }),
    []
  );
  const trackParticipantsClick = useCallback(() => trackPeopleButton(segmentation), []);

  useEffect(() => {
    if (!loading && (!assignment || !!error)) {
      navigate('/404');
    }
  }, [assignment, loading, error]);

  useEffect(() => {
    if (!initialLoading) {
      const openReflectModal = searchParams.get('reflect') === 'true';
      const m = strategicAssignment?.weeks
        ?.slice()
        .reverse()
        .find((x) => x.canReflect === true);
      if (!!m && !!openReflectModal) {
        searchParams.delete('reflect');
        setSearchParams(searchParams);
        setupOpenReflectModal(Source.Email);
      }
    }
  }, [initialLoading]);

  const handleOpenParticipants = () => {
    navigate('participants');
    trackParticipantsClick();
  };

  const setupLoopIdAndIndex = (loopId: string) => {
    setSelectedLoopId(loopId);
    setSelectedIndex(strategicAssignment?.weeks?.findIndex((w) => w.id === loopId) ?? -1);
  };

  const setupOpenReflectModal = (loopId: string, source?: string) => {
    refetch();
    setActiveModal('preReflectionSummary');
    setupLoopIdAndIndex(loopId);
    trackClickedReflectButton(source);
  };

  const openAddLearningTimeModal = (loopId: string) => {
    setActiveModal('addLearningTime');
    setupLoopIdAndIndex(loopId);
    trackClickedAddedLearningTime();
  };

  const handleModalClose = () => {
    setActiveModal(null);
    refetch();
  };

  /** Modal triggers called by Reflect Now flow  */
  const openReflectionNowModal = (loopId: string) => {
    setActiveModal('createReflectionNow');
    setupLoopIdAndIndex(loopId);
  };

  const openPreReflectSummaryModal = (loopId: string) => {
    setupOpenReflectModal(loopId);
  };

  /** Modal triggers called by View Details flow */
  const openViewDetailsSummaryModal = (loopId: string) => {
    refetch();
    const progress = strategicAssignment?.progress?.find((x) => x.loopId === loopId);

    setLearningProgressForSelectedWeek(progress?.learningProgress ?? []);
    setReflection(progress?.reflection);
    setIsCurrentWeek(!!progress?.isCurrentWeek);
    setActiveModal(!!progress?.learningProgress?.length || !!progress?.reflection ? 'weeklySummary' : 'noWeeklyProgress');
    setupLoopIdAndIndex(loopId);
    trackClickedViewDetails();
  };

  const openReflectionFromSummaryModal = (loopId: string) => {
    refetch();
    setActiveModal('createReflection');
    setupLoopIdAndIndex(loopId);
  };

  const openHelpModal = () => {
    setActiveModal('help');
    trackClickedHelpIcon();
  };

  /** Modal props */
  /** Reflection modal props */
  /** Common props */
  const reflectionModalCommonProps: Pick<
    ModalProps['CreateReflection'],
    'loopId' | 'loopIndex' | 'onSubmit' | 'isSubmitting' | 'onSuccess'
  > = {
    loopId: selectedLoopId,
    loopIndex: selectedLoopIndex,
    onSubmit: reflection.create,
    isSubmitting: reflection.loading,
    onSuccess: () => {
      setActiveModal('reflectionCreated');
      trackClickedSucessfulSubmitReflection();
    },
  };

  /** If called from Reflect Now flow */
  const reflectNowModalProps: ModalProps['CreateReflection'] = {
    ...reflectionModalCommonProps,
    open: activeModal === 'createReflectionNow',
    onClose: handleModalClose,
    onBack: openPreReflectSummaryModal,
  };

  /** If called from View Details flow */
  const reflectFromViewDetailsModalProps: ModalProps['CreateReflection'] = {
    ...reflectionModalCommonProps,
    open: activeModal === 'createReflection',
    onClose: handleModalClose,
    onCancel: handleModalClose,
  };

  /** Week Summary modal props */
  /** Common props */
  const viewDetailsModalCommonProps: Pick<
    ModalProps['WeeklySummary'],
    'loopIndex' | 'loopId' | 'learningsProgress' | 'reflection' | 'isCurrentWeek'
  > = {
    loopIndex: selectedLoopIndex,
    loopId: selectedLoopId,
    learningsProgress: learningProgressForSelectedWeek,
    reflection: selectedReflection,
    isCurrentWeek,
  };

  /** If called from View Details flow */
  const viewDetailSummaryProps: ModalProps['WeeklySummary'] = {
    ...viewDetailsModalCommonProps,
    open: activeModal === 'weeklySummary',
    onClose: handleModalClose,
    onCreateReflectionClick: openReflectionFromSummaryModal,
  };

  /** If called from Reflect Now flow */
  const preReflectionSummaryProps: ModalProps['WeeklySummary'] = {
    ...viewDetailsModalCommonProps,
    open: activeModal === 'preReflectionSummary',
    onClose: handleModalClose,
    onNext: openReflectionNowModal,
    isPreReflection: true,
  };

  const noProgressProps: ModalProps['NoProgress'] = {
    onClose: handleModalClose,
    loopIndex: selectedLoopIndex,
    open: activeModal === 'noWeeklyProgress',
  };

  const reflectionSuccessProps: ModalProps['ReflectionSuccess'] = {
    open: activeModal === 'reflectionCreated',
    onClose: handleModalClose,
    onViewDetails: () => openViewDetailsSummaryModal(selectedLoopId),
  };

  const helpProps: ModalProps['HelpModal'] = {
    open: activeModal === 'help',
    onClose: handleModalClose,
  };

  return (
    <>
      <AddLearningTimeModal
        open={activeModal === 'addLearningTime'}
        onClose={handleModalClose}
        loopId={selectedLoopId}
        userId={user?.id}
      />
      {/* Modal for reflect from Donut reflect button */}
      <CreateReflectionModal {...reflectNowModalProps} />
      {/* Modal for reflection from View Details */}
      <CreateReflectionModal {...reflectFromViewDetailsModalProps} />
      {/* Modal for no progress on view Details */}
      <NoProgressModal {...noProgressProps} />
      {/* Modal opened from view details button */}
      <WeeklySummaryModal {...viewDetailSummaryProps} />
      {/* Pre reflection summary variant */}
      <WeeklySummaryModal {...preReflectionSummaryProps} />
      {/* Post reflection success feedback */}
      <ReflectionSuccessModal {...reflectionSuccessProps} />
      {/* Shows help instructions */}
      <HelpModal {...helpProps} />

      {initialLoading || !strategicAssignment || userLoading ? (
        <Page.LoadingState />
      ) : (
        <Page
          assignment={strategicAssignment}
          onAddLearningTimeClick={openAddLearningTimeModal}
          onCreateReflectionClick={openPreReflectSummaryModal}
          onViewDetailsClick={openViewDetailsSummaryModal}
          onHelpClick={openHelpModal}
          onOpenParticipants={handleOpenParticipants}
        />
      )}
    </>
  );
};
