import { Variant as VariantType } from '@/types/learning/learning-catalog';

export const sortVariants = (variantA: VariantType, variantB: VariantType): number => {
  if (!variantA.validFrom) {
    return 1;
  } else if (variantA.validFrom > (variantB.validFrom ? variantB.validFrom : 0)) {
    return 1;
  } else if (variantA.validFrom < (variantB.validFrom ? variantB.validFrom : 0)) {
    return -1;
  }

  if (!variantA.name) {
    return 1;
  } else if (variantA.name < (variantB.name ? variantB.name : 0)) {
    return -1;
  } else if (variantA.name > (variantB.name ? variantB.name : 0)) {
    return 1;
  }

  if (!variantA.id) {
    return -1;
  } else if (variantA.id < (variantB.id ? variantB.id : 0)) {
    return 1;
  } else if (variantA.id > (variantB.id ? variantB.id : 0)) {
    return -1;
  } else {
    return 0;
  }
};
