import React from 'react';

import { ContainerStyled } from './styles';
import { ContainerProps } from './types';

export const Container = ({ variant, backgroundColor, children, className }: ContainerProps): JSX.Element => (
  <ContainerStyled variant={variant} backgroundColor={backgroundColor} className={className}>
    {children}
  </ContainerStyled>
);
