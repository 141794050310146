import React from 'react';

import { StyledBackgroundImage } from '../styles';
import { WrapperProps } from '../types';

export const StickyWrapper = ({ children, url }: WrapperProps): JSX.Element => {
  const fallbackImg =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEklEQVR42mMsPcKAFzCOKgADAA0fCwszc0b4AAAAAElFTkSuQmCC';
  const overlay = 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))';
  const background = `${overlay}, center / cover url(${url || fallbackImg})`;
  return <StyledBackgroundImage background={background}>{children}</StyledBackgroundImage>;
};
