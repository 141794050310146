import { Control, Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import { FormSchema, TextFieldName } from '../..';
import { TimeInput } from '@/ui/TextField/variants';

const getDefaultMinTime = (): Date => new Date(new Date().setHours(0, 0, 0));
const getMinTimeByBoundary = (boundaryDate: Date): Date => new Date(new Date(boundaryDate).getTime() + 60 * 1000 * 15);

export const FormEndsAt = ({ control }: { control: Control<FormSchema> }): JSX.Element => {
  const { watch, setValue } = useFormContext<FormSchema>();
  const [endsAtTime, date, startsAtTime] = watch(['endsAtTime', 'date', 'startsAtTime']);

  const minTime = startsAtTime ? getMinTimeByBoundary(new Date(startsAtTime)) : getDefaultMinTime();

  useEffect(() => {
    if (endsAtTime && date) {
      const pickedDate = new Date(date);

      const endsAtDate = new Date(endsAtTime);

      // We need to override time field date, once field from date picker is changed (initialized)
      endsAtDate.setDate(pickedDate.getDate());
      endsAtDate.setMonth(pickedDate.getMonth());
      endsAtDate.setUTCFullYear(pickedDate.getFullYear());

      setValue('endsAtTime', endsAtDate.toISOString());
    }
  }, [date, endsAtTime]);

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.EndsAtTime}
      render={({ field: { onChange, name, value }, fieldState: { error } }) => {
        return (
          <TimeInput
            value={value ? new Date(value) : undefined}
            onChange={(date: Date) => onChange(date.toISOString())}
            inputProps={{
              label: 'End time',
              name,
              error: error && {
                message: 'Enter end time',
              },
            }}
            minTime={minTime}
            width={144} // TODO In future, find out a better way to adjust react-datepicker popover size
          />
        );
      }}
    />
  );
};
