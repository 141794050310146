import { Nominal } from './nominal';

export type Actor = Nominal<ActorAgent | ActorGroup, 'XAPIActor'>;

interface ActorBase {
  name?: string;
  mbox?: string;
  mbox_sha1sum?: string;
  account?: ActorAccount;
  openid?: string;
}

export interface ActorAgent extends ActorBase {
  objectType: 'Agent';
}

export interface ActorGroup extends ActorBase {
  objectType: 'Group';
  members?: ActorAgent[];
}

export interface ActorAccount {
  homePage?: string;
  name?: string;
}

export const actorFromUserId = (userId: number): Actor =>
  <Actor>{
    objectType: 'Agent',
    account: {
      homePage: 'https://collegial.com',
      name: String(userId),
    },
  };
