import { ReactNode } from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { Layout, MultiPageBody, MultiPageNav, ViewDrawer, ViewDrawerItemProps } from '../../atoms';
import { ViewNavbar } from '../../atoms/Navbar';

type ViewLayoutProps = {
  title: string;
  onShowHideDrawerClick: () => void;
  onAddFavoritesClick?: () => void;
  onCloseClick: () => void;
  onUpNavigationClick: () => void;
  onDownNavigationClick: () => void;
  onNewPageClick: (pageNumber: number) => void;
  content: ReactNode[];
  drawerItems: ViewDrawerItemProps[];
  isSavedToMyLearning?: boolean;
  isDrawerOpen: boolean;
  progress?: {
    prevValue: number;
    value: number;
    maxValue?: number;
  };

  pageActiveCount: number;
  pageTotalCount: number;
};

export const _ViewLayout = ({
  title,
  content,
  drawerItems,
  onShowHideDrawerClick,
  onAddFavoritesClick,
  onCloseClick,
  onUpNavigationClick,
  onDownNavigationClick,
  onNewPageClick,
  isSavedToMyLearning: isSavedToMyLearning,
  isDrawerOpen,
  progress,
  pageActiveCount,
  pageTotalCount,
}: ViewLayoutProps): JSX.Element => {
  return (
    <Layout
      navbar={
        <ViewNavbar
          title={title}
          onShowHideDrawerClick={onShowHideDrawerClick}
          onSaveFavoritesClick={onAddFavoritesClick}
          onCloseClick={onCloseClick}
          isSavedToMyLearning={isSavedToMyLearning}
        />
      }
      progress={progress || undefined}
      content={content.map((item, key) => (
        <MultiPageBody key={key}>{item}</MultiPageBody>
      ))}
      contentNav={
        <MultiPageNav
          backDisabled={pageActiveCount === 0}
          nextDisabled={pageActiveCount === pageTotalCount - 1}
          onBackClick={onUpNavigationClick}
          onNextClick={onDownNavigationClick}
        />
      }
      drawer={<ViewDrawer items={drawerItems} />}
      isDrawerOpen={isDrawerOpen}
      contentScroll={{
        page: pageActiveCount + 1,
        maxPage: pageTotalCount,
        onBackPage: onUpNavigationClick,
        onNextPage: onDownNavigationClick,
        onUpdatePage: onNewPageClick,
      }}
    />
  );
};

const LoadingState = (): JSX.Element => <Layout.LoadingState />;

export const ViewLayout = makeNamespacedComponent(_ViewLayout, {
  LoadingState,
});
