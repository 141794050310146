import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'qs';

import { useRootContext } from '../../../../store/RootContext';
import { Loader } from '@/component/Loader';
import { FORCE_SSO_LOGIN_KEY } from '@/utils/constants';
import { isRealm } from '@/utils/realms';

export const AuthenticatedRoute = (): JSX.Element => {
  const { isAuthenticated, acceptedPrivacy, acceptedTos, acceptedExtraTerms, loading } = useRootContext();
  const location = useLocation();
  const navigate = useNavigate();
  const query = parse(location.search, { ignoreQueryPrefix: true });

  const needsToAcceptExtraTerms = isRealm('kompetens') && !acceptedExtraTerms;
  const needsOnboarding =
    isAuthenticated && (!acceptedTos || !acceptedPrivacy || needsToAcceptExtraTerms) && location.pathname !== '/onboarding';

  if (loading) return <Loader />;

  if (!isAuthenticated)
    return (
      <Navigate
        replace
        to={{
          pathname: `/login`,
          search: `${query[FORCE_SSO_LOGIN_KEY] ? `?${FORCE_SSO_LOGIN_KEY}=true` : ''}`
        }}
        state={{ from: location }}
      />
    );

  if (needsOnboarding) return <Navigate replace to="/onboarding" />;

  // If force_sso_login param exists, but we are save to show the actual content
  if (query[FORCE_SSO_LOGIN_KEY]) {
    // Check if any other search params exist
    const otherSearchParamsPresent = Object.keys(query).length > 1;
    // Get rid of the force_sso_login but keeping any other possible search param
    const search = otherSearchParamsPresent ? location.search.replace(`${FORCE_SSO_LOGIN_KEY}=true`, '') : '';
    navigate(
      {
        search
      },
      { replace: true }
    );
  }
  return <Outlet />;
};
