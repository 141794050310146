import styled, { useTheme, css } from 'styled-components';
import React from 'react';
import { useSelected } from 'slate-react';

import { Icon } from '@/ui/Icon';
import { Button } from '@/ui/Button';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { VideoFloatingToolbarButtons } from '@/component/customEditorV2/ui/elements/Video/VideoFloatingToolbarButton';

type VideoElementPlaceholderProps = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadClick: () => void;
  onOpenLinkModalClick: () => void;
  onDeleteClick: () => void;
};

const StyledContainer = styled.div<{ $selected: boolean }>`
  height: 440px;
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutral.container};
  border-radius: 12px;

  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `};
`;

const StyledTitle = styled.h3`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledText = styled.div`
  ${({ theme }) => theme.typography.overline};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledButton = styled(Button)`
  & {
    background: transparent;
  }
`;

export const VideoElementPlaceholder = ({
  fileInputRef,
  onFileChange,
  onUploadClick,
  onDeleteClick,
  onOpenLinkModalClick,
}: VideoElementPlaceholderProps): JSX.Element => {
  const theme = useTheme();
  const selected = useSelected();
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected });

  return (
    <>
      <div ref={setReference} style={{ width: '100%' }}>
        <StyledContainer $selected={selected}>
          <StyledInput
            id="video-upload-input"
            ref={fileInputRef}
            type="file"
            accept="video/*"
            style={{ display: 'none' }}
            onChange={onFileChange}
          />
          <Icon icon={['far', 'video']} size="2x" color={theme.colors.neutral.onContainer} />
          <StyledTitle>Add video</StyledTitle>
          <StyledButtons>
            <StyledButton icon={['far', 'file-upload']} title="Upload" variant={'secondary'} onClick={onUploadClick}>
              Upload
            </StyledButton>
            <StyledText>OR</StyledText>
            <StyledButton icon={'link'} title="Paste link" variant={'secondary'} onClick={onOpenLinkModalClick}>
              Paste link
            </StyledButton>
          </StyledButtons>
        </StyledContainer>
      </div>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <VideoFloatingToolbarButtons onRemoveClick={onDeleteClick} />
      </div>
    </>
  );
};
