import styled, { keyframes, css } from 'styled-components';

export const RootStyled = styled.div<{ percentage?: number; fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
`;

const progressSlideAnimation = (percentage: number, animationStartPercentage = 0) => keyframes`
    0% {
      width: ${`${animationStartPercentage}%`};
    }
    100% {
      width: ${`${percentage}%;`};
    }
`;

export const ProgressBarStyled = styled.div<{
  percentage: number;
  animated?: boolean;
  rounded: boolean;
  animationStartPercentage?: number;
}>`
  width: 100%;
  height: 8px;
  background: ${(p) => p.theme.colors.neutral.container};
  border-radius: ${({ rounded }) => (rounded ? '5px' : '0px')};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: ${({ percentage }) => `${percentage}%;`};
    ${({ animated, percentage, animationStartPercentage }) =>
      animated &&
      css`
        animation: 1s ease-out 0s 1 ${progressSlideAnimation(percentage, animationStartPercentage)};
      `}
    height: 8px;
    background: ${(p) => p.theme.colors.primary.outline};
    border-radius: ${({ rounded }) => (rounded ? '5px' : '0px')};
    display: block;
  }
`;

export const LabelStyled = styled.span`
  ${(p) => p.theme.typography.caption};
  color: ${(p) => p.theme.colors.neutral.onContainer};
`;
