import { ReactNode } from 'react';

export const Content = ({
  /** Used in higher components for filtering their children by this prop  */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  children,
}: {
  value: number;
  children: ReactNode | ReactNode[];
}): JSX.Element => {
  return <>{children}</>;
};
