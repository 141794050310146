import styled from 'styled-components';

import { MetaLabel } from '@/ui/Label';
import { formatToHoursAndMinutesFromSeconds, minutesToRelativeTime, round, utcToLocalDate } from '@/utils/time';
import { ProgressBar } from '@/ui/ProgressBar/variants/LinearProgressBar';

export type LearningCardProgressProps = {
  totalEffort?: number;
  totalLearningItems?: number;

  progress: {
    isCompleted?: boolean;
    userEffort?: number;
    progress?: number;
    completedAt?: Date | null;
    expiryAt?: Date | null;
  };
};

const ProgressBodyStyled = styled.div`
  display: flex;
  gap: 16px;

  ${(p) => p.theme.typography.caption};
  color: ${(p) => p.theme.colors.neutral.onBackgroundVariant};
`;

export const ProgressBarStyled = styled(ProgressBar)`
  width: 80px;
`;

export const LearningCardProgress = ({ totalLearningItems, totalEffort, progress }: LearningCardProgressProps): JSX.Element => {
  const isCompleted = progress.isCompleted && progress.completedAt;
  const isItemsProgressCard = totalLearningItems || totalLearningItems === 0;
  const isTimeProgressCard = !totalLearningItems && (totalEffort || totalEffort === 0);

  const renderCompletedProgress = () => {
    if (!progress.completedAt) return;

    const effortText = totalEffort || totalEffort === 0 ? minutesToRelativeTime(round(totalEffort / 60, 0.5)) : undefined;

    return (
      <ProgressBodyStyled>
        <MetaLabel icon={['far', 'check']} text={utcToLocalDate(progress.completedAt)} />
        {effortText && <MetaLabel icon={['far', 'clock']} text={effortText} />}
        {totalLearningItems && !effortText && (
          <MetaLabel icon={['far', 'layer-group']} text={`${totalLearningItems} learning items`} />
        )}
      </ProgressBodyStyled>
    );
  };

  const renderTimeProgress = () => {
    const userEffort = progress.userEffort || 0;
    const effortToGo = (totalEffort || 0) - userEffort;
    const effortToGoText = `${formatToHoursAndMinutesFromSeconds(effortToGo)} left`;

    return (
      <ProgressBodyStyled>
        {effortToGoText}
        <ProgressBarStyled value={userEffort} maxValue={totalEffort || 0} />
      </ProgressBodyStyled>
    );
  };

  const renderItemsProgress = () => {
    if (!isItemsProgressCard || !progress) return;

    const itemsCompleted = Math.round(totalLearningItems * (progress.progress || 0));
    const itemsCountToGo = totalLearningItems - itemsCompleted;
    const itemsCountToGoText = `${itemsCountToGo} items to go`; // TODO allow for singular form

    return (
      <ProgressBodyStyled>
        {itemsCountToGoText}
        <ProgressBarStyled value={itemsCompleted} maxValue={totalLearningItems} />
      </ProgressBodyStyled>
    );
  };

  return (
    <ProgressBodyStyled>
      {isCompleted && renderCompletedProgress()}
      {!isCompleted && isItemsProgressCard && renderItemsProgress()}
      {!isCompleted && isTimeProgressCard && renderTimeProgress()}
    </ProgressBodyStyled>
  );
};
