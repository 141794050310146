import styled from 'styled-components';

import { RUserLearningSubmissionReview } from '@/store/v2';
import { TextStyled } from '../../../../practicalAssessmentText/variants/View/styles';
import { toHtml } from '@/component/customEditor/logic/serialization';
import { AuthorHeader } from '../../../AuthorHeader';

const RootStyled = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubmittedReview = ({ review }: { review: RUserLearningSubmissionReview }): JSX.Element | null => {
  return (
    <RootStyled>
      <AuthorHeader authorId={review.reviewerId} createdAt={review.createdAt} />
      <TextStyled className="content is-article" dangerouslySetInnerHTML={{ __html: toHtml(review.data.text) }}></TextStyled>
    </RootStyled>
  );
};
