import styled from 'styled-components';

export const CourseCardBody = styled.div<{ $isCollection?: boolean; size: 'lg' | 'sm' }>`
  height: ${(p) => (p.size === 'sm' ? '127px' : '180px')};
  overflow: hidden;
  padding: var(--gap-sm);
  position: relative;
  ${({ $isCollection }) =>
    $isCollection && 'border-right: 2px solid var(--color-alto); border-left: 2px solid var(--color-alto);'};
  &:after {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    ${({ $isCollection }) =>
      $isCollection && 'background-image: linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(245, 245, 245, 1) 90%);'}
    bottom: 0;
    content: '';
    height: 3rem;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;

export const CourseCardBodyV2 = styled.div`
  height: 180px;
  overflow: hidden;
  padding: var(--gap-sm);
  position: relative;

  &:after {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    bottom: 0;
    content: '';
    height: 3rem;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;
