import styled from 'styled-components';

import { toHtml } from '@/component/customEditor/logic/serialization';
import { RUserLearningSubmissionReviewAssignment } from '@/store/v2';
import { TextStyled } from '../../../practicalAssessmentText/variants/View/styles';
import { WriteReviewForm } from './WriteReviewForm';
import { useCreateUserLearningSubmissionReview } from '@/pages/PracticalAssessment/store/useCreateUserLearningSubmissionReview';
import { useCurrentUser } from '@/store/currentUser';
import { SubmittedReviews } from './SubmittedReviews';
import { DocumentPreview } from '@/administration/pages/Course/blocks/document/components/DocumentPreview';
import { AuthorHeader } from '../../AuthorHeader';
import { Divider } from '../../Divider';
import { DocumentPreviewContainer } from '../../DocumentPreviewContainer';

const RootStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubmissionForReview = ({
  submissionForReview,
  pageRemoteId,
  type,
  spaceId,
}: {
  submissionForReview: RUserLearningSubmissionReviewAssignment;
  pageRemoteId?: number;
  type: 'text' | 'document';
  spaceId: number;
}): JSX.Element | null => {
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();
  const { createUserLearningSubmissionReview } = useCreateUserLearningSubmissionReview();
  const currentUserId = currentUser?.id;

  if (currentUserLoading || !currentUserId) return null;

  const handleReviewSubmit = async (reviewText: string) => {
    if (!pageRemoteId) return;

    await createUserLearningSubmissionReview({
      submissionId: submissionForReview.submissionId,
      pageId: pageRemoteId,
      data: {
        text: reviewText,
      },
    });
  };

  const isSubmissionReviewedByCurrentUser = submissionForReview.submission.reviews.find(
    (review) => review.reviewerId === currentUserId
  );

  return (
    <RootStyled>
      <AuthorHeader authorId={submissionForReview.submission.userId} createdAt={submissionForReview.createdAt} />
      {type === 'text' ? (
        <TextStyled
          className="content is-article"
          dangerouslySetInnerHTML={{ __html: toHtml(submissionForReview.submission.data.text) }}
        />
      ) : (
        <DocumentPreviewContainer>
          <DocumentPreview documentUrl={submissionForReview.submission.data.text} fileName={''} hideHeader />
        </DocumentPreviewContainer>
      )}
      <Divider />
      {isSubmissionReviewedByCurrentUser ? (
        <SubmittedReviews reviews={submissionForReview.submission.reviews} />
      ) : (
        <WriteReviewForm onSubmit={handleReviewSubmit} spaceId={spaceId} />
      )}
    </RootStyled>
  );
};
