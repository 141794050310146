import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { BodyMd } from '@/components/Typography/Typography';
import { StyledDropdown } from '@/components/Dropdown';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';

export const StyledMultiSelectRow = styled(BodyMd)<{ isSelected?: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${cvar('color-seashell')};
    &>span {
      width: 80%;
    }
  `}
  cursor: pointer;
  position: relative;
  white-space: pre-wrap;

  &:hover {
    background-color: ${cvar('color-wild-sand')};
  }

  &:focus {
    outline: none;
    background-color: ${cvar('color-wild-sand')};
  }
`;

export const StyledDropdownMultiSelect = styled(StyledDropdown)`
  > * {
    display: flex;
    padding: ${cvar('gap-xxs')} ${cvar('gap-sm')};
    min-height: ${BUTTON_HEIGHT_ENUM.default}rem;
    align-items: center;
    border: ${cvar('border-style')};
    border-bottom: 0;
    color: ${cvar('color-text')};
  }

  ${StyledMultiSelectRow}:last-child {
    border-bottom: ${cvar('border-style')};
    border-radius: 0 0 ${cvar('radius-inner-with-border')} ${cvar('radius-inner-with-border')};
  }
`;
