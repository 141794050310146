import React from 'react';
import styled from 'styled-components';
import { useSlate } from 'slate-react';

import { Icon, IconPropType } from '@/ui/Icon';
import { getTextSelection } from '../../utils/getTextSelection';

const MenuStyled = styled.div`
  max-height: 240px;
  box-sizing: border-box;
  width: 300px;
  padding: 8px 0;
  overflow-y: scroll;
  list-style-type: none;
  z-index: 100;
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[8]};
  border-radius: 8px;
`;

const EditorOption = styled.div`
  padding: 6px 16px;
  ${(p) => p.theme.typography.body4};
  color: ${(p) => p.theme.tones.neutral[10]};
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(p) => p.theme.colors.states.hover1};
  }
`;

const OptionGroupTitle = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  font-weight: 600;
  padding: 6px 16px;
`;

const LeadingIcon = styled(Icon)`
  margin-right: 16px;
`;

type CurratedPrompt = {
  title: string;
  icon: IconPropType;
  getPromptByText: (text: string) => string;
};

type CurratedPropmtsGroup = {
  title: string;
  propmts: CurratedPrompt[];
};

const CURRATED_PROMPTS_GROUPS: CurratedPropmtsGroup[] = [
  {
    title: 'Edit or review selection',
    propmts: [
      {
        title: 'Fix spelling & grammar',
        icon: 'check',
        getPromptByText: (text) => `Reply with the fixed text only. Fix spelling & grammar for the following text: ${text}`,
      },
      {
        title: 'Make shorter',
        icon: ['far', 'arrows-to-line'],
        getPromptByText: (text) => `Make following text shorter: ${text}`,
      },
      {
        title: 'Make longer',
        icon: ['far', 'arrows-from-line'],
        getPromptByText: (text) => `Make following text longer: ${text}`,
      },
    ],
  },
  {
    title: 'Generate from selection',
    propmts: [
      {
        title: 'Summarise',
        icon: ['far', 'arrow-up-short-wide'],
        getPromptByText: (text) => `Reply with the short summary of following text: ${text}`,
      },
      {
        title: 'Generate list of questions',
        icon: 'question',
        getPromptByText: (text) => `Generate list of questions based on following text: ${text}`,
      },
    ],
  },
  {
    title: 'Change tone',
    propmts: [
      {
        title: 'Friendly',
        icon: ['far', 'face-smile'],
        getPromptByText: (text) => `Make following text sound friendly: ${text}`,
      },
      {
        title: 'Professional',
        icon: ['far', 'briefcase'],
        getPromptByText: (text) => `Make following text sound professional: ${text}`,
      },
    ],
  },
  {
    title: 'Translate',
    propmts: [
      {
        title: 'To Swedish',
        icon: ['far', 'language'],
        getPromptByText: (text) => `Translate following text to Swedish: ${text}`,
      },
      {
        title: 'To English',
        icon: ['far', 'language'],
        getPromptByText: (text) => `Translate following text to English: ${text}`,
      },
    ],
  },
];

type CurratedPromptsProps = {
  onGenerate: (prompt: string) => void;
};

export const CurratedPrompts = ({ onGenerate }: CurratedPromptsProps): JSX.Element => {
  const editor = useSlate();

  return (
    <MenuStyled>
      {CURRATED_PROMPTS_GROUPS.map(({ title: groupTitle, propmts }) => (
        <React.Fragment key={groupTitle}>
          <OptionGroupTitle key={groupTitle}>{groupTitle}</OptionGroupTitle>
          {propmts.map(({ title, getPromptByText, icon }) => (
            <EditorOption key={title} onClick={() => onGenerate(getPromptByText(getTextSelection(editor)))}>
              <LeadingIcon icon={icon} />
              {title}
            </EditorOption>
          ))}
        </React.Fragment>
      ))}
    </MenuStyled>
  );
};
