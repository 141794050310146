import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { StickyWrapper } from './components/StickyWrapper';
import { trackBannerLearningsClicked, trackOpenCarouselModal } from '@/utils/tracking/homePage';
import {
  ArrowIcon,
  CarouselContainer,
  Container,
  EllipsisContainer as EditContainer,
  EllipsisIcon as EditIcon,
  MainContainer,
  StickyContent,
  StickyHeader,
  StickyTeaser,
  StickyTitle,
  StyledLink,
  StyledRadioButton,
  CarouselContentBox,
} from './styles';
import { PinnedLearningsModal } from '../pinnedLearnings/PinnedLearningsModal';
import { useRootContext } from '@/store/RootContext';
import { useCurrentUserAccess } from '@/store/currentUser';
import { Skeleton } from '@/ui/Skeleton';
import { useBannerLearnings } from './store';
import { useInterval } from '@/hooks/useInterval';

const CAROUSEL_AUTO_SLIDE_DELAY = 6500;

export const BillboardStickyWithLearnings = (): JSX.Element => {
  const { isAuthenticated } = useRootContext();
  const { isAdmin } = useCurrentUserAccess();
  const [index, setIndex] = useState(0);

  const { data, loading, error, refetch } = useBannerLearnings();
  const pinnedCards = data?.length ? data : [];

  const numOfCards = pinnedCards.length;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenBillboardLearning = () => {
    trackBannerLearningsClicked(pinnedCards[index].title);
  };

  const handleRightArrowNavigation = () => {
    const newIndex = index + 1;
    const startIndex = 0;
    if (numOfCards > newIndex && newIndex >= 0) {
      setIndex(newIndex);
    }

    if (numOfCards === newIndex) {
      setIndex(startIndex);
    }
  };

  const handleLeftArrowNavigation = () => {
    const newIndex = index - 1;
    const lastIndex = numOfCards - 1;
    const outOfBoundsIndex = -1;
    if (numOfCards > newIndex && newIndex >= 0) {
      setIndex(newIndex);
    }

    if (newIndex === outOfBoundsIndex) {
      setIndex(lastIndex);
    }
  };

  useInterval(handleRightArrowNavigation, CAROUSEL_AUTO_SLIDE_DELAY);
  if (loading || !pinnedCards) {
    return <Skeleton width={851} height={370} />;
  }

  return (
    <Container>
      {openModal && <PinnedLearningsModal open={openModal} setOpen={setOpenModal} />}
      <StyledLink to={pinnedCards[index]?.link || ''} onClick={handleOpenBillboardLearning}>
        <CarouselContainer>
          {isAuthenticated && isAdmin && (
            <EditContainer>
              <EditIcon
                icon={['far', 'pencil']}
                buttonVariant="light"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenModal(true);
                  trackOpenCarouselModal();
                }}
              />
            </EditContainer>
          )}
          <MainContainer>
            <ArrowIcon
              key={`left-${index}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleLeftArrowNavigation();
              }}
              icon={['far', 'chevron-left']}
              buttonVariant="ghost"
            />

            {pinnedCards.map(({ image, title = '', teaser = '' }, cardIndex) => (
              <CarouselContentBox active={cardIndex === index} key={`title-${cardIndex}`}>
                <StickyWrapper url={image || ''}>
                  <StickyHeader>
                    <StickyTitle>{title}</StickyTitle>
                    <StickyTeaser>{teaser}</StickyTeaser>
                  </StickyHeader>
                </StickyWrapper>
              </CarouselContentBox>
            ))}

            <StickyContent>
              {pinnedCards.length > 1 &&
                pinnedCards.map((_p, i) => (
                  <StyledRadioButton
                    key={i}
                    value={pinnedCards[i].entityId}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIndex(i);
                    }}
                    checked={i === index}
                    onChange={() => ({})}
                    variant="ghost"
                  />
                ))}
            </StickyContent>
            <div>
              {error && (
                <Trans ns="discussions" i18nKey="post-load-error">
                  Something went wrong.{' '}
                  <a style={{ cursor: 'wait' }} onClick={() => refetch()}>
                    Click here
                  </a>{' '}
                  to try again.
                </Trans>
              )}
            </div>
            <ArrowIcon
              key={`right-${index}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleRightArrowNavigation();
              }}
              icon={['far', 'chevron-right']}
              buttonVariant="ghost"
            />
          </MainContainer>
        </CarouselContainer>
      </StyledLink>
    </Container>
  );
};
