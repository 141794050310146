import { AssignmentV3 } from '@/store/assignments/types';
import { useCardCatalog } from '@/store/catalog';
import { useCurrentUser } from '@/store/currentUser';
import { useLearningPinned } from '@/store/learning-pinned';
import { getRealm } from '@/utils/realms';
import { mapLearningPinnedToBannerElement } from '../../pinnedLearnings/utils';
import { BannerElement, BannerLearnings, PinnedLearnings, StrategicLearnings } from '../types';
import image from '../styles/image.jpg';
import { useSpaces } from '@/store/spaces';
import { useBaseAssignments } from '@/store/assignments/homepage';
import { ASSIGNMENT_STATUS } from '@/store/assignments/constants';

export const useBannerLearnings = (): BannerLearnings => {
  const {
    data: strategicBannerElements,
    loading: strategicLoading,
    error: strategicError,
    refetch: strategicRefetch,
  } = useStrategicLearnings();

  const {
    data: pinnedLearnings,
    loading: pinnedLearningsLoading,
    error: pinnedLearningsError,
    refetch: pinnedLearningsRefetch,
  } = usePinnedLearnings();

  const bannerElements = (pinnedLearnings ?? []).concat(strategicBannerElements ?? []);

  return {
    data: bannerElements,
    loading: strategicLoading || pinnedLearningsLoading,
    error: strategicError || pinnedLearningsError,
    refetch: strategicError ? strategicRefetch : pinnedLearningsRefetch,
  };
};

export const useStrategicLearnings = (): StrategicLearnings => {
  const { user, loading: loadingUser } = useCurrentUser();
  const filteredUserIds = user?.id ? [user.id] : [];
  const { spaces, loading: loadingSpaces } = useSpaces();

  const hasLoadedUser = filteredUserIds?.length || !loadingUser;
  const hasLoadedSpaces = spaces?.length || !loadingSpaces;

  const {
    data: assignments,
    loading: loadingAssignments,
    error,
    refetch,
  } = useBaseAssignments({
    userIds: filteredUserIds,
    type: 'STRATEGIC',
    status: ASSIGNMENT_STATUS.published,
    spaces,
    skip: !hasLoadedUser || !hasLoadedSpaces,
  });

  const loading = !hasLoadedUser || !hasLoadedSpaces || loadingAssignments;

  return {
    data: loading
      ? undefined
      : assignments?.map(
          (x: AssignmentV3): BannerElement => ({
            id: x.id.toString(),
            entityId: x.id,
            title: x.name ?? '',
            teaser: x.teaser,
            image: !!x.image ? x.image : image,
            link: `/assignment/${x.id}/${x.name}`,
          })
        ),
    loading,
    error,
    refetch,
  };
};

export const usePinnedLearnings = (): PinnedLearnings => {
  const {
    learningsPinned,
    loading: learningPinnedLoading,
    refetch: pinnnedLearningsRefetch,
    error: pinnedLearningsError,
  } = useLearningPinned(getRealm());
  const {
    error,
    refetch,
    getCollectionCard,
    getLearningCard,
    getProductCard,
    getJourneyCard,
    loading: cardCatalogLoading,
  } = useCardCatalog();

  return {
    data:
      learningPinnedLoading || cardCatalogLoading
        ? undefined
        : mapLearningPinnedToBannerElement(learningsPinned, getCollectionCard, getLearningCard, getProductCard, getJourneyCard),
    loading: learningPinnedLoading || cardCatalogLoading,
    error: pinnedLearningsError || error,
    refetch: pinnedLearningsError ? pinnnedLearningsRefetch : refetch,
  };
};
