import Fuse from 'fuse.js';

import { Card } from '@/types/learning/card';

export const fuzzyOptions: Fuse.IFuseOptions<Card> = {
  ignoreLocation: true,
  minMatchCharLength: 2,
  threshold: 0.35,
  keys: [
    { name: 'title', weight: 2 },
    { name: 'teaser', weight: 1 },
    { name: 'subjects.subject', weight: 0.7 },
    { name: 'subjects.category', weight: 0.5 }
  ]
};
