import styled from 'styled-components';

import { OptionStyledProps } from './types';

export const OptionPrefixStyled = styled.span`
  margin-right: 16px;
`;

export const OptionSuffixStyled = styled.span`
  margin-left: 16px;
`;

export const OptionStyled = styled.li<OptionStyledProps>`
  ${(p) => p.theme.typography.body4};
  color: ${(p) => p.theme.tones.neutral[10]};
  cursor: pointer;
  display: flex;
  align-items: center;

  padding: 6px 16px;

  &:hover {
    background: ${(p) => p.theme.colors.states.hover1};
  }

  background: ${({ selected, theme: { colors } }) => (selected ? colors.neutral.backgroundVariant : colors.neutral.background)};
`;

export const OptionTextStyled = styled.div`
  max-height: 20px;
  box-sizing: content-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  gap: 8px;
  align-items: center;
`;
