import styled, { useTheme } from 'styled-components';

import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { LearningJourney } from '@/store/v2/journeys/journey';
import { Informer } from '@/ui/Informer';
import { ProgressBar } from '@/ui/ProgressBar/variants/LinearProgressBar';
import { ParticipationV3 } from '@/store/v2/catalog-v2/model/participation';
import { RLearningJourneyCompletionStatus } from '@/store/v2';
import { formatToHoursAndMinutesFromSeconds, utcToLocalDateСustomFormat } from '@/utils/time';
import { Icon } from '@/ui/Icon';

type ProgressSummaryProps = {
  journey: LearningJourney;
  participation: ParticipationV3 | undefined;
};

const RootStyled = styled.div`
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.h3`
  ${(p) => p.theme.typography.title3};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const Subheadline = styled.div`
  ${(p) => p.theme.typography.subheadline}
  color: ${(p) => p.theme.colors.neutral.onContainer};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ProgressBarStyled = styled(ProgressBar)`
  width: 80px;
`;

export const DelayWarningStyled = styled.div`
  color: ${({ theme }) => theme.colors.neutral.outline2};
  ${({ theme }) => theme.typography.body3};
`;

const ProgressSummary = ({ journey, participation }: ProgressSummaryProps): JSX.Element => {
  const theme = useTheme();

  const isCompleted = !!participation?.isCompleted;
  const progress = participation?.progress || 0;
  const plannedLearningsCount = participation?.plannedLearningsCount || 0;
  const completedLearningsCount =
    journey.completionCriteria === RLearningJourneyCompletionStatus.CompleteAllItems
      ? Math.round(plannedLearningsCount * progress)
      : 0;

  const userEffort = participation?.userEffort || 0;
  const investTimeSec = journey.completionInvestTimeSec || 0;
  return (
    <RootStyled>
      <Title>Your progress</Title>
      {journey.completionCriteria === RLearningJourneyCompletionStatus.CompleteAllItems && (
        <>
          <Informer
            title={`${completedLearningsCount}/${plannedLearningsCount} items`}
            label="Completion"
            sideBox={{ size: 'large', background: theme.colors.secondary.container, icon: ['fas', 'list-check'] }}
          />
          {!isCompleted && (
            <Subheadline>
              {!participation?.isCompleted ? `${plannedLearningsCount - completedLearningsCount} items to go` : ''}
              <ProgressBarStyled value={(participation?.progress || 0) * 100} maxValue={100} labelFormat="ratio" />
            </Subheadline>
          )}
        </>
      )}
      {journey.completionCriteria === RLearningJourneyCompletionStatus.InvestTime && (
        <>
          <Informer
            title={`${formatToHoursAndMinutesFromSeconds(userEffort, '0h')}/${formatToHoursAndMinutesFromSeconds(investTimeSec)}`}
            label="Completion"
            sideBox={{ size: 'large', background: theme.colors.secondary.container, icon: ['fas', 'list-check'] }}
          />
          {!isCompleted && (
            <Subheadline>
              {!participation?.isCompleted ? `${formatToHoursAndMinutesFromSeconds(investTimeSec - userEffort)}  to go` : ''}
              <ProgressBarStyled value={userEffort} maxValue={investTimeSec} labelFormat="ratio" />
            </Subheadline>
          )}
        </>
      )}
      {isCompleted && (
        <Subheadline>
          <Icon icon="check" />
          {participation?.completedAt ? utcToLocalDateСustomFormat(participation.completedAt, 'YYYY-MM-DD') : 'Completed'}
        </Subheadline>
      )}
      <DelayWarningStyled>Progress updates can be delayed up to 72 hours due to technical dependencies</DelayWarningStyled>
    </RootStyled>
  );
};

const LoadingState = () => <Skeleton height={230} />;

const _ProgressSummary = makeNamespacedComponent(ProgressSummary, {
  LoadingState,
});

export { _ProgressSummary as ProgressSummary };
