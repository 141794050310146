import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import './discussions.scss';
import { DiscussionPost } from './DiscussionPost';
import { Reflection } from './Reflection';
import { NewDiscussionPost } from './NewDiscussionPost';
import { GET_SECTION, useCreatePost, useUpdateSection } from '@/store/discussion';
import { Post, PostPayload } from '@/types/post';
import { GetSectionQuery, SectionPermissions } from '@/types/Section';
import { Team } from '@/administration/pages/ProductEditor/component/CourseTeams';
import { PostTeamParticipants } from './PostTeamParticipants';
import { UpdateSectionForm, UpdateSectionPayload } from './UpdateSectionForm';
import { toHtml } from '../customEditor/logic/serialization';
import { ErrorMessage } from '../ErrorMessage';
import { ButtonList } from '@/components/Button/ButtonList';
import { Button } from '@/components/Button/Button';
import { LinkButton } from '@/components/Button/LinkButton';
import { trackDiscussionPostLearningItemAdded, trackReflectionLearningItemAdded } from '@/utils/tracking/learnings';

interface Props {
  product?: number;
  path: string[];
  isReflections?: boolean;
  team?: Team;
  spaceId: number;
}

const Loading = () => <div className="pageloader" />;

export const PostSection = ({ path, isReflections = false, team, spaceId }: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const [showNewPost, toggleNewPost] = useState(false);
  const [showEditSection, toggleEditSection] = useState(false);
  const [updateError, setUpdateError] = useState('');

  const createPost = useCreatePost();
  const updateSection = useUpdateSection();

  const variables = {
    path,
    page: 1,
    pageSize: 4,
  };

  const { data, loading, updateQuery, fetchMore, error, refetch } = useQuery<GetSectionQuery>(GET_SECTION, {
    variables,
  });

  // If the section doesn't exist, the (apollo) cache id will be null
  // and any similar query will override the cache result
  if (data?.getSection) {
    const toIntHash = (path: string): number | null => {
      if (!path) return null;
      return path.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
    };
    updateQuery((prev: GetSectionQuery) => {
      if (!prev) return prev;
      const id = prev.getSection?.id || toIntHash(prev.getSection?.path);
      if (id === null) return prev;
      return { getSection: { ...prev.getSection, id } };
    });
  }

  const posts: Post[] = new Array<Post>().concat(data?.getSection?.stickyPosts || [], data?.getSection?.posts?.items || []);
  const currentPage: number = data?.getSection?.posts?.page || 0;
  const totalPages: number = data?.getSection?.posts?.pages || 0;
  const permissions: SectionPermissions | undefined = data?.getSection?.role?.permissions;

  const handlePost = async ({
    title,
    content,
    media,
    publishDate,
    shouldSendNotification,
    isReflectionSection,
  }: PostPayload): Promise<void> => {
    const { data: result } = await createPost(path, {
      title,
      content,
      media,
      publishDate,
      shouldSendNotification,
      isReflectionSection,
    });

    if (!result) {
      return;
    }

    updateQuery((prev) => ({
      getSection: {
        ...prev.getSection,
        posts: {
          ...prev.getSection.posts,
          items: [result.createPost, ...prev.getSection.posts.items].sort((a: Post, b: Post) =>
            a.sticky === b.sticky ? 0 : a.sticky ? -1 : 1
          ),
        },
      },
    }));
    isReflections ? trackReflectionLearningItemAdded() : trackDiscussionPostLearningItemAdded();

    setSelectedPostId(result.createPost.id);
  };

  const handleSection = async ({ title, description }: UpdateSectionPayload): Promise<void> => {
    const { data: result, errors } = await updateSection(path, title, description);

    if (errors) {
      console.error(errors);
      setUpdateError(errors[0].extensions?.response.statusText);
    }
    if (result) {
      const updatedFields = {
        title,
        description,
      };
      updateQuery((prev) => ({
        getSection: {
          ...prev.getSection,
          ...updatedFields,
        },
      }));
    }
  };

  const isTeamChannel = useMemo((): boolean => {
    if (!data?.getSection?.title) {
      return false;
    }
    return data.getSection.title.includes('team');
  }, [data]);

  const loadMorePosts = () =>
    fetchMore({
      variables: { ...variables, page: currentPage + 1 },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult) {
          return {
            getSection: {
              ...fetchMoreResult.getSection,
              posts: {
                ...fetchMoreResult.getSection.posts,
                items: [...prev.getSection.posts.items, ...fetchMoreResult.getSection.posts.items],
              },
            },
          };
        }
        return prev;
      },
    });

  if (loading && posts.length === 0) return <Loading />;
  if (error)
    return (
      <ErrorMessage>
        <Trans ns="discussions" i18nKey="post-load-error">
          Something went wrong.{' '}
          <Button $type="text" onClick={() => refetch()}>
            Click here
          </Button>{' '}
          to try again.
        </Trans>
      </ErrorMessage>
    );
  if (updateError)
    return (
      <ErrorMessage>
        <>
          {t('Could not update section:')} {updateError}
        </>
      </ErrorMessage>
    );

  return (
    <>
      {showEditSection && (
        <div styleName="discussion-create-container">
          <h3 className="header3 has-bottom-margin">{t('Update channel')}</h3>
          <UpdateSectionForm
            path={path}
            title={data?.getSection?.title}
            description={data?.getSection?.description}
            onSave={handleSection}
            onFinished={() => toggleEditSection(false)}
            onCancel={() => toggleEditSection(false)}
          />
        </div>
      )}
      {showNewPost && (
        <div styleName="discussion-create-container">
          <h3 className="header3 has-bottom-margin">{isReflections ? t('Ask a question') : t('Write a post')}</h3>
          <NewDiscussionPost
            isTeamChannel={isTeamChannel}
            isReflectionSection={isReflections}
            hasPublishDate={isReflections}
            canPostMedia={!isReflections}
            onPost={handlePost}
            onFinished={() => toggleNewPost(false)}
            onCancel={() => toggleNewPost(false)}
          />
        </div>
      )}
      <div styleName="flex-container">
        {!showNewPost && !showEditSection && (
          <ButtonList>
            {permissions?.canEditSection && <Button onClick={() => toggleEditSection(true)}>{t('Edit')}</Button>}
            {!isReflections && !showNewPost && (
              <Button onClick={() => toggleNewPost(true)} $type="primary" $icon="paper-plane">
                {t('Post')}
              </Button>
            )}
            {isReflections && !showNewPost && permissions?.canAddReflections && (
              <Button onClick={() => toggleNewPost(true)} $type="primary" $icon="paper-plane">
                {t('New reflection')}
              </Button>
            )}
          </ButtonList>
        )}

        {team && team.length && <PostTeamParticipants team={team} spaceId={spaceId} />}
      </div>
      {!showEditSection && data?.getSection?.description && (
        <div className="message is-cls">
          <div className="message-body">
            <div className="header4 is-sentence">{data?.getSection?.title}</div>
            <div className="content body2" dangerouslySetInnerHTML={{ __html: toHtml(data?.getSection?.description) }} />
          </div>
        </div>
      )}
      {posts.map((post: Post) =>
        isReflections ? (
          <Reflection
            canEdit={permissions?.canAddReflections}
            selected={true}
            key={`posts-${post.id}`}
            reflection={post}
            path={variables.path}
          />
        ) : (
          <DiscussionPost
            post={post}
            path={variables.path}
            key={`posts-${post.id}`}
            selected={selectedPostId === post.id}
            onClick={() => setSelectedPostId(selectedPostId === post.id ? null : post.id)}
          />
        )
      )}

      {totalPages > currentPage && <LinkButton onClick={loadMorePosts}>{t('Show more')}</LinkButton>}

      {posts.length === 0 && <div>{isReflections ? t('No reflections yet.') : t('No posts yet.')}</div>}
    </>
  );
};
