import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { useSize } from '@/utils/useSize';
import { variant } from './sizing';
import { preventOrphans } from '@/utils/text';

type Props = WithClassNameProp<{
  children?: string;
}>;

export const Description = ({ children: text, className }: Props): JSX.Element => {
  const elements = {
    wrapper: useRef(null),
    text: useRef(null),
  };
  const height = {
    wrapper: useSize(elements.wrapper),
    text: useSize(elements.text),
  };

  const hasOverflow = height.text > height.wrapper;

  return (
    <Wrapper className={className} ref={elements.wrapper} hasOverflow={hasOverflow}>
      <TextContent ref={elements.text}>{preventOrphans(text ?? '')}</TextContent>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hasOverflow?: boolean }>`
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  ${(p) =>
    p.hasOverflow &&
    css`
      :after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 32px;
        background-image: linear-gradient(transparent, ${(p) => p.theme.colors.primary.onMain} 100%);
      }
    `}

  ${variant.small} {
    margin: 8px 24px 8px 24px;
  }

  ${variant.large} {
    margin: 8px 16px 8px 16px;
  }

  @media screen and (max-width: 328px) {
    opacity: 0;
  }
`;

const TextContent = styled.p`
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  left: 0;
  right: 0;
  ${(p) => p.theme.typography.caption};
  color: ${(p) => p.theme.colors.neutral.onContainer};
  letter-spacing: 0.5px;
`;
