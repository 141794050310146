import { useLinkToolbarButton, useLinkToolbarButtonState } from '@udecode/plate-link/react';

import { ToggleButton } from '@/ui/Button';
import { Icon } from '@/ui/Icon';

export const LinkToolbarButton = (): JSX.Element => {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);

  return (
    <ToggleButton variant="default" size="small" toggled={props.pressed} onClick={props.onClick}>
      <Icon size="xs" icon={['far', 'link']} />
    </ToggleButton>
  );
};
