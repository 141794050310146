import { FeatureName, useFeatureEnabled } from '../../feature-toggles';

interface Props {
  name: FeatureName;
  children: JSX.Element | null;
}

export const FeatureToggle = ({ name, children }: Props): JSX.Element | null => {
  const isEnabled = useFeatureEnabled(name);
  if (!isEnabled) return null;
  return children;
};
