import { IMAGE_SIZE_LIMIT, RESOURCE_SIZE_LIMIT, VIDEO_SIZE_LIMIT } from './constants';
import { Dimensions, FileType, MetaType } from './types';

export const checkImageDimensions = (file: File): Promise<Dimensions> =>
  new Promise<Dimensions>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject();
    img.onabort = () => reject();
    img.src = window.URL.createObjectURL(file);
  });

export const getMeta = (type: FileType): MetaType => {
  switch (type) {
    case 'image':
      return {
        accept: 'image/png, image/jpeg',
        sizeLimit: IMAGE_SIZE_LIMIT,
      };
    case 'document':
      return {
        accept: 'application/*',
        sizeLimit: RESOURCE_SIZE_LIMIT,
      };
    case 'video':
      return {
        accept: 'video/*',
        sizeLimit: VIDEO_SIZE_LIMIT,
      };
  }
};

/**
 *
 * @param documentUrl processed file url
 * @param fileName original file name
 * @returns result of splitting the file name by name & extension
 */
export const getFileExtensionAndName = ({
  documentUrl,
  fileName,
}: {
  documentUrl: string;
  fileName?: string;
}): {
  name: string;
  extension: string;
} => {
  if (!fileName) {
    const [name, extension] = (documentUrl.split('/').pop() || '').split('.');
    return {
      name: name || '',
      extension: extension || '',
    };
  }

  const splitByDotsParts = fileName.split('.');
  const extension = splitByDotsParts.length > 1 ? splitByDotsParts.pop() : '';
  const name = splitByDotsParts.join('.');

  return {
    name: name || '',
    extension: extension || '',
  };
};
