/* eslint-disable import/no-unused-modules */
import { UnitContainerStyled, UnitLabelStyled, UnitValueStyled } from './styles';

export const TimeUnit = ({ value, label }: { label: string; value: string }): JSX.Element => (
  <div>
    <UnitContainerStyled>
      <UnitValueStyled>{value}</UnitValueStyled>
      <UnitLabelStyled>{label}</UnitLabelStyled>
    </UnitContainerStyled>
  </div>
);
