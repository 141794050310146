import { useEffect, useState } from 'react';

import { XAPISession } from './useXAPISession';
import { xapiClient } from '@/store/xapi/client';
import { StateId as OrgStateId } from '@/store/xapi/useXAPIState';

type StateId = OrgStateId | string;

export type XAPIStateHookResult<T> = {
  data: Record<StateId, T | undefined>;
  isInitialized: boolean;
  setState: (stateId: StateId, nextState: T) => Promise<void>;
  loading: boolean;
  error?: Error;
};

export const useXAPIStates = <T>(
  stateIds: StateId[],
  session?: XAPISession,
  initialState: Record<StateId, T> = {}
): XAPIStateHookResult<T> => {
  const [currentState, setCurrentState] = useState<Record<StateId, T | undefined>>(initialState);
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // first state initialization
  useEffect(() => {
    if (!session || isInitialized || stateIds?.length === 0) return;

    setLoading(true);

    Promise.all(
      stateIds.map((stateId) =>
        xapiClient
          .getState<T>(session, stateId)
          .then((state) => {
            if (state) setCurrentState((prevState) => ({ ...prevState, [stateId]: state }));
          })
          .catch(() => {})
      )
    ).finally(() => {
      setLoading(false);
      setIsInitialized(true);
    });
  }, [session, isInitialized, stateIds?.length]);

  const setState = async (stateId: StateId, nextState: T) => {
    if (loading || !isInitialized || !session) return;

    setLoading(true);
    const oldState = currentState[stateId];

    setCurrentState((prevState) => ({ ...prevState, [stateId]: nextState }));

    return await xapiClient
      .setState(session, stateId, nextState)
      .catch((err) => {
        setError(err);
        setCurrentState((prevState) => ({ ...prevState, [stateId]: oldState }));
      })
      .finally(() => setLoading(false));
  };

  return {
    data: currentState,
    isInitialized: !!session && isInitialized,
    setState,
    loading,
    error,
  };
};
