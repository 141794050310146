import { Wrapper, TextEditorStyled } from './styles';
import { CustomEditorV2 } from '@/component/customEditorV2';

export type EditReflectionPageProps = {
  id: string;
  spaceId: number;
  description?: string;
  onDescriptionChange: (description: string) => void;
};

export const EditReflectionPage = ({ id, spaceId, description, onDescriptionChange }: EditReflectionPageProps): JSX.Element => {
  return (
    <Wrapper>
      <TextEditorStyled>
        <CustomEditorV2
          key={id}
          value={description || ''}
          onChange={(text) => onDescriptionChange(text)}
          style={{ fontSize: 14 }}
          $fullSize
          minimized
          spaceId={spaceId}
          placeholder="Write your content"
          toggledFeatures={{
            dragAndDrop: true,
            draggableInsertHandler: true,
            editorGhostMode: true,
            floatingToolbar: true,
            slashInput: true,
            mediaFiles: true,
          }}
        />
      </TextEditorStyled>
    </Wrapper>
  );
};
