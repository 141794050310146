import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TableStatusRow, THead } from '../styles';
import { TableDeclaration, SortHandler } from '../types';
import { HeaderRow } from './HeaderRow';
import { BodyMd } from '@/components/Typography/Typography';
import { SelectionMeta } from '@/components/Table/hooks/useSelection';
import { PaginationMeta } from '@/components/Table/hooks/usePagination';

interface Props<T> {
  tableId: string;
  /**
   * For contextual translations
   */
  context?: string;
  tableDecl: TableDeclaration<T>;

  totalItemsCount: number;
  selection: SelectionMeta<T>;
  pagination: PaginationMeta<T>;
  /**
   * Sorting
   */
  onSort?: SortHandler<T>;
}

export function AutoHeader<T>({ tableId, context, tableDecl, selection, totalItemsCount, onSort }: Props<T>): JSX.Element {
  const { t } = useTranslation('autoTable');

  return (
    <>
      <THead showActions={selection.selectedItemsCount > 0}>
        <HeaderRow tableId={tableId} tableDecl={tableDecl} selection={selection} onSort={onSort} />
        <TableStatusRow show={selection.selectedItemsCount > 0}>
          <td colSpan={tableDecl.length + (selection.isEnabled ? 1 : 0)}>
            <BodyMd>
              {selection.areAllRowsSelected && t('all-rows-selected', { context, count: totalItemsCount })}
              {!selection.areAllRowsSelected && (
                <>
                  {t('table-subselection', { context, count: selection.selectedItemsCount })}
                  {selection.isAllVisibleSelected && (
                    <Trans
                      ns="autoTable"
                      i18nKey="select-all-question"
                      values={{ context, total: totalItemsCount }}
                      components={[<u key="0" style={{ cursor: 'pointer' }} onClick={selection.onSelectAll} />]}
                    />
                  )}
                </>
              )}
            </BodyMd>
          </td>
        </TableStatusRow>
      </THead>
    </>
  );
}
