import styled from 'styled-components';

import { OverlineMixin } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';

export const StyledLabel = styled.label<{ $required?: boolean }>`
  ${OverlineMixin({ color: 'color-gray' })};
  align-items: center;
  display: flex;
  left: calc(${cvar('gap-sm')} + ${cvar('border-width')});
  position: absolute;
  top: 12px;
  transform: translateY(-23px);
  transition: all 0.2s ease;
  z-index: 10;

  ${props =>
    props.$required &&
    `
      &:after {
        color: var(--color-monza);
        content: '*';
        font-size: 1.125rem;
        height: 12px;
        line-height: 1;
        margin-left: 5px;
        transition: opacity 0.225s ease-in-out;
      }
    `}

  fieldset:disabled &:before {
    background-color: ${cvar('color-secondary')};
  }

  &:before {
    background-color: ${cvar('color-wild-sand')};
    content: '';
    display: block;
    height: 2px;
    left: calc(-1 * ${cvar('gap-xs')});
    position: absolute;
    right: calc(-1 * ${cvar('gap-xs')});
    top: 11px;
    z-index: -1;
  }
`;
