import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';

export const useJourneyNavigate = (): {
  navigate: NavigateFunction;
  navigateToList: () => void;
  navigatoToEditor: (journeyId?: number) => void;
  navigateToLearnerView: (ops: { journeyId: number; journeyTitle: string }) => void;
  navigate404: () => void;
  navigateToAdminCatalog: () => void;
  navigateToAdminUsers: () => void;
} => {
  const navigate = useNavigate();

  return {
    navigate,
    navigate404: () => navigate('/404'),
    navigateToList: () => navigate(`${ADMIN_ROUTE_PATH.JOURNEYS}/`),
    navigatoToEditor: (journeyId?: number) =>
      journeyId ? navigate(`${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/content`, { replace: true }) : () => {},
    navigateToLearnerView: (ops: { journeyId: number; journeyTitle: string }) =>
      ops.journeyId ? navigate(`/journey/${ops.journeyId}/${ops.journeyTitle}`, { replace: true }) : () => {},
    navigateToAdminCatalog: () => navigate(ADMIN_ROUTE_PATH.CATALOG),
    navigateToAdminUsers: () => navigate(ADMIN_ROUTE_PATH.USERS),
  };
};
