import React from 'react';
import { PlateLeaf, PlateLeafProps } from '@udecode/plate-common/react';

import { StrikethroughText } from '@/component/customEditorV2/ui/Typography';

export const StrikethroughLeaf: React.FC<PlateLeafProps> = (props: any) => {
  const { leaf, children, ...rest } = props;

  const StrikethroughComponent = StrikethroughText;

  return (
    <PlateLeaf {...rest} leaf={leaf}>
      <StrikethroughComponent>{children}</StrikethroughComponent>
    </PlateLeaf>
  );
};
