import { RLearningProgress } from '@/store/v2';
import { Progress } from './types';

const PROGRESS_DEFAULT = {
  isStarted: false,
  isCompleted: false,
  completedPercentage: 0,
};

export const transformProgressFromRemote = (progress: RLearningProgress = PROGRESS_DEFAULT): Progress => {
  const perc = progress.completedPercentage || 0;

  return {
    isStarted: perc > 0,
    isCompleted: progress.completed || false,
    completedPercentage: perc,
    expiredAt: progress.expiredAt ? new Date(progress.expiredAt) : undefined,
  };
};

export const transformProgressWithRegistration = (progress: Progress = PROGRESS_DEFAULT, registration = ''): Progress => {
  return {
    ...progress,
    isStarted: progress.isStarted || !!registration,
  };
};
