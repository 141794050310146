import { forwardRef, ReactElement, useEffect, useState } from 'react';
import styled, { Keyframes, keyframes, useTheme } from 'styled-components';

const transitions = {
  enter: keyframes`
    from {
      opacity: 0;
      transform: translateX(50%);
    }
    to {
      opacity: 1;
      transform: none;
    }
  `,
  exit: keyframes`
    from {
      pointer-events: none;
      opacity: 1;
      transform: none;
    }
    to {
      opacity: 0;
      transform: translateX(-50%);
    }
  `,
};

const Transition = styled.div<{ transition: Keyframes; duration: number }>`
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: ${(p) => p.duration}ms;
  animation-name: ${(p) => p.transition};
`;

export type OpenCloseTransitionProps = {
  in: boolean;
  children: ReactElement;
  onEnter?: () => void; // automatically provided by mui-base's <ModalUnstyled>
  onExited?: () => void; // automatically provided by mui-base's <ModalUnstyled>
};

export const OpenCloseTransition = forwardRef<
  // Focus trap won't work without forwarding
  HTMLDivElement,
  OpenCloseTransitionProps
>((props, ref) => {
  const { in: open, onEnter, onExited, children } = props;
  const [isExiting, setIsExiting] = useState(false);
  const transitionDuration = useTheme().transitions.normal;

  useEffect(() => {
    if (open) {
      onEnter?.();
    }
  }, [open]);

  useEffect(() => {
    if (!open && !isExiting) {
      setIsExiting(true);
      setTimeout(() => onExited?.(), transitionDuration ?? 0);
    }
  }, [open, isExiting]);

  const transition = open && !isExiting ? transitions.enter : transitions.exit;

  return (
    <Transition ref={ref} tabIndex={-1} transition={transition} duration={transitionDuration}>
      {children}
    </Transition>
  );
});
OpenCloseTransition.displayName = 'OpenCloseTransition';
