import React from 'react';
import { LinkOpenButton } from '@udecode/plate-link/react';

import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Divider, OptionsPickerRootStyled } from './styles';

interface OptionsPickerProps {
  editButtonProps: {
    onClick: () => void;
  };
  unlinkButtonProps: {
    onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClick: () => void;
  };
}

export function OptionsPicker({ editButtonProps, unlinkButtonProps }: OptionsPickerProps): JSX.Element | null {
  return (
    <OptionsPickerRootStyled>
      <Button {...editButtonProps} variant="ghost">
        Edit link
      </Button>
      <Divider orientation="vertical" />
      <LinkOpenButton asChild>
        <Button variant="ghost">
          <Icon icon="external-link" />
        </Button>
      </LinkOpenButton>
      <Divider orientation="vertical" />
      <Button variant="ghost" {...unlinkButtonProps}>
        <Icon icon="link-slash" />
      </Button>
    </OptionsPickerRootStyled>
  );
}
