import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.thead``;

const StyledHeaderRow = styled.tr``;

export const Header = ({ children, className }: { children?: React.ReactNode; className?: string }): JSX.Element => {
  return (
    <StyledHeader className={className}>
      <StyledHeaderRow>{children}</StyledHeaderRow>
    </StyledHeader>
  );
};
