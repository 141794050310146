import styled from 'styled-components';

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CounterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ListStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  }
`;

export const LoadMoreStyled = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;
