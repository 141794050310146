import React from 'react';
import styled, { css } from 'styled-components';

import { Alignment } from '../types';

const Td = styled.td<{ minimal?: boolean }>`
  padding: 0;
  vertical-align: middle;
  ${(p) =>
    !p.minimal &&
    css`
      border-top: 1px solid ${(p) => p.theme.colors.neutral.outline1};
    `}
`;

const CellContent = styled.div<{ minimal?: boolean; condensed?: boolean; align?: Alignment }>`
  color: ${(p) => p.theme.colors.neutral.onBackground};
  white-space: nowrap;
  text-align: ${(p) => p.align ?? 'left'};
  justify-content: ${(p) =>
    ({
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
    }[p.align ?? 'left'])};
  // The "minimal" mode is intented to be as unobtrusive as possible.
  // A flex layout may affect the element inside the cell (preventing it
  // from occuppying 100% of available width), hence block display in this case:
  display: ${(p) => (p.minimal ? 'block' : 'flex')};
  ${(p) => p.theme.typography.body3};
  ${(p) =>
    !p.minimal &&
    !p.condensed &&
    css`
      padding: 16px;
    `}
`;

export const Cell = ({
  minimal,
  condensed,
  colSpan,
  children,
  className,
  onClick,
  align,
}: {
  /** No margins and no border */
  minimal?: boolean;
  /** No margins, but keep border */
  condensed?: boolean;
  colSpan?: number;
  children?: React.ReactNode;
  className?: string;
  align?: Alignment;
  onClick?: () => void;
}): JSX.Element => (
  <Td minimal={minimal} colSpan={colSpan} onClick={onClick}>
    <CellContent align={align} minimal={minimal} condensed={condensed} className={className}>
      {children}
    </CellContent>
  </Td>
);
