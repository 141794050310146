import styled from 'styled-components';

import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { MetaLabel } from '@/ui/Label';
import { LONG_DATE_FORMAT, SHORT_DATE_FORMAT, useLocalizedDate } from '@/hooks/useLocalizedDate';
import { RLearningContentLocationData, RLearningType } from '@/store/v2';
import { formatTimeDependingOnUserTimezone } from '@/utils/dates';

export type LearningCardMetaProps = {
  type?: string;
  level?: string;
  totalEffort?: number;
  totalLearningItems?: number;
  locations?: RLearningContentLocationData[];
  timezone?: number;
};

const MetaWrapStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const LearningCardMeta = ({ totalEffort, level, locations, type }: LearningCardMetaProps): JSX.Element => {
  const effortText = totalEffort || totalEffort === 0 ? formatToHoursAndMinutesFromSeconds(totalEffort) : undefined;

  const liveEventDate =
    type === RLearningType.LiveEvent && !!locations?.[0]?.startDate && !!locations?.[0]?.endDate
      ? formatTimeDependingOnUserTimezone({
          startTime: locations?.[0]?.startDate as Date,
          endTime: locations?.[0]?.endDate as Date,
          formatOptions: {
            showTimezone: true,
            startFormat: 'd MMM, HH:mm',
            endFormat: SHORT_DATE_FORMAT,
          },
        })
      : '';

  const formattedStart = useLocalizedDate(locations?.[0]?.startDate, LONG_DATE_FORMAT);
  const formattedEnd = useLocalizedDate(locations?.[0]?.endDate, SHORT_DATE_FORMAT);

  const locationDate = formattedStart && formattedEnd && `${formattedStart} - ${formattedEnd}`;

  return (
    <MetaWrapStyled>
      {level && <MetaLabel icon={['far', 'signal-strong']} text={level} />}
      {effortText && <MetaLabel icon={['far', 'clock']} text={effortText} />}
      {locationDate && (
        <MetaLabel icon={['far', 'calendar']} text={liveEventDate ? liveEventDate : `${formattedStart} - ${formattedEnd}`} />
      )}
    </MetaWrapStyled>
  );
};
