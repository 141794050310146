import { useEffect, useState } from 'react';

import { Image, Loader, Wrapper } from './styles';

type ImageWithLoaderProps = WithClassNameProp<{
  /** Image to display */
  src?: string;
  /** Fallback image, used when the main image is not specified or fails to load */
  fallbackSrc?: string;
  /** Alt text */
  alt?: string;
}>;

type ImageStatus = 'idle' | 'loading' | 'loaded' | 'error';

export const ImageWithLoader = ({ src, fallbackSrc, alt = '', className }: ImageWithLoaderProps): JSX.Element => {
  const [imageState, setImageState] = useState<ImageStatus>('idle');
  const [fallbackState, setFallbackState] = useState<ImageStatus>('idle');

  useEffect(() => setImageState(!!src ? 'loading' : 'idle'), [src]);
  useEffect(() => setFallbackState(!!fallbackSrc ? 'loading' : 'idle'), [fallbackSrc]);

  const imageReady = imageState === 'loaded' || fallbackState === 'loaded';
  const finalError = (imageState === 'error' || !src) && (fallbackState === 'error' || !fallbackSrc);

  return (
    <Wrapper className={className}>
      <Loader isHidden={imageReady || finalError} />
      {src && imageState !== 'error' && (
        <Image
          src={src}
          alt={alt}
          onLoad={() => setImageState('loaded')}
          onError={() => setImageState('error')}
          aria-hidden={imageState !== 'loaded'}
        />
      )}
      {fallbackSrc && (imageState === 'error' || !src) && (
        <Image
          src={fallbackSrc}
          alt={alt}
          onLoad={() => setFallbackState('loaded')}
          onError={() => setFallbackState('error')}
          aria-hidden={true}
        />
      )}
    </Wrapper>
  );
};
