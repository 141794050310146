import styled from 'styled-components';

import { preventOrphans } from '@/utils/text';

export const Title = styled.span.attrs((p) => ({
  children: typeof p.children === 'string' ? preventOrphans(p.children) : p.children,
}))`
  grid-area: title;
  ${(p) => p.theme.typography.title4};
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;
