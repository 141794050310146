import { LearningStatus, LearningSubject, LearningVisibility } from '../learning';
import { Provider } from '@/store/providers';
import { RLearningContentProvider } from '@/store/v2/remote.gen';
import { ApolloEntity } from '../apollo';
import { CardType, ProgressCard } from './card';
import { Participation } from '@/types/analytics';

export const LEARNING_CARD_TYPES = [
  'webinar',
  'seminar',
  'article',
  'audio',
  'video',
  'resource',
  'e-learning',
  'online-learning',
  'course',
  'journey',
] as const;
export type LearningCardType = typeof LEARNING_CARD_TYPES[number];
const REST_TYPES = ['learning-path', 'collection'] as const;
export type ProductType = typeof REST_TYPES[number] | LearningCardType | 'profile';
export const PRODUCT_TYPES: ProductType[] = [...LEARNING_CARD_TYPES, ...REST_TYPES];

export type CatalogEntities = typeof catalogEntities[number];

export interface CatalogItem extends ApolloEntity {
  id: number;
  entity: CatalogEntities;
}

const catalogEntities = ['product', 'learning', 'collection', 'journey'] as const;

export interface BaseProduct extends ApolloEntity {
  name: string;
  label: string;
  creator?: number;
  contentOwner?: number;
  costCenter: string;
  legalEntity: string;
  price: number;
  currency: string;
  status: LearningStatus;
  meta: ProductMeta;
  flags: ProductFlags;
  space: {
    id: number;
  };
  services: Service[];
  subjects?: LearningSubject[];
}

interface ProductSpace extends ApolloEntity {
  id: number;
  name?: string;
}

export interface Product extends BaseProduct {
  id: number;
  timestamp: string;
  space: ProductSpace;
  stocks?: Stock[];
  state?: {
    id: number;
    name: State;
    flags: {
      drawStock: boolean;
      endState: boolean;
      entitlement: boolean;
    };
  };
  variants: Variant[];
  rowId?: number;
  learnifierCourseName?: string;
  orderId?: number;
  isPending?: boolean;
  productVariantId?: number;
}

export interface InitialProduct extends Product {
  subjectIds: number[];
}

export interface InitialBaseProduct extends BaseProduct {
  subjectIds: number[];
}

export interface ProductFlags {
  available: boolean;
  approvalRequired: boolean;
  globalMandatoryTraining: boolean;
  enrollmentDisabled: boolean;
  requiresApplication: boolean;
  socialFeaturesEnabled: boolean;
  reflectionsEnabled: boolean;
  featured: boolean;
  canEdit?: boolean;
  hasTeams?: boolean;
  [key: string]: boolean | undefined;
}

export interface ProductMeta extends ApolloEntity {
  IMAGE: string | null;
  TEASER: string | null;
  DESCRIPTION: string | null;
  FORMAT: string | null;
  LEVEL: string | null;
  LENGTH: string | null;
  EFFORT: string | null;
  TOTAL_EFFORT: string | null;
  LANGUAGE: string | null;
  INSTITUTION: string | null;
  INSTRUCTORS: string | null;
  LOCATION: string | null;
  CATEGORY: string | null;
  SUBJECT: string | null;
  ENROLL_BUTTON_TEXT: string | null;
  CONFIRM_MESSAGE: string | null;
  TEAMS?: string;
  TEAMSIZE?: string;
  PROVIDER: string | null;
  CUSTOM_LINK: string | null;
  OBJECT_ID: string | null;
  PROVIDER_ACCESS?: string;
  DISCUSSION_SECTION?: string;
  REFLECTION_SECTION?: string;
  ATTRIBUTES?: string | null;
  CONNECTIONS?: string | null;
}

interface VariantMeta {
  LOCATION?: string;
  METHOD?: string;
  [key: string]: string | undefined;
}

interface Stock {
  id: number;
  total: number;
  reserved: number;
  enabled: boolean;
}

type State = 'registered' | 'pending approval' | 'not approved' | 'approved' | 'cancelled';

export enum Meta {
  TEASER = 'TEASER',
  FORMAT = 'FORMAT',
  LOCATION = 'LOCATION',
  INSTITUTION = 'INSTITUTION',
  INSTRUCTORS = 'INSTRUCTORS',
  LEVEL = 'LEVEL',
  LANGUAGE = 'LANGUAGE',
  SUBJECT = 'SUBJECT',
  TOTAL_EFFORT = 'TOTAL_EFFORT',
  ENROLL_BUTTON_TEXT = 'ENROLL_BUTTON_TEXT',
  CONFIRM_MESSAGE = 'CONFIRM_MESSAGE',
  DISCUSSION_SECTION = 'DISCUSSION_SECTION',
  REFLECTION_SECTION = 'REFLECTION_SECTION',
}
export enum Flags {
  Available = 'available',
  GlobalMandatoryTraining = 'globalMandatoryTraining',
  ApprovalRequired = 'approvalRequired',
  RequiresApplication = 'requiresApplication',
  SocialFeaturesEnabled = 'socialFeaturesEnabled',
  ReflectionsEnabled = 'reflectionsEnabled',
  EnrollmentDisabled = 'enrollmentDisabled',
  Featured = 'featured',
  HasTeams = 'hasTeams',
}

export interface Service extends ApolloEntity {
  id?: number;
  type: string;
  provider: string;
  resource: string;
  url: string;
  meta: LearnifierCourse;
}

export interface LearnifierCourse extends ApolloEntity {
  name: string;
  host: string;
  organizationId: string;
  projectId: string;
  description: string;
  title: string;
  picture: string;
  status: string;
  adminLink: string;
  locale: string;
  timezone: string;
  country: string;
  resource: string;
}

export enum SpacePermission {
  HasWriteAccessToLearningContent = 'hasWriteAccessToLearningContent',
}

export interface Space {
  id: number;
  name: string;
  label: string;
  realm?: string;
  permissions?: Record<SpacePermission, boolean | undefined>;
  contentOrigins?: SpaceContentOrigin[];
}

export interface SpaceContentOrigin {
  id: string;
  provider: { id: string; type: RLearningContentProvider };
}

export interface Inventory {
  ongoing: readonly InventoryProduct[];
  completed: readonly InventoryProduct[];
  allProgress: readonly InventoryProduct[];
  eventEnrollments: readonly EventEnrollment[];
}

export interface EventEnrollment {
  id: number;
  learningId: number;
  locationId: number;
  title: string;
  teaser: string;
  type: string;
  image?: string;
  address: string;
  startDate: string;
  endDate?: string;
  room?: string;
}

export interface InventoryProduct {
  name: string;
  id: number;
  productId: number;
  userSelfTrackedLearningId?: number;
  journeyId?: number;
  label: string;
  spaceId: number;
  state?: State;
  image?: string;
  provider?: Provider;
  providerAccess?: Provider;
  customLink?: string;
  accessLink?: string;
  teaser: string;
  totalEffort?: number;
  totalLearningsCount?: number;
  orderId: number;
  isCancellable?: boolean;
  variant?: Variant;
  mandatory: boolean;
  featured: boolean;
  available: boolean;
  subjects: LearningSubject[];
  isApprovalRequired?: boolean;
  isExternal?: boolean;
  participation?: Participation;
  archived: boolean;
}

export interface Variant extends ApolloEntity {
  id?: number;
  name?: string;
  enabled?: boolean;
  validTo?: string;
  validFrom?: string;
  meta?: VariantMeta;
}

export interface LearningCollection {
  id: number;
  spaceId: number;
  title: string;
  teaser: string;
  imageUrl: string;
  categoryId: number;
  subjectId: number;
  languageId: number;
  type: CardType;
  canEdit?: boolean;
  visibility: LearningVisibility;
  status: LearningStatus;
  items?: readonly ProgressCard[];
  contentOwner: number;
  creator?: number;
  created?: string;
  creatorEmail?: string;
  updated?: string;
  updatedByEmail?: string;
}

type VimeoStatus = 'complete' | 'error' | 'in_progress';

export interface UploadLinkResponse {
  vimeoId: string;
  uploadLink: string;
}

export interface UploadStatus {
  thumbnail: string;
  upload: VimeoStatus;
  transcode: VimeoStatus;
}

export interface VimeoVideo {
  id: string;
  name: string;
  ratio: number;
  duration: number;
  thumbnail: string;
}
