/* eslint-disable react/display-name */
import React, { forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { TextFieldProps } from '../../base/types';
import { Input } from '../Input';
import { TimeInputProps } from './types';

/** Work-around for satisfying CustomInput props (controlled by third-party of react-datepicker) */
const STUB_CUSTOM_INPUT_PROPS: Pick<TextFieldProps, 'value' | 'onChange'> = {
  value: '',
  onChange: () => {},
};

const RootStyled = styled.div<{ width: number }>`
  & .react-datepicker--time-only {
    // TODO In future, find out a better way to adjust react-datepicker popover size
    max-width: ${({ width }) => (width ? `${width}px` : 'auto')};

    border-radius: 8px !important;
    overflow: hidden;
    border: none;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px, rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px;

    .react-datepicker__triangle {
      display: none !important;
    }
  }

  & .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
    display: none !important;
  }

  & .react-datepicker-popper {
    padding-top: 2px !important;
  }

  & .react-datepicker__time-container {
    width: 100% !important;

    & .react-datepicker__time-box {
      width: 100% !important;
    }
  }

  & li {
    &.react-datepicker__time-list-item {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      display: flex;
      align-items: center;
      padding: 6px 16px;
      color: ${(p) => p.theme.tones.neutral[10]};

      &:hover {
        background: ${(p) => p.theme.colors.states.hover1} !important;
      }

      &.react-datepicker__time-list-item--selected {
        background: ${(p) => p.theme.colors.states.hover1} !important;
        color: ${(p) => p.theme.tones.neutral[10]} !important;
      }
    }
  }
`;

/** Temporary fix for issue of no click events on React Datepicker */
const InputWrap = styled.div`
  & label,
  svg {
    pointer-events: none;
  }
`;

const TimeInputStyled = styled(Input)<{ width: number }>`
  // TODO In future, find out a better way to adjust react-datepicker popover size
  width: ${({ width }) => (width ? `${width}px` : 'auto')}; ;
`;

/** Fix for ref warning of React Datepicker @link https://github.com/Hacker0x01/react-datepicker/issues/2165#issuecomment-711032947 */
const CustomTimeInput = forwardRef<HTMLInputElement, TextFieldProps & { width: number }>((props, ref) => (
  <InputWrap>
    <TimeInputStyled {...props} as={undefined} ref={ref} width={props.width} />
  </InputWrap>
));

export const TimeInput = ({
  value,
  onChange,
  inputProps,
  options,
  minTime,
  maxTime,
  width,
  onFilterDates,
}: TimeInputProps): JSX.Element => {
  const ref = useRef(null);

  return (
    <RootStyled width={width}>
      <DatePicker
        selected={value}
        onChange={(date) => {
          if (date) {
            const formattedDate = new Date(date);
            /**
             * Since we don't pick ms, it makes sense to clear them to avoid possible issues when calculating time range
             */
            formattedDate.setMilliseconds(0);
            onChange(formattedDate);
          }
        }}
        disabled={inputProps.disabled}
        showWeekNumbers
        dateFormat={'HH:mm'}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        showTimeSelect
        showTimeSelectOnly
        minTime={minTime || new Date(new Date().setHours(0, 0, 0))}
        maxTime={maxTime || new Date(new Date().setHours(23, 45))}
        customInput={<CustomTimeInput {...inputProps} {...STUB_CUSTOM_INPUT_PROPS} ref={ref} width={width} />}
        popperProps={{
          positionFixed: options?.positionFixed /** @link https://stackoverflow.com/a/64256411 */,
        }}
        filterDate={onFilterDates}
      />
    </RootStyled>
  );
};
