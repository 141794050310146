import { config } from '@/config';
import { Realm, REALM } from '@/types/realm';

export const realmChanger = (realm: string): string =>
  realm === REALM.CLS
    ? config.frontendRoute
    : window.REACT_APP_ENV === 'local'
    ? config.frontendRoute.replace(/(^.+\/\/)(.+)/, `$1${realm}.$2`)
    : config.frontendRoute.replace(/(^.+\/\/)([^.]*)(.*)/, `$1${realm}$3`);

export const isRealm = (realm: Realm | Realm[]): boolean => {
  if (Array.isArray(realm)) return realm.includes(window.realm as Realm);

  return realm === window.realm;
};

export const getRealm = (): Realm => window.realm as Realm;

export const getPrettyRealmName = (): string =>
  ({
    [REALM.CLS]: 'CLS',
    [REALM.KOMPETENS]: 'Kompetens',
    [REALM.SEB]: 'SEB',
    [REALM.ATLASCOPCO]: 'Atlas Copco',
    [REALM.AUTOLIV]: 'Autoliv',
    [REALM.AUTOLIVPILOT]: 'Autoliv-Pilot',
    [REALM.LANSFORSAKRINGAR]: 'Lansforsakringar',
  }[getRealm()]);
