import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';

import { LoadingHero } from '../LoadingHero';
import Page404 from '../../pages/Page404';
import { convertTitle } from '@/utils/misc';

const query = gql`
  query articleRedirect($articleId: Int!) {
    articleRedirect(articleId: $articleId) {
      learningId
      title
    }
  }
`;

type ArticleParams = 'id' | 'collectionId' | 'collectionName';
export const ArticleRedirect = (): JSX.Element => {
  const { id: paramsId, collectionId, collectionName } = useParams<ArticleParams>();
  const id = paramsId ? parseInt(paramsId) : 0;
  const { data, loading } = useQuery(query, { variables: { articleId: id } });

  if (loading) return <LoadingHero />;
  if (!data || !data.ArticleRedirect) return <Page404 />;

  const { learningId, title } = data.articleRedirect;
  const safeTitle = convertTitle(title);

  if (collectionId) {
    return <Navigate to={`/learning-collection/${collectionId}/${collectionName}/learning/${learningId}/${safeTitle}?`} />;
  } else {
    return <Navigate to={`/learning/${learningId}/${safeTitle}`} />;
  }
};
