import { NavLink } from 'react-router-dom';
import React, { MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';

import { Icon } from '@/components/Icon';

interface TabProps {
  hide?: boolean;
  to: string;
  children: ReactNode;
  hasChanges?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  isExact?: boolean;
}

export const Tab = ({ to, children, hide, hasChanges, onClick, isExact = true }: TabProps): JSX.Element => {
  return (
    <div>
      <li className={clsx(hide && 'is-hidden')}>
        <NavLink onClick={onClick} to={{ pathname: to }} state={{ noScroll: true }} replace end={isExact}>
          {hasChanges && <Icon icon="circle" size="xs" fixedWidth className="has-text-grey-dark is-size-8" />}
          <span>{children}</span>
        </NavLink>
      </li>
    </div>
  );
};
