import styled from 'styled-components';

import { useCurrentUser } from '@/store/currentUser';
import { DocumentPreview } from '@/administration/pages/Course/blocks/document/components/DocumentPreview';
import { FileUploader } from '@/component/FileUpload/types';
import { RUserLearningSubmissionReview } from '@/store/v2';
import { SubmittedReviews } from '../../../common/reviews/PeersToReview/SubmittedReviews';
import { AuthorHeader } from '../../../common/AuthorHeader';
import { Divider } from '../../../common/Divider';
import { DocumentPreviewContainer } from '../../../common/DocumentPreviewContainer';

const RootStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubmissionResult = ({
  document,
  createdAt,
  documentUpload,
  receivedReviews,
  peerReviewEnabled,
}: {
  document: {
    url: string;
    fileName: string;
  };
  createdAt: string;
  documentUpload: FileUploader<'document'>;
  receivedReviews: RUserLearningSubmissionReview[];
  peerReviewEnabled: boolean;
}): JSX.Element => {
  const { user } = useCurrentUser();

  return (
    <RootStyled>
      <AuthorHeader authorId={user?.id} createdAt={createdAt} />
      <DocumentPreviewContainer>
        <DocumentPreview documentUrl={document.url} documentUpload={documentUpload} fileName={document.fileName} hideHeader />
      </DocumentPreviewContainer>
      {peerReviewEnabled && (
        <>
          <Divider />
          <div>
            <SubmittedReviews reviews={receivedReviews} />
          </div>
        </>
      )}
    </RootStyled>
  );
};
