import { v4 as uuid } from 'uuid';

import { BlockType, TextBlock } from './types';
import { RBlock, RBlockInput } from '@/store/v2';
import { BlockId } from '../types';

export const createTextBlock = (): TextBlock => ({
  id: `block-l-${uuid()}` as BlockId,
  type: BlockType.TEXT,
  text: '[{"children":[{"text":""}]}]',
});

export const transformTextBlockToRemoteInput = (block: TextBlock, rPageId?: number, order?: number): RBlockInput => ({
  id: block?.remoteId || undefined,
  type: BlockType.TEXT,
  order: order || 0,
  pageId: rPageId || undefined,
  data: { text: block.text },
});

export const transformTextBlockFromRemote = (rBlock: RBlock): TextBlock => ({
  id: `block-r-${rBlock.id || 0}` as BlockId,
  remoteId: rBlock.id || 0,
  type: BlockType.TEXT,
  text: rBlock.data.text || '[{"children":[{"text":""}]}]',
});
