import { LazyLoadBackgroundImage } from '@tjoskar/react-lazyload-img';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './post-media.scss';
import { PostMedia } from '../../types/post';
import FallbackImage from '../../assets/fallback.png';
import { FileResource } from '../Resource/FileResource';

export const PostMediaContent = ({ media }: { media?: PostMedia }): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <div style={{ marginBottom: 10 }}>
      {media?.type === 'resource' && (
        <FileResource
          resource={{
            fileUrl: media.url,
            name: media.url
              .split('/')
              .pop()
              ?.split('.')
              .pop()
              ?.toUpperCase(),
            description: media.url.split('/').pop()
          }}
        />
      )}
      {media?.type === 'image' && (
        <LazyLoadBackgroundImage styleName="post-image" className="is-relative" defaultImage={FallbackImage} image={media.url} />
      )}
      {media?.type === 'video' && (
        <div styleName="video-container">
          <iframe styleName="video" src={media.url} allowFullScreen title={t('Video')} />
        </div>
      )}
    </div>
  );
};
