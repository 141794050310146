import React from 'react';
import { useTranslation } from 'react-i18next';

import { useContentRequestsCount } from '@/administration/store/administration';
import { ADMIN_LINKS, MENU_LABELS } from '@/components/Navbar/consts';
import { StyledDesktopWrapper, StyledNavItem, StyledNavItemNotification } from '@/components/Navbar/styles';
import { useProviderLicensesSummary } from '@/administration/pages/Catalog/utils/queries';
import { ProviderList } from '@/store/providers';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';

export const NavAdminContent = (): JSX.Element => {
  const { t } = useTranslation('navbar');
  const approvalsCount = useContentRequestsCount();
  const { data: courseraSummary } = useProviderLicensesSummary(ProviderList.COURSERA);

  const learningJourneysEnabled = useFeatureEnabled(FEATURE.UI_LEARNING_JOURNEYS);

  return (
    <StyledDesktopWrapper admin>
      {ADMIN_LINKS.filter((i) => learningJourneysEnabled || i.label !== MENU_LABELS.JOURNEYS).map(
        (link) =>
          link.link &&
          !link.onlyMobile && (
            <StyledNavItem to={link.link} key={link.label} className={({ isActive }) => (isActive ? ' selected' : '')}>
              {t(link.label)}
              {link.count && (!!approvalsCount || !!courseraSummary?.pendingLicenses) && <StyledNavItemNotification />}
            </StyledNavItem>
          )
      )}
    </StyledDesktopWrapper>
  );
};
