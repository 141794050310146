export const getIsLiveEventActive = ({ startDate, endDate }: { startDate: Date; endDate: Date }): boolean => {
  const now = new Date();

  // Calculate the time difference in milliseconds
  const diffToStart = (startDate as unknown as number) - (now as unknown as number);
  const diffToEnd = (endDate as unknown as number) - (now as unknown as number);

  // Convert milliseconds to hours
  const diffToStartInHours = diffToStart / (1000 * 60 * 60);
  const diffToEndInHours = diffToEnd / (1000 * 60 * 60);

  // Check if current time is within the desired range
  return diffToStartInHours <= 1 && diffToEndInHours >= 0;
};

export const getIsLiveEventEnded = ({ endDate }: { endDate: Date }): boolean => {
  const currentDate = new Date();

  return currentDate > endDate;
};
