import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CollectionRouteParams } from '../../LearningCollection/LearningCollectionPage';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { ButtonList } from '@/components/Button/ButtonList';

export const BackToCollectionButton = (): JSX.Element | null => {
  const { t } = useTranslation('learning');
  const params = useParams<CollectionRouteParams>();

  return params?.collectionId ? (
    <ButtonList>
      <RouterLinkButton
        to={`/learning-collection/${params.collectionId}/${params.collectionName}`}
        $type="text"
        $iconPosition="left"
        $icon="long-arrow-alt-left"
      >
        {t('Back to collection')}
      </RouterLinkButton>
    </ButtonList>
  ) : null;
};
