import React, { useMemo } from 'react';

import { DEFAULT_MAX_PROGRESS_VALUE, PERCENTAGE_100 } from '../../constants';
import { POSITIONS_TO_DEG } from './constants';
import { RootStyled, CircleContainerStyled, CircleTrackStyled, CircleProgressStyled, InnerContent, FakeCorner } from './styles';
import { CircularProgressBarData, CircularProgressBarProps } from './types';

const getCircleSetup = ({
  value,
  maxValue = DEFAULT_MAX_PROGRESS_VALUE,
  size,
  strokeWidth,
  gap = 0,
  startPosition = 'top',
}: CircularProgressBarData): {
  center: number;
  radius: number;
  rotateDeg: number;
  arcLength: number;
  arcOffset: number;
  innerContentSize: number;
  overflowed?: boolean;
} => {
  const overflowed = value > (maxValue || DEFAULT_MAX_PROGRESS_VALUE);

  const progress = Math.min(Math.round((value / maxValue) * PERCENTAGE_100), PERCENTAGE_100);

  const center = size / 2;
  const radius = center - strokeWidth / 2;
  // 2 * π * radius
  const arcLength = 2 * Math.PI * radius;

  // Ratio of the gap to the circle arc length
  const gapToArcLength = (overflowed ? 0 : gap) / arcLength;

  const arcGapMultiplier = 1 - gapToArcLength;
  const arcOffset = arcLength * ((100 - progress * arcGapMultiplier) / 100);

  // Represents how much degs of the whole circle would the gap take
  const gapDeg = 360 * gapToArcLength;
  // Represents the half of the gap degs, which is the center of the gap
  const gapCenter = gapDeg / 2;
  // Represents the amount of degs, by which we should rotate the whole container, to apply the start position
  const rotateDeg = POSITIONS_TO_DEG[startPosition] + gapCenter;

  // Size of the inner content equals to svg size minus margins and stroke width
  const innerContentSize = size - strokeWidth * 2;
  return {
    overflowed,
    center,
    radius,
    rotateDeg,
    arcLength,
    arcOffset,
    innerContentSize,
  };
};

export const CircularProgressBar = ({
  value,
  maxValue,
  size,
  strokeWidth,
  gap = 0,
  startPosition,
  rounded,
  children,
  customTrackBgColor,
}: CircularProgressBarProps): JSX.Element => {
  const { center, radius, rotateDeg, arcLength, arcOffset, innerContentSize, overflowed } = useMemo(
    () => getCircleSetup({ value, maxValue, size, strokeWidth, gap, startPosition }),
    [value, maxValue, size, strokeWidth, gap, startPosition]
  );

  const commonCircleProps = {
    center,
    radius,
    strokeWidth,
    rounded,
  };

  return (
    <RootStyled>
      <CircleContainerStyled size={size} rotateDeg={rotateDeg} overflowed={overflowed}>
        <CircleTrackStyled
          {...commonCircleProps}
          arcLenght={overflowed ? arcLength : arcLength - gap}
          customTrackBgColor={customTrackBgColor}
        />
        <CircleProgressStyled {...commonCircleProps} arcLenght={arcLength} arcOffset={arcOffset} />
      </CircleContainerStyled>
      {children && <InnerContent size={innerContentSize}>{children}</InnerContent>}
      <FakeCorner visible={overflowed} strokeWidth={strokeWidth} />
    </RootStyled>
  );
};
