import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import './courseTeams.scss';
import { ProductMeta, ProductFlags } from '../../../../types/learning/learning-catalog';
import { OrderUser, ProductOrderFlat } from '../../../types/administration';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { Box } from '@/components/Box/Box';
import { Tag } from '@/components/Tag/Tag';

interface CourseTeamsProps {
  setMeta: React.Dispatch<React.SetStateAction<ProductMeta>>;
  meta: ProductMeta;
  flags: ProductFlags;
  totalParticipants?: number;
  originalTeams?: string;
  orders: readonly ProductOrderFlat[];
  loading: boolean;
  refetch: () => void;
}

export type Team = OrderUser[];
export type Teams = Map<number, Team>;

export const getTeams = (teams: undefined | string): Map<number, Team> | undefined => {
  try {
    if (!teams) return undefined;
    const parsedTeams = JSON.parse(teams);
    const mappedTeams = new Map();
    parsedTeams.forEach((teamWithOrder: [number, Team[]], index: number) => {
      mappedTeams.set(index, teamWithOrder[1]);
    });
    return mappedTeams;
  } catch {
    return undefined;
  }
};

const getTeamMembers = (teams: undefined | string) => {
  const parsedTeams = getTeams(teams);
  if (!parsedTeams) return undefined;
  const teamMembers: Team = [];
  return teamMembers.concat(...[...parsedTeams.values()]);
};

const getTeamSize = (teamSize: undefined | string) => {
  if (!teamSize) return undefined;
  const parsedTeamSize = parseInt(teamSize, 10);
  if (isNaN(parsedTeamSize)) return undefined;
  return parsedTeamSize;
};

const DEFAULT_TEAMSIZE = 5;

const productOrderFlatToUser = ({
  stateId,
  productId,
  productVariantId,
  spaceId,
  orderId,
  userId,
  ...rest
}: ProductOrderFlat) => {
  return {
    ...rest,
    id: userId
  };
};

export const CourseTeams = ({
  setMeta,
  meta,
  flags,
  totalParticipants = 0,
  originalTeams,
  orders: productOrders,
  refetch: refetchProductOrders,
  loading: loadingProductOrders
}: CourseTeamsProps): JSX.Element => {
  const { t } = useTranslation('builderAdmin');
  const [participants] = useState(productOrders.map(productOrderFlatToUser));
  const [teams, setTeams] = useState<Teams | undefined>(getTeams(meta.TEAMS));
  const [teamSize, setTeamSize] = useState<number>(getTeamSize(meta.TEAMSIZE) || DEFAULT_TEAMSIZE);
  const [teamlessMembers, setTeamlessMembers] = useState<Team | undefined>();
  const [removableMembers, setRemovableMembers] = useState<Team | undefined>();
  const [originalTeamMembers] = useState<Team | undefined>(getTeamMembers(originalTeams));

  const participantsWithoutTeam = () => {
    if (!teams) return;
    const teamMembers: OrderUser[] = [];
    for (const team of teams.values()) {
      team && teamMembers.push(...team);
    }
    if (participants && participants.length) {
      const newTeamlessMembers = participants.filter(x => !teamMembers.some(y => x.id === y.id));
      const newRemovableMembers = teamMembers.filter(x => !participants.some(y => x.id === y.id));
      setTeamlessMembers(newTeamlessMembers.length ? newTeamlessMembers : undefined);
      setRemovableMembers(newRemovableMembers.length ? newRemovableMembers : undefined);
    }
  };

  useEffect(() => {
    Boolean(participants && totalParticipants !== participants.length) && refetchProductOrders();
  }, [totalParticipants]);
  useEffect(() => {
    setTeams(getTeams(meta.TEAMS) || undefined);
  }, [setTeams, meta.TEAMS]);
  useEffect(() => {
    setTeamSize(getTeamSize(meta.TEAMSIZE) || DEFAULT_TEAMSIZE);
  }, [setTeamSize, meta.TEAMSIZE]);
  useEffect(() => {
    participantsWithoutTeam();
  }, [teams, participants]);

  const handleTeamSetup = () => {
    if (!participants) return;
    const userCount = participants.length;
    const teamCount = Math.ceil(userCount / teamSize);
    const teams: Teams = new Map();
    let teamIterator = 0;
    const groupedData: Partial<Team[]> = participants.reduce<Partial<Team[]>>(
      // @ts-expect-error k index type error
      (r, v, _i, _a, k = v.realm) => ((r[k] || (r[k] = [])).push(v), r),
      []
    );
    Object.values(groupedData as Team[]).forEach(group =>
      group.forEach((user: OrderUser) => {
        let currentTeam = teams.get(teamIterator);
        if (!currentTeam) {
          teams.set(teamIterator, []);
          currentTeam = teams.get(teamIterator) as Team;
        }
        currentTeam.push(user);
        teamIterator++;
        if (teamIterator === teamCount) teamIterator = 0;
      })
    );
    setMeta(oldMeta => ({ ...oldMeta, TEAMS: JSON.stringify([...teams]) }));
  };

  const handleTeamSize = ({ currentTarget: { value } }: { currentTarget: { value: string } }) =>
    setMeta(oldMeta => ({ ...oldMeta, TEAMSIZE: value }));

  const handleTeamAdditions = () => {
    if (!teams || !teamlessMembers || !teamlessMembers.length) return;
    const memberAdditions: Team = JSON.parse(JSON.stringify(teamlessMembers));
    const teamCount = teams.size;
    let memberCount = 0;
    for (const value of teams.values()) {
      memberCount += value.length;
    }
    const totalMemberCount = memberCount + memberAdditions.length;
    let teamIterator = 0;

    const neededTeamCount = Math.ceil(totalMemberCount / teamSize);
    const minimumMemberCountPerTeam = Math.floor(teamSize / totalMemberCount);

    if (neededTeamCount > teamCount) {
      for (let index = 0; index < neededTeamCount - teamCount; index++) {
        teams.set(teamCount + 1 + index, []);
      }
    }

    const sortedTeams = [...teams.values()].sort((teamA, teamB) => teamA.length - teamB.length);

    do {
      const currentTeam = sortedTeams[teamIterator];
      if (currentTeam && currentTeam.length < teamSize) currentTeam.push(memberAdditions.shift() as OrderUser);
      if (currentTeam && currentTeam.length < minimumMemberCountPerTeam) continue;
      teamIterator++;
      if (teamIterator === teams.size) teamIterator = 0;
    } while (memberAdditions.length);

    setTeamlessMembers(undefined);
    setMeta(oldMeta => ({ ...oldMeta, TEAMS: JSON.stringify([...teams]) }));
  };

  const handleTeamHop = ({
    newTeamIndex,
    oldTeamIndex,
    memberId
  }: {
    newTeamIndex: number;
    oldTeamIndex: number;
    memberId: number;
  }) =>
    setMeta(oldMeta => {
      if (!oldMeta || !oldMeta.TEAMS) return oldMeta;
      const oldTeams = getTeams(oldMeta.TEAMS) as Teams;
      const oldTeam = oldTeams.get(oldTeamIndex);
      let newTeam = oldTeams.get(newTeamIndex);
      if (!oldTeam || !newTeam) return oldMeta;
      for (let index = 0; index < oldTeam.length; index++) {
        if (oldTeam[index].id === memberId) {
          newTeam = newTeam.concat(oldTeam.splice(index, 1));
          break;
        }
      }
      oldTeams.set(oldTeamIndex, oldTeam);
      oldTeams.set(newTeamIndex, newTeam);

      return { ...oldMeta, TEAMS: JSON.stringify([...oldTeams]) };
    });

  const removeMember = (memberId: number, teamIndex: number) =>
    setMeta(oldMeta => {
      if (!oldMeta || !oldMeta.TEAMS) return oldMeta;
      const oldTeams = getTeams(oldMeta.TEAMS) as Teams;
      const oldTeam = oldTeams.get(teamIndex);
      if (!oldTeam) return oldMeta;
      const memberIndex = oldTeam.findIndex(member => member.id === memberId);
      oldTeam.splice(memberIndex, 1);
      oldTeams.set(teamIndex, oldTeam);
      return { ...oldMeta, TEAMS: JSON.stringify([...oldTeams]) };
    });

  const removeRemovableMembers = () => {
    if (!removableMembers) return;
    setMeta(oldMeta => {
      if (!oldMeta || !oldMeta.TEAMS) return oldMeta;
      const oldTeams = getTeams(oldMeta.TEAMS) as Teams;
      for (const [index, oldTeam] of oldTeams) {
        const team = oldTeam.filter(member => !removableMembers.some(({ id: xId }) => member.id === xId));
        oldTeams.set(index, team);
      }
      return { ...oldMeta, TEAMS: JSON.stringify([...oldTeams]) };
    });
  };

  const TeamMemberRow = ({ member, teamIndex }: { member: OrderUser; teamIndex: number }) => {
    const [showMenu, setShowMenu] = useState(false);

    const isRemovable = Boolean(removableMembers) && (removableMembers as Team).some(({ id }) => member.id === id);
    const isNew = originalTeamMembers && !originalTeamMembers.find(originalMember => originalMember.id === member.id);
    const node = useRef<HTMLDivElement>(null);

    useOnClickOutside(showMenu, setShowMenu, node);

    const strikethroughCss = { className: clsx(isRemovable && 'is-line-through') };

    return (
      <label className="panel-block" styleName="member-block">
        <div>
          <div {...strikethroughCss} styleName="member-name">
            {member.username} {isNew && <Tag type="dark">{t('NEW', { ns: 'common' })}</Tag>}
          </div>
          <span {...strikethroughCss} styleName="member-email">
            {member.email}
          </span>
          {isRemovable && (
            <>
              <br />
              <em className="has-text-danger">{t('Not a participant in the course')}</em>
            </>
          )}
        </div>
        <span {...strikethroughCss} styleName="member-realm">
          {member.realm}
        </span>
        <ButtonList ref={node} align="right">
          {!isRemovable && (
            <div className={clsx('dropdown', 'is-right', showMenu && 'is-active')} onClick={() => setShowMenu(!showMenu)}>
              <div className="dropdown-trigger">
                <Button aria-haspopup="true" aria-controls="dropdown-menu" $icon="exchange-alt" />
              </div>
              <div className="dropdown-menu" styleName="team-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {teams &&
                    [...teams.values()].map(
                      (_team, optionIndex) =>
                        optionIndex !== teamIndex && (
                          <a
                            className="dropdown-item"
                            key={`${teamIndex}-${optionIndex}`}
                            onClick={() => {
                              setShowMenu(!showMenu);
                              handleTeamHop({ newTeamIndex: optionIndex, oldTeamIndex: teamIndex, memberId: member.id });
                            }}
                          >
                            <span className="body1">Team {optionIndex + 1}</span>
                          </a>
                        )
                    )}
                </div>
              </div>
            </div>
          )}
          {isRemovable && <Button onClick={() => removeMember(member.id, teamIndex)} $icon="trash" />}
        </ButtonList>
      </label>
    );
  };

  return (
    <>
      {loadingProductOrders && <div className="pageloader" />}
      {!loadingProductOrders && Boolean(participants && participants.length) && flags.hasTeams && (
        <>
          <nav className="level">
            <div className="level-left">
              {!teams && (
                <div className="level-item">
                  <Box style={{ maxWidth: '400px' }}>
                    <div className="field">
                      <label>{t('Team setup')}</label>
                      <p className="help has-bottom-margin">
                        {t('setup-info')}
                        <br />
                        {t('setup-info2')}
                      </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="field has-addons">
                        <div className="control">
                          <Button $type="static">{t('Pick teamsize')}</Button>
                        </div>
                        <div className="control">
                          <div className="select">
                            <select className="select" value={teamSize} onChange={handleTeamSize}>
                              {[...new Array(10)].map((_v, index) => (
                                <option key={index} value={index + 1}>
                                  {index + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="field">
                        <Button onClick={() => handleTeamSetup()}>{t('Setup', { ns: 'common' })}</Button>
                      </div>
                    </div>
                  </Box>
                </div>
              )}
              {teams && removableMembers && !!removableMembers.length && (
                <div className="level-item">
                  <Box>
                    <div className="field">
                      <label>{t('Old participants')}</label>
                      <p className="help has-bottom-margin">
                        {t('member-not-participating', { count: removableMembers.length })}
                      </p>
                      <div className="control">
                        <Button onClick={() => removeRemovableMembers()}>
                          {t('remove-member', { count: removableMembers.length })}
                        </Button>
                      </div>
                    </div>
                  </Box>
                </div>
              )}
              {teams && teamlessMembers && !!teamlessMembers.length && (
                <div className="level-item">
                  <Box>
                    <div className="field">
                      <label>{t('New participants')}</label>
                      <p className="help has-bottom-margin">
                        {t('participants-without-team', { count: teamlessMembers.length })}
                        {removableMembers && !!removableMembers.length && (
                          <>
                            <br />
                            <em>{t('Remove members not participating first')}</em>
                          </>
                        )}
                      </p>
                      <div className="control">
                        <Button disabled={removableMembers && !!removableMembers.length} onClick={() => handleTeamAdditions()}>
                          {t('add-member', { count: teamlessMembers.length })}
                        </Button>
                      </div>
                    </div>
                  </Box>
                </div>
              )}
            </div>
          </nav>

          {teams && (
            <div className="grid is-multiline">
              {[...teams.values()].map((team, index) => (
                <div className="grid-cell grid-cell-6" key={index}>
                  <nav className="panel">
                    <p className={clsx('panel-heading', 'has-text-centered')}>
                      Team {index + 1}
                      {team.length > teamSize && (
                        <>
                          <br />
                          <em className="has-text-danger">{t('Too many team members')}</em>
                        </>
                      )}
                    </p>
                    {team.map((member, memberIndex) => (
                      <TeamMemberRow member={member} teamIndex={index} key={memberIndex} />
                    ))}
                    {team.length < teamSize &&
                      Array(teamSize - team.length)
                        .fill(0)
                        .map((_x, index) => (
                          <label key={index} className="panel-block" styleName="member-block member-block--free">
                            <em>{t('Free spot')}</em>
                          </label>
                        ))}
                  </nav>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
