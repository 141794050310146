import React from 'react';

import { SaveToMyLearning } from '../../../../user/MyLearning/components/SaveToMyLearning';
import { CatalogEntities } from '@/types/learning/learning-catalog';
import { ButtonList } from '@/components/Button/ButtonList';
import { RouterLinkButton } from '@/components/Button/RouterLinkButton';

interface Props {
  id: number;
  entity: CatalogEntities;
  editLink?: string;
  isEvent?: boolean;
}

export const RightButtons = ({ id, editLink, entity, isEvent }: Props): JSX.Element => (
  <ButtonList>
    <SaveToMyLearning isEvent={isEvent} item={{ id, entity }} />
    {!!editLink && <RouterLinkButton to={editLink} $size="small" $icon="edit" />}
  </ButtonList>
);
