import { ApolloError, useQuery, gql } from '@apollo/client';

import { Space } from '@/types/learning/learning-catalog';
import { AssignmentsV3QueryResult, AssignmentV3 } from '@/store/assignments/types';
import { AssignmentV3Status } from '@/store/assignments/typesOther';

type UseBaseAssignmentsHook = (query: {
  userIds: number[] | undefined;
  spaces: readonly Space[] | null;
  status: AssignmentV3Status;
  skip?: boolean;
}) => {
  data: AssignmentV3[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

export const ASSIGNEMNTS_QUERY = gql`
  query assignmentsV3($userIdIN: [Int!], $spacedIdsIN: [Int!], $status: AssignmentV3Status, $pagination: Pagination) {
    assignmentsV3(filter: { userIdIN: $userIdIN, spacedIdsIN: $spacedIdsIN, status: $status }, pagination: $pagination) {
      data {
        __typename
        id
        name
        type
        learnings(pagination: $pagination) {
          data {
            id
            learningPathId
            learningId
            order
          }
          ... on AssignmentStandardV3LearningPage {
            data {
              ... on AssignmentStandardV3Learning {
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const useBaseAssignments: UseBaseAssignmentsHook = ({ userIds, spaces, status, skip }) => {
  const spacesIds = spaces?.map((space) => space.id) ?? null;

  const { data, loading, error } = useQuery<AssignmentsV3QueryResult>(ASSIGNEMNTS_QUERY, {
    skip,
    variables: {
      userIdIN: userIds,
      spacedIdsIN: spacesIds,
      pagination: {
        limit: -1,
        page: 1,
      },
      status,
    },
  });

  return {
    data: data?.assignmentsV3.data,
    loading,
    error,
  };
};
