import { CellFormatter, CellFormatterComponent } from '../types';
import { BooleanCellFormatter } from './BooleanCellFormatter';
import { DateCellFormatter } from './DateCellFormatter';
import { ProgressCellFormatter } from './ProgressCellFormatter';
import { TimeCellFormatter } from './TimeCellFormatter';

const cellFormatters: Record<CellFormatter, CellFormatterComponent> = {
  boolean: BooleanCellFormatter,
  date: DateCellFormatter,
  progress: ProgressCellFormatter,
  time: TimeCellFormatter
};

export { cellFormatters };
