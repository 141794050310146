import styled from 'styled-components';

import { IconButton } from '@/ui/Button';

type FileCardProps = {
  fileExtension: string;
  fileTitle: string;
  downloadUrl: string;
};

const FileCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 88px;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 12px;
  width: 100%;
`;

const FileCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 65%;
`;

const Informer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  background: ${({ theme }) => theme.colors.secondary.container};
  border-radius: 8px;
  flex-shrink: 0;
`;

const StyledInformerSpan = styled.div`
  color: ${({ theme }) => theme.colors.tertiary.onContainer};
  ${(p) => p.theme.typography.overline};
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
`;

const StyledText = styled.div`
  display: flex;
  gap: 4px;
  max-width: 100%;
`;

const StyledTitle = styled.h4`
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  ${(p) => p.theme.typography.title4};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const StyledFileType = styled.h4`
  color: ${({ theme }) => theme.tones.neutral[60]};
  ${(p) => p.theme.typography.title4};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
`;

const FileCardAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  size: 16px;
  Button {
    font-size: 16px;
  }
`;

export const FileCard = ({ fileExtension, fileTitle, downloadUrl }: FileCardProps): JSX.Element => {
  return (
    <FileCardContainer>
      <FileCardContent>
        <Informer>
          <StyledInformerSpan>{fileExtension}</StyledInformerSpan>
        </Informer>
        <StyledText>
          <StyledTitle>{fileTitle}</StyledTitle>
          <StyledFileType>{`.${fileExtension}`}</StyledFileType>
        </StyledText>
      </FileCardContent>
      <FileCardAction>
        <IconButton href={downloadUrl} target="_blank" icon={['far', 'arrow-down-to-line']} />
      </FileCardAction>
    </FileCardContainer>
  );
};
