import React from 'react';
import { useMediaState } from '@udecode/plate-media/react';

import { Placeholder } from '@/component/customEditorV2/ui/elements/ImageElement/Placeholder';
import { UploadedImage } from '@/component/customEditorV2/ui/elements/ImageElement/UploadedImage';
import { MediaElementUploadProgress } from '@/component/customEditorV2/ui/elements/MediaElementUploadProgress';

export const BaseImage = ({
  onUpload,
  onRemoveElement,
  uploading,
  uploadingProgress,
  url,
  selected,
}: {
  onUpload: (file: File) => void;
  onRemoveElement: () => void;
  uploading: boolean;
  uploadingProgress?: number;
  url?: string;
  selected: boolean;
}): JSX.Element | null => {
  const { readOnly } = useMediaState();

  if (readOnly && (uploading || !url)) return null;
  if (uploading) return <MediaElementUploadProgress currentProgress={uploadingProgress || 0} />;
  if (!url) return <Placeholder onUpload={onUpload} shouldShowToolbar={selected} onRemove={onRemoveElement} />;

  return <UploadedImage url={url} onRemove={onRemoveElement} onUpload={onUpload} shouldShowToolbar={selected && !readOnly} />;
};
