import { useEffect, useState } from 'react';
import './learning.scss';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Block } from './components/Block/Block';
import { BlockApprovedTypes, EventTypes, BlockType, CompletableTypes } from '@/types/learning';
import { BlockMeta } from '@/administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { EventHero } from './components/Hero/EventHero';
import { Hero } from './components/Hero/Hero';
import { LoadingHero } from '@/component/LoadingHero';
import { toHtml } from '@/component/customEditor/logic/serialization';
import { useLanguages } from '@/store/languages';
import { useLearning } from '@/store/learning';
import { useSetLearningCompleted } from '@/store/analytics/learning-progress';
import { validateBlock } from '@/administration/pages/LearningEditor/components/BlockEditor/useBlockValidation';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { trackMarkAsCompletedClicked } from '@/utils/tracking/learnings';
import { useLottieAnimation, Animation } from '@/ui/Animation';
import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import { UpNextLearning } from '@/administration/pages/Journey/common/atoms/UpNextLearning';
import {
  LockedLearningWarningModal,
  useLockedLearningWarningModalProps,
} from '@/administration/pages/Journey/common/modals/LockedLearningWarningModal';

const CompletedAnimationWrap = styled.div`
  position: absolute;
  bottom: calc(100% - 44px);
`;

export const Learning = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('learning');
  const parsedLearningId = id ? parseInt(id) : NaN;
  const { nextItem, journeyId, journeyTitle, currentItem } = useJourneyEnhancedNavigation({
    id: parsedLearningId,
  });
  const learningLockedModalProps = useLockedLearningWarningModalProps({
    parentJourneyId: journeyId,
    parentJourneyTitle: journeyTitle,
    isCurrentItemLock: currentItem?.isLocked,
  });

  const { play: playCompletionAnimation, animationProps: completedAnimationProps } = useLottieAnimation({
    variant: 'confetti',
  });

  const handleLearningCompletedSuccesfully = () => {
    playCompletionAnimation();
  };

  const [complete, setComplete] = useState(false);
  const { learning, refetch, loading } = useLearning({ id: parsedLearningId, skip: isNaN(parsedLearningId) });
  const showCompletionButton = learning ? CompletableTypes.includes(learning.type) : false;
  const { setLearningCompleted, loading: learningCompletedLoading } = useSetLearningCompleted(
    parsedLearningId,
    handleLearningCompletedSuccesfully
  );

  const setCompleted = () => {
    if (learningCompletedLoading) return;

    setLearningCompleted();
    trackMarkAsCompletedClicked({ learningTitle: learning?.title || '', learningType: learning?.type || '' });
  };

  useEffect(() => {
    if (learning?.progress?.completed) setComplete(true);
  }, [learning]);

  const { languages } = useLanguages();
  const type = learning?.type;
  const showBlocks = type && BlockApprovedTypes.includes(type);
  const isEvent = type && EventTypes.includes(type);

  if (loading) return <LoadingHero />;
  if (!learning) return <Navigate to="/404" replace />;

  // NOTE: for now assume single module, single page
  const blocks = learning.modules?.[0]?.pages?.[0]?.blocks || [];
  const filteredBlocks = blocks.filter((b) => validateBlock(b.type, b.data as unknown as BlockMeta));
  const isArchived = Boolean(learning.archived);
  const language = languages.find(({ id }) => id === learning.languageId)?.name;
  const eLearningUrl = learning?.meta.eLearningUrl;
  if (isArchived) return <Navigate to={{ pathname: '/404' }} state={{ isArchived: true }} />;

  if (!learning.preamble && filteredBlocks[0]?.type === BlockType.TEXT) {
    filteredBlocks[0] = { ...filteredBlocks[0], type: BlockType.PREAMBLE };
  }

  return (
    <>
      {learningLockedModalProps && <LockedLearningWarningModal {...learningLockedModalProps} />}
      {isEvent ? (
        <EventHero
          title={learning.title}
          teaser={learning.teaser}
          type={learning.type}
          id={learning.id}
          languageId={learning.languageId}
          effort={learning.effort}
          editLink={learning.canEdit ? learning.links.editLink : undefined}
          imageUrl={learning.image || undefined}
          locations={learning.locations || []}
          onEnrolled={refetch}
          isArchived={isArchived}
        />
      ) : (
        <Hero
          title={learning.title}
          teaser={learning.teaser}
          editLink={learning.canEdit ? learning.links.editLink : undefined}
          imageUrl={learning.image || undefined}
          effort={learning.effort}
          language={language}
          isArchived={isArchived}
          eLearningUrl={eLearningUrl}
          onLearningRefetch={refetch}
          learningId={learning.id}
          progress={learning.progress}
        />
      )}
      <section className="section has-background-white-ter is-learning">
        <div className="container">
          <div className="grid">
            <div className="gc gc-2 is-hidden-lp" />
            <div className="gc gc-8 gc-12-lp">
              {!!learning.preamble && (
                <div className="content is-article preamble" dangerouslySetInnerHTML={{ __html: toHtml(learning.preamble) }} />
              )}
              {showBlocks && filteredBlocks.map((b) => <Block key={b.id} block={b} />)}
              {showCompletionButton ? (
                <div styleName="completed-wrapper">
                  <ButtonList align="center">
                    <Button
                      $type="primary"
                      onClick={setCompleted}
                      $icon="check"
                      $loading={learningCompletedLoading}
                      disabled={complete}
                    >
                      <CompletedAnimationWrap>
                        <Animation {...completedAnimationProps} hideWhenStill />
                      </CompletedAnimationWrap>
                      {t(complete ? 'completed' : 'mark-complete')}
                    </Button>
                  </ButtonList>
                </div>
              ) : null}
              {nextItem && (
                <UpNextLearning
                  learningCardProps={{
                    objectId: nextItem.contentId,
                    title: nextItem.content?.title,
                    imageUrl: nextItem.content?.imageURL,
                    type: nextItem.content?.metadata?.type,
                    level: nextItem.content?.metadata?.level,
                    totalEffort: nextItem.content?.plannedDuration,
                  }}
                  isActionActive={complete}
                  actionHref={`${nextItem.content?.curations[0]?.launchURLPath}}`}
                  parentJourneyId={journeyId}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
