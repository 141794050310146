import { useCallback, useState } from 'react';

export interface PageControls {
  page: number;
  setPage: (page: number) => void;
}

interface PageControlsParams {
  controls?: PageControls;
  onPageChange?: (page: number | undefined) => void;
}

/**
 * AutoTable pagination can work in two configuration:
 * - it can be controlled by parent component
 * - it can use internal page state
 *
 * This hooks selects correct configuration and returns unified interface.
 *
 * @param controls
 * @param onPageChange
 */
export function usePageControls({ controls, onPageChange }: PageControlsParams): [number, (page: number) => void] {
  const [internalPage, setInternalPage] = useState<number>(1);

  const setPage = useCallback(
    (page: number) => {
      if (controls) controls.setPage(page);
      else setInternalPage(page);

      if (onPageChange) onPageChange(page);
    },
    [controls, onPageChange, setInternalPage]
  );

  const page = controls ? controls.page : internalPage;

  return [page, setPage];
}
