import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { Card } from '@/types/learning/card';
import { LoadingHero } from '@/component/LoadingHero';
import { Direction } from '@/administration/types/direction';
import { catalogItemsQuery } from '@/store/catalog';
import { PinnedLearningsSearch } from './PinnedLearningsSearch';
import { Tooltip } from '@/ui/Tooltip';
import { DeleteButton, DownButton, Header, NoData, Section, Title, UpButton } from './styles';
import { LearningSelectionProps } from './types';
import { AutoTable, AutoTableProps, ColumnDeclaration } from '@/ui/AutoTable';

export const LearningSelection = ({
  pinnedLearnings,
  handleAddItem,
  handleRemoveItem,
  handleReorder,
}: LearningSelectionProps): JSX.Element => {
  const { t } = useTranslation(['home']);
  const { data: learnings, loading } = useQuery(catalogItemsQuery);

  const options = ((learnings?.catalog?.cards || []) as Card[])
    .filter(({ entityId: entityId }) => !pinnedLearnings.some(({ entityId: itemsEntityId }) => itemsEntityId === entityId))
    .map((card: Card) => ({
      label: `${card.title}`,
      value: { ...card },
    }));

  const renderUpOrdering = ({ row: { id } }: { row: Card }) =>
    pinnedLearnings[0].id !== id ? (
      <Tooltip title="Move up" placement="bottom">
        <UpButton icon="arrow-up" buttonVariant="dark" onClick={() => handleReorder(id, Direction.UP)} />
      </Tooltip>
    ) : (
      <></>
    );

  const renderDownOrdering = ({ row: { id } }: { row: Card }) =>
    pinnedLearnings[pinnedLearnings.length - 1].id !== id ? (
      <Tooltip title="Move down" placement="bottom">
        <DownButton icon="arrow-down" buttonVariant="dark" onClick={() => handleReorder(id, Direction.DOWN)} />
      </Tooltip>
    ) : (
      <></>
    );

  const renderDelete = ({ row }: { row: Card }) => (
    <Tooltip title="Remove" placement="bottom">
      <DeleteButton onClick={() => handleRemoveItem(row)} icon="minus" buttonVariant="dark" />
    </Tooltip>
  );

  if (loading) return <LoadingHero />;

  const RowTitle = ({ row }: { row: Card }) => <Title>{row.title}</Title>;

  const columnDeclaration: ColumnDeclaration<Card> = [
    {
      component: renderUpOrdering,
      shrink: true,
    },
    {
      component: renderDownOrdering,
      shrink: true,
    },
    {
      header: <Header>{t('featured-learnings')}</Header>,
      component: RowTitle,
    },
    {
      component: renderDelete,
      shrink: true,
    },
  ];

  const tableProps: AutoTableProps<Card> = {
    rows: pinnedLearnings,
    rowKey: (pinnedLearnings) => JSON.stringify(pinnedLearnings),
    columnDeclaration,
    noDataMessage: <NoData>{t('no-featured-learning-items')}</NoData>,
    condensed: true,
  };

  return (
    <>
      <Section>
        <PinnedLearningsSearch
          initial={options}
          onSelect={handleAddItem}
          placeholder={t('choose-learnings-items-count', { count: options.length })}
        />
      </Section>
      <Section>{<AutoTable<Card> {...tableProps} />}</Section>
    </>
  );
};
