import React from 'react';
import isNil from 'lodash.isnil';

import { Paginator } from '@/ui/Paginator';
import { Table } from '../Table';
import { AutoTableProps, AnyDataItem } from './types';
import { HeaderItem } from './HeaderItem';
import { RowItem } from './RowItem';

export const AutoTable = <DataItem extends AnyDataItem, SortKey extends string = never>({
  columnDeclaration: columns,
  rows,
  page,
  pageCount,
  onPageChange,
  onSort,
  rowKey,
  ExpandedRow,
  currentSortKey,
  currentSortOrder,
  noDataMessage,
  condensed = false,
}: AutoTableProps<DataItem, SortKey>): JSX.Element => {
  const expandedRowsMode = !!ExpandedRow;
  const columnCount = expandedRowsMode ? columns.length + 1 : columns.length;
  const showData = rows.length > 0;
  const showNoDataMessage = rows.length === 0 && !!noDataMessage;

  return (
    <Table>
      <Table.Header>
        {expandedRowsMode && <Table.HeaderCell minimal shrink />}
        {columns.map((column, index) => (
          <HeaderItem
            key={index}
            column={column}
            currentSortKey={currentSortKey}
            currentSortOrder={currentSortOrder}
            onSort={onSort}
            condensed={condensed}
          />
        ))}
      </Table.Header>

      {showData && (
        <Table.Body>
          {rows.map((row) => (
            <RowItem
              row={row}
              columns={columns}
              ExpandedRow={ExpandedRow}
              condensed={condensed}
              key={
                typeof rowKey === 'function'
                  ? rowKey(row)
                  : ['string', 'number'].includes(typeof row[rowKey])
                  ? (row[rowKey] as string | number)
                  : null
              }
            />
          ))}
        </Table.Body>
      )}

      {showNoDataMessage && <Table.EmptyBody colSpan={columnCount}>{noDataMessage}</Table.EmptyBody>}

      {!isNil(page) && !isNil(pageCount) && pageCount >= 1 && (
        <Table.Footer colSpan={columnCount}>
          <Paginator page={page} pageCount={pageCount} onPageChange={onPageChange} />
        </Table.Footer>
      )}
    </Table>
  );
};
