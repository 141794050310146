import React from 'react';
import styled from 'styled-components';

import { Caption } from '@/components/Typography/v2';

export const ProgressBarBody = styled.div<{ percentage?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
`;

export const ProgressBarRectangle = styled.div<{ percentage?: number }>`
  width: 80px;
  height: 8px;
  background: #e9f3f3;
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    width: ${({ percentage }) => (!!percentage ? `${percentage}px;` : '0')};
    height: 8px;
    background: #0fc290;
    border-radius: 5px;
    display: block;
  }
`;

interface Props {
  percentage: number;
}

export const ProgressBar = ({ percentage }: Props): JSX.Element => (
  <ProgressBarBody>
    <ProgressBarRectangle percentage={percentage}></ProgressBarRectangle>
    <Caption>{percentage}%</Caption>
  </ProgressBarBody>
);
