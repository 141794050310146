import styled from 'styled-components';

import { Skeleton } from '@/ui/Skeleton';

export const Wrapper = styled.div`
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: inherit;
`;

export const Loader = styled(Skeleton)<{ isHidden: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  transition: opacity ${(p) => p.theme.transitions.normal}ms;
  opacity: ${(p) => (p.isHidden ? 0 : 1)};
`;
