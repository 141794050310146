import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentUser } from '@/store/currentUser';
import { StandardAssignment } from './standard';
import { Page } from './standard/components/Page';
import { useBaseAssignment } from './store';
import { StrategicAssignment } from './strategic';

export const Assignment = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const assignmentId = id ? parseInt(id) : undefined;
  const { user } = useCurrentUser();
  const { data: assignment, loading, error } = useBaseAssignment({ assignmentId: assignmentId, userId: user?.id });
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && (!assignment || !!error)) {
      navigate('/404');
    }
  }, [assignment, loading, error]);

  return (
    <>
      {assignment && assignment.type === 'STANDARD' && (
        <>
          <StandardAssignment assignment={assignment} />
        </>
      )}
      {assignment && assignment.type === 'STRATEGIC' && (
        <>
          <StrategicAssignment assignment={assignment} />
        </>
      )}
      {(loading || !assignment) && <Page.LoadingState />}
    </>
  );
};
