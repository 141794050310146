interface MultikeyInternalMapValue<V> {
  map: Map<any, MultikeyInternalMapValue<V>> | undefined;
  valueSet: boolean;
  value: V | undefined;
}

export class MultiKeyMap<V, K extends any[]> {
  private readonly map: Map<any, MultikeyInternalMapValue<V>>;

  constructor() {
    this.map = new Map<any, MultikeyInternalMapValue<V>>();
  }

  get(keys: K): V | undefined {
    const mapValue = this.getMapValueObject(keys);
    return mapValue ? mapValue.value : undefined;
  }

  has(keys: K): boolean {
    const mapValue = this.getMapValueObject(keys);
    return mapValue ? 'value' in mapValue : false;
  }

  hasAndGet(keys: K): [boolean, V | undefined] {
    const mapValue = this.getMapValueObject(keys);
    return mapValue ? [mapValue.valueSet, mapValue.value] : [false, undefined];
  }

  set(keys: K, value: V): void {
    let map = this.map;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let mapValue = map.get(key);

      if (!mapValue) {
        mapValue = {
          map: undefined,
          valueSet: false,
          value: undefined,
        };

        map.set(key, mapValue);
      }

      if (i < keys.length - 1) {
        if (mapValue.map) {
          map = mapValue.map;
        } else {
          map = mapValue.map = new Map<any, MultikeyInternalMapValue<V>>();
        }

        continue;
      }

      if (!mapValue.valueSet) {
        mapValue.valueSet = true;
      }

      mapValue.value = value;
    }
  }

  delete(keys: K): boolean {
    let map = this.map;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const mapValue = map.get(key);

      if (!mapValue) {
        return false;
      }

      if (i < keys.length - 1) {
        if (!mapValue.map) {
          return false;
        }

        map = mapValue.map;
        continue;
      }

      if (mapValue.valueSet) {
        mapValue.valueSet = false;
        mapValue.value = undefined;
        return true;
      } else {
        return false;
      }
    }

    // To pass TypeScript checking.
    return false;
  }

  private getMapValueObject(keys: any[]): MultikeyInternalMapValue<V> | undefined {
    let map = this.map;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const mapValue = map.get(key);

      if (!mapValue) {
        return undefined;
      }

      if (i < keys.length - 1) {
        if (!mapValue.map) {
          return undefined;
        }

        map = mapValue.map;
        continue;
      }

      return mapValue;
    }
  }
}
