import React from 'react';

import { AvatarsGroup, Avatar } from '@/ui/Avatar';
import { RootStyled, TotalStyled } from './styles';
import { DefaultButtonProps } from '@/ui/Button/type';
import { ParticipantProps } from '@/pages/Assignment/ParticipantsPage/components/Participant';
import { shortenName } from '@/utils/text';

type PeopleButtonProps = {
  participants: ParticipantProps[];
  total: number;
} & DefaultButtonProps;

const MAX_VISIBLE_ITEMS = 4;

export const PeopleButton = ({ participants, total, ...rest }: PeopleButtonProps): JSX.Element => {
  const visibleParticipants = participants.slice(0, MAX_VISIBLE_ITEMS);

  return (
    <RootStyled {...rest}>
      <AvatarsGroup>
        {visibleParticipants.map((person) => (
          <Avatar key={person.email} image={person.imageSrc} text={shortenName(person.name)} size="small" outlined />
        ))}
      </AvatarsGroup>
      {Boolean(total) && <TotalStyled>{total}</TotalStyled>}
    </RootStyled>
  );
};
