import { gql } from '@apollo/client';
import { useMemo } from 'react';

import { useSpacesQueryRemote } from './v2/remote.gen';
import { Space, SpacePermission } from '../types/learning/learning-catalog';

export const GET_SPACES_QUERY = gql`
  query getSpaces {
    getSpaces {
      id
      name
      label
      realm
      permissions {
        hasWriteAccessToLearningContent
      }
    }
  }
`;

/**
 * export declare enum NetworkStatus {
 *   loading = 1,
 *   setVariables = 2,
 *   fetchMore = 3,
 *   refetch = 4,
 *   poll = 6,
 *   ready = 7,
 *   error = 8
 * }
 */

interface SpacesResponse {
  spaces: readonly Space[];
  error?: Error;
  loading: boolean;
}

interface UseSpacesOpts {
  withContentOrigins?: boolean;
  withPermissions?: boolean;
}

export const useSpaces = (opts?: UseSpacesOpts): SpacesResponse => {
  const { data, error, loading } = useSpacesQueryRemote({
    variables: { withContentOrigins: opts?.withContentOrigins || false, withPermissions: opts?.withPermissions || false },
  });

  const spaces: ReadonlyArray<Space> = useMemo(
    () =>
      data?.spaces?.data?.map((s) => ({
        ...s,
        permissions: {
          hasWriteAccessToLearningContent: s?.permissions?.hasWriteAccessToLearningContent,
        },
        contentOrigins: s.contentOrigins?.data,
      })) || [],
    [data?.spaces]
  );

  return {
    spaces,
    error,
    loading,
  };
};

type SpacesFilter = {
  id?: number;
  permission?: SpacePermission;
  realm?: string;
  sorted?: boolean;
  hasContentOrigins?: boolean;
};

export function useSpacesFiltered({ id, permission, realm, sorted, hasContentOrigins }: SpacesFilter): {
  data: Space[];
  loading: boolean;
} {
  const { spaces, loading } = useSpaces({ withPermissions: true, withContentOrigins: hasContentOrigins });

  const spacesFiltered = useMemo(() => {
    let processedSpaces = spaces.filter((space) => {
      if (id && space.id !== id) return false;

      if (permission && space?.permissions?.[permission] !== true) return false;

      if (realm && space.label && space.label.toLowerCase() !== realm) return false;

      if (hasContentOrigins && !space.contentOrigins?.length) return false;

      return true;
    });

    if (sorted) {
      processedSpaces = processedSpaces.sort(({ name: nameA }, { name: nameB }) =>
        nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase())
      );
    }

    return {
      data: processedSpaces,
      loading,
    };
  }, [spaces, loading, id, permission, realm, sorted, hasContentOrigins]);

  return spacesFiltered;
}

export function useHasSpaceWithPermission(permission: SpacePermission): boolean {
  const { data } = useSpacesFiltered({ permission });

  return !!data.length;
}

export function useHasSpacePermission(spaceId: number, permission: SpacePermission): { data: boolean; loading: boolean } {
  const { data, loading } = useSpacesFiltered({ id: spaceId, permission });

  return {
    data: !!data.length,
    loading,
  };
}

export function useSpaceByCurrentRealm(): { space: Space | undefined; loading: boolean } {
  const { data, loading } = useSpacesFiltered({ realm: window.realm });

  return {
    space: data[0] || undefined,
    loading,
  };
}
