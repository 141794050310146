import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceMeta } from '@/administration/pages/LearningEditor/components/BlockEditor/blocks/types';
import { Icon } from '@/components/Icon';
import { Box } from '@/components/Box/Box';
import { LinkButton } from '@/components/Button/LinkButton';
import { Media, MediaFigure, MediaIconWrapper, MediaBody } from '@/components/MediaObject/MediaObject';
import { Overline, BodyMd } from '@/components/Typography/Typography';

interface Props {
  data: ResourceMeta;
}

export const ResourceBlock = ({ data: { url, description } }: Props): JSX.Element => {
  const { t } = useTranslation('productType');

  return (
    <Box>
      <Media alignCenter bordered>
        <MediaFigure>
          <MediaIconWrapper>
            <Icon icon={url.endsWith('.pdf') ? 'file-pdf' : 'file-alt'} />
          </MediaIconWrapper>
        </MediaFigure>
        <MediaBody>
          <Overline>{t('resource', { ns: 'productType' })}</Overline>
          <BodyMd>{description}</BodyMd>
        </MediaBody>
        <LinkButton href={url} target="_blank" rel="noopener noreferrer" $type="base" $round $icon="download" />
      </Media>
    </Box>
  );
};
