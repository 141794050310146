import { IconPropType } from '@/ui/Icon';
import { ElementType, MarkType } from './types';

export const ELEMENT_TO_ICON: Record<ElementType, IconPropType> = {
  ['heading']: ['far', 'h1'],
  ['heading2']: ['far', 'h2'],
  ['ordered-list']: ['far', 'list-ol'],
  ['bulleted-list']: ['far', 'list'],
  ['document']: ['far', 'paperclip'],
  ['video']: ['far', 'video'],
  ['quizQuestion']: 'question',
  ['image']: ['far', 'image'],
};

export const MARK_TO_ICON: Record<MarkType, IconPropType> = {
  ['bold']: ['far', 'bold'],
  ['italic']: ['far', 'italic'],
  ['underline']: ['far', 'underline'],
  ['lineThrough']: ['far', 'strikethrough'],
};
