import { v4 as uuid } from 'uuid';

import { RBlock, RBlockInput } from '@/store/v2';
import { BlockType, QuestionBlock } from '@/administration/pages/Course/store/block/types';
import { BlockId } from '../types';

export const createQuestionBlock = (): QuestionBlock => ({
  id: `block-l-${uuid()}` as BlockId,
  type: BlockType.QUESTION,
  title: '',
  variant: 'RADIO',
  answerOptions: [],
});

export const transformQuestionBlockToRemoteInput = (block: QuestionBlock, rPageId?: number, order?: number): RBlockInput => ({
  id: block?.remoteId || undefined,
  type: BlockType.QUESTION,
  order: order || 0,
  pageId: rPageId || undefined,
  data: {
    title: block.title,
    description: block.description,
  },
});

export const transformQuestionBlockFromRemote = (rBlock: RBlock): QuestionBlock => ({
  id: `block-r-${rBlock.id || 0}` as BlockId,
  remoteId: rBlock.id || 0,
  title: rBlock.data.title || '',
  description: rBlock.data.description || '',
  type: BlockType.QUESTION,
  variant: rBlock.data.type || 'RADIO',
  answerOptions: rBlock.data.answer.options,
});
