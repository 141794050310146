import { DependencyList, useEffect } from 'react';

export const useOnEnterPress = (fn: () => void, updateOn?: DependencyList): void => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        fn();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, updateOn);
};
