import { Button } from '@/ui/Button';
import { ButtonVariant } from '@/ui/Button/type/default/styles/button';
import { ButtonGroup } from '@/ui/ButtonGroup';

type ActionProps = {
  action: () => unknown;
  children: string;
  primary?: boolean;
  disabled?: boolean;
  icon?: React.ComponentProps<typeof Button>['icon'];
  iconPosition?: React.ComponentProps<typeof Button>['$iconPosition'];
  loading?: boolean;
  variant?: ButtonVariant;
  className?: string;
};

export const Action = ({
  primary,
  action,
  disabled,
  children: label,
  icon,
  iconPosition = 'end',
  variant,
  loading,
  className,
}: ActionProps): JSX.Element => {
  return (
    <ButtonGroup.Item>
      <Button
        variant={primary ? 'primary' : variant || 'ghost'}
        onClick={action}
        disabled={disabled}
        icon={icon}
        $iconPosition={iconPosition}
        $loading={loading}
        className={className}
      >
        {label}
      </Button>
    </ButtonGroup.Item>
  );
};
