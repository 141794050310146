/* eslint-disable import/no-unused-modules */
import React, { ReactNode } from 'react';

import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { EditNavbar } from '../Navbar';
import { EditDrawer } from '../Drawer';
import { usePageScroll, PageScroll } from '@/administration/pages/Course/MainLayoutDeprecated/atoms/Page/usePageScroll';
import { ContentRootStyled, DrawerStyled, NAVBAR_HEIGHT, NavStyled, Progress, PROGRESS_HEIGHT, RootStyled } from './styles';

type LayoutProps = {
  navbar: ReactNode;
  drawer: ReactNode;
  isDrawerOpen: boolean;
  content: ReactNode;
  contentNav?: ReactNode;
  progress?: {
    value: number;
    prevValue: number;
    maxValue?: number;
  };
  contentScroll?: PageScroll;
};

const _Layout = ({ navbar, drawer, isDrawerOpen, content, progress, contentNav, contentScroll }: LayoutProps): JSX.Element => {
  const navbarHeight = !!progress ? NAVBAR_HEIGHT + PROGRESS_HEIGHT : NAVBAR_HEIGHT;
  const { scrollRootRef, handleScroll } = usePageScroll(contentScroll, { clientHeightOffset: NAVBAR_HEIGHT });

  return (
    <RootStyled>
      <NavStyled>{navbar}</NavStyled>
      {progress && (
        <Progress
          animationStartPercentage={(progress.prevValue / (progress?.maxValue || 100)) * 100}
          value={progress.value}
          maxValue={progress.maxValue || 100}
          rounded={false}
          animated
        />
      )}
      <ContentRootStyled drawerOpen={isDrawerOpen} ref={scrollRootRef} onScroll={handleScroll}>
        {content}
      </ContentRootStyled>
      {contentNav}
      <DrawerStyled top={navbarHeight} isOpen={isDrawerOpen}>
        {drawer}
      </DrawerStyled>
    </RootStyled>
  );
};

const LoadingState = ({
  isProgressBarVisible,
  isDrawerOpen,
}: {
  isProgressBarVisible?: boolean;
  isDrawerOpen?: boolean;
}): JSX.Element => (
  <RootStyled>
    <NavStyled>
      <EditNavbar.LoadingState />
    </NavStyled>
    <DrawerStyled top={isProgressBarVisible ? NAVBAR_HEIGHT + PROGRESS_HEIGHT : NAVBAR_HEIGHT} isOpen={!!isDrawerOpen}>
      <EditDrawer.LoadingState />
    </DrawerStyled>
    <ContentRootStyled drawerOpen={!!isDrawerOpen}>
      <Skeleton />
    </ContentRootStyled>
  </RootStyled>
);

export const Layout = makeNamespacedComponent(_Layout, {
  LoadingState,
});
