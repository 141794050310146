import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import './discussions.scss';
import { Thread } from './Thread';
import { TextInput } from './TextInput';
import { toHtml } from '../customEditor/logic/serialization';
import { Post } from '../../types/post';
import { CustomEditor } from '../customEditor/CustomEditor';
import { GET_REPLIES, useCreatePost, useDeletePost, usePostAction, useUpdatePost } from '../../store/discussion';
import { PostTopMeta } from './PostTopMeta';
import { PostMediaContent } from './PostMediaContent';
import { PostMediaFormFragment } from './PostMediaFormFragment';
import { Avatar } from '../Avatar';
import { AutoComplete } from '../../components/Form/types';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { useModalContext } from '@/components/Modal/ModalContext';
import { Media, MediaBody, MediaFigure } from '@/components/MediaObject/MediaObject';
import { trackDiscussionLearningItemCommented } from '@/utils/tracking/learnings';
import { useCurrentUserAccess } from '@/store/currentUser';

interface Props {
  isStandalone?: boolean;
  highlightedPost?: number;

  onClick?(): void;

  selected: boolean;
  path: string[];
  post: Post;
}

interface Action {
  type: 'startEditing' | 'toggleDropdown' | 'reset' | 'toggleSticky';
}

interface State {
  isActiveDropdown: boolean;
  isEditing: boolean;
  isSticky: boolean;
}

export const DiscussionPost = ({
  isStandalone,
  highlightedPost,
  onClick,
  selected,
  path,
  post: referencedPost,
}: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [post, setPost] = useState(referencedPost);
  const [title, setTitle] = useState(post.title);
  const [content, setContent] = useState(post.content);
  const [media, setMedia] = useState(post.media);
  const [hasLiked, setHasLiked] = useState(!!post.you.have.liked);
  const [likes, setLikes] = useState(post.stats.likes || 0);
  const [pageSize, setPageSize] = useState(isStandalone ? 1000 : 5);
  const { isAdmin } = useCurrentUserAccess();
  const { showConfirmationModal } = useModalContext();

  const maxDepth = isStandalone ? 99 : 3;
  const initialState: State = {
    isActiveDropdown: false,
    isEditing: false,
    isSticky: post.sticky,
  };
  const [state, dispatch] = useReducer((state: State, action: Action) => {
    switch (action.type) {
      case 'toggleDropdown':
        return { ...state, isActiveDropdown: !state.isActiveDropdown };
      case 'toggleSticky':
        return { ...state, isActiveDropdown: false, isSticky: !state.isSticky };
      case 'startEditing':
        return { ...state, isEditing: true, isActiveDropdown: false };
      case 'reset':
        return { ...state, isEditing: false, isActiveDropdown: false };
      default:
        return state;
    }
  }, initialState);

  const createPostAction = useCreatePost();
  const { updatePostAction, loading: updateInProgress } = useUpdatePost();
  const deletePostAction = useDeletePost();
  const togglePostAction = usePostAction();

  const { data, updateQuery } = useQuery(GET_REPLIES, {
    variables: { path, postId: post.id, page: 1, pageSize },
    skip: !selected,
  });

  const replies = data?.getReplies.replies.items || [];
  const replyCount = post.stats.replies || 0;
  const { canEdit, canDelete, canSticky, canUnsticky } = post.permissions;
  const hasActions = canEdit || canDelete || canSticky || canUnsticky;

  useEffect(() => {
    !selected && dispatch({ type: 'reset' });
  }, [selected]);

  const toggleSticky = async () => {
    await updatePostAction(path, post.id, { sticky: !state.isSticky });
    dispatch({ type: 'toggleSticky' });
  };

  const handleComment = async (payload: Post): Promise<void> => {
    const { data } = await createPostAction(path, { ...payload, pid: post.id });

    if (!data) {
      return;
    }

    trackDiscussionLearningItemCommented();

    updateQuery((prev) => {
      const updatedPost = {
        ...prev.getReplies.post,
        stats: {
          ...prev.getReplies.post.stats,
          replies: prev.getReplies.post.stats.replies + 1,
        },
      };

      setPost(updatedPost);

      return {
        getReplies: {
          ...prev.getReplies,
          post: updatedPost,
          replies: {
            ...prev.getReplies.replies,
            items: [data.createPost, ...prev.getReplies.replies.items],
          },
        },
      };
    });
  };

  const editPost = async () => {
    if (content && title) {
      await updatePostAction(path, post.id, { title, content, media: media });
      setPost({ ...post, title, content });
      dispatch({ type: 'reset' });
    }
  };

  const deletePost = async () => {
    const { data } = await deletePostAction(path, post.id);
    if (!data) {
      return;
    }
    setPost({ ...data.deletePost });
    dispatch({ type: 'reset' });
  };

  const setPostAction = (action: string, scalar: number) => {
    if (action === 'liked') {
      if (hasLiked) {
        setHasLiked(false);
        setLikes(likes - 1);
        return togglePostAction(path, post.id, action);
      }

      setHasLiked(true);
      setLikes(likes + 1);
      return togglePostAction(path, post.id, action, { scalar });
    }
  };

  const postActions = () => (
    <div
      className={clsx('dropdown', 'is-right', state.isActiveDropdown && 'is-active')}
      styleName="post-actions"
      role="button"
      onClick={(e) => {
        if (!selected) return false;
        e.stopPropagation();
        dispatch({ type: 'toggleDropdown' });
      }}
    >
      <div aria-haspopup="true" aria-controls={`dropdown-menu${post.id}`} className="dropdown-trigger">
        <Button disabled={!selected} $icon="ellipsis-h" $size="small" />
      </div>
      <div className="dropdown-menu" id={`dropdown-menu${post.id}`} role="menu">
        <div className="dropdown-content">
          {!hasActions && <span className="dropdown-item is-small is-marginless">{t('No action available')}</span>}
          {isAdmin && (canSticky || canUnsticky) && (
            <Button $fullwidth $type="base" onClick={toggleSticky} $icon="thumbtack">
              {state.isSticky ? t('Un-sticky') : t('Make sticky')}
            </Button>
          )}
          {canEdit && (
            <Button $fullwidth $type="base" onClick={() => dispatch({ type: 'startEditing' })} $icon="edit">
              {t('Edit')}
            </Button>
          )}
          {canDelete && (
            <Button
              $fullwidth
              $type="base"
              $icon="trash"
              onClick={() =>
                showConfirmationModal({ action: deletePost, message: t('Are you sure you want to delete this post?') })
              }
            >
              {t('Delete')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div styleName={clsx('discussion-post', selected && 'selected')}>
      <div styleName={clsx('discussion-heading', state.isSticky && 'is-sticky')} onClick={onClick}>
        <Media>
          <MediaFigure>
            <figure className="image is-48x48">
              <Avatar profileImage={post.user.profileImage} />
            </figure>
          </MediaFigure>
          <MediaBody>
            <div className="content">
              <PostTopMeta
                username={post.deleted ? t('[deleted]') : post.user.username}
                realm={post.user.realm}
                created={post.created}
                sticky={state.isSticky}
                labels={post.user.role.labels}
                media={post.media?.type}
              />
              <div styleName="media-actions">
                <ButtonList padded="bottom">
                  <Button
                    $toggled={hasLiked}
                    disabled={!selected}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPostAction('liked', 1);
                    }}
                    $size="small"
                    $icon="arrow-up"
                  >{`${likes || 0}`}</Button>
                  <Button
                    $icon="comment-alt"
                    $size="small"
                    onClick={() => onClick && !selected && onClick()}
                    disabled={!selected}
                  >
                    {replyCount ? `${replyCount > 9 ? `${9}+` : replyCount}` : null}
                  </Button>
                  {postActions()}
                </ButtonList>
              </div>
              <div className="header4" styleName={`discussion-title ${selected ? 'is-expanded' : ''}`}>
                {post.title}
              </div>
            </div>
          </MediaBody>
        </Media>
      </div>
      {selected && (
        <div styleName={clsx('discussion-body', state.isSticky && 'is-sticky')}>
          {!state.isEditing && (
            <>
              {media && <PostMediaContent media={media} />}
              <div
                className="content"
                styleName="discussion-content"
                dangerouslySetInnerHTML={{ __html: toHtml(post.content) }}
              />
            </>
          )}
          {state.isEditing && (
            <div style={{ marginTop: '1rem' }}>
              <input
                className="input has-bottom-margin"
                type="text"
                autoComplete={AutoComplete.on}
                placeholder={t('Title')}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <PostMediaFormFragment media={media} onMediaSelected={setMedia} unsetMedia={() => setMedia(undefined)} />

              <div className="field">
                <CustomEditor
                  className="textarea content"
                  placeholder="Write here"
                  value={content}
                  onChange={setContent}
                  size="large"
                />
              </div>

              <ButtonList align="right">
                <Button $type="text" onClick={() => dispatch({ type: 'reset' })}>
                  {t('Cancel')}
                </Button>
                <Button $type="primary" $loading={updateInProgress} onClick={editPost}>
                  {t('Update')}
                </Button>
              </ButtonList>
            </div>
          )}
          <div styleName="comment-section">
            <TextInput placeholder={t('Write a comment')} onPosted={handleComment} />

            {replyCount > 0 &&
              replies.map((reply: Post) => (
                <Thread
                  isStandalone={isStandalone}
                  highlightedPost={highlightedPost}
                  key={`post-${reply.id}`}
                  {...reply}
                  depth={0}
                  maxDepth={maxDepth}
                  path={path}
                  pageSize={3}
                />
              ))}

            {replyCount > 0 && replyCount > pageSize && pageSize !== 0 && (
              <Button $type="text" onClick={() => setPageSize(pageSize + 5)}>
                {t('Show more')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
