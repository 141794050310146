import { LearningVisibility } from '@/types/learning';

export type Survey = {
  id?: Branded<string, 'Survey id'>;
  spaceId: number;

  title: string;
  teaser: string;
  image: string;
  language: number;

  effortHours: number;
  effortMinutes: number;

  level?: string;
  partner?: string;

  meta?: {
    [k: string]: unknown;
  };

  visibility: LearningVisibility;
};

export enum SurveyVariant {
  CHECK_IN = 1,
  CHECK_OUT = 2,
}
