import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMarkAllNotificationsAsSeen, useNotifications } from '@/store/notifications';
import { BabelonNotification } from '@/pages/user/Notifications/types';
import { Notification } from '@/pages/user/Notifications/components/Notification';
import { Icon } from '@/components/Icon';
import { useModalContext } from '@/components/Modal/ModalContext';
import { Overline } from '@/components/Typography/Typography';
import { StyledNavNotificationsItem, StyledNotificationsList, StyledNavNotificationsListHeader } from './styles';

const NotificationsContent = (props: { closeDropdown: () => void }): JSX.Element => {
  const { notifications } = useNotifications();
  const { t } = useTranslation('navbar');

  if (!notifications.length) {
    return (
      <StyledNavNotificationsItem>
        <em>{t('No notifications')}</em>
      </StyledNavNotificationsItem>
    );
  }

  return (
    <>
      {notifications.map((notification: BabelonNotification) => (
        <StyledNavNotificationsItem key={notification.topLevelPostId} onClick={props.closeDropdown}>
          <Notification notification={notification} />
        </StyledNavNotificationsItem>
      ))}
    </>
  );
};

export const NavNotificationsList = (props: { hasNotifications: boolean; closeDropdown: () => void }): JSX.Element => {
  const { t } = useTranslation('navbar');
  const { markAll } = useMarkAllNotificationsAsSeen();
  const { showConfirmationModal } = useModalContext();

  const handleEmptyNotifications = useCallback(() => {
    props.hasNotifications &&
      showConfirmationModal({
        action: async () => {
          await markAll();
          props.closeDropdown();
        },
        message: t('Are you sure that you want to clear all notifications?', { ns: 'discussions' })
      });
  }, [props.hasNotifications]);

  return (
    <StyledNotificationsList>
      <StyledNavNotificationsListHeader>
        <Overline>{t('Notifications')}</Overline>
        <Icon icon="trash" onClick={handleEmptyNotifications} />
      </StyledNavNotificationsListHeader>
      <NotificationsContent closeDropdown={props.closeDropdown} />
    </StyledNotificationsList>
  );
};
