import React, { Fragment, ReactElement } from 'react';

import { LinkOverline } from './BreadcrumbsStyles';
import { BreadcrumbItem } from './BreadcrumbItem';

interface Props {
  parents: BreadcrumbParent[];
  current?: string;
}

type BreadcrumbParent = BreadcrumbItemStatic | BreadcrumbItemDynamic | false | undefined;

type BreadcrumbItemStatic = {
  link?: string;
  title: string;
};

type BreadcrumbItemDynamic = {
  render: () => ReactElement;
};

export const Breadcrumbs = ({ parents, current }: Props): ReactElement => (
  <>
    {parents.map((parent: BreadcrumbParent, index: number) => (
      <Fragment key={`bci-${index}`}>
        {parent && (
          <>
            {'title' in parent && <BreadcrumbItem title={parent.title} link={parent.link} />}
            {'render' in parent && parent.render()}
          </>
        )}
      </Fragment>
    ))}
    {current && <LinkOverline>{current}</LinkOverline>}
  </>
);
