import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './discussions.scss';
import { CustomEditor } from '../customEditor/CustomEditor';
import { PostMedia, PostPayload } from '@/types/post';
import { PostMediaFormFragment } from './PostMediaFormFragment';
import { isValidTimestampFormat, localToUtcISOString } from '@/utils/time';
import { AutoComplete } from '@/components/Form/types';
import { Button } from '@/components/Button/Button';
import { ButtonList } from '@/components/Button/ButtonList';
import { Toggle } from '@/components/Toggle/Toggle';

interface Props {
  isTeamChannel?: boolean;
  isReflectionSection?: boolean;
  canPostMedia?: boolean;
  onPost(payload: PostPayload): Promise<void> | void;
  onFinished?(): Promise<void> | void;
  onCancel?(): Promise<void> | void;
  hasPublishDate?: boolean;
}

export const NewDiscussionPost = (props: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [media, setMedia] = useState<PostMedia>();
  const [shouldSendNotification, setShouldSendNotification] = useState(false);
  const [publishDate, setPublishDate] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const shouldShowNotificationToggle = useMemo(
    (): boolean =>
      // TODO remove checking if cls realm when emails will be released for all
      // isAdmin && !props.isReflectionSection && currentUser?.realm === 'cls' && !props.isTeamChannel,
      false, // disable as a temporary fix
    []
  );

  const handlePost = async () => {
    setError('');

    let utcTimestamp;
    if (props.hasPublishDate && publishDate) {
      if (!isValidTimestampFormat(publishDate)) {
        setError(t('Invalid time format: YYYY-MM-DD HH:MM'));
        return;
      }
      const [year, month, date, hours, minutes] = publishDate.split(/[ :-]/).map((i) => parseInt(i));
      utcTimestamp = localToUtcISOString(new Date(year, month - 1, date, hours, minutes));
    }

    if (title && content) {
      try {
        setLoading(true);
        await props.onPost({
          title,
          content,
          media,
          publishDate: utcTimestamp,
          shouldSendNotification,
          isReflectionSection: props.isReflectionSection,
        });

        setTitle('');
        setContent('');
      } catch (e) {
        setError(t('post-error', { context: 'discussion' }));
      } finally {
        setLoading(false);

        if (props.onFinished) {
          props.onFinished();
        }
      }
    }
  };

  return (
    <div className="grid">
      <div className={props.canPostMedia ? 'grid-cell gc-8 gc-12-l' : 'grid-cell gc-12'}>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              autoComplete={AutoComplete.on}
              placeholder={t('Title')}
              style={{ marginBottom: 5, marginTop: 5 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <CustomEditor
              className="textarea content has-bottom-margin"
              style={{ fontSize: 14 }}
              placeholder="Write here"
              value={content}
              onChange={setContent}
              size="large"
            />
          </div>
        </div>
        {props.hasPublishDate && (
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                autoComplete={AutoComplete.off}
                pattern="\d{4}-\d{2}-\d{2} \d{2}:\d{2}"
                placeholder={'YYYY-MM-DD HH:MM'}
                style={{ width: '100%' }}
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      {props.canPostMedia && (
        <div className="grid-cell gc-4 gc-12-l">
          <PostMediaFormFragment media={media} onMediaSelected={setMedia} unsetMedia={() => setMedia(undefined)} />
        </div>
      )}
      <div className="grid-cell gc-12">
        {shouldShowNotificationToggle && (
          <Toggle
            checked={shouldSendNotification}
            onChange={() => setShouldSendNotification(!shouldSendNotification)}
            label={t('Send notification')}
          />
        )}
        <ButtonList align="right">
          <Button $type="text" onClick={() => props.onCancel && props.onCancel()}>
            {t('Cancel')}
          </Button>
          <Button $type="primary" $loading={loading} onClick={handlePost}>
            {t('Post')}
          </Button>
        </ButtonList>
      </div>

      {error && (
        <div className="grid-cell gc-12">
          <article className="message is-warning">
            <div className="message-body">{error}</div>
          </article>
        </div>
      )}
    </div>
  );
};
