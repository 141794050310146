/* eslint-disable import/no-unused-modules */
import React, { useRef, useState } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import styled from 'styled-components';

import { Input } from '@/ui/TextField';
import { Button } from '@/ui/Button';
import { getTextSelection } from '../../utils/getTextSelection';

type CustomPromptProps = {
  onGenerate: (prompt: string) => void;
};

const CustomPromptContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

/** Temporary fix for issue of no click events on React Datepicker */
const InputWrap = styled.div`
  width: 100%;
  ${(p) => p.theme.shadow.primary[8]};
  & label,
  svg {
    pointer-events: none;
  }
`;

const GenerateButton = styled(Button)`
  height: 40px;
  box-sizing: content-box;
  ${(p) => p.theme.shadow.primary[8]};
`;

export const CustomPrompt = ({ onGenerate }: CustomPromptProps): JSX.Element => {
  const editor = useSlate();
  const customPromptElement = useRef<HTMLInputElement | null>(null);
  const [customPrompt, setCustomPrompt] = useState('');

  return (
    <CustomPromptContainer>
      <InputWrap>
        <Input
          leadingIcon={['far', 'wand-magic-sparkles']}
          ref={customPromptElement}
          fullWidth
          value={customPrompt}
          onChange={(e) => {
            setCustomPrompt(e.currentTarget.value);
          }}
          label="Ask AI to edit or generate"
          onMouseDown={() => {
            customPromptElement.current?.focus();
            Editor.addMark(editor, 'fakeSelection', true);
          }}
          contentEditable={false} // makes input not editable, from Slate perspective
          onBlur={() => {
            Editor.removeMark(editor, 'fakeSelection');
          }}
        />
      </InputWrap>
      <GenerateButton
        variant="secondary"
        onMouseDown={() => {
          onGenerate(`Apply following rule: "${customPrompt}" to the text: "${getTextSelection(editor)}"`);
        }}
        style={{ minWidth: 'fit-content' }}
        size="large"
      >
        Generate
      </GenerateButton>
    </CustomPromptContainer>
  );
};
