/** Partially based on @link https://platejs.org/docs/components/link-floating-toolbar */
import React from 'react';
import { flip, offset, UseVirtualFloatingOptions } from '@udecode/plate-floating';
import {
  LinkFloatingToolbarState,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
} from '@udecode/plate-link/react';

import { OptionsPicker } from './OptionsPicker';
import { EditLinkForm } from './EditLinkForm';

const floatingOptions: UseVirtualFloatingOptions = {
  placement: 'bottom-start',
  middleware: [
    offset(12),
    flip({
      padding: 12,
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
    }),
  ],
};

interface LinkFloatingToolbarProps {
  state?: LinkFloatingToolbarState;
}

export function LinkFloatingToolbar({ state }: LinkFloatingToolbarProps): JSX.Element | null {
  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const { props: insertProps, ref: insertRef, hidden, textInputProps } = useFloatingLinkInsert(insertState);

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const { props: editProps, ref: editRef, editButtonProps, unlinkButtonProps } = useFloatingLinkEdit(editState);

  if (hidden) return null;

  const input = <EditLinkForm textInputProps={textInputProps} />;

  const editContent = editState.isEditing ? (
    input
  ) : (
    <OptionsPicker editButtonProps={editButtonProps} unlinkButtonProps={unlinkButtonProps} />
  );

  return (
    <>
      <div ref={insertRef} {...insertProps}>
        {input}
      </div>

      <div ref={editRef} {...editProps}>
        {editContent}
      </div>
    </>
  );
}
