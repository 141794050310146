import styled from 'styled-components';

export const StyledFab = styled.div`
  --fab-size-large: 56px;
  --fab-size-small: 40px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  background-color: var(--color-mine-shaft);
  border-radius: var(--fab-size-large);
  bottom: 15px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16), 0px 3px 10px rgba(0, 0, 0, 0.16);
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: var(--fab-size-large);
  justify-content: center;
  position: fixed;
  right: 30px;
  transition: all 300ms ease-in-out;
  user-select: none;
  width: var(--fab-size-large);
  z-index: 200;

  &:before {
    background-color: rgba(70, 70, 70, 0.9);
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    color: #ececec;
    content: attr(data-tooltip);
    display: inline-block;
    font-size: 0.75rem;
    font-weight: var(--font-weight-bold);
    line-height: 1em;
    max-width: 200px;
    opacity: 0;
    padding: 6px 8px;
    position: absolute;
    right: 65px;
    text-align: center;
    text-overflow: ellipsis;
    top: 25%;
    transition: opacity 150ms cubic-bezier(0.4, 0, 1, 1);
    vertical-align: middle;
    visibility: hidden;
    white-space: nowrap;
  }

  &:hover:before {
    content: attr(data-tooltip);
    visibility: visible;
    opacity: 1;
  }

  & svg {
    transition: all 300ms ease-in-out;
    will-change: transform;
  }
`;

export const StyledSmallFab = styled(StyledFab)<{ $active?: boolean }>`
  background-color: var(${({ $active }) => ($active ? '--color-primary' : '--color-mine-shaft')});
  border-radius: var(--fab-size-small);
  width: var(--fab-size-small);
  height: var(--fab-size-small);
  right: 38px;
  bottom: 23px;
  font-size: 1.25em;
  will-change: bottom;

  &:hover {
    color: var(--color-white);
  }

  &:before {
    right: 50px;
  }
`;

export const StyledFabWrapper = styled.div<{ show?: boolean }>`
  ${({ show }) =>
    show &&
    `
      & ${StyledSmallFab}:nth-child(1) {
        bottom: 80px;
      }

      & ${StyledSmallFab}:nth-child(2) {
        bottom: 130px;
      }

      & ${StyledSmallFab}:nth-child(3) {
        bottom: 180px;
      }

      & ${StyledSmallFab}:nth-child(4) {
        bottom: 230px;
      }

      & ${StyledSmallFab}:nth-child(5) {
        bottom: 280px;
      }

      & ${StyledSmallFab}:nth-child(6) {
        bottom: 330px;
      }

      & + ${StyledFab} svg {
        transform: rotate(135deg);
      }
  `}
`;
