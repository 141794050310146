import styled, { css, DefaultTheme, FlattenInterpolation, FlattenSimpleInterpolation, ThemeProps } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { Mode, ChipStyledProps, Variant, IconStyledProps } from '../types';

const modeStyling: Record<Mode, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  none: css``,
  warning: css`
    background: ${(p) => p.theme.colors.warning.container};
    border: 1px solid ${(p) => p.theme.colors.warning.container};
    color: ${(p) => p.theme.colors.warning.onContainer};

    &:hover {
      background: ${(p) => p.theme.colors.warning.onContainer};
      border: 1px solid ${(p) => p.theme.colors.warning.onContainer};
      color: ${(p) => p.theme.colors.warning.container};
    }

    &:focus-visible {
      padding: 0 15px;
      outline: none;
      border: 2px solid ${(p) => p.theme.colors.warning.outline};
    }
  `,
  error: css`
    background: ${(p) => p.theme.colors.error.container};
    border: 1px solid ${(p) => p.theme.colors.error.container};
    color: ${(p) => p.theme.colors.error.onContainer};

    &:hover {
      background: ${(p) => p.theme.colors.error.onContainer};
      border: 1px solid ${(p) => p.theme.colors.error.onContainer};
      color: ${(p) => p.theme.colors.error.container};
    }

    &:focus-visible {
      padding: 0 15px;
      outline: none;
      border: 2px solid ${(p) => p.theme.colors.error.outline};
    }
  `,
  'error-outlined': css`
    color: ${(p) => p.theme.colors.error.main};
  `,
  success: css`
    color: ${(p) => p.theme.colors.success.main};
  `,
} as const;

export const ChipTypeStyling: Record<Variant, FlattenSimpleInterpolation> = {
  outline: css`
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
  `,
  avatar: css`
    height: 24px;
    padding: 0 16px;
    border-radius: 30px;
  `,
  dropdown: css`
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
  `,
} as const;

export const DefaultStatesStyling = css`
  &:active,
  &:hover {
    background: ${(p) => p.theme.colors.primary.onContainer};
    border: 1px solid ${(p) => p.theme.colors.primary.onContainer};
    cursor: pointer;
  }

  &:hover {
    background: ${(p) => p.theme.colors.states.hover4};
    color: ${(p) => p.theme.colors.neutral.background};
  }

  &:active {
    color: ${(p) => p.theme.colors.neutral.disabled};
    background: ${(p) => p.theme.colors.states.hover4};
  }

  &:focus-visible {
    padding: 0 15px;
    outline: none;
    border: 2px solid ${(p) => p.theme.colors.primary.outline};
  }
`;

export const DefaultStyling = css`
  ${(p) => p.theme.typography.body4};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.neutral.onContainer};
  border: 1px solid ${(p) => p.theme.colors.neutral.outline2};
  box-sizing: border-box;
  transition: all 0.3s;
  ${DefaultStatesStyling}
`;

export const DisabledStylings = css`
  &,
  :hover,
  :active,
  :focus-visible {
    color: ${(p) => p.theme.colors.neutral.disabled};
    border: 1px solid ${(p) => p.theme.colors.neutral.disabled};
    background: none;
    cursor: not-allowed;
    padding: 0 16px;
    filter: none;
  }
`;

export const ElevatedStylings = css`
  &,
  :hover,
  :focus-visible {
    ${(p) => p.theme.shadow.primary[2]};
  }

  :active {
    ${(p) => p.theme.shadow.primary[16]}
  }
`;

export const SelectedStylings = css`
  background: ${(p) => p.theme.colors.primary.container};
  border: 1px solid ${(p) => p.theme.colors.primary.container};
  color: ${(p) => p.theme.colors.primary.onContainer};

  &:hover {
    background: ${(p) => p.theme.colors.states.hover2};
    border: 1px solid ${(p) => p.theme.colors.states.hover2};
    color: ${(p) => p.theme.colors.primary.onContainer};
  }

  &:active {
    background: ${(p) => p.theme.colors.states.hover2};
    border: 2px solid ${(p) => p.theme.colors.primary.outline};
    color: ${(p) => p.theme.colors.neutral.disabled};
  }

  &:focus-visible {
    background: ${(p) => p.theme.colors.primary.container};
    padding: 0 15px;
    outline: none;
    border: 2px solid ${(p) => p.theme.colors.primary.outline};
  }
`;

export const SelectedDisabledStylings = css`
  &,
  :hover,
  :active,
  :focus-visible {
    color: ${(p) => p.theme.colors.neutral.disabled};
    background: ${(p) => p.theme.colors.neutral.container};
    border: none;
  }
`;

export const ChipStyled = styled.div<ChipStyledProps>`
  /* Specified in a certain order to override the stylings they are supposed to be overriden.
     Do not change the order, if you don't intend to change overriding logic
   */

  ${DefaultStyling}
  ${({ variant }) => ChipTypeStyling[variant || 'outline']}
  ${({ elevated }) => elevated && ElevatedStylings}
  ${({ selected }) => selected && SelectedStylings}
  ${({ mode }) => modeStyling[mode || 'none']}
  ${({ disabled }) => disabled && DisabledStylings}
  ${({ disabled, selected }) => disabled && selected && SelectedDisabledStylings}
`;

export const IconStyled = styled(Icon)<IconStyledProps>`
  margin: ${({ iconPosition }) => (iconPosition === 'end' ? '0 0 0 8px' : '0 8px 0 0')};
`;
