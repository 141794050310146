/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';

import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { CellFormatterComponent } from '../types';

const PrettyTime = styled.time`
  ${p => p.theme.typography.body3};
  color: ${p => p.theme.colors.neutral.onBackground};
`;

export const TimeCellFormatter: CellFormatterComponent = ({ data }) => {
  if (typeof data === 'number') {
    return <PrettyTime>{formatToHoursAndMinutesFromSeconds(data)}</PrettyTime>;
  }
  return null;
};
