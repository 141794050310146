import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { cvar } from '@/styles/GlobalStyle';
import { Layout } from '@/ui/Layout';

const TAB_BUTTON_AVATAR_HEIGHT = '26px';

export const StyledNavRightSection = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledDesktopMenuButton = styled.div`
  align-items: center;
  cursor: pointer;
  padding: 0 ${cvar('gap-sm')};

  > svg {
    color: ${cvar('color-icon')};
  }

  &:hover > svg {
    color: ${cvar('color-icon-hover')};
  }
`;

const MenuBodyWrapper = {
  Outer: styled.div`
    position: absolute;
    z-index: 35;
    top: ${cvar('navbar-height-desktop')};
    right: 0;
    width: 100%;
  `,
  Inner: styled.div`
    max-width: ${(p) => p.theme.sizes.maxContentWidth}px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    ${Layout.ContentSection.css}
  `,
  Contents: styled.div`
    background: ${(p) => p.theme.colors.neutral.background};
    min-width: 200px;
    border-radius: 0 0 8px 8px;
  `,
};

export const MenuBody = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <MenuBodyWrapper.Outer>
    <MenuBodyWrapper.Inner>
      <MenuBodyWrapper.Contents>{children}</MenuBodyWrapper.Contents>
    </MenuBodyWrapper.Inner>
  </MenuBodyWrapper.Outer>
);

export const StyledProfileMenuOption = styled(NavLink)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${cvar('color-text')};
  font-size: ${cvar('font-size-6')};
  border-top: ${cvar('border-style')};
  padding: ${cvar('gap-xs')} ${cvar('gap-sm')};

  &:last-child {
    border-radius: 0 0 ${cvar('radius-outer')} ${cvar('radius-outer')};
  }

  svg {
    color: ${cvar('color-icon')};
    font-size: ${cvar('font-size-7')};
  }

  &:hover {
    background-color: ${cvar('color-alto')};

    svg {
      color: ${cvar('color-icon-hover')};
    }
  }
`;

export const StyledProfileMenuOptionNativeLink = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${cvar('color-text')};
  font-size: ${cvar('font-size-6')};
  border-top: ${cvar('border-style')};
  padding: ${cvar('gap-xs')} ${cvar('gap-sm')};

  &:last-child {
    border-radius: 0 0 ${cvar('radius-outer')} ${cvar('radius-outer')};
  }

  svg {
    color: ${cvar('color-icon')};
    font-size: ${cvar('font-size-7')};
  }

  &:hover {
    background-color: ${cvar('color-alto')};

    svg {
      color: ${cvar('color-icon-hover')};
    }
  }
`;

export const StyledTabButtonWrapper = styled.div`
  display: flex;
  position: relative;
  box-shadow: ${cvar('box-shadow-large')};
`;

export const StyledTabButtonWrapperV2 = styled.div`
  display: flex;
  position: relative;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
`;

export const StyledTabButton = styled.button<{ icon?: boolean; hasNotifications?: boolean; $selected?: boolean }>`
  color: ${cvar('color-text')};
  font-size: ${cvar('font-size-6')};
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  height: ${BUTTON_HEIGHT_ENUM.default}rem;
  background-color: ${({ $selected }) => cvar($selected ? 'color-secondary' : 'color-white')};
  line-height: 1.6;

  &:hover {
    background-color: ${cvar('color-alto')};
  }

  ${(props) =>
    props.icon &&
    `svg {
      color: ${cvar('color-icon')};
      font-size: ${cvar('font-size-7')};
    }
    
    &:hover svg {
      color: ${cvar('color-icon-hover')};
    }`}
  ${(props) =>
    props.hasNotifications &&
    `&:hover svg, svg {
        color: ${cvar('color-brand')};
        margin-right: ${cvar('gap-xxs')};
    }`}

  a& {
    padding: 0 calc(2 * ${cvar('gap-xs')}) 0 ${cvar('gap-xs')};
    border-bottom-left-radius: ${cvar('radius-inner')};
    border-top-left-radius: ${cvar('radius-inner')};
  }

  button& {
    border-bottom-right-radius: ${cvar('radius-inner')};
    border-top-right-radius: ${cvar('radius-inner')};
    padding: 0 ${cvar('gap-sm')} 0 calc(2 * ${cvar('gap-xs')});
  }
`;

export const StyledTabButtonAvatar = styled.div`
  width: ${TAB_BUTTON_AVATAR_HEIGHT};
  height: ${TAB_BUTTON_AVATAR_HEIGHT};
  position: absolute;
  top: 10px;
  right: calc(-${TAB_BUTTON_AVATAR_HEIGHT} / 2 + 1px);
`;

export const StyledTabButtonDivider = styled.div`
  position: relative;
  width: ${cvar('border-width')};
  height: ${BUTTON_HEIGHT_ENUM.default}rem;
  background-color: ${cvar('color-secondary')};
`;
