import { IconButton } from '@/ui/Button';
import { ExtensionStyled, FormatInformerStyled, HeadingWrapStyled, MetaWrapStyled, RootStyled, TitleStyled } from './styles';

export const DownloadableResource = ({
  downloadUrl,
  fileExtension,
  fileName,
  bordered,
}: {
  downloadUrl: string;
  fileExtension?: string;
  fileName?: string;
  bordered?: boolean;
}): JSX.Element => {
  return (
    <RootStyled bordered={bordered}>
      <MetaWrapStyled>
        <FormatInformerStyled>{fileExtension}</FormatInformerStyled>
        <HeadingWrapStyled>
          <TitleStyled>{fileName}</TitleStyled>
          <ExtensionStyled>.{fileExtension}</ExtensionStyled>
        </HeadingWrapStyled>
      </MetaWrapStyled>
      <IconButton href={downloadUrl} target="_blank" icon={['far', 'arrow-down-to-line']} />
    </RootStyled>
  );
};
