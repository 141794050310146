import { ButtonHTMLAttributes } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { color, overline, body2 } from '@/components/Typography/v2';

export const sizes = ['small', 'medium', 'large', 'extraLarge'] as const;
export const variants = ['neutral', 'primary', 'secondary', 'ghost', 'link', 'raw', 'primaryDanger'] as const;

export type ButtonSize = typeof sizes[number];
export type ButtonVariant = typeof variants[number];

const buttonTypeStyle: Record<ButtonVariant, FlattenSimpleInterpolation> = {
  neutral: css`
    ${overline}
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    &&:disabled {
      ${color('#BEC8C8')}
      background-color: #ffffff;
      outline: none;
      cursor: default;
    }
    :hover {
      background-color: #002115;
      ${color('#ffffff')}
    }
    :focus:not(:active) {
      background-color: #ffffff;
      outline: 1px solid #0fc290;
      ${color('#141D1E')}
    }
    :active {
      background: #141d1e;
      ${color('#BEC8C8')}
    }
  `,
  primary: css`
    ${overline}
    background-color: #008862;
    ${color('#ffffff')}
    border-radius: 4px;
    &&:disabled {
      background-color: #e6ecec;
      ${color('#CEDADA')}
      outline: none;
      cursor: default;
    }
    :hover {
      border: none;
      background-color: #005139;
      ${color('#ffffff')}
    }
    :focus:not(:active) {
      background-color: #008862;
      outline: 1px solid #42dca8;
      ${color('#ffffff')}
    }
    :active {
      background: #141d1e;
      ${color('#CEDADA')}
    }
  `,
  secondary: css`
    ${overline}
    border: 1px solid #6f797a;
    border-radius: 4px;
    background-color: #ffffff;
    ${color('#141D1E')}
    &&:disabled {
      border: 1px solid #bec8c8;
      ${color('#BEC8C8')}
      background-color: #ffffff;
      outline: none;
      cursor: default;
    }
    :hover {
      border: 1px solid #005139;
      background-color: #005139;
      ${color('#ffffff')}
    }
    :focus:not(:active) {
      background-color: #ffffff;
      outline: 1px solid #0fc290;
    }
    :active {
      background: #141d1e;
      ${color('#BEC8C8')}
    }
  `,
  ghost: css`
    ${overline}
    border-radius: 4px;
    background-color: #ffffff;
    ${color('#141D1E')}
    &&:disabled {
      ${color('#BEC8C8')}
      background-color: #ffffff;
      outline: none;
      cursor: default;
    }
    :hover {
      background-color: rgba(0, 136, 98, 0.08);
    }
    :focus:not(:active) {
      background-color: #ffffff;
      outline: 1px solid #0fc290;
    }
    :active {
      background-color: rgba(0, 136, 98, 0.08);
      ${color('#6F797A')}
    }
  `,
  link: css`
    ${body2}
    border-radius: 4px;
    background-color: #ffffff;
    ${color('#008862')}
    &&:disabled {
      ${color('#BEC8C8')}
      outline: none;
      cursor: default;
    }
    :hover {
      ${color('#141D1E')}
    }
    :focus:not(:active) {
      outline: 1px solid #0fc290;
    }
    :active {
      ${color('#005139')}
    }
  `,
  raw: css`
    background-color: transparent;
  `,
  primaryDanger: css`
    ${overline};
    background-color: #ba1b1b;
    border-radius: 4px;
    ${color('#FFFFFF')}
    &&:disabled {
      background-color: #e6ecec;
      ${color('#CEDADA')}
      outline: none;
      opacity: 1;
      cursor: default;
    }
    :hover {
      border: none;
      opacity: 0.84;
    }
    :active {
      background: #141d1e;
      opacity: 1;
      ${color('#CEDADA')}
    }
  `,
} as const;

const buttonSizeStyle: Record<ButtonSize, FlattenSimpleInterpolation> = {
  small: css`
    height: 1.5rem;
    padding: 0 1rem;
  `,
  medium: css`
    height: 2rem;
    padding: 0 1rem;
  `,
  large: css`
    height: 2.25rem;
    padding: 0 1rem;
  `,
  extraLarge: css`
    height: 3rem;
    padding: 0 2rem;
  `,
} as const;

export type BaseButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  gridArea?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  /**
   * Hovering a disabled button in React with onMouseEnter / onMouseLeave is a known issue
   * @link https://github.com/facebook/react/issues/10109
   *
   * I sounds reasonable, but in a very rare case (like tooltiping the disabled button), we need to force hovering events.
   *
   * This prop enables this behavior for that case
   */
  hoverEventsOnDisabled?: boolean;
  $loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const BaseButton = styled.button<BaseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
  border: none;
  cursor: pointer;
  ${({ variant }) => buttonTypeStyle[variant || 'neutral']}
  ${({ size }) => buttonSizeStyle[size || 'medium']}
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}
  ${({ hoverEventsOnDisabled }) => hoverEventsOnDisabled && `pointer-events: none;`}
`;
