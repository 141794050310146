import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AnimationWrapper,
  ContentStyled,
  ImageContainer,
  LayoutWrapper,
  ResultsContainer,
  RootStyled,
  TitleStyled,
} from './styles';
import successImage from './images/success.svg';
import successCheckInSurvey from './images/successCheckInSurvey.svg';
import { PrimaryInformer } from '@/ui/Informer';
import { minutesToRelativeTime } from '@/utils/time';
import { BlockType, ResultBlock, SurveyResultBlock } from '../../../store/block/types';
import { useLottieAnimation, Animation } from '@/ui/Animation';
import { useJourneyEnhancedNavigation } from '@/store/v2/journeys/useJourneyEnhancedNavigation';
import { UpNextLearning } from '@/administration/pages/Journey/common/atoms/UpNextLearning';
import { SurveyVariant } from '@/pages/Survey/store/survey/types';

type ResultVariant = 'COURSE_RESULT' | 'SURVEY_CHECK_IN' | 'SURVEY_CHECK_OUT';

export type ResultPageLayoutProps = {
  id: string;
  block: ResultBlock | SurveyResultBlock;
  isActive: boolean;
};

const TYPE_TO_RESULTS_VISUALS: Record<
  ResultVariant,
  {
    titleKey: string;
    imageSrc: string;
  }
> = {
  COURSE_RESULT: {
    titleKey: 'nice-work',
    imageSrc: successImage,
  },
  SURVEY_CHECK_IN: {
    titleKey: 'success-check-in-survey',
    imageSrc: successCheckInSurvey,
  },
  SURVEY_CHECK_OUT: {
    titleKey: 'success-check-out-survey',
    imageSrc: successImage,
  },
};

const getResultPageVariant = (block: ResultBlock | SurveyResultBlock): ResultVariant => {
  if (block.type === BlockType.RESULT) return 'COURSE_RESULT';

  const surveyBlock = block as SurveyResultBlock;

  return surveyBlock.variant === SurveyVariant.CHECK_IN ? 'SURVEY_CHECK_IN' : 'SURVEY_CHECK_OUT';
};

const CourseResultInformers = ({ block }: { block: ResultBlock }) => {
  const theme = useTheme();
  const { t } = useTranslation('catalog');

  return (
    <ResultsContainer>
      <PrimaryInformer
        title={block.level}
        label={t('level')}
        sideBox={{
          background: theme.colors.tertiary.container,
          icon: ['far', 'bullseye-arrow'],
          size: 'medium',
          iconColor: theme.tones.tertiary[70],
        }}
      />
      <PrimaryInformer
        title={minutesToRelativeTime((block?.effortHours || 0) * 60 + (block?.effortMinutes || 0))}
        label={t('total-time')}
        sideBox={{
          background: theme.tones.primary[95],
          icon: ['far', 'timer'],
          size: 'medium',
          iconColor: theme.tones.primary[70],
        }}
      />
      <PrimaryInformer
        title={t('Completed')}
        label={t('status')}
        sideBox={{
          background: theme.tones.warning[95],
          icon: ['far', 'check'],
          size: 'medium',
          iconColor: theme.tones.warning[40],
        }}
      />
    </ResultsContainer>
  );
};
export const ResultPageLayout = ({ id, block, isActive }: ResultPageLayoutProps): JSX.Element => {
  const { t } = useTranslation('catalog');
  const { id: courseId } = useParams<{ id: string }>();

  const parsedCourseId = courseId ? parseInt(courseId) : NaN;

  const { nextItem, journeyId } = useJourneyEnhancedNavigation({
    id: parsedCourseId,
  });

  const { animationProps } = useLottieAnimation({
    variant: 'confetti-page',
    autoplay: true,
  });

  const resultVariant = getResultPageVariant(block);

  const resultVisuals = TYPE_TO_RESULTS_VISUALS[resultVariant];

  return (
    <>
      {isActive && (
        <LayoutWrapper>
          <RootStyled key={id}>
            <ContentStyled>
              <ImageContainer>
                <img src={resultVisuals.imageSrc} alt={resultVisuals.titleKey} />
                <TitleStyled>{t(resultVisuals.titleKey)}</TitleStyled>
              </ImageContainer>
              {resultVariant === 'COURSE_RESULT' && <CourseResultInformers block={block as ResultBlock} />}
              {nextItem && (
                <UpNextLearning
                  learningCardProps={{
                    objectId: nextItem.contentId,
                    title: nextItem.content?.title,
                    imageUrl: nextItem.content?.imageURL,
                    type: nextItem.content?.metadata?.type,
                    level: nextItem.content?.metadata?.level,
                    totalEffort: nextItem.content?.plannedDuration,
                  }}
                  isActionActive
                  actionHref={`${nextItem.content?.curations[0]?.launchURLPath}}`}
                  parentJourneyId={journeyId}
                />
              )}
            </ContentStyled>
          </RootStyled>
          {animationProps.isPlaying && (
            <AnimationWrapper>
              <Animation {...animationProps} />
            </AnimationWrapper>
          )}
        </LayoutWrapper>
      )}
    </>
  );
};
