import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { changeLanguage } from '@/i18n/i18n';
import { Lng } from '@/i18n/resources';
import { Me } from '@/store/currentUser';
import { useRootContext } from '@/store/RootContext';
import { useUpdateCurrentUser } from '@/store/user';

export const useLanguage = (currentUser: Me | null = null): [(lang: Lng) => void, boolean] => {
  const { t, i18n } = useTranslation('profile');
  const { isAuthenticated } = useRootContext();
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { updateUser, loading } = useUpdateCurrentUser();

  const handleLanguageChange = async (language: Lng) => {
    if (i18n.language === language) {
      return;
    }

    if (isAuthenticated) {
      const { errors } = await updateUser({
        language,
      });
      if (errors) {
        const message = `${t('Saving language failed.')}\n${t('Try again later.')} ${t(
          'If the issue persists, please contact support.'
        )}`;
        return openSnackbar({
          message,
          type: SnackbarType.DANGER,
        });
      }
    }

    await changeLanguage(language);
  };

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.language) {
        handleLanguageChange(i18n.language as Lng);
      } else if (currentUser.language !== i18n.language) {
        changeLanguage(currentUser.language as Lng);
      }
    }
  }, [currentUser]);

  return [handleLanguageChange, loading];
};
