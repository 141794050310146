import React from 'react';

import { ContentStyled, RootStyled } from './styles';
import { usePost } from '@/store/discussion';
import { Reflection } from '@/component/Discussions/Reflection';

export type ViewReflectionPageProps = {
  pageId: number;
  spaceId: number;
  sectionPath?: string[];
  postId?: number;
  title: string;
  description: string;
  onCompleted: (completed: boolean) => void;
};

export const ViewReflectionPage = ({
  pageId,
  sectionPath,
  postId,
  description,
  onCompleted,
}: ViewReflectionPageProps): JSX.Element => {
  const { post: reflection, loading } = usePost(sectionPath || [], postId || 0, !postId || !sectionPath);

  return (
    <RootStyled key={pageId}>
      <ContentStyled>
        {loading && <div className="pageloader" />}

        {!loading && reflection && (
          <Reflection
            path={sectionPath || []}
            reflection={{ ...reflection, title: '', content: description }}
            selected
            onSentPost={() => onCompleted(true)}
            onDeletePost={() => onCompleted(false)}
            expandable
          />
        )}
        {!loading && !reflection && (
          <>Something went wrong while loading reflection. Please try again later. If the issue persists, contact adminstrator.</>
        )}
      </ContentStyled>
    </RootStyled>
  );
};
