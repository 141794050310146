import styled from 'styled-components';

import { Caption } from '@/components/Typography/Typography';

type TagType = 'primary' | 'dark' | 'transparent';
type TagProps = {
  type?: TagType;
};
const handleType = (type?: TagType): { bg: string; color: string } => {
  switch (type) {
    case 'dark':
      return { bg: '--color-black', color: '--color-white' };
    case 'primary':
      return { bg: '--color-primary', color: '--color-white' };
    case 'transparent':
      return { bg: '--color-transparent', color: '--color-mine-shaft' };
    default:
      return { bg: '--color-alto', color: '--color-black' };
  }
};

export const Tag = styled(Caption).attrs((props) => ({ as: 'span', ...props }))<TagProps>`
  background-color: var(${({ type }) => handleType(type).bg});
  border-radius: var(--radius-inner);
  color: var(${({ type }) => handleType(type).color});
  display: inline-block;
  padding: 0 var(--gap-xxs);
  white-space: nowrap;
`;

export const TagHanging = styled(Tag)<{ primary?: boolean; dark?: boolean; right?: boolean; largeSpacingRight?: boolean }>`
  background-color: var(
    ${({ dark, primary }) => {
      if (primary) {
        return '--color-primary';
      } else if (dark) {
        return '--color-black';
      }
      return '--color-secondary';
    }}
  );
  border-radius: 0 0 var(--radius-inner) var(--radius-inner);
  color: var(${({ dark, primary }) => (dark || primary ? '--color-white' : '--color-black')});
  position: absolute;
  top: 0;
  z-index: 20;
  ${({ right, largeSpacingRight }) => `${right ? 'right' : 'left'}: var(${largeSpacingRight ? '--gap-lg' : '--gap-sm'});`}
`;

export const SidebarTag = styled(Caption)`
  color: var(--color-dove-gray);
`;

export const IndentedLeftTagText = styled(Caption)`
  height: 16px;
  left: 20px;
  right: 0px;
  top: calc(50% - 16px / 2);

  /* Collegial-Typography/Body/Body 1 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Collegial-theme/light/on-secondary-container */

  color: #141d1e;

  /* Inside auto layout */

  margin: 0px 4px;
`;

export const IndentedLeftTag = styled(IndentedLeftTagText)<{ primary?: boolean; dark?: boolean }>`
  display: flex;
  padding: 4px 8px;

  width: fit-content;
  height: 24px;
  left: 16px;
  top: 76px;
  border-radius: 4px;
  margin: 10px;
  background: ${({ dark, primary }) => {
    if (primary) {
      return '#FFEDE9';
    } else if (dark) {
      return '#FFB4A9';
    }
    return 'var(--color-secondary)';
  }};
`;
export const WelcomeTagText = styled(Caption)`
  width: 21px;
  height: 16px;
  left: calc(50% - 21px / 2);
  top: calc(50% - 16px / 2);

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;

  /* Collegial-theme/light/on-surface */

  color: #141d1e;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
  margin: 10px 0px;
`;
