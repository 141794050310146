import { mapStackTrace } from 'sourcemapped-stacktrace';

import { http } from './http';
import { config } from '../../config';

type Level = 'info' | 'warning' | 'error';

export function getConsolLogger(level: Level): (...data: any[]) => void {
  switch (level) {
    case 'info': {
      return console.log.bind(console);
    }
    case 'warning': {
      return console.warn.bind(console);
    }
    case 'error': {
      return console.error.bind(console);
    }
  }
}

export function createSendLogToServer(
  _http = http,
  _mapStackTrace = mapStackTrace
): (level: Level, message: string | Error) => void {
  let lastStackTrace: string | undefined = '';
  let lastErrorMessage: string | undefined = '';
  let resetTimeoutId: number;
  return (level: Level, message: string | Error) => {
    const timestamp = new Date();
    let error: Error;
    if (message instanceof Error) {
      error = message;
    } else {
      error = new Error(message);
    }
    if (lastStackTrace === error.stack && lastErrorMessage === error.message) {
      return;
    }

    clearTimeout(resetTimeoutId);
    resetTimeoutId = window.setTimeout(() => {
      lastStackTrace = lastErrorMessage = '';
    }, 100);

    lastStackTrace = error.stack;
    lastErrorMessage = error.message;

    const objectToSend = {
      timestamp,
      stack: error.stack,
      name: error.name,
      message: error.message,
      level,
      userAgent: navigator.userAgent
    };
    _mapStackTrace(error.stack || '', mappedStack => {
      objectToSend.stack = mappedStack;
      _http.post(config.baseApiRoute + '/log', objectToSend).catch(error => console.error('Could not log to server', error));
    });
  };
}

const sendLogToServer = createSendLogToServer();

function createLogger(level: Level, sendToServer: boolean): (message: string | Error) => void {
  const output = getConsolLogger(level);
  return (message: string | Error) => {
    output(message);
    if (sendToServer) {
      sendLogToServer(level, message);
    }
  };
}

export const logger = {
  info: createLogger('info', false),
  warning: createLogger('warning', true),
  error: createLogger('error', true),
  sendErrorToServer(error: Error): void {
    sendLogToServer('error', error);
  }
};
