import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuListOption, MenuListProps } from '@/ui/MenuList/types';
import { LiveEvent } from '@/pages/LiveEvent/store/liveEvent/types';
import { buildIcsFile, CalendarsType, calendarUrl, Event, isInternetExplorer } from '@/component/AddToCalendar/utils';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { convertTitle } from '@/utils/misc';

type UseLiveEventAddToCalendarProps = Pick<
  LiveEvent,
  'id' | 'room' | 'title' | 'teaser' | 'address' | 'endDate' | 'startDate' | 'myEnrollment'
>;

const CALENDAR_TYPES = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
  OUTLOOK365: 'outlook365',
  ICAL: 'ical',
};

const CALENDAR_OPTIONS: MenuListOption[] = [
  {
    icon: 'arrow-right',
    label: 'Google',
    value: CALENDAR_TYPES.GOOGLE,
  },
  {
    icon: 'arrow-right',
    label: 'Microsoft live',
    value: CALENDAR_TYPES.MICROSOFT,
  },
  {
    icon: 'arrow-right',
    label: 'Outlook 365',
    value: CALENDAR_TYPES.OUTLOOK365,
  },
];

const CALENDER_OPTIONS_AFTER_DIVIDER: MenuListOption[] = [
  {
    icon: 'download',
    label: 'iCal',
    value: CALENDAR_TYPES.ICAL,
  },
];

export const useLiveEventAddToCalendar = ({
  id,
  room,
  title,
  teaser,
  address,
  endDate,
  startDate,
  myEnrollment,
}: UseLiveEventAddToCalendarProps): MenuListProps => {
  const { t } = useTranslation('learning');
  const { openSnackbar } = useContext(SharedSnackbarContext);

  const eventData = useMemo(() => {
    return {
      end: endDate,
      title,
      start: startDate,
      teaser: teaser,
      learningId: id,
      learningUrl: document.URL,
      locationRoom: room,
      enrollmentId: (myEnrollment as { id: number })?.id || 0,
      locationAddress: address,
    };
  }, [id, room, title, teaser, address, endDate, startDate, myEnrollment]);

  const handleEarlyExit = () => {
    openSnackbar({
      message: t('serverMessages::generic|:error', { message: '' }),
      type: SnackbarType.DANGER,
    });
  };

  const handleICalClick = () => {
    const iCalData = buildIcsFile(eventData as unknown as Event);

    if (!iCalData) return handleEarlyExit();

    const filename = convertTitle(title);
    const blob = new Blob([iCalData], { type: 'text/calendar;charset=utf-8' });

    openSnackbar({
      message: t('iCal file downloaded successfully.'),
      type: SnackbarType.SUCCESS,
      isDismissive: true,
    });

    if (isInternetExplorer()) {
      window.navigator.msSaveOrOpenBlob(blob, `${filename}.ics`);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', `${filename}.ics`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleWebCalendarClick = (value: string) => {
    const href = calendarUrl({ calendar: value as CalendarsType, event: eventData as unknown as Event });

    if (!href) return handleEarlyExit();

    window.open(href, '_blank');
  };

  const handleOptionClick = (value: string) => {
    if (value === CALENDAR_TYPES.ICAL) {
      handleICalClick();
    } else {
      handleWebCalendarClick(value);
    }
  };

  return {
    options: CALENDAR_OPTIONS,
    buttonLabel: 'Add to calendar',
    optionsAfterDivider: CALENDER_OPTIONS_AFTER_DIVIDER,
    onOptionClick: handleOptionClick,
  } as MenuListProps;
};
