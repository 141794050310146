import { Statement } from '@/store/xapi/model';
import { XAPISession } from './useXAPISession';

export const xapiClient = {
  postStatement(session: XAPISession, statements: Statement[]): Promise<void> {
    return fetch(`${session.url}/statements`, {
      method: 'POST',
      body: JSON.stringify(statements),
      headers: {
        Authorization: session.authHeader,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(handleResponse);
  },
  setState<T>(session: XAPISession, stateId: string, body: T): Promise<void> {
    const params = new URLSearchParams({
      activityId: session.activityId,
      agent: JSON.stringify(session.actor),
      stateId,
      registration: session.registration,
    });

    return fetch(`${session.url}/activities/state?${params.toString()}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: session.authHeader,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(handleResponse);
  },
  getState<T>(session: XAPISession, stateId: string): Promise<T | undefined> {
    const params = new URLSearchParams({
      activityId: session.activityId,
      agent: JSON.stringify(session.actor),
      stateId,
      registration: session.registration,
    });

    return fetch(`${session.url}/activities/state?${params.toString()}`, {
      method: 'GET',
      headers: {
        Authorization: session.authHeader,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then((res) => {
      if (res.ok) return res.json();

      return undefined;
    });
  },
};

export async function handleResponse(response: Response): Promise<any> {
  if (!response.ok) {
    try {
      const errorText = (await response.text()) || response.statusText;
      return Promise.reject(new Error(errorText));
    } catch (e) {
      return Promise.reject(new Error(response.statusText));
    }
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') > -1) {
    return response.json();
  } else {
    return response.text();
  }
}
