import React, { FC, MouseEvent, useRef } from 'react';
import { Editor } from 'slate';

import { Block } from '@/component/customEditor/types';
import { useElements } from '@/component/customEditor/hooks/useElements';
import { LinkSection } from './LinkSection';
import { IconPropType } from '@/ui/Icon';
import { Button } from '@/components/Button/Button';
import { CustomSlateElementType } from '@/types/slate';
import { ImageSection } from './ImageSection';

interface ElementSectionProps {
  editor: Editor;
  isLinkPopoverOpen?: boolean;
  setIsLinkPopoverOpen?: (isOpened: boolean) => void;
  hideLink?: boolean;
  spaceId?: number;
  supportsImages?: boolean;
}

export const ElementSection: FC<ElementSectionProps> = ({
  isLinkPopoverOpen,
  setIsLinkPopoverOpen,
  hideLink,
  spaceId,
  supportsImages,
}) => {
  const { editor, toggleBlock, isBlockActive } = useElements();
  const fileInput = useRef<HTMLInputElement>(null);

  const onMarkClick = (e: MouseEvent, type: CustomSlateElementType) => {
    e.preventDefault();
    toggleBlock(type);
  };

  const onLinkClick = (e: MouseEvent) => {
    e.preventDefault();
    setIsLinkPopoverOpen && setIsLinkPopoverOpen(true);
  };

  const onLinkPopoverClose = () => {
    setIsLinkPopoverOpen && setIsLinkPopoverOpen(false);
  };

  const renderBlockButton = (type: Block, label: IconPropType): JSX.Element => (
    <Button
      $type="base"
      $icon={label}
      $toggled={isBlockActive(type)}
      onMouseDown={(event) => {
        if (type === 'link') {
          onLinkClick(event);
        } else if (type === 'image') {
          event.preventDefault();

          fileInput.current && fileInput.current.click();
        } else {
          onMarkClick(event, type);
        }
      }}
      tabIndex={0}
    />
  );

  return (
    <>
      {renderBlockButton('heading', 'h1')}
      {renderBlockButton('heading2', 'h2')}
      {renderBlockButton('bulleted-list', 'list-ul')}
      {renderBlockButton('ordered-list', 'list-ol')}
      {!hideLink && (
        <LinkSection editor={editor} onClose={onLinkPopoverClose} isOpened={isLinkPopoverOpen}>
          {renderBlockButton('link', 'link')}
        </LinkSection>
      )}
      {supportsImages && (
        <>
          {renderBlockButton('image', ['far', 'image'])}
          <ImageSection editor={editor} spaceId={spaceId} ref={fileInput} />
        </>
      )}
    </>
  );
};
