import { useCallback } from 'react';
import { NetworkStatus } from '@apollo/client';

import { toHtml } from '@/component/customEditor/logic/serialization';
import { useCurrentUser } from '@/store/currentUser';
import { StrategicAssignmentDetails, StrategicAssignmentWithProgress, UseStrategicAssignmentHookResult } from './types';
import { transformToWeeklyLearningLoop } from './utils/transformToWeeklyLearningLoop';
import { formatMMMD } from '@/utils/dates';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import { useLearningProgress } from './useLearningProgress';
import { useAssignmentUsers } from '@/store/assignments';

import { useCreateLearningLoopReflection, useLearningLoops } from '.';

export const useStrategicAssignment = (assignment: StrategicAssignmentWithProgress): UseStrategicAssignmentHookResult => {
  const { user } = useCurrentUser();
  const {
    data: loops,
    loading: learningLoopsLoading,
    error,
    refetch: refetchLearningLoops,
    networkStatus: learningLoopsNetworkStatus,
  } = useLearningLoops({ assignmentId: assignment.id, userId: user?.id });

  const {
    data: usersData,
    loading: loadingUsers,
    error: errorUsers,
  } = useAssignmentUsers({ assignmentId: assignment.id, currentUserOnTop: true });

  const {
    data: learningProgress,
    loading: learningProgressLoading,
    error: learningProgressError,
    refetch: refetchProgress,
    networkStatus: learningProgressNetworkStatus,
  } = useLearningProgress({ loops: loops ?? [] });

  const refetchInProgress = [learningLoopsNetworkStatus, learningProgressNetworkStatus].some(
    (status) => status === NetworkStatus.refetch
  );

  const learningLoops = loops ? transformToWeeklyLearningLoop(loops) : [];
  const reflection = useCreateLearningLoopReflection();

  const loading = learningLoopsLoading || reflection.loading || learningProgressLoading || loadingUsers;
  const initialLoading = loading && !refetchInProgress;

  const description = toHtml(assignment.description);
  const startAt = formatMMMD(new Date(assignment.startAt));
  const endAt = formatMMMD(new Date(assignment.endAt));

  const data: StrategicAssignmentDetails = {
    ...assignment,
    surveyId: assignment.surveyId ?? '',
    startAt,
    startAtFullTimestamp: assignment.startAt,
    endAt,
    endAtFullTimestamp: assignment.endAt,
    teaser: assignment?.teaser ?? '',
    assignmentType: assignment?.type,
    description,
    learnings: assignment?.learnings?.data,
    weeks: learningLoops,
    totalHoursRequired: formatToHoursAndMinutesFromSeconds(assignment.totalThresholdEffort),
    participations: loops?.flatMap((x) => x.activitiesAggregated).map((m) => m.participation) ?? [],
    progress: learningProgress,
    users: usersData
      ? {
          total: usersData.total,
          participants: usersData.users.map(({ id, email, profileImage, username }) => ({
            id,
            email,
            profileImage,
            name: username,
          })),
        }
      : undefined,
    reflectionsCompleted: loops?.filter((x) => !!x.reflection).length ?? 0,
    weeklyGoalReached: loops?.filter((x) => x.estimatedEffort >= x.recommendedEffort).length ?? 0,
    goalLimitCount: loops?.length ?? 0,
  };

  const refetch = useCallback(() => {
    refetchLearningLoops();
    refetchProgress();
  }, [refetchLearningLoops, refetchProgress]);

  return {
    assignment: initialLoading ? undefined : data,
    error: error || learningProgressError || errorUsers,
    loading,
    refetch,
    reflection: {
      create: reflection.createLearningLoopReflection,
      loading: reflection.loading,
      error: false,
    },
  };
};
