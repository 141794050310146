import { Nominal } from './nominal';
import { Activity, activityDefinitionFromName, ActivityDefinitionType } from './activity';
import { Actor } from './actor';
import { StatementRef } from './statementRef';

// https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#object
export type XObject = Nominal<Activity | Actor | StatementRef, 'XAPIObject'>;

export const objectActivityFromIdAndName = (objectId: string, objectName: string, type?: ActivityDefinitionType): XObject =>
  <XObject>{
    id: objectId,
    objectType: 'Activity',
    definition: activityDefinitionFromName('en', objectName, type),
  };
