/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';
import { useFormContext, Validate } from 'react-hook-form';

import { RootStyled, TimeContainerStyled } from './styles';
import { Hours } from './Hours';
import { LearningTitle } from './LearningTitle';
import { LearningLink } from './LearningLink';
import { Minutes } from './Minutes';
import { LearningDescription } from './LearningDescription';
import { LearningType } from './LearningType';
import { TextFieldName } from '../../../constants';

type FormProps = {
  onSubmit: () => void;
};

export const Form = ({ onSubmit }: FormProps): JSX.Element => {
  const {
    control,
    getValues,
    trigger,
    watch,
    formState: { isSubmitted },
  } = useFormContext();

  /**
   * Validation background
   *
   * Should PASS when:
   *
   * 1. Hours > 0 and minutes = 0 or undefined
   * 2. Minutes > 0 and hours = 0 or undefined
   * 3. Both hours and minutes defined and > 0
   *
   * Why do we want that and don't just mark both fields as required?
   *
   * Because UX is better, when user can submit the form, when he just want to trach 2 minutes OR 2 hours,
   * whithout having to fill hours inputs or minutes input with 0 accordingly
   */
  const validateTimeTextField: Validate<string> = () => {
    const [hoursValue = '0', minutesValue = '0'] = getValues([TextFieldName.Hours, TextFieldName.Minutes]);

    return Number(hoursValue) > 0 || Number(minutesValue) > 0;
  };

  /**
   * ReValidation background
   *
   * 1. We want to trigger validation of minutes & hours inputs,
   * when they are changed, but only when the user submitted the form at least 1 time
   * 2. We need it, because each of those inputs validation is depandant on the other's value as well as on it's own value
   */
  useEffect(() => {
    const revalidateTimeTextFields = async () => {
      await trigger(TextFieldName.Hours);
      await trigger(TextFieldName.Minutes);
    };

    if (isSubmitted) {
      revalidateTimeTextFields();
    }
  }, [watch([TextFieldName.Hours, TextFieldName.Minutes]), isSubmitted]);

  return (
    <form onSubmit={onSubmit}>
      <RootStyled>
        <LearningType control={control} />
        <LearningTitle control={control} />
        <TimeContainerStyled>
          <Hours control={control} validate={validateTimeTextField} />
          <span>:</span>
          <Minutes control={control} validate={validateTimeTextField} />
        </TimeContainerStyled>
        <LearningDescription control={control} />
        <LearningLink control={control} />
      </RootStyled>
    </form>
  );
};
