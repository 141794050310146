import { useEffect, useState } from 'react';

import { useGetLearningXapiSessionQueryRemote, useRegisterToLearningMutationRemote } from '@/store/v2';

export type XAPISession = {
  url: string;
  authHeader: string;
  actor: {
    objectType: 'Agent';
    account: {
      homePage: 'https://collegial.com';
      name: string;
    };
  };
  registration: string;
  activityId: string;
};

export const useXAPISession = (
  learningId?: number
): {
  session?: XAPISession;
  loadingSession: boolean;
  loadingRegister: boolean;
  errorSession?: Error;
  refetchSession: () => Promise<void>;
  register: () => void;
} => {
  const {
    data: sessionData,
    error: sessionError,
    loading: sessionLoading,
    refetch,
  } = useGetLearningXapiSessionQueryRemote({
    variables: { learningId: learningId || 0 },
    skip: !learningId,
    fetchPolicy: 'network-only',
  });
  const [registerRemote, { loading: registerLoading }] = useRegisterToLearningMutationRemote();
  const [session, setSession] = useState<XAPISession>();

  const register = async () => {
    if (!learningId) return;

    await registerRemote({ variables: { learningId } });
  };

  useEffect(() => {
    if (!sessionData?.getXAPISession?.queryString) return setSession(undefined);
    const params = new URLSearchParams(sessionData.getXAPISession.queryString);

    setSession({
      url: params.get('endpoint') || '',
      actor: JSON.parse(params.get('actor') || ''),
      authHeader: params.get('auth') || '',
      registration: params.get('registration') || '',
      activityId: params.get('activity_id') || '',
    });
  }, [sessionData]);

  return {
    session,
    loadingSession: sessionLoading,
    loadingRegister: registerLoading,
    errorSession: sessionError,
    refetchSession: async () => {
      await refetch();
    },
    register,
  };
};
