import styled from 'styled-components';

import { EndedWeeklyLoopChart } from '../LoopChart/variants/EndedLoopChart';
import { WeeklyLoopChart } from '../LoopChart/variants/WeeklyLoopChart';

export const Banner = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral.container};
  margin-bottom: 40px;
`;

export const FlexedWeeklyChart = styled(WeeklyLoopChart)`
  display: flex;
`;

export const FlexedEndedWeeklyChart = styled(EndedWeeklyLoopChart)`
  display: flex;
`;
