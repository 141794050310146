/* eslint-disable filenames/no-index */
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { parse } from 'qs';

import './i18n/i18n';
import '@/config/Zod';
import { App } from './component/App/App';
import { logger } from './utils/lib/logger';
import { CustomApolloProvider } from './store/CustomApolloProvider';
import { SharedSnackbarProvider } from './component/SharedSnackbar/SharedSnackbar';
import { RootContextProvider } from './store/RootContext';
import { ModalProvider } from './components/Modal/ModalContext';

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blob: Blob, fileName: string) => boolean;
  }
}

window.addEventListener('error', (event) => {
  logger.sendErrorToServer(event.error);
});

// IE doesn't have the origin property
if (!window.location.origin) {
  // @ts-expect-error assign to const
  window.location.origin =
    window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

// Parse querystring
const query = parse(window.location.search.substring(1));

const forceLoginMethod = query['login'];
if (forceLoginMethod === 'sso' || forceLoginMethod === 'external') {
  window.FORCE_LOGIN_METHOD = forceLoginMethod;
}

const ssoReturnUrl = query['ssoReturnUrl'];
if (ssoReturnUrl) {
  window.SSO_RETURN_URL = ssoReturnUrl;
}

const forceRealm = query['forceRealm'];
if (forceRealm) {
  console.warn('Force set realm to: ' + forceRealm);
  window.realm = forceRealm;
}

console.log('=====================\n' + 'Welcome to Collegial!\n' + '=====================');

render(
  <BrowserRouter>
    <ModalProvider>
      <SharedSnackbarProvider>
        <CustomApolloProvider>
          <Suspense fallback={<div className="pageloader pageloader--full" />}>
            <RootContextProvider>
              <App />
            </RootContextProvider>
          </Suspense>
        </CustomApolloProvider>
      </SharedSnackbarProvider>
    </ModalProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
