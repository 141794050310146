import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { BaseButton, BaseButtonProps } from './styles/button';
import { IconPosition, StyledIcon, StyledIndeterminateCircularProgressBar } from './styles/icon';
import { IconPropType } from '@/ui/Icon';
import { LinkProps as BaseCSVButtonProps } from '@/components/Button/CSVLinkButton';

export type DefaultButtonProps = BaseButtonProps & {
  icon?: IconPropType;
  $iconPosition?: IconPosition;
};

type UrlLinkProps = DefaultButtonProps & {
  href: string;
  download?: string;
  target?: string;
  rel?: string;
};

type RouterLinkButtonProps = DefaultButtonProps & {
  to: LinkProps['to'];
  target?: string;
  rel?: string;
};

type CSVButtonProps = DefaultButtonProps & BaseCSVButtonProps;

export const DefaultButton = (props: DefaultButtonProps | UrlLinkProps | RouterLinkButtonProps | CSVButtonProps): JSX.Element => {
  const urlLinkProps = props as UrlLinkProps;
  const routerLinkProps = props as RouterLinkButtonProps;
  const defaultProps = props as DefaultButtonProps;

  if (!!props.$loading) {
    const { children, ...rest } = props;
    return (
      <BaseButton {...rest} disabled>
        <StyledIndeterminateCircularProgressBar size={16} />
        {children}
      </BaseButton>
    );
  } else if (!!urlLinkProps.href) {
    const { icon, $iconPosition, children, ...rest } = urlLinkProps;
    return (
      <BaseButton as="a" {...rest}>
        {icon && $iconPosition !== 'end' && <StyledIcon icon={icon} $iconPosition={$iconPosition || 'start'} />}
        {children}
        {icon && $iconPosition === 'end' && <StyledIcon icon={icon} $iconPosition={$iconPosition} />}
      </BaseButton>
    );
  } else if (!!routerLinkProps.to) {
    const { icon, $iconPosition, children, ...rest } = routerLinkProps;
    return (
      <BaseButton as={Link} {...rest}>
        {icon && $iconPosition !== 'end' && <StyledIcon icon={icon} $iconPosition={$iconPosition || 'start'} />}
        {children}
        {icon && $iconPosition === 'end' && <StyledIcon icon={icon} $iconPosition={$iconPosition} />}
      </BaseButton>
    );
  } else {
    const { icon, $iconPosition, children, ...rest } = defaultProps;
    return (
      <BaseButton {...rest}>
        {icon && $iconPosition !== 'end' && <StyledIcon icon={icon} $iconPosition={$iconPosition || 'start'} />}
        {children}
        {icon && $iconPosition === 'end' && <StyledIcon icon={icon} $iconPosition={$iconPosition} />}
      </BaseButton>
    );
  }
};
