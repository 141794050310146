import styled from 'styled-components';

import { ImageWithLoader } from '@/ui/ImageWithLoader/ImageWithLoader';
import { variant } from './sizing';
import fallbackSrc from './ImagePlaceholder.png';

export const Image = styled(ImageWithLoader).attrs({ fallbackSrc })`
  transition: transform ${p => p.theme.transitions.normal}ms;
  object-fit: cover;
  
  ${variant.small} {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 100%;
    transform-origin: right center;
  }
  
  ${variant.large} {
    width: 100%;
    height: 155px;
    margin-bottom: 16px;
    transform-origin: bottom center;
  }
`;
