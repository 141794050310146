import { useEffect } from 'react';

import { LearningSubject } from '@/types/learning';
import { sortByProp } from '@/utils/sort';

export function useCategoriesAndSubjects({
  categories,
  subjects,
  selectedCategory,
  selectedSubject,
  setCategory,
  setSubject
}: {
  categories: LearningSubject[];
  subjects: LearningSubject[];
  selectedCategory?: number;
  selectedSubject?: number;
  setCategory: (categoryId?: number) => void;
  setSubject: (subjectId?: number) => void;
}): {
  availableCategories: LearningSubject[];
  availableSubjects: LearningSubject[];
} {
  const selectedCategoryData = categories.find(category => category.categoryId === selectedCategory);
  const availableSubjects = !selectedCategoryData
    ? subjects
    : subjects.filter(subject => subject.categoryId === selectedCategoryData?.categoryId);

  useEffect(() => {
    if (selectedSubject) {
      const selectedSubjectData = subjects.find(subject => subject.subjectId === selectedSubject);
      setCategory(selectedSubjectData?.categoryId);
    }
  }, [selectedSubject, subjects]);

  useEffect(() => {
    if (!selectedCategory) setSubject(undefined);
  }, [selectedCategory]);

  return {
    availableCategories: categories.sort((a, b) => sortByProp(a, b, 'category')),
    availableSubjects: availableSubjects.sort((a, b) => sortByProp(a, b, 'subject'))
  };
}
