import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { BadgeLabel } from '@/ui/Label';
import { makeNamespacedComponent, mediaQuery } from '@/ui/utils';
import { variant } from './sizing';

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${variant.small} {
    margin: ${(p) => (!!p.children ? '8px 24px' : 0)};
  }

  ${variant.large} {
    margin: ${(p) => (!!p.children ? '8px 16px 16px 16px' : 0)};
  }

  ${mediaQuery.until('mobile')} {
    display: none;
  }
`;

const ColoredLabels = {
  Recommended: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.tertiary[95]};
  `,
  Mandatory: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.error[95]};
  `,
  Featured: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.primary[95]};
  `,
  Upcoming: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.neutral[95]};
  `,
  Strategic: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.warning[95]};
  `,
  Expired: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.colors.error.main};
    & p {
      color: ${(p) => p.theme.colors.error.onMain};
    }
  `,
  ExpiresInDays: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.tones.warning[95]};
  `,
  PendingApproval: styled(BadgeLabel)`
    background-color: ${(p) => p.theme.colors.neutral.container};
  `,
};

const RecommendedLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('Recommended');
  return <ColoredLabels.Recommended badgeVariant="badge-text" text={label} />;
};

const MandatoryLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('Required');
  return <ColoredLabels.Mandatory badgeVariant="badge-text" text={label} />;
};

const FeaturedLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('Featured');
  return <ColoredLabels.Featured badgeVariant="badge-text" text={label} />;
};

const UpcomingLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('upcoming');
  return <ColoredLabels.Upcoming badgeVariant="badge-text" text={label} />;
};

const StrategicLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('Strategic');
  return <ColoredLabels.Strategic badgeVariant="badge-text" text={label} />;
};

const ExpiredLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('expired');
  return <ColoredLabels.Expired badgeVariant="badge-text" text={label} />;
};

const ExpiresInXDaysLabel = ({ daysCount, multipleDays }: { daysCount: number; multipleDays?: boolean }): JSX.Element => {
  const label = useTranslation('catalog').t(multipleDays ? 'expires_in_n_days_plural' : 'expires_in_n_days_singular', {
    count: daysCount,
  });
  return <ColoredLabels.ExpiresInDays badgeVariant="badge-text" text={label} />;
};

const PendingApprovalLabel = (): JSX.Element => {
  const label = useTranslation('catalog').t('Pending approval');
  return <ColoredLabels.PendingApproval badgeVariant="badge-text" text={label} />;
};

const LabelCollection = makeNamespacedComponent(Labels, {
  Recommended: RecommendedLabel,
  Mandatory: MandatoryLabel,
  Featured: FeaturedLabel,
  Upcoming: UpcomingLabel,
  Strategic: StrategicLabel,
  Expired: ExpiredLabel,
  ExpiresInXDays: ExpiresInXDaysLabel,
  PendingApproval: PendingApprovalLabel,
});

export { LabelCollection as Labels };
