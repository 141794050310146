import { ReactNode } from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { RootStyled, NavStyled, ContentRootStyled } from '@/administration/pages/Course/MainLayoutDeprecated/atoms/Layout/styles';
import { ViewNavbar } from '@/administration/pages/Course/MainLayoutDeprecated/atoms/Navbar';
import { Skeleton } from '@/ui/Skeleton';

type LayoutProps = {
  title: string;
  onAddFavoritesClick?: () => void;
  onCloseClick: () => void;
  content: ReactNode;
  isSavedToMyLearning?: boolean;
};
export const _Layout = ({ title, content, onAddFavoritesClick, onCloseClick, isSavedToMyLearning }: LayoutProps): JSX.Element => {
  return (
    <div>
      <RootStyled>
        <NavStyled>
          <ViewNavbar
            onSaveFavoritesClick={onAddFavoritesClick}
            onCloseClick={onCloseClick}
            title={title}
            isSavedToMyLearning={isSavedToMyLearning}
          />
        </NavStyled>
        <ContentRootStyled drawerOpen={false}>{content}</ContentRootStyled>
      </RootStyled>
    </div>
  );
};

const LoadingState = (): JSX.Element => (
  <div>
    <RootStyled>
      <NavStyled>
        <ViewNavbar.LoadingState />
      </NavStyled>
      <ContentRootStyled drawerOpen={false}>
        <Skeleton />
      </ContentRootStyled>
    </RootStyled>
  </div>
);

export const ModularLearningStartLayout = makeNamespacedComponent(_Layout, {
  LoadingState,
});
