import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';

const RootStyled = styled.div`
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InformerStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const IconBoxStyled = styled.div`
  background: ${({ theme }) => theme.colors.tertiary.container};
  padding: 16px;
  border-radius: 8px;
  width: fit-content;

  & path {
    fill: ${({ theme }) => theme.colors.tertiary.main};
  }
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const DiscusssionsBox = (): JSX.Element => {
  return (
    <RootStyled>
      <TitleStyled>Discussion channels</TitleStyled>
      <InformerStyled>
        <IconBoxStyled>
          <Icon icon={['far', 'comments']} size="2x" />
        </IconBoxStyled>
        <div>
          Connect and share resources in Discussion channels - your central hub for this Journey’s discussions and support.
        </div>
      </InformerStyled>
      <Button variant="primary" as={Link} to={'discussions'}>
        Go to discussion
      </Button>
    </RootStyled>
  );
};

const LoadingState = () => <Skeleton height={250} />;

const _DiscusssionsBox = makeNamespacedComponent(DiscusssionsBox, {
  LoadingState,
});

export { _DiscusssionsBox as DiscusssionsBox };
