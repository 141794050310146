import styled, { css } from 'styled-components';

import { Button } from '@/ui/Button';
import { BadgeLabel } from '@/ui/Label/variants/BadgeLabel';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  height: 40px;
  margin-bottom: 40px;
`;

export const Title = styled.p`
  ${(p) => p.theme.typography.title3}
  color: ${(p) => p.theme.colors.neutral.onBackground};
`;

export const Badge = styled(BadgeLabel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 24px;
`;

//NEED TO FIX THE BUTTONS
export const FilterButton = styled(Button)`
  padding: 0px;
`;

export const DisabledOverlayStyled = styled.div<{ hasAccess: boolean }>`
  ${({ hasAccess }) =>
    !hasAccess &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const LockedItemsBannerStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background-color: ${({ theme }) => theme.colors.neutral.container};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  padding: 12px 0;
  border-radius: 4px;
  margin-bottom: 40px;
`;
