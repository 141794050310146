import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useParentJourney } from '@/store/v2/journeys/useParentJourneyId';
import { Button } from '@/ui/Button';
import { convertTitle } from '@/utils/misc';

export const BackToJourney = (): JSX.Element | null => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const parsedLearningId = id ? parseInt(id) : NaN;

  const parentJourney = useParentJourney({
    id: parsedLearningId,
  });

  const handleClick = () => {
    navigate(`/journey/${parentJourney?.journeyId}/${convertTitle(parentJourney?.journeyTitle)}`);
  };

  return parentJourney?.journeyId && parentJourney?.journeyTitle ? (
    <Button variant="ghost" onClick={handleClick} icon="long-arrow-alt-left">
      Back to journey
    </Button>
  ) : null;
};
