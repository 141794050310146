import { useRef, useEffect } from 'react';

type IntervalFunction = () => unknown | void;

/**
 * React wrapper for setInterval using refs to allow callbacks with the access to component state & props
 *
 * Honestly stolen from Dan Abramov from @link https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback Callback function to be interval'ed
 * @param delay If number - the interval delay in ms, if null - a flag for the inverval to be paused
 */
export const useInterval = (callback: IntervalFunction, delay: number | null): void => {
  const savedCallback = useRef<IntervalFunction>(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
