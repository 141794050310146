import { useMemo } from 'react';
import { createEditor, Editor } from 'slate';
import { withReact, ReactEditor } from 'slate-react';

import { EditorConfig } from '@/component/customEditor/types';
import { withLinks } from '@/component/customEditor/hoc/withLinks';
import { withImages } from '@/component/customEditor/hoc//withImages';

export const useEditor = (config?: EditorConfig): Editor & ReactEditor => {
  const getEditor = () => {
    const disableLinks = config && config?.excludeBlocks.includes('link');

    if (disableLinks) {
      return withReact(createEditor());
    }

    return withLinks(withImages(withReact(createEditor())));
  };

  return useMemo(() => getEditor(), []);
};
