export enum OnboardingStep {
  Hello = 'Hello',
  LFTerms = 'LF-Terms',
  Terms = 'Terms',
  ExtraTerms = 'ExtraTerms',
  Profile = 'Profile',
  FinalStep = 'FinalStep',
}

type StepComponentProps = {
  onReady: (completedStep: OnboardingStep, ready?: boolean) => void;
  onSave: (step: OnboardingStep, saveFn: () => void | Promise<unknown>) => void;
};
export type StepComponent = (props: StepComponentProps) => JSX.Element;

export type StepState = {
  step: OnboardingStep;
  /**
   * Whether all required actions (eg. accepting terms & conditions) have been completed
   * and the user can move forward to the next step
   */
  ready: boolean;
  /** Whether the step can be activated (ie. all previous steps have been completed) */
  enabled: boolean;
};
