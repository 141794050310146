import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Composition } from 'atomic-layout';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { NestedDropdown } from '@/components/Dropdown/components/NestedDropdown/NestedDropdown';
import { NestedDropdownWrapper } from '@/components/Dropdown/components/NestedDropdown/NestedDropdownWrapper';
import { NestedDropdownFooterButton } from './styles';
import { useDropdown } from '@/hooks/useDropdown';
import { useLanguages } from '@/store/languages';
import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { Header4 } from '@/components/Typography/Typography';
import { LearningSubject } from '@/types/learning';
import { FilterType } from '../../CardGrid';
import { Input } from '@/components/Form/components/Input/Input';
import { useDurationOptions, useLearningTypeOptions, useProviderOptions, useAttributeOptions } from '../../hooks/optionHooks';
import { useFilterTracking } from '../../hooks/useFilterTracking';
import { FormAddons } from '@/components/FormAddons';
import { CARDFILTER_PAGETYPE } from '../../CardFilter';
import { useOnEscapePress } from '@/hooks/useOnEscapePress';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { BUTTON_HEIGHT_ENUM } from '@/components/Button/components/BaseButton';
import { AvailableMeta } from '../../hooks/useAvailableMeta';
import { MultiSelect } from '@/components/Form/components/MultiSelect/MultiSelect';
import { styleConfig } from '@/styles/GlobalStyle';
import { areas } from './constants';
import { Button } from '@/components/Button/Button';

type CardFilterBodyProps = {
  title?: string;
  filterTypes: [FilterType, ...FilterType[]];
  subjects: LearningSubject[];
  categories: LearningSubject[];
  pageType: CARDFILTER_PAGETYPE;
  availableMeta: AvailableMeta;
};

export const CardFilterBody = ({
  title,
  filterTypes,
  subjects = [],
  categories = [],
  availableMeta,
  pageType,
}: CardFilterBodyProps): JSX.Element => {
  const { t } = useTranslation(['common', 'catalog', 'categories', 'subjects', 'languages']);
  const [positionFilterMenuToLeft, setPositionFilterMenuToLeft] = useState<boolean>(false);
  const isAttributeFilterEnabled = useFeatureEnabled(FEATURE.UI_ATTRIBUTE_FILTER_CATALOG);
  const isCategorySubjectHidden = useFeatureEnabled(FEATURE.UI_CATEGORY_SUBJECT_HIDDEN);

  const {
    containerRef: moreFiltersRef,
    open: openMoreFilters,
    close: closeMoreFilters,
    isOpen: isMoreFiltersOpen,
  } = useDropdown<HTMLDivElement>();
  const { languages } = useLanguages();
  const { getValues, reset } = useFormContext();

  useEffect(() => {
    const filterMenuRef = moreFiltersRef.current?.querySelector('[role="listbox"]');
    const filterMenuRightCoordinates = filterMenuRef?.getBoundingClientRect()?.right ?? 0;
    setPositionFilterMenuToLeft(filterMenuRightCoordinates > window.innerWidth);
  }, [isMoreFiltersOpen]);

  const { trackCategory, trackSubject, trackType, trackDuration, trackProvider, trackTitle, trackLanguage, trackAttributes } =
    useFilterTracking(categories, subjects);
  // The so called hook is a simple method that returns value without any relations, so suppress error for better life
  // to not rebuild the options on every re-render of view.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const attributeOptions = useMemo(() => useAttributeOptions(), []);
  const notEventsPage = pageType !== CARDFILTER_PAGETYPE.EVENTS;
  const durationOptions = useDurationOptions(availableMeta.durations);
  const learningTypeOptions = useLearningTypeOptions(availableMeta.types);
  const providerOptions = useProviderOptions(availableMeta.providers);

  const moreFilterSelectedValueCounter = [getValues('language'), ...getValues('attributes')].filter((f) => f).length;

  const categorySideEffect = useCallback((value: number) => {
    trackCategory(value);
    // Reset subject after category change
    reset({ ...getValues(), subject: undefined });
  }, []);

  const clearAttributeFilters = () => {
    reset({ ...getValues(), language: undefined, attributes: [] });
    closeMoreFilters();
  };

  const moreFiltersBlur = (e?: KeyboardEvent) => {
    if (e instanceof KeyboardEvent && e.currentTarget instanceof HTMLElement) e.currentTarget?.blur();
    closeMoreFilters();
  };

  useOnClickOutside(isMoreFiltersOpen, closeMoreFilters, moreFiltersRef);
  useOnEscapePress(moreFiltersBlur, [moreFiltersBlur]);

  return (
    <Composition
      areas={areas[pageType].default}
      areasMd={areas[pageType].md}
      areasLg={areas[pageType].lg}
      areasXl={areas[pageType].xl}
      gap={styleConfig.gap.xs}
      gapMd={styleConfig.gap.md}
      paddingBottom={styleConfig.gap.md}
    >
      {({ Title, Filtermeta, Filtertaxonomy, Filtertitle, Filterattributes }) => (
        <>
          {title && (
            <Title>
              <Header4 unpadded style={{ whiteSpace: 'nowrap', lineHeight: `${BUTTON_HEIGHT_ENUM.default}rem` }}>
                {title}
              </Header4>
            </Title>
          )}
          {filterTypes.includes('meta') && (
            <Filtermeta>
              <FormAddons>
                <MultiSelect
                  name="type"
                  label={t('Type', { ns: 'catalog' })}
                  placeholder={t('Choose...', { ns: 'common' })}
                  aria-label={t('Filter by Type', { ns: 'catalog' })}
                  handleSideEffect={trackType}
                  options={learningTypeOptions}
                />
                {notEventsPage && ( // Separate checks so <FormAddons> can calculate correct number of children
                  <MultiSelect
                    name="duration"
                    label={t('Duration', { ns: 'catalog' })}
                    placeholder={t('Choose...', { ns: 'common' })}
                    aria-label={t('Filter by Duration', { ns: 'catalog' })}
                    handleSideEffect={trackDuration}
                    options={durationOptions}
                  />
                )}
                {notEventsPage && ( // Separate checks so <FormAddons> can calculate correct number of children
                  <MultiSelect
                    name="provider"
                    label={t('Provider', { ns: 'catalog' })}
                    placeholder={t('Choose...', { ns: 'common' })}
                    aria-label={t('Filter by Provider', { ns: 'catalog' })}
                    handleSideEffect={trackProvider}
                    options={providerOptions}
                  />
                )}
              </FormAddons>
            </Filtermeta>
          )}
          {filterTypes.includes('taxonomy') && subjects && !isCategorySubjectHidden && (
            <Filtertaxonomy>
              <FormAddons>
                <MultiSelect
                  name="category"
                  valueAsNumber
                  label={t('Category', { ns: 'common' })}
                  placeholder={t('Choose...', { ns: 'common' })}
                  handleSideEffect={categorySideEffect}
                  options={categories.map((sub: LearningSubject) => ({
                    value: sub.categoryId,
                    label: t(sub.category, { ns: 'categories' }),
                  }))}
                />
                <MultiSelect
                  name="subject"
                  valueAsNumber
                  label={t('Subject', { ns: 'common' })}
                  placeholder={t('Choose...', { ns: 'common' })}
                  handleSideEffect={trackSubject}
                  options={subjects?.map((sub: LearningSubject) => ({
                    value: sub.subjectId,
                    label: t(sub.subject, { ns: 'subjects' }),
                  }))}
                />
              </FormAddons>
            </Filtertaxonomy>
          )}
          {filterTypes.includes('title') && (
            <Filtertitle>
              <Input
                name="title"
                onChange={trackTitle}
                hiddenLabel
                label={t('Filter by title', { ns: 'catalog' })}
                placeholder={t('Filter by title', { ns: 'catalog' })}
                icon={'filter'}
              />
            </Filtertitle>
          )}
          {filterTypes.includes('attributes') && (
            <Filterattributes>
              <NestedDropdownWrapper ref={moreFiltersRef}>
                <Button
                  $type="secondary"
                  $icon="filter"
                  $iconCounter={moreFilterSelectedValueCounter}
                  onClick={() => (isMoreFiltersOpen ? closeMoreFilters() : openMoreFilters())}
                >
                  {t('More filters', { ns: 'catalog' })}
                </Button>

                <NestedDropdown open={isMoreFiltersOpen} nested positionToLeft={positionFilterMenuToLeft} role="listbox">
                  {isMoreFiltersOpen && (
                    <MultiSelect
                      name="language"
                      label={t('Language', { ns: 'common' })}
                      placeholder={t('Choose...', { ns: 'common' })}
                      aria-label={t('Filter by Language', { ns: 'catalog' })}
                      handleSideEffect={trackLanguage}
                      options={languages.map((l) => ({
                        value: l.name,
                        label: t(l.name, { ns: 'languages' }),
                      }))}
                    />
                  )}

                  {isMoreFiltersOpen && isAttributeFilterEnabled && (
                    <MultiSelect
                      name="attributes"
                      label={t('Attributes', { ns: 'catalog' })}
                      placeholder={t('Choose...', { ns: 'common' })}
                      aria-label={t('Filter by Attribute', { ns: 'catalog' })}
                      multiple
                      handleSideEffect={trackAttributes}
                      options={attributeOptions}
                    />
                  )}

                  {isMoreFiltersOpen && (
                    <NestedDropdownFooterButton $type="secondary" $fullwidth onClick={() => clearAttributeFilters()}>
                      {t('Clear all filters', { ns: 'catalog' })}
                    </NestedDropdownFooterButton>
                  )}
                </NestedDropdown>
              </NestedDropdownWrapper>
            </Filterattributes>
          )}
        </>
      )}
    </Composition>
  );
};
