export type NotificationType = 'REPLY' | 'LIKE' | 'MIXED';

interface NotificationBase {
  topLevelPostTitle: string;
  topLevelPostId: number;
  productId: number | null;
  productTitle: string | null;
  sectionTitle: string | null;
  sectionPath: string;
}

export type BabelonNotification = BabelonNotificationSingle | BabelonNotificationAggregate;

export function isSingle(notification: BabelonNotification): notification is BabelonNotificationSingle {
  return (notification as BabelonNotificationSingle).id !== undefined;
}

export interface BabelonNotificationSingle extends NotificationBase {
  id: string;
  notificationType: NotificationType;
  respondentName: string;
  replyId: number | null;
  postId: number;
}

export function isAggregate(notification: BabelonNotification): notification is BabelonNotificationAggregate {
  return (notification as BabelonNotificationAggregate).ids !== undefined;
}

export interface BabelonNotificationAggregate extends NotificationBase {
  ids: string[];
  notificationTypes: NotificationType[];
  respondentNames: string[];
  replyIds: number[];
  postIds: number[];
}
