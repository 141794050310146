import styled, { css } from 'styled-components';

import outline from './outline.svg';

const commonStyles = css`
  ${p => p.theme.typography.overline};
  color: ${p => p.theme.colors.neutral.outline2};
  &:hover {
    color: ${p => p.theme.colors.neutral.onBackground};
  }
  transition: color 0.2s;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  background: none;
  display: block;
  padding: 12px 8px;
`;

export const Wrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.colors.neutral.container};
  padding-top: 4px;
`;

export const PrevOrNext = styled.button`
  ${commonStyles};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.neutral.onBackground};
  }

  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      color: ${p => p.theme.colors.neutral.disabled};
    `}
`;

export const Ellipsis = styled.span.attrs({ children: '...' })`
  ${commonStyles};
  display: block;
  pointer-events: none;
  user-select: none;
`;

export const ToPage = styled.button<{ current?: boolean }>`
  ${commonStyles};
  cursor: pointer;
  padding: 12px;
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 36px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 3px));
    background-image: url(${outline});
    background-size: 100%;
    transition: opacity .2s;
    opacity: ${p => (p.current ? 1 : 0)};
  }
  ${p =>
    p.current &&
    css`
      color: ${p => p.theme.colors.neutral.onBackground};
      pointer-events: none;
    `}
`;
