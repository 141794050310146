import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Editor, Location, Range, Transforms } from 'slate';
import { ReactEditor, useFocused, useSlate } from 'slate-react';
import styled from 'styled-components';

import { GenerationResult } from './atoms/GenerationResult';
import { CustomPrompt } from './atoms/CustomPrompt';
import { CurratedPrompts } from './atoms/CurratedPrompts';
import { openAI } from './openAI';
import { useUsageCharge } from './useUsageCharge';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

const Portal = ({ children }: { children: JSX.Element }) => {
  return typeof document === 'object' ? createPortal(children, document.getElementById('custom-editor') || document.body) : null;
};

const RootStyled = styled.div`
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: 50%;
  margin-top: -6px;
  opacity: 0;
  transition: opacity 0.85s ease-out, top 0.15s;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PromptVariantsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 500px;
`;

export const EditorAssistant = (): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const editor = useSlate();
  const [isCopied, handleCopy] = useCopyToClipboard(1500);
  const inFocus = useFocused();
  const [selectionDomRect, setSelectionDomRect] = useState<DOMRect | undefined | null>(null);
  const [result, setResult] = useState('');
  const [lastPrompt, setLastPrompt] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [hasLimitReachedError, setHasLimitReachedError] = useState(false);
  const { updateUsage } = useUsageCharge();

  const handleReplace = () => {
    Editor.removeMark(editor, 'fakeSelection');
    Editor.insertText(editor, result);
    Transforms.select(editor, editor.selection as Location);
    ReactEditor.focus(editor);
    setResult('');
  };

  const handleRegenerate = () => {
    if (lastPrompt) {
      handleOpenAICall(lastPrompt);
    }
  };

  const handleCopyToClipboard = async () => {
    if (result) handleCopy(result);
  };

  const clearEditor = () => {
    setLoading(false);
    setHasLimitReachedError(false);
    setLastPrompt(undefined);
    setResult('');
  };

  useEffect(() => {
    if (inFocus && editor.selection) {
      const domSelection = window.getSelection();
      const domRange = domSelection?.getRangeAt(0);
      const rect = domRange?.getBoundingClientRect();

      setSelectionDomRect(rect);
    }
  }, [inFocus, editor.selection]);

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (!selection || Range.isCollapsed(selection) || Editor.string(editor, selection) === '' || !selectionDomRect) {
      el.removeAttribute('style');
      clearEditor();
      return;
    }

    const rect = selectionDomRect;
    const editorRect = document.getElementById('custom-editor')?.getBoundingClientRect();
    const scrollTop = document.getElementById('custom-editor')?.scrollTop || 0;

    el.style.opacity = '1';
    el.style.top = `${(rect?.bottom || 0) + scrollTop + 20 - (editorRect?.top || 0)}px`;
    el.style.left = `${el.offsetWidth / 2}px`;
  }, [editor, ref, selectionDomRect]);

  const handleOpenAICall = async (prompt: string) => {
    setHasLimitReachedError(false);
    setLoading(true);
    setResult('');
    setLastPrompt(prompt);

    try {
      const chatCompletion = await openAI.chat.completions.create({
        messages: [
          {
            role: 'user',
            content: prompt,
          },
        ],
        model: 'gpt-3.5-turbo',
      });

      const res = chatCompletion.choices[0].message.content || '';

      if (chatCompletion.usage) {
        updateUsage(chatCompletion.usage);
      }

      setResult(res);
      setLoading(false);
    } catch (e) {
      setHasLimitReachedError(true);
      setLoading(false);
    }
  };

  return (
    <Portal>
      <RootStyled
        ref={ref}
        onMouseDown={(e) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        <GenerationResult
          hasLimitReachedError={hasLimitReachedError}
          result={result}
          loading={loading}
          isCopied={isCopied}
          onReplaceClick={handleReplace}
          onRegenerateClick={handleRegenerate}
          onCopyToClipboardClick={handleCopyToClipboard}
        />
        <PromptVariantsStyled>
          <CustomPrompt onGenerate={(customPrompt) => handleOpenAICall(customPrompt)} />
          <CurratedPrompts
            onGenerate={(prompt) => {
              handleOpenAICall(prompt);
            }}
          />
        </PromptVariantsStyled>
      </RootStyled>
    </Portal>
  );
};
