import styled from 'styled-components';

import { LinearProgressBar } from '@/ui/ProgressBar';

type Props = {
  value: number;
  maxValue?: number;
  text: string;
};

export const Progress = ({value, maxValue, text}: Props): JSX.Element => {
  return <Wrapper>
    <span>{text}</span>
    <ProgressBar value={value} maxValue={maxValue ?? 1} />
  </Wrapper>
};

const Wrapper = styled.div`
  color: ${p => p.theme.colors.neutral.onContainer};
  span {
    ${p => p.theme.typography.caption};
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;

const ProgressBar = styled(LinearProgressBar)`
  width: 80px;
`;
