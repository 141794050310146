import React from 'react';
import styled from 'styled-components';

import { LinearProgressBar } from '@/ui/ProgressBar';
import { ProgressBarStyled } from '@/ui/ProgressBar/variants/LinearProgressBar/styles';

type MediaElementUploadProgressProps = {
  currentProgress: number;
  prevProgress?: number;
};

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 440px;
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 424px;
`;

const StyledLoadingInner = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledLoadingPercentage = styled.p`
  ${(p) => p.theme.typography.title5};
  color: ${(p) => p.theme.colors.neutral.onBackgroundVariant};
`;

const StyledLinearProgressBar = styled(LinearProgressBar)`
  width: 100%;

  & ${ProgressBarStyled} {
    height: 4px;

    &:after {
      height: 4px;
    }
  }
`;

const WarningMessageStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const MediaElementUploadProgress = ({ currentProgress, prevProgress }: MediaElementUploadProgressProps): JSX.Element => {
  return (
    <RootStyled>
      <ContentStyled>
        <StyledLoadingInner>
          <StyledLoadingPercentage>{`Uploading ${currentProgress}%`}</StyledLoadingPercentage>
          <StyledLinearProgressBar
            maxValue={100}
            animationStartPercentage={prevProgress}
            value={currentProgress}
            animated={true}
            rounded
          />
        </StyledLoadingInner>
        <WarningMessageStyled>Do not leave this page until the upload is complete, or progress will be lost</WarningMessageStyled>
      </ContentStyled>
    </RootStyled>
  );
};
