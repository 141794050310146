import { Colors } from '../types';

export const colors: Colors = {
  primary: {
    main: '#006C4D',
    onMain: '#FFFFFF',
    outline: '#42DCA8',
    container: '#B9FFE0',
    onContainer: '#002115',
  },
  secondary: {
    main: '#AD6608',
    onMain: '#FFFFFF',
    outline: '#FFB86F',
    container: '#F9E6D3',
    onContainer: '#312B20',
  },
  tertiary: {
    main: '#255FA7',
    onMain: '#FFFFFF',
    outline: '#7AACF9',
    container: '#D4E3FF',
    onContainer: '#001B3D',
  },
  error: {
    main: '#BA1B1B',
    onMain: '#FFFFFF',
    outline: '#FF5449',
    container: '#FFDAD6',
    onContainer: '#410002',
  },
  warning: {
    main: '#7C5800',
    onMain: '#FFFFFF',
    outline: '#FFD25D',
    container: '#FFDEA7',
    onContainer: '#271900',
  },
  success: {
    main: '#006E01',
    onMain: '#FFFFFF',
    outline: '#89CE7F',
    container: '#CAFFB9',
    onContainer: '#042904',
  },
  neutral: {
    background: '#FFFFFF',
    backgroundVariant: '#F5FEF7',
    onBackground: '#151D19',
    onBackgroundVariant: '#404944',
    container: '#E6ECEC',
    onContainer: '#3F494A',
    outline1: '#CEDADA',
    outline2: '#6F797A',
    disabled: '#BEC8C8',
  },
  states: {
    hover0: 'rgba(255, 255, 255, 0.16)',
    hover1: 'rgba(0, 136, 98, 0.08)',
    hover2: 'rgba(0, 32, 34, 0.16)',
    hover3: '#005139',
    hover4: '#141D1E',
    hover5: 'rgba(20, 29, 30, 0.08)',
    hover6: 'rgba(0, 108, 77, 0.08)',
    hover7: 'rgba(66, 220, 168, 0.1)',
    hover8: 'rgba(66, 220, 168, 0.1);',
  },
};
