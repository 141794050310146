import { IconPropType } from '@/ui/Icon';
import { PageVariantDeprecated as PageVariant } from '@/administration/pages/Course/store/page/types';

export type DrawerItemType = PageVariant | 'completed';
export const PAGE_TYPE_TO_ICON: Record<DrawerItemType, IconPropType> = {
  [PageVariant.TEXT]: ['far', 'text'],
  [PageVariant.RESULT]: ['far', 'party-horn'],
  completed: ['far', 'circle-check'],
  [PageVariant.REACTION]: ['far', 'angle-double-right'],
  [PageVariant.QUESTION]: ['far', 'square-poll-vertical'],
  [PageVariant.PRACTICAL_ASSESSMENT_TEXT]: ['far', 'pen-line'],
  [PageVariant.PRACTICAL_ASSESSMENT_DOCUMENT]: ['far', 'file-upload'],
  [PageVariant.REFLECTION]: ['far', 'comment-pen'],
  [PageVariant.QUIZ]: ['far', 'game-board-simple'],
};
