import axios from 'axios';

import { config } from '../../config';
import { UploadLinkResponse, UploadStatus } from '../../types/learning/learning-catalog';
import { http } from './http';

export const api = {
  get<T>(url: string): Promise<T> {
    return http.get<T>(config.baseApiRoute + url);
  },
  post<T>(url: string, body: unknown): Promise<T> {
    return http.post<T>(config.baseApiRoute + url, body);
  },
  postImage<T>(url: string, body: FormData): Promise<T> {
    return http.postImage<T>(config.baseApiRoute + url, body);
  },
  put<T>(url: string, body: unknown): Promise<T> {
    return http.put<T>(config.baseApiRoute + url, body);
  },
  delete<T>(url: string): Promise<T> {
    return http.delete<T>(config.baseApiRoute + url, {});
  },
  uploadImage(file: File, spaceId: number, onProgress: (progress: number) => void): Promise<{ path: string }> {
    const formData = new FormData();
    formData.append('file', file);

    return axios
      .post(`${config.baseApiRoute}/uploads/images/${spaceId}`, formData, {
        withCredentials: true,
        onUploadProgress: (progressEvent) =>
          onProgress(Math.trunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))),
      })
      .then((response) => response.data);
  },
  uploadResource(
    file: File,
    spaceId: number,
    type: 'audio' | 'file',
    onProgress: (progress: number) => void
  ): Promise<{ path: string }> {
    const formData = new FormData();
    formData.append('file', file);

    return axios
      .post(`${config.baseApiRoute}/uploads/resource/${type}/${spaceId}`, formData, {
        withCredentials: true,
        onUploadProgress: (progressEvent) =>
          onProgress(Math.trunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))),
      })
      .then((response) => response.data);
  },
  uploadELearningBundle(file: FormData, spaceId: number): Promise<{ path: string }> {
    return http.postFile(`${config.baseApiRoute}/uploads/xapi/${spaceId}`, file);
  },
  uploadLink(name: string, size: number): Promise<UploadLinkResponse> {
    return api.post<UploadLinkResponse>('/videos/upload-link', { name, size });
  },
  uploadStatus(vimeoId: string): Promise<UploadStatus> {
    return api.get<UploadStatus>(`/videos/${vimeoId}/status`);
  },
};

export type Api = typeof api;
