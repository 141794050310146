import React from 'react';
import { useParams } from 'react-router';

import { RouterLinkButton } from '@/components/Button/RouterLinkButton';
import { ButtonList } from '@/components/Button/ButtonList';
import { useParentJourney } from '@/store/v2/journeys/useParentJourneyId';
import { convertTitle } from '@/utils/misc';

export const BackToJourneyButton = ({ isProduct }: { isProduct?: boolean }): JSX.Element | null => {
  const { id } = useParams<{ id: string }>();
  const parsedLearningId = id ? parseInt(id) : NaN;

  const parentJourney = useParentJourney({
    id: parsedLearningId,
    isProduct,
  });

  return parentJourney?.journeyId && parentJourney?.journeyTitle ? (
    <ButtonList>
      <RouterLinkButton
        to={`/journey/${parentJourney.journeyId}/${convertTitle(parentJourney.journeyTitle)}`}
        $type="link"
        $iconPosition="left"
        $icon="long-arrow-alt-left"
      >
        {'Back to journey'}
      </RouterLinkButton>
    </ButtonList>
  ) : null;
};
