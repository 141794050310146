import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';

export const IconBody = styled.div<{ spin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${cvar('gap-sm')};

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${({ spin }) =>
    spin &&
    `
    svg {
      animation: spin 1s linear infinite
    }
  `}
`;
