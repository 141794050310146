import { ApolloQueryResult, FetchResult, useMutation, useQuery, gql } from '@apollo/client';

import { HookResult } from '../../../store/apolloClient';
import { CatalogItem } from '../../../types/learning/learning-catalog';

interface SavedLearnings {
  userId: number | null;
  meta: CatalogItem[];
}

const getSavedLearningsQuery = gql`
  query getSavedLearnings {
    getMeta(type: "SAVED_LEARNINGS") {
      userId
      type
      meta
    }
  }
`;

const updateSavedLearningsQuery = gql`
  mutation updateSavedLearnings($meta: JSON!) {
    updateMeta(type: "SAVED_LEARNINGS", meta: $meta) {
      userId
      type
      meta
    }
  }
`;

interface SavedLearningsResponse extends HookResult {
  savedLearnings: SavedLearnings;
  refetch: () => Promise<ApolloQueryResult<{ getMeta: { userId: number; type: string; meta: CatalogItem[] } }>>;
}

export const useSavedLearnings = (): SavedLearningsResponse => {
  const { data, error, refetch, networkStatus, loading } = useQuery<{
    getMeta: { userId: number; type: string; meta: CatalogItem[] };
  }>(getSavedLearningsQuery);

  const savedLearnings: SavedLearnings = data?.getMeta || { userId: null, meta: [] };
  savedLearnings.meta?.map(l => {
    delete l.__typename;
    return l;
  });

  return {
    savedLearnings,
    error,
    refetch,
    networkStatus,
    loading
  };
};

interface UpdateSavedLearningsResponse {
  loading: boolean;
  addSavedLearnings: (SavedLearnings: SavedLearnings, item: CatalogItem) => Promise<FetchResult<{ updateMeta: SavedLearnings }>>;
  removeSavedLearnings: (
    SavedLearnings: SavedLearnings,
    item: CatalogItem
  ) => Promise<FetchResult<{ updateMeta: SavedLearnings }>>;
}

export const useUpdateSavedLearnings = (): UpdateSavedLearningsResponse => {
  const [setSavedLearnings, { loading }] = useMutation(updateSavedLearningsQuery);

  return {
    addSavedLearnings: (savedLearnings: SavedLearnings, item: CatalogItem) =>
      setSavedLearnings({
        variables: {
          meta: savedLearnings.meta.concat(item)
        }
      }),
    removeSavedLearnings: (savedLearnings: SavedLearnings, item: CatalogItem) =>
      setSavedLearnings({
        variables: {
          meta: savedLearnings.meta.filter(l => !(item.id === l.id && item.entity === l.entity))
        }
      }),
    loading
  };
};
