/* eslint-disable import/no-unused-modules */
import styled from 'styled-components';

export const RootStyled = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral.outline1};
  position: relative;
`;

export const IndicatorStyled = styled.div<{ activeTabWidth: number; activeTabLeft: number }>`
  height: 2px;
  background: ${({ theme }) => theme.colors.primary.main};
  width: ${({ activeTabWidth }) => activeTabWidth}px;
  position: absolute;
  top: -2px;
  left: ${({ activeTabLeft }) => activeTabLeft}px;
  transition: all 0.2s ease-out;
`;
