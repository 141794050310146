/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';

import { makeNamespacedComponent } from '@/ui/utils';
import { RootStyled } from '../styles';
import { WeeklyLearningLoop } from '../../types';
import { LoadingState, WeeksNavigationHeader, WeeksPagination, WeeksTabNavigation } from '../../atoms';
import { useWeekNavigationsContext } from '../../context';

export type WeeksSetupProps = {
  weeks: WeeklyLearningLoop[];
  selectedWeek: WeeklyLearningLoop | null;
  maxVisibleWeeksCount?: number;
  onChangeSelectedWeek: (weekNumber: number) => void;
  onHelpClick: () => void;
};

const _WeeksSetup = ({
  weeks,
  maxVisibleWeeksCount = 4,
  selectedWeek,
  onChangeSelectedWeek,
  onHelpClick,
}: WeeksSetupProps): JSX.Element => {
  const { setWeeks, setMaxVisibleWeeksCount, setSelectedWeek, triggeredPagination, shownWeeks } = useWeekNavigationsContext();

  useEffect(() => {
    setWeeks(weeks);
    setMaxVisibleWeeksCount(maxVisibleWeeksCount);
    setSelectedWeek(selectedWeek);
  }, [weeks, maxVisibleWeeksCount, selectedWeek]);

  // Whether currently selected week is an element of shown weeks list
  const isSelectedWeekVisible = shownWeeks.map(({ id }) => id).includes(selectedWeek?.id || '');

  if (!triggeredPagination && !isSelectedWeekVisible) {
    return <LoadingState />;
  }

  return (
    <RootStyled>
      <WeeksNavigationHeader onHelpClick={onHelpClick} />
      <WeeksPagination>
        <WeeksTabNavigation onChangeSelectedWeek={onChangeSelectedWeek} />
      </WeeksPagination>
    </RootStyled>
  );
};

export const WeeksSetup = makeNamespacedComponent(_WeeksSetup, {
  LoadingState,
});
