import { OnboardingStep } from '../types';
import { Hello } from './Hello';
import { Terms } from './Terms';
import { ExtraTerms } from './ExtraTerms';
import { Profile } from './Profile';
import { LFTerms } from './LFTerms';

export const Steps = {
  [OnboardingStep.Hello]: Hello,
  [OnboardingStep.LFTerms]: LFTerms,
  [OnboardingStep.Terms]: Terms,
  [OnboardingStep.ExtraTerms]: ExtraTerms,
  [OnboardingStep.Profile]: Profile,
  [OnboardingStep.FinalStep]: null,
};
