import { useLearningByIdQueryRemote } from '@/store/v2';
import { Survey } from './types';
import { transformFromRemoteLearning } from '@/administration/pages/Course/store/course/transform';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { Module } from '@/administration/pages/Course/store/modules/types';
import { Page } from '@/administration/pages/Course/store/page/types';

type UseSurveyResult = {
  survey?: Survey;
  module?: Module;
  pages?: Page[];
  progress?: Progress;
};

type UseSurveyHook = (
  id: number,
  opts?: {
    includeProgress: boolean,
    includeLocations: boolean
  }
) => {
  data: UseSurveyResult | undefined;
  loading: boolean;
};

export const useSurvey: UseSurveyHook = (id: number, opts) => {
  const { data, loading } = useLearningByIdQueryRemote({
    variables: {
      id: String(id),
      includeProgress: opts?.includeProgress || false,
      includeLocations: opts?.includeLocations || false
    },
  });

  const [survey, module, pages] = transformFromRemoteLearning(data?.learning);

  return {
    data: {
      survey: survey as Survey,
      module,
      pages,
    },
    loading,
  };
};
