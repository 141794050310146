import { Control, Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import { FormSchema, TextFieldName } from '../..';
import { TimeInput } from '@/ui/TextField/variants';

const getDefaultMaxTime = (): Date => new Date(new Date().setHours(23, 45));
const getMaxTimeByBoundary = (boundaryDate: Date): Date => new Date(new Date(boundaryDate).getTime() - 60 * 1000 * 15);

export const FormStartsAt = ({ control }: { control: Control<FormSchema> }): JSX.Element => {
  const { watch, setValue } = useFormContext<FormSchema>();
  const [endsAtTime, date, startsAtTime] = watch(['endsAtTime', 'date', 'startsAtTime']);

  useEffect(() => {
    if (startsAtTime && date) {
      const pickedDate = new Date(date);

      const startsAtDate = new Date(startsAtTime);

      // We need to override time field date, once field from date picker is changed (initialized)
      startsAtDate.setDate(pickedDate.getDate());
      startsAtDate.setMonth(pickedDate.getMonth());
      startsAtDate.setUTCFullYear(pickedDate.getFullYear());

      setValue('startsAtTime', startsAtDate.toISOString());
    }
  }, [date, startsAtTime]);

  const maxTime = endsAtTime ? getMaxTimeByBoundary(new Date(endsAtTime)) : getDefaultMaxTime();

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.StartsAtTime}
      render={({ field: { onChange, name, value }, fieldState: { error } }) => (
        <TimeInput
          value={value ? new Date(value) : undefined}
          onChange={(date: Date) => onChange(date.toISOString())}
          inputProps={{
            label: 'Start time',
            name,
            error: error && {
              message: 'Enter start time',
            },
          }}
          maxTime={maxTime}
          width={144} // TODO In future, find out a better way to adjust react-datepicker popover size
        />
      )}
    />
  );
};
