import { parse } from 'qs';
import { useLocation } from 'react-router-dom';

import { config } from '@/config';
import { FORCE_SSO_LOGIN_KEY } from '@/utils/constants';

interface LocationState {
  from: {
    pathname: string;
    search?: string;
  };
}

const getReturnUrl = ({ from, redirectUrl }: { from: LocationState['from']; redirectUrl: string }) => {
  if (redirectUrl) {
    return redirectUrl;
  }
  if (window.SSO_RETURN_URL) {
    return window.SSO_RETURN_URL;
  }

  return `${window.location.origin}${from.pathname}${from.search ? from.search : ''}`;
};

export const LoginPage = (): JSX.Element => {
  const location = useLocation();

  const { from } = location?.state ? (location.state as LocationState) : { from: { pathname: '/' } };

  if (from.pathname === '/login') from.pathname = '/';

  const query = parse(location.search, { ignoreQueryPrefix: true });

  const fromUrl = getReturnUrl({ from, redirectUrl: query['redirect_url'] });

  const prepareReturnUrl = (base: string) => {
    // Removing force_sso_login search param for the redirection url
    const preparedBase = base.replace(`?${FORCE_SSO_LOGIN_KEY}=true`, '');
    const url = query?.returnUrl ?? preparedBase;

    return encodeURIComponent(url);
  };

  const returnUrl = prepareReturnUrl(fromUrl);
  window.location.href = `${config.authv2.login_url}?realm=${window.realm}&return_url=${returnUrl}`;
  return <></>;
};
