import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button';
import { MetaLabel } from '@/ui/Label';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';
import {
  PageStyled,
  PageBody,
  InnerContainer,
  ColumnStyled,
  ImageStyled,
  TextButtonContainerStyled,
  TextContainerStyled,
  TitleStyled,
  MetaContainer,
  DescriptionStyled,
} from './styles';

export const ModularLearningStartContent = ({
  title,
  teaser,
  imageSrc,
  effort,
  level,
  onStartClick,
  startCourseLoading,
}: {
  title?: string;
  teaser?: string;
  imageSrc?: string;
  effort?: {
    hours?: number;
    minutes?: number;
  };
  level?: string;
  onStartClick: () => void;
  startCourseLoading: boolean;
}): JSX.Element => {
  const { t } = useTranslation('catalog');

  const minutes = (effort?.hours || 0) * 60 + (effort?.minutes || 0);
  const seconds = minutes * 60;
  return (
    <PageStyled>
      <PageBody>
        <InnerContainer>
          <ColumnStyled>
            <ImageStyled src={imageSrc} />
          </ColumnStyled>
          <ColumnStyled>
            <TextButtonContainerStyled>
              <TextContainerStyled>
                <TitleStyled> {title}</TitleStyled>
                <MetaContainer>
                  {level && <MetaLabel icon="signal-alt" text={level || ''} />}
                  <MetaLabel icon={['far', 'clock']} text={formatToHoursAndMinutesFromSeconds(seconds)} />
                </MetaContainer>
                <DescriptionStyled>{teaser}</DescriptionStyled>
              </TextContainerStyled>
              <Button variant={'primary'} onClick={onStartClick} $loading={startCourseLoading}>
                {t('start-course')}
              </Button>
            </TextButtonContainerStyled>
          </ColumnStyled>
        </InnerContainer>
      </PageBody>
    </PageStyled>
  );
};
