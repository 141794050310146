import React from 'react';

import { supportEmails } from '@/config';

export const PrivacyEn = (): JSX.Element => {
  return (
    <>
      <h1>Privacy Notice</h1>
      <span className="body2">Last updated 2024-03-14</span>
      <h2>Collegial is committed to protecting and respecting your privacy</h2>
      <p>
        Collegial AB (
        <q>
          <strong>Collegial</strong>
        </q>
        ,{' '}
        <q>
          <strong>us</strong>
        </q>
        ,{' '}
        <q>
          <strong>we</strong>
        </q>{' '}
        or{' '}
        <q>
          <strong>our</strong>
        </q>
        ) wants you to feel safe when we process your personal data. This privacy notice (the{' '}
        <q>
          <strong>Privacy Notice</strong>
        </q>
        ) explains how Collegial ensures that your personal data is handled in compliance with applicable legislation and it
        applies to our processing of personal data, in the capacity of data controller relating to our customers and users of our
        services, visitors to our websites, and to other business contacts.
      </p>
      <p>
        If you are a user of our services or otherwise engage with us, you either do so as:
        <ul>
          <li>
            a representative (a{' '}
            <q>
              <strong>Company Representative</strong>
            </q>
            ) of a company that is a current or potential client (a{' '}
            <q>
              <strong>Company Subscriber</strong>
            </q>
            ) of ours or otherwise a business contact of ours, for example, if you manage the relationship with us on behalf of a
            Company Subscriber; or
          </li>
          <li>
            a user invited to use a service by an employer company or other entity which is a customer of ours (
            <q>
              <strong>User of a Company Subscriber</strong>
            </q>
            ), for example, if you are invited to use the services by your employer.
          </li>
        </ul>
      </p>
      <p>
        Please note that some of our processing of personal data differs depending on if you are Company Representative or a User
        of Company Subscriber. If you are a User of a Company Subscriber, we process your personal data in the capacity of data
        processor, when providing our services to our customer that is a Company Subscriber (i.e., the entity that invited you to
        use the service, for example, your employer). In relation to such processing, the Company Subscriber is data controller or
        business and hence responsible for providing information to you about its processing of personal data and addressing your
        rights as a data subject. We will endeavor to provide assistance to our Company Subscribers to address any concerns you
        may have in accordance with the terms of our contract with such customer.
      </p>
      <p>
        We only use your personal data for the purposes specified in this Privacy Notice and not in any manner that is
        incompatible with those purposes.
      </p>
      <h2>1. General</h2>
      <p>
        This Privacy Notice applies to all of our processing of personal data relating to our customers and to other business
        contacts. We use your personal data to be able to operate our business and meet our obligations and responsibilities in
        relation to applicable legislation and good industry practice.
      </p>
      <h2>2. Data controller</h2>
      <p>
        Collegial is the data controller for the processing of your personal data other than when we act as a processor or service
        provider on behalf of a Company Subscriber, as described above. Collegial is responsible for ensuring that the processing
        is carried out in accordance with applicable legislation. If you have any questions regarding the processing of your
        personal data, you will find our contact details at the end of this Privacy Notice.
      </p>
      <h2>3. Our use of your personal data</h2>
      <h3>3.1 The purposes of processing</h3>
      <p>
        We use your personal data for the following purposes:
        <ul>
          <li>To provide and manage our services;</li>
          <li>To comply with legal obligations;</li>
          <li>To establish and defend legal claims; and</li>
          <li>
            To enable mergers, divestitures, restructuring, reorganization, dissolution, and other sale or transfers of Collegial
            assets.
          </li>
        </ul>
      </p>
      <h3>3.2 Categories of personal data processed</h3>
      <p>
        We process the following categories of personal data:
        <ul>
          <li>
            User:
            <ul>
              <li>Name</li>
              <li>Contact information, such as (e-mail, signature, phone number and address information)</li>
              <li>
                Your (or your employer{"'"}s) businesses and dealings with us (including if you are a prospective Company
                Subscriber)
              </li>
            </ul>
          </li>
          <li>
            Billing and administration:
            <ul>
              <li>First and last name</li>
              <li>Signature</li>
              <li>Email</li>
              <li>Phone number</li>
              <li>Address</li>
              <li>Corporate division</li>
            </ul>
          </li>
        </ul>
      </p>
      <h3>3.3 How we process your personal data for each purpose</h3>
      <p>
        Below you can find more information about our processing of your personal data in relation to our purposes of processing:
      </p>
      <p>
        <h3>Purpose: To provide and manage our services.</h3>
        <p>
          <i>What we do:</i>
          <br />
          We process your personal data in order to provide you with our services and use of our services and to communicate with
          you.
        </p>
        <p>
          <i>Legal basis:</i>
          <br /> The processing is necessary for the purpose of fulfilling the agreement with the Company Subscriber, including
          administering our services.
        </p>
        <p>
          <i>Retention period:</i>
          <br />
          Personal data stored to provide and manage our services will be stored during the time period that it is necessary in
          order to fulfill the purposes with our processing, which is usually as long as you have an account to use our services
          or websites, or as set forth in our agreement. We may also need to store your personal data for a reasonable time
          thereafter in order to fulfill any surviving terms of our agreements.
        </p>
        <p>
          <i>Your rights:</i>
          <br />
          Please see Section 9 below for information about your rights.
        </p>
      </p>
      <p>
        <h3>Purpose: To comply with legal obligations.</h3>
        <p>
          <i>What we do:</i>
          <br /> We process your personal data to comply with our legal obligations under applicable law.
        </p>
        <p>
          <i>Legal basis:</i>
          <br />
          We need to process personal data to comply with our legal obligations under applicable legislation and to respond to
          your request to exercise your rights under the GDPR.
        </p>
        <p>
          <i>Retention period:</i>
          <br />
          We will store your personal data as long as necessary for us to fulfill our legal obligations. Personal data processed
          to fulfill legal obligations in the Accounting Act will be stored for seven years.
        </p>
        <p>
          <i>Your rights:</i>
          <br />
          Please see Section 9 below for information about your rights.
        </p>
      </p>
      <p>
        <h3>Purpose: To establish and defend against legal claims.</h3>
        <p>
          <i>What we do:</i>
          <br />
          In case of a dispute, we are entitled to process your personal data to establish, exercise, or defend the legal claim.
        </p>
        <p>
          <i>Legal basis:</i>
          <br />
          Processing is necessary for the purposes of our legitimate interests of the establishment, exercise, or defense of legal
          claims. In case of a dispute, we are entitled to process your personal data since we assess that our interest in
          safeguarding our interests in a dispute overrides your interest in the protection of your privacy.
        </p>
        <p>
          <i>Retention period:</i>
          <br />
          We will store your data for the purposes of establishing or defending Collegial against legal claims for as long as you
          can make legal claims against us. This means that we may store personal data during any warranty period and until any
          limitation period has expired. The general limitation period is ten years under the Act on Limitations.
        </p>
        <p>
          <i>Your rights:</i>
          <br />
          You have the right to object to the processing of your personal data based upon legitimate interest as legal basis.
          Please see Section 9 below for more information about your rights.
        </p>
      </p>
      <p>
        <h3>
          Purpose: To enable mergers, divestitures, restructuring, reorganization, dissolution, and other sale or transfers of
          Collegial’ assets.
        </h3>
        <p>
          <p>
            <i>What we do:</i>
            <br />
            In case of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of Collegial
            {"'"}s assets we may need to process your personal data in order to enable such transfer.
          </p>
          <p>
            <i>Legal basis:</i>
            <br />
            Processing is necessary for the purposes of our legitimate interests of enabling mergers, divestitures, restructuring,
            reorganization, dissolution and other sale or transfers of Collegial{"'"} assets which we assess overrides your
            interest in the protection of your personal data.
          </p>
          <p>
            <i>Retention period:</i>
            <br />
            The personal data will be processed as long as necessary to fulfill the purpose of the processing. Personal data that
            is transferred to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Collegial{"'"}s assets will not be stored by Collegial after
            such transfer unless required to fulfill any of the other purposes set out above.
          </p>
          <p>
            <i>Your rights:</i>
            <br />
            You have the right to object to the processing of your personal data based upon legitimate interest as legal basis.
            Please see Section 9 below for more information about your rights.
          </p>
        </p>
        <h2>4. Collection of personal data</h2>
        <p>
          The personal data that we process about you is data that you have provided us with or that we have otherwise acquired as
          part of the provision of our services.We collect personal data:
          <ul>
            <li>If you are a Company Representative, when we initiate a business relationship with a new Company Subscriber;</li>
            <li>When you perform search queries on our websites;</li>
            <li>Through online forms and otherwise through our websites;</li>
            <li>When you seek general information about Collegial;</li>
            <li>Through e-mails sent to and from Collegial; and</li>
            <li>
              Through social media, such as if we identify your employer or other organization as a prospective Company Subscriber
              through LinkedIn or other similar platforms.
            </li>
          </ul>
          <p>We do not collect any personal data from any third party sources.</p>
        </p>
        <h2>5. Automated decision-making</h2>
        <p>We do not use processes for automatic decision-making.</p>
        <h2>6. Retention of personal data</h2>
        <p>
          We retain your personal data only for as long as necessary for the purposes for which we originally collected the data
          in accordance with this Privacy Notice. When we no longer need to save your data, we will remove it from our systems,
          databases, and backups. The retention time depends on the context and cannot in all cases be specified, in that case, we
          will provide information about the factors deciding the retention time. If return or destruction is impracticable or
          incidentally prohibited by a valid legal order, Collegial shall take measures to inform you and block such personal data
          from any further processing (except to the extent necessary for its continued hosting or processing required by
          applicable law) and shall continue to appropriately protect the personal data remaining in its possession, custody, or
          control and, where any authorized sub-processor continues to possess personal data, require the authorized sub-processor
          to take the same measures that would be required of Collegial. For more detailed information on how long we retain your
          personal data in relation to our purposes of the processing, see Section 3.
        </p>
        <h2>7. With whom do we share your personal data?</h2>
        <p>
          We may share personal data with third parties that are trusted recipients and with whom we have an agreement ensuring
          that your personal data is processed in accordance with this Privacy Notice. We may share data with:
          <ul>
            <li>The subscriber with which you are employed</li>
            <li>
              Third party service providers such as:
              <ul>
                <li>Hubspot</li>
                <li>Google Workspace</li>
              </ul>
            </li>
            <li>
              A buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or
              other sale or transfer of some or all of Collegial{"'"} assets;
            </li>
          </ul>
          <p>
            In certain circumstances, we may also need to disclose personal data upon the request from authorities or to third
            parties in connection with court proceedings or business acquisition or combination processes, or other similar
            processes.
          </p>
          <p>We will not sell your personal data.</p>
        </p>
        <h2>8. Where do we use your personal data?</h2>
        <p>
          Collegial will process your personal data within the EU/EEA. However, we occasionally need to transfer personal data to
          third countries, either directly or through our sub-processors. If we engage in such transfer, we will ensure that there
          is a legal basis for the transfer and that the level of protection is equivalent to that applicable within the EU/EEA,
          either by ensuring that the country has an adequate level of protection, that we have taken adequate protective measures
          such as the European Commission’s standard contractual clauses, that you have given your explicit consent or that the
          transfer is necessary with regards to the purposes set out in article 49 of the GDPR.
        </p>
        <h2>9. Your rights</h2>
        <p>
          <strong>9.1 Our responsibilities for your rights</strong>
        </p>
        <p>
          In the capacity of data controller, we are responsible for ensuring that your personal data is processed in compliance
          with applicable laws and that you can exercise your rights. You may contact us at any time if you wish to exercise your
          rights. You will find our contact details at the end of this Privacy Notice.
        </p>
        <p>
          We have an obligation to respond to your requests to exercise your rights within one month of receiving your request. If
          your request is complex or if we have received many requests, we have the right to extend this deadline to two more
          months. If we are unable to take the action you request within one month, we will inform you of the reason for the delay
          and of your right to lodge a complaint with a supervisory authority and to seek judicial remedy.
        </p>
        <p>
          You will not be charged for requesting information, for communication, or measures that we carry out. However, if your
          request is manifestly unfounded or excessive, we may charge an administrative fee for providing the information or
          taking the action requested or refuse to act on your request altogether.
        </p>
        <p>
          <strong>9.2 Your rights to access, rectification, erasure, and restriction</strong>
          <p>
            <strong>You have the right to request:</strong>
          </p>
          <p>
            <strong>Access to your personal data.</strong>This means that you have the right to request access to the personal
            data that we hold about you. You also have the right to be provided, at no cost, a copy of the personal data about you
            that we are processing. We have the right to charge a reasonable administration fee if you request further copies. If
            you make a request in electronic form, e.g. via email, we will provide you with the information in a commonly used
            electronic format.
          </p>
          <p>
            <strong>Rectification of your personal data.</strong>At your request or on our own initiative, we will correct,
            anonymize, delete or complement data that is inaccurate, incomplete, or misleading. You also have the right to
            complete any incomplete personal data if something relevant is missing.
          </p>
          <p>
            <strong>Erasure of your personal data.</strong>You have the right to request that we delete your personal data if
            there is no compelling reason for us to continue processing the data. Personal data should therefore be erased if:
            <ul>
              <li>it is no longer needed for the purpose for which we collected it;</li>
              <li>
                you object to us processing your data based on a legitimate interest assessment and we have no compelling interest
                that overrides your interests and rights;
              </li>
              <li>we have processed the personal data unlawfully; or</li>
              <li>we have a legal obligation to erase personal data.</li>
            </ul>
          </p>
          <p>
            However, there may be legal requirements or other compelling reasons that prevent us from immediately erasing your
            personal data. We will then stop processing your personal data for purposes other than in compliance with the law or
            where there are no compelling legitimate grounds for doing so.
          </p>
          <p>
            <strong>Restriction of processing.</strong>This means that we temporarily restrict the processing of your data. You
            have the right to request restriction when:
            <ul>
              <li>
                you consider your data to be inaccurate and you have requested rectification as defined above, while we establish
                the accuracy of the data;
              </li>
              <li>the processing is unlawful and you do not want the data to be erased;</li>
              <li>
                as the personal data controller, we no longer need the personal data for our processing purposes, but you need
                them to be able to establish, exercise, or defend a legal claim; or
              </li>
              <li>
                you have objected to processing as defined in Section 9.3.1, while waiting for us to consider whether our
                legitimate interests override yours.
              </li>
            </ul>
          </p>
          <p>
            We will take all reasonable measures possible to notify everyone who has received personal data as stated in Section 7
            above if we have rectified, erased, or restricted access to your personal data after you have requested us to do so.
            If you request information on recipients of your personal data, we will inform you about the recipients.
          </p>
          <p>
            <strong>Your right to object to processing.</strong>
            You have the right to object to the processing of your personal data if our processing is based upon legitimate
            interests (see Section 3 above). If you object to such processing, we will only continue to process your data if we
            have compelling reasons for doing so that override your interests. If you do not wish that we use your personal data
            for direct marketing, you have the right to object to such processing by contacting us. We will cease to use your data
            for that purpose when we have received your objection.
          </p>
          <p>
            <strong>Your right to data portability.</strong>You have the right to data portability. This means the right to
            receive your personal data in a structured, commonly used, and machine-readable format, and to request that these data
            are transferred to another personal data controller. The right to data portability only applies when the processing is
            being carried out by automated means and our lawful basis for processing your data is the performance of an agreement
            between you (or a Company Subscriber) us.
          </p>
          <p>
            <strong>Your right to complain to a supervisory authority.</strong>You have the right to lodge a complaint with the
            Swedish Data Protection Authority (<i>Sw. Integritetsskyddsmyndigheten</i>) if you are not satisfied with our
            processing of your personal data.
          </p>
        </p>
        <h2>10. Protection of your personal data</h2>
        <p>
          We always want you to feel confident about providing us with your personal data. We have therefore taken appropriate
          security measures to protect your personal data against unauthorized access, alteration, and erasure. Even though we
          work hard to protect your data, no security measures are perfect or impenetrable. Should a security breach occur that
          may materially impact you or your personal data, e.g., risk of fraud or identity theft, we will contact you to explain
          what action you can take to mitigate potential adverse effects of the breach.
        </p>
        <p>
          We strongly advise you to be cautious and to protect your own personal data. You are responsible for keeping your
          passwords confidential and avoiding others from observing your personal data when using our services in public spaces.
        </p>
        <h2>12. Changes to the Privacy Notice</h2>
        <p>
          We have the right to make changes to this Privacy Notice at any time. When we make changes that are not purely
          editorial, such as formatting, typographical error corrections, or other changes that do not materially affect you, we
          will inform you of these changes and what they mean for you before they become effective.
        </p>
        <h2>13. Contact details</h2>
        <p>
          Do not hesitate to contact us if you have any questions about this Privacy Notice, our processing of your personal data,
          or if you wish to exercise your rights.
          <br />
          Collegial AB, reg. no. 559147-1510
          <br />
          E-mail: <a href={`mailto:${supportEmails.collegial}`}>{supportEmails.collegial}</a>.
          <br />
          Website: <a href="https://www.collegial.com/">https://www.collegial.com/</a>.
        </p>
      </p>
    </>
  );
};
