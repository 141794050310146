import React, { useContext } from 'react';
import { withRef } from '@udecode/cn';
import { findNodePath, useEditorRef, useRemoveNodeButton, withHOC } from '@udecode/plate-common/react';
import { setNodes } from '@udecode/plate-common';
import { ResizableProvider } from '@udecode/plate-resizable';
import styled, { css } from 'styled-components';
import { useFocused, useSelected } from 'slate-react';

import { useFileUploader } from '@/component/FileUpload/useFileUpload';
import { PlateElement } from '@/component/customEditorV2/ui/elements/PlateElement';
import { Resizable, ResizeHandle } from '@/component/customEditorV2/ui/elements/Resizable';
import { CustomEditorContext } from '@/component/customEditorV2/store/CustomEditorContext';
import { BaseImage } from '@/component/customEditorV2/ui/elements/ImageElement/BaseImage';

const ImageStyled = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  object-fit: cover;
  padding-right: 0;
  padding-left: 0;
  border-radius: 14px;

  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `};
`;

export const ImageElement = withHOC(
  ResizableProvider,
  withRef<typeof PlateElement>(({ className, children, nodeProps, ...props }, ref) => {
    const editor = useEditorRef();
    const focused = useFocused();
    const selected = useSelected();
    const { spaceId } = useContext(CustomEditorContext);
    const removeNodeButtonProps = useRemoveNodeButton({ element: props.element })?.props;
    const imageUpload = useFileUploader({
      spaceId,
      type: 'image',

      onUploadSuccess: ({ url }) => {
        const path = findNodePath(editor, props.element);

        setNodes(
          editor,
          { url },
          {
            at: path,
          }
        );
      },
    });

    const handleRemoveImage = () => {
      removeNodeButtonProps.onClick();
    };

    const userSelected = focused && selected;

    const imageUrl = typeof props.element.url === 'string' ? props.element.url : undefined;

    const canBeResized = imageUrl && !imageUpload.uploading;

    const renderImage = () => (
      <ImageStyled $selected={userSelected} {...nodeProps}>
        <BaseImage
          onUpload={imageUpload.uploadFile}
          uploading={imageUpload.uploading}
          url={imageUrl}
          uploadingProgress={imageUpload.uploadingProgress}
          onRemoveElement={handleRemoveImage}
          selected={selected}
        />
      </ImageStyled>
    );

    return (
      <PlateElement ref={ref} className={className} {...props}>
        <figure contentEditable={false}>
          {canBeResized ? (
            <Resizable
              $align="center"
              options={{
                align: 'center',
              }}
            >
              {userSelected && <ResizeHandle options={{ direction: 'left' }} />}
              {renderImage()}
              {userSelected && <ResizeHandle options={{ direction: 'right' }} />}
            </Resizable>
          ) : (
            <>{renderImage()}</>
          )}
        </figure>
        {children}
      </PlateElement>
    );
  })
);
