import React, { CSSProperties } from 'react';

import { Button } from '@/components/Button/Button';
import { ButtonProps } from '@/components/Button/types';

const ModalCloseButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 'var(--gap-xs)',
  top: 'var(--gap-xs)',
  zIndex: 1
};

export const ModalCloseButton = (props: ButtonProps): JSX.Element => (
  <Button $type="base" $round $icon="times" style={ModalCloseButtonStyle} {...props} />
);
