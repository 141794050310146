import { useState } from 'react';
import styled from 'styled-components';

import { RUserLearningSubmissionReviewAssignment } from '@/store/v2';
import { Header } from './Header';
import { SubmissionForReview } from './SubmissionForReview';
import { useCurrentUser } from '@/store/currentUser';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const PeersToReview = ({
  assessmentsToReview,
  pageRemoteId,
  type,
  spaceId,
}: {
  assessmentsToReview: RUserLearningSubmissionReviewAssignment[];
  pageRemoteId?: number;
  spaceId: number;
  type: 'text' | 'document';
}): JSX.Element | null => {
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();

  const [activeSubmissionIndex, setActiveSubmissionIndex] = useState(0);

  const maxSubmissionIndex = assessmentsToReview.length - 1;

  const handleNextClick = () => {
    setActiveSubmissionIndex((prev) => Math.min(maxSubmissionIndex, prev + 1));
  };

  const handlePrevClick = () => {
    setActiveSubmissionIndex((prev) => Math.max(0, prev - 1));
  };

  const currentSubmission = assessmentsToReview[activeSubmissionIndex];

  if (currentUserLoading || !currentUser?.id) return null;

  const reviewsLeftCount =
    assessmentsToReview.filter((assessment) =>
      assessment.submission.reviews.every((review) => review.reviewerId !== currentUser.id)
    ).length || 0;

  return (
    <RootStyled>
      <Header
        selectedSubmissionIndex={activeSubmissionIndex}
        submissionsForReviewCount={assessmentsToReview.length}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        reviewsLeftCount={reviewsLeftCount}
      />
      <SubmissionForReview type={type} submissionForReview={currentSubmission} pageRemoteId={pageRemoteId} spaceId={spaceId} />
    </RootStyled>
  );
};
