import { useState } from 'react';

import { XAPISession } from './useXAPISession';
import { xapiClient } from '@/store/xapi/client';
import {
  ActivityDefinitionType,
  ResultProps,
  Statement,
  statementCompleted,
  StatementId,
  statementQuestionAnswered,
  statementReaction,
} from '@/store/xapi/model';
import { useCurrentUser } from '@/store/currentUser';
import { ReactionId } from '@/administration/pages/Course/store/block/types';

export type UseXAPIClientHook = (xapiSession?: XAPISession) => {
  setLearningCompleted(learningId: number, moduleName: string): void;
  setLearningModuleCompleted(moduleId: number, moduleName: string): void;
  setLearningPageCompleted(pageId: number, moduleName: string): void;
  setLearningActivityCompleted(pageId: number, moduleName: string): void;
  setLearningReaction(learningId: number, reaction: ReactionId, learningName: string): void;
  setLearningQuestionAnswer(input: SetLearningQuestionAnswerInput): void;
  isInitialized: boolean;
  loading: boolean;
};

type SetLearningQuestionAnswerInput = {
  learningObject: {
    id: number;
    name: string;
  };
  questionObject: {
    id: number;
    name: string;
    type: ActivityDefinitionType;
  };
  questionAnswer: ResultProps;
};

export const useXAPIClient: UseXAPIClientHook = (session?: XAPISession) => {
  const [statementIdsSent, setStatementIdsSent] = useState<Set<StatementId>>(new Set([]));
  const { user, loading: loadingUser } = useCurrentUser();

  const sendStatement = async (statement: Statement) => {
    if (!statement.id || statementIdsSent.has(statement.id) || !session) return;

    try {
      await xapiClient.postStatement(session, [statement]);

      setStatementIdsSent((sent) => new Set([...sent, statement.id as StatementId]));
    } catch (e) {
      console.error(`Failed to send statement ${statement.id}: ${e}`);
    }
  };

  return {
    setLearningCompleted: (id, name) =>
      sendStatement(
        statementCompleted({
          userId: user?.id || 0,
          objectId: `urn:collegial:${id}`,
          objectName: name,
          timestamp: new Date(),
          registration: session?.registration,
        })
      ),
    setLearningModuleCompleted: (id, name) =>
      sendStatement(
        statementCompleted({
          userId: user?.id || 0,
          objectId: `urn:collegial:module:${id}`,
          objectName: name,
          timestamp: new Date(),
          registration: session?.registration,
        })
      ),
    setLearningPageCompleted: (id, name) =>
      sendStatement(
        statementCompleted({
          userId: user?.id || 0,
          objectId: `urn:collegial:page:${id}`,
          objectName: name,
          timestamp: new Date(),
          registration: session?.registration,
        })
      ),
    setLearningActivityCompleted: (id, name) =>
      sendStatement(
        statementCompleted({
          userId: user?.id || 0,
          objectId: `urn:collegial:self:learning-activity:${id}`,
          objectName: name,
          timestamp: new Date(),
          registration: session?.registration,
        })
      ),
    setLearningReaction: (id, reactionId, objectName: string) =>
      sendStatement(
        statementReaction({
          userId: user?.id || 0,
          objectId: `urn:collegial:${id}`,
          objectName: objectName,
          reactionId,
          timestamp: new Date(),
          registration: session?.registration,
        })
      ),
    setLearningQuestionAnswer: (input: SetLearningQuestionAnswerInput) =>
      sendStatement(
        statementQuestionAnswered({
          userId: user?.id || 0,
          learningObject: {
            id: `urn:collegial:${input.learningObject.id}`,
            name: input.learningObject.name,
          },
          questionObject: {
            id: `urn:collegial:page:${input.questionObject.id}`,
            name: input.questionObject.name,
            type: input.questionObject.type,
          },
          questionAnswer: input.questionAnswer,
          timestamp: new Date(),
          registration: session?.registration || '',
        })
      ),
    isInitialized: !!session,
    loading: loadingUser,
  };
};
