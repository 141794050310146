import styled, { keyframes } from 'styled-components';

const backdropTransition = {
  enter: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  exit: keyframes`
    from {
      pointer-events: none;
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
};

export const Backdrop = styled.div<{ open: boolean }>`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: ${(p) => p.theme.transitions.normal}ms;
  animation-name: ${(p) => (p.open ? backdropTransition.enter : backdropTransition.exit)};
`;
