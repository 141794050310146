import { Card, LearningCard, ProductCard } from '@/types/learning/card';
import { getCardsForEventLearnings } from './getCardsForEventLearnings';
import { AssignmentLearningsCardProps, CardProps, CardWithProgressStatus, EntityExistenceProps } from './types';

export const getExistanceFlagByEntity = ({ entity, learnings, entityId }: EntityExistenceProps): boolean =>
  !!entityId &&
  ((entity === 'learning' && learnings.some((a) => a.learningId === entityId)) ||
    (entity === 'product' && learnings.some((a) => a.learningPathId === entityId)));

export const getCardsForLearnings = ({ learnings, catalogCards }: AssignmentLearningsCardProps): Card[] =>
  learnings.map(
    (item) =>
      (catalogCards.find((card) => card.entity === 'learning' && card.entityId === item.learningId) as LearningCard) ||
      (catalogCards.find((card) => card.entity === 'product' && card.entityId === item.learningPathId) as ProductCard)
  );

export const getCards: CardProps = ({
  myLearningCatalogCards,
  inventoryCards,
  catalogCards,
  participations,
  eventEnrollments,
  ...props
}) => {
  const eventCards = getCardsForEventLearnings({ learnings: props.learnings, catalogCards, participations, eventEnrollments });
  const learnings = props.learnings.filter((x) => !eventCards.some((m) => m.id === x.id));
  const myLearningCards = myLearningCatalogCards
    .filter((x) => getExistanceFlagByEntity({ entity: x.entity, entityId: x.entityId, learnings }))
    .map(
      (card): CardWithProgressStatus => ({
        card,
        status: card.participation?.isCompleted ? 'completed' : 'ongoing',
      })
    );

  const inventoryAssignmentCards = inventoryCards
    .filter((x) => getExistanceFlagByEntity({ entity: x.card.entity, entityId: x.card.entityId, learnings }))
    .map(
      (x): CardWithProgressStatus => ({
        card: x.card,
        status: x.card.participation?.isCompleted ? 'completed' : 'ongoing',
      })
    );

  const learningsCards = getCardsForLearnings({
    learnings,
    catalogCards,
  })
    .filter(
      (card) =>
        !!card &&
        !myLearningCards.some((x) => x.card.entity === card?.entity && x.card.entityId === card?.entityId) &&
        !inventoryAssignmentCards.some((x) => x.card.entity === card?.entity && x.card.entityId === card?.entityId)
    )
    .map(
      (item): CardWithProgressStatus => ({
        card: item,
        status: 'not-started',
      })
    );

  //Decided to never show obsolete events that a user never attends
  return myLearningCards.concat(inventoryAssignmentCards, learningsCards, eventCards).filter((m) => m.status !== 'obsolete');
};
