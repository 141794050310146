import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { Caption } from '@/components/Typography/Typography';

const LABEL_HEIGHT = '24px';

export enum TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  THIRD = 'third'
}

const COLORS = {
  primary: cvar('color-primary'),
  secondary: cvar('color-secondary'),
  third: cvar('color-black')
};

export const Badge = styled(Caption)<{ type: TYPES; fit?: boolean }>`
  background-color: ${props => COLORS[props.type]};
  border-radius: ${cvar('radius-inner')};
  color: ${props => cvar(props.type === TYPES.SECONDARY ? 'color-text' : 'color-white')};
  font-weight: ${cvar('font-weight-regular')};
  height: ${LABEL_HEIGHT};
  line-height: ${LABEL_HEIGHT};
  min-width: ${LABEL_HEIGHT};
  padding: 0 8px;
  white-space: nowrap;
  width: ${props => props.fit && 'fit-content'};
`;
