import { WeeklyLearningLoop } from '../../types';
import { WeeksNavigationProvider } from '../../context';
import { EndedWeeksSetup } from './EndedWeeksSetup';

type EndedWeeksNavigationProps = {
  weeks: WeeklyLearningLoop[];
  selectedWeek: WeeklyLearningLoop | null;
  maxVisibleWeeksCount?: number;
  onChangeSelectedWeek: (weekNumber: number) => void;
};

export const EndedWeeksNavigation = (props: EndedWeeksNavigationProps): JSX.Element => (
  <WeeksNavigationProvider>
    <EndedWeeksSetup {...props} />
  </WeeksNavigationProvider>
);
