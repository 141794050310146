import { FloatingLinkUrlInput as BaseFloatingLinkUrlInput } from '@udecode/plate-link/react';
import styled, { css } from 'styled-components';

export const OptionsPickerRootStyled = styled.div`
  display: flex;
  padding: 4px;
  gap: 4px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.neutral.container};
  align-items: center;
  border-radius: 6px;
  ${(p) => p.theme.shadow.primary[8]}
`;

export const EditLinkRootStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 4px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.neutral.container};
  border-radius: 6px;
  ${(p) => p.theme.shadow.primary[8]}
`;

export const EditLinkRowStyled = styled.div`
  display: flex;
  gap: 4px;
`;

export const EditIconWrapStyled = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.div<{ orientation: 'vertical' | 'horizontal' }>`
  ${({ orientation }) =>
    orientation === 'vertical'
      ? css`
          width: 1px;
          height: 24px;
        `
      : css`
          height: 1px;
          width: 100%;
        `}

  background: ${({ theme }) => theme.colors.neutral.container};
`;

const InputCSS = css`
  border: none;
  outline: none;
  ${(p) => p.theme.typography.body4};
`;
export const FloatingLinkUrlInput = styled(BaseFloatingLinkUrlInput)`
  ${InputCSS};
`;

export const TextDisplayStyled = styled.input`
  ${InputCSS}
`;
