import React from 'react';
import styled from 'styled-components';

import { Caption } from '../Typography/Typography';
import { IconPropType } from '@/ui/Icon';
import { Icon } from '../Icon/Icon';

const StyledMeta = styled(Caption).attrs((props) => ({ ...props, as: 'span' }))`
  background-color: transparent;
  color: var(--color-text);
  display: inline-block;
  & > svg {
    color: var(--color-silver);
  }
  & > span {
    padding-left: calc(var(--gap-xs) / 2);
  }
`;

const StyledMetaInBlock = styled(StyledMeta)`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 0.25rem;
  text-align: center;
  .fa-icon {
    &:first-child:not(:last-child) {
      margin-right: 5px;
    }
    &:last-child:not(:first-child) {
      margin-left: 5px;
    }
  }
`;

export const Meta = ({
  caption,
  icon,
  inBlock = false,
}: {
  caption?: string;
  icon?: IconPropType;
  inBlock?: boolean;
}): JSX.Element | null => {
  const Wrapper = inBlock ? StyledMetaInBlock : StyledMeta;

  if (!caption) return null;

  return (
    <Wrapper>
      {icon && <Icon icon={icon} />}
      <span>{caption}</span>
    </Wrapper>
  );
};
