import { useContext, useEffect, useState } from 'react';

import { EditorAssistantContext } from './EditorAssistantContext';
import { usageToCharge } from './utils/convertUsageToCharge';

export const useUsageCharge = (): {
  updateUsage: (usage: { completion_tokens: number; prompt_tokens: number; total_tokens: number }) => void;
} => {
  const { setTotalCharge } = useContext(EditorAssistantContext);

  const [usage, setUsage] = useState({
    completion_tokens: 0,
    prompt_tokens: 0,
    total_tokens: 0,
  });

  useEffect(() => {
    const prevUsageFromStorage = localStorage.getItem('gpt_usage');

    if (prevUsageFromStorage) {
      setUsage(JSON.parse(prevUsageFromStorage));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('gpt_usage', JSON.stringify(usage));

    setTotalCharge(usageToCharge(usage));
  }, [usage]);

  const updateUsage = (usage: { completion_tokens: number; prompt_tokens: number; total_tokens: number }) => {
    setUsage((prevUsage) => ({
      completion_tokens: prevUsage.completion_tokens + usage.completion_tokens,
      prompt_tokens: prevUsage.prompt_tokens + usage.prompt_tokens,
      total_tokens: prevUsage.total_tokens + usage.total_tokens,
    }));
  };

  return { updateUsage };
};
