import styled from 'styled-components';

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TimeContainerStyled = styled.div`
  display: flex;
  gap: 9px;
  align-items: flex-start;

  & > span {
    padding-top: 5px;
  }
`;
