import { XAPISession } from './useXAPISession';
import { useXAPIClient } from '@/store/xapi/useXAPIClient';
import { StateId, useXAPIState } from '@/store/xapi/useXAPIState';
import { ReactionId } from '@/administration/pages/Course/store/block/types';

export type XAPIReaction = {
  reactionId: ReactionId | undefined;
  isInitialized: boolean;
  setReactionId: (nextReactionId: ReactionId) => Promise<void>;
  loading: boolean;
  error?: Error;
};

export type StateType = ReactionId;

export const useXAPIReaction = (learningId?: number, learningName?: string, session?: XAPISession): XAPIReaction => {
  const {
    data: stateData,
    loading: stateLoading,
    isInitialized: isStateInitialized,
    setState: setXAPIState,
    error: stateError,
  } = useXAPIState<StateType>(StateId.REACTION, session);
  const { isInitialized: isXAPIClientInitialized, loading: loadingXAPIClient, setLearningReaction } = useXAPIClient(session);

  const setReactionId = async (nextReactionId: ReactionId): Promise<undefined> => {
    if (!isXAPIClientInitialized || !isStateInitialized) return;

    setLearningReaction(learningId || 0, nextReactionId, learningName || '');
    await setXAPIState(nextReactionId);

    return;
  };

  return {
    reactionId: stateData,
    isInitialized: isStateInitialized,
    setReactionId,
    loading: stateLoading || loadingXAPIClient,
    error: stateError,
  };
};
