import { SelectChip, SelectChipOption } from '@/ui/Chip/variants/SelectChip';

export const QUESTION_TYPES = {
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
};

export const QUESTION_TYPES_OPTIONS: SelectChipOption[] = [
  {
    label: 'Select one option',
    value: 'SINGLE_SELECT',
    icon: ['far', 'circle-dot'],
  },
  {
    label: 'Select all that apply',
    value: 'MULTI_SELECT',
    icon: ['far', 'square-check'],
  },
];

type QuestionTypeChipProps = {
  onChange: (option: SelectChipOption) => void;
  questionType: SelectChipOption;
};

export function QuestionTypeChip({ onChange, questionType }: QuestionTypeChipProps): JSX.Element {
  return (
    <SelectChip
      leadingIcon={questionType?.icon}
      options={QUESTION_TYPES_OPTIONS}
      trailingIcon="caret-down"
      onMenuItemClick={onChange}
    >
      {questionType?.label}
    </SelectChip>
  );
}
