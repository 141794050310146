import { useEffect, useState } from 'react';

export const useActiveElement = (): Element | null => {
  const [active, setActive] = useState<Element | null>(document.activeElement);

  const handleFocusIn = () => {
    setActive(document.activeElement);
  };

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  return active;
};
