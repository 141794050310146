import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { tabItemActive, tabItemBase, tabItemInactiveFocus, tabItemInactiveHover } from './styles';
import { Skeleton } from '@/ui/Skeleton';

type TabLinkProps = {
  /**
   * Text label for the tab link.
   */
  label?: string;
  /**
   * URL path for the tab, relative to the current resolved path.
   * @see {@link https://reactrouter.com/docs/en/v6/hooks/use-resolved-path useResolvedPath} unser react-router docs
   */
  path: string;
  /**
   * Search parameters to append to the URL.
   */
  searchParams?: string;
};

const Link = styled(NavLink)`
  ${tabItemBase}
  &.active {
    ${tabItemActive}
  }
  &:not(.active):hover {
    ${tabItemInactiveHover}
  }
  &:not(.active):not(:hover):focus {
    ${tabItemInactiveFocus};
  }
`;

const LoadingTab = () => <Skeleton width={110} height={28} />;

export const TabLink = ({ label, path, searchParams, className }: WithClassNameProp<TabLinkProps>): JSX.Element => (
  <Link
    to={{
      pathname: path,
      search: searchParams,
    }}
    className={({ isActive }) => clsx(className, isActive && 'active')}
  >
    {label}
  </Link>
);

export const TabLoadingLink = ({ path, className }: WithClassNameProp<TabLinkProps>): JSX.Element => (
  <Link to={path} className={({ isActive }) => clsx(className, isActive && 'active')}>
    <LoadingTab />
  </Link>
);
