import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

import { NavigationActions } from './NavigationActions';

const MultiPageStyled = styled.div`
  min-height: 100%;
  height: 100%;
  align-self: stretch;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-snap-align: center;
`;

export const PageBody = styled.div`
  background: ${({ theme }) => theme.tones.neutral[100]};
  border-radius: 20px;
  margin: 8px;

  min-height: calc(100% - 16px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 72px;
`;

export const MultiPageBody = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    backDisabled?: boolean;
    onBackClick: () => void;
    nextDisabled?: boolean;
    nextHidden?: boolean;
    onNextClick: () => void;
  }
>(
  ({ children, ...rest }, ref): JSX.Element => (
    <MultiPageStyled ref={ref}>
      <PageBody>
        {children}
        <NavigationActions {...rest} />
      </PageBody>
    </MultiPageStyled>
  )
);

MultiPageBody.displayName = 'MultiPageBody';
