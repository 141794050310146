import { PluralizeEntity } from '../component/MultiEmailInput/MultiEmailInput';

export function convertTitle(title?: string): string {
  if (!title) return '';
  return title
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();
}

export function clone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export const DisplayRealm: { [key: string]: string } = {
  cls: 'Collegial',
  campus: 'Collegial',
  fazer: 'Fazer',
  atlascopco: 'Atlas Copco',
  seb: 'SEB',
  scania: 'Scania',
  ericsson: 'Ericsson',
  saab: 'SAAB',
  wartsila: 'Wärtsilä',
  sse: 'SSE',
  epiroc: 'Epiroc',
  husqvarna: 'Husqvarna',
  storaenso: 'Stora Enso',
  electrolux: 'Electrolux',
  partner: 'Partner',
  mix: 'Mix',
  skf: 'Skf',
};

export const ENTER_CHAR_CODE = 13;

export function displayRealm(realm: string): string {
  return DisplayRealm[realm] || realm;
}

// Picks a label from whitelist by priority
export const pickLabel = (labels?: string[]): string | undefined => {
  if (!labels || !labels.length) return;

  // Labels with top priority starting at 0
  const visibleLabels = [
    { value: 'admin', priority: 0 },
    { value: 'moderator', priority: 1 },
    { value: 'member', priority: 2 },
  ];

  return visibleLabels
    .sort((a, b) => b.priority - a.priority) // sorting from low to hight priority
    .map(({ value }) => value) // retrieving the actual values
    .reduce((previousLabel?: string, currentLabel?: string): string | undefined => {
      // sets the label value until it gets to the highest possible
      return labels.find((target) => currentLabel === target) ? currentLabel : previousLabel;
    }, undefined);
};

export const pluralize = (count: number, typeEntity: PluralizeEntity): string => typeEntity[count === 0 || count > 1 ? 'p' : 's'];

export const screenSizes = {
  landscapeMin: 768,
  tabletMin: 840,
  desktopMin: 1024,
};
