import styled from 'styled-components';

import { useUser } from '@/administration/store/users/user';
import { useCurrentUser } from '@/store/currentUser';
import { Avatar } from '@/ui/Avatar';
import { shortenName } from '@/utils/text';
import { distanceToNow } from '@/utils/time';
import { BadgeLabel } from '@/ui/Label';

const RootStyled = styled.div`
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AuthorMetaStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const AuthorMetaNameStyled = styled.div`
  ${({ theme }) => theme.typography.body3};
`;

const CreatedAtStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
`;

export const AuthorHeader = ({ authorId, createdAt }: { authorId?: number; createdAt: string | Date }): JSX.Element | null => {
  const { user: authorUser, loading: authorUserLoading } = useUser(authorId);
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();

  if (authorUserLoading || !authorUser || currentUserLoading || !currentUser) return null;

  const isCurrentUserAuthor = authorUser.id === currentUser.id;

  const shortName = shortenName(authorUser?.username || '');

  return (
    <RootStyled>
      <AuthorMetaStyled>
        <Avatar text={shortName} image={authorUser?.profileImage} />
        <AuthorMetaNameStyled>{authorUser?.username}</AuthorMetaNameStyled>
        {isCurrentUserAuthor && <BadgeLabel badgeVariant="badge-text" text="You" />}
      </AuthorMetaStyled>

      <CreatedAtStyled>Submitted {distanceToNow(new Date(createdAt))}</CreatedAtStyled>
    </RootStyled>
  );
};
