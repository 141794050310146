import styled from 'styled-components';

import { Icon as RawIcon } from '@/ui/Icon';

export const HelpWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const HelpItem = styled.li`
  ${(p) => p.theme.typography.body1}
  color: ${(p) => p.theme.colors.neutral.onBackground};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
`;

export const IconBox = styled.div`
  // We need to match text's line-height here,
  // otherwise the icon doesn't align well vertically
  ${(p) => p.theme.typography.body1}
`;

export const Icon = styled(RawIcon)`
  color: ${(p) => p.theme.colors.neutral.outline2};
  height: 20px;
`;
