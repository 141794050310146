import { makeNamespacedComponent } from '@/ui/utils';
import { AssignmentCard as AssignmentCard_ } from './AssignmentCard';
import { Animation } from './Animation';
import { Title } from './Title';
import { Labels } from './Labels';
import { Footer } from './Footer';
import { Progress } from './Progress';
import { Loader } from './Loader';
import { MetaLabel } from '@/ui/Label';

export const AssignmentCard = makeNamespacedComponent(AssignmentCard_, {
  Animation,
  Title,
  Labels,
  Footer: makeNamespacedComponent(Footer, { Progress, Label: MetaLabel }),
  Loader,
});
