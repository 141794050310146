import styled from 'styled-components';

import { Modal } from '@/ui/Modal';

export const Title = styled.h2`
  ${(p) => p.theme.typography.title3}
  margin: 24px 0 16px 0;
`;

type ShowDescriptionModalProps = {
  open: boolean;
  onClose: () => void;

  description?: string;
  businessGoal?: string;
};

export const ShowDescriptionModal = ({
  open,
  onClose,
  description,
  businessGoal,
}: ShowDescriptionModalProps): JSX.Element | null => {
  return (
    <Modal open={open} onClose={onClose} size="tablet">
      <Modal.Title>{!!description ? 'About this journey' : 'Journey goals'}</Modal.Title>
      <Modal.Contents>
        {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
        {description && businessGoal && <Title>Journey goals</Title>}
        {businessGoal && <div dangerouslySetInnerHTML={{ __html: businessGoal }} />}
      </Modal.Contents>

      <Modal.Actions>{<Modal.Action action={onClose}>Close</Modal.Action>}</Modal.Actions>
    </Modal>
  );
};
