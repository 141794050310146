import type { PlateEditor } from '@udecode/plate-common/react';
import { getBlockAbove, insertNode, insertNodes, removeEmptyPreviousBlock, withoutNormalizing } from '@udecode/plate-common';
import { Path } from 'slate';

import { OVERRIDE_PLUGINS } from '@/component/customEditorV2/useCreateEditor';
import { VideoPlugin } from '@/component/customEditorV2/plugins/custom/videoPlugin';
import { DocumentPlugin } from '@/component/customEditorV2/plugins/custom/documentPlugin';
import { QuizQuestionPlugin } from '@/component/customEditorV2/plugins/custom/quizQuestionPlugin';
import { ImagePlugin } from '@/component/customEditorV2/plugins/custom/imagePlugin';

const insertBlockMap: Record<string, (editor: PlateEditor, type: string) => void> = {};

const ELEMENTS_WITH_MANDATORY_EMPTY_LINE_AFTER: string[] = [
  VideoPlugin.key,
  DocumentPlugin.key,
  QuizQuestionPlugin.key,
  ImagePlugin.key,
];

export const insertBlock = (editor: PlateEditor, type: string): void => {
  const customType = OVERRIDE_PLUGINS[type as keyof typeof OVERRIDE_PLUGINS]?.node?.type || type;

  withoutNormalizing(editor, () => {
    if (type in insertBlockMap) {
      insertBlockMap[type](editor, type);
    } else {
      const path = getBlockAbove(editor)?.[1];

      if (!path) return;

      const at = Path.next(path);

      insertNodes(editor, editor.api.create.block({ type: customType }), {
        at,
        select: true,
      });

      if (ELEMENTS_WITH_MANDATORY_EMPTY_LINE_AFTER.includes(customType)) {
        // inserting empty line after the node
        insertNode(editor, { type: 'paragraph', children: [{ text: '' }] });
      }
    }

    removeEmptyPreviousBlock(editor);
  });
};
