import styled, { css } from 'styled-components';

import { PlateElement } from './elements/PlateElement';

const Heading2CSS = css`
  ${({ theme }) => theme.typography.title2};
  color: ${({ theme }) => theme.colors.neutral.onBackground} !important;
  padding: 16px 0 0 0;
  margin: 0 !important;
`;

const Heading3CSS = css`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground} !important;
  padding: 16px 0 0 0;
  margin: 0 !important;
`;

export const Heading2 = styled.h2`
  ${Heading2CSS};
`;

export const Heading3 = styled.h3`
  ${Heading3CSS};
`;

export const Heading2PlateElement = styled(PlateElement)`
  ${Heading2CSS};
`;

export const Heading3PlateElement = styled(PlateElement)`
  ${Heading3CSS};
`;

export const Body1 = styled.p`
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.neutral.onBackground} !important;
  padding: 8px 0 0 0;
  margin: 0 !important;
  min-height: 28px;
`;

export const HeadingBold = styled.strong`
  font-weight: 800;
`;

export const BodyBold = styled.strong`
  font-weight: 700;
`;

export const ItalicText = styled.em`
  font-style: italic;
`;

export const UnderlineText = styled.u`
  text-decoration: underline;
`;

export const StrikethroughText = styled.span`
  text-decoration: line-through;
`;

const LinkCSS = css`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const Link = styled.a`
  ${LinkCSS};
`;

export const LinkPlateElement = styled(PlateElement)`
  ${LinkCSS};
`;

const UnorderedListCSS = css`
  padding: 8px 0 0 12px !important;
  list-style-type: disc;
  list-style-position: outside;
  & li:not(:first-child):not(:last-child) {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
`;

const OrderedListCSS = css`
  padding: 8px 0 0 12px !important;
  list-style-type: decimal;
  list-style-position: outside;
  & li:not(:first-child):not(:last-child) {
    margin-bottom: 6px !important;
    margin-top: 6px !important;
  }
`;

export const UnorderedList = styled.ul`
  ${UnorderedListCSS};
`;

export const OrderedList = styled.ol`
  ${OrderedListCSS};
`;

export const OrderedListPlateElement = styled(PlateElement)`
  ${OrderedListCSS};
`;

export const UnorderedListPlateElement = styled(PlateElement)`
  ${UnorderedListCSS};
`;

const ListItemCSS = css`
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ListItem = styled.li`
  ${ListItemCSS};
`;

export const ListItemPlateElement = styled(PlateElement)`
  ${ListItemCSS};
`;
