import React, { ReactNode } from 'react';

import { CircularProgressBar } from '@/ui/ProgressBar';
import {
  ActionsContainerStyled,
  AddLearningTimeBox,
  CurrentEffortStyled,
  EffortDescriptionBoxStyled,
  ExpectedEffortStyled,
  InnerCircleContentStyled,
  LoopCounterBoxStyled,
  LoopCounterCurrentIndexStyled,
  RootStyled,
} from './styles';
import { IconButton } from '@/ui/Button';
import { Tooltip } from '@/ui/Tooltip';
import { ChartProps } from './types';
import { Skeleton } from '@/ui/Skeleton';
import { makeNamespacedComponent } from '@/ui/utils';
import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';

const _Chart = ({
  totalLoopsCount,
  currentEffort,
  expectedEffort,
  currentLoopIndex,
  onAddLearningTimeClick,
  actions,
}: ChartProps): JSX.Element => {
  return (
    <RootStyled>
      <LoopCounterBoxStyled>
        <LoopCounterCurrentIndexStyled>{currentLoopIndex}</LoopCounterCurrentIndexStyled>/{totalLoopsCount}
      </LoopCounterBoxStyled>
      <CircularProgressBar size={250} strokeWidth={25} value={currentEffort} maxValue={expectedEffort} gap={30} rounded>
        <InnerCircleContentStyled>
          <EffortDescriptionBoxStyled>
            <CurrentEffortStyled>{formatToHoursAndMinutesFromSeconds(currentEffort)}</CurrentEffortStyled>
            <ExpectedEffortStyled>of {formatToHoursAndMinutesFromSeconds(expectedEffort)} minimum</ExpectedEffortStyled>
          </EffortDescriptionBoxStyled>
          {onAddLearningTimeClick && (
            <AddLearningTimeBox>
              <Tooltip title="Add learning time">
                <IconButton buttonVariant="dark" icon="plus" onClick={onAddLearningTimeClick} />
              </Tooltip>
            </AddLearningTimeBox>
          )}
        </InnerCircleContentStyled>
      </CircularProgressBar>
      {actions && <ActionsContainerStyled>{actions}</ActionsContainerStyled>}
    </RootStyled>
  );
};

const LoadingState = ({ actions }: { actions?: ReactNode }): JSX.Element => {
  return (
    <RootStyled>
      <LoopCounterBoxStyled>
        <Skeleton width={72} height={24} />
      </LoopCounterBoxStyled>
      <CircularProgressBar size={250} strokeWidth={25} value={0} gap={30} rounded>
        <InnerCircleContentStyled>
          <Skeleton width={84} height={36} />
        </InnerCircleContentStyled>
      </CircularProgressBar>
      {actions && <ActionsContainerStyled>{actions}</ActionsContainerStyled>}
    </RootStyled>
  );
};

const Chart = makeNamespacedComponent(_Chart, {
  LoadingState,
});

export { Chart };
