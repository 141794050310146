import { Card, ProgressCard } from '@/types/learning/card';
import { LearningPinned, LearningPinnedUpdateInput } from '@/store/learning-pinned';
import { BannerElement } from '../../billboardStickyWithLearnings/types';
import { getLink } from '@/utils/card';

export const mapCardToLearningPinnedUpdateInput = (item: ProgressCard, realm: string): LearningPinnedUpdateInput => ({
  realm: realm,
  entity: item.entity,
  entityId: item.entityId,
});

export const mapLearningPinnedToCard = (
  pinnedLearnings: readonly LearningPinned[],
  getCollectionCard: (id: number) => Card | undefined,
  getLearningCard: (id: number) => Card | undefined,
  getProductCard: (id: number) => Card | undefined,
  getJourneyCard: (id: number) => Card | undefined
): Card[] => {
  if (pinnedLearnings.length === 0) {
    return [];
  }

  return pinnedLearnings.reduce((acc, curr) => {
    let item: Card | undefined;
    if (curr.learningId) {
      item = getLearningCard(curr.learningId);
    } else if (curr.learningPathId) {
      item = getProductCard(curr.learningPathId);
    } else if (curr.learningCollectionId) {
      item = getCollectionCard(curr.learningCollectionId);
    } else if (curr.learningJourneyId) {
      item = getJourneyCard(curr.learningJourneyId);
    }
    if (item) {
      acc.push(item);
    }
    return acc;
  }, [] as Card[]);
};

export const mapLearningPinnedToBannerElement = (
  pinnedLearnings: readonly LearningPinned[],
  getCollectionCard: (id: number) => Card | undefined,
  getLearningCard: (id: number) => Card | undefined,
  getProductCard: (id: number) => Card | undefined,
  getJourneyCard: (id: number) => Card | undefined
): BannerElement[] => {
  if (pinnedLearnings.length === 0) {
    return [];
  }
  return pinnedLearnings.reduce((acc, curr) => {
    let item: BannerElement | undefined;
    if (curr.learningId) {
      const card = getLearningCard(curr.learningId);
      if (card) {
        item = { ...card, link: getLink(card) };
      }
    } else if (curr.learningPathId) {
      const card = getProductCard(curr.learningPathId);
      if (card) {
        item = { ...card, link: getLink(card) };
      }
    } else if (curr.learningCollectionId) {
      const card = getCollectionCard(curr.learningCollectionId);
      if (card) {
        item = { ...card, link: getLink(card) };
      }
    } else if (curr.learningJourneyId) {
      const card = getJourneyCard(curr.learningJourneyId);
      if (card) {
        item = { ...card, link: getLink(card) };
      }
    }
    if (item) {
      acc.push(item);
    }
    return acc;
  }, [] as BannerElement[]);
};
