import styled from 'styled-components';

import { Body } from './Body';
import { Cell } from './Cell';
import { Row } from './Row';

const Text = styled.p`
  ${(p) => p.theme.typography.body3};
  color: ${(p) => p.theme.colors.neutral.outline2};
  padding: 0 16px;
  text-align: center;
`;

export const EmptyBody = ({ children, colSpan }: { children: React.ReactNode; colSpan: number }): JSX.Element => {
  const hasSimpleText = typeof children === 'string';
  return (
    <Body>
      <Row>
        <Cell colSpan={colSpan} minimal>
          {hasSimpleText ? <Text>{children}</Text> : children}
        </Cell>
      </Row>
    </Body>
  );
};
