import { UserSelfTrackedLearningsTypes } from '@/component/UserSelfTrackedLearning/types';

export const LEARNING_TYPES: { value: string; type: UserSelfTrackedLearningsTypes }[] = [
  {
    value: 'Book',
    type: UserSelfTrackedLearningsTypes.BOOK,
  },
  {
    value: 'Video',
    type: UserSelfTrackedLearningsTypes.VIDEO,
  },
  {
    value: 'Podcast',
    type: UserSelfTrackedLearningsTypes.PODCAST,
  },
  {
    value: 'Article',
    type: UserSelfTrackedLearningsTypes.ARTICLE,
  },
  {
    value: 'Program',
    type: UserSelfTrackedLearningsTypes.PROGRAM,
  },
  {
    value: 'Workshop',
    type: UserSelfTrackedLearningsTypes.WORKSHOP,
  },
  {
    value: 'Life-event',
    type: UserSelfTrackedLearningsTypes.LIVE_EVENT,
  },
  {
    value: 'Other',
    type: UserSelfTrackedLearningsTypes.OTHER,
  },
];

export enum TextFieldName {
  Minutes = 'minutes',
  Hours = 'hours',
  LearningLink = 'learningLink',
  LearningTitle = 'title',
  LearningDescription = 'learningDescription',
  LearningType = 'learningType',
}
