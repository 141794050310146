import { useCurrentUser } from '@/store/currentUser';
import {
  RCreateUserLearningSubmissionReviewMutation,
  RUserLearningSubmissionReviewType,
  useCreateUserLearningSubmissionReviewMutationRemote,
} from '@/store/v2';

type UseCreateUserLearningSubmissionReviewHook = () => {
  createUserLearningSubmissionReview: (
    input: {
      pageId: number;
      submissionId: number;
      data: {
        text: string;
      };
    },
    onSuccess?: () => void
  ) => Promise<{ errors?: unknown | undefined; data?: RCreateUserLearningSubmissionReviewMutation | null | undefined }>; // RCreateUserLearningSubmissionMutation
  loading: boolean;
};

export const useCreateUserLearningSubmissionReview: UseCreateUserLearningSubmissionReviewHook = () => {
  const [createUserLearningSubmissionReview, { loading }] = useCreateUserLearningSubmissionReviewMutationRemote();

  const { user } = useCurrentUser();
  const userId = Number(user?.id);

  return {
    createUserLearningSubmissionReview: async ({ data, pageId, submissionId }, onSuccess?: () => void) => {
      return await createUserLearningSubmissionReview({
        variables: {
          input: {
            data,
            pageId,
            reviewerId: userId,
            submissionId,
            type: RUserLearningSubmissionReviewType.PracticalAssessmentReviewText,
          },
        },
        update(cache, { data }) {
          if (data?.createUserLearningSubmissionReview) {
            cache.evict({ fieldName: 'userLearningSubmissions' });

            onSuccess && onSuccess();
          }
        },
      });
    },
    loading,
  };
};
