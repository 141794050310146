import { gql, useQuery } from '@apollo/client';

import { User } from '@/types/group';

export const QUERY_USER_BY_ID = gql`
  query getUserById($userId: Int!) {
    getUserById(userId: $userId) {
      id
      email
      realm
      username
      status
      profileImage
      hasPassword
      lastActive
      timestamp
    }
  }
`;

// TODO: Refine getting user query
export const useUser = (userId?: number): { user?: User; loading: boolean } => {
  const { data, loading } = useQuery<{ getUserById: User }>(QUERY_USER_BY_ID, { variables: { userId: userId }, skip: !userId });
  return { user: data?.getUserById, loading };
};
