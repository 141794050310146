import { gql, useMutation, useQuery, FetchResult, MutationFunctionOptions, ApolloError, ApolloQueryResult } from '@apollo/client';

import { HookResult } from '@/store/apolloClient';

const LEARNING_PATH_COMPLETED_QUERY = gql`
  query isLearningPathCompleted($learningPathId: Int!) {
    isLearningPathCompleted(learningPathId: $learningPathId)
  }
`;

const SET_LEARNING_PATH_COMPLETED = gql`
  mutation completeLearningPath($learningPathId: Int!) {
    completeLearningPath(learningPathId: $learningPathId)
  }
`;

interface LearningPathCompletedResponse extends HookResult {
  isLearningPathCompleted: boolean;
  refetch: (variables?: { learningPathId?: number }) => Promise<ApolloQueryResult<{ isLearningPathCompleted: boolean }>>;
}

export const useLearningPathCompleted = (learningPathId: number): LearningPathCompletedResponse => {
  const { data, error, refetch, loading, networkStatus } = useQuery<{ isLearningPathCompleted: boolean }>(
    LEARNING_PATH_COMPLETED_QUERY,
    {
      variables: { learningPathId },
      fetchPolicy: 'network-only',
      skip: !learningPathId || isNaN(learningPathId),
    }
  );

  return {
    isLearningPathCompleted: !!data?.isLearningPathCompleted,
    loading,
    error,
    refetch,
    networkStatus,
  };
};

interface SetLearningPathCompletedResponse {
  setLearningPathCompleted: (variables?: MutationFunctionOptions<void, { learningPathId: number }>) => Promise<FetchResult<void>>;
  loading: boolean;
  error?: ApolloError;
}

export const useSetLearningCompleted = (learningPathId: number, onComplete?: () => void): SetLearningPathCompletedResponse => {
  const [setLearningPathCompleted, { loading, error }] = useMutation<void, { learningPathId: number }>(
    SET_LEARNING_PATH_COMPLETED,
    {
      variables: { learningPathId },
      fetchPolicy: 'network-only',
      onCompleted: () => onComplete && onComplete(),
    }
  );

  return {
    setLearningPathCompleted,
    loading,
    error,
  };
};
