import React, { useEffect, useMemo, useState } from 'react';

import {
  ActionsStyled,
  ContentStyled,
  PreviewContainer,
  RootStyled,
  TextStyled,
  TitleStyled,
  UploaderContainerStyled,
} from './styles';
import { toHtml } from '@/component/customEditor/logic/serialization';
import { ViewPracticalAssessmentDocumentPageProps } from './types';
import { UploadFile } from '@/administration/pages/Course/blocks/document/components/UploadFile';
import { useFileUploader } from '@/component/FileUpload/useFileUpload';
import {
  RUserLearningSubmissionReview,
  RUserLearningSubmissionReviewAssignment,
  RUserLearningSubmissionType,
  useUserLearningSubmissionsQueryRemote,
} from '@/store/v2';
import { useCreateUserLearningSubmission } from '@/pages/PracticalAssessment/store/useCreateUserLearningSubmission';
import { DocumentPreview } from '@/administration/pages/Course/blocks/document/components/DocumentPreview';
import { Button } from '@/ui/Button';
import { SubmissionResult } from './SubmissionResult';
import { useCurrentUser } from '@/store/currentUser';
import { Skeleton } from '@/ui/Skeleton';
import { AssessmentTab, AssessmentViewerTabs } from '../../../common/tabs/AssessmentViewerTabs';
import { AssessmentSubmitted } from '../../../common/banners';

export const ViewPracticalAssessmentDocumentPage = ({
  description,
  title,
  spaceId,
  remoteId: pageRemoteId,
  learningId,
  peerReviewEnabled,
  onSubmitted: onCompleted,
}: ViewPracticalAssessmentDocumentPageProps): JSX.Element => {
  const [documentMeta, setDocumentMeta] = useState<{ fileName?: string; url?: string }>();
  const { createUserLearningSubmission, loading } = useCreateUserLearningSubmission();
  const [submissionResult, setSubmissionResult] = useState<{ createdAt: string; document: { fileName: string; url: string } }>();
  const [assessmentsToReview, setAssessmentsToReview] = useState<RUserLearningSubmissionReviewAssignment[]>([]);
  const [receivedReviews, setReceivedReviews] = useState<RUserLearningSubmissionReview[]>([]);
  const [activeTab, setActiveTab] = useState(AssessmentTab.MySubmission);

  const { user } = useCurrentUser();

  const {
    data: remoteSubmissions,
    loading: loadingSubmissions,
    refetch,
  } = useUserLearningSubmissionsQueryRemote({
    variables: {
      input: {
        learningIdsIN: learningId ? [learningId] : [],
        userIdsIN: user ? [user.id] : [],
        pageIdsIN: pageRemoteId ? [pageRemoteId] : [],
      },
    },
    skip: !pageRemoteId || !learningId || !user?.id,
  });

  const documentUpload = useFileUploader({
    spaceId,
    type: 'document',
    onUploadStart: () => setDocumentMeta({ url: '' }),
    onUploadSuccess: ({ url, name }) => setDocumentMeta({ url, fileName: name }),
  });

  useEffect(() => {
    if (remoteSubmissions?.userLearningSubmissions.length) {
      const currentRemoteSubmission = remoteSubmissions.userLearningSubmissions[0];

      if (currentRemoteSubmission) {
        if (currentRemoteSubmission.reviewAssignments) {
          setAssessmentsToReview(currentRemoteSubmission.reviewAssignments as RUserLearningSubmissionReviewAssignment[]);
        }

        if (currentRemoteSubmission.reviews) {
          setReceivedReviews(currentRemoteSubmission.reviews);
        }

        setSubmissionResult({
          createdAt: new Date(currentRemoteSubmission.createdAt).toISOString(),
          document: {
            fileName: '',
            url: currentRemoteSubmission.data.text,
          },
        });
      }
    }
  }, [remoteSubmissions]);

  const handleActiveTabChange = (tabValue: number) => {
    setActiveTab(tabValue);
  };

  const handleSubmit = async () => {
    if (pageRemoteId && learningId && documentMeta?.fileName && documentMeta?.url) {
      await createUserLearningSubmission({
        pageId: pageRemoteId,
        learningId,
        submissionData: {
          text: documentMeta.url,
        },
        assessmentType: RUserLearningSubmissionType.PracticalAssessmentText,
      });

      refetch();
    }
  };

  const hasReceivedReviews = Boolean(receivedReviews.length);
  const hasSubmitted = Boolean(submissionResult);
  const hasReviewedPeers = useMemo(
    () =>
      Boolean(assessmentsToReview.length) &&
      assessmentsToReview.every((assessment) => assessment.submission.reviews.some((review) => review.reviewerId === user?.id)),
    [assessmentsToReview, user]
  );

  const shouldShowSubmittedBanner = hasSubmitted && peerReviewEnabled;

  useEffect(() => {
    if (!hasSubmitted) return;

    if (peerReviewEnabled && !hasReviewedPeers) return;

    onCompleted();
  }, [hasSubmitted, hasReviewedPeers, peerReviewEnabled]);

  const renderSubmissionContainer = () => {
    if (loadingSubmissions) {
      return <Skeleton height={254} />;
    }

    if (submissionResult?.document) {
      return (
        <SubmissionResult
          peerReviewEnabled={peerReviewEnabled}
          document={submissionResult.document}
          createdAt={submissionResult.createdAt}
          documentUpload={documentUpload}
          receivedReviews={receivedReviews}
        />
      );
    }

    return (
      <UploaderContainerStyled>
        {documentMeta?.url ? (
          <PreviewContainer>
            <DocumentPreview documentUrl={documentMeta.url} documentUpload={documentUpload} fileName={documentMeta.fileName} />
            <ActionsStyled>
              <Button variant="primary" onClick={handleSubmit} $loading={loading}>
                Submit
              </Button>
            </ActionsStyled>
          </PreviewContainer>
        ) : (
          <UploadFile disabled={false} documentUpload={documentUpload} />
        )}
      </UploaderContainerStyled>
    );
  };

  return (
    <RootStyled>
      <ContentStyled>
        <TitleStyled>{title}</TitleStyled>
        <TextStyled className="content is-article" dangerouslySetInnerHTML={{ __html: toHtml(description) }}></TextStyled>
        <>
          {shouldShowSubmittedBanner && (
            <AssessmentSubmitted
              hasReceivedReviews={hasReceivedReviews}
              hasReviewedPeers={hasReviewedPeers}
              onReviewPeersClick={() => setActiveTab(AssessmentTab.PeersToReview)}
              onCheckReviewClick={() => setActiveTab(AssessmentTab.MySubmission)}
            />
          )}
          {peerReviewEnabled ? (
            <AssessmentViewerTabs
              submissionFormSection={renderSubmissionContainer()}
              submitted={!!submissionResult}
              assessmentsToReview={assessmentsToReview}
              pageRemoteId={pageRemoteId}
              type="document"
              activeTab={activeTab}
              onActiveTabChange={handleActiveTabChange}
              spaceId={spaceId}
            />
          ) : (
            renderSubmissionContainer()
          )}
        </>
      </ContentStyled>
    </RootStyled>
  );
};
