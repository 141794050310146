import React from 'react';
import { PlateLeaf, PlateLeafProps } from '@udecode/plate-common/react';

import { ItalicText } from '@/component/customEditorV2/ui/Typography';

export const ItalicLeaf: React.FC<PlateLeafProps> = (props: any) => {
  const { leaf, children, ...rest } = props;

  const ItalicComponent = ItalicText;

  return (
    <PlateLeaf {...rest} leaf={leaf}>
      <ItalicComponent>{children}</ItalicComponent>
    </PlateLeaf>
  );
};
