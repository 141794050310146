import { SortPriorityOrder } from './constants';
import { PriorityProps } from './types';

export const getStandardPriority = ({ status, type }: PriorityProps): number => {
  if (type === 'MANDATORY') {
    if (status === 'completed') {
      return SortPriorityOrder.MandatoryCompleted;
    } else if (status === 'ongoing') {
      return SortPriorityOrder.MandatoryOngoing;
    } else {
      return SortPriorityOrder.MandatoryNotStarted;
    }
  } else {
    if (status === 'completed') {
      return SortPriorityOrder.RecommendCompleted;
    } else if (status === 'ongoing') {
      return SortPriorityOrder.RecommendOngoing;
    } else {
      return SortPriorityOrder.RecommendNotStarted;
    }
  }
};
