import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GraphQLFormattedError } from 'graphql';
import { useEffect } from 'react';

import { Card } from '@/types/learning/card';
import { Direction } from '@/administration/types/direction';
import { useCardCatalog } from '@/store/catalog';
import { decorateReorder } from '@/administration/pages/LearningEditor/util';
import { getRealm } from '@/utils/realms';
import { useLearningPinned, useLearningPinnedUpdate } from '@/store/learning-pinned';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { mapCardToLearningPinnedUpdateInput, mapLearningPinnedToCard } from './utils';
import { LearningSelection } from './LearningSelection';
import { SpinningLoader } from '../loader/SpinningLoader';
import { Modal } from '@/ui/Modal';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PinnedLearningsModal = ({ open, setOpen }: Props): JSX.Element => {
  const realm = getRealm();
  const { t } = useTranslation(['home', 'builderAdmin']);
  const { learningsPinned, loading: learningPinnedLoading } = useLearningPinned(realm);
  const { getCollectionCard, getLearningCard, getProductCard, getJourneyCard, loading: cardCatalogLoading } = useCardCatalog();

  const { learningPinnedUpdate, loading: loadingSubmit } = useLearningPinnedUpdate();
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const [learningItems, setLearningItems] = useState<Card[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const loading = learningPinnedLoading || cardCatalogLoading;

  useEffect(() => {
    if (!loading && !hasSubmitted) {
      setLearningItems(
        mapLearningPinnedToCard(learningsPinned, getCollectionCard, getLearningCard, getProductCard, getJourneyCard)
      );
    }
  }, [loading, hasSubmitted]);

  const updateItems = (newItems: Card[]) => {
    setLearningItems(newItems);
    setHasChanges(true);
  };
  const handleAddItem = (card: Card) => updateItems(learningItems?.concat([card]) || [card]);
  const handleRemoveItem = (card: Card) => updateItems(learningItems?.filter((item) => item.id !== card.id));
  const handleReorder = (id: string, direction: Direction) =>
    updateItems(decorateReorder(learningItems, direction, (i) => i.id === id));

  const showError = (errors: readonly GraphQLFormattedError[]) =>
    openSnackbar({
      message: t('serverMessages::generic|:error-occurred', { message: errors }),
      type: SnackbarType.WARNING,
    });

  const onSubmit = useCallback(async () => {
    if (learningItems) {
      const itemsToSave = learningItems.map((x) => mapCardToLearningPinnedUpdateInput(x, realm));
      const { errors, data } = await learningPinnedUpdate(realm, itemsToSave);
      if (errors?.length) {
        showError(errors);
        return;
      }

      setHasSubmitted(true);
      setLearningItems(
        mapLearningPinnedToCard(
          data?.learningPinnedUpdate ?? [],
          getCollectionCard,
          getLearningCard,
          getProductCard,
          getJourneyCard
        )
      );
      setHasChanges(false);
      openSnackbar({
        message: 'Saved Successfully.',
        type: SnackbarType.SUCCESS,
        isDismissive: true,
      });
    }
  }, [learningItems]);

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="mobileLandscape">
      <Modal.Title>Featured learning items</Modal.Title>
      <Modal.Contents>
        {loading || loadingSubmit ? (
          <SpinningLoader />
        ) : (
          <LearningSelection
            pinnedLearnings={learningItems}
            handleAddItem={handleAddItem}
            handleRemoveItem={handleRemoveItem}
            handleReorder={handleReorder}
          />
        )}
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={() => setOpen(false)} disabled={loadingSubmit}>
          Cancel
        </Modal.Action>
        <Modal.Action primary action={onSubmit} disabled={!hasChanges}>
          Apply
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
