import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as tus from 'tus-js-client';

import { useFileUploaderProps, FileUploader, FileUploaderError, FileType, UploadedFile, UploadingState } from './types';
import { checkImageDimensions, getMeta } from './helpers';
import { api } from '@/utils/lib/api';
import { trimFilenameExtension } from '@/administration/utils';
import { checkVideoStatus } from '@/administration/component/VideoUpload/util';

export const useFileUploader = <T extends FileType>({
  onUploadSuccess: onUploadSuccessCb,
  onUploadStart: onUploadStartCb,
  onUploadFail: onUploadFailCb,
  onValidationFail: onValidationFailCb,
  spaceId,
  type,
  minDimensions,
}: useFileUploaderProps<T>): FileUploader<T> => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile<T> | undefined>();
  const [uploading, setUploading] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploadingState, setUploadingState] = useState<UploadingState>();
  const [error, setError] = useState<FileUploaderError>();
  const { t } = useTranslation('fileUpload');

  const onUploadSuccess = (file: UploadedFile<T>, fileLoadingId?: string) => {
    setUploading(false);
    setUploadingProgress(0);
    setUploadingState('Completed');
    setError(undefined);

    setUploadedFile(file);
    onUploadSuccessCb(file, fileLoadingId);
  };

  const onUploadFail = (fileLoadingId?: string) => {
    setUploading(false);
    setUploadingProgress(0);
    setUploadingState(undefined);
    setError({
      title: t('Something went wrong'),
      message:
        type === 'video'
          ? t("Your video could not be uploaded. Try again or start over if that doesn't do the trick.")
          : t("Your file could not be uploaded. Try again or start over if that doesn't do the trick."),
    });

    if (fileLoadingId) {
      onUploadFailCb && onUploadFailCb(fileLoadingId);
    }
  };

  const onValidationFail = (err: FileUploaderError, fileLoadingId?: string) => {
    setUploading(false);
    setUploadingProgress(0);
    setUploadingState(undefined);
    setError(err);

    if (fileLoadingId) {
      onValidationFailCb && onValidationFailCb(fileLoadingId);
    }
  };

  const uploadFile = async (file: File, fileLoadingId?: string) => {
    setUploading(true);
    setUploadingProgress(0);
    setUploadingState('Uploading');
    setUploadedFile(undefined);

    if (onUploadStartCb) onUploadStartCb(fileLoadingId);

    if (spaceId === undefined) {
      onValidationFail({
        title: t('Something went wrong'),
        message: t('image-upload-space'),
      });
      return;
    }

    if (file.size > getMeta(type).sizeLimit) {
      onValidationFail(
        {
          title: t('Something went wrong'),
          message: t(type === 'image' ? 'image-size-limit-error' : 'file-too-large'),
        },
        fileLoadingId
      );

      return;
    }

    if (type === 'image' && minDimensions) {
      const { width, height } = await checkImageDimensions(file);
      if (width < minDimensions.width && height < minDimensions.height) {
        onValidationFail(
          {
            title: t('Something went wrong'),
            message: t('image-dimension-validation-fail'),
          },
          fileLoadingId
        );
        return;
      }
    }

    // validate type
    switch (type) {
      case 'image': {
        return api
          .uploadImage(file, spaceId, setUploadingProgress)
          .then(({ path }) => onUploadSuccess({ url: path } as UploadedFile<T>, fileLoadingId))
          .catch(() => onUploadFail(fileLoadingId));
      }
      case 'document': {
        return api
          .uploadResource(file, spaceId, 'file', setUploadingProgress)
          .then(({ path }) => onUploadSuccess({ url: path, name: file.name } as UploadedFile<T>))
          .catch(onUploadFail);
      }
      case 'video': {
        const video = document.createElement('video');
        video.preload = 'metadata';

        let duration: number;
        let ratio: number;
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          duration = video.duration;
          ratio = video.videoHeight / video.videoWidth;
        };

        video.src = URL.createObjectURL(file);

        return api.uploadLink(trimFilenameExtension(file.name), file.size).then(({ vimeoId, uploadLink }) => {
          const upload = new tus.Upload(file, {
            uploadUrl: uploadLink,
            endpoint: '',
            resume: true,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
              filename: file.name,
              filetype: file.type,
            },
            uploadSize: file.size,
            onProgress: (bytesUploaded, bytesTotal) => {
              const percentage = Math.round((bytesUploaded / bytesTotal) * 100 * 1e2) / 1e2;
              setUploadingProgress(percentage);
            },
            onError: () => onUploadFail(),
            onSuccess: async () => {
              setUploadingState('Transcoding');
              setUploadedFile({ vimeoId, duration, ratio } as UploadedFile<T>);

              const thumbnail = await checkVideoStatus(vimeoId);
              onUploadSuccess({ thumbnail, vimeoId, duration, ratio } as UploadedFile<T>);
            },
          });

          upload.start();
        });
      }
    }
  };

  return { uploadFile: uploadFile, uploading, uploadingProgress, error, uploadingState, uploadedFile };
};
