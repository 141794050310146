import React, { useCallback } from 'react';

import { Table } from '../Table';
import { AutoTableProps, AnyDataItem } from './types';

export const HeaderItem = <DataItem extends AnyDataItem, SortKey extends string>({
  column: { header, align, shrink, expand, sortKey, tooltip },
  currentSortKey,
  currentSortOrder,
  onSort,
  condensed,
}: {
  column: AutoTableProps<DataItem, SortKey>['columnDeclaration'][number];
  currentSortKey: AutoTableProps<DataItem, SortKey>['currentSortKey'];
  currentSortOrder: AutoTableProps<DataItem, SortKey>['currentSortOrder'];
  onSort?: AutoTableProps<DataItem, SortKey>['onSort'];
  condensed?: boolean;
}): JSX.Element => {
  const sortable = !!sortKey;
  const sortOrder = currentSortKey === sortKey ? currentSortOrder : undefined;

  const handleSortChange = useCallback(
    (nextOrder: 'asc' | 'desc') => {
      if (!sortKey || !onSort) return;
      onSort(sortKey, nextOrder);
    },
    [sortKey, onSort]
  );

  return (
    <Table.HeaderCell
      align={align}
      condensed={condensed}
      shrink={shrink}
      expand={expand}
      sortable={sortable}
      tooltip={tooltip}
      order={sortOrder}
      onOrderChange={handleSortChange}
    >
      {header}
    </Table.HeaderCell>
  );
};
