import { ReactEditor, useSlate } from 'slate-react';
import { Editor } from 'slate';

import { TextFormats } from '@/types/slate';

type MarksLogic = {
  editor: Editor & ReactEditor;
  isMarkActive: (format: TextFormats) => boolean;
  hasMark: (format: TextFormats) => boolean;
  toggleMark: (format: TextFormats) => void;
};

export const useMarks = (): MarksLogic => {
  const editor = useSlate();

  const toggleMark = (format: TextFormats) => {
    const isActive = isMarkActive(format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const hasMark = (format: TextFormats): boolean => {
    const marks = Editor.marks(editor);

    return marks ? marks[format] === true : false;
  };

  const isMarkActive = (format: TextFormats) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  return {
    editor,
    hasMark,
    isMarkActive,
    toggleMark,
  };
};
