import styled from 'styled-components';

import { ListStyledProps } from './types';

const ListOptionsTop = `
top: auto;
bottom: 100%;
`;
const ListOptionsBottom = `
bottom: auto;
top: 100%;
`;
const ListOptionsRight = `
right: auto;
left: 0%;
`;

const ListOptionsLeft = `
right: 0%;
left: auto;
`;

export const ListStyled = styled.ul<ListStyledProps>`
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  box-sizing: border-box;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '240px')};
  margin: 4px 0;
  padding: 8px 0;
  overflow-y: scroll;
  list-style-type: none;
  z-index: 100;
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[8]};
  border-radius: 8px;

  ${({ position }) => {
    switch (position) {
      case 'top-right':
        return `
          ${ListOptionsTop}
          ${ListOptionsRight}
        `;
      case 'bottom-left':
        return `
          ${ListOptionsBottom}
          ${ListOptionsLeft}
        `;
      case 'bottom-right':
        return `
          ${ListOptionsBottom}
          ${ListOptionsRight}
        `;
      case 'top-left':
        return `
          ${ListOptionsTop}
          ${ListOptionsLeft}
        `;
    }
  }}

  position: absolute;
  display: ${({ shouldShowOptions }) => (shouldShowOptions ? 'block' : 'none')};
  // We hide options until it's safe to set them to visible
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
