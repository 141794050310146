import React from 'react';

import { IconPropType } from '@/ui/Icon';
import { Button } from './Button';
import { ButtonSizeUnion, ButtonType } from './types';
import { SimpleButtonGroup } from './styles/buttonGroupStyles';

interface ButtonGroupProps<T> {
  active?: T;
  data: { value: T; label: string }[];
  disabled?: boolean;
  handleClick: (t: T) => void;
  icon?: IconPropType;
  padded?: boolean;
  size?: ButtonSizeUnion;
  type?: ButtonType;
  vertical?: boolean;
  fullwidth?: boolean;
}

export function ButtonGroup<T>({
  type,
  handleClick,
  active,
  data,
  icon,
  size,
  disabled,
  fullwidth,
  ...otherProps
}: ButtonGroupProps<T>): JSX.Element {
  return (
    <SimpleButtonGroup $fullwidth={fullwidth} {...otherProps}>
      {data.map(({ value, label }, idx) => {
        return (
          <Button
            $type={type}
            key={idx}
            $toggled={value === active}
            $size={size}
            disabled={disabled}
            onClick={() => handleClick(value)}
            $icon={icon}
            $fullwidth={fullwidth}
          >
            {label}
          </Button>
        );
      })}
    </SimpleButtonGroup>
  );
}
