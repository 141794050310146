import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

import { CIconProps } from '@/types/icon';

const BASE_CLASS = 'fa-icon';
export const Icon = ({ className, icon, size, ...restProps }: CIconProps): JSX.Element | null => {
  if (!icon) {
    return null;
  }

  return <FontAwesomeIcon icon={icon as IconProp} size={size} className={clsx(className, BASE_CLASS)} {...restProps} />;
};
