import { Control, Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ImageUpload } from '@/component/FileUpload/ImageUpload';
import defaultImage from '@/administration/pages/Journey/common/assets/defaultCover.png';
import { FormSchema, TextFieldName } from '../..';

const ImageRelativeWrapperStyled = styled.div`
  position: relative;
`;

const ImageFieldContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ImageStyled = styled.img`
  height: 216px;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

const UploadActionStyled = styled(ImageUpload)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
`;

const ImageUploadErrorStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error.main};
`;

export const FormCoverImage = ({ control, spaceId }: { control: Control<FormSchema>; spaceId: number }): JSX.Element => {
  const { setError, clearErrors } = useFormContext<FormSchema>();

  return (
    <Controller
      control={control}
      name={TextFieldName.CoverImageURL}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        return (
          <ImageFieldContainerStyled>
            <ImageRelativeWrapperStyled>
              <ImageStyled src={value || defaultImage} />
              <UploadActionStyled
                setImage={(url?: string) => {
                  if (url) {
                    onChange(url);
                    clearErrors([TextFieldName.CoverImageURL]);
                  }
                }}
                image={value || defaultImage}
                spaceId={spaceId}
                icon={['far', 'image']}
                onError={() => {
                  setError(TextFieldName.CoverImageURL, {
                    message: 'Image is too large. Only .png and .jpg (Maximum size 4 MB)',
                  });
                }}
                hideDefaultErrorMessage
              />
            </ImageRelativeWrapperStyled>
            {errors.coverImageURL && <ImageUploadErrorStyled>{errors.coverImageURL.message}</ImageUploadErrorStyled>}
          </ImageFieldContainerStyled>
        );
      }}
    />
  );
};
