import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@/component/Input/Dropdown';
import { UserSelfTrackedLearningsTypes } from '../../../types';

type TypeSectionProps = {
  options: UserSelfTrackedLearningsTypes[];
  type: UserSelfTrackedLearningsTypes;
  setType: (type: UserSelfTrackedLearningsTypes) => void;
};

export const TypeSection: FC<TypeSectionProps> = ({ type, setType, options }) => {
  const { t } = useTranslation('userSelfTrackedLearning');

  const dropdownOptions = useMemo(() => options.map(option => ({ value: option, label: option.toUpperCase() })), [options]);

  return (
    <div className="field has-addons has-addons--mobile is-floating-label">
      <div className="control is-expanded">
        <div className="field">
          <label className="label required">{t('Type')}</label>
          <Dropdown<string>
            hideLabel
            label={t('Type')}
            limit={3}
            options={dropdownOptions}
            selected={type}
            disabled={false}
            onSelect={type => setType(type as UserSelfTrackedLearningsTypes)}
            required
          />
        </div>
      </div>
    </div>
  );
};
