import styled from 'styled-components';

import { IconButton } from '@/ui/Button';

const RootStyled = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.shadow.primary[8]};
  background: ${({ theme }) => theme.colors.neutral.background};
  border-radius: 8px;
  padding: 8px;
  user-select: none;
  gap: 8px;
`;

export const FloatingToolbarButtons = ({ onRemoveClick }: { onRemoveClick: () => void }): JSX.Element => {
  return (
    <RootStyled>
      <IconButton icon={['far', 'trash']} onClick={onRemoveClick} />
    </RootStyled>
  );
};
