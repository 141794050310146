import styled from 'styled-components';

import { deviceUp } from '@/styles/utils';

export const StyledEventWrapper = styled.div`
  max-height: 230px;
  min-height: 220px;
  z-index: 10;
  &:after {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    bottom: -1px;
    content: '';
    height: 3rem;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 1;
    border-bottom: 0.1px solid transparent;
    border-radius: 0 0 var(--radius-outer) var(--radius-outer);
  }
`;

export const StyledEventBox = styled.div<{ selected?: boolean; disabled?: boolean; noOverflow?: boolean; isVariant?: boolean }>`
  max-height: 175px;
  overflow: hidden;
  background-color: var(--color-white);
  border: var(--border-style);
  border-left-width: 1.25rem;
  border-radius: var(--radius-inner);
  box-shadow: var(--box-shadow-small);
  display: flex;
  flex-direction: column;
  padding: var(--gap-sm);
  z-index: 10;
  ${({ isVariant }) =>
    isVariant &&
    `
    width: 280px;
    cursor: pointer;
  `}
  ${({ noOverflow }) =>
    !noOverflow &&
    `
    margin: -5rem var(--gap-sm) 0;s
  
    ${deviceUp.sm`
      margin-top: -4rem;
    `};
    ${deviceUp.md`
      margin-top: -5rem;
    `};
    
    ${deviceUp.xxl`
      margin-top: -6rem;
    `};
`}
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events:none;
    background-color: var(--color-alto);
  `}
  ${({ selected }) =>
    selected &&
    `
    border-color: var(--color-border-dark);
`}
`;

export const StyledEventBody = styled.div`
  padding: var(--gap-sm);
  color: #3f494a;
`;
