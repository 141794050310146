import React, { FC } from 'react';
import { RenderLeafProps } from 'slate-react';

export const Leaf: FC<RenderLeafProps> = ({ attributes, leaf, children }) => {
  if (leaf.bold) {
    children = <strong {...attributes}>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em {...attributes}>{children}</em>;
  }

  if (leaf.underline) {
    children = (
      <span style={{ textDecoration: 'underline' }} {...attributes}>
        {children}
      </span>
    );
  }

  if (leaf.lineThrough) {
    children = <s {...attributes}>{children}</s>;
  }

  if (leaf.fakeSelection) {
    children = (
      <span {...attributes} style={{ background: '#BBDEFB', display: 'inline-flex' }}>
        {children}
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
};
