import {
  RCreateLearningJourneyLiveEventMutation,
  RLocationType,
  useCreateLearningJourneyLiveEventMutationRemote,
} from '@/store/v2';
import { DnDLearningCardUnordered } from './useDnDLearningCards';

type UseCreateLearningJourneyLiveEventHook = () => {
  createLearningJourneyLiveEvent: (
    spaceId: number,
    liveEvent: {
      customTimezoneOffsetInMinutes: number;
      title: string;
      plannedDuration: number;
      bannerURL?: string;
      description?: string;
      startDate: Date;
      endDate: Date;
      address: string;
      type: RLocationType;
    },
    onSuccess?: (surveyCard?: DnDLearningCardUnordered) => void
  ) => Promise<{ errors?: unknown | undefined; data?: RCreateLearningJourneyLiveEventMutation | null | undefined }>;
  loading: boolean;
};

export const useCreateLearningJourneyLiveEvent: UseCreateLearningJourneyLiveEventHook = () => {
  const [createLearningJourneyLiveEvent, { loading }] = useCreateLearningJourneyLiveEventMutationRemote();

  return {
    createLearningJourneyLiveEvent: async (
      spaceId,
      { customTimezoneOffsetInMinutes, title, plannedDuration, bannerURL, description, startDate, endDate, address, type },
      onSuccess?: (surveyCard?: DnDLearningCardUnordered) => void
    ) => {
      return await createLearningJourneyLiveEvent({
        variables: {
          input: {
            timezone: customTimezoneOffsetInMinutes,
            title,
            plannedDuration,
            bannerURL,
            description: description || '',
            spaceId,
            locations: [
              {
                startDate,
                endDate,
                address,
                type,
              },
            ],
          },
        },
        update(_, { data }) {
          if (data?.createLearningJourneyLiveEvent) {
            const event = data.createLearningJourneyLiveEvent;
            onSuccess &&
              onSuccess({
                objectId: event.urn,
                title: event.title,
                type: event.type,
                imageUrl: event.image,
                totalEffort: (event.effort || 0) * 60,
              });
          }
        },
      });
    },
    loading,
  };
};
