import { useNavigate, useParams } from 'react-router-dom';

import { ViewLayout } from '@/administration/pages/Course/MainLayoutDeprecated';
import { ViewPageContent } from '@/administration/pages/Course/pages';
import { PageId } from '@/administration/pages/Course/store/types';
import { Page } from '@/administration/pages/Course/store/page/types';
import { useParentJourney, useParentJourneyIdFromSearchParams } from '@/store/v2/journeys/useParentJourneyId';
import { convertTitle } from '@/utils/misc';
import { useNavigateBack } from '@/hooks/useNavigateBack';

export const ModularLearningDetails = ({
  loading,
  title,
  pages,
  isDrawerOpen,
  pageTotalCount,
  pageActiveCount,
  prevPageActiveCount,
  isSavedToMyLearning,
  spaceId,
  learningId,
  onNewPageClick,
  onUpNavigationClick,
  onDownNavigationClick,
  onSaveClick,
  onPageChange,
  onSetDrawerOpen,
  onSetPageActive,
}: {
  loading?: boolean;
  title?: string;
  pages: Page[];
  isDrawerOpen: boolean;
  pageTotalCount: number;
  pageActiveCount: number;
  prevPageActiveCount: number;
  isSavedToMyLearning?: boolean;
  spaceId?: number;
  learningId?: number;
  onNewPageClick: (pageNumber: number) => void;
  onUpNavigationClick: () => void;
  onDownNavigationClick: () => void;
  onSaveClick?: () => void;
  onSetDrawerOpen: (p: boolean) => void;
  onSetPageActive: (pageId: PageId) => void;
  onPageChange: (page: Page) => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const { navigateBack } = useNavigateBack();
  const { id } = useParams<{ id: string }>();
  const parsedCourseId = id ? parseInt(id) : NaN;

  const parentJourneyIdFromSearchParams = useParentJourneyIdFromSearchParams();
  const parentJourney = useParentJourney({
    id: parsedCourseId,
  });

  const handleGoBack = () => {
    if (
      parentJourneyIdFromSearchParams && // we SPECIFICALLY check that the search params has parent journey id. We ingore all other cases (when course has parent but was open not from journey).
      parentJourney?.journeyId &&
      parentJourney.journeyTitle
    ) {
      /**
       * If the modular learning has parent journey AND was open via journey page, in this case we make close button behave as a link to that journey
       */
      navigate(`/journey/${parentJourney.journeyId}/${convertTitle(parentJourney.journeyTitle)}`);
    } else {
      /**
       * In all other cases it behaves as usual
       */
      navigateBack();
    }
  };

  return (
    <>
      {!!loading || !spaceId || !learningId ? (
        <ViewLayout.LoadingState />
      ) : (
        <ViewLayout
          title={title || ''}
          content={pages.map((page) => (
            <ViewPageContent
              key={`page-${page.id}`}
              page={page}
              onPageChange={onPageChange}
              spaceId={spaceId}
              learningId={learningId}
            />
          ))}
          drawerItems={pages.map((page) => ({
            ...page,
            onSelect: (p: string) => onSetPageActive(p as unknown as PageId),
          }))}
          onShowHideDrawerClick={() => onSetDrawerOpen(!isDrawerOpen)}
          onAddFavoritesClick={onSaveClick}
          onCloseClick={handleGoBack}
          onNewPageClick={onNewPageClick}
          onUpNavigationClick={onUpNavigationClick}
          onDownNavigationClick={onDownNavigationClick}
          isDrawerOpen={isDrawerOpen}
          progress={{ value: pageActiveCount, maxValue: pageTotalCount - 1, prevValue: prevPageActiveCount }}
          isSavedToMyLearning={isSavedToMyLearning}
          pageActiveCount={pageActiveCount}
          pageTotalCount={pageTotalCount}
        />
      )}
    </>
  );
};
