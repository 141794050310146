import React, { FC, useState, useEffect } from 'react';
import { ReactEditor, useSlateSelection, useSlateStatic } from 'slate-react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { makeNamespacedComponent } from '@/ui/utils';
import { Skeleton } from '@/ui/Skeleton';
import { handleImageCaptionChange } from '../../../hoc/withImages';
import { CustomNode } from '../../../types';
import { ElementProps } from '../../Element';
import { Serializable } from './Serializable';

const ImageStyled = styled.img<{ selected?: boolean }>`
  border-radius: 12px;

  max-height: 460px;
  object-fit: cover;

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary.outline};
    `}
`;

const RootStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0;
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 686px;
  width: 100%;
`;

const CaptionStyled = styled.input`
  ${({ theme }) => theme.typography.caption};

  &::placeholder {
    color: ${({ theme }) => theme.tones.neutral[60]};
  }

  color: ${({ theme }) => theme.colors.neutral.onBackground};

  text-align: center;

  &,
  &:active,
  &:focus-visible {
    border: none;
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const _ImageElement: FC<Pick<ElementProps, 'element'>> = ({ element }) => {
  const { t } = useTranslation('common');

  const [isImageSelected, setImageSelected] = useState(false);
  const editor = useSlateStatic();

  const currentSelection = useSlateSelection();

  useEffect(() => {
    const ownPath = ReactEditor.findPath(editor, element);
    setImageSelected(currentSelection?.focus.path[0] === ownPath[0]);
  }, [currentSelection]);

  const [caption, setCaption] = useState<string>(element.type === 'image' ? (element as CustomNode)?.caption || '' : '');

  return (
    <RootStyled>
      <ContentStyled>
        <ImageStyled src={element.url} onFocus={() => console.log('focused')} selected={isImageSelected} />
        <CaptionStyled
          onChange={(e) => {
            setCaption(e.currentTarget.value);
            handleImageCaptionChange({ element, editor, newCaption: e.currentTarget.value });
          }}
          value={caption}
          placeholder={t('write-caption')} // TODO Translations
        />
      </ContentStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <ContentStyled>
      <Skeleton height={460} width={686} />
    </ContentStyled>
  </RootStyled>
);

export const ImageElement = makeNamespacedComponent(_ImageElement, {
  LoadingState,
  Serializable,
});
