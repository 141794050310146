import { useEffect, useState } from 'react';

import { useCourseV2 } from '@/administration/pages/Course/store/course/useCourseV2';
import { useSaveCourse } from '@/administration/pages/Course/store/courseSave/useSaveMyCourse';
import { useXAPISession, XAPISession } from '@/store/xapi/useXAPISession';
import { StateId, useXAPIState } from '@/store/xapi/useXAPIState';
import { useXAPIClient } from '@/store/xapi/useXAPIClient';
import { useXAPIReaction } from '@/store/xapi/useXAPIReaction';
import { useModularLearningViewerV2 } from '@/pages/ModularLearning/useModularLearningViewerV2';
import { Activity, TreeActivity } from '@/administration/pages/Course/store/activity/types';
import { createReactionActivity, createResultActivity } from '@/administration/pages/Course/store/activity/transform';
import { Course, CourseV2 } from '@/administration/pages/Course/store/course/types';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { ActivityId } from '@/administration/pages/Course/store/types';
import { RLearningActivityType } from '@/store/v2';
import { getActiveDefaultActivityIndex } from '../activity/getActiveDefaultActivityIndex';
import { getNextOrderIndex } from '../activity/getNextOrderIndex';
import { deepMerge } from '@/utils/datastructure/deep-merge';

const AUTO_NAVIGATION_TIMEOUT_DELAY = 500;

type UseCourseViewerV2Props = {
  id: number;
};

export type UseCourseViewerV2Result = {
  course?: CourseV2;
  activities: Activity[];
  treeActivities: TreeActivity[];
  progress?: Progress;
  xapiSession?: XAPISession;
  startCourse: () => void;
  onSetActivityActive: (activityId: ActivityId) => void;
  onSetPrevActivityActive: () => void;
  onSetNextActivityActive: () => void;
  onSetActivityActiveByIndex: (index: number) => void;
  onSaveCourse: () => void;
  activityActiveCount: number;
  previousActiveActivityIndex: number;
  activitiesTotalCount: number;
  isCourseSaved: boolean;
  loadingLearning: boolean;
  loadingProgressInitialized: boolean;
  loadingProgress: boolean;
  loadingSavedLearning: boolean;
  loadingStartCourse: boolean;
  isDrawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  onActivityChange: (id: ActivityId, diff: Partial<Activity>) => void;
};

export const useCourseViewerV2 = ({ id }: UseCourseViewerV2Props): UseCourseViewerV2Result => {
  const { data, loading: loadingLearning } = useCourseV2(id);
  const {
    handleSavedLearnings: handleSaveCourse,
    isSaved: isCourseSaved,
    loading: loadingSavedLearning,
  } = useSaveCourse(data?.course?.id);
  const { session, loadingSession, register, refetchSession } = useXAPISession(Number(data?.course?.id));
  const xapiLastActivityIdSeen = useXAPIState(StateId.LAST_ACTIVITY_SEEN, session, 0);
  const xapiCompletedActivities = useXAPIState<Record<string, boolean>>(StateId.COMPLETED_ACTIVITIES, session, {});
  const xapiClient = useXAPIClient(session);
  const xapiReaction = useXAPIReaction(Number(data?.course?.id), data?.course?.title, session);
  const [loadingStartCourse, setLoadingStartCourse] = useState(false);
  const [loadingProgressInitialized, setLoadingProgressInitialized] = useState(false);

  const {
    activities,
    setDrawerOpen,
    isDrawerOpen,
    setActivities,
    treeActivities,
    activityActiveCount,
    onSetActivityActive,
    activitiesTotalCount,
    onSetPrevActivityActive,
    onSetNextActivityActive,
    previousActiveActivityIndex,
    onSetActivityActiveByIndex,
  } = useModularLearningViewerV2();

  useEffect(() => {
    if (
      !xapiLastActivityIdSeen.isInitialized ||
      !data?.activities ||
      !xapiReaction.isInitialized ||
      !xapiCompletedActivities.isInitialized
    )
      return;

    const activities = (data?.activities || []).map((activity) => ({
      ...activity,
      completed: activity.completed || Boolean(xapiCompletedActivities?.data?.[String(activity?.remoteId)]),
    }));

    const nextActivities: Activity[] = [
      ...activities,
      createReactionActivity({ order: getNextOrderIndex(activities), completed: !!xapiReaction.reactionId }),
    ];

    const areAllActivitiesCompleted = activities
      // we should filter out for modules
      .filter((activity) => activity.type !== RLearningActivityType.Module)
      .every((activity) => activity.completed);

    // if reaction is set, display results page
    if (areAllActivitiesCompleted) {
      nextActivities.push(
        createResultActivity({ course: data?.course as unknown as Course, order: getNextOrderIndex(nextActivities) })
      );
    }

    if (xapiLastActivityIdSeen?.data) {
      const lastSeenActivity = nextActivities?.find((activity) => activity?.remoteId === xapiLastActivityIdSeen.data);

      if (lastSeenActivity) {
        setActivities(
          nextActivities?.map((activity) =>
            activity.id === lastSeenActivity.id ? { ...activity, active: true } : { ...activity, active: false }
          ) || []
        );
        return;
      }
    }

    const activityToActivateIndex = getActiveDefaultActivityIndex(nextActivities || []);

    if (activityToActivateIndex !== -1) {
      // we found a target activity to activate :)
      nextActivities[activityToActivateIndex] = { ...nextActivities[activityToActivateIndex], active: true };
    }

    setActivities(nextActivities || []);
  }, [loadingLearning, xapiLastActivityIdSeen.isInitialized, xapiReaction.isInitialized, xapiCompletedActivities.isInitialized]);

  // Update xapi state to restore last course page position
  useEffect(() => {
    if (!xapiLastActivityIdSeen.isInitialized || xapiLastActivityIdSeen.loading) return;

    const activePage = activities.find((activity) => activity.active);

    if (!activePage?.remoteId || activePage.remoteId === xapiLastActivityIdSeen?.data) return;

    xapiLastActivityIdSeen.setState(activePage.remoteId);
  }, [activities, xapiLastActivityIdSeen.isInitialized, xapiLastActivityIdSeen.loading]);

  // Update progress initialized flag
  useEffect(() => {
    if (loadingProgressInitialized || loadingLearning || loadingSession) return;

    setLoadingProgressInitialized(true);
  }, [loadingLearning, loadingSession, loadingProgressInitialized]);

  // Add results page - if completed all activities
  useEffect(() => {
    if (!activities.length) return;

    const areAllActivitiesCompleted = activities
      // we should filter out for modules
      .filter((activity) => activity.type !== RLearningActivityType.Module)
      .every((activity) => activity.completed);
    if (!areAllActivitiesCompleted) return;

    xapiClient.setLearningCompleted(Number(data?.course?.id), data?.course?.title || '');

    const resultsActivity = createResultActivity({
      course: data?.course as unknown as Course,
      order: getNextOrderIndex(activities),
    });

    if (!resultsActivity) return;

    setActivities((previous) => [...previous, resultsActivity]);

    if (resultsActivity && !resultsActivity.active) {
      setTimeout(() => onSetActivityActive({ activityId: resultsActivity.id }), AUTO_NAVIGATION_TIMEOUT_DELAY);
    }
  }, [activities]);

  // track activities completions
  useEffect(() => {
    if (!activities.length) return;

    const unsavedActivities = activities.filter(
      (activity) =>
        activity.remoteId && activity.completed !== Boolean(xapiCompletedActivities?.data?.[String(activity?.remoteId)])
    );
    if (!unsavedActivities.length) return;

    const diff = unsavedActivities.reduce(
      (acc, activity) => ({ ...acc, [String(activity?.remoteId)]: Boolean(activity.completed) }),
      {}
    );

    xapiCompletedActivities.setState({ ...(xapiCompletedActivities?.data || {}), ...diff });
    unsavedActivities
      .filter((activity) => activity.completed)
      .forEach((activity) => xapiClient.setLearningActivityCompleted(Number(activity.remoteId), data?.course?.title || ''));
  }, [activities]);

  const onActivityChange = (id: ActivityId, diff: Partial<Activity>): void => {
    setActivities((oldActivities: Activity[]) =>
      oldActivities.map((activityItem: Activity) => (activityItem.id !== id ? activityItem : deepMerge(activityItem, diff)))
    );
  };

  const handleStartCourse = async () => {
    try {
      if (session) return;

      setLoadingStartCourse(true);

      await refetchSession();

      if (session) return;

      await register();

      await refetchSession();
    } catch (e) {
    } finally {
      setLoadingStartCourse(false);
    }
  };

  const progress = {
    ...(data?.course?.userLastParticipation ? data?.course?.userLastParticipation : {}),
    isStarted: !!session?.registration,
  } as Progress;

  return {
    course: data?.course,
    activities,
    treeActivities,
    progress,
    xapiSession: session,

    startCourse: handleStartCourse,
    onSetActivityActive: (activityId) => onSetActivityActive({ activityId }),
    onSetPrevActivityActive,
    onSetNextActivityActive,
    onSetActivityActiveByIndex,
    onSaveCourse: handleSaveCourse,

    activityActiveCount,
    previousActiveActivityIndex,
    activitiesTotalCount,

    isCourseSaved,

    loadingLearning: loadingLearning,
    loadingProgressInitialized: loadingProgressInitialized,
    loadingProgress: loadingSession || loadingLearning,
    loadingSavedLearning,
    loadingStartCourse,
    isDrawerOpen,
    setDrawerOpen,
    onActivityChange,
  };
};
