import { ProviderList } from '@/store/providers';
import { Inventory, InventoryProduct, ProductType } from '@/types/learning/learning-catalog';
import { Card, isProgressCard, ProgressCard, isCardCompletionAboutToExpire } from '@/types/learning/card';
import { inventoryToCourseCards } from '@/store/inventory';
import { Assignment, AssignmentPage, AssignmentLearningCard } from '@/store/utils/assignments/types';
import { getAssignmentPages } from '@/store/utils/assignments';
import { ParticipationGroup } from '@/types/analytics';

type MyLearningContent = (props: {
  catalogCards: Card[];
  inventory: Inventory;
  serviceManagerCertificateCards?: Card[];
  assignments?: Assignment[];
  filterType?: ProductType;
  participations?: ParticipationGroup[];
}) => {
  cardFilterPredicate: (_: Card) => boolean;
  ongoingCount: number;
  todoCount: number;
  filtered: FilteredMyLearnings;
};

export interface FilteredMyLearnings {
  assigned: { otherAssignments: Card[]; assignedCards: AssignmentPage[] };
  ongoing: { catalog: ProgressCard[]; inventory: InventoryProduct[] };
  completed: { catalog: ProgressCard[]; inventory: InventoryProduct[] };
}

function filterCatalogDuplicates(
  catalogCards: Card[],
  predicate: (c: Card) => boolean,
  inventoryProducts: readonly InventoryProduct[]
): ProgressCard[] {
  return catalogCards
    .filter(predicate)
    .filter(
      (card) =>
        !['product', 'journey'].includes(card.entity) ||
        !inventoryProducts.some(
          (ip) =>
            (card.entity === 'product' && ip.productId === card.entityId) ||
            (card.entity === 'journey' && ip.journeyId === card.entityId)
        )
    ) as ProgressCard[];
}

export const calculateFilteredMyLearnings: MyLearningContent = ({
  catalogCards,
  inventory,
  serviceManagerCertificateCards,
  assignments,
  filterType,
  participations,
}) => {
  const predicate = (card: Card) => !filterType || card.type === filterType;
  const inventoryPredicate = (inv: InventoryProduct) => {
    return (
      !filterType ||
      filterType === 'learning-path' ||
      (filterType === 'journey' && !!inv.journeyId) ||
      (filterType === ('other-learning-time' as ProductType) && inv.provider === ProviderList.USER_SELF_TRACKED_LEARNING)
    );
  };

  const hideArchivedInventoryPredicate = (inv: InventoryProduct) => !inv.archived;
  const hideArchivedCardPredicate = (card: Card) => !card.archived;

  // COMPLETED & ONGOING
  const completedPredicate = (c: Card) =>
    isProgressCard(c) && Boolean(c.participation) && Boolean(c.participation?.isCompleted) && !isCardCompletionAboutToExpire(c);
  const ongoingPredicate = (c: Card) => isProgressCard(c) && Boolean(c.participation) && c.participation?.isCompleted !== true;

  const completedCatalogCards = filterCatalogDuplicates(catalogCards, completedPredicate, inventory.completed);
  const ongoingCatalogCards = filterCatalogDuplicates(catalogCards, ongoingPredicate, inventory.ongoing);

  // ASSIGNED
  const notCompletedPredicate = (c: Card) =>
    isProgressCard(c) && (c.participation?.isCompleted !== true || isCardCompletionAboutToExpire(c));

  const myLearningCatalogCards = ongoingCatalogCards.filter(predicate).concat(completedCatalogCards.filter(predicate));
  const inventoryCards = inventoryToCourseCards(
    inventory.ongoing.filter(inventoryPredicate),
    () => {},
    false,
    status === 'ongoing',
    false
  );

  const assignedPages: AssignmentPage[] = assignments
    ? getAssignmentPages({
        assignments,
        catalogCards,
        inventoryCards,
        myLearningCatalogCards,
        participations,
        eventEnrollments: inventory.eventEnrollments,
      })
    : [];

  const assigned: AssignmentPage[] = assignedPages
    .map((x): AssignmentPage | undefined => {
      if (x.type === 'STANDARD') {
        const cards: AssignmentLearningCard<'STANDARD'>[] = x.cards ?? [];
        const allNonEventCards = cards.filter((m) => !(m.type === 'webinar' || m.type === 'seminar')).filter(predicate);
        const remainingCards = allNonEventCards.filter(notCompletedPredicate);
        const total = allNonEventCards.length;

        return {
          ...x,
          cards: remainingCards,
          total,
          remaining: remainingCards.length,
          isCompleted: !filterType && allNonEventCards.filter(completedPredicate).length === total,
        };
      } else if (x.type === 'STRATEGIC') {
        const cards: AssignmentLearningCard<'STRATEGIC'>[] = x.cards ?? [];
        const allNonEventCards = cards.filter((m) => !(m.type === 'webinar' || m.type === 'seminar')).filter(predicate);
        const remainingCards = allNonEventCards.filter(notCompletedPredicate);
        const total = allNonEventCards.length;

        return {
          ...x,
          cards: remainingCards,
          total,
          remaining: remainingCards.length,
          isCompleted: !filterType && allNonEventCards.filter(completedPredicate).length === total,
        };
      }
      return undefined;
    })
    .filter((x): x is AssignmentPage => !!x);

  const eventCards = assignedPages
    .map((x) => x.cards)
    .flat()
    .filter((m): m is AssignmentLearningCard => !!m && (m.type === 'webinar' || m.type === 'seminar'))
    .filter(predicate)
    .map((m): ProgressCard => {
      return { ...m };
    });

  const nonRoleAssignments = serviceManagerCertificateCards ? [...serviceManagerCertificateCards] : [];

  const filteredOtherAssignments = nonRoleAssignments.filter(predicate).filter(notCompletedPredicate);

  // COUNT
  const ongoingCatalogCardsFiltered = ongoingCatalogCards.filter(predicate).filter(hideArchivedCardPredicate);
  const ongoingInventoryFiltered = inventory.ongoing.filter(inventoryPredicate).filter(hideArchivedInventoryPredicate);

  const ongoingCount = ongoingCatalogCardsFiltered.length + ongoingInventoryFiltered.length;
  const notCompletedAssignedItems = assigned
    .filter((m) => !m.isCompleted)
    .reduce((acc, obj) => {
      const total = obj.remaining ?? 0;
      return acc + total;
    }, 0);
  const todoCount = notCompletedAssignedItems + filteredOtherAssignments.length;

  // FILTER
  const filtered: FilteredMyLearnings = {
    completed: {
      catalog: completedCatalogCards.concat(eventCards.filter(completedPredicate)).filter(predicate),
      inventory: inventory.completed.filter(inventoryPredicate),
    },
    ongoing: {
      catalog: ongoingCatalogCardsFiltered,
      inventory: ongoingInventoryFiltered,
    },
    assigned: {
      assignedCards: assigned,
      otherAssignments: filteredOtherAssignments,
    },
  };
  return { cardFilterPredicate: predicate, ongoingCount, todoCount, filtered };
};
