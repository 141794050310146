import i18next from 'i18next';
import { GraphQLError, GraphQLFormattedError } from 'graphql';

export function getErrorResponseMessage(errors: ReadonlyArray<GraphQLFormattedError>): string {
  return getErrorResponseMessages(errors).join('\n');
}

const CONTACT_SUPPORT_ERROR_CODES = ['BaseError', 'GRAPHQL_VALIDATION_FAILED'];

export function getErrorResponseMessages(errors: ReadonlyArray<GraphQLFormattedError>): string[] {
  return errors.map((e) => {
    if (!e.extensions) {
      return 'Generic error';
    } else if (CONTACT_SUPPORT_ERROR_CODES.includes(e.extensions.code)) {
      return 'generic|:error-occured-contact-support';
    } else if (!e.extensions.response) {
      return e.extensions.code;
    }

    const { message, body, status, statusText } = e.extensions.response;
    if (body && body.message) return body.message;
    if (body && typeof body === 'string' && !body.includes('<!DOCTYPE html>')) return body;
    return message || `${status} ${statusText}`;
  });
}

export function getTranslatedErrorMessages(errors: ReadonlyArray<GraphQLFormattedError>): string[] {
  return getErrorResponseMessages(errors)
    .map((m) => i18next.getResource(i18next.language, 'serverMessages', m))
    .filter((m) => !!m);
}

export function getHttpCodes(errors: ReadonlyArray<GraphQLError>): number[] {
  return errors
    .map((e) => {
      if (e.extensions && e.extensions.response) {
        return e.extensions.response.status;
      }
    })
    .filter((c) => !!c);
}
