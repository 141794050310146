/* eslint-disable import/no-unused-modules */
import React, { useEffect, useState } from 'react';

import { Button } from '@/ui/Button';
import { makeNamespacedComponent } from '@/ui/utils';
import { Container, WeekChart, WeeksNavigation } from '../../atoms';
import { WeeklyLearningLoop } from '../../atoms/WeeksNavigation/types';
import { IconPropType } from '@/ui/Icon';
import { Tooltip } from '@/ui/Tooltip';
import { DefaultButtonProps } from '@/ui/Button/type';
import { Countdown } from '../../atoms/Countdown';
import { ActionsContainerStyled, CanReflectDotStyled, CountdownContainer, LoopsContainer, RootStyled } from './styles';
import { Skeleton } from '@/ui/Skeleton';
import { WeeksSetup } from '../../atoms/WeeksNavigation/variants';

export type WeeklyLoopChartProps = {
  weeks: WeeklyLearningLoop[];
  onAddLearningTimeClick?: (selectedWeekId: string) => void;
  onWeekReflect: (selectedWeekId: string) => void;
  onViewDetailsClick: (selectedWeekId: string) => void;
  onHelpClick: () => void;
  onSLIStarted: (started: boolean) => void;
  sliStarted: boolean;
  startAtFullTimestamp: string;
};

const getReflectButtonProps = ({
  selectedWeek,
  onWeekReflect,
  onViewDetails,
}: {
  selectedWeek: WeeklyLearningLoop;
  onWeekReflect: (selectedWeekId: string) => void;
  onViewDetails: (selectedWeekId: string) => void;
}): DefaultButtonProps => {
  /**
   * Simply every loop, that is not a current week and cannot be reflected (which means, it's the past week)
   * We don't consider future weeks here, since they are not accessible. Add a condition for that, if at some point we decide to show them
   */
  const hasRelfected = selectedWeek ? !isCurrentWeek(selectedWeek) && !selectedWeek?.canReflect : false;

  return {
    children: hasRelfected ? 'Reflected' : 'Reflect',
    icon: hasRelfected ? ('check' as IconPropType) : undefined,
    disabled: isCurrentWeek(selectedWeek),
    variant: 'primary',
    onClick: hasRelfected ? () => onViewDetails(selectedWeek.id) : () => onWeekReflect(selectedWeek.id),
  };
};

export const isCurrentWeek = ({ timeState }: WeeklyLearningLoop): boolean => timeState === 'current';
export const isPastWeek = ({ timeState }: WeeklyLearningLoop): boolean => timeState === 'past';
export const isFutureWeek = ({ timeState }: WeeklyLearningLoop): boolean => timeState === 'future';

const _WeeklyLoopChart = ({
  weeks,
  onAddLearningTimeClick,
  onWeekReflect,
  onViewDetailsClick,
  onHelpClick,
  onSLIStarted,
  sliStarted,
  startAtFullTimestamp,
}: WeeklyLoopChartProps): JSX.Element => {
  const [selectedWeekId, setSelectedWeekId] = useState<string | null>(null);
  useEffect(
    () => {
      const currentWeek = weeks.find(isCurrentWeek);
      const allWeeksArePast = weeks.every(isPastWeek);

      // if we found a current week
      if (currentWeek) {
        // we set it as an initial selected week
        setSelectedWeekId(currentWeek.id);
      } else if (allWeeksArePast) {
        // but if there is no current week, since all of them are past, we pick the last one in the array
        setSelectedWeekId(weeks[weeks?.length - 1]?.id || null);
      }
    },
    // We want to run this hook only when there's a fresh collection of weeks,
    // even if the reference is different *or* when any individual week's
    // details have changed (eg. `canReflect` status).
    //
    // A simple list of week ID's acts like a unique signature of the collection:
    weeks.map((w) => w.id)
  );

  const handleSelectedWeekChanged = (selectedWeekNumber: number) => {
    const targetWeek = weeks.find(({ weekNumber }) => weekNumber === selectedWeekNumber);

    if (targetWeek) {
      setSelectedWeekId(targetWeek.id);
    }
  };
  const selectedWeek = (selectedWeekId ? weeks.find((w) => w.id === selectedWeekId) : null) ?? null;

  return (
    <RootStyled>
      <CountdownContainer loopsStarted={sliStarted}>
        <Countdown sliStartDate={startAtFullTimestamp} onSLIStarted={onSLIStarted} />
      </CountdownContainer>
      <LoopsContainer loopsStarted={sliStarted}>
        <WeeksNavigation
          weeks={weeks}
          selectedWeek={selectedWeek}
          onChangeSelectedWeek={(newSelectedWeekNumber: number) => handleSelectedWeekChanged(newSelectedWeekNumber)}
          onHelpClick={onHelpClick}
        />
        {selectedWeek && (
          <WeekChart
            currentEffort={selectedWeek.currentEffort}
            expectedEffort={selectedWeek.recommendedEffort}
            onAddLearningTimeClick={
              isCurrentWeek(selectedWeek) ? () => onAddLearningTimeClick && onAddLearningTimeClick(selectedWeek.id) : undefined
            }
            onViewDetailsClick={() => onViewDetailsClick(selectedWeek.id)}
            actions={
              <>
                {isCurrentWeek(selectedWeek) ? (
                  <Tooltip placement="bottom" title="Reflecting is only available at the end of the week">
                    <Button
                      hoverEventsOnDisabled
                      {...getReflectButtonProps({ selectedWeek, onWeekReflect, onViewDetails: onViewDetailsClick })}
                    />
                  </Tooltip>
                ) : (
                  <ActionsContainerStyled>
                    {selectedWeek.canReflect && <CanReflectDotStyled />}
                    <Button {...getReflectButtonProps({ selectedWeek, onWeekReflect, onViewDetails: onViewDetailsClick })} />
                  </ActionsContainerStyled>
                )}
              </>
            }
          />
        )}
      </LoopsContainer>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <Container>
    <WeeksSetup.LoadingState />
    <WeekChart.LoadingState actions={<Skeleton width={86} height={32} />} />
  </Container>
);

export const WeeklyLoopChart = makeNamespacedComponent(_WeeklyLoopChart, {
  LoadingState,
});
