import React from 'react';
import styled from 'styled-components';

import { Icon, IconCounter } from '../Icon';
import { BaseButton } from './components/BaseButton';
import { ButtonProps } from './types';

const ChildrenSpan = styled.span`
  align-items: center;
  display: flex;
`;

export function Button({
  onClick,
  children,
  $icon,
  $iconSize = '1x',
  $iconRotation,
  $iconCounter,
  $loading,
  $ariaLabel,
  disabled,
  $type = 'default',
  $size = 'default',
  $fullwidth,
  $iconFixedWidth,
  $iconStyle,
  type = 'button',
  ...otherProps
}: ButtonProps): JSX.Element {
  let iconToUse = $icon;
  if ($loading) iconToUse = 'spinner-third';

  const $onlyIcon = !children;
  let iconElement: JSX.Element | null = null;
  if ($iconCounter) {
    iconElement = <IconCounter counter={$iconCounter} icon={iconToUse} />;
  } else if (iconToUse) {
    iconElement = (
      <Icon
        icon={iconToUse}
        spin={$loading}
        size={$loading && $iconSize === '1x' ? 'lg' : $iconSize}
        rotation={$iconRotation}
        fixedWidth={$fullwidth || $iconFixedWidth || $onlyIcon}
        style={$iconStyle}
      />
    );
  }

  return (
    <BaseButton
      onClick={$type === 'hidden' ? undefined : onClick}
      disabled={disabled || $loading}
      aria-label={children ? `${children}` : $ariaLabel}
      $loading={$loading}
      $type={$type}
      $size={$size}
      $fullwidth={$fullwidth}
      $onlyIcon={$onlyIcon}
      $hasIcon={!!$icon}
      type={type}
      {...otherProps}
    >
      {!!children && <ChildrenSpan>{children}</ChildrenSpan>}
      {iconElement}
    </BaseButton>
  );
}
