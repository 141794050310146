interface Auth {
  url: string;
}

interface Authv2 {
  url: string;
  login_url: string;
  logout_url: string;
  refresh_url: string;
}

interface Config {
  baseApiRoute: string;
  wsApiRoute: string;
  frontendApiRoute: string;
  frontendRoute: string;
  auth: Auth; // TODO: Remove once auth->authv2 migration is completed.
  authv2: Authv2; // TODO: Make required.
  cdnUrl?: string;
  countlyUrl?: string;
  coursera?: {
    organizationId: string;
  };
}

export const supportUrl = {
  seb: 'https://intra.sebank.se/en/employee-support/support-and-contacts/contacts-and-support-for-it-hr-and-procurement',
};

export const supportEmails = {
  seb: 'hrrequest@seb.se',
  collegial: 'support@collegial.com',
};

const envConfigMap: {
  [key: string]: Config;
} = {
  test: {
    baseApiRoute: '//test-api',
    wsApiRoute: '//test-api',
    frontendApiRoute: '//test-api',
    frontendRoute: '//test-web',
    auth: {
      url: '',
    },
    authv2: {
      url: '',
      login_url: '',
      logout_url: '',
      refresh_url: '',
    },
  },
  local: {
    baseApiRoute: 'http://local.clsnordic.com:5000',
    wsApiRoute: 'ws://local.clsnordic.com:5000/collaboration',
    frontendApiRoute: 'http://local.clsnordic.com:4000',
    frontendRoute: 'http://local.clsnordic.com:9000',
    auth: {
      url: 'http://local.clsnordic.com:7000',
    },
    authv2: {
      url: 'http://local.clsnordic.com:8080',
      login_url: 'http://local.clsnordic.com:8080/login',
      logout_url: 'http://local.clsnordic.com:8080/logout',
      refresh_url: 'http://local.clsnordic.com:8080/refresh',
    },
    coursera: {
      organizationId: 'DIKFepulR26aEGdKOmKbfw',
    },
  },
  dev: {
    baseApiRoute: 'https://api.dev.collegial.com',
    wsApiRoute: 'wss://api.dev.collegial.com/collaboration',
    frontendApiRoute: 'https://fapi.dev.collegial.com',
    frontendRoute: 'https://cls.dev.collegial.com',
    auth: {
      url: 'https://auth.dev.collegial.com',
    },
    authv2: {
      url: 'https://authv2.dev.collegial.com',
      login_url: 'https://authv2.dev.collegial.com/login',
      logout_url: 'https://authv2.dev.collegial.com/logout',
      refresh_url: 'https://authv2.dev.collegial.com/refresh',
    },
    cdnUrl: 'https://cdn-dev.collegial.com',
    coursera: {
      organizationId: 'DIKFepulR26aEGdKOmKbfw',
    },
  },
  prod: {
    baseApiRoute: 'https://api.collegial.com',
    wsApiRoute: 'wss://api.collegial.com/collaboration',
    frontendApiRoute: 'https://fapi.collegial.com',
    frontendRoute: 'https://cls.collegial.com',
    auth: {
      url: 'https://auth.collegial.com',
    },
    authv2: {
      url: 'https://authv2.collegial.com',
      login_url: 'https://authv2.collegial.com/login',
      logout_url: 'https://authv2.collegial.com/logout',
      refresh_url: 'https://authv2.collegial.com/refresh',
    },
    countlyUrl: 'https://countly.collegial.com',
    cdnUrl: 'https://cdn.collegial.com',
    coursera: {
      organizationId: 'EiNtebucR0-i38-xCglQzQ',
    },
  },
};

function getConfig(env = ''): Readonly<Config> {
  return Object.freeze(envConfigMap[env] || envConfigMap.prod);
}

export const config = getConfig(window.REACT_APP_ENV);
