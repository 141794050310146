import React from 'react';

import { CourseCards } from './CourseCards';
import { Badge, Header, Title } from './styles';
import { Skeleton } from '@/ui/Skeleton';
import { BadgeLabel } from '@/ui/Label/variants/BadgeLabel';
import { makeNamespacedComponent } from '@/ui/utils';
import { LearningItemProps } from './types';
import { useCourseCards } from './CourseCards/hooks';

const _LearningItems = ({ assignment }: LearningItemProps): JSX.Element => {
  const { processedTotal, processedCards, loading } = useCourseCards({ assignment });

  if (loading) {
    return <LearningItems.LoadingState />;
  }

  return (
    <div>
      <Header>
        <Title>Learning items</Title>
        <Badge badgeVariant="badge-text" text={processedTotal.toString()} />
      </Header>
      {processedCards && <CourseCards cards={processedCards} />}
    </div>
  );
};

const LoadingState = (): JSX.Element => (
  <div>
    <Header>
      <Skeleton width={216} height={40} />
      <BadgeLabel.LoadingState badgeVariant="badge-text" overrideWidth={23} />
    </Header>
    <CourseCards.LoadingState />
  </div>
);

const LearningItems = makeNamespacedComponent(_LearningItems, {
  LoadingState,
});

export { LearningItems };
