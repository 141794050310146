import styled from 'styled-components';

import { Lng } from '@/i18n/resources';
import { Icon } from '@/components/Icon';
import { Select } from '@/ui/Select';

const PickerButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  background: none;
  border: none;
  padding: 0;
  ${(p) => p.theme.typography.body2}
  color: ${(p) => p.theme.colors.neutral.onContainer};
  cursor: pointer;
`;

const PickerIcon = styled(Icon).attrs({ icon: 'chevron-down' })<{ $active: boolean }>`
  transform: scaleY(${(p) => (p.$active ? -1 : 1)});
  width: 16px;
  height: 16px;
`;

const languageOptions = [
  { label: 'English', value: Lng.en },
  { label: 'Svenska', value: Lng.sv },
];

export const LanguagePicker = ({
  currentLanguage,
  setLanguage,
  languageChangePending,
}: {
  currentLanguage: Lng;
  setLanguage: (nextLanguage: Lng) => void;
  languageChangePending: boolean;
}): JSX.Element => {
  // The language should always be set, but better safe than sorry:
  const currentLanguageLabel = languageOptions.find(({ value }) => value === currentLanguage)?.label ?? 'Select language';

  return (
    <Select onChange={(nextLanguage) => setLanguage(nextLanguage as Lng)} value={currentLanguage}>
      <Select.Header>
        {({ toggleShowOptions, shouldShowOptions }) => (
          <PickerButton onClick={toggleShowOptions} disabled={languageChangePending} aria-label="Language selector">
            {currentLanguageLabel}
            <PickerIcon $active={shouldShowOptions} />
          </PickerButton>
        )}
      </Select.Header>
      <Select.List>
        {languageOptions.map(({ value, label }) => (
          <Select.Option value={value} key={value}>
            {label}
          </Select.Option>
        ))}
      </Select.List>
    </Select>
  );
};
