import styled from 'styled-components';

import image from './image.svg';

export const Graphics = styled.img.attrs({ src: image })`
  border-radius: 8px;
  width: 100%;
`;

export const Title = styled.span`
  ${({ theme }) => theme.typography.headline};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;
