import React, { useState } from 'react';
import { PopupButton } from '@typeform/embed-react';

import { FEATURE, useFeatureEnabled } from '@/feature-toggles';
import { useCurrentUser } from '@/store/currentUser';
import { Button } from '@/ui/Button';
import { useAddAssignmentUserSurvey } from '@/pages/Assignment/strategic/components/Hero/CompetencyAssesmentButton/store/useAddAssignmentUserSurvey';

const CustomPopupButton = (props: React.ComponentProps<typeof PopupButton>) => (
  <Button as={PopupButton} {...(props as any)} variant="primary" />
);

interface Props {
  assignmentId: number;
  surveyId: string;
  enabled: boolean;
}

export const CompetencyAssessmentButton = ({ assignmentId, surveyId, enabled }: Props): React.ReactElement => {
  const isCompetencyAssessmentEnabled = useFeatureEnabled(FEATURE.UI_COMPETENCY_ASSESSMENT);
  const { user } = useCurrentUser();
  const { addAssignmentUserSurvey } = useAddAssignmentUserSurvey();
  const [showButton, setShowButton] = useState(enabled);

  return (
    <>
      {isCompetencyAssessmentEnabled &&
        surveyId &&
        (!showButton ? (
          <CustomPopupButton
            id={surveyId}
            hidden={{
              user_id: user?.id.toString() || '',
              assignment_id: assignmentId.toString(),
            }}
            onSubmit={async ({ responseId }) => {
              const response = await addAssignmentUserSurvey({
                userId: Number(user?.id),
                assignmentId: assignmentId,
                surveyId: surveyId,
                surveyResponseId: responseId,
              });

              setShowButton(!response.data?.addAssignmentUserSurvey.success);
            }}
          >
            Assess Competency
          </CustomPopupButton>
        ) : (
          <Button variant="primary" disabled={true}>
            Competency Assessment filled
          </Button>
        ))}
    </>
  );
};
