import React, { forwardRef } from 'react';
import { Editor } from 'slate';

import { getMeta } from '@/component/FileUpload/helpers';
import { useEditorImageUpload } from '@/component/customEditor/hooks/useEditorImageUpload';

export const ImageSection = forwardRef<HTMLInputElement, { spaceId?: number; editor: Editor }>(({ spaceId, editor }, ref) => {
  const { accept } = getMeta('image');
  const uploadImage = useEditorImageUpload({ spaceId, editor });

  return (
    <input
      type="file"
      accept={accept}
      onChange={(event) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) uploadImage(selectedFile);
      }}
      ref={ref}
      style={{ display: 'none' }}
    />
  );
});
