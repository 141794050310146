import { gql, FetchResult, useMutation } from '@apollo/client';

import { AssignmentsV3Result, AssignmentV3 } from '../types';
import { CreateAssignmentFromTemplateInput } from './types';

export const CREATE_ASSIGNMENT_TEMPLATE_MUTATION = gql`
  mutation createAssignmentFromTemplate($input: CreateAssignmentFromTemplateInput!) {
    createAssignmentFromTemplate(input: $input) {
      assignment {
        id
        name
      }
      success
    }
  }
`;

export const useCreateAssignmentTemplate = (): {
  createAssignmentFromTemplate: (
    input: CreateAssignmentFromTemplateInput,
    onSuccess?: (assignment?: AssignmentV3) => void
  ) => Promise<FetchResult<{ createAssignmentFromTemplate: AssignmentsV3Result }>>;
  loading: boolean;
} => {
  const [createAssignmentFromTemplate, { loading }] = useMutation<{
    createAssignmentFromTemplate: AssignmentsV3Result;
  }>(CREATE_ASSIGNMENT_TEMPLATE_MUTATION);

  return {
    createAssignmentFromTemplate: async (input, onSuccess) =>
      createAssignmentFromTemplate({
        variables: { input },
        update(cache, { data }) {
          if (data?.createAssignmentFromTemplate.success) {
            const newAssignment = data?.createAssignmentFromTemplate?.assignment;
            onSuccess && onSuccess(newAssignment);
            cache.evict({ fieldName: 'assignmentsV3' });
            cache.evict({ fieldName: 'catalog' });
          }
        },
      }),
    loading,
  };
};
