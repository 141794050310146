import React from 'react';

import { Button } from '@/components/Button/Button';
import { ButtonProps } from '@/components/Button/types';

export const CancelButton = ({ disabled, onClick, children }: ButtonProps): JSX.Element => (
  <Button $icon="trash" disabled={disabled} onClick={onClick}>
    {children}
  </Button>
);
