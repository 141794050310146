import dayjs from 'dayjs';

import { Location, WaitlistType } from '@/types/learning';
import { LearningCard, Card } from '@/types/learning/card';

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

export enum SortingEnum {
  ALPHA_ASC = 'ALPHA_ASC',
  ALPHA_DESC = 'ALPHA_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
  FEATURED = 'FEATURED'
}

const getEmptyLocation = () => ({
  id: 0,
  created: new Date(),
  startDate: '',
  endDate: '',
  address: '',
  waitlistType: WaitlistType.AUTOMATIC
});

export function firstLocation(locations: readonly Location[] | undefined): Location {
  if (!locations || locations?.length === 0) return getEmptyLocation();
  const [nextOccurrence] = locations
    .filter(location => !dayjs(location.startDate).isBefore(Date.now()))
    .sort((a, b) => collator.compare(a.startDate, b.startDate));
  return nextOccurrence || getEmptyLocation();
}

export const sortingMethods = {
  [SortingEnum.ALPHA_ASC]: (a: Card, b: Card): number => collator.compare(a.title, b.title),
  [SortingEnum.ALPHA_DESC]: (a: Card, b: Card): number => collator.compare(b.title, a.title),
  [SortingEnum.DATE_ASC]: (a: Card, b: Card): number =>
    collator.compare(
      firstLocation((a as LearningCard).locations).startDate,
      firstLocation((b as LearningCard).locations).startDate
    ),
  [SortingEnum.DATE_DESC]: (a: Card, b: Card): number =>
    collator.compare(
      firstLocation((b as LearningCard).locations).startDate,
      firstLocation((a as LearningCard).locations).startDate
    ),
  [SortingEnum.FEATURED]: (a: Card, b: Card): number => +b.featured - +a.featured || sortingMethods[SortingEnum.DATE_ASC](a, b)
};
