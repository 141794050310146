/* eslint-disable import/no-unused-modules */
import { trackEvent } from './countly';

export const trackAddUserSelfTrackedLearning = (time: number): void =>
  trackEvent({
    key: 'Added self tracked time',
    sum: time,
  });

export const trackHomepageLearningCardClicked = (title: string, sectionName: string): void =>
  trackEvent({
    key: '[Homepage] Clicked learning on the homepage',
    segmentation: { title, sectionName },
  });

export const trackHomepageAssignmentCardClicked = (title: string): void =>
  trackEvent({
    key: '[Homepage] Clicked assignment card on the homepage',
    segmentation: { title },
  });

export const trackShowAllButtonClicked = (sectionName: string): void =>
  trackEvent({ key: '[Homepage] Clicked show all on the homepage', segmentation: { sectionName } });

export const trackLearningTimeClicked = (): void =>
  trackEvent({
    key: '[Homepage] Clicked my learning time link on the homepage',
  });

export const trackBannerLearningsClicked = (title: string): void =>
  trackEvent({
    key: '[Homepage] Clicked a banner on the homepage',
    segmentation: { title },
  });

export const trackOpenUserSelfTrackedLearningModalV2 = (): void =>
  trackEvent({
    key: '[Homepage] Clicked add learning time on the homepage',
  });

export const trackOpenCarouselModal = (): void =>
  trackEvent({
    key: '[Homepage] Opened a carousel modal',
  });
