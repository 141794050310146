import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface LocationState {
  noScroll: boolean;
}

export const ScrollToTop = (): null => {
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { noScroll } = location.state as LocationState;
      if (noScroll) return;
    }

    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);

  return null;
};
