import React from 'react';
import { Only } from 'atomic-layout';

import { StyledNavSectionWrapper } from '@/components/Navbar/styles';
import { isRealm } from '@/utils/realms';
import { NavProfileMobileMenu } from '@/components/Navbar/components/mobile/NavProfileMobileMenu';
import { NavRightSection } from './NavRightSection';

export const NavProfile = (): JSX.Element => {
  return (
    <StyledNavSectionWrapper isKompetens={isRealm('kompetens')} itemsAlign="right">
      <Only to="lg">
        <NavProfileMobileMenu />
      </Only>
      <Only from="lg">
        <NavRightSection />
      </Only>
    </StyledNavSectionWrapper>
  );
};
