import React from 'react';

import './iframe.scss';
import { useCourseIframeNavigationListener } from '../../hooks/useCourseIframeNavigationListener';

interface Props {
  src: string;
  title?: string;
}

export const IFrame = ({ src, title }: Props): JSX.Element => {
  const { iframeHeight, courseIframe } = useCourseIframeNavigationListener();

  return (
    <iframe
      ref={courseIframe}
      frameBorder={0}
      src={src}
      style={{ width: '100%', height: iframeHeight, marginTop: '-1px', minHeight: '100vh' }}
      allowFullScreen
      title={title}
    />
  );
};
