import { Modal } from '@/ui/Modal';

type UnlockLearningModalProps = {
  open: boolean;
  onClose: () => void;
  onUnlock: () => void;
};

export const UnlockLearningModal = ({ open, onClose, onUnlock }: UnlockLearningModalProps): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} size="mobile">
      <Modal.Title>Unlock item</Modal.Title>
      <Modal.Contents>
        This learning journey has a set order.
        <br />
        Only unlock your next learning item if you have completed the previous one.
      </Modal.Contents>
      <Modal.Actions>
        <Modal.Action action={onClose}>Cancel</Modal.Action>
        <Modal.Action action={onUnlock} primary icon={['far', 'lock']} iconPosition="start">
          Unlock
        </Modal.Action>
      </Modal.Actions>
    </Modal>
  );
};
