import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { TextArea } from '@/ui/TextField';
import { TextFieldName } from '../../../constants';

export const LearningDescription = ({ control }: { control: Control<FieldValues> }): JSX.Element => (
  <Controller
    control={control}
    name={TextFieldName.LearningDescription}
    render={({ field: { onChange, name, value } }) => (
      <TextArea onChange={onChange} name={name} value={value} fullWidth label="What is your main takeaway?" optional rows={3} />
    )}
  />
);
