import { createPlatePlugin } from '@udecode/plate-common/react';

const ELEMENT_VIDEO = 'video';

export const VideoPlugin = createPlatePlugin({
  key: ELEMENT_VIDEO,
  node: {
    isElement: true,
    isVoid: true,
    type: ELEMENT_VIDEO,
  },
});
