import styled, { css } from 'styled-components';
import { ParagraphPlugin, focusEditor, useEditorRef, useSelectionFragmentProp } from '@udecode/plate-common/react';
import { useMemo } from 'react';
import { collapseSelection, toggleBlock } from '@udecode/plate-common';

import { LinkToolbarButton } from '@/component/customEditorV2/ui/floatingToolbar/LinkToolbarButton';
import { MarkToolbarButton } from '@/component/customEditorV2/ui/floatingToolbar/MarkToolbarButton';
import { Divider, StyledToolbarGroup } from '@/component/customEditorV2/ui/floatingToolbar/styles';
import { Button } from '@/ui/Button';
import { Select } from '@/ui/Select';
import { Icon, IconPropType } from '@/ui/Icon';
import { OptionTextStyled } from '@/ui/Select/atoms/Option/styles';

const MarkToolbarButtonsGroup = () => {
  return (
    <>
      <MarkToolbarButton type={'bold'} />
      <MarkToolbarButton type={'italic'} />
      <MarkToolbarButton type={'underline'} />
      <MarkToolbarButton type={'lineThrough'} />
    </>
  );
};

const TurnIntoListStyled = styled(Select.List)`
  margin-top: 12px;
  margin-bottom: 12px;
  left: -8px;
`;

const TriggerButtonStyled = styled(Button)<{ toggled?: boolean }>`
  :focus:not(:active) {
    outline: none;
  }

  padding: 4px 8px;
  gap: 8px;

  ${({ toggled }) =>
    toggled &&
    css`
      background-color: ${({ theme }) => theme.colors.states.hover1} !important;
      color: ${({ theme }) => theme.colors.neutral.outline2};
    `}
`;

const TURN_INTO_ELEMENTS: {
  label: string;
  id: string;
  icon: IconPropType;
}[] = [
  {
    label: 'Text',
    id: 'paragraph',
    icon: ['far', 'paragraph'],
  },
  {
    id: 'heading',
    label: 'Heading 1',
    icon: ['far', 'h1'],
  },
  {
    id: 'heading2',
    label: 'Heading 2',
    icon: ['far', 'h2'],
  },
  {
    id: 'bulleted-list',
    label: 'Bulleted list',
    icon: ['far', 'list'],
  },
  {
    id: 'ordered-list',
    label: 'Numbered list',
    icon: ['far', 'list-ol'],
  },
];

const TurnIntoOptionStyled = styled(Select.Option)`
  padding: 8px 16px;
  height: 40px;

  & ${OptionTextStyled} {
    gap: 16px;
  }
`;

const IconContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

const TurnIntoDropdown = () => {
  const editor = useEditorRef();

  const value = useSelectionFragmentProp({
    defaultValue: ParagraphPlugin.key,
    getProp: (node) => node.type,
  });

  const selectedItem = useMemo(
    () => TURN_INTO_ELEMENTS.find((item) => item.id === (value ?? ParagraphPlugin.key)) ?? TURN_INTO_ELEMENTS[0],
    [value]
  );

  const handleToggleBlock = (type: string) => {
    toggleBlock(editor, { type });
    collapseSelection(editor);

    focusEditor(editor);
  };

  return (
    <Select
      onChange={(value) => {
        handleToggleBlock(String(value));
      }}
      value={selectedItem.id}
    >
      <Select.Header>
        {({ toggleShowOptions, shouldShowOptions }) => (
          <TriggerButtonStyled onClick={toggleShowOptions} size="small" variant="ghost" toggled={shouldShowOptions}>
            {selectedItem.label}
            <Icon icon={'angle-down'} />
          </TriggerButtonStyled>
        )}
      </Select.Header>
      <TurnIntoListStyled>
        {TURN_INTO_ELEMENTS?.map(({ id, label, icon }) => {
          return (
            <TurnIntoOptionStyled value={id} key={id}>
              <IconContainerStyled>
                <Icon icon={icon} size="sm" />
              </IconContainerStyled>
              {label}
            </TurnIntoOptionStyled>
          );
        })}
      </TurnIntoListStyled>
    </Select>
  );
};

export const FloatingToolbarButtons = (): JSX.Element => {
  return (
    <StyledToolbarGroup>
      <TurnIntoDropdown />
      <Divider minimized />
      <MarkToolbarButtonsGroup />
      <Divider minimized />
      <LinkToolbarButton />
    </StyledToolbarGroup>
  );
};
