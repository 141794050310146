import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from '@/ui/Modal';
import { Button } from '@/ui/Button';
import { VideoLinkForm } from '@/component/customEditorV2/ui/elements/Video/VideoLinkForm';

type VideoLinkModalProps = {
  onClose: () => void;
  open: boolean;
  onSubmit: (link: string) => void;
};

export type FormSchema = {
  link: string;
};

export enum TextFieldName {
  Link = 'link',
}

export const VideoLinkModal = ({ onClose, open, onSubmit }: VideoLinkModalProps): JSX.Element => {
  const form = useForm<FormSchema>({
    defaultValues: {
      link: '',
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const _handleSubmit = (data: { link: string }) => {
    onSubmit(data.link);
    onClose();
  };

  const handleSubmit = form.handleSubmit(_handleSubmit);

  return (
    <Modal open={open} onClose={onClose} size="mobile">
      <Modal.Title>Add video link</Modal.Title>
      <Modal.Contents>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <VideoLinkForm />
          </form>
        </FormProvider>
      </Modal.Contents>
      <Modal.Actions>
        <Button variant="ghost" onClick={handleClose} title="Cancel">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} title="Add">
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
