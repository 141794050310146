import styled from 'styled-components';

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 92px);
`;
export const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ListStyled = styled.div`
  overflow: scroll;
`;
