import { BlockType, ResultBlock, SurveyResultBlock } from './types';
import { BlockId } from '../types';
import { Course } from '@/administration/pages/Course/store/course/types';
import { SurveyVariant } from '@/pages/Survey/store/survey/types';
import { PracticalAssessment } from '@/pages/PracticalAssessment/store/practicalAssessment/types';

export const createResultBlock = (course?: Course | PracticalAssessment): ResultBlock => ({
  id: `block-l-return` as BlockId,
  type: BlockType.RESULT,
  level: course?.level || '',
  effortHours: course?.effortHours || undefined,
  effortMinutes: course?.effortMinutes || undefined,
});

export const createSurveyResultBlock = (surveyVariant: SurveyVariant): SurveyResultBlock => ({
  id: `block-l-return` as BlockId,
  type: BlockType.SURVEY_RESULT,
  variant: surveyVariant,
});
