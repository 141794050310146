import { FILES, FILES_LIST } from './files';
import { config } from '@/config';
import collegialLogo from '@/assets/logos/collegial-grey.svg';

/**
 * Generate CDN asset URL for a given assetPath
 * @param assetPath
 */
export function getAssetUrl(file: FILES_LIST): string {
  if (!config.cdnUrl) return '';

  return `${config.cdnUrl}/realms/${window.realm}/${file}`;
}

/**
 * Get URL for current realm
 */
export function getLogoUrl(): string {
  if (!config.cdnUrl) return collegialLogo;

  return getAssetUrl(FILES.LOGO);
}
