import styled from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';

export const WelcomeCardContainer = styled.div`
  align-items: center;
  color: var(--color-moon-crater-gray);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px;
  z-index: 1;

  @media screen and (max-width: 1023px) {
    border-bottom: 1px solid ${cvar('color-alto')};
  }

  @media screen and (min-width: 1024px) {
    border-radius: ${cvar('radius-inner')};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: -50%;
    max-width: calc(100vw * (1 / 3));
  }
`;

export const WelcomeCardContainerV2 = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
  gap: 16px;
  padding: ${({ noPadding }) => (noPadding ? '0' : '28px')};

  background-color: ${(p) => p.theme.colors.neutral.background};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.outline1};
  > .progress {
    margin: 0;
  }
`;
