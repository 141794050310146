import dayjs from 'dayjs';

import { LearningCard } from '@/types/learning/card';
import { EventEnrollment } from '@/types/learning/learning-catalog';
import { E_LEARNING_OBJECT_ID_PREFIX } from '@/utils/analyticObjects';
import { AddEventCardsProps, EventCardsProps, EventCardWithProgressStatus, PastEventCardsProps, ProgressStatus } from './types';

const getFutureEventEnrollments = (events: readonly EventEnrollment[]): EventEnrollment[] => {
  const today = dayjs(new Date());

  return events.filter(({ startDate }) => dayjs(startDate).isAfter(today));
};

const addEventCard = ({
  participation,
  card,
  event,
  eventStartDate,
  eventEndDate,
  learningId,
}: AddEventCardsProps): EventCardWithProgressStatus => {
  const participationStartDate = participation?.firstActivity ? new Date(participation.firstActivity) : undefined;
  const participationEndDate = participation?.lastActivity ? new Date(participation.lastActivity) : undefined;
  const { locations, ...rest } = card;
  const filteredLocation = locations?.filter((l) => l.id === event.locationId);
  if (
    !!participationStartDate &&
    participationEndDate &&
    eventEndDate &&
    participationStartDate >= eventStartDate &&
    participationEndDate <= eventEndDate
  ) {
    return {
      id: learningId,
      card: {
        ...rest,
        customKey: `${card.id}-${event.locationId.toString()}`,
        locations: filteredLocation,
        participation: participation,
      },
      status: participation?.isCompleted ? 'completed' : 'obsolete',
    };
  } else {
    return {
      id: learningId,
      card: { ...rest, customKey: `${card.id}-${event.locationId.toString()}`, locations: filteredLocation },
      status: 'obsolete',
    };
  }
};

const getPastEventEnrollmentsWithParticipationData = ({
  events,
  learningId,
  card,
  participation,
}: PastEventCardsProps): EventCardWithProgressStatus[] => {
  const pastEventCards: EventCardWithProgressStatus[] = [];

  events.forEach((event) => {
    const eventStartDate = new Date(event.startDate);
    const eventEndDate = event?.endDate ? new Date(event.endDate) : undefined;
    pastEventCards.push(addEventCard({ card, event, learningId, eventStartDate, eventEndDate, participation }));
    participation?.previousParticipations?.forEach((previousParticipation) =>
      pastEventCards.push(
        addEventCard({ card, event, learningId, eventStartDate, eventEndDate, participation: previousParticipation })
      )
    );
  });

  return pastEventCards;
};

export const getCardsForEventLearnings = ({
  learnings,
  catalogCards,
  participations,
  eventEnrollments,
}: EventCardsProps): EventCardWithProgressStatus[] => {
  return learnings
    .filter((m) => !!m.learningId)
    .map((learning): { card: LearningCard; status: ProgressStatus; id: number }[] => {
      const enrollments = eventEnrollments?.filter((x) => x.learningId === learning.learningId) ?? [];
      const card = catalogCards.find(
        (card) => card.entity === 'learning' && card.entityId === learning.learningId
      ) as LearningCard;

      if (!card || !enrollments) return [];

      const futureEnrollments = getFutureEventEnrollments(enrollments);
      const pastEventEventEnrollments = enrollments.filter((x) => !futureEnrollments.some((m) => m.id === x.id));
      const participation = participations?.find((m) => m.catalogObjectId === `${E_LEARNING_OBJECT_ID_PREFIX}${card?.entityId}`);
      const pastEventCards = getPastEventEnrollmentsWithParticipationData({
        events: pastEventEventEnrollments,
        participation,
        learningId: learning.id,
        card,
      });
      const futureEventCards = futureEnrollments.map((m): EventCardWithProgressStatus => {
        const { locations, ...rest } = card;
        const filteredLocation = locations?.filter((l) => l.id === m.locationId);
        return {
          id: learning.id,
          card: { ...rest, customKey: `${card.id}-${m.locationId.toString()}`, locations: filteredLocation },
          status: 'not-started',
        };
      });

      return futureEventCards.concat(pastEventCards);
    })
    .flat();
};
