import React, { FC, useCallback, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { UserSelfTrackedLearning } from '@/component/UserSelfTrackedLearning/types';
import { UserSelfTrackedLearningForm } from '@/component/UserSelfTrackedLearning/components/UserSelfTrackedLearningForm/UserSelfTrackedLearningForm';
import { SharedSnackbarContext, SnackbarType } from '@/component/SharedSnackbar/SharedSnackbar';
import { getInventoryQuery } from '@/store/inventory';
import { trackAddUserSelfTrackedLearning } from '@/utils/tracking/homePage';
import { getEffortForUser } from '@/store/analytics/learning-progress';
import { useCurrentUser } from '@/store/currentUser';

const addUserSelfTrackedLearningMutation = gql`
  mutation createSelfTrackedLearning($userSelfTrackedLearning: CreateSelfTrackedLearningInput!) {
    createSelfTrackedLearning(input: $userSelfTrackedLearning) {
      success
    }
  }
`;

type AddUserSelfTrackedLearningProps = {
  onClose?: () => void;
};

export const AddUserSelfTrackedLearning: FC<AddUserSelfTrackedLearningProps> = ({ onClose }) => {
  const { t } = useTranslation('userSelfTrackedLearning');
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const { user } = useCurrentUser();

  const [addUserSelfTrackedLearning, { loading }] = useMutation<{
    createSelfTrackedLearning: { success: boolean };
  }>(addUserSelfTrackedLearningMutation, {
    refetchQueries: [getInventoryQuery, getEffortForUser],
  });

  const handleAddUserSelfTrackedLearning = useCallback(async (userSelfTrackedLearning: UserSelfTrackedLearning) => {
    trackAddUserSelfTrackedLearning(Math.floor(userSelfTrackedLearning.effort / 60));
    const { data } = await addUserSelfTrackedLearning({
      variables: { userSelfTrackedLearning: { ...userSelfTrackedLearning, userId: user?.id } },
    });

    if (data?.createSelfTrackedLearning?.success) {
      openSnackbar({
        type: SnackbarType.SUCCESS,
        message: t('Learning has been added'),
        isDismissive: true
      });
    }

    if (onClose) {
      onClose();
    }
  }, []);

  return <UserSelfTrackedLearningForm loading={loading} save={handleAddUserSelfTrackedLearning} />;
};
