import styled from 'styled-components';

import { tabItemActive, tabItemBase, tabItemInactiveFocus, tabItemInactiveHover } from './styles';

type TabButtonProps = {
  /**
   * Text label for the tab button.
   */
  label: string;
  /**
   * Click event callback
   */
  onClick?: () => void;
  /**
   * Marks the tab button as active.
   */
  isActive?: boolean;
};

const Button = styled.button<{ isActive: boolean }>`
  ${tabItemBase}
  ${p => p.isActive && tabItemActive};
  &:hover {
    ${p => !p.isActive && tabItemInactiveHover};
  }
  &:not(:hover):focus {
    ${p => !p.isActive && tabItemInactiveFocus};
  }
`;

export const TabButton = ({ label, isActive = false, onClick, className }: WithClassNameProp<TabButtonProps>): JSX.Element => (
  <Button isActive={isActive} onClick={onClick} className={className}>
    {label}
  </Button>
);
