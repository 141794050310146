/* eslint-disable import/no-unused-modules */
import React from 'react';

import { WeekTab } from '../../..';
import { useWeekNavigationsContext } from '../../context';

interface WeeksTabNavigationProps {
  onChangeSelectedWeek: (weekNumber: number) => void;
  showProgressLabel?: boolean;
  showSelectedStyling?: boolean;
}

export const WeeksTabNavigation = ({
  onChangeSelectedWeek,
  showProgressLabel,
  showSelectedStyling = true,
}: WeeksTabNavigationProps): JSX.Element => {
  const { shownWeeks, selectedWeek } = useWeekNavigationsContext();
  return (
    <>
      {shownWeeks.map((props) => (
        <WeekTab
          key={props.weekNumber}
          {...props}
          onClick={() => {
            onChangeSelectedWeek(props.weekNumber);
          }}
          selected={props.weekNumber === selectedWeek?.weekNumber && !!showSelectedStyling}
          showProgressLabel={showProgressLabel}
        />
      ))}
    </>
  );
};
