import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { cvar } from '@/styles/GlobalStyle';
import { CIconProps, IconPropType } from '@/types/icon';
import { Icon } from '@/components/Icon';

const IconWrapper = styled.div`
  position: relative;
`;
const Counter = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: ${cvar('color-secondary')};
`;

interface Props extends Omit<CIconProps, 'icon'> {
  counter: number;
  icon?: IconPropType;
}
const BASE_CONTAINER_CLASS = 'icon-container';

export const IconCounter = ({ counter, icon, className, ...rest }: Props): JSX.Element => {
  const showCustomIcon = icon && counter === 0;
  // Cannot use ternary due to TS2590: Expression produces a union type that is too complex to represent.
  let iconToUse: IconPropType = 'circle';
  if (showCustomIcon) {
    iconToUse = icon!;
  }
  return (
    <IconWrapper className={clsx(BASE_CONTAINER_CLASS)}>
      <Icon icon={iconToUse} size="lg" {...rest} />
      {!showCustomIcon && <Counter>{counter}</Counter>}
    </IconWrapper>
  );
};
