import { RotateProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { CSSProperties } from 'react';

import { IconPropType } from '@/ui/Icon/types';

const ButtonSize = ['default', 'x-mini', 'mini', 'large', 'small'] as const;
type ButtonSizeTuple = typeof ButtonSize;
export type ButtonSizeUnion = ButtonSizeTuple[number];
export const ButtonSizeEnum = ButtonSize.reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {} as { [key in ButtonSizeUnion]: string });

export type ButtonProps = React.ComponentProps<'button'> & {
  $ariaLabel?: string;
  $icon?: IconPropType;
  $iconPosition?: 'left' | 'right';
  $iconRotation?: RotateProp;
  $iconSize?: SizeProp;
  $iconCounter?: number;
  $iconFixedWidth?: boolean;
  $iconStyle?: CSSProperties;
  $loading?: boolean;
  $round?: boolean;
  $size?: ButtonSizeUnion;
  $toggled?: boolean;
  $fullwidth?: boolean;
  $type?: ButtonType;
  $onlyIcon?: boolean;
  $hasIcon?: boolean;
  $textButtonAlign?: boolean;
  $visibility?: 'visible' | 'hidden';
  $gridArea?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
};

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'quaternary'
  | 'default'
  | 'text'
  | 'link'
  | 'base'
  | 'baseInverted'
  | 'static'
  | 'hidden'
  | 'danger'
  | 'whiteButton'
  | 'primaryV2'
  | 'toolTip';
