export const courseCardBase = `
  id
  entity
  entityId
  title
  teaser
  subjects {
    category
    categoryId
    subject
    subjectId
  }
  language
  languageId
  image
  canEdit
  featured
  available
  type
  spaceId
  archived
  provider
  visibility
  participation {
    completedAt
  }
`;

const commonLearningItemFields = `
  totalEffort
  level
  participation {
    progress
    isCompleted
    userEffort
    completedAt
    expiredAt
  }
`;

export const learningCardExtras = `
  ... on LearningCard {
    ${commonLearningItemFields}
    locations {
      id
      startDate
      endDate
    }
    attributes
  }
`;
export const productCardExtras = `
  ... on ProductCard {
    ${commonLearningItemFields}
    format
    customLink
    isApprovalRequired
    state
    attributes
    objectId
  }
`;
