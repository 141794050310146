import styled from 'styled-components';

import { toHtml } from '@/component/customEditor/logic/serialization';

type RichTextDisplayerProps = {
  content: string;
};

export const TextStyled = styled.div`
  ${(p) => p.theme.typography.body1};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  ol {
    padding-left: 20px;
  }
`;

export const RichTextDisplayer = ({ content }: RichTextDisplayerProps): JSX.Element => {
  return <TextStyled dangerouslySetInnerHTML={{ __html: toHtml(content) }} />;
};
