import { useMediaState } from '@udecode/plate-media/react';

import { FloatingToolbarButtons } from './FloatingToolbarButtons';
import { FILE_FORMAT } from '@/component/FileUpload/constants';
import { useSelectedElementFloatingToolbar } from '@/component/customEditorV2/hooks/elementFloatingToolbar/useSelectedElementFloatingToolbar';
import { FileCard } from '../FileCard';
import { PDFStyled, PowerPointStyled } from './styles';
import { DownloadableResource } from '@/administration/pages/Course/blocks/document/components/DownloadableResource';

export const Resource = ({
  onUpload,
  onRemove,
  fileExtension,
  url,
  name,
  shouldShowToolbar,
}: {
  onUpload: (file: File) => void;
  onRemove: () => void;
  url: string;
  name: string;
  fileExtension: string;
  shouldShowToolbar: boolean;
}): JSX.Element => {
  const { setFloating, setReference, style } = useSelectedElementFloatingToolbar({ selected: shouldShowToolbar });
  const { readOnly } = useMediaState();

  const renderPreview = () => {
    switch (fileExtension) {
      case FILE_FORMAT.PDF:
        return <PDFStyled data={url} type="application/pdf" isInteractive={shouldShowToolbar || readOnly} />;
      case FILE_FORMAT.PPTX:
        return (
          <PowerPointStyled
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            isInteractive={shouldShowToolbar || readOnly}
          />
        );
      default: {
        if (readOnly) {
          return <DownloadableResource downloadUrl={url} fileExtension={fileExtension} fileName={name} bordered />;
        } else {
          return <FileCard downloadUrl={url} fileExtension={fileExtension} fileTitle={name} />;
        }
      }
    }
  };

  return (
    <>
      <div ref={setReference} style={{ width: '100%' }}>
        {renderPreview()}
      </div>
      <div
        ref={setFloating}
        style={{
          ...style,
        }}
      >
        <FloatingToolbarButtons onRemoveClick={onRemove} onReplaceFile={onUpload} />
      </div>
    </>
  );
};
