import React from 'react';
import styled from 'styled-components';

import { Button, IconButton } from '@/ui/Button';
import { Skeleton } from '@/ui/Skeleton';

type GenerationResultProps = {
  loading?: boolean;
  hasLimitReachedError: boolean;
  result: string;
  isCopied?: boolean;
  onReplaceClick: () => void;
  onRegenerateClick: () => void;
  onCopyToClipboardClick: () => void;
};

const ResultStyled = styled.div`
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[8]};
  width: 500px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const ResultActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const ErrorStyled = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
`;

const LoadingRootStyled = styled.div`
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[8]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
`;

const LoadingActionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const RegenerateButton = styled(IconButton)`
  position: absolute;
  top: -18px;
  right: -18px;
  background: ${(p) => p.theme.colors.neutral.background};
  ${(p) => p.theme.shadow.primary[16]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.container};
  }
`;

const LoadingResult = () => {
  return (
    <LoadingRootStyled>
      <Skeleton width={468} height={16} />
      <Skeleton width={468} height={16} />
      <Skeleton width={468} height={16} />
      <Skeleton width={468} height={16} />
      <Skeleton width={468} height={16} />
      <LoadingActionStyled>
        <Skeleton width={100} height={32} />
        <Skeleton width={100} height={32} />
      </LoadingActionStyled>
    </LoadingRootStyled>
  );
};

export const GenerationResult = ({
  loading,
  result,
  isCopied,
  hasLimitReachedError,
  onReplaceClick,
  onRegenerateClick,
  onCopyToClipboardClick,
}: GenerationResultProps): JSX.Element | null => {
  if (!loading && !result && !hasLimitReachedError) return null;

  if (loading) return <LoadingResult />;

  return (
    <ResultStyled>
      {(result || hasLimitReachedError) && (
        <>
          {hasLimitReachedError ? (
            <ErrorStyled>
              Free plan limit of 3 request per minute was reached.
              <br />
              Try again in <strong>20 seconds</strong>
            </ErrorStyled>
          ) : (
            <p>{result}</p>
          )}
          <ResultActionsContainer>
            <RegenerateButton icon={['far', 'arrows-rotate']} onClick={onRegenerateClick} buttonVariant="dark" />

            <Button variant="ghost" onClick={onCopyToClipboardClick} disabled={isCopied} icon={isCopied ? 'check' : undefined}>
              {isCopied ? `Copied` : `Copy`}
            </Button>
            <Button variant="primary" onClick={onReplaceClick}>
              Replace & Close
            </Button>
          </ResultActionsContainer>
        </>
      )}
    </ResultStyled>
  );
};
