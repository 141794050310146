import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@/ui/Skeleton';
import { HeadingWrapper, SubHeading } from './atoms';
import { SubtitleSpacer } from './atoms/Heading';

type PageLayoutProps = {
  hasSubHeading?: boolean;
  hasSubtitle?: boolean;
};
const Wrapper = styled.div``;

const HeadingGridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
`;

const SubHeadingGridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 12px;
`;

const LoadingHeading = () => <Skeleton width={300} height={44} />;
const LoadingSubtitle = () => <Skeleton width={450} height={28} />;
const LoadingSubHeadingItem = () => <Skeleton width={80} height={20} />;
const LoadingSubHeadingData = () => <Skeleton width={100} height={24} />;

export const LoadingStateHeaderLayout = ({ hasSubHeading, hasSubtitle }: PageLayoutProps): JSX.Element => {
  // Based on design, we only apply spacer, where there is either both subtitle and subheading or none of those provided
  const spacerNeeded = (hasSubtitle && hasSubHeading) || (!hasSubtitle && !hasSubHeading);

  return (
    <HeadingWrapper>
      <Wrapper>
        <HeadingGridStyle>
          <LoadingHeading />
          {hasSubtitle && <LoadingSubtitle />}
          {spacerNeeded && <SubtitleSpacer />}
        </HeadingGridStyle>
      </Wrapper>
      {hasSubHeading && (
        <SubHeading>
          <SubHeadingGridStyle>
            <LoadingSubHeadingItem />
            <LoadingSubHeadingItem />
            <LoadingSubHeadingData />
            <LoadingSubHeadingData />
          </SubHeadingGridStyle>
        </SubHeading>
      )}
    </HeadingWrapper>
  );
};
