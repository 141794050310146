import styled, { keyframes } from 'styled-components';

import { SkeletonProps } from './types';

const WaveKeyFrames = keyframes`
   100% {
    transform: translateX(100%);
  }
`;

export const RootStyled = styled.div<SkeletonProps>`
  display: inline-block;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: ${({ minwidth }) => minwidth && `${minwidth}px`};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${({ mode, theme }) => (mode === 'dark' ? theme.colors.neutral.outline1 : theme.colors.neutral.container)};

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${WaveKeyFrames} 1s infinite;
    content: '';
  }
`;
