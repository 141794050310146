export const assignmentBase = `
id
spaceId
name
teaser
description
image
type
status
createdAt
updatedAt
__typename
`;

export const assigmentTypes = `
... on AssignmentStandardV3 {
  progress (filter: {userTeamIdIN: $userTeamIdIN}) @include(if: $includeUserTeams) {
    completedCount
    completionRate
  }
}
... on AssignmentStrategicV3 {
  startAt
  endAt
  learningLoopRecommendedEffort
  totalThresholdEffort
}
`;

export const infoExtras = `
limit
page
total
`;

export const learnings = `
learnings(pagination: $pagination) @include(if: $includeLearnings){
  data {
    id
    assignmentGroupId
    learningPathId
    learningId
    order
  }
  ... on AssignmentStandardV3LearningPage {
    data {
      ... on AssignmentStandardV3Learning{
        type
      }
    }
  }
  ${infoExtras}
}
`;

export const users = `
users(pagination: $pagination, filter: { userTeamIdIN: $userTeamIdIN, userIdIN: $userIdIN, searchPhrase: $searchPhrase }) @include(if: $includeUsers) {
  data {
    assignmentId
    userId
    agent
    order
    user {
      id
      username
      email
      timestamp
      status
      teams @include(if: $includeUserTeams) {
        data {
          teamId 
          team {
            id
            name
          }
        }
      }
    }
  }
  ... on AssignmentStandardV3UsersPage{
    data {
      ... on AssignmentStandardV3User{
        progress {
          estimatedEffort
          requiredItemsCompletedCount
          requiredItemsCount
          completed
          participations {
            assignmentLearningId
            participationId
            progress
            completedAt
            expiredAt
          }
        }
      }
    }
  }
  ... on AssignmentStrategicV3UsersPage{
    data {
      ... on AssignmentStrategicV3User{
        progress {
          completed
          estimatedEffort
        }
      }
    }
  }
  ${infoExtras}
}
`;
