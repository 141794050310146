import React, { InputHTMLAttributes, RefObject } from 'react';
import { FieldError } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { CSSProperties } from 'styled-components';

import { Cursor, StyledInputV2, StyledIconV2 } from './styles';
import { StyledLabel } from '../Label/styles';
import { IconPropType } from '@/ui/Icon';
import { StyledError, StyledFieldWrapper } from '@/components/Form/styles';
import { Icon } from '@/components/Icon';

export type InputBasicProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  icon?: IconPropType;
  loading?: boolean;
  writableWhileLoading?: boolean;
  overline?: boolean;
  hiddenLabel?: boolean;
  error?: FieldError;
  onIconClick?: () => void;
  ref?: RefObject<HTMLInputElement>;
  rotatedIcon?: boolean;
  shouldShowCloseIcon?: boolean;
  cursor?: Cursor;
  newTheme?: boolean;
  onCloseIconClick?: () => void;
};

const closeIconStyle = {
  cursor: 'pointer',
  width: 41,
  height: 44,
  position: 'absolute',
  right: 0,
  padding: '16px 0',
} as CSSProperties;

export function InputBasicV2({
  label,
  icon,
  loading,
  overline,
  disabled,
  required,
  hiddenLabel,
  placeholder,
  writableWhileLoading,
  error,
  onChange,
  onBlur,
  rotatedIcon,
  cursor,
  onIconClick,
  shouldShowCloseIcon,
  onCloseIconClick,
  newTheme,
  ...rest
}: InputBasicProps): JSX.Element {
  const id = uuid();
  const elementDisabled = disabled || (loading && !writableWhileLoading);
  let inputIcon = icon;
  if (loading) inputIcon = 'spinner-third';

  const handleIconClick = () => {
    if (elementDisabled || !onIconClick) return;
    onIconClick();
  };

  return (
    <StyledFieldWrapper>
      {!hiddenLabel && (
        <StyledLabel htmlFor={id} $required={required}>
          {label}
        </StyledLabel>
      )}
      <StyledInputV2
        id={id}
        aria-label={label}
        $error={!!error}
        $hasIcon={!!inputIcon}
        placeholder={placeholder}
        readOnly={elementDisabled}
        disabled={elementDisabled}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        $overline={overline}
        $newTheme={newTheme}
        cursor={cursor}
        {...rest}
      />
      {!!inputIcon && !disabled && (
        // TODO: Wrap this in a Button
        <StyledIconV2
          onClick={handleIconClick}
          rotated={rotatedIcon}
          spin={loading}
          newTheme={newTheme}
          style={shouldShowCloseIcon ? { right: 29 } : {}}
          disabled={elementDisabled}
        >
          <Icon icon={inputIcon} fixedWidth />
        </StyledIconV2>
      )}
      {shouldShowCloseIcon && <Icon icon={'times'} onClick={onCloseIconClick} style={closeIconStyle} />}
      {!!error && <StyledError role="alert">{error.message}</StyledError>}
    </StyledFieldWrapper>
  );
}
