import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './post-media.scss';
import { PostMedia } from '../../types/post';
import { FileUpload } from '../FileUpload/FileUpload';
import { PostMediaContent } from './PostMediaContent';
import { convertVideoToEmbed } from '../../administration/utils/videoUrl';
import { CoursePageContext } from '../../pages/learning/CourseDetails/CoursePageContext';
import { ErrorMessage } from '../ErrorMessage';
import { AutoComplete } from '../../components/Form/types';
import { Button } from '@/components/Button/Button';
import { ButtonGroup } from '@/components/Button/ButtonGroup';

interface PostMediaProps {
  media?: PostMedia;
  onMediaSelected: (media: PostMedia) => void;
  unsetMedia: () => void;
}

enum MediaType {
  RESOURCE = 'resource',
  IMAGE = 'image',
  VIDEO = 'video',
}

export const PostMediaFormFragment = ({ media, onMediaSelected, unsetMedia }: PostMediaProps): JSX.Element => {
  const { t } = useTranslation('fileUpload');
  const [mediaType, setMediaType] = useState(media?.type);
  const [videoUrl, setVideoUrl] = useState('');
  const { spaceId } = useContext(CoursePageContext);

  if (!spaceId) {
    return (
      <ErrorMessage>
        <span>{t('Cannot upload file - product does not belong to a space')}</span>
      </ErrorMessage>
    );
  }

  const resetMedia = () => {
    unsetMedia();
    setMediaType(undefined);
  };

  const handleVideoUrl = () => {
    onMediaSelected({
      type: MediaType.VIDEO,
      url: convertVideoToEmbed(videoUrl),
    });
  };
  return (
    <>
      <div>
        {media ? (
          <Button onClick={resetMedia}>{t('Remove media')}</Button>
        ) : (
          <ButtonGroup<PostMedia['type']>
            active={mediaType}
            data={[
              {
                value: MediaType.RESOURCE,
                label: t('PDF'),
              },
              {
                value: MediaType.IMAGE,
                label: t('Image'),
              },
              {
                value: MediaType.VIDEO,
                label: t('Video'),
              },
            ]}
            icon="plus"
            handleClick={setMediaType}
            padded
            vertical
            size="small"
          />
        )}
      </div>
      {mediaType === MediaType.RESOURCE && (
        <div className="field">
          <FileUpload
            spaceId={spaceId}
            type="resource"
            path="discussions"
            uploaded={(url) => onMediaSelected({ type: MediaType.RESOURCE, url })}
            url={media?.type === MediaType.RESOURCE ? media?.url : ''}
            isDiscussions={true}
          />
        </div>
      )}
      {mediaType === MediaType.IMAGE && (
        <div className="field">
          <FileUpload
            spaceId={spaceId}
            type="image"
            path="discussions"
            uploaded={(url) => onMediaSelected({ type: MediaType.IMAGE, url })}
            url={media?.type === MediaType.IMAGE ? media?.url : ''}
            isDiscussions={true}
          />
        </div>
      )}
      {mediaType === MediaType.VIDEO &&
        (media?.url ? (
          <div className="field">
            <div className="control">
              <div className="label">{t('Video preview')}</div>
              <PostMediaContent media={media} />
            </div>
          </div>
        ) : (
          <>
            <div className="field is-floating-label">
              <div className="label">{t('URL')}</div>
              <div className="control is-expanded">
                <input
                  type="text"
                  autoComplete={AutoComplete.on}
                  className="input"
                  placeholder={t('Video embed URL')}
                  onChange={(e) => setVideoUrl(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <Button $type="primary" $fullwidth onClick={handleVideoUrl} disabled={!videoUrl}>
                  {t('Embed external video')}
                </Button>
              </div>
            </div>
          </>
        ))}
    </>
  );
};
