import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const Error = styled.div<{ justify?: 'start' | 'center' | 'end' }>`
  grid-area: error;
  justify-self: ${({ justify }) => justify || 'center'};
  padding: 0 8px;
  ${(p) => p.theme.typography.caption}
  color: ${(p) => p.theme.colors.error.main};
  opacity: 0;
  animation: ${appear} ${(p) => p.theme.transitions.normal}ms ${(p) => p.theme.transitions.normal}ms forwards;
`;
