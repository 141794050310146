/* eslint-disable import/no-unused-modules */
import { createContext } from 'react';

type EditorAssistantContextState = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  totalCharge: number;
  setTotalCharge: (totalCharge: number) => void;
};
export const EditorAssistantContext = createContext<EditorAssistantContextState>({
  enabled: false,
  setEnabled: () => {},
  totalCharge: 0,
  setTotalCharge: () => {},
});
