import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@/administration/component/FormInput/TextArea';
import { AutoComplete } from '@/components/Form/types';

type WhatDidILearnSectionProps = {
  whatDidILearn: string;
  setWhatDidILearn: (setWhatDidILearn: string) => void;
};

export const WhatDidILearnSection: FC<WhatDidILearnSectionProps> = ({ whatDidILearn, setWhatDidILearn }) => {
  const { t } = useTranslation('userSelfTrackedLearning');

  return (
    <div className="field has-addons has-addons--mobile is-floating-label">
      <div className="control is-expanded">
        <div className="field">
          <TextArea
            autoComplete={AutoComplete.off}
            value={whatDidILearn}
            onChange={e => setWhatDidILearn(e.currentTarget.value)}
            label={t('What did I learn')}
            rows={2}
          />
        </div>
      </div>
    </div>
  );
};
