import styled from 'styled-components';

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const PreReflectionMessageStyled = styled.div`
  ${({ theme }) => theme.typography.body1};
  display: flex;
  gap: 12px;
  align-items: center;
`;
