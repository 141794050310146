import { Editor, Element as SlateElement, Transforms, Range, BaseSelection } from 'slate';
import _defer from 'lodash/defer';
import { ReactEditor } from 'slate-react';

import { CustomSlateElementType } from '@/types/slate';

export const insertLink = (editor: Editor, url: string, text?: string): void => {
  /**
   * We have to rely on blurSelection here, since the editor.selection is vanished,
   * after we deselect the editor: like, when we focus the link popover input.
   *
   * Starting from that time, the selection we had is lost forever, meaning we don't have a place to paste a link anymore.
   *
   * It's a popular issue.
   *
   * Examples:
   * - @link https://stackoverflow.com/questions/70022858/how-to-create-a-hyperlink-insertion-modal-without-losing-text-selection
   * - @link https://github.com/ianstormtaylor/slate/issues/3412
   *
   * The trick for the workaround is to manipulate customly pre-saved selection,
   * which we set up, when we open link popover, and use to force restoring the selection.
   *
   * @link https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-573345842
   * @link https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
   */
  Transforms.select(editor, editor.blurSelection);

  /**
   * Though calling Transform.select with pre-saved selection handles the selection lost case, we need to defer the insertion
   *
   * Explanation:
   * @link  https://github.com/ianstormtaylor/slate/issues/4240#issuecomment-920834086
   */
  _defer(() => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    const link = {
      type: 'link' as CustomSlateElementType,
      url,
      children: [{ text: text ? text : url }],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }

    /**
     * We want to move the cursor out of the current link item after inserting it,
     * to avoid setting another link in the same <a></a> item due to the cursor position.
     *
     * Default left/right behavior is unit:'character'.
     *
     * According to comments in the official example
     * (
     * @link https://github.com/ianstormtaylor/slate/blob/9874ed704fecd989e1fed4114e4d4882f5d8aa76/site/examples/inlines.tsx#L65
     * )
     * it fails to distinguish between two cursor positions, such as
     * <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
     */

    Transforms.move(editor, { unit: 'offset' });

    // We should restore focus after transformations
    ReactEditor.focus(editor);
  });
};

const unwrapLink = (editor: Editor): void => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const isLinkActive = (editor: Editor): boolean => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });

  return !!link;
};

export const hasSelection = (editor: Editor): boolean => {
  const anchor = ((editor?.blurSelection as BaseSelection) || editor?.selection)?.anchor;
  const focus = ((editor?.blurSelection as BaseSelection) || editor?.selection)?.focus;

  return anchor?.offset !== focus?.offset;
};
