import { FetchResult, gql, useMutation } from '@apollo/client';

import { AssignmentUserSurveyInput, AssignmentUserSurveyResult } from '@/store/learningLoops/types';

export const ADD_ASSIGNMENT_USER_SURVEY_MUTATION = gql`
  mutation addAssignmentUserSurvey($input: AssignmentUserSurveyInput!) {
    addAssignmentUserSurvey(input: $input) {
      success
    }
  }
`;

export const useAddAssignmentUserSurvey = (): {
  addAssignmentUserSurvey: (
    input: AssignmentUserSurveyInput
  ) => Promise<FetchResult<{ addAssignmentUserSurvey: AssignmentUserSurveyResult }>>;
  loading: boolean;
  error: boolean;
} => {
  const [addAssignmentUserSurvey, { loading, error }] = useMutation<{
    addAssignmentUserSurvey: AssignmentUserSurveyResult;
  }>(ADD_ASSIGNMENT_USER_SURVEY_MUTATION);

  return {
    addAssignmentUserSurvey: async (input: AssignmentUserSurveyInput) =>
      addAssignmentUserSurvey({
        variables: { input },
      }),
    loading,
    error: !!error,
  };
};
