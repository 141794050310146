import React, { useContext } from 'react';

import { OptionStyled, OptionPrefixStyled, OptionSuffixStyled, OptionTextStyled } from './styles';
import { SelectContext } from '../SelectContext';
import { OptionProps } from './types';

export const Option = ({ children, value: optionValue, prefix, suffix, className }: OptionProps): JSX.Element => {
  const { onOptionClick, value: selectValue } = useContext(SelectContext);

  const isSelected = Array.isArray(selectValue) ? selectValue.includes(optionValue) : selectValue === optionValue;

  return (
    <OptionStyled selected={isSelected} onClick={() => onOptionClick(optionValue)} className={className}>
      {prefix && <OptionPrefixStyled>{prefix}</OptionPrefixStyled>}
      <OptionTextStyled>{children}</OptionTextStyled>
      {suffix && <OptionSuffixStyled>{suffix}</OptionSuffixStyled>}
    </OptionStyled>
  );
};
