import styled from 'styled-components';

import { Skeleton } from '@/ui/Skeleton';
import { cardStyles } from './Card';

export const Loader = (): JSX.Element => (
  <CardBox>
    <Title />
    <Labels />
    <Footer />
    <Image />
  </CardBox>
);

const CardBox = styled.div`
  ${cardStyles}
`;

const Title = styled(Skeleton)`
  grid-area: title;
  height: 24px;
`;

const Labels = styled(Skeleton)`
  grid-area: labels;
  height: 16px;
  width: 100%;
  max-width: 140px;
`;

const Footer = styled(Skeleton)`
  grid-area: metadata;
  height: 16px;
  align-self: flex-end;
  width: 100%;
  max-width: 160px;
`;

const Image = styled(Skeleton)`
  grid-area: image;
  width: 124px;
  height: 100%;
`;
