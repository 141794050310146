import styled from 'styled-components';

export const LinkPopoverWrapper = styled.div`
  background: var(--color-white);
  border-radius: var(--radius-inner);
  box-shadow: 0 0 35px rgba(50, 50, 93, 0.1), 0 0 15px rgba(0, 0, 0, 0.07);
  color: var(--color-mine-shaft);
  padding: var(--gap-xs);
  text-align: center;
  width: 300px;

  > * + * {
    margin-top: 12px;
  }
`;

type MessageWrapperProps = {
  isErrorMessage?: boolean;
};

export const MessageWrapper = styled.div<MessageWrapperProps>`
  background-color: var(--color-brand-light);
  border-radius: 16px;
  color: var(--color-white);
  display: inline-block;
  font-size: 13px;
  font-weight: var(--font-weight-regular);
  line-height: 1.6;
  margin-bottom: 15px;
  padding: 8px 12px;

  ${({ isErrorMessage }) =>
    isErrorMessage &&
    `
    background-color: var(--color-monza);
  `}
`;

export const Link = styled.a`
  text-decoration: underline;
`;
