import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import React, { ButtonHTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { IconPropType } from '@/ui/Icon';
import { color } from '@/components/Typography/v2/font-color';
import { icon0, icon1, icon2, icon3 } from '@/components/Typography/v2';
import { Icon } from '@/components/Icon';

export type IconButtonProps = {
  icon?: IconPropType;
  buttonVariant?: ButtonVariant;
  gridArea?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type UrlLinkProps = IconButtonProps & {
  href: string;
  download?: string;
  target?: string;
  rel?: string;
};

type RouterLinkButtonProps = IconButtonProps & {
  to: LinkProps['to'];
  target?: string;
  rel?: string;
};

export const iconVariants = ['light', 'buddySmall', 'dark', 'minimal', 'ghost', 'minimalGhost', 'minimalGhostDark'] as const;
export type ButtonVariant = typeof iconVariants[number];

const buttonTypeStyle: Record<ButtonVariant, FlattenSimpleInterpolation> = {
  light: css`
    ${icon2}
    ${color('#ffffff')}
    background: transparent;
    :hover {
      ${icon1}
      background: rgba(0, 136, 98, 0.08);
    }
    :focus:not(:active):not(:hover) {
      ${icon1}
      border: 1px solid #42DCA8;
    }
    :active {
      ${icon1}
      background: rgba(0, 136, 98, 0.08);
      ${color('#6F797A')}
    }
    & > svg {
      filter: drop-shadow(0px 2.4px 7.4px rgba(0, 0, 0, 0.18)) drop-shadow(0px 12.8px 28.8px rgba(0, 0, 0, 0.22));
    }
  `,
  buddySmall: css`
    ${icon2}
    ${color('#141D1E')};
    background: transparent;
    :hover {
      background: rgba(0, 136, 98, 0.08);
      border-radius: 4px;
    }
    :focus:not(:active):not(:hover) {
      border: 1px solid #42dca8;
      background: rgba(0, 136, 98, 0.08);
      ${color('#6F797A')}
      border-radius: 4px;
    }
    :active {
      background: rgba(0, 136, 98, 0.08);
      ${color('#6F797A')}
      border-radius: 4px;
    }
  `,
  dark: css`
    ${icon2}
    ${color('#141D1E')};
    background: transparent;
    :hover {
      ${icon1}
      background: rgba(0, 136, 98, 0.08);
    }
    :focus:not(:active):not(:hover) {
      ${icon1}
      border: 1px solid #42DCA8;
      background: rgba(0, 136, 98, 0.08);
      ${color('#6F797A')}
    }
    :active {
      ${icon1}
      background: rgba(0, 136, 98, 0.08);
      ${color('#6F797A')}
    }
  `,
  minimal: css`
    ${icon3}
    background: #ffffff;
    height: 0.75rem;
    width: 0.75rem;
    border: 1px solid #006c4d;
    ${color('#006C4D')} :hover {
      background: #141d1e;
      border: 1px solid #141d1e;
      ${color('#ffffff')}
    }
    :focus:not(:active):not(:hover) {
      background: #ffffff;
      border: 1px solid #42dca8;
      ${color('#42DCA8')}
    }
    :active {
      background: #6f797a;
      border: 1px solid #6f797a;
      ${color('#ffffff')}
    }
    & > svg {
      height: 0.5rem;
      width: 0.5rem;
    }
  `,
  minimalGhost: css`
    ${icon3}
    background: #ffffff;
    height: 0.75rem;
    width: 0.75rem;
    border: none;
    ${color('#006C4D')}
    font-size: 0.75rem;
    }
  `,
  minimalGhostDark: css`
    ${icon3}
    background: #ffffff;
    height: 0.75rem;
    width: 0.75rem;
    border: none;
    color: ${color('#000000')};
    font-size: 0.75rem;
    }
  `,
  ghost: css`
    height: 3rem;
    width: 3rem;
    ${color('#FFFFFF')}
    background: transparent;
    ${icon0} :focus:not(:active):not(:hover) {
      border: 1px solid #42dca8;
    }
    :active {
      color: #6f797a;
    }
    & > svg {
      text-shadow: 0px 2.4px 7.4px rgba(0, 0, 0, 0.18), 0px 12.8px 28.8px rgba(0, 0, 0, 0.22);
    }
  `,
} as const;

const IconBaseButton = styled.button<IconButtonProps>`
  transition: all 0.3s;
  border-radius: 50%;
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  & > svg {
    border-radius: 50%;
  }
  ${({ buttonVariant }) => buttonTypeStyle[buttonVariant || 'dark']}
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}
`;

export const IconButton = ({ icon, ...buttonProps }: IconButtonProps | UrlLinkProps | RouterLinkButtonProps): JSX.Element => {
  if (!icon) {
    return <></>;
  }
  const urlLinkProps = buttonProps as UrlLinkProps;
  const routerLinkProps = buttonProps as RouterLinkButtonProps;
  const defaultProps = buttonProps as IconButtonProps;

  if (!!urlLinkProps.href) {
    return (
      <IconBaseButton as="a" icon={icon} {...urlLinkProps}>
        <Icon icon={icon} />
      </IconBaseButton>
    );
  } else if (!!routerLinkProps.to) {
    return (
      <IconBaseButton as={Link} icon={icon} {...routerLinkProps}>
        <Icon icon={icon} />
      </IconBaseButton>
    );
  } else {
    return (
      <IconBaseButton icon={icon} {...defaultProps}>
        <Icon icon={icon} />
      </IconBaseButton>
    );
  }
};
