import { api } from '../../../utils/lib/api';

export async function checkVideoStatus(vimeoId?: string): Promise<string | undefined> {
  if (!vimeoId) throw 'Missing vimeo id';
  let poll = true;

  while (poll) {
    await new Promise(resolve => setTimeout(resolve, 5000));
    const response = await api.uploadStatus(vimeoId);

    if (response.transcode === 'complete') {
      poll = false;
      return response.thumbnail;
    }

    if (response.transcode === 'error') {
      poll = false;
      throw 'Transcoding error';
    }
  }
}
