import { useLearningByIdQueryRemote } from '@/store/v2';
import { PracticalAssessment } from './types';
import { transformFromRemoteLearning } from '@/administration/pages/Course/store/course/transform';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { Module } from '@/administration/pages/Course/store/modules/types';
import { Page } from '@/administration/pages/Course/store/page/types';

export type UsePracticalAssessmentResult = {
  practicalAssessment?: PracticalAssessment;
  module?: Module;
  pages?: Page[];
  progress?: Progress;
};

type UsePracticalAssessmentHook = (
  id: number,
  opts?: { includeProgress: boolean, includeLocations: boolean }
) => {
  data: UsePracticalAssessmentResult | undefined;
  loading: boolean;
};

export const usePracticalAssessment: UsePracticalAssessmentHook = (id: number, opts) => {
  const { data, loading } = useLearningByIdQueryRemote({
    variables: {
      id: String(id),
      includeProgress: opts?.includeProgress || false,
      includeLocations: opts?.includeLocations || false
    },
  });

  const [practicalAssessment, module, pages] = transformFromRemoteLearning(data?.learning);

  return {
    data: {
      practicalAssessment: practicalAssessment as PracticalAssessment,
      module,
      pages,
    },
    loading,
  };
};
