import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const RootStyled = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

export const CompletionBoxStyled = styled.div`
  ${({ theme }) => theme.typography.body2};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.container};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export const ActivityLinkStyled = styled(NavLink)`
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.colors.primary.main};
`;
