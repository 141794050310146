import styled from 'styled-components';

import { IconButton } from '@/ui/Button';

export const StyledEnabledButton = styled(IconButton)`
  transition: transform 200ms ease-in;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const StyledDisabledButton = styled(IconButton)`
  transition: transform 200ms ease-in;
`;
