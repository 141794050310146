import { useMarkToolbarButton, useMarkToolbarButtonState } from '@udecode/plate-common/react';

import { MarkType } from '@/component/customEditorV2/ui/fixedToolbar/types';
import { MARK_TO_ICON } from '@/component/customEditorV2/ui/fixedToolbar/constants';
import { ToggleButton } from '@/ui/Button';
import { Icon } from '@/ui/Icon';

export const MarkToolbarButton = ({ type }: { type: MarkType }): JSX.Element => {
  const state = useMarkToolbarButtonState({ nodeType: type });
  const { props } = useMarkToolbarButton(state);

  return (
    <ToggleButton size="small" variant="default" onClick={props.onClick} onMouseDown={props.onClick} toggled={props.pressed}>
      <Icon size="xs" icon={MARK_TO_ICON[type]} />
    </ToggleButton>
  );
};
