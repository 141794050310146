import React, { useEffect } from 'react';

import { ViewTextPageProps } from './types';
import { ContentStyled, RootStyled } from './styles';
import { CustomEditorV2Readonly } from '@/component/customEditorV2';

export const ViewTextPage = ({ id, text, spaceId, onCompleted }: ViewTextPageProps): JSX.Element => {
  useEffect(() => () => onCompleted && onCompleted(), []);

  return (
    <RootStyled key={id}>
      <ContentStyled>
        <CustomEditorV2Readonly value={text} style={{ fontSize: 14 }} $fullSize minimized spaceId={spaceId} />
      </ContentStyled>
    </RootStyled>
  );
};
