import styled from 'styled-components';

import fallback from '../../../../assets/fallback.png';
import { cvar } from '@/styles/GlobalStyle';
import { deviceUp } from '@/styles/utils';

export const LongDescription = styled.div<{ hasMeta?: boolean }>`
  padding: var(--gap-sm) var(--gap-sm) 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 110px;
}
`;

export const MetaWrapper = styled.div`
  background-color: var(--color-wild-sand);
  padding: var(--gap-xs);
  padding-top: 0;
  ${deviceUp.md`
    padding-top: 140px;
  `};
`;

export const ImageWrapper = styled.div<{ noMargin?: boolean }>`
  width: 100%;

  ${deviceUp.md`
    padding: 0 20px;
    margin-bottom: -140px;
    margin-right: 30px;
  `};
}
`;

export const Image = styled.div<{ image?: string | null }>`
  background-color: var(--color-white);
  background-image: url(${({ image }) => image || fallback});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${cvar('radius-inner')};
  box-shadow: ${cvar('box-shadow-small')};
  padding-bottom: 56%;
  position: relative;
  display: block;
  z-index: 1;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: calc(50% - 40px);
`;

export const SectionWithTopBorder = styled.div`
  border-top: ${cvar('border-style')};
  padding: var(--gap-sm);
`;

export const InformationMessage = styled.span<{ pl?: boolean }>`
  font-size: var(--v2-font-size-8);
  line-height: var(--v2-line-height-6);
  color: var(--color-buddy-light);
  ${({ pl }) => pl && `padding-left: ${cvar('gap-sm')};`}
`;
