import styled from 'styled-components';

export const RootStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const HeadingWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;
export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.tones.neutral[0]};
`;
