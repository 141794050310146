import { Node } from 'slate';

import { CustomSlateElementType } from '@/types/slate';

export const ListTypes = ['ordered-list', 'bulleted-list'];

export type Block = 'paragraph' | 'bulleted-list' | 'ordered-list' | 'list-item' | 'heading' | 'heading2' | 'link' | 'image';

export type EditorConfig = {
  excludeBlocks: Block[];
};

// TODO Caption to separate image type!!!
export type CustomNode = Node & { type?: CustomSlateElementType; url?: string; caption?: string; width?: number };
