import styled, { css } from 'styled-components';

import { BadgeLabel } from '@/ui/Label';
import { Button } from '@/ui/Button';

type ActionsBarProps = {
  title: string;
  minimized?: boolean;
  count: number;
  onEditClick?: () => void;
  onViewClick?: () => void;
};

const RootStyled = styled.div<{ minimized?: boolean }>`
  ${({ minimized }) =>
    minimized
      ? css`
          padding-bottom: 24px;
        `
      : css`
          padding: 32px 16px 16px 32px;
        `};

  display: flex;
  justify-content: space-between;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const HeadingStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const ActionsBar = ({ title, count, minimized, onEditClick, onViewClick }: ActionsBarProps): JSX.Element => (
  <RootStyled minimized={minimized}>
    <HeadingStyled>
      <TitleStyled>{title}</TitleStyled>
      <BadgeLabel badgeVariant="badge-text" text={String(count)} />
    </HeadingStyled>
    {onEditClick && (
      <Button variant="ghost" icon="arrow-right" $iconPosition="end" onClick={onEditClick}>
        Edit
      </Button>
    )}
    {onViewClick && (
      <Button variant="ghost" icon="arrow-right" $iconPosition="end" onClick={onViewClick}>
        Show all
      </Button>
    )}
  </RootStyled>
);
