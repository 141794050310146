import React from 'react';

import { ContentSection } from '../ContentSection';
import { Wrapper, Header, Breadcrumbs, HeadingWrapper, Heading, SubHeading, Tabs, Contents } from './atoms';
import { LoadingStateHeaderLayout } from './LoadingStateHeaderLayout';

type PageLayoutProps = {
  breadcrumbs?: React.ReactNode;
  tabs?: React.ReactNode;
  contents: React.ReactNode;
  title?: string;
  subheading?: React.ReactNode;
  subtitle?: string | JSX.Element;
  loading?: boolean;
  hasSubHeading?: boolean;
  hasSubtitle?: boolean;
};

/**
 * Layout component for displaying page contents plus a hero-style header
 * with breadcrumbs, headings and/or tabs.
 */
export const PageWithHeader = ({
  breadcrumbs,
  tabs,
  contents,
  title,
  subtitle,
  subheading,
  loading,
  hasSubHeading,
  hasSubtitle,
}: PageLayoutProps): JSX.Element => {
  return (
    <Wrapper $loading={loading}>
      <Header noTabs={!tabs}>
        <ContentSection>
          {breadcrumbs && <Breadcrumbs>{breadcrumbs}</Breadcrumbs>}
          {loading ? (
            <LoadingStateHeaderLayout hasSubHeading={hasSubHeading} hasSubtitle={hasSubtitle} />
          ) : (
            <HeadingWrapper>
              <Heading title={title} subtitle={subtitle} />
              {hasSubHeading && subheading && <SubHeading>{subheading}</SubHeading>}
            </HeadingWrapper>
          )}
          {tabs && <Tabs>{tabs}</Tabs>}
        </ContentSection>
      </Header>
      <ContentSection marginTop marginBottom>
        <Contents>{contents}</Contents>
      </ContentSection>
    </Wrapper>
  );
};
