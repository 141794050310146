import styled from 'styled-components';

type HeadingProps = {
  title?: string;
  subtitle?: string | JSX.Element;
};

const Wrapper = styled.div``;

const Title = styled.h1`
  ${(p) => p.theme.typography.title1}
  color: ${(p) => p.theme.colors.neutral.onBackground};
  margin: 0;
`;

const Subtitle = styled.span`
  ${(p) => p.theme.typography.caption}
  color: ${(p) => p.theme.colors.neutral.onContainer};
  margin: 0;
`;

export const SubtitleSpacer = styled.span.attrs({ children: ' ', 'aria-hidden': true })`
  ${(p) => p.theme.typography.caption};
  white-space: pre;
  user-select: none;
`;

const CustomSubtitle = styled.div`
  margin-top: 16px;
`;

export const Heading = ({ title, subtitle }: HeadingProps): JSX.Element => (
  <Wrapper>
    <Title>{title}</Title>
    {typeof subtitle === 'string' ? (
      <Subtitle>
        {subtitle}
        <SubtitleSpacer /> {/* Reserve space, even if no subtitle is present */}
      </Subtitle>
    ) : (
      <CustomSubtitle>{subtitle}</CustomSubtitle>
    )}
  </Wrapper>
);
