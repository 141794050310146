import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Image } from './Image';
import { variant } from './sizing';

export type CardProps = WithClassNameProp<{
  link: string;
  isExternalLink?: boolean;
  children?: ReactNode;
  onClick?: () => void;
  isCardDescriptionHidden: boolean;
}>;

export const Card = ({ link, isExternalLink, children, className, onClick, isCardDescriptionHidden }: CardProps): JSX.Element => {
  return isExternalLink ? (
    <ExternalLinkCard href={link} className={className} onClick={onClick} size={isCardDescriptionHidden ? 'sm' : 'lg'}>
      {children}
    </ExternalLinkCard>
  ) : (
    <RouterLinkCard to={link} className={className} onClick={onClick} size={isCardDescriptionHidden ? 'sm' : 'lg'}>
      {children}
    </RouterLinkCard>
  );
};

export const cardStyles = css<{ size?: 'sm' | 'lg' }>`
  display: block;
  box-sizing: border-box;
  position: relative;
  background: ${(p) => p.theme.colors.primary.onMain};
  ${(p) => p.theme.shadow.primary[4]};
  text-decoration: unset;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  transition-property: box-shadow;
  transition-duration: ${(p) => p.theme.transitions.normal}ms;

  :hover {
    ${(p) => p.theme.shadow.primary[16]};
    ${Image} {
      transform: scale(1.01);
    }
  }

  :focus {
    outline: 1px solid ${(p) => p.theme.colors.primary.outline};
    outline-offset: 0;
  }

  ${variant.small} {
    min-height: 192px;
    padding-left: 80px; // reserve for image
    padding-top: 16px;
  }

  ${variant.large} {
    min-height: ${(p) => (p.size === 'sm' ? '327px' : '400px')};
  }
`;

const RouterLinkCard = styled(Link)`
  ${cardStyles}
`;

const ExternalLinkCard = styled.a`
  ${cardStyles}
`;
