import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledBackgroundImage = styled.div<{ background: string; uploading?: boolean }>`
  background: ${({ background, uploading, theme }) => (uploading ? theme.colors.neutral.container : background)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
`;
