import styled from 'styled-components';

import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Divider } from '../Divider';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const BannerContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title4};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const Content = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Action = styled(Button)`
  width: fit-content;
`;

const SubmissionOnly = ({ onReviewPeersClick }: { onReviewPeersClick: () => void }) => (
  <>
    <HeaderStyled>
      <Icon icon="party-horn" />
      <TitleStyled>Nice work!</TitleStyled>
    </HeaderStyled>
    <Content>
      You have submitted your assessment to be reviewed. To complete this activity your next step is to review two of your peers.
    </Content>
    <Action variant="primary" onClick={onReviewPeersClick}>
      Review peers
    </Action>
  </>
);

const SubmissionReviewedPeersWaiting = ({
  onReviewPeersClick,
  onCheckReviewClick,
}: {
  onReviewPeersClick: () => void;
  onCheckReviewClick: () => void;
}) => (
  <>
    <HeaderStyled>
      <Icon icon={['far', 'comment-lines']} />
      <TitleStyled>You got a review!</TitleStyled>
    </HeaderStyled>
    <Content>
      Your assessment has been reviewed, check out what your peers wrote! Don’t forget to review two of your peers to complete
      this activity.
    </Content>
    <ActionsStyled>
      <Action variant="primary" onClick={onCheckReviewClick}>
        Go to review
      </Action>
      <Action variant="secondary" onClick={onReviewPeersClick}>
        Review Peers
      </Action>
    </ActionsStyled>
  </>
);

const SubmissionReviewedPeersRewiewed = ({ onCheckReviewClick }: { onCheckReviewClick: () => void }) => (
  <>
    <HeaderStyled>
      <Icon icon={['far', 'comment-lines']} />
      <TitleStyled>You got a review!</TitleStyled>
    </HeaderStyled>
    <Content>Your assessment has been reviewed, check out what your peers wrote!</Content>
    <Action variant="primary" onClick={onCheckReviewClick}>
      Go to review
    </Action>
  </>
);

export const AssessmentSubmitted = ({
  onReviewPeersClick,
  onCheckReviewClick,
  hasReceivedReviews,
  hasReviewedPeers,
}: {
  onReviewPeersClick: () => void;
  onCheckReviewClick: () => void;
  hasReviewedPeers?: boolean;
  hasReceivedReviews?: boolean;
}): JSX.Element | null => {
  if (hasReviewedPeers && !hasReceivedReviews) return null;
  return (
    <RootStyled>
      <Divider />

      <BannerContentStyled>
        {!hasReceivedReviews && !hasReviewedPeers && <SubmissionOnly onReviewPeersClick={onReviewPeersClick} />}
        {hasReceivedReviews && !hasReviewedPeers && (
          <SubmissionReviewedPeersWaiting onReviewPeersClick={onReviewPeersClick} onCheckReviewClick={onCheckReviewClick} />
        )}
        {hasReceivedReviews && hasReviewedPeers && <SubmissionReviewedPeersRewiewed onCheckReviewClick={onCheckReviewClick} />}
      </BannerContentStyled>
      <Divider />
    </RootStyled>
  );
};
