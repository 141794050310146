import styled from 'styled-components';

import { GridItemProps } from '../types';
import { mediaQuery } from '@/ui/utils';

export const Item = styled.div<GridItemProps>`
  ${(p) =>
    p.mobile &&
    `${mediaQuery.only('mobile')}  {
        grid-column: span ${p.theme.columns[p.mobile]}};
    }`}

  ${(p) =>
    p.mobileLandscape &&
    `${mediaQuery.only('mobileLandscape')}{
        grid-column: span ${p.theme.columns[p.mobileLandscape]}};
    }`}

${(p) =>
    p.tablet &&
    `${mediaQuery.only('tablet')}  {
        grid-column: span ${p.theme.columns[p.tablet]}};
    }`}

${(p) =>
    p.desktop &&
    `${mediaQuery.only('desktop')} {
        grid-column: span ${p.theme.columns[p.desktop]}};
    }`}

${(p) =>
    p.desktopLarge &&
    `${mediaQuery.only('desktopLarge')} {
        grid-column: span ${p.theme.columns[p.desktopLarge]}};
    }`}

${(p) =>
    p.widescreen &&
    `${mediaQuery.only('widescreen')} {
        grid-column: span ${p.theme.columns[p.widescreen]}};
    }`}

${(p) =>
    p.fullHD &&
    `${mediaQuery.from('fhd')}  {
        grid-column: span ${p.theme.columns[p.fullHD]}};
    }`}

${(p) => p.override && `grid-column: span ${p.theme.columns[p.override]};`}
`;
