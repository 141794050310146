import { ApolloError } from '@apollo/client/errors';

import {
  RUnlockUserLearningJourneyItemMutation,
  RUnlockUserLearningJourneyItemMutationVariables,
  useUnlockUserLearningJourneyItemMutationRemote,
} from '@/store/v2';

type UseUnlockUserLearningJourneyItemHook = () => {
  unlockUserLearningJourneyItem: (
    input: RUnlockUserLearningJourneyItemMutationVariables,
    onSuccess?: () => void
  ) => Promise<{ errors?: unknown | undefined; data?: RUnlockUserLearningJourneyItemMutation | null | undefined }>;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useUnlockUserLearningJourneyItem: UseUnlockUserLearningJourneyItemHook = () => {
  const [unlockUserLearningJourneyItem, { loading, error }] = useUnlockUserLearningJourneyItemMutationRemote();

  return {
    unlockUserLearningJourneyItem: async (input: RUnlockUserLearningJourneyItemMutationVariables, onSuccess?: () => void) => {
      return await unlockUserLearningJourneyItem({
        variables: {
          ...input,
        },
        update(cache, { data }) {
          if (data?.unlockUserLearningJourneyItem?.success) {
            cache.evict({ fieldName: 'learningJourney' });

            onSuccess && onSuccess();
          }
        },
      });
    },
    loading,
    error,
  };
};
