import styled from 'styled-components';

export const ProgressWrapStyled = styled.div`
  width: 80px;
`;

export const ProgressWrapper = styled.div`
  color: ${(p) => p.theme.colors.neutral.onContainer};
  span {
    ${(p) => p.theme.typography.body3};
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
