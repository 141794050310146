import { gql, FetchResult, useMutation } from '@apollo/client';

import { AddSelfTrackedLearningToLearningLoopInput, AddSelfTrackedLearningToLearningLoopResult } from './types';

export const LEARNING_LOOP_SELF_TRACKED_MUTATION = gql`
  mutation learningLoopSelfTrackedLearningAdd($input: AddSelfTrackedLearningToLearningLoopInput!) {
    addSelfTrackedLearningToLearningLoop(input: $input) {
      success
    }
  }
`;

export const useLearningLoopSelfTrackedLearningAdd = (): {
  learningLoopSelfTrackedLearningAdd: (
    input: AddSelfTrackedLearningToLearningLoopInput
  ) => Promise<FetchResult<{ learningLoopSelfTrackedLearningAdd: AddSelfTrackedLearningToLearningLoopResult }>>;
  loading: boolean;
} => {
  const [learningLoopSelfTrackedLearningAdd, { loading }] = useMutation<{
    learningLoopSelfTrackedLearningAdd: AddSelfTrackedLearningToLearningLoopResult;
  }>(LEARNING_LOOP_SELF_TRACKED_MUTATION);

  return {
    learningLoopSelfTrackedLearningAdd: async (input: AddSelfTrackedLearningToLearningLoopInput) =>
      learningLoopSelfTrackedLearningAdd({
        variables: { input },
        update: (cache, { errors }) => {
          if (errors) {
            return;
          }
          cache.modify({
            id: cache.identify({ __typename: 'LearningLoop', id: input.learningLoopId }),
            fields: {
              estimatedEffort(effort) {
                return effort + input.effort;
              },
            },
          });
        },
      }),
    loading,
  };
};
