import styled from 'styled-components';

export const RootStyled = styled.div`
  width: fit-content;
`;

export const InnerCircleContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const EffortDescriptionBoxStyled = styled.div`
  text-align: center;
`;

export const CurrentEffortStyled = styled.div`
  ${({ theme }) => theme.typography.title2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const ExpectedEffortStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.neutral.disabled};
`;

export const LoopCounterBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;
  ${({ theme }) => theme.typography.title3};
  color: ${({ theme }) => theme.colors.neutral.disabled};
  letter-spacing: 5px;
  margin-bottom: 24px;
`;

export const LoopCounterCurrentIndexStyled = styled.span`
  color: ${({ theme }) => theme.colors.neutral.onBackground};
`;

export const AddLearningTimeBox = styled.div`
  position: absolute;
  right: 10%;
`;

export const ActionsContainerStyled = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;
