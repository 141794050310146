import styled from 'styled-components';

export const StyledToolbarGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Divider = styled.div<{ minimized?: boolean }>`
  height: 24px;
  width: 1px;
  background: ${({ theme }) => theme.colors.neutral.outline1};
`;
