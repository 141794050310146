import { ActionButton, BannerStyled } from './styles';

export const SubmitFirstBanner = ({ onViewSubmissionFormClick }: { onViewSubmissionFormClick: () => void }): JSX.Element => {
  return (
    <BannerStyled>
      <div>Submit your assessment before reviewing peers.</div>
      <ActionButton variant="link" onClick={onViewSubmissionFormClick}>
        Go to my assessment
      </ActionButton>
    </BannerStyled>
  );
};
