import React from 'react';

import { CARD_SORTING_METHODS, Catalog } from '@/store/catalog';
import { Card } from '@/types/learning/card';
import { numberOfCards } from './constants';
import { CampusSectionDetails } from './types';
import { CourseCard } from '@/components/CourseCard';
import { CatalogSortingEnum } from '@/types/CatalogSortingEnum';
import { trackHomepageLearningCardClicked } from '@/utils/tracking/homePage';

export const getSavedCards = (
  cardFilterPredicate: ((_: Card) => boolean) | undefined,
  savedCatalog: Catalog
): CampusSectionDetails => {
  const savedCards = cardFilterPredicate ? savedCatalog.cards.filter(cardFilterPredicate) : savedCatalog.cards;
  const cards = [...savedCards]
    .sort(CARD_SORTING_METHODS[CatalogSortingEnum.ALPHA_ASC])
    .slice(numberOfCards.startIndex, numberOfCards.endIndex)
    .map((c) => <CourseCard card={c} key={c.id} onClick={() => trackHomepageLearningCardClicked(c.title, 'featured')} />);
  return {
    cards,
    count: savedCards.length,
  };
};
