import { en } from './en';
import { sv } from './sv';

export enum Lng {
  sv = 'sv',
  en = 'en',
}

export type I18nResources = {
  [key in Lng]: I18nLanguage;
};

interface I18nKeys {
  [key: string]: string;
}

export type I18nLanguage = {
  [key in I18nNamespace]: I18nKeys;
};

export type I18nNamespace =
  | 'about'
  | 'analytics'
  | 'application'
  | 'assignmentGroup'
  | 'auth'
  | 'autoTable'
  | 'builderAdmin'
  | 'catalog'
  | 'catalogAdmin'
  | 'categories'
  | 'changelog'
  | 'common'
  | 'discussions'
  | 'fileUpload'
  | 'footer'
  | 'groupsAdmin'
  | 'teams'
  | 'home'
  | 'languages'
  | 'learning'
  | 'license'
  | 'myLearning'
  | 'navbar'
  | 'onboarding'
  | 'ordersAdmin'
  | 'orderState'
  | 'productType'
  | 'profile'
  | 'profileImageUpload'
  | 'providers'
  | 'search'
  | 'serverMessages'
  | 'subjects'
  | 'terms'
  | 'time'
  | 'usersAdmin'
  | 'userSelfTrackedLearning'
  | 'userStatus'
  | 'validation'
  | 'variants'
  | 'managerDashboard'
  | 'assignmentTemplate'
  | 'uploaders'
  | 'journey'
  | 'journeyTemplate';

export const resources: I18nResources = {
  en,
  sv,
};
