import React from 'react';
import { Link } from 'react-router-dom';

export const TermsEn = (): JSX.Element => {
  return (
    <>
      <div className="has-text-centered has-margin-bottom">
        <h1 className="header1">Terms of Use</h1>
        <p className="body1">
          Collegial
          <br />
          <span className="body2">Updated 2024-03-14</span>
        </p>
      </div>
      <div className="is-italic has-margin-bottom">
        Please read these terms of use (
        <q>
          <strong>Terms of Use</strong>
        </q>
        ) carefully before using the services, the website www.collegial.com and/or any other software or service provided by
        Collegial (the{' '}
        <q>
          <strong>Services</strong>
        </q>
        ). By using the Services, you agree to be bound by these Terms of Use. If you do not wish to be bound by these Terms of
        Use, you may not access or use the Services.
      </div>
      <ol className="nested-ordered-list">
        <li>
          <h2 className="header4">General</h2>
          <ol>
            <li>
              <span>
                Collegial AB, with registration number 559147-1510 (
                <q>
                  <strong>Collegial</strong>
                </q>
                ,{' '}
                <q>
                  <strong>us</strong>
                </q>
                ,{' '}
                <q>
                  <strong>we</strong>
                </q>
                ,{' '}
                <q>
                  <strong>our</strong>
                </q>
                ) provides subscriptions for e-learning services that allow users to collect, compile and display content from
                various e-learning providers (the{' '}
                <q>
                  <strong>Services</strong>
                </q>
                ).
              </span>
            </li>
            <li>
              <span>
                For the purpose of these Terms of Use, the terms{' '}
                <q>
                  <strong>you</strong>
                </q>{' '}
                and/or{' '}
                <q>
                  <strong>your</strong>
                </q>{' '}
                refer to the individual registering an account with us and/or accessing or using the Services.
              </span>
            </li>
            <li>
              <span>
                By registering an account with us (an{' '}
                <q>
                  <strong>Account</strong>
                </q>
                ) and/or accessing or using the Services provided by us and/or by at any time clicking <q>I AGREE</q> or similar
                buttons, links or any click-through that signifies your approval of these Terms of Use, you accept and agree to be
                bound by these Terms of Use, which form a legally binding agreement between you and us.
              </span>
            </li>
            <li>
              <span>If you do not accept these Terms of Use, you may not access or use the Services.</span>
            </li>
          </ol>
        </li>

        <li>
          <h2 className="header4">Access, use and user accounts</h2>
          <ol>
            <li>
              <span>
                To be able to access and use the Services, you must be at least eighteen (18) years old, agree to these Terms of
                Use, register an Account and provide the registration information requested by us. By registering, accessing
                and/or using an Account or providing registration information to us, you agree and warrant to provide and maintain
                valid, true, correct and complete information.
              </span>
            </li>
            <li>
              <span>
                In order to provide access to the Services, we rely on the relevant log-in credentials to authorize your access to
                the Services. You are obliged to keep your account information, user name and password secret and confidential and
                you are solely responsible for any and all use or access through your log-in credentials. Unless otherwise is
                expressly agreed in writing, you may not allow any other person or entity to use or access your Account or the
                Services. If you become aware or suspect any unauthorized use of your Account, you are required to immediately
                notify us hereof. Any unauthorized use of your user name or password shall be deemed as your use and you are
                responsible for all charges incurred, until such notification of unauthorized use is made to us. If you give away,
                share or lose your user name and/or password, we will not be liable for any loss or damage as a result hereof.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="header4">User submitted content</h2>
          <ol>
            <li>
              <span>
                Certain use of the Services may require that you post, upload or otherwise submit content to the Services,
                including, but not limited to, posts, text, movies, images, pictures, audio and designs (hereinafter collectively
                referred to as the{' '}
                <q>
                  <strong>Content</strong>
                </q>
                ).
              </span>
            </li>
            <li>
              <span>
                By posting, uploading, publishing or otherwise submitting Content to us, you represent and warrant that you have
                the right to post, upload, publish and submit such Content and that such Content (or any part thereof), including
                the use by us as contemplated by these Terms of Use, does not violate any agreement, applicable law or third party
                right, including intellectual property rights, right of privacy and/or applicable data protection legislation.
                Moreover, you agree to indemnify and hold us harmless from any third party claim arising from or relating to our
                use of Content as contemplated by these Terms of Use.
              </span>
            </li>
            <li>
              <span>
                By posting, uploading, publishing or otherwise submitting Content to us, you grant us a non-exclusive,
                transferrable, sub-licensable, fully paid license to use the Content (and any part thereof) in the way
                contemplated by these Terms of Use or as is otherwise necessary for our performance of the Services to you, or any
                other contractual right or obligation in relation to you. We are not allowed to use the Content in any other way.
              </span>
            </li>
            <li>
              <span>
                You understand and agree that you are solely responsible and liable for any losses, damages and/or third party
                claims arising from or relating to your use of the Content. We shall not in any way or form be responsible or
                liable for such use.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="header4">Suspension and termination</h2>
          <div>
            We have the right to suspend your access to the Services and/or to terminate these Terms of Use with immediate effect,
            without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms
            of Use, or if your right of use has been terminated under the agreement between us and our customer from which you
            have been granted access to our Services. All provisions of the Terms of Use which by their nature should survive
            termination, including, without limitation, ownership provisions, warranty disclaimers and indemnification, shall
            survive termination of these Terms of Use.
          </div>
        </li>
        <li>
          <h2 className="header4">Intellectual property rights</h2>
          <ol>
            <li>
              <span>
                The Services (including, for the avoidance of doubt, all online training materials created by Collegial or any
                third party supplier engaged by Collegial), are the sole and exclusive property of Collegial and/or our third
                party suppliers. The Services are provided with a right of use, not sold, and you do not acquire any ownership of
                the Services or any intellectual property rights therein. Title and interest in the Services (including
                copyrights, trade marks and other intellectual property rights therein) are and will remain the sole and exclusive
                property of Collegial and/or our third party suppliers. The foregoing applies to any modifications, enhancement,
                alterations or subsequent versions of the Services. For the avoidance of doubt, you will remain the owner of any
                Content that you submit to the Services, and Collegial{"'"}s right to use that Content is limited to what is set
                out in Clause 3.3.
              </span>
            </li>
            <li>
              <span>
                All content of the Services (including but not limited to text, graphics, designs, logos, button icons, images,
                photos, audio clips, videos, digital downloads and compilation of these, and all software, object code and source
                code created by Collegial or any third party supplier engaged by Collegial but excluding any Content that you
                submit), - are owned or licensed by us. You may not access, print or download portions of the material from the
                Services, unless you have received our prior written consent. The content of the Services may not be altered,
                distributed or displayed without our prior written consent. For the avoidance of doubt, you will remain the owner
                of any Content that you submit to the Services, and Collegial{"'"}s right to use that Content is limited to what
                is set out in Clause 3.3.
              </span>
            </li>
            <li>
              <span>
                Our trademarks, trade names and other symbols included or referenced in the Services (collectively the{' '}
                <q>
                  <strong>Marks</strong>
                </q>
                ) are protected by national and international intellectual property laws. All use of the Marks is strictly
                prohibited without our prior written consent.
              </span>
            </li>
            <li>
              <span>
                Nothing contained herein shall be interpreted as a transfer of any of our rights whatsoever and all rights not
                expressly granted herein are reserved by us.
              </span>
            </li>
            <li>
              <span>
                Any bespoke or customised development (whether or not it is developed following a suggestion from you) made by
                Collegial and supplied to you shall become part of the Services and shall be treated as such under these Terms of
                Use. In the event that any intellectual property rights in the Services shall be deemed to vest to any extent in
                you, you hereby grant, transfer and assign all such rights to Collegial. For the avoidance of doubt, the rights
                granted, transferred and assigned to Collegial hereunder shall include the right to (freely and without paying any
                compensation) modify, develop, transfer and assign such rights (or parts thereof).
              </span>
            </li>
            <li>
              <span>
                The Services are licensed, not sold, to you. During the subscription period you are granted a personal,
                non-exclusive, non-transferrable and non-sub licensable right to use the Services. We retain ownership of all
                parts of the Services, even if software or a portion thereof would be installed on your computer or other hardware
                device. We have the unlimited right to assign and/or transfer this license or any part thereof to any third party.
                You may not assign, transfer or sub license this license, your rights under these Terms of Use or any part thereof
                to any third party.
              </span>
            </li>
            <li>
              <span>
                Any third party software included in the Services is licensed to you in accordance with the respective third-party
                license terms for such software. Our Services may furthermore contain links to third-party web sites or services
                that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content,
                privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we
                shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused
                by or in connection with use of or reliance on any such content or services available on or through any such third
                party web sites or services.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="header4">Personal data</h2>
          <div>
            By registering an Account, accessing or using the Services, you acknowledge that we will process your personal data in
            such manner as described in our <Link to="/privacy">privacy policy</Link>.
          </div>
        </li>
        <li>
          <h2 className="header4">Disclaimer of warranties</h2>
          <ol>
            <li>
              <span>
                We provide the Services on an <q>as is</q> and <q>as available</q>-basis, without any warranties of any kind,
                either express or implied, including, without limitation, any warranties of quality or fitness for a particular
                purpose.
              </span>
            </li>
            <li>
              <span>
                We do not warrant that the functions contained in the Services will meet your requirements, or that the operation
                of the Services will be uninterrupted or error or defect free.
              </span>
            </li>
            <li>
              <span>
                In the event that these disclaimers are held to be legally invalid, the scope and duration of such warranty will
                be the minimum permitted under such applicable law.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="header4">Indemnification</h2>
          <div>
            You agree to defend, indemnify, and hold harmless Collegial, its parent, subsidiaries, directors, officers, employees,
            agents and contractors from and against any and all damages, claims, suits, or proceedings (including reasonable legal
            fees) brought against Collegial by any third party, that alleges that the access, use or provision of your Content,
            any other content or personal information in connection with your use of the Services, violates any applicable law,
            regulation or the priority rights of others.
          </div>
        </li>
        <li>
          <h2 className="header4">Amendments and changes</h2>
          <ol>
            <li>
              <span>
                We reserve the right to amend or change these Terms of Use at any time. In case of material amendments or changes
                to these Terms of Use, we will notify you thirty (30) days before such changes enter into force. If you do not
                accept the amended or changed Terms of Use you may not access, use or continue to use the Services. By continuing
                to access and/or use the Services, you are expressing and acknowledging your acceptance of the amendments and
                changes. We advise you to periodically review the Terms of Use in order to be informed of any changes.
              </span>
            </li>
            <li>
              <span>
                We reserve the right to update, amend, change and/or modify the content of the Services, without prior
                notification and will not be liable in any way for possible consequences of such changes. We may suspend,
                discontinue or restrict access to any portion of the Services at any time and without notice.
              </span>
            </li>
            <li>
              <span>
                Please note that we do not undertake to keep copies of prior versions of these Terms of Use. Therefore, we suggest
                that you print these Terms of Use.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="header4">Entire agreement</h2>
          <div>
            Unless otherwise is expressly agreed in writing, these Terms of Use contain the entire understanding between you and
            us with respect to the subject matter contained herein and supersede and cancel all prior agreements, negotiations,
            correspondence, undertakings and communications between you and us, oral or written, with respect to such subject
            matter. You represent that you have not accepted the Terms of Use in reliance on any oral or written representations
            made by us that are not contained in these Terms of Use.
          </div>
        </li>
        <li>
          <h2 className="header4">Severability</h2>
          <div>
            If at any time any provision of these Terms of Use is or becomes invalid, illegal or unenforceable under applicable
            law, the validity, legality and enforceability of the remainder of these Terms of Use shall not be affected. In the
            event any provision is held in any proceeding to be invalid, illegal or unenforceable, the deficient provision shall
            be replaced with a new provision permitted by applicable law as similar as possible to the deficient provision.
          </div>
        </li>
        <li>
          <h2 className="header4">Governing law and disputes</h2>
          <ol>
            <li>
              <span>
                Any disputes arising out of or relating to these Terms of Use, the breach thereof, or the subject matter thereof,
                unless otherwise required by mandatory law in any other jurisdiction, shall be governed by and interpreted in
                accordance with the laws of Sweden.
              </span>
            </li>
            <li>
              <span>
                A dispute arising in relation to these Terms of Use may be settled by a Swedish general court within the area of
                jurisdiction where Collegial has its registered office or where you are domiciled.
              </span>
            </li>
            <li>
              <span>
                Disputes may be referred to the European Online Dispute Resolution (ODR) platform for online dispute resolution
                available at{' '}
                <a
                  href="https://ec.europa.eu/consumers/odr"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="EU Online Dispute Resolution"
                >
                  https://ec.europa.eu/consumers/odr
                </a>
                .
              </span>
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
};
