import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogItem } from '@/types/learning/learning-catalog';
import { useSavedLearnings, useUpdateSavedLearnings } from '@/pages/user/MyLearning/store';
import { SharedSnackbarContext } from '@/component/SharedSnackbar/SharedSnackbar';

type UseFavouriteJourneyProps = (journeyId?: number) => {
  loading: boolean;
  handleSavedLearnings: () => void;
  isSaved: boolean;
};

export const useFavouriteJourney: UseFavouriteJourneyProps = (journeyId) => {
  const item: CatalogItem = {
    id: Number(journeyId),
    entity: 'journey',
  };
  const { t } = useTranslation('catalog');
  const { savedLearnings, loading } = useSavedLearnings();
  const { addSavedLearnings, removeSavedLearnings } = useUpdateSavedLearnings();
  const { openSnackbar } = useContext(SharedSnackbarContext);
  const isSaved = Boolean(savedLearnings.meta?.find((l) => l.id === item.id && l.entity === item.entity));

  const handleSavedLearnings = async () => {
    if (isSaved) {
      await removeSavedLearnings(savedLearnings, item);
      openSnackbar({ message: t('journey-remove'), isDismissive: true });
    } else {
      await addSavedLearnings(savedLearnings, item);
      openSnackbar({ message: t('journey-add'), isDismissive: true });
    }
  };

  return {
    loading,
    handleSavedLearnings,
    isSaved,
  };
};
