/* eslint-disable import/no-unused-modules */
import { ApolloError, useQuery, gql } from '@apollo/client';

import { AssignmentV3QueryResult } from '@/store/assignments/types';
import { transformResults } from '../shared/utils';
import { AssignmentWithStatus } from './types';

type UseBaseAssignmentHook = (query: { assignmentId: number | undefined; userId: number | undefined }) => {
  data: AssignmentWithStatus | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

export const ASSIGNMENT_QUERY = gql`
  query assignmentV3($id: Int!, $userIdIN: [Int!]) {
    assignmentV3(id: $id) {
      id
      name
      description
      type
      teaser
      image
      surveyId
      users(pagination: { page: 1, limit: 1 }, filter: { userIdIN: $userIdIN }) {
        data {
          assignmentId
          userId
          user {
            id
          }
        }
        ... on AssignmentStandardV3UsersPage {
          data {
            ... on AssignmentStandardV3User {
              progress {
                estimatedEffort
                requiredItemsCompletedCount
                requiredItemsCount
                completed
                participations {
                  progress
                }
              }
            }
          }
        }
        ... on AssignmentStrategicV3UsersPage {
          data {
            ... on AssignmentStrategicV3User {
              progress {
                completed
                estimatedEffort
                competencyAssessment {
                  canTake
                }
              }
            }
          }
        }
      }
      learnings(pagination: { limit: -1, page: 1 }) {
        data {
          id
          learningPathId
          learningId
          order
        }
        ... on AssignmentStandardV3LearningPage {
          data {
            ... on AssignmentStandardV3Learning {
              type
            }
          }
        }
      }
      ... on AssignmentStrategicV3 {
        startAt
        endAt
        learningLoopRecommendedEffort
        totalThresholdEffort
      }
    }
  }
`;

export const useBaseAssignment: UseBaseAssignmentHook = ({ assignmentId, userId }) => {
  const { data, loading, error } = useQuery<AssignmentV3QueryResult>(ASSIGNMENT_QUERY, {
    variables: {
      id: assignmentId,
      userIdIN: [userId],
    },
    skip: !assignmentId || !userId,
  });

  const userIds = userId ? [userId] : [];

  return {
    data: data ? transformResults(data.assignmentV3, userIds) : undefined,
    loading,
    error,
  };
};
