import styled from 'styled-components';

import { OverlineMixin } from '@/components/Typography/Typography';
import { cvar } from '@/styles/GlobalStyle';

export const LinkOverline = styled.span`
  ${OverlineMixin({ color: 'color-primary' })}
  font-size: ${cvar('font-size-8')};

  &:hover {
    color: ${cvar('color-moon-crater-gray')};
  }
`;

export const Separator = styled.span`
  ${OverlineMixin()}
  padding: 0 ${cvar('gap-xxs')}
`;

export const CollapsedItemsRef = styled.div`
  position: relative;
  left: 0;
`;

export const CollapsedItemsBody = styled.span`
  position: absolute;
  left: 0;
`;
