import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Composition } from 'atomic-layout';

import { Button } from '@/components/Button/Button';
import { isRealm } from '@/utils/realms';
import { LicenseStatus } from '@/administration/pages/Catalog/utils/types';
import { ProviderAccessStatus, ProviderList } from '@/store/providers';
import { useProviderAccess } from '@/hooks/useProviderAccess';
import { LinkButton } from '@/components/Button/LinkButton';
import { ButtonList } from '@/components/Button/ButtonList';
import { courseraPageQuery, requestCourseraLicenseMutation } from '../queries';
import { ProviderLogo } from '@/components/ProviderLogo/ProviderLogo';
import { Header1, BodyLg } from '@/components/Typography/Typography';
import { providerFormAreas, providerFormAreasLg } from './GeneralProvider';
import { PageLoader } from '@/components/Pageloader/Pageloader';

export const Coursera = (): JSX.Element => {
  const provider = ProviderList.COURSERA;
  const currentRealm = window.realm;
  const { t } = useTranslation('providers');
  const { loading, data } = useQuery(courseraPageQuery, { variables: { provider, currentRealm } });
  const providerAccess = useProviderAccess(provider);
  const [requestCourseraLicense] = useMutation(requestCourseraLicenseMutation, {
    refetchQueries: [{ query: courseraPageQuery, variables: { provider, currentRealm } }]
  });

  if (loading) return <PageLoader />;

  const status: LicenseStatus = data?.issuedLicense?.status;
  const { catalogUrl } = data?.realmProviderLicensesSummary || {};

  return (
    <Composition
      areas={providerFormAreas}
      areasLg={providerFormAreasLg}
      templateCols="minmax(0, 1fr)"
      templateColsLg="minmax(0, 9fr) minmax(0, 3fr)"
      gap={0.875}
      gapMd={1.75}
    >
      {({ First, Second }) => (
        <>
          <First>
            <Header1>{status === LicenseStatus.APPROVED ? t('coursera-welcome') : t('coursera-teaser')}</Header1>
            {(status === LicenseStatus.NOT_APPROVED || !status) && (
              <BodyLg>
                <Trans t={t} i18nKey="coursera-not-registered" tOptions={isRealm('seb') ? { context: 'seb' } : undefined} />
              </BodyLg>
            )}
            {status === LicenseStatus.PENDING_APPROVAL && <BodyLg>{t('coursera-pending')}</BodyLg>}
            {status === LicenseStatus.APPROVED && (
              <>
                <BodyLg>{t('coursera-approved-p1')}</BodyLg>
                <BodyLg>{t('coursera-approved-p2')}</BodyLg>
              </>
            )}
          </First>
          <Second>
            <ButtonList align="right" padded="bottom">
              <ProviderLogo provider={provider} />
            </ButtonList>
            <ButtonList align="right">
              {providerAccess === ProviderAccessStatus.HAS_ACCESS ||
                (status !== LicenseStatus.APPROVED && (
                  <Button
                    $type="primary"
                    $loading={loading}
                    disabled={status === LicenseStatus.PENDING_APPROVAL}
                    onClick={() => requestCourseraLicense({ variables: { currentRealm: window.realm, provider: provider } })}
                    $icon="check"
                  >
                    {!status || status === LicenseStatus.NOT_APPROVED
                      ? t('request-access')
                      : status === LicenseStatus.PENDING_APPROVAL
                      ? t('access-requested')
                      : ''}
                  </Button>
                ))}
              {status === LicenseStatus.APPROVED && catalogUrl && (
                <LinkButton href={catalogUrl} target="_blank" rel="noopener noreferrer" $type="primary">
                  {t('coursera-catalog')}
                </LinkButton>
              )}
            </ButtonList>
          </Second>
        </>
      )}
    </Composition>
  );
};
