import { Controller, useFormContext } from 'react-hook-form';

import { FormSchema } from '@/component/customEditorV2/ui/elements/Video/VideoLinkModal';
import { Input } from '@/ui/TextField';
import { isValidEmbedVideoUrl } from '@/component/customEditorV2/ui/elements/Video/hooks/useVideoUploadProgress';

export const VideoLinkForm = (): JSX.Element => {
  const { control } = useFormContext<FormSchema>();

  return (
    <Controller
      control={control}
      rules={{ required: true, validate: (text) => isValidEmbedVideoUrl(text) }}
      name={'link'}
      render={({ field: { onChange: onLinkChange, name, value: linkValue }, fieldState: { error } }) => (
        <Input
          onChange={onLinkChange}
          name={name}
          value={linkValue || ''}
          leadingIcon="link"
          fullWidth
          label="Link"
          helperText="Only YouTube, Ted, Creo, Vimeo, and Microsoft videos"
          error={
            error && {
              message: 'Only YouTube, Ted, Creo, Vimeo and Microsoft videos',
            }
          }
        />
      )}
    />
  );
};
