import { Icon } from '@/ui/Icon';
import { MenuListOptionContainer, MenuListOptionLabel } from '@/ui/MenuList/atoms/MenuListOption/styles';
import { MenuListOptionProps } from '@/ui/MenuList/atoms/MenuListOption/types';

export const MenuListOption = ({ icon, label, value, onClick }: MenuListOptionProps): JSX.Element => {
  return (
    <MenuListOptionContainer onClick={() => onClick(value)}>
      <MenuListOptionLabel>{label}</MenuListOptionLabel>
      <Icon icon={icon} size="xs" />
    </MenuListOptionContainer>
  );
};
