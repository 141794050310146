import { getParser } from 'bowser';

import { utcToICSDateTime, utcToLocalDateСustomFormat } from '@/utils/time';

const DIVIDER = '----------------------------------------';

export interface Event {
  learningId: number;
  learningUrl: string;
  enrollmentId: number;
  title: string;
  locationAddress?: string;
  locationRoom?: string;
  teaser: string;
  start: string;
  end: string;
}

interface UrlParameters {
  [k: string]: string;

  location: string;
}

interface GoogleParameters extends UrlParameters {
  text: string;
  details: string;
  dates: string;
}

interface MicrosoftParameters extends UrlParameters {
  subject: string;
  body: string;
  startdt: string;
  enddt: string;
}

type WebParameters = GoogleParameters | MicrosoftParameters;

interface Calendars {
  [k: string]: {
    url: string;
    convertParameters: (event: Event) => WebParameters;
  };
}

export type CalendarsType = 'microsoft' | 'google' | 'outlook365' | 'ical';

export const CALENDARS_ENUM: { [k: string]: CalendarsType } = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
  OUTLOOK365: 'outlook365',
  ICAL: 'ical',
};

const calendars: Calendars = {
  [CALENDARS_ENUM.GOOGLE]: {
    url: 'http://www.google.com/calendar/event?action=TEMPLATE&trp=false',
    convertParameters: ({ title, locationAddress, locationRoom, teaser, start, end, learningUrl }: Event): GoogleParameters => ({
      text: handleFormatString(title),
      location: handleFormatString([locationAddress, locationRoom].filter((x) => !!x).join(',')),
      details: encodeURIComponent(`${learningUrl}\n\n${teaser}`),
      dates: `${utcToICSDateTime(start)}/${utcToICSDateTime(end)}`,
    }),
  },

  [CALENDARS_ENUM.MICROSOFT]: {
    url: 'https://outlook.live.com/owa/?rru=addevent',
    convertParameters: ({
      title,
      locationAddress,
      locationRoom,
      teaser,
      start,
      end,
      learningUrl,
    }: Event): MicrosoftParameters => {
      return {
        subject: handleFormatString(title),
        location: handleFormatString([locationAddress, locationRoom].filter((x) => !!x).join(',')),
        /*
         * OWA url maxlength is 2048
         * encodeURIComponents can theoretically triple length of body
         * Only use teaser to avoid hitting max
         */
        body: encodeURIComponent(`${learningUrl}<br>${teaser}`),
        startdt: utcToLocalDateСustomFormat(start, 'YYYY-MM-DD[T]HH:mm:ss'),
        enddt: utcToLocalDateСustomFormat(end, 'YYYY-MM-DD[T]HH:mm:ss'),
      };
    },
  },

  [CALENDARS_ENUM.OUTLOOK365]: {
    url: 'https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent',
    convertParameters: ({ title, locationAddress, locationRoom, teaser, start, end }: Event): MicrosoftParameters => {
      const { meetingUrl } = locationMeetingLink(locationAddress, locationRoom);
      const eventDescription = meetingUrl
        ? `Click here to join the meeting <a href="${meetingUrl}">${meetingUrl}</a><br/>${DIVIDER}<br/>${teaser}`
        : teaser;

      return {
        subject: handleFormatString(title),
        location: handleFormatString([locationAddress, locationRoom].filter((x) => !!x).join(',')),
        /*
         * OWA url maxlength is 2048
         * Only use teaser to avoid hitting max
         */
        body: encodeURIComponent(eventDescription),
        startdt: utcToLocalDateСustomFormat(start, 'YYYY-MM-DD[T]HH:mm:ss'),
        enddt: utcToLocalDateСustomFormat(end, 'YYYY-MM-DD[T]HH:mm:ss'),
      };
    },
  },
};

const bowser = getParser(navigator.userAgent);

export function buildIcsFile({
  learningId,
  enrollmentId,
  title,
  locationAddress,
  locationRoom,
  teaser,
  start,
  end,
}: Event): string {
  const { locationString, meetingUrl, meetingTags } = locationMeetingLink(locationAddress, locationRoom);
  const eventDescription = meetingUrl ? `\\nClick here to join the meeting ${meetingUrl}\\n${DIVIDER}\\n${teaser}` : teaser;

  const content = `BEGIN:VCALENDAR
PRODID:-//Collegial//learning:${learningId}//EN
VERSION:2.0
METHOD:PUBLISH
BEGIN:VEVENT
UID:${enrollmentId}-event@collegial
X-WR-RELCALID:${enrollmentId}-event@collegial
METHOD:PUBLISH
STATUS:CONFIRMED
SEQUENCE:0
DTSTAMP:${utcToICSDateTime(new Date())}
DTSTART:${utcToICSDateTime(start)}
DTEND:${utcToICSDateTime(end)}
SUMMARY:${title}
DESCRIPTION:${eventDescription}
LOCATION:${locationString}${meetingTags}
BEGIN:VALARM
ACTION:DISPLAY
DESCRIPTION:REMINDER
TRIGGER:-PT15M
END:VALARM
END:VEVENT
END:VCALENDAR
`;

  return bowser.is('mobile') ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content;
}

function locationMeetingLink(address = '', room = ''): { locationString: string; meetingUrl: string; meetingTags: string } {
  if (address.startsWith('https://meet.google.com')) {
    return {
      locationString: 'Google Meet Meeting',
      meetingUrl: address,
      meetingTags: `\nX-GOOGLE-CONFERENCE:${address}\nCONFERENCE:${address}\nURL;VALUE=URI:${address}`,
    };
  }
  if (address.startsWith('https://teams.microsoft.com')) {
    return {
      locationString: 'Microsoft Teams Meeting',
      meetingUrl: address,
      meetingTags: `\nX-MICROSOFT-SKYPETEAMSMEETINGURL:${address}\nCONFERENCE:${address}\nURL;VALUE=URI:${address}`,
    };
  }
  return {
    locationString: [address, room].filter((s) => !!s).join(', '),
    meetingUrl: '',
    meetingTags: '',
  };
}

export function isInternetExplorer(): boolean {
  return bowser.isBrowser('ie', true);
}

export function calendarUrl({
  calendar,
  event: { learningId, enrollmentId, title, locationAddress, locationRoom, teaser, start, end },
}: {
  calendar: CalendarsType;
  event: Event;
}): string {
  let { url } = calendars[calendar];
  const { convertParameters } = calendars[calendar];

  const parameters = convertParameters({
    learningUrl: document.URL,
    learningId,
    enrollmentId,
    title,
    locationAddress,
    locationRoom,
    teaser,
    start,
    end,
  });

  for (const key in parameters) {
    if (parameters.hasOwnProperty(key) && parameters[key]) {
      url += `&${key}=${encodeURIComponent(parameters[key])}`;
    }
  }

  return url;
}

function formatString(string: string): string {
  return encodeURIComponent(string).replace(/%20/g, '+');
}

function handleFormatString(string: string): string;
function handleFormatString(string: string[]): string[];
function handleFormatString(string: string | string[]) {
  if (Array.isArray(string)) return string.map((s) => formatString(s));
  return formatString(string);
}
