import React from 'react';

import { Icon } from '@/components/Icon';
import { IconPropType } from '@/ui/Icon';
import { TagProps, Tag } from './Tag';

export function TagWithIcon({
  children,
  icon,
  ...tagProps
}: TagProps & { icon: IconPropType; children: React.ReactNode }): JSX.Element {
  return (
    <Tag {...tagProps}>
      <Icon icon={icon} />
      {children}
    </Tag>
  );
}
