import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { LearningProgress } from './types';

const Wrapper = styled.div<{ noCategory: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ noCategory }) =>
    noCategory &&
    css`
      padding: 8px 0;
    `}
`;
const ContentTitle = styled(NavLink)`
  ${(p) => p.theme.typography.body3};
  white-space: normal;
  color: ${(p) => p.theme.tones.primary[50]};
`;

const ContentTitleNoNavigation = styled.div`
  ${(p) => p.theme.typography.body3};
  white-space: normal;
  color: ${({ theme }) => theme.colors.neutral.onContainer};
`;

const Category = styled.span`
  display: block;
  ${(p) => p.theme.typography.overline};
  color: ${(p) => p.theme.colors.neutral.outline2};
`;

export const Info = ({ row: { id, category, subject, title, canNavigate } }: { row: LearningProgress }): JSX.Element => {
  const noCategory = !category;

  return (
    <Wrapper noCategory={noCategory}>
      {!noCategory && (
        <Category>
          {category} / {subject}
        </Category>
      )}
      {!!canNavigate ? (
        <ContentTitle to={`${id}`}>{title}</ContentTitle>
      ) : (
        <ContentTitleNoNavigation>{title}</ContentTitleNoNavigation>
      )}
    </Wrapper>
  );
};
