import { gql } from '@apollo/client';

export const courseraPageQuery = gql`
  query coursera($provider: String, $currentRealm: String) {
    issuedLicense(provider: $provider, realm: $currentRealm) {
      status
      id
      userId
    }
    realmProviderLicensesSummary(provider: $provider, realm: $currentRealm) {
      id
      catalogUrl
    }
  }
`;

export const providerAccessProductIdQuery = gql`
  query providerAccessProduct($realm: String, $provider: String) {
    providerAccessProductId(realm: $realm, provider: $provider) {
      id
    }
  }
`;

export const requestCourseraLicenseMutation = gql`
  mutation coursera($currentRealm: String, $provider: String) {
    requestLicense(provider: $provider, realm: $currentRealm)
  }
`;
