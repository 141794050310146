import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';

import { FilterSchema } from '@/components/CardGrid/CardFilter';
import { filterProviderEnum, filterDurationEnum, filterTypeEnum } from '../types';

export function useResetFiltersEffects(
  methods: UseFormReturn<FilterSchema>,
  availableMeta: { providers: Array<filterProviderEnum>; durations: Array<filterDurationEnum>; types: Array<filterTypeEnum> },
  filterType: filterTypeEnum,
  filterDuration: filterDurationEnum,
  filterProvider: filterProviderEnum
): void {
  useEffect(() => {
    if (filterType && !availableMeta.types.includes(filterType)) {
      methods.setValue('type', '');
    }
  }, [availableMeta.types]);

  useEffect(() => {
    if (filterDuration && !availableMeta.durations.includes(filterDuration)) {
      methods.setValue('duration', '');
    }
  }, [availableMeta.durations]);

  useEffect(() => {
    if (filterProvider && !availableMeta.providers.includes(filterProvider) && filterProvider !== 'empty') {
      methods.setValue('provider', '');
    }
  }, [availableMeta.providers]);
}
