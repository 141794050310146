import { trackEvent } from './countly';

/**
 * Learning Paths (Products)
 */

export const trackUnenrollLearningPathItemClicked = (): void => trackEvent({ key: 'Clicked cancel enrollment' });

export const trackMarkAsCompletedLearningPathClicked = (payload: { learningTitle: string; learningType: string }): void =>
  trackEvent({
    key: 'Clicked marked as completed on learning path',
    segmentation: { title: payload.learningTitle, type: payload.learningType }
  });
