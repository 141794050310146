export const AllBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;

type BreakpointTuple = typeof AllBreakpoints;

export type BreakpointUnion = BreakpointTuple[number];

export const BreakpointEnum = AllBreakpoints.reduce(
  (acc, key) => {
    acc[key] = key;
    return acc;
  },
  {} as {
    [key in BreakpointUnion]: string;
  }
);

export type TaggedTemplateType = (style: TemplateStringsArray, ...expr: (string | number)[]) => string;
