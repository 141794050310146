import styled from 'styled-components';

import { deviceUp } from '@/styles/utils';

export const Media = styled.article<{ reverse?: boolean; alignCenter?: boolean; bordered?: boolean; unpadded?: boolean }>`
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
  display: flex;
  position: relative;

  &:not(:last-child) {
    margin-bottom: var(--gap-sm);
  }

  ${({ bordered }) =>
    bordered &&
    `
    border: var(--border-style);
    border-radius: var(--radius-inner);

    & ${MediaFigure} {
      border-right: var(--border-style);
    }
  `}

  ${({ reverse }) =>
    reverse &&
    `
    & ${MediaFigure} {
      order: 1;
    }
  `}

  > * {
    margin: ${({ reverse, unpadded }) => (unpadded ? 0 : reverse ? '0 0 0 var(--gap-xs)' : '0 var(--gap-xs) 0 0')};

    ${({ reverse, unpadded }) => deviceUp.md`
      margin: ${unpadded ? 0 : reverse ? '0 0 0 var(--gap-sm)' : '0 var(--gap-sm) 0 0'};
    `}
  }
`;

export const MediaFigure = styled.figure`
  border-radius: var(--radius-inner) 0 0 var(--radius-inner);
`;

export const MediaBody = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  &,
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MediaIconWrapper = styled.div`
  align-items: center;
  background-color: var(--color-secondary);
  color: var(--color-text);
  border-radius: var(--gap-md);
  display: flex;
  height: var(--gap-lg);
  justify-content: center;
  margin: var(--gap-sm);
  padding: var(--gap-sm);
  width: var(--gap-lg);
`;
