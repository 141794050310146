import styled, { css } from 'styled-components';

import { Button } from '@/ui/Button';

const ButtonCSS = css`
  ${({ theme }) => theme.shadow.primary[2]};
  transition: all 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral.background};
    ${({ theme }) => theme.shadow.primary[8]};
  }
  gap: 8px;
`;

const DisabledButtonCSS = css`
  opacity: 0.3;
`;

export const RootStyled = styled(Button)`
  ${({ disabled }) => (disabled ? DisabledButtonCSS : ButtonCSS)}
`;

export const TotalStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.tones.neutral[0]};
`;
