export const areas = {
  search: {
    default: `
      filtermeta
      filtertaxonomy
      filterattributes
    `,
    md: `
      filtermeta
      filtertaxonomy filterattributes
    `,
    lg: `
      filtermeta filtertaxonomy filterattributes
    `,
    xl: `
      filtermeta filtertaxonomy filterattributes
    `
  },
  all: {
    default: `
      filtermeta
      filtertaxonomy
      filtertitle
      filterattributes
    `,
    md: `
      filtermeta filtertitle
      filtertaxonomy filterattributes
    `,
    lg: `
      filtermeta filtertitle
      filtertaxonomy filterattributes
    `,
    xl: `
      filtermeta filtertaxonomy filtertitle filterattributes
    `
  },
  events: {
    default: `
      filtermeta
      filtertaxonomy
      filtertitle
      filterattributes
    `,
    md: `
      filtermeta filtertitle
      filtertaxonomy filterattributes
    `,
    lg: `
      filtermeta filtertitle
      filtertaxonomy filterattributes
    `,
    xl: `
      filtermeta filtertaxonomy filtertitle filterattributes
    `
  },
  catsub: {
    default: `
      title
      filtermeta
      filtertitle
      filterattributes
    `,
    md: `
      title filtertitle
      filtermeta filterattributes
    `,
    lg: `
      title filtermeta filtertitle filterattributes
    `,
    xl: `
      title filtermeta filtertitle filterattributes
    `
  }
};
