import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button';
import { getMeta } from './helpers';
import { StyledButtonWrapper, StyledBackgroundImage, StyledContainer } from './styles';
import { FileType, ImageUploadProps, WrapperProps } from './types';
import { useFileUploader } from './useFileUpload';

export const StickyWrapper = ({ children, url, uploading }: WrapperProps): JSX.Element => {
  const overlay = ' lightgray 50%';
  const background = `url(${url}), ${overlay}`;
  return (
    <StyledBackgroundImage background={background} uploading={uploading}>
      {children}
    </StyledBackgroundImage>
  );
};

export const ImageUpload = ({
  setImage,
  spaceId,
  image,
  icon,
  hideDefaultErrorMessage,
  onError,
}: ImageUploadProps): JSX.Element => {
  const [url, setUrl] = useState<string>(image || '');
  const fileInput = createRef<HTMLInputElement>();
  const type: FileType = 'image';
  const { t } = useTranslation('uploaders');
  const { accept } = getMeta(type);

  const { uploadFile, uploading, uploadedFile, error, uploadingProgress } = useFileUploader<'image'>({
    onUploadSuccess: ({ url }) => {
      setImage(url);
      setUrl(url);
    },
    spaceId,
    type,
  });

  useEffect(() => {
    /**
     * TODO For some reason onValidationFail & onUploadFail events are not fired from the hook
     * Requires further investigation.
     *
     * useEffected here for now
     */
    if (error && onError) {
      onError();
    }
  }, [error]);

  const handleClick = () => {
    fileInput.current && fileInput.current.click();
  };

  return (
    <StyledContainer>
      <StickyWrapper url={url} uploading={uploading}>
        <StyledButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
            $loading={!!uploading}
            variant={'neutral'}
            size="large"
            disabled={!spaceId}
            icon={icon}
          >
            {uploading ? t('uploading', { count: uploadingProgress }) : uploadedFile?.url ? t('change-image') : t('upload-image')}
          </Button>
          <input
            type="file"
            accept={accept}
            onChange={(event) => {
              const selectedFile = event.target.files?.[0];
              if (selectedFile) {
                uploadFile(selectedFile);
              }
            }}
            ref={fileInput}
            style={{ display: 'none' }}
          />
          {!hideDefaultErrorMessage && error && <div>An error occurred: {error.title}</div>}
        </StyledButtonWrapper>
      </StickyWrapper>
    </StyledContainer>
  );
};
