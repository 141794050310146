import React, { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { Modal } from '@/ui/Modal';
import { useLearningLoopSelfTrackedLearningAdd } from '@/store/learningLoops';
import { LEARNING_TYPES } from './constants';
import { AddSelfTrackedLearningToLearningLoopInput } from '@/store/learningLoops/types';
import { FormContent, SubmittedContent } from './states';
import { UserSelfTrackedLearningsTypes } from '@/component/UserSelfTrackedLearning/types';
import { TextFieldNameType } from './types';

type AddLearningTimeModalProps = {
  userId?: number;
  loopId?: string;
  open: boolean;
  onClose: () => void;
};

export const AddLearningTimeModal = ({ open, onClose, userId, loopId }: AddLearningTimeModalProps): JSX.Element => {
  const form = useForm<FieldValues>();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittedEffort, setSubmittedEffort] = useState<number>(0);
  const { learningLoopSelfTrackedLearningAdd, loading: submitLoading } = useLearningLoopSelfTrackedLearningAdd();

  const handleSubmit = async (data: Record<TextFieldNameType, string | undefined>) => {
    const { learningDescription, hours, minutes, learningLink, title, learningType } = data;
    const effort = Math.round((Number(hours) || 0) * 3600 + (Number(minutes) || 0) * 60);

    const input = {
      description: learningDescription || '',
      effort,
      link: learningLink || '',
      title,
      learningLoopId: loopId,
      type: LEARNING_TYPES.find(({ value }) => learningType === value)?.type || UserSelfTrackedLearningsTypes.OTHER,
      userId,
    } as AddSelfTrackedLearningToLearningLoopInput;

    await learningLoopSelfTrackedLearningAdd(input);
    setSubmittedEffort(effort);
    setSubmitted(true);
  };

  const handleModalClose = () => {
    onClose();
    form.reset();
    setSubmitted(false);
  };

  return (
    <Modal open={open} onClose={handleModalClose} size="mobile">
      {submitted ? (
        <SubmittedContent effort={submittedEffort} onModalClose={handleModalClose} />
      ) : (
        <FormProvider {...form}>
          <FormContent onSubmit={form.handleSubmit(handleSubmit)} onModalClose={handleModalClose} submitLoading={submitLoading} />
        </FormProvider>
      )}
    </Modal>
  );
};
