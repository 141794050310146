import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { cvar } from '@/styles/GlobalStyle';
import { Icon } from '@/components/Icon';
import { useCategoriesFromCatalog } from '@/hooks/useCategoriesFromCatalog';
import { Loader } from '@/component/Loader';
import { TabsContainer, Tab, Title, ListContainer, ListElement, SelectedCategory } from './ExploreMobileMenuStyles';

enum Tabs {
  SUBJECTS = 'subjects',
  PROVIDERS = 'providers'
}

export const ExploreMobileMenu = ({ close }: { close: () => void }): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(Tabs.SUBJECTS);
  const { t } = useTranslation('catalog');
  const {
    selectedCategoryId,
    setSelectedCategoryId,
    isLoadingCatalog,
    sortedCategoriesFromCatalog,
    topProviders,
    subjectsFromCatalog,
    selectedCategoryName
  } = useCategoriesFromCatalog();
  const isTabSelected = (tabName: Tabs) => tabName === selectedTab;

  const renderContent = () => {
    if (isTabSelected(Tabs.SUBJECTS) && !selectedCategoryId) {
      return sortedCategoriesFromCatalog.map(({ categoryId, categoryName }, index) => (
        <ListElement key={index} onClick={() => setSelectedCategoryId(categoryId)}>
          {categoryName}
          <Icon icon="arrow-right" />
        </ListElement>
      ));
    }
    if (isTabSelected(Tabs.SUBJECTS) && selectedCategoryId && subjectsFromCatalog?.length) {
      return subjectsFromCatalog.map(({ subjectName, subjectId }, index) => (
        <ListElement as={Link} key={index} to={`/learning/category/${selectedCategoryId}/subject/${subjectId}`} onClick={close}>
          {subjectName}
          <Icon icon="arrow-right" />
        </ListElement>
      ));
    }
    return topProviders.map(({ icon, provider, providerName }, index) => (
      <ListElement as={Link} key={index} to={`/learning/provider/${provider}`} onClick={close}>
        <div>
          <Icon icon={icon} style={{ marginRight: cvar('gap-xs') }} />
          {providerName}
        </div>
        <Icon icon="arrow-right" />
      </ListElement>
    ));
  };

  if (isLoadingCatalog) {
    return <Loader />;
  }

  return (
    <>
      <TabsContainer>
        <Tab isSelected={isTabSelected(Tabs.SUBJECTS)} onClick={() => setSelectedTab(Tabs.SUBJECTS)}>
          <Title isSelected={isTabSelected(Tabs.SUBJECTS)}>{selectedCategoryId ? t('Subjects') : t('Categories')}</Title>
        </Tab>
        <Tab isSelected={isTabSelected(Tabs.PROVIDERS)} onClick={() => setSelectedTab(Tabs.PROVIDERS)}>
          <Title isSelected={isTabSelected(Tabs.PROVIDERS)}>{t('Providers')}</Title>
        </Tab>
      </TabsContainer>
      {selectedCategoryId && isTabSelected(Tabs.SUBJECTS) && (
        <SelectedCategory>
          <Icon icon="arrow-left" onClick={() => setSelectedCategoryId(null)} />
          <Title isSelected>{selectedCategoryName}</Title>
          <Link to={`/learning/category/${selectedCategoryId}`} onClick={close} style={{ color: cvar('color-text') }}>
            <Icon icon="arrow-right" />
          </Link>
        </SelectedCategory>
      )}
      <ListContainer>{renderContent()}</ListContainer>
    </>
  );
};
