import styled from 'styled-components';

export const HeroStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
`;

export const TitleStyled = styled.div`
  ${({ theme }) => theme.typography.title2};
  color: ${({ theme }) => theme.colors.neutral.onBackground};
  padding-left: 16px;
`;

export const ContentStyled = styled.div`
  margin: auto;
  max-width: 800px;
`;
