import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { LinkButton } from '@/components/Button/LinkButton';
import { Action } from './Reflection';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useModalContext } from '@/components/Modal/ModalContext';

interface Props {
  id: number;
  path: string[];
  state: {
    isActiveDropdown: boolean;
  };
  isLearnifier?: boolean;
  dispatch: Dispatch<Action>;
  deleteReflection: () => void;
}

export const ReflectionActions = ({ id, path, state, dispatch, deleteReflection, isLearnifier }: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const [isCopied, handleCopy] = useCopyToClipboard(30000);
  const reflectionPath = [...path, id].join('/');
  const embedCode = `CLS:V2:REFLECTIONS(/${reflectionPath})`;
  const previewLink = `/inject-reflection?link=${reflectionPath}`;
  const { showConfirmationModal } = useModalContext();

  return (
    <div className={`dropdown${state.isActiveDropdown ? ' is-active' : ''}`}>
      <div aria-haspopup="true" aria-controls={`dropdown-menu${id}`} className="dropdown-trigger">
        <Button $type="text" $icon="wrench" onClick={() => dispatch({ type: 'toggleDropdown' })}>
          {t('Actions', { ns: 'common' })}
        </Button>
      </div>
      <div className="dropdown-menu" id={`dropdown-menu${id}`} role="menu">
        <div className="dropdown-content">
          <LinkButton target="_blank" $type="base" $fullwidth $icon="search" href={previewLink}>
            {t('Preview')}
          </LinkButton>
          <Button $type="base" $fullwidth $icon="copy" onClick={() => handleCopy(embedCode)}>
            {t('Copy code')}
          </Button>
          {isCopied && (
            <>
              <hr className="dropdown-divider" />
              <div className="dropdown-item is-text is-dark is-small">{embedCode}</div>
              <hr className="dropdown-divider" />
            </>
          )}
          <Button $type="base" $fullwidth $icon="edit" onClick={() => dispatch({ type: 'startEditing' })}>
            {t('Edit')}
          </Button>
          <Button
            $type="base"
            $fullwidth
            $icon="trash"
            onClick={() =>
              isLearnifier
                ? confirm(t('Are you sure you want to delete this reflection?')) && deleteReflection()
                : showConfirmationModal({
                    action: deleteReflection,
                    message: t('Are you sure you want to delete this reflection?')
                  })
            }
          >
            {t('Delete')}
          </Button>
        </div>
      </div>
    </div>
  );
};
