import styled, { css } from 'styled-components';

import { IconButton, Button } from '@/ui/Button';

/**
  Handling case, when element is resized to small dimensions,
  but we still want to have regular toolbar size
 */

const RootStyled = styled.div<{ replaceEnabled: boolean }>`
  ${({ replaceEnabled }) =>
    replaceEnabled &&
    css`
      min-width: 176px;
    `};

  display: flex;
  align-items: center;
  ${({ theme }) => theme.shadow.primary[8]};
  background: ${({ theme }) => theme.colors.neutral.background};
  border-radius: 8px;
  padding: 8px;
  user-select: none;
  gap: 8px;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background: ${({ theme }) => theme.colors.neutral.outline1};
`;

const ReplaceButton = styled(Button)`
  & {
    background: transparent;
  }

  &:focus:not(:active) {
    outline: none;
  }
`;

export const VideoFloatingToolbarButtons = ({
  onRemoveClick,
  onReplaceFile,
}: {
  onRemoveClick: () => void;
  onReplaceFile?: () => void;
}): JSX.Element => {
  return (
    <RootStyled replaceEnabled={Boolean(onReplaceFile)}>
      {!!onReplaceFile && (
        <>
          <ReplaceButton icon={['far', 'rotate']} variant="ghost" onClick={onReplaceFile}>
            Replace
          </ReplaceButton>
          <Divider />
        </>
      )}
      <IconButton icon={['far', 'trash']} onClick={onRemoveClick} />
    </RootStyled>
  );
};
