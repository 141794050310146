import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@/ui/Icon';
import { Tooltip } from '@/ui/Tooltip';
import { Alignment, SortOrder } from '../types';

const StyledHeaderCell = styled.th<{ shrink?: boolean; expand?: boolean }>`
  padding: 0;
  background: ${(p) => p.theme.colors.neutral.outline1};
  ${(p) =>
    p.shrink &&
    !p.expand &&
    css`
      width: 0%;
    `}
  ${(p) =>
    p.expand &&
    !p.shrink &&
    css`
      width: 100%;
    `}
`;
const ContentWrapper = styled.div<{
  align?: Alignment;
  sortable: boolean;
  minimal?: boolean;
  condensed?: boolean;
}>`
  display: flex;
  ${(p) => p.theme.typography.overline};
  color: ${(p) => p.theme.colors.neutral.onBackground};
  justify-content: ${(p) => ({ left: 'flex-start', center: 'center', right: 'flex-end' }[p.align || 'left'])};
  white-space: nowrap;
  ${(p) =>
    !p.minimal &&
    css`
      padding: 16px;
    `}
  ${(p) =>
    p.condensed &&
    css`
      padding-left: 0;
    `}
  display: flex;
  align-items: ${(p) => p.align ?? 'left'};
  cursor: ${(p) => (p.sortable ? 'pointer' : 'auto')};
`;

const SortIcon = styled(Icon).attrs({ icon: 'chevron-down' })`
  display: block;
  width: 12px;
  height: 12px;
`;

const SortButton = styled.button<{
  $sortable?: boolean;
  $order: SortOrder;
}>`
  display: block;
  background: none;
  border: none;
  padding: 0;
  width: 12px;
  height: 12px;
  margin-left: 8px;
  transition: transform 0.2s linear;
  transform: scaleY(${(p) => ({ indefinite: 0, asc: -1, desc: 1 }[p.$order])});
  ${ContentWrapper} > && {
    ${(p) =>
      p.$sortable &&
      css`
        transform: scaleY(${{ indefinite: 1, asc: 1, desc: -1 }[p.$order]});
      `}
  }
`;

const TooltipIcon = styled(Icon).attrs({ icon: ['far', 'circle-info'] })`
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

const resolveNextOrder = (order: SortOrder): Exclude<SortOrder, 'indefinite'> =>
  ((
    {
      asc: 'desc',
      desc: 'asc',
      indefinite: 'asc',
    } as Record<SortOrder, Exclude<SortOrder, 'indefinite'>>
  )[order]);

type HeaderCellProps = {
  order?: SortOrder;
  sortable?: boolean;
  onOrderChange?: (nextOrder: Exclude<SortOrder, 'indefinite'>) => void;
  children?: React.ReactNode;
  tooltip?: string;
  align?: Alignment;
  className?: string;
  minimal?: boolean;
  condensed?: boolean;
  shrink?: boolean;
  expand?: boolean;
};

export const HeaderCell = ({
  order = 'indefinite',
  onOrderChange,
  sortable = false,
  tooltip,
  children,
  align,
  className,
  minimal,
  condensed = false,
  expand,
  shrink,
}: HeaderCellProps): JSX.Element => {
  const handleOrderChange = useCallback(() => onOrderChange?.(resolveNextOrder(order)), [onOrderChange, order]);
  return (
    <StyledHeaderCell shrink={shrink} expand={expand} className={className}>
      <ContentWrapper
        onClick={sortable ? handleOrderChange : undefined}
        align={align}
        sortable={sortable}
        minimal={minimal}
        condensed={condensed}
      >
        {children}
        {tooltip && (
          <Tooltip title={tooltip}>
            <TooltipIcon data-testid="tooltipIcon" />
          </Tooltip>
        )}
        {(sortable || order !== 'indefinite') && (
          <SortButton $order={order} $sortable={sortable} data-testid="sortButton">
            <SortIcon />
          </SortButton>
        )}
      </ContentWrapper>
    </StyledHeaderCell>
  );
};
