import { formatToHoursAndMinutesFromSeconds } from '@/utils/time';

export const formatTimeProgressLabel = (current: number, total: number): string | null => {
  if (!Number.isFinite(current) || !Number.isFinite(total)) {
    return null;
  }

  const secondsLeft = total - current;

  return `${formatToHoursAndMinutesFromSeconds(secondsLeft)} left`;
};
