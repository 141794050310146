import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import './hero.scss';

import { minutesToRelativeTime } from '@/utils/time';
import { Icon } from '@/components/Icon';
import { RightButtons } from './RightButtons';
import { Box } from '@/components/Box/Box';
import { Addons } from '@/components/Addons';
import { Tag } from '@/components/Tag/Tag';
import { ELearningOpenButton } from '@/pages/learning/Learning/components/Hero/ELearningOpenButton';
import { CourseProgress } from '@/types/learning/card';
import { BackToParentButton } from '@/pages/learning/CourseDetails/components/BackToParentButton';

interface Props {
  title: string;
  teaser: string;
  learningId: number;
  editLink?: string;
  imageUrl?: string;
  language?: string;
  effort?: number;
  isArchived: boolean;
  eLearningUrl?: string;
  progress?: CourseProgress;
  onLearningRefetch: () => void;
}

enum BackgroundImageSize {
  Small = 500,
  Medium = 1000,
  Large = 1500,
}

export const Hero = ({
  title,
  teaser,
  editLink,
  imageUrl,
  language,
  effort,
  isArchived,
  eLearningUrl,
  learningId,
  progress,
  onLearningRefetch,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['learning', 'catalogAdmin', 'builderAdmin']);
  const [imageWidth, setImageWidth] = useState(determineBackgroundImageSize());

  const autoResize = () => {
    const newImageWidth = determineBackgroundImageSize();
    if (newImageWidth > imageWidth) setImageWidth(newImageWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', autoResize);
    autoResize();
    return () => window.removeEventListener('resize', autoResize);
  }, []);

  return (
    <section
      className={clsx('hero', 'is-learning', 'is-padded', imageUrl && 'has-image')}
      styleName="has-background-image"
      style={{ backgroundImage: imageUrl ? `url(${imageUrl}?width=${imageWidth})` : undefined }}
    >
      <div className="hero-body">
        <div className="container">
          <Box>
            {isArchived && (
              <div className="message is-warning is-large is-marginless">
                <div className="message-header">{t('This learning has been archived.')}</div>
              </div>
            )}

            <div className="grid">
              <div className="gc gc-8-d gc-7-t gc-12-l gc-12-p">
                <div styleName="preamble">
                  <BackToParentButton />
                  <h1 className="header1">{title}</h1>
                  <div className="preamble">{teaser}</div>
                </div>
              </div>
              <div className="gc gc-4-d gc-5-t gc-12-l gc-12-p">
                <div styleName="right-block">
                  <div className="has-bottom-margin">
                    {effort && (
                      <Addons>
                        <Tag type="transparent">
                          <Icon icon="clock" className="is-grey-darker" fixedWidth />
                        </Tag>
                        <Tag type="transparent">{minutesToRelativeTime(effort)}</Tag>
                      </Addons>
                    )}
                    {language && (
                      <Addons>
                        <Tag type="transparent">
                          <Icon icon="language" className="is-grey-darker" fixedWidth />
                        </Tag>
                        <Tag type="transparent">{language}</Tag>
                      </Addons>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              styleName="enroll-box"
              className={`is-flex ${eLearningUrl ? 'is-justified-space-between' : 'is-justified-right-column'} is-aligned-center`}
            >
              {eLearningUrl && (
                <ELearningOpenButton
                  learningId={learningId}
                  eLearningUrl={eLearningUrl}
                  isLearningCompleted={!!progress?.completed}
                  isLearningOnGoing={!progress?.completed && !!progress?.completedPercentage}
                  title={title}
                  expiredAt={progress?.expiredAt}
                  onLearningRefetch={onLearningRefetch}
                />
              )}

              <RightButtons id={learningId} entity="learning" editLink={editLink} />
            </div>
          </Box>
        </div>
      </div>
    </section>
  );
};

function determineBackgroundImageSize(): number {
  const screenSizeScale = 500;
  const screenSize = Math.ceil(window.innerWidth / screenSizeScale) * screenSizeScale;
  if (screenSize === BackgroundImageSize.Small) {
    return BackgroundImageSize.Small;
  } else if (screenSize === BackgroundImageSize.Medium) {
    return BackgroundImageSize.Medium;
  } else {
    return BackgroundImageSize.Large;
  }
}
