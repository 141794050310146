export const http = {
  get<T = void>(url: string): Promise<T> {
    return fetch(url, {
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(handleResponse);
  },

  post<T = void>(url: string, body: unknown): Promise<T> {
    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(handleResponse);
  },

  postFile(url: string, formData: FormData): Promise<any> {
    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        enctype: 'multipart/form-data',
      },
    }).then(handleResponse);
  },

  postImage<T = void>(url: string, body: FormData): Promise<T> {
    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: body,
    })
      .then(handleResponse)
      .catch((error) => {
        throw error;
      });
  },

  put<T = void>(url: string, body: unknown): Promise<T> {
    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(handleResponse);
  },

  delete<T = void>(url: string, body: unknown): Promise<T> {
    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(handleResponse);
  },
};

export async function handleResponse(response: Response): Promise<any> {
  if (!response.ok) {
    try {
      const errorText = (await response.text()) || response.statusText;
      return Promise.reject(new Error(errorText));
    } catch (e) {
      return Promise.reject(new Error(response.statusText));
    }
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') > -1) {
    return response.json();
  } else {
    return response.text();
  }
}
