import React, { useState, useEffect, createContext } from 'react';

import { noop } from '@/utils/noop';
import { SharedSnackbar } from './SharedSnackbar.component';

export enum SnackbarType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  LINK = 'link',
  PRIMARY = 'primary',
}

export interface SnackbarContent {
  type?: SnackbarType;
  message: string | JSX.Element;
  isDismissive?: boolean;
}

interface ContextProps {
  message?: string | JSX.Element;
  openSnackbar: ({ message, type }: SnackbarContent) => void;
  closeSnackbar: () => void;
  isOpen: boolean;
  type?: SnackbarType;
  isDismissive: boolean;
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

const contextData: ContextProps = {
  isOpen: false,
  message: '',
  isDismissive: false,
  type: SnackbarType.PRIMARY,
  closeSnackbar: noop,
  openSnackbar: ({}: SnackbarContent) => void 0,
};
export const SharedSnackbarContext = createContext(contextData);

const SNACKBAR_DISAPPEARANCE_DELAY = 5000;

export const SharedSnackbarProvider = ({ children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDismissive, setIsDismissive] = useState<boolean>(false);
  const [message, setMessage] = useState<string | JSX.Element | undefined>();
  const [type, setType] = useState<SnackbarType>(SnackbarType.INFO);

  useEffect(() => {
    if (isDismissive) {
      setTimeout(closeSnackbar, SNACKBAR_DISAPPEARANCE_DELAY);
    }
  }, [isDismissive]);

  const openSnackbar = ({ message, type, isDismissive = false }: SnackbarContent) => {
    setMessage(message);
    setIsDismissive(isDismissive);
    setType((type || contextData.type) as SnackbarType);
    setIsOpen(true);
  };

  const closeSnackbar = () => {
    setMessage(undefined);
    setIsDismissive(false);
    setType(SnackbarType.INFO);
    setIsOpen(false);
  };

  return (
    <SharedSnackbarContext.Provider
      value={{
        isDismissive,
        openSnackbar,
        closeSnackbar,
        isOpen,
        message,
        type,
      }}
    >
      <SharedSnackbar />
      {children}
    </SharedSnackbarContext.Provider>
  );
};
