import React from 'react';

import { Button } from '@/ui/Button';

export function generateButton(onClick: () => void, to: string, title: string, isShowAll = true): JSX.Element {
  return isShowAll ? (
    <Button to={to} icon="arrow-right" $iconPosition="end" variant="ghost" size="large" onClick={onClick}>
      {title}
    </Button>
  ) : (
    <Button to={to} variant="primary" size="large" onClick={onClick}>
      {title}
    </Button>
  );
}
