import { SetStateAction, useMemo, useState } from 'react';

import { Activity, TreeActivity } from '@/administration/pages/Course/store/activity/types';
import { useQueryBooleanState } from '@/hooks/useQueryBooleanState';
import { convertActivitiesToSortedNavigationList, convertActivitiesToTree } from '@/pages/ModularLearning/utils';
import { ActivityId } from '@/administration/pages/Course/store/types';

type UseModularLearningViewerResult = {
  activities: Activity[];
  isDrawerOpen: boolean;
  treeActivities: TreeActivity[];
  activityActiveCount: number;
  previousActiveActivityIndex: number;
  activitiesTotalCount: number;
  onSetActivityActive: (ops: { activityId: ActivityId }) => void;
  onSetPrevActivityActive: () => void;
  onSetNextActivityActive: () => void;
  onSetActivityActiveByIndex: (index: number) => void;
  setDrawerOpen: (value: boolean) => void;
  setActivities: (value: SetStateAction<Activity[]>) => void;
};

export const useModularLearningViewerV2 = (): UseModularLearningViewerResult => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isDrawerOpen, setDrawerOpen] = useQueryBooleanState('drawer-open', true);
  const [previousActiveActivityIndex, setPreviousActiveActivityIndex] = useState(0);

  const treeActivities = useMemo(() => {
    return convertActivitiesToTree(activities);
  }, [activities]);

  // Activities flat list (sorted & without modules)
  const sortedNavigationList = useMemo(() => convertActivitiesToSortedNavigationList(activities), [activities]);
  const activityActiveCount = sortedNavigationList?.findIndex((activity) => activity.active);
  const activitiesTotalCount = sortedNavigationList?.length;

  const handleActivateActivity = ({ activityId }: { activityId: ActivityId }) => {
    const activeActivityIndex = sortedNavigationList.findIndex((activity) => activity.active);

    setPreviousActiveActivityIndex(activeActivityIndex);

    setActivities((oldActivities: Activity[]) =>
      oldActivities.map((activity: Activity) =>
        activity.id === activityId ? { ...activity, active: true } : { ...activity, active: false }
      )
    );
  };

  const handleSetPrevActivityActive = () => {
    let indexOf = sortedNavigationList.findIndex((activity) => activity.active) - 1;
    if (indexOf < 0) indexOf = 0;

    handleActivateActivity({ activityId: sortedNavigationList[indexOf].id });
  };

  const handleSetNextActivityActive = () => {
    let indexOf = sortedNavigationList.findIndex((activity) => activity.active) + 1;

    if (indexOf >= sortedNavigationList.length) indexOf = sortedNavigationList.length - 1;

    handleActivateActivity({ activityId: sortedNavigationList[indexOf].id });
  };

  const handleSetActivityActiveByIndex = (index: number) => {
    const parsedIndex = index >= activities.length ? activities.length - 1 : index;

    handleActivateActivity({ activityId: activities[parsedIndex].id });
  };

  return {
    activities,
    isDrawerOpen,
    treeActivities,
    activityActiveCount,
    previousActiveActivityIndex,
    activitiesTotalCount,
    onSetActivityActive: handleActivateActivity,
    onSetPrevActivityActive: handleSetPrevActivityActive,
    onSetNextActivityActive: handleSetNextActivityActive,
    onSetActivityActiveByIndex: handleSetActivityActiveByIndex,
    setDrawerOpen,
    setActivities,
  };
};
