import styled, { css } from 'styled-components';

import { SimpleButtonGroup } from '@/components/Button/styles/buttonGroupStyles';
import { BaseButton } from '@/components/Button/components/BaseButton';

export const ToolbarWrapper = styled.div<{ $minimized?: boolean }>`
  ${({ theme, $minimized }) => {
    return $minimized
      ? css`
          border: 1px solid ${theme.colors.neutral.onContainer};
          border-radius: 4px 4px 0 0;
        `
      : css`
          background: var(--color-background-light);
          border: var(--border-style);
          border-bottom: none;
          border-radius: calc(var(--radius-inner) + var(--border-width)) calc(var(--radius-inner) + var(--border-width)) 0 0;
        `;
  }};
`;

export const ToolbarRight = styled.div`
  padding: 10px;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const StyledToolbarGroup = styled(SimpleButtonGroup)`
  ${BaseButton} {
    border-radius: 0;
    font-size: var(--font-size-7);
    padding: 0 1rem;
  }
`;

export const Divider = styled.div<{ minimized?: boolean }>`
  ${({ theme, minimized }) => {
    return minimized
      ? css`
          border-left: 1px ${theme.colors.neutral.onContainer} solid;
        `
      : css`
          border-left: var(--border-style);
        `;
  }};
  display: inline-block;
  height: 2.75rem;
  margin-left: 8px;
  padding-right: 8px;
  width: 0;
`;

type BlockButtonProps = {
  isActive?: boolean;
};

export const Button = styled.div<BlockButtonProps>`
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  color: var(--color-black);
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  ${({ isActive }) =>
    isActive &&
    `
      background-color: var(--color-alto);
      opacity: 1;
    `}
`;
