import { createContext, useContext, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { mediaQuery } from '@/ui/utils';

type AssignmentCardProps = WithClassNameProp<{
  /** Router link */
  link: string;
  /** Card index for stagerring the fade-in animation */
  stagger?: number;
  /** Card contents. Use <AssignmentCard.* /> atoms */
  children?: React.ReactNode;
  onClick?: () => void;
}>;

const AssignmentCardHoverState = createContext(false);
export const useAssignmentCardHoverState = (): boolean => useContext(AssignmentCardHoverState);

export const AssignmentCard = ({ link, children, className, stagger = 0, onClick }: AssignmentCardProps): JSX.Element => {
  const [hover, setHover] = useState(false);
  const handleHover = useCallback(() => setHover(true), []);
  const handleBlur = useCallback(() => setHover(false), []);

  return (
    <AssignmentCardHoverState.Provider value={hover}>
      <AssignmentCardRoot
        to={link}
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
        className={className}
        $stagger={stagger}
        onClick={onClick}
      >
        {children}
      </AssignmentCardRoot>
    </AssignmentCardHoverState.Provider>
  );
};

export const assignmentCardStyles = css`
  display: grid;
  box-sizing: border-box;
  overflow: hidden;
  height: 155px;
  border-radius: 12px;
  outline: 1px solid transparent;
  ${(p) => p.theme.shadow.primary[4]};
  text-decoration: none;
  grid-template-areas:
    'title image'
    'labels image'
    'metadata image';
  grid-template-columns: minmax(0, 1fr) 120px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  padding: 16px 24px;
  ${mediaQuery.until('mobile')} {
    height: unset;
    grid-template-rows: unset;
    min-height: 155px;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1}
`;

const AssignmentCardRoot = styled(Link)<{ $stagger: number }>`
  ${assignmentCardStyles}
  transition-property: box-shadow, outline;
  transition-duration: ${(p) => p.theme.transitions.normal}ms;
  opacity: 0;
  animation: ${fadeIn} ${(p) => p.theme.transitions.normal}ms forwards;
  animation-delay: ${(p) => p.$stagger * 100}ms;
  :hover {
    ${(p) => p.theme.shadow.primary[16]};
  }
  :focus {
    outline: 1px solid ${(p) => p.theme.colors.primary.outline};
    outline-offset: 0;
  }
`;
