import styled from 'styled-components';

import { StyledDropdown } from '@/components/Dropdown/StyledDropdown';
import { cvar } from '@/styles/GlobalStyle';

type NestedDropdownProps = {
  open?: boolean;
  nested?: boolean;
  fullwidth?: boolean;
  positionToLeft?: boolean;
};

export const NestedDropdown = styled(StyledDropdown)<NestedDropdownProps>`
  ${({ nested }) => (nested ? 'overflow: initial;' : 'overflow-y: auto;')};
  ${({ fullwidth }) => (fullwidth ? 'width: 100%;' : 'width: auto;')};
  ${({ positionToLeft }) => positionToLeft && 'right: 0;'};

  > * {
    padding: ${cvar('gap-sm')};

    // Negate the padding effect from above for dropdown list if it is a nested dropdown
    div[role='listbox'] {
      width: calc(100% - ${cvar('gap-sm')});
      margin-top: calc(${cvar('gap-sm')} * -1);
    }
  }
`;
