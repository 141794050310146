import { Control, Controller } from 'react-hook-form';

import { FormSchema, TextFieldName } from '../..';
import { DateInput } from '@/ui/TextField/variants';

export const FormDate = ({ control }: { control: Control<FormSchema> }): JSX.Element => {
  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={TextFieldName.Date}
      render={({ field: { onChange, name, value }, fieldState: { error } }) => (
        <DateInput
          value={value ? new Date(value) : undefined}
          onChange={(date: Date) => onChange(date.toISOString())}
          inputProps={{
            label: 'Date',
            name,
            error: error && {
              message: 'Enter date',
            },
            // TODO Once issue with TimeInput is fixed, apply fullWidth={true} to match the designs
          }}
        />
      )}
    />
  );
};
