import { css } from 'styled-components';

import { Shadow } from '../types';

export const shadow: Shadow = {
  primary: {
    [2]: css`
      box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    `,
    [4]: css`
      box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    `,
    [8]: css`
      box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    `,
    [16]: css`
      box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    `,
    [32]: css`
      box-shadow: 0px 2.4px 7.4px rgba(0, 0, 0, 0.18), 0px 12.8px 28.8px rgba(0, 0, 0, 0.22);
    `,
    [64]: css`
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4.8px 14.4px rgba(0, 0, 0, 0.18);
    `,
  },
  inset: {
    [2]: css`
      box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.05), inset 0px 0px 2px 0.25px rgba(0, 0, 0, 0.06);
    `,
  },
};
