import { OptionalCardElements, StatusIcon, TransformedCard } from '../types';

export const useOptionalCardElements = (card: TransformedCard): OptionalCardElements => {
  let statusIcon: StatusIcon = 'none';

  if (!card.isPending && card.hasExpirationInfo && card.expiresInDays! < 1) {
    // expired
    statusIcon = 'xMark';
  } else if (!card.isPending && card.isCompleted) {
    // if completed & not expired
    statusIcon = 'checkmark';
  }

  const elements: OptionalCardElements = {
    statusIcon,
    tags: {
      recommended: card.isRecommended,

      mandatory: card.isMandatory,

      featured: card.isFeatured,

      upcoming: card.isEvent,

      expiresInDay: !card.isPending && card.hasExpirationInfo && card.expiresInDays! === 1,

      expiresSoon: !card.isPending && card.hasExpirationInfo && card.expiresInDays! > 1,

      hasExpired: !card.isPending && card.hasExpirationInfo && card.expiresInDays! < 1,

      pendingApproval: card.isPending,
    },

    // There are 3 collections of meta labels: primary, secondary, and tertriary.
    // In the end, we will display only labels from one of them:
    //   - primary labels (if any is active)
    //   - secondary labels (if any is active and no primary label is active)
    //   - tertiary labels (if any is active and no primary nor secondary label is active)
    metaLabels: {
      primary: {
        eventLocation: card.eventLocation !== null && card.eventLocation !== '',
      },

      secondary: {
        completed: !card.isPending && card.isCompleted && (!card.hasExpirationInfo || !(card.expiresInDays! < 1)),

        ongoing: !card.isPending && !card.isCompleted && !!card.participation,

        itemCount: !card.isPending && !card.participation && card.isCollection,
      },

      tertriary: {
        level: card.level !== null,

        totalEffort: true,

        format: card.type !== 'course', //Should not be shown for courseV2
      },
    },
  };

  /** Checks if any primary/secondary/tertiary label is enabled */
  const hasMetaOfType = (type: keyof OptionalCardElements['metaLabels']) =>
    Object.values(elements.metaLabels[type]).reduce((a, b) => a || b, false);

  /** Sets all primary/secondary/tertiary labels to disabled */
  const disableMetaLabelsOfType = (type: keyof OptionalCardElements['metaLabels']) => {
    const flags = Object.keys(elements.metaLabels[type]);
    flags.forEach((flag) => {
      if (flag in elements.metaLabels[type]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (elements.metaLabels[type] as any)[flag] = false;
      }
    });
  };

  if (hasMetaOfType('primary')) {
    disableMetaLabelsOfType('secondary');
    disableMetaLabelsOfType('tertriary');
  } else if (hasMetaOfType('secondary')) {
    disableMetaLabelsOfType('tertriary');
  }

  return elements;
};
