import styled, { css } from 'styled-components';

import { cvar } from '@/styles/GlobalStyle';
import { StyledInput } from '../Form/components/Input/styles';
import { StyledFieldWrapper } from '../Form/styles';
import { StyledDropdown } from './StyledDropdown';

type StyledDropdownWrapperProps = {
  $display?: 'inline' | 'inherit' | 'block';
  $fullwidth?: boolean;
  disabled?: boolean;
};

const InnerStyling = css`
  ${StyledFieldWrapper},
  ${StyledDropdown} {
    box-shadow: ${cvar('box-shadow-large')};
  }

  ${StyledInput} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const StyledDropdownWrapper = styled.div<StyledDropdownWrapperProps>`
  position: relative;
  ${({ $fullwidth }) => $fullwidth && 'width: 100%;'}
  ${({ $display }) => $display && `display: ${$display};`}

  &:hover,
  &:focus-within {
    ${({ disabled }) => !disabled && InnerStyling}
  }
`;
