import React, { useRef, useState } from 'react';

import { SelectStyled } from './styles';
import { SelectContext } from '../SelectContext';
import { SelectProps } from './types';

export const Select = ({
  children,
  value,
  onChange,
  multiple,
  autoclose = true,
  fullWidth,
  className,
}: SelectProps): JSX.Element => {
  const [shouldShowOptions, setShouldShowOptions] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: string) => {
    if (multiple) {
      const currentValue = Array.isArray(value) ? value : [];
      const newValue = value.includes(option) ? [...currentValue.filter((i) => i !== option)] : [...currentValue, option];
      onChange(newValue);
    } else {
      onChange(option);
      setShouldShowOptions(!autoclose);
    }
  };

  const toggleShowOptions = () => setShouldShowOptions((shown) => !shown);

  return (
    <SelectContext.Provider
      value={{
        selectRef,
        value,
        onOptionClick: handleOptionClick,
        shouldShowOptions,
        toggleShowOptions,
        setShouldShowOptions,
      }}
    >
      <SelectStyled ref={selectRef} className={className} fullWidth={fullWidth}>
        {children}
      </SelectStyled>
    </SelectContext.Provider>
  );
};
