import dayjs from 'dayjs';

export const sortByProp = <T, K extends keyof T>(a: T, b: T, property: K): number => {
  const fa = a[property];
  const fb = b[property];
  return -(fa < fb) || +(fa > fb);
};

export const sortByName = <T extends { name: string }>(a: T, b: T): number => {
  const fa = a.name?.toLocaleLowerCase();
  const fb = b.name?.toLocaleLowerCase();
  return -(fa < fb) || +(fa > fb);
};

export const sortExactMatch = <T>(items: T[], getTextProperty: (object: T) => string, filter?: string): T[] => {
  const { compare } = new Intl.Collator(undefined, { numeric: true });

  return items.sort((a, b) => {
    const displayA = getTextProperty(a)
      .trim()
      .toLocaleLowerCase();
    const displayB = getTextProperty(b)
      .trim()
      .toLocaleLowerCase();

    if (!filter) return compare(displayA, displayB);

    const filterLower = filter?.toLocaleLowerCase();

    if (displayA.startsWith(filterLower)) {
      if (displayB.startsWith(filterLower)) return compare(displayA, displayB);
      return -1;
    } else {
      if (displayB.startsWith(filterLower)) return 1;
      return compare(displayA, displayB);
    }
  });
};

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc'
} as const;

export const sortBy = <T>(
  items: readonly T[],
  iteratees: (item: T) => string | number | Date,
  order: typeof SortOrder[keyof typeof SortOrder] = 'asc'
): T[] => {
  const collator = new Intl.Collator(undefined, { numeric: true });

  return [...items].sort((a, b): number => {
    const valueA = iteratees(a);
    const valueB = iteratees(b);

    let result: number;

    switch (true) {
      // compare numbers
      case typeof valueA === 'number' && typeof valueB === 'number':
        result = (valueA as number) - (valueB as number);
      // compare dates
      case valueA instanceof Date && dayjs(valueA).isValid() && valueB instanceof Date && dayjs(valueB).isValid():
        result = new Date(valueA).getTime() - new Date(valueB).getTime();
      // compare strings and unsupported types
      default:
        result = collator.compare(String(valueA), String(valueB));
    }

    return order === 'asc' ? result : -result;
  });
};
