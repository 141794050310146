import { Children } from 'react';
import styled from 'styled-components';

export const Footer = styled.div`
  grid-column-start: metadata;
  grid-column-end: image;
  flex-shrink: 1;
  display: ${(p) => (Children.map(p.children, (c) => !!c)?.reduce((a, b) => a || b, false) ? 'flex' : 'none')};
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
`;
