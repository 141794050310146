import { makeNamespacedComponent } from '../utils';
import { Modal as Modal_ } from './Modal';
import { Action, Actions, Contents, Error, Title } from './atoms';

const Modal = makeNamespacedComponent(Modal_, {
  Action,
  Actions,
  Contents,
  Error,
  Title,
});

export { Modal };
