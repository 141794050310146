import styled from 'styled-components';

import { ViewItemV2, ViewItemV2Props } from '@/administration/pages/Course/MainLayout/variants/View/atoms/ViewItemV2/ViewItemV2';
import { makeNamespacedComponent } from '@/ui/utils';

const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 92px);
`;

const ListStyled = styled.div`
  overflow: scroll;
`;

type ViewDrawerV2Props = {
  items: ViewItemV2Props[];
};

const _ViewDrawerV2 = ({ items }: ViewDrawerV2Props): JSX.Element => {
  return (
    <RootStyled>
      <ListStyled>
        {items.map((item) => (
          <ViewItemV2 {...item} key={item.id} />
        ))}
      </ListStyled>
    </RootStyled>
  );
};

const LoadingState = (): JSX.Element => (
  <RootStyled>
    <ViewItemV2.LoadingState />
  </RootStyled>
);

export const ViewDrawerV2 = makeNamespacedComponent(_ViewDrawerV2, {
  LoadingState,
});
