import styled from 'styled-components';

import { preventOrphans } from '@/utils/text';
import { variant } from './sizing';

export const Title = styled.span.attrs((p) => ({
  children: typeof p.children === 'string' ? preventOrphans(p.children) : p.children,
}))<{ strechVertically?: boolean }>`
  ${(p) => p.strechVertically && 'flex: 1;'}

  ${(p) => p.theme.typography.title4};
  color: ${(p) => p.theme.colors.neutral.onBackground};

  ${variant.small} {
    margin: 0 24px;
  }

  ${variant.large} {
    margin: 0 16px;
  }
`;
