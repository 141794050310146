import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { Provider, ProviderList } from '@/store/providers';
import { screenSizes } from './misc';

type ProviderSizes = {
  [key in Provider]: SizeProp;
};

const availableProviderSizes = [
  ProviderList.COLLEGIAL,
  ProviderList.LEXICON,
  ProviderList.TEKNISKA_COLLEGE,
  ProviderList.IMD,
] as const;

const PROVIDER_SIZES: Pick<ProviderSizes, typeof availableProviderSizes[number]> & { default: SizeProp } = {
  [ProviderList.COLLEGIAL]: '2x',
  [ProviderList.IMD]: '1x',
  [ProviderList.LEXICON]: 'lg',
  [ProviderList.TEKNISKA_COLLEGE]: '2x',
  default: '3x',
};

export const getIconSize = (provider: Provider, width?: number): SizeProp => {
  if (width && width < screenSizes.landscapeMin) return '1x';
  if (provider in PROVIDER_SIZES) return PROVIDER_SIZES[provider as typeof availableProviderSizes[number]];
  return PROVIDER_SIZES.default;
};
