import dayjs from 'dayjs';
import isNil from 'lodash.isnil';

import { SortOrder } from '../types';

export const sort = <T>(items: T[], sortBy: keyof T, sortOrder: SortOrder): T[] => {
  const sorted = [...items].sort((a, b) => {
    if (isNil(a[sortBy])) {
      return -1;
    }

    if (typeof a[sortBy] === 'string') {
      if (dayjs(String(a[sortBy])).isValid()) {
        return new Date(String(a[sortBy])).getTime() - new Date(String(b[sortBy])).getTime();
      }

      return String(a[sortBy] || '').localeCompare(String(b[sortBy] || ''));
    } else if (typeof a[sortBy] === 'number') {
      return Number(a[sortBy]) - Number(b[sortBy]);
    }

    // Hint. If one day we need a more complex sorting logic, we can also take a sorting function as an argument

    return 0;
  });
  if (sortOrder === 'desc') {
    sorted.reverse();
  }
  return sorted;
};
