import { useState, useRef, useEffect, memo } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import styled from 'styled-components';

import notStartedAnimation from '../animations/notStarted.json';
import startedAnimation from '../animations/started.json';
import finishedAnimation from '../animations/finished.json';
import endedAnimation from '../animations/ended.json';
import { useAssignmentCardHoverState } from './AssignmentCard';

const animationMap = {
  notStarted: notStartedAnimation,
  ongoing: startedAnimation,
  completed: finishedAnimation,
  ended: endedAnimation,
};

type AnimationProps = {
  variant: 'notStarted' | 'ongoing' | 'completed' | 'ended';
};

const Animation = ({ variant }: AnimationProps): JSX.Element => {
  const animationData = animationMap[variant];
  const ref = useRef<LottieRefCurrentProps | null>(null);
  const lottie = ref.current;
  const hover = useAssignmentCardHoverState();
  const stopAtFinalFrame = () => lottie?.goToAndStop((lottie.getDuration(true) ?? 1) - 1, true);

  // At start, we want to set the animation at its final frame. But there's no reliable way
  // to tell when the Lottie view is ready (it accepts `onDataReady` callback,
  // but it doesn't get called at all). To bypass that, let's poll Lottie's ref
  // value; once it's truthy, we're good to go.
  const [animationReady, setAnimationReady] = useState(false);
  useEffect(stopAtFinalFrame, [animationReady]);
  useEffect(() => {
    if (!animationReady) {
      const interval = setInterval(() => {
        if (ref.current) {
          setAnimationReady(true);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [animationReady]);

  // Play the animation indefinitely for as long as the card is focused:
  useEffect(() => {
    if (lottie && hover) {
      lottie.play();
    }
  }, [hover]);

  // When the card is no longer hovered, we let the animation finish
  // its current loop and then stop it at the final frame:
  const stopOnLoopEnd = () => {
    !hover && stopAtFinalFrame();
  };

  // If the animation is still unfinished after the card has been un-hovered,
  // play the remaining part faster:
  useEffect(() => {
    lottie?.setSpeed(hover ? 1 : 4);
  }, [hover]);

  return (
    <AnimationRoot
      lottieRef={ref}
      autoplay={false}
      animationData={animationData}
      onLoopComplete={stopOnLoopEnd}
      $reveal={animationReady}
    />
  );
};

const MemoizedAnimation = memo(Animation);
export { MemoizedAnimation as Animation };

const AnimationRoot = styled(Lottie)<{ $reveal: boolean }>`
  opacity: ${(p) => (p.$reveal ? 1 : 0)};
  transition: opacity ${(p) => p.theme.transitions.normal}ms;
  grid-area: image;
  max-width: 120px;
  max-height: 120px;
  justify-self: center;
  align-self: center;
`;
