import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GET_REPLIES, POST_FRAGMENT, useCreatePost } from '../../store/discussion';
import { TextInput } from './TextInput';
import { Thread } from './Thread';
import { Post } from '../../types/post';
import { LinkButton } from '@/components/Button/LinkButton';
import { trackReflectionLearningItemCommented } from '@/utils/tracking/learnings';
import { useCurrentUserAccess } from '@/store/currentUser';

interface Props {
  forceLoadReplies: boolean;
  path: string[];
  postId: number;
  onSentPost?: () => void;
  onDeletePost?: () => void;
  expandable?: boolean;
}

const GET_USER_REPLIES = gql`
  query getUserReplies($path: [String!]!, $postId: Int, $page: Int, $pageSize: Int) {
    getUserReplies(path: $path, postId: $postId, page: $page, pageSize: $pageSize) {
      replies {
        page
        pageSize
        total
        pages
        items {
          ...PostFragment
        }
      }
    }
  }
  ${POST_FRAGMENT}
`;

const loadingComponent = <div style={{ fontSize: 14, padding: 20, opacity: 0.5 }}>Loading...</div>;

const PAGE_SIZE = 3;

export const ReflectionComments = ({
  path,
  postId,
  forceLoadReplies,
  onSentPost,
  onDeletePost,
  expandable,
}: Props): JSX.Element => {
  const { t } = useTranslation('discussions');
  const createPost = useCreatePost();
  const { isAdmin } = useCurrentUserAccess();

  const variables = { path, postId, page: 1, pageSize: PAGE_SIZE };

  // When we add cursor based pagination, we will add sorting (on e.g. user) so we don't have to make two requests
  // and then just check if the first post is our own post, then we can display the rest of the posts without making a new request
  const {
    data: myReplies,
    loading: loadingMyReplies,
    error: errorMyReplies,
    refetch: refetchMyReplies,
  } = useQuery(GET_USER_REPLIES, { variables: { ...variables, pageSize: 1 } });

  const myPost = myReplies?.getUserReplies?.replies?.items[0] || null;
  const hasPost = myPost && !myPost.deleted;
  const shouldSeeReplies = isAdmin || hasPost || forceLoadReplies;

  const {
    data: allReplies,
    loading: loadingAllReplies,
    error: errorAllReplies,
    refetch: refetchAllReplies,
    fetchMore,
  } = useQuery(GET_REPLIES, { variables, skip: !shouldSeeReplies });
  const replies: Post[] = allReplies?.getReplies?.replies?.items || [];

  const repliesWithoutUser = replies.filter((reply) => !myPost || reply.id !== myPost.id);

  const post = async ({ content }: Post) => {
    await createPost(path, { content, pid: postId });
    trackReflectionLearningItemCommented();
    await Promise.all([refetchMyReplies(), refetchAllReplies()]);

    onSentPost && onSentPost();
  };

  const currentPage: number = allReplies?.getReplies?.replies.page || 0;
  const totalPages: number = allReplies?.getReplies?.replies.pages || 0;

  const loadMoreReplies = () =>
    fetchMore({
      variables: { ...variables, page: currentPage + 1 },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult
          ? {
              getReplies: {
                ...fetchMoreResult.getReplies,
                replies: {
                  ...fetchMoreResult.getReplies.replies,
                  items: [...prev.getReplies.replies.items, ...fetchMoreResult.getReplies.replies.items],
                },
              },
            }
          : prev;
      },
    });

  if (loadingMyReplies) return loadingComponent;

  if (errorMyReplies || errorAllReplies) {
    return (
      <article className="message is-warning">
        <div className="message-body">{t('post-error', { context: 'reflection' })}</div>
      </article>
    );
  }

  return (
    <>
      {hasPost && (
        <>
          <div style={{ padding: '0 20px 20px' }}>
            <Thread
              isReflection
              depth={0}
              maxDepth={2}
              {...myPost}
              path={path}
              pageSize={PAGE_SIZE}
              onDeletePost={onDeletePost}
            />
          </div>
        </>
      )}
      {shouldSeeReplies && repliesWithoutUser.length > 0 && (
        <>
          <hr className="is-marginless" />
          <div style={{ marginBottom: 24, padding: '0 20px' }}>
            {repliesWithoutUser.map((reply) => (
              <Thread isReflection key={reply.id} depth={0} maxDepth={2} {...reply} path={path} pageSize={PAGE_SIZE} />
            ))}
            {totalPages > currentPage && <LinkButton onClick={loadMoreReplies}>{t('Show more')}</LinkButton>}
            {loadingAllReplies && loadingComponent}
          </div>
        </>
      )}

      {!hasPost && (
        <TextInput
          contentStyle={{
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderRadius: 0,
            borderBottomLeftRadius: 8,
            ...(expandable ? { height: undefined, maxHeight: undefined } : {}),
          }}
          $fullSize={expandable}
          buttonStyle={{ borderRadius: 0, borderBottomRightRadius: 8, marginRight: '-1px', height: 'calc(100% + 1px)' }}
          placeholder="Write a reflection..."
          onPosted={post}
        />
      )}
    </>
  );
};
