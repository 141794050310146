import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { FormSchema, TextFieldName } from '../..';
import { CustomEditorV2, EMPTY_VALUE } from '@/component/customEditorV2';

const RootStyled = styled.div`
  height: 240px;

  & div[role='textbox'] {
    height: calc(100% - 44px);
  }
`;

export const FormDescription = ({ control, spaceId }: { control: Control<FormSchema>; spaceId: number }): JSX.Element => {
  return (
    <Controller
      control={control}
      name={TextFieldName.Description}
      render={({ field: { onChange, value } }) => (
        <RootStyled>
          <CustomEditorV2
            value={value || EMPTY_VALUE}
            onChange={(description) => onChange(description)}
            style={{ fontSize: 14 }}
            $fullSize
            minimized
            spaceId={spaceId}
            placeholder="Add description (optional)"
            toggledFeatures={{
              mediaFiles: true,
            }}
          />
        </RootStyled>
      )}
    />
  );
};
