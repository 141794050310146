import { Routes, Route, Navigate } from 'react-router';

import { Tabs, FadeInContents } from '../atoms';
import { makeNestedPath } from '../helpers';
import { UseTabsHook } from '../types';

export const useTabs: UseTabsHook = (tabs, loading) => {
  const defaultPath = tabs.find((t) => t.defaultSelected)?.path;

  const contentOutlet = (
    <Routes>
      {tabs.map(({ path, component: Component }) => (
        <Route key={path} path={makeNestedPath(path)} element={<FadeInContents key={path}>{Component}</FadeInContents>} />
      ))}
      <Route path="*" element={defaultPath ? <Navigate replace to={defaultPath} /> : null} />
    </Routes>
  );

  const navigationOutlet = (
    <Tabs>
      {tabs.map(({ path, searchParams, label }) =>
        loading ? (
          <Tabs.LoadingState key={path} path={path} />
        ) : (
          <Tabs.Link key={path} path={path} searchParams={searchParams} label={label} />
        )
      )}
    </Tabs>
  );

  return {
    contentOutlet,
    navigationOutlet,
  };
};
