import dayjs from 'dayjs';

import { LearningLoop } from '@/store/learningLoops/types';
import { WeeklyLearningLoop } from '../../components/LoopChart/atoms/WeeksNavigation/types';

export const transformToWeeklyLearningLoop = (result: LearningLoop[]): WeeklyLearningLoop[] => {
  const today = dayjs(new Date());

  return result.map((x, index): WeeklyLearningLoop => {
    return {
      id: x.id,
      weekNumber: index + 1,
      timeState: x.isActive ? 'current' : dayjs(x.endAt).isBefore(today) ? 'past' : 'future',
      currentEffort: x.estimatedEffort,
      recommendedEffort: x.recommendedEffort,
      weekStartTimestamp: x.startAt,
      weekEndTimestamp: x.endAt,
      canReflect: x.canReflect,
    };
  });
};
