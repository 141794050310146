/* eslint-disable react/display-name */
import React, { forwardRef, useRef } from 'react';
import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import { TextFieldProps } from '../../base/types';
import { Input } from '../Input';
import { DateInputProps } from './types';

/** Work-around for satisfying CustomInput props (controlled by third-party of react-datepicker) */
const STUB_CUSTOM_INPUT_PROPS: Pick<TextFieldProps, 'value' | 'onChange'> = {
  value: '',
  onChange: () => {},
};

const RootStyled = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;

      .react-datepicker-wrapper {
        width: 100%;
      }
    `};
`;

/** Temporary fix for issue of no click events on React Datepicker */
const InputWrap = styled.div`
  & label,
  svg {
    pointer-events: none;
  }
`;

/** Fix for ref warning of React Datepicker @link https://github.com/Hacker0x01/react-datepicker/issues/2165#issuecomment-711032947 */
const CustomInput = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <InputWrap>
    <Input {...props} ref={ref} leadingIcon={['far', 'calendar']} />
  </InputWrap>
));

export const DateInput = ({ value, onChange, inputProps, options, onFilterDates }: DateInputProps): JSX.Element => {
  const ref = useRef(null);

  return (
    <RootStyled fullWidth={inputProps.fullWidth}>
      <DatePicker
        selected={value}
        onChange={onChange}
        disabled={inputProps.disabled}
        showWeekNumbers
        dateFormat={'yyyy-MM-dd'}
        customInput={<CustomInput {...inputProps} {...STUB_CUSTOM_INPUT_PROPS} ref={ref} />}
        popperProps={{
          positionFixed: options?.positionFixed /** @link https://stackoverflow.com/a/64256411 */,
        }}
        filterDate={onFilterDates}
      />
    </RootStyled>
  );
};
