import { CourseCardData } from '@/store/inventory';
import { ParticipationGroup } from '@/types/analytics';
import { Card, ProgressCard } from '@/types/learning/card';
import { EventEnrollment } from '@/types/learning/learning-catalog';
import { Assignment, AssignmentPage, AssignmentLearningCard } from './types';
import { getCards } from './getCards';
import { getStandardPriority } from './standardAssignment/getStandardPriority';
import { getStrategicPriority } from './strategicAssignment/getPriority';

type LearningCardProps = {
  assignment: Assignment;
  myLearningCatalogCards: ProgressCard[];
  inventoryCards: CourseCardData[];
  catalogCards: Card[];
  participations?: ParticipationGroup[];
  eventEnrollments?: readonly EventEnrollment[];
};

export const getAssignmentPage = ({
  assignment,
  myLearningCatalogCards,
  inventoryCards,
  catalogCards,
  participations,
  eventEnrollments,
}: LearningCardProps): AssignmentPage | undefined => {
  if (assignment.assignmentType === 'STANDARD') {
    const learnings = assignment.learnings ?? [];
    const cards = getCards({ myLearningCatalogCards, learnings, inventoryCards, catalogCards, participations, eventEnrollments });
    const learningCards = cards.map((x): AssignmentLearningCard<'STANDARD'> => {
      const standardLearning = learnings.find(
        (m) =>
          (m.learningId === x.card.entityId && x.card.entity === 'learning') ||
          (m.learningPathId === x.card.entityId && x.card.entity === 'product')
      );

      const isMandatory = standardLearning?.type === 'MANDATORY';
      const isRecommended = standardLearning?.type !== 'MANDATORY';

      return {
        ...x.card,
        status: x.status,
        isMandatory,
        isRecommended,
        order: standardLearning?.order,
        priority: getStandardPriority({ status: x.status, type: standardLearning?.type }),
      };
    });
    return {
      id: assignment?.id,
      name: assignment.name,
      type: assignment.assignmentType,
      cards: learningCards,
    };
  } else if (assignment.assignmentType === 'STRATEGIC') {
    const learnings = assignment.learnings ?? [];
    const cards = getCards({ myLearningCatalogCards, learnings, inventoryCards, catalogCards, participations, eventEnrollments });
    const learningCards = cards.map((x): AssignmentLearningCard<'STRATEGIC'> => {
      const standardLearning = learnings.find(
        (m) =>
          (m.learningId === x.card.entityId && x.card.entity === 'learning') ||
          (m.learningPathId === x.card.entityId && x.card.entity === 'product')
      );

      return {
        ...x.card,
        order: standardLearning?.order,
        priority: getStrategicPriority(x.status),
        status: x.status,
      };
    });

    return {
      id: assignment?.id,
      name: assignment.name,
      type: assignment.assignmentType,
      cards: learningCards,
    };
  }

  return undefined;
};
